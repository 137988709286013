const positiveIntegerOnlyInput = (
  e: React.KeyboardEvent<HTMLElement>
): void => {
  const { key } = e;

  if (key !== 'Backspace' && key !== 'Delete' && !key.match(/^[0-9]+$/)) {
    e.preventDefault();
  }
};

export default positiveIntegerOnlyInput;
