import create from 'zustand';
import { QuickOrderBookingQuery } from './types/QuickOrderBookingQuery.interface';

type QuickOrderBookingState = {
  storedQuery: QuickOrderBookingQuery | undefined;
  setStoredQuery: (storedQuery: QuickOrderBookingQuery) => void;
};

const useQuickOrderBookingStore = create<QuickOrderBookingState>((set) => ({
  storedQuery: undefined,
  setStoredQuery: (storedQuery: QuickOrderBookingQuery) =>
    set(() => ({ storedQuery })),
}));

export default useQuickOrderBookingStore;
