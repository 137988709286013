import { Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import Table from '../../../../components/Table/Table';
import { LoadPlan } from '../types/LoadPlan.interface';
import styles from './LoadPlanTable.module.scss';

interface ILoadPlanTableProps {
  loadPlans: LoadPlan[];
}

const LoadPlanTable: React.FunctionComponent<ILoadPlanTableProps> = ({
  loadPlans,
}) => {
  const columns: ColumnsType<LoadPlan> = [
    {
      title: (
        <>
          <Trans>Container # </Trans> {+loadPlans[0]?.containerNumber}
        </>
      ),
      key: 'containerNumber',
      render: (value, record) => (
        <>
          {record.materialNumber}
          <br />
          {record.length === 0 && (
            <span>
              W: {record.sheetWidth}; RD: {record.reelDiameter}; CD:{' '}
              {record.coreDiameter}
            </span>
          )}
          {record.length > 0 && (
            <span>
              W: {record.sheetWidth}; L: {record.length}; H:{' '}
              {record.heightPerPallet};
            </span>
          )}
        </>
      ),
    },
    {
      title: <Trans>Layer</Trans>,
      key: 'layer',
      dataIndex: 'layer',
    },
    {
      title: <Trans>Row</Trans>,
      key: 'row',
      dataIndex: 'row',
    },
    {
      title: <Trans>Column</Trans>,
      key: 'column',
      dataIndex: 'column',
    },
  ];
  return (
    <Table
      rowKey={(record) => JSON.stringify(record)}
      className={styles.loadPlanTable}
      columns={columns}
      dataSource={loadPlans}
      pagination={false}
    />
  );
};

export default LoadPlanTable;
