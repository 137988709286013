import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQueryCache } from 'react-query';
import Bookmark from '../../../../components/Bookmark/Bookmark';
import { Button } from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Callout from '../../../../components/Callout/Callout';
import ShippingInformationModal from '../../../../components/ShippingInformationModal/ShippingInformationModal';
import FirstColumnCell from '../../../../components/Table/FirstColumnCell';
import Table from '../../../../components/Table/Table';
import useUserCustomization from '../../../../hooks/useUserCustomization';
import useCustomerBasket from '../../../../hooks/useCustomerBasket';
import showNotification from '../../../../services/Notification';
import { togglePriorityFlagFromOrder } from '../../../../services/PriorityFlag';
import { saveUserCustomization } from '../../../../services/User';
import { updateBasket } from '../../../../services/Basket/index';
import { Order } from '../../../../types/Order.interface';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { SearchOrderInputs } from '../../SearchOrderInputs.interface';
import { ViewOrdersRow } from './ViewOrdersRow.interface';
import { isBookmarked } from './viewOrdersTableCell.util';
import './ViewOrdersWidgetTable.scss';
import useExpandedRowKeyStore from './expandedRowKey.store';
import {
  confirmedDeliveryDateColumn,
  orderColumn,
  productColumn,
  shipToColumn,
  skuColumn,
  stateColumn,
} from './viewOrdersTableColumns.util';
import useViewOrdersRow from './useViewOrderRows';
import { UserCustomization } from '../../../../types/User/UserCustomization.interface';
import { getItemToAdd } from './ActionsMenuPopover.util';
import { AuthorityRole } from '../../../../types/Authority.interface';
import useRole from '../../../../hooks/useRole';

interface IViewOrdersWidgetTableProps {
  data: Order[];
  filters?: Partial<SearchOrderInputs>;
}

const SHOW_ALL_PAGE_SIZE = 10000;
const STATUS_THRESHOLD_FOR_SHIPPING_INFO = 40;

const ViewOrdersWidgetTable: React.FunctionComponent<IViewOrdersWidgetTableProps> = ({
  data,
  filters,
}) => {
  const { viewOrderRows } = useViewOrdersRow(data);
  const [tableData, setTableData] = useState<ViewOrdersRow[]>();
  const [showCallout, setShowCallout] = useState<boolean>(false);
  const [addingToBasket, setAddingToBasket] = useState(false);
  const [showBasketPopUp, setShowBasketPopUp] = useState(false);
  const { getCustomerBasket } = useCustomerBasket();
  const { hasRole } = useRole();

  const [deliveryDetailsOrder, setDeliveryDetailsOrder] = useState<Order>();
  const [
    deliveryDetailsModalVisible,
    setDeliveryDetailsModalVisible,
  ] = useState<boolean>(false);
  const { data: userCustomization } = useUserCustomization();
  const queryCache = useQueryCache();
  const { generateRowKey } = useExpandedRowKeyStore();

  useEffect(() => {
    setTableData(viewOrderRows);
  }, [viewOrderRows]);

  useEffect(() => {
    if (userCustomization && !userCustomization.hideBookmarksCallout) {
      setShowCallout(true);
    }
  }, [userCustomization]);

  const updateUserBookmarkCallout = async () => {
    setShowCallout(false);
    await saveUserCustomization({
      ...userCustomization,
      hideBookmarksCallout: true,
    } as UserCustomization);
    queryCache.invalidateQueries(QueryCacheName.USER_CUSTOMIZATION);
  };
  
  const orderSimilar = async (record: ViewOrdersRow) => {
    setAddingToBasket(true);
    const itemToAdd = await getItemToAdd(record);
    const customerBasket = getCustomerBasket(record.customerNumber);
    if (customerBasket) {
      customerBasket.items.push(itemToAdd);
      await updateBasket(customerBasket);
      setShowBasketPopUp(true);
      setAddingToBasket(false);
      queryCache.invalidateQueries(QueryCacheName.BASKET);
    }
  };

  const toggleBookmark = async (record: ViewOrdersRow) => {
    const overviews = await togglePriorityFlagFromOrder(record);
    if (tableData) {
      setTableData((state) =>
        state?.map((row) => {
          if (row.key === record.key) {
            row.overviews = overviews;
          }
          return row;
        })
      );
    }
    const notificationTxt = `${t({
      id: 'yourOrder',
      message: 'Your Order #',
    })} ${record.documentNumber} ${t({
      id: 'order_tracking.bookmark.confirm_message',
      message: 'was added to your Bookmarked Orders on the Dashboard',
    })}`;
    if (isBookmarked(record)) {
      showNotification({
        message: '',
        description: notificationTxt,
      });
    }
    queryCache.invalidateQueries([QueryCacheName.ORDERS, filters], {
      refetchActive: false,
    });
  };

  const showShippingDetailsModal = (record: ViewOrdersRow) => {
    setDeliveryDetailsModalVisible(true);
    setDeliveryDetailsOrder(record);
  };

  const onShippingDetailsClose = () => {
    setDeliveryDetailsModalVisible(false);
  };

  const columns: ColumnsType<ViewOrdersRow> = [
    {
      title: (
        <Callout
          title={<Trans id="order_tracking.callout.title">Bookmarks</Trans>}
          body={
            <Trans>
              Select the bookmark symbol to boomark an order and view it on your
              dashboard
            </Trans>
          }
          onClose={updateUserBookmarkCallout}
          visible={showCallout}
          placement="right"
        >
          <Bookmark solid inheritColor />
        </Callout>
      ),
      dataIndex: 'bookmark',
      key: 'bookmark',
      width: '78px',
      align: 'center',
      render: (text: string, record: ViewOrdersRow) => {
        return (
          <FirstColumnCell isChildRow={record.isChild || false}>
            <Bookmark
              solid={isBookmarked(record)}
              onClick={() => toggleBookmark(record)}
            />
          </FirstColumnCell>
        );
      },
      sorter: (a: ViewOrdersRow, b: ViewOrdersRow) =>
        isBookmarked(a) ? 1 : -1,
      sortDirections: ['descend', 'ascend'],
    },
    orderColumn,
    stateColumn,
    {
      title: <i className="fa fa-truck" />,
      dataIndex: 'shippingInformation',
      key: 'shippingInformation',
      render: (text: string, record: ViewOrdersRow): JSX.Element => (
        <>
          {parseInt(record.overviews[0].status, 10) >=
          STATUS_THRESHOLD_FOR_SHIPPING_INFO ? (
            <Button
              theme="link"
              onClick={() => showShippingDetailsModal(record)}
              title={`${t({
                id: 'order_list.group_order.transit_info',
                message: 'Transit information for order #',
              })}
      ${record.customerOrderNumber}`}
            >
              <i className="fa fa-truck" />
            </Button>
          ) : (
            <i className="fa fa-truck" />
          )}
        </>
      ),
      align: 'center',
    },
    productColumn,
    skuColumn,
    confirmedDeliveryDateColumn,
    shipToColumn,
    {
      title: <Trans>Actions</Trans>,
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: ViewOrdersRow) => {
        return (
          <div className="text-lg leading-8">
            {record.overviews.length === 1 &&
            record.overviews[0].orderStatusText !== "DELIVERED TO YOUR PREMISES OR PORT OF LOAD" && (
            <Button theme="link" onClick={() => orderSimilar(record)}>
              <div className="flex">
                <Trans id="availableActions.orderSimilar">
                  Order Similar
                </Trans>
                {addingToBasket && <LoadingSpinner className="mt-1 ml-2" />}
              </div>
            </Button>
          )}
          </div>
        )
      },
    },
  ];
  
  return (
    <>
      <Table
        dataSource={tableData}
        columns={columns.filter(
          (column) =>
            column.key === 'bookmark' ||
            column.key === 'order' ||
            column.key === 'status' ||
            column.key === 'shippingInformation' ||
            column.key === 'product' ||
            column.key === 'sku' ||
            column.key === 'confirmedDeliveryDate' ||
            column.key === 'shipTo' ||
            column.key === 'orderDate' ||
            (column.key === 'actions' && hasRole(AuthorityRole.ROLE_NA) && hasRole(AuthorityRole.ROLE_ORDER_BOOKING))
        )}
        className="sappi-table-override-dashboard"
        pagination={{ pageSize: SHOW_ALL_PAGE_SIZE, hideOnSinglePage: true }}
        rowKey={(record: ViewOrdersRow) => generateRowKey(record)}
        locale={{
          emptyText: (
            <div className="text-gray-600 my-3" id="order_list.empty">
              <Trans>Orders can be added to this section in</Trans>{' '}
              <a className="link" href="#/orderTracking/">
                <Trans>Track Orders</Trans>
              </a>
              <Trans>
                . Select the bookmark symbol within Track Orders to add them
                here.
              </Trans>
            </div>
          ),
        }}
      />
      <ShippingInformationModal
        visible={deliveryDetailsModalVisible}
        customerNumber={get(deliveryDetailsOrder, 'customerNumber', '')}
        shipToPartyName={get(
          deliveryDetailsOrder,
          'overviews[0].shipToPartyName',
          ''
        )}
        shipToPartyCity={get(
          deliveryDetailsOrder,
          'overviews[0].shipToPartyCity',
          ''
        )}
        documentNumber={get(deliveryDetailsOrder, 'documentNumber', '')}
        customerOrderNumber={get(
          deliveryDetailsOrder,
          'customerOrderNumber',
          ''
        )}
        overviews={deliveryDetailsOrder?.overviews || []}
        onClose={onShippingDetailsClose}
      />
    </>
  );
};

export default ViewOrdersWidgetTable;
