import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import styles from './LoginHeader.module.scss';

const LoginHeader: FC = () => {
  return (
    <div className={styles.header}>
      <Trans>
        A leading global provider of sustainable woodfibre products and
        solutions.
      </Trans>
    </div>
  );
};

export default LoginHeader;
