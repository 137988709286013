import { DatePicker as AntDatePicker, Tooltip } from 'antd';
import { Moment } from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/hu';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/sk';
import * as React from 'react';
import useLanguage from '../../hooks/useLanguage';
import useRole from '../../hooks/useRole';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import { AuthorityRole } from '../../types/Authority.interface';
import './DatePicker.scss';
import getDatePickerLocale from './getDatePickerLocale.util';

export interface IDatePickerProps {
  onChange?: (momentDate: Moment | null, dateStr: string) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onOk?: ((date: Moment) => void) | undefined;
  disabledDate?: (date: Moment) => boolean;
  value?: Moment | null;
  disabled?: boolean;
  hasError?: boolean;
  toolTipErrorMsg?: string | React.ReactNode;
  required?: boolean;
  className?: string;
  showTime?: boolean | { format: string };
  allowClear?: boolean;
}
export const DEFAULT_EU_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_NA_FORMAT = 'MM/DD/YYYY';
export const PARAM_FORMAT = 'YYYY-MM-DD';

const DatePicker: React.FunctionComponent<IDatePickerProps> = ({
  onChange,
  onBlur,
  onOk,
  disabledDate,
  value,
  disabled,
  hasError,
  toolTipErrorMsg,
  required,
  className = '',
  showTime,
  allowClear,
}) => {
  const EU_FORMATTING_OPTION = showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
  const NA_FORMATTING_OPTION = showTime ? 'MM/DD/YYYY HH:mm' : 'MM/DD/YYYY';
  const EU_FORMAT_ARRAY = [
    EU_FORMATTING_OPTION,
    'D/M/YYYY',
    'DD/M/YYYY',
    'D/MM/YYYY',
  ];
  const NA_FORMAT_ARRAY = [
    NA_FORMATTING_OPTION,
    'M/D/YYYY',
    'M/DD/YYYY',
    'MM/D/YYYY',
  ];
  const { hasRole } = useRole();
  const urlParams = useUrlSearchParams();
  const lang = useLanguage();
  const locale = getDatePickerLocale(lang);
  const placeHolder = (): string => {
    const naPlaceholder = showTime ? 'mm/dd/yyyy hh:mm' : 'mm/dd/yyyy';
    const euPlaceholder = showTime ? 'dd/mm/yyyy hh:mm' : 'dd/mm/yyyy';
    return hasRole(AuthorityRole.ROLE_NA) ? naPlaceholder : euPlaceholder;
  };

  return (
    <Tooltip
      overlayClassName={`sappi-ant-datepicker-tooltip ${
        hasError ? '' : 'hidden'
      }`}
      title={toolTipErrorMsg}
      trigger="hover"
    >
      <AntDatePicker
        className={`${className} ${
          hasError
            ? 'sappi-ant-override-datepicker-error'
            : 'sappi-ant-override-datepicker'
        } ${required ? 'sappi-ant-override-datepicker-required' : ''}`}
        disabledDate={disabledDate}
        onChange={onChange}
        onBlur={onBlur}
        onOk={onOk}
        locale={locale}
        format={
          hasRole(AuthorityRole.ROLE_EU) ? EU_FORMAT_ARRAY : NA_FORMAT_ARRAY
        }
        suffixIcon={
          <i className="fa fa-calendar text-blue-pacific" aria-hidden="true" />
        }
        value={value}
        placeholder={placeHolder()}
        disabled={disabled}
        showTime={showTime}
        allowClear={allowClear}
      />
    </Tooltip>
  );
};

export default DatePicker;
