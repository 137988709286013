import { t, Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import InputLabel from '../../../../components/InputLabel';
import Textarea from '../../../../components/Textarea/Textarea';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import CustomerBasketFormData from './types/CustomerBasketFormData.interface';
import CustomerBasketFormFields from './types/CustomerBasketFormField.enum';

interface LabelInfoCellContentProps {
  itemStacksPerPallet: AvailabilityBasketItem['stacksPerPallet'];
  itemRequestedUnitOfMeasure: AvailabilityBasketItem['requestedUnitOfMeasure'];
  formMethods: UseFormMethods<CustomerBasketFormData>;
  itemIndex: number;
  itemProductType: AvailabilityBasketItem['productType'];
}

const LabelInfoCellContent: FC<LabelInfoCellContentProps> = ({
  itemStacksPerPallet,
  itemRequestedUnitOfMeasure,
  formMethods,
  itemIndex,
  itemProductType,
}) => {
  const { errors, register } = formMethods;
  const isSheet = itemProductType === PutUpCategory.SHEET;

  return (
    <div>
      {itemStacksPerPallet && itemRequestedUnitOfMeasure !== UnitOfMeasure.RL && (
        <div>
          {itemStacksPerPallet} <Trans>ST/Pallet</Trans>
        </div>
      )}
      <InputLabel
        text={isSheet ? t`Pallet Label Info` : t`Roll Label Info`}
        size="lg"
      >
        <Textarea
          name={`items.${itemIndex}.${CustomerBasketFormFields.NEW_LABEL}`}
          ref={register({ maxLength: 240 })}
          error={
            errors?.items?.[itemIndex]?.[CustomerBasketFormFields.NEW_LABEL]
          }
          maxLength={240}
          width="full"
        />
      </InputLabel>
    </div>
  );
};

export default LabelInfoCellContent;
