import { Steps as AntdSteps } from 'antd';
import { StepsProps } from 'antd/lib/steps';
import * as React from 'react';
import './Steps.scss';

interface IStepsProps extends StepsProps {
  attr?: unknown;
}

const Steps: React.FunctionComponent<IStepsProps> = ({ children, ...rest }) => {
  return (
    <div className="sappi-ant-override-steps">
      <AntdSteps {...rest}>{children}</AntdSteps>
    </div>
  );
};

export const { Step } = AntdSteps;
export default Steps;
