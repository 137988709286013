import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';

interface INoTitleCellProps {
  item: AvailabilityBasketItem;
}

const NoTitleCell: FC<INoTitleCellProps> = ({ item }) => {
  return (
    <>
      <div>
        <Trans>Per Ton Price</Trans>
      </div>
      <br />
      {item.productType === PutUpCategory.SHEET && (
        <div>
          <Trans>Per 1000 Sheets</Trans>
        </div>
      )}
    </>
  );
};

export default NoTitleCell;
