import { Trans } from '@lingui/macro';
import * as React from 'react';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';

interface ITableOrderNumCellProps {
  item: ConsignmentInventoryItem;
}

const TableOrderNumCell: React.FunctionComponent<ITableOrderNumCellProps> = ({
  item,
}) => {
  return (
    <>
      {item.customerPurchaseOder && (
        <div>
          <Trans>My Order #</Trans>: {item.customerPurchaseOder}
        </div>
      )}
      {item.documentNumber && (
        <div>
          <Trans>Sappi Order #</Trans>: {stripZeros(item.documentNumber)}
          {item.salesDocumentItem && `/${stripZeros(item.salesDocumentItem)}`}
        </div>
      )}
    </>
  );
};

export default TableOrderNumCell;
