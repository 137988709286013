import { Space } from 'antd';
import React, { FC } from 'react';
import PromoPosition from '../../types/PromoPosition.enum';
import ImagePromo from '../Promo/ImagePromo';

interface IPodThreeProps {
  className?: string;
}
const PodThree: FC<IPodThreeProps> = ({ className = '' }) => {
  return (
    <Space direction="vertical" size="middle" className={`${className}`}>
      <ImagePromo position={PromoPosition.DASHBOARD_TOP} />
      <ImagePromo position={PromoPosition.DASHBOARD_BOTTOM} />
    </Space>
  );
};

export default PodThree;
