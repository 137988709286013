import * as React from 'react';
import { Trans } from '@lingui/macro';

interface IProductAvailabilityCheckboxInputProps {
  checked: boolean | undefined;
  onChange: (checked: boolean) => void;
  text: string | React.ReactNode;
}

const ProductAvailabilityCheckboxInput: React.FunctionComponent<IProductAvailabilityCheckboxInputProps> = ({
  checked,
  onChange,
  text,
}) => {
  return (
    <label className="pr-6 inline-block whitespace-nowrap">
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />{' '}
      <span className="text-lg leading-tight mr-2">{text}</span>
    </label>
  );
};

export default ProductAvailabilityCheckboxInput;
