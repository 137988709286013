import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';

const RegistrationPromoText: FC = () => {
  return (
    <Space
      size="large"
      direction="vertical"
      className="ml-52 text-3xl mt-8"
      style={{ width: '400px' }}
    >
      <div>
        <Trans>
          Sappi e-commerce is a customer portal delivering seamless access to
          customer information, at your fingertips 24/7.
        </Trans>
      </div>
      <div>
        <Trans>Not yet a Sappi customer?</Trans>
        <div>
          <Trans>Visit us at</Trans>&nbsp;
          <a
            className="app-link"
            href="https://www.sappi.com"
            target="_blank"
            rel="noreferrer"
          >
            https://www.sappi.com
          </a>
        </div>
      </div>
      <div>
        <Trans>
          Our Sappi website offers information on products, services,
          sustainability, careers, locations, and more!
        </Trans>
      </div>
    </Space>
  );
};

export default RegistrationPromoText;
