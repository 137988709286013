import { useQuery } from 'react-query';
import { getPromoContent } from '../../services/Content';
import { PromoContent } from '../../types/PromoContent.interface';
import PromoPosition from '../../types/PromoPosition.enum';
import QueryCacheName from '../../types/QueryCacheName.enum';

const usePromoContent = (
  lang: string,
  position: PromoPosition
): {
  promoContent: PromoContent | undefined;
  isFetching: boolean;
} => {
  const { data: promoContent, isFetching } = useQuery(
    [QueryCacheName.PROMO_CONTENT, position],
    () => getPromoContent(lang, position),
    {
      staleTime: Infinity,
      enabled: lang,
      keepPreviousData: true,
    }
  );
  return {
    promoContent,
    isFetching,
  };
};

export default usePromoContent;
