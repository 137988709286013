import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import Option from '../../../../types/Option.type';

interface IFormLeftHalfProps {
  register: UseFormMethods['register'];
  customerOptions: Option[];
}

const FormLeftHalf: React.FunctionComponent<IFormLeftHalfProps> = ({
  register,
  customerOptions,
}) => {
  const IDS = {
    soldToCustomerNumber: 'sold-to-customer-number',
    customerPurchaseOrder: 'customer-purchase-order',
    documentNumber: 'document-number',
    invoiceNumber: 'invoice-number',
    custMatSku: 'customer-material-sku-number',
  };

  return (
    <div className="w-1/2">
      <div className="text-base">
        <p>
          <Trans>
            Select customer and status and any additional fields below to look
            up status of consignment inventory.
          </Trans>
        </p>
      </div>
      <div className="mb-10">
        <InputLabel
          text={<Trans>Customer</Trans>}
          size="lg"
          required
          htmlFor={IDS.soldToCustomerNumber}
        />
        <Select
          name="soldToCustomerNumber"
          ref={register({ required: true })}
          options={customerOptions}
          id={IDS.soldToCustomerNumber}
          required
        />
      </div>
      <div className="w-1/2">
        <div className="mb-4">
          <InputLabel
            text={<Trans>My Order #</Trans>}
            visuallyHidden
            htmlFor={IDS.customerPurchaseOrder}
          />
          <Input
            type="text"
            name="customerPurchaseOrder"
            ref={register()}
            id={IDS.customerPurchaseOrder}
            placeholder={t`My Order #`}
          />
        </div>
        <div className="mb-4">
          <InputLabel
            text={<Trans>Sappi Order #</Trans>}
            visuallyHidden
            htmlFor={IDS.documentNumber}
          />
          <Input
            type="text"
            name="documentNumber"
            ref={register()}
            id={IDS.documentNumber}
            placeholder={t`Sappi Order #`}
          />
        </div>
        <div className="mb-4">
          <InputLabel
            text={<Trans>Invoice #</Trans>}
            visuallyHidden
            htmlFor={IDS.invoiceNumber}
          />
          <Input
            type="text"
            name="invoiceNumber"
            ref={register()}
            id={IDS.invoiceNumber}
            placeholder={t`Invoice #`}
          />
        </div>
        <div className="mb-4">
          <InputLabel
            text={<Trans>Customer Material #</Trans>}
            visuallyHidden
            htmlFor={IDS.custMatSku}
          />
          <Input
            type="text"
            name="custMatSku"
            ref={register()}
            id={IDS.custMatSku}
            placeholder={t`Customer Material #`}
          />
        </div>
      </div>
    </div>
  );
};

export default FormLeftHalf;
