import { Trans } from '@lingui/macro';
import { noop } from 'lodash';
import React, { FC } from 'react';
import useAnalytics from '../../hooks/useAnalytics';

interface ICustomizeDashboardProps {
  setVisibility: (dashboardCustomizationIsVisible: boolean) => void;
}

const CustomizeDashboard: FC<ICustomizeDashboardProps> = ({
  setVisibility,
}) => {
  const { trackPageView } = useAnalytics();
  return (
    <button
      className="border border-gray-light400 border-solid text-xl w-full"
      onClick={() => {
        console.log(`Track Page View CUSTOMIZEDASHBOARD`);
        trackPageView('ADMIN', 'CUSTOMIZE_DASHBOARD');
        setVisibility(true)
      }}
    >
      <div className="flex justify-between">
        <div className="text-blue-pacific p-3 ml-2">
          <Trans>Customize Your Dashboard</Trans>
        </div>
        <i className="fa fa-gear p-3" />
      </div>
    </button>
  );
};

export default CustomizeDashboard;
