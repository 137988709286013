import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { getPartnerSearchCustomerByNumber } from '../../../../services/Customer';
import { PartnerSearchCustomer } from '../../../../types/PartnerSearchCustomer.interface';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { SamplesOrder } from '../../../../types/Samples/SamplesOrder.interface';
import { SamplesOrderDeliveryAddress } from '../../../../types/Samples/SamplesOrderDeliveryAddress.interface';
import CustomerCell from './CustomerCell';
import styles from './ShippingInstructions.module.scss';
import ShippingInstructionsCellLeft from './ShippingInstructionsCellLeft';
import ShippingInstructionsCellRight from './ShippingInstructionsCellRight';
import ShippingType from './ShippingType.enum';
import ShipToCell from './ShipToCell';

interface IShippingInstructionsProps {
  order?: SamplesOrder;
  buyer?: PartnerSearchCustomer;
  isFetchingBuyer?: boolean;
  readonly?: boolean;
}

const ShippingInstructions: React.FunctionComponent<IShippingInstructionsProps> = ({
  order,
  buyer,
  isFetchingBuyer,
  readonly,
}) => {
  const {
    resolvedData: shipTo,
    isFetching: isFetchingShipTo,
  } = usePaginatedQuery<PartnerSearchCustomer>(
    [QueryCacheName.PARTNER_SEARCH_CUSTOMER, order?.shipToNumber, true],
    getPartnerSearchCustomerByNumber,
    {
      staleTime: Infinity,
      enabled: order?.shipToNumber && order?.shipToNumber !== 'NEW',
      keepPreviousData: true,
    }
  );
  const [
    newShipTo,
    setNewShipTo,
  ] = useState<SamplesOrderDeliveryAddress | null>();

  useEffect(() => {
    if (order?.shipToNumber === 'NEW') {
      setNewShipTo(order.deliveryAddress);
    }
  }, [order]);

  return (
    <table
      className={`${styles.table} my-4 border-t border-b border-gray-light400 w-full`}
    >
      <thead>
        <tr>
          <th>
            <Trans>Customer</Trans>
          </th>
          <th>
            <Trans>Ship To</Trans>
          </th>
          <th colSpan={2} style={{ width: '55%' }}>
            <Trans>Shipping Instructions</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{!isFetchingBuyer && <CustomerCell buyer={buyer} />}</td>
          <td>
            {!isFetchingShipTo && (
              <ShipToCell
                shipTo={newShipTo || shipTo}
                deliveryInfoName={order?.deliveryInfo?.name}
              />
            )}
          </td>
          <td>
            <ShippingInstructionsCellLeft
              readonly={readonly}
              defaultCustomCourierAccountNumber={
                order?.shippingAccountNumber as string
              }
              defaultIsCustomCourier={order?.customShipping}
              defaultShippingType={order?.shippingType as ShippingType}
            />
          </td>
          <td style={{ width: '28%' }}>
            <ShippingInstructionsCellRight
              readonly={readonly}
              defaultComment={order?.deliveryComment as string}
              defaultCourierAccount={order?.courierAccountNumber as string}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ShippingInstructions;
