import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Space } from 'antd';
import React, { FC } from 'react';
import { Button } from '../../components/Button/Button';
import useQuickLinks from '../../hooks/useQuickLinks';
import { colors } from '../../theme';
import rightChevronSrc from '../../assets/Arrow-Thin-Right.svg';

const QuickLinks: FC = () => {
  const { enabledQuickLinks: quickLinks } = useQuickLinks();

  return (
    <section
      className="p-6"
      style={{ border: `20px solid ${colors.gray.whiteSmoke200}` }}
    >
      <div className="mb-6 text-4xl">
        <Trans>Quick Links</Trans>
      </div>
      {quickLinks && quickLinks.length > 0 && (
        <Space direction="vertical" size="large">
          {quickLinks.map((quickLink) => (
            <Space key={quickLink.id} className="w-full" align="center">
              <img src={rightChevronSrc} alt="" className="w-2" />
              <Button
                theme="link"
                onClick={() => window.open(quickLink.url, quickLink.target)}
                className="text-left text-lg text-blue-cobaltVariation"
              >
                {i18n._(quickLink.label)}
              </Button>
            </Space>
          ))}
        </Space>
      )}
    </section>
  );
};

export default QuickLinks;
