import { useQuery } from 'react-query';
import { orderBy } from 'lodash';
import Option from '../../types/Option.type';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { StockLotRequestParams } from './types/StockLotRequestParams.interface';
import { deriveGrammageOptions } from './StockLot.util';
import { getGrammages } from './services';
import PutUpCategory from '../../types/PutUpCategory.enum';

const useGrammageOptions = (
  customerNumber: string,
  division: string,
  productType: PutUpCategory,
  gradeDescription: string
): {
  grammageOptions: Option[];
  isFetchingGrammage: boolean;
} => {
  const stockLotGrammageParams: StockLotRequestParams = {
    customerNumber,
    productType,
    division,
    gradeDescription,
  };
  const { data: grammages, isFetching } = useQuery(
    [QueryCacheName.STOCK_LOT_GRAMMAGES, stockLotGrammageParams],
    () => getGrammages(stockLotGrammageParams),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      enabled: customerNumber && productType && division && gradeDescription,
      keepPreviousData: true,
    }
  );

  const grammageOpts = orderBy(deriveGrammageOptions(grammages || []), [
    'label',
  ]);
  return {
    grammageOptions: grammageOpts,
    isFetchingGrammage: isFetching,
  };
};

export default useGrammageOptions;
