import Axios from 'axios';
import { filter, flatten, get, omit, orderBy, padStart, uniqBy } from 'lodash';
import { AvailabilityCustomer } from '../../types/AvailabilityCustomer.interface';
import AvailabilityPlanningOptions from '../../types/AvailabilityPlanningOptions.interface';
import BaseWeight from '../../types/BaseWeight.interface';
import Brand from '../../types/Brand.interface';
import EuBrand from '../../types/EuBrand.interface';
import { EuOrderBookingBrand } from '../../types/EuOrderBookingBrand.interface';
import GetBaseWeightsParams from '../../types/GetBaseWeightsParams.interface';
import Option from '../../types/Option.type';
import PutUpCategory from '../../types/PutUpCategory.enum';
import { EMPTY_OPTION_SELECT } from '../Util/emptyOption.const';
import generateOptions from '../Util/generateOptions.util';

/**
 * Utility
 */
export const getOption = (
  brand: Brand | EuOrderBookingBrand,
  key: string
): Option => {
  let option;
  switch (key) {
    case 'baseWeight':
      option = {
        key: `${get(brand, key)} ${get(brand, 'tradeBasis')}`,
        value: `${get(brand, key)} ${get(brand, 'tradeBasis')}`,
        label: `${get(brand, key)} ${get(brand, 'tradeBasis')}`,
      };
      break;
    case 'baseWeightGrammageAsValue':
      option = {
        key: `${get(brand, 'baseWeight')} ${get(brand, 'tradeBasis')}`,
        value: get(brand, 'grammage'),
        label: `${get(brand, 'baseWeight')} ${get(brand, 'tradeBasis')}`,
      };
      break;
    case 'finish':
      option = {
        value: get(brand, 'finish'),
        label: get(brand, 'finishDescription') === undefined ? '' : get(brand, 'finishDescription').toUpperCase(),
      };
      break;
    case 'euBrandName':
      option = {
        value: get(brand, 'brand'),
        label: get(brand, 'brandName'),
      };
      break;
    default:
      option = {
        value: get(brand, key),
        label: get(brand, key),
      };
  }
  return option;
};

export const getUniqueOptionsByKey = (
  brands: any[] | undefined,
  key: string,
  defaultSelectLabel?: Option['label']
): Option[] => {
  const options: Option[] = [
    {
      value: '',
      label: defaultSelectLabel || '',
    },
  ];
  let uniqueKey: string;
  switch (key) {
    case 'baseWeightGrammageAsValue':
      uniqueKey = 'baseWeight';
      break;
    case 'euBrandName':
      uniqueKey = 'brandName';
      break;
    default:
      uniqueKey = key;
  }
  return brands
    ? options.concat(
        uniqBy(brands, (brand) =>
          key === 'baseWeightGrammageAsValue'
            ? `${brand.baseWeight} ${brand.tradeBasis}`
            : brand[uniqueKey]
        ).map((b) => getOption(b, key))
      )
    : [];
};

export const getFilteredGrammage = (
  brands: Brand[] = [],
  selectedGrammage?: string
): Brand[] => {
  return selectedGrammage
    ? filter(brands, (b) => b.grammage === selectedGrammage)
    : brands;
};

export const getFilteredCaliper = (
  brands: Brand[] = [],
  selectedCaliper?: string,
): Brand[] => {
  return selectedCaliper
    ? filter(brands, (b) => String(b.caliper) === selectedCaliper)
    : brands;
};

export const getFilteredCaliperMils = (
  brands: Brand[] = [],
  selectedCaliperMils?: string,
): Brand[] => {
  return selectedCaliperMils
    ? filter(brands, (b) => String(b.caliperMils) === selectedCaliperMils)
    : brands;
};

export const getFilteredChemistry = (
  brands: Brand[] = [],
  selectedChemistry?: string
): Brand[] => {
  return selectedChemistry
    ? filter(brands, (b) => b.chemistry === selectedChemistry)
    : brands;
};

export const getFilteredTexture = (
  brands: Brand[] = [],
  selectedTexture?: string
): Brand[] => {
  return selectedTexture
    ? filter(brands, (b) => b.texture === selectedTexture)
    : brands;
};

export const getFilteredParentBrands = (
  brands: Brand[] = [],
  selectedParentBrand?: string
): Brand[] =>
  selectedParentBrand
    ? filter(brands, (b) => b.parentBrand === selectedParentBrand)
    : brands;

export const getFilteredBrands = (
  brands: Brand[] = [],
  selectedBrand?: string
): Brand[] =>
  selectedBrand ? filter(brands, (b) => b.brand === selectedBrand) : brands;

const getFilteredEUBrandsByBrand = (
  brands: EuBrand[] = [],
  selectedBrand?: string
) =>
  selectedBrand ? filter(brands, (b) => b.brand === selectedBrand) : brands;

const getUniqueEuOptionsByKey = (
  brands: any[] | undefined,
  key: string,
  selectedBrand?: string,
  defaultSelectLabel?: string
): Option[] => {
  const options: Option[] = [
    {
      value: '',
      label: defaultSelectLabel || '',
    },
  ];
  const filteredBrands = selectedBrand
    ? getFilteredEUBrandsByBrand(brands, selectedBrand)
    : brands;

  if (filteredBrands) {
    flatten(filteredBrands.map((brand) => brand[key])).forEach((value) => {
      if (key === 'finishes') {
        options.push({
          value: value.finish,
          label: value.finishDescription.toUpperCase(),
        });
      } else {
        options.push({
          value,
          label: value,
        });
      }
    });
  }
  return uniqBy(options, 'value');
};

export const getBrandOptions = (
  brands: Brand[] | undefined,
  options?: AvailabilityPlanningOptions
): Option[] => {
  const filteredByGrammage = getFilteredGrammage(
    brands,
    options?.selectedGrammage
  );
  
  const filteredByCaliper = getFilteredCaliper(
    filteredByGrammage,
    options?.selectedCaliper
  );
  
  const filteredByCaliperMils = getFilteredCaliperMils(
    filteredByGrammage,
    options?.selectedCaliper
  );

  return getUniqueOptionsByKey(
    options?.isMetric ? filteredByCaliperMils : filteredByCaliper,
    'brand',
    options?.defaultSelectLabel
  );
};

export const getEuBrandOptions = (
  brands: EuBrand[] | undefined,
  options?: AvailabilityPlanningOptions
): Option[] => {
  const emptyOption = options?.defaultSelectLabel
    ? {
        value: '',
        label: options.defaultSelectLabel,
      }
    : EMPTY_OPTION_SELECT;

  return generateOptions(
    (brands || []) as EuBrand[],
    (brand, index) => ({
      label: brand.brand,
      value: index,
    }),
    emptyOption
  );
};

export const getEuOrderBookingBrandOptions = (
  euBrands: EuOrderBookingBrand[],
  type: PutUpCategory
): Option[] => {
  const filteredBrands = filter(euBrands, {
    productType: type,
  });
  return getUniqueOptionsByKey(filteredBrands, 'euBrandName');
};

export const getFinishOptions = (
  brands: Brand[] | undefined,
  selectedBrand?: string,
  defaultSelectLabel?: string
): Option[] =>
  getUniqueOptionsByKey(getFilteredBrands(brands, selectedBrand), 'finish', defaultSelectLabel);

export const getEuFinishOptions = (
  brands: EuBrand[] | undefined,
  selectedBrand?: string
): Option[] => {
  const options: Option[] = getUniqueEuOptionsByKey(
    brands,
    'finishes',
    selectedBrand
  );
  return orderBy(options, ['value'], ['asc']);
};

export const getCaliperOptions = (
  brands: Brand[] | undefined,
  options?: AvailabilityPlanningOptions
): Option[] => {
  const filteredByBrands = getFilteredBrands(brands, options?.selectedBrand);
  const filteredByGrammage = getFilteredGrammage(
    filteredByBrands,
    options?.selectedGrammage
  );

  const uniqueOptions = getUniqueOptionsByKey(
    filteredByGrammage,
    options?.isMetric ? 'caliperMils' : 'caliper',
    options?.defaultSelectLabel
  );
  return orderBy(uniqueOptions, 'value', 'asc');
};

export const getEuCaliperOptions = (
  brands: EuBrand[] | undefined,
  selectedBrand?: string,
  isMetric?: boolean,
  defaultSelectLabel?: string
): Option[] => {
  const options: Option[] = getUniqueEuOptionsByKey(
    brands,
    isMetric ? 'calipersInMils' : 'calipers',
    selectedBrand,
    defaultSelectLabel
  );
  return orderBy(options, ['value'], ['asc']);
};

export const getGrammageOptions = (
  brands: Brand[] | undefined,
  selectedBrand?: string,
  defaultSelectLabel?: string
): Option[] =>
  getUniqueOptionsByKey(
    getFilteredBrands(brands, selectedBrand),
    'grammage',
    defaultSelectLabel
  );
  
export const getAvailabilityGrammageOptions = (
  brands: Brand[] | undefined,
  options: AvailabilityPlanningOptions
): Option[] => {
    const filteredByBrands = getFilteredBrands(brands, options.selectedBrand);
    const filteredByCaliper = getFilteredCaliperMils(
    filteredByBrands,
    options.selectedCaliper
  );
  
  const uniqueOptions = getUniqueOptionsByKey(
    filteredByCaliper,
    'grammage',
    options?.defaultSelectLabel
  );
  return orderBy(uniqueOptions, 'value', 'asc');
};
  
export const getEuGrammageOptions = (
  brands: EuBrand[] | undefined,
  key: string,
  selectedBrand?: string,
  defaultSelectLabel?: string
): Option[] => {
  let options: Option[] = getUniqueEuOptionsByKey(brands, key, selectedBrand);
  const defaultLabel = defaultSelectLabel || '';
  options = options.map((o) => {
    return {
      label:
        String(o.label) !== ''
          ? padStart(String(o.label), 3, '0')
          : defaultLabel,
      value: String(o.value) !== '' ? padStart(String(o.value), 3, '0') : '',
    };
  });

  return orderBy(options, ['value'], ['asc']);
};

export const getOrderBookingGrammageOptions = (
  euBrands: EuOrderBookingBrand[],
  type: PutUpCategory,
  selectedBrand?: string
): Option[] => {
  const filteredBrands = selectedBrand
    ? filter(euBrands, {
        productType: type,
        brand: selectedBrand,
      })
    : euBrands;
  return getUniqueOptionsByKey(orderBy(filteredBrands, 'grammage'), 'grammage');
};

export const getBaseWeightOptions = (
  brands: Brand[] | undefined,
  options: AvailabilityPlanningOptions,
  useBaseWeightForValue?: boolean
): Option[] => {
  const filteredByBrands = getFilteredBrands(brands, options.selectedBrand);
  const filteredByCaliper = getFilteredCaliper(
    filteredByBrands,
    options.selectedCaliper
  );

  return getUniqueOptionsByKey(
    orderBy(
      options.selectedCaliper ? filteredByCaliper : filteredByBrands,
      ['tradeBasis', 'baseWeight'],
      ['desc', 'asc']
    ),
    useBaseWeightForValue ? 'baseWeight' : 'baseWeightGrammageAsValue',
    options.defaultSelectLabel
  );
};

// Apply the equivalent grammage option for the value of the basis weight option for EU users
export const getEuBaseWeightOptions = (
  brands: EuBrand[] | undefined,
  selectedBrand?: string,
  defaultSelectLabel?: string,
  useBaseWeightForValue?: boolean
): Option[] => {
  const filteredBrands = getFilteredEUBrandsByBrand(brands, selectedBrand);

  let weights = orderBy(
    flatten(
      filteredBrands.map((s) => {
        return get(s, 'weights');
      })
    ),
    ['tradeBasis', 'baseWeight'],
    ['desc', 'asc']
  );

  const options: Option[] = [
    {
      value: '',
      label: defaultSelectLabel || '',
    },
  ];

  weights = uniqBy(weights, 'baseWeight');

  if (weights && weights.length > 0) {
    const optionals = (weights as []).map((w) => {
      return {
        key: get(w, 'baseWeight'),
        value: useBaseWeightForValue
          ? get(w, 'displayValue')
          : get(w, 'grammageEquivalent'),
        label: get(w, 'displayValue'),
      };
    });

    return options.concat(optionals);
  }

  return options;
};

export const getBaseWeights = async (
  queryParams: GetBaseWeightsParams
): Promise<BaseWeight[]> => {
  const { data } = await Axios.get('/portal/resources/brands/search/weights', {
    params: queryParams,
  });

  return data;
};

export const getParentBrandOptions = (
  brands: Brand[] | undefined,
  options?: AvailabilityPlanningOptions
): Option[] => {
  const filteredByChemistry = getFilteredChemistry(
    brands,
    options?.selectedChemistry
  );
  const filteredByTexture = getFilteredTexture(
    filteredByChemistry,
    options?.selectedTexture
  );

  return getUniqueOptionsByKey(
    filteredByTexture,
    'parentBrand',
    options?.defaultSelectLabel
  );
};

export const getGradeOptions = (
  brands: Brand[] | undefined,
  options: AvailabilityPlanningOptions
): Option[] => {
  const filteredByParentBrands = getFilteredParentBrands(
    brands,
    options.selectedParentBrand
  );
  const filteredByTexture = getFilteredTexture(
    filteredByParentBrands,
    options.selectedTexture
  );

  return getUniqueOptionsByKey(
    orderBy(filteredByTexture, 'chemistry'),
    'chemistry',
    options.defaultSelectLabel
  );
};

export const getTextureOptionsForProductAvailability = (
  brands: Brand[] | undefined,
  options: AvailabilityPlanningOptions
): Option[] => {
  const filteredByParentBrands = getFilteredParentBrands(
    brands,
    options.selectedParentBrand
  );
  const filteredByChemistry = getFilteredChemistry(
    filteredByParentBrands,
    options.selectedChemistry
  );

  return getUniqueOptionsByKey(
    orderBy(filteredByChemistry, 'texture'),
    'texture',
    options.defaultSelectLabel
  );
};

export const getTextureOptions = (
  brands: Brand[] | undefined,
  selectedBrand?: string,
  selectedGrade?: string
): Option[] => {
  const filteredByBrand = selectedBrand
    ? filter(brands, { parentBrand: selectedBrand })
    : brands;

  const filteredByChemistry = selectedGrade
    ? filter(brands, { chemistry: selectedGrade })
    : filteredByBrand;
  return getUniqueOptionsByKey(
    orderBy(filteredByChemistry, 'texture'),
    'texture'
  );
};

/**
 * HTTP
 */
export interface QueryParams {
  customer: string;
  skuOnly: boolean;
  productType: 'S' | 'R';
  context: 'PL' | 'RL';
  qobFlag: 'X' | null | undefined;
  caliperInMils: 'X' | null | undefined;
  availabilityFlag: 'X' | null | undefined;
}

interface QueryParamsForSkus {
  soldTo: string;
  baseWeight: string;
  caliper: string;
  gradeText: string;
  lengthImpFrom: string;
  lengthImpTo: string;
  widthImpFrom: string;
  widthImpTo: string;
  tradeBasis: string;
  putUp: string;
}

export const getBrands = async (
  _: string,
  queryParams: Partial<QueryParams>
): Promise<Brand[] | EuBrand[]> => {
  const { data } = await Axios.get('/portal/resources/brands', {
    params: queryParams,
  });
  return data;
};

export const getEUBrands = async (
  _: string,
  customer: AvailabilityCustomer
): Promise<EuOrderBookingBrand[]> => {
  const allowableFields = omit(customer, 'shipTos');
  const { data } = await Axios.post(
    '/portal/resources/eu/availability/brands',
    allowableFields
  );
  return data;
};

export const getSkus = async (
  _: string,
  queryParams: QueryParamsForSkus
): Promise<Partial<Brand>[]> => {
  const { data } = await Axios.get(`/portal/resources/brands/search`, {
    params: queryParams,
  });
  return data;
};
