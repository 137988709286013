import React from 'react';
import { LanderContent } from '../../types/LanderContent.interface';
import styles from './LanderContent.module.scss';

interface ILanderContentProps {
  content: LanderContent | undefined;
  className?: string;
}

const LanderContentRender: React.FunctionComponent<ILanderContentProps> = ({
  content,
  className,
}) => {
  return (
    <div
      className={`${styles.lander} ${className}`}
      style={{
        backgroundImage: `url(${encodeURI(content?.image || '')})`,
      }}
    >
      <>
        {content && (content.header || content.body) && (
          <div className={styles.landerContentBlock}>
            <div
              className={styles.landerTitle}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: content.header }}
            />
            <div
              className={styles.landerBody}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: content.body }}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default LanderContentRender;
