import React, { FC, useState } from 'react';
import Criteria from './Criteria';
import { RollCalculatorResult } from './types/RollCalculatorResult.interface';
import Results from './Results';

const RollCalculator: FC = () => {
  const [results, setResults] = useState<RollCalculatorResult | null>(null);
  return (
    <div className="flex">
      <Criteria onSubmit={setResults} />
      <Results results={results} />
    </div>
  );
};

export default RollCalculator;
