import Axios from 'axios';
import LiveAgentChatConfig from './types/LiveAgentChatConfig.interface';

export const getLiveAgentChatConfig = async (): Promise<
  LiveAgentChatConfig
> => {
  const { data } = await Axios.get('/portal/resources/live-agent-config');

  return data;
};
