import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import { capitalize } from 'lodash';
import React from 'react';
import { Button } from '../../components/Button/Button';
import Date from '../../components/Date';
import LabelValue from '../../components/LabelValue/LabelValue';
import Table from '../../components/Table/Table';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import stripZeros from '../../services/Util/stripZeros.util';
import Complaints from '../../types/Complaints.interface';
import './ClaimsReporting.scss';

interface IClaimsReportingTableProps {
  results: Complaints[] | undefined;
  onAttachmentLinkClick: (record: Complaints, bypassModal?: boolean) => void;
  onDescriptionLinkClick: (record: Complaints) => void;
  onDocumentNoteLinkClick: (record: Complaints) => void;
}
const ClaimsReportingTable: React.FunctionComponent<IClaimsReportingTableProps> = ({
  onAttachmentLinkClick,
  onDescriptionLinkClick,
  onDocumentNoteLinkClick,
  results,
}) => {
  const { format: formatNumber } = useNumberFormatter();

  const getRowKey = (record: Complaints): string => {
    return (
      record.complaintNumber +
      record.creditNoteNumber +
      record.referenceCount +
      record.purchaseOrderNumber +
      record.salesDocumentNumber +
      record.itemNetValue +
      record.status +
      record.debitNote +
      record.customerName + 
      record.claimType
    );
  };

  const columns: ColumnsType<Complaints> = [
    {
      title: <Trans>Claim Received</Trans>,
      key: 'claimReceived',
      width: '131px',
      render: (value, record) => <Date date={record.createDate || 0} />,
      sorter: (a: Complaints, b: Complaints) => {
        let sortValue = false;
        if (a.createDate && b.createDate) {
          if (a.createDate !== b.createDate) {
            sortValue = a.createDate > b.createDate;
          } else {
            sortValue = a.complaintNumber > b.complaintNumber;
          }
        }
        return sortValue ? 1 : -1;
      },
    },
    {
      title: <Trans>Claim Type</Trans>,
      key: 'claimType',
      width: '126px',
      render: (value, record) => <>{record.claimType}</>,
    },
    {
      title: <Trans>Claim Issued By</Trans>,
      key: 'claimIssuedBy',
      width: '95px',
      render: (value, record) => <>{record.customerName}</>,
    },
    {
      title: <Trans>Claim Reference</Trans>,
      key: 'claimReference',
      width: '185px',
      render: (value, record) => <>{record.referenceCount}</>,
    },
    {
      title: <Trans>Order</Trans>,
      key: 'order',
      width: '220px',
      render: (value, record) => (
        <>
          <LabelValue
            noBold
            inline
            label={t`My Order`}
            value={stripZeros(record.purchaseOrderNumber)}
          />
          <LabelValue
            noBold
            inline
            label={t`Sappi Order #`}
            value={stripZeros(record.salesDocumentNumber)}
          />
        </>
      ),
    },
    {
      title: <Trans>Sappi Claim Number</Trans>,
      key: 'sappiClaimNumber',
      width: '132px',
      render: (value, record) => <>{record.complaintNumber}</>,
    },
    {
      title: <Trans>Description</Trans>,
      key: 'description',
      width: '131px',
      render: (value, record) => (
        <div>
          <div>{record.complaintText}</div>{' '}
          {record.complaintText && (
            <Button theme="link" onClick={() => onDescriptionLinkClick(record)}>
              <Trans>See All</Trans>
            </Button>
          )}
        </div>
      ),
    },
    {
      title: <Trans>Claim Status</Trans>,
      key: 'claimStatus',
      width: '101px',
      render: (value, record) => (
        <>
          {record.status === 'Processing' && (
            <div className="text-blue-cobaltVariation">{record.status}</div>
          )}
          {record.status !== 'Processing' && <div>{record.status}</div>}
        </>
      ),
      sorter: (a: Complaints, b: Complaints) => {
        let sortValue = false;
        if (a.status && b.status) {
          sortValue = a.status > b.status;
        }
        return sortValue ? 1 : -1;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: <Trans>Credit/Debit Note</Trans>,
      key: 'creditDebitNote',
      width: '130px',
      render: (value, record) => (
        <>
          {record.complaintDocuments && record.complaintDocuments.length > 1 && (
            <Button theme="link" onClick={() => onDocumentNoteLinkClick(record)}>
              <Trans>See All Notes</Trans>
            </Button>
          )}
          {record.complaintDocuments && record.complaintDocuments.length === 1 && (
            <>
            <a
              className="app-link"
              href={`../resources/complaints/creditNote/${record.complaintDocuments[0].documentID}`}
            >
              {stripZeros(record.complaintDocuments[0].documentNumber)}
            </a>
          </>
          )}
        </>
      ),
    },
    {
      title: <Trans>Amount</Trans>,
      key: 'amount',
      width: '130px',
      render: (value, record) => (
        <>
          {formatNumber(record.itemNetValue)} {record.documentCurrency}
        </>
      ),
    },
    {
      title: <Trans>Attachments</Trans>,
      key: 'attachments',
      width: '144px',
      render: (value, record) => (
        <>
          {(record.status === 'Processing' ||
            record.numberOfAttachments > 0) && (
            <Button
              theme="link"
              onClick={() =>
                onAttachmentLinkClick(record, record.numberOfAttachments === 0)
              }
            >
              {record.numberOfAttachments === 0 ? (
                <Trans>Add Attachment</Trans>
              ) : (
                <>
                  {record.numberOfAttachments} <Trans>Attachments</Trans>
                </>
              )}
            </Button>
          )}
          {record.status !== 'Processing' && record.numberOfAttachments === 0 && (
            <>
              {record.numberOfAttachments} <Trans>Attachments</Trans>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="relative">
      <Table
        dataSource={results}
        columns={columns}
        rowKey={(record) => getRowKey(record)}
        pagination={false}
        scroll={{ x: 1664, y: 720 }}
        className="sappi-ant-override-claims-reporting-results"
        locale={{
          emptyText: (
            <div className="text-red-red">
              <Trans>No claims returned</Trans>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default ClaimsReportingTable;
