import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import usePageHeaderItems from '../../hooks/usePageHeaderItems';
import PageHeader from '../PageHeader/PageHeader';
import useNewsItem from './useNewsItem';

const NewsPageHeader: FC = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const { newsItem, isFetching } = useNewsItem(newsId);
  const { commonPageHeaderItemSet } = usePageHeaderItems();
  return (
    <PageHeader
      title={
        isFetching ? (
          <Trans>News</Trans>
        ) : (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: newsItem?.title || '' }} />
        )
      }
      items={commonPageHeaderItemSet}
    />
  );
};

export default NewsPageHeader;
