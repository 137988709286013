import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { Button } from '../../components/Button/Button';
import { DeviationData } from './types/DeviationData.interface';
import { TrackAndTraceData } from './types/TrackAndTraceData.interface';
import useDownloadExcel from './useDownloadExcel';

interface AboveTableHeaderProps {
  headerText: string;
  deviationExcelData?: DeviationData[];
  arrivalExcelData?: TrackAndTraceData[];
}

const AboveTableHeader: FC<AboveTableHeaderProps> = ({
  headerText,
  deviationExcelData,
  arrivalExcelData,
}) => {
  const { downloadExcel } = useDownloadExcel({
    deviation: deviationExcelData,
    arrival: arrivalExcelData,
  });
  return (
    <div className="flex justify-between">
      <div className="text-xl">{headerText}</div>
      <Button theme="link" onClick={downloadExcel}>
        <i className="far fa-file-excel mr-1" />
        <Trans>XLS</Trans>
      </Button>
    </div>
  );
};

export default AboveTableHeader;
