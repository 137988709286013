import { Trans } from '@lingui/macro';
import { Collapse } from 'antd';
import * as React from 'react';
import ExpandIcon from '../../../components/ExpandIcon';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { FinancialPeriod } from '../../../types/FinancialPeriod.interface';
import AccountActivityTable from './AccountActivityTable';

interface IFinancialReportingDueSoonProps {
  customerNumber: string;
  totalDue: number;
  lessThan1WeekPeriod: FinancialPeriod;
  moreThan1WeekPeriod: FinancialPeriod;
}
const { Panel } = Collapse;
const FinancialReportingDueSoon: React.FunctionComponent<IFinancialReportingDueSoonProps> = ({
  customerNumber,
  totalDue,
  lessThan1WeekPeriod,
  moreThan1WeekPeriod,
}) => {
  const { format } = useNumberFormatter();
  return (
    <>
      <h4 className="text-xl font-bold mb-2">
        <Trans>Due Soon</Trans>
      </h4>
      <Collapse
        className="sappi-collapse-custom-collapse"
        accordion
        bordered={false}
        expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
        destroyInactivePanel
      >
        <Panel
          header={<Trans>Due in less than 1 week</Trans>}
          key="1"
          extra={
            <div>
              {format(lessThan1WeekPeriod.amount)}{' '}
              {lessThan1WeekPeriod.currency}
            </div>
          }
          className="sappi-collapse-custom-summary-collapse"
        >
          <AccountActivityTable
            period={lessThan1WeekPeriod}
            customerNumber={customerNumber}
          />
        </Panel>
        <Panel
          header={<Trans>Due in more than 1 week</Trans>}
          key="2"
          extra={
            <div>
              {format(moreThan1WeekPeriod.amount)}{' '}
              {moreThan1WeekPeriod.currency}
            </div>
          }
          className="sappi-collapse-custom-summary-collapse"
        >
          <AccountActivityTable
            period={moreThan1WeekPeriod}
            customerNumber={customerNumber}
          />
        </Panel>
      </Collapse>
      <div className="text-right mt-7 text-lg mr-5">
        <span>
          <Trans>TOTAL Due</Trans>:&nbsp;
        </span>
        <strong>
          {format(totalDue)} {lessThan1WeekPeriod.currency}
        </strong>
      </div>
    </>
  );
};

export default FinancialReportingDueSoon;
