import * as React from 'react';

interface IExpandIconProps {
  isActive?: boolean;
  className?: string;
  iconClassName?: string;
}

const ExpandIcon: React.FunctionComponent<IExpandIconProps> = ({
  isActive,
  className,
  iconClassName = '',
}) => {
  return (
    <span className={className}>
      <i
        className={`${iconClassName} fas text-2xl ${
          isActive ? 'fa-minus-circle' : 'fa-plus-circle'
        }`}
      />
    </span>
  );
};

export default ExpandIcon;
