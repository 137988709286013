import { t } from '@lingui/macro';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { getCaliperOptions } from '../../../services/Brand';
import Brand from '../../../types/Brand.interface';
import Option from '../../../types/Option.type';
import useCustomerBrands from './useCustomerBrands';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';

const useCaliperOptions = (
  customer: string,
  brand?: string,
  grammage?: string,
  availFlag?: string
): {
  caliperOptions: Option[];
  isFetching: boolean;
} => {
  const { brands, isFetching } = useCustomerBrands(customer, availFlag);
  const { format } = useNumberFormatter();
  const { isMetric } = useMeasurementSystem();

  const formatCaliperOption = (option: Option, idx: number) => {
    if (idx === 0) {
      return option;
    }
    option.label = format(option.label as string);
    return option;
  };

  return {
    caliperOptions: getCaliperOptions(brands as Brand[], {
      selectedBrand: brand,
      selectedGrammage: grammage,
      defaultSelectLabel: t`Select`,
      isMetric,
    })
      .filter((o) => o.value !== 0)
      .map(formatCaliperOption),
    isFetching,
  };
};

export default useCaliperOptions;
