import { t } from '@lingui/macro';
import { filter, some, capitalize } from 'lodash';
import Authority, { AuthorityRole } from '../../types/Authority.interface';
import { SappiMenuItem } from './Types/SappiMenuItem.interface';
import { getAllBreadCrumbs } from './BreadCrumbConfigs.util';
import { getAllLinks } from './TopMenuConfigs.util';

export const getUrlParamFromHistory = (
  pathname: string,
  search: string
): string => {
  let path = '';
  if (pathname.indexOf('calculators') > 0) {
    path = `${pathname}${search}`;
  }
  return path || pathname;
};

export const getBreadCrumbValuesFromMenu = (
  menuItems: SappiMenuItem[],
  urlParam: string
): string[] => {
  let breadCrumbs: string[] = [];

  menuItems.forEach((menuItem) => {
    const foundMenuItem = filter(
      menuItem.children,
      (child: SappiMenuItem) => child.url === `${urlParam}`
    );

    if (foundMenuItem && foundMenuItem[0]) {
      breadCrumbs = [
        menuItem.name,
        foundMenuItem[0].breadCrumb
          ? foundMenuItem[0].breadCrumb
          : foundMenuItem[0].name,
      ];
    }
  });

  if (breadCrumbs.length < 1) {
    breadCrumbs = [capitalize(urlParam.toLowerCase())];
  }

  return breadCrumbs;
};

export const getBreadCrumbValuesFromUserDefinedArray = (
  urlParam: string
): string[] => {
  let breadCrumbs: string[] = [];

  getAllBreadCrumbs().forEach((breadCrumb) => {
    if (breadCrumbs.length < 1 && urlParam.indexOf(breadCrumb.url) >= 0) {
      breadCrumbs = breadCrumb.siteLocation
        ? [breadCrumb.siteLocation, breadCrumb.breadcrumb]
        : [breadCrumb.breadcrumb];
    }
  });

  return breadCrumbs;
};

// Two steps to build string array for BreadCrumbs:
// Step 1: Parse exact url from menu (TopMenuConfigs.util.ts)
// Step 2: Parse specific url from used defined bread crumb array (BreadCrumbConfigs.util.ts, copied from applications.ts in Angular)
export const buildBreadCrumbValues = (urlParam: string): string[] => {
  let breadCrumbValues = [];
  const breadCrumbsPerMenu = getBreadCrumbValuesFromMenu(
    getAllLinks(),
    urlParam
  );
  breadCrumbValues =
    breadCrumbsPerMenu.length > 1
      ? breadCrumbsPerMenu
      : getBreadCrumbValuesFromUserDefinedArray(urlParam);

  return breadCrumbValues;
};

export const hasAuthorizedRole = (
  roles?: Authority[],
  allowedRoles?: AuthorityRole[]
): boolean => {
  if (!roles) {
    return false;
  }
  if (!allowedRoles) {
    return true;
  }

  let allowed = false;
  allowedRoles.forEach((allowedRole) => {
    const hasAllowedRole = () =>
      some(roles, (role) => role.authority === allowedRole);

    if (!allowed) {
      allowed = hasAllowedRole();
    }
  });

  return allowed;
};

export const hasAllAuthorizedRoles = (
  roles?: Authority[],
  allowedRoles?: AuthorityRole[]
): boolean => {
  if (!roles) {
    return false;
  }
  if (!allowedRoles) {
    return true;
  }

  let allowed = true;
  allowedRoles.forEach((allowedRole) => {
    const hasAllowedRole = () =>
      some(roles, (role) => role.authority === allowedRole);

    if (allowed) {
      allowed = hasAllowedRole();
    }
  });

  return allowed;
};

export const hasAuthorizedRolesArray = (
  roles?: Authority[],
  allowedRolesArray?: AuthorityRole[][]
): boolean => {
  if (!roles) {
    return false;
  }
  if (!allowedRolesArray) {
    return true;
  }

  let allowed = false;
  allowedRolesArray.forEach((allowedRoles) => {
    if (!allowed) {
      allowed = hasAuthorizedRole(roles, allowedRoles);
    }
  });

  return allowed;
};

export const getSappiMenuItems = (
  menuItems: SappiMenuItem[] | SappiMenuItem,
  roles?: Authority[]
): SappiMenuItem[] => {
  const linksList = Array.isArray(menuItems) ? menuItems : menuItems.children;

  const authorizedLinks = filter(linksList, (link) => {
    let authorized = true;

    // We need to do an inverse role check to prevent certain links from loading if the user has a specific role.
    if (
      authorized &&
      link.excludedRoles &&
      link.excludedRoles.length > 0 &&
      hasAuthorizedRole(roles, link.excludedRoles)
    ) {
      authorized = false;
    }

    if (
      authorized &&
      link.rolesArray &&
      link.rolesArray.length > 0 &&
      !hasAuthorizedRolesArray(roles, link.rolesArray)
    ) {
      authorized = false;
    }

    if (
      authorized &&
      link.roles &&
      link.roles.length > 0 &&
      !hasAllAuthorizedRoles(roles, link.roles)
    ) {
      authorized = false;
    }

    return authorized;
  });

  return authorizedLinks;
};
