import { Trans } from '@lingui/macro';
import moment, { Moment } from 'moment';
import { UseFormMethods } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import DatePicker from '../../../components/DatePicker/DatePicker';
import Select from '../../../components/Select/Select';
import './OrderRelatedDates.scss';
import {
  getMoment,
  getMonthsOption,
  getOrderDates,
} from './SearchCriteria.util';
import useTrackOrdersStore from '../useTrackOrders.store';

interface IOrderRelatedDates {
  onMinOrderDateChange: (minOrderDate: string) => void;
  onMaxOrderDateChange: (maxOrderDate: string) => void;
  onMinLastRevisionDateChange: (minLastRevisionDate: string) => void;
  onMaxLastRevisionDateChange: (maxLastRevisionDate: string) => void;
  onMinDeliveryDateChange: (minDeliveryDate: string) => void;
  onMaxDeliveryDateChange: (maxDeliveryDate: string) => void;
  onMinArrivalDateChange: (minArrivalDate: string) => void;
  onMaxArrivalDateChange: (maxArrivalDate: string) => void;
  onMonthChosenChange: (chosenMonth: number) => void;
  resetOrderRelatedDates: boolean;
  register: UseFormMethods['register'];
}

const OrderRelatedDates: React.FunctionComponent<IOrderRelatedDates> = ({
  onMinOrderDateChange,
  onMaxOrderDateChange,
  onMinLastRevisionDateChange,
  onMaxLastRevisionDateChange,
  onMinDeliveryDateChange,
  onMaxDeliveryDateChange,
  onMinArrivalDateChange,
  onMaxArrivalDateChange,
  onMonthChosenChange,
  resetOrderRelatedDates,
  register,
}) => {
  const { storedQuery: storedFilters } = useTrackOrdersStore();
  const [minOrderDate, setMinOrderDate] = useState<Moment | null>();
  const [minOrderDateStr, setMinOrderDateStr] = useState<string>();
  const [maxOrderDate, setMaxOrderDate] = useState<Moment | null>();
  const [
    minLastRevisionDate,
    setMinLastRevisionDate,
  ] = useState<Moment | null>();

  const [minLastRevisionDateStr, setMinLastRevisionDateStr] = useState<
    string
  >();
  const [
    maxLastRevisionDate,
    setMaxLastRevisionDate,
  ] = useState<Moment | null>();
  const [minDeliveryDate, setMinDeliveryDate] = useState<Moment | null>();
  const [minDeliveryDateStr, setMinDeliveryDateStr] = useState<string>();
  const [maxDeliveryDate, setMaxDeliveryDate] = useState<Moment | null>();
  const [minArrivalDate, setMinArrivalDate] = useState<Moment | null>();
  const [minArrivalDateStr, setMinArrivalDateStr] = useState<string>();
  const [maxArrivalDate, setMaxArrivalDate] = useState<Moment | null>();
  const [monthChosen, setMonthChosen] = useState<number>(-1);
  const [applyStoredMonthChosen, setApplyStoredMonthChosen] = useState<boolean>(
    true
  );
  const [orderDateError, setOrderDateError] = useState<boolean>(false);
  const [lastRevisionError, setLastRevisionError] = useState<boolean>(false);
  const [deliveryDateError, setDeliveryDateError] = useState<boolean>(false);
  const [arrivalDateError, setArrivalDateError] = useState<boolean>(false);

  useEffect(() => {
    setMinOrderDate(null);
    setMaxOrderDate(null);
    setMinLastRevisionDate(null);
    setMaxLastRevisionDate(null);
    setMinDeliveryDate(null);
    setMaxDeliveryDate(null);
    setMinArrivalDate(null);
    setMaxArrivalDate(null);
    setOrderDateError(false);
    setLastRevisionError(false);
    setDeliveryDateError(false);
    setArrivalDateError(false);
    setMonthChosen(-1);
    setApplyStoredMonthChosen(false);
  }, [resetOrderRelatedDates]);

  useEffect(() => {
    setMonthChosen(+(storedFilters?.monthsFromToday || 0));
    setMinLastRevisionDate(getMoment(storedFilters?.minLastRevisionDate));
    setMaxLastRevisionDate(getMoment(storedFilters?.maxLastRevisionDate));
    setMinDeliveryDate(getMoment(storedFilters?.minDeliveryDate));
    setMaxDeliveryDate(getMoment(storedFilters?.maxDeliveryDate));
    setMinArrivalDate(getMoment(storedFilters?.minArrivalDate));
    setMaxArrivalDate(getMoment(storedFilters?.maxArrivalDate));
    onMinDeliveryDateChange(
      applyStoredMonthChosen
        ? (storedFilters?.minDeliveryDate as string) || ''
        : ''
    );
    onMaxDeliveryDateChange(
      applyStoredMonthChosen
        ? (storedFilters?.maxDeliveryDate as string) || ''
        : ''
    );
    onMinLastRevisionDateChange(
      applyStoredMonthChosen
        ? (storedFilters?.minLastRevisionDate as string) || ''
        : ''
    );
    onMaxLastRevisionDateChange(
      applyStoredMonthChosen
        ? (storedFilters?.maxLastRevisionDate as string) || ''
        : ''
    );
    onMinArrivalDateChange(
      applyStoredMonthChosen
        ? (storedFilters?.minArrivalDate as string) || ''
        : ''
    );
    onMaxArrivalDateChange(
      applyStoredMonthChosen
        ? (storedFilters?.maxArrivalDate as string) || ''
        : ''
    );
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (monthChosen > 0) {
      const { maxDateStr, minDateStr, today, previousDay } = getOrderDates(
        monthChosen
      );
      setMinOrderDate(previousDay);
      setMaxOrderDate(today);
      onMinOrderDateChange(minDateStr);
      onMaxOrderDateChange(maxDateStr);
      setOrderDateError(false);
    }

    if (monthChosen < 1) {
      setMinOrderDate(
        applyStoredMonthChosen ? getMoment(storedFilters?.minOrderDate) : null
      );
      setMaxOrderDate(
        applyStoredMonthChosen ? getMoment(storedFilters?.maxOrderDate) : null
      );
      onMinOrderDateChange(
        applyStoredMonthChosen ? storedFilters?.minOrderDate || '' : ''
      );
      onMaxOrderDateChange(
        applyStoredMonthChosen ? storedFilters?.maxOrderDate || '' : ''
      );
    }
    onMonthChosenChange(monthChosen);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [monthChosen]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const errorMassage = (message: string): React.ReactElement => {
    return (
      <div className="flex">
        <div className="w-1/3 max-width-200" />
        <div className="pb-3 text-red-red">
          <Trans id="search.error.dateAfter">
            Date needs to be after the first
          </Trans>{' '}
          {message}
        </div>
      </div>
    );
  };

  const dummyMassage = (): React.ReactElement => {
    return (
      <div className="flex pb-3">
        <div className="w-1/3" />
        <div className="" />
      </div>
    );
  };

  const exclamationIcon = (): React.ReactElement => {
    return (
      <span className="pl-2 text-3xl red">
        <i className="fa fa-exclamation-circle" />
      </span>
    );
  };

  const orderDateContent = (): React.ReactElement => {
    return (
      <>
        <div className="flex pb-3">
          <div
            className="w-1/3 align-right pr-3 pt-1 text-lg"
            style={{
              color: monthChosen > 0 ? '#ccc' : 'black',
              maxWidth: '200px',
            }}
          >
            <Trans id="order.orderPlaced">Order Date</Trans>
          </div>
          <div className="flex w-1/2">
            <DatePicker
              onChange={(momentDate, dateStr) => {
                setMinOrderDate(momentDate);
                setMinOrderDateStr(momentDate ? dateStr : '');
                setOrderDateError(
                  maxOrderDate && momentDate
                    ? momentDate.isAfter(maxOrderDate)
                    : false
                );
                onMinOrderDateChange(momentDate ? dateStr : '');
              }}
              value={minOrderDate}
              disabled={monthChosen > 0}
            />
            <span className="px-3 pt-1 text-lg">to</span>
            <DatePicker
              onChange={(momentDate, dateStr) => {
                setMaxOrderDate(momentDate);
                setOrderDateError(
                  minOrderDate && momentDate
                    ? momentDate.isBefore(minOrderDate)
                    : false
                );
                onMaxOrderDateChange(momentDate ? dateStr : '');
              }}
              value={maxOrderDate}
              disabled={monthChosen > 0}
              hasError={orderDateError}
              toolTipErrorMsg={
                <div>
                  <Trans>Date must be after</Trans> {minOrderDateStr}
                </div>
              }
            />
            {orderDateError && exclamationIcon()}
          </div>
        </div>
        {!orderDateError && dummyMassage()}
        {orderDateError && errorMassage('Order Date')}
      </>
    );
  };

  const minLastRevisionDateContent = (): React.ReactElement => {
    return (
      <>
        <div className="flex pb-3">
          <div className="w-1/3 align-right pr-3 pt-1 text-lg max-width-200">
            <Trans id="Last Date of Revision">Last Date of Revision</Trans>
          </div>
          <div className="flex w-1/2">
            <DatePicker
              onChange={(momentDate, dateStr) => {
                setMinLastRevisionDate(momentDate);
                setMinLastRevisionDateStr(momentDate ? dateStr : '');
                setLastRevisionError(
                  maxLastRevisionDate && momentDate
                    ? momentDate.isAfter(maxLastRevisionDate)
                    : false
                );
                onMinLastRevisionDateChange(momentDate ? dateStr : '');
              }}
              value={minLastRevisionDate}
            />
            <span className="px-3 pt-1 text-lg">to</span>
            <DatePicker
              onChange={(momentDate, dateStr) => {
                setMaxLastRevisionDate(momentDate);
                setLastRevisionError(
                  minLastRevisionDate && momentDate
                    ? momentDate.isBefore(minLastRevisionDate)
                    : false
                );
                onMaxLastRevisionDateChange(momentDate ? dateStr : '');
              }}
              value={maxLastRevisionDate}
              hasError={lastRevisionError}
              toolTipErrorMsg={
                <div>
                  <Trans>Date must be after</Trans> {minLastRevisionDateStr}
                </div>
              }
            />
            {lastRevisionError && exclamationIcon()}
          </div>
        </div>
        {!lastRevisionError && dummyMassage()}
        {lastRevisionError && errorMassage('Last Date of Revision')}
      </>
    );
  };

  const deliveryDateContent = (): React.ReactElement => {
    return (
      <>
        <div className="flex pb-3">
          <div className="w-1/3 align-right pr-3 pt-1 text-lg max-width-200">
            <Trans id="search.deliveryDate">Delivery Date</Trans>
          </div>
          <div className="flex w-1/2">
            <DatePicker
              onChange={(momentDate, dateStr) => {
                setMinDeliveryDate(momentDate);
                setMinDeliveryDateStr(momentDate ? dateStr : '');
                setDeliveryDateError(
                  maxDeliveryDate && momentDate
                    ? momentDate.isAfter(maxDeliveryDate)
                    : false
                );
                onMinDeliveryDateChange(momentDate ? dateStr : '');
              }}
              value={minDeliveryDate}
            />
            <span className="px-3 pt-1 text-lg">to</span>
            <DatePicker
              onChange={(momentDate, dateStr) => {
                setMaxDeliveryDate(momentDate);
                setDeliveryDateError(
                  minDeliveryDate && momentDate
                    ? momentDate.isBefore(minDeliveryDate)
                    : false
                );
                onMaxDeliveryDateChange(momentDate ? dateStr : '');
              }}
              value={maxDeliveryDate}
              hasError={deliveryDateError}
              toolTipErrorMsg={
                <div>
                  <Trans>Date must be after</Trans> {minDeliveryDateStr}
                </div>
              }
            />
            {deliveryDateError && exclamationIcon()}
          </div>
        </div>
        {!deliveryDateError && dummyMassage()}
        {deliveryDateError && errorMassage('Delivery Date')}
      </>
    );
  };

  const arrivalDateContent = (): React.ReactElement => {
    return (
      <>
        <div className="flex pb-3">
          <div className="w-1/3 align-right pr-3 pt-1 text-lg max-width-200">
            <Trans id="search.arrivalDate">Expected Arrival Date</Trans>
          </div>
          <div className="flex w-1/2">
            <DatePicker
              onChange={(momentDate, dateStr) => {
                setMinArrivalDate(momentDate);
                setMinArrivalDateStr(momentDate ? dateStr : '');
                setArrivalDateError(
                  minArrivalDate && momentDate
                    ? momentDate.isAfter(maxArrivalDate)
                    : false
                );
                onMinArrivalDateChange(momentDate ? dateStr : '');
              }}
              value={minArrivalDate}
            />
            <span className="px-3 pt-1 text-lg">to</span>
            <DatePicker
              onChange={(momentDate, dateStr) => {
                setMaxArrivalDate(momentDate);
                setArrivalDateError(
                  minArrivalDate && momentDate
                    ? momentDate.isBefore(minArrivalDate)
                    : false
                );
                onMaxArrivalDateChange(momentDate ? dateStr : '');
              }}
              value={maxArrivalDate}
              hasError={arrivalDateError}
              toolTipErrorMsg={
                <div>
                  <Trans>Date must be after</Trans> {minArrivalDateStr}
                </div>
              }
            />
            {arrivalDateError && exclamationIcon()}
          </div>
        </div>
        {!arrivalDateError && dummyMassage()}
        {arrivalDateError && errorMassage('Expected Arrival Date')}
      </>
    );
  };

  return (
    <>
      <div className="text-xl font-extrabold text-gray-dark200 pt-2.5 mb-3">
        <Trans id="search.orderDateHeader">Order Related Dates</Trans>
      </div>
      {orderDateContent()}
      {minLastRevisionDateContent()}
      {deliveryDateContent()}
      {arrivalDateContent()}
      <div className="flex pb-3">
        <div className="w-1/3 align-right pr-3 pt-1 text-lg max-width-200">
          <Trans id="search.or">or</Trans>
        </div>
      </div>
      <div className="flex pb-3">
        <div className="w-1/3 align-right pr-3 pt-1 text-lg max-width-87" />
        <div className="flex text-lg">
          <div className="pt-2 pr-2">View all orders placed</div>
          <div className="pt-2 -mt-1">
            <Select
              id="monthsFromToday"
              name="monthsFromToday"
              className="min-width-100"
              options={getMonthsOption()}
              ref={register()}
              onChange={(e) => {
                setMonthChosen(+e.target.value);
                setApplyStoredMonthChosen(false);
              }}
            />
          </div>
          <div className="pt-2 pl-2">months from today</div>
        </div>
      </div>
    </>
  );
};

export default OrderRelatedDates;
