import { t } from '@lingui/macro';
import { useMemo } from 'react';
import useBasket from '../../../../hooks/useBasket';
import generateOptions from '../../../../services/Util/generateOptions.util';
import Option from '../../../../types/Option.type';
import { ALL_CUSTOMERS, getCustomersFromBasketData } from '../NaBasket.util';

const useNaBasketCustomerOptions = (): {
  isFetching: boolean;
  naBasketCustomerOptions: Option[];
} => {
  const { data: basketData, isFetching } = useBasket();

  const naBasketCustomerOptions = useMemo(
    () =>
      generateOptions(
        getCustomersFromBasketData(basketData),
        (customer) => ({ label: customer.name, value: customer.number }),
        {
          label: t`ALL`,
          value: ALL_CUSTOMERS,
        }
      ),
    [basketData]
  );

  return { isFetching, naBasketCustomerOptions };
};

export default useNaBasketCustomerOptions;
