import { Trans } from '@lingui/macro';
import React, { Fragment } from 'react';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import AddToBasket from './AddToBasket';
import SamplesProductTypeEnum from '../../../../types/Samples/SamplesProductType.enum';
import SamplesProductImage from '../../SamplesProductImage';
import SamplesProductType from '../../SamplesProductType';
import SamplesProductAvailability from './SamplesProductAvailability';
import SamplesProductItemDetail from './SamplesProductItemDetail';
import styles from './SearchResults.module.scss';
import DeconstructedTable, {
  SparseColumnType,
} from '../../../../components/DeconstructedTable/DeconstructedTable';
import DeconstructedTableHead from '../../../../components/DeconstructedTable/DeconstructedTableHead';
import DeconstructedTableHeadRow from '../../../../components/DeconstructedTable/DeconstructedTableHeadRow';
import DeconstructedTableHeadCell from '../../../../components/DeconstructedTable/DeconstructedTableHeadCell';
import DeconstructedTableBody from '../../../../components/DeconstructedTable/DeconstructedTableBody';
import DeconstructedTableBodyRow from '../../../../components/DeconstructedTable/DeconstructedTableBodyRow';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';

interface ISearchResultsProps {
  products: SamplesProduct[];
}

const SearchResults: React.FunctionComponent<ISearchResultsProps> = ({
  products,
}) => {
  const columns: SparseColumnType<SamplesProduct>[] = [
    {
      title: <Trans>Item</Trans>,
      key: 'item',
      render: (record) => <SamplesProductImage product={record} />,
    },
    {
      title: <Trans>Product</Trans>,
      key: 'product',
      render: (record) => record.brandFamilyDescription,
    },
    {
      title: <Trans>Type</Trans>,
      key: 'type',
      render: (record) => (
        <SamplesProductType type={record.type as SamplesProductTypeEnum} />
      ),
    },
    {
      title: <Trans>Item Detail</Trans>,
      key: 'itemDetail',
      render: (record) => <SamplesProductItemDetail product={record} />,
    },
    {
      title: <Trans>Description</Trans>,
      key: 'description',
      render: (record) => {
        const { materialDescription, materialNumber, boxDetail } = record;

        return (
          <>
            {materialDescription && <div>{materialDescription}</div>}
            {materialNumber && <div>{materialNumber}</div>}
            {boxDetail && <div>{boxDetail}</div>}
          </>
        );
      },
    },
    {
      title: <Trans>Availability</Trans>,
      key: 'availability',
      render: (record) => {
        return <SamplesProductAvailability product={record} />;
      },
    },
  ];

  return (
    <DeconstructedTable
      tableElementProps={{ className: styles.resultsTable }}
      noStripes
    >
      <DeconstructedTableHead>
        <DeconstructedTableHeadRow>
          {columns.map((column, colIndex) => (
            <DeconstructedTableHeadCell
              className={colIndex === 0 ? styles.firstColCell : ''}
              key={column.key}
            >
              {column.title}
            </DeconstructedTableHeadCell>
          ))}
        </DeconstructedTableHeadRow>
      </DeconstructedTableHead>
      <DeconstructedTableBody>
        {products.map((product, prodIndex) => {
          const prodKey = `${product.id}_${product.brand}_${product.grammage}_${product.size}_${product.materialNumber}_${product.productMaterialNumber}_${product.type}`;
          return (
            /* eslint-disable react/no-array-index-key */
            <Fragment key={`frag_${prodKey}_${prodIndex}`}>
              {/* eslint-enable react/no-array-index-key */}
              <DeconstructedTableBodyRow
                className={prodIndex % 2 !== 0 ? styles.rowGroupEven : ''}
              >
                {columns.map((column, colIndex) => (
                  <DeconstructedTableBodyCell
                    className={`bkgd-element ${
                      colIndex === 0 ? styles.firstColCell : ''
                    }`}
                    key={column.key}
                  >
                    {column.render(product)}
                  </DeconstructedTableBodyCell>
                ))}
              </DeconstructedTableBodyRow>
              <DeconstructedTableBodyRow
                className={prodIndex % 2 !== 0 ? styles.rowGroupEven : ''}
              >
                <DeconstructedTableBodyCell
                  className={styles.basketCell}
                  colSpan={columns.length}
                >
                  <div className="border-b-8 border-white-white bkgd-element">
                    <div
                      className={`flex justify-end border-l border-r border-b border-gray-light400 ${styles.basketCellInner}`}
                    >
                      <AddToBasket product={product} />
                    </div>
                  </div>
                </DeconstructedTableBodyCell>
              </DeconstructedTableBodyRow>
            </Fragment>
          );
        })}
        {products.length < 1 && (
          <DeconstructedTableBodyRow>
            <DeconstructedTableBodyCell
              colSpan={columns.length}
              className={`p-3 ${styles.noResultsCell}`}
            >
              <span className="text-red-red">
                <Trans>No results returned, please try again.</Trans>
              </span>
            </DeconstructedTableBodyCell>
          </DeconstructedTableBodyRow>
        )}
      </DeconstructedTableBody>
    </DeconstructedTable>
  );
};

export default SearchResults;
