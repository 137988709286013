import { Space } from 'antd';
import { delay } from 'lodash';
import React, { useState } from 'react';
import { Button } from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import ContainerType from '../types/ContainerType.enum';

interface IContainerTypeTabsProps {
  onContainerTypeChange: (type: ContainerType) => void;
  className?: string;
}

const ContainerTypeTabs: React.FunctionComponent<IContainerTypeTabsProps> = ({
  onContainerTypeChange,
  className,
}) => {
  const [selectedType, setSelectedType] = useState(ContainerType.TYPE_20);
  const [loadingUI, setLoadingUI] = useState(false);

  const onContainerTypeClick = (type: ContainerType) => {
    setSelectedType(type);
    setLoadingUI(true);
    // because the UI takes a long time to load all the rows, we want to show the user action immediately
    delay(() => {
      onContainerTypeChange(type);
      setLoadingUI(false);
    }, 0);
  };

  return (
    <div className={className}>
      <Space className="text-lg relative">
        <LoadingSpinner
          className={`inline-block absolute -left-7 top-0.5 ${
            loadingUI ? 'visible' : 'invisible'
          }`}
        />
        <Button
          theme="link"
          onClick={() => onContainerTypeClick(ContainerType.TYPE_20)}
          className={`${
            selectedType === ContainerType.TYPE_20 && 'font-bold'
          } text-black-black no-underline`}
        >
          20ft
        </Button>
        <strong>|</strong>
        <Button
          theme="link"
          onClick={() => onContainerTypeClick(ContainerType.TYPE_40)}
          className={`${
            selectedType === ContainerType.TYPE_40 && 'font-bold'
          } text-black-black no-underline`}
        >
          40ft
        </Button>
        <strong>|</strong>
        <Button
          theme="link"
          onClick={() => onContainerTypeClick(ContainerType.TYPE_40_HC)}
          className={`${
            selectedType === ContainerType.TYPE_40_HC && 'font-bold'
          } text-black-black no-underline`}
        >
          40HC
        </Button>
      </Space>
    </div>
  );
};

export default ContainerTypeTabs;
