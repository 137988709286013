import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import BasketTable from './Results/BasketTable';
import { PayloadOrder } from './types/PayloadOrder.interface';
import usePayloadStore from './usePayloadStore';

interface Form {
  basket: {
    selected: boolean;
    quantity: number;
  }[];
}

interface IBasketSectionProps {
  onCalculate: (requests: PayloadOrder[]) => void;
  basketItems: PayloadOrder[];
}

const BasketSection: React.FunctionComponent<IBasketSectionProps> = ({
  onCalculate,
  basketItems,
}) => {
  const { register, handleSubmit } = useForm<Form>({
    mode: 'all',
    defaultValues: {
      basket: basketItems.map((item) => ({
        selected: true,
        quantity: item.unitQuantity,
      })),
    },
  });
  const { clearOrders, requiresRecalculation, updateOrder } = usePayloadStore();

  const updateOrders = (formData: Form) => {
    const { basket: items } = formData;
    items.forEach((item, index) => {
      updateOrder({
        ...basketItems[index],
        active: item.selected,
        unitQuantity: +item.quantity,
      });
    });
  };

  const handleCalculate = handleSubmit((data: Form) => {
    const updateQuantity = (order: PayloadOrder, index: number) => ({
      ...order,
      unitQuantity: data.basket[index].quantity,
    });
    const filterSelected = (item: PayloadOrder, index: number) =>
      data.basket[index].selected;

    const transformedOrders = basketItems
      .map(updateQuantity)
      .filter(filterSelected);

    onCalculate(transformedOrders);
    updateOrders(data);
  });

  const handleClearBasket = () => {
    clearOrders();
  };

  return (
    <Space direction="vertical" size="large" className="w-full">
      <BasketTable basketItems={basketItems} register={register} />
      <div className="w-full text-right">
        <Space size="middle">
          <Button onClick={handleClearBasket}>
            <Trans>Clear Basket</Trans>
          </Button>
          <Button theme="primary" onClick={handleCalculate}>
            {requiresRecalculation ? (
              <Trans>Recalculate</Trans>
            ) : (
              <Trans>Calculate</Trans>
            )}
          </Button>
        </Space>
      </div>
    </Space>
  );
};

export default BasketSection;
