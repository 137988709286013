import { Trans } from '@lingui/macro';
import * as React from 'react';
import Customer from '../../../types/Customer.interface';

interface IShipToAddressPopoverProps {
  customer: Customer;
}

const ShipToAddressPopover: React.FunctionComponent<IShipToAddressPopoverProps> = ({
  customer,
}) => {
  return (
    <div className="p-3">
      <h4 className=" bg-gray-dark100 text-white-white p-3 text-xl">
        <Trans>Ship to Address</Trans>
      </h4>
      <div className="p-3">
        <div>{customer.address}</div>
        <div>
          {customer.city}, {customer.country}
        </div>
        <div>{customer.postalCode}</div>
      </div>
    </div>
  );
};

export default ShipToAddressPopover;
