import React, { FC } from 'react';
import SimpleCard from '../../components/SimpleCard/SimpleCard';
import AccountForm from '../Account/AccountForm';
import useFormProperties from '../Account/useFormProperties';

interface IDashboardCustomizationPopoverProps {
  visible: boolean;
  setVisibility: (dashboardCustomizationIsVisible: boolean) => void;
}

const DashboardCustomizationPopover: FC<IDashboardCustomizationPopoverProps> = ({
  visible,
  setVisibility,
}) => {
  const { formProps } = useFormProperties();

  return (
    <SimpleCard
      innerWrapperClassName="py-11 px-6"
      style={{
        position: 'absolute',
        zIndex: 10,
        minWidth: '930px',
        width: 'calc(100% - 256px)',
        backgroundColor: 'white',
        display: visible ? 'block' : 'none',
      }}
    >
      {formProps && (
        <AccountForm {...formProps} setVisibility={setVisibility} isWidget />
      )}
    </SimpleCard>
  );
};

export default DashboardCustomizationPopover;
