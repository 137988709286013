import { isEmpty } from 'lodash';
import {
  euNumStringFormat,
  parseNumberString,
} from '../../../../services/Util/parseNumberString.util';
import EuBrand from '../../../../types/EuBrand.interface';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import { PayloadBasketRequest } from '../types/PayloadBasketRequest.interface';
import { PayloadForm } from '../types/PayloadForm.interface';

export const mapFormToRequest = (
  form: Partial<PayloadForm>,
  brands: EuBrand[]
): PayloadBasketRequest => {
  const {
    selectedBrand: selectedBrandIndex,
    ivGrammage,
    selectedProductType,
    ivPalletWidth = '0',
    ivPalletLength,
    ivPalletSheets,
    ivPalletMaxHeight,
    ivQtyPallets = '0',
    ivSheetUom = UnitOfMeasure.SH,
    ivReelWidth = '0',
    ivReelLength,
    ivReelDiameter,
    ivCoreDiameter,
    ivReelsPerPackage,
    ivPackageType,
    ivQtyReels = '0',
    ivReelUom = UnitOfMeasure.RL,
  } = form;
  const selectedBrand = selectedBrandIndex ? brands[+selectedBrandIndex] : null;
  const brandMember = selectedBrand ? selectedBrand.brandMember : null;
  const grade = selectedBrand ? selectedBrand.grade : null;
  const isSheet = selectedProductType === PutUpCategory.SHEET;
  const request: PayloadBasketRequest = {
    materialNumber: `${
      brandMember || grade
    }-${ivGrammage}${selectedProductType}`,
    unitQuantity: isSheet ? +ivQtyPallets : +ivQtyReels,
    unitType: isSheet ? ivSheetUom : ivReelUom,
    width: isSheet
      ? parseNumberString(ivPalletWidth, euNumStringFormat)
      : parseNumberString(ivReelWidth, euNumStringFormat),
  };
  return isSheet
    ? {
        ...request,
        length: ivPalletLength
          ? parseNumberString(ivPalletLength, euNumStringFormat)
          : null,
        sheetsPerPallet: !isEmpty(ivPalletMaxHeight)
          ? null
          : ivPalletSheets || null,
        heightPerPallet: ivPalletMaxHeight || null,
      }
    : {
        ...request,
        reelLength: ivReelLength
          ? parseNumberString(ivReelLength, euNumStringFormat)
          : undefined,
        reelDiameter: ivReelDiameter || null,
        coreDiameter: ivCoreDiameter ? +ivCoreDiameter : undefined,
        reelsPerPackage: ivReelsPerPackage ? +ivReelsPerPackage : undefined,
        reelPackage: ivPackageType ? 10 : 11,
      };
};
