import { t } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';
import { FRACTION_DECIMAL_INPUT } from '../../../types/RegularExpression.constants';
import useTradeBasisOptions, {
  EMPTY_TRADE_BASIS_OPTION,
} from '../useTradeBasisOptions';

const MIN_REQUIRED = 0.1;
const MAX_REQUIRED = 9999.9;
interface IImperialCriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

const ImperialCriteria: React.FunctionComponent<IImperialCriteriaProps> = ({
  register,
  errors,
}) => {
  const { tradeBasisOptions } = useTradeBasisOptions();
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Select
        name="tradeBasis"
        options={tradeBasisOptions}
        defaultValue={EMPTY_TRADE_BASIS_OPTION.value}
        ref={register({ required: true })}
        error={errors?.tradeBasis}
        required
      />
      <Input
        name="baseWeight"
        type="number"
        ref={register({
          required: true,
          min: MIN_REQUIRED,
          max: MAX_REQUIRED,
          validate: (value) => validateNumberOfDecimalPlaces(value, 1),
        })}
        required
        error={errors?.baseWeight}
        placeholder={t`Basis Weight`}
      />
      <Input
        name="sheetWidthImp"
        ref={register({
          required: true,
          min: MIN_REQUIRED,
          max: MAX_REQUIRED,
          pattern: FRACTION_DECIMAL_INPUT,
        })}
        required
        error={errors?.sheetWidthImp}
        placeholder={t`Width (in)`}
      />
      <Input
        name="sheetLengthImp"
        ref={register({
          required: true,
          min: MIN_REQUIRED,
          max: MAX_REQUIRED,
          pattern: FRACTION_DECIMAL_INPUT,
        })}
        required
        error={errors?.sheetLengthImp}
        placeholder={t`Length (in)`}
      />
    </Space>
  );
};

export default ImperialCriteria;
