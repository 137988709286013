import Axios from 'axios';
import MeasurementSystem from '../../types/MeasurementSystem.enum';
import UpdatePasswordRequest from '../../types/User/UpdatePasswordRequest.interface';
import User from '../../types/User/User.interface';
import { UserCustomization } from '../../types/User/UserCustomization.interface';

const getUser = async (): Promise<User> => {
  const { data } = await Axios.get('/portal/resources/user');
  return data;
};

export const getUserSelectedMeasurementSystem = async (): Promise<
  MeasurementSystem
> => {
  const { data } = await Axios.get('/portal/resources/measurement');
  return data.system;
};

export const getUserCustomization = async (): Promise<UserCustomization> => {
  const { data } = await Axios.get('/portal/resources/user/customization');
  return data;
};

export const saveUserCustomization = async (
  customization: UserCustomization
): Promise<UserCustomization> => {
  const { data } = await Axios.put(
    '/portal/resources/user/customization',
    customization
  );
  return data;
};

export const updatePassword = async (
  request: UpdatePasswordRequest
): Promise<string> => {
  const { data } = await Axios.put('/portal/resources/user/password', request)
  .catch(e => {
    return Promise.reject(e);
  });
  // Note that successful requests appear to just return an empty string.
  return data;
};

export const updatePasswordOnLogin = async (
  request: UpdatePasswordRequest
): Promise<string> => {
  const { data } = await Axios.post(
    '/portal/resources/login/password',
    request
  );
  // Note that successful requests appear to just return an empty string.
  return data;
};

export default getUser;
