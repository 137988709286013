import create from 'zustand';
import { SearchDocumentInputs } from '../PrintDocuments/SearchDocuments/SearchDocumentInputs';
import { DocumentDateTypes } from '../../types/DocumentDateTypes.interface';

export const DEFAULT_SEARCH_DOCUMENT_INPUTS: SearchDocumentInputs = {
  customerNumber: '0',
  dataType: DocumentDateTypes.ORDER_DATE,
};

type DashboardState = {
  searchDocumentInputs: SearchDocumentInputs;
  setSearchDocumentInputs: (searchDocumentInputs: SearchDocumentInputs) => void;
};

const useDashboardStore = create<DashboardState>((set) => ({
  searchDocumentInputs: DEFAULT_SEARCH_DOCUMENT_INPUTS,
  setSearchDocumentInputs: (searchDocumentInputs: SearchDocumentInputs) =>
    set(() => ({ searchDocumentInputs })),
}));

export default useDashboardStore;
