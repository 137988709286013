import { t, Trans } from '@lingui/macro';
import { Popover } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import useAnalytics from '../../../../hooks/useAnalytics';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import useRole from '../../../../hooks/useRole';
import useSamplesOrder from '../../../../hooks/useSamplesOrder';
import {
  createSamplesOrder,
  updateSamplesOrder,
} from '../../../../services/SamplesOrder';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { SamplesOrder } from '../../../../types/Samples/SamplesOrder.interface';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import ShippingType from '../../Basket/ShippingInstructions/ShippingType.enum';
import AddedToBasketPopUp from './AddedToBasketDummyPopUp';
import useDummyOrderStore, { DummyOrderState } from '../useDummyOrder.store';
import { SamplesDummyCharacteristics } from '../../../../types/Samples/SamplesDummyCharacteristics.interface';
import {
  assignState,
  noDummyCharacteristics,
  searchParamsInvalid,
} from './Copies.util';
import SamplesProductType from '../../../../types/Samples/SamplesProductType.enum';
import { Button } from '../../../../components/Button/Button';
import SamplesPage from '../../../../types/Samples/SamplesPage.enum';
import ErrorTooltip from '../../../../components/ErrorTooltip/ErrorTooltip';
import styles from './Copies.module.scss';

interface IAddFormData {
  quantity: string;
  dummyType: string;
  additionalInstructions: string;
}

const DEFAULT_SAMPLES_ORDER: SamplesOrder = {
  buyerNumber: null,
  comments: null,
  courierAccountNumber: null,
  customShipping: false,
  deliveryAddress: null,
  deliveryComment: null,
  deliveryInfo: {
    name: '',
    email: null,
    phone: null,
  },
  languageIso: null,
  palletDeliveryAvailable: null,
  products: [],
  shipToNumber: null,
  shippingAccountNumber: null,
  shippingType: ShippingType.STANDARD,
  userSessionId: '',
  username: '',
};

interface IAddToBasketProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  errors: UseFormMethods['errors'];
  handleSubmit: UseFormMethods['handleSubmit'];
}

const AddDummyToBasket: React.FunctionComponent<IAddToBasketProps> = ({
  register,
  watch,
  errors,
  handleSubmit,
}) => {
  const state = useDummyOrderStore();

  const { parse } = useNumberFormatter();
  const { isInternalUser } = useRole();
  const {
    data: existingOrder,
    isFetching: orderIsFetching,
  } = useSamplesOrder();
  const queryCache = useQueryCache();
  const { trackPageView } = useAnalytics();
  const [popUpIsVisible, setPopUpIsVisible] = useState<boolean>(false);
  const [addedProduct, setAddedProduct] = useState<SamplesProduct>();
  const gatefoldFrontWatch = watch('gatefoldFront');
  const gatefoldRearWatch = watch('gatefoldRear');
  const gatefoldFront2Watch = watch('gatefoldFront2');
  const gatefoldRear2Watch = watch('gatefoldRear2');
  const quantityWatch = watch('quantity');

  const getProductToCreate = (
    quantity: number,
    itemToAdd: typeof state
  ): SamplesProduct => {
    return {
      brandFamily: 'DUMMY',
      type: state.dummyType || SamplesProductType.UNPRINTED,
      quantity,
      unitSize: 1,
      unitSizeUnit: 'PCE',
      materialDescription: state.description || '',
      materialNumber: 'DUMMY',
      additionalInstructions: state.additionalInstructions || '',
      dummyCharacteristics: itemToAdd as SamplesDummyCharacteristics,
      largeQuantity: null,
      price: 0,
    };
  };

  const getItem = (
    parsedQuantity: number,
    additionalInstructions: string
  ): Partial<DummyOrderState> => {
    const assignedState = assignState(state, parsedQuantity);
    return {
      ...assignedState,
      additionalInstructions,
      coverPaperDifferentFromText:
        (state.coverPaperDifferentFromText || ('' as string)) !== 'false',
    };
  };

  const updateOrder = async () => {
    if (existingOrder) {
      await updateSamplesOrder({
        ...existingOrder,
      });
    }
  };

  const validateQtyRange = (quantity: string): boolean => {
    if (isInternalUser || quantity === '') {
      return true;
    }

    const parsed = parse(quantity);

    return parsed === null || parsed <= 5;
  };

  const doSubmit = async (data: IAddFormData) => {
    const parsedQuantity = parse(data.quantity);
    state.dummyType =
      data.dummyType === 'PRINT'
        ? SamplesProductType.PRINTED
        : SamplesProductType.UNPRINTED;

    if (parsedQuantity !== null) {
      const itemToAdd: typeof state = getItem(
        parsedQuantity,
        data.additionalInstructions
      );
      const product = getProductToCreate(parsedQuantity, itemToAdd);
      setAddedProduct(product);

      if (existingOrder?.id && existingOrder?.id > 0) {
        existingOrder.products.push(product);
        await updateOrder();
      } else {
        await createSamplesOrder({
          ...DEFAULT_SAMPLES_ORDER,
          products: [product],
        });
      }
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDER);
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDERS_HISTORY);
      trackPageView('SAMPLES', 'ADD_TO_BASKET');
    }

    setPopUpIsVisible(true);
  };

  return (
    <Popover
      visible={popUpIsVisible && !orderIsFetching}
      content={
        <AddedToBasketPopUp order={existingOrder} product={addedProduct} />
      }
      placement="top"
    >
      <div className="flex items-stretch">
        <ErrorTooltip
          hasError={errors && errors.quantity}
          placement="bottom"
          message={
            <div className="text-xs">
              <Trans>Value must be less than or equal to: 5</Trans>
            </div>
          }
        >
          <input
            type="number"
            name="quantity"
            ref={register({
              min: 0,
              max: 5,
              required: true,
              validate: {
                validateQtyRange,
              },
            })}
            defaultValue={state.quantity || undefined}
            placeholder={t`Quantity`}
            className={`border border-r-0 border-gray-gray text-lg text-center text-blue-pacific w-28 ${
              styles.quantityInput
            } ${errors?.quantity && 'error'}`}
          />
        </ErrorTooltip>

        {isInternalUser && (
          <Button
            theme="inverse"
            type="submit"
            onClick={handleSubmit(doSubmit)}
            disabled={
              !quantityWatch ||
              +quantityWatch > 5 ||
              +quantityWatch < 1 ||
              (errors && errors.additionalInstructions)
            }
            className="py-4 px-6"
          >
            <Trans>Add to Basket</Trans>
          </Button>
        )}
        {!isInternalUser && (
          <Button
            theme="inverse"
            onClick={handleSubmit(doSubmit)}
            disabled={
              !quantityWatch ||
              +quantityWatch > 5 ||
              +quantityWatch < 0 ||
              noDummyCharacteristics(
                state as SamplesDummyCharacteristics,
                SamplesPage.DESCRIPTION
              ) ||
              noDummyCharacteristics(
                state as SamplesDummyCharacteristics,
                SamplesPage.FORMAT
              ) ||
              noDummyCharacteristics(
                state as SamplesDummyCharacteristics,
                SamplesPage.TEXT_PAGES
              ) ||
              searchParamsInvalid(
                +gatefoldFrontWatch || 0,
                +gatefoldRearWatch || 0,
                +gatefoldFront2Watch || 0,
                +gatefoldRear2Watch || 0,

                state as SamplesDummyCharacteristics,
                SamplesPage.TEXT_PAGES
              ) ||
              noDummyCharacteristics(
                state as SamplesDummyCharacteristics,
                SamplesPage.COVER_OPTIONS
              )
            }
            className="py-4 px-6"
          >
            <Trans>Add to Basket</Trans>
          </Button>
        )}
      </div>
    </Popover>
  );
};

export default AddDummyToBasket;
