import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import useAnalytics from '../../hooks/useAnalytics';
import showNotification from '../../services/Notification';
import { VALID_EMAIL_REG_EX } from '../../types/RegularExpression.constants';
import PageTitle from '../PageHeader/PageTitle';
import { sendForgotUsernameRequest } from './ForgotUsername.service';

interface FormData {
  email: string;
}

const ForgotUsername: FC = () => {
  const { trackPageView } = useAnalytics();
  const history = useHistory();
  const {
    errors,
    formState: { isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<FormData>({ mode: 'all' });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  const successMessage = t`Your username has been sent! Please check your email.`;

  const submit = async (values: FormData) => {
    const { email } = values;

    setIsSubmitting(true);

    trackPageView('ADMIN', 'FORGOT_ID', { email });

    try {
      await sendForgotUsernameRequest(email);

      reset();
      setHasError(false);
      history.push('/login');
      showNotification({
        message: '',
        description: successMessage,
      });

      setIsSuccessful(true);
    } catch (error) {
      setHasError(true);
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="mt-10">
      <Space direction="vertical" size="large">
        <PageTitle title={t`Username Request`} />
        <div className="text-lg mt-4">
          <Trans>
            Please enter your email address below and we will email your user ID to you.
          </Trans>
        </div>
        <InputLabel size="lg" text={t`Email`} required>
          <Input
            name="email"
            ref={register({ required: true, pattern: VALID_EMAIL_REG_EX })}
            type="email"
            width="96"
            error={errors?.email}
            required
          />
        </InputLabel>
        <Button
          type="submit"
          theme="primary"
          disabled={isSubmitting || !isValid}
          loading={isSubmitting}
        >
          <Trans>Submit</Trans>
        </Button>
        {hasError && (
          <div className="text-red-red">
            <Trans>
              Could not find a user account with the given email address.
            </Trans>
          </div>
        )}
        {isSuccessful && <div>{successMessage}</div>}
      </Space>
    </form>
  );
};

export default ForgotUsername;
