import { Trans } from '@lingui/macro';
import * as React from 'react';

interface IContainerViewLeftColumnRowProps {
  label: string;
  value: number | string;
}

const ContainerViewLeftColumnRow: React.FunctionComponent<IContainerViewLeftColumnRowProps> = ({
  label,
  value,
}) => {
  return (
    <div className="flex justify-between my-4">
      <div className="ml-4 font-bold">{label}</div>
      <div className="mr-4">{value}</div>
    </div>
  );
};

export default ContainerViewLeftColumnRow;
