import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb as AntdBreadcrumb } from 'antd';

interface IBreadCrumbProps {
  breadCrumbValues: string[];
}

const BreadCrumb: FC<IBreadCrumbProps> = ({ breadCrumbValues }) => {
  const extraBreadcrumbItems = breadCrumbValues.map((breadCrumbValue) => {
    return (
      <AntdBreadcrumb.Item key={breadCrumbValue}>
        {breadCrumbValue}
      </AntdBreadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <AntdBreadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </AntdBreadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <>
      {breadcrumbItems.length > 1 && (
        <AntdBreadcrumb>{breadcrumbItems}</AntdBreadcrumb>
      )}
    </>
  );
};
export default BreadCrumb;
