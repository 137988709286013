import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect } from 'react';
import useAnalytics from '../../hooks/useAnalytics';

const RegistrationConfirmation: FC = () => {
  const { trackPageView } = useAnalytics();
  useEffect(() => {
    trackPageView('ADMIN', 'REGISTRATION_SUBMITTED');
  }, [trackPageView]);
  return (
    <Space size="middle" className="text-xl w-1/2" direction="vertical">
      <div className="text-2xl font-semibold">
        <Trans>
          Sappi has received your registration request for e-commerce customer
          portal.
        </Trans>
      </div>
      <div className="leading-8">
        <Trans>
          Please allow 1-3 business days for review. You will receive
          communication via the email address provided regarding your request.
        </Trans>
      </div>
      <div>
        <Trans>Thank you.</Trans>
      </div>
    </Space>
  );
};

export default RegistrationConfirmation;
