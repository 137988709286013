import create from 'zustand';
import { FileClaimSearchRequest } from './FileClaimSearchRequest.interface';

export const DEFAULT_REQUEST: FileClaimSearchRequest = {
  documentNumber: '',
  documentPosition: '',
};

type FileClaimSearchState = {
  error: string | null;
  request: FileClaimSearchRequest | null;
  setError: (error: string | null) => void;
  setRequest: (request: FileClaimSearchRequest | null) => void;
};

const useFileClaimSearchStore = create<FileClaimSearchState>((set) => ({
  error: null,
  request: DEFAULT_REQUEST,
  setError: (error: string | null) => set(() => ({ error })),
  setRequest: (request: FileClaimSearchRequest | null) => set(() => ({ request })),
}));

export default useFileClaimSearchStore;
