import React, { FC } from 'react';
import { Button } from '../../../components/Button/Button';

interface ITrashButtonProps {
  onClick: () => void;
}

const TrashButton: FC<ITrashButtonProps> = ({ onClick }) => {
  return (
    <Button theme="link" className="pl-3 pt-6 text-gray-600" onClick={onClick}>
      <i className="fa fa-2x fa-trash" />
    </Button>
  );
};

export default TrashButton;
