import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

interface IDescriptionCellProps {
  salesGradeDescription: string;
  width: number;
  length: number;
  grammage: string;
}

const DescriptionCell: FC<IDescriptionCellProps> = ({
  salesGradeDescription,
  width,
  length,
  grammage,
}) => {
  return (
    <>
      <strong>{salesGradeDescription}</strong>
      <br />(<Trans id="eu.width.abbrv">W</Trans>) {width}
      <Trans id="calculator.cm">cm</Trans> x (
      <Trans id="eu.length.abbrv">L</Trans>) {length}
      <Trans id="calculator.cm">cm</Trans> <br />
      {grammage}{' '}
      <Trans id="conversion.baseweight.grammage">grammage (gsm)</Trans>
    </>
  );
};

export default DescriptionCell;
