import { values } from 'lodash';
import create from 'zustand';
import { ConsignmentInventoryItem } from '../types/ConsignmentInventoryItem.interface';
import ConsignmentInventorySubmittedState from '../types/ConsignmentInventorySubmittedState.enum';

type ConsignmentInventoryStoreState = {
  submittedStates: { [key: string]: ConsignmentInventorySubmittedState };
  setSubmittedStates: (
    key: string,
    state: ConsignmentInventorySubmittedState
  ) => void;
  isAnySubmitting: boolean;
  showSubmissionPage: boolean;
  submittedInventory: ConsignmentInventoryItem[];
  addInventoryToSubmitted: (item: ConsignmentInventoryItem) => void;
  clearSubmittedInventory: () => void;
};

const useConsignmentInventoryStore = create<ConsignmentInventoryStoreState>(
  (set) => ({
    submittedStates: {},
    setSubmittedStates: (
      key: string,
      submittedState: ConsignmentInventorySubmittedState
    ) => {
      set((state) => {
        const submittedStates = {
          ...state.submittedStates,
          [key]: submittedState,
        };
        return {
          submittedStates,
          isAnySubmitting: values(submittedStates).some(
            (state_) => state_ === ConsignmentInventorySubmittedState.SUBMITTING
          ),
          showSubmissionPage:
            submittedState === ConsignmentInventorySubmittedState.SUBMITTED,
        };
      });
    },
    isAnySubmitting: false,
    showSubmissionPage: false,
    submittedInventory: [],
    addInventoryToSubmitted: (item: ConsignmentInventoryItem) =>
      set((state) => ({
        submittedInventory: [...state.submittedInventory, item],
      })),
    clearSubmittedInventory: () => set((state) => ({ submittedInventory: [] })),
  })
);

export default useConsignmentInventoryStore;
