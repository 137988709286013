import Axios from 'axios';
import { Moment } from 'moment';
import { convertMomentToSearchDate } from '../../services/Util/convertMomentToSearchDate.util';
import { convertMomentToSearchTime } from '../../services/Util/convertMomentToSearchTime.util';
import { Exceptions } from '../../types/Exceptions.interface';
import { ValueText } from '../../types/ValueText.interface';
import { DeviationData } from './types/DeviationData.interface';
import SearchFormField from './types/SearchFormField.enum';
import { TrackAndTraceData } from './types/TrackAndTraceData.interface';
import { TrackAndTraceLog } from './types/TrackAndTraceLog.interface';
import { ISearchForm } from './types/TSearchForm.type';

const getSearchParams = (params: ISearchForm) => {
  const dateFrom = params[SearchFormField.TRANSIT_DATE_FROM];
  const dateTo = params[SearchFormField.TRANSIT_DATE_TO];
  return {
    ...params,
    [SearchFormField.TRANSIT_DATE_FROM]: dateFrom
      ? convertMomentToSearchDate(params[SearchFormField.TRANSIT_DATE_FROM])
      : null,
    [SearchFormField.TRANSIT_DATE_TO]: dateTo
      ? convertMomentToSearchDate(params[SearchFormField.TRANSIT_DATE_TO])
      : null,
  };
};

const getChangedArrivalPostBody = (changed: TrackAndTraceData[]) => {
  return changed.map((item) => ({
    ...item,
    newArrivalDate: item.newArrivalDate
      ? convertMomentToSearchDate(item.newArrivalDate as Moment)
      : null,
    newDepartureDate: item.newDepartureDate
      ? convertMomentToSearchDate(item.newDepartureDate as Moment)
      : null,
    arrivalTimeString: item.newArrivalDate
      ? convertMomentToSearchTime(item.newArrivalDate as Moment)
      : null,
    departureTimeString: item.newDepartureDate
      ? convertMomentToSearchTime(item.newDepartureDate as Moment)
      : null,
  }));
};

const getChangedDeviationPostBody = (
  changed: DeviationData[],
  reasons: ValueText[]
) => {
  return changed.map((item) => ({
    ...item,
    newPlannedDate: item.newPlannedDate
      ? convertMomentToSearchDate(item.newPlannedDate as Moment)
      : null,
    newPlannedTimeString: item.newPlannedDate
      ? convertMomentToSearchTime(item.newPlannedDate as Moment)
      : null,
    deviationReason2: reasons.find(
      (reason) => reason.value === item.deviationReason
    )?.text,
  }));
};

export const searchArrival = async (
  params: ISearchForm
): Promise<TrackAndTraceData[]> => {
  const { data } = await Axios.get('/portal/resources/trackAndTrace/arrival', {
    params: getSearchParams(params),
  });
  return data;
};

export const searchDeviations = async (
  params: ISearchForm
): Promise<DeviationData[]> => {
  const { data } = await Axios.get(
    '/portal/resources/trackAndTrace/deviations',
    {
      params: getSearchParams(params),
    }
  );
  return data;
};
export const getTrackAndTraceLogs = async (
  payloadId: string
): Promise<TrackAndTraceLog[]> => {
  const { data } = await Axios.get(
    `/portal/resources/trackAndTrace/log/${payloadId}`
  );
  return data;
};

export const submitArrivals = async (
  changed: TrackAndTraceData[]
): Promise<unknown> => {
  const { data } = await Axios.post(
    '../resources/trackAndTrace/arrival',
    getChangedArrivalPostBody(changed)
  );
  return data;
};

export const submitDeviations = async (
  changed: DeviationData[],
  reasons: ValueText[]
): Promise<unknown> => {
  const { data } = await Axios.post(
    '../resources/trackAndTrace/deviations',
    getChangedDeviationPostBody(changed, reasons)
  );
  return data;
};

export const downloadArrivalExcel = async (
  arrivalData: TrackAndTraceData[]
): Promise<void> => {
  await Axios.post(
    '/portal/resources/trackAndTrace/arrival/excel',
    getChangedArrivalPostBody(arrivalData)
  );
  window.location.href = '/portal/resources/trackAndTrace/excel';
};

export const downloadDeviationExcel = async (
  deviationData: DeviationData[],
  reasons: ValueText[]
): Promise<void> => {
  await Axios.post(
    '/portal/resources/trackAndTrace/deviations/excel',
    getChangedDeviationPostBody(deviationData, reasons)
  );
  window.location.href = '/portal/resources/trackAndTrace/excel';
};

export const getUploadError = async (
  errorNumber: string
): Promise<Exceptions> => {
  const { data } = await Axios.get(
    `/portal/resources/trackAndTrace/error/${errorNumber}`
  );
  return data;
};
