import { QueryResult, useQuery } from 'react-query';
import { getSamplesOrder } from '../services/SamplesOrder';
import QueryCacheName from '../types/QueryCacheName.enum';
import { SamplesOrder } from '../types/Samples/SamplesOrder.interface';

const useSamplesOrder = (): QueryResult<SamplesOrder> => {
  return useQuery([QueryCacheName.SAMPLES_ORDER], getSamplesOrder, {
    staleTime: 5000,
    refetchOnWindowFocus: false,
  });
};

export default useSamplesOrder;
