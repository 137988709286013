import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';

interface ISummaryProps {
  country: string;
  maxPayload: number;
  totalUtilization: number;
  totalGrossWeight: number;
  totalNetWeight: number;
  perfectLoad: string;
}

const Summary: React.FunctionComponent<ISummaryProps> = ({
  country,
  maxPayload,
  totalUtilization,
  totalGrossWeight,
  totalNetWeight,
  perfectLoad,
}) => {
  const { format } = useNumberFormatter();
  return (
    <Space
      direction="vertical"
      size="large"
      className="p-5 w-full bg-gray-whiteSmoke400 text-lg"
    >
      <Space className="w-full flex-wrap" size="large">
        <LabelValue
          inline
          inverse
          label={`${t`Max Payload for`} ${country}`}
          value={`${format(maxPayload)} kg`}
        />
        <LabelValue
          inline
          inverse
          label={t`Total Utilization`}
          value={`${totalUtilization}%`}
        />
        <LabelValue
          inline
          inverse
          label={t`Total Weight (gross)`}
          value={`${format(totalGrossWeight)} kg`}
        />
        <LabelValue
          inline
          inverse
          label={t`Total Weight (net)`}
          value={`${format(totalNetWeight)} kg`}
        />
      </Space>
      {perfectLoad && (
        <Trans>
          For the perfect load, you need an additional {perfectLoad}
        </Trans>
      )}
    </Space>
  );
};

export default Summary;
