import React, { FC } from 'react';

interface IContactInfoCellProps {
  name: string;
  phone: string;
  email?: string;
  receiving?: string;
}

const ContactInfoCell: FC<IContactInfoCellProps> = ({
  name,
  phone,
  email,
  receiving,
}) => {
  return (
    <>
      <div>{name}</div>
      <div>{phone}</div>
      {email && <div>{email}</div>}
      {receiving && <div>{receiving}</div>}
    </>
  );
};

export default ContactInfoCell;
