import { NaShipTo } from '../../../../types/NaShipTo.interface';
import useDeliveryLocations from './useDeliveryLocations';

const useSelectedShipToCustomer = (
  selectedShipToNumber: string
): {
  selectedShipToCustomer: NaShipTo | undefined;
  isFetching: boolean;
} => {
  const { deliveryLocations, isFetching } = useDeliveryLocations();
  return {
    selectedShipToCustomer: deliveryLocations.find(
      (location) => location.customerNumber === selectedShipToNumber
    ),
    isFetching,
  };
};

export default useSelectedShipToCustomer;
