import { Space } from 'antd';
import { isArray } from 'lodash';
import React, { FC } from 'react';
import DateDisplay from '../../../../components/Date';
import useAnalytics from '../../../../hooks/useAnalytics';
import useCustomer from '../../../../hooks/useCustomer';
import useConsignmentInventoryStore from '../useConsignmentInventoryStore.store';
import Header from './Header';
import ResultsTable from './ResultsTable';

const ConfirmationPage: FC = () => {
  const { submittedInventory } = useConsignmentInventoryStore();
  const { trackPageView } = useAnalytics();
  const { getCustomerByNumber } = useCustomer();
  const backToSearchCriteria = () => {
    useConsignmentInventoryStore.setState({
      showSubmissionPage: false,
    });
  };
  const printConsignment = () => {
    trackPageView('CONSIGNMENT', 'PRINT');
    window.print();
  };
  const today = new Date();
  return (
    <Space direction="vertical" size="large" className="w-full print:block">
      <Header
        customerName={
          isArray(submittedInventory) && submittedInventory[0]
            ? getCustomerByNumber(submittedInventory[0].customerNumber)?.name ||
              ''
            : ''
        }
        date={<DateDisplay date={today} />}
        onBackToSearch={backToSearchCriteria}
        onPrintConsumption={printConsignment}
      />
      <ResultsTable results={submittedInventory} />
    </Space>
  );
};

export default ConfirmationPage;
