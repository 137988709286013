import { t, Trans } from '@lingui/macro';
import { Table as AntTable } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { Button } from '../../../components/Button/Button';
import Date from '../../../components/Date';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Table from '../../../components/Table/Table';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import {
  FinancialInvoiceParams,
  getFinancialInvoices,
} from '../../../services/Financials';
import stripZeros from '../../../services/Util/stripZeros.util';
import { FinancialPeriod } from '../../../types/FinancialPeriod.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { SavedInvoice } from '../../../types/SavedInvoice.interface';
import DownloadFinancials from '../DownloadFinancials';
import './AccountActivityTable.scss';

interface IAccountActivityTableProps {
  customerNumber: string;
  period: FinancialPeriod;
}
const SHOW_ALL_PAGE_SIZE = 10000;
const PAGE_SIZE = 50;

const AccountActivityTable: React.FunctionComponent<IAccountActivityTableProps> = ({
  customerNumber,
  period,
}) => {
  const { format } = useNumberFormatter();
  const [filters, setFilters] = useState<FinancialInvoiceParams>({
    customerNumber: '',
    period: '1',
    page: 1,
    sort: 'date',
    sortDirection: 'DESC',
  });
  const [sortColumn, setSortColumn] = useState<string>('date');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC');

  const { resolvedData: pagedInvoices, isFetching } = usePaginatedQuery(
    [QueryCacheName.FINANCIAL_INVOICES, filters || {}],
    getFinancialInvoices,
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      enabled: filters.customerNumber !== '',
      keepPreviousData: true,
    }
  );
  const onChange = (page: number, pageSize?: number) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page,
    }));
  };

  const onToggleShowAll = () => onChange(filters.page === 0 ? 1 : 0);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      customerNumber,
      period: period.periodId,
      sort: sortColumn,
      sortDirection,
    }));
  }, [customerNumber, period, sortColumn, sortDirection]);

  const handleSort = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, React.Key[] | null>,
    sorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    const columnKey = get(sorter, 'columnKey');
    const sortOrder = get(sorter, 'order');
    if (columnKey) {
      setSortColumn(columnKey);
      setSortDirection(sortOrder === 'ascend' ? 'ASC' : 'DESC');
    }
  };

  const columns: ColumnsType<SavedInvoice> = [
    {
      title: t`Invoice Number`,
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      sorter: true,
      render: (value) => (
        <a
          className="app-link"
          href={`../resources/financials/invoice/${value}`}
        >
          {stripZeros(value)}
        </a>
      ),
    },
    {
      title: t`My Order #`,
      dataIndex: 'customerRefNA',
      key: 'customerRefNA',
      sorter: true,
    },
    {
      title: t`Date Issued`,
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: (value) => <Date date={value} />,
      defaultSortOrder: 'descend',
    },
    {
      title: t`Due Date`,
      dataIndex: 'dueDate',
      key: 'dueDate',
      sorter: true,
      render: (value) => <Date date={value} />,
    },
    {
      title: t`Amount`,
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      render: (value) => format(value),
    },
    {
      title: t`Currency`,
      dataIndex: 'currency',
      key: 'currency',
      sorter: true,
    },
  ];

  return (
    <>
      <div>
        {pagedInvoices?.results.length ? (
          <div className="relative">
            <DownloadFinancials
              customerNumber={customerNumber}
              period={filters.period}
              sort={filters.sort}
              sortDirection="ASC"
            >
              <Button theme="link" className="absolute top-10 z-10">
                <i className="far fa-file-excel-o text-blue-pacific text-2xl mr-2" />
                <span className="text-lg">Download Report</span>
              </Button>
            </DownloadFinancials>
            <Table
              dataSource={pagedInvoices?.results}
              columns={columns}
              rowKey="id"
              pagination={{
                current: filters?.page === 0 ? 1 : filters?.page,
                onChange,
                pageSize: filters?.page === 0 ? SHOW_ALL_PAGE_SIZE : PAGE_SIZE,
                position: ['topRight'],
                total: pagedInvoices?.totalResults,
                showSizeChanger: false,
              }}
              config={{
                showAllOption: true,
                showExtraPaginationInfo: true,
              }}
              isShowAll={filters?.page !== 0}
              onToggleShowAll={onToggleShowAll}
              expandable={{
                rowExpandable: (record) => record.overviews.length > 1,
              }}
              onChange={handleSort}
              className="basic-table account-activity-table w-full"
              summary={(pageData) => {
                return (
                  <>
                    <AntTable.Summary.Row className="text-lg font-bold">
                      <AntTable.Summary.Cell
                        className="text-right border-gray-gray border-t-2 py-2"
                        index={3}
                        colSpan={4}
                      >
                        <Trans>Sub-Total</Trans>
                      </AntTable.Summary.Cell>
                      <AntTable.Summary.Cell
                        className="border-gray-gray border-t-2 py-2"
                        index={4}
                      >
                        {format(pagedInvoices.totalAmount)}
                      </AntTable.Summary.Cell>
                      <AntTable.Summary.Cell
                        className="border-gray-gray border-t-2 py-2"
                        index={5}
                      >
                        {pagedInvoices.totalCurrency}
                      </AntTable.Summary.Cell>
                    </AntTable.Summary.Row>
                  </>
                );
              }}
            />
          </div>
        ) : (
          <div>
            {isFetching ? (
              <LoadingSpinner horizontal />
            ) : (
              <div className="text-gray-600 my-3 text-center">
                <Trans>No invoices returned for period.</Trans>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AccountActivityTable;
