import { Space } from 'antd';
import React, { FC, useState } from 'react';
import BookmarkedOrders from './BookmarkedOrders';
import CustomizeDashboard from './CustomizeDashboard';
import PodOne from './PodOne';
import PodThree from './PodThree';
import PodTwo from './PodTwo';
import QuickLinks from './QuickLinks';
import Widgets from './Widgets';
import DashboardCustomizationPopover from './DashboardCustomizationPopover';

const Dashboard: FC = () => {
  const [
    dashboardCustomizationIsVisible,
    setDashboardCustomizationIsVisible,
  ] = useState(false);

  return (
    <>
      <div className="grid gap-6 grid-cols-3" style={{ minWidth: '930px' }}>
        <BookmarkedOrders className="col-span-3 mb-8" />
        <div className="col-span-3">
          <DashboardCustomizationPopover
            visible={dashboardCustomizationIsVisible}
            setVisibility={setDashboardCustomizationIsVisible}
          />
        </div>
        <Widgets className="col-span-2" />
        <Space direction="vertical" size="middle">
          <CustomizeDashboard
            setVisibility={setDashboardCustomizationIsVisible}
          />
          <QuickLinks />
        </Space>
        <PodOne />
        <PodTwo />
        <PodThree />
      </div>
    </>
  );
};

export default Dashboard;
