import { noop } from 'lodash';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../../components/Input/Input';
import useNumberFormatter from '../../../../../hooks/useNumberFormatter';
import { StockLotBasketForm } from '../StockLotBasketForm.type';
import StockLotBasketFormField from '../StockLotBasketFormField.enum';

interface IQuantityCellProps {
  register: UseFormMethods<StockLotBasketForm>['register'];
  errors: UseFormMethods<StockLotBasketForm>['errors'];
  index: number;
  quantity: number;
  atpQuantity: number;
  maxQuantity: number;
  onQuantityChange: (newQuantity: number) => void;
}

const QuantityCell: FC<IQuantityCellProps> = ({
  register,
  errors,
  index,
  quantity,
  atpQuantity,
  maxQuantity,
  onQuantityChange,
}) => {
  const { format } = useNumberFormatter();
  const error =
    errors && errors.basket ? errors.basket[index]?.numberOfUnits : undefined;

  return (
    <>
      <Input
        name={`basket.${index}.${StockLotBasketFormField.NUMBER_OF_UNITS}`}
        ref={register({ required: true, max: maxQuantity, min: 1 })}
        required
        error={error}
        defaultValue={quantity}
        width="52"
        type="number"
        onChange={(e) => (error ? noop : onQuantityChange(+e.target.value))}
      />
      <div>({format(atpQuantity)} KG)</div>
    </>
  );
};

export default QuantityCell;
