import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import ContainerType from '../types/ContainerType.enum';
import { LoadPlan } from '../types/LoadPlan.interface';
import { PayloadBasketResponse } from '../types/PayloadBasketResponse.interface';
import ContainerTypeTabs from './ContainerTypeTabs';
import LoadPlanTable from './LoadPlanTable';
import Summary from './Summary';
import UtilizationViewTable from './UtilizationViewTable';

interface IResultsProps {
  results: PayloadBasketResponse;
  selectedLoadPlans: LoadPlan[];
  error: string;
  onContainerTypeChange: (type: ContainerType) => void;
  onContainerNumberSelect: (containerNumber: number) => void;
  className?: string;
}

const Results: React.FunctionComponent<IResultsProps> = ({
  results,
  selectedLoadPlans,
  error,
  onContainerTypeChange,
  onContainerNumberSelect,
  className,
}) => {
  const {
    output: outputRows,
    finalCountry,
    containerMaxPayload,
    percentage: totalUtilization,
    containerWeightGrossKg,
    containerWeightNetKg,
  } = results;
  const perfectLoadText = outputRows[outputRows.length - 1]?.perfectLoad;
  return (
    <div className={className}>
      {error ? (
        <div className="text-red-red">{error}</div>
      ) : (
        <Space
          direction="vertical"
          size="large"
          className="w-full"
          style={{ minWidth: '677px' }}
        >
          <Summary
            country={finalCountry}
            maxPayload={containerMaxPayload}
            totalUtilization={totalUtilization}
            totalGrossWeight={containerWeightGrossKg}
            totalNetWeight={containerWeightNetKg}
            perfectLoad={perfectLoadText}
          />
          <Space direction="vertical" size="large" className="w-full">
            <div className="flex justify-between mx-5">
              <h4 className="text-lg text-blue-malibu font-bold">
                <Trans>Utilization View</Trans>
              </h4>
              <ContainerTypeTabs
                onContainerTypeChange={onContainerTypeChange}
              />
            </div>
            <UtilizationViewTable
              outputRows={outputRows}
              onContainerSelect={onContainerNumberSelect}
            />
            <h4 className="text-lg text-blue-malibu font-bold ml-5">
              <Trans>Load Plan</Trans>
            </h4>
            <LoadPlanTable loadPlans={selectedLoadPlans} />
          </Space>
        </Space>
      )}
    </div>
  );
};

export default Results;
