import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonProps } from '../../../components/Button/Button';
import useLocationQueryParams from '../../../hooks/useLocationQueryParams';
import useAnalytics from '../../../hooks/useAnalytics';

const ContinueShopping: FC<Omit<ButtonProps, 'onClick'>> = (props) => {
  const urlParams = useLocationQueryParams();
  const history = useHistory();
  const { trackPageView } = useAnalytics();

  const doContinueShopping = () => {
    if (urlParams.get('quickOrder')) {
      history.push('/quickOrder');
    } else {
      trackPageView('AVAILABILITY', 'CONTINUE_SHOPPING', {
        customerId: '',
      });
      history.push('/availabilityPlanning');
    }
  };

  return (
    <Button onClick={doContinueShopping} {...props}>
      <Trans>Continue Shopping</Trans>
    </Button>
  );
};

export default ContinueShopping;
