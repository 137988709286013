enum HelpCategory {
  BASKET = 'Basket',
  CALCULATORS = 'Calculators',
  CLAIM_REPORTING = 'Reporting&page=Claims Reporting',
  CONSIGNMENT_CONSUMPTION = 'Consignment Consumption',
  CONSIGNMENT_REPORTING = 'Reporting&page=Consignment Reporting',
  FINANCIAL_REPORTING = 'Reporting&page=Financial Reporting',
  HOME_PAGE = 'Home Page',
  MY_SAVED_ENQUIRIES = 'My Saved Enquiries',
  OPEN_ORDERS = 'View Open Mill Direct Orders',
  PRINT_DOCUMENTS = 'Print Documents',
  PRINT_LABELS = 'Print Labels',
  PRODUCT_AVAILABILITY = 'Product Availability',
  SAMPLES = 'Samples',
  STOCK_LOT = 'Stock Lot',
  TRACK_AND_TRACE = 'Track and Trace',
  TRACK_AND_TRACE_UPLOAD = 'Track and Trace Upload',
  TRACK_BY_LABEL = 'Track By Label ID',
  TRACK_ORDERS = 'Track Orders',
  TRACK_ORDERS_DETAIL = 'Order Details',
  WELCOME = 'Welcome',
}

export default HelpCategory;
