import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { getEqUiElementByName } from '../../Calculator.service';
import EqUiElementName from '../../types/EqCalculator/EqUiElementName.enum';
import { getTextFromUiElement } from './EqCalculatorResults.util';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import ResultsSection from './ResultsSection';
import EqBrandInfo from '../../types/EqCalculator/EqBrandInfo.interface';
import GetEqUiElementByNameParams from '../../types/EqCalculator/GetEqUiElementByNameParams.interface';

interface ProductsSectionProps {
  selectedBrands: EqBrandInfo[];
  weightUnits: string;
}

const ProductsSection: FC<ProductsSectionProps> = ({
  selectedBrands,
  weightUnits,
}) => {
  const [title, setTitle] = useState<string>();

  const params: GetEqUiElementByNameParams = {
    name: EqUiElementName.PRODUCTS_INCLUSION,
    lang: 'EN',
  };
  const { data: uiElement, isFetching } = useQuery(
    [QueryCacheName.EQ_UI_ELEMENT_BY_NAME, params],
    (
      queryKey: QueryCacheName.EQ_UI_ELEMENT_BY_NAME,
      queryParams: GetEqUiElementByNameParams
    ) => getEqUiElementByName(queryParams),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setTitle(getTextFromUiElement(uiElement));
  }, [uiElement]);

  return (
    <div>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        !!title && (
          <ResultsSection title={title}>
            {selectedBrands.length > 0 && (
              <ul className="px-5">
                {selectedBrands.map((brand) => (
                  <li key={brand.brand}>
                    {brand.brand}, {brand.value.toLocaleString()} {weightUnits}
                  </li>
                ))}
              </ul>
            )}
          </ResultsSection>
        )
      )}
    </div>
  );
};

export default ProductsSection;
