import { t, Trans } from '@lingui/macro';
import { Checkbox, Space, Tree } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { CAPTCHA_SITE_KEY } from '../../App.const';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import Select from '../../components/Select/Select';
import Textarea from '../../components/Textarea/Textarea';
import useAnalytics from '../../hooks/useAnalytics';
import { VALID_EMAIL_REG_EX } from '../../types/RegularExpression.constants';
import { CANADA, UNITED_STATES } from './Countries.const';
import { sendRegistration } from './Registration.service';
import {
  addInParentProducts,
  CANADA_PROVINCE_OPTIONS,
  COUNTRY_OPTIONS,
  getLanguage,
  isPageNavigated,
  PACKAGING_SPECIALTY_PAPERS,
  SAPPI_PRODUCTS,
  USA_STATE_OPTIONS,
} from './Registration.util';
import RegistrationPromoText from './RegistrationPromoText';
import { RegistrationForm } from './types/RegistrationForm.interface';
import RegistrationFormFields from './types/RegistrationFormFields.enum';

const Registration: FC = () => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    errors,
    watch,
    formState: { isValid },
  } = useForm<RegistrationForm>({
    mode: 'all',
    defaultValues: {
      [RegistrationFormFields.ACCEPTED_TERMS]: '',
      [RegistrationFormFields.SAPPI_PRODUCTS]: [],
    },
  });
  const country = watch(RegistrationFormFields.COUNTRY);
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<null | string>(null);
  const history = useHistory();
  const { trackPageView } = useAnalytics();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  useEffect(() => {
    if (country) {
      setValue(RegistrationFormFields.LANGUAGE, getLanguage(country));
    }
    if (isPageNavigated()) {
      trackPageView('ADMIN', 'REGISTRATION');
    }
  }, [country, setValue, trackPageView]);

  const resetFields = () => {
    reset();
  };

  const submitForm = async (formData: RegistrationForm) => {
    setHasError(false);
    setIsSubmitting(true);
    if (formData.optInAe) {
      formData.optInAe = 'TRUE';
    } else {
      formData.optInAe = 'FALSE';
    }
    try {
      if (captchaToken) {
        await sendRegistration(
          {
            ...formData,
            sappiProducts: addInParentProducts(formData.sappiProducts),
          },
          captchaToken,
          CAPTCHA_SITE_KEY
        );
        history.push('/registration/submitted');
      }
    } catch (e) {
      setHasError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onCaptchaComplete = (token: string | null) => {
    setCaptchaToken(token);
  };

  return (
    <div className="flex">
      <form
        onSubmit={handleSubmit(submitForm)}
        className="flex-grow"
        style={{ maxWidth: '640px', minWidth: '300px' }}
      >
        <Space direction="vertical" size="large" className="w-full">
          <InputLabel text={t`First Name`} required size="lg">
            <Input
              name={RegistrationFormFields.FIRST_NAME}
              ref={register({ required: true })}
              required
            />
          </InputLabel>
          <InputLabel text={t`Last Name`} required size="lg">
            <Input
              name={RegistrationFormFields.LAST_NAME}
              ref={register({ required: true })}
              required
            />
          </InputLabel>
          <InputLabel text={t`Company Name`} required size="lg">
            <Input
              name={RegistrationFormFields.COMPANY}
              ref={register({ required: true })}
              required
            />
          </InputLabel>
          <InputLabel
            text={t`What Sappi products are you interested in?`}
            size="lg"
            required
          >
            <Controller
              name={RegistrationFormFields.SAPPI_PRODUCTS}
              control={control}
              rules={{ validate: (value) => value && value.length > 0 }}
              render={({ onChange, value }) => (
                <Tree
                  checkable
                  defaultExpandedKeys={[PACKAGING_SPECIALTY_PAPERS]}
                  onCheck={onChange}
                  treeData={SAPPI_PRODUCTS}
                  checkedKeys={value}
                />
              )}
            />
          </InputLabel>
          <InputLabel text={t`Job Title`} size="lg" required>
            <Input
              name={RegistrationFormFields.JOB_TITLE}
              ref={register({ required: true })}
              required
            />
          </InputLabel>
          <InputLabel text={t`Postal Address`} required size="lg">
            <Textarea
              name={RegistrationFormFields.POSTAL_ADDRESS}
              ref={register({ required: true })}
              rows={4}
              cols={40}
              required
            />
          </InputLabel>
          <InputLabel text={t`City`} required size="lg">
            <Input
              name={RegistrationFormFields.CITY}
              ref={register({ required: true })}
              required
            />
          </InputLabel>
          <InputLabel text={t`Postal Code`} required size="lg">
            <Input
              name={RegistrationFormFields.POSTAL_CODE}
              ref={register({ required: true })}
              required
            />
          </InputLabel>
          <InputLabel text={t`Country`} required size="lg">
            <Select
              name={RegistrationFormFields.COUNTRY}
              ref={register({ required: true })}
              options={COUNTRY_OPTIONS}
              required
            />
          </InputLabel>
          {country === UNITED_STATES && (
            <InputLabel text={t`State`} size="lg">
              <Select
                name={RegistrationFormFields.STATE}
                ref={register()}
                options={USA_STATE_OPTIONS}
              />
            </InputLabel>
          )}
          {country === CANADA && (
            <InputLabel text={t`Province`} size="lg">
              <Select
                name={RegistrationFormFields.STATE}
                ref={register()}
                options={CANADA_PROVINCE_OPTIONS}
              />
            </InputLabel>
          )}
          <InputLabel text={t`Telephone`} required size="lg">
            <Input
              name={RegistrationFormFields.PHONE}
              ref={register({ required: true })}
              required
            />
          </InputLabel>
          <InputLabel text={t`EMail`} required size="lg">
            <Input
              name={RegistrationFormFields.EMAIL}
              type="email"
              ref={register({ required: true, pattern: VALID_EMAIL_REG_EX })}
              required
              error={errors.email}
            />
          </InputLabel>
          <InputLabel text={t`Message`} size="lg">
            <Textarea
              name={RegistrationFormFields.MESSAGE}
              ref={register()}
              rows={4}
              cols={40}
            />
          </InputLabel>
          {/* {<Space size="small">
            <Controller
              name={RegistrationFormFields.OPT_IN_AE}
              control={control}
              rules={{ validate: (value) => !!value }}
              render={({ onChange, value, name }) => (
                <Checkbox
                  name={name}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                  defaultChecked={false}
                  value={`${value}`}
                />
              )}
            />
            <div>
              <Trans>
                I sign up to receive tailored communications on useful marketing
                insights, our recent printed publications, and relevant Sappi
                news. You may unsubscribe from these communications at any time.
                For more information on how Sappi processes and secures your
                data, please view our{' '}
                <a
                  className="app-link"
                  href="https://www.sappi.com/privacy-notice#"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Notice
                </a>
              </Trans>
            </div>
          </Space>} */}
          <Space size="small">
            <Controller
              name={RegistrationFormFields.ACCEPTED_TERMS}
              control={control}
              rules={{ validate: (value) => !!value }}
              render={({ onChange, value, name }) => (
                <Checkbox
                  name={name}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={value}
                />
              )}
            />
            <div>
              <Trans>
                I accept the{' '}
                <a
                  className="app-link"
                  href="https://www.sappi.com/legal"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>{' '}
                of this website
              </Trans>
            </div>
            <input
              type="hidden"
              id="language"
              name={RegistrationFormFields.LANGUAGE}
              ref={register()}
            />
          </Space>
          {CAPTCHA_SITE_KEY && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={CAPTCHA_SITE_KEY}
              onChange={onCaptchaComplete}
            />
          )}
          <Space size="middle">
            <Button
              theme="primary"
              // disabled={!isValid || isSubmitting || !captchaToken}
              loading={isSubmitting}
            >
              <Trans>Submit</Trans>
            </Button>
            <Button
              type="reset"
              theme="link"
              className="text-lg font-bold"
              onClick={() => resetFields()}
            >
              <Trans>Reset</Trans>
            </Button>
          </Space>
          {hasError && (
            <div className="text-red-red">
              <Trans>An unspecified server error has occurred.</Trans>
            </div>
          )}
        </Space>
      </form>
      <RegistrationPromoText />
    </div>
  );
};

export default Registration;
