import React, { FC, useState } from 'react';
import CalculateEqSavingsParams from '../types/EqCalculator/CalculateEqSavingsParams.interface';
import EqCalculatorInputs from './EqCalculatorInputs';
import leafImage from '../../../assets/calculators/eQ-calculator-tree-leaves.jpg';
import EqCalculatorResults from './Results/EqCalculatorResults';
import useAnalytics from '../../../hooks/useAnalytics';

const EqCalculator: FC = () => {
  const { trackPageView } = useAnalytics();
  const [paramsForCalculation, setParamsForCalculation] = useState<
    CalculateEqSavingsParams
  >();

  const doCalculate = (params: CalculateEqSavingsParams) => {
    trackPageView('CALCULATORS', 'CALCULATE_EQ');
    setParamsForCalculation(params);
  };

  const doReset = () => {
    setParamsForCalculation(undefined);
  };

  return (
    <div className="flex my-6 -mx-9">
      <div className="flex-grow-0 mx-9">
        <EqCalculatorInputs onCalculate={doCalculate} onReset={doReset} />
      </div>
      <div className="flex-grow mx-9">
        {paramsForCalculation ? (
          <EqCalculatorResults paramsForCalculation={paramsForCalculation} />
        ) : (
          <img
            src={leafImage}
            alt=""
            className="h-full object-cover object-left-top pr-7"
            style={{ height: '705px' }}
          />
        )}
      </div>
    </div>
  );
};

export default EqCalculator;
