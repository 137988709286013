import { map } from 'lodash';
import QuickOrderRow from '../../../components/QuickOrderBookingTable/QuickOrderRow.interface';
import { AvailabilityBasketItem } from '../../../types/AvailabilityBasketItem.interface';
import AvailabilityBasketType from '../../../types/AvailabilityBasketType.enum';
import addToAvailabilityBasketItem from '../../../Util/Basket/addToAvailabilityBasketItems';

function toAvailabilityBasketItem(
  row: QuickOrderRow,
  requestedDeliveryDate: number | undefined
): AvailabilityBasketItem | null {
  // prior logic required reelWidthInches to be passed in
  // to calculate Mill Inventory basket item
  if (row.materialNumber) {
    const currDate = new Date();
    const baseResult: AvailabilityBasketItem = {
      id: null,
      product: row.brand,
      skuCode: row.kcode || '',
      requestedQuantity: row.userEnteredQuantity || 0,
      bookweight: row.bookweight,
      grainDirection: row.grainDirection || '',
      packageTypeDesc: row.wrapDescription || '',
      tradeBasis: row.usBaseWeight,
      grammage: row.grammage,
      caliper: row.caliper,
      caliperMils: row.caliperMils,
      basketType: AvailabilityBasketType.STOCK,
      requestedUnitOfMeasure: row.selectedMeasureUnit || '',
      requestedDate: requestedDeliveryDate === undefined ? currDate.setDate(currDate.getDate() + 1) : requestedDeliveryDate,
      productType: row.productType,
      reelWrapping: row.reelWrapping || '',
      reelPackage: row.reelPackage || '',
      windingDirection: row.windingDirection || '',
      materialNumber: `${row.grade}-${row.grammage}${row.productType}`,
      coreDiameter: row.coreDiameter || 0,
      coreDiameterImp: row.coreDiameterInches || '',
      gradeBrandMember: row.brandMember,
    };
    return addToAvailabilityBasketItem(baseResult, row);
  }
  return null;
}
export default function toAvailabilityBasketItems(
  rows: QuickOrderRow[],
  requestedDeliveryDate: number | undefined
): AvailabilityBasketItem[] {
  return map(rows, (row) =>
    toAvailabilityBasketItem(row, requestedDeliveryDate)
  ).filter((row) => row != null) as AvailabilityBasketItem[];
}
