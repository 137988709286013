import { t, Trans } from '@lingui/macro';
import { forOwn } from 'lodash';
import React, { FC, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import Select from '../../components/Select/Select';
import SimpleCard from '../../components/SimpleCard/SimpleCard';
import useAnalytics from '../../hooks/useAnalytics';
import { getPackingList } from '../../services/Packing';
import { PACKING_LIST_OPTIONS } from './PackingList.util';
import ContainerInvoiceRequest from './types/ContainerInvoiceRequest.interface';
import ContainerNumberRequest from './types/ContainerNumberRequest.interface';
import PackingListResponse from './types/PackingListResponse.interface';
import PackingListType from './types/PackingListType.enum';

const SEARCH_BY = 'searchBy';

interface IPackingListReportingFormProps {
  onDisplayResponse: (retrievedData: PackingListResponse | null) => void;
}

const PackingListReportingForm: FC<IPackingListReportingFormProps> = ({
  onDisplayResponse,
}) => {
  const { trackPageView } = useAnalytics();
  const { register, control, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      packingList: [{ itemInput: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'packingList',
  });
  const [error, setError] = useState('');

  const onReset = () => {
    reset();
    onDisplayResponse(null);
  };

  const onSubmit = async () => {
    const packingListInputs = getValues().packingList;
    const invoiceParams: ContainerInvoiceRequest[] = [];
    const numberParams: ContainerNumberRequest[] = [];
    packingListInputs.forEach((p) => {
      forOwn(p, (value) => {
        if (value) {
          if (getValues(SEARCH_BY) === PackingListType.INVOICES) {
            invoiceParams.push({
              invoiceNumber: value,
            });
          } else {
            numberParams.push({
              containerNumber: value,
            });
          }
        }
      });
    });

    try {
      setError('');
      trackPageView('REPORTING', 'DOWNLOAD_PACKING_LIST');
      const response = await getPackingList(
        getValues(SEARCH_BY) === PackingListType.INVOICES
          ? {
              type: PackingListType.INVOICES,
              params: invoiceParams,
            }
          : {
              type: PackingListType.CONTAINERS,
              params: numberParams,
            }
      );
      onDisplayResponse(response);
    } catch (e) {
      setError(e.message ? e.message : t`Unknown error occurred`);
    }
  };

  return (
    <SimpleCard style={{ width: '690px' }} className="mr-8">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ width: '310px' }}>
          <InputLabel size="lg" text={t`Search By`} className="pb-3">
            <Select
              id={SEARCH_BY}
              name={SEARCH_BY}
              options={PACKING_LIST_OPTIONS}
              ref={register()}
              width="full"
            />
          </InputLabel>
        </div>
        <div className="my-4" style={{ width: '310px' }}>
          {fields.map((item, index) => {
            return (
              <div className="flex" key={item.id}>
                <Input
                  name={`packingList[${index}].itemInput`}
                  width="full"
                  className="my-2"
                  defaultValue={item.itemInput}
                  ref={register()}
                />
                {fields.length > 1 ? (
                  <Button
                    theme="link"
                    className="fa fa-minus-circle text-blue-pacific text-3xl ml-3 no-underline"
                    onClick={() => remove(index)}
                  />
                ) : (
                  <div className="w-10" />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex justify-between mt-8">
          <Button type="submit" theme="primary" onClick={() => append({})}>
            <Trans>Add Item</Trans>
          </Button>
          <div className="flex">
            <Button className="mr-5" type="submit" theme="primary">
              <Trans>Search</Trans>
            </Button>
            <Button
              type="reset"
              theme="link"
              className="text-lg font-bold"
              onClick={onReset}
            >
              <Trans>Reset</Trans>
            </Button>
          </div>
        </div>
      </form>
      {!!error && <div className="text-red-red">{error}</div>}
    </SimpleCard>
  );
};

export default PackingListReportingForm;
