import { Space } from 'antd';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import useDateFormatter, { DateFormat } from '../../hooks/useDateFormatter';
import useNewsItem from './useNewsItem';

const News: FC = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const { newsItem } = useNewsItem(newsId);
  const { format } = useDateFormatter();

  return (
    <Space direction="vertical" size="middle">
      <i className="-mt-10">
        {format(newsItem?.publishDate || '', DateFormat.NEWS)}
      </i>

      <div
        className="text-xl"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: newsItem?.body || '' }}
      />
    </Space>
  );
};

export default News;
