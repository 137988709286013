import { t } from '@lingui/macro';
import Option from '../../../types/Option.type';

export const getOptions = (
  items: any[] | undefined,
  labelField: string,
  valueField: string
): Option[] => {
  const options: Option[] = [
    {
      label: t`Select`,
      value: '',
    },
  ];
  return items
    ? options.concat(
        items.map((item) => ({
          value: item[valueField],
          label: item[labelField],
        }))
      )
    : options;
};
