import { t } from '@lingui/macro';
import React, { FC } from 'react';
import Modal, { IModalProps } from '../../../components/Modal/Modal';
import useRole from '../../../hooks/useRole';
import { AuthorityRole } from '../../../types/Authority.interface';
import CalculatorsNote from '../../Calculators/CalculatorsNote';
import NaOrReleaseCalculators from './NaOrReleaseCalculators';
import EuCalculators from './EuCalculators';

const HeaderCalculatorsModal: FC<IModalProps> = (props) => {
  const { hasRole } = useRole();
  const isNaOrRelease =
    hasRole(AuthorityRole.ROLE_NA) || hasRole(AuthorityRole.ROLE_RELEASE);
  const isNonReleaseEu =
    hasRole(AuthorityRole.ROLE_EU) && !hasRole(AuthorityRole.ROLE_RELEASE);

  return (
    <Modal title={t`Calculators`} width={1146} {...props}>
      <CalculatorsNote className="mb-3" />
      {isNaOrRelease && <NaOrReleaseCalculators />}
      {isNonReleaseEu && <EuCalculators />}
    </Modal>
  );
};

export default HeaderCalculatorsModal;
