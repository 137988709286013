import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import React from 'react';
import TagManager from 'react-gtm-module';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { HashRouter as Router } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import SiteFooter from './domain/SiteFooter/SiteFooter';
import SiteHeader from './domain/SiteHeader/SiteHeader';
import TopMenu from './domain/TopMenu/TopMenu';
import useLanguage from './hooks/useLanguage';
import { doLanguageChange } from './services/Locale';
import { getProperties } from './services/Properties';
import { Properties } from './types/Properties.interface';
import ClaimRouteListener from './Util/RouteListener/ClaimRouteListener';

const setupGTM = async () => {
  const properties = await getProperties();
  const tagManagerArgs = {
    gtmId: (properties as Properties).gtmId,
    auth: (properties as Properties).gtmAuth,
    preview: (properties as Properties).gtmPreview,
  };
  TagManager.initialize(tagManagerArgs);
};
setupGTM();
const queryCache = new QueryCache();

// WARNING: Do not put any markup outside of a <Route> as it will show up for all paths and we use this to
// allow targeted injection (via React Portals) of react components outside the react component tree
function App(): JSX.Element {
  const lang = useLanguage();
  doLanguageChange(lang);

  return (
    <>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <I18nProvider i18n={i18n}>
          <Router>
            <div className="pre-footer-container">
              <SiteHeader />
              <div className="wrapper">
                <TopMenu />
                <ClaimRouteListener key="claim-route-listener" />
                <AppRoutes />
              </div>
            </div>
            <SiteFooter />
          </Router>
        </I18nProvider>
      </ReactQueryCacheProvider>
    </>
  );
}

export default App;
