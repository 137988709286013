import locale_de_DE from 'antd/es/date-picker/locale/de_DE';
import locale_en_GB from 'antd/es/date-picker/locale/en_GB';
import locale_en_US from 'antd/es/date-picker/locale/en_US';
import locale_es_ES from 'antd/es/date-picker/locale/es_ES';
import locale_fi_FI from 'antd/es/date-picker/locale/fi_FI';
import locale_fr_FR from 'antd/es/date-picker/locale/fr_FR';
import locale_hu_HU from 'antd/es/date-picker/locale/hu_HU';
import locale_it_IT from 'antd/es/date-picker/locale/it_IT';
import locale_nl_NL from 'antd/es/date-picker/locale/nl_NL';
import locale_pl_PL from 'antd/es/date-picker/locale/pl_PL';
import locale_ru_RU from 'antd/es/date-picker/locale/ru_RU';
import locale_sk_SK from 'antd/es/date-picker/locale/sk_SK';
import Locale from '../../types/Locale.enum';

const getDatePickerLocale = (locale: Locale) => {
  let localeToUse;
  switch (locale) {
    case Locale.de:
      localeToUse = locale_de_DE;
      break;
    case Locale.es:
      localeToUse = locale_es_ES;
      break;
    case Locale.fi:
      localeToUse = locale_fi_FI;
      break;
    case Locale.fr:
      localeToUse = locale_fr_FR;
      break;
    case Locale.hu:
      localeToUse = locale_hu_HU;
      break;
    case Locale.it:
      localeToUse = locale_it_IT;
      break;
    case Locale.nl:
      localeToUse = locale_nl_NL;
      break;
    case Locale.pl:
      localeToUse = locale_pl_PL;
      break;
    case Locale.ru:
      localeToUse = locale_ru_RU;
      break;
    case Locale.sk:
      localeToUse = locale_sk_SK;
      break;
    case Locale.eu:
      localeToUse = locale_en_GB;
      break;
    default:
      localeToUse = locale_en_US;
  }

  return localeToUse;
};

export default getDatePickerLocale;
