import MeasurementQuantity from '../types/MeasurementQuantity.interface';
import MeasurementSystem from '../types/MeasurementSystem.enum';
import useMeasurementSystem from './useMeasurementSystem';
import useNumberFormatter from './useNumberFormatter';

const useMeasurementFormatter = (): {
  format: (
    imperialQuantity: MeasurementQuantity,
    metricQuantity: MeasurementQuantity
  ) => string;
} => {
  const { measurementSystem } = useMeasurementSystem();
  const { format: formatNumber } = useNumberFormatter();

  const formatMeasurement = (
    imperialQuantity: MeasurementQuantity,
    metricQuantity: MeasurementQuantity
  ) => {
    const quantityToUse =
      measurementSystem === MeasurementSystem.METRIC
        ? metricQuantity
        : imperialQuantity;
    return `${formatNumber(Math.round(quantityToUse.amount))} ${
      quantityToUse.unit
    }`;
  };

  return {
    format: formatMeasurement,
  };
};

export default useMeasurementFormatter;
