import * as React from 'react';
import InputLabel from '../../../components/InputLabel';

interface ILabelInputProps {
  label: string;
  required?: boolean;
}

const NewAddressInputWrapper: React.FunctionComponent<ILabelInputProps> = ({
  label,
  required,
  children,
}) => {
  return (
    <>
      <div className="py-1 pb-4 w-1/3">
        <InputLabel
          size="lg"
          required={required}
          className="text-gray-dark200"
          text={label}
        />
        {children}
      </div>
    </>
  );
};

export default NewAddressInputWrapper;
