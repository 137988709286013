import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import getDeliveryInfo from '../../services/Delivery';
import stripZeros from '../../services/Util/stripZeros.util';
import { OrderOverviewRecord } from '../../types/OrderOverviewRecord.interface';
import InformationModal from '../InformationModal/InformationModal';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import OrderShipmentDetail from './OrderShipmentDetail';

interface IShippingInformationModalProps {
  visible: boolean;
  customerNumber: string;
  documentNumber: string;
  shipToPartyName: string;
  shipToPartyCity: string;
  customerOrderNumber: string;
  overviews: OrderOverviewRecord[];
  onClose: () => void;
}

const ShippingInformationModal: React.FunctionComponent<IShippingInformationModalProps> = ({
  visible,
  customerNumber,
  documentNumber,
  shipToPartyName,
  shipToPartyCity,
  customerOrderNumber,
  overviews,
  onClose,
}) => {
  let summaryFields;
  const { trackPageView } = useAnalytics();
  const [overviewsWithDeliveryInfo, setOverviewsWithDeliveryInfo] = useState<
    OrderOverviewRecord[]
  >([]);
  const [loading, setLoading] = useState(false);
  if (customerNumber) {
    summaryFields = [
      {
        label: t({ id: 'sappiOrder', message: 'Sappi Order #' }),
        value: stripZeros(documentNumber),
      },
      {
        label: t({ id: 'delivery.address', message: 'Delivery Location' }),
        value: `${shipToPartyName} / ${shipToPartyCity}`,
      },
      {
        label: t`My Order #`,
        value: stripZeros(customerOrderNumber),
      },
    ];
  }

  useEffect(() => {
    setOverviewsWithDeliveryInfo([]);
    async function loadDeliveries(overviews_: OrderOverviewRecord[]) {
      if (overviews_.length && visible) {
        trackPageView('TRACK_ORDERS', 'SHIPMENT_DETAILS', {
          customerId: customerNumber,
        });
        setLoading(true);
        const overviewsPromises = await overviews_.map(
          async (overview: OrderOverviewRecord) =>
            ({
              ...overview,
              deliveryInfo: await getDeliveryInfo(
                overview.documentNumber,
                overview.documentPosition
              ),
            } as OrderOverviewRecord)
        );
        setOverviewsWithDeliveryInfo(await Promise.all(overviewsPromises));
        setLoading(false);
      }
    }
    if (overviews.length) {
      loadDeliveries(overviews);
    }
  }, [overviews, customerNumber, trackPageView, visible]);

  return (
    <InformationModal
      visible={visible}
      title={
        <Trans id="order_list.header.transit_info">
          Information About Shipment
        </Trans>
      }
      summaryFields={summaryFields || []}
      detailsTitle={
        <Trans id="delivery.shipmentHeader">Shipment Details</Trans>
      }
      onCancel={onClose}
    >
      <div className="overflow-auto h-96 w-full">
        {loading && <LoadingSpinner horizontal />}
        {!loading &&
          overviewsWithDeliveryInfo.length &&
          overviewsWithDeliveryInfo.map((ov) => (
            <OrderShipmentDetail
              key={`${ov.documentNumber}-${ov.documentPosition}`}
              orderOverview={ov}
            />
          ))}
      </div>
    </InformationModal>
  );
};

export default ShippingInformationModal;
