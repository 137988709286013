import { Trans } from '@lingui/macro';
import { Popover } from 'antd';
import React, { FC, useState } from 'react';
import { Button } from '../../../components/Button/Button';
import useNonReleaseEuBasketPopupStore from '../../PageHeader/OldSappiBasket/useNonReleaseEuBasketPopup.store';
import { Batch } from '../types/Batch.interface';
import StockLotAddedToBasketPopUp from './StockLotAddedToBasketPopup';

interface StockLotAddToBasketProps {
  item: Batch;
  onAddToBasket: (item: Batch) => Promise<void>;
  isItemBeingAdded: boolean;
}

const StockLotAddToBasket: FC<StockLotAddToBasketProps> = ({
  item,
  onAddToBasket,
  isItemBeingAdded,
}) => {
  const [isAlreadyAdded, setIsAlreadyAdded] = useState<boolean>(false);
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(false);
  const { setStockLotLastAddedFor15 } = useNonReleaseEuBasketPopupStore();

  const addItemToBasket = async () => {
    await onAddToBasket(item);

    setIsPopUpVisible(true);
    setStockLotLastAddedFor15(item);

    setIsAlreadyAdded(true);
  };

  return (
    <Popover
      visible={isPopUpVisible}
      placement="bottom"
      content={
        <StockLotAddedToBasketPopUp
          item={item}
          onClose={() => setIsPopUpVisible(false)}
          onContinueShopping={() => setIsPopUpVisible(false)}
        />
      }
    >
      <Button
        theme="primary"
        onClick={addItemToBasket}
        disabled={isItemBeingAdded || isAlreadyAdded}
        loading={isItemBeingAdded}
      >
        {isAlreadyAdded ? (
          <Trans>Added to Basket</Trans>
        ) : (
          <Trans>Add to Basket</Trans>
        )}
      </Button>
    </Popover>
  );
};

export default StockLotAddToBasket;
