import { Trans } from '@lingui/macro';
import * as React from 'react';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import ViewSampleOrdersTableRowType from '../../../../types/Samples/ViewSampleOrdersTableRowType.enum';

interface IProductCellProps {
  order: AugmentedSamplesHistoryOrder;
  rowType: ViewSampleOrdersTableRowType;
}

const ProductCell: React.FunctionComponent<IProductCellProps> = ({
  order,
  rowType,
}) => {
  const isGrouped = rowType === ViewSampleOrdersTableRowType.GROUPED;

  return (
    <DeconstructedTableBodyCell className="product-cell">
      <div className="font-bold">
        {isGrouped ? (
          <Trans>Grouped Order</Trans>
        ) : (
          <>
            <div>{order.brand}</div>
            <div>{order.materialDescription}</div>
          </>
        )}
      </div>
    </DeconstructedTableBodyCell>
  );
};

export default ProductCell;
