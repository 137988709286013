import { Trans } from '@lingui/macro';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import DummyButtons from '../DummyButtons';
import useDummyOrderStore from '../useDummyOrder.store';
import BindingType from './BindingType';
import BindingTypeEnum from './BindingType.enum';
import FormatAndSize from './FormatAndSize';
import { getBindingType } from './SampleFormat.util';
import DummySamplesUserGuide from '../DummySamplesUserGuide';

interface ISampleFormatProps {
  prop?: unknown;
}

const SampleFormat: React.FunctionComponent<ISampleFormatProps> = (props) => {
  const state = useDummyOrderStore();
  const { register, getValues, formState, errors, reset } = useForm({
    mode: 'all',
    defaultValues: {
      width: state.width,
      height: state.height,
      bindingType: getBindingType({
        isStitchedStapled: state.stitchedStapled,
        isNoBindingLoosePages: state.noBindingLoosePages,
        isPerfectBoundGlued: state.perfectBoundGlued,
      }),
    },
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const history = useHistory();
  const next = () => {
    useDummyOrderStore.setState({
      width: getValues('width'),
      height: getValues('height'),
      stitchedStapled:
        getValues('bindingType') === BindingTypeEnum.STICHED_STAPLED,
      noBindingLoosePages:
        getValues('bindingType') === BindingTypeEnum.NO_BINDING_LOOSE_PAGES,
      perfectBoundGlued:
        getValues('bindingType') === BindingTypeEnum.PERFECT_BOUND_GLUED,
    });
    history.push('/samples/createDummy/textPages');
  };
  return (
    <>
      <DummySamplesUserGuide />
      <div style={{ minWidth: '960px' }}>
        <Card header={<Trans>Select your format, size & binding type</Trans>}>
          <form>
            <div className="flex justify-between">
              <FormatAndSize register={register} errors={errors} />
              <BindingType register={register} />
            </div>
          </form>
        </Card>
        <DummyButtons
          onNext={next}
          onNextDisabled={!formState.isValid}
          onPrev={() => history.push('/samples/createDummy')}
        />
      </div>
    </>
  );
};

export default SampleFormat;
