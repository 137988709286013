import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSamplesOrder from '../../../hooks/useSamplesOrder';
import Steps, { Step } from '../../../components/Steps/Steps';
import DummySampleStep from './DummySampleStep.enum';

interface IOrderSamplesProps {
  activeStep: DummySampleStep;
}

const DummySamplesStepper: React.FunctionComponent<IOrderSamplesProps> = ({
  activeStep,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(activeStep);
  const { data: order } = useSamplesOrder();
  const [isStepDisabled, setIsStepDisabled] = useState({
    [DummySampleStep.DESCRIPTION]: false,
    [DummySampleStep.FORMAT]: false,
    [DummySampleStep.TEXT_PAGES]: false,
    [DummySampleStep.COVER_OPTIONS]: false,
    [DummySampleStep.COPIES]: false,
  });

  useEffect(() => {
    setCurrentStep(activeStep);
  }, [activeStep]);

  useEffect(() => {
    setIsStepDisabled({
      [DummySampleStep.DESCRIPTION]: true,
      [DummySampleStep.FORMAT]: true,
      [DummySampleStep.TEXT_PAGES]: true,
      [DummySampleStep.COVER_OPTIONS]: true,
      [DummySampleStep.COPIES]: true,
    });
  }, [order]);

  return (
    <>
      <Steps
        current={currentStep}
        onChange={setCurrentStep}
        labelPlacement="vertical"
        className="ml-auto"
        style={{ width: '700px' }}
      >
        <Step
          subTitle={t`Description`}
          disabled={isStepDisabled[DummySampleStep.DESCRIPTION]}
        />
        <Step
          subTitle={t`Format, Size & Binding`}
          disabled={isStepDisabled[DummySampleStep.FORMAT]}
        />
        <Step
          subTitle={t`Text Pages`}
          disabled={isStepDisabled[DummySampleStep.TEXT_PAGES]}
        />
        <Step
          subTitle={t`Cover Options`}
          disabled={isStepDisabled[DummySampleStep.COVER_OPTIONS]}
        />
        <Step
          subTitle={t`Number of Copiess`}
          disabled={isStepDisabled[DummySampleStep.COPIES]}
        />
      </Steps>
    </>
  );
};

export default DummySamplesStepper;
