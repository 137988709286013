import React from 'react';
import AddPlanItemToBasket from './AddPlanItemToBasket';
import PlannedProductionResultTable from './PlannedProductionResultTable';
import { ResultRow } from './ResultRow.interface';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';

interface IPlanneProductionResultProps {
  data: ResultRow;
  alert: React.ReactNode;
}

const PlanneProductionResult: React.FunctionComponent<IPlanneProductionResultProps> = ({
  data,
  alert,
}) => {
  const { measurementSystem } = useMeasurementSystem();
  return (
    <div className="-mx-4 mb-8 text-gray-dark200 border-gray-light400 border">
      {alert}
      {data?.productionPlansByPlant?.map((plant) => (
        <div key={
          measurementSystem === MeasurementSystem.METRIC
            ? plant[0].productNameMet
            : plant[0].productName
          } className="-mb-6">
          <div className="text-4xl font-medium p-4 pl-7">
            {
              measurementSystem === MeasurementSystem.METRIC
                ? plant[0].productNameMet
                : plant[0].productName
            }
          </div>
          <div className="bg-lavender-pale p-4 border-gray-light400 border-t flex justify-between">
            <PlannedProductionResultTable data={plant} />
            <AddPlanItemToBasket
              data={plant[0]}
              bookweight={
                data.stockAvailabilities && data.stockAvailabilities.length > 0
                  ? data.stockAvailabilities[0].bookweight
                  : plant[0].bookweight
              }
              isContainerExpanded
            />
          </div>
        </div>
      ))}
      <br />
    </div>
  );
};

export default PlanneProductionResult;
