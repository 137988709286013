import { Trans } from '@lingui/macro';
import { Popover } from 'antd';
import React, { useState } from 'react';
import Date from '../../../../components/Date';
import { TrackOrder } from '../../../../types/TrackOrder.interface';
import { SearchOrderDetailsInputs } from '../../SearchOrderDetailsInputs.interface';
import TrackOrderTab from '../../TrackOrderTab.enum';
import ActionsMenuPopover from '../../ViewOrders/Table/ActionsMenuPopover';

interface IOrderDetailsTableHeader {
  order: TrackOrder;
  isNAUser: boolean;
  onNewTabTrigger: (tab: TrackOrderTab, data: SearchOrderDetailsInputs) => void;
}

const OrderDetailsTableHeader: React.FunctionComponent<IOrderDetailsTableHeader> = ({
  order,
  isNAUser,
  onNewTabTrigger,
}) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const confirmedDeliverDate =
    order.scheduleDataArray && order.scheduleDataArray.length > 0 ? (
      <Trans id="orderDetails.see_below">See below</Trans>
    ) : (
      <Date date={order.overview.deliveryDate} />
    );

  return (
    <div className="flex">
      <div className="w-11/12">
        {confirmedDeliverDate && (
          <div className="text-lg font-bold text-blue-pacific">
            <span className="pr-2">
              <Trans>Confirmed Delivery Date</Trans>:
            </span>
            {confirmedDeliverDate}
          </div>
        )}
        <ul className="mt-0 list-none table px-0">
          <li className="table-cell p-4 align-top">
            {order.createDate && (
              <div className="mb-2.5 flex">
                <div className="mr-3 label-colon">
                  <Trans id="order.orderPlaced">Order Date</Trans>
                </div>
                <div className="font-bold">
                  <Date date={order.createDate} />
                </div>
              </div>
            )}
            {order.requestedDeliveryDate && (
              <div className="mb-2.5 flex min-w-max">
                <div className="mr-3 label-colon">
                  <Trans id="order.requestedDeliveryDate">
                    Requested Delivery Date
                  </Trans>
                </div>
                <div className="font-bold">
                  <Date date={order.requestedDeliveryDate} />
                </div>
              </div>
            )}
            {order.latestRevisionDate && (
              <div className="mb-2.5 flex">
                <div className="mr-3 label-colon">
                  <Trans id="order.latestRevisionDate">
                    Last Date of Revision
                  </Trans>
                </div>
                <div className="font-bold">
                  <Date date={order.latestRevisionDate} />
                </div>
              </div>
            )}
            {order.overview.deliveryDateUpd && (
              <div className="mb-2.5 flex">
                <div className="mr-3 label-colon">
                  <Trans id="order.deliveryDateUpd">
                    Updated Delivery Date
                  </Trans>
                </div>
                <div className="font-bold">
                  <Date date={order.overview.deliveryDateUpd} />
                </div>
              </div>
            )}
          </li>
          {order.soldToName && (
            <li className="table-cell p-4 align-top">
              <div className="mb-2.5">
                <div className="mb-2.5 label-colon">
                  <Trans id="orderDetails.soldTo">Customer Name</Trans>
                </div>
                <div className="font-bold">{order.soldToName}</div>
              </div>
            </li>
          )}
          {(order?.overview?.shipToPartyName ||
            order?.overview?.shipToPartyCity) && (
            <li className="table-cell p-4 align-top">
              <div>
                <div className="mb-2.5 label-colon">
                  <Trans id="orderDetails.shippedTo">Delivery Location</Trans>
                </div>
                <div className="font-bold">
                  {order.overview && (
                    <span>
                      {order.overview.shipToPartyName}/
                      {order.overview.shipToPartyCity}
                    </span>
                  )}
                </div>
              </div>
            </li>
          )}

          {isNAUser && order.pobName && (
            <li className="table-cell p-4 align-top">
              <div>
                <div className="mb-2.5 label-colon">
                  <Trans id="orderDetails.pieceOfBusiness">
                    Piece of Business
                  </Trans>
                </div>
                <div className="font-bold">{order.pobName}</div>
              </div>
            </li>
          )}
          {!isNAUser && order.overview.decisionMakerName && (
            <li className="table-cell p-4 align-top">
              <div>
                <div className="mb-2.5 label-colon">
                  <Trans id="orderDetails.decisionMaker">Decision Maker</Trans>
                </div>
                <div className="font-bold">
                  {order.overview.decisionMakerName}
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
      <div className="mt-7">
        <div className="ml-0 list-none flex divide-x divide-gray-300">
          <Popover
            placement="leftTop"
            visible={popoverVisible}
            content={
              <ActionsMenuPopover
                order={order}
                onClick={() => {
                  setPopoverVisible(false);
                }}
                onNewTabTrigger={onNewTabTrigger}
                isOrderDetailsPage
              />
            }
          >
            <div className="pr-3.5 text-2xl text-blue-pacific">
              <button
                className="fa fa-bars available-actions__icon"
                onClick={() => {
                  setPopoverVisible(!popoverVisible);
                }}
              >
                <span />
              </button>
            </div>
          </Popover>
          <div className="pl-3 text-2xl text-blue-pacific">
            <button
              className="fa fa-print reset-button"
              onClick={() => window.print()}
            >
              <span />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsTableHeader;
