import {t, Trans } from '@lingui/macro';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import NonPaperValue from '../../types/NonPaperValue.interface';
import NonPaperValueEntry from '../../types/NonPaperValueEntry';
import { ClaimTypes } from '../../types/ClaimTypes.enum';
import { getNPValOptions } from '../../services/Batch';

interface INonPaperValueProps {
    id: number;
    formMethods: UseFormMethods;
    nonPaperItems?: NonPaperValue[];
    nonPaperValueEntries?: NonPaperValueEntry[];
    chosenClaimType: ClaimTypes;
    onValueChange: (params: any) => any;
    onItemChange: (params: any) => any;
    onQuantityChange: (params:any) => any;
  }

  const NonPaperItem: React.FunctionComponent<INonPaperValueProps> = ({
    id,
    formMethods,
    nonPaperItems,
    nonPaperValueEntries,
    chosenClaimType,
    onValueChange,
    onItemChange,
    onQuantityChange,
  }) => {
    const { register } = formMethods;
    const handleValueChange = (e: any) => {
      nonPaperValueEntries?.map(np => {
        if(np.id === id){
          np.npValue = e.target.value;
        }
        return nonPaperValueEntries;
      })
      onValueChange(nonPaperValueEntries);
    };

    const handleQuantityChange = (e: any) => {
      nonPaperValueEntries?.map(np => {
        if(np.id === id){
          np.npQuantity = e.target.value;
        }
        return nonPaperValueEntries;
      })
      onQuantityChange(nonPaperValueEntries);
    };

    const handleItemChange = (e: any) => {
      nonPaperValueEntries?.map(np => {
        if(np.id === id){
          np.npItem = e.target.value;
        }
        return nonPaperValueEntries;
      })
      onItemChange(nonPaperValueEntries);
    };

    return (
    <>
        {(chosenClaimType === ClaimTypes.QUALITY) && (
          <>
            <div className="flex items-center py-3">
              <div className="w-1/8">
                <InputLabel
                  size="lg"
                  style={{ width: '400px' }}
                  text={
                    <strong>
                      <Trans>Non Paper Item (UOM)</Trans>
                    </strong>
                  }
                >
                  <Select
                    name="npItem"
                    ref={register()}
                    options={
                      getNPValOptions(nonPaperItems)
                    }
                    data-test-id="npv-dropdown"
                    onChange={handleItemChange}
                  />
                </InputLabel>
              </div>
              <div className="w-32">
                <InputLabel
                  size="lg"
                  text={
                    <strong>
                      <Trans>Quantity</Trans>
                    </strong>
                  }
                >
                  <Input
                    name="npQuantity"
                    ref={register()}
                    placeholder={t`Enter Qty`}
                    width="full"
                    defaultValue="0"
                    onChange={handleQuantityChange}
                  />
                </InputLabel>
              </div>
              <div className="w-32 px-6">
                <InputLabel
                  size="lg"
                  text={
                    <strong>
                      <Trans>Value(Ea.)</Trans>
                    </strong>
                  }
                >
                  <Input
                    name="npValue"
                    ref={register()}
                    placeholder={t`Enter Amount`}
                    width="full"
                    defaultValue="0"
                    onChange={handleValueChange}
                  />
                </InputLabel>
              </div>
            </div>
          </>
        )}
    </>
  )};
  export default NonPaperItem;