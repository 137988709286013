import * as React from 'react';
import SearchPopoverContent from './SearchPopoverContent';

interface ISearchPanelProps {
  title: string | React.ReactNode;
  onClose: () => void;
  body: string | React.ReactNode;
  button: React.ReactNode;
  visible: boolean;
}

const SearchPanel: React.FunctionComponent<ISearchPanelProps> = ({
  title,
  onClose,
  body,
  button,
  visible,
}) => {
  return (
    <div style={{ display: visible ? 'block' : 'none' }}>
      <SearchPopoverContent
        title={title}
        body={body}
        button={button}
        onClose={onClose}
      />
    </div>
  );
};

export default SearchPanel;
