import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Date from '../../components/Date';
import Modal from '../../components/Modal/Modal';
import Table from '../../components/Table/Table';
import {
  deleteComplaintAttachment,
  downloadAttachment,
  getComplaintAttachments,
} from '../../services/Complaints';
import { ComplaintAttachment } from '../../types/ComplaintAttachment.interface';
import QueryCacheName from '../../types/QueryCacheName.enum';

interface IAttachmentModalProps {
  salesDocumentNumber: string;
  complaintNumber: string | null;
  customerNumber?: string;
  visible: boolean;
  onCancel: () => void;
}

const AttachmentModal: React.FunctionComponent<IAttachmentModalProps> = ({
  salesDocumentNumber,
  complaintNumber,
  customerNumber,
  visible,
  onCancel,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const queryCache = useQueryCache();
  const { data: attachments } = useQuery(
    [QueryCacheName.COMPLAINTS_ATTACHMENTS, complaintNumber],
    (key, number: string) => getComplaintAttachments(number),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      enabled: complaintNumber && visible,
    }
  );

  const onFileClick = async (attachment: ComplaintAttachment) => {
    try {
      setErrorMessage('');
      await downloadAttachment(attachment.salesDocument, attachment.documentId);
    } catch (exception) {
      setErrorMessage(t`There was an error downloading the attachment.`);
    }
  };

  const onDeleteAttachment = async (attachment: ComplaintAttachment) => {
    try {
      // eslint-disable-next-line no-alert
      if (window.confirm(t`Are you sure you want to delete this attachment?`)) {
        setErrorMessage('');
        await deleteComplaintAttachment(
          attachment.salesDocument,
          attachment.documentId
        );
        queryCache.invalidateQueries(QueryCacheName.COMPLAINTS_ATTACHMENTS);
        queryCache.invalidateQueries(QueryCacheName.COMPLAINTS);
      }
    } catch (exception) {
      setErrorMessage(t`There was an error deleting the attachment.`);
    }
  };

  const navigateToAddAttachment = () => {
    const fileNumber = (attachments?.numberOfAttachments || 0) + 1;
    history.push(
      `/reporting/claimReport/addAttachment/${complaintNumber}/${fileNumber}/${customerNumber}`
    );
    onCancel();
  };

  const columns: ColumnsType<ComplaintAttachment> = [
    {
      title: <Trans>Date</Trans>,
      key: 'date',
      render: (value, record) => <Date date={record.creationDate} />,
    },
    {
      title: <Trans>File Name</Trans>,
      key: 'fileName',
      render: (value, record) => (
        <Button theme="link" onClick={() => onFileClick(record)}>
          {record.description}.{record.documentClassCode}
        </Button>
      ),
    },
    {
      key: 'delete',
      render: (value, record) => (
        <Button
          theme="link"
          className="text-red-red"
          onClick={() => onDeleteAttachment(record)}
        >
          <Trans>Delete Attachment</Trans>
        </Button>
      ),
    },
  ];
  return (
    <Modal
      visible={visible}
      title={`${
        attachments?.numberOfAttachments
      } ${t`Attachment(s)`} - ${salesDocumentNumber}`}
      onCancel={onCancel}
      width="850px"
      footer={
        <Button theme="primary" onClick={() => navigateToAddAttachment()}>
          <Trans>Add attachment to claim</Trans>
        </Button>
      }
    >
      <Table
        columns={columns}
        dataSource={attachments?.attachments}
        pagination={false}
        locale={{
          emptyText: (
            <div className="text-gray-600">
              <Trans>No Attachments</Trans>
            </div>
          ),
        }}
      />
      <div className="text-red-red mt-4 ml-4">{errorMessage}</div>
    </Modal>
  );
};

export default AttachmentModal;
