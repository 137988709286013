import { t } from '@lingui/macro';
import React, { FC } from 'react';
import trackAndTraceLanderImage from '../../assets/sappi-track-and-trace-lander-bg.jpg';
import LanderContentRender from '../../components/LanderContent/LanderContentRender';
import { LanderContent as ILanderContent } from '../../types/LanderContent.interface';

interface ILanderContentProps {
  className?: string;
}

const LanderContent: FC<ILanderContentProps> = ({ className = '' }) => {
  const content: ILanderContent = {
    body: t`Simply enter one or more fields using the Track And Trace selection and click on "Report Deviation" or "Report Arrival".`,
    header: t`Use our Track And Trace tool to report a delayed delivery or to confirm an arrival.`,
    id: 0,
    image: trackAndTraceLanderImage,
    language: 'EN',
    title: '',
  };
  return (
    <div className={className}>
      <LanderContentRender content={content} />
    </div>
  );
};

export default LanderContent;
