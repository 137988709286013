import { useMemo } from 'react';
import track from '../services/Analytics';
import { AnalyticsEventProps } from '../services/Analytics/AnalyticsEventProps.interface';
import AnalyticsEventType from '../services/Analytics/AnalyticsEventType.enum';
import useProperties from './useProperties';
import useUser from './useUser';

interface AnalyticsMethods {
  trackPageView: (category: string, page: string, extra?: Extras) => void;
  trackLinkClick: (linkName: string, category: string, extra: string) => void;
  trackLogin: (email: string, username: string) => void;
}

interface Extras {
  customerId?: string;
  username?: string;
  email?: string;
}
const NAMESPACE = 'ECOMM2:'; // Default string to prepend to an event's 'category' and 'page' parameters.

const useAnalytics = (): AnalyticsMethods => {
  const { data: properties } = useProperties();
  const { data: user } = useUser();
  const trackPageView = useMemo(
    () => (category: string, page: string, extra?: Extras) => {
      // Extra will add
      const trackParams: AnalyticsEventProps = {
        category: `${NAMESPACE}${category}`,
        subcategory: `${NAMESPACE}${page}`,
        environment: properties ? properties.environment : 'NONE DEFINED',
      };
      // trackParams.environment = 'dev';
      if (extra) {
        // The following pages have customer id info.
        switch (page) {
          case 'ADD_TO_BASKET':
          case 'ALTERNATIVES':
          case 'APPLY_REEL_FILTER':
          case 'APPLY_SHEET_FILTER':
          case 'AVAILABLE_ACTIONS':
          case 'BOOK_ORDER':
          case 'COMBINE_SHIPMENT':
          case 'CLEAR_SELECTED':
          case 'COLLAPSE_ALL':
          case 'COMPLAINT_VIEW_ATTACH':
          case 'COMPLAINT_VIEW_DESC':
          case 'CONTINUE_SHOPPING':
          case 'CONTINUE_CHECKOUT':
          case 'CREATE_PDF':
          case 'DELIVERY_DETAILS':
          case 'DOWNLOAD_DOCUMENTS':
          case 'DOWNLOAD_FINANCIALS':
          case 'DOWNLOAD_PACKING_LIST':
          case 'EXCEL_DOWNLOAD':
          case 'EXPAND_ALL':
          case 'FILE_COMPLAINT':
          case 'INVOICE_DETAILS':
          case 'OPEN_ROLL_TAB':
          case 'OPEN_SHEET_TAB':
          case 'ORDER_SIMILAR':
          case 'PRINTING_DOCUMENTS':
          case 'PRINT_WEIGHT_LIST':
          case 'REMOVE_ITEM':
          case 'REQUEST_BOL':
          case 'REQUEST_DELIVERY_DETAILS':
          case 'REQUEST_INVOICE':
          case 'SAVE_ENQUIRY_RESERVE':
          case 'SEARCH':
          case 'SELECT_CUSTOMER':
          case 'SEND_RESERVATION':
          case 'SHIPMENT_DETAILS':
          case 'SKU_LOOKUP':
          case 'SKU_SEARCH':
          case 'SHOW_AVAILABILITY':
          case 'SHOW_PRODUCT':
          case 'SUBMIT_ALL':
          case 'SUBMIT_COMPLAINT':
          case 'UPDATE_BASKET':
          case 'UPDATE_ITEM':
          case 'VIEW_BASKET':
          case 'VIEW_ORDER_DETAILS':
            trackParams.customerId = extra.customerId;
            break;
          default:
            trackParams.customerId = '';
        }
        if (user) {
          trackParams.email = user.email;
          trackParams.userId = user.username;
          trackParams.site = NAMESPACE;
        } else {
          if (page === 'LOGIN') {
            trackParams.email = extra.email;
            trackParams.userId = extra.username;
          } else {
            trackParams.email = '';
            trackParams.userId = '';
          }
          trackParams.site = NAMESPACE;
        }
      } else if (user) {
        trackParams.email = user.email;
        trackParams.userId = user.username;
        trackParams.site = NAMESPACE;
      } else {
        trackParams.email = '';
        trackParams.userId = '';
        trackParams.site = NAMESPACE;
      }
      track(AnalyticsEventType.PAGE_VIEW, trackParams);
    },
    [properties, user]
  );

  const trackLinkClick = (linkName: string, category: string) => {
    trackPageView(category, linkName);
  };

  const trackLogin = (email: string, username: string) => {
    trackPageView('ADMIN', 'LOGIN', { email, username });
  };
  return {
    trackPageView,
    trackLinkClick,
    trackLogin,
  };
};

export default useAnalytics;
