import { Trans } from '@lingui/macro';
import React from 'react';
import { Space } from 'antd';
import { UseFormMethods } from 'react-hook-form';
import { Moment } from 'moment';
import { Button } from '../../../components/Button/Button';
import { resetDate } from '../PrintDocuments.util';
import useDashboardStore, {
  DEFAULT_SEARCH_DOCUMENT_INPUTS,
} from '../../Dashboard/useDashboard.store';
import { anyInputExists } from './SearchDocuments.util';

interface ISearchButtonsProp {
  isWidget?: boolean;
  isInputValid: boolean;
  isDateFromAfterDateTo: boolean;
  clearErrors: UseFormMethods['clearErrors'];
  reset: UseFormMethods['reset'];
  setValue: UseFormMethods['setValue'];
  dateFromValueWatch: Moment | undefined;
  dateToValueWatch: Moment | undefined;
  watchFields: {
    customerOrderNumberFrom?: string | undefined;
    documentNumberFrom?: string | undefined;
    invoiceNumberFrom?: string | undefined;
  };
  setIsInputValid: (isInputValid: boolean) => void;
}

const SearchButtons: React.FunctionComponent<ISearchButtonsProp> = ({
  isWidget = false,
  isInputValid,
  isDateFromAfterDateTo,
  clearErrors,
  reset,
  setValue,
  dateFromValueWatch,
  dateToValueWatch,
  watchFields,
  setIsInputValid,
}) => {
  const { setSearchDocumentInputs } = useDashboardStore();

  return (
    <div className={`flex items-center ${isWidget ? 'pt-8 pb-12' : 'px-5'}`}>
      <div className={`${isWidget ? '' : 'flex'}`}>
        <Space size="small">
          <Button
            type="submit"
            theme="primary"
            disabled={
              isDateFromAfterDateTo ||
              (isWidget &&
                !anyInputExists(
                  watchFields,
                  dateFromValueWatch,
                  dateToValueWatch
                ))
            }
          >
            <Trans>Search Documents</Trans>
          </Button>
          <span className="px-2" />
          <Button
            type="reset"
            theme="link"
            className="text-lg font-bold"
            onClick={() => {
              resetDate(setValue);
              clearErrors();
              reset();

              setIsInputValid(true);
              setSearchDocumentInputs(DEFAULT_SEARCH_DOCUMENT_INPUTS);
            }}
          >
            <Trans>Reset</Trans>
          </Button>
        </Space>
        {!isInputValid && (
          <div className="pt-2" style={{ maxWidth: '435px' }}>
            <label
              className={`inline-block leading-4 text-red-600 w-1/3 h-11 min-width-490 ${
                isWidget ? '' : 'px-5'
              }`}
            >
              <Trans>
                To perform a search of available documents, please enter{' '}
                <strong>at minimum</strong> a Document Number{' '}
                <strong>or</strong> a Date Search Parameter.
              </Trans>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchButtons;
