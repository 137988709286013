import { TrackAndTraceData } from '../types/TrackAndTraceData.interface';
import { ArrivalForm } from './ArrivalForm.interface';

export const getShownResults = (
  data: TrackAndTraceData[],
  formValues: ArrivalForm
): TrackAndTraceData[] => {
  const changedData = formValues.arrivalRow
    ?.map((item, index) =>
      item.newArrivalDate
        ? {
            ...data[index],
            newArrivalDate: item.newArrivalDate,
            newDepartureDate: item.newDepartureDate,
          }
        : undefined
    )
    .filter((item) => item !== undefined) as TrackAndTraceData[];
  return changedData;
};
