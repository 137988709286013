import { filter } from 'lodash';
import ExistingComplaint from '../../types/ExistingComplaint.interface';
import { ReturnMessage } from '../../types/ReturnMessage.interface';

export const getTotalClaimValue = (
  claimValue?: number,
  additionalClaimValue?: number
): string => {
  const totalClaimValue = (claimValue || 0) + (additionalClaimValue || 0);
  return `${totalClaimValue}`;
};

export const getTotalClaimValueFromString = (
  isEuUser: boolean,
  claimValue?: string,
  additionalClaimValue?: string
): string => {
  const totalClaimValue =
    +(claimValue || '').replace(',', '.') +
    +(additionalClaimValue || '').replace(',', '.');

  const fixedTotalClaimValue = parseFloat(totalClaimValue.toFixed(3));

  return isEuUser
    ? `${fixedTotalClaimValue}`.replace('.', ',')
    : `${fixedTotalClaimValue}`;
};

export const getComment = (
  complaintNumber: string,
  complaint?: ExistingComplaint
): string[] => {
  const comment:string[] = [];
  complaint?.complaintComments.forEach((c) => {
    if (complaintNumber === c.complaintNumber) {
      comment.push(c.comment);
    }
  });

  return comment;
};

export const getReturnMessage = (
  complaintNumber: string,
  complaint?: ExistingComplaint
): ReturnMessage | undefined => {
  const returnMessage = filter(
    complaint?.returnMessage,
    (c) => c.complaintNumber === complaintNumber
  );

  return returnMessage ? returnMessage[0] : undefined;
};
