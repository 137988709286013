import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { isEmpty } from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { object, SchemaOf, string, TestContext } from 'yup';
import { Button } from '../../../components/Button/Button';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import TagInput from '../../../components/TagInput/TagInput';
import {
  addToStringifiedArray,
  getParsedValue,
  getStringifiedValue,
} from '../../../components/TagInput/TagInput.util';
import { validateSappiSkuOrMatlNoInput } from '../../../services/SappiSku';
import PutUpKwd from '../../../types/PutUpKwd.enum';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import useAvailabilityGrammageOptions from '../hooks/useAvailabilityGrammageOptions';
import useBasisWeightOptions from '../hooks/useBasisWeightOptions';
import useBrandOptions from '../hooks/useBrandOptions';
import useCaliperOptions from '../hooks/useCaliperOptions';
import useFinishOptions from '../hooks/useFinishOptions';
import useCustomerOptions from '../hooks/useCustomerOptions';
import SkuLookupModal from '../SkuLookupModal/SkuLookupModal';
import useProductAvailabilityStore from '../useProductAvailabilityStore.store';
import { NonReleaseWidgetForm } from './NonReleaseWidgetForm.interface';
import useUser from '../../../hooks/useUser';
import Region from '../../../types/Region.enum';


function oneOfRequired(this: TestContext<Record<string, string>>): boolean {
  return (
    this.parent.brand ||
    this.parent.finish ||
    this.parent.grammage ||
    this.parent.basisWeight ||
    this.parent.caliper ||
    this.parent.sappiSkusValues
  );
}

const schema: SchemaOf<NonReleaseWidgetForm> = object().shape({
  customerSoldTo: string().required(),
  brand: string().test('oneOfRequired', '', oneOfRequired),
  finish: string().test('oneOfRequired', '', oneOfRequired),
  grammage: string().test('oneOfRequired', '', oneOfRequired),
  basisWeight: string().test('oneOfRequired', '', oneOfRequired),
  caliper: string().test('oneOfRequired', '', oneOfRequired),
  sappiSkus: string(),
  sappiSkusValues: string().test('oneOfRequired', '', oneOfRequired),
});

const NonReleaseProductAvailabilityWidget: FC = () => {
  const initialState = useProductAvailabilityStore();
  const history = useHistory();
  const hasPopulatedDefaults = useRef(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState,
  } = useForm<NonReleaseWidgetForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      customerSoldTo: '',
      finish: '',
      grammage: '',
      brand: '',
      caliper: '',
      sappiSkusValues: getStringifiedValue(initialState.skuCodes),
    },
  });
  const { isValid } = formState;
  const [skuLookupModalVisible, setSkuLookupModalVisible] = useState(false);
  
  const caliper = watch('caliper');
  const grammage = watch('grammage');
  const finish = watch('finish');
  const customer = watch('customerSoldTo');
  const product = watch('brand');
  const sappiSkusValues = watch('sappiSkusValues');
  const { isMetric } = useMeasurementSystem();
  const availFlag = 'X';
  const { data: user } = useUser();
  const isEuUser = user?.region === Region.EU;
  const { customerOptions, isFetchingUser } = useCustomerOptions();
  const { brandOptions } = useBrandOptions(customer, grammage, caliper, isMetric, availFlag);
  const { finishOptions } = useFinishOptions(customer, product, availFlag);
  const { caliperOptions } = useCaliperOptions(customer, product, grammage, availFlag);
  const { basisWeightOptions } = useBasisWeightOptions(
    customer,
    product,
    caliper,
    availFlag
  );
  const { grammageOptions } = useAvailabilityGrammageOptions(
    customer,
    product,
    caliper,
    availFlag
  );

  // Initial population of customer dropdown
  useEffect(() => {
    if (!isEmpty(customerOptions) && !isFetchingUser) {
      setValue(
        'customerSoldTo',
        initialState.customerSoldTo || customerOptions[0].value
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingUser, customerOptions]);

  // Initial population of other dropdowns
  useEffect(() => {
    if (brandOptions.length > 1 && !hasPopulatedDefaults.current) {
      reset({
        ...getValues(),
        grammage: initialState.grammage,
        finish: initialState.finish,
        brand: initialState.brand,
        caliper: initialState.caliper ? String(initialState.caliper) : '',
      });
      hasPopulatedDefaults.current = true;
    }
  }, [getValues, reset, initialState, brandOptions]);

  // on change of sappiSkuValues
  useEffect(() => {
    if (sappiSkusValues) {
      reset({
        ...getValues(),
        basisWeight: '',
        grammage: '',
        finish: '',
        brand: '',
        caliper: '',
      });
    }
  }, [sappiSkusValues, reset, getValues]);

  const clearAllSappiSkus = () => {
    reset({
      ...getValues(),
      sappiSkus: '',
      sappiSkusValues: '',
    });
  };

  const doReset = () => {
    reset({
      customerSoldTo: customerOptions[0].value as string,
      caliper: '',
      brand: '',
      finish: '',
      grammage: '',
      sappiSkus: '',
      sappiSkusValues: '',
    });
  };

  const doSubmit = (data: NonReleaseWidgetForm) => {
    useProductAvailabilityStore.setState({
      putUps: [
        { putUp: PutUpKwd.ROLL },
        { putUp: PutUpKwd.SHEETER_REEL },
        { putUp: PutUpKwd.SHEET },
      ],
      customerSoldTo: data.customerSoldTo,
      brand: data.brand,
      finish: data.finish,
      caliper: data.caliper ? +data.caliper : undefined,
      grammage: data.grammage,
      skuCodes: getParsedValue(data.sappiSkusValues || ''),
      isAvailabilityPlanning: true,
    });
    history.push('/availabilityPlanning');
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-10">
        <Space direction="vertical" className="w-full">
          <InputLabel text={t`Customer`} required size="lg">
            <Select
              name="customerSoldTo"
              ref={register()}
              options={customerOptions}
              required
            />
          </InputLabel>
          <div className="grid grid-cols-2 gap-3">
            <InputLabel text={t`Product`} size="lg">
              <Select
                name="brand"
                ref={register()}
                options={brandOptions}
                disabled={!!sappiSkusValues}
              />
              </InputLabel>
              <InputLabel text={t`Finish`} size="lg">
                <Select
                  name="finish"
                  ref={register()}
                  options={finishOptions}
                  disabled={!!sappiSkusValues}
                />
              </InputLabel>
            <InputLabel text={t`Caliper`} size="lg">
              <Select
                name="caliper"
                ref={register()}
                options={caliperOptions}
                disabled={!!sappiSkusValues}
              />
            </InputLabel>
            {isMetric && (
              <InputLabel text={t`Grammage`} size="lg">
                <Select
                  name="grammage"
                  ref={register()}
                  options={grammageOptions}
                  disabled={!!sappiSkusValues}
                />
              </InputLabel>
            )}
            {!isMetric && (
              <InputLabel text={t`Basis Weight`} size="lg">
                <Select
                  name="grammage"
                  ref={register()}
                  options={basisWeightOptions}
                  disabled={!!sappiSkusValues}
                />
              </InputLabel>
            )}
          </div>
        </Space>
          <Space direction="vertical" size="middle">
          {!isEuUser && (
            <Button
              theme="link"
              onClick={() => setSkuLookupModalVisible(true)}
              className="text-lg no-underline hover:opacity-60 float-right"
            >
              <Trans>Sappi SKU Lookup</Trans>
            </Button>
          )}
            <div>
              <InputLabel size="lg" text={t`Enter Sappi SKU or Customer Material Number`}>
                <TagInput
                  name="sappiSkus"
                  register={register}
                  reset={reset}
                  getValues={getValues}
                  watch={watch}
                  formState={formState}
                />
              </InputLabel>
                <div className="w-full text-right">
                  <Button theme="link" onClick={clearAllSappiSkus}>
                    <Trans>Clear All SKUs or Customer Material No.s</Trans>
                  </Button>
                </div>
            </div>
          </Space>
      </div>
      <div className="w-full text-right">
        <Space size="large">
          <Button
            type="submit"
            theme="primary"
            disabled={!isValid || !hasPopulatedDefaults.current}
            onClick={handleSubmit(doSubmit)}
          >
            <Trans>Show Availability</Trans>
          </Button>
          <Button
            type="reset"
            theme="link"
            className="text-lg font-bold"
            onClick={() => doReset()}
          >
            <Trans>Reset</Trans>
          </Button>
        </Space>
      </div>
      <SkuLookupModal
        visible={skuLookupModalVisible}
        customer={customer || ''}
        onCancel={() => setSkuLookupModalVisible(false)}
        updateCustomer={(newCustomer) => {
          setValue('customerSoldTo', newCustomer);
        }}
        addSappiSkus={(skus) => {
          setValue(
            'sappiSkusValues',
            addToStringifiedArray(getValues().sappiSkusValues || '', skus),
            { shouldDirty: true }
          );
        }}
      />
    </div>
  );
};

export default NonReleaseProductAvailabilityWidget;
