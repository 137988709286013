import React, { FC } from 'react';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import EuBasketForm from './EuBasketForm';

interface IEuOrderBookingBasketsProps {
  baskets: AvailabilityBasketEU[] | undefined;
  refetchBaskets: () => void;
}
const EuOrderBookingBaskets: FC<IEuOrderBookingBasketsProps> = ({
  baskets,
  refetchBaskets,
}) => {
  return (
    <>
      {baskets?.map((basket) => (
        <EuBasketForm
          loadedBasket={basket}
          key={basket.soldTo.number}
          refetchBaskets={refetchBaskets}
        />
      ))}
    </>
  );
};

export default EuOrderBookingBaskets;
