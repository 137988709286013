import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import moment from 'moment';
import { Button } from '../../../../components/Button/Button';
import useRole from '../../../../hooks/useRole';
import useUser from '../../../../hooks/useUser';
import {
  getBrandOptions,
  getBrands,
  getCaliperOptions,
  getBaseWeightOptions,
  getAvailabilityGrammageOptions,
} from '../../../../services/Brand';
import { IGetConsignmentInventoryParams } from '../../../../services/Consignment';
import { deriveCustomerOptions } from '../../../../services/Customer';
import {
  EMPTY_OPTION,
  EMPTY_OPTION_ANY,
  EMPTY_OPTION_SELECT,
} from '../../../../services/Util/emptyOption.const';
import Brand from '../../../../types/Brand.interface';
import Option from '../../../../types/Option.type';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import FormLeftHalf from './FormLeftHalf';
import FormRightHalf from './FormRightHalf';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import { DocumentDateTypes } from '../../../../types/DocumentDateTypes.interface';
import { PARAM_FORMAT } from '../../../../components/DatePicker/DatePicker';

interface ISearchFormData {
  soldToCustomerNumber: string;
  customerPurchaseOrder: string;
  custMatSku: string;
  documentNumber: string;
  inStock: boolean;
  inTransit: boolean;
  onOrder: boolean;
  invoiced: boolean;
  product: string;
  caliper?: string;
  caliperMils?: string;
  grammage?: string;
  widthMin?: string;
  widthMax?: string;
  widthMinInches?: string;
  widthMaxInches?: string;
  olderThan: string;
  productType: string;
  bookweight: string;
  lengthMin?: string;
  lengthMax?: string;
  lengthMinInches?: string;
  lengthMaxInches?: string;
  invoiceNumber?: string;
  invoicedDateFrom?: string;
  invoicedDateTo?: string;
}

interface ISearchCriteriaProps {
  onSearch: (queryParams: IGetConsignmentInventoryParams) => void;
}

const SearchCriteria: React.FC<ISearchCriteriaProps> = ({ onSearch }) => {
  const { data: user } = useUser();
  const { isEuUser, isNaUser, isNonReleaseEuUser } = useRole();
  const { isMetric } = useMeasurementSystem();

  const defaultFormValues: ISearchFormData = useMemo(
    () => ({
      soldToCustomerNumber: '',
      customerPurchaseOrder: '',
      custMatSku: '',
      documentNumber: '',
      inStock: false,
      inTransit: false,
      onOrder: false,
      invoiced: false,
      product: '',
      caliper: '',
      caliperMils: '',
      grammage: '',
      widthMin: '',
      widthMax: '',
      widthMinInches: '',
      widthMaxInches: '',
      olderThan: '',
      productType: '',
      bookweight: '',
      lengthMin: '',
      lengthMax: '',
      lengthMinInches: '',
      lengthMaxInches: '',
      invoiceNumber: '',
      invoicedDateFrom: '',
      invoicedDateTo: '',
    }),
    []
  );

  const {
    errors,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    control,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: defaultFormValues,
  });
  const { isValid } = formState;
  const soldToCustomerNumberWatch = watch('soldToCustomerNumber');
  const inStockWatch = watch('inStock');
  const inTransitWatch = watch('inTransit');
  const onOrderWatch = watch('onOrder');
  const productWatch = watch('product');
  const invoicedWatch = watch('invoiced');
  const caliperWatch = watch('caliper');
  const caliperMilsWatch = watch('caliperMils');
  const grammageWatch = watch('grammage');
  const invoicedDateFromWatch = watch('invoicedDateFrom');
  const invoicedDateToWatch = watch('invoicedDateTo');

  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [caliperOptions, setCaliperOptions] = useState<Option[]>([]);
  const [bookWeightOptions, setBookWeightOptions] = useState<Option[]>([]);
  const [grammageOptions, setGrammageOptions] = useState<Option[]>([]);

  useEffect(() => {
    // When the selected customer is changed, reset the values of the product
    // and caliper fields .
    setValue('product', defaultFormValues.product, { shouldValidate: true });
    if (isMetric){
      setValue('caliperMils', defaultFormValues.caliperMils, { 
        shouldValidate: true,
      });
      setValue('grammage', defaultFormValues.grammage, { shouldValidate: true });
    }
    else{
      setValue('caliper', defaultFormValues.caliper, { shouldValidate: true });
      setValue('bookweight', defaultFormValues.bookweight, {
        shouldValidate: true,
      });
    }
  }, [soldToCustomerNumberWatch, setValue, defaultFormValues, isMetric]);

  useEffect(() => {
    // Reset the value of the caliper and basis weight fields when 
    // the selected product is changed (basis weight values are converted to grammage for FM)
    if (isMetric){
      setValue('caliperMils', defaultFormValues.caliperMils, { 
        shouldValidate: true,
      });
      setValue('grammage', defaultFormValues.bookweight, { shouldValidate: true});
    }
    else{
      setValue('caliper', defaultFormValues.caliper, { shouldValidate: true });
      setValue('grammage', defaultFormValues.bookweight, {
        shouldValidate: true,
      });
    }
  
  }, [productWatch, setValue, defaultFormValues, isMetric]);

  useEffect(() => {
    if (user) {
      const customers = user?.customers || [];

      const consignmentCustomers = customers.filter(
        (customer) => customer.consignment
      );

      const derivedCustomerOptions = deriveCustomerOptions(
        consignmentCustomers,
        isNonReleaseEuUser,
        isEuUser,
        true
      );

      setCustomerOptions([EMPTY_OPTION, ...derivedCustomerOptions]);
    }
  }, [user, isEuUser, isNonReleaseEuUser]);
  
  useEffect(() => {
    if(invoicedWatch){
      setValue('inStock', defaultFormValues.inStock, { shouldValidate: true});
      setValue('inTransit', defaultFormValues.inTransit, { shouldValidate: true});
      setValue('onOrder', defaultFormValues.onOrder, { shouldValidate: true});
    }
  }, [invoicedWatch, setValue, defaultFormValues])

  // Brands (which drive product options and caliper options) update upon
  // choosing a customer.
  const { data: brands, isFetching: fetchingBrands } = useQuery(
    [
      QueryCacheName.BRANDS,
      {
        customer: soldToCustomerNumberWatch,
        availabilityFlag: 'X',
      },
    ],
    getBrands,
    {
      enabled: soldToCustomerNumberWatch,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setProductOptions(
      getBrandOptions(brands as Brand[], {
        defaultSelectLabel: EMPTY_OPTION_SELECT.label,
      })
    );
    setCaliperOptions(
      getCaliperOptions(brands as Brand[], {
        defaultSelectLabel: EMPTY_OPTION_ANY.label,
        isMetric,
      })
    );
  }, [brands, isMetric]);

  useEffect(() => {
    // Product, Caliper and basis weight options update bi-directionally based on the selection of the other 2 options.
    if(isMetric){
      setProductOptions(
        getBrandOptions(brands as Brand[], {
          selectedCaliper: String(caliperWatch) || undefined,
          selectedGrammage: grammageWatch || undefined,
          defaultSelectLabel: EMPTY_OPTION_SELECT.label,
        })
      );
      
      setCaliperOptions(
        getCaliperOptions(brands as Brand[], {
          selectedBrand: productWatch || undefined,
          selectedGrammage: grammageWatch || undefined,
          defaultSelectLabel: EMPTY_OPTION_SELECT.label,
          isMetric,
        })
      );
      
      setGrammageOptions(
        getAvailabilityGrammageOptions(
          brands as Brand[],
          {
            selectedBrand: productWatch || undefined,
            selectedCaliper: String(caliperMilsWatch),
            defaultSelectLabel: EMPTY_OPTION_SELECT.label,
          }
        )
      );
    }
    else {
      // basis weight option values are converted to grammage for FM consistency
      setProductOptions(
        getBrandOptions(brands as Brand[], {
          selectedCaliper: String(caliperWatch) || undefined,
          selectedGrammage: grammageWatch || undefined,
          defaultSelectLabel: EMPTY_OPTION_SELECT.label,
        })
      );
      
      setCaliperOptions(
        getCaliperOptions(brands as Brand[], {
          selectedBrand: productWatch || undefined,
          selectedGrammage: grammageWatch || undefined,
          defaultSelectLabel: EMPTY_OPTION_SELECT.label,
          isMetric,
        })
      );
      
      setBookWeightOptions(
        getBaseWeightOptions(
          brands as Brand[], {
            selectedBrand: productWatch || undefined,
            selectedCaliper: String(caliperWatch) || undefined,
            defaultSelectLabel: EMPTY_OPTION_SELECT.label,
          },
          false
        )
      );
    }
  }, [brands, productWatch, caliperWatch, caliperMilsWatch, grammageWatch, isMetric]);

  useEffect(() => {
    trigger(['inStock', 'inTransit', 'onOrder', 'invoiced']);
  }, [trigger, inStockWatch, inTransitWatch, onOrderWatch, invoicedWatch]);

  const getCorrespondingBrand = (product: string): Brand | undefined => {
    const brandsArr = (brands || []) as Brand[];

    return brandsArr.find((brand) => brand.brand === product);
  };
  
  const getDateFromValue = () => {
    return invoicedDateFromWatch
      ? moment(invoicedDateFromWatch).format(PARAM_FORMAT)
      : '';
  };

  const getDateToValue = () => {
    return invoicedDateToWatch
      ? moment(invoicedDateToWatch).format(PARAM_FORMAT)
      : '';
  };

  const getQueryParams = (
    data: ISearchFormData
  ): IGetConsignmentInventoryParams => {
    const selectedBrand = getCorrespondingBrand(data.product);
    
    data.invoicedDateFrom =
      data.invoiced ? getDateFromValue() : undefined;

    data.invoicedDateTo =
      data.invoiced ? getDateToValue() : undefined;
    
    const queryParams: IGetConsignmentInventoryParams = {
      bookweight: data.bookweight || undefined,
      caliper: data.caliper || undefined,
      caliperMils: data.caliperMils,
      grammage: data.grammage || undefined,
      customerPurchaseOrder: data.customerPurchaseOrder || undefined,
      custMatSku: data.custMatSku || undefined,
      documentNumber: data.documentNumber || undefined,
      inStock: data.inStock || undefined,
      inTransit: data.inTransit || undefined,
      lengthMin: data.lengthMin || undefined,
      lengthMax: data.lengthMax || undefined,
      lengthMaxInches: data.lengthMaxInches || undefined,
      lengthMinInches: data.lengthMinInches || undefined,
      olderThan: data.olderThan || undefined,
      onOrder: data.onOrder || undefined,
      productType: data.productType || undefined,
      salesGrade: selectedBrand?.grade,
      selectedBrand,
      soldToCustomerNumber: data.soldToCustomerNumber,
      widthMin: data.widthMin || undefined,
      widthMax: data.widthMax || undefined,
      widthMaxInches: data.widthMaxInches || undefined,
      widthMinInches: data.widthMinInches || undefined,
      invoiced: data.invoiced || undefined,
      invoiceNumber: data.invoiceNumber || undefined,
      invoicedDateFrom: data.invoicedDateFrom || undefined,
      invoicedDateTo: data.invoicedDateTo || undefined,
    };
    return queryParams;
  };

  const doReset = (): void => reset();

  const doSearch = (data: ISearchFormData) => {
    const queryParams = getQueryParams(data);
    if(!queryParams.invoiced || (queryParams.invoiced && queryParams.invoicedDateFrom && queryParams.invoicedDateTo)){
      onSearch(queryParams);
    }
  };

  return (
    <form onSubmit={handleSubmit(doSearch)}>
      <div className="flex my-6 text-lg">
        <FormLeftHalf register={register} customerOptions={customerOptions} />
        <FormRightHalf
          errors={errors}
          getValues={getValues}
          register={register}
          fetchingBrands={fetchingBrands}
          productOptions={productOptions}
          caliperOptions={caliperOptions}
          bookWeightOptions={bookWeightOptions}
          grammageOptions={grammageOptions}
          isNaUser={isNaUser}
          isMetric={isMetric}
          control={control}
        />
      </div>
      <div className="flex">
        <Space size="middle" className="ml-4">
          <Button type="submit" disabled={!isValid} theme="primary">
            <Trans>Search</Trans>
          </Button>
          <Button
            onClick={doReset}
            type="reset"
            theme="link"
            className="text-lg font-bold"
          >
            <Trans>Reset</Trans>
          </Button>
        </Space>
        {!isValid && (
          <div className="ml-12 text-red-red">
            <Trans>
              To perform a search of Consignment orders, please select a Customer 
              and <strong>at minimum</strong> a Status.  If ‘Invoiced’ Status is chosen, please 
              also additionally enter a Date Search parameter
            </Trans>
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchCriteria;
