import { t, Trans } from '@lingui/macro';
import React, { FC } from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import useRole from '../../../../hooks/useRole';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import AvailabilityBasketType from '../../../../types/AvailabilityBasketType.enum';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';

interface ItemDescriptionCellContentProps {
  basketItem: AvailabilityBasketItem;
  itemIsExpanded?: boolean;
  onToggleItemIsExpanded?: () => void;
}

const ItemDescriptionCellContent: FC<ItemDescriptionCellContentProps> = ({
  basketItem,
  itemIsExpanded,
  onToggleItemIsExpanded,
}) => {
  const { isEuUser, isNaUser, isReleaseUser } = useRole();
  const { isMetric } = useMeasurementSystem();
  const isProduct = basketItem.basketType === AvailabilityBasketType.PRODUCT;

  const sheetsPerPallet: string | undefined = (() => {
    if (isNaUser && basketItem.sheetsPerPalletNa != null) {
      return `${basketItem.sheetsPerPalletNa}`;
    }
    if (isEuUser && basketItem.sheetsPerPallet) {
      return stripZeros(basketItem.sheetsPerPallet);
    }
    return undefined;
  })();

  const rollWidth: string | undefined = (() => {
    if (isMetric && basketItem.reelWidth != null) {
      return `${basketItem.reelWidth}`;
    }
    return (!isMetric && basketItem.reelWidthImp) || undefined;
  })();

  const rollDiameter: string | undefined =
    (isMetric && basketItem.reelDiameter) ||
    (!isMetric && basketItem.reelDiameterImp) ||
    undefined;

  const rollWeight: number | undefined = (() => {
    if (isMetric && basketItem.reelWeight) {
      return Math.round(+basketItem.reelWeight);
    }
    if (!isMetric && basketItem.reelWeightImp) {
      return Math.round(+basketItem.reelWeightImp);
    }
    return undefined;
  })();

  return (
    <>
      <div>
        <div className="font-bold">
          {isProduct ? basketItem.material : basketItem.product}{' '}
          {basketItem.bookweight} {basketItem.tradeBasis}
        </div>
        {!isProduct && (
          <>
            <div>
              {basketItem.grainDirection} {basketItem.widthImp}{' '}
              {basketItem.widthImp && basketItem.lengthImp && 'x '}{' '}
              {basketItem.lengthImp}
            </div>
            {sheetsPerPallet && (
              <div>
                {sheetsPerPallet} <Trans>SH/Pallet</Trans>
              </div>
            )}
            {basketItem.stacksPerPallet &&
              (basketItem.requestedUnitOfMeasure === UnitOfMeasure.PL ||
                basketItem.requestedUnitOfMeasure === UnitOfMeasure.SH) && (
                <div>
                  {basketItem.stacksPerPallet} <Trans>ST/Pallet</Trans>
                </div>
              )}
            {rollWidth && (
              <div>
                <Trans>Roll Width</Trans>: {rollWidth}
              </div>
            )}
            {rollDiameter && (
              <LabelValue
                label={t`Roll Diameter`}
                value={rollDiameter}
                inline
                inverse
              />
            )}
            {rollWeight &&
              basketItem.requestedUnitOfMeasure === UnitOfMeasure.RL && (
                <LabelValue
                  label={t`Avg Roll Wgt`}
                  value={rollWeight}
                  inline
                  inverse
                />
              )}
            {!isMetric && basketItem.mWeight && (
              <LabelValue
                label={t`M Weight`}
                value={Math.round(+basketItem.mWeight)}
                inline
                inverse
              />
            )}
            {isReleaseUser ? (
              <>
                {basketItem.reelWrapping && (
                  <LabelValue
                    label={t`Roll Wrapping`}
                    value={basketItem.reelWrapping}
                    inline
                    inverse
                  />
                )}
                {basketItem.reelPackage && (
                  <LabelValue
                    label={t`Roll Package`}
                    value={basketItem.reelPackage}
                    inline
                    inverse
                  />
                )}
                {basketItem.windingDirection && (
                  <LabelValue
                    label={t`Winding Direction`}
                    value={basketItem.windingDirection}
                    inline
                    inverse
                  />
                )}
              </>
            ) : (
              <>
                {basketItem.packageTypeDesc && (
                  <div>{basketItem.packageTypeDesc}</div>
                )}
              </>
            )}
          </>
        )}
        {!isReleaseUser && basketItem.skuCode && (
          <LabelValue
            label={t`Sappi SKU`}
            value={basketItem.skuCode}
            inline
            inverse
          />
        )}
      </div>
      {isProduct && onToggleItemIsExpanded !== undefined && (
        <button
          className="mt-16 font-bold text-left text-blue-pacific"
          onClick={onToggleItemIsExpanded}
          type="button"
        >
          {itemIsExpanded ? (
            <>
              <Trans>Hide Order Details</Trans> <i className="fa fa-caret-up" />
            </>
          ) : (
            <>
              <Trans>Show Order Details</Trans>{' '}
              <i className="fa fa-caret-down pl-2" />
            </>
          )}
        </button>
      )}
    </>
  );
};

export default ItemDescriptionCellContent;
