import { t, Trans } from '@lingui/macro';
import { noop } from 'lodash';
import * as React from 'react';
import { Button } from '../../../components/Button/Button';
import FirstColumnCell from '../../../components/Table/FirstColumnCell';
import stripZeros from '../../../services/Util/stripZeros.util';
import { AvailableDocumentRow } from './AvailableDocumentRow.interface';
import styles from './AvailableDocumentsSearch.module.scss';

interface IOrderCellProps {
  record: AvailableDocumentRow;
  onLinkClick: () => void;
}

const OrderCell: React.FunctionComponent<IOrderCellProps> = ({
  record,
  onLinkClick,
}) => {
  return (
    <div className={`${styles.orderCellWidth} whitespace-nowrap`}>
      {!record.isChild && (
        <>
          <div className="flex">
            <div>
              <Trans>My Order #:</Trans> &nbsp;
            </div>
            <div>{record.customerOrderNumber}</div>
          </div>
          <div className="flex">
            <div>
              <Trans>Sappi Order #:</Trans>&nbsp;
            </div>
            <div>
              {record.overviews.length > 1 ? (
                <>{stripZeros(record.documentNumber)}</>
              ) : (
                <>
                  {record.overviews[0].details && (
                    <div
                      role="button"
                      className="underline"
                      tabIndex={0}
                      onKeyDown={noop}
                      title={t({ id: 'view_details', message: 'View Details' })}
                      onClick={() => {
                        onLinkClick();
                      }}
                    >
                      {stripZeros(record.documentNumber)}/
                      {stripZeros(record.overviews[0].documentPosition)}
                    </div>
                  )}
                  {!record.overviews[0].details && (
                    <>
                      {stripZeros(record.documentNumber)}/
                      {stripZeros(record.overviews[0].documentPosition)}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
      {record.isChild && (
        <FirstColumnCell isChildRow={record.isChild || false}>
          <Button
            theme="link"
            title={t({ id: 'view_details', message: 'View Details' })}
            onClick={() => {
              onLinkClick();
            }}
          >
            <strong>
              {stripZeros(record.documentNumber)}/
              {stripZeros(record.overviews[0].documentPosition)}
            </strong>
          </Button>
        </FirstColumnCell>
      )}
      {record.overviews[0].bookingReference && (
        <div className="flex">
          <div>
            <Trans>Booking reference:</Trans> &nbsp;
          </div>
          <div> {stripZeros(record.overviews[0].bookingReference)}</div>
        </div>
      )}
    </div>
  );
};

export default OrderCell;
