import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import additonalSectionsImage from '../../../../assets/dummy_sample/additional_sections.png';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import { getSamplesParameters } from '../../../../services/SamplesOrder';
import Option from '../../../../types/Option.type';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import SamplesProductGroup from '../../OrderSamples/SamplesProductGroup.enum';
import NumPagesInput from './NumPagesInput';
import { getTextPagesOptions } from './TextPages.util';
import Input from '../../../../components/Input/Input';

interface IAdditionalSectionsProps {
  register: UseFormMethods['register'];
  getValues: UseFormMethods['getValues'];
  errors: UseFormMethods['errors'];
  trigger: UseFormMethods['trigger'];
  watch: UseFormMethods['watch'];
}

const POSITION_OPTIONS: Option[] = [
  { value: '', label: '' },
  { value: 'front', label: t`FRONT` },
  { value: 'center', label: t`CENTER` },
  { value: 'back', label: t`BACK` },
  { value: 'other', label: t`OTHER` },
];

const AdditionalSections: React.FunctionComponent<IAdditionalSectionsProps> = ({
  register,
  getValues,
  errors,
  trigger,
  watch,
}) => {
  const [brandFamilyOptions, setBrandFamilyOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [grammageOptions, setGrammageOptions] = useState<Option[]>([]);
  const positionWatch = watch('position');

  const [filters, setFilters] = useState({
    brandFamily: null,
    brand: null,
    grammage: null,
    productGroup: [SamplesProductGroup.PUBLIC, SamplesProductGroup.COMM],
  });

  const {
    resolvedData: parameters,
    isFetching: isFetchingParameters,
  } = usePaginatedQuery(
    [QueryCacheName.SAMPLES_DUMMY_ADDTL_PARAMETERS, filters],
    getSamplesParameters,
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const updateFilters = () => {
    setFilters({
      brandFamily: getValues('brandFamilyAddtl'),
      brand: getValues('brandAddtl'),
      grammage: getValues('grammageAddtl'),
      productGroup: [SamplesProductGroup.PUBLIC, SamplesProductGroup.COMM],
    });
  };

  useEffect(() => {
    setBrandFamilyOptions(
      getTextPagesOptions(parameters?.brandFamilies, 'display', 'brandFamily')
    );
    setProductOptions(
      getTextPagesOptions(parameters?.brands, 'brandName', 'brand')
    );
    setGrammageOptions(
      getTextPagesOptions(parameters?.grammages, 'grammage', 'grammage')
    );
  }, [parameters]);

  return (
    <div className="ml-10 w-1/2">
      <h3 className="text-xl font-bold mb-6">
        <Trans>Additional Sections</Trans>
      </h3>
      <div className="flex">
        <div style={{ maxWidth: '25%' }}>
          <img
            src={additonalSectionsImage}
            alt={t`Additional Sections Paper`}
          />
        </div>
        <div className="ml-9 mr-28 w-full">
          <Space direction="vertical" className="w-full">
            <InputLabel
              size="lg"
              text={t`Position in dummy`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="position"
                ref={register()}
                width="full"
                options={POSITION_OPTIONS}
                disabled={isFetchingParameters}
                onChange={() =>
                  trigger([
                    'brandFamilyAddtl',
                    'brandAddtl',
                    'grammageAddtl',
                    'numPagesAddtl',
                  ])
                }
              />
            </InputLabel>
            {positionWatch === 'other' && (
              <InputLabel size="lg" text={t`Describe position in dummy`}>
                <Input
                  name="additionalSectionsPositionDescription"
                  width="full"
                  ref={register({ required: positionWatch === 'other' })}
                  maxLength={50}
                />
              </InputLabel>
            )}
            <InputLabel
              size="lg"
              text={t`Brand`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="brandFamilyAddtl"
                ref={register({
                  validate: {
                    required: (value) => !getValues('position') || !!value,
                  },
                })}
                width="full"
                options={brandFamilyOptions}
                disabled={isFetchingParameters}
                onChange={() => updateFilters()}
              />
            </InputLabel>
            <InputLabel
              size="lg"
              text={t`Product`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="brandAddtl"
                ref={register({
                  validate: {
                    required: (value) => !getValues('position') || !!value,
                  },
                })}
                width="full"
                options={productOptions}
                disabled={isFetchingParameters}
                onChange={() => updateFilters()}
              />
            </InputLabel>
            <InputLabel
              size="lg"
              text={t`Grammage`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="grammageAddtl"
                ref={register({
                  validate: {
                    required: (value) => !getValues('position') || !!value,
                  },
                })}
                width="full"
                options={grammageOptions}
                disabled={isFetchingParameters}
                onChange={() => updateFilters()}
              />
            </InputLabel>
            <NumPagesInput
              name="numPagesAddtl"
              register={register}
              validate={{
                required: (value) => !getValues('position') || !!value,
              }}
              errors={errors}
            />
          </Space>
        </div>
      </div>
    </div>
  );
};

export default AdditionalSections;
