import * as React from 'react';

interface IAvailableDocumentsColumnTitleProps {
  checked: boolean | undefined;
  onChange: (checked: boolean) => void;
  text: string | React.ReactNode;
}

const AvailableDocumentsColumnTitle: React.FunctionComponent<IAvailableDocumentsColumnTitleProps> = ({
  checked,
  onChange,
  text,
}) => {
  return (
    <div className="flex items-baseline">
      <input
        type="checkbox"
        className="ml-2 mr-1"
        checked={checked}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />{' '}
      <span className="text-lg font-bold leading-tight mr-2">{text}</span>
    </div>
  );
};

export default AvailableDocumentsColumnTitle;
