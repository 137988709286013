import create from 'zustand';

type RequiredPasswordUserState = {
  username: string | null;
};

const useRequiredPasswordUser = create<RequiredPasswordUserState>(() => ({
  username: null,
}));

export default useRequiredPasswordUser;
