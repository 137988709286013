import { t, Trans } from '@lingui/macro';
import React, { FC } from 'react';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import ErrorTooltip from '../../../../components/ErrorTooltip/ErrorTooltip';

interface IDescriptionCellProps {
  item: AvailabilityBasketItem;
}

const DescriptionCell: FC<IDescriptionCellProps> = ({ item }) => {
  return (
    <>
      <ErrorTooltip
        hasError={
          item.returnCode !== '002' &&
          item.returnCode !== '000' &&
          item.returnCode !== undefined &&
          item.returnCode !== null &&
          item.returnCode !== ''
        }
        message={item.returnMessage}
        placement="bottom"
      >
        <span className="font-bold">
          {item.txtGradeBrandMember} {item.grammage}{' '}
        </span>
      </ErrorTooltip>
      {item.productType === PutUpCategory.SHEET && (
        <>
          <div>
            <Trans>
              (W) {item.width}cm x (L) {item.length}cm
            </Trans>
          </div>
        </>
      )}
      <div>
        <Trans>Wrapping Type</Trans>
      </div>
      <span>{item.txtWrappingType}</span>
    </>
  );
};

export default DescriptionCell;
