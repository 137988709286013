import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import Option from '../../../../types/Option.type';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';

const OPTIONS: Option[] = [
  { label: '', value: '' },
  { label: t`Trial`, value: 'Trial' },
  { label: t`Dummy Production`, value: 'Dummy Production' },
  { label: t`Sample Stock Replenishment`, value: 'Sample Stock Replenishment' },
];
interface IDescriptionCellProps {
  product: SamplesProduct;
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  index: number;
  updating?: boolean;
  readonly?: boolean;
}

const DescriptionCell: React.FunctionComponent<IDescriptionCellProps> = ({
  product,
  register,
  watch,
  updating,
  index,
  readonly,
}) => {
  const quantity = watch ? watch(`quantity[${index}]`) : product.quantity;
  const [isLargeQuantity, setIsLargeQuantity] = useState(false);
  useEffect(() => {
    setIsLargeQuantity(
      Boolean(
        product &&
          quantity * product.unitSize >= 500 &&
          product.size === '70X100'
      )
    );
  }, [product, quantity]);

  return (
    <>
      {product.materialDescription}
      <br />
      {product.materialNumber}
      <br />
      {product.boxDetail}
      {isLargeQuantity && (
        <InputLabel
          text={t`We noticed you are placing a large quantity order, please explain why:`}
          position="top"
          labelClassName="font-bold"
        >
          {readonly ? (
            <>{product.largeQuantity}</>
          ) : (
            <Select
              name={`largeQuantityReason[${index}]`}
              defaultValue={product.largeQuantity as string}
              ref={register({ required: true })}
              options={OPTIONS}
              disabled={updating}
              required
            />
          )}
        </InputLabel>
      )}
    </>
  );
};

export default DescriptionCell;
