import * as React from 'react';

interface IResultProps {
  quantity?: number;
  unit: string;
}

const Result: React.FunctionComponent<IResultProps> = ({ quantity, unit }) => {
  return (
    <div>
      {quantity} {unit}
    </div>
  );
};

export default Result;
