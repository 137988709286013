import * as React from 'react';

const BasketPopUpItemProps: React.FunctionComponent<React.HTMLProps<
  HTMLDivElement
>> = ({ className = '', children, ...rest }) => {
  return (
    <div className={`item-properties my-5 mx-2 ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default BasketPopUpItemProps;
