import { useQuery } from 'react-query';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { getMyContact } from './MyContact.service';
import { MyContact } from './Types/MyContact.interface';
import useLanguage from '../../hooks/useLanguage';

const useMyContact = (): {
  myContact: MyContact | undefined;
} => {
  const lang = useLanguage();
  const { data: myContact } = useQuery(
    [QueryCacheName.MY_CONTACT, lang],
    () => getMyContact(lang),
    {
      staleTime: Infinity,
      enabled: lang,
      keepPreviousData: true,
    }
  );
  return {
    myContact,
  };
};

export default useMyContact;
