import { capitalize, each, groupBy, map } from 'lodash';
import QuickOrderRow from '../../../components/QuickOrderBookingTable/QuickOrderRow.interface';
import QuickOrderRowLocation from '../../../components/QuickOrderBookingTable/QuickOrderRowLocation.interface';
import Product from '../../../types/Product.interface';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import StorageLocation from '../../../types/StorageLocation.enum';


function getAvailableQuantitySum(products: Product[]): number {
  return products
    .map((p) => p.availableQuantity)
    .reduce((sum, currentValue) => sum + currentValue);
}

function getDefaultUnit(type: string, uom: string, rUnit: string, sUnit: string): string {
  //  I will revert to the metric or imperial default in cases where type doesn't match.
  let def = uom;
  if (type === PutUpCategory.SHEET) {
    if(sUnit && sUnit !== def){
      def = sUnit;
    }
    else{
      def = 'PL';
    }
  } else if (type === PutUpCategory.ROLL) {
    if(rUnit && rUnit !== def){
      def = rUnit;
    }
    else{
      def = 'RL';
    }
  }
  return def;
}

function getLocations(products: Product[]): QuickOrderRowLocation[] {
  return map(products, (product) => ({
    location: product.rdcText,
    quantity: `${product.availableQuantity} ${capitalize(
      product.unitOfMeasure
    )}(s)`,
    replenishmentDate: product.replenishmentDate1,
    replenishmentAmount: `${product.replenishmentQuantity1} ${capitalize(
      product.unitOfMeasure
    )}(s)`,
  }));
}

export default function toQuickOrderRows(
  products: Product[],
  reelUnit: string,
  sheetUnit: string,
  isMetric?: boolean,
): QuickOrderRow[] {
  let keyIdxFallback = 1;
  const uom = isMetric ? 'KG' : 'LB';
  const rows: QuickOrderRow[] = [];

  const groupedProducts = groupBy(
    products,
    (p) => p.kcode || p.materialNumber || keyIdxFallback++
  );
  each(groupedProducts, (groupedProductArray, key) => {
    const product = groupedProductArray[0];
    rows.push({
      ...product,
      key,
      productType: product.productType as PutUpCategory,
      width: product.width || product.reelWidth,
      widthAndLength: 'TBD',
      finish: product.finish || '',
      reelAndCoreDiameter: 'TBD',
      coreDiameterInches: product.coreDiameterImp,
      inStock: getAvailableQuantitySum(groupedProductArray),
      selectedMeasureUnit: getDefaultUnit(product.productType, uom, reelUnit, sheetUnit),
      userEnteredQuantity: 0,
      wrapType: product.wrapDescription,
      locations: getLocations(groupedProductArray),
    });
  });
  // Filter storage locations
  const filteredRows = rows.filter(
    (row) => row.storageLocation !== StorageLocation.AM
  );

  return filteredRows;
}
