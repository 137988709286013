import React, { FC } from 'react';
import useLanguage from '../../hooks/useLanguage';
import PromoPosition from '../../types/PromoPosition.enum';
import usePromoContent from './usePromoContent';

interface IImagePromoProps {
  position: PromoPosition;
}

const ImagePromo: FC<IImagePromoProps> = ({ position }) => {
  const lang = useLanguage();
  const { promoContent } = usePromoContent(lang, position);
  const navigateToUrl = () => {
    window.open(promoContent?.link?.url, '_blank');
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <img
      className="cursor-pointer w-full"
      src={promoContent?.image}
      alt={promoContent?.title}
      onClick={navigateToUrl}
      onKeyPress={() => {}}
    />
  );
};

export default ImagePromo;
