import React, { FC } from 'react';

interface IPageTitleProps {
  title: React.ReactNode | string;
}

const PageTitle: FC<IPageTitleProps> = ({ title }) => {
  return (
    <h1 className="text-6xl font-bold" style={{ maxWidth: '900px' }}>
      {title}
    </h1>
  );
};

export default PageTitle;
