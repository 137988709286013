import { t, Trans } from '@lingui/macro';
import { filter as lodashFilter, find, orderBy, trim } from 'lodash';
import { get } from 'lodash/fp';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import useMeasurementSystem from '../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import useRole from '../../hooks/useRole';
import useAnalytics from '../../hooks/useAnalytics';
import {
  getBaseWeightOptions,
  getBrands,
  getEuBaseWeightOptions,
  getEuFinishOptions,
  getFinishOptions,
} from '../../services/Brand';
import {
  getCustomerSavedFilter,
  isFilterExisting,
} from '../../services/Filter';
import alphanumericOnlyInput from '../../services/Util/alphanumericOnlyInput.util';
import { AuthorityRole } from '../../types/Authority.interface';
import Brand from '../../types/Brand.interface';
import EuBrand from '../../types/EuBrand.interface';
import MeasurementSystem from '../../types/MeasurementSystem.enum';
import Option from '../../types/Option.type';
import ProductQuery from '../../types/ProductQuery.interface';
import PutUpCategory from '../../types/PutUpCategory.enum';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { Button } from '../Button/Button';
import Input from '../Input/Input';
import Modal from '../Modal/Modal';
import Select from '../Select/Select';
import ConfirmationModal from './ConfirmationModal';
import './FilterModal.scss';

interface IFilterProps {
  visible: boolean;
  showSavedFilters: boolean;
  selectedCustomer?: string;
  rollFilterName?: string;
  sheetFilterName?: string;
  type: PutUpCategory;
  onCancel: () => void;
  productQuery?: ProductQuery;
  onSubmit?: (filterName: string) => void;
  applyFilter?: (chosenFilter: ProductQuery) => void;
  deleteFilter?: (chosenFilter: ProductQuery) => void;
}

const FilterModal: React.FunctionComponent<IFilterProps> = ({
  visible,
  showSavedFilters,
  selectedCustomer,
  rollFilterName,
  sheetFilterName,
  type,
  onCancel,
  productQuery,
  onSubmit,
  applyFilter,
  deleteFilter,
}) => {
  const { register, handleSubmit, reset, watch, errors } = useForm();
  const [chosenFilter, setChosenFilter] = useState(
    {} as ProductQuery | undefined
  );
  const [filterNameInput, setFilterNameInput] = useState('');
  const { trackPageView } = useAnalytics();
  const [savedFilters, setSavedFilters] = useState([] as ProductQuery[]);

  const [chemistry, setChemistry] = useState('-');
  const [minWidth, setMinWidth] = useState('-');
  const [maxWidth, setMaxWidth] = useState('-');
  const [minLength, setMinLength] = useState('-');
  const [maxLength, setMaxLength] = useState('-');
  const [grammage, setGrammage] = useState('-');
  const [basisWeight, setBasisWeight] = useState('-');
  const [brand, setBrand] = useState('-');
  const [parentBrand, setParentBrand] = useState('-');
  const [filterType, setFilterType] = useState('-');
  const [texture, setTexture] = useState('-');
  const [finishCode, setFinishCode] = useState('');
  const { hasRole, isNonReleaseEuUser } = useRole();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { format } = useNumberFormatter();
  const { measurementSystem } = useMeasurementSystem();
  const { resolvedData: brands } = usePaginatedQuery(
    [
      QueryCacheName.BRANDS,
      {
        customer: selectedCustomer,
        context: type === PutUpCategory.SHEET ? 'PL' : 'RL',
        productType: type,
        qobFlag: 'X',
      },
    ],
    getBrands,
    {
      staleTime: Infinity,
      enabled: selectedCustomer,
      keepPreviousData: true,
    }
  );

  const getFinishDescription = React.useMemo(
    () => (finishValue: string): string => {
      const options = isNonReleaseEuUser
        ? getEuFinishOptions(brands as EuBrand[])
        : getFinishOptions(brands as Brand[]);
      const finish = find(options, { value: finishValue });
      return finish ? String(finish.label) : '-';
    },
    [brands, isNonReleaseEuUser]
  );

  const getBasisWeightFromGrammage = React.useMemo(
    () => (grammageValue: string, brandValue: string): string => {
      const baseWeightOptions = isNonReleaseEuUser
        ? getEuBaseWeightOptions(brands as EuBrand[], brandValue)
        : getBaseWeightOptions(brands as Brand[], {
            selectedBrand: brandValue,
          });
      const baseWeight = find(baseWeightOptions, { value: grammageValue });
      return baseWeight ? String(baseWeight.label) : '-';
    },
    [brands, isNonReleaseEuUser]
  );

  function displayFilter(filter: ProductQuery | undefined) {
    setBrand(String(filter?.brand ? filter?.brand : '-'));
    setChemistry(String(filter?.chemistry ? filter?.chemistry : '-'));
    setMinWidth(
      filter?.maxWidth && !filter.minWidth ? '0' : String(filter?.minWidth)
    );
    setMaxWidth(
      filter?.minWidth && !filter.maxWidth ? '99999' : String(filter?.maxWidth)
    );
    setMinLength(
      filter?.maxLength && !filter.minLength ? '0' : String(filter?.minLength)
    );
    setMaxLength(
      filter?.minLength && !filter.maxLength
        ? '99999'
        : String(filter?.maxLength)
    );
    setGrammage(String(filter?.grammage ? filter?.grammage : '-'));
    setBasisWeight(String(filter?.basisWeight ? filter?.basisWeight : '-'));
    setFilterType(String(filter?.type));
    setParentBrand(String(filter?.parentBrand ? filter?.parentBrand : '-'));
    setTexture(String(filter?.texture ? filter?.texture : '-'));
    setFinishCode(String(filter?.finish));
  }

  useEffect(() => {
    if (visible) {
      reset();
      setFilterNameInput('');
      const getCustomerSavedFilterAsync = async () => {
        const filters = await getCustomerSavedFilter(
          String(selectedCustomer),
          String(type),
          String(measurementSystem)
        );

        const neededFilters = lodashFilter(filters, (o) => o.type === type);
        setSavedFilters(neededFilters);
      };

      getCustomerSavedFilterAsync();

      if (productQuery) {
        setChosenFilter(productQuery);
        displayFilter(productQuery);
      } else {
        setChosenFilter(undefined);
      }
    }
  }, [reset, visible, selectedCustomer, productQuery, type, measurementSystem]);

  interface IFormInputs {
    filterName: string;
  }

  const submit = async (data: IFormInputs) => {
    if (onSubmit) {
      trackPageView('QUICK_ORDER_BOOKING', 'SAVE_FILTER');
      const filterExists = await isFilterExisting(
        String(selectedCustomer),
        trim(data.filterName),
        String(type),
        String(measurementSystem)
      );

      setFilterNameInput(trim(data.filterName));
      setShowConfirmationModal(filterExists);

      if (!filterExists) {
        onSubmit(trim(data.filterName));
      }
    }
  };

  const getFilterOptions = (): Option[] => {
    const options: Option[] = [
      {
        value: '',
        label: '',
      },
    ];
    savedFilters.forEach((f) => {
      if (f.filterName) {
        options.push({
          value: f.filterName,
          label: f.filterName,
        });
      }
    });
    return orderBy(options, ['value'], ['asc']);
  };

  const filterNameWatch = watch('filterName');
  useEffect(() => {
    setFilterNameInput(trim(filterNameWatch));
  }, [filterNameWatch]);

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        filterNameInput={filterNameInput}
        onConfirm={(continueToSave: boolean) => {
          if (continueToSave && onSubmit) {
            onSubmit(filterNameInput);
          }
          setShowConfirmationModal(false);
        }}
      />
      <Modal
        className="filter-modal-container"
        visible={visible}
        title={
          <div key="filterModalTitle">
            <p className="text-xl flex-grow">
              {showSavedFilters ? (
                <Trans>Saved Filters</Trans>
              ) : (
                <Trans>Save Filter</Trans>
              )}
            </p>
          </div>
        }
        okText={t`Submit`}
        maskTransitionName=""
        onCancel={onCancel}
        centered
        maskClosable={false}
        width={600}
        footer={[
          <div className="pl-9 pb-5 text-left" key="filterModalFooter">
            {!showSavedFilters && (
              <Button
                key="saveFilter"
                theme="primary"
                disabled={!filterNameInput}
                onClick={handleSubmit(submit)}
              >
                <Trans>Save Filter</Trans>
              </Button>
            )}
            {showSavedFilters && (
              <Button
                key="deleteFilter"
                theme="inverse"
                disabled={
                  !chosenFilter ||
                  chosenFilter.filterName === String(rollFilterName) ||
                  chosenFilter.filterName === String(sheetFilterName)
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (deleteFilter && chosenFilter) {
                    deleteFilter(chosenFilter);
                  }
                  onCancel();
                }}
              >
                <Trans>Delete Filter</Trans>
              </Button>
            )}
            {showSavedFilters && <span className="px-16 mx-2 my-1" />}
            {showSavedFilters && (
              <Button
                key="applyFilter"
                theme="primary"
                disabled={!chosenFilter}
                onClick={(e) => {
                  e.stopPropagation();
                  if (applyFilter && chosenFilter) {
                    applyFilter(chosenFilter);
                  }
                  onCancel();
                }}
              >
                <Trans>Apply Filter</Trans>
              </Button>
            )}
            <Button key="cancel" onClick={onCancel}>
              <Trans>Cancel</Trans>
            </Button>
          </div>,
        ]}
      >
        <form className="items-center px-6">
          <div className="pb-7">
            <div className="pb-2">
              <span className="text-2xl text-gray-500">Filter Name</span>
            </div>
            {!showSavedFilters && (
              <Input
                name="filterName"
                className="filter-modal-input"
                placeholder={t`Filter Name`}
                type="text"
                onKeyDown={alphanumericOnlyInput}
                ref={register({ required: true, maxLength: 50 })}
              />
            )}
            {!showSavedFilters &&
              get('filterName.type', errors) === 'required' && (
                <p className="text-red-900 font-bold mt-2">
                  <Trans>This Filter Name field is required</Trans>
                </p>
              )}
            {!showSavedFilters &&
              get('filterName.type', errors) === 'maxLength' && (
                <p className="text-red-900 font-bold mt-2">
                  <Trans>Filter name cannot exceed 50 characters</Trans>
                </p>
              )}
            {showSavedFilters && (
              <Select
                className="filter-modal-input"
                options={getFilterOptions()}
                ref={register}
                name="filterName"
                onChange={(e) => {
                  const savedFilter = savedFilters?.find(
                    (c) => c.filterName === e.target.value
                  );
                  setChosenFilter(savedFilter);
                  displayFilter(savedFilter);
                }}
              />
            )}
          </div>
          {chosenFilter && hasRole(AuthorityRole.ROLE_RELEASE) && (
            <div className="filter-modal-border">
              <div className="filter-modal-body">
                <div className="flex pl-9 py-3">
                  <div className="flex-1 text-lg font-bold text-gray-500">
                    <Trans>Product</Trans>
                  </div>
                  <div className="flex-1">{parentBrand}</div>
                </div>
                <div className="flex pl-9 py-3">
                  <div className="flex-1 text-lg font-bold text-gray-500">
                    <Trans>Grade</Trans>
                  </div>
                  <div className="flex-1">{chemistry}</div>
                </div>
                <div className="flex pl-9 py-3">
                  <div className="flex-1 text-lg font-bold text-gray-500">
                    <Trans>Texture</Trans>
                  </div>
                  <div className="flex-1">{texture}</div>
                </div>
              </div>
            </div>
          )}
          {chosenFilter && !hasRole(AuthorityRole.ROLE_RELEASE) && (
            <div className="filter-modal-border">
              <div className="filter-modal-body">
                <div className="flex pl-9 py-3">
                  <div className="flex-1 text-lg font-bold text-gray-500">
                    <Trans>Type</Trans>
                  </div>
                  <div className="flex-1">{filterType}</div>
                </div>
                <div className="flex pl-9 py-3">
                  <div className="flex-1 text-lg font-bold text-gray-500">
                    <Trans>Brand</Trans>
                  </div>
                  <div className="flex-1">{brand}</div>
                </div>
                <div className="flex pl-9 py-3">
                  <div className="flex-1 text-lg font-bold text-gray-500">
                    <Trans>Finish</Trans>
                  </div>
                  <div className="flex-1">
                    {getFinishDescription(finishCode)}
                  </div>
                </div>
                {measurementSystem === MeasurementSystem.METRIC ? (
                  <div className="flex pl-9 py-3">
                    <div className="flex-1 text-lg font-bold text-gray-500">
                      <Trans>Grammage</Trans>
                    </div>
                    <div className="flex-1">{grammage}</div>
                  </div>
                ) : (
                  <div className="flex pl-9 py-3">
                    <div className="flex-1 text-lg font-bold text-gray-500">
                      <Trans>Basis Weight</Trans>
                    </div>
                    <div className="flex-1">
                      {getBasisWeightFromGrammage(grammage, brand)}
                    </div>
                  </div>
                )}
                <div className="flex pl-9 py-3">
                  <div className="flex-1 text-lg font-bold text-gray-500">
                    {chosenFilter.type === PutUpCategory.ROLL ? (
                      <Trans>Width</Trans>
                    ) : (
                      <Trans>Width & Length</Trans>
                    )}
                  </div>
                  <div className="flex-1">
                    {chosenFilter.type === PutUpCategory.ROLL ? (
                      <span>
                        [{minWidth} - {maxWidth}]
                      </span>
                    ) : (
                      <span>
                        [{minWidth} - {maxWidth}] & [{minLength} - {maxLength}]
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex pl-9 py-3">
                  <div className="flex-1 text-lg font-bold text-gray-500">
                    <Trans>Caliper</Trans>
                  </div>
                  <div className="flex-1">
                    {format(
                      String(chosenFilter.caliper ? chosenFilter.caliper : '-')
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </Modal>
    </>
  );
};

export default FilterModal;
