import { Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import { Order } from '../../../../types/Order.interface';
import { TrackOrder } from '../../../../types/TrackOrder.interface';
import '../OrderDetailsTable.scss';
import DescriptionCell from './DescriptionCell';
import OrderCell from './OrderCell';
import { OrderDetailsRow } from './OrderDetailsRow.interface';
import QuantityCell from './QuantityCell';
import StatusCell from './StatusCell';

interface IOrderDetailsTableProps {
  data: TrackOrder;
  loading?: boolean;
}

const OrderDetailsTable: React.FunctionComponent<IOrderDetailsTableProps> = ({
  data,
  loading,
}) => {
  const [tableData, setTableData] = useState<OrderDetailsRow[]>();

  useEffect(() => {
    const orderDetailsRow: OrderDetailsRow = {
      ...data,
      key: data.documentNumber + data.documentPosition,
    };
    setTableData([orderDetailsRow]);
  }, [data]);

  const columns: ColumnsType<TrackOrder> = [
    {
      title: <Trans>Order</Trans>,
      dataIndex: 'order',
      key: 'order',
      render: (text: string, record: TrackOrder) => (
        <OrderCell order={record} />
      ),
    },
    {
      title: <Trans>Status</Trans>,
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: TrackOrder) => (
        <StatusCell record={record} />
      ),
    },
    {
      title: <Trans>Description</Trans>,
      dataIndex: 'description',
      key: 'Description',
      render: (text: string, record: TrackOrder) => (
        <DescriptionCell record={record} />
      ),
    },
    {
      title: <Trans>Quantity</Trans>,
      dataIndex: 'quantity',
      key: 'quantity',
      className: 'align-top',
      render: (text: string, record: Order) => <QuantityCell record={record} />,
    },
  ];

  return (
    <>
      <Table
        dataSource={tableData}
        loading={loading}
        columns={columns}
        expandIconColumnIndex={-1}
        pagination={false}
        config={{
          noStripes: true,
          noOutsideBorders: true,
        }}
        className="order-details"
      />
    </>
  );
};

export default OrderDetailsTable;
