import * as React from 'react';

interface IAddressRenderProps {
  addressData?: {
    customerNumber?: string;
    address: string;
    city: string;
    country: string;
    postalCode: string;
  };
}

const AddressRender: React.FunctionComponent<IAddressRenderProps> = ({
  addressData,
}) => {
  return (
    <>
      <div>{addressData?.customerNumber}</div>
      <div>{addressData?.address}</div>
      <div>
        {addressData?.city}
        {', '} {addressData?.country} {addressData?.postalCode}
      </div>
    </>
  );
};

export default AddressRender;
