import { forOwn, trim, uniq } from 'lodash';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { isValidSappiSku, isValidSappiSkuOrMatlNo, normalizeSappiSku } from '../../../services/SappiSku';

/**
 * Prepares Sappi SKUs array for submission.
 *
 * Normalizes all Sappi SKUs, removes any invalid Sappi SKUs and empty values,
 * and dedupes the array.
 */
export const prepSappiSkus = (sappiSkus: string[]): string[] => {
  let preppedSappiSkus = sappiSkus.map((sappiSku) =>
    normalizeSappiSku(sappiSku)
  );
  preppedSappiSkus = preppedSappiSkus.filter((sappiSku) => {
    return isValidSappiSku(sappiSku) && sappiSku !== '';
  });
  preppedSappiSkus = uniq(preppedSappiSkus);

  return preppedSappiSkus;
};

/**
 * Prepares Sappi SKUs and Customer Material Number array for submission.
 *
 * Normalizes all Sappi SKUs and Customer Material Number, removes any 
 * invalid Sappi SKUs and empty values,
 * and dedupes the array.
 */
export const prepSappiSkusOrMatlNos = (sappiSkus: string[]): string[] => {
  let preppedSappiSkusOrMatlNos = sappiSkus;
  
  preppedSappiSkusOrMatlNos = preppedSappiSkusOrMatlNos.filter((sappiSkuOrMatlNo) => {
    // return isValidSappiSkuOrMatlNo(sappiSkuOrMatlNo) && sappiSkuOrMatlNo !== '';
    return sappiSkuOrMatlNo !== '';
  });
  preppedSappiSkusOrMatlNos = uniq(preppedSappiSkusOrMatlNos);

  return preppedSappiSkusOrMatlNos;
};

export function isApplyFilterDisabled(
  form: UnpackNestedValue<any>,
  sappiSkus: string[]
): boolean {
  if (sappiSkus.length) {
    return false;
  }
  let isDisabled = true;
  forOwn(form, (value, key) => {
    if (
      value !== undefined &&
      value !== '' &&
      trim(String(value)) !== '' &&
      key !== 'minWidth' &&
      key !== 'maxWidth' &&
      key !== 'minLength' &&
      key !== 'maxLength' &&
      key !== 'sappiSkus'
    ) {
      isDisabled = false;
    }
    return value;
  });

  return isDisabled;
}
