import { QueryPutUp } from '../../../services/Product/queryPutUp';
import PutUpKwd from '../../../types/PutUpKwd.enum';

const getPutUpQueryParam = (putUps: QueryPutUp[]) => {
  const rollChosen = putUps.filter((p) => p.putUp === PutUpKwd.ROLL);
  const sheetChosen = putUps.filter((p) => p.putUp === PutUpKwd.SHEET);

  if (rollChosen.length > 0 && sheetChosen.length > 0) {
    return '';
  }
  if (rollChosen.length > 0) {
    return PutUpKwd.ROLL;
  }
  if (sheetChosen.length > 0) {
    return PutUpKwd.SHEET;
  }

  return '';
};

export default getPutUpQueryParam;
