import { useQuery } from 'react-query';
import { getStockLotHarbors } from '../../../../services/Customer';
import Customer from '../../../../types/Customer.interface';
import QueryCacheName from '../../../../types/QueryCacheName.enum';

const useHarbors = (
  soldToCustomerNumber: string,
  shipToCustomerNumber: string,
  division: string | null
): {
  harbors: Customer[];
  decisionMaker: string;
  isFetching: boolean;
} => {
  const { data, isFetching } = useQuery(
    [
      QueryCacheName.STOCK_LOT_HARBORS,
      { soldToCustomerNumber, shipToCustomerNumber, division },
    ],
    () =>
      getStockLotHarbors(soldToCustomerNumber, shipToCustomerNumber, division),
    {
      enabled: soldToCustomerNumber && shipToCustomerNumber && division,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return {
    harbors: data?.customers || [],
    decisionMaker: data?.decisionMaker || '',
    isFetching,
  };
};

export default useHarbors;
