import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SearchStepButtons from '../SearchStepButtons';
import { TrackAndTraceData } from '../types/TrackAndTraceData.interface';
import TrackAndTraceStep from '../types/TrackAndTraceStep.enum';
import { ArrivalForm } from './ArrivalForm.interface';
import ArrivalFormField from './ArrivalFormField.enum';
import ResultsTable from './ResultsTable';
import useArrivalDataStore from './useArrivalData.store';

interface ISearchStepProps {
  onSaveAndContinue: (formData: ArrivalForm) => void;
  showExcessResultsMessage: boolean;
  data: TrackAndTraceData[];
}

const SearchStep: FC<ISearchStepProps> = ({
  onSaveAndContinue,
  showExcessResultsMessage,
  data,
}) => {
  const defaultValues = useArrivalDataStore((state) => state);
  const formMethods = useForm<ArrivalForm>({
    mode: 'onSubmit',
    defaultValues,
  });
  const {
    formState: { errors },
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    reset,
  } = formMethods;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (errors.arrivalRow) {
      setErrorMessage(t`Errors exist in above entries.`);
    } else {
      setErrorMessage(null);
    }
  }, [errors.arrivalRow]);

  const isOneArrivalDateFilledIn = (): boolean =>
    getValues().arrivalRow.some((row) => !!row.newArrivalDate);

  const saveAndContinue = (formData: ArrivalForm) => {
    // For performance reasons we cannot use a validation rule on the ControlledDatePicker
    if (!isOneArrivalDateFilledIn()) {
      return;
    }
    setErrorMessage(null);
    onSaveAndContinue(formData);
  };

  const cancel = () => {
    data.forEach((item, index) => {
      setValue(
        `arrivalRow.${index}.${ArrivalFormField.NEW_ARRIVAL_DATE}`,
        null,
        { shouldValidate: false }
      );
      setValue(
        `arrivalRow.${index}.${ArrivalFormField.NEW_DEPARTURE_DATE}`,
        null,
        { shouldValidate: false }
      );
    });
    clearErrors('arrivalRow');
  };
  return (
    <FormProvider {...formMethods}>
      <form>
        <Space direction="vertical" size="middle" className="w-full">
          <ResultsTable
            data={data}
            showExcessResultsMessage={showExcessResultsMessage}
            step={TrackAndTraceStep.SEARCH}
          />
          <div className="text-right w-full">
            <SearchStepButtons
              onSaveAndContinue={handleSubmit(saveAndContinue)}
              onCancel={cancel}
              requirementsMessage={t`Enter at least one arrival date.`}
              errorMessage={errorMessage}
            />
          </div>
        </Space>
      </form>
    </FormProvider>
  );
};

export default SearchStep;
