enum QuickLinkId {
  CALCULATORS_CONVERTERS = 'calculators_converters',
  CALCULATORS_EQ = 'calculators_eq',
  CALCULATORS_METRIC_CONVERSIONS = 'calculators_metric_conversions',
  CALCULATORS_PALLET = 'calculators_pallet',
  CALCULATORS_PAPER_MATH = 'calculators_paper_math',
  CALCULATORS_PAYLOAD = 'calculators_payload',
  CALCULATORS_PRICE = 'calculators_price',
  CALCULATORS_REEL = 'calculators_reel',
  CALCULATORS_ROLL = 'calculators_roll',
  // CALCULATORS_SOMERSET = 'calculators_somerset',
  CALCULATORS_SHEET = 'calculators_sheet',
  COMPLAINT_REPORTS = 'complaint_reports',
  CONSIGNMENT_REPORTING = 'consignment',
  CONSIGNMENT_CONSUMPTION = 'consignment_consumption',
  FINANCIAL_REPORTS = 'financial_reports',
  MILL_DIRECT = 'mill_direct',
  ORDER_BOOKING = 'order_booking',
  ORDER_SAMPLES = 'order_samples',
  PACKING_LIST_REPORTING = 'packing_list_reporting',
  POLICIES = 'policies',
  PRINT_LABELS = 'print_labels',
  PRINT_DOCUMENTS = 'print_documents',
  PRODUCT_AVAILABILITY_NA = 'product_availability_na',
  PRODUCT_AVAILABILITY_EU = 'product_availability_eu',
  SAVED_ENQUIRIES = 'saved_enquiries',
  STOCK_AVAILABILITY_EU = 'stock_availability_eu',
  STOCK_BOOK = 'stock_book',
  STOCK_BOOK_REL = 'stock_book_rel',
  STOCK_LOT = 'stock_lot',
  TRACK_BY_LABEL = 'track_by_label',
  TRACK_ORDERS = 'track_orders',
  QUICK_ORDER = 'quick_order',
}

export default QuickLinkId;
