import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { forOwn } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import { Button } from '../../../../components/Button/Button';
import DeconstructedTable from '../../../../components/DeconstructedTable/DeconstructedTable';
import DeconstructedTableBody from '../../../../components/DeconstructedTable/DeconstructedTableBody';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import DeconstructedTableBodyRow from '../../../../components/DeconstructedTable/DeconstructedTableBodyRow';
import DeconstructedTableHead from '../../../../components/DeconstructedTable/DeconstructedTableHead';
import DeconstructedTableHeadCell from '../../../../components/DeconstructedTable/DeconstructedTableHeadCell';
import DeconstructedTableHeadRow from '../../../../components/DeconstructedTable/DeconstructedTableHeadRow';
import DeconstructedTablePagination, {
  IDeconstructedTablePaginationProps,
} from '../../../../components/DeconstructedTable/DeconstructedTablePagination';
import useAnalytics from '../../../../hooks/useAnalytics';
import useCustomer from '../../../../hooks/useCustomer';
import {
  consumeConsignment,
  CONSUMED_INPUT_PREFIX,
} from '../../Consignment.service';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import ConsignmentInventorySubmittedState from '../../types/ConsignmentInventorySubmittedState.enum';
import { ConsignmentInventoryTableColumns } from '../../types/ConsignmentInventoryTableColumns.interface';
import useConsignmentInventoryStore from '../useConsignmentInventoryStore.store';
import {
  convertSelectedItemsToPoRequest,
  getSelectedInventory,
} from './ConsignmentInventoryTable.util';
import ConsignmentInventoryTableItem from './ConsignmentInventoryTableItem';
import TableBrandCell from './TableBrandCell';
import TableDimensionsCell from './TableDimensionsCell';
import TableMaterialNumCell from './TableMaterialNumCell';
import TableOrderNumCell from './TableOrderNumCell';
import TableSappiMaterialNumCell from './TableSappiMaterialNumCell';
import TableShippingInformationCell from './TableShippingInformationCell';
import TableStatusCell from './TableStatusCell';
import TableUnitsCell from './TableUnitsCell';
import TableWeightCell from './TableWeightCell';
import styles from './ConsignmentInventoryTable.module.scss';
import ConsignmentExpandCollapseInfo from '../../types/ConsignmentExpandCollapseInfo.interface';

interface IConsignmentInventoryTableProps {
  consignmentInventory: ConsignmentInventoryItem[];
  isPoRequired: boolean;
  paginationProps?: IDeconstructedTablePaginationProps;
  isError?: boolean;
  expandCollapseInfo: ConsignmentExpandCollapseInfo;
}

const ConsignmentInventoryTable: React.FunctionComponent<IConsignmentInventoryTableProps> = ({
  consignmentInventory,
  isPoRequired,
  paginationProps,
  isError,
  expandCollapseInfo,
}) => {
  const {
    setSubmittedStates,
    isAnySubmitting,
    clearSubmittedInventory,
    addInventoryToSubmitted,
  } = useConsignmentInventoryStore();
  const { trackPageView } = useAnalytics();
  const { getCustomerByNumber } = useCustomer();
  const columns: ConsignmentInventoryTableColumns = [
    {
      title: <Trans>Brand</Trans>,
      key: 'brand',
      render: (consignmentInventoryItem) => (
        <TableBrandCell item={consignmentInventoryItem} />
      ),
    },
    {
      title: <Trans>Dimensions</Trans>,
      key: 'dimensions',
      render: (consignmentInventoryItem) => (
        <TableDimensionsCell item={consignmentInventoryItem} />
      ),
    },
    {
      title: <Trans>Material #</Trans>,
      key: 'materialNum',
      render: (consignmentInventoryItem) => (
        <TableMaterialNumCell item={consignmentInventoryItem} />
      ),
    },
    {
      title: <Trans>Status</Trans>,
      key: 'status',
      render: (consignmentInventoryItem) => (
        <TableStatusCell item={consignmentInventoryItem} />
      ),
    },
    {
      title: <i className="fa fa-truck" />,
      key: 'shippingInformation',
      render: (consignmentInventoryItem) => (
        <TableShippingInformationCell item={consignmentInventoryItem} />
      ),
    },
    {
      title: <Trans>Order #</Trans>,
      key: 'orderNum',
      render: (consignmentInventoryItem) => (
        <TableOrderNumCell item={consignmentInventoryItem} />
      ),
    },
    {
      title: <Trans>Units</Trans>,
      key: 'units',
      render: (consignmentInventoryItem) => (
        <TableUnitsCell item={consignmentInventoryItem} />
      ),
    },
    {
      title: <Trans>Weight</Trans>,
      key: 'weight',
      render: (consignmentInventoryItem) => (
        <TableWeightCell item={consignmentInventoryItem} />
      ),
    },
  ];

  const methods = useForm({
    mode: 'onChange',
    shouldFocusError: false,
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isValid, isDirty },
  } = methods;

  const submitRow = async (
    checkboxes: boolean[],
    detailRows: ConsignmentInventoryItem[],
    customerNumber: string,
    rowKey: string,
    rowIndex: number,
    poNumber?: string
  ) => {
    try {
      setSubmittedStates(rowKey, ConsignmentInventorySubmittedState.SUBMITTING);
      await consumeConsignment(
        convertSelectedItemsToPoRequest(
          checkboxes,
          detailRows,
          getCustomerByNumber(customerNumber)?.name || '',
          poNumber
        )
      );
      setSubmittedStates(rowKey, ConsignmentInventorySubmittedState.SUBMITTED);
      const selectedInventory = getSelectedInventory(
        checkboxes,
        detailRows,
        poNumber
      );
      if (selectedInventory) {
        addInventoryToSubmitted(selectedInventory);
      }
      setValue(
        `${CONSUMED_INPUT_PREFIX}${rowIndex}`,
        new Array(detailRows.length).fill(null),
        {
          shouldValidate: true,
          shouldDirty: false,
        }
      );
      reset(getValues());
    } catch (e) {
      setSubmittedStates(rowKey, ConsignmentInventorySubmittedState.ERRORED);
    }
  };
  const onSubmitAll = handleSubmit(async (data) => {
    clearSubmittedInventory();
    forOwn(data, async (value: unknown, key) => {
      if (key.startsWith(CONSUMED_INPUT_PREFIX)) {
        const dashIndex = key.indexOf('-');
        const rowIndex = +key.substr(dashIndex + 1);
        const poNumber = data?.poNumber ? data?.poNumber[rowIndex] : null;
        const checkboxes = value as boolean[];
        const row = consignmentInventory[rowIndex];
        const firstDetailRow = row.details[0];
        await submitRow(
          checkboxes,
          row.details,
          firstDetailRow.customerNumber,
          `${firstDetailRow.documentNumber}-${firstDetailRow.salesDocumentItem}`,
          rowIndex,
          poNumber
        );
      }
    });

    trackPageView('CONSIGNMENT', 'SUBMIT_ALL');
  });

  return (
    <div>
      {paginationProps && (
        <Space size="large" className="justify-between w-full my-4">
          <Space size="large">
            <Button
              theme="link"
              onClick={expandCollapseInfo.onToggleExpandAllState}
              className="mr-4"
            >
              {expandCollapseInfo.expandAllIsExpanded ? (
                <Trans>Close All Details</Trans>
              ) : (
                <Trans>Expand All Details</Trans>
              )}
            </Button>
          </Space>
          <Space size="large">
            <DeconstructedTablePagination {...paginationProps} />
            <a
              className="app-link"
              title={t`Export to Microsoft Excel`}
              href="../resources/consignment/excel?withDetails=true"
              onClick={() =>
                trackPageView('CONSIGNMENT', 'DOWNLOAD_DETAIL')
              }
            >
              <i
                className="fa fa-file-excel-o text-2xl mr-2"
                aria-hidden="true"
              />
              <span className="text-lg">
                <Trans>Download Detail</Trans>
              </span>
            </a>
            <a
              className="app-link"
              title={t`Export to Microsoft Excel`}
              href="../resources/consignment/excel?withDetails=false"
              onClick={() =>
                trackPageView('CONSIGNMENT', 'DOWNLOAD_SUMMARY')
              }
            >
              <i
                className="fa fa-file-excel-o text-2xl mr-2"
                aria-hidden="true"
              />
              <span className="text-lg">
                <Trans>Download Summary</Trans>
              </span>
            </a>
          </Space>
        </Space>
      )}
      <FormProvider {...methods}>
        <DeconstructedTable noStripes>
          <DeconstructedTableHead>
            <DeconstructedTableHeadRow>
              {columns.map((column) => (
                <DeconstructedTableHeadCell key={column.key}>
                  {column.title}
                </DeconstructedTableHeadCell>
              ))}
            </DeconstructedTableHeadRow>
          </DeconstructedTableHead>
          <DeconstructedTableBody className={styles.tableBody}>
            {isError ? (
              <DeconstructedTableBodyRow>
                <DeconstructedTableBodyCell colSpan={columns.length}>
                  <div>
                    <Trans>
                      An error has occurred, please try again later.
                    </Trans>
                  </div>
                </DeconstructedTableBodyCell>
              </DeconstructedTableBodyRow>
            ) : (
              <>
                {consignmentInventory.length > 0 ? (
                  consignmentInventory.map(
                    (consignmentInventoryItem, index) => (
                      <ConsignmentInventoryTableItem
                        key={consignmentInventoryItem.itemNumber}
                        item={consignmentInventoryItem}
                        columns={columns}
                        isPoRequired={isPoRequired}
                        rowIndex={index}
                        detailsExpanded={expandCollapseInfo.isItemExpanded(
                          consignmentInventoryItem.itemNumber
                        )}
                        onExpandDetails={expandCollapseInfo.onExpandItem}
                        onCollapseDetails={expandCollapseInfo.onCollapseItem}
                      />
                    )
                  )
                ) : (
                  <DeconstructedTableBodyRow>
                    <DeconstructedTableBodyCell colSpan={columns.length}>
                      <Trans>No records found</Trans>
                    </DeconstructedTableBodyCell>
                  </DeconstructedTableBodyRow>
                )}
              </>
            )}
          </DeconstructedTableBody>
        </DeconstructedTable>
        <div className="w-full text-right mt-4">
          <Button
            theme="primary"
            disabled={!isValid || !isDirty || isAnySubmitting}
            loading={isAnySubmitting}
            onClick={onSubmitAll}
          >
            <Trans>Submit All</Trans>
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default ConsignmentInventoryTable;
