import { t } from '@lingui/macro';
import { capitalize } from 'lodash';
import { Moment } from 'moment';
import React, { FC } from 'react';
import InputLabel from '../../../components/InputLabel';
import { DateFormat } from '../../../hooks/useDateFormatter';

interface IReviewStepArrivalDatesCellProps {
  arrivalDate: Moment | null;
  departureDate: Moment | null;
}

const ReviewStepArrivalDatesCell: FC<IReviewStepArrivalDatesCellProps> = ({
  arrivalDate,
  departureDate,
}) => {
  return (
    <>
      <InputLabel text={t`New Arrival`} size="lg">
        {capitalize(arrivalDate?.format(DateFormat.BASIC_WITH_TIME))}
      </InputLabel>
      {departureDate && (
        <InputLabel text={t`New Departure`} size="lg">
          {capitalize(departureDate?.format(DateFormat.BASIC_WITH_TIME))}
        </InputLabel>
      )}
    </>
  );
};

export default ReviewStepArrivalDatesCell;
