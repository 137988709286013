import { Output } from '../types/Output.interface';
import { UtilizationRow } from '../types/UtilizationRow.interface';

export const mapOutputRowsToUtilizationRows = (
  outputRows: Output[]
): UtilizationRow[] => {
  const utilizationRows: UtilizationRow[] = [];
  let containerNumber = 1;
  outputRows.forEach((row) => {
    for (let i = 0; i < row.numberOfContainers; i++) {
      utilizationRows.push({
        containerNumber: containerNumber++,
        units: row.numberOfPallets,
        weight: row.containerWeight,
        utilized: row.percentUsed,
        full: row.full === 'X',
        description: row.content,
      });
    }
  });
  return utilizationRows;
};
