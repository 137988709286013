import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import moment, { Moment } from 'moment';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Select from '../../../../components/Select/Select';
import useValidator from '../../../../hooks/useValidator';
import { EMPTY_OPTION_SELECT } from '../../../../services/Util/emptyOption.const';
import positiveIntegerOnlyInput from '../../../../services/Util/positiveIntegerOnlyInput.util';
import Option from '../../../../types/Option.type';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import ControlledDatePicker from '../../../../components/DatePicker/ControlledDatePicker';
import useCorrectMomentFormat from '../../../../hooks/useCorrectMomentFormat';

interface IFormRightHalfProps {
  errors: UseFormMethods['errors'];
  getValues: UseFormMethods['getValues'];
  register: UseFormMethods['register'];
  fetchingBrands: boolean;
  productOptions: Option[];
  caliperOptions: Option[];
  bookWeightOptions: Option[];
  grammageOptions: Option[];
  isNaUser: boolean;
  isMetric: boolean;
  control: UseFormMethods['control'];
}

const FormRightHalf: React.FC<IFormRightHalfProps> = ({
  errors,
  getValues,
  register,
  fetchingBrands,
  productOptions,
  caliperOptions,
  bookWeightOptions,
  grammageOptions,
  isNaUser,
  isMetric,
  control,
}) => {
  const { validateStringIsNum } = useValidator();

  const PRODUCT_TYPE_OPTIONS: Option[] = [
    EMPTY_OPTION_SELECT,
    { label: t`Sheet`, value: PutUpCategory.SHEET },
    { label: t`Roll`, value: PutUpCategory.ROLL },
  ];

  const IDS = {
    product: 'product',
    caliper: 'caliper',
    caliperMils: 'caliperMils',
    grammage: 'grammage',
    widthMin: 'width-min',
    widthMax: 'width-max',
    widthMinInches: 'width-min-inches',
    widthMaxInches: 'width-max-inches',
    olderThan: 'older-than',
    productType: 'product-type',
    bookweight: 'book-weight',
    lengthMin: 'length-min',
    lengthMax: 'length-max',
    lengthMinInches: 'length-min-inches',
    lengthMaxInches: 'length-max-inches',
    invoicedDateFrom: 'invoicedDateFrom',
    invoicedDateTo: 'invoicedDateTo',
  };
  
  const { momentFormat } = useCorrectMomentFormat();
  
  const invoicedChanged = () => {
    const values = getValues();
    
    if(!values.invoiced){
      values.invoicedDateFrom = "";
      values.invoicedDateTo = "";
    }
  };
  
  const isDateFromAfterDateTo = () => {
    const values = getValues();
    
    if (values.invoiced && values.invoicedDateFrom && values.invoicedDateTo) {
      return !(values.invoicedDateFrom as Moment).isAfter(values.invoicedDateTo as Moment);
    }
    return true;
  };
  
  const getInvoicedFromDate = () => {
    const currentInvoicedFromDate = getValues('invoicedDateFrom');
    if (currentInvoicedFromDate) {
      return momentFormat(currentInvoicedFromDate as Moment);
    }
    return '';
  };

  const requireAtLeastOneStatus = (): boolean => {
    const values = getValues();
    return values.inStock || values.inTransit || values.onOrder || values.invoiced;
  };
  
  return (
    <div className="w-1/2">
      <div className="flex">
        <div className="w-1/2">
          <fieldset className="mb-10">
            <legend className="mb-2.5 text-xl font-extrabold">
              <Trans>Status</Trans>
            </legend>
            <div className="flex">
              <Checkbox
                name="inStock"
                ref={register({
                  validate: requireAtLeastOneStatus,
                })}
                label={<Trans>In Stock</Trans>}
                className="mr-6"
                disabled={getValues("invoiced")}
              />
              <Checkbox
                name="inTransit"
                ref={register({
                  validate: requireAtLeastOneStatus,
                })}
                label={<Trans>In Transit</Trans>}
                className="mr-6"
                disabled={getValues("invoiced")}
              />
              <Checkbox
                name="onOrder"
                ref={register({
                  validate: requireAtLeastOneStatus,
                })}
                label={<Trans>On Order</Trans>}
                className="mr-6"
                disabled={getValues("invoiced")}
              />
              <Checkbox
                onChange={invoicedChanged}
                name="invoiced"
                ref={register({
                  validate: requireAtLeastOneStatus,
                })}
                label={<Trans>Invoiced</Trans>}
                className="mr-6"
              />
            </div>
          </fieldset>
        </div>
          { getValues("invoiced") && (
            <div className="w-1/2">
              <fieldset className="mb-7">
                <legend className="mb-2.5 text-xl font-extrabold">
                  <Trans>Include Invoiced Date Parameter</Trans>
                </legend>
                <div className="flex">
                  <ControlledDatePicker
                    name="invoicedDateFrom"
                    control={control}
                    defaultValue={null}
                    required={getValues("invoiced")}
                    hasError={!isDateFromAfterDateTo()}
                  />
                  <span className="px-3 pt-1 text-lg">
                    <Trans>to</Trans>
                  </span>
                  <ControlledDatePicker
                    name="invoicedDateTo"
                    control={control}
                    defaultValue={null}
                    required={getValues("invoiced")}
                    hasError={!isDateFromAfterDateTo()}
                    toolTipErrorMsg={
                      <div>
                        <Trans>Date must be after</Trans> &nbsp;
                        {getInvoicedFromDate()}
                      </div>
                    }
                  />
                </div>
              </fieldset>
            </div>
          )}
      </div>
      <div className="flex">
        <div className="w-1/2">
          <div className="mb-7">
            <InputLabel
              text={
                <Space>
                  <Trans>Product</Trans>
                  {fetchingBrands && <LoadingSpinner />}
                </Space>
              }
              size="lg"
              htmlFor={IDS.product}
            />
            <Select
              name="product"
              ref={register()}
              options={productOptions}
              id={IDS.product}
            />
          </div>
          <>
            <div className="mb-7">
              <InputLabel
                text={
                  <Space>
                    <Trans>Caliper</Trans>
                    {fetchingBrands && <LoadingSpinner />}
                  </Space>
                }
                size="lg"
                htmlFor={IDS.caliper}
              />
              { !isMetric && (
                <Select
                  name="caliper"
                  ref={register()}
                  options={caliperOptions}
                  id={IDS.caliper}
                />
              )}
              { isMetric && (
                <Select
                  name="caliperMils"
                  ref={register()}
                  options={caliperOptions}
                  id={IDS.caliperMils}
                />
              )}
            </div>
            <fieldset className="mb-7">
              <legend className="mb-2.5 text-xl font-extrabold">
                <Trans>Width</Trans>
              </legend>
              <div className="flex items-center">
                { !isMetric && (
                  <div>
                    <InputLabel
                      text={<Trans>Minimum width</Trans>}
                      htmlFor={IDS.widthMinInches}
                      visuallyHidden
                    />
                    <Input
                      type="text"
                      name="widthMinInches"
                      ref={register({
                        validate: {
                          isNumber: validateStringIsNum,
                        },
                      })}
                      id={IDS.widthMinInches}
                      width="28"
                      error={errors.widthMinInches}
                    />
                  </div>
                )}
                { isMetric && (
                  <div>
                    <InputLabel
                      text={<Trans>Minimum width</Trans>}
                      htmlFor={IDS.widthMin}
                      visuallyHidden
                    />
                    <Input
                      type="text"
                      name="widthMin"
                      ref={register({
                        validate: {
                          isNumber: validateStringIsNum,
                        },
                      })}
                      id={IDS.widthMin}
                      width="28"
                      error={errors.widthMin}
                    />
                  </div>
                )}
                <div className="mx-2">to</div>
                { !isMetric && (
                  <div>
                    <InputLabel
                      text={<Trans>Maximum width</Trans>}
                      htmlFor={IDS.widthMaxInches}
                      visuallyHidden
                    />
                    <Input
                      type="text"
                      name="widthMaxInches"
                      ref={register({
                        validate: {
                          isNumber: validateStringIsNum,
                        },
                      })}
                      id={IDS.widthMaxInches}
                      width="28"
                      error={errors.widthMaxInches}
                    />
                  </div>
                )}
                { isMetric && (
                  <div>
                    <InputLabel
                      text={<Trans>Maximum width</Trans>}
                      htmlFor={IDS.widthMax}
                      visuallyHidden
                    />
                    <Input
                      type="text"
                      name="widthMax"
                      ref={register({
                        validate: {
                          isNumber: validateStringIsNum,
                        },
                      })}
                      id={IDS.widthMax}
                      width="28"
                      error={errors.widthMax}
                    />
                  </div>
                )}
              </div>
            </fieldset>
          </>
          <div className="mb-7">
            <InputLabel
              text={<Trans>Inventory Older Than</Trans>}
              size="lg"
              htmlFor={IDS.olderThan}
            />
            <div className="flex items-center">
              <Input
                type="text"
                name="olderThan"
                ref={register()}
                onKeyDown={positiveIntegerOnlyInput}
                id={IDS.olderThan}
              />
              <div className="ml-2">
                <Trans>Days</Trans>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <div className="mb-7">
            <InputLabel
              text={<Trans>Product Type</Trans>}
              size="lg"
              htmlFor={IDS.productType}
            />
            <Select
              name="productType"
              ref={register()}
              options={PRODUCT_TYPE_OPTIONS}
              id={IDS.productType}
            />
          </div>
          { !isMetric && (
            <div className="mb-7">
              <InputLabel
                text={
                  <Space>
                    <Trans>Basis Weight</Trans>
                    {fetchingBrands && <LoadingSpinner />}
                  </Space>
                }
                size="lg"
                htmlFor={IDS.bookweight}
              />
              <Select
                name="grammage"
                ref={register()}
                options={bookWeightOptions}
                id={IDS.grammage}
              />
            </div>
          )}
          { isMetric && (
            <div className="mb-7">
              <InputLabel
                text={
                  <Space>
                    <Trans>Grammage</Trans>
                    {fetchingBrands && <LoadingSpinner />}
                  </Space>
                }
                size="lg"
                htmlFor={IDS.grammage}
              />
              <Select
                name="grammage"
                ref={register()}
                options={grammageOptions}
                id={IDS.grammage}
              />
            </div>
          )}
          <fieldset className="mb-7">
            <legend className="mb-2.5 text-xl font-extrabold">
              <Trans>Length</Trans>
            </legend>
            <div className="flex items-center">
              { !isMetric && (
                <div>
                  <InputLabel
                    text={<Trans>Minimum length</Trans>}
                    htmlFor={IDS.lengthMinInches}
                    visuallyHidden
                  />
                  <Input
                    type="text"
                    name="lengthMinInches"
                    ref={register({
                      validate: {
                        isNumber: validateStringIsNum,
                      },
                    })}
                    id={IDS.lengthMinInches}
                    width="28"
                    error={errors.lengthMinInches}
                  />
                </div>
              )}
              { isMetric && (
                <div>
                  <InputLabel
                    text={<Trans>Minimum length</Trans>}
                    htmlFor={IDS.lengthMin}
                    visuallyHidden
                  />
                  <Input
                    type="text"
                    name="lengthMin"
                    ref={register({
                      validate: {
                        isNumber: validateStringIsNum,
                      },
                    })}
                    id={IDS.lengthMin}
                    width="28"
                    error={errors.lengthMin}
                  />
                </div>
              )}
              <div className="mx-2">to</div>
              { !isMetric && (
                <div>
                  <InputLabel
                    text={<Trans>Maximum length</Trans>}
                    htmlFor={IDS.lengthMaxInches}
                    visuallyHidden
                  />
                  <Input
                    type="text"
                    name="lengthMaxInches"
                    ref={register({
                      validate: {
                        isNumber: validateStringIsNum,
                      },
                    })}
                    id={IDS.lengthMaxInches}
                    width="28"
                    error={errors.lengthMaxInches}
                  />
                </div>
              )}
              { isMetric && (
                <div>
                  <InputLabel
                    text={<Trans>Maximum length</Trans>}
                    htmlFor={IDS.lengthMax}
                    visuallyHidden
                  />
                  <Input
                    type="text"
                    name="lengthMax"
                    ref={register({
                      validate: {
                        isNumber: validateStringIsNum,
                      },
                    })}
                    id={IDS.lengthMax}
                    width="28"
                    error={errors.lengthMax}
                  />
                </div>
              )}
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default FormRightHalf;
