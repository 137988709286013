import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { FieldError, UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import positiveIntegerOnlyInput from '../../../../services/Util/positiveIntegerOnlyInput.util';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import CustomerBasketFormData from './types/CustomerBasketFormData.interface';
import CustomerBasketFormField from './types/CustomerBasketFormField.enum';

const DIMENSION_FIELD_CONSTRAINTS = {
  min: 1,
  max: 999999999,
};

interface OrderDetailsCellContentProps {
  itemProductType: AvailabilityBasketItem['productType'];
  itemRequestedUnitOfMeasure: AvailabilityBasketItem['requestedUnitOfMeasure'];
  formMethods: UseFormMethods<CustomerBasketFormData>;
  itemIndex: number;
}

const OrderDetailsCellContent: FC<OrderDetailsCellContentProps> = ({
  itemProductType,
  itemRequestedUnitOfMeasure,
  formMethods,
  itemIndex,
}) => {
  const { isMetric } = useMeasurementSystem();
  const isRoll = itemProductType === PutUpCategory.ROLL;
  const isSheet = itemProductType === PutUpCategory.SHEET;
  const isPallet = itemRequestedUnitOfMeasure === UnitOfMeasure.PL;
  const { errors, register } = formMethods;

  const getName = (fieldName: CustomerBasketFormField): string => {
    return `items.${itemIndex}.${fieldName}`;
  };

  const getError = (
    fieldName: CustomerBasketFormField
  ): FieldError | undefined => {
    // This type assertion is necessary because having an object as a field
    // value (our date picker component uses a moment object as its value)
    // appears to break React Hook Form's error nesting logic, resulting in type
    // errors here without an assertion. Note that this function might fail if
    // called on a date picker field.
    return errors?.items?.[itemIndex]?.[fieldName] as FieldError | undefined;
  };

  return (
    <Space>
      {isRoll && !isMetric && (
        <InputLabel text={t`Roll Width (in)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.REEL_WIDTH_IMP)}
            ref={register({
              required: true,
              ...DIMENSION_FIELD_CONSTRAINTS,
            })}
            error={getError(CustomerBasketFormField.REEL_WIDTH_IMP)}
            required
          />
        </InputLabel>
      )}
      {isRoll && isMetric && (
        <InputLabel text={t`Roll Width (cm)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.REEL_WIDTH)}
            ref={register({ required: true, ...DIMENSION_FIELD_CONSTRAINTS })}
            error={getError(CustomerBasketFormField.REEL_WIDTH)}
            required
          />
        </InputLabel>
      )}
      {isRoll && !isMetric && (
        <InputLabel text={t`Roll Diameter (in)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.REEL_DIAMETER_IMP)}
            ref={register({ required: true, ...DIMENSION_FIELD_CONSTRAINTS })}
            error={getError(CustomerBasketFormField.REEL_DIAMETER_IMP)}
            required
          />
        </InputLabel>
      )}
      {isRoll && isMetric && (
        <InputLabel text={t`Roll Diameter (cm)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.REEL_DIAMETER)}
            ref={register({ required: true, ...DIMENSION_FIELD_CONSTRAINTS })}
            error={getError(CustomerBasketFormField.REEL_DIAMETER)}
            required
          />
        </InputLabel>
      )}
      {isRoll && !isMetric && (
        <InputLabel text={t`Core Diameter (in)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.CORE_DIAMETER_IMP)}
            ref={register({ required: true, ...DIMENSION_FIELD_CONSTRAINTS })}
            error={getError(CustomerBasketFormField.CORE_DIAMETER_IMP)}
            required
          />
        </InputLabel>
      )}
      {isRoll && isMetric && (
        <InputLabel text={t`Core Diameter (cm)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.CORE_DIAMETER)}
            ref={register({ required: true, ...DIMENSION_FIELD_CONSTRAINTS })}
            error={getError(CustomerBasketFormField.CORE_DIAMETER)}
            required
          />
        </InputLabel>
      )}
      {isSheet && !isMetric && (
        <InputLabel text={t`Width (in)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.WIDTH_IMP)}
            ref={register({ required: true, ...DIMENSION_FIELD_CONSTRAINTS })}
            error={getError(CustomerBasketFormField.WIDTH_IMP)}
            required
          />
        </InputLabel>
      )}
      {isSheet && isMetric && (
        <InputLabel text={t`Width (cm)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.WIDTH)}
            ref={register({ required: true, ...DIMENSION_FIELD_CONSTRAINTS })}
            error={getError(CustomerBasketFormField.WIDTH)}
            required
          />
        </InputLabel>
      )}
      {isSheet && !isMetric && (
        <InputLabel text={t`Length (in)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.LENGTH_IMP)}
            ref={register({ required: true, ...DIMENSION_FIELD_CONSTRAINTS })}
            error={getError(CustomerBasketFormField.LENGTH_IMP)}
            required
          />
        </InputLabel>
      )}
      {isSheet && isMetric && (
        <InputLabel text={t`Length (cm)`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.LENGTH)}
            ref={register({ required: true, ...DIMENSION_FIELD_CONSTRAINTS })}
            error={getError(CustomerBasketFormField.LENGTH)}
            required
          />
        </InputLabel>
      )}
      {isSheet && (
        <InputLabel text={t`Sheets per Pallet`} size="lg">
          <Input
            name={getName(CustomerBasketFormField.SHEETS_PER_PALLET_NA)}
            ref={register({ required: isPallet, min: 1, max: 999999 })}
            error={getError(CustomerBasketFormField.SHEETS_PER_PALLET_NA)}
            type="number"
            onKeyDown={positiveIntegerOnlyInput}
            required={isPallet}
          />
        </InputLabel>
      )}
    </Space>
  );
};

export default OrderDetailsCellContent;
