import { some } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getEuBasketStatus } from '../../../services/Basket';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import AvailabilityBasketEUStatus from '../../../types/AvailabilityBasketEUStatus.interface';
import { AvailabilityBasketItem } from '../../../types/AvailabilityBasketItem.interface';
import useBasketStore from './useBasketStore.store';

const useBasketStatusUpdate = (
  basket: AvailabilityBasketEU,
  refetchBaskets: () => void
): {
  statusUpdating: boolean;
  statusError: boolean;
} => {
  const [statusUpdating, setStatusUpdating] = useState(false);
  const [basketStatus, setBasketStatus] = useState<
    AvailabilityBasketEUStatus
  >();

  const [statusError, setStatusError] = useState(false);
  const location = useLocation();
  const {
    updateBasket,
    reloadBasketStatus,
    setReloadBasketStatus,
  } = useBasketStore();

  const refreshBasketStatus = useCallback(() => {
    const updateStatus = async () => {
      const euBasketStatus = await getEuBasketStatus(basket);
      setBasketStatus(euBasketStatus);
      setReloadBasketStatus(false);
    };
    updateStatus();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (
      reloadBasketStatus &&
      basket &&
      location.pathname.indexOf('/basket') !== -1
    ) {
      console.debug('useBasketStatusUpdate: Updating basket status.');
      setStatusUpdating(true);
      setStatusError(false);

      let needsUpdate: AvailabilityBasketItem | undefined;
      basket.items.forEach((item) => {
        if (!item.responseCode || item.responseCode === '002') {
          needsUpdate = item;
        }
      });

      if (needsUpdate !== undefined) {
        try {
          refreshBasketStatus();
        } catch (e) {
          setStatusError(true);
        }
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [reloadBasketStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    console.log('basketStatus:', basketStatus);
    console.debug(
      'useBasketStatusUpdate: Got completion from basket update call.'
    );
    if (basketStatus?.responseCode === '002') {
      setStatusUpdating(true);
      console.debug(
        'useBasketStatusUpdate: Updating updates not done. Will check again in 5 seconds.'
      );
      setTimeout(refreshBasketStatus, 5000); // Updating updates not done. Will check again in 5 seconds.');
    } else if (basket) {
      if (basket.soldTo) {
        updateBasket(basket);
      }
      if (basketStatus?.responseCode === '000') {
        console.log('refetched basket');
        refetchBaskets();
      }

      // pre-calculated error flag will make sure the value reflected in statusError immediately
      const hasStatusError = some(
        basketStatus?.basket.items,
        (item) => !item.confirmedDate
      );

      setStatusError(hasStatusError);
      setStatusUpdating(false);
      setReloadBasketStatus(false);
      console.debug('useBasketStatusUpdate: Basket update complete.');
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [basketStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    statusUpdating,
    statusError,
  };
};

export default useBasketStatusUpdate;
