import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import useRole from '../hooks/useRole';
import { AuthorityRole } from '../types/Authority.interface';

interface IAuthRouteProps {
  path: string;
  exact?: boolean;
  hasAll?: AuthorityRole[];
  hasOneOf?: AuthorityRole[];
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = ({
  path,
  exact,
  hasAll,
  hasOneOf,
  children,
}) => {
  const { roles = [], isFetching } = useRole();
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    if (roles.length > 0) {
      const hasAllRequiredRoles =
        hasAll &&
        hasAll.length &&
        hasAll.every((role) => find(roles, { authority: role }));
      const hasOneRequiredRole =
        hasOneOf &&
        hasOneOf.length &&
        hasOneOf.some((role) => find(roles, { authority: role }));
      const isAuthorizedToAccess = hasAllRequiredRoles || hasOneRequiredRole;
      if (!isAuthorizedToAccess) {
        console.error('Not authorized to access route');
      }
      setIsAuthorized(Boolean(isAuthorizedToAccess));
    }
  }, [roles, hasAll, hasOneOf]);

  return (
    <Route exact={exact} path={path}>
      {isAuthorized || roles.length === 0 ? (
        <>{children}</>
      ) : (
        <Redirect to={{ pathname: '/home' }} />
      )}
    </Route>
  );
};

export default AuthRoute;
