import { t } from '@lingui/macro';
import * as React from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { StockAvailabilityResult } from '../../../../types/StockAvailabilityResult.interface';

interface ICharacteristicsCellProps {
  record: StockAvailabilityResult;
}

const CharacteristicsCell: React.FunctionComponent<ICharacteristicsCellProps> = ({
  record,
}) => {
  return (
    <>
      {(record.txtWrappingType || record.txtReelWrapping) && (
        <LabelValue
          inverse
          inline
          label={t`Wrapping Type`}
          value={t`${record.txtWrappingType} ${record.txtReelWrapping}`}
        />
      )}
      {record.productType === PutUpCategory.SHEET && record.txtPackageType && (
        <LabelValue
          inverse
          inline
          label={t`Pallet Type`}
          value={t`${record.txtPackageType}`}
        />
      )}
      {!record.txtWrappingType &&
        !record.txtReelWrapping &&
        !record.txtPackageType && (
          <LabelValue inverse inline={false} label="" value="" />
        )}
    </>
  );
};

export default CharacteristicsCell;
