import { t } from '@lingui/macro';
import { isEmpty, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import { Order } from '../../../../types/Order.interface';
import ConsignmentStockStatus from '../../types/ConsignmentStockStatus.enum';
import { Button } from '../../../../components/Button/Button';
import ShippingInformationModal from '../../../../components/ShippingInformationModal/ShippingInformationModal';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { SearchOrderInputs } from '../../../TrackOrders/SearchOrderInputs.interface';
import { cancellableSearchOrders } from '../../../../services/Order';
import prepareFiltersForQuery, { getMonthsFromToday } from '../../../TrackOrders/TrackOrders.util';

interface ITableShippingInformationCellProps {
  item: ConsignmentInventoryItem;
}

const TableShippingInformationCell: React.FunctionComponent<ITableShippingInformationCellProps> = ({
  item
}) => {
  const [deliveryDetailsModalVisible, setDeliveryDetailsModalVisible] = useState<boolean>(false);
  
  const [deliveryDetailsOrder, setDeliveryDetailsOrder] = useState<Order>();

  const showShippingDetailsModal = (record: Order) => {
    setDeliveryDetailsModalVisible(true);
    setDeliveryDetailsOrder(record);
  };

  const onShippingDetailsClose = () => {
    setDeliveryDetailsModalVisible(false);
  };

  const [filters, setFilters] = useState<Partial<SearchOrderInputs>>({});

  useEffect(() => {
    const preparedFilters = prepareFiltersForQuery({
      customerNumber: item.customerNumber||'',
      customerOrderNumber: item.customerPurchaseOder||'',
      documentNumber: item.documentNumber||''
    });
    setFilters(preparedFilters);
  },[item]);

  const { resolvedData: orders, isFetching } = usePaginatedQuery(
    [QueryCacheName.ORDERS, filters || {}],
    cancellableSearchOrders,
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      enabled: !isEmpty(filters),
      keepPreviousData: true,
    }
  );
  
  return (
    <>
       <Button
         theme="link"
         onClick={() => showShippingDetailsModal(orders ? orders.results[0] : {} as Order)}
         title={`${t({
           id: 'order_list.group_order.transit_info',
           message: 'Transit information for order #',
          })}
          ${item.documentNumber}`
         }
       >
        {item.stockStatus === ConsignmentStockStatus.ON_ORDER && (
         <>
          <i className="fa fa-truck" />
         </>
        )}
        {item.stockStatus === ConsignmentStockStatus.IN_TRANSIT && (
         <>
          <i className="fa fa-truck" />
         </>
       )}
      </Button>
      
      <ShippingInformationModal
        visible             = {deliveryDetailsModalVisible}
        customerNumber      = {get(deliveryDetailsOrder, 'customerNumber', '')}
        shipToPartyName     = {get(deliveryDetailsOrder, 'overviews[0].shipToPartyName', '')}
        shipToPartyCity     = {get(deliveryDetailsOrder, 'overviews[0].shipToPartyCity','')}
        documentNumber      = {get(deliveryDetailsOrder, 'documentNumber', '')}
        customerOrderNumber = {get(deliveryDetailsOrder, 'customerOrderNumber','')}
        overviews           = {deliveryDetailsOrder?.overviews?.filter(overview => item.salesDocumentItem === overview.documentPosition) || []}
        onClose             = {onShippingDetailsClose}
      />
    </>
  );
};

export default TableShippingInformationCell;
