import Axios from 'axios';
import ValueKey from '../../types/ValueKey.enum';
import { ValueText } from '../../types/ValueText.interface';

export const getValuesFor = async (key: ValueKey): Promise<ValueText[]> => {
  const { data } = await Axios.get('/portal/resources/values', {
    params: {
      char: key,
    },
  });
  return data;
};
