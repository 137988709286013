import { omit } from 'lodash';
import { LoadPlan } from './types/LoadPlan.interface';
import { PayloadBasketRequest } from './types/PayloadBasketRequest.interface';
import { PayloadBasketResponse } from './types/PayloadBasketResponse.interface';
import { PayloadOrder } from './types/PayloadOrder.interface';

const NON_REQUEST_FIELDS = [
  'key',
  'brand',
  'grammage',
  'departureMill',
  'numberOf',
  'totalWeight',
  'productType',
  'active',
];

export const getPlansForContainerNumber = (
  number: number,
  results: PayloadBasketResponse | null
): LoadPlan[] => {
  if (results) {
    const { loadPlan } = results as PayloadBasketResponse;
    return loadPlan.filter((plan) => +plan.containerNumber === number);
  }
  return [];
};

export const payloadOrderToRequest = (
  order: PayloadOrder
): PayloadBasketRequest => ({
  ...(omit(order, NON_REQUEST_FIELDS) as PayloadBasketRequest),
});
