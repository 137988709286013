import * as React from 'react';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';

interface ITableUnitsCellProps {
  item: ConsignmentInventoryItem;
}

const TableUnitsCell: React.FunctionComponent<ITableUnitsCellProps> = ({
  item,
}) => {
  return <>{item.numberOfUnits}</>;
};

export default TableUnitsCell;
