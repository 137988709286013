import { Menu } from 'antd';
import React, { FC, Key, useEffect, useState } from 'react';
import { SappiMenuItem } from './Types/SappiMenuItem.interface';
import useRole from '../../hooks/useRole';
import { getSappiMenuItems } from './TopMenu.util';
import styles from './TopMenu.module.scss';

interface IMenuNavigationProps {
  menuItems: SappiMenuItem[];
  backToHome: boolean;
  onMenuItemSelect: (menuItemName: string, child: SappiMenuItem) => void;
}

const MenuNavigation: FC<IMenuNavigationProps> = ({
  menuItems,
  backToHome,
  onMenuItemSelect,
}) => {
  const { SubMenu } = Menu;
  const { roles } = useRole();

  const [openKeys, setOpenKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedKeys([]);
  }, [backToHome]);

  const onOpenChange = (keys: Key[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      selectedKeys={selectedKeys}
      mode="horizontal"
      className={`mr-1 ${styles.TopMenu}`}
    >
      {menuItems &&
        menuItems.map((menuItem) => (
          <SubMenu
            key={menuItem.id}
            title={menuItem.name}
            className="border-b border-gray-500"
          >
            {getSappiMenuItems(menuItem, roles) &&
              getSappiMenuItems(menuItem, roles).map((child) => (
                <Menu.Item
                  key={child.id}
                  className="whitespace-normal"
                  onClick={() => {
                    onMenuItemSelect(menuItem.name, child);
                    setSelectedKeys([child.id]);
                  }}
                >
                  {child.name}
                </Menu.Item>
              ))}
          </SubMenu>
        ))}
    </Menu>
  );
};

export default MenuNavigation;
