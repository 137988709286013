import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import { every, uniq } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../../hooks/useAnalytics';
import useRole from '../../../../hooks/useRole';
import { AuthorityRole } from '../../../../types/Authority.interface';
import { consumeConsignmentsForMultiplePOs } from '../../Consignment.service';
import { UploadedItem } from '../../types/UploadedItem.interface';
import {
  areSelectedIdsAlreadySubmitted,
  convertSelectedItemsToPoDelimitedRequests,
  getNonSubmittedRequests,
} from './Results.util';
import ResultsTable from './ResultsTable';

interface IResultsProps {
  results: UploadedItem[];
}

const Results: React.FunctionComponent<IResultsProps> = ({ results }) => {
  const { register, watch, setValue, handleSubmit } = useForm<{
    checkAll: boolean;
    rollPalletId: boolean[];
  }>({ mode: 'all' });
  const rollPalletIdsSelected = watch('rollPalletId');
  const [referenceNumbers, setReferenceNumbers] = useState<string[]>([]);
  const [submittedResults, setSubmittedResults] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { hasRole } = useRole();
  const { trackPageView } = useAnalytics();

  const onSubmit = handleSubmit(async (form) => {
    const requests = convertSelectedItemsToPoDelimitedRequests(
      form.rollPalletId,
      results
    );
    const nonSubmittedRequests = getNonSubmittedRequests(
      submittedResults,
      requests
    );
    try {
      setErrorMessage(null);
      setIsSubmitting(true);
      const result = await consumeConsignmentsForMultiplePOs(
        nonSubmittedRequests
      );
      trackPageView('CONSIGNMENT', 'SUBMIT_ALL', {
        customerId: results[0].selectedCustomerNumber,
      });
      setReferenceNumbers(result.map((r) => r.idocNumber));
      setSubmittedResults((state) =>
        uniq(state.concat(requests.flatMap((r) => r.barcodes)))
      );
    } catch (exception: any) {
      setReferenceNumbers([]);
      setErrorMessage(exception.message);
    } finally {
      setIsSubmitting(false);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Space size="large" direction="vertical" className="p-3">
        <div>
          <Trans>
            The results of the upload will be in black font if the item is valid
            and red font with the text “Non-consumable item” if the item is
            invalid. If there is a non-consumable item in your file, you can
            correct the file and repeat the upload process. Or you can proceed
            to creating a consumption order by checking the box next to the
            items and selecting the submit button.
          </Trans>
        </div>
        <ResultsTable
          results={results}
          submittedResults={submittedResults}
          register={register}
          setValue={setValue}
        />
        <Space size="large">
          {hasRole(AuthorityRole.ROLE_CONSIGN_SUBMIT) && (
            <Button
              type="submit"
              theme="primary"
              disabled={
                every(rollPalletIdsSelected, (isSelected) => !isSelected) ||
                areSelectedIdsAlreadySubmitted(
                  rollPalletIdsSelected,
                  submittedResults,
                  results
                ) ||
                isSubmitting
              }
            >
              Submit
            </Button>
          )}
          {isSubmitting ? (
            <LoadingSpinner />
          ) : (
            <>
              {!!referenceNumbers.length && (
                <div>
                  <Trans>
                    Your submission has been received. Reference Numbers:
                  </Trans>
                  <div>
                    <strong>{referenceNumbers.join(', ')}</strong>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="text-red-red">{errorMessage}</div>
        </Space>
      </Space>
    </form>
  );
};

export default Results;
