import { t, Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import useUser from '../../../hooks/useUser';
import AccountSection from '../AccountSection';
import UserInfoItem from './UserInfoItem';
import UserInfoRow from './UserInfoRow';

const UserInfo: FC = () => {
  const { data: userData } = useUser();
  const history = useHistory();

  return (
    <AccountSection headingText={t`User Information`} className="pb-14">
      <UserInfoRow>
        <UserInfoItem
          className="w-1/2"
          label={t`User ID`}
          value={userData?.username}
        />
        <UserInfoItem
          className="w-1/4"
          label={t`First Name`}
          value={userData?.firstName}
        />
        <UserInfoItem
          className="w-1/4"
          label={t`Last Name`}
          value={userData?.lastName}
        />
      </UserInfoRow>
      <UserInfoRow>
        <UserInfoItem
          className="w-1/2"
          label={t`E-mail Address`}
          value={userData?.email}
        />
        <UserInfoItem
          className="w-1/4"
          label={t`Phone`}
          value={userData?.phone}
        />
      </UserInfoRow>
      <div>
        <div className="mb-2.5 text-xl font-bold">
          <Trans>Password</Trans>
        </div>
        <div>
          <Button
            theme="link"
            onClick={() => history.push('/account/password')}
          >
            <Trans>Change Password</Trans>
          </Button>
        </div>
      </div>
    </AccountSection>
  );
};

export default UserInfo;
