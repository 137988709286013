import NumStringFormatDef from '../../types/NumStringFormatDef.interface';

export const naNumStringFormat: NumStringFormatDef = {
  regex: /(?:^|\s)([1-9](?:\d*|(?:\d{0,2})(?:,\d{3})*)(?:\.\d*[0-9])?|0?\.\d*[1-9]|0)(?:\s|$)/,
  separator: ',',
  decimal: '.',
};

export const euNumStringFormat: NumStringFormatDef = {
  regex: /(?:^|\s)([1-9](?:\d*|(?:\d{0,2})(?:\.\d{3})*)(?:,\d*[0-9])?|0?,\d*[1-9]|0)(?:\s|$)/,
  separator: '.',
  decimal: ',',
};

export const parseNumberString = (
  input: string,
  numFormatDef: NumStringFormatDef
): number => {
  const { regex, separator, decimal } = numFormatDef;

  // Ensure input matches expected format.
  if (!regex.test(input)) {
    return NaN;
  }

  // Strip out all instances of separator.
  let processed = input.split(separator).join('');

  // Replace all instances of dec with '.'.
  processed = processed.split(decimal).join('.');

  // Parse the processed string into a floating point number.
  return parseFloat(processed);
};
