import * as React from 'react';

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: React.ReactNode;
  labelPosition?: 'above' | 'side' | 'below';
};

const Radio = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, labelPosition: position = 'side', className, ...rest }, ref) => (
    <label
      className={`${position === 'above' ? 'flex flex-col items-center' : ''}`}
    >
      {position === 'above' && <div className="mb-3">{label}</div>}
      <input
        type="radio"
        className={`${className || ''} mr-2`}
        {...rest}
        ref={ref}
      />
      {position === 'side' && <>{label}</>}
      <div>{position === 'below' && <>{label}</>}</div>
    </label>
  )
);

/**
 * Component is described here.
 *
 * @example ../componentExamples/OtherInput.md
 */
export default Radio;
