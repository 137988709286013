import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import ControlledDatePicker from '../../../components/DatePicker/ControlledDatePicker';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import SimpleCard from '../../../components/SimpleCard/SimpleCard';
import { EMPTY_OPTION } from '../../../services/Util/emptyOption.const';
import Option from '../../../types/Option.type';
import ValueKey from '../../../types/ValueKey.enum';
import SearchFormField from '../types/SearchFormField.enum';
import { ISearchForm } from '../types/TSearchForm.type';
import useTrackAndTraceStore from '../useTrackAndTrace.store';
import useValueOptions from '../useValueOptions';
import AdvancedSearchForm from './AdvancedSearchForm';
import useForwardingAgentOptions from './useForwardingAgentOptions';

const ARRIVAL_STATE_OPTIONS: Option[] = [
  EMPTY_OPTION,
  {
    value: 'O',
    label: t`Open`,
  },
  {
    value: 'B',
    label: t`Reported back`,
  },
];
interface ISearchFormProps {
  style?: React.CSSProperties;
  onReportArrival: (formData: ISearchForm) => void;
  onReportDeviation: (formData: ISearchForm) => void;
}

const SearchForm: FC<ISearchFormProps> = ({
  style,
  onReportArrival,
  onReportDeviation,
}) => {
  const { forwardingAgentOptions } = useForwardingAgentOptions();
  const { valueOptions: shippingPointOptions } = useValueOptions(
    ValueKey.SHIPPING_POINT_TT
  );
  const { valueOptions: shipToPartyCountryOptions } = useValueOptions(
    ValueKey.LAND1
  );
  const defaultValues = useTrackAndTraceStore((state) => state.request);
  const { register, control, reset, handleSubmit, setValue } = useForm<
    ISearchForm
  >({
    mode: 'all',
    defaultValues: defaultValues || {},
  });

  const doReset = () => {
    setValue(SearchFormField.TRANSIT_DATE_FROM, undefined, {
      shouldValidate: true,
    });
    setValue(SearchFormField.TRANSIT_DATE_TO, undefined, {
      shouldValidate: true,
    });
    reset({});
  };
  return (
    <SimpleCard style={style}>
      <form>
        <Space direction="vertical" size="middle" className="w-full">
          <InputLabel size="lg" text={t`Forwarding Agent`}>
            <Select
              name={SearchFormField.FORWARDING_AGENT}
              ref={register()}
              options={forwardingAgentOptions}
            />
          </InputLabel>
          <InputLabel size="lg" text={t`Your CMR Number`}>
            <Input name={SearchFormField.DELIVERY_NOTE} ref={register()} />
          </InputLabel>
          <InputLabel size="lg" text={t`Sappi Trans ID`}>
            <Input name={SearchFormField.TRANSIT_ID} ref={register()} />
          </InputLabel>
          <InputLabel size="lg" text={t`Loading Date`}>
            <Space direction="vertical" size="middle">
              <div className="flex items-center justify-end">
                <div>
                  <Trans>From</Trans>
                </div>
                <ControlledDatePicker
                  name={SearchFormField.TRANSIT_DATE_FROM}
                  control={control}
                  className="ml-4 w-56"
                />
              </div>
              <div className="flex items-center justify-end">
                <div>
                  <Trans>To</Trans>
                </div>
                <ControlledDatePicker
                  name={SearchFormField.TRANSIT_DATE_TO}
                  control={control}
                  className="ml-4 w-56"
                />
              </div>
            </Space>
          </InputLabel>
          <InputLabel size="lg" text={t`Shipping Point`}>
            <Select
              name={SearchFormField.SHIPPING_POINT}
              ref={register()}
              options={shippingPointOptions}
            />
          </InputLabel>
          <InputLabel size="lg" text={t`Arrival State`}>
            <Select
              name={SearchFormField.ARRIVAL_STATE}
              ref={register()}
              options={ARRIVAL_STATE_OPTIONS}
            />
          </InputLabel>
          <InputLabel size="lg" text={t`Country of Ship-to-Party`}>
            <Select
              name={SearchFormField.SHIP_TO_COUNTRY}
              ref={register()}
              options={shipToPartyCountryOptions}
            />
          </InputLabel>
          <Button
            theme="link"
            className="no-underline font-bold"
            onClick={doReset}
          >
            <Trans id="clear_all">Clear All</Trans>
          </Button>
          <AdvancedSearchForm register={register} />

          <Space direction="vertical" size="middle" className="w-full">
            <Button
              type="button"
              theme="primary"
              width="full"
              onClick={handleSubmit(onReportArrival)}
            >
              <Trans>Report Arrival</Trans>
            </Button>
            <Button
              type="button"
              theme="primary"
              width="full"
              onClick={handleSubmit(onReportDeviation)}
            >
              <Trans>Report Deviation</Trans>
            </Button>
          </Space>
        </Space>
      </form>
    </SimpleCard>
  );
};

export default SearchForm;
