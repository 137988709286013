import * as React from 'react';

interface ISectionHeaderProps {
  className?: string;
  darkMode?: boolean;
}

const SectionHeader: React.FunctionComponent<ISectionHeaderProps> = ({
  className,
  darkMode,
  children,
}) => {
  const classes = `${className} text-xl font-bold my-2`;
  const darkModeClasses = `${className} bg-gray-dark200 text-white-white text-xl font-bold py-4 px-6`;
  return <div className={darkMode ? darkModeClasses : classes}>{children}</div>;
};

export default SectionHeader;
