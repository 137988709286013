import { Trans } from '@lingui/macro';
import { noop } from 'lodash';
import * as React from 'react';

interface IOldBasketButtonProps {
  disabled?: boolean;
  onCaretClick: () => void;
  onButtonClick: () => void;
}

const OldBasketButton: React.FunctionComponent<IOldBasketButtonProps> = ({
  disabled,
  onButtonClick,
  onCaretClick,
}) => {
  return (
    <div
      className={`flex text-white-white ${
        disabled
          ? 'bg-gray-dark300Disabled pointer-events-none'
          : 'bg-blue-pacific'
      }    text-base font-medium`}
      style={{
        padding: '1px 0',
        borderRadius: '3px',
        height: '28px',
        lineHeight: '28px',
      }}
    >
      <div
        className="border-r-2 border-white-white px-2"
        onClick={onButtonClick}
        role="button"
        onKeyDown={noop}
        aria-label="Go To Basket"
        tabIndex={0}
        aria-disabled={disabled}
      >
        <i className="fa fa-shopping-basket mr-2" />
        <Trans>Baskets</Trans>
      </div>
      <div
        className="px-2"
        onClick={onCaretClick}
        role="button"
        onKeyDown={noop}
        aria-label="Open Basket Popup"
        tabIndex={0}
        aria-disabled={disabled}
      >
        <i className="fa fa-caret-down" />
      </div>
    </div>
  );
};

export default OldBasketButton;
