import create, { State } from 'zustand';
import { DeviationForm } from './DeviationForm.interface';

interface DeviationDataState extends DeviationForm, State {}

const useDeviationDataStore = create<DeviationDataState>(() => ({
  deviationRow: [],
}));

export default useDeviationDataStore;
