import React, { FC } from 'react';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';
import useAnalytics from '../../hooks/useAnalytics';

const StockBook: FC = () => {
  const { trackPageView } = useAnalytics();
  const { hasRole } = useRole();
  
  const isNaStockBookShown = 
    hasRole(AuthorityRole.ROLE_NA) && 
    hasRole(AuthorityRole.ROLE_STOCK_VIEW) &&
    hasRole(AuthorityRole.ROLE_STOCK_REPORT) && 
    !hasRole(AuthorityRole.ROLE_RELEASE);
  
  const isReleaseStockBookShown = 
    hasRole(AuthorityRole.ROLE_RELEASE) && 
    hasRole(AuthorityRole.ROLE_STOCK_VIEW) &&
    hasRole(AuthorityRole.ROLE_STOCK_REPORT);
    
  const openStockBook = () => {
    trackPageView('STOCK_BOOK', 'DOWNLOAD');
    if (isNaStockBookShown) {
      window.open(
        'https://www.sappi.com/files/sappi-north-america-stock-bookpdf',
        'top'
      );
    }
    else if(isReleaseStockBookShown) {
      window.open(
        'https://www.sappi.com/textures',
        'top'
      );
    }
  };
  return (
    <button onClick={openStockBook} title="Download Stock Book PDF">
      <i className="fa fa-book" />
      <span className="sr-only">Stock Book</span>
    </button>
  );
};

export default StockBook;
