import create, { State } from 'zustand';
import { AugmentedSamplesHistoryOrder } from '../../types/Samples/AugmentedSamplesHistoryOrder.interface';

export interface SamplesOrderState extends AugmentedSamplesHistoryOrder, State {
  isAddItemToExistingOrder: boolean;
}

const useSamplesOrderStore = create<Partial<SamplesOrderState>>(() => ({
  isAddItemToExistingOrder: false,
}));

export default useSamplesOrderStore;
