import React, { FC } from 'react';
import './BasketSummaryColumn.scss';

interface IBasketSummaryColumnProps {
  title: string;
  className?: string;
}

const BasketSummaryColumn: FC<IBasketSummaryColumnProps> = ({
  title,
  className = '',
  children,
}) => {
  return (
    <div className={`basket-summary-column ${className}`}>
      <div className="p-5 text-lg font-bold border-b border-gray-light400 label-colon">
        {title}
      </div>
      <div className="flex p-5">{children}</div>
    </div>
  );
};

export default BasketSummaryColumn;
