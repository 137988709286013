import { Trans } from '@lingui/macro';
import React, { FC, useState } from 'react';
import Tabs, { TabPane } from '../../../components/Tabs/Tabs';
import PalletHeightAndWeight from '../../Calculators/PalletHeightAndWeight/PalletHeightAndWeight';
import RollWeightAndLengthCalculator from '../../Calculators/RollWeightAndLength/RollWeightAndLengthCalculator';
import { CALCULATOR_TAB_LABELS } from '../../Calculators/types/Calculator.constants';
import EuCalculatorsTab from '../../Calculators/types/EuCalculatorsTab.enum';
import styles from './HeaderCalculators.module.scss';
import HeaderMetricConversionCalculators from './HeaderMetricConversionCalculators';

const EuCalculators: FC = () => {
  const [activeTab, setActiveTab] = useState<EuCalculatorsTab>();

  const safelySetActiveTab = (key: string): void => {
    if (Object.values<string>(EuCalculatorsTab).includes(key)) {
      setActiveTab(key as EuCalculatorsTab);
    }
  };

  return (
    <Tabs
      type="card"
      activeKey={activeTab}
      onChange={(key) => safelySetActiveTab(key)}
      className={styles.calculatorTabs}
    >
      <TabPane
        tab={CALCULATOR_TAB_LABELS[EuCalculatorsTab.METRIC_CONVERSIONS]}
        key={EuCalculatorsTab.METRIC_CONVERSIONS}
      >
        <HeaderMetricConversionCalculators />
      </TabPane>
      <TabPane
        tab={<Trans>Paper Math</Trans>}
        key={EuCalculatorsTab.PAPER_MATH}
      >
        <div className="flex justify-center">
          <RollWeightAndLengthCalculator />
        </div>
      </TabPane>
      <TabPane
        tab={CALCULATOR_TAB_LABELS[EuCalculatorsTab.PALLET_HEIGHT_WEIGHT]}
        key={EuCalculatorsTab.PALLET_HEIGHT_WEIGHT}
      >
        <div className="flex justify-center">
          <PalletHeightAndWeight />
        </div>
      </TabPane>
    </Tabs>
  );
};

export default EuCalculators;
