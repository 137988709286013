import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs, { TabPane } from '../../components/Tabs/Tabs';
import useLocationQueryParams from '../../hooks/useLocationQueryParams';
import LengthConversionCalculator from './LengthConversion/LengthConversionCalculator';
import PalletHeightAndWeight from './PalletHeightAndWeight/PalletHeightAndWeight';
import PayloadCalculator from './Payload/PayloadCalculator';
import RollCalculator from './RollCalculator/RollCalculator';
import RollWeightAndLengthCalculator from './RollWeightAndLength/RollWeightAndLengthCalculator';
import WeightConversionCalculator from './WeightConversion/WeightConversionCalculator';
import { CALCULATOR_TAB_LABELS } from './types/Calculator.constants';
import EuCalculatorsTab from './types/EuCalculatorsTab.enum';

const EuCalculators: FC = () => {
  const queryParams = useLocationQueryParams();
  const [selectedTab, setSelectedTab] = useState<EuCalculatorsTab>();
  const history = useHistory();

  useEffect(() => {
    setSelectedTab(queryParams.get('type') as EuCalculatorsTab);
  }, [queryParams]);

  return (
    <Tabs
      type="card"
      activeKey={selectedTab}
      onChange={(key) => {
        history.push(`/calculators?type=${key}`);
      }}
      style={{ minWidth: '1163px' }}
    >
      <TabPane
        tab={CALCULATOR_TAB_LABELS[EuCalculatorsTab.METRIC_CONVERSIONS]}
        key={EuCalculatorsTab.METRIC_CONVERSIONS}
      >
        <Space size="large" align="start">
          <WeightConversionCalculator />
          <LengthConversionCalculator />
        </Space>
      </TabPane>
      <TabPane
        tab={<Trans>Paper Math</Trans>}
        key={EuCalculatorsTab.PAPER_MATH}
      >
        <RollWeightAndLengthCalculator />
      </TabPane>
      <TabPane
        tab={CALCULATOR_TAB_LABELS[EuCalculatorsTab.ROLL]}
        key={EuCalculatorsTab.ROLL}
      >
        <RollCalculator />
      </TabPane>
      <TabPane
        tab={CALCULATOR_TAB_LABELS[EuCalculatorsTab.PALLET_HEIGHT_WEIGHT]}
        key={EuCalculatorsTab.PALLET_HEIGHT_WEIGHT}
      >
        <PalletHeightAndWeight />
      </TabPane>
      <TabPane
        tab={CALCULATOR_TAB_LABELS[EuCalculatorsTab.PAYLOAD]}
        key={EuCalculatorsTab.PAYLOAD}
      >
        <PayloadCalculator />
      </TabPane>
    </Tabs>
  );
};

export default EuCalculators;
