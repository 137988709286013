import { t } from '@lingui/macro';
import { ValidateResult } from 'react-hook-form';
import { UserCustomization } from '../../types/User/UserCustomization.interface';
import DashboardWidgetId from '../Dashboard/types/DashboardWidgetId.enum';
import QuickLinkId from '../Dashboard/types/QuickLinkId.enum';
import AccountFormData from './types/AccountFormData.interface';
import FieldPrefixes from './types/FieldPrefixes.enum';
import UserSettingsFormData from './types/UserSettingsFormData.interface';

const getUserSettingsInitialValues = (
  userCustomizationSettings: UserCustomization
): UserSettingsFormData => {
  const {
    unitOfMeasure,
    reelUnit,
    sheetUnit,
    dateFormat,
  } = userCustomizationSettings;

  return {
    unitOfMeasure: unitOfMeasure ?? '',
    reelUnit: reelUnit ?? '',
    sheetUnit: sheetUnit ?? '',
    dateFormat: dateFormat ?? '',
  };
};

export const getAccountFormInitialValues = (
  userCustomizationSettings: UserCustomization,
  enabledDashboardWidgetIds: DashboardWidgetId[],
  enabledQuickLinkIds: QuickLinkId[]
): AccountFormData => {
  const userSettingsInitialValues = getUserSettingsInitialValues(
    userCustomizationSettings
  );

  return {
    [FieldPrefixes.USER_SETTINGS]: userSettingsInitialValues,
    [FieldPrefixes.DASHBOARD_WIDGETS]: enabledDashboardWidgetIds,
    [FieldPrefixes.QUICK_LINKS]: enabledQuickLinkIds,
  };
};

export const validateMinSelected = (
  values: string[],
  minRequired: number,
  errorMessage: string = t`Please select at least ${minRequired}.`
): ValidateResult => {
  const numSelected = values.length;

  const fieldIsValid = numSelected >= minRequired;

  return fieldIsValid ? true : errorMessage;
};

export const validateMaxSelected = (
  values: string[],
  maxAllowed: number,
  errorMessage: string = t`Please select no more than ${maxAllowed}.`
): ValidateResult => {
  const numSelected = values.length;

  const fieldIsValid = numSelected <= maxAllowed;

  return fieldIsValid ? true : errorMessage;
};

export const getNewUserCustomizationSettings = (
  accountFormData: AccountFormData,
  currentUserCustomization: UserCustomization
): UserCustomization => {
  const userSettingsFormData = accountFormData[FieldPrefixes.USER_SETTINGS];
  const {
    unitOfMeasure,
    reelUnit,
    sheetUnit,
    dateFormat,
  } = userSettingsFormData;

  const dashboardWidgetsFormData =
    accountFormData[FieldPrefixes.DASHBOARD_WIDGETS];
  const activeWidgetsJson = JSON.stringify(dashboardWidgetsFormData);

  const quickLinksFormData = accountFormData[FieldPrefixes.QUICK_LINKS];
  const activeQuickLinksJson = JSON.stringify(quickLinksFormData);

  const newUserCustomization: UserCustomization = {
    ...currentUserCustomization,
    unitOfMeasure: unitOfMeasure || null,
    reelUnit: reelUnit || null,
    sheetUnit: sheetUnit || null,
    dateFormat: dateFormat || null,
    tabs: activeWidgetsJson,
    links: activeQuickLinksJson,
  };

  return newUserCustomization;
};
