import { noop } from 'lodash';
import * as React from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import useCustomer from '../../hooks/useCustomer';
import { generateFinancialExcel } from '../../services/Excel';

interface IDownloadFinancialsProps {
  customerNumber: string;
  period: string;
  sort: string;
  sortDirection: 'ASC' | 'DESC';
}

const DownloadFinancials: React.FunctionComponent<IDownloadFinancialsProps> = ({
  customerNumber,
  period,
  sort,
  sortDirection,
  children,
}) => {
  const { trackPageView } = useAnalytics();
  const { getCustomerByNumber } = useCustomer();
  const downloadFinancials = async () => {
    trackPageView('REPORTING', 'DOWNLOAD_FINANCIALS', {
      customerId: customerNumber,
    });
    const customer = getCustomerByNumber(customerNumber);
    await generateFinancialExcel(
      customer?.name || '',
      customerNumber,
      period,
      sort,
      sortDirection
    );
    window.location.href = `${window.location.protocol}//${window.location.host}/portal/resources/financials/excel`;
  };
  return (
    <div
      onClick={() => downloadFinancials()}
      onKeyDown={noop}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export default DownloadFinancials;
