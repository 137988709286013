import { QueryResult, useQuery } from 'react-query';
import { getOrderBookingBaskets } from '../services/Basket';
import AvailabilityBasketEU from '../types/AvailabilityBasketEU.interface';
import QueryCacheName from '../types/QueryCacheName.enum';
import useUser from './useUser';
import useRole from './useRole';

const useOrderBookingBaskets = (): QueryResult<AvailabilityBasketEU[]> => {
  const { data: userData } = useUser();
  const { isEuUser } = useRole();
  return useQuery(
    [QueryCacheName.BASKET_ORDER_BOOKING],
    async () => getOrderBookingBaskets(),
    {
      enabled: userData && isEuUser,
    }
  );
};

export default useOrderBookingBaskets;
