import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import SimpleCard from '../../components/SimpleCard/SimpleCard';
import style from './MyContact.module.scss';
import useMyContact from './useMyContact';

const MyContact: FC = () => {
  const { myContact } = useMyContact();
  const NA_REGION = 'NA';
  const EU_REGION = 'EU';

  return (
    <SimpleCard innerWrapperClassName="py-6" className="mb-6">
      <h2 className="text-4xl mt-1 mb-4">
        <Trans>Your Sappi Contact</Trans>
      </h2>
      {!myContact?.error ? (
        <div className="flex text-lg">
          <div className={style.imageContainer}>
            {myContact?.isr_Photo && (
              <img
                className={style.imageWidth}
                src={`data:image/png;base64,${myContact.isr_Photo}`}
                alt=""
              />
            )}
            {myContact?.isr_PhotoURL && (
              <img
                className={style.imageWidth}
                src={myContact.isr_PhotoURL}
                alt=""
              />
            )}
          </div>
          <div>
            <div className="mb-3">{myContact?.isr_Name}</div>
            <div className="mb-1">{myContact?.isr_Title}</div>
            {myContact?.isr_Region === NA_REGION && (
              <div className="mb-1">
                <Trans>North America</Trans>
              </div>
            )}
            {myContact?.isr_Region === EU_REGION && (
              <div className="mb-1">
                <Trans>Europe</Trans>
              </div>
            )}
            {myContact?.isr_Region !== NA_REGION &&
              myContact?.isr_Region !== EU_REGION &&
              myContact?.isr_Region && (
                <div className="mb-1">{myContact?.isr_Region}</div>
              )}
            {myContact?.isr_Phone && (
              <div className="mb-1">{myContact?.isr_Phone}</div>
            )}
            <div className="break-all">
              <a
                className="underline text-blue-pacific"
                href={`mailto:${myContact?.isr_Email}`}
              >
                {myContact?.isr_Email}
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-red-red text-lg">{myContact.error}</div>
      )}
    </SimpleCard>
  );
};

export default MyContact;
