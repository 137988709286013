import { Trans } from '@lingui/macro';
import React, { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import useStockLotBasket from '../../../hooks/useStockLotBasket';
import { getOrderBookingBaskets } from '../../../services/Basket';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import EuOrderBookingBaskets from './EuOrderBookingBaskets';
import EuStockLotBaskets from './StockLot/Baskets';
import useQuantityUpdateStore from './StockLot/ItemsTable/useQuantityUpdate.store';
import UpdatingBasket from './UpdatingBasket';
import useBasketStore from './useBasketStore.store';

const EuBasket: FC = () => {
  const { setBaskets, setReloadBasketStatus } = useBasketStore();
  const isStockLotQuantityUpdating = useQuantityUpdateStore(
    (state) => state.isQuantityUpdating
  );

  const {
    data: orderBookingBaskets,
    isFetching: isFetchingOrderBookingBaskets,
    refetch: refetchBaskets,
  } = useQuery(
    [QueryCacheName.BASKET_ORDER_BOOKING],
    () => getOrderBookingBaskets(),
    {
      staleTime: 60000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: stockLotBaskets,
    isFetching: isFetchingStockLotBaskets,
  } = useStockLotBasket(() => {
    // We want to not show the loading icon until the query cache is cleared and the updated data is already retrieved
    if (isStockLotQuantityUpdating) {
      useQuantityUpdateStore.setState({ isQuantityUpdating: false });
    }
  });

  const isBasketEmpty =
    (!orderBookingBaskets || orderBookingBaskets?.length < 1) &&
    (!stockLotBaskets || stockLotBaskets?.length < 1);
  const isFetching =
    (isFetchingOrderBookingBaskets || isFetchingStockLotBaskets) &&
    !isStockLotQuantityUpdating; // we dont want to show the loading icon when stock lot quantity is updating

  useEffect(() => {
    setBaskets(orderBookingBaskets || []);
    setReloadBasketStatus(true);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [orderBookingBaskets]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div style={{ minWidth: '1200px' }}>
      {isFetching ? (
        <UpdatingBasket isUpdating={isFetching} />
      ) : (
        <>
          {isBasketEmpty ? (
            <Trans>Your basket currently has no items in it.</Trans>
          ) : (
            <>
              <EuOrderBookingBaskets
                baskets={orderBookingBaskets}
                refetchBaskets={refetchBaskets}
              />
              <EuStockLotBaskets baskets={stockLotBaskets} />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default EuBasket;
