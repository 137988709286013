import * as React from 'react';

interface ICardProps {
  header: React.ReactNode;
  className?: string;
}

const Card: React.FunctionComponent<ICardProps> = ({
  header,
  className,
  children,
}) => {
  return (
    <div className={className}>
      <h3 className="p-6 bg-blue-pacific text-white-white text-lg">{header}</h3>
      <div className="bg-gray-whiteSmoke200 p-10">{children}</div>
    </div>
  );
};

export default Card;
