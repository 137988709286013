import React, { FunctionComponent, useEffect } from 'react';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';
import EuCalculators from './EuCalculators';
import {
  loadMillBrands,
  loadCo2Equivalents,
  loadInterfaceElements,
  loadMillListings,
  loadMillEfficiency,
} from './Calculator.service';
import NaOrReleaseCalculators from './NaOrReleaseCalculators';

const Calculators: FunctionComponent = () => {
  const { hasRole } = useRole();
  const isNaOrRelease =
    hasRole(AuthorityRole.ROLE_NA) || hasRole(AuthorityRole.ROLE_RELEASE);
  const isNonReleaseEu =
    hasRole(AuthorityRole.ROLE_EU) && !hasRole(AuthorityRole.ROLE_RELEASE);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await loadMillBrands();
    await loadCo2Equivalents();
    await loadInterfaceElements();
    await loadMillListings();
    await loadMillEfficiency();
  }, []);
  
  return (
    <div>
      {isNaOrRelease && <NaOrReleaseCalculators />}
      {isNonReleaseEu && <EuCalculators />}
    </div>
  );
};

export default Calculators;
