import { camelCase } from 'lodash';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import Input, { InputProps } from '../Input/Input';
import InputLabel from '../InputLabel';

type InputWithHiddenLabelProps = Omit<InputProps, 'ref'> & {
  name: string;
  label: string;
  wrapperProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
};

const InputWithHiddenLabel = React.forwardRef<
  HTMLInputElement,
  InputWithHiddenLabelProps
>(({ name, id, label, placeholder, wrapperProps, ...rest }, ref) => {
  const defaultedId = id || camelCase(name);

  return (
    <div {...wrapperProps}>
      <InputLabel text={label} htmlFor={defaultedId} visuallyHidden />
      <Input
        name={name}
        ref={ref}
        id={defaultedId}
        placeholder={placeholder !== undefined ? placeholder : label}
        {...rest}
      />
    </div>
  );
});

export default InputWithHiddenLabel;
