import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { Button } from '../../../../components/Button/Button';
import DeconstructedTable from '../../../../components/DeconstructedTable/DeconstructedTable';
import DeconstructedTableBody from '../../../../components/DeconstructedTable/DeconstructedTableBody';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import DeconstructedTableBodyRow from '../../../../components/DeconstructedTable/DeconstructedTableBodyRow';
import DeconstructedTableHead from '../../../../components/DeconstructedTable/DeconstructedTableHead';
import DeconstructedTableHeadCell from '../../../../components/DeconstructedTable/DeconstructedTableHeadCell';
import DeconstructedTableHeadRow from '../../../../components/DeconstructedTable/DeconstructedTableHeadRow';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { AvailabilityBasketNA } from '../../../../types/AvailabilityBasketNA.interface';
import CustomerBasketSummaryColumnType from './types/CustomerBasketSummaryColumnType.type';
import NaBasketStep from '../types/NaBasketStep.enum';
import useNaBasketStore from '../useNaBasketStore.store';
import CertificationCellContent from './CertificationCellContent';
import styles from './CustomerBasketSummary.module.scss';
import ContinueShopping from '../ContinueShopping';
import CustomerBasketSummaryItem from './CustomerBasketSummaryItem';
import ItemDescriptionCellContent from './ItemDescriptionCellContent';
import ItemInfoCellContent from './ItemInfoCellContent';
import LabelInfoCellContent from './LabelInfoCellContent';
import QuantityCellContent from './QuantityCellContent';
import CustomerBasketFormData from './types/CustomerBasketFormData.interface';
import { updateBasket } from '../../../../services/Basket';
import useAnalytics from '../../../../hooks/useAnalytics';
import {
  getFormDefaultValues,
  getUpdatedBasket,
} from './CustomerBasketSummary.util';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';

interface CustomerBasketSummaryProps {
  customerBasket: AvailabilityBasketNA;
  setCurrentStep: (step: NaBasketStep) => void;
}

const CustomerBasketSummary: FC<CustomerBasketSummaryProps> = ({
  customerBasket,
  setCurrentStep,
}) => {
  const queryCache = useQueryCache();
  const { isMetric } = useMeasurementSystem();
  const { format: formatNumber } = useNumberFormatter();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [hasUpdateError, setHasUpdateError] = useState<boolean>(false);
  const { trackPageView } = useAnalytics();

  const formMethods = useForm<CustomerBasketFormData>({
    mode: 'all',
    defaultValues: getFormDefaultValues(customerBasket),
  });
  const {
    formState: { isDirty, isValid },
    getValues,
    handleSubmit,
    reset,
  } = formMethods;

  const goToDeliveryDetails = () => {
    useNaBasketStore.setState({
      currentBasketSubmissionCandidate: customerBasket,
    });
    setCurrentStep(NaBasketStep.DELIVERY_DETAILS);
  };

  const sendBasketUpdate = async (
    updatedBasket: AvailabilityBasketNA
  ): Promise<void> => {
    try {
      await updateBasket(updatedBasket);

      setHasUpdateError(false);
      reset(getValues());
      queryCache.invalidateQueries(QueryCacheName.BASKET);
    } catch (e) {
      setHasUpdateError(true);
    }
  };

  const doBasketUpdate = async (
    values: CustomerBasketFormData
  ): Promise<void> => {
    setIsUpdating(true);
    trackPageView('BASKET', 'UPDATE_ITEM', {
      customerId: customerBasket.soldToCustomer.number,
    });
    const updatedBasket = getUpdatedBasket(customerBasket, values);

    await sendBasketUpdate(updatedBasket);

    setIsUpdating(false);
  };

  const doRemoveItem = async (
    itemToRemove: AvailabilityBasketItem
  ): Promise<void> => {
    setIsUpdating(true);
    
    const updatedBasket = {
      ...customerBasket,
      items: customerBasket.items.filter(
        (currentItem) => currentItem.id !== itemToRemove.id
      ),
    };
    trackPageView('BASKET', 'REMOVE_ITEM', {
      customerId: customerBasket.soldToCustomer.number,
    });
    await sendBasketUpdate(updatedBasket);

    setIsUpdating(false);
  };

  // Whenever the customer basket changes, update the form fields' values to
  // match the new customer basket data.
  useEffect(() => {
    reset(getFormDefaultValues(customerBasket));
  }, [customerBasket, reset]);

  const columns: CustomerBasketSummaryColumnType[] = [
    {
      key: 'itemDescription',
      title: t`Item Description`,
      render: (
        basketItem,
        itemIndex,
        itemIsExpanded,
        onToggleItemIsExpanded
      ) => (
        <ItemDescriptionCellContent
          basketItem={basketItem}
          itemIsExpanded={itemIsExpanded}
          onToggleItemIsExpanded={onToggleItemIsExpanded}
        />
      ),
      width: '15%',
    },
    {
      key: 'labelInfo',
      title: t`Label Info`,
      render: (basketItem, itemIndex) => (
        <LabelInfoCellContent
          itemStacksPerPallet={basketItem.stacksPerPallet}
          itemRequestedUnitOfMeasure={basketItem.requestedUnitOfMeasure}
          formMethods={formMethods}
          itemIndex={itemIndex}
          itemProductType={basketItem.productType}
        />
      ),
      width: '15%',
    },
    {
      key: 'certification',
      title: t`Certification`,
      render: (basketItem, itemIndex) => (
        <CertificationCellContent
          formMethods={formMethods}
          itemIndex={itemIndex}
        />
      ),
      width: '15%',
    },
    {
      key: 'quantity',
      title: t`Quantity`,
      render: (basketItem, itemIndex) => (
        <QuantityCellContent
          basketItem={basketItem}
          formMethods={formMethods}
          itemIndex={itemIndex}
        />
      ),
      width: '15%',
    },
    {
      key: 'itemInfo',
      title: t`Item Info`,
      render: (
        basketItem,
        itemIndex,
        itemIsExpanded,
        onToggleItemIsExpanded,
        onRemoveItem
      ) => (
        <ItemInfoCellContent
          basketItem={basketItem}
          formMethods={formMethods}
          itemIndex={itemIndex}
          onRemoveItem={onRemoveItem}
        />
      ),
      width: '40%',
    },
  ];

  return (
    <form onSubmit={handleSubmit(doBasketUpdate)}>
      <div className="px-6 py-4 text-3xl font-bold bg-blue-pacific text-white-white">
        Customer: {customerBasket.soldToCustomer.name}
      </div>
      <DeconstructedTable className={`mb-9 ${styles.table}`} noStripes>
        <DeconstructedTableHead>
          <DeconstructedTableHeadRow>
            {columns.map((column) => (
              <DeconstructedTableHeadCell
                key={column.key}
                style={{ width: column.width }}
              >
                {column.title}
              </DeconstructedTableHeadCell>
            ))}
          </DeconstructedTableHeadRow>
        </DeconstructedTableHead>
        <DeconstructedTableBody>
          {customerBasket.items.map((basketItem, index) => (
            <CustomerBasketSummaryItem
              key={basketItem.id}
              basketItem={basketItem}
              columns={columns}
              itemIndex={index}
              formMethods={formMethods}
              onRemoveItem={doRemoveItem}
            />
          ))}
          <DeconstructedTableBodyRow className="total-row">
            <DeconstructedTableBodyCell colSpan={columns.length - 1}>
              <div className="text-lg font-bold text-right">
                <Trans>Total Weight</Trans>:
              </div>
            </DeconstructedTableBodyCell>
            <DeconstructedTableBodyCell>
              <div className="text-lg font-bold">
                {isMetric ? (
                  <>
                    {formatNumber(Math.round(customerBasket.totalQuantityKg))}{' '}
                    <Trans>kg</Trans>
                  </>
                ) : (
                  <>
                    {formatNumber(Math.round(customerBasket.totalQuantityLb))}{' '}
                    <Trans>pounds</Trans>
                  </>
                )}
              </div>
            </DeconstructedTableBodyCell>
          </DeconstructedTableBodyRow>
        </DeconstructedTableBody>
      </DeconstructedTable>
      <div className="flex justify-between">
        <ContinueShopping />
        <Space size="large">
          {hasUpdateError && (
            <div className="text-red-red">
              <Trans>
                Changes not saved. Please contact Sappi at 800.224.4391 for
                assistance.
              </Trans>
            </div>
          )}
          {!isValid && (
            <div className="text-red-red">
              <Trans>Please enter all required fields to proceed.</Trans>
            </div>
          )}
          {isDirty ? (
            <Button
              theme="primary"
              type="submit"
              loading={isUpdating}
              disabled={isUpdating || !isValid}
            >
              <Trans>Update</Trans>
            </Button>
          ) : (
            <Button
              theme="primary"
              onClick={goToDeliveryDetails}
              disabled={isUpdating || !isValid}
            >
              <Trans>Delivery Details</Trans>
            </Button>
          )}
        </Space>
      </div>
    </form>
  );
};

export default CustomerBasketSummary;
