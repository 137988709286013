import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import InputLabel from '../../../../components/InputLabel';
import Radio from '../../../../components/Radio/Radio';
import SectionHeader from '../../../../components/SectionHeader';
import Textarea from '../../../../components/Textarea/Textarea';
import useRole from '../../../../hooks/useRole';
import { AuthorityRole } from '../../../../types/Authority.interface';
import OrderType from '../OrderType.enum';
import { DeliveryDetailsForm } from './types/DeliveryDetailsForm.type';
import DeliveryDetailsFormField from './types/DeliveryDetailsFormFields.enum';

interface ITypeOfOrderSectionProps {
  register: UseFormMethods<DeliveryDetailsForm>['register'];
}

const TypeOfOrderSection: FC<ITypeOfOrderSectionProps> = ({ register }) => {
  const { hasRole } = useRole();
  return (
    <Space direction="vertical" size="large" className="w-full">
      <SectionHeader darkMode>
        <Trans>Type of Order</Trans>
      </SectionHeader>
      <div className="grid grid-cols-2">
        {hasRole(AuthorityRole.ROLE_CONSIGNMENT) ? (
          <Space direction="vertical" size="middle">
            <Radio
              name={DeliveryDetailsFormField.ORDER_TYPE}
              value={OrderType.STANDARD}
              label={
                <span className="text-lg">
                  <Trans>orderDetails.type.standard</Trans>
                </span>
              }
              ref={register({
                required: true,
              })}
            />
            <Radio
              name={DeliveryDetailsFormField.ORDER_TYPE}
              value={OrderType.CONSIGNMENT}
              label={
                <span className="text-lg">
                  <Trans>orderDetails.type.consignment</Trans>
                </span>
              }
              ref={register({
                required: true,
              })}
            />
          </Space>
        ) : (
          <span className="text-lg">
            <Trans>orderDetails.type.standard</Trans>
          </span>
        )}
        <InputLabel text={t`Notes to Dedicated Contact`} size="lg">
          <Textarea
            name={DeliveryDetailsFormField.CONTACT_NOTES}
            ref={register({ maxLength: 107 })}
            maxLength={107}
            style={{ height: '200px' }}
          />
        </InputLabel>
      </div>
    </Space>
  );
};

export default TypeOfOrderSection;
