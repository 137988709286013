import { DeviationData } from '../types/DeviationData.interface';
import { DeviationForm } from './DeviationForm.interface';

export const getShownResults = (
  data: DeviationData[],
  formValues: DeviationForm
): DeviationData[] => {
  const changedData = formValues.deviationRow
    ?.map((item, index) =>
      item.newPlannedDate
        ? {
            ...data[index],
            newPlannedDate: item.newPlannedDate,
            deviationReason: item.deviationReason,
          }
        : undefined
    )
    .filter((item) => item !== undefined) as DeviationData[];
  return changedData;
};
