import { Space } from 'antd';
import { Moment } from 'moment';
import React, { FC } from 'react';
import TrackAndTraceStep from '../types/TrackAndTraceStep.enum';
import ArrivalDepartureDates from './ArrivalDepartureDates';
import ReviewStepArrivalDatesCell from './ReviewStepArrivalDatesCell';
import SearchStepArrivalDatesCell from './SearchStepArrivalDatesCell';

interface IArrivalDatesCellProps {
  arrivalDate: number;
  arrivalTime: string | null;
  departureDate: number;
  departureTime: string | null;
  newArrivalDate: Moment | null;
  newDepartureDate: Moment | null;
  index: number;
  step: TrackAndTraceStep;
}

const ArrivalDatesCell: FC<IArrivalDatesCellProps> = ({
  arrivalDate,
  arrivalTime,
  departureDate,
  departureTime,
  newArrivalDate,
  newDepartureDate,
  index,
  step,
}) => {
  return (
    <Space direction="vertical" size="middle">
      {(!!arrivalDate || !!departureDate) && (
        <ArrivalDepartureDates
          arrivalDate={arrivalDate}
          arrivalTime={arrivalTime}
          departureDate={departureDate}
          departureTime={departureTime}
        />
      )}
      {step === TrackAndTraceStep.SEARCH && (
        <SearchStepArrivalDatesCell index={index} />
      )}
      {step === TrackAndTraceStep.REVIEW && (
        <ReviewStepArrivalDatesCell
          arrivalDate={newArrivalDate}
          departureDate={newDepartureDate}
        />
      )}
    </Space>
  );
};

export default ArrivalDatesCell;
