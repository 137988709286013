import { QueryResult, useQuery } from 'react-query';
import { getFinancialSummary } from '../services/Financials';
import { FinancialSummary } from '../types/FinancialSummary.interface';
import QueryCacheName from '../types/QueryCacheName.enum';

const useFinancialSummary = (
  customerNumber: string
): QueryResult<FinancialSummary> => {
  return useQuery(
    [QueryCacheName.FINANCIAL_SUMMARY, customerNumber],
    async (key, number: string) => {
      return getFinancialSummary(number);
    },
    {
      enabled: customerNumber,
      refetchOnWindowFocus: false,
      staleTime: 0, // CANNOT BE CACHED BECAUSE SERVER SESSION STATE
    }
  );
};

export default useFinancialSummary;
