import { Space } from 'antd';
import React, { FC } from 'react';
import CustomerBasketSummary from './CustomerBasketSummary';
import StepComponentProps from '../types/StepComponentProps.interface';
import useSelectedCustomerBaskets from '../hooks/useSelectedCustomerBaskets';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

interface BasketStepProps extends StepComponentProps {
  selectedCustomerNum: string;
}

const BasketStep: FC<BasketStepProps> = ({
  setCurrentStep,
  selectedCustomerNum,
}) => {
  const {
    isFetching,
    selectedCustomerBaskets: customerBaskets,
  } = useSelectedCustomerBaskets(selectedCustomerNum);

  return (
    <>
      {isFetching && <LoadingSpinner horizontal />}
      {customerBaskets && (
        <Space direction="vertical" size={60} className="w-full">
          {customerBaskets.map((customerBasket) => (
            <CustomerBasketSummary
              key={customerBasket.soldToCustomer.number}
              customerBasket={customerBasket}
              setCurrentStep={setCurrentStep}
            />
          ))}
        </Space>
      )}
    </>
  );
};

export default BasketStep;
