import { Trans } from '@lingui/macro';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import LanderContent from '../../components/LanderContent/LanderContent';
import LanderContentType from '../../components/LanderContent/LanderContentType.enum';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useLanguage from '../../hooks/useLanguage';
import { cancellableQueryOrderBookingProducts } from '../../services/Product';
import { ErrorResponse } from '../../types/ErrorResponse.interface';
import { OrderBookingProductQuery } from '../../types/OrderBookingProductQuery.interface';
import QueryCacheName from '../../types/QueryCacheName.enum';
import CustomerInformation from '../../components/CustomerInformation/CustomerInformation';
import SearchForm from './SearchForm/SearchForm';
import SearchResults from './SearchResults/SearchResults';
import useOrderBookingStore from './useOrderBooking.store';

const OrderBooking: React.FunctionComponent = () => {
  const lang = useLanguage();
  const searchFormMethods = useForm({
    mode: 'all',
  });
  const { selectedCustomer } = useOrderBookingStore();
  const { storedQuery: productQuery } = useOrderBookingStore();
  const [error, setError] = useState<ErrorResponse | null>(null);
  const { resolvedData: products, isFetching } = usePaginatedQuery(
    [QueryCacheName.ORDER_BOOKING_PRODUCTS, productQuery],
    cancellableQueryOrderBookingProducts,
    {
      enabled: productQuery,
      retry: false,
      staleTime: 50000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSettled: (data, err: unknown | null) => {
        if (err && (err as any).response) {
          setError((err as any).response.data);
        } else {
          setError(null);
        }
      },
    }
  );

  const resetSearchForm = (): void => {
    searchFormMethods.reset({
      soldToParty: searchFormMethods.getValues('soldToParty'),
      requestedDate: searchFormMethods.getValues('requestedDate'),
      shipToParty: searchFormMethods.getValues('shipToParty'),
    });
  };

  const resetFullyToLander = (): void => {
    resetSearchForm();
    useOrderBookingStore.setState({ storedQuery: undefined });
  };

  return (
    <div style={{ minWidth: '1200px' }}>
      <CustomerInformation selectedCustomer={selectedCustomer} />
      <div className="flex">
        <SearchForm
          formMethods={searchFormMethods}
          onSearch={(query) =>
            useOrderBookingStore.setState({ storedQuery: query })
          }
          onReset={resetSearchForm}
        />
        <div className="w-full">
          {!isFetching && !productQuery && (
            <LanderContent lang={lang} type={LanderContentType.ORDER_BOOKING} />
          )}
          {isFetching ? (
            <>
              <LoadingSpinner horizontal />
              <div className="w-full text-2xl text-center mt-4">
                <Trans>
                  Please be patient, your results will be processed soon. This
                  can take up to 30 seconds.
                </Trans>
              </div>
            </>
          ) : (
            <>
              {productQuery && (
                <SearchResults
                  className="ml-6 z-0"
                  productQuery={productQuery as OrderBookingProductQuery}
                  results={products || []}
                  error={error}
                  resetFullyToLander={resetFullyToLander}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderBooking;
