import { t } from '@lingui/macro';
import * as React from 'react';
import styles from './AddToBasketQtyInput.module.scss';

interface IAddToBasketQtyInputProps extends React.HTMLProps<HTMLInputElement> {
  hasError?: boolean;
}

const AddToBasketQtyInput = React.forwardRef<
  HTMLInputElement,
  IAddToBasketQtyInputProps
>(
  (
    {
      className,
      hasError = false,
      type = 'text',
      placeholder = t`Quantity`,
      ...rest
    },
    ref
  ) => {
    return (
      <input
        className={`border border-gray-gray text-lg text-center text-blue-pacific w-28 ${
          styles.quantityInput
        } ${hasError ? 'error' : ''}`}
        {...rest}
        placeholder={placeholder}
        type={type}
        ref={ref}
      />
    );
  }
);

export default AddToBasketQtyInput;
