import { useQuery } from 'react-query';
import { getBrands } from '../../../services/Brand';
import Brand from '../../../types/Brand.interface';
import EuBrand from '../../../types/EuBrand.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';

const useCustomerBrands = (
  customer: string,
  availFlag?: string,
): {
  brands: Brand[] | EuBrand[] | undefined;
  isFetching: boolean;
} => {
  const { data: brands, isFetching } = useQuery(
    [
      QueryCacheName.BRANDS,
      {
        customer,
        availabilityFlag: availFlag,
      },
    ],
    getBrands,
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      enabled: customer,
      keepPreviousData: true,
    }
  );

  return {
    brands,
    isFetching,
  };
};

export default useCustomerBrands;
