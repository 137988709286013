import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { loadEqEnvImpactEquivImages } from '../../Calculator.service';
import EqEnvImpactEquivalent from '../../types/EqCalculator/EqEnvImpactEquivalent.interface';
import EqEnvImpactSynthesis from '../../types/EqCalculator/EqEnvImpactSynthesis.interface';
import EqImpactsDisplayInfo from '../../types/EqCalculator/EqImpactsDisplayInfo.interface';
import ImpactEquivalent from './ImpactEquivalent';

interface ImpactSectionContentProps {
  impactSynthesis: EqEnvImpactSynthesis;
  impactsDisplayInfo: EqImpactsDisplayInfo;
  paperTotalWeight: number;
  weightUnits: string;
}

const ImpactSectionContent: FC<ImpactSectionContentProps> = ({
  impactSynthesis,
  impactsDisplayInfo,
  paperTotalWeight,
  weightUnits,
}) => {
  const [impactEquivalents, setImpactEquivalents] = useState<
    EqEnvImpactEquivalent[]
  >([]);

  useEffect(() => {
    const loadEquivImages = async (equivs: EqEnvImpactEquivalent[]) => {
      const equivsWithImagesLoaded = await loadEqEnvImpactEquivImages(equivs);

      setImpactEquivalents(equivsWithImagesLoaded);
    };

    loadEquivImages(impactSynthesis.equivalents);
  }, [impactSynthesis.equivalents]);

  return (
    <Space direction="vertical" size="large" className="w-full">
      <Space direction="vertical" className="w-full">
        <div className="font-bold">
          <div>
            <Trans>Greenhouse gas emissions (CO&#x2082;e)&sup1;</Trans>
          </div>
          <div>
            <Trans>Carbon footprint for</Trans> {paperTotalWeight} {weightUnits}
          </div>
        </div>
        <div>
          <div>
            Sappi ={' '}
            {impactSynthesis.sappiCo2Footprint.toFixed(1).toLocaleString()}{' '}
            {weightUnits}
          </div>
          <div>
            <Trans>Industry Average</Trans> ={' '}
            {impactSynthesis.industryAvgCo2Footprint
              .toFixed(1)
              .toLocaleString()}{' '}
            {weightUnits}
          </div>
        </div>
        {/* eslint-disable react/no-danger */}
        <div
          dangerouslySetInnerHTML={{
            __html: impactsDisplayInfo.impactStatement,
          }}
        />
        {/* eslint-enable react/no-danger */}
        {impactEquivalents.length > 0 && (
          <div>
            {impactEquivalents.map((impactEquivalent) => (
              <ImpactEquivalent
                key={impactEquivalent.type}
                impactEquivalent={impactEquivalent}
              />
            ))}
          </div>
        )}
      </Space>
      <div className="text-xs">
        <div>
          <sup>1</sup>
          <Trans>Scope 1 = </Trans>
          {impactsDisplayInfo.scope1Footnote}
        </div>
        <div>
          <sup>1</sup>
          <Trans>Scope 2 = </Trans>
          {impactsDisplayInfo.scope2Footnote}
        </div>
        <div>
          <sup>2</sup>
          {impactsDisplayInfo.industryAvgFootnote}
        </div>
      </div>
    </Space>
  );
};

export default ImpactSectionContent;
