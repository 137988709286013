import * as React from 'react';

const DeconstructedTableHeadRow: React.FunctionComponent<React.HTMLProps<
  HTMLTableRowElement
>> = ({ className, children, ...rest }) => (
  <tr className={className} {...rest}>
    {children}
  </tr>
);

export default DeconstructedTableHeadRow;
