import { AvailabilityBasketNA } from '../../../types/AvailabilityBasketNA.interface';
import Customer from '../../../types/Customer.interface';

export const ALL_CUSTOMERS = 'ALL';

export const getCustomersFromBasketData = (
  basketData?: AvailabilityBasketNA[]
): Customer[] => {
  const customers: Customer[] = [];

  if (basketData) {
    basketData.forEach((basket) => {
      if (
        !customers.some(
          (customer) => customer.number === basket.soldToCustomer.number
        )
      ) {
        customers.push(basket.soldToCustomer);
      }
    });
  }

  return customers;
};

const getContactDetailsArray = (notesString: string) =>
  (notesString || '//').split('/', 3);
export const deriveContactNameFromNotes = (
  contactNotes: string | undefined | null
): string => (contactNotes ? getContactDetailsArray(contactNotes)[0] : '');
export const derivePhoneFromNotes = (
  contactNotes: string | undefined | null
): string => (contactNotes ? getContactDetailsArray(contactNotes)[1] : '');
export const deriveEmailFromNotes = (
  contactNotes: string | undefined | null
): string => (contactNotes ? getContactDetailsArray(contactNotes)[2] : '');

const getNotesArray = (
  notes:
    | {
        contactNotes2?: string | null;
        contactNotes3?: string | null;
        contactNotes4?: string | null;
      }
    | undefined
    | null
) => {
  const notesBlob = `${notes?.contactNotes2 || ''}${
    notes?.contactNotes3 || ''
  }${notes?.contactNotes4 || ''}`;
  return notesBlob ? notesBlob.split('/', 3) : [];
};

export const deriveReceivingFromNotes = (
  notes:
    | {
        contactNotes2?: string | null;
        contactNotes3?: string | null;
        contactNotes4?: string | null;
      }
    | undefined
    | null
): string => (notes ? getNotesArray(notes)[0] : '');

export const deriveDeliveryInstructionsFromNotes = (
  notes:
    | {
        contactNotes2?: string | null;
        contactNotes3?: string | null;
        contactNotes4?: string | null;
      }
    | undefined
    | null
): string => (notes ? getNotesArray(notes)[1] : '');

export const deriveContactNotesFromNotes = (
  notes:
    | {
        contactNotes2?: string | null;
        contactNotes3?: string | null;
        contactNotes4?: string | null;
      }
    | undefined
    | null
): string => (notes ? getNotesArray(notes)[2] : '');
