import * as React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface IBasketProps {
  count: number | undefined;
  loading?: boolean;
}

const BasketButton: React.FunctionComponent<IBasketProps> = ({
  count,
  loading,
}) => {
  return (
    <div className="flex text-blue-pacific items-center">
      {loading ? <LoadingSpinner /> : <></>}
      <div className="ml-2">
        <i className="fa fa-shopping-basket " />
      </div>
      <div className="ml-2 text-2xl">{count || 0}</div>
    </div>
  );
};

export default BasketButton;
