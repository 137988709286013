import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { getEqUiElementsByType } from '../../Calculator.service';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import ResultsSection from './ResultsSection';
import GetEqUiElementsByTypeParams from '../../types/EqCalculator/GetEqUiElementsByTypeParams.interface';
import EqUiElementType from '../../types/EqCalculator/EqUiElementType.enum';
import { getBenefitsDisplayInfoFromUiElements } from './EqCalculatorResults.util';

const BenefitsSection: FC = () => {
  const [title, setTitle] = useState<string>();
  const [benefits, setBenefits] = useState<string[]>([]);

  const params: GetEqUiElementsByTypeParams = {
    type: EqUiElementType.BENEFITS,
    lang: 'EN',
  };
  const { data: uiElements, isFetching } = useQuery(
    [QueryCacheName.EQ_UI_ELEMENTS_BY_TYPE, params],
    (
      queryKey: QueryCacheName.EQ_UI_ELEMENTS_BY_TYPE,
      queryParams: GetEqUiElementsByTypeParams
    ) => getEqUiElementsByType(queryParams),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (uiElements) {
      const {
        title: newTitle,
        benefits: newBenefits,
      } = getBenefitsDisplayInfoFromUiElements(uiElements);

      setTitle(newTitle);
      setBenefits(newBenefits || []);
    }
  }, [uiElements]);

  return (
    <div>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        !!title && (
          <ResultsSection title={title}>
            {benefits.length > 0 && (
              <ul className="px-5">
                {benefits.map((benefit) => (
                  /* eslint-disable react/no-danger */
                  <li
                    key={benefit}
                    dangerouslySetInnerHTML={{
                      __html: benefit,
                    }}
                  />
                  /* eslint-enable react/no-danger */
                ))}
              </ul>
            )}
          </ResultsSection>
        )
      )}
    </div>
  );
};

export default BenefitsSection;
