import Axios from 'axios';
import { find, uniqBy } from 'lodash';
import { StockLotRequestParams } from '../../domain/StockLot/types/StockLotRequestParams.interface';
import { AvailabilityCustomer } from '../../types/AvailabilityCustomer.interface';
import Customer from '../../types/Customer.interface';
import { NaShipTo } from '../../types/NaShipTo.interface';
import Option from '../../types/Option.type';
import { PartnerSearchCustomer } from '../../types/PartnerSearchCustomer.interface';
import { StockLotHarborsResponse } from '../../types/StockLotHarborsResponse';

export const getPartnerSearchCustomerByNumber = async (
  _key: string,
  customerNumber: string,
  shipTo?: boolean
): Promise<PartnerSearchCustomer> => {
  const { data } = await Axios.get(
    `/portal/resources/customer/${customerNumber}`,
    {
      params: shipTo ? { shipTo: 'X' } : undefined,
    }
  );
  return data;
};

export const getDirectEuCustomers = async (): Promise<
  AvailabilityCustomer[]
> => {
  const { data } = await Axios.get(
    '/portal/resources/eu/availability/customers?direct=X'
  );
  return data;
};

export const getEuCustomers = async (): Promise<AvailabilityCustomer[]> => {
  const { data } = await Axios.get(
    '/portal/resources/eu/availability/customers'
  );
  return data;
};

export const getNaShipTos = async (
  customerNumber: string
): Promise<NaShipTo[]> => {
  const { data } = await Axios.get(
    `/portal/resources/customer/${customerNumber}/shiptos`
  );
  return data;
};

export const getDivisions = async (
  customerNumber: string
): Promise<string[]> => {
  const { data } = await Axios.get(
    `/portal/resources/customer/${customerNumber}/divisions`
  );
  return data;
};

export const getStockLotShipTos = async (
  params: Pick<StockLotRequestParams, 'customerNumber' | 'division'>
): Promise<NaShipTo[]> => {
  const { data } = await Axios.get(
    `/portal/resources/customer/${params.customerNumber}/${params.division}/shiptos/stocklot`
  );
  return data;
};

export const getStockLotHarbors = async (
  soldToCustomerNumber: string,
  shipToCustomerNumber: string,
  division: string | null
): Promise<StockLotHarborsResponse> => {
  const { data } = await Axios.get(
    `/portal/resources/stocklot/harbors/${soldToCustomerNumber}/${shipToCustomerNumber}/${
      division || ''
    }`
  );
  return data;
};

// Although the overlap is minimal, it is useful to do this in some cases to avoid a lot of unnecessary if/then
export const convertNaShipToToCustomer = (shipTo: NaShipTo): Customer => ({
  number: shipTo.customerNumber,
  customerNumber: shipTo.customerNumber,
  name: shipTo.name1,
  name2: shipTo.name2,
  city: shipTo.city,
  address: shipTo.address1,
  postalCode: shipTo.postalCode,
  countryAbr: shipTo.countryAbrv,
  country: shipTo.country || '',
  salesOrg: '',
  distributionChannel: '',
  division: '',
  orderBooking: null,
  reservationBooking: null,
  release: null,
  stockLot: false,
  millDirect: false,
  consignment: false,
  quickOrder: false,
  samples: false,
  showStock: false,
  showMillInventory: false,
  showProductionPlanning: false,
});

export const deriveCustomerOptions = (
  customers: Customer[] | AvailabilityCustomer[] | NaShipTo[],
  isNonReleaseEuUser?: boolean,
  isEuUser?: boolean,
  showCity?: boolean,
  showCityCountry?: boolean,
  showNumber?: boolean
): Option[] => {
  const uniqCustomers = uniqBy<Customer | AvailabilityCustomer | NaShipTo>(
    customers,
    (c) =>
      (c as Customer).number ||
      (c as AvailabilityCustomer | NaShipTo).customerNumber
  );

  return uniqCustomers.map((c) => {
    const name = (c as Customer).name || (c as NaShipTo).name1 || '';
    const name2 =
      isNonReleaseEuUser && !isEuUser && (c as NaShipTo).name2
        ? (c as NaShipTo).name2
        : '';
    const city =
      isNonReleaseEuUser || !(c as Customer).name || showCity
        ? ` - ${(c as NaShipTo).city}`
        : '';
    const cityCountry = showCityCountry
      ? ` (${(c as NaShipTo).city}, ${
          (c as Customer).countryCode || (c as Customer).country
        })`
      : '';
    const number = showNumber ? ` (${(c as Customer).number})` : '';
    return {
      label: `${name}${name2}${city}${cityCountry}${number}`,
      value: (c as Customer).number || (c as NaShipTo).customerNumber,
    };
  });
};

export const deriveShipToCustomerOptions = (
  customers: AvailabilityCustomer[]
): Option[] => {
  const uniqCustomers = uniqBy<AvailabilityCustomer>(
    customers,
    (c) => c.shipToCustomerNumber
  );

  return uniqCustomers.map((c) => ({
    label: `${c.name1}, ${c.city}`,
    value: c.shipToCustomerNumber || '',
  }));
};

// TODO: Tests?
export const getEuShipToCustomerOptions = (
  customerNumber: string | undefined,
  customers: AvailabilityCustomer[]
): Option[] => {
  let shipToOptions: Option[] = [];
  if (customerNumber && customers && customers.length > 0) {
    const customer = find(customers, {
      customerNumber,
    });
    if (customer) {
      const { shipTos } = customer;
      if (shipTos && shipTos.length) {
        shipToOptions = deriveShipToCustomerOptions(customer.shipTos);
      } else {
        // Selected customer becomes ship to customer
        shipToOptions = deriveCustomerOptions([customer]);
      }
    }
  }
  return shipToOptions;
};

export const getDetailsOfAllEuShipToLocations = (
  customerNumber: string | undefined,
  customers: AvailabilityCustomer[]
): AvailabilityCustomer[] => {
  let shipToLocations: AvailabilityCustomer[] = [];
  if (customerNumber && customers && customers.length > 0) {
    const customer = find(customers, {
      customerNumber,
    }) as AvailabilityCustomer;
    const { shipTos } = customer;
    shipToLocations = shipTos;
    if (!shipTos || !shipTos.length) {
      shipToLocations = [customer];
    }
  }
  return shipToLocations;
};

export const getNaShipToOptions = (
  customerNumber: string,
  shipTos: NaShipTo[],
  customers: Customer[] | undefined
): Option[] => {
  let shipToOptions: Option[] = [];
  if (shipTos && shipTos.length) {
    shipToOptions = deriveCustomerOptions(shipTos);
  } else {
    // Selected customer becomes ship to customer
    const customer = find(customers, { number: customerNumber });
    if (customer) {
      shipToOptions = deriveCustomerOptions([customer]);
    }
  }
  return shipToOptions;
};

export const getDetailsOfAllNaShipTos = (
  customerNumber: string,
  shipTos: NaShipTo[],
  customers: Customer[] | undefined
): NaShipTo[] => {
  if (shipTos && shipTos.length) {
    return shipTos;
  }
  const customer = find(customers, { number: customerNumber }) as Customer;
  return [
    {
      customerNumber: customer.number,
      name1: customer.name,
      address1: customer.address,
      city: customer.city,
      postalCode: customer.postalCode,
      countryAbrv: customer.countryAbr,
    },
  ];
};

export const isValidCustomerNumber = (value: string): boolean => {
  // Ensures value is an all-numeric string of exactly 10 digits.
  if (/^(\d{10})$/.test(value)) {
    return true;
  }

  return false;
};

interface QueryParams {
  city: string;
  country: string;
  customerNumber: string;
  name: string;
  postalCode: string;
  shipToFlag?: string;
  soldToFlag?: string;
}

export const searchCustomers = async (
  queryParams: QueryParams
): Promise<Customer[]> => {
  const { data } = await Axios.get(`/portal/resources/customer/search`, {
    params: queryParams,
  });

  return data;
};

export const getCustomerByCustomerNumber = (
  customerNumber: string | undefined,
  customers: Customer[] = []
): Customer | undefined => {
  if (customerNumber) {
    return customers.find((customer) => customer.number === customerNumber);
  }
  return undefined;
};
