import { useQuery } from 'react-query';
import Option from '../../types/Option.type';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { StockLotRequestParams } from './types/StockLotRequestParams.interface';
import { OPTION_ALL } from '../../services/Util/emptyOption.const';
import PutUpCategory from '../../types/PutUpCategory.enum';
import generateOptions from '../../services/Util/generateOptions.util';
import { getGrades } from './services';

const useGradeOptions = (
  customerNumber: string,
  division: string,
  productType: PutUpCategory
): {
  gradeOptions: Option[];
  isFetchingGrade: boolean;
} => {
  const stockLotGradeParams: Pick<
    StockLotRequestParams,
    'customerNumber' | 'productType' | 'division'
  > = {
    customerNumber,
    productType,
    division,
  };
  const { data: grades, isFetching } = useQuery(
    [QueryCacheName.STOCK_LOT_GRADES, stockLotGradeParams],
    () => getGrades(stockLotGradeParams),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      enabled: customerNumber && productType && division,
      keepPreviousData: true,
    }
  );

  const gradeOpts = generateOptions(
    (grades || []).sort(),
    (item) => ({
      label: item.gradeDescription,
      value: item.gradeDescription,
    }),
    OPTION_ALL
  );

  return {
    gradeOptions: gradeOpts,
    isFetchingGrade: isFetching,
  };
};

export default useGradeOptions;
