import { Trans } from '@lingui/macro';
import * as React from 'react';

interface IStockWeightsProps {
  stacksPerPallet: number;
  sheetsPerReam: number;
  sheetsPerPallet: number;
  txtWrappingType?: string;
}

const StockWeights: React.FunctionComponent<IStockWeightsProps> = ({
  stacksPerPallet,
  sheetsPerReam,
  sheetsPerPallet,
  txtWrappingType,
}) => {
  const isSkid = stacksPerPallet < 16;
  const isReamWrapped =
    txtWrappingType === 'BULK PACKED' ? false : sheetsPerReam > 0;
  const isPackWrapped = txtWrappingType === 'BULK PACKED' && sheetsPerReam > 0;
  const isCarton = !isSkid;
  return (
    <div>
      {isSkid && (
        <div>
          {sheetsPerPallet !== 0 && (
            <span>
              {sheetsPerPallet} <Trans>SH/Pallet</Trans>
              <br />
            </span>
          )}
          {stacksPerPallet} <Trans>ST/Pallet</Trans>
          {isReamWrapped && (
            <span>
              <br />
              {sheetsPerReam} <Trans>SH/Ream</Trans>
            </span>
          )}
          {isPackWrapped && (
            <span>
              <br />
              {sheetsPerReam} <Trans>SH/Pack</Trans>
            </span>
          )}
        </div>
      )}
      {isCarton && (
        <div>
          {sheetsPerPallet !== 0 && (
            <span>
              {sheetsPerPallet}
              <Trans>SH/CTN</Trans> <br />
            </span>
          )}
          {stacksPerPallet} <Trans>CTN/Pallet</Trans>
          {isReamWrapped && (
            <span>
              <br />
              {sheetsPerReam} <Trans>SH/Ream</Trans>
            </span>
          )}
          {isPackWrapped && (
            <span>
              <br />
              {sheetsPerReam} <Trans>SH/Pack</Trans>
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default StockWeights;
