enum SamplesProductGroup {
  NONE = '',
  PUBLIC = 'PUBLIC',
  COMM = 'COMM',
  DUMMY = 'DUMMY',
  SAPPI = 'SAPPI',
  SPEC = 'SPEC',
}

export default SamplesProductGroup;
