import { t } from '@lingui/macro';
import { Space } from 'antd';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import { EMPTY_OPTION_SELECT } from '../../../../services/Util/emptyOption.const';
import fractionOrDecimalInput from '../../../../services/Util/fractionOrDecimalInput.util';
import positiveIntegerOnlyInput from '../../../../services/Util/positiveIntegerOnlyInput.util';
import { EU_NUMBER_REG_EX } from '../../../../types/RegularExpression.constants';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';

const UOM_OPTIONS = [
  EMPTY_OPTION_SELECT,
  {
    value: UnitOfMeasure.PL,
    label: t`Pallets`,
  },
  {
    value: UnitOfMeasure.SH,
    label: t`Sheets`,
  },
  {
    value: UnitOfMeasure.KG,
    label: t`Kilograms`,
  },
  {
    value: UnitOfMeasure.TO,
    label: t`Tons`,
  },
];

interface ISheetsCriteriaProps {
  register: UseFormMethods['register'];
  getValues: UseFormMethods['getValues'];
  trigger: UseFormMethods['trigger'];
  watch: UseFormMethods['watch'];
  errors: UseFormMethods['errors'];
}

const SheetsCriteria: React.FunctionComponent<ISheetsCriteriaProps> = ({
  register,
  getValues,
  trigger,
  watch,
  errors,
}) => {
  const palletSheets = watch('ivPalletSheets');
  const palletMaxHeight = watch('ivPalletMaxHeight');
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <InputLabel text={t`Width (cm)`} size="lg" required>
        <Input
          name="ivPalletWidth"
          ref={register({ required: true, pattern: EU_NUMBER_REG_EX })}
          onKeyPress={fractionOrDecimalInput}
          required
          error={errors?.ivPalletWidth}
        />
      </InputLabel>
      <InputLabel text={t`Length (cm)`} size="lg" required>
        <Input
          name="ivPalletLength"
          ref={register({ required: true, pattern: EU_NUMBER_REG_EX })}
          onKeyPress={fractionOrDecimalInput}
          required
          error={errors?.ivPalletLength}
        />
      </InputLabel>
      <InputLabel text={t`Sheets per Pallet`} size="lg">
        <Input
          name="ivPalletSheets"
          ref={register({ required: isEmpty(getValues('ivPalletMaxHeight')) })}
          onKeyPress={positiveIntegerOnlyInput}
          onChange={() => trigger('ivPalletMaxHeight')}
          disabled={!!palletMaxHeight}
          required
        />
      </InputLabel>
      <InputLabel text={t`Max Pallet Height (cm)`} size="lg">
        <Input
          name="ivPalletMaxHeight"
          ref={register({ required: isEmpty(getValues('ivPalletSheets')) })}
          onChange={() => trigger('ivPalletSheets')}
          required
          disabled={!!palletSheets}
        />
      </InputLabel>
      <div className="flex items-end">
        <InputLabel text={t`Unit Quantity`} size="lg" required>
          <Input
            name="ivQtyPallets"
            ref={register({ maxLength: 9, required: true })}
            required
            width="36"
            onKeyDown={positiveIntegerOnlyInput}
            error={errors?.ivQtyPallets}
          />
        </InputLabel>
        <InputLabel text="" size="lg" className="ml-16">
          <Select
            name="ivSheetUom"
            ref={register({ required: true })}
            options={UOM_OPTIONS}
            required
            width="36"
            error={errors?.ivSheetUom}
          />
        </InputLabel>
      </div>
    </Space>
  );
};

export default SheetsCriteria;
