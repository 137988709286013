import { t } from '@lingui/macro';
import { useCallback } from 'react';
import { deriveCustomerOptions } from '../../../../services/Customer';
import Option from '../../../../types/Option.type';
import useDeliveryLocations from './useDeliveryLocations';

const NEW_ADDRESS_OPTION: Option = {
  value: '',
  label: t`New Address`,
};

const useDeliveryLocationOptions = (): {
  deliveryLocationOptions: Option[];
  isFetching: boolean;
} => {
  const { deliveryLocations, isFetching } = useDeliveryLocations();
  const options: Option[] = useCallback(
    () =>
      deliveryLocations
        ? [
            NEW_ADDRESS_OPTION,
            ...deriveCustomerOptions(
              deliveryLocations,
              false,
              false,
              false,
              true
            ),
          ]
        : [NEW_ADDRESS_OPTION],
    [deliveryLocations]
  )();
  return {
    deliveryLocationOptions: options,
    isFetching,
  };
};

export default useDeliveryLocationOptions;
