import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import WrappingType from '../WrappingType.enum';

interface IStaticWrappingTypeCellProps {
  externalWrappingType: string;
}

const StaticWrappingTypeCell: FC<IStaticWrappingTypeCellProps> = ({
  externalWrappingType,
}) => {
  return (
    <>
      {externalWrappingType === WrappingType.BULK_PACKED && (
        <span>
          <Trans>Bulk Packed</Trans>
        </span>
      )}
      {externalWrappingType === WrappingType.REAM_WRAPPED && (
        <span>
          <Trans>Ream Wrapped</Trans>
        </span>
      )}
    </>
  );
};

export default StaticWrappingTypeCell;
