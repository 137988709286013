import * as React from 'react';
import { Button, ButtonProps } from '../Button/Button';

const BasketPopUpButton: React.FunctionComponent<ButtonProps> = ({
  children,
  className = '',
  ...rest
}) => {
  return (
    <Button className={`w-full ${className}`} {...rest}>
      {children}
    </Button>
  );
};

export default BasketPopUpButton;
