import QuickOrderRow from '../../components/QuickOrderBookingTable/QuickOrderRow.interface';
import { AvailabilityBasketItem } from '../../types/AvailabilityBasketItem.interface';
import { StockAvailabilityOverview } from '../../types/StockAvailabilityOverview.interface';
import PutUpCategory from '../../types/PutUpCategory.enum';

export default function addToAvailabilityBasketItem(
  baseResult: AvailabilityBasketItem,
  row: QuickOrderRow | StockAvailabilityOverview
): AvailabilityBasketItem {
  const basketItem =
    row.productType === PutUpCategory.SHEET
      ? {
          ...baseResult,
          lengthImp: row.lengthInches,
          widthImp: row.widthInches,
          length: row.length,
          width: row.width,
          sheetsPerPalletNa: parseFloat(row.sheetsPerPallet || '0'),
          sheetsPerPallet: row.sheetsPerPallet || '0',
          mWeight: row.weight,
        }
      : {
          ...baseResult,
          reelWidth: row.reelWidth,
          reelWidthImp: row.reelWidthInches,
          reelDiameter: row.reelDiameter,
          reelDiameterImp: row.reelDiameterInches,
          reelWeight: `${row.weightKg}`,
          reelWeightImp: `${Math.round(row.weight || 0)}`,
        };

  return basketItem;
}
