import Axios from 'axios';
import { filter } from 'lodash';
import { OpenOrderFilters } from '../../domain/OpenOrders/Results/OpenOrderFilters.interface';
import { OpenOrdersResults } from '../../types/OpenOrdersResults.interface';
import { OrderOverviewRecord } from '../../types/OrderOverviewRecord.interface';

const applyMillFilter = (
  millName: string,
  results: OrderOverviewRecord[]
): OrderOverviewRecord[] => {
  return filter(results, { millName });
};

export const searchOpenOrders = async (
  _key: string,
  queryParams: OpenOrderFilters
): Promise<OpenOrdersResults> => {
  const url = `/portal/resources/mill-direct-orders/search?all=true&customerNumber=${queryParams.customerNumber}&finishedFlag=true&openFlag=true&page=1&pageSize=1&underProductionFlag=true`;
  const { data } = await Axios.get<OpenOrdersResults>(url, {
    params: queryParams,
  });
  return {
    ...data,
    results: queryParams.millName
      ? applyMillFilter(queryParams.millName, data.results)
      : data.results,
  };
};
