import create, { State } from 'zustand';
import ProductQuery from '../../types/ProductQuery.interface';

interface ProductAvailabilityState extends ProductQuery, State {}

const useProductAvailabilityStore = create<ProductAvailabilityState>(() => ({
  customerSoldTo: '',
}));

export default useProductAvailabilityStore;
