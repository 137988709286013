import { t } from '@lingui/macro';
import React from 'react';
import { Space } from 'antd';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import { FRACTION_DECIMAL_INPUT } from '../../../types/RegularExpression.constants';

interface IImperialAreaPerSheetProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

const ImperialCriteria: React.FunctionComponent<IImperialAreaPerSheetProps> = ({
  register,
  errors,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Input
        name="reelWidthImp"
        ref={register({
          required: true,
          pattern: FRACTION_DECIMAL_INPUT,
        })}
        required
        error={errors?.reelWidthImp}
        placeholder={t`Roll Width (in)`}
      />
      <Input
        name="lengthImp"
        ref={register({
          required: true,
          pattern: FRACTION_DECIMAL_INPUT,
        })}
        required
        error={errors?.lengthImp}
        placeholder={t`Length (ft)`}
      />
    </Space>
  );
};

export default ImperialCriteria;
