import create from 'zustand';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';

type BasketState = {
  reloadBasketStatus: boolean;
  baskets: AvailabilityBasketEU[];
  setReloadBasketStatus: (reloadStatus: boolean) => void;
  setBaskets: (baskets: AvailabilityBasketEU[]) => void;
  updateBasket: (updatedBasket: AvailabilityBasketEU) => void;
};

const useBasketStore = create<BasketState>((set) => ({
  baskets: [],
  reloadBasketStatus: false,
  setReloadBasketStatus: (reloadStatus: boolean) =>
    set(() => ({ reloadBasketStatus: reloadStatus })),
  setBaskets: (baskets: AvailabilityBasketEU[]) => set(() => ({ baskets })),
  updateBasket: (updatedBasket: AvailabilityBasketEU) =>
    set((state) => {
      return {
        ...state,
        baskets: state.baskets.map((basket) =>
          basket.id === updatedBasket.id ? updatedBasket : basket
        ),
      };
    }),
}));

export default useBasketStore;
