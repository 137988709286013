import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import loosePagesImage from '../../../../assets/dummy_sample/loose_pages.png';
import perfectBoundImage from '../../../../assets/dummy_sample/perfect_bound.png';
import stitchedImage from '../../../../assets/dummy_sample/stitched.png';
import Radio from '../../../../components/Radio/Radio';
import BindingTypeEnum from './BindingType.enum';

interface IBindingTypeProps {
  register: UseFormMethods['register'];
}

const BindingType: React.FunctionComponent<IBindingTypeProps> = ({
  register,
}) => {
  return (
    <div className="w-2/3 ml-14">
      <h3 className="text-xl font-bold mb-6">
        <Trans>Binding type</Trans>
      </h3>
      <div className="flex justify-around">
        <div>
          <img className="mb-4" src={stitchedImage} alt={t`Stitched`} />
          <Radio
            name="bindingType"
            value={BindingTypeEnum.STICHED_STAPLED}
            labelPosition="above"
            label={
              <span className="text-lg">
                <Trans>Stitched / stapled</Trans>
              </span>
            }
            ref={register({ required: true })}
            width="full"
            required
          />
        </div>
        <div>
          <img
            className="mb-4"
            src={perfectBoundImage}
            alt={t`Perfect Bound/Glued`}
          />
          <Radio
            name="bindingType"
            value={BindingTypeEnum.PERFECT_BOUND_GLUED}
            label={
              <span className="text-lg">
                <Trans>Perfect bound / glued</Trans>
              </span>
            }
            labelPosition="above"
            ref={register({ required: true })}
            width="full"
            required
          />
        </div>
        <div>
          <img
            className="mb-4"
            src={loosePagesImage}
            alt={t`No Binding / Loose Pages`}
          />
          <Radio
            name="bindingType"
            value={BindingTypeEnum.NO_BINDING_LOOSE_PAGES}
            label={
              <span className="text-lg">
                <Trans>No binding / loose pages</Trans>
              </span>
            }
            labelPosition="above"
            ref={register({ required: true })}
            width="full"
            required
          />
        </div>
      </div>
    </div>
  );
};

export default BindingType;
