import Option from '../../../types/Option.type';

export const getTonPriceTradeBasis = (
  tradeBasis: string | undefined,
  tradeBasisOptions: Option[]
): string | undefined => {
  if (tradeBasis !== undefined) {
    const found = tradeBasisOptions.find(
      (tradeBasisOption) => tradeBasisOption.value === tradeBasis
    );

    const label = found?.label;

    if (label && typeof label === 'string') {
      return label;
    }
  }

  return undefined;
};
