import { t, Trans } from '@lingui/macro';
import { Table as AntTable } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import Table from '../../../../components/Table/Table';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import useRole from '../../../../hooks/useRole';
import { deleteSampleFromBasket } from '../../../../services/SamplesOrder';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import SamplesProductTypeEnum from '../../../../types/Samples/SamplesProductType.enum';
import SamplesProductImage from '../../SamplesProductImage';
import SamplesProductType from '../../SamplesProductType';
import DescriptionCell from './DescriptionCell';
import './ItemsTable.scss';
import { getTotalPrice } from './ItemsTable.util';
import ProductCell from './ProductCell';
import QuantityCell from './QuantityCell';

interface IItemsTableProps {
  updating?: boolean;
  items: SamplesProduct[];
  buyerName?: string;
  readonly?: boolean;
}

const ItemsTable: React.FunctionComponent<IItemsTableProps> = ({
  updating,
  items,
  buyerName,
  readonly,
}) => {
  const { isInternalUser, isFetching } = useRole();
  const queryCache = useQueryCache();
  const { register, errors, getValues, trigger, watch, reset } =
    useFormContext() || ({} as any);
  const [totalPrice, setTotalPrice] = useState('0');
  const { format } = useNumberFormatter();
  const DELETE_MESSAGE = t`Are you sure you want to delete this item?`;

  const onRemove = async (product: SamplesProduct) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(DELETE_MESSAGE)) {
      await deleteSampleFromBasket(product.id);
      queryCache.invalidateQueries(
        QueryCacheName.SAMPLES_ORDER_DELIVERY_COST_DETAILS
      );
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDER);
    }
  };
  const updateTotalPrice = useCallback((): void => {
    const quantities = getValues
      ? (getValues().quantity as string[])
      : items.map((i) => i.quantity);
    const price = getTotalPrice(
      quantities,
      items.map((i) => i.price)
    );
    setTotalPrice(format(price) as string);
  }, [format, items, getValues]);

  useEffect(() => {
    updateTotalPrice();
  }, [items, updateTotalPrice]);

  const columns: ColumnsType<SamplesProduct> = [
    {
      title: t`Item`,
      dataIndex: 'item',
      key: 'item',
      className: 'p-4',
      render: (text: string, record: SamplesProduct) => (
        <div style={{ maxWidth: '150px', width: '95%' }}>
          <SamplesProductImage product={record} />
        </div>
      ),
    },
    {
      title: t`Product`,
      dataIndex: 'product',
      key: 'product',
      width: '215px',
      render: (text: string, record: SamplesProduct, index: number) => (
        <ProductCell
          product={record}
          isInternalUser={isInternalUser}
          register={register}
          index={index}
          updating={updating}
          readonly={readonly}
        />
      ),
    },
    {
      title: t`Type`,
      dataIndex: 'type',
      key: 'type',
      render: (text: string, record: SamplesProduct) => (
        <SamplesProductType type={record.type as SamplesProductTypeEnum} />
      ),
    },
    {
      title: t`Item Detail`,
      dataIndex: 'itemDetail',
      key: 'itemDetail',
      render: (text: string, record: SamplesProduct) => (
        <LabelValue
          inverse
          inline
          label={<Trans>UNIT</Trans>}
          className="w-36"
          value={
            <>
              <span>{record.unitSize}&nbsp;</span>
              <span>
                {record.unitSizeUnit === 'ST' ? 'PCE' : record.unitSizeUnit}
              </span>
            </>
          }
        />
      ),
    },
    {
      title: t`Description`,
      dataIndex: 'description',
      key: 'description',
      render: (text: string, record: SamplesProduct, index: number) => (
        <DescriptionCell
          product={record}
          register={register}
          watch={watch}
          updating={updating}
          index={index}
          readonly={readonly}
        />
      ),
    },
    {
      title: t`Quantity`,
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: string, record: SamplesProduct, index: number) => (
        <QuantityCell
          product={record}
          isInternalUser={isInternalUser}
          isFetchingUser={isFetching}
          register={register}
          index={index}
          errors={errors}
          trigger={trigger}
          onRemove={onRemove}
          updating={updating}
          onChange={updateTotalPrice}
          readonly={readonly}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="p-4 text-2xl bg-blue-pacific text-white-white font-bold">
        {buyerName}
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={items}
        pagination={false}
        className="sappi-override-samples-basket-table"
        summary={() => (
          <AntTable.Summary.Row>
            <AntTable.Summary.Cell
              colSpan={5}
              className="text-right p-5 font-bold text-lg border-r border-gray-light400"
              index={0}
            >
              <Trans>Total Price</Trans>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell
              className={`p-5 font-bold text-lg ${
                readonly ? 'text-black-black' : 'text-blue-pacific'
              }`}
              index={1}
            >
              {totalPrice}
            </AntTable.Summary.Cell>
          </AntTable.Summary.Row>
        )}
      />
    </div>
  );
};

export default ItemsTable;
