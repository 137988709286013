import { useQuery } from 'react-query';
import { getValuesFor } from '../services/Values';
import QueryCacheName from '../types/QueryCacheName.enum';
import ValueKey from '../types/ValueKey.enum';
import { ValueText } from '../types/ValueText.interface';

const useValues = (
  key: ValueKey
): { items: ValueText[] | undefined; isFetching: boolean } => {
  const { data: items, isFetching } = useQuery(
    [QueryCacheName.VALUES, key],
    () => getValuesFor(key),
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    items,
    isFetching,
  };
};

export default useValues;
