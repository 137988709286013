import React, { FC, useCallback, useEffect, useState } from 'react';
import useDashboardWidgets from '../../hooks/useDashboardWidgets';
import useUserCustomization from '../../hooks/useUserCustomization';
import safelyParseJsonToStringArray from '../../services/Util/safelyParseJsonToStringArray.util';
import DashboardWidgetId from './types/DashboardWidgetId.enum';
import { getDashboardWidgetId } from './Widgets/Widgets.util';
import WidgetTabs from './Widgets/WidgetTabs';

interface IWidgetsProps {
  className?: string;
}
const Widgets: FC<IWidgetsProps> = ({ className = '' }) => {
  const { data: userCustomization } = useUserCustomization();
  const { authorizedDashboardWidgets } = useDashboardWidgets();
  const [tabIds, setTabIds] = useState<DashboardWidgetId[]>([]);

  const getAuthorizedTabNames = useCallback(
    (unauthorizedTabNames: string[]) => {
      return unauthorizedTabNames.filter((unauthorizedTabName) =>
        authorizedDashboardWidgets.some(
          (authorizedWidget) => authorizedWidget.id === unauthorizedTabName
        )
      );
    },
    [authorizedDashboardWidgets]
  );

  useEffect(() => {
    if (userCustomization) {
      const parsedTabNames = safelyParseJsonToStringArray(
        userCustomization.tabs
      );

      if (parsedTabNames) {
        const authorizedTabNames = getAuthorizedTabNames(parsedTabNames);
        setTabIds(
          authorizedTabNames.map((tabName) => getDashboardWidgetId(tabName))
        );
      }
    }
  }, [userCustomization, getAuthorizedTabNames]);

  return (
    <div className={`${className}`}>
      <WidgetTabs tabIds={tabIds} />
    </div>
  );
};

export default Widgets;
