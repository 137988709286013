import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import sappiLogoImg from '../../../../assets/sappi-logo-blue-on-transparent-with-padding.png';
import { Button } from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../../hooks/useAnalytics';
import useUser from '../../../../hooks/useUser';
import { convertHtmlToPdf } from '../../../../services/Util/convertHtmlToPdf.util';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { calculateEqSavings } from '../../Calculator.service';
import CalculateEqSavingsParams from '../../types/EqCalculator/CalculateEqSavingsParams.interface';
import BenefitsSection from './BenefitsSection';
import { getTotalWeightFromParams } from './EqCalculatorResults.util';
import ImpactSection from './ImpactSection';
import ProductsSection from './ProductsSection';

const RESULTS_ELEMENT_ID = 'eq-calculator-results';

interface EqCalculatorResultsProps {
  paramsForCalculation: CalculateEqSavingsParams;
}

const EqCalculatorResults: FC<EqCalculatorResultsProps> = ({
  paramsForCalculation,
}) => {
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();
  const [hideForPdfGeneration, setHideForPdfGeneration] = useState(false);

  const {
    data: calculationResults,
    isFetching: isFetchingCalculationResults,
  } = useQuery(
    [QueryCacheName.EQ_CALCULATED_SAVINGS, paramsForCalculation],
    (
      queryKey: QueryCacheName.EQ_CALCULATED_SAVINGS,
      queryParams: CalculateEqSavingsParams
    ) => calculateEqSavings(queryParams),
    {
      enabled: paramsForCalculation,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  const totalWeight = getTotalWeightFromParams(paramsForCalculation);

  const downloadResults = () => {
    setHideForPdfGeneration(true);
    trackPageView(
      'CALCULATORS',
      'DOWNLOAD_EQ_RESULTS',
      user?.username ? { username: user.username } : undefined
    );

    convertHtmlToPdf(RESULTS_ELEMENT_ID, 'eqCalculationResults.pdf');
    setTimeout(() => setHideForPdfGeneration(false), 2000);
  };

  return (
    <div className="mr-14" id={RESULTS_ELEMENT_ID}>
      {isFetchingCalculationResults ? (
        <LoadingSpinner horizontal />
      ) : (
        !!calculationResults && (
          <Space direction="vertical" className="w-full">
            <div className="flex -mx-0.5">
              <Space direction="vertical" className="w-1/2 mx-0.5 font-bold">
                <h2 className="text-3xl">
                  <Trans>Sappi Products</Trans>
                </h2>
                <div className="text-3xl text-blue-euHeader">
                  {totalWeight} {calculationResults.units}
                </div>
                <div className="text-xl">{calculationResults.title}</div>
              </Space>
              <div className="w-1/2 mx-0.5">
                <img
                  src={sappiLogoImg}
                  alt={t`Sappi logo`}
                  className="w-44 -mt-4"
                />
              </div>
            </div>
            <div className="flex -mx-1">
              <div className="w-1/2 mx-1">
                <ImpactSection
                  calculationResults={calculationResults}
                  paperTotalWeight={totalWeight}
                />
              </div>
              <div className="w-1/2 mx-1">
                <Space direction="vertical" size="large" className="w-full">
                  <ProductsSection
                    selectedBrands={paramsForCalculation.brands}
                    weightUnits={calculationResults.units}
                  />
                  <BenefitsSection />
                </Space>
              </div>
            </div>
            {!hideForPdfGeneration && (
              <div className="m-4">
                <Button onClick={downloadResults}>
                  <Trans>Download</Trans>
                </Button>
              </div>
            )}
            <div className="text-right">
              <Trans>
                &copy; 2019 Sappi North America, Inc. All Rights Reserved.
              </Trans>
            </div>
          </Space>
        )
      )}
    </div>
  );
};

export default EqCalculatorResults;
