import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { Space } from 'antd';
import Table from '../../components/Table/Table';
import { Order } from '../../types/Order.interface';
import LabelValue from '../../components/LabelValue/LabelValue';
import stripZeros from '../../services/Util/stripZeros.util';
import './ClaimsReporting.scss';

interface IFileClaimTableProps {
  results: Order[];
}
const FileClaimTable: React.FunctionComponent<IFileClaimTableProps> = ({
  results,
}) => {
  const getRowKey = (record: Order): string => {
    return (
      record.customerOrderNumber +
      record.documentNumber +
      record.documentPosition +
      record.materialDescription +
      record.zzcogrm +
      record.confirmedWidth +
      record.confirmedLength +
      record.reelDiameter +
      record.materialNumber +
      record.quantity +
      record.quantityProduced
    );
  };

  const columns: ColumnsType<Order> = [
    {
      title: (
        <div className="py-4 text-lg font-bold">
          <Trans>Order #</Trans>
        </div>
      ),
      key: 'availableStockOfAlternatives',
      width: '30%',
      render: (value, record) => (
        <>
          <LabelValue
            inline
            inverse
            label={t`My Order #`}
            value={record.customerOrderNumber}
          />
          <LabelValue
            inline
            inverse
            className="mt-3"
            label={t`Sappi Order #`}
            value={t`${stripZeros(record.documentNumber)}/${stripZeros(
              record.documentPosition
            )}`}
          />
        </>
      ),
    },
    {
      title: (
        <div className="py-4 text-lg font-bold">
          <Trans>Description</Trans>
        </div>
      ),
      key: 'unitOfAlternatives',
      width: '30%',
      render: (value, record) => (
        <>
          <LabelValue
            inline
            inverse
            label={t`Brand`}
            value={record.materialDescription}
          />
          <LabelValue
            inline
            inverse
            className="mt-3"
            label={t`Grammage`}
            value={record.zzcogrm}
          />
          {record.confirmedWidth && (
            <LabelValue
              inline
              inverse
              className="mt-3"
              label={t`Width`}
              value={record.confirmedWidth}
            />
          )}
          {record.confirmedLength && (
            <LabelValue
              inline
              inverse
              className="mt-3"
              label={t`Length`}
              value={record.confirmedLength}
            />
          )}
          {record.reelDiameter && (
            <LabelValue
              inline
              inverse
              className="mt-3"
              label={t`Roll Diameter`}
              value={record.reelDiameter}
            />
          )}
          {record.labelText1 && (
            <>
              <div className="flex mt-3">
                <Space>
                  <div className="font-bold">
                    <Trans>Label Text/Stencil:</Trans>
                  </div>
                  <div>{record.labelText1}</div>
                  {record.labelText2 && <div>{record.labelText2}</div>}
                  {record.labelText3 && <div>{record.labelText3}</div>}
                  {record.labelText4 && <div>{record.labelText4}</div>}
                </Space>
              </div>
            </>
          )}
          {record.materialNumber && (
            <LabelValue
              inline
              inverse
              className="mt-3"
              label={t`Customer Material #`}
              value={record.materialNumber}
            />
          )}
        </>
      ),
    },
    {
      title: (
        <div className="py-4 text-lg font-bold">
          <Trans>Quantity</Trans>
        </div>
      ),
      key: 'descriptionOfAlternatives',
      width: '40%',
      render: (value, record) => (
        <>
          <LabelValue
            inline
            inverse
            label={t`Order Quantity`}
            value={record.quantity}
          />
          <LabelValue
            inline
            inverse
            className="mt-3"
            label={t`Quantity Produced`}
            value={
              <div>
                {record.quantityProduced}{' '}
                <Trans>(net / gross / sheet count)</Trans>
              </div>
            }
          />
        </>
      ),
    },
  ];

  return (
    <div className="relative">
      <Table
        dataSource={results}
        columns={columns}
        pagination={false}
        rowKey={(record) => getRowKey(record)}
        className="sappi-ant-override-file-claim"
        locale={{
          emptyText: (
            <div className="text-gray-600 text-xl">
              <Trans>No order found</Trans>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default FileClaimTable;
