import { Trans } from '@lingui/macro';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import Textarea from '../../../components/Textarea/Textarea';
import DummyButtons from './DummyButtons';
import useDummyOrderStore from './useDummyOrder.store';
import DummySamplesUserGuide from './DummySamplesUserGuide';

const DummySamplesDescription: React.FunctionComponent = () => {
  const history = useHistory();
  const state = useDummyOrderStore();
  const { register, watch } = useForm({
    mode: 'all',
    defaultValues: {
      description: state.description,
    },
  });

  const descriptionValues = watch('description') || '';

  const next = () => {
    useDummyOrderStore.setState({
      description: descriptionValues.trim(),
    });
    history.push('/samples/createDummy/format');
  };

  return (
    <>
      <DummySamplesUserGuide />
      <form>
        <div style={{ minWidth: '960px' }}>
          <Card header={<Trans>Please give your dummy a description</Trans>}>
            <div className="text-xl font-extrabold pl-2 pb-3">
              Dummy description
            </div>
            <div className="pl-2" style={{ width: '435px' }}>
              <Textarea
                name="description"
                maxLength={1000}
                width="full"
                rows={5}
                ref={register()}
              />
            </div>
          </Card>

          <DummyButtons
            onNext={next}
            onNextDisabled={descriptionValues.trim().length < 1}
          />
        </div>
      </form>
    </>
  );
};

export default DummySamplesDescription;
