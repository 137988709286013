import { Popover } from 'antd';
import * as React from 'react';
import './SearchPopover.scss';
import SearchPopoverContent from './SearchPopoverContent';

interface ISearchPopoverProps {
  title: string | React.ReactNode;
  onClose: () => void;
  body: string | React.ReactNode;
  button: React.ReactNode;
  visible: boolean;
  className?: string;
  placement?:
    | 'left'
    | 'right'
    | 'topLeft'
    | 'top'
    | 'bottom'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | undefined;
}

const SearchPopover: React.FunctionComponent<ISearchPopoverProps> = ({
  title,
  onClose,
  visible,
  placement,
  body,
  button,
  className,
  children,
}) => {
  const classes = `${className || 'sappi-popover-search-width'}`;

  return (
    <Popover
      placement={placement}
      content={
        <SearchPopoverContent
          title={title}
          body={body}
          button={button}
          onClose={onClose}
        />
      }
      visible={visible}
      overlayClassName={classes}
    >
      <span>{children}</span>
    </Popover>
  );
};

export default SearchPopover;
