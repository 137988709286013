import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { Button } from '../../../../components/Button/Button';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import ViewSampleOrdersTableRowType from '../../../../types/Samples/ViewSampleOrdersTableRowType.enum';

interface IOrderCellProps {
  order: AugmentedSamplesHistoryOrder;
  rowType: ViewSampleOrdersTableRowType;
  expanded?: boolean;
  onToggleExpanded?: () => void;
  onDocRefClick?: () => void;
}

const OrderCell: React.FunctionComponent<IOrderCellProps> = ({
  order,
  rowType,
  expanded,
  onToggleExpanded,
  onDocRefClick,
}) => {
  const caretDirection = expanded ? 'down' : 'right';
  const isGrouped = rowType === ViewSampleOrdersTableRowType.GROUPED;
  const isSingle = rowType === ViewSampleOrdersTableRowType.SINGLE;
  const isSubitem = rowType === ViewSampleOrdersTableRowType.SUBITEM;

  return (
    <DeconstructedTableBodyCell className="order-cell">
      <Space size="large" direction="vertical">
        {isGrouped && (
          <button onClick={onToggleExpanded} className="text-left">
            <i className={`icon fa fa-caret-${caretDirection} pr-1`} />{' '}
            <Trans>Document Ref #:</Trans> {stripZeros(order.documentNumber)}
          </button>
        )}
        {(isSingle || isSubitem) && onDocRefClick && (
          <div>
            <div className="font-bold">
              <Trans>Document Ref #:</Trans>
            </div>
            <Button
              theme="link"
              className="text-3xl"
              onClick={() => onDocRefClick()}
            >
              {stripZeros(order.documentNumber)}/
              {stripZeros(order.documentPosition)}
            </Button>
          </div>
        )}
        {(isGrouped || isSingle) && (
          <div>
            <div className="font-bold">
              <Trans>Customer:</Trans>
            </div>
            <div>{order?.soldToCustomerInfo?.name}</div>
          </div>
        )}
      </Space>
    </DeconstructedTableBodyCell>
  );
};

export default OrderCell;
