import * as React from 'react';
import ExpandedRowProductDescription from './ExpandedRowProductDescription';
import QuickOrderRow from './QuickOrderRow.interface';

interface IExpandedRowProps {
  record: QuickOrderRow;
}

const ExpandedRow: React.FunctionComponent<IExpandedRowProps> = ({
  record,
}) => {
  return (
    <section className="flex flex-row py-4">
      <ExpandedRowProductDescription record={record} />
      {/* <ExpandedRowProductLocation record={record} /> */}
    </section>
  );
};

export default ExpandedRow;
