import { useEffect, useState } from 'react';
import useRole from '../../../hooks/useRole';
import useUser from '../../../hooks/useUser';
import { deriveCustomerOptions } from '../../../services/Customer';
import Option from '../../../types/Option.type';

const useCustomerOptions = (): {
  customerOptions: Option[];
  isFetchingUser: boolean;
} => {
  const { isNonReleaseEuUser } = useRole();
  const { data: user, isFetching } = useUser();
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (user?.customers) {
      const { customers } = user;
      const derivedCustomerOptions = deriveCustomerOptions(
        customers || [],
        isNonReleaseEuUser,
        false,
        false
      );
      setCustomerOptions(derivedCustomerOptions);
    }
  }, [user, isNonReleaseEuUser]);

  return {
    customerOptions,
    isFetchingUser: isFetching,
  };
};

export default useCustomerOptions;
