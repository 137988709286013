import { find } from 'lodash';
import DashboardWidgetId from '../types/DashboardWidgetId.enum';
import DashboardWidgetInfo from '../types/DashboardWidgetInfo.interface';

export const getDashboardWidgetId = (name: string): DashboardWidgetId => {
  if (name === DashboardWidgetId.PRINT_DOCUMENTS) {
    return DashboardWidgetId.PRINT_DOCUMENTS;
  }

  if (name === DashboardWidgetId.PRODUCT_AVAILABILITY_NA) {
    return DashboardWidgetId.PRODUCT_AVAILABILITY_NA;
  }

  if (name === DashboardWidgetId.PRODUCT_AVAILABILITY_EU_RELEASE) {
    return DashboardWidgetId.PRODUCT_AVAILABILITY_NA;
  }

  if (name === DashboardWidgetId.PRODUCT_AVAILABILITY_EU) {
    return DashboardWidgetId.PRODUCT_AVAILABILITY_NA;
  }

  return DashboardWidgetId.TRACK_BY_LABEL_ID;
};

export const getDashboardWidgetLabel = (
  id: DashboardWidgetId,
  dashboardWidgetInfos: DashboardWidgetInfo[]
): string => {
  const widgetInfo = find(dashboardWidgetInfos, { id });
  return widgetInfo?.label || '';
};
