import { useQuery } from 'react-query';
import { getDivisions } from '../../services/Customer';
import { EMPTY_OPTION } from '../../services/Util/emptyOption.const';
import generateOptions from '../../services/Util/generateOptions.util';
import Option from '../../types/Option.type';
import QueryCacheName from '../../types/QueryCacheName.enum';

const useDivisionOptions = (
  customerNumber: string
): {
  divisionOptions: Option[];
  isFetchingDivision: boolean;
} => {
  const { data: divisions, isFetching } = useQuery(
    [QueryCacheName.STOCK_LOT_DIVISIONS, customerNumber],
    () => getDivisions(customerNumber),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      enabled: customerNumber,
      keepPreviousData: true,
    }
  );

  const divisionOpts = generateOptions(
    (divisions || []).sort(),
    (item) => ({
      label: item === '10' ? 'Graphics' : 'Specialties',
      value: item,
    }),
    EMPTY_OPTION
  );

  return {
    divisionOptions: divisionOpts,
    isFetchingDivision: isFetching,
  };
};

export default useDivisionOptions;
