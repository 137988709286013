import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import Date from '../../../components/Date';
import InformationModal from '../../../components/InformationModal/InformationModal';
import LabelValue from '../../../components/LabelValue/LabelValue';
import { getPartnerSearchCustomerByNumber } from '../../../services/Customer';
import stripZeros from '../../../services/Util/stripZeros.util';
import { PartnerSearchCustomer } from '../../../types/PartnerSearchCustomer.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { getShippingUrl } from './ShippingInformationModal.util';

interface IShippingInformationModalProps {
  visible: boolean;
  documentNumber: string;
  documentPosition: string;
  shipToCustomerNumber: string;
  details: {
    shipmentDate?: number;
    deliveryDate?: number;
    status?: string; // Enum?
    shippingMethodText?: string;
    shipper?: string;
    tourName?: string;
  };
  onClose: () => void;
}

const ShippingInformationModal: React.FunctionComponent<IShippingInformationModalProps> = ({
  visible,
  documentNumber,
  documentPosition,
  shipToCustomerNumber,
  details,
  onClose,
}) => {
  const [shippingUrl, setShippingUrl] = useState<string>('');
  const [deliveryLocation, setDeliveryLocation] = useState<string>('');
  const { resolvedData: shipToCustomer } = usePaginatedQuery<
    PartnerSearchCustomer
  >(
    [QueryCacheName.PARTNER_SEARCH_CUSTOMER, shipToCustomerNumber, true],
    getPartnerSearchCustomerByNumber,
    {
      staleTime: Infinity,
      enabled: visible,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (shipToCustomer) {
      setDeliveryLocation(shipToCustomer?.name);
    }
  }, [shipToCustomer]);

  useEffect(() => {
    setShippingUrl(getShippingUrl(details.shipper, details.tourName));
  }, [details.shipper, details.tourName]);

  return (
    <InformationModal
      title={t`Information About Shipment`}
      detailsTitle={t`Shipment Details`}
      visible={visible}
      summaryFields={[
        {
          label: t`Document Ref #`,
          value: stripZeros(documentNumber),
        },
        {
          label: t`Delivery Location`,
          value: deliveryLocation,
        },
      ]}
      onCancel={onClose}
    >
      <div className="mb-2 mt-4 ml-3">
        <Trans id="order">Order</Trans> {stripZeros(documentNumber)}/
        {stripZeros(documentPosition)}
      </div>
      <div className="bg-gray-whiteSmoke200 py-1 px-1 mb-2 border-gray-light400 border-t">
        <div className="flex m-1 py-3 px-6 text-lg">
          <div className="w-1/2">
            {details.shipmentDate && (
              <LabelValue
                className="text-base"
                inline
                inverse
                center
                label={t`Shipment Date`}
                value={<Date date={details.shipmentDate} />}
              />
            )}
            {details.deliveryDate && (
              <LabelValue
                className="text-base"
                inline
                inverse
                center
                label={t`Est. Arrival Date`}
                value={<Date date={details.deliveryDate} />}
              />
            )}
            {details.status && (
              <LabelValue
                className="text-base"
                inline
                inverse
                center
                label={t`Status`}
                value={details.status}
              />
            )}
          </div>
          <div className="w-1/2">
            {details.shippingMethodText && (
              <LabelValue
                className="text-base"
                inline
                inverse
                center
                label={t`Shipping Method`}
                value={details.shippingMethodText}
              />
            )}
            {details.shipper && (
              <LabelValue
                className="text-base"
                inline
                inverse
                center
                label={t`Shipping Service`}
                value={details.shipper}
              />
            )}
            {details.tourName && (
              <LabelValue
                className="text-base"
                inline
                inverse
                center
                label={t`Tracking Number`}
                value={
                  <a
                    className="text-blue-pacific underline"
                    href={shippingUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {details.tourName}
                  </a>
                }
              />
            )}
          </div>
        </div>
      </div>
    </InformationModal>
  );
};

export default ShippingInformationModal;
