import { StockAvailabilityOverview } from '../../../types/StockAvailabilityOverview.interface';

const getSheetsAvailable = (
  availability: StockAvailabilityOverview
): number => {
  let sheetsAvailable: number;
  switch (availability.unitOfMeasure.toLowerCase()) {
    case 'pallet':
    case 'carton':
      sheetsAvailable =
        availability.availableQuantity *
        parseInt(availability.sheetsPerPallet, 10);
      break;
    default:
      sheetsAvailable = 0;
  }
  return Math.round(sheetsAvailable);
};
export default getSheetsAvailable;
