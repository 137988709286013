import * as React from 'react';
import { Trans } from '@lingui/macro';
import { ClaimTypes } from '../../types/ClaimTypes.enum';
import useUser from '../../hooks/useUser';
import useRole from '../../hooks/useRole';
import Region from '../../types/Region.enum';

interface IFileClaimFormDescriptionsProps {
  evidence: boolean;
  chosenClaimType: ClaimTypes;
  complaintsReason: string;
  evidenceDescriptions: string[];
}
const FileClaimFormDescriptions: React.FunctionComponent<IFileClaimFormDescriptionsProps> = ({
  evidence,
  chosenClaimType,
  complaintsReason,
  evidenceDescriptions,
}) => {
  const { data: user } = useUser();
  const { isReleaseUser, isNonReleaseEuUser } = useRole();
  const isNaUser = user?.region === Region.NA;

  return (
    <>
      {(isNaUser || isReleaseUser) && (
        <>
          {evidence &&
            chosenClaimType !== ClaimTypes.TRANSIT &&
            chosenClaimType !== ClaimTypes.QUALITY && (
              <div className="pt-4 pb-3 flex-wrap" style={{ width: '500px' }}>
                <Trans>
                  You will be able to upload evidence files on the next screen.
                </Trans>
              </div>
            )}
          {(chosenClaimType === ClaimTypes.TRANSIT ||
            chosenClaimType === ClaimTypes.QUALITY) && (
            <div className="pt-4 pb-3 flex-wrap" style={{ width: '500px' }}>
              <Trans>
                Based on the problem you selected, evidence is required. You
                will be able to upload evidence files on the next screen.
              </Trans>
            </div>
          )}
          {evidenceDescriptions.length > 0 && (
            <ul className="pl-5">
              {evidenceDescriptions.map((description) => (
                <li className="font-bold mb-1" key={`${description}`}>
                  {description}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
      {isNonReleaseEuUser && (
        <>
          {evidence && complaintsReason === '' && (
            <div className="pt-4 pb-3 flex-wrap" style={{ width: '500px' }}>
              <Trans>
                You will be able to upload evidence files on the next screen.
              </Trans>
            </div>
          )}
          {complaintsReason !== '' && (
            <div className="pt-4 flex-wrap" style={{ width: '500px' }}>
              <Trans>
                Based on the problem you selected, the following evidence is
                required. You will be able to upload evidence files on the next
                screen.
              </Trans>

              <ul className="pl-5">
                {evidenceDescriptions.map((description) => (
                  <li className="font-bold mb-1" key={`${description}`}>
                    {description}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FileClaimFormDescriptions;
