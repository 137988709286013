import * as React from 'react';
import { FieldError } from 'react-hook-form';
import getWidthCssClass from '../../services/Util/getWidthCssClass.util';
import InputWrapper, {
  calculateInputWrapperWidth,
  calculateWrappedInputWidth,
} from '../InputWrapper/InputWrapper';

type TextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  error?: FieldError | { message: string } | undefined;
  width?: string;
};

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ name, error, width, className, ...rest }, ref) => (
    <InputWrapper error={error} width={calculateInputWrapperWidth(width)}>
      <textarea
        name={name}
        className={`${className || ''} ${getWidthCssClass(
          calculateWrappedInputWidth(width)
        )} max-w-full`}
        {...rest}
        ref={ref}
      />
    </InputWrapper>
  )
);

export default Textarea;
