import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

import AddAttachmentCore from './AddAttachmentCore';

const AddAttachment: FunctionComponent = () => {
  const { complaintNumber, fileNumber, customerNumber } = useParams<{
    complaintNumber: string;
    fileNumber: string;
    customerNumber: string;
  }>();
  return (
    <>
      <AddAttachmentCore
        complaintNumber={complaintNumber}
        fileNumber={fileNumber}
        customerNumber={customerNumber}
        isFileClaimUpdate={false}
      />
    </>
  );
};

export default AddAttachment;
