import { Trans } from '@lingui/macro';
import { Collapse } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import BackToTop from '../../../components/BackToTop/BackToTop';
import { Button } from '../../../components/Button/Button';
import ExpandIcon from '../../../components/ExpandIcon';
import { Inventory } from './Inventory.interface';
import MillInventoryResults from './MillInventoryResults';
import PlannedProductionResults from './PlannedProductionResults';
import './Results.scss';
import { getInventoryCount } from './Results.util';
import StockResults from './StockResults';

const { Panel } = Collapse;

interface IResultsProps {
  data: Inventory;
  stockInventoryVisible: boolean;
  millInventoryVisible: boolean;
  plannedProductionVisible: boolean;
}

enum ResultType {
  STOCK = 'STOCK',
  MILL_INVENTORY = 'MILL_INVENTORY',
  PLANNED_PRODUCTION = 'PLANNED_PRODUCTION',
}

const Results: React.FunctionComponent<IResultsProps> = ({
  data,
  stockInventoryVisible,
  millInventoryVisible,
  plannedProductionVisible,
}) => {
  const [stockActiveKey, setStockActiveKey] = useState<ResultType.STOCK[]>([
    ResultType.STOCK,
  ]);
  const [millInventoryActiveKey, setMillInventoryActiveKey] = useState<
    ResultType.MILL_INVENTORY[]
  >([ResultType.MILL_INVENTORY]);
  const [plannedProdActiveKey, setPlannedProdActiveKey] = useState<
    ResultType.PLANNED_PRODUCTION[]
  >([ResultType.PLANNED_PRODUCTION]);
  const [inventoryCount, setInventoryCount] = useState(0);
  const plannedProdRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setInventoryCount(
      getInventoryCount(
        data,
        stockInventoryVisible,
        millInventoryVisible,
        plannedProductionVisible
      )
    );
  }, [
    stockInventoryVisible,
    millInventoryVisible,
    plannedProductionVisible,
    data,
  ]);

  const scrollToPlannedProduction = () =>
    window.scrollTo(0, (plannedProdRef?.current?.offsetTop || 0) - 50);

  const NoResults = (
    <div className="center my-5">
      <Trans>
        No results found. Please contact your Sappi Inside Sales representative.
      </Trans>
    </div>
  );

  return (
    <>
      {inventoryCount === 0 ? (
        NoResults
      ) : (
        <>
          <div className="w-full flex justify-between my-5 mt-6 text-lg">
            <div>
              {inventoryCount} <Trans>results</Trans>
            </div>
            {plannedProductionVisible &&
              (millInventoryVisible || stockInventoryVisible) && (
                <div>
                  <Button
                    theme="link"
                    onClick={() => scrollToPlannedProduction()}
                  >
                    <Trans>View Planned Production</Trans>
                  </Button>
                </div>
              )}
          </div>
          <Collapse
            activeKey={stockActiveKey}
            bordered={false}
            className="sappi-ant-collapse-override"
            onChange={(keys) => {
              setStockActiveKey(keys as ResultType.STOCK[]);
            }}
            expandIcon={({ isActive }) => (
              <ExpandIcon
                isActive={isActive}
                iconClassName="relative top-1 -left-1"
              />
            )}
            destroyInactivePanel
            ghost
          >
            {data.stock.length > 0 && stockInventoryVisible && (
              <Panel
                key={ResultType.STOCK}
                className="mb-9"
                forceRender
                header={
                  <h2 className="text-3xl w-full">
                    <Trans>Stock</Trans>
                  </h2>
                }
              >
                <StockResults
                  data={data.stock}
                  isStockExpanded={stockActiveKey.includes(ResultType.STOCK)}
                />
              </Panel>
            )}
          </Collapse>
          {stockInventoryVisible && (
            <BackToTop className="text-lg w-full text-right relative bottom-5" />
          )}
          <Collapse
            activeKey={millInventoryActiveKey}
            bordered={false}
            className="sappi-ant-collapse-override"
            onChange={(keys) => {
              setMillInventoryActiveKey(keys as ResultType.MILL_INVENTORY[]);
            }}
            expandIcon={({ isActive }) => (
              <ExpandIcon
                isActive={isActive}
                iconClassName="relative top-1 -left-1"
              />
            )}
            destroyInactivePanel
            ghost
          >
            {data.mill.length > 0 && millInventoryVisible && (
              <Panel
                forceRender
                key={ResultType.MILL_INVENTORY}
                className="mb-9"
                header={
                  <h2 className="text-3xl w-full">
                    <Trans>Mill Inventory</Trans>
                  </h2>
                }
              >
                <MillInventoryResults data={data.mill} />
              </Panel>
            )}
          </Collapse>
          {millInventoryVisible && (
            <BackToTop className="text-lg w-full text-right relative bottom-5" />
          )}
          <Collapse
            activeKey={plannedProdActiveKey}
            bordered={false}
            className="sappi-ant-collapse-override"
            onChange={(keys) => {
              setPlannedProdActiveKey(keys as ResultType.PLANNED_PRODUCTION[]);
            }}
            expandIcon={({ isActive }) => (
              <ExpandIcon
                isActive={isActive}
                iconClassName="relative top-1 -left-1"
              />
            )}
            destroyInactivePanel
            ghost
          >
            {data.productionPlans.length > 0 && plannedProductionVisible && (
              <Panel
                forceRender
                key={ResultType.PLANNED_PRODUCTION}
                className="mb-9"
                header={
                  <h2 className="text-3xl w-full">
                    <Trans>Planned Production</Trans>
                  </h2>
                }
              >
                <div ref={plannedProdRef}>
                  <PlannedProductionResults data={data.productionPlans} />
                </div>
              </Panel>
            )}
          </Collapse>
          {plannedProductionVisible && (
            <BackToTop className="text-lg w-full text-right relative bottom-5" />
          )}
        </>
      )}
    </>
  );
};

export default Results;
