enum HeaderItemKey {
  EU_BASKET,
  NA_BASKET,
  SAMPLES_BASKET,
  QOB_BASKET,
  EXCEL_NOTIFICATION,
  CALCULATOR,
  STOCK_BOOK,
  HELP,
}

export default HeaderItemKey;
