import * as React from 'react';
import CustomerInfo from './CustomerInfo';
import SelectCustomer from './SelectCustomer';

interface IFinancialReportingSidebarProps {
  selectedCustomerNum: string;
  setSelectedCustomerNum(customerNumber: string): void;
}

const FinancialReportingSidebar: React.FunctionComponent<IFinancialReportingSidebarProps> = ({
  selectedCustomerNum,
  setSelectedCustomerNum,
}) => {
  return (
    <div className="border-r border-b border-l border-gray-light400">
      <SelectCustomer
        selectedCustomerNum={selectedCustomerNum}
        setSelectedCustomerNum={setSelectedCustomerNum}
      />
      <CustomerInfo customerNumber={selectedCustomerNum || ''} />
    </div>
  );
};

export default FinancialReportingSidebar;
