import React, { Fragment } from 'react';
import ExcelNotification from './ExcelNotification/ExcelNotification';
import HeaderCalculators from './HeaderCalculators/HeaderCalculators';
import HeaderHelp from './HeaderHelp';
import HeaderItem from './HeaderItem';
import BasicBasketPopup from './OldSappiBasket/BasicBasketPopup';
import NonReleaseEuBasketPopup from './OldSappiBasket/NonReleaseEuBasketPopup';
import SamplesBasketPopup from './OldSappiBasket/SamplesBasketPopup';
import PageTitle from './PageTitle';
import SappiBasket from './SappiBasket/SappiBasket';
import StockBook from './StockBook';
import HeaderItemKey from './types/HeaderItemKey.enum';
import HelpCategory from './types/HelpCategory.enum';

interface IPageHeaderProps {
  title: React.ReactNode | string;
  items: HeaderItemKey[];
  helpCategory?: HelpCategory;
}

const PageHeader: React.FunctionComponent<IPageHeaderProps> = ({
  title,
  items,
  helpCategory,
}) => {
  return (
    <header className="pb-4 pt-8 mb-10 border-b border-gray-light400 w-full flex justify-between items-end">
      <PageTitle title={title} />
      <div
        className="flex justify-end print:hidden"
        style={{ minWidth: '300px' }}
      >
        {items.map((item, index) => (
          <Fragment key={item}>
            {item === HeaderItemKey.EXCEL_NOTIFICATION ? (
              <>
                {/* Needs HeaderItem wrapper built in to ExcelNotification because it is hidden until the popup occurrs */}
                <ExcelNotification />
              </>
            ) : (
              <HeaderItem key={item} hideBorder={index === items.length - 1}>
                {item === HeaderItemKey.NA_BASKET && <BasicBasketPopup />}
                {item === HeaderItemKey.EU_BASKET && (
                  <NonReleaseEuBasketPopup />
                )}
                {item === HeaderItemKey.SAMPLES_BASKET && (
                  <SamplesBasketPopup />
                )}
                {item === HeaderItemKey.QOB_BASKET && <SappiBasket />}
                {item === HeaderItemKey.STOCK_BOOK && <StockBook />}
                {item === HeaderItemKey.CALCULATOR && <HeaderCalculators />}
                {item === HeaderItemKey.HELP && (
                  <HeaderHelp category={helpCategory} />
                )}
              </HeaderItem>
            )}
          </Fragment>
        ))}
      </div>
    </header>
  );
};

export default PageHeader;
