import { Trans } from '@lingui/macro';
import * as React from 'react';
import NonSamplesEuBasketPopup from '../../components/BasketPopUp/NonSamplesEuBasketPopup';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import { AvailabilityBasketItem } from '../../types/AvailabilityBasketItem.interface';
import PutUpCategory from '../../types/PutUpCategory.enum';
import UnitOfMeasure from '../../types/UnitOfMeasure.enum';

interface IOrderBookingAddedToBasketPopUpProps {
  itemAdded: AvailabilityBasketItem | null;
  onClose: () => void;
  onContinueShopping: () => void;
}

const OrderBookingAddedToBasketPopUp: React.FunctionComponent<IOrderBookingAddedToBasketPopUpProps> = ({
  itemAdded,
  onClose,
  onContinueShopping,
}) => {
  const { format } = useNumberFormatter();

  return (
    <NonSamplesEuBasketPopup
      onContinueShopping={onContinueShopping}
      onClose={onClose}
    >
      {itemAdded && (
        <>
          <div>
            <div className="font-bold">
              {itemAdded.txtGradeBrandMember} {itemAdded.grammage}
            </div>
            {itemAdded.productType === PutUpCategory.SHEET && (
              <div>
                (W) {itemAdded.width}cm x (L) {itemAdded.length}cm
              </div>
            )}
            {(itemAdded.productType === PutUpCategory.ROLL ||
              itemAdded.productType === PutUpCategory.CUTTER) && (
              <div>
                <Trans>Roll Diameter</Trans>: {itemAdded.reelDiameter};
              </div>
            )}
            <div>
              {itemAdded.grammage} <Trans>grammage (gsm)</Trans>
            </div>
          </div>
          <div>
            <span className="font-bold">
              <Trans>Qty</Trans>:{' '}
            </span>
            {itemAdded.requestedUnitOfMeasure === UnitOfMeasure.KG ? (
              <span>
                {format(itemAdded.requestedQuantity)} {UnitOfMeasure.KG}
              </span>
            ) : (
              <>
                <span>{itemAdded.requestedQuantity} </span>
                {itemAdded.requestedUnitOfMeasure === UnitOfMeasure.PK && (
                  <span>
                    <Trans>Reams</Trans>{' '}
                  </span>
                )}
                {itemAdded.requestedUnitOfMeasure === UnitOfMeasure.PL && (
                  <span>
                    <Trans>Pallets</Trans>{' '}
                  </span>
                )}
                {itemAdded.requestedUnitOfMeasure === UnitOfMeasure.SH && (
                  <span>
                    <Trans>Sheets</Trans>{' '}
                  </span>
                )}
                {itemAdded.requestedUnitOfMeasure === UnitOfMeasure.RL && (
                  <span>
                    <Trans>Reels</Trans>{' '}
                  </span>
                )}
                ({format(itemAdded.requestedQuantityKg)} {UnitOfMeasure.KG})
              </>
            )}
          </div>
        </>
      )}
    </NonSamplesEuBasketPopup>
  );
};

export default OrderBookingAddedToBasketPopUp;
