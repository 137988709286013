import Axios from 'axios';
import { StockLotBasket } from '../../types/StockLotBasket.interface';
import { Batch } from './types/Batch.interface';
import StockLotAddToBasketParams from './types/StockLotAddToBasketParams.interface';

export const stockLotAddItemToBasket = async (
  item: Batch,
  addToBasketParams: StockLotAddToBasketParams
): Promise<StockLotBasket> => {
  const { soldToParty, shipToParty, division } = addToBasketParams;

  const { data } = await Axios.post(
    `/portal/resources/stocklot/basket/${soldToParty}/${shipToParty}/${division}`,
    item
  );

  return data;
};
