import { Trans } from '@lingui/macro';
import { Menu, Popover } from 'antd';
import React, { FC, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import useLanguage, { LANGUAGE_COOKIE } from '../../hooks/useLanguage';
import { doLanguageChange } from '../../services/Locale';
import Locale from '../../types/Locale.enum';

const LANGUAGE_LIST = [
  {
    text: 'English',
    code: Locale.en,
  },
  {
    text: 'Polski',
    code: Locale.pl,
  },
  {
    text: 'Nederlands',
    code: Locale.nl,
  },
  {
    text: 'Français',
    code: Locale.fr,
  },
  {
    text: 'Deutsch',
    code: Locale.de,
  },
  {
    text: 'Magyar',
    code: Locale.hu,
  },
  {
    text: 'Italiano',
    code: Locale.it,
  },
  {
    text: 'Português',
    code: Locale.pt,
  },
  {
    text: 'Pусский',
    code: Locale.ru,
  },
  {
    text: 'Slovensky',
    code: Locale.sk,
  },
  {
    text: 'Español',
    code: Locale.es,
  },
  {
    text: 'Suomalainen',
    code: Locale.fi,
  },
];

const LanguageDropdown: FC = () => {
  const storedLanguage = useLanguage();
  const [cookie, setCookie] = useCookies([LANGUAGE_COOKIE]);
  const [clicked, setClicked] = useState(false);
  const history = useHistory();

  const languageChange = (locale: Locale) => {
    setCookie(LANGUAGE_COOKIE, locale);
    doLanguageChange(locale);
    window.open(
      `?lang=${locale}#${history.location.pathname}${history.location.search}`,
      '_self'
    );
  };
  return (
    <Popover
      trigger="click"
      visible={clicked}
      onVisibleChange={setClicked}
      content={
        <Menu mode="vertical" defaultSelectedKeys={[storedLanguage]}>
          {LANGUAGE_LIST.map((lang) => (
            <Menu.Item
              key={lang.code}
              onClick={() => languageChange(lang.code)}
            >
              {lang.text}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <div>
        <Trans>Language</Trans>&nbsp;
        <span className="fa fa-caret-down" />
      </div>
    </Popover>
  );
};

export default LanguageDropdown;
