import { t } from '@lingui/macro';
import moment, { Moment } from 'moment';
import Option from '../../../types/Option.type';

export function getOrderDates(
  previousMonths: number
): {
  maxDateStr: string;
  minDateStr: string;
  today: moment.Moment;
  previousDay: moment.Moment;
} {
  const today = moment();
  const previousDay = moment().add(-previousMonths, 'months');

  const currentDate = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const maxDateStr = currentDate.toLocaleDateString('en-US', options);
  currentDate.setMonth(currentDate.getMonth() - previousMonths);

  const minDateStr = currentDate.toLocaleDateString('en-US', options);

  return { maxDateStr, minDateStr, today, previousDay };
}

export const getMoment = (storedDate?: string | Moment): Moment | null => {
  return storedDate ? moment(storedDate) : null;
};

export function getMonthsOption(): Option[] {
  return [
    {
      value: '-1',
      label: '',
    },
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '3',
      label: '3',
    },
    {
      value: '4',
      label: '4',
    },
    {
      value: '5',
      label: '5',
    },
    {
      value: '6',
      label: '6',
    },
    {
      value: '7',
      label: '7',
    },
    {
      value: '8',
      label: '8',
    },
    {
      value: '9',
      label: '9',
    },
    {
      value: '10',
      label: '10',
    },
    {
      value: '11',
      label: '11',
    },
    {
      value: '12',
      label: '12',
    },
  ];
}

export function getAllTypes(hasConsignmentRole: boolean, hasReleaseRole: boolean): Option[] {
  if(hasConsignmentRole){
    if(hasReleaseRole){
      return [
          {
            value: '',
            label: t({ id: 'orderDetails.status.all', message: 'All' }),
          },
          {
            value: 'ZOR',
            label: t({ id: 'orderDetails.type.standard', message: 'Standard' }),
          },
          {
            value: 'ZKB',
            label: t({
              id: 'consignment',
              message: 'Consignment',
            }),
          },
        ];
    }
    
    return [
        {
          value: '',
          label: t({ id: 'orderDetails.status.all', message: 'All' }),
        },
        {
          value: 'ZTA',
          label: t({ id: 'orderDetails.type.standard', message: 'Standard' }),
        },
        {
          value: 'ZKB',
          label: t({
            id: 'orderDetails.type.consignment',
            message: 'Consignment',
          }),
        },
      ]
  }
  if(hasReleaseRole){
    return [
      {
        value: '',
        label: t({ id: 'orderDetails.status.all', message: 'All' }),
      },
      {
        value: 'ZOR',
        label: t({ id: 'orderDetails.type.standard', message: 'Standard' }),
      },
    ];
  }
  return [
    {
      value: '',
      label: t({ id: 'orderDetails.status.all', message: 'All' }),
    },
    {
      value: 'ZTA',
      label: t({ id: 'orderDetails.type.standard', message: 'Standard' }),
    },
  ];
}

export function getAllStatuses(isRoleNA: boolean): Option[] {
  return isRoleNA
    ? [
        {
          value: '',
          label: t({ id: 'orderDetails.status.all', message: 'All' }),
        },
        {
          value: '00',
          label: t({ id: 'orderDetails.status.open', message: 'Open' }),
        },
        {
          value: '01',
          label: t({ id: 'orderDetails.status.rejected', message: 'Rejected' }),
        },
        {
          value: '10',
          label: t({
            id: 'orderDetails.status.under_production',
            message: 'Under Production',
          }),
        },
        {
          value: '35',
          label: t({ id: 'orderDetails.status.finished', message: 'Finished' }),
        },
        {
          value: '36',
          label: t({
            id: 'orderDetails.status.dispatch_planned_to_sappi_warehouse',
            message: 'Dispatch Planned To Sappi Warehouse',
          }),
        },
        {
          value: '37',
          label: t({
            id: 'orderDetails.status.on_the_way_to_sappi_warehouse',
            message: 'On The Way To Sappi Warehouse',
          }),
        },
        {
          value: '38',
          label: t({
            id: 'orderDetails.status.in_sappi_warehouse',
            message: 'In Sappi Warehouse',
          }),
        },
        {
          value: '40',
          label: t({
            id: 'orderDetails.status.dispatch_planned_to_you',
            message: 'Dispatch Planned To You',
          }),
        },
        {
          value: '49',
          label: t({
            id: 'orderDetails.status.partly_shipped_to_you',
            message: 'Partly Shipped To You',
          }),
        },
        {
          value: '50',
          label: t({ id: 'orderDetails.status.shipped', message: 'Shipped' }),
        },
        {
          value: '55',
          label: t({
            id: 'orderDetails.status.shipped_ex_sappi_plant',
            message: 'Shipped Ex Sappi Plant',
          }),
        },
        {
          value: '60',
          label: t({
            id: 'orderDetails.status.order_complete',
            message: 'Order Complete',
          }),
        },
      ]
    : [
        {
          value: '',
          label: t({ id: 'orderDetails.status.all', message: 'All' }),
        },
        {
          value: '00',
          label: t({ id: 'orderDetails.status.open', message: 'Open' }),
        },
        {
          value: '01',
          label: t({ id: 'orderDetails.status.rejected', message: 'Rejected' }),
        },
        {
          value: '10',
          label: t({
            id: 'orderDetails.status.under_production',
            message: 'Under Production',
          }),
        },
        {
          value: '35',
          label: t({
            id: 'orderDetails.status.production_finished',
            message: 'Production Finished',
          }),
        },
        {
          key: '37-1',
          value: '37',
          label: t({
            id: 'orderDetails.status.in_sappi_warehouse_(goodsrecpt)',
            message: 'In Sappi Warehouse (GOODSRECPT)',
          }),
        },
        {
          key: '37-2',
          value: '37',
          label: t({
            id:
              'orderDetails.status.loading_means_of_transport_to_sappi_wh_(departure)',
            message: 'Loading Means Of Transport To Sappi WH (DEPARTURE)',
          }),
        },
        {
          key: '37-3',
          value: '37',
          label: t({
            id:
              'orderDetails.status.means_of_transport_arrival_to_sappi_wh_(arrival)',
            message: 'Means Of Transport Arrival To Sappi WH (ARRIVAL)',
          }),
        },
        {
          key: '37-4',
          value: '37',
          label: t({
            id: 'orderDetails.status.on_the_way_to_sappi_warehouse',
            message: 'On The Way To Sappi Warehouse',
          }),
        },
        {
          key: '49-1',
          value: '49',
          label: t({
            id:
              'orderDetails.status.expected_arrival_date_at_your_premises_or_port_of_load_(arrival)',
            message:
              'Expected Arrival Date At Your Premises Or Port Of Load (ARRIVAL)',
          }),
        },
        {
          key: '49-2',
          value: '49',
          label: t({
            id:
              'orderDetails.status.loading_truck_at_sappi_location_(truck_load)',
            message: 'Loading Truck At Sappi Location (TRUCK_LOAD)',
          }),
        },
        {
          key: '49-3',
          value: '49',
          label: t({
            id:
              'orderDetails.status.means_of_transport_arrival_at_sappi_location_(truck_arr)',
            message: 'Means Of Transport Arrival At Sappi Location (TRUCK_ARR)',
          }),
        },
        {
          key: '49-4',
          value: '49',
          label: t({
            id:
              'orderDetails.status.means_of_transport_departs_sappi_location_(departure)',
            message: 'Means Of Transport Departs Sappi Location (DEPARTURE)',
          }),
        },
        {
          key: '49-5',
          value: '49',
          label: t({
            id: 'orderDetails.status.partly_shipped_to_you',
            message: 'Partly Shipped To You',
          }),
        },
        {
          key: '50-1',
          value: '50',
          label: t({
            id:
              'orderDetails.status.Loading Means Of Transport At Sappi Location (TRUCK_LOAD)',
            message:
              'Loading Means Of Transport At Sappi Location (TRUCK_LOAD)',
          }),
        },
        {
          key: '50-2',
          value: '50',
          label: t({ id: 'orderDetails.status.shipped', message: 'Shipped' }),
        },
        {
          value: '55',
          label: t({
            id: 'orderDetails.status.shipped_ex_sappi_plant',
            message: 'Shipped Ex Sappi Plant',
          }),
        },
        {
          value: '60',
          label: t({
            id:
              'orderDetails.status.delivered_to_your_premises_or_port_of_load',
            message: 'Delivered To Your Premises Or Port Of Load',
          }),
        },
      ];
}
