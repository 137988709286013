import { Trans } from '@lingui/macro';
import { Popover, Space } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { Button } from '../../../components/Button/Button';
import LabelValue from '../../../components/LabelValue/LabelValue';
import Customer from '../../../types/Customer.interface';
import SavedEnquiryAddedToBasketPopup from '../SavedEnquiryAddedToBasketPopup';
import { recreateEnquiry } from '../service';
import ShipToAddressPopover from './ShipToAddressPopover';
import useRecreateEnquiry from './useRecreateEnquiry.store';

interface IEnquiryAsideInfoProps {
  enquiryNumber: string;
  salesOrg: string;
  customerName: string;
  shipToCustomer: Customer;
  isRecreatingDisabled: boolean;
}

const EnquiryAsideInfo: React.FunctionComponent<IEnquiryAsideInfoProps> = ({
  enquiryNumber,
  salesOrg,
  customerName,
  shipToCustomer,
  isRecreatingDisabled,
}) => {
  const [isShippingPopoverOpen, setIsShippingPopoverOpen] = useState(false);
  const [
    isEnquiryBasketPopupVisible,
    setIsEnquiryBasketPopupVisible,
  ] = useState(false);
  const [isRecreated, setIsRecreated] = useState(false);
  const [isRecreating, setIsRecreating] = useState(false);

  const preRecreate = () => {
    setIsRecreating(true);
    useRecreateEnquiry.setState({ enquiryRecreating: true });
  };
  const postRecreate = () => {
    setIsEnquiryBasketPopupVisible(true);
    setTimeout(() => setIsEnquiryBasketPopupVisible(false), 15000);
    setIsRecreated(true);
    useRecreateEnquiry.setState({ enquiryRecreating: false });
    setIsRecreating(false);
  };
  const recreate = async () => {
    preRecreate();
    await recreateEnquiry(enquiryNumber, salesOrg);
    postRecreate();
  };

  return (
    <div className="bg-gray-whiteSmoke300 w-full h-full flex justify-between flex-col">
      <Space size="large" direction="vertical" className="p-4 w-full h-full">
        <LabelValue
          className="font-bold"
          label={
            <div className="text-lg">
              <Trans>Enquiry Number</Trans>
            </div>
          }
          value={<div className="text-3xl">{enquiryNumber}</div>}
        />
        <LabelValue
          inverse
          label={<Trans>Customer</Trans>}
          value={customerName}
        />
        <LabelValue
          inverse
          label={<Trans>Ship To</Trans>}
          value={
            <Space>
              {shipToCustomer.name}
              <Popover
                placement="topLeft"
                trigger="click"
                visible={isShippingPopoverOpen}
                onVisibleChange={setIsShippingPopoverOpen}
                content={<ShipToAddressPopover customer={shipToCustomer} />}
              >
                <i className="fa fa-caret-down" />
              </Popover>
            </Space>
          }
        />
      </Space>
      <Popover
        visible={isEnquiryBasketPopupVisible}
        content={
          <SavedEnquiryAddedToBasketPopup
            enquiryNumber={enquiryNumber}
            onClose={() => setIsEnquiryBasketPopupVisible(false)}
            onContinueShopping={() => setIsEnquiryBasketPopupVisible(false)}
          />
        }
        placement="topRight"
        overlayClassName="popover-remove-arrow"
      >
        <Button
          theme="primary"
          className="w-full"
          onClick={recreate}
          loading={isRecreating}
          disabled={isRecreatingDisabled || isRecreated}
        >
          <Trans>Re-create this Enquiry</Trans>
        </Button>
      </Popover>
    </div>
  );
};

export default EnquiryAsideInfo;
