import { t } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import Date from '../../../components/Date';
import Table from '../../../components/Table/Table';
import { DateFormat } from '../../../hooks/useDateFormatter';
import { TrackAndTraceLog } from '../types/TrackAndTraceLog.interface';
import styles from './LogTable.module.scss';

interface ILogTableProps {
  results: TrackAndTraceLog[];
}

const LogTable: FC<ILogTableProps> = ({ results }) => {
  const columns: ColumnsType<TrackAndTraceLog> = [
    {
      title: t`Payload ID`,
      key: 'payloadId',
      dataIndex: 'payloadId',
    },
    {
      title: t`Weightlist #`,
      key: 'weightlistNumber',
      dataIndex: 'weightlistNumber',
    },
    {
      title: t`user ID`,
      key: 'userName',
      dataIndex: 'userName',
    },
    {
      title: t`Edit On`,
      key: 'createTime',
      render: (value, record) => (
        <>
          <Date date={record.createDate} />
          &nbsp;
          <Date date={record.createTime} format={DateFormat.BASIC_TIME} />
        </>
      ),
    },
    {
      title: t`Field`,
      key: 'fieldName',
      dataIndex: 'fieldName',
    },
    {
      title: t`Old Value`,
      key: 'oldValue',
      dataIndex: 'oldValue',
    },
    {
      title: t`New Value`,
      key: 'newValue',
      dataIndex: 'newValue',
    },
  ];
  return (
    <Table
      rowKey="transitId"
      className={styles.table}
      columns={columns}
      dataSource={results}
      pagination={false}
    />
  );
};

export default LogTable;
