import { Trans } from '@lingui/macro';
import { Popover } from 'antd';
import { filter, some } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '../../../components/Button/Button';
import { excelExportCheck } from '../../../services/Excel';
import { DocumentExportReport } from '../../../types/DocumentExportReport.interface';
import HeaderItem from '../HeaderItem';
import useExcelNotificationStore from './excelNotification.store';
import ExcelPopover from './ExcelPopover';

interface IExcelNotificationProps {
  attr?: string;
}

const ExcelNotification: React.FunctionComponent<IExcelNotificationProps> = (
  props
) => {
  const { exportInProcess } = useExcelNotificationStore();
  const [available, setAvailable] = useState<DocumentExportReport[]>();
  const [popoverVisible, setPopoverVisible] = useState(false);

  /**
   * Checks for changes/information for documents.
   *
   * @param {type} scope
   * @returns {undefined}
   */
  const excelUpdateCheck = useCallback((noRepeat?: boolean) => {
    return setTimeout(async () => {
      const reports = await excelExportCheck();
      const availableReports = filter(
        reports,
        (report: DocumentExportReport) =>
          report.downloaded === false &&
          report.generationDone &&
          report.errored === false
      );
      setAvailable(availableReports);

      const isExportInProcess = some(
        reports,
        (report: DocumentExportReport) => !report.generationDone
      );

      useExcelNotificationStore.setState({
        exportInProcess: isExportInProcess,
      });

      if (!noRepeat && isExportInProcess) {
        excelUpdateCheck();
      } else if (availableReports.length === 0) {
        setPopoverVisible(false);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const timeout = excelUpdateCheck(!exportInProcess);
    return () => clearTimeout(timeout);
  }, [exportInProcess, excelUpdateCheck]);

  return (
    <Popover
      visible={popoverVisible}
      placement="bottom"
      content={
        <ExcelPopover
          title={<Trans id="export.available">Available Files</Trans>}
          reportsForDownload={available || []}
          onDownload={() => excelUpdateCheck(true)}
          onClose={() => setPopoverVisible(false)}
        />
      }
    >
      <>
        {(exportInProcess || !!available?.length) && (
          <HeaderItem className="relative">
            <Button
              theme="link"
              className="text-gray-gray hover:text-gray-grayHover"
              onClick={() => setPopoverVisible(true)}
            >
              <i
                className={`far fa-file-excel-o ${
                  exportInProcess ? 'faa-falling animated' : ''
                }`}
              />
            </Button>
            {!exportInProcess && !!available?.length && (
              <div className="rounded-full bg-gray-500 w-5 h-5 flex flex-col justify-center items-center absolute -top-2 left-5">
                <div className="text-white-white text-sm">
                  {available?.length}
                </div>
              </div>
            )}
          </HeaderItem>
        )}
      </>
    </Popover>
  );
};

export default ExcelNotification;
