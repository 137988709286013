import { find } from 'lodash';
import { useMemo } from 'react';
import Customer from '../types/Customer.interface';
import User from '../types/User/User.interface';
import useUser from './useUser';

interface IUseCustomer {
  getCustomerByNumber: (customerNumber: string) => Customer | undefined;
}

const getCustomerByNumber = (customerNumber: string, user: User) => {
  return find(user.customers, (customer) => {
    return customer.number === customerNumber;
  });
};

const useCustomer = (): IUseCustomer => {
  const { data: user } = useUser();
  return {
    getCustomerByNumber: useMemo(
      () => (customerNumber: string) =>
        user ? getCustomerByNumber(customerNumber, user) : undefined,
      [user]
    ),
  };
};

export default useCustomer;
