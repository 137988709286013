import React from 'react';

const ProductAvailabilityFormLeftHalf: React.FunctionComponent = ({
  children,
}) => {
  return (
    <>
      <div className="pr-2.5 align-top w-1/2">{children}</div>
    </>
  );
};

export default ProductAvailabilityFormLeftHalf;
