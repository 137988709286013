import UnitOfMeasure from '../../../types/UnitOfMeasure.enum';

export const getSheetTotal = (params: {
  quantity: number;
  quantityInKg: number;
  unit: UnitOfMeasure;
  length: number;
  width: number;
  grammage: string;
  sheetsPerPallet: string;
  sheetsPerReam: string;
}): number => {
  if (params.unit === UnitOfMeasure.KG || params.unit === UnitOfMeasure.TO) {
    const quantity =
      params.unit === UnitOfMeasure.TO ? params.quantityInKg : params.quantity;
    const rawSheets =
      (quantity /
        (params.length * params.width * parseInt(params.grammage, 10))) *
      10000000;
    return Math.round(rawSheets);
  }
  if (params.unit === UnitOfMeasure.PK) {
    return params.quantity * parseInt(params.sheetsPerReam, 10);
  }
  return params.quantity * parseInt(params.sheetsPerPallet, 10);
};
