import Axios from 'axios';
import { MyContact } from './Types/MyContact.interface';

export const getMyContact = async (lang: string): Promise<MyContact> => {
  const { data } = await Axios.get(`/portal/resources/mycontact`, {
    params: {
      lang,
    },
  });
  return data;
};
