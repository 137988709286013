import { t } from '@lingui/macro';
import { Modal as AntModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { noop } from 'lodash';
import * as React from 'react';
import './Modal.scss';

/* eslint-disable @typescript-eslint/no-empty-interface */
export interface IModalProps extends ModalProps {}
/* eslint-enable @typescript-eslint/no-empty-interface */

const DEFAULT_CLOSE_ICON = (
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
) => (
  <div
    className="h-full w-full flex flex-col justify-center"
    onClick={onCancel}
    onKeyDown={noop}
    role="button"
    aria-label={t`Close`}
    tabIndex={0}
  >
    <div className="fa fa-times text-xl text-white-white" />
  </div>
);

const Modal: React.FunctionComponent<IModalProps> = ({
  className = '',
  footer,
  children,
  closeIcon,
  onCancel,
  ...rest
}) => {
  const classes = `${className} sappi-ant-override-modal`;
  return (
    <div>
      <AntModal
        className={classes}
        closeIcon={closeIcon || (onCancel && DEFAULT_CLOSE_ICON(onCancel))}
        footer={footer || <></>}
        maskTransitionName=""
        {...rest}
      >
        {children}
      </AntModal>
    </div>
  );
};

export default Modal;
