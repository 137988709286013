import MeasurementSystem from '../types/MeasurementSystem.enum';
import useMeasurementSystem from './useMeasurementSystem';
import useMeasurementUnit from './useMeasurementUnit';
import useNumberFormatter from './useNumberFormatter';

const useWeightFormatter = (): {
  format: (weight: number, kgWeight: number) => string;
} => {
  const { measurementSystem } = useMeasurementSystem();
  const { measurementUnit2 } = useMeasurementUnit();
  const { format: formatNumber } = useNumberFormatter();
  const formatWeight = (weight: number, kgWeight: number): string => {
    const correctWeight =
      measurementSystem === MeasurementSystem.IMPERIAL ? weight : kgWeight;
    return `${formatNumber(Math.round(correctWeight))} ${measurementUnit2}`;
  };
  return {
    format: formatWeight,
  };
};

export default useWeightFormatter;
