import * as React from 'react';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';

interface ITableStatusCellProps {
  item: ConsignmentInventoryItem;
}

const TableStatusCell: React.FunctionComponent<ITableStatusCellProps> = ({
  item,
}) => {
  return <>{item.stockStatus}</>;
};

export default TableStatusCell;
