import Axios from 'axios';
import { forEach } from 'lodash';
import { SearchOrderInputs } from '../../domain/TrackOrders/SearchOrderInputs.interface';
import { DocumentExportReport } from '../../types/DocumentExportReport.interface';
import Complaints from '../../types/Complaints.interface';
import OpenOrders from '../../types/OpenOrders.interface';

export const exportExcel = async (
  params: Partial<SearchOrderInputs>
): Promise<DocumentExportReport[]> => {
  const { data } = await Axios.post('/portal/resources/order/excel', params);
  return data;
};

export const excelExportCheck = async (): Promise<DocumentExportReport[]> => {
  const timeStamp = Date.now();
  const { data } = await Axios.get(
    `/portal/resources/order/excel/check/${timeStamp}`
  );
  return data;
};

export const generateFinancialExcel = async (
  customerName: string,
  customerNumber: string,
  period: string,
  sort: string,
  order: 'ASC' | 'DESC'
): Promise<void> =>
  Axios.post(
    `/portal/resources/financials/excel/${customerName}/${customerNumber}/${period}/${sort}/${order}`
  );

export const generateClaimsExcel = async (
  params: Complaints[]
): Promise<void> => {
  await Axios.post('/portal/resources/complaints/excel', params);
};

export const generateOpenOrdersExcel = async (
  params: OpenOrders[]
): Promise<void> => {
  console.log(`generating open orders. ${JSON.stringify(params)}`);
  await Axios.post('/portal/resources/mill-direct-orders/excel', params);
};
