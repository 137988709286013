import create from 'zustand';
import PutUpCategory from '../../types/PutUpCategory.enum';
import { StockLotRequest } from './types/StockLotRequest.interface';
import { StockLotResponse } from './types/StockLotResponse.interface';

export const DEFAULT_REQUEST: StockLotRequest = {
  soldToParty: '',
  shipToParty: '',
  productType: PutUpCategory.SHEET,
  division: '',
  gradeDescription: 'All',
};

type StockLotState = {
  request: StockLotRequest | null;
  searchFormRequest: StockLotRequest | null;
  response: StockLotResponse | null;
  isSearched: boolean;
  showAll: boolean;
  currentPage: number;
  setRequest: (request: StockLotRequest | null) => void;
  setResponse: (response: StockLotResponse | null) => void;
  setSearchFormRequest: (request: StockLotRequest | null) => void;
  setIsSearched: (isSearched: boolean) => void;
  setShowAll: (showAll: boolean) => void;
  setCurrentPage: (currentPage: number) => void;
};

const useStockLotStore = create<StockLotState>((set) => ({
  request: DEFAULT_REQUEST,
  searchFormRequest: DEFAULT_REQUEST,
  response: null,
  isSearched: false,
  showAll: false,
  currentPage: 1,
  setRequest: (request: StockLotRequest | null) => set(() => ({ request })),
  setSearchFormRequest: (searchFormRequest: StockLotRequest | null) =>
    set(() => ({ searchFormRequest })),
  setResponse: (response: StockLotResponse | null) => set(() => ({ response })),
  setIsSearched: (isSearched: boolean) => set(() => ({ isSearched })),
  setShowAll: (showAll: boolean) => set(() => ({ showAll })),
  setCurrentPage: (currentPage: number) => set(() => ({ currentPage })),
}));

export default useStockLotStore;
