import { Trans } from '@lingui/macro';
import * as React from 'react';

const DummySamplesUserGuide: React.FunctionComponent = () => {
  return (
    <>
      <div className="align-right pb-4">
        <a
          target="_blank"
          rel="noreferrer"
          href="http://cdn-s3.sappi.com/s3fs-public/EN_Dummy%20ordering_user%20guide.pdf"
          className="text-blue-iris underline"
        >
          <Trans>How to create a dummy sample?</Trans>
        </a>
      </div>
    </>
  );
};

export default DummySamplesUserGuide;
