enum UnitOfMeasure {
  IN = 'IN', // inches
  CM = 'CM', // Centimeters
  LB = 'LB', // pounds
  KG = 'KG', // kilograms
  RL = 'RL', // "reels"
  SH = 'SH', // "sheets"
  PL = 'PL', // "pallets"
  PK = 'PK', // "Reams"
  MSF = 'MSF', // "thousand square feet"
  MSI = 'MSI',
  MSM = 'MSM', // "thousand square meters"
  LF = 'LF', // "linear feet"
  LM = 'LM', // "linear meters"
  LY = 'LY',
  CTN = 'CTN',
  TO = 'TO', // "Tons"
}

export default UnitOfMeasure;
