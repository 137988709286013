import { Trans } from '@lingui/macro';
import * as React from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import useUser from '../../hooks/useUser';
import HelpCategory from './types/HelpCategory.enum';

interface IHeaderHelpProps {
  category?: HelpCategory;
}

const HeaderHelp: React.FunctionComponent<IHeaderHelpProps> = ({
  category = HelpCategory.WELCOME,
}) => {
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();
  const goToHelp = () => {
    trackPageView('ADMIN', 'VIEW_HELP', { username: user?.username });
    window.open(`#/help?category=${category}`, '_blank');
  };
  return (
    <button onClick={goToHelp}>
      <div className="flex items-center">
        <i className="fa fa-question-circle mr-2" />
        <span className="text-lg">
          <Trans>Help</Trans>
        </span>
      </div>
    </button>
  );
};

export default HeaderHelp;
