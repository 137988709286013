import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useLocationQueryParams(): URLSearchParams {
  const location = useLocation();

  const locationUrlSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  return locationUrlSearchParams;
}
