import { TrackOrder } from '../../types/TrackOrder.interface';

const enrichOrderQuantity = (order: TrackOrder): TrackOrder => {
  if (!order || !order.quantityProduced) {
    return order;
  }

  const orderQuantities = order.quantityProduced.split('/');

  if (orderQuantities[0]) {
    if (order.nominalWeightFlag === 'X') {
      order.quantityProducedNominalString = orderQuantities[0].trim();
    } else {
      order.quantityProducedNetString = orderQuantities[0].trim();
    }
  }

  if (orderQuantities[1]) {
    order.quantityProducedGrossString = orderQuantities[1].trim();
  }

  if (orderQuantities[2]) {
    order.quantityProducedSheetCountString = orderQuantities[2].trim();
  }

  return order;
};

export default enrichOrderQuantity;
