import { Moment } from 'moment';
import React, { FC } from 'react';
import useRole from '../../hooks/useRole';
import { DEFAULT_EU_FORMAT, DEFAULT_NA_FORMAT } from './DatePicker';

interface IDateErrorProps {
  date: Moment;
  messageTemplate: string; // format example: 'Date must be after {date}' where {date} will be replaced with the formatted date
}

const DateError: FC<IDateErrorProps> = ({ date, messageTemplate }) => {
  const { isNaUser } = useRole();
  const formattedMessage = messageTemplate.replace(
    '{date}',
    date
      ? date.format(isNaUser ? DEFAULT_NA_FORMAT : DEFAULT_EU_FORMAT)
      : DEFAULT_NA_FORMAT
  );
  return <span className="font-xs">{formattedMessage}</span>;
};

export default DateError;
