import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { t } from '@lingui/macro';
import { Space } from 'antd';
import { round } from 'lodash';
import Input from '../../../components/Input/Input';
import CalculatorResponse from '../types/CalculatorResponse.interface';
import { priceCwtCalculator } from '../Calculator.service';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import Select from '../../../components/Select/Select';
import {
  getTradeBasisValuePerType,
  isCwtToLinFoot,
  isCwtToMsf,
  isCwtToThousandSht,
  isCwtToTonne,
  isThousandShtToCwt,
  isTonneToCwt,
} from './PriceCWT.util';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import { CalculatorUnitTypes } from '../types/CalculatorUnitTypes.enum';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import useAnalytics from '../../../hooks/useAnalytics';
import { METRIC_IMP_OPTIONS } from '../Calculators.util';
import { PriceCWTParams } from './PriceCWT.interface';
import { PriceCWTServices } from './PriceCWTServices.enum';
import ImperialPriceCWT from './ImperialPriceCWT';
import MetricPriceCWT from './MetricPriceCWT';
import CalculationType from '../types/CalculationType.enum';
import {
  EMPTY_CALC_TYPE_OPTION,
  getCalculationTypeOptions,
} from './PriceCalculators.util';
import CalculatorTypeIdentifier from '../types/CalculatorTypeIdentifier.enum';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';

const MIN_REQUIRED = 0.01;
const MAX_REQUIRED = 99999999.99;
const PriceCWT: FC = () => {
  const {
    register,
    formState,
    reset,
    getValues,
    setValue,
    errors,
    watch,
    handleSubmit,
  } = useForm({
    mode: 'all',
  });
  const { isDirty, isValid } = formState;
  const isFormUnsubmittable = !(isDirty && isValid);
  const { measurementSystem } = useMeasurementSystem();
  const { format } = useNumberFormatter();
  const { trackPageView } = useAnalytics();

  const conversionType = watch('conversionType');
  const metImp = watch('metImp');
  const isMetric = metImp === CalculatorUnitTypes.MET;

  const [isCalculating, setIsCalculating] = useState(false);
  const [priceUnit, setPriceUnit] = useState<string>('');
  const [calculatorResponse, setCalculatorResponse] = useState<
    CalculatorResponse
  >();

  const showPriceInput =
    isCwtToThousandSht(conversionType) ||
    isCwtToLinFoot(conversionType) ||
    isCwtToMsf(conversionType) ||
    isCwtToTonne(conversionType);

  useEffect(() => {
    setValue(
      'metImp',
      measurementSystem === MeasurementSystem.METRIC
        ? CalculatorUnitTypes.MET
        : CalculatorUnitTypes.IMP
    );
  }, [measurementSystem, setValue]);

  const onCalculate = async (data: PriceCWTParams) => {
    let result: CalculatorResponse = {};
    data.conversionType = undefined;

    setIsCalculating(true);
    // eslint-disable-next-line default-case
    switch (conversionType) {
      case CalculationType.CWT_TO_METRIC_TON:
        trackPageView('CALCULATORS', 'CWT_TO_METRIC_TON');
        result = await priceCwtCalculator(
          PriceCWTServices.priceCWTToPriceMetricTon,
          data
        );
        setPriceUnit(t` per Metric Ton`);
        break;

      case CalculationType.CWT_TO_THOUSAND_SHEETS:
        trackPageView('CALCULATORS', 'CWT_TO_1000_SHEETS');
        if (!isMetric) {
          data.price = data.priceCWT;
          data.sheetLengthImp = data.sheetLength;
          data.sheetLength = undefined;
          data.sheetWidthImp = data.sheetWidth;
          data.sheetWidth = undefined;
          data.cwtPriceTradeBasis = getTradeBasisValuePerType(
            getValues('tradeBasis')
          );
        }
        result = await priceCwtCalculator(
          PriceCWTServices.priceCWTToPrice1000Sheets,
          data
        );
        setPriceUnit(t` per Thousand Sheets`);
        break;

      case CalculationType.METRIC_TON_TO_CWT:
        trackPageView('CALCULATORS', 'METRIC_TON_TO_CWT');
        data.price = data.priceTonne;
        result = await priceCwtCalculator(
          PriceCWTServices.priceMetricTonToPriceCWT,
          data
        );
        setPriceUnit(t` per Hundred Weight`);
        break;

      case CalculationType.THOUSAND_SHEETS_TO_CWT:
        trackPageView('CALCULATORS', 'THOUSAND_SHEETS_TO_CWT');
        data.price = data.priceThousandSht;

        if (!isMetric) {
          data.sheetLengthImp = data.sheetLength;
          data.sheetLength = undefined;
          data.sheetWidthImp = data.sheetWidth;
          data.sheetWidth = undefined;
          data.cwtPriceTradeBasis = getTradeBasisValuePerType(
            getValues('tradeBasis')
          );
        }

        result = await priceCwtCalculator(
          PriceCWTServices.price1000SheetsToPriceCWT,
          data
        );
        setPriceUnit(t` per Hundred Weight`);
        break;

      case CalculationType.CWT_TO_LINEAL_FOOT:
        trackPageView('CALCULATORS', 'CWT_TO_LINEAL_FOOT');
        data.cwtPriceTradeBasis = getTradeBasisValuePerType(
          getValues('tradeBasis')
        );
        result = await priceCwtCalculator(
          PriceCWTServices.priceCWTToPriceLinFoot,
          data
        );
        setPriceUnit(t` per Lineal Foot`);
        break;

      case CalculationType.CWT_TO_MSF:
        trackPageView('CALCULATORS', 'CWT_TO_MSF');
        result = await priceCwtCalculator(
          PriceCWTServices.priceCWTToPriceMSF,
          data
        );
        setPriceUnit(t` per MSF`);
        break;
    }
    setIsCalculating(false);
    setCalculatorResponse(result);
  };
  
  const onMetricImperialChange = (selectedValue: CalculatorUnitTypes) => {
    reset({
      metImp: selectedValue,
    });
    setValue('sheetWidth', '');
    setValue('sheetLength', '');
    setCalculatorResponse(undefined);
    setPriceUnit('');
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.PRICE_CWT}
      instructions={t`All fields must be filled in to calculate`}
      onCalculate={handleSubmit(onCalculate)}
      onReset={() => {
        reset({
          metImp: getValues('metImp'),
          conversionType: getValues('conversionType'),
        });
        setCalculatorResponse(undefined);
        setPriceUnit('');
      }}
      isCalculateDisabled={isFormUnsubmittable || isCalculating}
      result={
        <div className="flex justify-center -mx-4">
          {calculatorResponse && (
            <div>
              &#36;
              {conversionType === CalculationType.METRIC_TON_TO_CWT ||
              conversionType === CalculationType.THOUSAND_SHEETS_TO_CWT
                ? format(round(calculatorResponse.ePriceCWT || 0, 2))
                : format(round(calculatorResponse.calcPrice || 0, 2))}
              &nbsp;
            </div>
          )}
          <div className="text-left">{priceUnit}</div>
        </div>
      }
    >
      <Space direction="vertical" size="middle" className="w-full">
        <Select
          required
          name="metImp"
          options={METRIC_IMP_OPTIONS}
          style={{ width: '315px' }}
          ref={register({ required: true })}
          width="full"
          error={errors?.metImp}
          onChange={(e) => {
            onMetricImperialChange(e.target.value as CalculatorUnitTypes)
          }}
        />
        <Select
          required
          name="conversionType"
          options={getCalculationTypeOptions(
            CalculatorTypeIdentifier.PRICE_CWT_CONVERTER,
            metImp
          )}
          style={{ width: '315px' }}
          ref={register({ required: true })}
          width="full"
          error={errors?.conversionType}
          defaultValue={EMPTY_CALC_TYPE_OPTION.value}
        />
        {!isMetric && (
          <ImperialPriceCWT
            register={register}
            errors={errors}
            conversionType={conversionType}
          />
        )}
        {isMetric && (
          <MetricPriceCWT
            register={register}
            errors={errors}
            conversionType={conversionType}
          />
        )}
        {showPriceInput && (
          <Input
            required
            name="priceCWT"
            type="number"
            placeholder={t`Price Per Hundred Weight`}
            ref={register({
              required: showPriceInput,
              min: MIN_REQUIRED,
              max: MAX_REQUIRED,
              validate: (value) => validateNumberOfDecimalPlaces(value, 2),
            })}
            error={errors?.priceCWT}
          />
        )}
        {isThousandShtToCwt(conversionType) && (
          <Input
            required
            name="priceThousandSht"
            type="number"
            placeholder={t`Price Per Thousand Sheets`}
            ref={register({
              required: isThousandShtToCwt(conversionType),
              min: MIN_REQUIRED,
              max: MAX_REQUIRED,
              validate: (value) => validateNumberOfDecimalPlaces(value, 2),
            })}
            error={errors?.priceThousandSht}
          />
        )}
        {isTonneToCwt(conversionType) && (
          <Input
            required
            name="priceTonne"
            type="number"
            placeholder={t`Price Per Metric Ton`}
            ref={register({
              required: isTonneToCwt(conversionType),
              min: MIN_REQUIRED,
              max: MAX_REQUIRED,
              validate: (value) => validateNumberOfDecimalPlaces(value, 2),
            })}
            error={errors?.priceTonne}
          />
        )}
      </Space>
    </CalculatorTemplate>
  );
};

export default PriceCWT;
