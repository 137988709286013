import { noop, orderBy } from 'lodash';
import * as React from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import { generateClaimsExcel } from '../../services/Excel';
import Complaints from '../../types/Complaints.interface';

interface IDownloadClaimsProps {
  complaints: Complaints[];
}

const DownloadClaims: React.FunctionComponent<IDownloadClaimsProps> = ({
  complaints,
  children,
}) => {
  const { trackPageView } = useAnalytics();
  const downloadClaims = async () => {
    if (complaints.length > 0) {
      trackPageView('REPORTING', 'DOWNLOAD_COMPLAINTS');
      await generateClaimsExcel(
        orderBy(complaints, ['createDate', 'billingDocument'], ['asc', 'asc'])
      );
      window.location.href = `${window.location.protocol}//${window.location.host}/portal/resources/complaints/excel`;
    }
  };
  return (
    <div
      onClick={() => downloadClaims()}
      onKeyDown={noop}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export default DownloadClaims;
