import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import NonSamplesEuBasketPopup from '../../../components/BasketPopUp/NonSamplesEuBasketPopup';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import { Batch } from '../types/Batch.interface';

interface StockLotAddedToBasketPopUpProps {
  item: Batch;
  onClose: () => void;
  onContinueShopping: () => void;
}

const StockLotAddedToBasketPopUp: FC<StockLotAddedToBasketPopUpProps> = ({
  item,
  onClose,
  onContinueShopping,
}) => {
  const { format } = useNumberFormatter();

  const isSheet = item.productType === PutUpCategory.SHEET;

  return (
    <NonSamplesEuBasketPopup
      onClose={onClose}
      onContinueShopping={onContinueShopping}
    >
      <div className="font-bold">
        {item.salesGradeDescription} {item.grammage}
      </div>
      {isSheet ? (
        <div>
          <Trans>
            (W) {item.bwidth}cm x (L) {item.blength}cm
          </Trans>
        </div>
      ) : (
        <div>
          <Trans>Reel Diameter</Trans>: {item.bReelDiameter}
        </div>
      )}
      <div>
        {item.grammage} <Trans>grammage (gsm)</Trans>
      </div>
      <div className="font-bold">
        <Trans>Article Number</Trans>:
      </div>
      <div>
        <span className="font-bold">
          <Trans>Qty</Trans>:
        </span>{' '}
        {item.numberOfUnits}{' '}
        {isSheet ? <Trans>Pallet</Trans> : <Trans>Roll</Trans>} (
        {format(item.atpQuantity)} KG)
      </div>
    </NonSamplesEuBasketPopup>
  );
};

export default StockLotAddedToBasketPopUp;
