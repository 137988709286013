import { t } from '@lingui/macro';
import { isEmpty } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../hooks/useAnalytics';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import useUser from '../../../hooks/useUser';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import {
  calculateMsfOrMsmToWeight,
  calculateWeightToMsfOrMsm,
} from '../Calculator.service';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import CalculationType from '../types/CalculationType.enum';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';
import { CalculatorUnitTypes } from '../types/CalculatorUnitTypes.enum';
import Criteria from './Criteria';
import { Criteria as SquareAreaWeightConversionCriteria } from './Criteria.interface';
import Result from './Result';

const SquareAreaWeightConversionCalculator: FC = () => {
  const {
    register,
    reset,
    errors,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<SquareAreaWeightConversionCriteria>({ mode: 'all' });
  const { measurementSystem } = useMeasurementSystem();
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();
  const metImpDefaultValue =
    measurementSystem === MeasurementSystem.METRIC
      ? CalculatorUnitTypes.MET
      : CalculatorUnitTypes.IMP;
  const [weightResult, setWeightResult] = useState<number>();
  const [msResult, setMsResult] = useState<number>();
  const [isCalculating, setIsCalculating] = useState(false);
  const imperialConversionType = watch('imperialConversionType');
  const metricConversionType = watch('metricConversionType');
  const metImp = watch('metImp');
  const isImperial = metImp === CalculatorUnitTypes.IMP;

  const showImperialWeightResult =
    isImperial &&
    (imperialConversionType === CalculationType.MSF_TO_WEIGHT ||
      isEmpty(imperialConversionType));

  const showMetricWeightResult =
    !isImperial &&
    (metricConversionType === CalculationType.MSM_TO_WEIGHT ||
      isEmpty(metricConversionType));

  const showImperialMsResult =
    isImperial &&
    (imperialConversionType === CalculationType.WEIGHT_TO_MSF ||
      isEmpty(imperialConversionType));

  const showMetricMsResult =
    !isImperial &&
    (metricConversionType === CalculationType.WEIGHT_TO_MSM ||
      isEmpty(metricConversionType));

  useEffect(() => {
    setValue('metImp', metImpDefaultValue);
  }, [metImpDefaultValue, setValue]);

  const onCalculate = async (data: SquareAreaWeightConversionCriteria) => {
    setIsCalculating(true);
    if (
      data.metricConversionType === CalculationType.MSM_TO_WEIGHT ||
      data.imperialConversionType === CalculationType.MSF_TO_WEIGHT
    ) {
      trackPageView('CALCULATORS', 'MSM-MSF_TO_WEIGHT', {
        username: user?.username,
      });
      const response = await calculateMsfOrMsmToWeight(data);
      setWeightResult(
        data.metImp === CalculatorUnitTypes.IMP
          ? response.eWeightImp
          : response.eWeight
      );
    } else {
      trackPageView('CALCULATORS', 'WEIGHT_TO_MSM-MSF', {
        username: user?.username,
      });
      const response = await calculateWeightToMsfOrMsm(data);
      setMsResult(
        data.metImp === CalculatorUnitTypes.IMP ? response.eMSF : response.eMSM
      );
    }
    setIsCalculating(false);
  };

  const onReset = () => {
    reset({
      metImp: metImpDefaultValue,
    });
    setWeightResult(undefined);
    setMsResult(undefined);
  };

  const onMetImpChange = (selectedType: CalculatorUnitTypes) => {
    reset({
      metImp: selectedType,
    });
    setWeightResult(undefined);
    setMsResult(undefined);
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.AREA_WEIGHT_CONVERSION}
      instructions={t`All fields must be filled in to calculate`}
      onCalculate={handleSubmit(onCalculate)}
      isCalculating={isCalculating}
      isCalculateDisabled={!isValid || isCalculating}
      onReset={() => onReset()}
      result={
        <>
          {showImperialWeightResult && (
            <Result quantity={weightResult} unit={t`Pounds`} />
          )}
          {showMetricWeightResult && (
            <Result quantity={weightResult} unit={t`Kilograms`} />
          )}
          {showImperialMsResult && (
            <Result quantity={msResult} unit={t`Thousand Square Feet`} />
          )}
          {showMetricMsResult && (
            <Result quantity={msResult} unit={t`Thousand Square Meters`} />
          )}
        </>
      }
    >
      <Criteria
        register={register}
        errors={errors}
        watch={watch}
        onMetImpChange={onMetImpChange}
        isImperial={metImp === CalculatorUnitTypes.IMP}
      />
    </CalculatorTemplate>
  );
};

export default SquareAreaWeightConversionCalculator;
