import { LengthConversion } from './LengthConversion.interface';
import LengthUnit from './LengthUnit.interface';

const CONSTANTS = {
  [LengthUnit.MM]: {
    cm: 0.1,
    mm: 1,
    inches: 0.0393701,
    feet: 0.00328084,
  },
  [LengthUnit.CM]: {
    cm: 1,
    mm: 10,
    inches: 0.393701,
    feet: 0.0328084,
  },
  [LengthUnit.INCHES]: {
    cm: 2.54,
    mm: 25.4,
    inches: 1,
    feet: 0.0833333,
  },
  [LengthUnit.FEET]: {
    cm: 30.48,
    mm: 304.8,
    inches: 12,
    feet: 1,
  },
};

export const convertLength = (
  quantity: string,
  unit: LengthUnit
): LengthConversion => {
  const quantityNumber = +quantity.replace(',', '.');
  return {
    cm: (
      Math.round(quantityNumber * CONSTANTS[unit].cm * 1000) / 1000
    ).toString(),
    mm: (
      Math.round(quantityNumber * CONSTANTS[unit].mm * 1000) / 1000
    ).toString(),
    inches: (
      Math.round(quantityNumber * CONSTANTS[unit].inches * 1000) / 1000
    ).toString(),
    feet: (
      Math.round(quantityNumber * CONSTANTS[unit].feet * 1000) / 1000
    ).toString(),
  };
};

export const convertToEU = (lengths: LengthConversion): LengthConversion => {
  const { mm, cm, inches, feet } = lengths;
  return {
    mm: mm.replace('.', ','),
    cm: cm.replace('.', ','),
    inches: inches.replace('.', ','),
    feet: feet.replace('.', ','),
  };
};
