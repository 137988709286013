import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import ItemUnit from '../../../../types/ItemUnit.enum';

export const getItemUnit = (
  productType: string,
  unitOfMeasure: string
): string => {
  if (productType === PutUpCategory.SHEET) {
    if (unitOfMeasure === UnitOfMeasure.PK) {
      return ItemUnit.REAMS;
    }
    return ItemUnit.PALLET;
  }
  if (
    productType === PutUpCategory.ROLL ||
    productType === PutUpCategory.CUTTER
  ) {
    return ItemUnit.ROLL;
  }
  return '';
};
