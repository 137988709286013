import html2pdf from 'html2pdf.js';

export const convertHtmlToPdf = (elementId: string, fileName: string): void => {
  const element = document.getElementById(elementId);
  const opt = {
    margin: [0.75, 1.25, 0, 1], // top, left, bottom, right
    filename: fileName,
    html2canvas: { scale: 2, y: 0, scrollY: 0 },
    image: { type: 'jpeg', quality: 1 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    enableLinks: true,
  };

  html2pdf().set(opt).from(element).save();
};
