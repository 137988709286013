import { t } from '@lingui/macro';
import { reject } from 'lodash';
import { useCallback } from 'react';
import useRole from '../../../hooks/useRole';
import { EMPTY_OPTION_SELECT } from '../../../services/Util/emptyOption.const';
import Option from '../../../types/Option.type';
import { SamplesTypeParameter } from '../../../types/Samples/SamplesTypeParameter.interface';
import SamplesProductGroup from '../OrderSamples/SamplesProductGroup.enum';

const DEFAULT_SAMPLE_TYPE_OPTIONS: Option[] = [
  EMPTY_OPTION_SELECT,
  {
    label: t`Heatset web offset (rolls)`,
    value: SamplesProductGroup.PUBLIC,
  },
  {
    label: t`Sheet fed offset (sheets)`,
    value: SamplesProductGroup.COMM,
  },
  {
    label: t`Create a Dummy Sample`,
    value: SamplesProductGroup.DUMMY,
  },
];

const INTERNAL_SAMPLE_TYPE_OPTIONS: Option[] = DEFAULT_SAMPLE_TYPE_OPTIONS.concat(
  [
    {
      label: t`Sappi Products`,
      value: SamplesProductGroup.SAPPI,
    },
    {
      label: t`Packaging & speciality papers`,
      value: SamplesProductGroup.SPEC,
    },
  ]
);

const processTypeParameters = (
  types: SamplesTypeParameter[],
  isInternalUser: boolean
) => {
  return !isInternalUser
    ? reject(
        types,
        (type) =>
          type && (type.sampleType === 'TECH' || type.sampleType === 'PROMO')
      )
    : types;
};

const useUserBasedSampleOptions = (): {
  isFetching: boolean;
  sampleTypeOptions: Option[];
  getUserBasedTypeParameters: (
    typeParameters: SamplesTypeParameter[] | undefined
  ) => SamplesTypeParameter[];
} => {
  const { isInternalUser, isFetching } = useRole();

  return {
    isFetching,
    sampleTypeOptions: isInternalUser
      ? INTERNAL_SAMPLE_TYPE_OPTIONS
      : DEFAULT_SAMPLE_TYPE_OPTIONS,
    getUserBasedTypeParameters: useCallback(
      (typeParameters) =>
        typeParameters
          ? processTypeParameters(typeParameters, isInternalUser)
          : [],
      [isInternalUser]
    ),
  };
};

export default useUserBasedSampleOptions;
