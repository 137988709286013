import { t } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import {
  IMP_REG_EX,
  METRIC_NUMBER_ONE_DECIMAL_REG_EX,
} from '../../../../types/RegularExpression.constants';

const MAX_REQUIRED = 999;
interface IImperialCriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

const ImperialCriteria: React.FunctionComponent<IImperialCriteriaProps> = ({
  register,
  errors,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Input
        name="textWeight"
        type="number"
        ref={register({
          required: true,
          min: 0.1,
          max: MAX_REQUIRED,
          pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
        })}
        required
        error={errors?.textWeight}
        placeholder={t`Text Weight`}
      />
      <Input
        name="reelWidthImp"
        type="number"
        ref={register({
          required: true,
          min: 0.1,
          pattern: IMP_REG_EX,
        })}
        required
        error={errors?.reelWidthImp}
        placeholder={t`Width (inches)`}
      />
      <Input
        name="reelDiamImp"
        type="number"
        ref={register({
          required: true,
          min: 0.1,
          pattern: IMP_REG_EX,
        })}
        required
        error={errors?.reelDiamImp}
        placeholder={t`Diameter (inches)`}
      />
      <Input
        name="coreDiamImp"
        type="number"
        ref={register({
          required: true,
          min: 0.1,
          pattern: IMP_REG_EX,
        })}
        required
        error={errors?.coreDiamImp}
        placeholder={t`Core Diameter (inches)`}
      />
    </Space>
  );
};

export default ImperialCriteria;
