import { t } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import Select from '../../../../components/Select/Select';
import Option from '../../../../types/Option.type';
import {
  METRIC_NUMBER_REG_EX,
  NUMBER_NO_DECIMAL_REG_EX,
} from '../../../../types/RegularExpression.constants';

interface IMetricCriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  watch: UseFormMethods['watch'];
  grammageOptions: Option[];
}

const MetricCriteria: React.FunctionComponent<IMetricCriteriaProps> = ({
  register,
  errors,
  watch,
  grammageOptions,
}) => {
  const paperBrand = watch('paperBrand');
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Select
        name="grammage"
        options={grammageOptions}
        ref={register({
          required: true,
        })}
        required
        error={errors?.grammage}
        placeholder={t`Grammage`}
        disabled={!paperBrand}
      />
      <Input
        name="reelWidth"
        ref={register({
          required: true,
          pattern: METRIC_NUMBER_REG_EX,
        })}
        required
        error={errors?.reelWidth}
        placeholder={t`Width (cm)`}
      />
      <Input
        name="reelDiam"
        type="number"
        ref={register({
          required: true,
          pattern: NUMBER_NO_DECIMAL_REG_EX,
          min: 0,
          max: 999,
        })}
        required
        error={errors?.reelDiam}
        placeholder={t`Diameter (cm)`}
      />
      <Input
        name="coreDiam"
        ref={register({
          required: true,
          pattern: METRIC_NUMBER_REG_EX,
        })}
        required
        error={errors?.coreDiam}
        placeholder={t`Core Diameter (cm)`}
      />
    </Space>
  );
};

export default MetricCriteria;
