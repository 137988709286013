import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { Button } from '../../../../components/Button/Button';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { SearchOrderDetailsInputs } from '../../SearchOrderDetailsInputs.interface';
import TrackOrderTab from '../../TrackOrderTab.enum';
import { ViewOrdersRow } from './ViewOrdersRow.interface';

interface IOrderCellProps {
  record: ViewOrdersRow;
  onLinkClick: (
    tab: TrackOrderTab,
    searchOrderDetailsInputs: SearchOrderDetailsInputs
  ) => void;
}

const OrderCell: React.FunctionComponent<IOrderCellProps> = ({
  record,
  onLinkClick,
}) => {
  return (
    <dl>
      {!record.isChild && (
        <>
          <dt>
            <Trans>My Order #:</Trans> &nbsp;
          </dt>
          <dd>{record.customerOrderNumber}</dd>
          <br />
          <dt>
            <Trans id="sappiOrder">Sappi Order #:</Trans>&nbsp;
          </dt>
        </>
      )}

      <dd>
        {record.overviews.length > 1 ? (
          <>{stripZeros(record.documentNumber)}</>
        ) : (
          <Button
            theme="link"
            title={t({ id: 'view_details', message: 'View Details' })}
            onClick={() => {
              onLinkClick(TrackOrderTab.VIEW_ORDER_DETAILS, {
                documentNumber: record.documentNumber,
                documentPosition: record.overviews[0].documentPosition,
              });
            }}
          >
            <strong>
              {stripZeros(record.documentNumber)}/
              {stripZeros(record.overviews[0].documentPosition)}
            </strong>
          </Button>
        )}
      </dd>
      <br />
      {!record.isChild && (
        <>
          <dt>
            <Trans>Order Type:</Trans> &nbsp;
          </dt>
          <dd>{record.overviews[0].orderTypeDesc}</dd>
        </>
      )}
    </dl>
  );
};

export default OrderCell;
