import { CancelToken } from 'axios';
import { queryAlternates, queryProducts } from '../../../services/Product';
import cancellableQuery from '../../../services/Util/cancellableQuery.util';
import CancellablePromise from '../../../types/CancellablePromise.interface';
import ProductQuery from '../../../types/ProductQuery.interface';
import { Inventory } from './Inventory.interface';
import { processResults } from './Results.util';

// DERIVED INVENTORY
const queryInventory = async (
  query: ProductQuery,
  cancelToken?: CancelToken
): Promise<Inventory> => {
  const data = await queryProducts(query, cancelToken);
  return processResults(data.stockAvailabilityOverviews, data.productionPlans);
};

export const cancellableQueryInventory = (
  key: string,
  query: ProductQuery
): CancellablePromise<Inventory> => {
  return cancellableQuery(key, queryInventory, query);
};

export const queryAlternativeStock = async (
  params: {
    customerNumber: string;
    kcode: string;
  },
  cancelToken?: CancelToken
): Promise<Inventory> => {
  const data = await queryAlternates(
    params.customerNumber,
    params.kcode,
    cancelToken
  );
  return processResults(data.stockAvailabilityOverviews, data.productionPlans);
};

export const cancellableQueryAltStock = (
  key: string,
  params: {
    customerNumber: string;
    kcode: string;
  }
): CancellablePromise<Inventory> => {
  console.log(JSON.stringify(params));
  return cancellableQuery(key, queryAlternativeStock, params);
};
