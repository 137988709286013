import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { IDeconstructedTablePaginationProps } from '../../../../components/DeconstructedTable/DeconstructedTablePagination';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import useUser from '../../../../hooks/useUser';
import {
  getConsignmentInventory,
  IGetConsignmentInventoryParams,
} from '../../../../services/Consignment';
import { getCustomerByCustomerNumber } from '../../../../services/Customer';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { isPoRequiredForCustomer } from '../../Consignment.service';
import ConsignmentExpandedItems from '../../types/ConsignmentExpandedItems.interface';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import ConsignmentInventoryTable from './ConsignmentInventoryTable';
import {
  areAllItemsExpandedOrCollapsed,
  getUniformExpandedItemsState,
  processConsignmentInventory,
} from './ViewConsignmentInventory.util';
import useAnalytics from '../../../../hooks/useAnalytics';

interface IViewConsignmentInventoryProps {
  queryParams: IGetConsignmentInventoryParams;
}

const ViewConsignmentInventory: React.FunctionComponent<IViewConsignmentInventoryProps> = ({
  queryParams,
}) => {
  const { data: user } = useUser();
  const { trackPageView } = useAnalytics();
  const [isShowingAll, setIsShowingAll] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>();
  const [expandedItems, setExpandedItems] = useState<ConsignmentExpandedItems>(
    {}
  );
  const [expandAllIsExpanded, setExpandAllIsExpanded] = useState<boolean>(
    false
  );
  const [consignmentInventory, setConsignmentInventory] = useState<
    ConsignmentInventoryItem[]
  >([]);
  const FIRST_PAGE_NUM = 1;

  const doChangePage = (newPage: number): void => {
    trackPageView('CONSIGNMENT', 'PAGE_CHANGE');
    setCurrentPage(newPage);
  };

  const doToggleShowingAll = (): void => {
    trackPageView('CONSIGNMENT', 'SHOW_ALL');
    let revertingToPaged = false;

    setIsShowingAll((currentValue) => {
      if (currentValue) {
        revertingToPaged = true;
      }

      return !currentValue;
    });

    if (revertingToPaged) {
      setCurrentPage(1);
    }
  };

  const queryParamsWithPagination: IGetConsignmentInventoryParams = {
    ...queryParams,
    all: isShowingAll || undefined,
    page: isShowingAll ? undefined : currentPage,
  };

  const customerName = getCustomerByCustomerNumber(
    queryParams.soldToCustomerNumber,
    user?.customers
  )?.name;

  const { data, isError, isFetching } = useQuery(
    [QueryCacheName.CONSIGNMENT_INVENTORY, queryParamsWithPagination],
    () => getConsignmentInventory(queryParamsWithPagination),
    {
      enabled: queryParamsWithPagination,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const { data: isPoRequired } = useQuery(
    [
      QueryCacheName.CONSIGNMENT_INVENTORY_PO_REQUIRED,
      queryParams.soldToCustomerNumber,
    ],
    () => isPoRequiredForCustomer(queryParams.soldToCustomerNumber),
    {
      enabled: queryParams.soldToCustomerNumber,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // When data from query is updated:
  useEffect(() => {
    // Apply frontend processing to the data.
    const processed = processConsignmentInventory(
      data?.consignmentInventory?.results || []
    );

    // Set the consignment inventory state to the processed data.
    setConsignmentInventory(processed);

    // Reset expanded items state to all items collapsed.
    setExpandedItems(getUniformExpandedItemsState(processed, false));
  }, [data]);

  // When the expanded state of any items is updated:
  useEffect(() => {
    const numItems = Object.keys(expandedItems).length;

    if (numItems > 0) {
      // Check if all are expanded or all are collapsed.
      const { allExpanded, allCollapsed } = areAllItemsExpandedOrCollapsed(
        expandedItems
      );

      // Update the state which drives the "Expand All"/"Collapse All" UI
      // accordingly.
      if (allExpanded) {
        trackPageView('CONSIGNMENT', 'EXPAND_ALL');
        setExpandAllIsExpanded(true);
      }
      if (allCollapsed) {
        trackPageView('CONSIGNMENT', 'COLLAPSE_ALL');
        setExpandAllIsExpanded(false);
      }
    }
  }, [expandedItems, trackPageView]);

  const paginationInfo = data?.paginationInfo;

  const paginationProps: IDeconstructedTablePaginationProps | undefined =
    paginationInfo?.pageCount !== undefined &&
    paginationInfo.recordCount !== undefined
      ? {
          pageCount: paginationInfo.pageCount,
          currentPage: currentPage !== undefined ? currentPage : FIRST_PAGE_NUM,
          recordCount: paginationInfo.recordCount,
          isShowingAll,
          onChangePage: doChangePage,
          onToggleShowingAll: doToggleShowingAll,
        }
      : undefined;

  const isItemExpanded = (itemNumber: string): boolean =>
    !!expandedItems?.[itemNumber];

  const doExpandItem = (itemNumber: string): void => {
    setExpandedItems((currentExpandedItems) => ({
      ...currentExpandedItems,
      [itemNumber]: true,
    }));
  };

  const doCollapseItem = (itemNumber: string): void => {
    setExpandedItems((currentExpandedItems) => ({
      ...currentExpandedItems,
      [itemNumber]: false,
    }));
  };

  const doToggleExpandAllState = (): void => {
    let currentlyExpanding = false;

    setExpandAllIsExpanded((currentValue) => {
      if (!currentValue) {
        currentlyExpanding = true;
      }
      return !currentValue;
    });

    setExpandedItems(
      getUniformExpandedItemsState(consignmentInventory, currentlyExpanding)
    );
  };

  return (
    <div>
      <div className="my-6 text-3xl">{customerName}</div>
      <div>
        {isFetching ? (
          <LoadingSpinner horizontal />
        ) : (
          <ConsignmentInventoryTable
            isError={isError}
            consignmentInventory={consignmentInventory}
            isPoRequired={isPoRequired || false}
            paginationProps={paginationProps}
            expandCollapseInfo={{
              isItemExpanded,
              expandAllIsExpanded,
              onToggleExpandAllState: doToggleExpandAllState,
              onExpandItem: doExpandItem,
              onCollapseItem: doCollapseItem,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ViewConsignmentInventory;
