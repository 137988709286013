import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { Trans } from '@lingui/macro';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { cancellableGetSamples } from '../../../services/SamplesOrder';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { SamplesSearchFilters } from './SamplesSearchFilters.interface';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults/SearchResults';
import useSamplesOrderStore from '../useSamplesOrderStore.store';

interface IOrderSamplesProps {
  prop?: unknown;
}

const OrderSamples: React.FunctionComponent<IOrderSamplesProps> = (props) => {
  const [filters, setFilters] = useState<Partial<SamplesSearchFilters>>();
  const state = useSamplesOrderStore();

  const { resolvedData: samples, isFetching } = usePaginatedQuery(
    [QueryCacheName.SAMPLES_PRODUCTS, filters],
    cancellableGetSamples,
    {
      staleTime: 50000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: filters !== undefined,
    }
  );

  useEffect(() => {
    return () => {
      useSamplesOrderStore.setState({ isAddItemToExistingOrder: false });
    };
  }, []);

  return (
    <>
      {state.isAddItemToExistingOrder && (
        <div>
          <Trans>Add Item to Existing Order:</Trans> {state.documentNumber}
        </div>
      )}
      <SearchForm onSearch={setFilters} />
      {isFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        <>{samples && <SearchResults products={samples} />}</>
      )}
    </>
  );
};

export default OrderSamples;
