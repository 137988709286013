import { Trans } from '@lingui/macro';
import * as React from 'react';
import NonSamplesEuBasketPopup from '../../components/BasketPopUp/NonSamplesEuBasketPopup';

interface ISavedEnquiryAddedToBasketPopupProps {
  enquiryNumber: string;
  onClose: () => void;
  onContinueShopping: () => void;
}

const SavedEnquiryAddedToBasketPopup: React.FunctionComponent<ISavedEnquiryAddedToBasketPopupProps> = ({
  enquiryNumber,
  onClose,
  onContinueShopping,
}) => {
  return (
    <NonSamplesEuBasketPopup
      onClose={onClose}
      onContinueShopping={onContinueShopping}
    >
      {enquiryNumber && (
        <strong>
          <Trans>Last Enquiry Added</Trans> {enquiryNumber}
        </strong>
      )}
    </NonSamplesEuBasketPopup>
  );
};

export default SavedEnquiryAddedToBasketPopup;
