import { forOwn, get, pick } from 'lodash';
import ProductQuery from '../../../types/ProductQuery.interface';

const FILTER = [
  'brand',
  'grammage',
  'caliper',
  'minWidth',
  'maxWidth',
  'minLength',
  'maxLength',
  'chemistry',
  'parentBrand',
  'texture',
];

export default function isFilterUpdated(
  originalFilter: ProductQuery,
  updatedFilter: ProductQuery
): boolean {
  let isUpdated = false;
  forOwn(pick(updatedFilter, FILTER), (value, key) => {
    let selectedValue = get(pick(originalFilter, FILTER), key);
    selectedValue =
      selectedValue !== undefined && selectedValue !== null
        ? selectedValue
        : '';
    const checkedValue = value !== undefined && value !== null ? value : '';

    if (String(selectedValue) !== String(checkedValue)) {
      isUpdated = true;
    }
  });

  return isUpdated;
}
