import { t } from '@lingui/macro';
import { find } from 'lodash';
import { getStatusByStatusId, getStatusList } from '../../../services/Order';
import getByLink from '../../../services/Util/getByLink.util';
import { Link } from '../../../types/Link.interface';
import Option from '../../../types/Option.type';
import { OrderStatus } from '../../../types/OrderStatus.interface';

const ANY_OPTION = {
  label: t({ id: 'search.any', message: 'Any' }),
  value: '',
};

export const getStatusOptions = async (
  statusLink: Link | undefined
): Promise<Option[]> => {
  if (statusLink) {
    const statusCodes = (await getByLink(statusLink)) as string[];
    const allStatuses = await getStatusList();
    const options = statusCodes.map(async (statusCode: string) => {
      const statusOption = find<OrderStatus>(allStatuses, {
        status: statusCode,
      });
      if (statusOption) {
        return {
          value: statusCode,
          label: statusOption.text,
        };
      }
      const retrievedStatus = await getStatusByStatusId(statusCode);
      return {
        value: retrievedStatus.status,
        label: retrievedStatus.text,
      };
    });
    const resolvedOptions = await Promise.all(options);
    return [ANY_OPTION].concat(resolvedOptions);
  }
  return Promise.resolve([]);
};

export const getProductOptions = async (
  productLink: Link | undefined
): Promise<Option[]> => {
  if (productLink) {
    const products = (await getByLink(productLink)) as string[];
    const productOptions = products.map((product) => ({
      value: product,
      label: product,
    }));
    return [ANY_OPTION].concat(productOptions);
  }
  return Promise.resolve([]);
};

export const getShipToOptions = async (
  shipToLink: Link | undefined
): Promise<Option[]> => {
  if (shipToLink) {
    const shipTos = (await getByLink(shipToLink)) as string[][];
    const shipToOptions = shipTos.map((shipTo) => ({
      value: shipTo[0],
      label: shipTo[1],
    }));
    return [ANY_OPTION].concat(shipToOptions);
  }
  return Promise.resolve([]);
};
