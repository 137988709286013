export const IMP_REG_EX = /^[1-9][0-9]*$|^([0-9]* )?[0-9]+\/[0-9]+$|^[0-9]*\.[0-9]+$/;
export const MET_REG_EX = /^[0-9]{0,9}(\.[0-9]{0,3})?$/;

// Note: Many of these regular expressions also allow for two whole numbers
// separated by a whitespace character. It is not clear whether this is intended
// or in error (these were ported from the existing Angular implementation as
// found).
export const EU_NUMBER_REG_EX = /^[0-9]{0,9}(,[0-9]{0,3})?$/; // comma only, no fractions
export const METRIC_NUMBER_REG_EX = /^[0-9]*([,.]|\s)?[0-9]*$/; // comma or decimal, no fractions
export const METRIC_NUMBER_ONE_DECIMAL_REG_EX = /^[0-9]*([.]|\s)?[0-9]$/; // one decimal, no fractions
export const METRIC_NUMBER_THREE_DECIMAL_REG_EX = /^[0-9]*([.]|\s)?[0-9]{0,3}$/; // three decimal, no fractions
export const METRIC_NUMBER_THREE_REG_EX = /^[0-9]*([,.]|\s)?[0-9]{0,3}$/; // three decimal or comma, no fractions
export const NUMBER_NO_DECIMAL_REG_EX = /^[0-9]*$/;

// Allow fraction and decimal input
export const FRACTION_DECIMAL_INPUT = /^[0-9]*(\.|\s)?[0-9]+\/?[0-9]*$/;

export const METRIC_FRACTION_COMMA_INPUT_REG_EX = /^[0-9]*(,|\s)?[0-9]+\/?[0-9]*$/;
export const EU_FRACTION_COMMA_INPUT_REG_EX = /^[0-9]*(.|\s)?[0-9]+\/?[0-9]*$/;

export const VALID_EMAIL_REG_EX = /.+@(?:[a-zA-Z\-0-9]+\.)+[A-Za-z]+$/;

export const VALID_PHONE_NUMBER = /\d{3}[- ]?\d{3}[- ]?\d{4}$/;
