import { t } from '@lingui/macro';
import UnitOfMeasure from '../../../types/UnitOfMeasure.enum';
import { RollCalculatorResult } from './types/RollCalculatorResult.interface';
import { ResultCon } from './types/ResultContent.interface';

export const UOM_OPTIONS = [
  {
    value: UnitOfMeasure.KG,
    label: t`Kg`,
  },
  {
    value: UnitOfMeasure.RL,
    label: t`Rolls`,
  },
];

export const EMPTY_RESULT_CON = {
  contHi: 0,
  contLo: 0,
  contUsed: 0,
  contWeight: 0,
  maxPl: 0,
  reelWeight: '',
  reelsPerCont: 0,
  rlHi: 0,
  rlLayer1: 0,
  rlLayer2: 0,
  rlLayer3: 0,
  rlLayer4: 0,
  rlLo: 0,
  rlWeightHi: 0,
  rlWeightLo: 0,
};

export const EMPTY_ROLL_CALCULATOR_RESULT = {
  ivBrandMember: '',
  ivCoreDiameter: '',
  ivCountryMaxPl: '',
  ivGrade: '',
  ivGrammage: '',
  ivQty: 0,
  ivReelDiameter: '',
  ivReelWidth: 0,
  ivUom: '',
  reelsPerPack: '',
  resultCont20: EMPTY_RESULT_CON,
  resultCont40: EMPTY_RESULT_CON,
};

export const isEmptyResultCon = (resultCon: ResultCon): boolean => {
  return (
    resultCon.contHi === 0 &&
    resultCon.contLo === 0 &&
    resultCon.contUsed === 0 &&
    resultCon.contWeight === 0 &&
    resultCon.maxPl === 0 &&
    resultCon.reelWeight === '' &&
    resultCon.reelsPerCont === 0 &&
    resultCon.rlHi === 0 &&
    resultCon.rlLayer1 === 0 &&
    resultCon.rlLayer2 === 0 &&
    resultCon.rlLayer3 === 0 &&
    resultCon.rlLayer4 === 0 &&
    resultCon.rlLo === 0 &&
    resultCon.rlWeightHi === 0 &&
    resultCon.rlWeightLo === 0
  );
};

export const getOrderedQuantities = (
  results?: RollCalculatorResult | null
): [number, number] => {
  const requestedQuantity = +(results?.ivQty || 0);
  let orderedQuantityReels;
  if (results?.ivUom === UnitOfMeasure.KG) {
    orderedQuantityReels = Math.round(
      requestedQuantity / +(results?.resultCont20?.reelWeight || 1)
    );
  } else {
    orderedQuantityReels = Math.round(requestedQuantity);
  }

  const orderedQuantityKg =
    +(results?.resultCont20?.reelWeight || 1) * orderedQuantityReels;

  return [orderedQuantityReels, orderedQuantityKg];
};
