import React, { FC, HTMLProps } from 'react';

export type CustomizationAreaProps = HTMLProps<HTMLFieldSetElement> & {
  heading: React.ReactNode;
  errorMessage?: string;
};

const CustomizationArea: FC<CustomizationAreaProps> = ({
  heading,
  errorMessage,
  children,
  ...rest
}) => {
  return (
    <fieldset {...rest}>
      {!!heading && (
        <legend className="mb-7 text-xl font-bold">{heading}</legend>
      )}
      <div className="flex flex-wrap">{children}</div>
      {!!errorMessage && <div className="text-red-red">{errorMessage}</div>}
    </fieldset>
  );
};

export default CustomizationArea;
