import { t } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';

interface IOrderCellProps {
  purchaseOrderNumber: string;
  sappiOrderNumber: string;
}

const OrderCell: React.FunctionComponent<IOrderCellProps> = ({
  purchaseOrderNumber,
  sappiOrderNumber,
}) => {
  return (
    <Space direction="vertical">
      <LabelValue inline label={t`My Order #`} value={purchaseOrderNumber} />
      <LabelValue inline label={t`Sappi Order #`} value={sappiOrderNumber} />
    </Space>
  );
};

export default OrderCell;
