import { t } from '@lingui/macro';
import Option from '../../types/Option.type';

export const EMPTY_OPTION: Option = {
  label: '',
  value: '',
};

export const EMPTY_OPTION_SELECT: Option = {
  label: t`Select`,
  value: '',
};

export const EMPTY_OPTION_ANY: Option = {
  label: t`Any`,
  value: '',
};

export const EMPTY_OPTION_FROM: Option = {
  label: t`From`,
  value: '',
};

export const EMPTY_OPTION_TO: Option = {
  label: t`To`,
  value: '',
};

export const OPTION_ALL: Option = {
  label: t`All`,
  value: 'All',
};

export const EMPTY_OPTION_ALL: Option = {
  label: t`All`,
  value: '',
};
