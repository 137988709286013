import { Popover } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import OldBasketPopUp from '../../../components/BasketPopUp/OldBasketPopUp';
import useAnalytics from '../../../hooks/useAnalytics';
import useBasketMutation from '../../../hooks/useBasketMutation';
import useCustomerBasket from '../../../hooks/useCustomerBasket';
import { AvailabilityBasketNA } from '../../../types/AvailabilityBasketNA.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { StockAvailabilityOverview } from '../../../types/StockAvailabilityOverview.interface';
import useProductAvailabilityStore from '../useProductAvailabilityStore.store';
import AddItemToBasket, { AvailabilityForm } from './AddItemToBasket';
import { addStockRowToNaBasket } from './Results.util';

interface IAddStockItemToBasket {
  data: StockAvailabilityOverview;
  isContainerExpanded?: boolean;
}

const AddStockItemToBasket: React.FunctionComponent<IAddStockItemToBasket> = ({
  data,
  isContainerExpanded,
}) => {
  const [updateBasket] = useBasketMutation();
  const history = useHistory();
  const { getCustomerBasket } = useCustomerBasket();
  const { trackPageView } = useAnalytics();
  const { customerSoldTo } = useProductAvailabilityStore();

  const [addingToBasket, setAddingToBasket] = useState(false);
  const [showBasketPopUp, setShowBasketPopUp] = useState(false);
  const queryCache = useQueryCache();

  const onAdd = (availabilityData: AvailabilityForm) => {
    const updatedBasket = addStockRowToNaBasket(
      data,
      getCustomerBasket(customerSoldTo || ''),
      availabilityData.selectedMeasureUnit,
      availabilityData.quantity
    );

    setAddingToBasket(true);
    return updateBasket(updatedBasket as AvailabilityBasketNA).then(
      (availabilityBasketNA) => {
        if (availabilityBasketNA) {
          setShowBasketPopUp(true);
          trackPageView('PRODUCT_AVAILABILITY', 'ADD_TO_BASKET', {
            customerId: customerSoldTo,
          });
          queryCache.invalidateQueries(QueryCacheName.BASKET);
          setAddingToBasket(false);
        }
      }
    );
  };

  return (
    <>
      <Popover
        visible={showBasketPopUp && isContainerExpanded}
        content={
          <OldBasketPopUp
            onContinueShopping={() => setShowBasketPopUp(false)}
            onViewBasket={() =>
              history.push({
                pathname: '/submitOrder',
                search: customerSoldTo
                  ? `?customer=${customerSoldTo}`
                  : undefined,
              })
            }
          />
        }
        placement="topRight"
        overlayClassName="popover-remove-arrow"
      >
        <AddItemToBasket
          productType={data.productType}
          onAdd={onAdd}
          addingToBasket={addingToBasket}
        />
      </Popover>
    </>
  );
};

export default AddStockItemToBasket;
