const safelyParseJsonToStringArray = (
  possibleJsonString?: unknown
): string[] | undefined => {
  let parsed: unknown;

  try {
    if (possibleJsonString && typeof possibleJsonString === 'string') {
      parsed = JSON.parse(possibleJsonString);
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }

  const filtered: string[] = [];

  if (Array.isArray(parsed)) {
    // Using forEach and push instead of filter to keep TypeScript happy without
    // type assertions.
    parsed.forEach((element) => {
      if (typeof element === 'string') {
        filtered.push(element);
      }
    });
  } else {
    return undefined;
  }

  return filtered;
};

export default safelyParseJsonToStringArray;
