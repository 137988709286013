import React, { FC } from 'react';
import CalculatorButtons from './CalculatorButtons';
import CalculatorResults from './CalculatorResults';
import CalculatorTemplateProps from './CalculatorTemplateProps.interface';
import CalculatorTitle from './CalculatorTitle';
import CalculatorWrapper from './CalculatorWrapper';

const CalculatorTemplate: FC<CalculatorTemplateProps> = ({
  title,
  instructions,
  isCalculateDisabled,
  isCalculating,
  onCalculate,
  onReset,
  error,
  result,
  children,
}) => {
  return (
    <CalculatorWrapper>
      <CalculatorTitle>{title}</CalculatorTitle>
      <div className="mb-3">{instructions}</div>
      <div>{children}</div>
      <CalculatorButtons
        isCalculateDisabled={isCalculateDisabled}
        isCalculating={isCalculating}
        onCalculate={onCalculate}
        onReset={onReset}
      />
      <div className="text-red-red mt-4">{error}</div>
      {!!result && <CalculatorResults>{result}</CalculatorResults>}
    </CalculatorWrapper>
  );
};

export default CalculatorTemplate;
