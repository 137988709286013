import { Location } from 'history';
import create from 'zustand';

type BlockRouteState = {
  lastLocation: Location<unknown> | null;
};

const useBlockRouteStore = create<BlockRouteState>(() => ({
  lastLocation: null,
}));

export default useBlockRouteStore;
