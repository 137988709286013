import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import ContainerViewLeftColumnRow from './ContainerViewLeftColumnRow';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { ResultCon } from './types/ResultContent.interface';
import ContainerViewRightColumnRow from './ContainerViewRightColumnRow';
import ContainerViewRightColumnBottomRow from './ContainerViewRightColumnBottomRow';
import { isEmptyResultCon } from './Criteria.util';

interface IContainerTableProps {
  title: string;
  reelsPerPack?: string;
  resultCon: ResultCon;
}

const ContainerView: React.FunctionComponent<IContainerTableProps> = ({
  title,
  reelsPerPack,
  resultCon,
}) => {
  const { format } = useNumberFormatter();

  return (
    <>
      <div className="p-3 bg-gray-dark200 text-white-white w-full text-lg">
        {title}
      </div>
      <div className="flex">
        <div
          className={`${
            isEmptyResultCon(resultCon) ? 'w-1/2' : 'w-5/12'
          } bg-gray-whiteSmoke400 mr-3 my-3 pb-6`}
        >
          <ContainerViewLeftColumnRow
            label={t`Max Payload (kg)`}
            value={format(resultCon.maxPl) || ''}
          />
          <ContainerViewLeftColumnRow
            label={t`Kg/roll`}
            value={format(resultCon.reelWeight) || ''}
          />
          <div className="my-4">
            <div className="ml-4 font-bold">
              <Trans>Max Quantity of Packages/Layer</Trans>
            </div>
            <div className="ml-4 font-bold">
              {reelsPerPack} <Trans> RL/Pack</Trans>
            </div>
          </div>
          <ContainerViewLeftColumnRow
            label={t`Layer 1`}
            value={format(resultCon.rlLayer1) || ''}
          />
          <ContainerViewLeftColumnRow
            label={t`Layer 2`}
            value={format(resultCon.rlLayer2) || ''}
          />
          <ContainerViewLeftColumnRow
            label={t`Layer 3`}
            value={format(resultCon.rlLayer3) || ''}
          />
          <ContainerViewLeftColumnRow
            label={t`Layer 4`}
            value={format(resultCon.rlLayer4) || ''}
          />
        </div>
        <div className={`${isEmptyResultCon(resultCon) ? 'w-1/2' : 'w-7/12'}`}>
          <ContainerViewRightColumnRow
            label={t`Kg/Container`}
            value={format(resultCon.contWeight) || ''}
          />
          <ContainerViewRightColumnRow
            label={t`# Rolls/Container`}
            value={format(resultCon.reelsPerCont) || ''}
          />
          <ContainerViewRightColumnRow
            label={t`# Containers Used`}
            value={format(resultCon.contUsed) || ''}
          />
          <div className="bg-gray-whiteSmoke400 py-3.5">
            <ContainerViewRightColumnBottomRow
              contValue={format(resultCon.contLo) || ''}
              rlValue={format(resultCon.rlLo) || ''}
              rlWeightValue={format(resultCon.rlWeightLo) || ''}
            />
            <ContainerViewRightColumnBottomRow
              contValue={format(resultCon.contHi) || ''}
              rlValue={format(resultCon.rlHi) || ''}
              rlWeightValue={format(resultCon.rlWeightHi) || ''}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContainerView;
