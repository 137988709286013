/**
 * So far, only need this for Environment/Build information
 */

import Axios from 'axios';
import { get } from 'lodash';
import { Properties } from '../../types/Properties.interface';
import { TradeBasis } from '../../types/TradeBasis.interface';

export const getLocalization = (): null => null;

export const getProperties = async (): Promise<Properties> => {
  const { data } = await Axios.get('/portal/resources/localization');
  return {
    environment: data['build.environment'],
    number: data['build.number'],
    version: data['build.version'],
    gtmId: data['gtm.id'],
    gtmAuth: data['gtm.auth'],
    gtmPreview: data['gtm.preview'],
  };
};

export const getLocale = async (): Promise<string> => {
  const { data } = await Axios.get('/portal/resources/localization/locale');
  return data.slice(-2);
};

export const getLocalizationProperty = async (
  property: string
): Promise<string> => {
  const { data } = await Axios.get('/portal/resources/localization');
  return get(data, property);
};

export const getCountryOfRequest = async (): Promise<{
  ip: string;
  country: string;
}> => {
  const { data } = await Axios.get(
    '/portal/resources/localization/country/request'
  );
  return data;
};

export const isNorthAmericanRequest = async (): Promise<boolean> => {
  const response = await getCountryOfRequest();
  return (
    response.country === undefined ||
    response.country === 'United States' ||
    response.country === 'Canada'
  );
};

export const getTradeBasis = async (): Promise<TradeBasis[]> => {
  const { data } = await Axios.get('/portal/resources/property/tradebasis');
  return data;
};
