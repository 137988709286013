import * as React from 'react';

const BasketPopUpWrapper: React.FunctionComponent<React.HTMLProps<
  HTMLDivElement
>> = ({ className = '', children, ...rest }) => {
  return (
    <div
      className={`p-3 text-gray-dark200 ${className}`}
      style={{ width: '280px' }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default BasketPopUpWrapper;
