import React, { FC } from 'react';
import { useQuery } from 'react-query';
import Note from '../../components/Note';
import { getLoginNotificationContent } from '../../services/Content';
import QueryCacheName from '../../types/QueryCacheName.enum';

interface ILoginNotificationProps {
  lang?: string;
}

const LoginNotification: FC<ILoginNotificationProps> = ({ lang }) => {
  const { data: loginNotification } = useQuery(
    QueryCacheName.LOGIN_NOTIFICATION,
    () => getLoginNotificationContent(lang || ''),
    {
      enabled: lang,
      staleTime: 50000,
    }
  );
  return (
    <>
      {loginNotification && (
        <Note className="mb-10">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: loginNotification.body,
            }}
          />
        </Note>
      )}
    </>
  );
};

export default LoginNotification;
