import { Trans } from '@lingui/macro';
import { Popover } from 'antd';
import React, { useState } from 'react';
import { useQueryCache } from 'react-query';
import { Button } from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { addProductToEuBasket } from '../../../../services/Basket';
import AvailabilityBasketEU from '../../../../types/AvailabilityBasketEU.interface';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import { ProductAvailabilityResult } from '../../../../types/ProductAvailabilityResult.interface';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import useNonReleaseEuBasketPopupStore from '../../../PageHeader/OldSappiBasket/useNonReleaseEuBasketPopup.store';
import OrderBookingAddedToBasketPopUp from '../../OrderBookingAddedToBasketPopUp';

interface IAddToBasketProps {
  product: ProductAvailabilityResult;
  resetFullyToLander: () => void;
}

enum Status {
  READY_TO_ADD = 'READY_TO_ADD',
  ADDING = 'ADDING',
  ALREADY_ADDED = 'ALREADY_ADDED',
}

const AddToBasket: React.FunctionComponent<IAddToBasketProps> = ({
  product,
  resetFullyToLander,
}) => {
  const [popUpIsOpen, setPopUpIsOpen] = useState<boolean>(false);
  const [basketWithItemAdded, setBasketWithItemAdded] = useState<
    AvailabilityBasketEU
  >();
  const [itemAddedToBasket, setItemAddedToBasket] = useState<
    AvailabilityBasketItem
  >();
  const [status, setStatus] = useState<Status>(Status.READY_TO_ADD);
  const { setNonStockLotLastAddedFor15 } = useNonReleaseEuBasketPopupStore();
  const queryCache = useQueryCache();

  const handleAddToBasket = async (): Promise<void> => {
    setStatus(Status.ADDING);
    const result: AvailabilityBasketEU = await addProductToEuBasket(product);
    setBasketWithItemAdded(result);
    if (result?.items?.length && result?.items?.length > 0) {
      const item = result.items[result.items.length - 1];
      setItemAddedToBasket(item);
      setNonStockLotLastAddedFor15(item);
      queryCache.invalidateQueries(QueryCacheName.BASKET_ORDER_BOOKING);
    }
    setStatus(Status.ALREADY_ADDED);
    setPopUpIsOpen(true);
  };

  return (
    <div className="flex">
      {status === Status.ADDING && <LoadingSpinner />}
      <Popover
        visible={basketWithItemAdded && itemAddedToBasket && popUpIsOpen}
        content={
          basketWithItemAdded &&
          itemAddedToBasket && (
            <OrderBookingAddedToBasketPopUp
              itemAdded={itemAddedToBasket}
              onClose={() => setPopUpIsOpen(false)}
              onContinueShopping={resetFullyToLander}
            />
          )
        }
        placement="topRight"
        overlayClassName="popover-remove-arrow"
      >
        <div className="ml-auto">
          {status !== Status.ALREADY_ADDED ? (
            <Button
              theme="primary"
              onClick={handleAddToBasket}
              disabled={status === Status.ADDING}
            >
              <Trans>Add to basket</Trans>
            </Button>
          ) : (
            <Trans>Added to basket</Trans>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default AddToBasket;
