import { t } from '@lingui/macro';
import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { noop } from 'lodash';
import React from 'react';
import { usePaginatedQuery, useQueryCache } from 'react-query';
import Table from '../../../components/Table/Table';
import useRole from '../../../hooks/useRole';
import useUser from '../../../hooks/useUser';
import { AuthorityRole } from '../../../types/Authority.interface';
import Region from '../../../types/Region.enum';
import { StockAvailabilityOverview } from '../../../types/StockAvailabilityOverview.interface';
import AvailableProductCell from './AvailableProductCell';
import DescriptionCell from './DescriptionCell';
import { getUnitFromStockAvailOv, isSkuSaved } from './Results.util';
import {
  deleteSavedSku,
  loadSavedSkus,
  saveSku,
} from '../../../services/SappiSku';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import useAnalytics from '../../../hooks/useAnalytics';

interface IStockOrMillResultTableProps {
  data: StockAvailabilityOverview[];
  resultTitle: string;
  compareStockData?: StockAvailabilityOverview;
  isAltStockResult?: boolean;
  isMillResult?: boolean;
}

const StockOrMillResultTable: React.FunctionComponent<IStockOrMillResultTableProps> = ({
  data,
  resultTitle,
  compareStockData,
  isAltStockResult,
  isMillResult,
}) => {
  const { data: user } = useUser();
  const { hasRole } = useRole();
  const { trackPageView } = useAnalytics();
  const queryCache = useQueryCache();
  const { resolvedData: savedSkus } = usePaginatedQuery(
    [QueryCacheName.SAVED_SKUS],
    loadSavedSkus,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: data,
      keepPreviousData: true,
    }
  );

  const saveOrDeleteSkuCode = async (record: StockAvailabilityOverview) => {
    if (isSkuSaved(savedSkus || [], record.kcode)) {
      await deleteSavedSku(record.kcode);
      queryCache.invalidateQueries(QueryCacheName.SAVED_SKUS);
    } else {
      await saveSku(
        record.kcode,
        record.productName,
        record.productNameMet
      );
      trackPageView("AVAILABILITY", "SAVE_SKU");
      queryCache.invalidateQueries(QueryCacheName.SAVED_SKUS);
    }
  };

  const SAPPI_SKU_COLUMN = {
    title: t`Sappi SKU`,
    dataIndex: 'kcode',
    key: 'kcode',
    className: 'text-lg font-bold text-blue-pacific align-baseline',
    render: (value: any, record: StockAvailabilityOverview, idx: number) => ({
      children: (
        <>
          {isAltStockResult ? (
            <div className="bg-yellow-lemonChiffon px-3 py-1">{value}</div>
          ) : (
            <div className="flex items-center">
              {!isMillResult && (
                <Tooltip placement="topLeft" title={t`Save or remove saved sku`}>
                  <i
                    aria-label={t`Bookmark Sku Code`}
                    className={`mr-3 fa-star ${
                      isSkuSaved(savedSkus || [], record.kcode)
                        ? 'fa text-green-lime'
                        : 'far'
                    }`}
                    onKeyDown={noop}
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      saveOrDeleteSkuCode(record);
                    }}
                  />
                </Tooltip>
              )}
              {value}
            </div>
          )}
        </>
      ),
      props: {
        rowSpan: idx === 0 ? data?.length : 0,
      },
    }),
  };

  const columns: ColumnsType<StockAvailabilityOverview> = [
    {
      title: t`Product Location`,
      dataIndex: 'rdcText',
      key: 'rdcText',
      className: `text-lg font-bold text-blue-pacific align-baseline ${
        isAltStockResult ? 'px-0' : ''
      }`,
      render: (value, record) => (
        <>
          {isAltStockResult ? (
            <div className="bg-yellow-lemonChiffon px-3 py-1">{record.rdc}</div>
          ) : (
            <>{value}</>
          )}
        </>
      ),
    },
    {
      title: t`Unit`,
      dataIndex: 'unit',
      key: 'unit',
      className: 'align-baseline text-lg',
      render: (value, record) =>
        getUnitFromStockAvailOv(user?.region || Region.NA, record),
    },
    {
      title: t`Available Product`,
      dataIndex: 'product',
      key: 'product',
      className: 'align-baseline',
      width: '35%',
      render: (value, record) => (
        <AvailableProductCell
          data={record}
          isAltStockResult={isAltStockResult}
        />
      ),
    },
    {
      title: t`Description`,
      dataIndex: 'description',
      key: 'description',
      className: 'align-baseline',
      width: '35%',
      render: (value, record, idx) => {
        const props = {
          rowSpan: idx === 0 ? data?.length : 0,
        };
        if (isMillResult) {
          props.rowSpan = 1;
        }

        return {
          children: (
            <DescriptionCell
              data={record}
              compareData={compareStockData}
              showAddItemToBasket={isMillResult}
            />
          ),
          props,
        };
      },
    },
  ];
  return (
    <>
      <div
        className={`text-4xl font-medium p-4 pl-7 ${
          isAltStockResult ? 'bg-yellow-lemonChiffon' : ''
        }`}
      >
        {resultTitle}
      </div>
      <Table
        rowKey={(record: StockAvailabilityOverview) =>
          `${record.productName}-${record.rdcText}-${record.reelWidth}-${record.reelDiameter}-${record.length}-${record.width}-${record.availableQuantityLbs}`
        }
        dataSource={data}
        columns={
          !hasRole(AuthorityRole.ROLE_RELEASE)
            ? [SAPPI_SKU_COLUMN, ...columns]
            : columns
        }
        pagination={false}
        className="sappi-product-availability-table-override"
      />
    </>
  );
};

export default StockOrMillResultTable;
