import { t, Trans } from '@lingui/macro';
import { Popover } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import AddToBasketQtyInput from '../../../../components/AddToBasket/AddToBasketQtyInput';
import { Button } from '../../../../components/Button/Button';
import ErrorTooltip from '../../../../components/ErrorTooltip/ErrorTooltip';
import useAnalytics from '../../../../hooks/useAnalytics';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import useRole from '../../../../hooks/useRole';
import useSamplesOrder from '../../../../hooks/useSamplesOrder';
import useValidator from '../../../../hooks/useValidator';
import showNotification from '../../../../services/Notification';
import {
  addItemPerDocumentNumber,
  addItemToSamplesOrder,
  createSamplesOrder,
} from '../../../../services/SamplesOrder';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import { SamplesOrder } from '../../../../types/Samples/SamplesOrder.interface';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import { SamplesProductOriginal } from '../../../../types/Samples/SamplesProductOrigin.interface';
import useSamplesBasketPopupStore from '../../../PageHeader/OldSappiBasket/useSamplesBasketPopup.store';
import ShippingType from '../../Basket/ShippingInstructions/ShippingType.enum';
import useSamplesOrderStore from '../../useSamplesOrderStore.store';
import AddedToBasketPopUp from './AddedToBasketPopUp';
import AddItemToExistingOrderModal from './AddItemToExistingOrderModal';

interface IAddFormData {
  quantity: string;
}

const DEFAULT_SAMPLES_ORDER: SamplesOrder = {
  buyerNumber: null,
  comments: null,
  courierAccountNumber: null,
  customShipping: false,
  deliveryAddress: null,
  deliveryComment: null,
  deliveryInfo: {
    name: '',
    email: null,
    phone: null,
  },
  languageIso: null,
  palletDeliveryAvailable: null,
  products: [],
  shipToNumber: null,
  shippingAccountNumber: null,
  shippingType: ShippingType.STANDARD,
  userSessionId: '',
  username: '',
};

interface IAddToBasketProps {
  product: SamplesProduct;
}

const AddToBasket: React.FunctionComponent<IAddToBasketProps> = ({
  product,
}) => {
  const { register, handleSubmit, errors, formState, watch, reset } = useForm({
    mode: 'all',
    defaultValues: { quantity: '' },
  });
  const { isValid, isSubmitSuccessful, isSubmitting } = formState;
  const qtyWatch = watch('quantity');
  const { parse } = useNumberFormatter();
  const { isInternalUser } = useRole();
  const {
    data: existingOrder,
    isFetching: orderIsFetching,
  } = useSamplesOrder();
  const queryCache = useQueryCache();
  const { trackPageView } = useAnalytics();
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [popUpIsVisible, setPopUpIsVisible] = useState<boolean>(false);
  const [quantityInput, setQuantityInput] = useState<number>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const state = useSamplesOrderStore();
  const { validateStringIsNum } = useValidator();
  const { setLastAdded } = useSamplesBasketPopupStore();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const getQtyErrMsg = (errorType: string): string => {
    switch (errorType) {
      case 'isNumber':
        return t`Invalid number.`;
      case 'range':
        return t`Maximum of 3 pieces/units per order.`;
      default:
        return '';
    }
  };

  const addItemToOrder = async (
    samplesOrder: SamplesOrder | undefined,
    item: SamplesProduct
  ) => {
    if (samplesOrder?.id && samplesOrder?.id > 0) {
      await addItemToSamplesOrder(item);
    } else {
      await createSamplesOrder({
        ...DEFAULT_SAMPLES_ORDER,
        products: [item],
      });
    }
    queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDER);
    queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDERS_HISTORY);

    trackPageView('SAMPLES', 'ADD_TO_BASKET', {
      customerId: '',
    });
  };

  const doSubmit = async (data: IAddFormData) => {
    const parsedQuantity = parse(data.quantity);

    if (parsedQuantity !== null) {
      if (!state.isAddItemToExistingOrder) {
        const itemToAdd: SamplesProduct = {
          ...product,
          quantity: parsedQuantity,
        };

        await addItemToOrder(existingOrder, itemToAdd);
        queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDER);
        setLastAdded(itemToAdd);

        setPopUpIsVisible(true);
      }
      if (state.isAddItemToExistingOrder) {
        setQuantityInput(parsedQuantity);
        setModalIsVisible(true);
      }
    }
  };

  const addItemToSampleOrder = async () => {
    if (quantityInput !== undefined) {
      const samplesProduct: SamplesProductOriginal = {
        materialNumber: product.materialNumber || '',
        quantity: quantityInput,
      };
      setIsProcessing(true);
      await addItemPerDocumentNumber(
        state.documentNumber || '',
        samplesProduct
      );

      setModalIsVisible(false);
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDER);
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDERS_HISTORY);
      setIsProcessing(false);

      showNotification({
        message: '',
        description: t`Item successfully added to order.`,
      });
    }
  };

  const validateQtyRange = (quantity: string): boolean => {
    if (isInternalUser || quantity === '') {
      return true;
    }

    const parsed = parse(quantity);

    return parsed === null || parsed <= 3;
  };

  return (
    <>
      <Popover
        visible={popUpIsVisible}
        content={
          <AddedToBasketPopUp
            onClose={() => setPopUpIsVisible(false)}
            order={existingOrder}
            orderIsFetching={orderIsFetching}
            product={product}
          />
        }
        placement="topRight"
        overlayClassName="popover-remove-arrow"
      >
        <div className="flex items-stretch">
          <ErrorTooltip
            hasError={!!errors?.quantity}
            message={
              errors?.quantity?.type ? getQtyErrMsg(errors.quantity.type) : null
            }
            placement="bottom"
          >
            <AddToBasketQtyInput
              name="quantity"
              ref={register({
                validate: {
                  isNumber: validateStringIsNum,
                  range: validateQtyRange,
                },
              })}
              disabled={
                product.available != 'X' || orderIsFetching || isSubmitting // eslint-disable-line eqeqeq
              }
              hasError={!!errors?.quantity}
            />
          </ErrorTooltip>
          <Button
            onClick={handleSubmit(doSubmit)}
            disabled={orderIsFetching || !qtyWatch || !isValid || isSubmitting}
            theme="primary"
          >
            <Trans>Add to Basket</Trans>
          </Button>
        </div>
      </Popover>
      {state.isAddItemToExistingOrder && (
        <AddItemToExistingOrderModal
          visible={modalIsVisible}
          onClose={() => setModalIsVisible(false)}
          onConfirm={addItemToSampleOrder}
          isProcessing={isProcessing}
          product={product}
          quantityInput={quantityInput}
          order={{ ...state } as AugmentedSamplesHistoryOrder}
        />
      )}
    </>
  );
};

export default AddToBasket;
