import * as React from 'react';
import { t } from '@lingui/macro';
import { Moment } from 'moment';
import { ClaimTypes } from '../../types/ClaimTypes.enum';
import useUser from '../../hooks/useUser';
import Region from '../../types/Region.enum';
import InputLabel from '../../components/InputLabel';
import DatePicker from '../../components/DatePicker/DatePicker';

interface IFileClaimFormPlannedDeliveryDatesProps {
  chosenClaimType: ClaimTypes;
  plannedDate?: Moment | null;
  achievedDate: Moment | null;
  applyDeliveryDate: boolean;
  setAchievedDate: (achievedDate: Moment | null) => void;
}
const FileClaimFormPlannedDeliveryDates: React.FunctionComponent<IFileClaimFormPlannedDeliveryDatesProps> = ({
  chosenClaimType,
  plannedDate,
  achievedDate,
  applyDeliveryDate,
  setAchievedDate,
}) => {
  const { data: user } = useUser();
  const isEuUser = user?.region === Region.EU;

  return (
    <>
      {/*  There is error when Planned/Delivery data added for Transit type, Need to check in SAP
          such error is returned index.js:1 Complaint creation failed - No batch input data for dynpro SAPMV45A 9300. */}
      {chosenClaimType === ClaimTypes.TRANSIT && (
        <InputLabel size="lg" text={t`Delivery Date`} className="py-1 pt-5">
          <div className="flex">
            <InputLabel
              text={t`Planned`}
              className="py-1 pr-10"
              position="side"
              labelClassName="text-lg"
            >
              <DatePicker disabled value={plannedDate} />
            </InputLabel>
            <InputLabel
              text={t`Achieved`}
              required
              className="py-1"
              position="side"
              labelClassName="text-lg"
            >
              <DatePicker
                onChange={(momentDate) => {
                  setAchievedDate(momentDate);
                }}
                required
                // disabled={!applyDeliveryDate}
                value={achievedDate}
              />
            </InputLabel>
          </div>
        </InputLabel>
      )}
    </>
  );
};

export default FileClaimFormPlannedDeliveryDates;
