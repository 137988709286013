import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import SectionHeader from '../../../../components/SectionHeader';
import { NaShipTo } from '../../../../types/NaShipTo.interface';
import ContactInformation from './ContactInformation';
import DeliveryInstructions from './DeliveryInstructions';
import { DeliveryDetailsForm } from './types/DeliveryDetailsForm.type';

interface IContactInformationSectionProps {
  register: UseFormMethods<DeliveryDetailsForm>['register'];
  errors: UseFormMethods<DeliveryDetailsForm>['errors'];
  selectedShipToCustomer: NaShipTo | undefined;
}

const ContactInformationSection: FC<IContactInformationSectionProps> = ({
  register,
  errors,
  selectedShipToCustomer,
}) => {
  return (
    <Space direction="vertical" size="large" className="w-full">
      <SectionHeader darkMode>
        <Trans>Delivery Contact Information</Trans>
      </SectionHeader>
      <div className="grid grid-cols-2">
        <ContactInformation
          register={register}
          errors={errors}
          isNewDeliveryLocation={!selectedShipToCustomer}
        />
        <DeliveryInstructions
          register={register}
          selectedShipToCustomer={selectedShipToCustomer}
        />
      </div>
    </Space>
  );
};

export default ContactInformationSection;
