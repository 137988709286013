import { useEffect, useState } from 'react';
import { ViewOrdersRow } from './ViewOrdersRow.interface';
import { Order } from '../../../../types/Order.interface';

const useViewOrdersRow = (
  data: Order[]
): {
  viewOrderRows: ViewOrdersRow[];
} => {
  const [viewOrderRows, setViewOrderRows] = useState<ViewOrdersRow[]>([]);

  useEffect(() => {
    const rows: ViewOrdersRow[] = data.map((order, idx) => {
      const viewOrderRow: ViewOrdersRow = {
        ...order,
        key: idx,
      };
      if (order.overviews.length > 1) {
        viewOrderRow.children = order.overviews.map((overview) => ({
          ...order,
          key: `${overview.id}-${idx}`,
          overviews: [overview],
          isChild: true,
        }));
      }
      return viewOrderRow;
    });
    setViewOrderRows(rows);
  }, [data]);

  return { viewOrderRows };
};

export default useViewOrdersRow;
