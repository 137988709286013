import React, { FC } from 'react';

interface IHeaderItemProps {
  hideBorder?: boolean;
  className?: string;
}

const HeaderItem: FC<IHeaderItemProps> = ({
  hideBorder,
  className = '',
  children,
}) => {
  return (
    <div
      className={`text-gray-gray text-3xl ${
        hideBorder ? 'pl-3' : 'border-r border-gray-light400 px-3'
      } ${className}`}
    >
      {children}
    </div>
  );
};

export default HeaderItem;
