import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import useUser from '../../../hooks/useUser';
import { deriveCustomerOptions } from '../../../services/Customer';
import { EMPTY_OPTION } from '../../../services/Util/emptyOption.const';
import Option from '../../../types/Option.type';
import { isPoRequiredForCustomer } from '../Consignment.service';

interface FormState {
  customer: string;
  yourOrderNumber: string;
  file: FileList;
}
interface IUploadCosumptionItemsProps {
  defaultSelectedCustomer?: string;
  defaultSelectedPoNumber: string | null;
}

const UploadCosumptionItems: React.FunctionComponent<IUploadCosumptionItemsProps> = ({
  defaultSelectedCustomer = '',
  defaultSelectedPoNumber = '',
}) => {
  const { data: user } = useUser();
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [showPoInput, setShowPoInput] = useState(false);
  const { register, formState, reset, setValue } = useForm<FormState>({
    mode: 'all',
  });
  const [poRequiredLoading, setPoRequiredLoading] = useState(false);

  useEffect(() => {
    if (user) {
      const consignmentCustomers = filter(
        user?.customers,
        (customer) => customer.consignment
      );
      setCustomerOptions([
        EMPTY_OPTION,
        ...deriveCustomerOptions(consignmentCustomers),
      ]);
    }
  }, [user]);

  const onCustomerChange = async (customerNumber: string) => {
    setPoRequiredLoading(true);
    const isPoRequired = await isPoRequiredForCustomer(customerNumber);
    setPoRequiredLoading(false);
    setShowPoInput(isPoRequired);
  };

  useEffect(() => {
    if (defaultSelectedCustomer) {
      setValue('customer', defaultSelectedCustomer, { shouldValidate: true });
      onCustomerChange(defaultSelectedCustomer);
    }
  }, [defaultSelectedCustomer, setValue]);

  return (
    <form
      className="w-1/2 mt-10 px-3"
      action="/portal/resources/consignment/upload"
      method="post"
      encType="multipart/form-data"
    >
      <ol>
        <li>
          <strong>
            <Trans>
              Select a customer account which has access to a Sappi consignment
              program.
            </Trans>
          </strong>
        </li>
        <li>
          <strong>
            <Trans>
              If you need to assign a purchase order number to the upload, enter
              this in the “My Order #” field. If Sappi will be invoicing the
              same purchase order number used on the replenishment order, then
              this field will not appear.
            </Trans>
          </strong>
        </li>
        <li>
          <strong>
            <Trans>
              Upload your list of roll or pallet IDs using one of the example
              file formats (CSV file or Excel file).
            </Trans>
          </strong>
        </li>
      </ol>
      <div className="ml-8">
        <div>
          <strong>
            <Trans>
              (Please contact your Sappi Inside Sales representative if you have
              any questions)
            </Trans>
          </strong>
        </div>
        <br />
        <div>
          <strong>
            <Trans>You can upload a file with the following format:</Trans>
          </strong>
        </div>
        <ul>
          <li>
            <strong>
              <a
                className="app-link"
                href="/portal/static/ConsignmentConsumptionUploadExample.csv"
              >
                <Trans>Click here to download an example CSV file</Trans>
              </a>
            </strong>
          </li>
          <li>
            <strong>
              <a
                className="app-link"
                href="/portal/static/ConsignmentConsumptionUploadExample.xlsx"
              >
                <Trans>Click here to download an example Excel file</Trans>
              </a>
            </strong>
          </li>
        </ul>
      </div>
      <Space direction="vertical" size="middle" className="w-full w-">
        <InputLabel text={t`Customer`} size="lg">
          <Select
            name="customer"
            ref={register({ required: true })}
            options={customerOptions}
            required
            onChange={(e) => onCustomerChange(e.target.value)}
          />
        </InputLabel>
        {showPoInput && (
          <Input
            name="yourOrderNumber"
            ref={register({ required: true })}
            placeholder={t`My Order #`}
            width="1/2"
            required
            defaultValue={defaultSelectedPoNumber || ''}
          />
        )}
        <Input name="file" type="file" ref={register({ required: true })} />
      </Space>
      <Space className="mt-10">
        <Button
          type="submit"
          theme="primary"
          disabled={!formState.isValid || poRequiredLoading}
        >
          <Trans>Upload File</Trans>
        </Button>
        <span className="px-2" />
        <Button
          type="reset"
          theme="link"
          className="text-lg font-bold"
          onClick={() => reset()}
        >
          <Trans>Reset</Trans>
        </Button>
      </Space>
    </form>
  );
};

export default UploadCosumptionItems;
