import create from 'zustand';
import { AvailabilityBasketItem } from '../../../types/AvailabilityBasketItem.interface';
import { Batch } from '../../StockLot/types/Batch.interface';

type BasketPopupState = {
  lastNonStockLotItemAdded: AvailabilityBasketItem | null;
  lastStockLotItemAdded: Batch | null;
  setNonStockLotLastAddedFor15: (
    lastAdded: AvailabilityBasketItem | null
  ) => void;
  setStockLotLastAddedFor15: (lastAdded: Batch | null) => void;
};

const useNonReleaseEuBasketPopupStore = create<BasketPopupState>((set) => ({
  lastNonStockLotItemAdded: null,
  lastStockLotItemAdded: null,
  setNonStockLotLastAddedFor15: (lastNonStockLotItemAdded) => {
    set({ lastNonStockLotItemAdded });
    setTimeout(() => set({ lastNonStockLotItemAdded: null }), 15000);
  },
  setStockLotLastAddedFor15: (lastStockLotItemAdded) => {
    set({ lastStockLotItemAdded });
    setTimeout(() => set({ lastStockLotItemAdded: null }), 15000);
  },
}));

export default useNonReleaseEuBasketPopupStore;
