import { t } from '@lingui/macro';
import { trimStart } from 'lodash';
import {
  getBaseWeightOptions,
  getBrandOptions,
  getCaliperOptions,
  getEuGrammageOptions,
  getGradeOptions,
  getParentBrandOptions,
  getTextureOptions,
} from '../../../services/Brand';
import Brand from '../../../types/Brand.interface';
import EuBrand from '../../../types/EuBrand.interface';
import Option from '../../../types/Option.type';

const getPlaceholderOptions = (placeholder: string, addAny?: boolean) => {
  const options: Option[] = [
    {
      label: placeholder,
      value: '',
    },
  ];
  if (addAny) {
    options.push({
      label: t({ id: 'search.any', message: 'Any' }),
      value: '',
      key: 'ANY',
    });
  }
  return options;
};

export const doGetBrandOptions = (brands: Brand[]): Option[] => {
  let options = getPlaceholderOptions(
    t({ id: 'search.paperBrand', message: 'Product' }),
    true
  );
  if (brands) {
    const brandOptions = getBrandOptions(brands as Brand[]);
    brandOptions.shift();
    options = options.concat(brandOptions);
  }
  return options;
};

export const doGetParentBrandOptions = (brands: Brand[]): Option[] => {
  let options = getPlaceholderOptions(
    t({ id: 'search.parentBrand', message: 'Parent Brand' }),
    true
  );
  if (brands) {
    const brandOptions = getParentBrandOptions(brands);
    brandOptions.shift();
    options = options.concat(brandOptions);
  }
  return options;
};

export const doGetBaseWeightOptions = (
  brands: Brand[],
  selectedProduct?: string
): Option[] => {
  let options = getPlaceholderOptions(
    t({ id: 'search.tradeBasisWeight', message: 'Basis Weight' })
  );
  const baseWeightOptions = getBaseWeightOptions(
    brands,
    {
      selectedBrand: selectedProduct,
    },
    false
  );
  baseWeightOptions.shift();
  options = options.concat(baseWeightOptions);
  return options;
};

export const doGetCaliperOptions = (
  brands: Brand[],
  selectedProduct?: string
): Option[] => {
  let options = getPlaceholderOptions(
    t({ id: 'search.caliperNumber', message: 'Caliper #' })
  );
  const calOptions = getCaliperOptions(brands, {
    selectedBrand: selectedProduct,
  });
  calOptions.shift();
  options = options.concat(calOptions);
  return options;
};

export const doGetGrammageOptions = (
  brands: EuBrand[],
  selectedProduct?: string
): Option[] => {
  let options = getPlaceholderOptions(
    t({ id: 'search.grammage', message: 'Grammage' })
  );
  const grammageOptions = getEuGrammageOptions(
    brands,
    'grammages',
    selectedProduct
  ).map((opt) => {
    opt.value = trimStart(opt.value as string, '0');
    opt.label = trimStart(opt.label as string, '0');
    return opt;
  });
  grammageOptions.shift();
  options = options.concat(grammageOptions);
  return options;
};

export const doGetChemistryOptions = (
  brands: Brand[],
  selectedProduct?: string
): Option[] => {
  let options = getPlaceholderOptions(
    t({ id: 'search.grade', message: 'Grade' })
  );
  const gradeOptions = getGradeOptions(brands, {
    selectedParentBrand: selectedProduct,
  });
  gradeOptions.shift();
  options = options.concat(gradeOptions);
  return options;
};

export const doGetTextureOptions = (
  brands: Brand[],
  selectedProduct?: string,
  selectedGrade?: string
): Option[] => {
  let options = getPlaceholderOptions(
    t({ id: 'search.texture', message: 'Texture' })
  );
  const textureOptions = getTextureOptions(
    brands,
    selectedProduct,
    selectedGrade
  );
  textureOptions.shift();
  options = options.concat(textureOptions);
  return options;
};
