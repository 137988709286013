import React, { FC } from 'react';

const DeconstructedTableHeadCell: FC<React.HTMLProps<
  HTMLTableHeaderCellElement
>> = ({ className = '', children, ...rest }) => (
  <th className={`ant-table-cell ${className}`} {...rest}>
    {children}
  </th>
);

export default DeconstructedTableHeadCell;
