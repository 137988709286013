import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import AboveTableHeader from '../AboveTableHeader';
import { DeviationData } from '../types/DeviationData.interface';
import TrackAndTraceStep from '../types/TrackAndTraceStep.enum';
import { getShownResults } from './Deviation.util';
import { DeviationForm } from './DeviationForm.interface';
import ReviewStep from './ReviewStep';
import SearchStep from './SearchStep';
import useDeviationDataStore from './useDeviationData.store';

const MAX_RESULTS = 250;
interface IDeviationProps {
  data: DeviationData[];
  onConfirm: (changed: DeviationData[]) => void;
}

const Deviation: FC<IDeviationProps> = ({ data, onConfirm }) => {
  const hasExcessResults = data.length > MAX_RESULTS;
  const [currentTableStep, setCurrentTableStep] = useState<TrackAndTraceStep>(
    TrackAndTraceStep.SEARCH
  );
  const [changedData, setChangedData] = useState<DeviationData[]>([]);

  const doSaveAndContinue = (formData: DeviationForm) => {
    useDeviationDataStore.setState({ ...formData });
    setCurrentTableStep(TrackAndTraceStep.REVIEW);
    setChangedData(getShownResults(data, formData));
  };

  return (
    <Space direction="vertical" size="middle" className="w-full">
      <AboveTableHeader
        headerText={t`Report Deviation`}
        deviationExcelData={
          currentTableStep === TrackAndTraceStep.SEARCH ? data : changedData
        }
      />
      <div className="text-right w-full">
        {currentTableStep === TrackAndTraceStep.SEARCH && (
          <SearchStep
            data={hasExcessResults ? data.slice(0, MAX_RESULTS) : data}
            showExcessResultsMessage={hasExcessResults}
            onSaveAndContinue={doSaveAndContinue}
          />
        )}
        {currentTableStep === TrackAndTraceStep.REVIEW && (
          <ReviewStep
            data={changedData}
            onBack={() => setCurrentTableStep(TrackAndTraceStep.SEARCH)}
            onConfirm={onConfirm}
          />
        )}
      </div>
    </Space>
  );
};

export default Deviation;
