import { useMemo } from 'react';
import useBasket from '../../../../hooks/useBasket';
import { AvailabilityBasketNA } from '../../../../types/AvailabilityBasketNA.interface';
import { ALL_CUSTOMERS } from '../NaBasket.util';

const useSelectedCustomerBaskets = (
  selectedCustomerNum: string | undefined
): {
  isFetching: boolean;
  selectedCustomerBaskets: AvailabilityBasketNA[] | undefined;
} => {
  const { data: basketData, isFetching } = useBasket();

  const selectedCustomerBaskets:
    | AvailabilityBasketNA[]
    | undefined = useMemo(() => {
    return selectedCustomerNum === ALL_CUSTOMERS
      ? basketData
      : basketData?.filter(
          (basket) => basket.soldToCustomer.number === selectedCustomerNum
        );
  }, [basketData, selectedCustomerNum]);

  return { isFetching, selectedCustomerBaskets };
};

export default useSelectedCustomerBaskets;
