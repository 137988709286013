import { find } from 'lodash';
import Brand from '../../../../types/Brand.interface';
import EuBrand from '../../../../types/EuBrand.interface';
import EuBrandGrammageGrade from '../../../../types/EuBrandGrammageGrade.interface';
import { CalculatorUnitTypes } from '../../types/CalculatorUnitTypes.enum';
import { Criteria } from './Criteria.interface';
import { BasisWeightConversion } from '../../BasisWeightConversion/BasisWeightConversion.interface';
import { convertBasisWeight } from '../../BasisWeightConversion/BasisWeightConversionCalculator.util';
import BasisWeightUnit from '../../BasisWeightConversion/BasisWeight.enum';

const replaceCommasWithPeriods = (value: string) => value.replace(',', '.');

const deriveImperialMetricParams = (criteria: Criteria) => {
  const newCriteria = {
    ...criteria,
  };
  if (criteria.metImp === CalculatorUnitTypes.IMP) {
    if (criteria.basisWeight && !criteria.baseWeight) {
      const [baseWeight, tradeBasis] = criteria.basisWeight.split(' ');
      newCriteria.baseWeight = baseWeight;
      if (!criteria.tradeBasis){
        newCriteria.tradeBasis = tradeBasis;
      }
    }
  } else {
    newCriteria.reelWidth = replaceCommasWithPeriods(newCriteria.reelWidth);
    newCriteria.reelDiam = replaceCommasWithPeriods(newCriteria.reelDiam);
    newCriteria.coreDiam = replaceCommasWithPeriods(newCriteria.coreDiam);
  }
  console.log(JSON.stringify(newCriteria));
  return newCriteria;
};

export const deriveAdditionalEUParams = (
  criteria: Criteria,
  brands: EuBrand[]
): Criteria => {
  const newCriteria: Criteria = {
    ...criteria,
    paperBrand: brands[+criteria.paperBrand].brand,
  };
  const { grammage } = criteria;
  if (grammage) {
    const { grammageGrades } = brands[+criteria.paperBrand];
    const filteredGrammageGrades: EuBrandGrammageGrade[] = grammageGrades.filter(
      (item) => item.grammage === +grammage
    );
    newCriteria.salesGrade = filteredGrammageGrades.length
      ? filteredGrammageGrades[0].grade
      : brands[+criteria.paperBrand].grade;
  } else {
    newCriteria.salesGrade = brands[+criteria.paperBrand].grade;
  }

  return deriveImperialMetricParams(newCriteria);
};

export const deriveAdditionalNAParams = (
  criteria: Criteria,
  brands: Brand[]
): Criteria => {
  const newCriteria: Criteria = {
    ...criteria,
  };
  
  const selectedBrand = criteria.basisWeight ? find(brands, { brand: criteria.paperBrand, grammage: criteria.basisWeight }) : find(brands, { brand: criteria.paperBrand });
  
  // Packaging brands need to be able to run a calculation through without a caliper entry.
  // To do this, we need to ensure that the baseweight is the value per the tradebasis.
  if(newCriteria.metImp === "IMP"){
    const convertedBasisWeight = convertBasisWeight(
      criteria.basisWeight,
      BasisWeightUnit.GRAMMAGE
    );
    
    switch (selectedBrand?.tradeBasis) {
      case 'TXT':
        newCriteria.baseWeight = convertedBasisWeight.text;
        break;
      case 'TAG':
        newCriteria.baseWeight = convertedBasisWeight.tag;
        break;
      case 'CVR':
        newCriteria.baseWeight = convertedBasisWeight.cover;
        break;
      default:
        break;
    }
  }
  newCriteria.tradeBasis = selectedBrand?.tradeBasis;
  newCriteria.salesGrade = selectedBrand?.grade;
  return deriveImperialMetricParams(newCriteria);
};
