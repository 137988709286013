import { useQuery } from 'react-query';
import { EMPTY_OPTION_ALL } from '../../services/Util/emptyOption.const';
import Option from '../../types/Option.type';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { getFieldValues } from './services';
import { FilterFormData } from './types/FilterFormData.interface';
import { FilterType } from './types/FilterType.enum';
import { StockLotRequest } from './types/StockLotRequest.interface';

const useFieldValueOptions = (
  forField: FilterType,
  stockLotRequest: StockLotRequest | undefined
): {
  fieldValueOptions: Option[];
  isFetching: boolean;
} => {
  const DEFAULT_FILTER_FORM_DATA: FilterFormData = {
    productType: '',
    shipToParty: '',
    soldToParty: '',
    division: '',
  };

  const filterFormData: FilterFormData = {
    ...(stockLotRequest || DEFAULT_FILTER_FORM_DATA),
    forField,
  };

  const { data: fieldValues, isFetching } = useQuery(
    [QueryCacheName.STOCK_LOT_FIELD_VALUES, filterFormData],
    () => getFieldValues(filterFormData),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      enabled: forField && stockLotRequest,
      keepPreviousData: false,
    }
  );

  const options: Option[] = [];
  fieldValues?.map((c) => {
    return options.push({
      label: c,
      value: c,
    });
  });

  return {
    fieldValueOptions: [EMPTY_OPTION_ALL, ...options],
    isFetching,
  };
};

export default useFieldValueOptions;
