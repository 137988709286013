import { t, Trans } from '@lingui/macro';
import React, { FC } from 'react';
import Date from '../../../components/Date';
import LabelValue from '../../../components/LabelValue/LabelValue';

interface IArrivalDepartureDatesProps {
  arrivalDate: number;
  arrivalTime: string | null;
  departureDate: number;
  departureTime: string | null;
}

const ArrivalDepartureDates: FC<IArrivalDepartureDatesProps> = ({
  arrivalDate,
  arrivalTime,
  departureDate,
  departureTime,
}) => {
  return (
    <div>
      {!!arrivalDate && (
        <LabelValue
          label={t`Arrival`}
          inline
          inverse
          value={
            <>
              <Date date={arrivalDate} />
              &nbsp;<Trans>at</Trans>&nbsp;{arrivalTime}
            </>
          }
        />
      )}
      {!!departureDate && (
        <LabelValue
          label={t`Departure`}
          inline
          inverse
          value={
            <>
              <Date date={departureDate} />
              &nbsp;<Trans>at</Trans>&nbsp;{departureTime}
            </>
          }
        />
      )}
    </div>
  );
};

export default ArrivalDepartureDates;
