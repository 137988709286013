import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useValues from '../../hooks/useValues';
import ValueKey from '../../types/ValueKey.enum';
import Arrival from './Arrival/Arrival';
import useArrivalDataStore from './Arrival/useArrivalData.store';
import ConfirmationStep from './ConfirmationStep';
import Deviation from './Deviation/Deviation';
import useDeviationDataStore from './Deviation/useDeviationData.store';
import LanderContent from './LanderContent';
import SearchForm from './SearchForm/SearchForm';
import {
  searchArrival,
  searchDeviations,
  submitArrivals,
  submitDeviations,
} from './TrackAndTrace.service';
import { DeviationData } from './types/DeviationData.interface';
import { TrackAndTraceData } from './types/TrackAndTraceData.interface';
import { ISearchForm } from './types/TSearchForm.type';
import useTrackAndTraceStore from './useTrackAndTrace.store';

const TrackAndTrace: FC = () => {
  const history = useHistory();
  const { items } = useValues(ValueKey.DELAY_REASON);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [arrivalExcelData, setArrivalExcelData] = useState<
    TrackAndTraceData[]
  >();
  const [deviationExcelData, setDeviationExcelData] = useState<
    DeviationData[]
  >();
  const arrivalData = useTrackAndTraceStore((state) => state.arrivalData);
  const deviationData = useTrackAndTraceStore((state) => state.deviationData);

  const doReportArrival = async (formData: ISearchForm) => {
    setIsLoading(true);
    useTrackAndTraceStore.setState({
      request: formData,
      arrivalData: await searchArrival(formData),
      deviationData: null,
    });
    setIsLoading(false);
  };

  const doReportDeviation = async (formData: ISearchForm) => {
    setIsLoading(true);
    useTrackAndTraceStore.setState({
      request: formData,
      arrivalData: null,
      deviationData: await searchDeviations(formData),
    });
    setIsLoading(false);
  };

  const doConfirmDeviation = async (changed: DeviationData[]) => {
    setIsLoading(true);
    await submitDeviations(changed, items || []);
    useTrackAndTraceStore.setState({
      request: null,
      arrivalData: null,
      deviationData: null,
    });
    setDeviationExcelData(changed);
    setShowConfirmation(true);
    setIsLoading(false);
  };

  const doConfirmArrival = async (changed: TrackAndTraceData[]) => {
    setIsLoading(true);
    await submitArrivals(changed);
    useTrackAndTraceStore.setState({
      request: null,
      arrivalData: null,
      deviationData: null,
    });
    setArrivalExcelData(changed);
    setShowConfirmation(true);
    setIsLoading(false);
  };

  const doTrackAnotherDelivery = () => {
    useTrackAndTraceStore.setState({
      request: null,
      arrivalData: null,
      deviationData: null,
    });
    useDeviationDataStore.setState({
      deviationRow: [],
    });
    useArrivalDataStore.setState({
      arrivalRow: [],
    });
    setArrivalExcelData(undefined);
    setDeviationExcelData(undefined);
    setShowConfirmation(false);
  };

  return (
    <div className="flex">
      {showConfirmation ? (
        <ConfirmationStep
          arrivalExcelData={arrivalExcelData}
          deviationExcelData={deviationExcelData}
          onTrackAnotherDelivery={doTrackAnotherDelivery}
        />
      ) : (
        <>
          <SearchForm
            onReportArrival={doReportArrival}
            onReportDeviation={doReportDeviation}
            style={{ width: '350px', minWidth: '350px' }}
          />
          <Space direction="vertical" size="middle" className="flex-grow ml-6">
            <div className="w-full flex justify-end items-center">
              <div className="text-lg mr-4">
                <Trans>Upload file for batch processing</Trans>
              </div>
              <Button
                theme="primary"
                onClick={() => history.push('/trackAndTrace/upload')}
              >
                <Trans>Upload</Trans>
              </Button>
            </div>
            {arrivalData || deviationData || isLoading || showConfirmation ? (
              <>
                {isLoading ? (
                  <LoadingSpinner horizontal />
                ) : (
                  <>
                    {arrivalData && (
                      <Arrival
                        data={arrivalData}
                        onConfirm={doConfirmArrival}
                      />
                    )}
                    {deviationData && (
                      <Deviation
                        data={deviationData}
                        onConfirm={doConfirmDeviation}
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <LanderContent />
            )}
          </Space>
        </>
      )}
    </div>
  );
};

export default TrackAndTrace;
