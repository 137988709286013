import { PrintDocument } from '../../types/PrintDocument.interface';

const printDocument = (value: PrintDocument): void => {
  if (value && value.documentIds && value.documentIds.length > 0) {
    let idQr = '?';
    const documentsUrl = '../resources/documents';

    for (let i = 0, l = value.documentIds.length; i < l; i++) {
      const id = value.documentIds[i];
      idQr += `&documentId=${id}`;
    }
    // @ts-expect-error: to replicate the behavior of angularjs to download file without
    window.location = `${documentsUrl}${idQr}`;
  }
};

export default printDocument;
