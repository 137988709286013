import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { Button } from '../../components/Button/Button';
import { DeviationData } from './types/DeviationData.interface';
import { TrackAndTraceData } from './types/TrackAndTraceData.interface';
import useDownloadExcel from './useDownloadExcel';

interface IConfirmationStepProps {
  deviationExcelData?: DeviationData[];
  arrivalExcelData?: TrackAndTraceData[];
  onTrackAnotherDelivery: () => void;
}

const ConfirmationStep: FC<IConfirmationStepProps> = ({
  deviationExcelData,
  arrivalExcelData,
  onTrackAnotherDelivery,
}) => {
  const { downloadExcel } = useDownloadExcel({
    deviation: deviationExcelData,
    arrival: arrivalExcelData,
  });
  return (
    <Space direction="vertical" size="large">
      <h2 className="text-3xl">
        <Trans>Confirmation</Trans>
      </h2>
      <div>
        <Trans>
          Your Report with the new arrival and/or departure time has been
          submitted.
        </Trans>
        <Button theme="link" className="block" onClick={downloadExcel}>
          <Trans>Download Report (xls)</Trans>
        </Button>
      </div>
      <Button theme="primary" onClick={onTrackAnotherDelivery}>
        <Trans>Track Another Delivery</Trans>
      </Button>
    </Space>
  );
};

export default ConfirmationStep;
