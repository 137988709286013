import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Button } from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Input from '../../../../components/Input/Input';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import Table from '../../../../components/Table/Table';
import useAnalytics from '../../../../hooks/useAnalytics';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { PayloadOrder } from '../types/PayloadOrder.interface';
import usePayloadStore from '../usePayloadStore';
import BasketCharacteristicsCell from './BasketCharacteristicsCell';
import styles from './BasketTable.module.scss';

interface IBasketTableProps {
  register: UseFormMethods['register'];
  basketItems: PayloadOrder[];
}

const BasketTable: React.FunctionComponent<IBasketTableProps> = ({
  register,
  basketItems,
}) => {
  const { format } = useNumberFormatter();
  const { removeOrder } = usePayloadStore();
  const { trackPageView } = useAnalytics();

  const onRemove = (order: PayloadOrder) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t`Are you sure you want to delete this item?`)) {
      trackPageView('CALCULATORS', 'REMOVE_PAYLOAD_ITEM');
      removeOrder(order);
      usePayloadStore.setState({ requiresRecalculation: true });
    }
  };
  const columns: ColumnsType<PayloadOrder> = [
    {
      key: 'partOfCalculation',
      align: 'center',
      render: (value, record, index) => (
        <Checkbox
          name={`basket[${index}].selected`}
          ref={register()}
          label=""
          defaultChecked
        />
      ),
    },
    {
      title: <Trans>Quantity</Trans>,
      key: 'unitQuantity',
      render: (value, record, index) => (
        <div className="flex items-center">
          <Input
            name={`basket[${index}].quantity`}
            ref={register()}
            className="font-bold"
            defaultValue={record.unitQuantity}
          />{' '}
          <div className="ml-5 text-lg font-bold">{record.unitType}</div>
        </div>
      ),
    },
    {
      title: <Trans>Unit</Trans>,
      key: 'unit',
      render: (value, record) => (
        <div>
          <div>
            {format(record.numberOf)}{' '}
            {record.productType === PutUpCategory.SHEET ? t`Pallets` : t`Rolls`}
          </div>
          <LabelValue
            label={t`Total Weight (gross)`}
            value={`${format(record.totalWeight)} kg`}
            noBold
            inline
          />
        </div>
      ),
    },
    {
      title: <Trans>Description</Trans>,
      key: 'description',
      render: (value, record) => (
        <div>
          <div>
            {record.brand} {record.grammage}
          </div>
          <div>{record.materialNumber}</div>
        </div>
      ),
    },
    {
      title: <Trans>Characteristics</Trans>,
      key: 'characteristics',
      render: (value, record) => <BasketCharacteristicsCell record={record} />,
    },
    {
      title: <Trans>Mill</Trans>,
      key: 'departureMill',
      dataIndex: 'departureMill',
    },
    {
      key: 'delete',
      render: (value, record) => (
        <Button
          theme="circle-danger"
          onClick={() => onRemove(record)}
          className="bg-red-red"
        >
          <i className="fa fa-times text-white-white" />
        </Button>
      ),
    },
  ];
  return (
    <Table
      className={styles.basketTable}
      columns={columns}
      dataSource={basketItems}
      pagination={false}
    />
  );
};

export default BasketTable;
