import { i18n } from '@lingui/core';
import React, { FC, useState } from 'react';
import Tabs, { TabPane } from '../../../components/Tabs/Tabs';
import useDashboardWidgets from '../../../hooks/useDashboardWidgets';
import DashboardWidgetId from '../types/DashboardWidgetId.enum';
import { WIDGET_COMPONENTS } from './Widgets.constant';
import { getDashboardWidgetLabel } from './Widgets.util';

interface IWidgetTabsProps {
  tabIds: DashboardWidgetId[];
}

const WidgetTabs: FC<IWidgetTabsProps> = ({ tabIds }) => {
  const { allDashboardWidgets } = useDashboardWidgets();
  const [selectedTab, setSelectedTab] = useState<DashboardWidgetId>();

  const safelySetActiveTab = (key: string): void => {
    if (Object.values<string>(DashboardWidgetId).includes(key)) {
      setSelectedTab(key as DashboardWidgetId);
    }
  };

  return (
    <Tabs
      type="card"
      activeKey={selectedTab}
      onChange={(key) => safelySetActiveTab(key)}
    >
      {tabIds.map((id) => (
        <TabPane
          tab={i18n._(getDashboardWidgetLabel(id, allDashboardWidgets))}
          key={id}
        >
          {React.createElement(WIDGET_COMPONENTS[id])}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default WidgetTabs;
