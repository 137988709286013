import Axios, { CancelToken } from 'axios';
import { omit } from 'lodash';
import { SearchOrderDetailsInputs } from '../../domain/TrackOrders/SearchOrderDetailsInputs.interface';
import CancellablePromise from '../../types/CancellablePromise.interface';
import { DeliveryHistory } from '../../types/DeliveryHistory.interface';
import { DeliveryResponse } from '../../types/DeliveryResponse.interface';
import { LinkResultResponse } from '../../types/LinkResultResponse.interface';
import { Order } from '../../types/Order.interface';
import { OrderStatus } from '../../types/OrderStatus.interface';
import { ScheduleData } from '../../types/ScheduleData.interface';
import { TrackOrder } from '../../types/TrackOrder.interface';
import { omitEmptyProperties } from '../Util/omitEmptyProperties.util';

export interface QueryParams {
  customerNumber: string;
  customerOrderNumber?: string;
  shipToPONumber?: string;
  documentNumber?: string;
  containerNumber?: string;
  invoiceNumber?: string;
  billOfLadingNumber?: string;
  weightListNumber?: string;
  statusCode?: string;
  typeCode?: string;
  minOrderDate?: string;
  maxOrderDate?: string;
  minLastRevisionDate: string;
  maxLastRevisionDate: string;
  minDeliveryDate: string;
  maxDeliveryDate: string;
  usePaginatedVersion?: boolean;
  page: number;
  pageSize: number;
  all?: boolean;
  paperBrand?: string;
  shipToCustomerNumber?: string;
  minArrivalDate: string;
  maxArrivalDate: string;
}

export const searchOrders = async (
  queryParams: QueryParams,
  cancelToken?: CancelToken
): Promise<LinkResultResponse<Order>> => {
  const url = queryParams.usePaginatedVersion
    ? '/portal/resources/order/search/page'
    : '/portal/resources/order/search';
  const { data, headers } = await Axios.get(url, {
    params: omit(queryParams, 'weightEntry'),
    cancelToken,
  });
  return {
    ...data,
    totalPages: headers['page-info-pages'],
    totalResults: headers['page-info-total'],
  };
};

export const cancellableSearchOrders = (
  key: string,
  queryParams: QueryParams
): CancellablePromise<LinkResultResponse<Order>> => {
  const source = Axios.CancelToken.source();
  const promise = searchOrders(queryParams, source.token) as CancellablePromise<
    LinkResultResponse<Order>
  >;
  promise.cancel = () => {
    source.cancel(`Query ${key} was cancelled by React Query`);
  };
  return promise;
};

export const getMarkedOrders = async (
  queryParams: QueryParams
): Promise<LinkResultResponse<Order>> => {
  const url = queryParams.usePaginatedVersion
    ? '/portal/resources/order/marked/page'
    : '/portal/resources/order/marked';
  const { data } = await Axios.get(url, {
    params: omitEmptyProperties(queryParams),
  });
  return data;
};

export const getOrderDetails = async (
  documentNumber: string,
  documentPosition: string
): Promise<Order> => {
  const { data } = await Axios.get(
    `/portal/resources/order/${documentNumber}/${documentPosition}`
  );
  return data;
};

export const getOrderDetailsWithQuery = async (
  _: string,
  documentNumber: string,
  documentPosition: string
): Promise<Order> => {
  const searchedOrderDetails = await getOrderDetails(
    documentNumber,
    documentPosition
  );
  return searchedOrderDetails;
};

export const searchOrderDetailsSchedule = async (
  documentNumber: string,
  documentPosition: string
): Promise<ScheduleData[]> => {
  const { data } = await Axios.get(
    `/portal/resources/order/${documentNumber}/${documentPosition}/schedule`
  );
  return data;
};

export const searchOrderDetailsDelivery = async (
  documentNumber: string,
  documentPosition: string
): Promise<DeliveryResponse[]> => {
  const { data } = await Axios.get(
    `/portal/resources/delivery/${documentNumber}/${documentPosition}`
  );
  return data;
};

export const searchOrderDetailsHistory = async (
  documentNumber: string,
  documentPosition: string
): Promise<DeliveryHistory[]> => {
  const { data } = await Axios.get(
    `/portal/resources/order/${documentNumber}/${documentPosition}/history`
  );
  return data;
};

export const searchOrderDetailsAsync = async (
  _: string,
  input: SearchOrderDetailsInputs
): Promise<{
  searchedTrackOrderDetails: TrackOrder;
  deliveries: DeliveryResponse[];
  histories: DeliveryHistory[];
}> => {
  const searchedOrderDetails = await getOrderDetails(
    input.documentNumber,
    input.documentPosition
  );

  const scheduleDataArray = await searchOrderDetailsSchedule(
    input.documentNumber,
    input.documentPosition
  );

  const searchedTrackOrderDetails = { ...searchedOrderDetails } as TrackOrder;
  searchedTrackOrderDetails.scheduleDataArray = scheduleDataArray;

  const deliveries = await searchOrderDetailsDelivery(
    input.documentNumber,
    input.documentPosition
  );

  const histories = await searchOrderDetailsHistory(
    input.documentNumber,
    input.documentPosition
  );

  return { searchedTrackOrderDetails, deliveries, histories };
};

export const getStatusList = async (): Promise<OrderStatus[]> => {
  const { data } = await Axios.get(`/portal/resources/status`);
  return data;
};

export const getStatusByStatusId = async (
  statusId: string
): Promise<OrderStatus> => {
  const { data } = await Axios.get(`/portal/resources/status/${statusId}`);
  return data;
};

export const nominalWeightFlagTrue = 'X';

export const orderWeightIsNominal = (
  nominalWeightFlag: string | undefined
): boolean => {
  return nominalWeightFlag === nominalWeightFlagTrue;
};

export default searchOrders;
