import { WeightConversion } from './WeightConversion.interface';
import WeightUnit from './WeightUnit.interface';

const CONSTANTS = {
  [WeightUnit.KG]: {
    kg: 1,
    lbs: 2.20462,
    metricTons: 0.001,
    shortTons: 0.00110231,
  },
  [WeightUnit.LBS]: {
    kg: 0.453592,
    lbs: 1,
    metricTons: 0.000453592,
    shortTons: 0.0005,
  },
  [WeightUnit.METRIC_TON]: {
    kg: 1000,
    lbs: 2204.62,
    metricTons: 1,
    shortTons: 1.10231,
  },
  [WeightUnit.SHORT_TON]: {
    kg: 907.185,
    lbs: 2000,
    metricTons: 0.907185,
    shortTons: 1,
  },
};

export const convertWeight = (
  quantity: string,
  unit: WeightUnit
): WeightConversion => {
  const quantityNumber = +quantity.replace(',', '.');
  return {
    kg: (
      Math.round(quantityNumber * CONSTANTS[unit].kg * 1000) / 1000
    ).toString(),
    lbs: (
      Math.round(quantityNumber * CONSTANTS[unit].lbs * 1000) / 1000
    ).toString(),
    metricTons: (
      Math.round(quantityNumber * CONSTANTS[unit].metricTons * 1000) / 1000
    ).toString(),
    shortTons: (
      Math.round(quantityNumber * CONSTANTS[unit].shortTons * 1000) / 1000
    ).toString(),
  };
};

export const convertToEU = (weights: WeightConversion): WeightConversion => {
  const { kg, lbs, metricTons, shortTons } = weights;
  return {
    kg: kg.replace('.', ','),
    lbs: lbs.replace('.', ','),
    metricTons: metricTons.replace('.', ','),
    shortTons: shortTons.replace('.', ','),
  };
};
