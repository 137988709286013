import { QueryResult, useQuery } from 'react-query';
import { getCustomerBaskets } from '../services/Basket';
import { AvailabilityBasketLastItemAdded } from '../types/AvailabilityBasketLastItemAdded.interface';
import { AvailabilityBasketNA } from '../types/AvailabilityBasketNA.interface';
import QueryCacheName from '../types/QueryCacheName.enum';
import { getLastItemAdded } from './useBasket.util';
import useMeasurementSystem from './useMeasurementSystem';
import useUser from './useUser';

export interface BasketQueryResult extends QueryResult<AvailabilityBasketNA[]> {
  lastItemAdded: AvailabilityBasketLastItemAdded | null;
}

const useBasket = (): BasketQueryResult => {
  const { data: userData } = useUser();
  const { measurementSystem } = useMeasurementSystem();
  const useQueryResult = useQuery(
    [QueryCacheName.BASKET],
    async () => getCustomerBaskets(),
    {
      enabled: userData,
      refetchOnWindowFocus: false,
    }
  );

  return {
    ...useQueryResult,
    lastItemAdded: getLastItemAdded(useQueryResult.data, measurementSystem),
  };
};

export default useBasket;
