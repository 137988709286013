import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { t, Trans } from '@lingui/macro';
import { UseFormMethods } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import Table from '../../../components/Table/Table';
import { AvailabilityBasketItem } from '../../../types/AvailabilityBasketItem.interface';
import styles from './BasketTable.module.scss';
import CharacteristicsCell from './TableCells/CharacteristicsCell';
import { availabilityUpdateRequired } from './Basket.util';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import DescriptionCell from './TableCells/DescriptionCell';
import QuantityCell from './TableCells/QuantityCell';
import BasketTableSummary from './BasketTableSummary';
import { deleteAvailabilityItem } from '../../../services/Basket';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import UpdateBasket from './UpdateBasket';
import NoTitleCell from './TableCells/NoTileCell';
import ArticlePriceCell from './TableCells/ArticlePriceCell';
import InvoicePriceCell from './TableCells/InvoicePriceCell';
import CertificationCell from './TableCells/CertificationCell';
import DeliveryDateCell from './TableCells/DeliveryDateCell';
import UpdateDeliveryDates from './UpdateDeliveryDates';
import { AuthorityRole } from '../../../types/Authority.interface';
import useRole from '../../../hooks/useRole';
import useAnalytics from '../../../hooks/useAnalytics';

interface IBasketTableProps {
  basket: AvailabilityBasketEU;
  formMethods: UseFormMethods;
  hasError: boolean;
  reservationBooking: boolean;
  onUpdateBasket: () => void;
  onSubmitBasket: () => void;
  onSaveEnquiry: () => void;
  onCombineShipment: () => void;
}

const BasketTable: React.FunctionComponent<IBasketTableProps> = ({
  basket,
  formMethods,
  hasError,
  reservationBooking,
  onUpdateBasket,
  onSubmitBasket,
  onSaveEnquiry,
  onCombineShipment,
}) => {
  const { format } = useNumberFormatter();
  const { hasRole } = useRole();
  const queryCache = useQueryCache();
  const { trackPageView } = useAnalytics();

  const doRemoveEuBasketItem = async (id: number) => {
    if (basket) {
      trackPageView('BASKET', 'REMOVE_ITEM', {
        customerId: basket.soldTo.number,
      });
      await deleteAvailabilityItem(basket.id, id);
      queryCache.invalidateQueries(QueryCacheName.BASKET_ORDER_BOOKING);
    }
  };

  const commonColumns: ColumnsType<AvailabilityBasketItem> = [
    {
      title: t`Description`,
      key: 'description',
      width: !hasRole(AuthorityRole.ROLE_K_CODE_PRICES) ? '20%' : '15%',
      render: (value: unknown, item: AvailabilityBasketItem) => (
        <DescriptionCell item={item} />
      ),
    },
    {
      title: t`Characteristics`,
      key: 'characteristics',
      width: !hasRole(AuthorityRole.ROLE_K_CODE_PRICES) ? '20%' : '15%',
      render: (value: unknown, item: AvailabilityBasketItem, index) => (
        <CharacteristicsCell
          item={item}
          index={index}
          basketId={basket.id || 0}
          formMethods={formMethods}
        />
      ),
    },
    {
      title: t`Certification`,
      key: 'certification',
      width: !hasRole(AuthorityRole.ROLE_K_CODE_PRICES) ? '20%' : '15%',
      render: (value: unknown, item: AvailabilityBasketItem, index) => (
        <CertificationCell
          item={item}
          index={index}
          basketId={basket.id || 0}
          formMethods={formMethods}
        />
      ),
    },
    {
      title: t`Quantity`,
      key: 'quantity',
      width: !hasRole(AuthorityRole.ROLE_K_CODE_PRICES) ? '21%' : '15%',
      render: (value: unknown, item: AvailabilityBasketItem, index) => (
        <QuantityCell
          item={item}
          index={index}
          basketId={basket.id || 0}
          formMethods={formMethods}
        />
      ),
    },
  ];

  const deliveryDateColumn: ColumnsType<AvailabilityBasketItem> = [
    {
      title: t`Delivery Date`,
      key: 'deliveryDate',
      width: !hasRole(AuthorityRole.ROLE_K_CODE_PRICES) ? '19%' : '15%',
      render: (value: unknown, item: AvailabilityBasketItem, index) => (
        <DeliveryDateCell
          basket={basket}
          item={item}
          index={index}
          formMethods={formMethods}
          doRemoveEuBasketItem={doRemoveEuBasketItem}
        />
      ),
    },
  ];

  const allColumns: ColumnsType<AvailabilityBasketItem> = [
    ...commonColumns,
    {
      title: ``,
      key: 'noTitle',
      width: '6%',
      render: (value: unknown, item: AvailabilityBasketItem) => (
        <NoTitleCell item={item} />
      ),
    },
    {
      title: t`Article Price`,
      key: 'articlePrice',
      width: '6%',
      render: (value: unknown, item: AvailabilityBasketItem) => (
        <ArticlePriceCell item={item} />
      ),
    },
    {
      title: t`Invoice Price`,
      key: 'invoicePrice',
      width: '7%',
      render: (value: unknown, item: AvailabilityBasketItem) => (
        <InvoicePriceCell item={item} />
      ),
    },
    {
      title: t`Total Invoice Price`,
      key: 'totalInvoicePrice',
      width: '6%',
      render: (value: unknown, item: AvailabilityBasketItem) => (
        <div className="text-xl font-bold text-blue-corporate">
          {format(item.priceOrder)} {item.currency}
        </div>
      ),
    },
    ...deliveryDateColumn,
  ];

  return (
    <>
      <Table
        rowKey={(record: AvailabilityBasketItem) =>
          `${record.requestedMaterial}${record.confirmedDate}${record.guid}${record.material}`
        }
        columns={
          hasRole(AuthorityRole.ROLE_K_CODE_PRICES)
            ? allColumns
            : [...commonColumns, ...deliveryDateColumn]
        }
        dataSource={basket.items}
        pagination={false}
        className={styles.basketTable}
        summary={() => <BasketTableSummary basket={basket} />}
      />
      <div className="p-3 pt-8 text-right">
        {availabilityUpdateRequired(basket) ? (
          <UpdateDeliveryDates
            basket={basket}
            formMethods={formMethods}
            onUpdateBasket={onUpdateBasket}
          />
        ) : (
          <UpdateBasket
            basket={basket}
            formMethods={formMethods}
            hasError={hasError}
            reservationBooking={reservationBooking}
            onCombineShipment={onCombineShipment}
            onSaveEnquiry={onSaveEnquiry}
            onSubmitBasket={onSubmitBasket}
          />
        )}
      </div>
      {availabilityUpdateRequired(basket) && (
        <div className="text-right py-2 font-bold text-red-red">
          <Trans>
            An update is required to the order before it can be booked.
          </Trans>
        </div>
      )}
    </>
  );
};

export default BasketTable;
