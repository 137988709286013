import { startCase, toLower } from 'lodash';
import { useQuery } from 'react-query';
import { EMPTY_OPTION_SELECT } from '../../services/Util/emptyOption.const';
import Option from '../../types/Option.type';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { loadCalcCountries } from './Calculator.service';

const useCalculatorCountryOptions = (): {
  isFetching: boolean;
  countryOptions: Option[];
} => {
  const { data: countries = [], isFetching } = useQuery(
    QueryCacheName.CALC_COUNTRIES,
    () => loadCalcCountries(),
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const countryOptions: Option[] = [
    EMPTY_OPTION_SELECT,
    ...countries.map((country) => ({
      label: startCase(toLower(country.country)),
      value: country.country,
    })),
  ];
  return {
    isFetching,
    countryOptions,
  };
};

export default useCalculatorCountryOptions;
