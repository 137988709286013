import { t } from '@lingui/macro';
import React, { FC, useState } from 'react';
import Tabs, { TabPane } from '../../components/Tabs/Tabs';
import SearchCriteria from './SearchCriteria';
import ViewLabels from './ViewLabels';
import GetPrintableLabelsParams from './types/GetPrintableLabelsParams.type';
import PrintLabelsTab from './types/PrintLabelsTab.enum';

const PrintLabels: FC = () => {
  const [activeTab, setActiveTab] = useState<PrintLabelsTab>(
    PrintLabelsTab.SEARCH
  );
  const [resultsTabIsDisabled, setResultsTabIsDisabled] = useState<boolean>(
    false
  );
  const [searchParams, setSearchParams] = useState<GetPrintableLabelsParams>();

  const safelySetActiveTab = (key: string): void => {
    if (Object.values<string>(PrintLabelsTab).includes(key)) {
      setActiveTab(key as PrintLabelsTab);
    }
  };

  const doSearch = async (newSearchParams: GetPrintableLabelsParams) => {
    setSearchParams(newSearchParams);
    setResultsTabIsDisabled(false);
    setActiveTab(PrintLabelsTab.RESULTS);
  };

  const doReset = () => {
    setActiveTab(PrintLabelsTab.SEARCH);
    setResultsTabIsDisabled(true);
    setSearchParams(undefined);
  };

  return (
    <Tabs
      type="card"
      activeKey={activeTab}
      onChange={(key) => safelySetActiveTab(key)}
    >
      <TabPane tab={t`Enter Search Criteria`} key={PrintLabelsTab.SEARCH}>
        <SearchCriteria onSearch={doSearch} onReset={doReset} />
      </TabPane>
      <TabPane
        tab={t`View Labels`}
        key={PrintLabelsTab.RESULTS}
        disabled={resultsTabIsDisabled}
      >
        <ViewLabels searchParams={searchParams} />
      </TabPane>
    </Tabs>
  );
};

export default PrintLabels;
