import Axios from 'axios';
import { AreaPerSheet } from './AreaPerSheet/AreaPerSheet.interface';
import { Criteria as RollWeightAndLengthCriteria } from './RollWeightAndLength/NonRelease/Criteria.interface';
import { Criteria as SheetsToWeightCriteria } from './SheetsToWeight/Criteria.interface';
import { Criteria as SquareAreaWeightConversionCriteria } from './SquareAreaWeightConversion/Criteria.interface';
import { CalculatorCountry } from './types/CalculatorCountry.interface';
import CalculatorResult from './types/CalculatorResult.interface';
import { CalculatorUnitTypes } from './types/CalculatorUnitTypes.enum';
import GetEqUiElementsByTypeParams from './types/EqCalculator/GetEqUiElementsByTypeParams.interface';
import EqUiElement from './types/EqCalculator/EqUiElement.interface';
import GetEqUiElementByNameParams from './types/EqCalculator/GetEqUiElementByNameParams.interface';
import CalculateEqSavingsParams from './types/EqCalculator/CalculateEqSavingsParams.interface';
import CalculateEqSavingsResults from './types/EqCalculator/CalculateEqSavingsResults.interface';
import CalculatorResponse from './types/CalculatorResponse.interface';
import { PriceCWTParams } from './PriceCalculators/PriceCWT.interface';
import EqEnvImpactEquivalent from './types/EqCalculator/EqEnvImpactEquivalent.interface';
import { LinearLengthToArea } from './LinealLengthToArea/LinearLengthToArea.interface';
import PriceTonToPriceLinFootParams from './types/PriceCalculators/PriceTonToPriceLinFootParams.interface';
import PriceTonToPriceMsfParams from './types/PriceCalculators/PriceTonToPriceMsfParams.interface';
import PriceMsfToPriceTonParams from './types/PriceCalculators/PriceMsfToPriceTonParams.interface';
import PriceTonToPriceMetricTonParams from './types/PriceCalculators/PriceTonToPriceMetricTonParams.interface';
import CalculatePalletHeightAndWeightParams from './types/PalletHeightAndWeight/CalculatePalletHeightAndWeightParams.interface';
import CalculatePalletHeightAndWeightResults from './types/PalletHeightAndWeight/CalculatePalletHeightAndWeightResults.interface';
import { RollCalculatorResult } from './RollCalculator/types/RollCalculatorResult.interface';
import { RollForm } from './RollCalculator/types/RollForm.interface';
import { omitEmptyProperties } from '../../services/Util/omitEmptyProperties.util';

export const loadCalcCountries = async (): Promise<CalculatorCountry[]> => {
  const { data } = await Axios.get('/portal/resources/eu/reelcalccountries');
  return data;
};

export const calculateRoll = async (
  params: RollForm
): Promise<RollCalculatorResult> => {
  const { data } = await Axios.get('/portal/resources/eu/reelcalc', {
    params,
  });

  return data;
};

export const calculatePagesPerInch = async (
  caliper: number
): Promise<CalculatorResult> => {
  const { data } = await Axios.get(
    '/portal/resources/calculators/pagesPerInch',
    {
      params: {
        caliper,
        metImp: CalculatorUnitTypes.IMP,
      },
    }
  );
  return data;
};

export const getMWeight = async (
  baseWeight: string,
  metImp: string,
  sheetLengthImp: string,
  sheetWidthImp: string,
  tradeBasis: string
): Promise<CalculatorResponse> => {
  const { data } = await Axios.get(
    `/portal/resources/calculators/mWeight?baseWeight=${baseWeight}&metImp=${metImp}&sheetLengthImp=${sheetLengthImp}&sheetWidthImp=${sheetWidthImp}&tradeBasis=${tradeBasis}`
  );
  return data;
};

export const calculateSheetsToWeight = async (
  sheetsToWeightCriteria: SheetsToWeightCriteria
): Promise<CalculatorResult> => {
  const { data } = await Axios.get(
    `/portal/resources/calculators/sheetsToWeight`,
    {
      params: sheetsToWeightCriteria,
    }
  );
  return data;
};

export const calculateRollWeightAndLength = async (
  criteria: RollWeightAndLengthCriteria
): Promise<CalculatorResult> => {
  const { data } = await Axios.get(`/portal/resources/calculators/rollWeight`, {
    params: omitEmptyProperties(criteria),
  });
  return data;
};

export const getAvailableBrands = async (): Promise<string[]> => {
  const { data } = await Axios.get('/portal/resources/eqtooldata/brandNames');

  return data;
};

export const calculateEqSavings = async (
  params: CalculateEqSavingsParams
): Promise<CalculateEqSavingsResults> => {
  // The backend requires the brands parameter to be passed with this specific
  // format, which Axios cannot be configured on a per-request basis to provide,
  // so prior to making the request we must apply custom serialization to the
  // brands parameter.
  const transformedParams = {
    ...params,
    brands: JSON.stringify(params.brands).slice(1, -1),
  };

  const { data } = await Axios.get(
    '/portal/resources/eqtooldata/calculateEqSavings',
    {
      params: transformedParams,
    }
  );

  return data;
};

export const loadMillBrands = async (): Promise<void> => {
  await Axios.get('/portal/resources/eqtooldata/millBrands');
};

export const loadInterfaceElements = async (): Promise<void> => {
  await Axios.get('/portal/resources/eqtooldata/interfaceElements');
};

export const loadMillListings = async (): Promise<void> => {
  await Axios.get('/portal/resources/eqtooldata/millListings');
};

export const loadMillEfficiency = async (): Promise<void> => {
  console.log("loading mill efficiency");
  await Axios.get('/portal/resources/eqtooldata/millEfficiency');
};

export const loadCo2Equivalents = async (): Promise<void> => {
  await Axios.get('/portal/resources/eqtooldata/co2Equivalents');
};

export const getEqUiElementsByType = async (
  params: GetEqUiElementsByTypeParams
): Promise<EqUiElement[]> => {
  const { data } = await Axios.get(
    '/portal/resources/eqtooldata/interfaceElements/type',
    {
      params,
    }
  );

  return data;
};

export const getEqUiElementByName = async (
  params: GetEqUiElementByNameParams
): Promise<EqUiElement> => {
  const { data } = await Axios.get(
    '/portal/resources/eqtooldata/interfaceElements/name',
    {
      params,
    }
  );

  return data;
};

export const loadEqEnvImpactEquivImage = async (
  imageUri: string
): Promise<string> => {
  const loaded = await import(`../../assets/calculators/${imageUri}`);

  return loaded.default;
};

export const loadEqEnvImpactEquivImages = async (
  envImpactEquivs: EqEnvImpactEquivalent[]
): Promise<EqEnvImpactEquivalent[]> => {
  const equivsWithImageLoaded = envImpactEquivs.map(async (envImpactEquiv) => {
    const loadedImage = await loadEqEnvImpactEquivImage(envImpactEquiv.image);

    const equivWithImageLoaded: EqEnvImpactEquivalent = {
      ...envImpactEquiv,
      image: loadedImage,
    };

    return equivWithImageLoaded;
  });

  return Promise.all(equivsWithImageLoaded);
};

export const getSquareFootagePerSingleSheet = async (
  areaPerSheet: AreaPerSheet
): Promise<CalculatorResult> => {
  const { data } = await Axios.get(
    `/portal/resources/calculators/squareFootagePerSingleSheet`,
    {
      params: areaPerSheet,
    }
  );
  return data;
};

export const calculateWeightToMsfOrMsm = async (
  criteria: SquareAreaWeightConversionCriteria
): Promise<CalculatorResult> => {
  const { data } = await Axios.get(
    `/portal/resources/calculators/weightToMSF`,
    {
      params: criteria,
    }
  );
  return data;
};

export const calculateMsfOrMsmToWeight = async (
  criteria: SquareAreaWeightConversionCriteria
): Promise<CalculatorResult> => {
  const { data } = await Axios.get(
    `/portal/resources/calculators/msfToWeight`,
    {
      params: criteria,
    }
  );
  return data;
};

export const priceCwtCalculator = async (
  destination: string,
  queryParams: PriceCWTParams
): Promise<CalculatorResponse> => {
  const { data } = await Axios.get(
    `/portal/resources/calculators/${destination}`,
    {
      params: queryParams,
    }
  );
  return data;
};

export const getLengthToArea = async (
  linearLengthToArea: LinearLengthToArea
): Promise<CalculatorResponse> => {
  const { data } = await Axios.get(
    `/portal/resources/calculators/lengthToArea`,
    {
      params: linearLengthToArea,
    }
  );
  return data;
};

export const calculatePriceTonToPriceLinFoot = async (
  params: PriceTonToPriceLinFootParams
): Promise<CalculatorResponse> => {
  const { data } = await Axios.get(
    '/portal/resources/calculators/priceTonToPriceLinFoot',
    {
      params,
    }
  );
  return data;
};

export const calculatePriceTonToPriceMsf = async (
  params: PriceTonToPriceMsfParams
): Promise<CalculatorResponse> => {
  const { data } = await Axios.get(
    '/portal/resources/calculators/priceTonToPriceMSF',
    {
      params,
    }
  );
  return data;
};

export const calculatePriceMsfToPriceTon = async (
  params: PriceMsfToPriceTonParams
): Promise<CalculatorResponse> => {
  const { data } = await Axios.get(
    '/portal/resources/calculators/priceMSFToPriceTon',
    {
      params,
    }
  );
  return data;
};

export const calculatePriceTonToPriceMetricTon = async (
  params: PriceTonToPriceMetricTonParams
): Promise<CalculatorResponse> => {
  const { data } = await Axios.get(
    '/portal/resources/calculators/priceTonToPriceMetricTon',
    {
      params,
    }
  );
  return data;
};

export const calculatePalletHeightAndWeight = async (
  params: CalculatePalletHeightAndWeightParams
): Promise<CalculatePalletHeightAndWeightResults> => {
  const { data } = await Axios.post('/portal/resources/eu/palletcalc', params);
  return data;
};
