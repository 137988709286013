import Axios from 'axios';
import { isArray, isEqual, reduce, unionWith } from 'lodash';
import { SamplesSearchFilters } from '../../domain/Samples/OrderSamples/SamplesSearchFilters.interface';
import CancellablePromise from '../../types/CancellablePromise.interface';
import { SamplesOrder } from '../../types/Samples/SamplesOrder.interface';
import { SamplesProductOriginal } from '../../types/Samples/SamplesProductOrigin.interface';
import { SamplesOrderDeliveryCostDetails } from '../../types/Samples/SamplesOrderDeliveryCostDetails.interface';
import { SamplesOrderItemUpdateProperites } from '../../types/Samples/SamplesOrderItemUpdateProperites.interface';
import SamplesParameters from '../../types/Samples/SamplesParameters.interface';
import { SamplesProduct } from '../../types/Samples/SamplesProduct.interface';
import cancellableQuery from '../Util/cancellableQuery.util';
import { omitEmptyProperties } from '../Util/omitEmptyProperties.util';

export const getSamples = async (
  filters: SamplesSearchFilters
): Promise<SamplesProduct[]> => {
  const { data } = await Axios.get('/portal/resources/samples', {
    params: omitEmptyProperties(filters),
  });
  return data;
};

export const cancellableGetSamples = (
  key: string,
  query: SamplesSearchFilters
): CancellablePromise<SamplesProduct[]> => {
  return cancellableQuery(key, getSamples, query);
};

export const getSamplesOrder = async (): Promise<SamplesOrder> => {
  const { data } = await Axios.get('/portal/resources/samples/order');
  return data;
};

export const updateSamplesOrder = async (
  samplesOrder: SamplesOrder
): Promise<SamplesOrder> => {
  // TODO: Clear QueryCacheName.SAMPLES_ORDER and QueryCacheName.SAMPLES_ORDERS_HISTORY here (would need to use mutation hook).
  // Note: until we do the above, we should clear the query cache ourselves whenever we use this function.
  const { data } = await Axios.put(
    '/portal/resources/samples/order',
    samplesOrder
  );
  return data;
};

export const updateSamplesOrderItem = async (
  documentNumber: string,
  documentPosition: string,
  updateProperties: SamplesOrderItemUpdateProperites
): Promise<void> => {
  // TODO: Clear QueryCacheName.SAMPLES_ORDER and QueryCacheName.SAMPLES_ORDERS_HISTORY here (would need to use mutation hook).
  // Note: until we do the above, we should clear the query cache ourselves whenever we use this function.
  const { data } = await Axios.post(
    `/portal/resources/samples/order/${documentNumber}/${documentPosition}`,
    updateProperties
  );
  return data;
};

export const deleteSamplesOrderItem = async (
  documentNumber: string,
  documentPosition: string
): Promise<void> => {
  // TODO: Clear QueryCacheName.SAMPLES_ORDER and QueryCacheName.SAMPLES_ORDERS_HISTORY here (would need to use mutation hook).
  // Note: until we do the above, we should clear the query cache ourselves whenever we use this function.
  const { data } = await Axios.delete(
    `/portal/resources/samples/order/${documentNumber}/${documentPosition}`
  );
  return data;
};

export const createSamplesOrder = async (
  order: SamplesOrder
): Promise<SamplesOrder> => {
  // TODO: Clear QueryCacheName.SAMPLES_ORDER and QueryCacheName.SAMPLES_ORDERS_HISTORY here (would need to use mutation hook).
  // Note: until we do the above, we should clear the query cache ourselves whenever we use this function.
  const { data } = await Axios.post('/portal/resources/samples/order', order);
  return data;
};

export const addItemToSamplesOrder = async (
  item: SamplesProduct
): Promise<SamplesProduct> => {
  // TODO: Clear QueryCacheName.SAMPLES_ORDER and QueryCacheName.SAMPLES_ORDERS_HISTORY here (would need to use mutation hook).
  // Note: until we do the above, we should clear the query cache ourselves whenever we use this function.
  const { data } = await Axios.post(
    '/portal/resources/samples/order/item',
    item
  );
  return data;
};

export const addItemPerDocumentNumber = async (
  documentNumber: string,
  order: SamplesProductOriginal
): Promise<SamplesProductOriginal> => {
  // TODO: Clear QueryCacheName.SAMPLES_ORDER here (would need to use mutation hook).
  // Note: until we do the above, we should clear the query cache ourselves whenever we use this function.
  const { data } = await Axios.put(
    `/portal/resources/samples/order/${documentNumber}`,
    order
  );
  return data;
};

const callGetSamplesParameters = async (
  filters: SamplesSearchFilters
): Promise<SamplesParameters> => {
  const { data } = await Axios.get('/portal/resources/samples/parameters', {
    params: omitEmptyProperties(filters),
  });
  return data;
};

const EMPTY_SAMPLES = {
  brandFamilies: [],
  brands: [],
  grammages: [],
  group: [],
  sizes: [],
  surfaces: [],
  types: [],
};

const combineData = (
  parameterDataArray: SamplesParameters[]
): SamplesParameters => {
  const combinedParameters = reduce(parameterDataArray, (prev, curr) => {
    return {
      brandFamilies: unionWith(prev.brandFamilies, curr.brandFamilies, isEqual),
      brands: unionWith(prev.brands, curr.brands, isEqual),
      grammages: unionWith(prev.grammages, curr.grammages, isEqual),
      group: unionWith(prev.group, curr.group, isEqual),
      sizes: unionWith(prev.sizes, curr.sizes, isEqual),
      surfaces: unionWith(prev.surfaces, curr.surfaces, isEqual),
      types: unionWith(prev.types, curr.types, isEqual),
    };
  });
  return combinedParameters || EMPTY_SAMPLES;
};

export const getSamplesParameters = async (
  _key: string,
  filters: SamplesSearchFilters
): Promise<SamplesParameters> => {
  let samplesParameters;
  if (isArray(filters.productGroup)) {
    const promises = filters.productGroup.map(async (group) =>
      callGetSamplesParameters({ ...filters, productGroup: group })
    );
    const data = await Promise.all(promises);
    samplesParameters = combineData(data);
  } else {
    samplesParameters = await callGetSamplesParameters(filters);
  }
  return samplesParameters;
};

export const getDeliveryCostInformationForOrder = async (): Promise<
  SamplesOrderDeliveryCostDetails
> => {
  const { data } = await Axios.get(`/portal/resources/samples/order/delivery`);
  return data;
};

export const deleteSampleFromBasket = async (
  id: number | null | undefined
): Promise<SamplesProduct> => {
  const { data } = await Axios.delete(
    `/portal/resources/samples/order/item/${id}`
  );
  return data;
};

interface SamplesOrderResponse {
  documentNumber: string;
  responseCode?: string;
  responseMessage?: string;
  failedOrderReference?: string;
}

export const placeSamplesOrder = async (
  order: SamplesOrder
): Promise<SamplesOrderResponse> => {
  // TODO: update this to invalidate QueryCacheName.SAMPLES_ORDERS_HISTORY and QueryCacheName.SAMPLES_ORDERS_HISTORY cache
  // Note: until we do the above, we should clear the query cache ourselves whenever we use this function.
  const { data } = await Axios.post(
    `/portal/resources/samples/order/place`,
    order
  );
  return data;
};
