import { Trans } from '@lingui/macro';
import { Collapse } from 'antd';
import * as React from 'react';
import ExpandIcon from '../../../components/ExpandIcon';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { FinancialPeriod } from '../../../types/FinancialPeriod.interface';
import AccountActivityTable from './AccountActivityTable';

interface IFinancialReportingOverdueProps {
  customerNumber: string;
  totalOverdue: number;
  lessThan1WeekPeriod: FinancialPeriod;
  between1and4WeekPeriod: FinancialPeriod;
  moreThan4WeekPeriod: FinancialPeriod;
}
const { Panel } = Collapse;
const FinancialReportingOverdue: React.FunctionComponent<IFinancialReportingOverdueProps> = ({
  customerNumber,
  totalOverdue,
  lessThan1WeekPeriod,
  between1and4WeekPeriod,
  moreThan4WeekPeriod,
}) => {
  const { format } = useNumberFormatter();
  return (
    <>
      <h4 className="text-xl font-bold mb-2">
        <Trans>Overdue</Trans>
      </h4>
      <Collapse
        className="sappi-collapse-custom-collapse"
        accordion
        bordered={false}
        expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
        destroyInactivePanel
      >
        <Panel
          header={<Trans>More than 4 weeks overdue</Trans>}
          key="1"
          extra={
            <div>
              {format(moreThan4WeekPeriod.amount)}{' '}
              {moreThan4WeekPeriod.currency}
            </div>
          }
          className="sappi-collapse-custom-summary-collapse"
        >
          <AccountActivityTable
            period={moreThan4WeekPeriod}
            customerNumber={customerNumber}
          />
        </Panel>
        <Panel
          header={<Trans>Between 1 and 4 weeks overdue</Trans>}
          key="2"
          extra={
            <div>
              {format(between1and4WeekPeriod.amount)}{' '}
              {between1and4WeekPeriod.currency}
            </div>
          }
          className="sappi-collapse-custom-summary-collapse"
        >
          <AccountActivityTable
            period={between1and4WeekPeriod}
            customerNumber={customerNumber}
          />
        </Panel>
        <Panel
          header={<Trans>Less than 1 week overdue</Trans>}
          key="3"
          extra={
            <div>
              {format(lessThan1WeekPeriod.amount)}{' '}
              {lessThan1WeekPeriod.currency}
            </div>
          }
          className="sappi-collapse-custom-summary-collapse"
        >
          <AccountActivityTable
            period={lessThan1WeekPeriod}
            customerNumber={customerNumber}
          />
        </Panel>
      </Collapse>
      <div className="text-right mt-7 text-lg mr-5">
        <span>
          <Trans>TOTAL Overdue</Trans>:&nbsp;
        </span>
        <strong>
          {format(totalOverdue)} {moreThan4WeekPeriod.currency}
        </strong>
      </div>
    </>
  );
};

export default FinancialReportingOverdue;
