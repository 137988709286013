import React, { FunctionComponent } from 'react';
import { t, Trans } from '@lingui/macro';
import LabelValue from '../../components/LabelValue/LabelValue';
import Date from '../../components/Date';
import {
  getComment,
  getReturnMessage,
  getTotalClaimValue,
} from './FileClaimConfirmation.util';
import { Order } from '../../types/Order.interface';
import ExistingComplaint from '../../types/ExistingComplaint.interface';
import { ComplaintAttachmentResponse } from '../../types/ComplaintAttachmentResponse.interface';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import useCustomer from '../../hooks/useCustomer';
import stripZeros from '../../services/Util/stripZeros.util';
import padZeros from '../../services/Util/padZeros.util';

interface IFileClaimConfirmationHeaderProps {
  order?: Order;
  complaint?: ExistingComplaint;
  attachments?: ComplaintAttachmentResponse;
  fileNames?: string[];
  complaintNumber: string;
}

const FileClaimConfirmationHeader: FunctionComponent<IFileClaimConfirmationHeaderProps> = ({
  order,
  complaint,
  attachments,
  fileNames,
  complaintNumber,
}) => {
  const { getCustomerByNumber } = useCustomer();
  const { format } = useNumberFormatter();
  const customer = getCustomerByNumber(order?.customerNumber || '');
  const returnMessage = getReturnMessage(complaintNumber, complaint);
  const complaintMessage = getComment(padZeros(complaintNumber, 10), complaint);

  return (
    <>
      <div className="py-10">
        <div className="flex py-2">
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Customer Reference`}
              value={returnMessage?.customer}
            />
          </div>
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Claim Value`}
              value={`${format(returnMessage?.claimValue)} ${
                returnMessage?.claimCurrency
              }`}
            />
          </div>
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Customer Name`}
              value={customer?.name || order?.customerNumber}
            />
          </div>
        </div>
        <div className="flex py-2">
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Claim Type`}
              value={returnMessage?.claimType}
            />
          </div>
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Additional Claim Value`}
              value={`${format(returnMessage?.additionalClaimValue)} ${
                returnMessage?.additionalClaimCurrency
              }`}
            />
          </div>
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Delivery Location`}
              value={`${order?.overview.shipToPartyName}/${order?.overview.shipToPartyCity}`}
            />
          </div>
        </div>
        <div className="flex py-2">
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Order Date`}
              value={<Date date={order?.createDate || 0} />}
            />
          </div>
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Total Claim Value`}
              value={`${format(
                getTotalClaimValue(
                  returnMessage?.claimValue,
                  returnMessage?.additionalClaimValue
                )
              )} ${returnMessage?.additionalClaimCurrency}`}
            />
          </div>
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Requested Delivery Date`}
              value={<Date date={order?.requestedDeliveryDate || 0} />}
            />
          </div>
        </div>
        <div className="flex py-2">
        <div className="w-1/3">
          <LabelValue
            inline
            label={t`Affected Items`}
            value=""
          /><br/>
          {complaint?.complaintItems && complaint.complaintItems.filter(complaintItem => stripZeros(complaintItem.complaintNo) === complaintNumber).map((complaintItem) => 
            <div className="font-bold">
              {complaintItem.item}
            </div>
          )}
        </div>
          <div className="w-1/3">
            <LabelValue
              inline
              label={t`Number of Files Attached`}
              value={attachments?.numberOfAttachments}
            /><br/>
            {fileNames?.map((fileName) => (
              <div className="font-bold" key={`${fileName}${Math.random()}`}>
                {fileName}
              </div>
            ))}
          </div>
          <div className="w-1/3">
            <Trans>
              Please send all physical evidence samples to the following
              address:
            </Trans><br/><br/>
            <div className="font-bold">
              {order?.billToAddressLine1}<br/>
              {order?.billToAddressLine2}<br/>
              {order?.billToAddressLine3}<br/>
              {order?.billToAddressLine4}
            </div>
          </div>
          </div>
          <div className="flex py-2">
          <div className="w-1/3">
            <Trans>
              Description:
            </Trans><br/><br/>
            <div className="font-bold">
            {complaintMessage?.map((message) => (
              <div key={`${Math.random()}`}>
                {message}
              </div>
            ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileClaimConfirmationHeader;
