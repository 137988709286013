import { t } from '@lingui/macro';
import Axios from 'axios';
import { ConsignmentInventoryItem } from '../../domain/Consignment/types/ConsignmentInventoryItem.interface';
import ConsignmentStatusCode from '../../domain/Consignment/types/ConsignmentStatusCode.enum';
import Brand from '../../types/Brand.interface';
import { Link } from '../../types/Link.interface';
import { PageInfo } from '../../types/PageInfo.interface';
import parsePageInfoString from '../Util/parsePageInfoString.util';

export interface IGetConsignmentInventoryParams {
  soldToCustomerNumber: string;
  customerPurchaseOrder?: string;
  custMatSku?: string;
  documentNumber?: string;
  inStock?: boolean;
  inTransit?: boolean;
  onOrder?: boolean;
  invoiced?: boolean;
  selectedBrand?: Brand;
  salesGrade?: string;
  caliper?: string;
  caliperMils?: string;
  widthMin?: string;
  widthMax?: string;
  widthMinInches?: string;
  widthMaxInches?: string;
  olderThan?: string;
  productType?: string;
  bookweight?: string;
  grammage?: string;
  lengthMin?: string;
  lengthMax?: string;
  lengthMinInches?: string;
  lengthMaxInches?: string;
  invoiceNumber?: string;
  invoicedDateFrom?: string;
  invoicedDateTo?: string;
  page?: number;
  all?: boolean;
}

export interface IConsignmentInventoryResults {
  consignmentInventory: {
    results: ConsignmentInventoryItem[];
    links: Link[];
  };
  paginationInfo: Partial<PageInfo>;
}

export const getConsignmentInventory = async (
  queryParams: IGetConsignmentInventoryParams
): Promise<IConsignmentInventoryResults> => {
  const { data, headers } = await Axios.get('/portal/resources/consignment', {
    params: queryParams,
  });
  const paginationInfo: Partial<PageInfo> = {
    page: parsePageInfoString(headers['page-info-page']),
    pageCount: parsePageInfoString(headers['page-info-pages']),
    recordCount: parsePageInfoString(headers['page-info-total']),
  };
  return {
    consignmentInventory: data,
    paginationInfo,
  };
};

export const getConsignmentStatusFromCode = (
  code?: string | null,
  translated = false
): string | null => {
  switch (code) {
    case ConsignmentStatusCode.OO:
      return translated ? t`On Order` : 'On Order';
    case ConsignmentStatusCode.IT:
      return translated ? t`In Transit` : 'In Transit';
    case ConsignmentStatusCode.AL:
      return translated ? t`In Stock` : 'In Stock';
    case ConsignmentStatusCode.IV:
      return translated ? t`Invoiced` : 'Invoiced';
    default:
      return null;
  }
};
