import React, { FC } from 'react';
import useLocale from '../../hooks/useLocale';
import useLoggedInUserCheck from '../../hooks/useLoggedInUserCheck';
import PromoPosition from '../../types/PromoPosition.enum';
import User from '../../types/User/User.interface';
import LoginPromo from '../Promo/LoginPromo';
import styles from './Login.module.scss';
import LoginForm from './LoginForm';
import LoginHeader from './LoginHeader';
import LoginNotification from './LoginNotification';

interface ILoginProps {
  onLoginSuccess: (data: User) => void;
}

const Login: FC<ILoginProps> = ({ onLoginSuccess }) => {
  const { language } = useLocale();
  useLoggedInUserCheck();
  return (
    <div>
      <LoginNotification lang={language} />
      <LoginHeader />
      <div className={styles.main}>
        <LoginForm onLoginSuccess={onLoginSuccess} />
      </div>
      <div
        className="grid justify-between gap-6"
        style={{ gridTemplateColumns: 'repeat(3, minmax(198px, 410px))' }}
      >
        <LoginPromo position={PromoPosition.LOGIN_LEFT} lang={language} />
        <LoginPromo position={PromoPosition.LOGIN_CENTER} lang={language} />
        <LoginPromo position={PromoPosition.LOGIN_RIGHT} lang={language} />
      </div>
    </div>
  );
};

export default Login;
