import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import PackingListResponse from './types/PackingListResponse.interface';
import LabelValue from '../../components/LabelValue/LabelValue';
import Table from '../../components/Table/Table';
import styles from './PackingListReportingTable.module.scss';
import Container from './types/Container.interface';
import stripZeros from '../../services/Util/stripZeros.util';

interface IPackingListReportingTableProps {
  results: PackingListResponse | null;
}

const PackingListReportingTable: FC<IPackingListReportingTableProps> = ({
  results,
}) => {
  const getRowKey = (record: Container): string => {
    return (
      record.billingDocument +
      record.blNumber +
      record.containerNumber +
      record.coreDiameter +
      record.customerPurchaseNumber +
      record.diameter +
      record.gradeDesc +
      record.grammage +
      record.grossKg +
      record.grossLb +
      record.ifraCode +
      record.itemNumber +
      record.length +
      record.materialNumber +
      record.salesOrderNumber +
      record.sheetGrainDirection +
      record.sheetsPerPallet +
      record.sheetsPerReam +
      record.shipName +
      record.theoWeight +
      record.width
    );
  };

  const columns: ColumnsType<Container> = [
    {
      title: <Trans>Order</Trans>,
      key: 'order',
      width: '27%',
      render: (value, record) => (
        <div>
          {record.customerPurchaseNumber && (
            <LabelValue
              label={t`My Order #`}
              value={record.customerPurchaseNumber}
              noBold
              inline
            />
          )}
          {(stripZeros(record.salesOrderNumber) ||
            stripZeros(record.itemNumber)) && (
            <LabelValue
              label={t`Sappi Order #`}
              value={`${stripZeros(record.salesOrderNumber)}/${stripZeros(
                record.itemNumber
              )}`}
              noBold
              inline
            />
          )}
        </div>
      ),
    },
    {
      title: <Trans>Shipping Details</Trans>,
      key: 'shippingDetails',
      width: '27%',
      render: (value, record) => (
        <div>
          {record.shipName && (
            <LabelValue
              label={t`Carrier Name`}
              value={record.shipName}
              noBold
              inline
            />
          )}
          {record.containerNumber && (
            <LabelValue
              label={t`Container Number`}
              value={record.containerNumber}
              noBold
              inline
            />
          )}
          {record.grossKg && (
            <LabelValue
              label={t`Weight (KG)`}
              value={record.grossKg}
              noBold
              inline
            />
          )}
          {record.grossLb && (
            <LabelValue
              label={t`Weight (LB)`}
              value={record.grossLb}
              noBold
              inline
            />
          )}
          {record.ifraCode && (
            <LabelValue
              label={t`Pallet / Roll ID`}
              value={record.ifraCode}
              noBold
              inline
            />
          )}
        </div>
      ),
    },
    {
      title: <Trans>Product Details</Trans>,
      key: 'productDetails',
      width: '46%',
      render: (value, record) => (
        <> {record.gradeDesc && <div>{record.gradeDesc}</div>}</>
      ),
    },
  ];
  return (
    <Table
      className={`mt-2 ${styles.table}`}
      columns={columns}
      rowKey={(record) => getRowKey(record)}
      dataSource={results?.containers}
      pagination={false}
    />
  );
};

export default PackingListReportingTable;
