import { t } from '@lingui/macro';
import React, { FC, useState } from 'react';
import HeaderCalculatorsModal from './HeaderCalculatorsModal';
import useAnalytics from '../../../hooks/useAnalytics';

const HeaderCalculators: FC = () => {
  const { trackPageView } = useAnalytics();
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const screenReaderText = modalIsVisible
    ? t`Close calculators modal.`
    : t`Open calculators modal.`;

  const toggleModalVisibility = () => {
    trackPageView('MODAL_CALCULATOR', 'TOGGLE_VISIBILITY');
    setModalIsVisible((currentValue) => !currentValue);
  };

  return (
    <div>
      <button onClick={toggleModalVisibility} aria-expanded={modalIsVisible}>
        <span className="sr-only">{screenReaderText}</span>
        <i className="fa fa-calculator" />
      </button>
      <HeaderCalculatorsModal
        visible={modalIsVisible}
        onCancel={() => {
          setModalIsVisible(false);
        }}
      />
    </div>
  );
};

export default HeaderCalculators;
