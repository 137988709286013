import { t } from '@lingui/macro';
import Option from '../../types/Option.type';
import DateRangeSelection from './types/DateRangeSelection.enum';

export const DATE_RANGE_OPTIONS: Option[] = [
  {
    value: DateRangeSelection.LAST_WEEK,
    label: t`Last Week`,
  },
  {
    value: DateRangeSelection.PAST_MONTH,
    label: t`Past 1 Months`,
  },
  {
    value: DateRangeSelection.PAST_3_MONTHS,
    label: t`Past 3 Months`,
  },
  {
    value: DateRangeSelection.PAST_6_MONTHS,
    label: t`Past 6 Months`,
  },
];
