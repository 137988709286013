import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { Output } from '../types/Output.interface';
import { UtilizationRow } from '../types/UtilizationRow.interface';
import styles from './UtilizationViewTable.module.scss';
import { mapOutputRowsToUtilizationRows } from './UtilizationViewTable.util';

interface IUtilizationViewProps {
  outputRows: Output[];
  onContainerSelect: (containerNumber: number) => void;
}

const UtilizationViewTable: React.FunctionComponent<IUtilizationViewProps> = ({
  outputRows,
  onContainerSelect,
}) => {
  const { format } = useNumberFormatter();
  const [selectedRow, setSelectedRow] = useState<number | undefined>(0);
  const utilizationRows = mapOutputRowsToUtilizationRows(outputRows);

  const onRowSelect = (
    record: UtilizationRow,
    rowIndex: number | undefined
  ) => {
    setSelectedRow(rowIndex);
    onContainerSelect(record.containerNumber);
  };

  // reset selected row whenever tab changes
  useEffect(() => {
    setSelectedRow(0);
  }, [outputRows]);

  const columns: ColumnsType<UtilizationRow> = [
    {
      title: <Trans>Container #</Trans>,
      key: 'containerNumber',
      render: (value, record) => `${t`Container`} ${record.containerNumber}`,
    },
    {
      title: <Trans>Units / Weight</Trans>,
      key: 'unitsWeights',
      render: (value, record) =>
        `${format(record.units)} / ${format(record.weight)} kg`,
    },
    {
      title: <Trans>Utilized / Full</Trans>,
      key: 'utilized',
      render: (value, record) =>
        `${record.utilized}% / ${record.full ? t`Yes` : t`No`}`,
    },
    {
      title: <Trans>Description</Trans>,
      key: 'description',
      dataIndex: 'description',
    },
  ];
  return (
    <Table
      rowKey="containerNumber"
      className={styles.utilizationViewTable}
      rowClassName={(record, index) =>
        selectedRow === index ? styles.selectedRow : ''
      }
      columns={columns}
      dataSource={utilizationRows}
      pagination={false}
      onRow={(record, rowIndex) => ({
        onClick: () => onRowSelect(record, rowIndex),
      })}
    />
  );
};

export default UtilizationViewTable;
