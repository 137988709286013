import { t, Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { useForm, ValidateResult } from 'react-hook-form';
import { Space } from 'antd';
import { useQuery } from 'react-query';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Radio from '../../../components/Radio/Radio';
import CalculateEqSavingsParams from '../types/EqCalculator/CalculateEqSavingsParams.interface';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import EqCalculatorFormData from '../types/EqCalculator/EqCalculatorFormData.interface';
import EqUnits from '../types/EqCalculator/EqUnits.enum';
import {
  EQ_CALC_BRAND_INPUT_PREFIX,
  getParamsFromFormData,
} from './EqCalculatorInputs.util';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { getAvailableBrands } from '../Calculator.service';
import useValidator from '../../../hooks/useValidator';
import InputWithHiddenLabel from '../../../components/InputWithHiddenLabel/InputWithHiddenLabel';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';

interface EqCalculatorInputsProps {
  onCalculate: (params: CalculateEqSavingsParams) => void;
  onReset: () => void;
}

const EqCalculatorInputs: FC<EqCalculatorInputsProps> = ({
  onCalculate,
  onReset,
}) => {
  const {
    errors,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    trigger,
  } = useForm({
    mode: 'all',
  });
  const { isValid } = formState;
  const { validateStringIsNum } = useValidator();

  const { data: availableBrands, isFetching } = useQuery(
    [QueryCacheName.EQ_AVAILABLE_BRANDS],
    getAvailableBrands,
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  const doCalculate = (data: EqCalculatorFormData): void => {
    const params = getParamsFromFormData(data);

    onCalculate(params);
  };

  const requireAtLeastOneBrand = (): ValidateResult => {
    const values = getValues();

    const atLeastOneBrand = Object.entries(values).some((entry) => {
      const inputName = entry[0];
      const inputValue = entry[1];

      return inputName.startsWith(EQ_CALC_BRAND_INPUT_PREFIX) && inputValue;
    });

    return atLeastOneBrand
      ? true
      : t`Please enter a value for at least one brand`;
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.EQ}
      onCalculate={handleSubmit(doCalculate)}
      isCalculateDisabled={!isValid}
      onReset={() => {
        reset();
        onReset();
      }}
      error={
        isValid
          ? null
          : t`Please enter a report title, unit, and at least one brand.`
      }
    >
      <Space direction="vertical" size="middle">
        <InputWithHiddenLabel
          name="title"
          label={t`Report Title`}
          ref={register({ required: true })}
          required
        />
        <fieldset>
          <legend className="font-bold">
            <Trans>Units</Trans>:
          </legend>
          <Space size="middle">
            <Radio
              name="units"
              value={EqUnits.TON}
              label={
                <span className="font-bold">
                  <Trans>Tons</Trans>
                </span>
              }
              ref={register({
                required: true,
              })}
            />
            {/* <Radio
                name="units"
                value={EqUnits.LB}
                label={
                  <span className="font-bold">
                  <Trans>Lbs</Trans>
                </span>
                }
                ref={register({
                  required: true,
                })}
            /> */}
          </Space>
        </fieldset>
        <fieldset>
          <legend className="mb-3 font-bold">
            <Trans>Enter weight for at least one paper grade.</Trans>
          </legend>
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <div className="flex flex-wrap -my-2">
              {(availableBrands || []).map((brand) => {
                const inputName = `${EQ_CALC_BRAND_INPUT_PREFIX}${brand}`;
                const hasValidNumError =
                  errors?.[inputName]?.type === 'validNum';

                return (
                  <div key={brand} className="w-1/2 my-2">
                    <InputWithHiddenLabel
                      label={brand}
                      name={inputName}
                      ref={register({
                        validate: {
                          atLeastOneBrand: requireAtLeastOneBrand,
                          validNum: validateStringIsNum,
                        },
                      })}
                      error={hasValidNumError ? errors[inputName] : undefined}
                      errorMessageDisplay={{
                        icon: false,
                        belowInput: false,
                        tooltip: true,
                      }}
                      onChange={() => trigger()}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </fieldset>
      </Space>
    </CalculatorTemplate>
  );
};

export default EqCalculatorInputs;
