import { Trans } from '@lingui/macro';
import { Pagination, Space } from 'antd';
import React, { FC } from 'react';
import { Button } from '../Button/Button';

export interface IDeconstructedTablePaginationProps {
  pageCount: number;
  currentPage: number;
  recordCount: number;
  isShowingAll: boolean;
  onChangePage: (newPage: number) => void;
  onToggleShowingAll: () => void;
  className?: string;
}

const DeconstructedTablePagination: FC<IDeconstructedTablePaginationProps> = ({
  pageCount,
  currentPage,
  recordCount,
  isShowingAll,
  onChangePage,
  onToggleShowingAll,
  className = '',
}) => {
  const positiveNumRecordsAndPages = recordCount > 0 && pageCount > 0;

  if (!positiveNumRecordsAndPages) {
    return <></>;
  }

  const recordsPerPage = recordCount / pageCount;

  return (
    <Space size="large" className={className}>
      <Button theme="link" onClick={onToggleShowingAll}>
        {isShowingAll ? <Trans>Page Results</Trans> : <Trans>Show All</Trans>}
      </Button>
      <Pagination
        current={isShowingAll ? 1 : currentPage}
        pageSize={isShowingAll ? recordCount : recordsPerPage}
        onChange={onChangePage}
        total={recordCount}
        showSizeChanger={false}
      />
    </Space>
  );
};

export default DeconstructedTablePagination;
