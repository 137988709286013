import { t } from '@lingui/macro';
import React, { FC } from 'react';
import Date from '../../../../components/Date';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import useRole from '../../../../hooks/useRole';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';

interface IOrderDetailsCellProps {
  basketItem: AvailabilityBasketItem;
}

const OrderDetailsCell: FC<IOrderDetailsCellProps> = ({ basketItem }) => {
  const { isNaUser } = useRole();
  return (
    <>
      {isNaUser && (
        <>
          <LabelValue
            inverse
            inline
            label={t`Customer Material Number #`}
            value={basketItem.customerMaterial || t`None`}
          />
          {basketItem.requestedDate && (
            <LabelValue
              inverse
              inline
              label={t`Requested Delivery Date`}
              value={<Date date={basketItem.requestedDate} />}
            />
          )}
          <LabelValue
            inverse
            inline
            label={t`Last Date of Revision Needed`}
            value={basketItem.ldr ? t`Yes` : t`No`}
          />
        </>
      )}
    </>
  );
};

export default OrderDetailsCell;
