import { Trans } from '@lingui/macro';
import * as React from 'react';
import { Button } from '../../../components/Button/Button';

interface IDummyButtonsProps {
  onNext?: () => void;
  onNextDisabled?: boolean;
  onPrev?: () => void;
  onPrevDisabled?: boolean;
}

const DummyButtons: React.FunctionComponent<IDummyButtonsProps> = ({
  onNext,
  onNextDisabled,
  onPrev,
  onPrevDisabled,
}) => {
  return (
    <div
      className={`my-5 ${
        onPrev ? 'flex justify-between w-full' : 'align-right'
      }`}
    >
      {onPrev && (
        <Button onClick={() => onPrev()} disabled={onPrevDisabled}>
          <Trans>Previous Step</Trans>
        </Button>
      )}
      {onNext && (
        <Button
          theme="primary"
          onClick={() => onNext()}
          disabled={onNextDisabled}
        >
          <Trans>Next Step</Trans>
        </Button>
      )}
    </div>
  );
};

export default DummyButtons;
