import React, { FC, useState } from 'react';
import useHelp from './useHelp';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import styles from './Help.module.scss';
import HelpMenu from './HelpMenu';

const Help: FC = () => {
  const { helps, isFetching } = useHelp();
  const [helpBody, setHelpBody] = useState<string>();

  return (
    <div style={{ minWidth: '985px' }}>
      {isFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        <div className="flex ml-6">
          <HelpMenu helps={helps || []} setHelpBody={setHelpBody} />
          <div className="w-3/4">
            {/* eslint-disable react/no-danger */}
            {helpBody && (
              <div
                dangerouslySetInnerHTML={{ __html: helpBody }}
                className={`pl-4 text-lg ${styles.helpContent}`}
              />
            )}
            {/* eslint-enable react/no-danger */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Help;
