import * as React from 'react';

interface IOrderDetailsModalDetailRowProps {
  label: string | React.ReactNode;
  value?: string | React.ReactNode;
}

const OrderDetailsModalDetailRow: React.FunctionComponent<IOrderDetailsModalDetailRowProps> = ({
  label,
  value,
}) => {
  return (
    <div className="flex w-full">
      <dt className="w-1/2 mr-3 text-right">{label}</dt>
      <dd className="font-bold">{value}</dd>
    </div>
  );
};

export default OrderDetailsModalDetailRow;
