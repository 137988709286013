import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

const ReasonCodeMapping: FC = () => {
  return (
    <>
      <div>
        <Trans>Reason Code Mappings:</Trans>
      </div>
      <ul>
        <li>
          <Trans>01: Bad weather conditions</Trans>
        </li>
        <li>
          <Trans>02: Damaged at unloading</Trans>
        </li>
        <li>
          <Trans>03: Damaged in transit</Trans>
        </li>
        <li>
          <Trans>05: Driver ill</Trans>
        </li>
        <li>
          <Trans>06: Ferry delayed</Trans>
        </li>
        <li>
          <Trans>07: Involved in accident</Trans>
        </li>
        <li>
          <Trans>08: Lack of driving hours</Trans>
        </li>
        <li>
          <Trans>09: Long unloading at previous drop</Trans>
        </li>
        <li>
          <Trans>10: Traffic jam</Trans>
        </li>
        <li>
          <Trans>11: Truck out of order</Trans>
        </li>
        <li>
          <Trans>12: Long loading time</Trans>
        </li>
        <li>
          <Trans>13: Duty problems</Trans>
        </li>
        <li>
          <Trans>14: Will be delivered early</Trans>
        </li>
        <li>
          <Trans>
            15: Sales requested different delivery date after loading of goods
          </Trans>
        </li>
        <li>
          <Trans>
            16: Merchant requested different delivery date after loading of
            goods
          </Trans>
        </li>
        <li>
          <Trans>
            17: Ship-to party requested different delivery date after loading of
            goods
          </Trans>
        </li>
        <li>
          <Trans>18: Availability at warehouse</Trans>
        </li>
        <li>
          <Trans>19: Inspection by police</Trans>
        </li>
        <li>
          <Trans>20: Wrong loading at shipping point</Trans>
        </li>
        <li>
          <Trans>
            21: Transp. planner req. different del. date after loading
          </Trans>
        </li>
        <li>
          <Trans>22: Riots</Trans>
        </li>
        <li>
          <Trans>23: Natural disaster</Trans>
        </li>
        <li>
          <Trans>24: Unpredictable Strike</Trans>
        </li>
        <li>
          <Trans>25: St.of War/Emergency</Trans>
        </li>
        <li>
          <Trans>26: Border of ship to country closed</Trans>
        </li>
        <li>
          <Trans>27: Sappi missed to send dispatch (Aviso)</Trans>
        </li>
        <li>
          <Trans>28: Wrong loading / unloading due to bad labels</Trans>
        </li>
        <li>
          <Trans>29: Postponed by Mill Planner</Trans>
        </li>
      </ul>
    </>
  );
};

export default ReasonCodeMapping;
