import { Trans } from '@lingui/macro';
import React from 'react';
import stripZeros from '../../../../services/Util/stripZeros.util';
import '../OrderDetailsTable.scss';
import { TrackOrder } from '../../../../types/TrackOrder.interface';

interface IOrderCell {
  order: TrackOrder;
  loading?: boolean;
}

const OrderCell: React.FunctionComponent<IOrderCell> = ({ order }) => {
  return (
    <>
      {order.customerOrderNumber && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="My Order #">My Order #</Trans>
          </dt>
          <dd className="font-bold">{order.customerOrderNumber}</dd>
        </dl>
      )}
      {(order.documentNumber || order.documentPosition) && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="Sappi Order #">Sappi Order #</Trans>
          </dt>
          <dd className="font-bold">
            {stripZeros(order.documentNumber)}/
            {stripZeros(order.documentPosition)}
          </dd>
        </dl>
      )}
      {order.overview.shipTpPoNumber && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="consigneeOrder">Ship To PO #</Trans>
          </dt>
          <dd className="font-bold">{order.overview.shipTpPoNumber}</dd>
        </dl>
      )}
      {order.overview.orderTypeDesc && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderType">Order Type</Trans>
          </dt>
          <dd className="font-bold">{order.overview.orderTypeDesc}</dd>
        </dl>
      )}
    </>
  );
};

export default OrderCell;
