import { t } from '@lingui/macro';
import * as React from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import { ProductAvailabilityResult } from '../../../../types/ProductAvailabilityResult.interface';
import PutUpCategory from '../../../../types/PutUpCategory.enum';

interface ICharacteristicsCellProps {
  record: ProductAvailabilityResult;
}

const CharacteristicsCell: React.FunctionComponent<ICharacteristicsCellProps> = ({
  record,
}) => {
  return (
    <>
      <LabelValue
        inverse
        inline
        label={t`Wrapping`}
        value={t`${record.txtWrappingType}${record.reelWrappingType}`}
      />
      <div>
        {record.productType !== PutUpCategory.SHEET && (
          <>
            <LabelValue
              inverse
              inline
              label={t`Roll Diameter`}
              value={t`${record.reelDiameter}`}
            />
            <LabelValue
              inverse
              inline
              label={t`Roll Width`}
              value={t`${record.reelWidth}`}
            />
            <LabelValue
              inverse
              inline
              label={t`Core Diameter`}
              value={t`${record.coreDiameter}`}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CharacteristicsCell;
