import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import DeliveryDetailsFormField from './types/DeliveryDetailsFormFields.enum';

interface INewDeliveryAddressProps {
  register: UseFormMethods['register'];
}

const NewDeliveryAddress: FC<INewDeliveryAddressProps> = ({ register }) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Input
        name={DeliveryDetailsFormField.SHIP_TO_NAME}
        ref={register({ required: true })}
        placeholder={t`Company Name`}
        maxLength={35}
        required
      />
      <Input
        name={DeliveryDetailsFormField.SHIP_TO_ADDRESS}
        ref={register({ required: true })}
        placeholder={t`Address`}
        maxLength={25}
        required
      />
      <Input
        name={DeliveryDetailsFormField.SHIP_TO_CITY}
        ref={register({ required: true })}
        placeholder={t`City`}
        maxLength={35}
        required
      />
      <Input
        name={DeliveryDetailsFormField.SHIP_TO_COUNTRY_CODE}
        ref={register({ required: true })}
        placeholder={t`Country Code`}
        maxLength={3}
        required
      />
      <Input
        name={DeliveryDetailsFormField.SHIP_TO_POSTAL_CODE}
        ref={register({ required: true })}
        placeholder={t`Postal Code`}
        maxLength={10}
        required
      />
    </Space>
  );
};

export default NewDeliveryAddress;
