import { Trans } from '@lingui/macro';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import SamplesProductType from '../../../../types/Samples/SamplesProductType.enum';
import SamplesProductGroup from '../../OrderSamples/SamplesProductGroup.enum';

interface IProductCellProps {
  isInternalUser: boolean;
  product: SamplesProduct;
  updating?: boolean;
  register: UseFormMethods['register'];
  index: number;
  readonly?: boolean;
}

const ProductCell: React.FunctionComponent<IProductCellProps> = ({
  isInternalUser,
  product,
  updating,
  register,
  index,
  readonly,
}) => {
  const showCustomBrand = () =>
    (isInternalUser &&
      product.productGroup === SamplesProductGroup.PUBLIC &&
      product.type === SamplesProductType.UNPRINTED) ||
    (product.productGroup === SamplesProductGroup.COMM &&
      product.brandFamily === 'OTHSAP') ||
    product.brandFamily === 'OTHMOL';
  return (
    <>
      <div>{product.brandFamily}</div>
      {showCustomBrand() && (
        <span>
          <label>
            <Trans>Custom Brand Name</Trans>:
          </label>
          <br />
          {readonly ? (
            <>{product.customBrand}</>
          ) : (
            <Input
              name={`customBrand[${index}]`}
              defaultValue={product.customBrand as string}
              ref={register({ maxLength: 40 })}
              disabled={updating}
            />
          )}
        </span>
      )}
    </>
  );
};

export default ProductCell;
