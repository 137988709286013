import * as React from 'react';

const DeconstructedTableBody: React.FunctionComponent<React.HTMLProps<
  HTMLTableSectionElement
>> = ({ className, children, ...rest }) => {
  const baseClass = 'ant-table-tbody';
  return (
    <tbody className={`${baseClass} ${className || ''}`} {...rest}>
      {children}
    </tbody>
  );
};

export default DeconstructedTableBody;
