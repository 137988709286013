import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import { ConsumeRequest } from '../../types/ConsumeRequest.interface';

export const convertSelectedItemsToPoRequest = (
  selectedItems: boolean[],
  allItems: ConsignmentInventoryItem[],
  shipToName: string,
  poNumber?: string
): ConsumeRequest => {
  const firstRow = allItems[0];
  return {
    barcodes: selectedItems
      .map((checked: boolean, index: number) => 
        checked ? allItems[index].ifraCode || allItems[index].nariNumber : false
      )
      .filter((value) => !!value) as string[],
    soldToCustomer: firstRow.customerNumber,
    shipToName,
    documentLineNumber: firstRow.documentPosition || '',
    documentNumber: firstRow.documentNumber || '',
    purchaseOrder: poNumber || firstRow.customerPurchaseOder || '',
  };
};

export const getSelectedInventory = (
  selectedItems: boolean[],
  allItems: ConsignmentInventoryItem[],
  poNumber?: string
): ConsignmentInventoryItem | null => {
  const selectedInventory: ConsignmentInventoryItem[] = [];
  selectedItems.forEach((checked: boolean, index: number) => {
    if (checked) {
      selectedInventory.push({
        ...allItems[index],
        customerPurchaseOder: poNumber || allItems[index].customerPurchaseOder,
      });
    }
  });
  return selectedInventory.length
    ? {
        ...selectedInventory[0],
        details: selectedInventory,
      }
    : null;
};
