import { Trans } from '@lingui/macro';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Select from '../../../components/Select/Select';
import useRole from '../../../hooks/useRole';
import { getUnitOfMeasuresForProductAvailability } from '../../../services/Product';
import fractionOrDecimalInput from '../../../services/Util/fractionOrDecimalInput.util';
import { AuthorityRole } from '../../../types/Authority.interface';
import ButtonTheme from '../../../types/ButtonTheme';
import Option from '../../../types/Option.type';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import { IMP_REG_EX } from '../../../types/RegularExpression.constants';
import { SelectOption } from '../../../types/SelectOption';
import styles from './AddItemToBasket.module.scss';

export interface AvailabilityForm {
  selectedMeasureUnit: string;
  quantity: number;
}
interface IAddItemToBasket {
  productType: string;
  onAdd: (availabilityData: AvailabilityForm) => void;
  addingToBasket?: boolean;
}

const AddItemToBasket: React.FunctionComponent<IAddItemToBasket> = ({
  productType,
  onAdd,
  addingToBasket,
}) => {
  const { hasRole, isReleaseUser } = useRole();

  const { register, handleSubmit, errors, watch, reset } = useForm({
    mode: 'all',
  });
  const quantityWatch = watch('quantity');
  const selectedMeasureUnitWatch = watch('selectedMeasureUnit');
  const [buttonTheme, setButtonTheme] = useState<ButtonTheme>(
    ButtonTheme.inverse
  );
  const [uomOptions, setUomOptions] = useState<Option[]>([]);

  useEffect(() => {
    setButtonTheme(
      quantityWatch && !errors.quantity
        ? ButtonTheme.primary
        : ButtonTheme.inverse
    );
  }, [errors.quantity, quantityWatch]);

  useEffect(() => {
    let unitOptions = [];
    if (productType && !isReleaseUser) {
      unitOptions = getUnitOfMeasuresForProductAvailability(
        productType === PutUpCategory.ROLL
          ? PutUpCategory.ROLL
          : PutUpCategory.SHEET
      ).map((key) => ({
        label: key,
        value: key,
      }));
    } else {
      unitOptions = getUnitOfMeasuresForProductAvailability(
        PutUpCategory.ROLL,
        true
      ).map((key) => ({
        label: key,
        value: key,
      }));
    }
    setUomOptions(unitOptions);
  }, [isReleaseUser, productType]);

  useEffect(() => {
    if (!addingToBasket) {
      reset();
    }
  }, [addingToBasket, reset]);
  const onSubmit = handleSubmit((availabilityData: AvailabilityForm) => {
    onAdd(availabilityData);
  });

  return (
    <>
      {hasRole(AuthorityRole.ROLE_ORDER_BOOKING) && (
        <form onSubmit={onSubmit}>
          <div className="flex justify-end -mr-5">
            <div className="w-1/7 mb-5 mt-5">
              <InputLabel
                size="lg"
                labelClassName="justify-end mr-12"
                text={<Trans>Unit of Measure</Trans>}
              >
                <Select
                  required
                  name="selectedMeasureUnit"
                  options={uomOptions}
                  ref={register}
                />
              </InputLabel>
            </div>
          </div>
          <div className="flex items-center justify-end mr-6 mt-1 mb-6">
            <div className="w-1/8">
              <Input
                solidOutline
                className={`text-right ${styles.resultStockInput}`}
                name="quantity"
                type="number"
                min="0"
                step="any"
                width="max"
                placeholder="Quantity"
                onKeyPress={fractionOrDecimalInput}
                ref={register({
                  pattern: IMP_REG_EX,
                })}
                error={errors.quantity}
              />
            </div>
            <div className="text-lg leading-8">
              <Button
                className="h-14"
                type="submit"
                theme={buttonTheme}
                disabled={
                  selectedMeasureUnitWatch === SelectOption.Select ||
                  buttonTheme === ButtonTheme.inverse
                }
              >
                <div className="flex items-center">
                  <Trans>Add to basket</Trans>
                  {addingToBasket && <LoadingSpinner className="ml-1" />}
                </div>
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default AddItemToBasket;
