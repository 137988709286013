import * as React from 'react';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';

interface ITableBrandCellProps {
  item: ConsignmentInventoryItem;
}

const TableBrandCell: React.FunctionComponent<ITableBrandCellProps> = ({
  item,
}) => {
  return (
    <>
      {item.details[0].brand} {item.bookweight} {item.tradeBasis}
    </>
  );
};

export default TableBrandCell;
