import React, { useState } from 'react';
import { usePaginatedQuery, useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useSamplesOrder from '../../../hooks/useSamplesOrder';
import { getPartnerSearchCustomerByNumber } from '../../../services/Customer';
import { placeSamplesOrder } from '../../../services/SamplesOrder';
import { PartnerSearchCustomer } from '../../../types/PartnerSearchCustomer.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import ItemsTable from '../Basket/ItemsTable/ItemsTable';
import ShippingInstructions from '../Basket/ShippingInstructions/ShippingInstructions';
import OrderSummaryButtons from './OrderSummaryButtons';
import useSamplesOrderPlacedStore from './useSamplesOrderPlacedStore.store';

interface IOrderSummaryProps {
  prop?: unknown;
}

const OrderSummary: React.FunctionComponent<IOrderSummaryProps> = (props) => {
  const queryCache = useQueryCache();
  const { data: order } = useSamplesOrder();
  const history = useHistory();
  const { resolvedData: buyer } = usePaginatedQuery<PartnerSearchCustomer>(
    [QueryCacheName.PARTNER_SEARCH_CUSTOMER, order?.buyerNumber],
    getPartnerSearchCustomerByNumber,
    {
      staleTime: Infinity,
      enabled: order?.buyerNumber,
      keepPreviousData: true,
    }
  );
  const [placingOrder, setPlacingOrder] = useState(false);

  const placeOrder = async () => {
    if (order) {
      try {
        setPlacingOrder(true);
        const response = await placeSamplesOrder(order);
        // TODO: clear dummy state?
        if (response.documentNumber) {
          history.push(`/samples/ordered/${response.documentNumber}`);
        } else if (response.failedOrderReference) {
          useSamplesOrderPlacedStore.setState({
            errorMessage: response.responseMessage,
          });
          history.push(
            `/samples/ordered/error/${response.failedOrderReference}`
          );
        }
        queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDERS_HISTORY);
        setPlacingOrder(false);
      } catch (error) {
        console.error(`Error saving order info: ${error}`);
      }
    }
  };

  return (
    <div className="mb-8">
      <ShippingInstructions order={order} buyer={buyer} readonly />
      <div className="mb-8 mt-3">
        <ItemsTable
          items={order?.products || []}
          buyerName={buyer?.name}
          readonly
        />
      </div>
      {placingOrder ? (
        <LoadingSpinner horizontal />
      ) : (
        <OrderSummaryButtons onPlaceOrder={placeOrder} />
      )}
    </div>
  );
};

export default OrderSummary;
