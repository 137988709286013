import { isValidSappiSkuInput } from '../../../services/SappiSku';

export const skuCheckboxPrefix = 'addSku';

export const getSkusToAddFromFormData = (
  formData: Record<string, unknown>
): string[] => {
  const sappiSkusToAdd: string[] = [];
  const inputEntries = Object.entries(formData);

  inputEntries.forEach((inputEntry) => {
    const [inputName, inputValue] = inputEntry;
    if (inputName.startsWith(skuCheckboxPrefix) && inputValue === true) {
      const dePrefixed = inputName.slice(skuCheckboxPrefix.length);
      if (isValidSappiSkuInput(dePrefixed)) {
        sappiSkusToAdd.push(dePrefixed);
      }
    }
  });

  return sappiSkusToAdd;
};

export const getCheckedBoxCount = (form: Record<string, unknown>): number => {
  let checkedBoxCount = 0;
  const entries = Object.entries(form);
  entries.map((e) => {
    if (e[1]) {
      checkedBoxCount++;
    }
    return e;
  });

  return checkedBoxCount;
};

export const getCheckedBoxCountWithoutKCode = (
  form: Record<string, unknown>,
  kcode: string
): number => {
  let checkedBoxCount = 0;
  const entries = Object.entries(form);
  entries.map((e) => {
    if (e[1] && !e[0].includes(kcode)) {
      checkedBoxCount++;
    }
    return e;
  });

  return checkedBoxCount;
};
