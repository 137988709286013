import { t } from '@lingui/macro';
import { Space } from 'antd';
import { some } from 'lodash';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';
import {
  METRIC_NUMBER_ONE_DECIMAL_REG_EX,
  METRIC_NUMBER_REG_EX,
} from '../../../types/RegularExpression.constants';

const MIN_REQUIRED = 0.1;
const MAX_REQUIRED = 9999.9;
interface ICriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  watch: UseFormMethods['watch'];
  getValues: UseFormMethods['getValues'];
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  calculated: boolean;
}

const Criteria: React.FunctionComponent<ICriteriaProps> = ({
  register,
  errors,
  watch,
  getValues,
  onInputChange,
  calculated,
}) => {
  const grammage = watch('grammage');
  const cover = watch('cover');
  const tag = watch('tag');
  const text = watch('text');

  const oneInputFilled = () => some(getValues(), (value) => !!value);
  return (
    <Space direction="vertical" size="middle" className="w-full pr-12">
      <InputLabel text={t`grammage (gsm)`} size="lg" className="-mt-4">
        <Input
          name="grammage"
          type="number"
          ref={register({
            min: MIN_REQUIRED,
            max: MAX_REQUIRED,
            pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.grammage}
          disabled={(cover || tag || text) && !calculated}
          onChange={onInputChange}
          width="full"
        />
      </InputLabel>
      <InputLabel text={t`cover (20" x 26")`} size="lg">
        <Input
          name="cover"
          type="number"
          ref={register({
            min: MIN_REQUIRED,
            max: MAX_REQUIRED,
            pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.cover}
          disabled={(grammage || tag || text) && !calculated}
          onChange={onInputChange}
          width="full"
        />
      </InputLabel>
      <InputLabel text={t`tag (24" x 36")`} size="lg">
        <Input
          name="tag"
          type="number"
          ref={register({
            min: MIN_REQUIRED,
            max: MAX_REQUIRED,
            pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.tag}
          onChange={onInputChange}
          disabled={(grammage || cover || text) && !calculated}
          width="full"
        />
      </InputLabel>
      <InputLabel text={t`text (25" x 38")`} size="lg">
        <Input
          name="text"
          type="number"
          ref={register({
            min: MIN_REQUIRED,
            max: MAX_REQUIRED,
            pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.text}
          disabled={(grammage || tag || cover) && !calculated}
          onChange={onInputChange}
          width="full"
        />
      </InputLabel>
    </Space>
  );
};

export default Criteria;
