import React, { FunctionComponent } from 'react';
import { t, Trans } from '@lingui/macro';
import { useHistory, useParams } from 'react-router-dom';
import { usePaginatedQuery, useQuery } from 'react-query';
import { Button } from '../../components/Button/Button';
import LabelValue from '../../components/LabelValue/LabelValue';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { getOrderDetails } from '../../services/Order';
import {
  getComplaintAttachments,
  getExistingComplaint,
} from '../../services/Complaints';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { 
  getComment, 
  getReturnMessage 
} from './FileClaimConfirmation.util';
import FileClaimConfirmationHeader from './FileClaimConfirmationHeader';
import FileClaimTable from './FileClaimTable';

const FileClaimConfirmation: FunctionComponent = () => {
  const { documentNumber, documentPosition, complaintNumber } = useParams<{
    documentNumber: string;
    documentPosition: string;
    complaintNumber: string;
  }>();
  const history = useHistory();

  const {
    resolvedData: order,
    isFetching: isOrderFetching,
  } = usePaginatedQuery(
    [QueryCacheName.ORDER_DETAILS, documentNumber, documentPosition],
    (key, number: string, position: string) =>
      getOrderDetails(number, position),
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: documentNumber && documentPosition,
      keepPreviousData: true,
    }
  );

  const {
    resolvedData: complaint,
    isFetching: isComplaintFetching,
  } = usePaginatedQuery(
    [QueryCacheName.EXISTING_COMPLAINT, documentNumber, documentPosition],
    (key, number: string, position: string) =>
      getExistingComplaint(number, position),
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: documentNumber && documentPosition,
      keepPreviousData: true,
    }
  );

  const { data: attachments, isFetching: isAttachmentsFetching } = useQuery(
    [QueryCacheName.COMPLAINTS_ATTACHMENTS, complaintNumber],
    (key, number: string) => getComplaintAttachments(number),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      enabled: complaintNumber,
    }
  );

  const fileNames = attachments?.attachments.map(
    (attachment) => `${attachment.description}.${attachment.documentClassCode}`
  );
  
  const returnMessage = getReturnMessage(complaintNumber, complaint);

  return (
    <>
      {isOrderFetching || isComplaintFetching || isAttachmentsFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        <>
          <div className="mb-3" style={{ height: '52px' }}>
            <Button
              type="submit"
              theme="primary"
              className="float-left"
              style={{ height: '30px' }}
              onClick={() => {
                history.push(`/orderTracking`);
              }}
            >
              <Trans>Back to Order Tracking</Trans>
            </Button>
            <Button
              className="reset-button float-right"
              style={{ height: '30px' }}
              onClick={() => window.print()}
            >
              <Trans>Print Claim</Trans>
            </Button>
          </div>
          <div className="text-4xl py-1">
            <Trans>
              Your claim has been transmitted to Sappi successfully.
            </Trans>
          </div>
          <div className="text-xl font-bold py-2">
            <Trans>Claim #</Trans> {complaintNumber}
          </div>
          <hr />
          <FileClaimConfirmationHeader
            order={order}
            complaint={complaint}
            attachments={attachments}
            fileNames={fileNames}
            complaintNumber={complaintNumber}
          />
          <hr />
          <div className="py-5">
            <LabelValue
              label={t`Problem Description`}
              inline
              value={`${returnMessage?.problemCode}/
              ${returnMessage?.problemDescription}`}
            />
          </div>
          <hr />
          <div className="py-3" />
          {order && <FileClaimTable results={[order]} />}
        </>
      )}
    </>
  );
};

export default FileClaimConfirmation;
