import React, { FC } from 'react';
import Customer from '../../../../types/Customer.interface';

interface ICustomerMessageProps {
  customer?: Customer;
}

const CustomerAddress: FC<ICustomerMessageProps> = ({ customer }) => {
  return (
    <>
      {customer?.number}
      <br />
      {customer?.name}
      <br />
      {customer?.address}
      <br />
      {customer?.city}, {customer?.postalCode} <br />
      {customer?.country}
    </>
  );
};

export default CustomerAddress;
