import { t } from '@lingui/macro';
import { useMemo } from 'react';
import { CALCULATOR_TAB_LABELS } from '../domain/Calculators/types/Calculator.constants';
import EuCalculatorsTab from '../domain/Calculators/types/EuCalculatorsTab.enum';
import NaOrReleaseCalculatorsTab from '../domain/Calculators/types/NaOrReleaseCalculatorsTab.enum';
import useDashboardCustomizationAccess from '../domain/Dashboard/hooks/useDashboardCustomizationAccess';
import QuickLinkId from '../domain/Dashboard/types/QuickLinkId.enum';
import QuickLinkInfo from '../domain/Dashboard/types/QuickLinkInfo.interface';
import safelyParseJsonToStringArray from '../services/Util/safelyParseJsonToStringArray.util';
import { AuthorityRole } from '../types/Authority.interface';
import useUserCustomization from './useUserCustomization';

export const ALL_QUICK_LINKS: QuickLinkInfo[] = [
  {
    id: QuickLinkId.CALCULATORS_CONVERTERS,
    label: t`Conversion Calculators`,
    url: '#/calculators?type=converters',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_NA, AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.CALCULATORS_EQ,
    label: CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.EQ_CALCULATORS],
    url: '#/calculators?type=eqtools',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_NA],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.CALCULATORS_METRIC_CONVERSIONS,
    label: CALCULATOR_TAB_LABELS[EuCalculatorsTab.METRIC_CONVERSIONS],
    url: '#/calculators?type=metric-conversions',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_EU],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.CALCULATORS_PALLET,
    label: t`Pallet Height & Weight Calculator`,
    url: '#/calculators?type=pallet',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_EU],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.CALCULATORS_PAPER_MATH,
    label: t`Paper Math Calculator`,
    url: '#/calculators?type=paper-math',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_EU],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.CALCULATORS_PAYLOAD,
    label: t`Payload Calculator`,
    url: '#/calculators?type=payload',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_EU],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.CALCULATORS_PRICE,
    label: CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.PRICE_CALCULATORS],
    url: '#/calculators?type=price',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_NA, AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.CALCULATORS_REEL,
    label: t`Roll Calculator`,
    url: '#/calculators?type=reel',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_EU],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.CALCULATORS_ROLL,
    label: CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.ROLL_CALCULATORS],
    url: '#/calculators?type=roll',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_NA, AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.CALCULATORS_SHEET,
    label: CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.SHEET_CALCULATORS],
    url: '#/calculators?type=sheet',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_NA, AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.COMPLAINT_REPORTS,
    label: t`Claims Reporting`,
    url: '#/reporting/claimReport',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_COMPLAINT_REQUEST],
  },
  {
    id: QuickLinkId.CONSIGNMENT_REPORTING,
    label: t`Consignment Reporting`,
    url: '#/consignment',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_CONSIGNMENT],
  },
  {
    id: QuickLinkId.CONSIGNMENT_CONSUMPTION,
    label: t`Consignment Consumption`,
    url: '#/consignmentConsumption',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_CONSIGN_SUBMIT],
  },
  {
    id: QuickLinkId.FINANCIAL_REPORTS,
    label: t`Financial Reporting`,
    url: '#/reporting/financialReport',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_FINANCIAL_INFO],
  },
  {
    id: QuickLinkId.MILL_DIRECT,
    label: t`View All Open Orders`,
    url: '#/millDirect/search',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_MILL_DIRECT],
  },
  {
    id: QuickLinkId.ORDER_BOOKING,
    label: t`Order Booking`,
    url: '#/orderBooking',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_DIRECT_BOOKING],
    requiredRoles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_DIRECT_BOOKING],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.ORDER_SAMPLES,
    label: t`Samples`,
    url: '#/samples/findProducts',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_SAMPLES],
    requiredRoles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_SAMPLES],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.PACKING_LIST_REPORTING,
    label: t`Packing List Reporting`,
    url: '#/packingList',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_CONTAINER_LIST],
  },
  {
    id: QuickLinkId.POLICIES,
    label: t`Policies & Conditions`,
    url: '#/policies',
    target: '_self',
    allowedRoles: Object.values(AuthorityRole),
  },
  {
    id: QuickLinkId.PRINT_DOCUMENTS,
    label: t`Print Documents`,
    url: '#/documentPrinter_',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_DOCUMENT_PRINTER],
  },
  {
    id: QuickLinkId.PRINT_LABELS,
    label: t`Print Labels`,
    url: '#/labelPrinter',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_LABEL_PRINTER],
    requiredRoles: [AuthorityRole.ROLE_EU, AuthorityRole.ROLE_LABEL_PRINTER],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.PRODUCT_AVAILABILITY_NA,
    label: t`Product Availability`,
    url: '#/availabilityPlanning',
    target: '_self',
    allowedRoles: [
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
      AuthorityRole.ROLE_NA,
      AuthorityRole.ROLE_RELEASE,
    ],
    requiredRoles: [
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
      AuthorityRole.ROLE_NA,
    ],
  },
  {
    id: QuickLinkId.PRODUCT_AVAILABILITY_NA,
    label: t`Product Availability`,
    url: '#/availabilityPlanning',
    target: '_self',
    allowedRoles: [
      AuthorityRole.ROLE_EU,
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
      AuthorityRole.ROLE_RELEASE,
    ],
    requiredRoles: [
      AuthorityRole.ROLE_EU,
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
    ],
  },
  {
    id: QuickLinkId.QUICK_ORDER,
    label: t`Quick Order`,
    url: '#/quickOrder',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_QOB],
  },
  {
    id: QuickLinkId.SAVED_ENQUIRIES,
    label: t`My Saved Enquiries`,
    url: '#/savedenquiries',
    target: '_self',
    allowedRoles: [
      AuthorityRole.ROLE_EU,
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
      AuthorityRole.ROLE_DIRECT_BOOKING,
    ],
    requiredRoles: [
      AuthorityRole.ROLE_EU,
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
      AuthorityRole.ROLE_DIRECT_BOOKING,
    ],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  // TODO: Will be deleted till the sap work is done to enable metric values in NA prod availability
  // {
  //   id: QuickLinkId.STOCK_AVAILABILITY_EU,
  //   label: t`Stock Availability`,
  //   url: '#/stockAvailability',
  //   target: '_self',
  //   allowedRoles: [
  //     AuthorityRole.ROLE_STOCK_AVAILABILITY,
  //     AuthorityRole.ROLE_EU,
  //   ],
  //   requiredRoles: [
  //     AuthorityRole.ROLE_STOCK_AVAILABILITY,
  //     AuthorityRole.ROLE_EU,
  //   ],
  //   excludedRoles: [AuthorityRole.ROLE_RELEASE],
  // },
  {
    id: QuickLinkId.STOCK_BOOK,
    label: t`Stock Book`,
    url: 'https://www.sappi.com/files/sappi-north-america-stock-bookpdf',
    target: '_blank',
    allowedRoles: [
      AuthorityRole.ROLE_NA,
      AuthorityRole.ROLE_STOCK_VIEW,
      AuthorityRole.ROLE_STOCK_REPORT,
    ],
    requiredRoles: [
      AuthorityRole.ROLE_NA,
      AuthorityRole.ROLE_STOCK_VIEW,
      AuthorityRole.ROLE_STOCK_REPORT,
    ],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.STOCK_BOOK_REL,
    label: t`Stock Book`,
    url: 'https://www.sappi.com/textures',
    target: '_blank',
    allowedRoles: [
      AuthorityRole.ROLE_STOCK_VIEW,
      AuthorityRole.ROLE_STOCK_REPORT,
      AuthorityRole.ROLE_RELEASE,
    ],
    requiredRoles: [
      AuthorityRole.ROLE_STOCK_VIEW,
      AuthorityRole.ROLE_STOCK_REPORT,
      AuthorityRole.ROLE_RELEASE,
    ],
  },
  {
    id: QuickLinkId.STOCK_LOT,
    label: t`Stock Lot`,
    url: '#/stockLot',
    target: '_self',
    allowedRoles: [
      AuthorityRole.ROLE_EU,
      AuthorityRole.ROLE_STOCK_LOT,
    ],
    requiredRoles: [
      AuthorityRole.ROLE_EU,
      AuthorityRole.ROLE_STOCK_LOT,
    ],
    excludedRoles: [AuthorityRole.ROLE_RELEASE],
  },
  {
    id: QuickLinkId.TRACK_BY_LABEL,
    label: t`Track By Label ID`,
    url: '#/trackByLabel',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_TRACK_BY],
  },
  {
    id: QuickLinkId.TRACK_ORDERS,
    label: t`Track Orders`,
    url: '#/orderTracking',
    target: '_self',
    allowedRoles: [AuthorityRole.ROLE_ORDER_TRACKING],
  },
].sort((a, b) => a.label.localeCompare(b.label));

const useQuickLinks = (): {
  allQuickLinks: QuickLinkInfo[];
  authorizedQuickLinks: QuickLinkInfo[];
  enabledQuickLinks: QuickLinkInfo[] | undefined;
  enabledQuickLinkIds: QuickLinkId[] | undefined;
} => {
  const { userHasAccessToItem } = useDashboardCustomizationAccess();
  const { data: userCustomizationSettings } = useUserCustomization();

  const authorizedQuickLinks = useMemo(
    () => ALL_QUICK_LINKS.filter((quickLink) => userHasAccessToItem(quickLink)),
    [userHasAccessToItem]
  );

  const { enabledQuickLinks, enabledQuickLinkIds } = useMemo(() => {
    const quickLinksSettings = safelyParseJsonToStringArray(
      userCustomizationSettings?.links
    );

    const enabled = quickLinksSettings
      ? authorizedQuickLinks.filter((quickLink) =>
          quickLinksSettings.includes(quickLink.id)
        )
      : undefined;

    const enabledIds = enabled?.map((quickLink) => quickLink.id);

    return {
      enabledQuickLinks: enabled,
      enabledQuickLinkIds: enabledIds,
    };
  }, [userCustomizationSettings, authorizedQuickLinks]);

  return {
    allQuickLinks: ALL_QUICK_LINKS,
    authorizedQuickLinks,
    enabledQuickLinks,
    enabledQuickLinkIds,
  };
};

export default useQuickLinks;
