enum DeliveryDetailsFormField {
  SELECTED_SHIP_TO = 'selectedShipTo',
  SOLD_TO_PO = 'soldToPO',
  SHIP_TO_NAME = 'shipToName',
  SHIP_TO_ADDRESS = 'shipToAddress',
  SHIP_TO_CITY = 'shipToCity',
  SHIP_TO_COUNTRY_CODE = 'shipToCountryCode',
  SHIP_TO_POSTAL_CODE = 'shipToPostalCode',
  SHIP_TO_PO = 'shipToPO',
  CONTACT_NAME = 'contactName',
  PHONE = 'phone',
  EMAIL = 'email',
  RECEIVING = 'receiving',
  DELIVERY_INSTRUCTIONS = 'deliveryInstructions',
  ORDER_TYPE = 'orderType',
  CONTACT_NOTES = 'dedicatedContactNotes',
}

export default DeliveryDetailsFormField;
