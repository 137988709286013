import * as React from 'react';

interface IFirstColumnCellProps {
  isChildRow: boolean;
}

const FirstColumnCell: React.FunctionComponent<IFirstColumnCellProps> = ({
  isChildRow,
  children,
}) => {
  return (
    <div>
      {isChildRow ? (
        <div className="flex relative justify-center">
          <div>
            <i className="fa fa-rotate-90 fa-level-up-alt mr-3" />
            {children}
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default FirstColumnCell;
