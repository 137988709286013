import create from 'zustand';
import { OpenOrdersResults } from '../../types/OpenOrdersResults.interface';

export const defaultOpenOrdersResults: OpenOrdersResults = {
  links: [],
  millTotals: {},
  results: [],
};

type OpenOrderState = {
  visible: boolean;
  customerNum: string;
  setPopoverVisible: (visible: boolean) => void;
  setCustomerNum: (customerNum: string) => void;
};

const useOpenOrdersStore = create<OpenOrderState>((set) => ({
  visible: false,
  customerNum: '',
  setPopoverVisible: (visible) => set({ visible }),
  setCustomerNum: (customerNum) => set({ customerNum }),
}));

export default useOpenOrdersStore;
