import { t } from '@lingui/macro';
import React, { FC } from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';

interface IPlannedProductionDescriptionCellProps {
  basketItem: AvailabilityBasketItem;
}

const PlannedProductionDescriptionCell: FC<IPlannedProductionDescriptionCellProps> = ({
  basketItem,
}) => {
  const isUnitPallet = basketItem.requestedUnitOfMeasure === UnitOfMeasure.PL;
  const isUnitSheet = basketItem.requestedUnitOfMeasure === UnitOfMeasure.SH;
  const isRoll = basketItem.productType === PutUpCategory.ROLL;
  const isSheet = basketItem.productType === PutUpCategory.SHEET;
  return (
    <>
      <div className="font-bold">
        {basketItem.material} {basketItem.tradeBasis}
      </div>
      {basketItem.finish && (
        <LabelValue
          inline
          inverse
          label={t`Finish`}
          value={basketItem.finish}
        />
      )}
      {basketItem.bookweight && (
        <LabelValue
          inline
          inverse
          label={t`Basis Weight`}
          value={basketItem.bookweight}
        />
      )}
      {basketItem.sheetsPerPalletNa && (
        <LabelValue
          inline
          inverse
          label={t`Basis Weight`}
          value={stripZeros(String(basketItem.sheetsPerPalletNa))}
        />
      )}
      {basketItem.stacksPerPallet && (isUnitSheet || isUnitPallet) && (
        <LabelValue
          inline
          inverse
          label={t`ST/Pallet`}
          value={basketItem.stacksPerPallet}
        />
      )}
      {isRoll && (
        <>
          {basketItem.reelWidthImp && (
            <LabelValue
              inline
              inverse
              label={t`Roll Width`}
              value={basketItem.reelWidthImp}
            />
          )}
          {basketItem.reelDiameterImp && (
            <LabelValue
              inline
              inverse
              label={t`Roll Diameter`}
              value={basketItem.reelDiameterImp}
            />
          )}
          {basketItem.coreDiameterImp && (
            <LabelValue
              inline
              inverse
              label={t`Core Diameter`}
              value={basketItem.coreDiameterImp}
            />
          )}
        </>
      )}
      {isSheet && (
        <>
          {basketItem.reelWidthImp && (
            <LabelValue
              inline
              inverse
              label={t`Length`}
              value={basketItem.reelWidthImp}
            />
          )}
          {basketItem.reelDiameterImp && (
            <LabelValue
              inline
              inverse
              label={t`Width`}
              value={basketItem.reelDiameterImp}
            />
          )}
          {basketItem.coreDiameterImp && (
            <LabelValue
              inline
              inverse
              label={t`Sheet Count`}
              value={basketItem.coreDiameterImp}
            />
          )}
        </>
      )}
    </>
  );
};

export default PlannedProductionDescriptionCell;
