import { t } from '@lingui/macro';
import { forOwn } from 'lodash';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../hooks/useAnalytics';
import useUser from '../../../hooks/useUser';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import Criteria from './Criteria';
import { BasisWeightConversion } from './BasisWeightConversion.interface';
import { convertBasisWeight } from './BasisWeightConversionCalculator.util';
import WeightUnit from './BasisWeight.enum';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';

const BasisWeightConversionCalculator: FC = () => {
  const {
    register,
    reset,
    errors,
    getValues,
    trigger,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<BasisWeightConversion>({ mode: 'all' });
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();
  const [calculated, setCalculated] = useState(false);

  const onCalculate = (data: BasisWeightConversion) => {
    forOwn(data, (value, key) => {
      if (value) {
        const convertedBasisWeight = convertBasisWeight(
          value,
          key as WeightUnit
        );
        reset(convertedBasisWeight);
      }
    });

    setCalculated(true);
    trackPageView('CALCULATORS', 'BASIS_WEIGHT_CONVERT', {
      username: user?.username,
    });
  };

  const onReset = () => {
    reset({
      grammage: '',
      cover: '',
      tag: '',
      text: '',
    });
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (calculated) {
      reset({
        [e.target.name]: e.target.value,
      });

      setCalculated(false);
    }
    setTimeout(() => trigger(), 0); // set timeout allows reset magic to happen
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.BASIS_WEIGHT_CONVERSION}
      instructions={t`Specify one weight and calculate conversion`}
      onCalculate={handleSubmit(onCalculate)}
      isCalculateDisabled={!isValid}
      onReset={() => onReset()}
    >
      <Criteria
        errors={errors}
        watch={watch}
        register={register}
        getValues={getValues}
        calculated={calculated}
        onInputChange={onInputChange}
      />
    </CalculatorTemplate>
  );
};

export default BasisWeightConversionCalculator;
