import { without } from 'lodash';
import create from 'zustand';
import { PayloadOrder } from './types/PayloadOrder.interface';

const generateOrderKey = (orders: PayloadOrder[]): number => {
  let highestOrderId = 0;
  orders.forEach((order) => {
    highestOrderId = order.key && order.key > 0 ? order.key : highestOrderId;
  });
  return ++highestOrderId;
};

type PayloadState = {
  country: string | null;
  requiresRecalculation: boolean;
  orders: PayloadOrder[];
  addOrder: (order: PayloadOrder) => PayloadOrder[];
  updateOrder: (order: PayloadOrder) => void;
  removeOrder: (order: PayloadOrder) => PayloadOrder[];
  clearOrders: () => void;
};

const usePayloadStore = create<PayloadState>((set, get) => ({
  country: null,
  requiresRecalculation: false,
  orders: [],
  addOrder: (order: PayloadOrder) => {
    set((state) => ({
      orders: [
        ...state.orders,
        {
          ...order,
          key: generateOrderKey(state.orders),
        },
      ],
    }));
    return get().orders;
  },
  updateOrder: (updatedOrder: PayloadOrder) => {
    set((state) => {
      return {
        ...state,
        orders: state.orders.map((order) =>
          order.key === updatedOrder.key ? updatedOrder : order
        ),
      };
    });
  },
  removeOrder: (order: PayloadOrder) => {
    set((state) => ({
      orders: without(state.orders, order),
    }));
    return get().orders;
  },
  clearOrders: () => {
    set(() => ({ orders: [] }));
  },
}));

export default usePayloadStore;
