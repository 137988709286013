import { t } from '@lingui/macro';
import Option from '../../../types/Option.type';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import UnitOfMeasure from '../../../types/UnitOfMeasure.enum';

export const CORE_DIAMETER_OPTIONS: Option[] = [
  {
    label: '',
    value: '',
  },
  {
    label: '7,0',
    value: '7',
  },
  {
    label: '7,6',
    value: '7.6',
  },
  {
    label: '10,0',
    value: '10',
  },
  {
    label: '12,0',
    value: '12',
  },
  {
    label: '15,0',
    value: '15',
  },
  {
    label: '15,2',
    value: '15.2',
  },
  {
    label: '15,3',
    value: '15.3',
  },
];

export const PRODUCT_TYPE_OPTIONS: Option[] = [
  {
    label: t`Sheet`,
    value: PutUpCategory.SHEET,
  },
  {
    label: t`Roll`,
    value: PutUpCategory.ROLL,
  },
];

export const UNIT_OF_MEASURE_OPTIONS_SHEETS: Option[] = [
  {
    label: t`Pallets`,
    value: UnitOfMeasure.PL,
  },
  {
    label: t`Sheets`,
    value: UnitOfMeasure.SH,
  },
  {
    label: t`Kilograms`,
    value: UnitOfMeasure.KG,
  },
  {
    label: t`Tons`,
    value: UnitOfMeasure.TO,
  },
];

export const UNIT_OF_MEASURE_OPTIONS_ROLLS: Option[] = [
  {
    label: t`Rolls`,
    value: UnitOfMeasure.RL,
  },
  {
    label: t`Kilograms`,
    value: UnitOfMeasure.KG,
  },
  {
    label: t`Tons`,
    value: UnitOfMeasure.TO,
  },
];
