import { camelCase } from 'lodash';
import React from 'react';
import Select, { SelectProps } from '../Select/Select';
import InputLabel from '../InputLabel';

type SelectWithHiddenLabelProps = Omit<SelectProps, 'ref'> & {
  name: string;
  label: string;
};

const SelectWithHiddenLabel = React.forwardRef<
  HTMLSelectElement,
  SelectWithHiddenLabelProps
>(({ name, id, label, ...rest }, ref) => {
  const defaultedId = id || camelCase(name);

  return (
    <div>
      <InputLabel text={label} htmlFor={defaultedId} visuallyHidden />
      <Select name={name} id={defaultedId} ref={ref} {...rest} />
    </div>
  );
});

export default SelectWithHiddenLabel;
