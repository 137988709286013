import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import Customer from '../../../../types/Customer.interface';
import CustomerAddress from '../CustomerAddress';

interface ICustomerSectionProps {
  customer: Customer;
}

const CustomerSection: FC<ICustomerSectionProps> = ({ customer }) => {
  return (
    <div>
      <h3 className="text-xl font-bold mb-5">
        <Trans>Customer</Trans>
      </h3>
      <div className="grid grid-cols-2 w-full">
        <div>{customer.name}</div>
        <CustomerAddress
          customerNumber={customer.number}
          street={customer.address}
          city={customer.city}
          country={customer.country}
          postalCode={customer.postalCode}
        />
      </div>
    </div>
  );
};

export default CustomerSection;
