import { BasisWeightConversion } from './BasisWeightConversion.interface';
import BasisWeightUnit from './BasisWeight.enum';

export const convertBasisWeight = (
  quantity: string,
  unit: BasisWeightUnit
): BasisWeightConversion => {
  let basisWeightConversion: BasisWeightConversion = {
    [BasisWeightUnit.GRAMMAGE]: '',
    [BasisWeightUnit.COVER]: '',
    [BasisWeightUnit.TAG]: '',
    [BasisWeightUnit.TEXT]: '',
  };

  // eslint-disable-next-line default-case
  switch (unit) {
    case BasisWeightUnit.GRAMMAGE:
      basisWeightConversion = {
        [BasisWeightUnit.GRAMMAGE]: quantity,
        [BasisWeightUnit.COVER]: Math.round(
          (+quantity * 520) / 1406.5
        ).toString(),
        [BasisWeightUnit.TAG]: Math.round(
          (+quantity * 864) / 1406.5
        ).toString(),
        [BasisWeightUnit.TEXT]: Math.round(
          (+quantity * 950) / 1406.5
        ).toString(),
      };
      break;
    case BasisWeightUnit.COVER:
      basisWeightConversion = {
        [BasisWeightUnit.COVER]: quantity,
        [BasisWeightUnit.GRAMMAGE]: Math.round(
          (+quantity * 1406.5) / 520
        ).toString(),
        [BasisWeightUnit.TAG]: Math.round(+quantity * 1.664).toString(),
        [BasisWeightUnit.TEXT]: Math.round(+quantity * 1.828).toString(),
      };
      break;
    case BasisWeightUnit.TAG:
      basisWeightConversion = {
        [BasisWeightUnit.TAG]: quantity,
        [BasisWeightUnit.GRAMMAGE]: Math.round(
          (+quantity * 1406.5) / 864
        ).toString(),
        [BasisWeightUnit.COVER]: Math.round(+quantity / 1.664).toString(),
        [BasisWeightUnit.TEXT]: Math.round(+quantity / 0.91).toString(),
      };
      break;
    case BasisWeightUnit.TEXT:
      basisWeightConversion = {
        [BasisWeightUnit.TEXT]: quantity,
        [BasisWeightUnit.GRAMMAGE]: Math.round(
          (+quantity * 1406.5) / 950
        ).toString(),
        [BasisWeightUnit.COVER]: Math.round(+quantity * 0.546).toString(),
        [BasisWeightUnit.TAG]: Math.round(+quantity * 0.91).toString(),
      };
      break;
  }

  return basisWeightConversion;
};
