import { UseFormMethods } from 'react-hook-form';
import * as React from 'react';
import { t } from '@lingui/macro';
import { useEffect } from 'react';
import InputLabel from '../../../../components/InputLabel';
import Input from '../../../../components/Input/Input';
import CoverOptionType from './CoverOptionType.enum';
import style from './CoverOptions.module.scss';
import useDummyOrderStore from '../useDummyOrder.store';

interface ICoverFormatRightBottomProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  setValue: UseFormMethods['setValue'];
  errors: UseFormMethods['errors'];
  clearErrors: UseFormMethods['clearErrors'];
}

const CoverFormatRightBottom: React.FunctionComponent<ICoverFormatRightBottomProps> = ({
  register,
  watch,
  setValue,
  errors,
  clearErrors,
}) => {
  const state = useDummyOrderStore();
  const coverOptionTypeWatch = watch('coverOptionType');

  useEffect(() => {
    setValue('gatefoldFront', '');
    setValue('gatefoldRear', '');
    setValue('gatefoldFront2', '');
    setValue('gatefoldRear2', '');
    clearErrors();
  }, [clearErrors, coverOptionTypeWatch, setValue]);

  return (
    <>
      <div className="flex">
        <div className="text-center w-1/3 pb-16 px-4">
          <InputLabel size="md" text={t`Gatefold, front`} className="w-108">
            <div className="flex items-center">
              <Input
                name="gatefoldFront"
                type="number"
                ref={register({ min: 0, max: state.width })}
                disabled={
                  coverOptionTypeWatch !==
                  CoverOptionType.SIX_PAGE_STANDARD_FRONT
                }
                className={style.inputBackground}
                onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                error={errors.gatefoldFront}
              />
              <div className="ml-2">mm</div>
            </div>
          </InputLabel>
        </div>
        <div className="border-l border-gray-light400 text-center w-1/3 pb-16 px-4">
          <InputLabel size="md" text={t`Gatefold, rear`} className="w-108">
            <div className="flex items-center">
              <Input
                name="gatefoldRear"
                type="number"
                ref={register({ min: 0, max: state.width })}
                disabled={
                  coverOptionTypeWatch !==
                  CoverOptionType.SIX_PAGE_STANDARD_REAR
                }
                className={style.inputBackground}
                onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                error={errors.gatefoldRear}
              />
              <div className="ml-2">mm</div>
            </div>
          </InputLabel>
        </div>
        <div className="border-l border-gray-light400 text-center w-1/3 pb-16 px-4">
          <InputLabel size="md" text={t`Gatefold, front`} className="w-108">
            <div className="flex items-center pb-3">
              <Input
                name="gatefoldFront2"
                type="number"
                ref={register({ min: 0, max: state.width })}
                disabled={coverOptionTypeWatch !== CoverOptionType.EIGHT_PAGE}
                className={style.inputBackground}
                onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                error={errors.gatefoldFront2}
              />
              <div className="ml-2">mm</div>
            </div>
          </InputLabel>
          <InputLabel size="md" text={t`Gatefold, rear`} className="w-108">
            <div className="flex items-center">
              <Input
                name="gatefoldRear2"
                type="number"
                ref={register({ min: 0, max: state.width })}
                disabled={coverOptionTypeWatch !== CoverOptionType.EIGHT_PAGE}
                className={style.inputBackground}
                onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                error={errors.gatefoldRear2}
              />
              <div className="ml-2">mm</div>
            </div>
          </InputLabel>
        </div>
      </div>
    </>
  );
};

export default CoverFormatRightBottom;
