import { Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import { filter as lodashFilter, forIn } from 'lodash';
import React, {
  FunctionComponent,
  ReactText,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button } from '../../../components/Button/Button';
import Callout from '../../../components/Callout/Callout';
import Date from '../../../components/Date';
import Table from '../../../components/Table/Table';
import useRole from '../../../hooks/useRole';
import useUserCustomization from '../../../hooks/useUserCustomization';
import { saveUserCustomization } from '../../../services/User';
import stripZeros from '../../../services/Util/stripZeros.util';
import { AuthorityRole } from '../../../types/Authority.interface';
import { DocumentTypes } from '../../../types/DocumentTypes.interface';
import { UserCustomization } from '../../../types/User/UserCustomization.interface';
import {
  getRowKey,
  isKeyExisting,
  setPrintDocForRow,
  updatePrintFlag,
} from '../PrintDocuments.util';
import { SearchDocumentInputs } from '../SearchDocuments/SearchDocumentInputs';
import { AvailableDocumentRow } from './AvailableDocumentRow.interface';
import './AvailableDocuments.scss';
import AvailableDocumentsColumnTitle from './AvailableDocumentsColumnTitle';
import availableDocumentsPopoverNotificationStore from './availableDocumentsPopoverNotification.store';
import EditDocPopOver from './EditDocPopOver';
import useDynamicColumns from './hooks/useDynamicColumns';
import OrderCell from './OrderCell';
import ProductCell from './ProductCell';
import useAnalytics from '../../../hooks/useAnalytics';

const PAGE_SIZE = 10;
const SHOW_ALL_PAGE_SIZE = 10000;

interface IAvailableDocumentsTableProp {
  dataSource: AvailableDocumentRow[] | undefined;
  clearSelected: boolean;
  currentPage: number | undefined;
  totalResults: number | undefined;
  expKeys: (string | null | undefined)[] | undefined;
  filters: SearchDocumentInputs | undefined;
  setExpKeys: (expKeys: (string | null | undefined)[]) => void;
  setCurrentPage: (page: number | undefined) => void;
  updateDataSource: (dataSource: AvailableDocumentRow[] | undefined) => void;
  showOrderDetails: (record: AvailableDocumentRow) => void;
}

const AvailableDocumentsTable: React.FunctionComponent<IAvailableDocumentsTableProp> = ({
  dataSource,
  clearSelected,
  currentPage,
  totalResults,
  expKeys,
  setExpKeys,
  setCurrentPage,
  updateDataSource,
  showOrderDetails,
}) => {
  const { hasRole } = useRole();
  const { dynamicColumns, setDynamicColumnChecked } = useDynamicColumns();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [tablePage, setTablePage] = useState<number | undefined>(1);
  const { data: userCustomization } = useUserCustomization();
  const { trackPageView } = useAnalytics();

  const {
    setEditColumnsHelpPopoverVisibility,
    setEditColumnsPopoverVisibility,
    editColumnsPopoverVisibility,
    editColumnsHelpPopoverVisibility,
  } = availableDocumentsPopoverNotificationStore();

  const [clearSelections, setClearSelections] = useState<boolean>(false);
  const onChange = (page: number) => {
    trackPageView('PRINT_DOCUMENTS', 'PAGE_CHANGE');
    setCurrentPage(page);
    setTablePage(page);
  };

  const onToggleShowAll = () => {
    trackPageView('PRINT_DOCUMENTS', 'TOGGLE_SHOW_ALL');
    if (!showAll) {
      setShowAll(true);
      setTablePage(1);
    } else {
      setShowAll(false);
      setTablePage(undefined);
    }
  };

  useEffect(() => {
    setClearSelections(true);
  }, [clearSelected]);

  useEffect(() => {
    setTablePage(currentPage);
  }, [currentPage]);
  useEffect(() => {
    if (userCustomization && !userCustomization.hideEditColumnsCallout) {
      setEditColumnsHelpPopoverVisibility(true);
    }
  }, [userCustomization, setEditColumnsHelpPopoverVisibility]);

  const getStartRow = useCallback(() => {
    const applyToAllPages = showAll || clearSelections;
    return applyToAllPages ? 0 : ((tablePage || 1) - 1) * PAGE_SIZE;
  }, [clearSelections, showAll, tablePage]);

  const getEndRow = useCallback(() => {
    const applyToAllPages = showAll || clearSelections;
    const endRow = applyToAllPages
      ? totalResults || SHOW_ALL_PAGE_SIZE
      : (tablePage || 1) * PAGE_SIZE;

    return +endRow - 1;
  }, [clearSelections, showAll, tablePage, totalResults]);

  const setPrintDocForAllRows = useCallback(
    (documentType: DocumentTypes, toPrint: boolean) => {
      if (dataSource) {
        dataSource.map((row, idx) => {
          if (row.overviews && idx >= getStartRow() && idx <= getEndRow()) {
            row.overviews.map((o) => {
              return setPrintDocForRow(o, documentType, toPrint);
            });
          }
          return row;
        });
      }

      updateDataSource(dataSource);
    },
    [dataSource, updateDataSource, getStartRow, getEndRow]
  );

  useEffect(() => {
    if (clearSelections) {
      forIn(DocumentTypes, (value) => setPrintDocForAllRows(value, false));
      updateDataSource(dataSource);
      setClearSelections(false);
    }
  }, [clearSelections, dataSource, setPrintDocForAllRows, updateDataSource]);

  const toggleTableCell = (record: AvailableDocumentRow) => {
    if (dataSource) {
      updateDataSource(
        dataSource.map((row) => {
          if (row.key === record.key) {
            row.overviews = record.overviews;
          }
          return row;
        })
      );
    }
  };

  const updateTableDataForSameNumber = (
    record: AvailableDocumentRow,
    documentType: DocumentTypes,
    toPrint: boolean
  ) => {
    if (dataSource) {
      updateDataSource(
        dataSource.map((row) => {
          row.overviews.map((o) => {
            return updatePrintFlag(record, o, documentType, toPrint);
          });
          return row;
        })
      );
    }
  };

  const updateEditColumnsCallout = async () => {
    await saveUserCustomization({
      ...userCustomization,
      hideEditColumnsCallout: true,
    } as UserCustomization);
    setEditColumnsHelpPopoverVisibility(false);
  };

  interface IDocumentsColProps {
    type: DocumentTypes;
  }

  const DocumentsCol: FunctionComponent<IDocumentsColProps> = ({ type }) => {
    return (
      <AvailableDocumentsColumnTitle
        checked={dynamicColumns[type]?.checked}
        onChange={(checked) => {
          setDynamicColumnChecked(type, checked);
          setPrintDocForAllRows(type, checked);
        }}
        text={dynamicColumns[type]?.display}
      />
    );
  };

  const columns: ColumnsType<AvailableDocumentRow> = [
    {
      title: (
        <div className="flex items-center text-lg font-bold ml-2">
          <Trans>Order</Trans>
        </div>
      ),
      dataIndex: 'order',
      key: 'order',
      render: (text: string, record: AvailableDocumentRow) => (
        <OrderCell
          record={record}
          onLinkClick={() => {
            showOrderDetails(record);
          }}
        />
      ),
    },
    {
      title: (
        <div className="flex items-center text-lg font-bold leading-tight ml-2">
          <Trans>Call Off #</Trans>
        </div>
      ),
      dataIndex: 'callOff',
      key: 'callOff',
      render: (text: string, record: AvailableDocumentRow) =>
        record.overviews[0].callOff,
    },
    {
      title: (
        <div className="flex items-center text-lg font-bold ml-2">
          <Trans>Product</Trans>
        </div>
      ),
      dataIndex: 'product',
      key: 'product',
      render: ProductCell,
    },
    {
      title: (
        <div className="flex items-center ml-2">
          <span className="text-lg font-bold leading-tight mr-2">
            <Trans>Order Date</Trans>
          </span>
        </div>
      ),
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (text: string, record: AvailableDocumentRow) =>
        record.overviews &&
        record.overviews.length === 1 &&
        record.overviews[0].details &&
        record.overviews[0].details.dateCreated && (
          <Date date={record.overviews[0].details.dateCreated} />
        ),
    },
    {
      title: <DocumentsCol type={DocumentTypes.ORDER_CONFIRMATION} />,
      dataIndex: DocumentTypes.ORDER_CONFIRMATION,
      key: DocumentTypes.ORDER_CONFIRMATION,
      render: (text: string, record: AvailableDocumentRow) =>
        record.overviews &&
        record.overviews.length === 1 &&
        record.overviews[0].confirmationNumber && (
          <label className="whitespace-nowrap">
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={record.overviews[0].printConfirmationDoc}
              onChange={(e) => {
                record.overviews[0].printConfirmationDoc = e.target.checked;
                updateTableDataForSameNumber(
                  record,
                  DocumentTypes.ORDER_CONFIRMATION,
                  e.target.checked
                );
                toggleTableCell(record);
              }}
            />{' '}
            {stripZeros(record.overviews[0].confirmationNumber)}
          </label>
        ),
    },
    {
      title: <DocumentsCol type={DocumentTypes.INVOICE} />,
      dataIndex: DocumentTypes.INVOICE,
      key: DocumentTypes.INVOICE,
      render: (text: string, record: AvailableDocumentRow) =>
        record.overviews &&
        record.overviews.length === 1 &&
        record.overviews[0].invoiceNumber && (
          <label className="whitespace-nowrap">
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={record.overviews[0].printInvoiceDoc}
              onChange={(e) => {
                record.overviews[0].printInvoiceDoc = e.target.checked;
                updateTableDataForSameNumber(
                  record,
                  DocumentTypes.INVOICE,
                  e.target.checked
                );
                toggleTableCell(record);
              }}
            />{' '}
            {stripZeros(record.overviews[0].invoiceNumber)}
          </label>
        ),
    },
    {
      title: <DocumentsCol type={DocumentTypes.WEIGHTLIST} />,
      dataIndex: DocumentTypes.WEIGHTLIST,
      key: DocumentTypes.WEIGHTLIST,
      render: (text: string, record: AvailableDocumentRow) =>
        record.overviews &&
        record.overviews.length === 1 &&
        record.overviews[0].weightListNumber && (
          <label className="whitespace-nowrap">
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={record.overviews[0].printWeightListDoc}
              onChange={(e) => {
                record.overviews[0].printWeightListDoc = e.target.checked;
                toggleTableCell(record);
              }}
            />{' '}
            {stripZeros(record.overviews[0].weightListNumber)}
            {hasRole(AuthorityRole.ROLE_NA) && (
              <div className="ml-7">
                {stripZeros(record.overviews[0].deliveryNote)}
              </div>
            )}
          </label>
        ),
    },
    {
      title: <DocumentsCol type={DocumentTypes.DELIVERY} />,
      dataIndex: DocumentTypes.DELIVERY,
      key: DocumentTypes.DELIVERY,
      render: (text: string, record: AvailableDocumentRow) =>
        record.overviews &&
        record.overviews.length === 1 &&
        record.overviews[0].deliveryNumber && (
          <label className="whitespace-nowrap">
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={record.overviews[0].printDeliveryDoc}
              onChange={(e) => {
                record.overviews[0].printDeliveryDoc = e.target.checked;
                toggleTableCell(record);
              }}
            />{' '}
            {stripZeros(record.overviews[0].deliveryNumber)}
          </label>
        ),
    },
    {
      title: <DocumentsCol type={DocumentTypes.CREDIT_NOTE} />,
      dataIndex: DocumentTypes.CREDIT_NOTE,
      key: DocumentTypes.CREDIT_NOTE,
      render: (text: string, record: AvailableDocumentRow) =>
        record.overviews &&
        record.overviews.length === 1 &&
        record.overviews[0].creditNoteNumber && (
          <label className="whitespace-nowrap">
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={record.overviews[0].printCreditNoteDoc}
              onChange={(e) => {
                record.overviews[0].printCreditNoteDoc = e.target.checked;
                toggleTableCell(record);
              }}
            />{' '}
            {stripZeros(record.overviews[0].creditNoteNumber)}
          </label>
        ),
    },
    {
      title: <DocumentsCol type={DocumentTypes.BAR_CODE_WEIGHT_LIST} />,
      dataIndex: DocumentTypes.BAR_CODE_WEIGHT_LIST,
      key: DocumentTypes.BAR_CODE_WEIGHT_LIST,
      render: (text: string, record: AvailableDocumentRow) =>
        record.overviews &&
        record.overviews.length === 1 &&
        record.overviews[0].barCodeWeightList && (
          <label className="whitespace-nowrap">
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={record.overviews[0].printBarCodeWeightListDoc}
              onChange={(e) => {
                record.overviews[0].printBarCodeWeightListDoc =
                  e.target.checked;
                toggleTableCell(record);
              }}
            />{' '}
            {stripZeros(record.overviews[0].barCodeWeightList)}
          </label>
        ),
    },
    {
      title: <DocumentsCol type={DocumentTypes.CERTIFICATE_OF_ANALYSIS} />,
      dataIndex: DocumentTypes.CERTIFICATE_OF_ANALYSIS,
      key: DocumentTypes.CERTIFICATE_OF_ANALYSIS,
      render: (text: string, record: AvailableDocumentRow) =>
        record.overviews &&
        record.overviews.length === 1 &&
        record.overviews[0].coaDocId &&
        record.overviews[0].documentNumber && (
          <label className="whitespace-nowrap">
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={record.overviews[0].printCoaDoc}
              onChange={(e) => {
                record.overviews[0].printCoaDoc = e.target.checked;
                toggleTableCell(record);
              }}
            />{' '}
            {stripZeros(record.overviews[0].weightListNumber)}
          </label>
        ),
    },
    {
      title: (
        <Callout
          title={<Trans>Edit Columns</Trans>}
          body={
            <Trans>Use this button to add/remove the visible columns.</Trans>
          }
          onClose={updateEditColumnsCallout}
          visible={editColumnsHelpPopoverVisibility}
          placement="right"
        >
          <EditDocPopOver visible={editColumnsPopoverVisibility}>
            <Button
              className="bg-blue-pacific flex items-center h-full px-2 font-normal"
              onClick={() =>
                setEditColumnsPopoverVisibility(!editColumnsPopoverVisibility)
              }
            >
              <div className="text-center">
                <i className="fas fa-edit text-3xl" />
                <strong className="text-sm">EDIT</strong>
              </div>
            </Button>
          </EditDocPopOver>
        </Callout>
      ),
      key: 'editColumns',
      render: () => '',
    },
  ];

  const isColumnShown = (key: string): boolean => {
    const column = dynamicColumns[key as DocumentTypes];
    return !!column && column.show;
  };

  return (
    <form>
      <Table
        dataSource={dataSource}
        rowClassName={(record) =>
          !record.isChild && !record.children ? 'hide-icon' : ''
        }
        expandedRowKeys={expKeys as ReactText[]}
        rowKey={(record) => getRowKey(record)}
        className="available-documents"
        columns={columns.filter(
          (column) =>
            column.key === 'editColumns' ||
            column.key === 'order' ||
            column.key === 'callOff' ||
            column.key === 'product' ||
            column.key === 'orderDate' ||
            isColumnShown(column.key as string)
        )}
        pagination={{
          current: tablePage,
          onChange,
          pageSize: showAll ? SHOW_ALL_PAGE_SIZE : PAGE_SIZE,
          position: ['topRight'],
          total: totalResults,
          showSizeChanger: false,
          itemRender: (page, type, orginalElement) => {
            if (type === 'prev') {
              return (
                <div className="relative">
                  <Button
                    theme="link"
                    className="absolute z-10 text-blue-pacific"
                    width="40"
                    style={{ right: '20px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onToggleShowAll();
                    }}
                  >
                    {showAll ? (
                      <Trans>Page Results</Trans>
                    ) : (
                      <Trans>Show All</Trans>
                    )}
                  </Button>
                  {orginalElement}
                </div>
              );
            }
            return <div>{orginalElement}</div>;
          },
        }}
        expandable={{
          rowExpandable: (record) => record.overviews.length > 1,
          onExpand: (expanded, record) => {
            if (expanded && (!expKeys || !isKeyExisting(expKeys, record))) {
              expKeys?.push(getRowKey(record));
            } else {
              const updatedKeys = lodashFilter(
                expKeys,
                (o) => o !== getRowKey(record)
              );
              setExpKeys(updatedKeys);
            }
          },
        }}
        locale={{
          emptyText: (
            <div className="text-gray-600 my-3">
              <Trans>No matching documents</Trans>
            </div>
          ),
        }}
      />
    </form>
  );
};

export default AvailableDocumentsTable;
