import { Space } from 'antd';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Select from '../../../../components/Select/Select';
import Option from '../../../../types/Option.type';
import { METRIC_IMP_OPTIONS } from '../../Calculators.util';
import { CalculatorUnitTypes } from '../../types/CalculatorUnitTypes.enum';
import ImperialCriteria from './ImperialCriteria';
import MetricCriteria from './MetricCriteria';

interface ICriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  reset: UseFormMethods['reset'];
  watch: UseFormMethods['watch'];
  onMetricImperialChange: (selectedValue: CalculatorUnitTypes) => void;
  brandOptions: Option[];
  caliperOptions: Option[];
  basisWeightOptions: Option[];
  grammageOptions: Option[];
  isImperial: boolean;
}

const Criteria: React.FunctionComponent<ICriteriaProps> = ({
  register,
  isImperial,
  errors,
  reset,
  watch,
  onMetricImperialChange,
  brandOptions,
  caliperOptions,
  basisWeightOptions,
  grammageOptions,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Select
        name="metImp"
        ref={register({ required: true })}
        options={METRIC_IMP_OPTIONS}
        required
        onChange={(e) =>
          onMetricImperialChange(e.target.value as CalculatorUnitTypes)
        }
      />
      <Select
        name="paperBrand"
        ref={register({ required: true })}
        options={brandOptions}
        required
        error={errors?.paperBrand}
      />
      {!isImperial && (
        <MetricCriteria
          register={register}
          errors={errors}
          watch={watch}
          grammageOptions={grammageOptions}
        />
      )}
      {isImperial && (
        <ImperialCriteria
          register={register}
          errors={errors}
          caliperOptions={caliperOptions}
          basisWeightOptions={basisWeightOptions}
        />
      )}
    </Space>
  );
};

export default Criteria;
