import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import ReasonCodeMapping from './ReasonCodeMapping';
import SampleTable from './SampleTable';

interface ISampleSectionProps {
  className: string;
}

const SampleSection: FC<ISampleSectionProps> = ({ className }) => {
  return (
    <Space direction="vertical" size="middle" className={className}>
      <div>
        <Trans>You can upload a file with the following format:</Trans>
        <ul>
          <li>
            <a
              href="/portal/static/DeviationUploadExample.csv"
              className="app-link"
            >
              <Trans>Click here to download an example CSV file</Trans>
            </a>
          </li>
          <li className="leading-8">
            <a
              href="/portal/static/DeviationUploadExample.xlsx"
              className="app-link"
            >
              <Trans>Click here to download an example Excel file</Trans>
            </a>
          </li>
        </ul>
      </div>
      <SampleTable />
      <ReasonCodeMapping />
    </Space>
  );
};

export default SampleSection;
