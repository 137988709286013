import { Collapse } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { Trans } from '@lingui/macro';
import ExpandIcon from '../../../../components/ExpandIcon';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { getOrderBookingStockAlternates } from '../../../../services/Product';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../../hooks/useAnalytics';
import AlternativesTable from './AlternativesTable';
import { StockAvailabilityResult } from '../../../../types/StockAvailabilityResult.interface';
import AddToBasketWithQty from '../AddToBasketWithQty';

const { Panel } = Collapse;
interface IAlternateSizesProps {
  stockToFindAlternatesFor: StockAvailabilityResult | null;
  resetFullyToLander: () => void;
}

const Alternatives: React.FunctionComponent<IAlternateSizesProps> = ({
  stockToFindAlternatesFor,
  resetFullyToLander,
}) => {
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);

  const { trackPageView } = useAnalytics();
  const [stockAlternates, setStockAlternates] = useState<
    StockAvailabilityResult[]
  >([]);
  const { isFetching } = usePaginatedQuery(
    [QueryCacheName.ALT_ORDER_BOOKING_PRODUCTS, stockToFindAlternatesFor],
    getOrderBookingStockAlternates,
    {
      enabled: stockToFindAlternatesFor,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        trackPageView('ORDER_BOOKING', 'ALTERNATIVES');
        setStockAlternates(data || []);
      },
    }
  );

  return (
    <Collapse
      className="sappi-collapse-custom-collapse -mx-4"
      bordered={false}
      activeKey={activeKey}
      onChange={(key) => {
        setActiveKey(key as string);
      }}
      expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
    >
      <Panel
        header={
          (activeKey || '').indexOf('1') !== -1 ? (
            <Trans>Hide Alternatives</Trans>
          ) : (
            <Trans>Show Alternatives</Trans>
          )
        }
        key="2"
        className="sappi-collapse-custom-summary-collapse my-4"
      >
        {isFetching ? (
          <LoadingSpinner horizontal />
        ) : (
          <>
            {stockAlternates?.map((alternate) => (
              <div key={alternate.skuCode} className="-mx-4">
                <AlternativesTable className="z-0 mb-2" results={[alternate]} />
                <AddToBasketWithQty
                  stockResult={alternate}
                  resetFullyToLander={resetFullyToLander}
                />
              </div>
            ))}
          </>
        )}
      </Panel>
    </Collapse>
  );
};

export default Alternatives;
