import { Collapse } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { Trans } from '@lingui/macro';
import ExpandIcon from '../../../../components/ExpandIcon';
import { OrderBookingProductQuery } from '../../../../types/OrderBookingProductQuery.interface';
import AlternateSizesTable from './AlternateSizesTable';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { getOrderBookingStockAlternateSizes } from '../../../../services/Product';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { StockAvailabilityResult } from '../../../../types/StockAvailabilityResult.interface';
import Alternatives from './Alternatives';
import AddToBasketWithQty from '../AddToBasketWithQty';
import useAnalytics from '../../../../hooks/useAnalytics';

const { Panel } = Collapse;
interface IAlternateSizesProps {
  productQuery: OrderBookingProductQuery | undefined;
  resetFullyToLander: () => void;
}

const AlternateSizes: React.FunctionComponent<IAlternateSizesProps> = ({
  productQuery,
  resetFullyToLander,
}) => {
  const { trackPageView } = useAnalytics();
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [orderedAlternateSizes, setOrderedAlternateSizes] = useState<
    StockAvailabilityResult[]
  >([]);

  const { isFetching } = usePaginatedQuery(
    [QueryCacheName.ORDER_BOOKING_STOCKS, productQuery],
    getOrderBookingStockAlternateSizes,
    {
      enabled: productQuery && activeKey,
      retry: false,
      staleTime: 50000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        trackPageView('ORDER_BOOKING', 'ALTERNATIVE_SIZES');
        setOrderedAlternateSizes(data || []);
      },
    }
  );

  const hasAlternatives = (
    stockToFindAlternatesFor: StockAvailabilityResult
  ): boolean => {
    return (
      stockToFindAlternatesFor.alternativeBrand1 !== '' ||
      stockToFindAlternatesFor.alternativeBrand2 !== '' ||
      stockToFindAlternatesFor.alternativeBrand3 !== ''
    );
  };

  return (
    <Collapse
      className="sappi-collapse-custom-collapse"
      style={{ marginRight: '1px' }}
      bordered={false}
      activeKey={activeKey}
      onChange={(key) => {
        setActiveKey(key as string);
      }}
      expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
    >
      <Panel
        header={
          (activeKey || '').indexOf('1') !== -1 ? (
            <Trans>Hide Alternate Sizes for Faster Delivery</Trans>
          ) : (
            <Trans>Show Alternate Sizes for Faster Delivery</Trans>
          )
        }
        key="1"
        className="sappi-collapse-custom-summary-collapse"
      >
        {isFetching ? (
          <LoadingSpinner horizontal />
        ) : (
          <>
            {orderedAlternateSizes?.map((orderedAlternateSize, index) => (
              <React.Fragment key={orderedAlternateSize.skuCode}>
                <div className="-mx-4 mb-2">
                  <AlternateSizesTable
                    className="z-0 mb-2"
                    results={[orderedAlternateSize]}
                    showHeader={index === 0}
                  />
                  <AddToBasketWithQty
                    stockResult={orderedAlternateSize}
                    productQuery={productQuery}
                    resetFullyToLander={resetFullyToLander}
                  />
                </div>
                {hasAlternatives(orderedAlternateSize) && (
                  <Alternatives
                    stockToFindAlternatesFor={orderedAlternateSize}
                    resetFullyToLander={resetFullyToLander}
                  />
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </Panel>
    </Collapse>
  );
};

export default AlternateSizes;
