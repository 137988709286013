import { useQuery } from 'react-query';
import { getNewsContentById } from '../../services/Content';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { RecentNewsContent } from '../../types/RecentNewsContent.interface';

const useNewsItem = (
  newsId: string
): {
  newsItem: RecentNewsContent | undefined;
  isFetching: boolean;
} => {
  const { data: newsItem, isFetching } = useQuery(
    [QueryCacheName.NEWS_ITEM, newsId],
    () => getNewsContentById(newsId),
    {
      keepPreviousData: true,
      staleTime: Infinity,
      enabled: newsId,
    }
  );
  return {
    newsItem,
    isFetching,
  };
};

export default useNewsItem;
