import { t, Trans } from '@lingui/macro';
import { Popover } from 'antd';
import Axios from 'axios';
import { noop } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import styles from '../../../../components/AvailableActionsButton/AvailableActionsButton.module.scss';
import OldBasketPopUp from '../../../../components/BasketPopUp/OldBasketPopUp';
import { Button } from '../../../../components/Button/Button';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../../hooks/useAnalytics';
import useCustomerBasket from '../../../../hooks/useCustomerBasket';
import useRole from '../../../../hooks/useRole';
import { updateBasket } from '../../../../services/Basket/index';
import { checkDocuments } from '../../../../services/Documents';
import { AuthorityRole } from '../../../../types/Authority.interface';
import { DocumentTypes } from '../../../../types/DocumentTypes.interface';
import { Order } from '../../../../types/Order.interface';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { SearchOrderDetailsInputs } from '../../SearchOrderDetailsInputs.interface';
import TrackOrderTab from '../../TrackOrderTab.enum';
import { getItemToAdd } from './ActionsMenuPopover.util';
import { getActiveState, showSignedBOL } from './viewOrdersTableCell.util';

interface IOrderRelatedDatesPopoverProps {
  order: Order;
  onClick(): void;
  isOrderDetailsPage?: boolean;
  onNewTabTrigger: (tab: TrackOrderTab, data: SearchOrderDetailsInputs) => void;
}

interface QueryParam {
  documentType: string;
  documentId: string;
}

const ActionsMenuPopover: React.FunctionComponent<IOrderRelatedDatesPopoverProps> = ({
  order,
  onClick,
  isOrderDetailsPage,
  onNewTabTrigger,
}) => {
  const { trackPageView } = useAnalytics();
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);

  const [printOrderConfirm, setPrintOrderConfirm] = useState<boolean>(false);
  const [printWeightList, setPrintWeightList] = useState<boolean>(false);
  const [printInvoice, setPrintInvoice] = useState<boolean>(false);
  const [downloadPackingList, setDownloadPackingList] = useState<boolean>(
    false
  );
  const [printCertificate, setPrintCertificate] = useState<boolean>(false);
  const [sendingBOLRequest, setSendingBOLRequest] = useState<boolean>(false);
  const [requestBOLSent, setRequestBOLSent] = useState<boolean>(false);
  const [addingToBasket, setAddingToBasket] = useState(false);
  const [showBasketPopUp, setShowBasketPopUp] = useState(false);
  const [showNoDocumentsFound, setShowNoDocumentsFound] = useState(false);
  const { getCustomerBasket } = useCustomerBasket();
  const history = useHistory();
  const queryCache = useQueryCache();

  const { hasRole } = useRole();

  const getNewTabParams = (): SearchOrderDetailsInputs => ({
    documentNumber: isOrderDetailsPage
      ? order.overview.documentNumber
      : order.overviews[0].documentNumber,
    documentPosition: isOrderDetailsPage
      ? order.overview.documentPosition
      : order.overviews[0].documentPosition,
  });

  function requestDetails() {
    onClick();
    onNewTabTrigger(TrackOrderTab.VIEW_ORDER_DETAILS, getNewTabParams());
  }

  function downloadExcel() {
    trackPageView('TRACK_ORDERS', 'DOWNLOAD_PACKING_LIST', {
      customerId: order.customerNumber,
    });

    const windowOpened = isOrderDetailsPage
      ? window.open(
          `../resources/order/${order.overview.documentNumber}/${order.overview.documentPosition}/excel`,
          '_self'
        )
      : window.open(
          `../resources/order/${order.overviews[0].documentNumber}/${order.overviews[0].documentPosition}/excel`,
          '_self'
        );

    if (windowOpened) {
      windowOpened.blur();
      setTimeout(() => {
        setLoadingDocuments(false);
      }, 2000);
    }
  }
  async function orderSimilar() {
    trackPageView('TRACK_ORDERS', 'ORDER_SIMILAR', {
      customerId: order.customerNumber,
    });
    setAddingToBasket(true);
    const itemToAdd = await getItemToAdd(order);
    const customerBasket = getCustomerBasket(order.customerNumber);
    if (customerBasket) {
      customerBasket.items.push(itemToAdd);
      await updateBasket(customerBasket);
      setShowBasketPopUp(true);
      setAddingToBasket(false);
      queryCache.invalidateQueries(QueryCacheName.BASKET);
      onClick();
    }
  }

  async function fileComplaint() {
    trackPageView('TRACK_ORDERS', 'FILE_COMPLAINT', {
      customerId: order.customerNumber,
    });
    const documentNumber = isOrderDetailsPage
      ? order.overview.documentNumber
      : order.overviews[0].documentNumber;
    const documentPosition = isOrderDetailsPage
      ? order.overview.documentPosition
      : order.overviews[0].documentPosition;
    history.push(`/claim/file/${documentNumber}/${documentPosition}`);
  }

  function requestInvoice() {
    trackPageView('TRACK_ORDERS','REQUEST_INVOICE', {
      customerId: order.customerNumber,
    });
    onClick();
    onNewTabTrigger(TrackOrderTab.VIEW_INVOICE, getNewTabParams());
  }

  function requestDeliveryDetails() {
    trackPageView('TRACK_ORDERS', 'REQUEST_DELIVERY_DETAILS', {
      customerId: order.customerNumber,
    });
    onClick();
    onNewTabTrigger(TrackOrderTab.VIEW_DELIVERY_DETAILS, getNewTabParams());
  }

  const loadDocuments = async (queryParams: QueryParam[]) => {
    let queryString = '';
    const documents = await checkDocuments(
      isOrderDetailsPage
        ? order.overview.documentNumber
        : order.overviews[0].documentNumber,
      isOrderDetailsPage
        ? order.overview.documentPosition
        : order.overviews[0].documentPosition,
      queryParams
    );

    if (documents?.documentIds.length > 0) {
      queryString = documents.documentIds
        .map((q: string) => {
          return `documentId=${q}&`;
        })
        .join('');
    } else {
      throw Error('No document Ids');
    }
    return queryString;
  };

  function loadIcons(windowOpened: Window | null) {
    if (windowOpened) {
      windowOpened.blur();
      setTimeout(() => {
        setLoadingDocuments(false);
      }, 2000);
    }
  }

  const printDocuments = async (queryParams: QueryParam[]) => {
    let windowOpened;
    if (queryParams.length > 0) {
      try {
        const parameters = await loadDocuments(queryParams);
        windowOpened = window.open(
          `../resources/documents?${parameters}`,
          '_self'
        );
        loadIcons(windowOpened);
      } catch {
        setLoadingDocuments(false);
        setShowNoDocumentsFound(true);
        setTimeout(() => setShowNoDocumentsFound(false), 3500);
      }
    }
  };

  async function requestBOL() {
    setSendingBOLRequest(true);
    setRequestBOLSent(false);
    const params = getNewTabParams();
    trackPageView('TRACK_ORDERS', 'REQUEST_BOL', {
      customerId: order.customerNumber,
    });
    await Axios.post(
      `/portal/resources/billOfLading/requestSigned/${params.documentNumber}/${params.documentPosition}`
    );
    setSendingBOLRequest(false);
    setRequestBOLSent(true);
  }

  function addQueryParams(queryParams: QueryParam[], id: string) {
    queryParams.push({
      documentType: 'documentTypes',
      documentId: id,
    });
  }

  function printDocs() {
    trackPageView('TRACK_ORDERS', 'DOWNLOAD_DOCUMENTS', {
      customerId: order.customerNumber,
    });

    const queryParams: QueryParam[] = [];
    if (printOrderConfirm) {
      addQueryParams(queryParams, DocumentTypes.ORDER_CONFIRMATION);
    }

    if (printWeightList) {
      trackPageView('TRACK_ORDERS', 'PRINT_WEIGHT_LIST', {
        customerId: order.customerNumber,
      });
      addQueryParams(queryParams, DocumentTypes.WEIGHTLIST);
    }

    if (printInvoice) {
      addQueryParams(queryParams, DocumentTypes.INVOICE);
    }
    if (printCertificate) {
      addQueryParams(queryParams, DocumentTypes.CERTIFICATE_OF_ANALYSIS);
    }

    setLoadingDocuments(true);
    printDocuments(queryParams);
  }

  const showPrintWeightList = (): boolean => {
    return getActiveState(order, 1, isOrderDetailsPage);
  };
  const showPrintInvoice = (): boolean => {
    return getActiveState(order, 2, isOrderDetailsPage);
  };
  const showDownloadPackingList = (): boolean => {
    return getActiveState(order, 1, isOrderDetailsPage);
  };
  const showFileComplaint = (): boolean => {
    return getActiveState(order, 2, isOrderDetailsPage);
  };
  const showViewInvoice = (): boolean => {
    return getActiveState(order, 2, isOrderDetailsPage);
  };
  const showViewDeliveryDetails = (): boolean => {
    return getActiveState(order, 1, isOrderDetailsPage);
  };
  const showPrintCertificate = (): boolean => {
    return getActiveState(order, 4, isOrderDetailsPage);
  };

  const showDownload = (): boolean => {
    return getActiveState(order, 1, isOrderDetailsPage);
  };

  function printButtonEnabled(): boolean {
    return (
      printOrderConfirm || printWeightList || printInvoice || printCertificate
    );
  }

  return (
    <div className="p-3">
      <h4 className=" bg-gray-dark100 text-white-white p-3 text-xl min-width-240">
        <Trans id="available_actions">Available Actions</Trans>
        <div
          key="actionsMenuPopoverIcon"
          className="fa fa-times text-2xl ml-32 mt-1"
          onClick={() => onClick()}
          onKeyDown={noop}
          role="button"
          aria-label={t`Close Confirmation Modal`}
          tabIndex={0}
        />
      </h4>
      <div className="p-3">
        {hasRole(AuthorityRole.ROLE_NA) &&
          hasRole(AuthorityRole.ROLE_ORDER_BOOKING) && (
            <Popover
              visible={showBasketPopUp}
              content={
                <OldBasketPopUp
                  onContinueShopping={() => setShowBasketPopUp(false)}
                  onViewBasket={() => history.push('/submitOrder')}
                />
              }
              overlayClassName="popover-remove-arrow"
            >
              <div className="text-lg leading-8">
                <Button theme="link" onClick={orderSimilar}>
                  <div className="flex">
                    <Trans id="availableActions.orderSimilar">
                      Order Similar
                    </Trans>
                    {addingToBasket && <LoadingSpinner className="mt-1 ml-2" />}
                  </div>
                </Button>
              </div>
            </Popover>
          )}
        {hasRole(AuthorityRole.ROLE_COMPLAINT_CREATE) && showFileComplaint() && (
          <div className="text-lg leading-8">
            <Button theme="link" onClick={fileComplaint}>
              <Trans id="order_booking.complaint.file">
                File a Claim
              </Trans>
            </Button>
          </div>
        )}
        {!isOrderDetailsPage && (
          <div className="text-lg leading-8">
            <Button theme="link" onClick={requestDetails}>
              <Trans id="availableActions.viewDetails">
                View Order Details
              </Trans>
            </Button>
          </div>
        )}
        {hasRole(AuthorityRole.ROLE_PRICING_DOCUMENTS) && showViewInvoice() && (
          <div className="text-lg leading-8">
            <Button theme="link" onClick={requestInvoice}>
              <Trans id="availableActions.viewInvoiceDetails">
                View Invoice Details
              </Trans>
            </Button>
          </div>
        )}
        {showViewDeliveryDetails() && (
          <div className="text-lg leading-8">
            <Button theme="link" onClick={requestDeliveryDetails}>
              <Trans id="availableActions.viewDeliveryDetails">
                View Delivery Details
              </Trans>
            </Button>
          </div>
        )}
      </div>
      <div className="py-1">
        <hr />
      </div>
      <div className="py-3 pl-3">
        {hasRole(AuthorityRole.ROLE_PRICING_DOCUMENTS) &&
          !(
            hasRole(AuthorityRole.ROLE_SHIP_TO) ||
            hasRole(AuthorityRole.ROLE_PROJECT_GROUP)
          ) && (
            <div className="flex">
              <div className="text-lg w-11/12">
                <Trans id="availableActions.printOrderConfirmation">
                  Print Order Confirmation
                </Trans>
              </div>
              <input
                type="checkbox"
                className="mt-2 ml-3"
                onChange={(e) => setPrintOrderConfirm(e.target.checked)}
              />
            </div>
          )}
        {!(
          hasRole(AuthorityRole.ROLE_SHIP_TO) ||
          hasRole(AuthorityRole.ROLE_PROJECT_GROUP)
        ) &&
          showPrintWeightList() && (
            <div className="flex">
              <div className="text-lg w-11/12">
                <Trans id="availableActions.printWeightlist">
                  Print Weightlist(s)
                </Trans>
              </div>
              <input
                type="checkbox"
                className="mt-2 ml-3"
                onChange={(e) => setPrintWeightList(e.target.checked)}
              />
            </div>
          )}
        {hasRole(AuthorityRole.ROLE_PRICING_DOCUMENTS) &&
          !(
            hasRole(AuthorityRole.ROLE_SHIP_TO) ||
            hasRole(AuthorityRole.ROLE_PROJECT_GROUP)
          ) &&
          showPrintInvoice() && (
            <div className="flex">
              <div className="text-lg w-11/12">
                <Trans id="availableActions.printInvoice">
                  Print Invoice(s)
                </Trans>
              </div>
              <input
                type="checkbox"
                className="mt-2 ml-3"
                onChange={(e) => setPrintInvoice(e.target.checked)}
              />
            </div>
          )}
        {hasRole(AuthorityRole.ROLE_EU) &&
          hasRole(AuthorityRole.ROLE_CONTAINER_LIST) &&
          !(
            hasRole(AuthorityRole.ROLE_SHIP_TO) ||
            hasRole(AuthorityRole.ROLE_PROJECT_GROUP)
          ) &&
          showDownloadPackingList() && (
            <div className="flex">
              <div className="text-lg w-11/12">
                <Trans id="availableActions.downloadPackingList">
                  Download Packing List
                </Trans>
              </div>
              <input
                type="checkbox"
                className="mt-2 ml-3"
                onChange={(e) => setDownloadPackingList(e.target.checked)}
              />
            </div>
          )}
        {hasRole(AuthorityRole.ROLE_PRICING_DOCUMENTS) &&
          !(
            hasRole(AuthorityRole.ROLE_SHIP_TO) ||
            hasRole(AuthorityRole.ROLE_PROJECT_GROUP)
          ) &&
          showPrintCertificate() && (
            <div className="flex">
              <div className="text-lg w-11/12">
                <Trans>Print Certificate of Analysis</Trans>
              </div>
              <input
                type="checkbox"
                className="mt-2 ml-3"
                onChange={(e) => setPrintCertificate(e.target.checked)}
              />
            </div>
          )}

        <div className="text-right leading-7 mt-4">
          <Button
            theme="primary"
            onClick={printDocs}
            className={styles.button}
            disabled={!printButtonEnabled()}
          >
            <i className={`fa fa-file-pdf ${styles.buttonIcon}`} />
            <div className={styles.buttonText}>
              <Trans id="icon.print">Print</Trans>
            </div>
          </Button>
          {showNoDocumentsFound && (
            <div className="mt-3 w-72 text-left">
              <Trans>None of the selected documents were found.</Trans>
            </div>
          )}

          {hasRole(AuthorityRole.ROLE_EU) &&
            !(
              hasRole(AuthorityRole.ROLE_SHIP_TO) ||
              hasRole(AuthorityRole.ROLE_PROJECT_GROUP)
            ) &&
            downloadPackingList &&
            showDownload() && (
              <>
                <span className="mr-1" />
                <Button
                  theme="primary"
                  className="mt-0.5"
                  onClick={downloadExcel}
                >
                  <div>
                    <Trans id="download">Download</Trans>
                  </div>
                </Button>
              </>
            )}
        </div>
      </div>
      {loadingDocuments && (
        <div>
          <Trans id="documents.loading">
            Loading documents, please wait...
          </Trans>
          <LoadingSpinner />
        </div>
      )}
      <div className="py-1">
        <hr />
      </div>
      {hasRole(AuthorityRole.ROLE_NA) &&
        !hasRole(AuthorityRole.ROLE_RELEASE) &&
        showSignedBOL(order, isOrderDetailsPage) && (
          <>
            <div className="text-lg leading-8">
              <Button theme="link" onClick={() => requestBOL()}>
                <Trans id="availableActions.requestSignedBillOfLading">
                  Request Signed Bill of Lading
                </Trans>
              </Button>
              {sendingBOLRequest && <LoadingSpinner ajax />}
              {requestBOLSent && (
                <div className="text-blue-pacific">
                  <Trans>Your request has been sent.</Trans>
                </div>
              )}
            </div>
          </>
        )}
    </div>
  );
};

export default ActionsMenuPopover;
