import { usePaginatedQuery } from 'react-query';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { EMPTY_OPTION_SELECT } from '../../../services/Util/emptyOption.const';
import Option from '../../../types/Option.type';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { getDiameters } from './Payload.service';
import { Diameter } from './types/Diameter.interface';
import { DiameterRequestParams } from './types/DiameterRequestParams.interface';

const DEFAULT_OPTION = [EMPTY_OPTION_SELECT];

const useDiameterOptions = (
  params: DiameterRequestParams
): { reelDiameterOptions: Option[]; coreDiameterOptions: Option[] } => {
  const { format } = useNumberFormatter();
  const { data: diameterResponse } = usePaginatedQuery(
    [QueryCacheName.CALC_DIAMETERS, params],
    () => getDiameters(params),
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: (params.brandMember || params.grade) && params.grammage,
    }
  );
  
  const reelDiameterOptions: Option[] = diameterResponse
    ? DEFAULT_OPTION.concat(
        ...diameterResponse.reelDiameter.map((resp: Diameter) => ({
          label: resp.reelDiameter,
          value: resp.reelDiameter,
        }))
      )
    : DEFAULT_OPTION;
  const coreDiameterOptions: Option[] = diameterResponse
    ? DEFAULT_OPTION.concat(
        ...diameterResponse.coreDiameter.map((resp: Diameter) => ({
          label: format(resp.coreDiameter),
          value: resp.coreDiameter,
        }))
      )
    : DEFAULT_OPTION;
    
  return {
    reelDiameterOptions,
    coreDiameterOptions,
  };
};

export default useDiameterOptions;
