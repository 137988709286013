import { Popover } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import OldBasketButton from '../../../components/OldBasketButton/OldBasketButton';
import useSamplesOrder from '../../../hooks/useSamplesOrder';
import AddedToBasketPopup from '../../Samples/OrderSamples/SearchResults/AddedToBasketPopUp';
import useSamplesBasketPopupStore from './useSamplesBasketPopup.store';

const SamplesBasketPopup: FunctionComponent = () => {
  const { data: order, isFetching: isFetchingOrder } = useSamplesOrder();
  const history = useHistory();
  const { lastAdded } = useSamplesBasketPopupStore();
  const [visible, setVisible] = useState(false);
  return (
    <Popover
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      placement="bottom"
      content={
        <AddedToBasketPopup
          onClose={() => setVisible(false)}
          order={order}
          orderIsFetching={isFetchingOrder}
          product={lastAdded}
        />
      }
    >
      <OldBasketButton
        disabled={order?.products.length === 0}
        onCaretClick={() => setVisible((state) => !state)}
        onButtonClick={() => history.push('/samples/selectBuyer')}
      />
    </Popover>
  );
};

export default SamplesBasketPopup;
