import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button/Button';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import useAnalytics from '../../../../hooks/useAnalytics';
import { getEuGrammageOptions } from '../../../../services/Brand';
import { EMPTY_OPTION_SELECT } from '../../../../services/Util/emptyOption.const';
import EuBrand from '../../../../types/EuBrand.interface';
import Option from '../../../../types/Option.type';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import useBrandOptions from '../../useBrandOptions';
import useCalculatorCountryOptions from '../../useCalculatorCountryOptions';
import { getPalletHeight } from '../Payload.service';
import { PayloadForm } from '../types/PayloadForm.interface';
import usePayloadStore from '../usePayloadStore';
import RollCriteria from './RollCriteria';
import SheetsCriteria from './SheetsCriteria';

const PRODUCT_TYPE_OPTIONS: Option[] = [
  EMPTY_OPTION_SELECT,
  {
    value: PutUpCategory.ROLL,
    label: t`Rolls`,
  },
  {
    value: PutUpCategory.SHEET,
    label: t`Sheets`,
  },
];
interface ICriteriaProps {
  onSubmit: (formData: PayloadForm, brands: EuBrand[]) => void;
  isSubmitting: boolean;
  itemInBasket: boolean;
}

const Criteria: React.FunctionComponent<ICriteriaProps> = ({
  onSubmit,
  isSubmitting,
  itemInBasket,
}) => {
  const {
    countryOptions,
    isFetching: isFetchingCountries,
  } = useCalculatorCountryOptions();
  const {
    register,
    watch,
    getValues,
    setValue,
    trigger,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<PayloadForm>({ mode: 'all' });
  const { trackPageView } = useAnalytics();
  const selectedProductType = watch('selectedProductType');
  const { brandOptions, rawBrands } = useBrandOptions(
    selectedProductType === PutUpCategory.SHEET
      ? { context: UnitOfMeasure.PL, productType: PutUpCategory.SHEET }
      : { context: UnitOfMeasure.RL }
  );
  const selectedBrandIndex = watch('selectedBrand');
  const selectedBrand =
    selectedBrandIndex && rawBrands ? rawBrands[+selectedBrandIndex] : null;
  const selectedGrammage = watch('ivGrammage');
  const grammageOptions = selectedBrand
    ? getEuGrammageOptions(
        rawBrands as EuBrand[],
        'grammages',
        selectedBrand.brand,
        t`Select`
      )
    : [EMPTY_OPTION_SELECT];
  const onProductTypeChange = async (productType: PutUpCategory) => {
    if (productType === PutUpCategory.SHEET) {
      const selectedCountry = getValues('ivCountryMaxPl');
      const result = await getPalletHeight(selectedCountry);
      setValue('ivPalletMaxHeight', result.palletHeight, {
        shouldValidate: true,
        shouldDirty: true,
      });
      trigger('ivPalletSheets');
    }
  };

  const onReset = () => {
    reset();
    trackPageView('CALCULATORS', 'RESET_PAYLOAD');
  };

  const onAdd = handleSubmit(async (data: PayloadForm) => {
    onSubmit(data, rawBrands as EuBrand[]);
  });

  return (
    <form onSubmit={onAdd} noValidate>
      <Space direction="vertical" size="middle" style={{ width: '350px' }}>
        <Trans>
          Select from the following to calculate the optimal number of rolls and
          pallets per container.
        </Trans>
        <InputLabel
          text={t`Destination Country / Max Payload`}
          size="lg"
          required
          isFetching={isFetchingCountries}
        >
          <Select
            name="ivCountryMaxPl"
            ref={register({ required: true })}
            options={countryOptions}
            required
            onChange={(e) =>
              usePayloadStore.setState({ country: e.target.value })
            }
            error={errors?.ivCountryMaxPl}
            disabled={isFetchingCountries || itemInBasket}
          />
        </InputLabel>
        <InputLabel text={t`Product Type`} size="lg" required>
          <Select
            name="selectedProductType"
            ref={register({ required: true })}
            onChange={(e) =>
              onProductTypeChange(e.target.value as PutUpCategory)
            }
            options={PRODUCT_TYPE_OPTIONS}
            required
            error={errors?.selectedProductType}
          />
        </InputLabel>
        <InputLabel text={t`Sales Grade`} size="lg" required>
          <Select
            name="selectedBrand"
            ref={register({ required: true })}
            options={brandOptions}
            required
            error={errors?.selectedBrand}
          />
        </InputLabel>
        <InputLabel text={t`Grammage`} size="lg" required>
          <Select
            name="ivGrammage"
            ref={register({ required: true })}
            options={grammageOptions}
            required
            disabled={!selectedBrandIndex}
            error={errors?.ivGrammage}
          />
        </InputLabel>
        {selectedProductType === PutUpCategory.ROLL && (
          <RollCriteria
            register={register}
            watch={watch}
            getValues={getValues}
            trigger={trigger}
            errors={errors}
            diameterParams={{
              brandMember: selectedBrand?.brandMember || '',
              grade: selectedBrand?.grade || '',
              grammage: selectedGrammage,
            }}
          />
        )}
        {selectedProductType === PutUpCategory.SHEET && (
          <SheetsCriteria
            register={register}
            getValues={getValues}
            trigger={trigger}
            watch={watch}
            errors={errors}
          />
        )}
        <Space size="middle" className="mt-8">
          <Button
            theme="primary"
            disabled={!isValid || isSubmitting}
            loading={isSubmitting}
          >
            <Trans>Add</Trans>
          </Button>
          <Button
            type="reset"
            theme="link"
            className="text-lg font-bold"
            onClick={() => onReset()}
          >
            <Trans>Reset</Trans>
          </Button>
        </Space>
      </Space>
    </form>
  );
};

export default Criteria;
