import { t, Trans } from '@lingui/macro';
import React from 'react';
import useCustomer from '../../../hooks/useCustomer';
import useFinancialSummary from '../../../hooks/useFinancialSummary';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import stripZeros from '../../../services/Util/stripZeros.util';

interface ICustomerInfo {
  customerNumber: string;
}

const CustomerInfo: React.FunctionComponent<ICustomerInfo> = ({
  customerNumber,
}) => {
  const { data: financialSummary, isFetching } = useFinancialSummary(
    customerNumber
  );
  const { format } = useNumberFormatter();
  const { getCustomerByNumber } = useCustomer();

  const customer = getCustomerByNumber(customerNumber);
  const customerName = customer?.name || '';
  const creditCurrency = financialSummary?.creditCurrency || '';
  const creditLimit = financialSummary?.creditLimit || null;
  const percentCreditUsed = financialSummary?.percentageUsed || null;
  const availableCredit = financialSummary?.availableCredit || null;
  const currentBalance =
    creditLimit !== null && availableCredit !== null
      ? creditLimit - availableCredit
      : null;

  let creditLimitStr = '';
  if (creditLimit !== null && creditCurrency) {
    creditLimitStr = `${format(creditLimit)} ${creditCurrency}`;
  }

  let availableCreditStr = '';
  if (availableCredit !== null && creditCurrency) {
    availableCreditStr = `${format(availableCredit)} ${creditCurrency}`;

    if (percentCreditUsed !== null) {
      const formattedPercentCreditAvailable = format(
        100 - percentCreditUsed,
        true
      );
      const percentCreditAvailableStr = t`(${formattedPercentCreditAvailable}%) remaining`;

      availableCreditStr = `${availableCreditStr} ${percentCreditAvailableStr}`;
    }
  }

  let currentBalanceStr = '';
  if (currentBalance !== null && creditCurrency) {
    currentBalanceStr = `${format(currentBalance)} ${creditCurrency}`;
  }

  if (!(customerNumber && financialSummary)) {
    return null;
  }

  return (
    <div className="border-t-8 border-gray-whiteSmoke400 px-11 py-6">
      {customerName && (
        <div className="mb-6">
          <div className="font-bold mb-1.5">
            <Trans>Customer Name</Trans>
          </div>
          <div>{customerName}</div>
        </div>
      )}
      {customerNumber && (
        <div className="mb-6">
          <div className="font-bold mb-1.5">
            <Trans>Customer Number</Trans>
          </div>
          <div>{stripZeros(customerNumber)}</div>
        </div>
      )}
      {creditLimitStr && (
        <div className="mb-6">
          <div className="font-bold mb-1.5">
            <Trans>Credit Limit</Trans>
          </div>
          <div>{creditLimitStr}</div>
        </div>
      )}
      {availableCreditStr && (
        <div className="mb-6">
          <div className="font-bold mb-1.5">
            <Trans>Available Credit</Trans>
          </div>
          <div>{availableCreditStr}</div>
        </div>
      )}
      {currentBalanceStr && (
        <div className="mb-6">
          <div className="font-bold mb-1.5">
            <Trans>Current Balance</Trans>
          </div>
          <div>{currentBalanceStr}</div>
        </div>
      )}
    </div>
  );
};

export default CustomerInfo;
