import { Trans } from '@lingui/macro';
import { Collapse } from 'antd';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import ExpandIcon from '../../../components/ExpandIcon';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../hooks/useAnalytics';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import useProductAvailabilityStore from '../useProductAvailabilityStore.store';
import AltStockResult from './AltStockResult';
import { ResultRow } from './ResultRow.interface';
import { cancellableQueryAltStock } from './Results.service';

const { Panel } = Collapse;

interface IAltStockResultsProps {
  data: ResultRow;
}

const AltStockResults: React.FunctionComponent<IAltStockResultsProps> = ({
  data,
}) => {
  const { trackPageView } = useAnalytics();
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [filteredResults, setFilteredResults] = useState<ResultRow[]>([]);
  const { customerSoldTo } = useProductAvailabilityStore();

  const { resolvedData: alternateStock, isFetching } = usePaginatedQuery(
    [
      QueryCacheName.PRODUCT_AVAILABILITY_ALT,
      {
        customerSoldTo,
        kcode: data.kcode,
      },
    ],
    cancellableQueryAltStock,
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: customerSoldTo && data.kcode && activeKey,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (alternateStock?.stock?.length) {
      const filtered = alternateStock?.stock.filter(
        (item) => item.kcode !== data.kcode
      );
      setFilteredResults(
        orderBy(filtered, [
          'brand',
          'stockAvailabilities[0].grammage',
          'stockAvailabilities[0].widthInches',
          'stockAvailabilities[0].reelWidthInches',
          'stockAvailabilities[0].lengthInches',
          'stockAvailabilities[0].reelDiameterInches',
          'usBaseWeight',
        ])
      );
    }
  }, [alternateStock, data.kcode]);

  return (
    <div className="border border-gray-light400 m-4">
      <Collapse
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key as string);
          trackPageView('PRODUCT_AVAILABILITY', 'ALTERNATIVES', {
            customerId: customerSoldTo,
          });
        }}
        expandIcon={({ isActive }) => (
          <ExpandIcon
            isActive={isActive}
            iconClassName="relative top-1 -left-1 text-blue-pacific"
          />
        )}
        ghost
      >
        <Panel
          key="ALT_STOCK"
          header={<h2 className="text-3xl w-full">Alternative Stock</h2>}
        >
          {isFetching ? (
            <LoadingSpinner className="mb-8" horizontal />
          ) : (
            <>
              {filteredResults.length ? (
                filteredResults.map((result) => (
                  <AltStockResult
                    key={`${result.kcode}`}
                    data={result}
                    compareStockData={data.stockAvailabilities[0]}
                  />
                ))
              ) : (
                <div className="w-full text-center my-5">
                  <Trans>
                    No results found. Please contact your Sappi Inside Sales
                    representative at 800.224.4391
                  </Trans>
                </div>
              )}
            </>
          )}
        </Panel>
      </Collapse>
    </div>
  );
};

export default AltStockResults;
