import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import { Popover } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { map } from 'lodash';
import React, { useState } from 'react';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import BasketButton from '../../../components/BasketButton/BasketButton';
import BasketPopUp from '../../../components/BasketPopUp/BasketPopUp';
import BasketPopupItem from '../../../components/BasketPopUp/BasketPopUpItem';
import useBasket from '../../../hooks/useBasket';
import useBasketMutation from '../../../hooks/useBasketMutation';
import useCustomerBasket from '../../../hooks/useCustomerBasket';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import useMeasurementUnit from '../../../hooks/useMeasurementUnit';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import {
  removeItemFromBasket,
  submitNaBasketsOrder,
} from '../../../services/Basket';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import { AvailabilityBasketItem } from '../../../types/AvailabilityBasketItem.interface';
import { AvailabilityBasketNA } from '../../../types/AvailabilityBasketNA.interface';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import useSappiBasketStore from './sappiBasket.store';
import SappiBasketOrderConfirmedAlert from './SappiBasketOrderConfirmedAlert';
import useAnalytics from '../../../hooks/useAnalytics';

interface ISappiBasketProps {
  attr?: string;
}

const SappiBasket: React.FunctionComponent<ISappiBasketProps> = () => {
  const queryCache = useQueryCache();
  const { data: baskets } = useBasket();
  const { trackPageView } = useAnalytics();
  const { loading, localLoadingOnly } = useSappiBasketStore();
  const [clicked, setClicked] = useState(false);
  const [submittingOrder, setSubmittingOrder] = useState(false);
  const { measurementUnit1, measurementUnit2 } = useMeasurementUnit();
  const { measurementSystem } = useMeasurementSystem();
  const { getCustomerBasket } = useCustomerBasket();
  const [updateNaBasket] = useBasketMutation(true);
  // const [updateEuBasket] = useEuBasketUpdateMutation();
  const { format } = useNumberFormatter();

  const history = useHistory();

  const getSize = (item: AvailabilityBasketItem) => {
    if (item.productType === PutUpCategory.ROLL) {
      return measurementSystem === MeasurementSystem.METRIC
        ? `${format(item.reelWidth)} ${measurementUnit1}`
        : `${item.reelWidthImp} ${measurementUnit1}`;
    }
    return measurementSystem === MeasurementSystem.METRIC
      ? `${format(item.width)} ${measurementUnit1} x ${format(
          item.length
        )} ${measurementUnit1}`
      : `${item.widthImp} ${measurementUnit1} x ${item.lengthImp} ${measurementUnit1}`;
  };

  const getCaliper = (item: AvailabilityBasketItem) => {
    return measurementSystem === MeasurementSystem.METRIC
      ? `${item.caliperMils}`
      : `${item.caliper}`;
  };

  const getItemTotal = (item: AvailabilityBasketItem) => {
    const firstSection = `${format(item.requestedQuantity)} ${
      item.requestedUnitOfMeasure
    }`;
    if (item.sheetsPerPalletNa) {
      return `${firstSection} / ${format(item.sheetsPerPalletNa)} SH`;
    }
    return firstSection;
  };

  const goToBasketPage = () => {
    // TODO: PHASE 2
    // const path = isNonReleaseEuUser
    //   ? `/basket?source=order-booking`
    //   : `/submitOrder`;
    trackPageView('MODAL_BASKET', 'OPEN_BASKET');
    history.push('/submitOrder?quickOrder=true');
  };

  const closePopover = () => {
    setClicked(false);
  };

  const submitOrderForAllCustomerBaskets = async () => {
    setSubmittingOrder(true);
    const submittedBaskets = await submitNaBasketsOrder(
      baskets as AvailabilityBasketNA[]
    );
    setSubmittingOrder(false);
    closePopover();
    queryCache.invalidateQueries(QueryCacheName.BASKET);
    const { destroy } = confirm({
      maskTransitionName: 'BOOTSTRAP_FIX',
      modalRender() {
        return (
          <I18nProvider i18n={i18n}>
            <SappiBasketOrderConfirmedAlert
              baskets={submittedBaskets}
              onConfirm={destroy}
              onConfirmPrint={window.print}
            />
          </I18nProvider>
        );
      },
    });
  };

  const doRemoveNaBasketItem = (id: number, customerNumber: string) => {
    const customerBasket = getCustomerBasket(customerNumber);
    if (customerBasket) {
      updateNaBasket(removeItemFromBasket(customerBasket, id));
      if (customerBasket.items.length < 2) {
        closePopover();
      }
    }
  };

  // Phase 2
  // const doRemoveEuBasketItem = (id: number, customerNumber: string) => {
  //   const customerBasket = getEuCustomerBasket(customerNumber);
  //   if (customerBasket) {
  //     updateEuBasket(markEuItemForDeletion(customerBasket, id));
  //     if (customerBasket.items.length < 2) {
  //       closePopover();
  //     }
  //   }
  // };

  const getTotalQuantity = (
    basket: AvailabilityBasketNA | AvailabilityBasketEU
  ) => {
    let totalQuantity = 0;
    // PHASE 2
    // if (isNonReleaseEuUser) {
    //   const euBasket = basket as AvailabilityBasketEU;
    //   totalQuantity = euBasket.items
    //     .map((i) => i.requestedQuantityKg || 0)
    //     .reduce((p, c) => p + c);
    // } else {
    //   const naBasket = basket as AvailabilityBasketNA;
    //   totalQuantity =
    //     measurementSystem === MeasurementSystem.METRIC
    //       ? naBasket.totalQuantityKg
    //       : naBasket.totalQuantityLb;
    // }

    const naBasket = basket as AvailabilityBasketNA;
    totalQuantity =
      measurementSystem === MeasurementSystem.METRIC
        ? naBasket.totalQuantityKg
        : naBasket.totalQuantityLb;
    return totalQuantity;
  };

  const getBasketCount = () => {
    return baskets
      ? (baskets as { items: AvailabilityBasketItem[] }[])
          .map((b) => b.items.length)
          .reduce((p: number, c: number) => p + c, 0)
      : 0;
  };

  return (
    <>
      <Popover
        trigger="click"
        visible={clicked}
        onVisibleChange={setClicked}
        content={
          <BasketPopUp
            title={t`Basket`}
            onPrimaryAction={submitOrderForAllCustomerBaskets}
            onSecondaryAction={goToBasketPage}
            onClose={closePopover}
            primaryActionLoading={submittingOrder}
            primaryActionDisabled={!(baskets && baskets.length) || submittingOrder}
          >
            {baskets && baskets.length ? (
              map<AvailabilityBasketNA | AvailabilityBasketEU>(
                baskets,
                (b: AvailabilityBasketNA | AvailabilityBasketEU) =>
                  b.items.length ? (
                    <BasketPopUp.Item
                      key={b.id}
                      customer={
                        (b as AvailabilityBasketNA).soldToCustomer.name
                        // PHASE 2
                        // isNonReleaseEuUser
                        //   ? (b as AvailabilityBasketEU).soldTo.name
                        //   : (b as AvailabilityBasketNA).soldToCustomer.name
                      }
                      numItems={b.items.length}
                      totalQuantity={getTotalQuantity(b)}
                      totalQuantityUnit={measurementUnit2}
                    >
                      {b.items.map((item) => (
                        <BasketPopupItem.Row
                          id={item.id || -1}
                          key={item.id}
                          product={
                            item.product || item.txtGradeBrandMember || ''
                          }
                          sku={item.skuCode || item.material || ''}
                          type={item.productType}
                          grammage={item.grammage}
                          bookWeight={item.bookweight}
                          tradeBasis={item.tradeBasis || ''}
                          size={getSize(item)}
                          caliper={getCaliper(item)}
                          total={getItemTotal(item)}
                          onRemove={
                            (id: number) =>
                              doRemoveNaBasketItem(
                                id,
                                (b as AvailabilityBasketNA).soldToCustomer
                                  .number
                              )
                            // PHASE 2
                            // isNonReleaseEuUser
                            //   ? doRemoveEuBasketItem(
                            //       id,
                            //       (b as AvailabilityBasketEU).soldTo.number
                            //     )
                            //   : doRemoveNaBasketItem(
                            //       id,
                            //       (b as AvailabilityBasketNA).soldToCustomer
                            //         .number
                            //     )
                          }
                        />
                      ))}
                    </BasketPopUp.Item>
                  ) : (
                    <></>
                  )
              )
            ) : (
              <div className="m-6 text-center">
                <strong>
                  <Trans>Empty</Trans>
                </strong>
              </div>
            )}
          </BasketPopUp>
        }
      >
        <div>
          <BasketButton
            loading={loading || localLoadingOnly}
            count={getBasketCount()}
          />
        </div>
      </Popover>
    </>
  );
};

export default SappiBasket;
