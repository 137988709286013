import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import {
  getCustomersFromSamplesSearch,
  getSamplesOrders,
  getStatusesFromSamplesSearch,
} from '../../../services/SamplesHistory';
import Customer from '../../../types/Customer.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { AugmentedSamplesHistoryOrder } from '../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import { GetSamplesOrdersParams } from '../../../types/Samples/GetSamplesOrdersParams.interface';
import { getParamsFromFilters, transformOrders } from './ViewSampleOrders.util';
import ViewSampleOrdersFilters, {
  EMPTY_FILTERS,
  FilterFormData,
} from './ViewSampleOrdersFilters';
import ViewSampleOrdersTable from './Table/ViewSampleOrdersTable';

const ViewSampleOrders: React.FunctionComponent = () => {
  const [filters, setFilters] = useState<FilterFormData>(EMPTY_FILTERS);
  const [paramsForGettingOrders, setParamsForGettingOrders] = useState<
    GetSamplesOrdersParams
  >({});
  const [orders, setOrders] = useState<AugmentedSamplesHistoryOrder[]>([]);
  const [statuses, setStatuses] = useState<string[]>();
  const [customers, setCustomers] = useState<Customer[]>();

  useEffect(() => {
    setParamsForGettingOrders(getParamsFromFilters(filters));
  }, [filters]);

  const { isFetching, resolvedData: samplesOrdersResponse } = usePaginatedQuery(
    [QueryCacheName.SAMPLES_ORDERS_HISTORY, paramsForGettingOrders],
    () => getSamplesOrders(paramsForGettingOrders),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (samplesOrdersResponse) {
      const fetchCustomers = async () => {
        const fetchedCustomers = await getCustomersFromSamplesSearch();
        setCustomers(fetchedCustomers);
      };
      const fetchStatuses = async () => {
        const fetchedStatuses = await getStatusesFromSamplesSearch();
        setStatuses(fetchedStatuses);
      };

      fetchCustomers();
      fetchStatuses();
    }
  }, [samplesOrdersResponse]);

  useEffect(() => {
    const untransformedOrders = samplesOrdersResponse?.items || [];

    const transformedOrders =
      untransformedOrders.length > 0
        ? transformOrders(untransformedOrders)
        : untransformedOrders;

    setOrders(transformedOrders);
  }, [samplesOrdersResponse]);

  return (
    <div>
      <ViewSampleOrdersFilters
        customers={customers}
        statuses={statuses}
        onUpdateFilters={setFilters}
        loading={isFetching}
      />
      <ViewSampleOrdersTable orders={orders} loading={isFetching} />
    </div>
  );
};

export default ViewSampleOrders;
