import { some } from 'lodash';
import { QueryResult, useQuery } from 'react-query';
import { getDirectEuCustomers } from '../services/Customer';
import getUser from '../services/User';
import { AuthorityRole } from '../types/Authority.interface';
import QueryCacheName from '../types/QueryCacheName.enum';
import User from '../types/User/User.interface';

const useUser = (): QueryResult<User | undefined> => {
  return useQuery(
    [QueryCacheName.USER],
    async () => {
      try {
        const user = await getUser();
        const isEU = some(user?.authorities, {
          authority: AuthorityRole.ROLE_EU,
        });
        const isRelease = some(user?.authorities, {
          authority: AuthorityRole.ROLE_RELEASE,
        });
        if (isEU && !isRelease) {
          user.euDirectCustomers = await getDirectEuCustomers();
        }
        return user;
      } catch (e) {
        return undefined;
      }
    },
    {
      staleTime: 300000, // 5 min
    }
  );
};

export default useUser;
