import { t } from '@lingui/macro';
import { concat } from 'lodash';
import create from 'zustand';
import Customer from '../../../types/Customer.interface';
import Option from '../../../types/Option.type';

const DEFAULT_CUSTOMER: Customer = {
  id: 0,
  version: 0,
  dateCreated: null,
  lastUpdated: null,
  number: '',
  name: '',
  name2: '',
  city: '',
  address: '',
  postalCode: '',
  countryAbr: '',
  country: '',
  salesOrg: '',
  distributionChannel: '',
  division: '',
  orderBooking: '',
  reservationBooking: '',
  release: '',
  stockLot: false,
  millDirect: false,
  consignment: false,
  quickOrder: false,
  samples: false,
  showStock: false,
  showMillInventory: false,
  showProductionPlanning: false,
};

type CustomerDeliveryOptionsState = {
  defaultCustomerOptions: Option[];
  defaultDeliveryOptions: Option[];
  setDeliveryOptions: (options: Option[], isExternalUser?: boolean) => void;
  setCustomerOptions: (options: Option[]) => void;
  reset: () => void;
  resetDeliveryOptions: () => void;
  resetCustomerOptions: () => void;
  searchDeliveryFormValid: boolean;
  setSearchDeliveryFormValid: (searchDeliveryFormValid: boolean) => void;
  customerLocationAsDeliveryChecked: boolean;
  setCustomerLocationAsDeliveryChecked: (
    customerLocationAsDeliveryChecked: boolean
  ) => void;
  selectedDelivery: Customer;
  setSelectedDelivery: (selectedDelivery?: Customer) => void;
  selectedCustomer: Customer;
  setSelectedCustomer: (selectedCustomer?: Customer) => void;
};

export const NEW_ADDRESS_OPTION_VALUE = '1';
const NEW_ADDRESS_OPTION: Option = {
  label: t`NEW ADDRESS`,
  value: NEW_ADDRESS_OPTION_VALUE,
};
const DEFAULT_DELIVERY_OPTIONS: Option[] = [
  { label: '', value: '' },
  NEW_ADDRESS_OPTION,
];

const DEFAULT_CUSTOMER_OPTIONS: Option[] = [{ label: '', value: '' }];

const useCustomerDeliveryOptionsStore = create<CustomerDeliveryOptionsState>(
  (set) => ({
    defaultCustomerOptions: DEFAULT_CUSTOMER_OPTIONS,
    defaultDeliveryOptions: DEFAULT_DELIVERY_OPTIONS,
    resetDeliveryOptions: () =>
      set((state) => ({
        ...state,
        defaultDeliveryOptions: DEFAULT_DELIVERY_OPTIONS,
      })),
    resetCustomerOptions: () =>
      set((state) => ({
        ...state,
        defaultCustomerOptions: DEFAULT_CUSTOMER_OPTIONS,
      })),
    setDeliveryOptions: (options: Option[], isExternalUser?: boolean) => {
      let defaultDeliveryOptions: Option[] = [];
      if (!isExternalUser) {
        defaultDeliveryOptions =
          options.length > 0
            ? concat([NEW_ADDRESS_OPTION], options)
            : DEFAULT_DELIVERY_OPTIONS;
      } else {
        defaultDeliveryOptions =
          options.length > 0
            ? concat(options, [NEW_ADDRESS_OPTION])
            : DEFAULT_DELIVERY_OPTIONS;
      }
      set((state) => ({
        ...state,
        defaultDeliveryOptions,
      }));
    },
    setCustomerOptions: (options: Option[]) =>
      set((state) => ({
        ...state,
        defaultCustomerOptions:
          options.length > 0 ? options : DEFAULT_CUSTOMER_OPTIONS,
      })),
    searchDeliveryFormValid: false,
    setSearchDeliveryFormValid: (searchDeliveryFormValid: boolean) =>
      set({ searchDeliveryFormValid }),
    customerLocationAsDeliveryChecked: false,
    setCustomerLocationAsDeliveryChecked: (
      customerLocationAsDeliveryChecked: boolean
    ) => set({ customerLocationAsDeliveryChecked }),
    selectedDelivery: DEFAULT_CUSTOMER,
    setSelectedDelivery: (selectedDelivery?: Customer) =>
      set((state) => ({
        ...state,
        selectedDelivery,
      })),
    selectedCustomer: DEFAULT_CUSTOMER,
    setSelectedCustomer: (selectedCustomer?: Customer) =>
      set((state) => ({
        ...state,
        selectedCustomer,
      })),
    reset: () =>
      set((state) => ({
        ...state,
        defaultCustomerOptions: DEFAULT_CUSTOMER_OPTIONS,
        defaultDeliveryOptions: DEFAULT_DELIVERY_OPTIONS,
        searchDeliveryFormValid: false,
        customerLocationAsDeliveryChecked: false,
        selectedDelivery: DEFAULT_CUSTOMER,
        selectedCustomer: DEFAULT_CUSTOMER,
      })),
  })
);

export default useCustomerDeliveryOptionsStore;
