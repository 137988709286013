import { t, Trans } from '@lingui/macro';
import { ModalProps } from 'antd/lib/modal';
import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import {
  getOrderDetails,
  getStatusByStatusId,
  getStatusList,
  orderWeightIsNominal,
} from '../../services/Order';
import { Order } from '../../types/Order.interface';
import Date from '../Date';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Modal from '../Modal/Modal';
import OrderDetailsModalDetailRow from './OrderDetailsModalDetailRow';
import './OrderDetailsModal.scss';
import stripZeros from '../../services/Util/stripZeros.util';
import { OrderStatus } from '../../types/OrderStatus.interface';

interface IOrderDetailsModalProps extends ModalProps {
  visible: boolean;
  documentNumber?: string;
  documentPosition?: string;
  onClose: () => void;
}

const OrderDetailsModal: React.FunctionComponent<IOrderDetailsModalProps> = ({
  visible,
  documentNumber,
  documentPosition,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const [statusText, setStatusText] = useState<string>();

  useEffect(() => {
    async function loadOrderInfo(docNum: string, docPos: string) {
      setOrder(undefined);
      setLoading(true);

      const loadedOrder = await getOrderDetails(docNum, docPos);
      setOrder(loadedOrder);
      setLoading(false);

      const allStatuses = await getStatusList();

      const loadedStatus = await getStatusByStatusId(
        loadedOrder?.overview?.status
      );

      const statusOption = find<OrderStatus>(allStatuses, {
        status: loadedStatus?.status,
      });

      setStatusText(statusOption ? statusOption.text : loadedStatus?.text);
    }

    if (documentNumber && documentPosition) {
      loadOrderInfo(documentNumber, documentPosition);
    }
  }, [documentNumber, documentPosition]);
  const sappiOrderNum = order?.documentNumber;
  const customerOrderNum = order?.customerOrderNumber;
  const deliveryLocation = order?.overview?.shipToPartyName;
  const shipToPartyCity = order?.overview?.shipToPartyCity;

  const requestedDeliverDate = (
    <>
      {order?.requestedDeliveryDate && (
        <Date date={order?.requestedDeliveryDate} />
      )}
    </>
  );
  const confirmedDeliveryDate = (
    <>{order?.deliveryDate && <Date date={order?.deliveryDate} />}</>
  );
  const updatedDeliveryDate = (
    <>
      {order?.overview?.deliveryDateUpd && (
        <Date date={order?.overview?.deliveryDateUpd} />
      )}
    </>
  );

  const formattedStatus = (
    <>
      {statusText && (
        <span className="uppercase text-orange-orange">{statusText}</span>
      )}
    </>
  );

  const brand = order?.materialDescription;
  const basisWeight = order?.zzcogrm;
  const width = order?.confirmedWidth;
  const length = order?.confirmedLength;
  const sappiSku = order?.overview?.kcode;
  const custMaterialNumber = order?.materialNumber;
  const orderedQuantity = order?.quantity;
  const weightIsNominal = orderWeightIsNominal(order?.nominalWeightFlag);
  const quantityProduced = order?.quantityProduced;
  let formattedQuantProd;
  if (quantityProduced) {
    formattedQuantProd = weightIsNominal
      ? t`${quantityProduced} (nominal / gross / sheet count / MSF)`
      : (formattedQuantProd = t`${quantityProduced} (net / gross / sheet count / MSF)`);
  }

  return (
    <Modal
      visible={visible}
      title={t`Order Details`}
      onCancel={onClose}
      width="970px"
      className="sappi-ant-override-order-details-modal"
    >
      {loading && <LoadingSpinner horizontal />}
      {!loading && !order && (
        <Trans>Order details could not be loaded at this time.</Trans>
      )}
      {order && (
        <div className="text-gray-dark400">
          <dl className="mb-4">
            <OrderDetailsModalDetailRow
              label={t`Sappi Order #`}
              value={`${stripZeros(sappiOrderNum || '')}/${stripZeros(
                documentPosition || ''
              )}`}
            />
            <OrderDetailsModalDetailRow
              label={t`My Order #`}
              value={customerOrderNum}
            />
            <OrderDetailsModalDetailRow
              label={t`Delivery Location`}
              value={`${deliveryLocation} - ${shipToPartyCity}`}
            />
          </dl>
          <dl className="mb-4">
            <OrderDetailsModalDetailRow
              label={t`Requested Delivery Date`}
              value={requestedDeliverDate}
            />
            <OrderDetailsModalDetailRow
              label={t`Confirmed Delivery Date`}
              value={confirmedDeliveryDate}
            />
            <OrderDetailsModalDetailRow
              label={t`Updated Delivery Date`}
              value={updatedDeliveryDate}
            />
          </dl>
          <dl className="mb-4">
            <OrderDetailsModalDetailRow
              label={t`Status`}
              value={formattedStatus}
            />
          </dl>
          <dl className="mb-4">
            <OrderDetailsModalDetailRow label={t`Brand`} value={brand} />
            <OrderDetailsModalDetailRow
              label={t`Basis Weight`}
              value={basisWeight}
            />
            <OrderDetailsModalDetailRow label={t`Width`} value={width} />
            <OrderDetailsModalDetailRow label={t`Length`} value={length} />
            <OrderDetailsModalDetailRow
              label={t`Sappi SKU #`}
              value={sappiSku}
            />
            <OrderDetailsModalDetailRow
              label={t`Customer Material #`}
              value={custMaterialNumber}
            />
            <OrderDetailsModalDetailRow
              label={t`Order Quantity`}
              value={orderedQuantity}
            />
            <OrderDetailsModalDetailRow
              label={t`Quantity Produced`}
              value={formattedQuantProd}
            />
          </dl>
        </div>
      )}
    </Modal>
  );
};

export default OrderDetailsModal;
