import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import React, { FC, useRef } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Button } from '../Button/Button';
import Input from '../Input/Input';
import Tag from '../Tag/Tag';
import {
  addToStringifiedArray,
  removeFromStringifiedArray,
} from './TagInput.util';

interface ITagInputProps {
  name: string;
  register: UseFormMethods['register'];
  reset: UseFormMethods['reset'];
  getValues: UseFormMethods['getValues'];
  watch: UseFormMethods['watch'];
  formState: UseFormMethods['formState'];
  onChange?: (value: string) => void;
  validate?: (value: string) => boolean | string;
  placeHolder?: string;
}

const TagInput: FC<ITagInputProps> = ({
  name,
  register,
  validate = () => true,
  reset,
  getValues,
  watch,
  formState,
  onChange,
  placeHolder,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const TAG_VALUE_INPUT = `${name}Values`;
  const { dirtyFields, errors } = formState;
  const tags = watch(TAG_VALUE_INPUT);

  const doAddTag = (tagToAdd: string) => {
    const values = getValues();
    const newValue = addToStringifiedArray(values[TAG_VALUE_INPUT], [tagToAdd]);
    reset(
      {
        ...values,
        [name]: '',
        [TAG_VALUE_INPUT]: newValue,
      },
      { isDirty: true }
    );
    if (onChange) onChange(newValue);
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  const removeTag = (tagToRemove: string): void => {
    const values = getValues();
    const newValue = removeFromStringifiedArray(values[TAG_VALUE_INPUT], [
      tagToRemove,
    ]);
    reset(
      {
        ...values,
        [TAG_VALUE_INPUT]: newValue,
      },
      { isDirty: true }
    );
    if (onChange) onChange(newValue);
  };

  return (
    <div {...rest}>
      <input
        name={TAG_VALUE_INPUT}
        ref={register()}
        type="string"
        className="hidden"
      />
      {tags && (
        <div className="mb-2">
          {JSON.parse(tags).map((tagValue: string) => (
            <Tag
              closable
              onClose={() => {
                removeTag(tagValue);
              }}
              key={tagValue}
            >
              {tagValue}
            </Tag>
          ))}
        </div>
      )}
      <div className="mb-2">
        <div className="flex items-center">
          <Input
            name={name}
            ref={(inputElement: HTMLInputElement) => {
              register(inputElement, {
                validate,
              });
              inputRef.current = inputElement;
            }}
            placeholder={placeHolder}
          />
          <Button
            theme="primary-tiny"
            className="ml-4 w-20 h-10"
            onClick={() => doAddTag(getValues(name))}
            disabled={
              !dirtyFields[name] || (!isEmpty(errors[name]) && errors[name])
            }
          >
            <Trans>Add</Trans>
          </Button>
        </div>
        {errors[name] && (
          <div className="text-red-red">{errors[name].message}</div>
        )}
      </div>
    </div>
  );
};

export default TagInput;
