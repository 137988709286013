import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Radio from '../../../../components/Radio/Radio';
import Select from '../../../../components/Select/Select';
import useRole from '../../../../hooks/useRole';
import getCouriers from '../../../../services/Courier';
import Option from '../../../../types/Option.type';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { getCourierOptions } from './ShippingInstructions.util';
import ShippingType from './ShippingType.enum';

interface IShippingInstructionsCellLeftProps {
  defaultIsCustomCourier?: boolean;
  defaultShippingType?: ShippingType;
  defaultCustomCourierAccountNumber?: string;
  readonly?: boolean;
}

const ShippingInstructionsCellLeft: React.FunctionComponent<IShippingInstructionsCellLeftProps> = ({
  defaultIsCustomCourier,
  defaultShippingType,
  defaultCustomCourierAccountNumber,
  readonly,
}) => {
  const { register, watch } = useFormContext() || { watch: () => {} }; // watch: () => {} Used for readonly version
  const { data: couriers } = useQuery(QueryCacheName.COURIERS, getCouriers, {
    staleTime: Infinity,
    keepPreviousData: true,
  });
  const [courierOptions, setCourierOptions] = useState<Option[]>([]);
  const customCourier = readonly
    ? defaultIsCustomCourier
    : watch('customCourier');
  const expressShipping = readonly
    ? defaultShippingType
    : watch('expressShipping');
  const { isInternalUser, isFetching } = useRole();

  useEffect(() => {
    setCourierOptions(getCourierOptions(couriers?.courierList || []));
  }, [couriers]);

  // TODO: Refactor into smaller components that are only dependent on readonly
  return (
    <Space direction="vertical">
      {readonly ? (
        <>
          <div>{capitalize(defaultShippingType)}</div>
          {defaultIsCustomCourier && (
            <div>
              <div>
                <Trans>Use own courier</Trans>
              </div>
              <div>
                <Trans>Account Number</Trans>:{' '}
                {defaultCustomCourierAccountNumber}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <Checkbox
            name="customCourier"
            label={t`Use own courier`}
            ref={register}
            defaultChecked={defaultIsCustomCourier}
          />
          {!isFetching && (
            <>
              {isInternalUser && (
                <Radio
                  name="expressShipping"
                  value={ShippingType.EXPRESS}
                  defaultChecked={defaultShippingType === ShippingType.EXPRESS}
                  ref={register}
                  label={t`Express`}
                />
              )}
              <Radio
                name="expressShipping"
                value={ShippingType.STANDARD}
                defaultChecked={defaultShippingType === ShippingType.STANDARD}
                ref={register}
                label={t`Standard`}
              />
            </>
          )}
          {!isFetching && customCourier && (
            <Select
              name="customCourierSelect"
              ref={register({ required: true })}
              defaultValue={defaultCustomCourierAccountNumber}
              options={courierOptions}
              required
            />
          )}
        </>
      )}
      {!isFetching && (
        <div className="mt-3">
          {!isInternalUser && (
            <div>
              <i>
                <Trans>
                  If faster delivery is needed, please contact your Sappi
                  Representative.
                </Trans>
              </i>
            </div>
          )}
          {isInternalUser && expressShipping === ShippingType.EXPRESS && (
            <div className="text-red-red">
              <Trans>Express shipping will incur additional charges.</Trans>
            </div>
          )}
        </div>
      )}
    </Space>
  );
};

export default ShippingInstructionsCellLeft;
