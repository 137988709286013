import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../hooks/useAnalytics';
import EuBrand from '../../../types/EuBrand.interface';
import BasketSection from './BasketSection';
import Criteria from './Criteria/Criteria';
import { mapFormToRequest } from './Criteria/Criteria.util';
import {
  calculatePayloadForAllContainerTypes,
  updateBasketInfo,
} from './Payload.service';
import {
  getPlansForContainerNumber,
  payloadOrderToRequest,
} from './PayloadCalculator.util';
import Results from './Results/Results';
import { AllContainerTypeResults } from './types/AllContainerTypeResults.interface';
import ContainerType from './types/ContainerType.enum';
import { LoadPlan } from './types/LoadPlan.interface';
import { PayloadBasketRequest } from './types/PayloadBasketRequest.interface';
import { PayloadBasketResponse } from './types/PayloadBasketResponse.interface';
import { PayloadForm } from './types/PayloadForm.interface';
import { PayloadOrder } from './types/PayloadOrder.interface';
import usePayloadStore from './usePayloadStore';

const PayloadCalculator: FC = () => {
  const [
    curentTabResults,
    setCurrentTabResults,
  ] = useState<PayloadBasketResponse | null>(null);
  const [allTabResults, setAllTabResults] = useState<AllContainerTypeResults>();
  const {
    addOrder,
    updateOrder,
    country,
    orders: storedOrders,
  } = usePayloadStore();
  const { trackPageView } = useAnalytics();
  const [isAdding, setIsAdding] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [error, setError] = useState('');
  const [loadPlans, setLoadPlans] = useState<LoadPlan[]>([]);

  const onContainerTypeChange = (type: ContainerType) => {
    if (allTabResults) {
      setCurrentTabResults(allTabResults[type]);
      setLoadPlans(getPlansForContainerNumber(1, curentTabResults));
    }
  };

  const onContainerSelect = (containerNumber: number) => {
    setLoadPlans(getPlansForContainerNumber(containerNumber, curentTabResults));
  };

  const calculate = async (orders: PayloadOrder[]) => {
    if (country) {
      setIsCalculating(true);
      const filteredRequests: PayloadBasketRequest[] = orders
        .filter((order) => order.active)
        .map(payloadOrderToRequest);
      const response = await calculatePayloadForAllContainerTypes(
        filteredRequests,
        country
      );
      trackPageView('CALCULATORS', 'CALCULATE_PAYLOAD');
      setAllTabResults(response);
      setCurrentTabResults(response[ContainerType.TYPE_20]);
      setLoadPlans(
        getPlansForContainerNumber(1, response[ContainerType.TYPE_20])
      );
      setIsCalculating(false);
      usePayloadStore.setState({ requiresRecalculation: false });
    }
  };

  const recalculate = async (orders: PayloadOrder[]) => {
    trackPageView('CALCULATORS', 'RECALCULATE_PAYLOAD');
    setIsCalculating(true);
    const result = await Promise.all(
      orders.map(payloadOrderToRequest).map(updateBasketInfo)
    );
    result.forEach((item, index) => {
      const orderToUpdate = orders[index];
      updateOrder({
        ...orderToUpdate,
        numberOf: item.itemList[0].numberOfPallets,
        totalWeight: item.itemList[0].totalWeight,
      });
    });
    calculate(orders);
  };

  const onAdd = async (data: PayloadForm, brands: EuBrand[]) => {
    try {
      trackPageView('CALCULATORS', 'ADD_PAYLOAD');
      setIsAdding(true);
      const request = mapFormToRequest(data, brands as EuBrand[]);
      const { itemList } = await updateBasketInfo(request);
      const orders = addOrder({
        ...request,
        active: true,
        brand: brands[+data.selectedBrand].brand,
        grammage: data.ivGrammage,
        departureMill: itemList[0].departureMill,
        numberOf: itemList[0].numberOfPallets,
        totalWeight: itemList[0].totalWeight,
        productType: data.selectedProductType,
        materialNumber: itemList[0].materialNumber,
      });
      calculate(orders);
      setError('');
    } catch (e) {
      setError(e.message ? e.message : t`Unknown error occurred`);
    } finally {
      setIsAdding(false);
    }
  };
  return (
    <div className="p-2">
      <div className="flex">
        <Criteria
          onSubmit={onAdd}
          isSubmitting={isAdding}
          itemInBasket={storedOrders.length > 0}
        />
        <Space direction="vertical" size="large" className="w-full ml-10">
          {storedOrders.length > 0 && (
            <BasketSection
              basketItems={storedOrders}
              onCalculate={recalculate}
            />
          )}
          {isCalculating && <LoadingSpinner horizontal />}
          {curentTabResults &&
            loadPlans.length > 0 &&
            !isCalculating &&
            storedOrders.length > 0 && (
              <Results
                onContainerTypeChange={onContainerTypeChange}
                onContainerNumberSelect={onContainerSelect}
                results={curentTabResults}
                selectedLoadPlans={loadPlans}
                error={error}
              />
            )}
        </Space>
      </div>
    </div>
  );
};

export default PayloadCalculator;
