import React, { FC } from 'react';
import { StockLotBasket } from '../../../../types/StockLotBasket.interface';
import Basket from './Basket';

interface IBasketsProps {
  baskets: StockLotBasket[] | undefined;
}

const Baskets: FC<IBasketsProps> = ({ baskets }) => {
  return (
    <>
      {baskets?.map((basket) => (
        <Basket basket={basket} key={basket.id} />
      ))}
    </>
  );
};

export default Baskets;
