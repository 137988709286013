import { t } from '@lingui/macro';
import { getParentBrandOptions } from '../../../services/Brand';
import Brand from '../../../types/Brand.interface';
import Option from '../../../types/Option.type';
import useCustomerBrands from './useCustomerBrands';

const useParentBrandOptions = (
  customer: string,
  chemistry?: string,
  texture?: string
): {
  parentBrandOptions: Option[];
  isFetching: boolean;
} => {
  const { brands, isFetching } = useCustomerBrands(customer);

  return {
    parentBrandOptions: getParentBrandOptions(brands as Brand[], {
      selectedChemistry: chemistry,
      selectedTexture: texture,
      defaultSelectLabel: t`Select`,
    }),
    isFetching,
  };
};

export default useParentBrandOptions;
