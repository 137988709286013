import { Trans } from '@lingui/macro';
import React from 'react';
import Date from '../../../../components/Date';
import { DeliveryResponse } from '../../../../types/DeliveryResponse.interface';

const hasShipped = (delivery: DeliveryResponse) => {
  return delivery && delivery.transitId;
};

const deliveryHeader = (delivery: DeliveryResponse, idx: number): any => {
  return (
    <dl>
      <dt className="flex pt-1.5">
        <div className="w-1/2 text-lg -mt-0.5">
          <Trans>Shipment</Trans> {idx + 1}
        </div>
        {hasShipped(delivery) && delivery.details.arrivalDate && (
          <div className="w-1/2 text-right text-lg pr-5">
            <span className="label-colon pr-2">
              <Trans id="orderDetails.estimatedArrivalDate">
                Estimated Arrival Date
              </Trans>
            </span>
            <span className="font-bold">
              <Date date={delivery.details.arrivalDate} />
            </span>
          </div>
        )}
        {!hasShipped(delivery) && (
          <div className="w-1/2 text-right text-lg pr-2">
            <Trans id="orderDetails.notYetShipped">Not Yet Shipped</Trans>
          </div>
        )}
      </dt>
    </dl>
  );
};

export default deliveryHeader;
