import { t } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import TransIdCell from './TransIdCell';
import { DeviationData } from './types/DeviationData.interface';
import { TrackAndTraceData } from './types/TrackAndTraceData.interface';

export const BASE_COLUMNS: ColumnsType<TrackAndTraceData | DeviationData> = [
  {
    title: t`Carrier`,
    key: 'lfName1',
    dataIndex: 'lfName1',
    className: 'align-top',
  },
  {
    title: t`CMR Number`,
    key: 'deliveryNote',
    dataIndex: 'deliveryNote',
    className: 'align-top',
  },
  {
    title: t`Trans ID`,
    key: 'transId',
    className: 'align-top',
    render: (_value, record) => (
      <TransIdCell transitId={record.transitId} payloadId={record.payloadId} />
    ),
  },
  {
    title: t`Ship To Party`,
    key: 'customerName1',
    dataIndex: 'customerName1',
    className: 'align-top',
  },
  {
    title: t`Destination`,
    key: 'customerCity',
    dataIndex: 'customerCity',
    className: 'align-top',
  },
];
