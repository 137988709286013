import { t, Trans } from '@lingui/macro';
import { Modal } from 'antd';
import { noop } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import { Button } from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Select from '../../../components/Select/Select';
import useAnalytics from '../../../hooks/useAnalytics';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import useRole from '../../../hooks/useRole';
import useUser from '../../../hooks/useUser';

import {
  getBaseWeightOptions,
  getBrandOptions,
  getBrands,
  getCaliperOptions,
  getAvailabilityGrammageOptions,
  getSkus,
} from '../../../services/Brand';
import { deriveCustomerOptions } from '../../../services/Customer';
import fractionOrDecimalInput from '../../../services/Util/fractionOrDecimalInput.util';
import { mediumWidthKwd } from '../../../services/Util/getWidthCssClass.util';
import { AuthorityRole } from '../../../types/Authority.interface';
import Brand from '../../../types/Brand.interface';
import Option from '../../../types/Option.type';
import ProductQuery from '../../../types/ProductQuery.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { IMP_REG_EX } from '../../../types/RegularExpression.constants';
import ProductAvailabilityCheckboxInput from '../Form/ProductAvailabilityCheckboxInput';
import ProductAvailabilityFormLeftHalf from '../Form/ProductAvailabilityFormLeftHalf';
import ProductAvailabilityFormRightHalf from '../Form/ProductAvailabilityFormRightHalf';
import SkuLookupModalAddSkusForm from './SkuLookupModalAddSkusForm';
import PutUpKwd from '../../../types/PutUpKwd.enum';
import getPutUpQueryParam from './ProductAvailabilityModal.util';
import './SkuLookupModal.scss';

interface ISkuLookupModalProps {
  visible: boolean;
  customer: string;
  onCancel: () => void;
  updateCustomer: (customer: string) => void;
  addSappiSkus: (sappiSkusToAdd: string[]) => void;
}

const SkuLookupModal: React.FunctionComponent<ISkuLookupModalProps> = ({
  visible,
  customer,
  onCancel,
  updateCustomer,
  addSappiSkus,
}) => {
  const { data: user } = useUser();
  type FilterForm = {
    brand: string;
    caliper: number;
    caliperMils: number;
    basisWeight: string;
    grammage: string;
    minWidth: number;
    maxWidth: number;
    minLength: number;
    maxLength: number;
  };

  const {
    register,
    handleSubmit,
    reset,
    errors,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'all',
  });

  const {
    getValues: addSkusFormGetValues,
    handleSubmit: addSkusFormHandleSubmit,
    register: addSkusFormRegister,
    reset: addSkusFormReset,
    setValue: addSkusFormSetValue,
    watch: addSkusFormWatch,
  } = useForm();

  const { trackPageView } = useAnalytics();
  const { format } = useNumberFormatter();

  const { hasRole } = useRole();
  const { isMetric } = useMeasurementSystem();

  const brandWatch = watch('brand') as string;
  const caliperWatch = watch('caliper') as number;
  const caliperInMilsWatch = watch('caliperInMils') as number;
  // The basisWeightWatch (ie grammage) is to control interaction among brand and caliper
  const basisWeightWatch = watch('basisWeight') as string;
  const grammageWatch = watch('grammage') as string;
  const addSkusFormData = addSkusFormWatch();

  const [selectedCustomer, setSelectedCustomer] = useState<string>();
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [rollsChecked, setRollsChecked] = useState<boolean>(true);
  const [sheetsChecked, setSheetsChecked] = useState<boolean>(true);
  const [productQuery, setProductQuery] = useState<ProductQuery>();
  const [showResults, setShowResults] = useState<boolean>(false);
  const [fetchedSkus, setFetchedSkus] = useState<Partial<Brand>[]>();
  const [resetFlag, setResetFlag] = useState<boolean>(false);
  const [addSkusFormSubmittable, setAddSkusFormSubmittable] = useState<boolean>(
    false
  );
  const [extraDelaying, setExtraDelaying] = useState<boolean>(true);
  // The basisWeightText (including baseWeight and tradeBasis) is used to getSkus
  const [basisWeightText, setBasisWeightText] = useState<string>('');

  const addSkusFormId = 'SkuLookupModalAddSkusForm';

  useEffect(() => {
    setValue('brand', '');
    setValue('basisWeight', '');
    setValue('caliper', '');
    setValue('caliperInMils', '');
    setValue('grammage', '');

    setShowResults(false);
    setFetchedSkus([]);
  }, [selectedCustomer, setValue, resetFlag]);

  useEffect(() => {
    setValue('basisWeight', '');
    setValue('caliper', '');
    setValue('caliperInMils', '');
    setValue('grammage', '');
  }, [brandWatch, setValue]);

  useEffect(() => {
    setSelectedCustomer(customer);
  }, [customer]);

  useEffect(() => {
    if (user) {
      const { customers } = user;
      const derivedCustomerOptions = deriveCustomerOptions(
        customers || [],
        undefined,
        false,
        false
      );
      setCustomerOptions(derivedCustomerOptions);
    }
  }, [user, selectedCustomer, updateCustomer]);

  const { resolvedData: brands, isFetching } = usePaginatedQuery(
    [
      QueryCacheName.BRANDS,
      {
        customer: selectedCustomer,
        availabilityFlag: 'X',
      },
    ],
    getBrands,
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      enabled: selectedCustomer,
      keepPreviousData: true,
    }
  );

  const {
    resolvedData: resolvedSkus,
    isFetching: skusFetching,
  } = usePaginatedQuery(
    [
      QueryCacheName.PRODUCT_AVAILABILITY_SKU,
      {
        soldTo: productQuery?.customerSoldTo || '',
        baseWeight:
          productQuery &&
          basisWeightText &&
          basisWeightText.toLowerCase() !== 'select'
            ? basisWeightText.split(' ')[0]
            : '0',
        caliper:
          productQuery && productQuery?.caliper
            ? String(productQuery?.caliper)
            : '',
        caliperInMils: 
          productQuery && productQuery?.caliperInMils
            ? String(productQuery?.caliperInMils)
            : '',
        grammage: 
          productQuery && productQuery?.grammage
            ? String(productQuery?.grammage)
            : '',
        gradeText: productQuery?.brand || '',
        lengthFrom: 
          isMetric && productQuery?.minLength
            ? String(productQuery?.minLength)
            : 'NA',
        lengthTo:
          isMetric && productQuery?.maxLength
            ? String(productQuery?.maxLength)
            : 'NA',
        lengthImpFrom: 
          !isMetric && productQuery?.minLength
            ? String(productQuery?.minLength)
            : 'NA',
        lengthImpTo: 
          !isMetric && productQuery?.maxLength
            ? String(productQuery?.maxLength)
            : 'NA',
        widthFrom:
          isMetric && productQuery?.minWidth
            ? String(productQuery?.minWidth)
            : 'NA',
        widthTo:
          isMetric && productQuery?.maxWidth
            ? String(productQuery?.maxWidth)
            : 'NA',
        widthImpFrom: 
          !isMetric && productQuery?.minWidth
            ? String(productQuery?.minWidth)
            : 'NA',
        widthImpTo: 
          !isMetric && productQuery?.maxWidth
            ? String(productQuery?.maxWidth)
            : 'NA',
        tradeBasis:
          productQuery &&
          basisWeightText &&
          basisWeightText.toLowerCase() !== 'select'
            ? basisWeightText.split(' ')[1]
            : '0',
        putUp: getPutUpQueryParam(productQuery?.putUps || []),
      },
    ],
    getSkus,
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: productQuery?.customerSoldTo,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (skusFetching) {
      setExtraDelaying(true);
      addSkusFormReset();
      setFetchedSkus([]);
    }
  }, [skusFetching, addSkusFormReset]);

  useEffect(() => {
    const filteredSkus = resolvedSkus?.filter(
      (resolvedSku) => !!resolvedSku?.skuCode
    );

    // This is to solve No results message briefly displaying before table displays results (EV2-551)
    // Extra delay is to give Antd table more time to load data
    if (resolvedSkus?.length && resolvedSkus?.length > 100) {
      setTimeout(() => setExtraDelaying(false), resolvedSkus?.length);
    } else {
      setExtraDelaying(false);
    }

    setFetchedSkus(filteredSkus);
  }, [resolvedSkus]);

  /**
   * Whenever the data in the add SKUs form changes, update whether the form can
   * be submitted or not (it should only be submittable when at least one SKU
   * has been selected to be added).
   */
  useEffect(() => {
    const addSkusFormValues = Object.values(addSkusFormData);
    const anyValuesAreTrue = addSkusFormValues.some((value) => {
      return value === true;
    });
    setAddSkusFormSubmittable(anyValuesAreTrue);
  }, [addSkusFormData, fetchedSkus]);

  const formatCaliperOption = (option: Option, idx: number) => {
    if (idx === 0) {
      return option;
    }
    option.label = format(option.label as string);
    return option;
  };

  const onSubmitReset = () => {
    reset();
    addSkusFormReset();
    clearErrors();
    setFetchedSkus([]);
    setShowResults(false);
    setRollsChecked(true);
    setSheetsChecked(true);
    setResetFlag(!resetFlag);
  };

  const onCancelAction = () => {
    onCancel();
    reset();
    addSkusFormReset();
    setFetchedSkus([]);
    setShowResults(false);
  };

  const onSubmit = (data: FilterForm) => {
    console.log(`Track Page View AVAILABILITY SKUSEARCH`);
    trackPageView('AVAILABILITY', 'SKU_SEARCH', {
      customerId: selectedCustomer,
    });

    const rollsPutUp = rollsChecked
      ? [{ putUp: PutUpKwd.ROLL }, { putUp: PutUpKwd.SHEETER_REEL }]
      : [];
    const sheetsPutUp = sheetsChecked ? [{ putUp: PutUpKwd.SHEET }] : [];

    const productQueryParams: ProductQuery = {
      ...data,
      customerSoldTo: selectedCustomer,
      putUps: !hasRole(AuthorityRole.ROLE_RELEASE)
        ? rollsPutUp.concat(sheetsPutUp)
        : [],
      isAvailabilityPlanning: true,
    };

    setExtraDelaying(true);
    setShowResults(true);
    setProductQuery(productQueryParams);
  };

  const onSubmitAddSkusForm = () => {
    onCancelAction();
  };

  return (
    <Modal
      className="sku-lookup-modal-container"
      width={990}
      visible={visible}
      onCancel={onCancelAction}
      maskTransitionName=""
      title={
        <div
          key="alertFilterModalTitle"
          className="text-white-white px-4 py-3 text-lg justify-between items-center flex bg-blue-pacific"
        >
          <p className="text-2xl font-bold flex-grow">
            <Trans>Sappi SKU Look Up</Trans>
          </p>
        </div>
      }
      closeIcon={
        <div className="pt-1 pr-2" key="skuLookupModalIcon">
          <div
            className="fa fa-times text-2xl text-white-white"
            onClick={onCancelAction}
            onKeyDown={noop}
            role="button"
            aria-label={t`Close Sappi SKU Lookup modal`}
            tabIndex={0}
          />
        </div>
      }
      footer={[
        <div className="pb-5 flex items-center">
          <Button
            type="submit"
            theme="primary"
            form={addSkusFormId}
            disabled={!addSkusFormSubmittable}
          >
            <Trans>Add Sappi SKUs</Trans>
          </Button>
          <Button
            type="reset"
            theme="link"
            className="ml-2 text-lg font-bold"
            onClick={onSubmitReset}
          >
            <Trans>Reset</Trans>
          </Button>
        </div>,
      ]}
    >
      <form>
        <div className="flex">
          <ProductAvailabilityFormLeftHalf>
            <div className="pb-4">
              <InputLabel
                size="lg"
                className="text-gray-dark200"
                text={t`Customer`}
                htmlFor="product-availability-customer-select"
              />
              <Select
                value={selectedCustomer}
                options={customerOptions}
                onChange={(e) => {
                  setSelectedCustomer(e.target.value);
                  updateCustomer(e.target.value);
                }}
                id="product-availability-customer-select"
              />
            </div>
            {!isMetric && (
              <div className="pb-3">
                <InputLabel
                  size="lg"
                  isFetching={isFetching}
                  labelClassName="justify-between mr-10"
                  text={<Trans>Product</Trans>}
                >
                  <Select
                    options={getBrandOptions(brands as Brand[], {
                      selectedCaliper: String(caliperWatch),
                      selectedGrammage: basisWeightWatch,
                      defaultSelectLabel: t`Select`,
                    })}
                    ref={register()}
                    name="brand"
                  />
                </InputLabel>
              </div>
            )}
            {isMetric && (
              <div className="pb-3">
                <InputLabel
                  size="lg"
                  isFetching={isFetching}
                  labelClassName="justify-between mr-10"
                  text={<Trans>Product</Trans>}
                >
                  <Select
                    options={getBrandOptions(brands as Brand[], {
                      selectedCaliper: String(caliperInMilsWatch),
                      selectedGrammage: grammageWatch,
                      defaultSelectLabel: t`Select`,
                      isMetric,
                    })}
                    ref={register()}
                    name="brand"
                  />
                </InputLabel>
              </div>
            )}
            {!isMetric && (
              <div className="pb-4">
                <InputLabel
                  size="lg"
                  isFetching={isFetching}
                  labelClassName="justify-between mr-10"
                  text={<Trans>Caliper</Trans>}
                >
                  <Select
                    options={getCaliperOptions(brands as Brand[], {
                      selectedBrand: brandWatch,
                      selectedGrammage: basisWeightWatch,
                      defaultSelectLabel: t`Select`,
                      isMetric,
                    })
                      .filter((o) => o.value !== 0)
                      .map(formatCaliperOption)}
                    ref={register()}
                    name="caliper"
                    disabled={brandWatch === ''}
                  />
                </InputLabel>
              </div>
            )}
            {isMetric && (
              <div className="pb-4">
                <InputLabel
                  size="lg"
                  isFetching={isFetching}
                  labelClassName="justify-between mr-10"
                  text={<Trans>Caliper</Trans>}
                >
                  <Select
                    options={getCaliperOptions(brands as Brand[], {
                      selectedBrand: brandWatch,
                      selectedGrammage: grammageWatch,
                      defaultSelectLabel: t`Select`,
                      isMetric,
                    })
                      .filter((o) => o.value !== 0)
                      .map(formatCaliperOption)}
                    ref={register()}
                    name="caliperInMils"
                    disabled={brandWatch === ''}
                  />
                </InputLabel>
              </div>
            )}
            {!isMetric && (
              <div>
                <InputLabel
                  size="lg"
                  isFetching={isFetching}
                  labelClassName="justify-between mr-10"
                  text={<Trans>Basis Weight</Trans>}
                >
                  <Select
                    options={getBaseWeightOptions(
                      brands as Brand[],
                      {
                        selectedBrand: brandWatch,
                        selectedCaliper: String(caliperWatch),
                        defaultSelectLabel: t`Select`,
                      },
                      false
                    )}
                    ref={register()}
                    name="basisWeight"
                    disabled={brandWatch === ''}
                    onChange={(event) => {
                      setBasisWeightText(
                        event.target.options[event.target.selectedIndex].text
                      );
                    }}
                  />
                </InputLabel>
              </div>
            )}
            {isMetric && (
              <div>
                <InputLabel
                  size="lg"
                  isFetching={isFetching}
                  labelClassName="justify-between mr-10"
                  text={<Trans>Grammage</Trans>}
                >
                  <Select
                    options={getAvailabilityGrammageOptions(
                      brands as Brand[],
                      {
                        selectedBrand: brandWatch,
                        selectedCaliper: String(caliperInMilsWatch),
                        defaultSelectLabel: t`Select`,
                      }
                    )}
                    ref={register()}
                    name="grammage"
                    disabled={brandWatch === ''}
                  />
                </InputLabel>
              </div>
            )}
          </ProductAvailabilityFormLeftHalf>
          <ProductAvailabilityFormRightHalf>
            {!hasRole(AuthorityRole.ROLE_RELEASE) && (
              <div className="pb-3 align-top w-1/2">
                <div className="text-xl font-extrabold text-gray-dark200 py-2 pb-2">
                  <Trans>Product Type</Trans>
                </div>
                <div className="flex">
                  <ProductAvailabilityCheckboxInput
                    checked={rollsChecked}
                    onChange={(checked) => {
                      setRollsChecked(checked);
                    }}
                    text={t`Rolls`}
                  />
                  <ProductAvailabilityCheckboxInput
                    checked={sheetsChecked}
                    onChange={(checked) => {
                      setSheetsChecked(checked);
                    }}
                    text={t`Sheets`}
                  />
                </div>
              </div>
            )}
            <div className="flex items-center py-3">
              <InputLabel
                position="side"
                size="lg"
                className="ml-2.5"
                text={
                  <>
                    <span className="mx-6" />
                    <Trans>Width</Trans>
                  </>
                }
              />
              <Input
                width={mediumWidthKwd}
                name="minWidth"
                maxLength={9}
                type="string"
                onKeyPress={fractionOrDecimalInput}
                ref={register({
                  pattern: IMP_REG_EX,
                })}
                error={errors.minWidth}
              />
              <span className="mx-2 text-xl font-extrabold">
                <Trans>to</Trans>
              </span>
              <Input
                width={mediumWidthKwd}
                id="maxWidth"
                name="maxWidth"
                maxLength={9}
                type="string"
                onKeyPress={fractionOrDecimalInput}
                ref={register({
                  pattern: IMP_REG_EX,
                })}
                error={errors.maxWidth}
              />
            </div>
            <div className="flex items-center py-3">
              <InputLabel
                position="side"
                size="lg"
                text={<Trans>Length/Dia.</Trans>}
              />
              <Input
                width={mediumWidthKwd}
                name="minLength"
                maxLength={9}
                type="string"
                onKeyPress={fractionOrDecimalInput}
                ref={register({
                  pattern: IMP_REG_EX,
                })}
                error={errors.minLength}
              />
              <span className="mx-2 text-xl font-extrabold">
                <Trans>to</Trans>
              </span>
              <Input
                width={mediumWidthKwd}
                name="maxLength"
                id="maxLength"
                maxLength={9}
                type="string"
                onKeyPress={fractionOrDecimalInput}
                ref={register({
                  pattern: IMP_REG_EX,
                })}
                error={errors.maxLength}
              />
            </div>
          </ProductAvailabilityFormRightHalf>
        </div>
        <div
          className="pl-9 pb-5 flex items-center justify-end"
          key="alertFilterModalFooter"
        >
          <Button
            type="submit"
            theme="primary"
            disabled={!rollsChecked && !sheetsChecked}
            onClick={handleSubmit(onSubmit)}
          >
            <Trans>Sappi SKU Look Up</Trans>
          </Button>
          <Button
            type="reset"
            theme="link"
            className="ml-2 text-lg font-bold"
            onClick={onSubmitReset}
          >
            <Trans>Reset</Trans>
          </Button>
        </div>
      </form>

      {showResults && (
        <>
          {skusFetching || extraDelaying ? (
            <div className="h-80">
              <LoadingSpinner horizontal />
            </div>
          ) : (
            <SkuLookupModalAddSkusForm
              data={fetchedSkus}
              addSappiSkus={addSappiSkus}
              getValues={addSkusFormGetValues}
              handleSubmit={addSkusFormHandleSubmit}
              register={addSkusFormRegister}
              reset={addSkusFormReset}
              setValue={addSkusFormSetValue}
              formId={addSkusFormId}
              onSubmit={onSubmitAddSkusForm}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default SkuLookupModal;
