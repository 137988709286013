import { Trans } from '@lingui/macro';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Date from '../../../../components/Date';
import ControlledDatePicker from '../../../../components/DatePicker/ControlledDatePicker';
import { DateFormat } from '../../../../hooks/useDateFormatter';
import AvailabilityBasketEU from '../../../../types/AvailabilityBasketEU.interface';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import {
  hasRequestedDateError,
  isInputDateBeforeRequestedDate,
} from '../Basket.util';
import TodayErrorMessage from '../TodayErrorMessage';
import useItemRequiresUpdate from '../useItemRequiresUpdate';

interface IRequestedDateComponentProps {
  basket: AvailabilityBasketEU;
  item: AvailabilityBasketItem;
  index: number;
  formMethods: UseFormMethods;
}

const RequestedDateComponent: React.FC<IRequestedDateComponentProps> = ({
  basket,
  item,
  index,
  formMethods,
}) => {
  const { updateItemRequiresUpdate } = useItemRequiresUpdate();

  const { control, errors, watch } = formMethods;
  const [
    inputDateBeforeRequestedDate,
    setInputDateBeforeRequestedDate,
  ] = useState(false);

  const requestedDate = watch
    ? watch(`basket[${index}].requestedDate`)
    : item.requestedDate;

  const getConfirmedDeliveryDateClass = (): string => {
    if (hasRequestedDateError(errors, index)) {
      return 'text-red-red';
    }
    if (
      inputDateBeforeRequestedDate ||
      moment.utc(item.requestedDate).isBefore(moment.utc(item.confirmedDate))
    ) {
      return 'text-orange-orange';
    }

    return 'text-green-green';
  };

  useEffect(() => {
    updateItemRequiresUpdate(item, index, basket.id || 0, formMethods);
    setInputDateBeforeRequestedDate(
      isInputDateBeforeRequestedDate(requestedDate, item.requestedDate)
    );
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [requestedDate]);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <>
      <ControlledDatePicker
        control={control}
        name={`basket[${index}].requestedDate`}
        className="w-full mb-1"
        required
        rules={{
          required: true,
          validate: {
            isDateAfterToday: (date: Moment) => date.isAfter(),
          },
        }}
        hasError={hasRequestedDateError(errors, index)}
        defaultValue={
          item.requestedDate ? moment.utc(item.requestedDate) : null
        }
        toolTipErrorMsg={
          hasRequestedDateError(errors, index) ? (
            <TodayErrorMessage />
          ) : undefined
        }
      />
      {item.confirmedDate && (
        <div className={`inline ${getConfirmedDeliveryDateClass()}`}>
          <span className="font-bold">
            <Trans>Confirmed Delivery Date:</Trans>
          </span>{' '}
          <Date format={DateFormat.EU_BASIC_NUM} date={item.confirmedDate} />
        </div>
      )}
    </>
  );
};

export default RequestedDateComponent;
