import { UseFormMethods } from 'react-hook-form';
import { get, map, find } from 'lodash';
import { useMemo } from 'react';
import moment from 'moment';
import { AvailabilityBasketItem } from '../../../types/AvailabilityBasketItem.interface';
import useBasketStore from './useBasketStore.store';

const useItemRequiresUpdate = (): {
  updateItemRequiresUpdate: (
    currentItem: AvailabilityBasketItem,
    index: number,
    basketId: number,
    formMethods: UseFormMethods
  ) => void;
} => {
  const { baskets: storedBaskets, updateBasket } = useBasketStore();

  const updateItemRequiresUpdate = useMemo(
    () => (
      currentItem: AvailabilityBasketItem,
      index: number,
      basketId: number,
      formMethods: UseFormMethods
    ) => {
      const {
        formState: { dirtyFields },
        getValues,
      } = formMethods;
      const inputDate = moment(new Date(currentItem.requestedDate || 0)).format(
        'YYYY-MM-DD'
      );
      const requestedDate = moment(
        new Date(getValues(`basket[${index}].requestedDate`) || '')
      ).format('YYYY-MM-DD');

      const requiresUpdate =
        get(dirtyFields, `basket[${index}].newLabel`, false) ||
        get(dirtyFields, `basket[${index}].paperType`, false) ||
        get(dirtyFields, `basket[${index}].requestedQuantity`, false) ||
        inputDate !== requestedDate;

      const basket = find(storedBaskets, { id: basketId });
      if (basket) {
        const updatedBasket = {
          ...basket,
          items: map(basket?.items, (item) => ({
            ...item,
            requiresUpdate:
              item.id === currentItem.id ? requiresUpdate : item.requiresUpdate,
          })),
        };

        if (updatedBasket && updatedBasket.soldTo) {
          updateBasket(updatedBasket);
        }
      }
    },
    [storedBaskets, updateBasket]
  );

  return {
    updateItemRequiresUpdate,
  };
};

export default useItemRequiresUpdate;
