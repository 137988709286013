import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { getTrackAndTraceLogs } from '../TrackAndTrace.service';
import LogTable from './LogTable';

const TrackAndTraceLog: FC = () => {
  const history = useHistory();
  const { payloadId } = useParams<{ payloadId: string }>();
  const { data } = useQuery(
    [QueryCacheName.TRACK_AND_TRACE_LOGS, payloadId],
    () => getTrackAndTraceLogs(payloadId),
    {
      enabled: payloadId,
      staleTime: 60000,
      keepPreviousData: true,
    }
  );
  return (
    <Space direction="vertical" size="large" className="w-full">
      <LogTable results={data || []} />
      <Button theme="primary" onClick={() => history.push('/trackAndTrace')}>
        <Trans>Back To Search</Trans>
      </Button>
    </Space>
  );
};

export default TrackAndTraceLog;
