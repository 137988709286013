import { useEffect, useState } from 'react';

const useErrorInputTips = (
  input?: string
): {
  showError: boolean;
  errorMessage: string;
} => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (input !== undefined && input !== '') {
      const parsedInput = parseFloat(input);
      if (parsedInput < 20 || parsedInput > 300) {
        setShowError(true);
        setErrorMessage('Valid entries must be between 20 and 300');
      } else {
        setShowError(false);
      }
    } else {
      setShowError(false);
    }
  }, [input]);

  return {
    showError,
    errorMessage,
  };
};

export default useErrorInputTips;
