import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import AvailabilityBasketType from '../../../../types/AvailabilityBasketType.enum';

interface IQuantityCellProps {
  basketItem: AvailabilityBasketItem;
}

const QuantityCell: FC<IQuantityCellProps> = ({ basketItem }) => {
  const { isMetric, isImperial } = useMeasurementSystem();
  const { format } = useNumberFormatter();
  return (
    <>
      {basketItem.basketType === AvailabilityBasketType.PRODUCT && (
        <div className="font-bold mb-4">
          <Trans>PLANNED PRODUCTION</Trans>
        </div>
      )}
      <div className="font-bold">
        {basketItem.requestedQuantity} {basketItem.requestedUnitOfMeasure}
      </div>
      {isImperial && (
        <div>
          {format(basketItem.quantityLbs)} <Trans>pounds</Trans>
        </div>
      )}
      {isMetric && (
        <div>
          {format(basketItem.quantityKg)} <Trans>kg</Trans>
        </div>
      )}
    </>
  );
};

export default QuantityCell;
