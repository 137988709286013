import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import useRole from '../../../hooks/useRole';
import { AuthorityRole } from '../../../types/Authority.interface';
import NonReleaseProductAvailabilityWidget from './NonReleaseProductAvailabilityWidget';
import ReleaseProductAvailabilityWidget from './ReleaseProductAvailabilityWidget';

const ProductAvailabilityWidget: FC = () => {
  const { hasRole } = useRole();
  return (
    <>
      {hasRole(AuthorityRole.ROLE_RELEASE) ? (
        <ReleaseProductAvailabilityWidget />
      ) : (
        <NonReleaseProductAvailabilityWidget />
      )}
      <div className="w-full text-right mt-4">
        <Trans>
          To perform a product availability search, please enter{' '}
          <strong>at minimum</strong> one additional parameter
        </Trans>
      </div>
    </>
  );
};

export default ProductAvailabilityWidget;
