import { t, Trans } from '@lingui/macro';
import { noop } from 'lodash';
import * as React from 'react';
import useMeasurementSystem from '../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import MeasurementSystem from '../../types/MeasurementSystem.enum';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';

export interface IBasketPopUpItemRowProps {
  id: number;
  product: string;
  sku: string;
  type?: string;
  grammage?: string;
  bookWeight?: number;
  tradeBasis?: string;
  size?: string;
  caliper?: string;
  total?: string;
  onRemove: (id: number) => void;
}

const BasketPopUpItemRow: React.FunctionComponent<IBasketPopUpItemRowProps> = ({
  id,
  product,
  sku,
  type,
  grammage,
  bookWeight,
  tradeBasis,
  size,
  caliper,
  total,
  onRemove,
}) => {
  const { hasRole } = useRole();
  const { format } = useNumberFormatter();
  const { measurementSystem } = useMeasurementSystem();
  return (
    <div className=" py-4 pt-3 border-b border-gray-whiteSmoke200">
      {/* TITLE ROW */}
      <div className="flex justify-between mb-2 ">
        <h6 className="">
          <strong>
            {product} ({sku})
          </strong>
        </h6>
        <div>
          <span
            className="fa fa-trash text-blue-pacific"
            role="button"
            onClick={() => onRemove(id)}
            onKeyDown={noop}
            aria-label="Remove Basket Item"
            tabIndex={0}
          />
        </div>
      </div>

      {/* PRODUCT DETAIL */}
      <div className="flex">
        <div className="w-1/2">
          <div>
            <Trans>Type</Trans>
          </div>
          {measurementSystem === MeasurementSystem.METRIC ? (
            <div>
              <Trans>Grammage</Trans>
            </div>
          ) : (
            <div>
              <Trans>Basis Weight</Trans>
            </div>
          )}
          <div>
            <Trans>Size</Trans>
          </div>
          {!hasRole(AuthorityRole.ROLE_RELEASE) ? (
            <div>Caliper</div>
          ) : null}
          <div className="mt-3">
            <strong>
              <Trans>Total</Trans>
            </strong>
          </div>
        </div>
        <div className="w-1/2">
          <div>{type === 'R' ? t`Roll` : t`Sheet`}</div>
          {measurementSystem === MeasurementSystem.METRIC ? (
            <div>{grammage || t`(None Listed)`}</div>
          ) : (
            <div>
              {(bookWeight ? `${bookWeight} ${tradeBasis}` : false) ||
                t`(None Listed)`}
            </div>
          )}
          <div>{size}</div>
          {!hasRole(AuthorityRole.ROLE_RELEASE) ? (
            <div>{caliper === 'null' ? t`(None Listed)` : format(caliper)}</div>
          ) : null}
          <div className="mt-3">{total}</div>
        </div>
      </div>
    </div>
  );
};

export default BasketPopUpItemRow;
