import { t } from '@lingui/macro';
import { useQuery } from 'react-query';
import { getTradeBasis } from '../../services/Properties';
import Option from '../../types/Option.type';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { TradeBasis } from '../../types/TradeBasis.interface';
import { TradeBasisTypes } from './types/TradeBasisTypes.enum';
import { getTradeBasisValuePerType } from './PriceCalculators/PriceCWT.util';

export const EMPTY_TRADE_BASIS_OPTION = {
  value: '',
  label: t`Select Trade Basis`,
  disabled: true,
};

const getTradeBasisOptions = (tradeBases: TradeBasis[]): Option[] => {
  const options: Option[] = [EMPTY_TRADE_BASIS_OPTION];
  tradeBases.forEach((tradeBasis) => {
    // eslint-disable-next-line default-case
    switch (tradeBasis.text) {
      case TradeBasisTypes.CVR:
        options.push({
          value: tradeBasis.text,
          label: getTradeBasisValuePerType(TradeBasisTypes.CVR),
        });
        break;
      case TradeBasisTypes.TAG:
        options.push({
          value: tradeBasis.text,
          label: getTradeBasisValuePerType(TradeBasisTypes.TAG),
        });
        break;
      case TradeBasisTypes.TXT:
        options.push({
          value: tradeBasis.text,
          label: getTradeBasisValuePerType(TradeBasisTypes.TXT),
        });
        break;
    }
  });

  return options;
};

const useTradeBasisOptions = (): {
  isFetching: boolean;
  tradeBasisOptions: Option[];
} => {
  const { data: tradeBases = [], isFetching } = useQuery(
    QueryCacheName.TRADE_BASIS,
    getTradeBasis,
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isFetching,
    tradeBasisOptions: getTradeBasisOptions(tradeBases),
  };
};

export default useTradeBasisOptions;
