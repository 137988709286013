import { t } from '@lingui/macro';
import { SamplesDummyCharacteristics } from '../../../../types/Samples/SamplesDummyCharacteristics.interface';
import Option from '../../../../types/Option.type';
import SamplesBrandFamily from '../../../../types/Samples/SamplesBrandFamily.enum';
import SamplesProductType from '../../../../types/Samples/SamplesProductType.enum';
import useDummyOrderStore, { DummyOrderState } from '../useDummyOrder.store';

export const isPrintedDummyUnavailable = (
  dummyCharacteristics: SamplesDummyCharacteristics
): boolean => {
  return (
    dummyCharacteristics.height > 305 ||
    dummyCharacteristics.width > 230 ||
    (dummyCharacteristics.textPaperBrandFamily !== SamplesBrandFamily.GALRIE &&
      dummyCharacteristics.textPaperBrandFamily !== SamplesBrandFamily.ROYAL) ||
    ((dummyCharacteristics.textPaperBrandFamily === SamplesBrandFamily.GALRIE ||
      dummyCharacteristics.textPaperBrandFamily === SamplesBrandFamily.ROYAL) &&
      dummyCharacteristics.additionalSectionsBrandFamily !== undefined &&
      dummyCharacteristics.additionalSectionsBrandFamily !== "" &&
      dummyCharacteristics.additionalSectionsBrandFamily !==
        SamplesBrandFamily.GALRIE &&
      dummyCharacteristics.additionalSectionsBrandFamily !==
        SamplesBrandFamily.ROYAL)
  );
};

export const getCoverPages = (
  dummyCharacteristics: SamplesDummyCharacteristics
): number | undefined => {
  if (dummyCharacteristics.fourPageCoverStandard) {
    return 4;
  }
  if (
    dummyCharacteristics.sixPageCoverGatefoldFront ||
    dummyCharacteristics.sixPageCoverGatefoldRear
  ) {
    return 6;
  }
  if (dummyCharacteristics.eightPageCoverGatefold) {
    return 8;
  }

  return undefined;
};

export const getSize = (
  dummySearchParams: SamplesDummyCharacteristics
): string => {
  if(dummySearchParams.width > dummySearchParams.height){
    return `${dummySearchParams.width} x ${dummySearchParams.height} ${t`Landscape`}`;
  }
  if(dummySearchParams.width <= dummySearchParams.height){
    return `${dummySearchParams.width} x ${dummySearchParams.height} ${t`Portrait`}`;
  }
  return '';
};

export const getBinding = (
  dummySearchParams: SamplesDummyCharacteristics
): string => {
  if (dummySearchParams.stitchedStapled) {
    return t`Stitched / Stapled`;
  }
  if (dummySearchParams.perfectBoundGlued) {
    return t`Perfect bound / glued`;
  }
  if (dummySearchParams.noBindingLoosePages) {
    return t`No binding / loose pages`;
  }
  return '';
};

export const noDummyCharacteristics = (
  dummyCharacteristics: SamplesDummyCharacteristics,
  section: string
): boolean => {
  switch (section) {
    case 'description': {
      return !dummyCharacteristics.description;
    }
    case 'textPages': {
      return (
        !dummyCharacteristics.textPaperBrandFamily ||
        !dummyCharacteristics.textPaperBrand ||
        !dummyCharacteristics.textPaperGrammage ||
        !dummyCharacteristics.textPaperNumberOfPages ||
        (dummyCharacteristics.additionalSectionsPositionInDummy &&
          !dummyCharacteristics.additionalSectionsBrandFamily) ||
        (dummyCharacteristics.additionalSectionsPositionInDummy &&
          !dummyCharacteristics.additionalSectionsBrand) ||
        (dummyCharacteristics.additionalSectionsPositionInDummy &&
          !dummyCharacteristics.additionalSectionsGrammage) ||
        (dummyCharacteristics.additionalSectionsPositionInDummy &&
          !dummyCharacteristics.additionalSectionsNumberOfPages) ||
        (dummyCharacteristics.additionalSectionsPositionInDummy === 'other' &&
          !dummyCharacteristics.additionalSectionsPositionDescription)
      );
    }
    case 'coverOptions': {
      return (
        (dummyCharacteristics.coverPaperDifferentFromText || ('' as string)) !==
          'false' &&
        (!dummyCharacteristics.coverBrandFamily ||
          !dummyCharacteristics.coverBrand ||
          !dummyCharacteristics.coverGrammage ||
          (!dummyCharacteristics.fourPageCoverStandard &&
            !dummyCharacteristics.sixPageCoverGatefoldFront &&
            !dummyCharacteristics.sixPageCoverGatefoldRear &&
            !dummyCharacteristics.eightPageCoverGatefold))
      );
    }
    case 'format': {
      return (
        !dummyCharacteristics.height ||
        !dummyCharacteristics.width ||
        (!dummyCharacteristics.stitchedStapled &&
          !dummyCharacteristics.perfectBoundGlued &&
          !dummyCharacteristics.noBindingLoosePages)
      );
    }
    default:
      return false;
  }
};

export const searchParamsInvalid = (
  gatefoldFrontValue: number,
  gatefoldRearValue: number,
  gatefoldFront2Value: number,
  gatefoldRear2Value: number,
  dummyCharacteristics: SamplesDummyCharacteristics,
  section: string
): boolean => {
  switch (section) {
    case 'textPages': {
      return dummyCharacteristics.textPaperNumberOfPages <= 3;
    }
    case 'coverOptions': {
      return (
        (typeof dummyCharacteristics.gatefoldFront !== 'undefined' &&
          (dummyCharacteristics.gatefoldFront || 0) < 0) ||
        (typeof dummyCharacteristics.gatefoldRear !== 'undefined' &&
          (dummyCharacteristics.gatefoldRear || 0) < 0) ||
        gatefoldFrontValue > dummyCharacteristics.width ||
        gatefoldRearValue > dummyCharacteristics.width ||
        gatefoldFront2Value > dummyCharacteristics.width ||
        gatefoldRear2Value > dummyCharacteristics.width
      );
    }
    default:
      return false;
  }
};

export const assignState = (
  state: Partial<DummyOrderState>,
  parsedQuantity: number
): Partial<DummyOrderState> => {
  if (state.dummyType === SamplesProductType.PRINTED) {
    state.copiesPrinted = parsedQuantity;
    state.copiesUnprinted = null;
  }
  if (state.dummyType === SamplesProductType.UNPRINTED) {
    state.copiesUnprinted = parsedQuantity;
    state.copiesPrinted = null;
  }

  // If the additional section position selected is other, sub the text entered in the position description for it
  if (state.additionalSectionsPositionInDummy === 'other') {
    state.additionalSectionsPositionInDummy =
      state.additionalSectionsPositionDescription;
  }

  return state;
};

export const resetState = () =>
  useDummyOrderStore.setState({
    prePaperDifferentFromTextOption: undefined,
    quantity: null,
    dummyType: undefined,
    additionalInstructions: null,
    additionalSectionsBrand: null,
    additionalSectionsBrandFamily: null,
    additionalSectionsGrammage: null,
    additionalSectionsNumberOfPages: null,
    additionalSectionsPositionInDummy: null,
    additionalSectionsPositionDescription: null,
    copiesPrinted: null,
    copiesUnprinted: null,
    coverBrand: null,
    coverBrandFamily: null,
    coverGrammage: null,
    coverPaperDifferentFromText: undefined,
    description: null,
    eightPageCoverGatefold: null,
    fourPageCoverStandard: null,
    gatefoldFront: null,
    gatefoldRear: null,
    height: undefined,
    noBindingLoosePages: undefined,
    perfectBoundGlued: undefined,
    sixPageCoverGatefoldFront: null,
    sixPageCoverGatefoldRear: null,
    stitchedStapled: undefined,
    textPaperBrand: null,
    textPaperBrandFamily: null,
    textPaperGrammage: null,
    textPaperNumberOfPages: undefined,
    width: undefined,
  });
