import * as React from 'react';
import { Button } from '../Button/Button';
import styles from './AvailableActionsButton.module.scss';

interface IAvailableActionsButtonProps {
  text: React.ReactNode;
  onClick(): void;
}

const AvailableActionsButton: React.FunctionComponent<IAvailableActionsButtonProps> = ({
  text,
  onClick,
}) => {
  return (
    <div>
      <Button
        theme="primary"
        className={styles.button}
        onClick={() => {
          onClick();
        }}
      >
        <i className={`fa fa-bars ${styles.buttonIcon}`} />
        <div className={styles.buttonText}>{text}</div>
      </Button>
    </div>
  );
};

export default AvailableActionsButton;
