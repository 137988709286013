import { Trans } from '@lingui/macro';
import { Divider, Space } from 'antd';
import React, { FC } from 'react';

const LoginLinks: FC = () => {
  return (
    <Space direction="vertical">
      <Space
        split={<Divider type="vertical" className="text-white-white" />}
        size="middle"
      >
        <span>
          <a className="underline" href="#/forgotUsername">
            <Trans>Forgot ID</Trans>
          </a>{' '}
          or{' '}
          <a className="underline" href="#/forgotPassword">
            <Trans>forgot password?</Trans>
          </a>
        </span>
        <span>
          <a className="underline" href="#/registration">
            <Trans>Register</Trans>
          </a>
        </span>
      </Space>
      <a className="underline" href="#/loginHelp">
        <Trans>Need help logging in?</Trans>
      </a>
    </Space>
  );
};

export default LoginLinks;
