import * as React from 'react';

const BasketPopUpBasketProps: React.FunctionComponent<React.HTMLProps<
  HTMLDivElement
>> = ({ className = '', children, ...rest }) => {
  return (
    <div
      className={`basket-properties p-2.5 border border-gray-light400 font-bold text-gray-dark200 bg-gray-whiteSmoke200 ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default BasketPopUpBasketProps;
