import { Popover } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import OldBasketButton from '../../../components/OldBasketButton/OldBasketButton';
import useEuBasketNumItems from '../../../hooks/useEuBasketNumItems';
import OrderBookingAddedToBasketPopUp from '../../OrderBooking/OrderBookingAddedToBasketPopUp';
import StockLotAddedToBasketPopUp from '../../StockLot/Results/StockLotAddedToBasketPopup';
import useNonReleaseEuBasketPopupStore from './useNonReleaseEuBasketPopup.store';

const NonReleaseEuBasketPopup: FunctionComponent = () => {
  const [visible, setVisible] = useState(false);
  const {
    lastNonStockLotItemAdded,
    lastStockLotItemAdded,
  } = useNonReleaseEuBasketPopupStore();
  const { numBasketItems } = useEuBasketNumItems();
  const history = useHistory();

  const nonStockLotContent = (
    <OrderBookingAddedToBasketPopUp
      itemAdded={lastNonStockLotItemAdded}
      onClose={() => setVisible(false)}
      onContinueShopping={() => null}
    />
  );
  const stockLotContent = lastStockLotItemAdded ? (
    <StockLotAddedToBasketPopUp
      item={lastStockLotItemAdded}
      onClose={() => setVisible(false)}
      onContinueShopping={() => null}
    />
  ) : null;

  return (
    <Popover
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      content={stockLotContent || nonStockLotContent}
    >
      <OldBasketButton
        disabled={numBasketItems === 0}
        onCaretClick={() => setVisible((state) => !state)}
        onButtonClick={() => history.push('/basket')}
      />
    </Popover>
  );
};

export default NonReleaseEuBasketPopup;
