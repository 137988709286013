import { t } from '@lingui/macro';
import { Courier } from '../../../../types/Courier.interface';
import Option from '../../../../types/Option.type';

export const getCourierOptions = (couriers: Courier[]): Option[] => {
  const courierOptions: Option[] = [
    {
      value: '',
      label: t`Select`,
    },
  ];
  return courierOptions.concat(
    couriers?.map((courier) => ({
      value: courier.accountNumber,
      label: courier.name1,
    }))
  );
};
