import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { Table as AntTable } from 'antd';
import { getTotalPrice, isBelowMinimum } from './Basket.util';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import useRole from '../../../hooks/useRole';
import { AuthorityRole } from '../../../types/Authority.interface';

interface IBasketTableSummaryProps {
  basket: AvailabilityBasketEU;
}

const BasketTableSummary: FC<IBasketTableSummaryProps> = ({ basket }) => {
  const { format } = useNumberFormatter();
  const { hasRole } = useRole();

  return (
    <>
      {hasRole(AuthorityRole.ROLE_K_CODE_PRICES) && (
        <AntTable.Summary.Row>
          <AntTable.Summary.Cell
            colSpan={7}
            className="text-right font-bold text-lg border-r border-gray-light400"
            index={0}
          >
            <Trans>Total Price:</Trans>
          </AntTable.Summary.Cell>
          <AntTable.Summary.Cell
            colSpan={2}
            className="text-xl font-bold text-blue-corporate"
            index={1}
          >
            {isBelowMinimum(basket) ? (
              <>
                <div>
                  {format(basket.items[0].minimumValue || 0)}{' '}
                  {basket.items[0].currency}
                </div>
                <div className="text-red-red text-base font-normal">
                  <Trans>
                    A minimum order value will be charged due to the small total
                    size of the order.
                  </Trans>
                </div>
              </>
            ) : (
              <>
                {format(getTotalPrice(basket))} {basket.items[0].currency}
              </>
            )}
          </AntTable.Summary.Cell>
        </AntTable.Summary.Row>
      )}
    </>
  );
};

export default BasketTableSummary;
