import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import useLocationQueryParams from '../../../../hooks/useLocationQueryParams';
import { submitNaBasketOrder } from '../../../../services/Basket';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import OrderType from '../OrderType.enum';
import NaBasketStep from '../types/NaBasketStep.enum';
import StepComponentProps from '../types/StepComponentProps.interface';
import useContinueShopping from '../useContinueShopping';
import useNaBasketStore from '../useNaBasketStore.store';
import CustomerDeliveryLocationTable from './CustomerDeliveryLocationTable';
import OrderDetailsTable from './OrderDetailsTable';
import OrderSummary from './OrderSummary';
import useAnalytics from '../../../../hooks/useAnalytics';
import useUser from '../../../../hooks/useUser';

const SubmitOrderStep: FC<StepComponentProps> = ({ setCurrentStep }) => {
  const { continueShopping } = useContinueShopping();
  const { currentBasketSubmissionCandidate } = useNaBasketStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const queryCache = useQueryCache();
  const history = useHistory();
  const urlParams = useLocationQueryParams();
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();

  const submitOrder = async () => {
    if (currentBasketSubmissionCandidate) {
      try {
        setIsSubmitting(true);
        await submitNaBasketOrder(currentBasketSubmissionCandidate);
        queryCache.invalidateQueries(QueryCacheName.BASKET);
        if (urlParams.get('quickOrder')) {
          trackPageView('QUICK_ORDER_BOOKING', 'BOOK_ORDER', {
            customerId: currentBasketSubmissionCandidate?.soldToCustomer.number,
          });
          history.push('submitOrder/submitted?quickOrder=true');
        } else {
          history.push('submitOrder/submitted');
        }
      } catch (e) {
        setHasError(true);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  return (
    <div>
      {currentBasketSubmissionCandidate && (
        <Space direction="vertical" className="w-full" size="middle">
          <CustomerDeliveryLocationTable
            basket={currentBasketSubmissionCandidate}
          />
          <OrderSummary
            orderType={currentBasketSubmissionCandidate?.orderType as OrderType}
            poNumber={currentBasketSubmissionCandidate.soldToPO}
            shipToPoNumber={currentBasketSubmissionCandidate.shipToPO}
          />
          <OrderDetailsTable basket={currentBasketSubmissionCandidate} />
        </Space>
      )}

      <div className="flex justify-between mt-5">
        <Button onClick={continueShopping} disabled={isSubmitting}>
          <Trans>Continue Shopping</Trans>
        </Button>
        <Space size="middle">
          <Button
            onClick={() => {
              trackPageView('BASKET', 'PREVIOUS_STEP');
              setCurrentStep(NaBasketStep.DELIVERY_DETAILS);
            }}
            disabled={isSubmitting}
          >
            <Trans>Previous</Trans>
          </Button>
          <Button
            type="submit"
            theme="primary"
            onClick={submitOrder}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            <Trans>Submit Order</Trans>
          </Button>
        </Space>
      </div>
      {hasError && <div className="text-red-red">An error has occurred.</div>}
    </div>
  );
};

export default SubmitOrderStep;
