// Format here should follow the tailwind.config.js format since it is imported directly there
const colors = {
  white: {
    white: '#fff', // DEFAULT
    hover: '#d9d9d9',
    offWhite: '#fafafa',
  },
  green: {
    citrus: '#B0D858',
    light: '#009933',
    green: 'green', // DEFAULT
    pineTree: '#088677',
    lime: '#8cd600',
    lawn: '#23836E',
    sirocco: '#707271',
  },
  lavender: {
    lighter: '#fafcfe',
    violet: '#EEF8FC',
    light: '#e6f0fa',
    pale: '#e5f4fb',
    lavender: '#cce0f5', // DEFAULT
  },
  blue: {
    cornflower: '#59b8ee',
    cornflowerPale: '#BFE2F5',
    malibu: '#65B3E1',
    iris: '#099ed7',
    pacific: '#0090D9',
    pacificDisabled: '#73d0ff',
    pacificHover: '#005d8d',
    cerulean: '#2876c0',
    corporate: '#06c',
    denim: '#0b61b1',
    cobalt: '#005ea7',
    cobaltVariation: '#0057b8',
    darkCerulean: '#069',
    lagoon: '#025888',
    menuHeader: '#141e3b',
    euHeader: '#094997',
  },
  gray: {
    whiteSmoke100: '#f8f8f8',
    whiteSmoke200: '#f1f1f1',
    whiteSmoke300: '#efefef',
    whiteSmoke400: '#eee',
    whiteSmoke500: '#ebebeb',
    whiteSmoke600: '#e5e7eb',
    light100: '#ddd',
    light200: '#d3d3d3',
    light300: '#cecece',
    light400: '#ccc',
    light500: '#aaa',
    gray: '#999', // DEFAULT
    grayHover: '#737373',
    dim100: '#717171',
    dim200: '#767b7d',
    dim300: '#6f6f6f',
    dim400: '#6e6e6e',
    dim500: '#6c6c6c',
    dim600: '#666',
    dark100: '#5d5d5d',
    dark200: '#54585a',
    dark300: '#4a4a4a',
    dark300Disabled: '#979797',
    dark300Hover: '#242424',
    dark400: '#333',
    dark500: '#2e2e2e',
  },
  black: {
    black: '#000', // DEFAULT
  },
  red: {
    roseBud: '#fea89e',
    red: '#FF0000', // DEFAULT
    venetian: '#D81E05',
    venetianDisabled: '#fc897a',
    venetianHover: '#8d1403',
  },
  orange: {
    orange: '#f96000',
  },
  yellow: {
    yellow: '#ffea87',
    lemonChiffon: '#faffbd',
    lemonOrange: '#fecf16',
  },
};

const boxShadow = {
  md: `0 0 10px ${colors.gray.light400}`,
};

const borderRadius = {
  DEFAULT: '3px',
};

exports.colors = colors;
exports.boxShadow = boxShadow;
exports.borderRadius = borderRadius;

// ANT DESIGN THEME VARIABLES https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
exports.theme = {
  'primary-color': colors.blue.pacific, // primary color for all components
  'table-header-sort-bg': colors.gray.dark200, // heading text color
  'table-header-bg': colors.gray.dark200, // major text color
  'table-padding-vertical': '10px',
  'table-padding-horizontal': '10px',
  'table-header-color': colors.white.white,
  'btn-disable-bg': colors.blue.pacificDisabled,
  'btn-disable-color': colors.white.white,
  'btn-border-radius-base': '0px',
  'btn-padding-horizontal-base': '20px',
  'btn-height-base': '20px',
  'btn-default-border': colors.gray.dark300,
  'card-head-background': colors.blue.pacific,
  'card-head-color': colors.white.white,
  'card-background': colors.gray.whiteSmoke200,
  'card-head-padding': '10px',
  'card-head-height': '46px',
  'divider-color': colors.gray.light400,
  'tabs-card-tab-active-border-top': '2px solid blue',
  'tabs-card-horizontal-padding': '12px 40px',
  'tabs-title-font-size': '16px',
  'tabs-vertical-margin': '0',
};
