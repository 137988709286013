const loadExternalScript = (
  scriptUrl: string,
  onLoadCallback: () => void = () => {}
): HTMLScriptElement => {
  const bodyTag = document.getElementsByTagName('body')[0];
  const scriptTag = document.createElement('script');
  scriptTag.addEventListener('load', onLoadCallback);
  scriptTag.src = scriptUrl;
  bodyTag.appendChild(scriptTag);

  return scriptTag;
};

export default loadExternalScript;
