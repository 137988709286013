import { Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import Date from '../../../../components/Date';
import Table from '../../../../components/Table/Table';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { DeliveryHistory } from '../../../../types/DeliveryHistory.interface';
import '../OrderDetailsTable.scss';
import { DeliveryHistoryRow } from './OrderDetailsRow.interface';

const props = {
  style: { borderRightWidth: '0' },
};

interface IOrderDetailsTableProps {
  data: DeliveryHistory[];
  showFooter: boolean;
  loading?: boolean;
}

const OrderDetailsHistoryTable: React.FunctionComponent<IOrderDetailsTableProps> = ({
  data,
  showFooter,
  loading,
}) => {
  const [tableData, setTableData] = useState<DeliveryHistory[]>();
  const { format } = useNumberFormatter();

  useEffect(() => {
    if (data) {
      const deliveryHistoryRows: DeliveryHistoryRow[] = data.map(
        (deliveryHistory, idx) => {
          return {
            ...deliveryHistory,
            key: `delivery-${idx}`,
          };
        }
      );
      setTableData(deliveryHistoryRows);
    }
  }, [data]);

  const columns: ColumnsType<DeliveryHistoryRow> = [
    {
      title: <Trans id="Date">Date</Trans>,
      dataIndex: 'date',
      key: 'date',
      render: (text: string, record: DeliveryHistoryRow) => (
        <Date date={record.statusDate} />
      ),
    },
    {
      title: <Trans id="Time">Time</Trans>,
      dataIndex: 'time',
      key: 'time',
      render: (text: string, record: DeliveryHistoryRow) =>
        Moment.utc(record.statusTime).format('HH:mm'),
    },
    {
      title: <Trans id="Quantity">Quantity</Trans>,
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: string, record: DeliveryHistoryRow) =>
        format(record.quantity),
    },
    {
      title: <Trans id="Status">Status</Trans>,
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: DeliveryHistoryRow) => {
        return {
          props,
          children: <div>{record.statusText}</div>,
        };
      },
    },
  ];
  return (
    <>
      <Table
        dataSource={tableData}
        loading={loading}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default OrderDetailsHistoryTable;
