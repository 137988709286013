import useUser from '../../../hooks/useUser';
import { EMPTY_OPTION } from '../../../services/Util/emptyOption.const';
import generateOptions from '../../../services/Util/generateOptions.util';
import Option from '../../../types/Option.type';

const useForwardingAgentOptions = (): { forwardingAgentOptions: Option[] } => {
  const { data: user } = useUser();

  const forwardingAgentOptions = user?.customers
    ? generateOptions(
        user.customers,
        (customer) => ({
          label: customer.name,
          value: customer.number,
        }),
        EMPTY_OPTION
      )
    : [];

  return {
    forwardingAgentOptions,
  };
};

export default useForwardingAgentOptions;
