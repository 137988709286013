import { Trans } from '@lingui/macro';
import React, { useState } from 'react';
import Tabs, { TabPane } from '../../../components/Tabs/Tabs';
import useAnalytics from '../../../hooks/useAnalytics';
import { IGetConsignmentInventoryParams } from '../../../services/Consignment';
import ConfirmationPage from './Confirmation/ConfirmationPage';
import ConsignmentReportingTab from './ConsignmentReportingTab.enum';
import SearchCriteria from './SearchCriteria/SearchCriteria';
import ViewConsignmentInventory from './ViewConsignmentInventory/ViewConsignmentInventory';
import useConsignmentInventoryStore from './useConsignmentInventoryStore.store';

const ConsignmentReporting: React.FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState<ConsignmentReportingTab>();
  const [queryParams, setQueryParams] = useState<
    IGetConsignmentInventoryParams
  >();
  const { showSubmissionPage } = useConsignmentInventoryStore();
  const { trackPageView } = useAnalytics();

  const safelySetActiveTab = (key: string): void => {
    if (Object.values<string>(ConsignmentReportingTab).includes(key)) {
      setActiveTab(key as ConsignmentReportingTab);
    }
  };

  const onSearch = (
    searchQueryParams: IGetConsignmentInventoryParams
  ): void => {
    setQueryParams(searchQueryParams);
    setActiveTab(ConsignmentReportingTab.VIEW_CONSIGNMENT_INVENTORY);
    trackPageView('CONSIGNMENT', 'SEARCH', {
      customerId: searchQueryParams.soldToCustomerNumber,
    });
  };

  return (
    <>
      {showSubmissionPage && <ConfirmationPage />}
      <Tabs
        type="card"
        activeKey={activeTab}
        onChange={(key) => safelySetActiveTab(key)}
        className={`${showSubmissionPage ? 'invisible h-0' : 'visible'}`} // this will maintain state perfectly as opposed to unmounting
      >
        <TabPane
          key={ConsignmentReportingTab.ENTER_SEARCH_CRITERIA}
          tab={<Trans>Enter Search Criteria</Trans>}
        >
          <SearchCriteria onSearch={onSearch} />
        </TabPane>
        <TabPane
          key={ConsignmentReportingTab.VIEW_CONSIGNMENT_INVENTORY}
          tab={<Trans>View Consignment Inventory</Trans>}
          disabled={!queryParams}
        >
          {queryParams && (
            <ViewConsignmentInventory queryParams={queryParams} />
          )}
        </TabPane>
      </Tabs>
    </>
  );
};

export default ConsignmentReporting;
