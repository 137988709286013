import { t } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';
import { useQuery, useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import FileUploadScreen from '../../components/FileUploadScreen';
import {
  deleteComplaintAttachment,
  getComplaintAttachments,
  uploadAttachment,
  uploadFileClaimAttachment,
} from '../../services/Complaints';
import QueryCacheName from '../../types/QueryCacheName.enum';
import AttachmentModal from './AttachmentModal';

interface IAddAttachmentProps {
  complaintNumber: string;
  fileNumber?: string;
  customerNumber?: string;
  documentNumber?: string;
  documentPosition?: string;
  isFileClaimUpdate?: boolean;
}

const AddAttachmentCore: FunctionComponent<IAddAttachmentProps> = ({
  complaintNumber,
  fileNumber,
  customerNumber,
  documentNumber,
  documentPosition,
  isFileClaimUpdate,
}) => {
  const queryCache = useQueryCache();
  const history = useHistory();
  const [isAttachmentModalVisible, setIsAttachmentModalVisible] = useState(
    false
  );
  const [modalComplaintNumber, setModalComplaintNumber] = useState('');
  const [modalSalesDocumentNumber, setModalSalesDocumentNumber] = useState('');
  const { data: attachments } = useQuery(
    [QueryCacheName.COMPLAINTS_ATTACHMENTS, complaintNumber],
    (key, number: string) => getComplaintAttachments(number),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
      enabled: complaintNumber,
    }
  );
  const fileNames = attachments?.attachments.map(
    (attachment) => `${attachment.description}.${attachment.documentClassCode}`
  );
  
  const openAttachmentModal = () => {
    setModalComplaintNumber(complaintNumber);
    setModalSalesDocumentNumber(documentNumber!);
    setIsAttachmentModalVisible(true);
  };

  const uploadFile = async (fileList: FileList) => {
    await uploadAttachment(
      fileList[0],
      complaintNumber,
      fileNumber || '',
      customerNumber || ''
    );
    queryCache.invalidateQueries([
      QueryCacheName.COMPLAINTS_ATTACHMENTS,
      complaintNumber,
    ]);
    queryCache.invalidateQueries([
      QueryCacheName.EXISTING_COMPLAINT,
      documentNumber,
      documentPosition,
    ]);
    queryCache.invalidateQueries([
      QueryCacheName.ORDER_DETAILS,
      documentNumber,
      documentPosition,
    ]);
  };

  const uploadFileClaim = async (fileList: FileList) => {
    await uploadFileClaimAttachment(
      fileList[0],
      documentNumber || '',
      documentPosition || '',
      complaintNumber
    );
    queryCache.invalidateQueries([
      QueryCacheName.COMPLAINTS_ATTACHMENTS,
      complaintNumber,
    ]);
    queryCache.invalidateQueries([QueryCacheName.COMPLAINTS, customerNumber]);
  };
  return (
    <>
      <FileUploadScreen
        filesUploaded={fileNames || []}
        primaryButton={{
          text: t`Upload File`,
          action: isFileClaimUpdate ? uploadFileClaim : uploadFile,
        }}
        secondaryButton={
          isFileClaimUpdate
            ? {
                text: t`Done - View Claim Confirmation`,
                action: () =>
                  history.push(
                    `/claim/confirmation/${documentNumber}/${documentPosition}/${complaintNumber}/${attachments?.attachments.length}`
                  ),
              }
            : {
                text: t`Done - Back to claim reporting`,
                action: () =>
                  history.push(`/reporting/claimReport/${customerNumber}`),
              }
        }
        deleteAttachmentButton={{
          text: t`Delete Attachment(s)`,
          action: openAttachmentModal,
        }}
      />
      <AttachmentModal
        complaintNumber={modalComplaintNumber}
        customerNumber={customerNumber}
        salesDocumentNumber={modalSalesDocumentNumber}
        visible={isAttachmentModalVisible}
        onCancel={() => setIsAttachmentModalVisible(false)}
      />
    </>
  );
};

export default AddAttachmentCore;
