import { t, Trans } from '@lingui/macro';
import { concat } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import Select from '../../../components/Select/Select';
import useRole from '../../../hooks/useRole';
import useUser from '../../../hooks/useUser';
import {
  deriveCustomerOptions,
  isValidCustomerNumber,
} from '../../../services/Customer';
import { AuthorityRole } from '../../../types/Authority.interface';
import Option from '../../../types/Option.type';

interface ISelectCustomerProps {
  selectedCustomerNum: string;
  setSelectedCustomerNum(customerNumber: string): void;
}

interface SelectCustomerInputs {
  customerNumber: string;
}

const SelectCustomer: React.FunctionComponent<ISelectCustomerProps> = ({
  selectedCustomerNum,
  setSelectedCustomerNum,
}) => {
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;
  const { hasRole, isNonReleaseEuUser } = useRole();
  const { data: user } = useUser();

  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);

  const onSubmit = (data: SelectCustomerInputs) => {
    setSelectedCustomerNum(data.customerNumber);
  };

  useEffect(() => {
    if (user) {
      const { customers } = user;
      const derivedCustomerOptions = deriveCustomerOptions(
        customers || [],
        isNonReleaseEuUser,
        hasRole(AuthorityRole.ROLE_EU),
        true
      );
      setCustomerOptions(
        concat(
          [{ label: t`Select Customer`, value: '' }],
          derivedCustomerOptions
        )
      );
    }
  }, [user, selectedCustomerNum, isNonReleaseEuUser, hasRole]);

  return (
    <form
      className="flex flex-col p-6 border-t-4 border-blue-cobaltVariation"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-7">
        <label
          className="block text-xl font-extrabold text-gray-dark200 pb-3"
          htmlFor="customerSelect"
        >
          <span className="text-red-red pr-0.5">*</span>
          <Trans>Select Customer</Trans>
        </label>
        <Select
          id="customerSelect"
          name="customerNumber"
          options={customerOptions}
          ref={register({ required: true, validate: isValidCustomerNumber })}
          width="full"
          required
        />
      </div>
      <Button
        className="self-end"
        type="submit"
        theme="primary"
        disabled={!(isDirty && isValid)}
      >
        <Trans>Search</Trans>
      </Button>
    </form>
  );
};

export default SelectCustomer;
