import { Space } from 'antd';
import { Moment } from 'moment';
import React, { FC } from 'react';
import { DeviationData } from '../types/DeviationData.interface';
import TrackAndTraceStep from '../types/TrackAndTraceStep.enum';
import PlannedDate from './PlannedDate';
import ReviewStepDeviationDatesCell from './ReviewStepDeviationDatesCell';
import SearchStepDeviationDatesCell from './SearchStepDeviationDatesCell';

interface IDeviationDatesCellProps {
  record: DeviationData;
  index: number;
  step: TrackAndTraceStep;
}

const DeviationDatesCell: FC<IDeviationDatesCellProps> = ({
  record,
  index,
  step,
}) => {
  return (
    <Space direction="vertical" size="middle" className="mb-4">
      {record.arrivalDate && (
        <PlannedDate
          arrivalDate={record.arrivalDate}
          arrivalTime={record.arrivalTime || ''}
        />
      )}
      {step === TrackAndTraceStep.SEARCH && (
        <SearchStepDeviationDatesCell index={index} />
      )}
      {step === TrackAndTraceStep.REVIEW && (
        <ReviewStepDeviationDatesCell
          newPlannedDate={record.newPlannedDate as Moment}
          reason={record.deviationReason}
        />
      )}
    </Space>
  );
};

export default DeviationDatesCell;
