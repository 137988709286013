import * as React from 'react';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import ViewSampleOrdersTableRowType from '../../../../types/Samples/ViewSampleOrdersTableRowType.enum';

interface IStatusCellProps {
  order: AugmentedSamplesHistoryOrder;
  rowType: ViewSampleOrdersTableRowType;
}

const StatusCell: React.FunctionComponent<IStatusCellProps> = ({
  order,
  rowType,
}) => {
  const isGrouped = rowType === ViewSampleOrdersTableRowType.GROUPED;
  return (
    <DeconstructedTableBodyCell className="status-cell">
      {!isGrouped ? <div className="font-bold">{order.status}</div> : <></>}
    </DeconstructedTableBodyCell>
  );
};

export default StatusCell;
