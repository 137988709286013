import { t } from '@lingui/macro';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import { getCountriesOptions } from '../../../services/Countries';
import Country from '../../../types/Country.interface';
import NewAddressInputWrapper from './NewAddressInputWrapper';

interface INewAddressProps {
  countries: Country[];
}

const NewAddress: React.FunctionComponent<INewAddressProps> = ({
  countries,
}) => {
  const { register, errors } = useFormContext();
  return (
    <>
      <NewAddressInputWrapper required label={t`Company Name`}>
        <Input
          required
          name="newAddressCompanyName"
          ref={register({
            required: true,
            minLength: 1,
            maxLength: 40,
          })}
          type="string"
          width="full"
          error={errors.newAddressCompanyName}
        />
      </NewAddressInputWrapper>
      <NewAddressInputWrapper required label={t`Address 1`}>
        <Input
          required
          name="newAddressAddress1"
          ref={register({
            required: true,
            minLength: 1,
            maxLength: 40,
          })}
          type="string"
          width="full"
          error={errors.newAddressAddress1}
        />
      </NewAddressInputWrapper>
      <NewAddressInputWrapper label={t`Address 2`}>
        <Input
          name="newAddressAddress2"
          ref={register({
            maxLength: 40,
          })}
          type="string"
          width="full"
          error={errors.newAddressAddress2}
        />
      </NewAddressInputWrapper>
      <NewAddressInputWrapper required label={t`City`}>
        <Input
          required
          name="newAddressCity"
          ref={register({
            required: true,
            minLength: 1,
            maxLength: 40,
          })}
          type="string"
          width="full"
          error={errors.newAddressCity}
        />
      </NewAddressInputWrapper>
      <NewAddressInputWrapper required label={t`Country`}>
        <Select
          required
          options={getCountriesOptions(countries, true)}
          width="full"
          name="newAddressCountryCode"
          ref={register()}
        />
      </NewAddressInputWrapper>
      <NewAddressInputWrapper required label={t`Postal Code`}>
        <Input
          required
          name="newAddressPostalCode"
          ref={register({
            required: true,
            minLength: 1,
            maxLength: 10,
          })}
          type="string"
          width="full"
          error={errors.newAddressPostalCode}
        />
      </NewAddressInputWrapper>
    </>
  );
};

export default NewAddress;
