import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { Button, ButtonProps } from '../../components/Button/Button';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

interface IAddToBasketButtonProps extends ButtonProps {
  loading?: boolean;
}

const AddToBasketButton: React.FunctionComponent<IAddToBasketButtonProps> = ({
  children,
  loading = false,
  ...rest
}) => {
  const [priorLoadingState, setPriorLoadingState] = useState<boolean>(false);
  const [addedToBasketVersion, setAddedToBasketVersion] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (!loading && priorLoadingState !== false) {
      setAddedToBasketVersion(true);
      setTimeout(() => setAddedToBasketVersion(false), 3000);
    } else {
      setPriorLoadingState(loading);
    }
  }, [loading, priorLoadingState]);

  return (
    <Button {...rest} data-test-id="qob-add-to-basket">
      <div className="flex">
        <div style={{ marginTop: '2px' }}>
          {loading ? <LoadingSpinner /> : <></>}
        </div>
        <div className="px-1">
          {addedToBasketVersion ? (
            <div>
              {' '}
              <span className="fa fa-check mr-2" />
              <Trans>Added!</Trans>
            </div>
          ) : (
            <Trans>Add To Basket</Trans>
          )}
        </div>
      </div>
    </Button>
  );
};

export default AddToBasketButton;
