import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import LabelValue from '../../../components/LabelValue/LabelValue';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import useRole from '../../../hooks/useRole';
import stripZeros from '../../../services/Util/stripZeros.util';
import { AuthorityRole } from '../../../types/Authority.interface';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import { StockAvailabilityOverview } from '../../../types/StockAvailabilityOverview.interface';
import AddStockItemToBasket from './AddStockItemToBasket';

interface IDescriptionCellProps {
  data: StockAvailabilityOverview;
  compareData?: StockAvailabilityOverview;
  showAddItemToBasket?: boolean;
}

const DescriptionCell: React.FunctionComponent<IDescriptionCellProps> = ({
  data,
  compareData,
  showAddItemToBasket,
}) => {
  const { format: formatNumber } = useNumberFormatter();
  const { hasRole } = useRole();
  const { measurementSystem } = useMeasurementSystem();

  const highlightMismatch = <U extends keyof StockAvailabilityOverview>(
    key: U
  ) =>
    compareData && compareData[key] !== data[key]
      ? 'bg-yellow-lemonChiffon'
      : '';

  const RELEASE_USER_REEL_DIMENSIONS =
    measurementSystem === MeasurementSystem.METRIC ? (
      <>
        {!!data.reelWidth && !!data.reelDiameter && (
          <div className="text-xl font-bold text-blue-pacific">
            {data.grainDirection} {data.reelWidth} x {data.reelDiameter}
          </div>
        )}
      </>
    ) : (
      <>
        {!!data.reelWidthInches && !!data.reelDiameterInches && (
          <div className="text-xl font-bold text-blue-pacific">
            {data.grainDirection} {data.reelWidthInches} x{' '}
            {data.reelDiameterInches}
          </div>
        )}
      </>
    );

  const NON_RELEASE_USER_PRODUCT_DIMENSIONS = 
    measurementSystem === MeasurementSystem.METRIC ? (
      <>
        {!!data.width && !!data.length && (
          <div className="text-xl font-bold text-blue-pacific">
            {data.grainDirection} {data.width} x {data.length}
          </div>
        )}
      </>
      ) : (
      <>
        {!!data.widthInches && !!data.lengthInches && (
          <div className="text-xl font-bold text-blue-pacific">
            {data.grainDirection} {data.widthInches} x {data.lengthInches}
          </div>
        )}
      </>
    );

  return (
    <div className="text-lg text-gray-dark200 py-2 pl-1">
      {measurementSystem === MeasurementSystem.METRIC && (
        <div className="text-xl font-bold text-blue-pacific">
          {data.brand} {data.grammage}
        </div>
      )}
      {measurementSystem === MeasurementSystem.IMPERIAL && (
        <div className="text-xl font-bold text-blue-pacific">
          {data.brand} {data.bookweight} {data.usBaseWeight}
        </div>
      )}
      {hasRole(AuthorityRole.ROLE_RELEASE)
        ? RELEASE_USER_REEL_DIMENSIONS
        : NON_RELEASE_USER_PRODUCT_DIMENSIONS}
      {data.sheetsPerPallet && parseInt(data.sheetsPerPallet, 10) > 0 && (
        <div className={highlightMismatch('sheetsPerPallet')}>
          {stripZeros(data.sheetsPerPallet)} SH/
          <span className="capitalize">{data.unitOfMeasure}</span>
        </div>
      )}
      {data.stacksPerPallet && parseInt(data.stacksPerPallet, 10) > 0 && 
        data.unitOfMeasure === 'Pallet' && (
        <div className={highlightMismatch('stacksPerPallet')}>
          {stripZeros(data.stacksPerPallet)} <Trans>ST/Pallet</Trans>
        </div>
      )}
      {data.stacksPerPallet && parseInt(data.stacksPerPallet, 10) > 0 && 
        data.unitOfMeasure === 'carton' && (
        <div className={highlightMismatch('stacksPerPallet')}>
          {stripZeros(data.stacksPerPallet)} <Trans>CT/Pallet</Trans>
        </div>
      )}
      {data.reelWidthInches && (
        <LabelValue
          label={t`Roll Width`}
          inverse
          inline
          className={highlightMismatch('reelWidthInches')}
          value={
            measurementSystem === MeasurementSystem.METRIC
              ? data.reelWidth
              : data.reelWidthInches
          }
        />
      )}
      {data.reelDiameterInches && (
        <LabelValue
          label={t`Roll Diameter`}
          inverse
          inline
          className={highlightMismatch('reelDiameterInches')}
          value={
            measurementSystem === MeasurementSystem.METRIC
              ? data.reelDiameter
              : data.reelDiameterInches
          }
        />
      )}
      {!hasRole(AuthorityRole.ROLE_RELEASE) &&
        data.isCpStock &&
        data.unitOfMeasure !== 'Pallet' && (
          <LabelValue
            label={t`Core Diameter`}
            inverse
            inline
            className={highlightMismatch('coreDiameter')}
            value={
              measurementSystem === MeasurementSystem.METRIC
                ? data.coreDiameter
                : data.coreDiameterImp
              }
          />
        )}
      {!hasRole(AuthorityRole.ROLE_RELEASE) &&
        data.unitOfMeasure !== 'Pallet' && (
          <LabelValue
            label={
              measurementSystem === MeasurementSystem.METRIC
              ? t`Avg Lineal Metre`
              : t`Avg Lineal Feet`
            }
            inverse
            inline
            className={highlightMismatch('averageLinealFt')}
            value={
              measurementSystem === MeasurementSystem.METRIC
              ? formatNumber(Math.round(data.averageLinealM))
              : formatNumber(Math.round(data.averageLinealFt))
            }
          />
        )}
      {!hasRole(AuthorityRole.ROLE_RELEASE) &&
        data.windingDirection &&
        data.isCpStock && (
          <LabelValue
            label={t`Winding Direction`}
            inverse
            inline
            className={highlightMismatch('windingDirection')}
            value={data.windingDirection}
          />
        )}
      {!hasRole(AuthorityRole.ROLE_RELEASE) && data.widthInches && 
        measurementSystem === MeasurementSystem.IMPERIAL && (
        <LabelValue
          label={t`M Weight`}
          inverse
          inline
          className={highlightMismatch('weight')}
          value={formatNumber(Math.round(data.weight))}
        />
      )}
      {!hasRole(AuthorityRole.ROLE_RELEASE) && data.bookweight && (
        <LabelValue
          label={
            measurementSystem === MeasurementSystem.METRIC 
              ? t`Grammage`
              : t`Basis Weight`
          }
          inverse
          inline
          className={
            measurementSystem === MeasurementSystem.METRIC 
              ? highlightMismatch('grammage')
              : highlightMismatch('bookweight')
          }
          value={
            measurementSystem === MeasurementSystem.METRIC 
              ? data.grammage
              : data.bookweight
          }
        />
      )}
      {data.reelWidth > 0 && 
        measurementSystem === MeasurementSystem.METRIC && (
        <LabelValue
          label={t`Avg Roll Wgt`}
          inverse
          inline
          className={highlightMismatch('weightKg')}
          value={`${formatNumber(Math.round(data.weightKg))} kg`}
        />
      )}
      {data.reelWidthInches && 
        measurementSystem === MeasurementSystem.IMPERIAL && (
        <LabelValue
          label={t`Avg Roll Wgt`}
          inverse
          inline
          className={highlightMismatch('weight')}
          value={`${formatNumber(Math.round(data.weight))} lbs`}
        />
      )}
      {(!!compareData || hasRole(AuthorityRole.ROLE_RELEASE)) &&
        data.reelWrapping && (
          <LabelValue
            label={t`Reel Wrapping`}
            inverse
            inline
            className={highlightMismatch('reelWrapping')}
            value={data.reelWrapping}
          />
        )}
      {(!!compareData || hasRole(AuthorityRole.ROLE_RELEASE)) &&
        data.reelPackage && (
          <LabelValue
            label={t`Reel Package`}
            inverse
            inline
            className={highlightMismatch('reelPackage')}
            value={data.reelPackage}
          />
        )}
      {!!compareData ||
        (hasRole(AuthorityRole.ROLE_RELEASE) && data.windingDirection && (
          <LabelValue
            label={t`Winding Direction`}
            inverse
            inline
            className={highlightMismatch('windingDirection')}
            value={data.windingDirection}
          />
        ))}
      {!!compareData && data.wrapDescription && (
        <div className={highlightMismatch('wrapDescription')}>
          {data.wrapDescription}
        </div>
      )}
      {showAddItemToBasket && (
        <AddStockItemToBasket data={data} isContainerExpanded />
      )}
    </div>
  );
};

export default DescriptionCell;
