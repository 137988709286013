import { t, Trans } from '@lingui/macro';
import Axios from 'axios';
import { debounce } from 'lodash';
import React, { FC } from 'react';
import { useQueryCache } from 'react-query';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { MANUAL_LOG_OUT } from './App.const';
import AuthRoute from './components/AuthRoute';
import Note from './components/Note';
import Account from './domain/Account/Account';
import Password from './domain/Account/Password/Password';
import EuBasket from './domain/Basket/Eu/EuBasket';
import EuBasketOrderSubmitted from './domain/Basket/Eu/EuBasketOrderSubmitted';
import EuBasketSaved from './domain/Basket/Eu/EuBasketSaved';
import NaBasket from './domain/Basket/Na/NaBasket';
import OrderSubmitted from './domain/Basket/Na/OrderSubmitted/OrderSubmitted';
import Calculators from './domain/Calculators/Calculators';
import CalculatorsNote from './domain/Calculators/CalculatorsNote';
import { resetLiveAgent } from './domain/Chat/resetLiveAgent.util';
import AddAttachment from './domain/ClaimsReporting/AddAttachment';
import AttachFilesPageHeader from './domain/ClaimsReporting/AttachFilesPageHeader';
import ClaimsReporting from './domain/ClaimsReporting/ClaimsReporting';
import FileClaim from './domain/ClaimsReporting/FileClaim';
import FileClaimSearch from './domain/FileClaim/FileClaimSearch';
import FileClaimConfirmation from './domain/ClaimsReporting/FileClaimConfirmation';
import FileClaimUpload from './domain/ClaimsReporting/FileClaimUpload';
import ConsignmentConsumption from './domain/Consignment/Consumption/ConsignmentConsumption';
import ConsignmentReporting from './domain/Consignment/Reporting/ConsignmentReporting';
import Dashboard from './domain/Dashboard/Dashboard';
import FinancialReporting from './domain/FinancialReporting/FinancialReporting';
import ForgotPassword from './domain/ForgotPassword/ForgotPassword';
import ForgotUsername from './domain/ForgotUsername/ForgotUsername';
import Help from './domain/Help/Help';
import Login from './domain/Login/Login';
import { isUserTrackAndTraceUser } from './domain/Login/Login.util';
import RequiredPasswordChange from './domain/Login/RequiredPasswordChange';
import LoginHelp from './domain/LoginHelp/LoginHelp';
import News from './domain/News/News';
import NewsPageHeader from './domain/News/NewsPageHeader';
import OpenOrders from './domain/OpenOrders/OpenOrders';
import OrderBooking from './domain/OrderBooking/OrderBooking';
import PackingListReporting from './domain/PackingListReporting/PackingListReporting';
import PageHeader from './domain/PageHeader/PageHeader';
import HeaderItemKey from './domain/PageHeader/types/HeaderItemKey.enum';
import HelpCategory from './domain/PageHeader/types/HelpCategory.enum';
import Policies from './domain/Policies/Policies';
import PrintDocuments from './domain/PrintDocuments/PrintDocuments';
import PrintLabels from './domain/PrintLabels/PrintLabels';
import ProductAvailability from './domain/ProductAvailability/ProductAvailability';
import QuickOrderBooking from './domain/QuickOrderBooking/QuickOrderBooking';
import Registration from './domain/Registration/Registration';
import RegistrationConfirmation from './domain/Registration/RegistrationConfirmation';
import SamplesBasket from './domain/Samples/Basket/SamplesBasket';
import Copies from './domain/Samples/DummySamples/Copies/Copies';
import CoverOptions from './domain/Samples/DummySamples/CoverOptions/CoverOptions';
import DummySamplesDescription from './domain/Samples/DummySamples/DummySamplesDescription';
import DummySamplesStepper from './domain/Samples/DummySamples/DummySamplesStepper';
import DummySampleStep from './domain/Samples/DummySamples/DummySampleStep.enum';
import SampleFormat from './domain/Samples/DummySamples/SampleFormat/SampleFormat';
import TextPages from './domain/Samples/DummySamples/TextPages/TextPages';
import OrderSamples from './domain/Samples/OrderSamples/OrderSamples';
import OrderPlaced from './domain/Samples/OrderSummary/OrderPlaced';
import OrderPlacedError from './domain/Samples/OrderSummary/OrderPlacedError';
import OrderSummary from './domain/Samples/OrderSummary/OrderSummary';
import SamplesStepper from './domain/Samples/SamplesStepper';
import SampleStep from './domain/Samples/SampleStep.enum';
import SelectBuyer from './domain/Samples/SelectBuyer/SelectBuyer';
import ViewSampleOrders from './domain/Samples/ViewSampleOrders/ViewSampleOrders';
import SavedEnquries from './domain/SavedEnquiries/SavedEnquiries';
import StockLot from './domain/StockLot/StockLot';
import TrackAndTraceLog from './domain/TrackAndTrace/Log/TrackAndTraceLog';
import TrackAndTrace from './domain/TrackAndTrace/TrackAndTrace';
import TrackAndTraceUpload from './domain/TrackAndTrace/Upload/Upload';
import TrackByLabelId from './domain/TrackByLabelId/TrackByLabelId';
import TrackOrders from './domain/TrackOrders/TrackOrders';
import useAnalytics from './hooks/useAnalytics';
import usePageHeaderItems from './hooks/usePageHeaderItems';
import NoMatchRoute from './NoMatchRoute';
import { AuthorityRole } from './types/Authority.interface';
import User from './types/User/User.interface';
import useAppStore from './useApp.store';

const AppRoutes: FC = () => {
  const {
    basePageHeaderItemSet,
    commonPageHeaderItemSet,
  } = usePageHeaderItems();
  const { trackLogin } = useAnalytics();
  const history = useHistory();
  const queryCache = useQueryCache();
  const locationPriorToLogout = useAppStore(
    (state) => state.locationPriorToLogout
  );
  const notDebouncedPathNames = [
    '/login',
    '/loginHelp',
    '/registration',
    '/forgotUsername',
    '/forgotPassword',
    '/passwordChangeRequired',
  ];
  const loginSuccess = async (data: User) => {
    await queryCache.invalidateQueries();
    if (data) {
      trackLogin(data.email, data.username); // The login via the password change callback does not have the necessary data to feed analytics.
    }

    if (locationPriorToLogout && locationPriorToLogout !== '/login') {
      history.push(locationPriorToLogout);
      useAppStore.setState({
        locationPriorToLogout: null,
      });
    } else if (data && isUserTrackAndTraceUser(data)) {
      history.push('/trackAndTrace');
    } else {
      history.push('/home');
    }
  };

  // Since lots of 403's happen at once we want to debounce to cut down on the number of times this code is ran
  const debouncedReturnToLogin = debounce(() => {
    if (!notDebouncedPathNames.includes(history.location.pathname)) {
      // requires use of localStorage because Axios interceptors operate outside of React's lifecylces
      const manualLogOut = localStorage.getItem(MANUAL_LOG_OUT) === 'true';
      if (!manualLogOut) {
        resetLiveAgent();
        useAppStore.setState({
          locationPriorToLogout: history.location.pathname,
        });
        history.push('/login');
        setTimeout(() => {
          window.onbeforeunload = null; // prevents prompt from popping up
          window.location.reload(); // clears locally cached data
        }, 0);
      }
    }
  }, 500);

  Axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response ? error.response.status : null;

      if (status === 403 || status === 401) {
        debouncedReturnToLogin();
      }

      return Promise.reject(error);
    }
  );

  return (
    <Switch>
      <Route path="/registration/submitted">
        <PageHeader title={t`Registration Submitted`} items={[]} />
        <RegistrationConfirmation />
      </Route>
      <Route path="/registration">
        <PageHeader title={t`Registration Form`} items={[]} />
        <Registration />
      </Route>
      <Route path="/login">
        <Login onLoginSuccess={loginSuccess} />
      </Route>
      <Route path="/passwordChangeRequired">
        <PageHeader title={t`Update Password`} items={[]} />
        <RequiredPasswordChange onLoginSuccess={loginSuccess} />
      </Route>
      <Route path="/forgotPassword">
        <ForgotPassword />
      </Route>
      <Route path="/forgotUsername">
        <ForgotUsername />
      </Route>
      <Route path="/loginHelp">
        <LoginHelp />
      </Route>
      <Route path="/home">
        <PageHeader
          title={t`Your Dashboard`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.HOME_PAGE}
        />
        <Dashboard />
      </Route>
      <Route path="/quickOrder">
        <PageHeader
          title={t`Quick Order`}
          items={[HeaderItemKey.QOB_BASKET, ...basePageHeaderItemSet]}
        />
        <QuickOrderBooking />
      </Route>
      <Route path="/availabilityPlanning">
        <div className="flex justify-end">
          <Note style={{ width: '580px' }}>
            <Trans>
              Please contact your Sappi Inside Sales representative if you do
              not see the product which meets your needs.
            </Trans>
          </Note>
        </div>
        <PageHeader
          title={t`Product Availability`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.PRODUCT_AVAILABILITY}
        />
        <ProductAvailability />
      </Route>
      <Route exact path="/documentPrinter_">
        <PageHeader
          title={t`Print Documents`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.PRINT_DOCUMENTS}
        />
        <PrintDocuments />
      </Route>
      <Route path="/reporting/financialReport">
        <PageHeader
          title={t`Financial Reporting`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.FINANCIAL_REPORTING}
        />
        <FinancialReporting />
      </Route>
      <Route exact path="/orderTracking">
        <PageHeader
          title={t`Track Orders`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.TRACK_ORDERS}
        />
        <TrackOrders />
      </Route>
      <Route path="/orderTracking/details/:docNumber/:docPosition">
        <PageHeader
          title={t`Order Details`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.TRACK_ORDERS}
        />
        <TrackOrders />
      </Route>
      <Route path="/orderTracking/deliveryDetails/:docNumber/:docPosition">
        <PageHeader
          title={t`Delivery Details`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.TRACK_ORDERS}
        />
        <TrackOrders />
      </Route>
      <Route path="/orderTracking/invoice/:docNumber/:docPosition">
        <PageHeader
          title={t`Invoice Details`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.TRACK_ORDERS}
        />
        <TrackOrders />
      </Route>
      <Route path="/claim/file/:documentNumber/:documentPosition">
        <PageHeader
          title={t`File a Claim`}
          items={commonPageHeaderItemSet}
        />
        <FileClaim />
      </Route>
      <Route path="/reporting/claimReport/addAttachment/:complaintNumber/:fileNumber/:customerNumber">
        <AttachFilesPageHeader />
        <AddAttachment />
      </Route>
      <Route path="/claim/upload/:documentNumber/:documentPosition/:complaintNumber/:index">
        <AttachFilesPageHeader />
        <FileClaimUpload />
      </Route>
      <Route path="/claim/confirmation/:documentNumber/:documentPosition/:complaintNumber/:index">
        <PageHeader title={t`Claim Filed`} items={commonPageHeaderItemSet} />
        <FileClaimConfirmation />
      </Route>
      <Route exact path="/reporting/claimReport/:customerNumber">
        <PageHeader
          title={t`Claims Reporting`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.CLAIM_REPORTING}
        />
        <ClaimsReporting />
      </Route>
      <Route exact path="/reporting/claimReport">
        <PageHeader
          title={t`Claims Reporting`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.CLAIM_REPORTING}
        />
        <ClaimsReporting />
      </Route>
      <Route exact path="/fileClaimSearch">
        <PageHeader
          title={t`File a Claim`}
          items={commonPageHeaderItemSet}
        />
        <FileClaimSearch  isWidget={false} />
      </Route>
      <Route exact path="/packingList">
        <PageHeader
          title={t`Packing List Reporting`}
          items={commonPageHeaderItemSet}
        />
        <PackingListReporting />
      </Route>
      <AuthRoute
        hasAll={[AuthorityRole.ROLE_MILL_DIRECT]}
        path="/millDirect/search"
      >
        <PageHeader
          title={t`All Open Orders`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.OPEN_ORDERS}
        />
        <OpenOrders />
      </AuthRoute>
      <Route path="/samples/findProducts">
        <SamplesStepper activeStep={SampleStep.ORDER_SAMPLES} />
        <PageHeader
          title={t`Order Samples`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <OrderSamples />
      </Route>
      <Route path="/samples/selectBuyer">
        <SamplesStepper activeStep={SampleStep.BASIC_INFORMATION} />
        <PageHeader
          title={t`Order Samples`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <SelectBuyer />
      </Route>
      <Route path="/samples/basket">
        <SamplesStepper activeStep={SampleStep.BASKET} />
        <PageHeader
          title={t`Order Samples`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <SamplesBasket />
      </Route>
      <Route path="/samples/summary">
        <SamplesStepper activeStep={SampleStep.ORDER_SUMMARY} />
        <PageHeader
          title={t`Order Samples`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <OrderSummary />
      </Route>
      <Route path="/samples/ordered/error/:failedOrderReference">
        <SamplesStepper activeStep={SampleStep.ORDER_SUMMARY} />
        <PageHeader
          title={t`Order Samples`}
          items={[HeaderItemKey.HELP]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <OrderPlacedError />
      </Route>
      <Route path="/samples/ordered/:orderNumber">
        <SamplesStepper activeStep={SampleStep.ORDER_SUMMARY} />
        <PageHeader
          title={t`Order Samples`}
          items={[HeaderItemKey.HELP]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <OrderPlaced />
      </Route>
      <Route path="/samples/createDummy/format">
        <DummySamplesStepper activeStep={DummySampleStep.FORMAT} />
        <PageHeader
          title={t`Order a Dummy`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <SampleFormat />
      </Route>
      <Route path="/samples/createDummy/textPages">
        <DummySamplesStepper activeStep={DummySampleStep.TEXT_PAGES} />
        <PageHeader
          title={t`Order a Dummy`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <TextPages />
      </Route>
      <Route path="/samples/createDummy/coverOptions">
        <DummySamplesStepper activeStep={DummySampleStep.COVER_OPTIONS} />
        <PageHeader
          title={t`Order a Dummy`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <CoverOptions />
      </Route>
      <Route path="/samples/createDummy/copies">
        <DummySamplesStepper activeStep={DummySampleStep.COPIES} />
        <PageHeader
          title={t`Order a Dummy`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <Copies />
      </Route>
      <Route path="/samples/createDummy">
        <DummySamplesStepper activeStep={DummySampleStep.DESCRIPTION} />
        <PageHeader
          title={t`Order a Dummy`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <DummySamplesDescription />
      </Route>
      <Route path="/samples/status">
        <PageHeader
          title={t`My Sample Orders`}
          items={[HeaderItemKey.SAMPLES_BASKET, ...basePageHeaderItemSet]}
          helpCategory={HelpCategory.SAMPLES}
        />
        <ViewSampleOrders />
      </Route>
      <AuthRoute
        path="/orderBooking"
        hasAll={[AuthorityRole.ROLE_EU, AuthorityRole.ROLE_DIRECT_BOOKING]}
      >
        <PageHeader title={t`Order Booking`} items={commonPageHeaderItemSet} />
        <OrderBooking />
      </AuthRoute>
      <Route path="/consignmentConsumption/:status">
        <PageHeader
          title={t`Consignment Consumption`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.CONSIGNMENT_CONSUMPTION}
        />
        <ConsignmentConsumption />
      </Route>
      <Route exact path="/consignmentConsumption">
        <PageHeader
          title={t`Consignment Consumption`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.CONSIGNMENT_CONSUMPTION}
        />
        <ConsignmentConsumption />
      </Route>
      <Route path="/consignment">
        <PageHeader
          title={t`Consignment`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.CONSIGNMENT_REPORTING}
        />
        <ConsignmentReporting />
      </Route>
      <Route path="/calculators">
        <div className="flex justify-end">
          <CalculatorsNote style={{ width: '580px' }} />
        </div>
        <PageHeader
          title={t`Calculators`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.CALCULATORS}
        />
        <Calculators />
      </Route>
      <Route path="/policies">
        <PageHeader
          title={t`Policies & Conditions`}
          items={commonPageHeaderItemSet}
        />
        <Policies />
      </Route>
      <Route path="/stockLot">
        <PageHeader
          title={t`Stock Lot`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.STOCK_LOT}
        />
        <StockLot />
      </Route>
      <Route path="/trackByLabel">
        <PageHeader
          title={t`Track By Label ID`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.TRACK_BY_LABEL}
        />
        <TrackByLabelId isWidget={false} />
      </Route>
      <Route path="/savedenquiries">
        <PageHeader
          title={t`My Saved Enquiries`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.MY_SAVED_ENQUIRIES}
        />
        <SavedEnquries />
      </Route>
      <Route exact path="/account">
        <PageHeader
          title={t`My Account Information`}
          items={basePageHeaderItemSet}
        />
        <Account />
      </Route>
      <Route path="/account/password">
        <PageHeader title={t`Update Password`} items={[]} />
        <Password />
      </Route>
      <Route path="/news/:newsId">
        <NewsPageHeader />
        <News />
      </Route>
      <Route path="/submitOrder/submitted">
        <PageHeader
          title={t`Your Purchase Order has been submitted!`}
          items={basePageHeaderItemSet}
        />
        <OrderSubmitted />
      </Route>
      <Route path="/submitOrder">
        <NaBasket />
      </Route>
      <Route exact path="/basket">
        <PageHeader
          title={t`Basket`}
          items={commonPageHeaderItemSet}
          helpCategory={HelpCategory.BASKET}
        />
        <EuBasket />
      </Route>
      <Route path="/help">
        <PageHeader title={t`Help`} items={[]} />
        <Help />
      </Route>
      <Route path="/basket/:basketId/booked">
        <PageHeader
          title={t`Your order has been booked!`}
          items={commonPageHeaderItemSet}
        />
        <EuBasketOrderSubmitted />
      </Route>
      <Route path="/basket/:basketId/saved">
        <PageHeader title={t`Basket Saved`} items={commonPageHeaderItemSet} />
        <EuBasketSaved />
      </Route>
      <Route path="/trackAndTrace/log/:payloadId">
        <PageHeader
          title={t`Track And Trace Logs`}
          helpCategory={HelpCategory.TRACK_AND_TRACE}
          items={[HeaderItemKey.HELP]}
        />
        <TrackAndTraceLog />
      </Route>
      <Route path="/trackAndTrace/upload/:status">
        <PageHeader
          title={t`Track And Trace`}
          helpCategory={HelpCategory.TRACK_AND_TRACE}
          items={[HeaderItemKey.HELP]}
        />
        <TrackAndTraceUpload />
      </Route>
      <Route path="/trackAndTrace/upload">
        <PageHeader
          title={t`Track And Trace`}
          helpCategory={HelpCategory.TRACK_AND_TRACE}
          items={[HeaderItemKey.HELP]}
        />
        <TrackAndTraceUpload />
      </Route>
      <Route path="/trackAndTrace">
        <PageHeader
          title={t`Track And Trace`}
          helpCategory={HelpCategory.TRACK_AND_TRACE}
          items={[HeaderItemKey.HELP]}
        />
        <TrackAndTrace />
      </Route>
      <Route path="/labelPrinter">
        <PageHeader title={t`Print Labels`} items={commonPageHeaderItemSet} />
        <PrintLabels />
      </Route>
      <Route path="/">
        <Redirect to={{ pathname: '/login' }} />
      </Route>
      <Route path="*">
        <NoMatchRoute />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
