import { noop, orderBy } from 'lodash';
import * as React from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import { generateOpenOrdersExcel } from '../../../services/Excel';
import OpenOrders from '../../../types/OpenOrders.interface';

interface IDownloadOpenOrdersProps {
  openOrders: OpenOrders[];
}

const DownloadOpenOrders: React.FunctionComponent<IDownloadOpenOrdersProps> = ({
  openOrders,
  children,
}) => {
  const { trackPageView } = useAnalytics();
  const downloadOpenOrders = async () => {
    if (openOrders.length > 0) {
      trackPageView('MILL_DIRECT', 'DOWNLOAD');
      await generateOpenOrdersExcel(
        orderBy(openOrders, ['dateCreated', 'customerOrderNumber'], ['asc', 'asc'])
      );
      window.location.href = `${window.location.protocol}//${window.location.host}/portal/resources/mill-direct-orders/excel`;
    }
  };
  return (
    <div
      onClick={() => downloadOpenOrders()}
      onKeyDown={noop}
      role="button"
      tabIndex={0}
    >
    {children}
    </div>
  );
};

export default DownloadOpenOrders;
