import { filter } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logoImage from '../../assets/sappi_e-commerce_logo.svg';
import { Button } from '../../components/Button/Button';
import useRole from '../../hooks/useRole';
import useUser from '../../hooks/useUser';
import { isUserTrackAndTraceUser } from '../Login/Login.util';
import BreadCrumb from './BreadCrumb';
import MenuNavigation from './MenuNavigation';
import styles from './TopMenu.module.scss';
import {
  buildBreadCrumbValues,
  getSappiMenuItems,
  getUrlParamFromHistory,
} from './TopMenu.util';
import { getAllLinks } from './TopMenuConfigs.util';
import { SappiMenuItem } from './Types/SappiMenuItem.interface';

const TopMenu: FC = () => {
  const { roles } = useRole();
  const { data: user } = useUser();
  const history = useHistory();
  const isAuthenticated = !!user;

  const location = useLocation();
  const [sappiMenuItems, setSappiMenuItems] = useState<SappiMenuItem[]>([]);
  const [breadCrumbValues, setBreadCrumbValues] = useState<string[]>([]);
  const [backToHome, setBackToHome] = useState<boolean>(false);

  // When URL changed
  useEffect(() => {
    const unlisten = history.listen((historyLocation) => {
      const urlParam = getUrlParamFromHistory(
        historyLocation.pathname,
        historyLocation.search
      );

      setBreadCrumbValues(buildBreadCrumbValues(urlParam));
    });

    return unlisten;
  }, [history]);

  // When page reloaded
  useEffect(() => {
    const urlParam =
      location.pathname +
      (location.pathname.indexOf('calculators') >= 0 ? location.search : '');

    setBreadCrumbValues(buildBreadCrumbValues(urlParam));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const goHome = () => {
    if (user && isUserTrackAndTraceUser(user)) {
      history.push('/trackAndTrace');
    } else {
      history.push('/home');
    }

    setBackToHome((state) => !state);
  };

  useEffect(() => {
    if (roles && user && !isUserTrackAndTraceUser(user)) {
      const menuItems = getSappiMenuItems(getAllLinks(), roles);
      const filteredMenuItems = filter(
        menuItems,
        (menuItem) =>
          getSappiMenuItems(menuItem, roles) &&
          getSappiMenuItems(menuItem, roles).length > 0
      ) as SappiMenuItem[];
      setSappiMenuItems(filteredMenuItems);
    } else {
      setSappiMenuItems([]);
    }
  }, [user, roles]);

  return (
    <>
      <div
        className="border-blue-cobaltVariation whitespace-nowrap flex"
        style={{ minWidth: '1200px', borderBottomWidth: '3px' }}
      >
        <div className="inline-block pt-3">
          <Button
            theme="link"
            style={{ width: '250px', height: '63px' }}
            onClick={goHome}
          >
            <img src={logoImage} alt="logoImage" className={styles.SappiLogo} />
          </Button>
        </div>
        <div className="pl-3 pt-5 ">
          {isAuthenticated && (
            <MenuNavigation
              menuItems={sappiMenuItems}
              backToHome={backToHome}
              onMenuItemSelect={(menuItemName, child) => {
                if (child.target === '_self') {
                  history.push(child.url || '');

                  const breadcrumbs = [menuItemName, child.name];
                  setBreadCrumbValues(breadcrumbs);
                } else {
                  window.open(child.url || '', child.target);
                }
              }}
            />
          )}
        </div>
      </div>{' '}
      {breadCrumbValues.length > 0 && user && !isUserTrackAndTraceUser(user) && (
        <div className="text-gray-gray my-3 flex " style={{ fontSize: '15px' }}>
          <BreadCrumb breadCrumbValues={breadCrumbValues} />
        </div>
      )}
    </>
  );
};
export default TopMenu;
