import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { Button } from '../../components/Button/Button';

interface IReviewStepButtonsProps {
  onBack: () => void;
  onConfirm: () => void;
}

const ReviewStepButtons: FC<IReviewStepButtonsProps> = ({
  onBack,
  onConfirm,
}) => {
  return (
    <Space size="middle">
      <Button type="button" onClick={onBack}>
        <Trans>Back</Trans>
      </Button>
      <Button type="button" theme="primary" onClick={onConfirm}>
        <Trans>Confirm</Trans>
      </Button>
    </Space>
  );
};

export default ReviewStepButtons;
