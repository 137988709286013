import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import useDummyOrderStore from '../useDummyOrder.store';
import Card from '../../../../components/Card/Card';
import DummyButtons from '../DummyButtons';
import Textarea from '../../../../components/Textarea/Textarea';
import AddDummyToBasket from './AddDummyToBasket';
import DummySamplesUserGuide from '../DummySamplesUserGuide';
import Radio from '../../../../components/Radio/Radio';
import {
  getBinding,
  getCoverPages,
  getSize,
  isPrintedDummyUnavailable,
} from './Copies.util';
import { SamplesDummyCharacteristics } from '../../../../types/Samples/SamplesDummyCharacteristics.interface';
import colorBookImage from '../../../../assets/dummy_sample/color-book.png';
import styles from './Copies.module.scss';
import SamplesProductType from '../../../../types/Samples/SamplesProductType.enum';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import ErrorTooltip from '../../../../components/ErrorTooltip/ErrorTooltip';

const Copies: React.FunctionComponent = () => {
  const state = useDummyOrderStore();
  const history = useHistory();
  const { parse } = useNumberFormatter();

  const { register, getValues, watch, errors, reset, handleSubmit } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const prev = () => {
    const parsedQuantity = parse(getValues('quantity'));

    useDummyOrderStore.setState({
      dummyType: getValues('dummyType'),
      quantity: parsedQuantity,
      additionalInstructions:
        getValues('additionalInstructions') &&
        getValues('additionalInstructions').length < 131
          ? getValues('additionalInstructions')
          : '',
    });
    history.push('/samples/createDummy/coverOptions');
  };

  return (
    <>
      <DummySamplesUserGuide />
      <div style={{ minWidth: '960px' }}>
        <Card header={<Trans>Select the number of copies required</Trans>}>
          <div
            className={`${
              !isPrintedDummyUnavailable(state as SamplesDummyCharacteristics)
                ? 'flex'
                : ''
            }`}
          >
            {!isPrintedDummyUnavailable(
              state as SamplesDummyCharacteristics
            ) && (
              <div className="pt-6 pr-20" style={{ width: '230px' }}>
                <div className="pb-8">
                  <Trans>
                    The format you have chosen is available as a printed dummy
                  </Trans>
                </div>
                <img
                  src={colorBookImage}
                  alt={t`colorBookImager`}
                  className={`mb-4 ${styles.coverFormatPrintedDisplay}`}
                />
              </div>
            )}
            <form
              className={`pr-2 pl-4 ${
                !isPrintedDummyUnavailable(state as SamplesDummyCharacteristics)
                  ? 'w-5/6'
                  : ''
              }`}
            >
              <div className="flex text-lg font-bold">
                <div className="bg-gray-dark100 text-white-white p-6 w-1/5 border-r border-gray-whiteSmoke400">
                  Description
                </div>
                <div className="bg-gray-dark100 text-white-white p-6 w-1/4 border-r border-gray-whiteSmoke400">
                  Type
                </div>
                <div className="bg-gray-dark100 text-white-white p-6 w-7/12">
                  Item Detail
                </div>
              </div>
              <div className="flex border-b border-gray-light400">
                <div className="p-6 w-1/5">{state.description}</div>
                <div className="p-6 text-lg w-1/4">
                  <div className="flex">
                    <Radio
                      name="dummyType"
                      value="UNPRN"
                      ref={register}
                      label=""
                      defaultChecked={
                        isPrintedDummyUnavailable(
                          state as SamplesDummyCharacteristics
                        ) || state.dummyType !== SamplesProductType.PRINTED
                      }
                    />
                    <label className="pl-1 pb-2">Unprinted</label>
                  </div>
                  <div className="flex">
                    {!isPrintedDummyUnavailable(
                      state as SamplesDummyCharacteristics
                    ) && (
                      <>
                        <Radio
                          name="dummyType"
                          value="PRINT"
                          ref={register}
                          label=""
                          defaultChecked={
                            !isPrintedDummyUnavailable(
                              state as SamplesDummyCharacteristics
                            ) && state.dummyType === SamplesProductType.PRINTED
                          }
                        />
                        <label className="pl-1">Printed</label>
                      </>
                    )}
                  </div>
                </div>
                <div className="p-6 text-lg w-7/12">
                  <div>
                    <Trans>Text:</Trans> {state.textPaperBrand}{' '}
                    {state.textPaperGrammage}gsm
                  </div>
                  <div>
                    <Trans>Text pages:</Trans> {state.textPaperNumberOfPages}
                  </div>
                  {(state.coverPaperDifferentFromText || ('' as string)) !==
                    'false' && (
                    <div>
                      <Trans>Cover:</Trans> {state.coverBrand}{' '}
                      {state.coverGrammage}gsm
                    </div>
                  )}
                  {(state.coverPaperDifferentFromText || ('' as string)) ===
                    'false' && (
                    <div>
                      <Trans>Cover: No (selfcover)</Trans>
                    </div>
                  )}
                  {getCoverPages(state as SamplesDummyCharacteristics) && (
                    <div>
                      <Trans>
                        Cover pages:{' '}
                        {getCoverPages(state as SamplesDummyCharacteristics)}
                      </Trans>
                    </div>
                  )}
                  <div>
                    <Trans>
                      Size: {getSize(state as SamplesDummyCharacteristics)}
                    </Trans>
                  </div>
                  <div>
                    <Trans>Binding: </Trans>
                    {getBinding(state as SamplesDummyCharacteristics)}
                  </div>
                </div>
              </div>

              <div className="text-xl font-extrabold pl-2 pt-6 pb-3">
                Additional Instructions
              </div>
              <div className="flex justify-between">
                <div className="pl-2" style={{ width: '740px' }}>
                  <ErrorTooltip
                    hasError={errors && errors.additionalInstructions}
                    placement="bottom"
                    message={
                      <>
                        <div>
                          <Trans>
                            Your instructions are too long, please shorten to
                            130.
                          </Trans>
                        </div>
                      </>
                    }
                  >
                    <Textarea
                      name="additionalInstructions"
                      width="full"
                      rows={6}
                      ref={register({ maxLength: 130 })}
                      error={errors.additionalInstructions}
                      defaultValue={state.additionalInstructions || ''}
                    />
                  </ErrorTooltip>
                </div>
                <div>
                  <AddDummyToBasket
                    register={register}
                    watch={watch}
                    errors={errors}
                    handleSubmit={handleSubmit}
                  />
                </div>
              </div>
            </form>
          </div>
        </Card>
        <DummyButtons onPrev={prev} />
      </div>
    </>
  );
};

export default Copies;
