import React, { FC, useState } from 'react';
import Results from './Results/Results';
import { getStockLot } from './services';
import StockLotForm from './StockLotForm';
import { StockLotRequest } from './types/StockLotRequest.interface';
import useStockLotStore from './useStockLotStore.store';

const StockLot: FC = () => {
  const { setRequest, setResponse, setIsSearched } = useStockLotStore();
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const doApplyFilters = async (incomingFilters: StockLotRequest) => {
    setRequest(incomingFilters);
    setIsSearching(true);
    const stockLotResponse = await getStockLot(incomingFilters);
    setResponse(stockLotResponse);
    setIsSearched(true);
    setIsSearching(false);
  };

  return (
    <div className="flex mt-5">
      <StockLotForm isSearching={isSearching} onApplyFilters={doApplyFilters} />
      <Results isSearching={isSearching} onApplyFilters={doApplyFilters} />
    </div>
  );
};

export default StockLot;
