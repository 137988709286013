import { t } from '@lingui/macro';
import { capitalize } from 'lodash';
import { Moment } from 'moment';
import React, { FC } from 'react';
import InputLabel from '../../../components/InputLabel';
import { DateFormat } from '../../../hooks/useDateFormatter';
import useValues from '../../../hooks/useValues';
import ValueKey from '../../../types/ValueKey.enum';

interface IReviewStepDeviationDatesCellProps {
  newPlannedDate: Moment | null;
  reason: string | null;
}

const ReviewStepDeviationDatesCell: FC<IReviewStepDeviationDatesCellProps> = ({
  newPlannedDate,
  reason,
}) => {
  const { items } = useValues(ValueKey.DELAY_REASON);
  const reasonString = reason
    ? items?.find((item) => item.value === reason)?.text
    : '';
  return (
    <>
      <InputLabel text={t`New Planned`} size="lg">
        {capitalize(newPlannedDate?.format(DateFormat.BASIC_WITH_TIME))}
      </InputLabel>
      <InputLabel text={t`Reason`} size="lg">
        {reasonString}
      </InputLabel>
    </>
  );
};

export default ReviewStepDeviationDatesCell;
