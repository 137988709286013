import { Trans } from '@lingui/macro';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';

interface IOrderSummaryButtonsProps {
  onPlaceOrder: () => void;
}

const OrderSummaryButtons: React.FunctionComponent<IOrderSummaryButtonsProps> = ({
  onPlaceOrder,
}) => {
  const history = useHistory();
  return (
    <div className="w-full flex justify-between">
      <Button onClick={() => history.push('/samples/basket')}>
        <Trans>Back to Basket</Trans>
      </Button>
      <Button theme="primary" onClick={() => onPlaceOrder()}>
        <Trans>Place Order</Trans>
      </Button>
    </div>
  );
};

export default OrderSummaryButtons;
