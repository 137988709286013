import React from 'react';
import ProductAvailabilityWidget from '../../ProductAvailability/Widget/ProductAvailabilityWidget';
import DashboardWidgetId from '../types/DashboardWidgetId.enum';
import DummyWidget from './DummyWidget';
import TrackByLabelIdWidget from './TrackByLabelIdWidget';
import PrintDocumentsWidget from './PrintDocumentsWidget';

export const WIDGET_COMPONENTS: Record<DashboardWidgetId, React.FC> = {
  [DashboardWidgetId.PRINT_DOCUMENTS]: PrintDocumentsWidget,
  [DashboardWidgetId.TRACK_BY_LABEL_ID]: TrackByLabelIdWidget,
  [DashboardWidgetId.PRODUCT_AVAILABILITY_EU]: DummyWidget,
  [DashboardWidgetId.PRODUCT_AVAILABILITY_EU_RELEASE]: DummyWidget,
  [DashboardWidgetId.PRODUCT_AVAILABILITY_NA]: ProductAvailabilityWidget,
};
