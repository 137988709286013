enum CalculatorId {
  // Sheet calculators:
  M_WEIGHT = 'M_WEIGHT',
  PAGES_PER_INCH = 'PAGES_PER_INCH',
  SHEETS_TO_WEIGHT = 'SHEETS_TO_WEIGHT',
  AREA_PER_SHEET = 'AREA_PER_SHEET',
  // Roll calculators:
  ROLL_WEIGHT_AND_LENGTH = 'ROLL_WEIGHT_AND_LENGTH',
  LINEAR_LENGTH_TO_AREA = 'LINEAR_LENGTH_TO_AREA',
  AREA_WEIGHT_CONVERSION = 'AREA_WEIGHT_CONVERSION',
  ROLL = 'ROLL',
  // Price calculators:
  PRICE_CWT = 'PRICE_CWT',
  PRICE_PER_TON = 'PRICE_PER_TON',
  // Conversion calculators:
  WEIGHT_CONVERSION = 'WEIGHT_CONVERSION',
  LENGTH_CONVERSION = 'LENGTH_CONVERSION',
  BASIS_WEIGHT_CONVERSION = 'BASIS_WEIGHT_CONVERSION',
  THICKNESS_CONVERSION = 'THICKNESS_CONVERSION',
  // eQ calculator:
  EQ = 'EQ',
  // Pallet height & weight calculator:
  PALLET_HEIGHT_WEIGHT = 'PALLET_HEIGHT_WEIGHT',
  // Payload calculator:
  PAYLOAD = 'PAYLOAD',
}

export default CalculatorId;
