import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import React, { FC } from 'react';
import { FieldError, UseFormMethods } from 'react-hook-form';
import QuickLinkInfo from '../../Dashboard/types/QuickLinkInfo.interface';
import CustomizationCheckbox from './CustomizationCheckbox';
import CustomizationArea, { CustomizationAreaProps } from './CustomizationArea';
import AccountFormData from '../types/AccountFormData.interface';
import FieldPrefixes from '../types/FieldPrefixes.enum';
import { validateMaxSelected, validateMinSelected } from '../Account.util';

const MIN_SELECTED = 1;
const MAX_SELECTED = 5;

type CustomizeQuickLinksProps = Omit<CustomizationAreaProps, 'heading'> & {
  formMethods: UseFormMethods<AccountFormData>;
  quickLinks: QuickLinkInfo[];
};

const CustomizeQuickLinks: FC<CustomizeQuickLinksProps> = ({
  formMethods,
  quickLinks,
  ...rest
}) => {
  const { errors, getValues, register, trigger } = formMethods;

  // This type assertion is necessary due to what appears to be a bug wherein
  // TypeScript infers the type of quickLinksError incorrectly as an array.
  const quickLinksError = errors?.[FieldPrefixes.QUICK_LINKS] as
    | FieldError
    | undefined;
  const quickLinksErrorMessage = quickLinksError?.message;

  return (
    <CustomizationArea
      heading={
        <Trans>
          Quick Links{' '}
          <span className="font-normal">
            (select {MIN_SELECTED} to {MAX_SELECTED})
          </span>
        </Trans>
      }
      errorMessage={quickLinksErrorMessage}
      {...rest}
    >
      {quickLinks.map((quickLink) => (
        <CustomizationCheckbox
          key={quickLink.id}
          label={i18n._(quickLink.label)}
          name={FieldPrefixes.QUICK_LINKS}
          value={quickLink.id}
          ref={register({
            validate: {
              minSelected: () =>
                validateMinSelected(
                  getValues()[FieldPrefixes.QUICK_LINKS],
                  MIN_SELECTED
                ),
              maxSelected: () =>
                validateMaxSelected(
                  getValues()[FieldPrefixes.QUICK_LINKS],
                  MAX_SELECTED
                ),
            },
          })}
          onChange={() => trigger(FieldPrefixes.QUICK_LINKS)}
        />
      ))}
    </CustomizationArea>
  );
};

export default CustomizeQuickLinks;
