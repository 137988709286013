import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import AlertModal from '../../../components/AlertModal/AlertModal';
import { Button } from '../../../components/Button/Button';
import { AvailabilityBasketNA } from '../../../types/AvailabilityBasketNA.interface';

interface ISappiBasketOrderConfirmedAlertProps {
  baskets: AvailabilityBasketNA[];
  onConfirm: () => void;
  onConfirmPrint: () => void;
}

const SappiBasketOrderConfirmedAlert: React.FunctionComponent<ISappiBasketOrderConfirmedAlertProps> = ({
  baskets,
  onConfirm,
  onConfirmPrint,
}) => {
  return (
    <AlertModal
      size="md"
      title={t`Purchase Order(s) submitted!`}
      body={
        <div>
          <div>
            <Trans>Your purchase order(s) for</Trans>
          </div>
          <ul>
            {baskets.map((basket) => (
              <li className="my-2" key={basket.id}>
                <strong>
                  {basket.soldToCustomer.name}{' '}
                  <i>
                    ( <Trans>Reference #:</Trans> {basket.soldToPO} )
                  </i>
                </strong>
              </li>
            ))}
          </ul>
          <div>
            <Trans>have been submitted successfully.</Trans>
          </div>
          <br />
          <div>
            <Trans>
              Once your order has been processed and confirmed, an order
              confirmation will be sent, and you will be able to track your
              order via the Track Orders function.
            </Trans>
            <br />
            <br />
            <Trans>
              If you need assistance or have any questions, please contact your
              dedicated Sappi Inside Sales representative. Thank you!
            </Trans>
          </div>
        </div>
      }
      buttons={[
        <Button key="cancel" onClick={onConfirmPrint}>
          <Trans>Print This Information</Trans>
        </Button>,
        <Button key="ok" theme="primary" onClick={onConfirm}>
          <Trans>Close</Trans>
        </Button>,
      ]}
    />
  );
};

export default SappiBasketOrderConfirmedAlert;
