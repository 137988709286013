import React, { FC } from 'react';
import InputLabel from '../../../components/InputLabel';
import Select, { SelectProps } from '../../../components/Select/Select';

interface CalculatorTypePickerProps extends Omit<SelectProps, 'ref'> {
  label: string;
}

const CalculatorTypePicker: FC<CalculatorTypePickerProps> = ({
  label,
  ...rest
}) => {
  return (
    <div className="w-80">
      <InputLabel text={label} size="lg">
        <Select {...rest} />
      </InputLabel>
    </div>
  );
};

export default CalculatorTypePicker;
