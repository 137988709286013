import { Popover } from 'antd';
import * as React from 'react';
import { Button } from '../Button/Button';
import './Callout.scss';

interface ICalloutProps {
  title: string | React.ReactNode;
  body: string | React.ReactNode;
  onClose: () => void;
  visible: boolean;
  placement?:
    | 'left'
    | 'right'
    | 'topLeft'
    | 'top'
    | 'bottom'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
    | undefined;
}

const Callout: React.FunctionComponent<ICalloutProps> = ({
  title,
  visible,
  children,
  body,
  placement,
  onClose,
}) => {
  return (
    <Popover
      placement={placement}
      content={
        <div className="p-4 bg-blue-pacific text-white-white w-80">
          <div className="flex justify-between">
            <div className="text-2xl">{title}</div>
            <div className="relative">
              <Button theme="link" onClick={onClose}>
                <i className="fa fa-times text-white-white text-2xl absolute -top-1 right-1" />
              </Button>
            </div>
          </div>
          <div className="text-lg my-4">{body}</div>
          <div className="w-100 text-center">
            <Button
              theme="primary"
              onClick={onClose}
              className="border-white-white"
            >
              OK
            </Button>
          </div>
        </div>
      }
      visible={visible}
      overlayClassName="sappi-ant-override-popover-callout"
    >
      <span> {children}</span>
    </Popover>
  );
};

export default Callout;
