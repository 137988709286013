import { UseFormMethods } from 'react-hook-form';
import * as React from 'react';
import { t, Trans } from '@lingui/macro';
import Radio from '../../../../components/Radio/Radio';
import CoverOptionType from './CoverOptionType.enum';
import fourPageImage from '../../../../assets/dummy_sample/4page_cover.png';
import styles from './CoverOptions.module.scss';
import PaperDifferentFromTextOptionType from './PaperDifferentFromTextOptionType.enum';

interface ICoverFormatRightTopProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
}

const CoverFormatLeft: React.FunctionComponent<ICoverFormatRightTopProps> = ({
  register,
  watch,
}) => {
  const paperDifferentFromTextOptionWatch = watch('paperDifferentFromText');
  const isPaperDifferentFromText =
    paperDifferentFromTextOptionWatch !==
    PaperDifferentFromTextOptionType.FALSE;

  return (
    <>
      <img
        src={fourPageImage}
        alt={t`Four Page Standard`}
        className={`pl-28 ml-2 mb-4 ${styles.coverPaperImageSize}`}
      />
      <Radio
        name="coverOptionType"
        value={CoverOptionType.FOUR_PAGE_STANDARD}
        labelPosition="below"
        className="mb-4"
        label={
          <span className="text-lg">
            <Trans>4 page cover Standard</Trans>
          </span>
        }
        ref={register({
          required: isPaperDifferentFromText,
        })}
        disabled={!isPaperDifferentFromText}
        width="full"
        required
      />
    </>
  );
};

export default CoverFormatLeft;
