import { QueryResult, useQuery } from 'react-query';
import { getProperties } from '../services/Properties';
import { Properties } from '../types/Properties.interface';
import QueryCacheName from '../types/QueryCacheName.enum';

const useProperties = (): QueryResult<Properties> => {
  return useQuery([QueryCacheName.PROPERTIES], async () => getProperties(), {
    staleTime: Infinity,
  });
};

export default useProperties;
