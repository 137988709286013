import { filter, union } from 'lodash';
import { Key } from 'react';
import create from 'zustand';
import { Order } from '../../../../types/Order.interface';

type ExpandedRowKeyState = {
  keys: Key[];
  add: (key: Key) => void;
  remove: (key: Key) => void;
  replaceAll: (keys: Key[]) => void;
  removeAll: () => void;
  generateRowKey: (record: Order) => Key;
};

const useExpandedRowKeyStore = create<ExpandedRowKeyState>((set) => ({
  keys: [],
  add: (key) =>
    set((state) => ({
      keys: union(state.keys, [key]),
    })),
  remove: (key) =>
    set((state) => ({
      keys: filter(state.keys, (_key) => _key !== key),
    })),
  replaceAll: (keys) => set(() => ({ keys })),
  removeAll: () => set(() => ({ keys: [] })),
  generateRowKey: (record: Order) =>
    `${record.overviews[0].id}-${record.overviews[0].documentNumber}-${record.overviews[0].documentPosition}` as Key,
}));

export default useExpandedRowKeyStore;
