import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import { Button } from '../../../components/Button/Button';
import {
  availabilityUpdateRequired,
  backToSearch,
  isAnyRequestedDateBeforeToday,
} from './Basket.util';
import ErrorTooltip from '../../../components/ErrorTooltip/ErrorTooltip';

interface IUpdateDeliveryDatesProps {
  basket: AvailabilityBasketEU;
  formMethods: UseFormMethods;
  onUpdateBasket: () => void;
}

const UpdateDeliveryDates: FC<IUpdateDeliveryDatesProps> = ({
  basket,
  formMethods,
  onUpdateBasket,
}) => {
  const [mouseVisited, setMouseVisited] = useState<boolean>(false);
  const { errors, watch } = formMethods;

  return (
    <div className="flex justify-between">
      <Button theme="link" onClick={backToSearch} type="button">
        <Trans>Back to Search</Trans>
      </Button>
      <Space size="middle">
        {(true || isAnyRequestedDateBeforeToday(basket, errors)) && (
          <div className="text-right py-2 font-bold text-red-red">
            <Trans>
              The requested delivery precedes the minimum delivery date.
            </Trans>
          </div>
        )}
        <ErrorTooltip
          hasError={!watch('purchaseOrder')}
          message={t`Make sure all parameters are complete.`}
          placement="top"
          defaultVisible={!mouseVisited && availabilityUpdateRequired(basket)}
        >
          <Button
            theme="primary"
            key={`updateDeliveryDates${basket.id}`}
            onClick={onUpdateBasket}
            disabled={isAnyRequestedDateBeforeToday(basket, errors)}
            onMouseOver={() => setMouseVisited(true)}
            type="button"
          >
            <Trans>Update Delivery Dates</Trans>
          </Button>
        </ErrorTooltip>
      </Space>
    </div>
  );
};

export default UpdateDeliveryDates;
