import { Space } from 'antd';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { t } from '@lingui/macro';
import Select from '../../../../components/Select/Select';
import { METRIC_IMP_OPTIONS } from '../../Calculators.util';
import { CalculatorUnitTypes } from '../../types/CalculatorUnitTypes.enum';
import ImperialCriteria from './ImperialCriteria';
import MetricCriteria from './MetricCriteria';
import Input from '../../../../components/Input/Input';
import { METRIC_NUMBER_ONE_DECIMAL_REG_EX } from '../../../../types/RegularExpression.constants';

const MAX_REQUIRED = 999;
interface ICriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  onMetricImperialChange: (selectedValue: CalculatorUnitTypes) => void;
  isImperial: boolean;
}

const Criteria: React.FunctionComponent<ICriteriaProps> = ({
  register,
  isImperial,
  errors,
  onMetricImperialChange,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Select
        name="metImp"
        ref={register({ required: true })}
        options={METRIC_IMP_OPTIONS}
        required
        onChange={(e) =>
          onMetricImperialChange(e.target.value as CalculatorUnitTypes)
        }
      />
      <Input
        name="caliper"
        type="number"
        ref={register({
          required: true,
          min: 0.1,
          max: MAX_REQUIRED,
          pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
        })}
        required
        error={errors?.caliper}
        placeholder={t`Caliper`}
      />
      {!isImperial && <MetricCriteria register={register} errors={errors} />}
      {isImperial && <ImperialCriteria register={register} errors={errors} />}
    </Space>
  );
};

export default Criteria;
