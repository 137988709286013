enum PromoPosition {
  DASHBOARD_TOP = 'dashboard_top',
  DASHBOARD_BOTTOM = 'dashboard_bottom',
  LOGIN_LEFT = 'login_left',
  LOGIN_CENTER = 'login_center',
  LOGIN_RIGHT = 'login_right',
  DASHBOARD_SUSTAINABILITY = 'dashboard_sustainability',
}

export default PromoPosition;
