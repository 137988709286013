import { t } from '@lingui/macro';
import { concat, find, forEach, forIn, sortBy } from 'lodash';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import { MillTotals } from '../../../types/MillTotals.interface';
import { OpenOrdersResults } from '../../../types/OpenOrdersResults.interface';
import Option from '../../../types/Option.type';
import { OrderOverviewRecord } from '../../../types/OrderOverviewRecord.interface';
import { PopoverOptions } from './PopoverOptions.interface';
import Status from './Status.enum';
import { StatusSection } from './StatusSection.interface';
import OpenOrders from '../../../types/OpenOrders.interface';
import stripZeros from '../../../services/Util/stripZeros.util';

export const getPopoverOptions = (
  results: OrderOverviewRecord[]
): PopoverOptions => {
  const ANY_OPTION = { value: '', label: t`Any` };
  const millOptions: Option[] = [];
  const productOptions: Option[] = [];
  const orderPlacedOptions: Option[] = [];
  const confirmedDeliveryDateOptions: Option[] = [];
  const shipToOptions: Option[] = [];
  const concatAny = (array: Option[]) =>
    concat([ANY_OPTION] as Option[], array);

  forEach(results, (result) => {
    if (!find(millOptions, { value: result.millName })) {
      millOptions.push({
        title: result.millName,
        value: result.millName,
        label: result.millName,
      });
    }
    if (!find(productOptions, { value: result.materialDescription })) {
      productOptions.push({
        title: result.materialDescription,
        value: result.materialDescription,
        label: result.materialDescription,
      });
    }
    if (
      !find(confirmedDeliveryDateOptions, {
        value: String(result.deliveryDate),
      })
    ) {
      confirmedDeliveryDateOptions.push({
        title: String(result.deliveryDate),
        value: String(result.deliveryDate),
        label: result.deliveryDate,
      });
    }
    if (
      !find(orderPlacedOptions, {
        value: String(result.sapDateCreated),
      })
    ) {
      orderPlacedOptions.push({
        title: String(result.sapDateCreated),
        value: String(result.sapDateCreated),
        label: result.sapDateCreated,
      });
    }
    if (!find(shipToOptions, { label: result.shipToPartyName })) {
      shipToOptions.push({
        title: `${result.shipToPartyName}[${result.shipToPartyCity}]`,
        value: result.shipToPartyCustomerNumber,
        label: result.shipToPartyName,
      });
    }
  });

  return {
    mill: concatAny(millOptions.sort()),
    product: concatAny(
      productOptions.sort((a, b) =>
        String(a.value).trim().localeCompare(String(b.value).trim())
      )
    ),
    orderPlacedDate: concatAny(
      orderPlacedOptions.sort((a, b) => (a.value > b.value ? 0 : -1))
    ),
    confirmedDeliveryDate: concatAny(
      confirmedDeliveryDateOptions.sort((a, b) => (a.value > b.value ? 0 : -1))
    ),
    shipTo: concatAny(
      shipToOptions.sort((a, b) =>
        String(a.label).localeCompare(String(b.label))
      )
    ),
  };
};

export const getOpenOrdersExcel = (
  results: OrderOverviewRecord[]
) : OpenOrders[] => {
  const openOrders: OpenOrders[] = [];
  forEach(results, (result) => {
    openOrders.push({
      fullName: result.fullName,
      status: result.status,
      dateCreated: result.sapDateCreated,
      deliveryDate: result.deliveryDate,
      deliveryDateUpd: result.deliveryDateUpd,
      arrivalDate: result.arrivalDate,
      arrivalTime: result.arrivalTime,
      customerOrderNumber: result.customerOrderNumber,
      documentNumber: stripZeros(result.documentNumber),
      documentPosition: stripZeros(result.documentPosition),
      deliveryInfo: result.deliveryInfo,
      materialDescription: result.materialDescription,
      materialInfo: result.materialInfo,
      plant: result.plant,
      distChannel: result.distChannel,
      salesOrg: result.salesOrg,
      shipToPartyName: result.shipToPartyName,
      shipToPartyCustomerNumber: result.shipToPartyCustomerNumber,
      shipTpPoNumber: result.shipTpPoNumber,
      shipToPartyCity: result.shipToPartyCity,
      finalShipToPartyName: result.finalShipToPartyName,
      finalShipToPartyCustomerNumber: result.finalShipToPartyCustomerNumber,
      finalShipToPartyCity: result.finalShipToPartyCity,
      pobPartyName: result.pobPartyName,
      pobPartyCustomerNumber: result.pobPartyCustomerNumber,
      pobPartyCity: result.pobPartyCity,
      activeState: result.activeState,
      shipToPurchaseOrderNumber: result.shipToPurchaseOrderNumber,
      kcode: result.kcode,
      customerMaterialNumber: result.customerMaterialNumber,
      latestRevisionDate: result.latestRevisionDate,
      putup: result.putup,
      putupText: result.putupText,
      endUserPO: result.endUserPO,
      decisionMakerName: result.decisionMakerName,
      decisionMakerNumber: result.decisionMakerNumber,
      orderStatusText: result.orderStatusText,
      orderQuantity: result.orderQuantity,
      orderQuantityKg: result.orderQuantityKg,
      orderQuantityMsi: 0,
      orderQuantityMsf: 0,
      orderQuantityLy: 0,
      orderQuantityLf: 0,
      orderQuantityMsm: 0,
      orderQuantityMsc: 0,
      orderQuantityLm: 0,
      openQuantity: result.openQuantity,
      openQuantityKg: result.openQuantityKg,
      openQuantityMsi: 0,
      openQuantityMsf: 0,
      openQuantityLy: 0,
      openQuantityLf: 0,
      openQuantityMsm: 0,
      openQuantityMsc: 0,
      openQuantityLm: 0,
      shippedQuantity: result.shippedQuantity,
      shippedQuantityKg: result.shippedQuantityKg,
      shippedQuantityMsi: 0,
      shippedQuantityMsf: 0,
      shippedQuantityLy: 0,
      shippedQuantityLf: 0,
      shippedQuantityMsm: 0,
      shippedQuantityMsc: 0,
      shippedQuantityLm: 0,
      readyToShipQuantity: result.readyToShipQuantity,
      readyToShipQuantityKg: result.readyToShipQuantityKg,
      readyToShipQuantityMsi: 0,
      readyToShipQuantityMsf: 0,
      readyToShipQuantityLy: 0,
      readyToShipQuantityLf: 0,
      readyToShipQuantityMsm: 0,
      readyToShipQuantityMsc: 0,
      readyToShipQuantityLm: 0,
      underProductionQuantity: result.underProductionQuantity,
      underProductionQuantityKg: result.underProductionQuantityKg,
      underProductionQuantityMsi: 0,
      underProductionQuantityMsf: 0,
      underProductionQuantityLy: 0,
      underProductionQuantityLf: 0,
      underProductionQuantityMsm: 0,
      underProductionQuantityMsc: 0,
      underProductionQuantityLm: 0,
      millName: result.millName,
      unit: result.unit
    });
  });
  return sortBy(openOrders, ['status', 'millName']);
};

const getStatusSectionCount = (
  status: Status,
  results: OrderOverviewRecord[]
): number => {
  let count;
  switch (status) {
    case Status.OPEN:
      count = results.filter((r) => r.openQuantity > 0).length;
      break;
    case Status.READY_TO_SHIP:
      count = results.filter((r) => r.readyToShipQuantity > 0).length;
      break;
    case Status.UNDER_PRODUCTION:
      count = results.filter((r) => r.underProductionQuantity > 0).length;
      break;
    case Status.SHIPPED:
      count = results.filter((r) => r.shippedQuantity > 0).length;
      break;
    default:
      count = 0;
  }
  return count;
};
const getStatusSectionTotalWgt = (
  status: Status,
  totals: MillTotals,
  system: MeasurementSystem
): number => {
  let total = 0;
  forIn(totals, (totalObj) => {
    total +=
      system === MeasurementSystem.IMPERIAL
        ? totalObj.statusCounts[status].total
        : totalObj.statusCounts[status].totalKg;
  });
  return total;
};

const getStatusSectionResults = (
  status: Status,
  results: OrderOverviewRecord[]
): OrderOverviewRecord[] => {
  let sortedFilteredResults = [];
  switch (status) {
    case Status.OPEN:
      sortedFilteredResults = results.filter((r) => r.openQuantity > 0);
      break;
    case Status.READY_TO_SHIP:
      sortedFilteredResults = results.filter((r) => r.readyToShipQuantity > 0);
      break;
    case Status.UNDER_PRODUCTION:
      sortedFilteredResults = results.filter(
        (r) => r.underProductionQuantity > 0
      );
      break;
    case Status.SHIPPED:
      sortedFilteredResults = results.filter((r) => r.shippedQuantity > 0);
      break;
    default:
      sortedFilteredResults = results;
  }
  return sortBy(sortedFilteredResults, ['millName', 'customerOrderNumber']);
};

const getStatusSectionData = (
  status: Status,
  data: OpenOrdersResults,
  system: MeasurementSystem
): StatusSection => ({
  count: getStatusSectionCount(status, data.results),
  totalWgt: getStatusSectionTotalWgt(status, data.millTotals, system),
  results: getStatusSectionResults(status, data.results),
});
export default getStatusSectionData;
