import React, { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { getEqUiElementsByType } from '../../Calculator.service';
import EqUiElementName from '../../types/EqCalculator/EqUiElementName.enum';
import EqUiElementType from '../../types/EqCalculator/EqUiElementType.enum';
import GetEqUiElementsByTypeParams from '../../types/EqCalculator/GetEqUiElementsByTypeParams.interface';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import ResultsSection from './ResultsSection';
import {
  getEnvImpactSynthesis,
  getImpactsDisplayInfoFromUiElements,
  getUiElementText,
} from './EqCalculatorResults.util';
import EqImpactsDisplayInfo from '../../types/EqCalculator/EqImpactsDisplayInfo.interface';
import ImpactSectionContent from './ImpactSectionContent';
import CalculateEqSavingsResults from '../../types/EqCalculator/CalculateEqSavingsResults.interface';

interface ImpactSectionProps {
  calculationResults: CalculateEqSavingsResults;
  paperTotalWeight: number;
}

const ImpactSection: FC<ImpactSectionProps> = ({
  calculationResults,
  paperTotalWeight,
}) => {
  const [title, setTitle] = useState<string>();
  const [impactsDisplayInfo, setImpactsDisplayInfo] = useState<
    EqImpactsDisplayInfo
  >();
  const weightUnits = calculationResults.units;
  const impactSynthesis = getEnvImpactSynthesis(calculationResults);

  const params: GetEqUiElementsByTypeParams = {
    type: EqUiElementType.IMPACT,
    lang: 'EN',
  };
  const { data: uiElements, isFetching } = useQuery(
    [QueryCacheName.EQ_UI_ELEMENTS_BY_TYPE, params],
    (
      queryKey: QueryCacheName.EQ_UI_ELEMENTS_BY_TYPE,
      queryParams: GetEqUiElementsByTypeParams
    ) => getEqUiElementsByType(queryParams),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (uiElements) {
      setTitle(getUiElementText(uiElements, EqUiElementName.IMPACT_TITLE));
      setImpactsDisplayInfo(getImpactsDisplayInfoFromUiElements(uiElements));
    }
  }, [uiElements]);

  return (
    <div>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        !!title &&
        !!impactsDisplayInfo && (
          <ResultsSection title={title}>
            <ImpactSectionContent
              impactSynthesis={impactSynthesis}
              impactsDisplayInfo={impactsDisplayInfo}
              paperTotalWeight={paperTotalWeight}
              weightUnits={weightUnits}
            />
          </ResultsSection>
        )
      )}
    </div>
  );
};

export default ImpactSection;
