import * as React from 'react';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import DeconstructedTableBodyRow from '../../../../components/DeconstructedTable/DeconstructedTableBodyRow';
import styles from './ConsignmentInventoryTable.module.scss';

interface IConsignmentInventoryTableSpacerRowProps {
  numCols: number;
}

const ConsignmentInventoryTableSpacerRow: React.FunctionComponent<IConsignmentInventoryTableSpacerRowProps> = ({
  numCols,
}) => {
  return (
    <DeconstructedTableBodyRow className={styles.spacerRow}>
      <DeconstructedTableBodyCell colSpan={numCols}>
        <div className="h-3" />
      </DeconstructedTableBodyCell>
    </DeconstructedTableBodyRow>
  );
};

export default ConsignmentInventoryTableSpacerRow;
