import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { isEmpty } from 'lodash';
import React, { FC, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { object, SchemaOf, string, TestContext } from 'yup';
import { Button } from '../../../components/Button/Button';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import useCustomerOptions from '../hooks/useCustomerOptions';
import useGradeOptions from '../hooks/useGradeOptions';
import useParentBrandOptions from '../hooks/useParentBrandOptions';
import useTextureOptions from '../hooks/useTextureOptions';
import useProductAvailabilityStore from '../useProductAvailabilityStore.store';
import { ReleaseWidgetForm } from './ReleaseWidgetForm.interface';

function oneOfRequired(this: TestContext<Record<string, string>>): boolean {
  return (
    this.parent.parentBrand || this.parent.chemistry || this.parent.texture
  );
}

const schema: SchemaOf<ReleaseWidgetForm> = object().shape({
  customerSoldTo: string().required(),
  parentBrand: string().test('oneOfRequired', '', oneOfRequired),
  chemistry: string().test('oneOfRequired', '', oneOfRequired),
  texture: string().test('oneOfRequired', '', oneOfRequired),
});

const ReleaseProductAvailabilityWidget: FC = () => {
  const initialState = useProductAvailabilityStore();
  const history = useHistory();
  const hasPopulatedDefaults = useRef(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState,
  } = useForm<ReleaseWidgetForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      customerSoldTo: '',
      parentBrand: '',
      chemistry: '',
      texture: '',
    },
  });
  const { isValid } = formState;

  const customer = watch('customerSoldTo');
  const parentBrand = watch('parentBrand');
  const chemistry = watch('chemistry');
  const texture = watch('texture');

  const { customerOptions, isFetchingUser } = useCustomerOptions();
  const { parentBrandOptions } = useParentBrandOptions(
    customer,
    chemistry,
    texture
  );
  const { gradeOptions } = useGradeOptions(customer, parentBrand, texture);
  const { textureOptions } = useTextureOptions(
    customer,
    parentBrand,
    chemistry
  );

  // Initial population of customer dropdown
  useEffect(() => {
    if (!isEmpty(customerOptions) && !isFetchingUser) {
      setValue(
        'customerSoldTo',
        initialState.customerSoldTo || customerOptions[0].value
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingUser, customerOptions]);

  // Initial population of other dropdowns
  useEffect(() => {
    if (parentBrandOptions.length > 1 && !hasPopulatedDefaults.current) {
      reset({
        ...getValues(),
        parentBrand: initialState.parentBrand,
        chemistry: initialState.chemistry,
        texture: initialState.texture,
      });
      hasPopulatedDefaults.current = true;
    }
  }, [getValues, reset, initialState, parentBrandOptions]);

  const doReset = () => {
    reset({
      customerSoldTo: customerOptions[0].value as string,
      parentBrand: '',
      chemistry: '',
      texture: '',
    });
  };

  const doSubmit = (data: ReleaseWidgetForm) => {
    useProductAvailabilityStore.setState({
      ...data,
      putUps: [],
      isAvailabilityPlanning: true,
    });
    history.push('/availabilityPlanning');
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-10 mb-10">
        <Space direction="vertical" className="w-full">
          <InputLabel text={t`Customer`} required size="lg">
            <Select
              name="customerSoldTo"
              ref={register()}
              options={customerOptions}
              required
            />
          </InputLabel>
          <div className="grid grid-cols-2 gap-3">
            <InputLabel text={t`Product`} size="lg">
              <Select
                name="parentBrand"
                ref={register()}
                options={parentBrandOptions}
              />
            </InputLabel>
            <InputLabel text={t`Texture`} size="lg">
              <Select
                name="texture"
                ref={register()}
                options={textureOptions}
              />
            </InputLabel>
            <InputLabel text={t`Grade`} size="lg">
              <Select
                name="chemistry"
                ref={register()}
                options={gradeOptions}
              />
            </InputLabel>
          </div>
        </Space>
        <div className="w-full text-right mt-10">
          <Space size="large">
            <Button
              type="submit"
              theme="primary"
              disabled={!isValid || !hasPopulatedDefaults.current}
              onClick={handleSubmit(doSubmit)}
            >
              <Trans>Show Availability</Trans>
            </Button>
            <Button
              type="reset"
              theme="link"
              className="text-lg font-bold"
              onClick={() => doReset()}
            >
              <Trans>Reset</Trans>
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default ReleaseProductAvailabilityWidget;
