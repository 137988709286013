import { t, Trans } from '@lingui/macro';
import { Collapse } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import useAnalytics from '../../../hooks/useAnalytics';
import useUser from '../../../hooks/useUser';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import SectionHeader from '../../../components/SectionHeader';
import Select from '../../../components/Select/Select';
import useRole from '../../../hooks/useRole';
import { getBrands } from '../../../services/Brand';
import { AuthorityRole } from '../../../types/Authority.interface';
import Brand from '../../../types/Brand.interface';
import EuBrand from '../../../types/EuBrand.interface';
import Option from '../../../types/Option.type';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import './AdvancedSearchCriteria.scss';
import {
  doGetBaseWeightOptions,
  doGetBrandOptions,
  doGetCaliperOptions,
  doGetChemistryOptions,
  doGetGrammageOptions,
  doGetParentBrandOptions,
  doGetTextureOptions,
} from './AdvancedSearchCriteria.util';
import {
  IMP_REG_EX,
  MET_REG_EX,
} from '../../../types/RegularExpression.constants';
import useTrackOrdersStore from '../useTrackOrders.store';

interface IAdvancedSearchCriteriaProps {
  expanded: boolean;
  selectedCustomer: string;
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  watch: UseFormMethods['watch'];
  setValue: UseFormMethods['setValue'];
}

const { Panel } = Collapse;
const ADVANCED_SEARCH_EXPAND_KEY = 'ADVANCED_SEARCH_EXPANDED';

const AdvancedSearchCriteria: React.FunctionComponent<IAdvancedSearchCriteriaProps> = ({
  expanded,
  selectedCustomer,
  register,
  errors,
  watch,
  setValue,
}) => {
  const { storedQuery: storedFilters } = useTrackOrdersStore();
  const [activeKey, setActiveKey] = useState('');
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [basisWeightOptions, setBasisWeightOptions] = useState<Option[]>([]);
  const [caliperOptions, setCaliperOptions] = useState<Option[]>([]);
  const [grammageOptions, setGrammageOptions] = useState<Option[]>([]);
  const [parentBrandOptions, setParentBrandOptions] = useState<Option[]>([]);
  const [chemistryOptions, setChemistryOptions] = useState<Option[]>([]);
  const [textureOptions, setTextureOptions] = useState<Option[]>([]);

  const [dependentFieldsDisabled, setDependentFieldsDisabled] = useState(true);
  const { hasRole } = useRole();
  const { resolvedData: brands } = usePaginatedQuery(
    [
      QueryCacheName.BRANDS,
      {
        customer:
          hasRole(AuthorityRole.ROLE_NA) && !hasRole(AuthorityRole.ROLE_RELEASE)
            ? selectedCustomer
            : undefined,
      },
    ],
    getBrands,
    {
      staleTime: Infinity,
      enabled: selectedCustomer,
      keepPreviousData: true,
    }
  );
  const paperBrandWatch = watch('paperBrand');

  useEffect(() => {
    if (expanded) {
      trackPageView('TRACK_ORDERS', 'ADVANCED_SEARCH', {
        username: user?.username,
      });
      setTimeout(() => {
        setValue('caliperMicrons', storedFilters?.caliperMicrons);
        setValue('widthMinInches', storedFilters?.widthMinInches);
        setValue('widthMaxInches', storedFilters?.widthMaxInches);
        setValue('lengthMinInches', storedFilters?.lengthMinInches);
        setValue('lengthMaxInches', storedFilters?.lengthMaxInches);
        setValue('widthMin', storedFilters?.widthMin);
        setValue('widthMax', storedFilters?.widthMax);
        setValue('lengthMin', storedFilters?.lengthMin);
        setValue('lengthMax', storedFilters?.lengthMax);
        setValue('kcode', storedFilters?.kcode);
        setValue(
          'customerMaterialNumber',
          storedFilters?.customerMaterialNumber
        );

        setValue('paperBrand', storedFilters?.paperBrand);
        setValue('caliperMicrons', storedFilters?.caliperMicrons);
        setValue('weightEntry', storedFilters?.weightEntry);

        setValue('bookweightFrom', storedFilters?.bookweightFrom);
        setValue('parentBrand', storedFilters?.parentBrand);
        setValue('chemistry', storedFilters?.chemistry);
        setValue('texture', storedFilters?.texture);
      }, 0);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [expanded]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setActiveKey(expanded ? ADVANCED_SEARCH_EXPAND_KEY : '');
  }, [expanded]);

  const materialNumberOnly =
    hasRole(AuthorityRole.ROLE_EU) || hasRole(AuthorityRole.ROLE_RELEASE);
  const sappiProductIdentifer = materialNumberOnly ? (
    <Trans id="search.kcodeHeader.eu">Material Number</Trans>
  ) : (
    <Trans>Sappi SKU # or Material Number</Trans>
  );

  const doGetBrandOptionsMemo = useMemo(
    () => () => doGetBrandOptions(brands as Brand[]),
    [brands]
  );

  const doGetParentBrandOptionsMemo = useMemo(
    () => () => doGetParentBrandOptions(brands as Brand[]),
    [brands]
  );

  const updateBrandDependentFields = useMemo(
    () => (selectedProduct?: string) => {
      setBasisWeightOptions(
        doGetBaseWeightOptions(brands as Brand[], selectedProduct)
      );
      setCaliperOptions(
        doGetCaliperOptions(brands as Brand[], selectedProduct)
      );
      setGrammageOptions(
        doGetGrammageOptions(brands as EuBrand[], selectedProduct)
      );
    },
    [brands]
  );

  const updateParentBrandDependentFields = useMemo(
    () => (selectedProduct?: string) => {
      setChemistryOptions(
        doGetChemistryOptions(brands as Brand[], selectedProduct)
      );
      setTextureOptions(
        doGetTextureOptions(brands as Brand[], selectedProduct)
      );
    },
    [brands]
  );

  useEffect(() => {
    setProductOptions(doGetBrandOptionsMemo());
    updateBrandDependentFields();
  }, [doGetBrandOptionsMemo, updateBrandDependentFields]);

  useEffect(() => {
    setParentBrandOptions(doGetParentBrandOptionsMemo());
    updateParentBrandDependentFields();
  }, [doGetParentBrandOptionsMemo, updateParentBrandDependentFields]);

  useEffect(() => {
    setDependentFieldsDisabled(!paperBrandWatch);
  }, [paperBrandWatch]);

  return (
    <Collapse activeKey={activeKey} ghost>
      <Panel header="" key={ADVANCED_SEARCH_EXPAND_KEY} showArrow={false}>
        <div className="border-b border-gray-300 mb-6 -mx-4">
          {/* Sappi SKU or Material Number */}
          <div className="w-1/2">
            <InputLabel text={sappiProductIdentifer} size="lg">
              <div className="mb-4">
                <Input
                  error={errors.kcode}
                  name="kcode"
                  placeholder={t`Sappi SKU # or Material Number`}
                  ref={register()}
                />
              </div>
            </InputLabel>
          </div>

          {/* Product Specs */}
          <div className="border-t border-gray-300 my-11 pt-4">
            <SectionHeader className="mb-8">
              <Trans id="search.tradeHeader.eu">Product Details</Trans>
            </SectionHeader>
            <div className="flex">
              <div className="w-1/2">
                {!hasRole(AuthorityRole.ROLE_RELEASE) && (
                  <Select
                    name="paperBrand"
                    onChange={(e) => updateBrandDependentFields(e.target.value)}
                    ref={register()}
                    options={productOptions}
                    className="mb-4"
                  />
                )}
                {hasRole(AuthorityRole.ROLE_NA) &&
                  !hasRole(AuthorityRole.ROLE_RELEASE) && (
                    <>
                      <Select
                        disabled={dependentFieldsDisabled}
                        name="caliperMicrons"
                        ref={register()}
                        options={caliperOptions}
                        className="mb-4"
                      />
                      <Select
                        disabled={dependentFieldsDisabled}
                        name="weightEntry"
                        ref={register()}
                        options={basisWeightOptions}
                      />
                    </>
                  )}
                {hasRole(AuthorityRole.ROLE_EU) &&
                  !hasRole(AuthorityRole.ROLE_RELEASE) && (
                    <>
                      <Select
                        disabled={dependentFieldsDisabled}
                        name="bookweightFrom"
                        ref={register()}
                        options={grammageOptions}
                        className="mb-4"
                      />
                      <Input
                        name="caliperMicrons"
                        ref={register({
                          maxLength: 30,
                          pattern: {
                            value: /^\d*\.?\d*$/,
                            message: t({
                              id: 'orderTracking.kCode.error',
                              message: 'Please enter numbers only.',
                            }),
                          },
                        })}
                        error={errors.caliperMicrons}
                        placeholder={t({
                          id: 'search.caliperNumber',
                          message: 'Caliper #',
                        })}
                      />
                    </>
                  )}

                {hasRole(AuthorityRole.ROLE_RELEASE) && (
                  <>
                    <Select
                      name="parentBrand"
                      onChange={(e) =>
                        updateParentBrandDependentFields(e.target.value)
                      }
                      ref={register()}
                      options={parentBrandOptions}
                      className="mb-4"
                    />
                    <Select
                      name="chemistry"
                      ref={register()}
                      options={chemistryOptions}
                      className="mb-4"
                    />
                    <Select
                      name="texture"
                      ref={register()}
                      options={textureOptions}
                    />
                  </>
                )}
              </div>
              <div className="ml-20">
                <div className="flex justify-end">
                  <InputLabel
                    size="md"
                    text={t({ id: 'search.width', message: 'Width' })}
                    position="side"
                  >
                    <div className="w-40 justify-end">
                      {hasRole(AuthorityRole.ROLE_NA) && (
                        <Input
                          name="widthMinInches"
                          error={errors.widthMinInches}
                          ref={register({
                            maxLength: 9,
                            pattern: IMP_REG_EX,
                          })}
                        />
                      )}
                      {hasRole(AuthorityRole.ROLE_EU) && (
                        <Input
                          name="widthMin"
                          error={errors.widthMin}
                          ref={register({
                            maxLength: 9,
                            pattern: MET_REG_EX,
                          })}
                        />
                      )}
                    </div>
                  </InputLabel>

                  <InputLabel
                    className="-ml-5 w-48"
                    text={t({ id: 'search.to', message: 'to' })}
                    position="side"
                    size="md"
                  >
                    {hasRole(AuthorityRole.ROLE_NA) && (
                      <Input
                        name="widthMaxInches"
                        error={errors.widthMaxInches}
                        ref={register({
                          maxLength: 9,
                          pattern: IMP_REG_EX,
                        })}
                      />
                    )}
                    {hasRole(AuthorityRole.ROLE_EU) && (
                      <Input
                        name="widthMax"
                        error={errors.widthMax}
                        ref={register({
                          maxLength: 9,
                          pattern: MET_REG_EX,
                        })}
                      />
                    )}
                  </InputLabel>
                </div>

                <div className="flex mt-4">
                  <InputLabel
                    text={t({ id: 'search.length', message: 'Length' })}
                    position="side"
                    size="md"
                  >
                    <div className="w-40">
                      {hasRole(AuthorityRole.ROLE_NA) && (
                        <Input
                          name="lengthMinInches"
                          error={errors.lengthMinInches}
                          ref={register({
                            maxLength: 9,
                            pattern: IMP_REG_EX,
                          })}
                        />
                      )}
                      {hasRole(AuthorityRole.ROLE_EU) && (
                        <Input
                          name="lengthMin"
                          error={errors.lengthMin}
                          ref={register({
                            maxLength: 9,
                            pattern: MET_REG_EX,
                          })}
                        />
                      )}
                    </div>
                  </InputLabel>

                  <InputLabel
                    className="-ml-5 w-48"
                    text={t({ id: 'search.to', message: 'to' })}
                    position="side"
                    size="md"
                  >
                    {hasRole(AuthorityRole.ROLE_NA) && (
                      <Input
                        name="lengthMaxInches"
                        error={errors.lengthMaxInches}
                        ref={register({
                          maxLength: 9,
                          pattern: IMP_REG_EX,
                        })}
                      />
                    )}
                    {hasRole(AuthorityRole.ROLE_EU) && (
                      <Input
                        name="lengthMax"
                        error={errors.lengthMax}
                        ref={register({
                          maxLength: 9,
                          pattern: MET_REG_EX,
                        })}
                      />
                    )}
                  </InputLabel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};

export default AdvancedSearchCriteria;
