import EuBrand from '../../../types/EuBrand.interface';
import CalculatePalletHeightAndWeightParams from '../types/PalletHeightAndWeight/CalculatePalletHeightAndWeightParams.interface';
import PalletHeightAndWeightFormData from '../types/PalletHeightAndWeight/PalletHeightAndWeightFormData.type';

const formatParamValue = (value: string | undefined): number | undefined =>
  value !== undefined ? +value : undefined;

export const getParamsFromFormData = (
  formData: PalletHeightAndWeightFormData,
  selectedBrand: EuBrand
): CalculatePalletHeightAndWeightParams => {
  const grammageGrades = selectedBrand.grammageGrades.filter(
    (grammageGrade) => grammageGrade.grammage === +formData.grammage
  );

  const salesGrade = grammageGrades.length
    ? grammageGrades[0].grade
    : selectedBrand.grade;

  const { brandMember } = selectedBrand;

  const params: CalculatePalletHeightAndWeightParams = {
    grammage: +formData.grammage,
    stacksPerPallet: +formData.stacksPerPallet,
    sheetsPerPallet: formatParamValue(formData.sheetsPerPallet),
    heightPerPallet: formatParamValue(formData.heightPerPallet),
    weightPerPallet: formatParamValue(formData.weightPerPallet),
    salesGrade,
    brandMember,
    sheetWidth: formData.sheetWidth.replace(',', '.'),
    sheetLength: formData.sheetLength.replace(',', '.'),
  };

  return params;
};
