import { Trans } from '@lingui/macro';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Tabs, { TabPane } from '../../components/Tabs/Tabs';
import { searchDocumentPrinter } from '../../services/Documents';
import QueryCacheName from '../../types/QueryCacheName.enum';
import useDashboardStore, {
  DEFAULT_SEARCH_DOCUMENT_INPUTS,
} from '../Dashboard/useDashboard.store';
import AvailableDocuments from './AvailableDocuments/AvailableDocuments';
import availableDocumentsPopoverNotificationStore from './AvailableDocuments/availableDocumentsPopoverNotification.store';
import styles from './PrintDocuments.module.scss';
import { prepareFiltersForDocumentPrinter } from './PrintDocuments.util';
import { SearchDocumentInputs } from './SearchDocuments/SearchDocumentInputs';
import SearchDocuments from './SearchDocuments/SearchDocuments';
import { hasValidInput } from './SearchDocuments/SearchDocuments.util';

enum PrintDocumentTab {
  FIND_DOCUMENTS = 'FIND_DOCUMENTS',
  VIEW_AVAILABLE_DOCUMENTS = 'VIEW_AVAILABLE_DOCUMENTS',
}

const DOCUMENT_PRINTER_PAGE_SIZE = 10;

interface IPrintDocumentsProp {
  attr?: string;
}

const PrintDocuments: React.FunctionComponent<IPrintDocumentsProp> = () => {
  const [selectedTab, setSelectedTab] = useState<PrintDocumentTab>(
    PrintDocumentTab.FIND_DOCUMENTS
  );
  const [filters, setFilters] = useState<SearchDocumentInputs>();

  const {
    hidePopover,
    setHidePopover,
    setEditColumnsHelpPopoverVisibility,
    setEditColumnsPopoverVisibility,
  } = availableDocumentsPopoverNotificationStore();

  const { searchDocumentInputs } = useDashboardStore();

  const { data: documentPrinterData, isFetching } = useQuery(
    [
      QueryCacheName.PRINT_DOCUMENTS,
      prepareFiltersForDocumentPrinter(filters || {}),
    ],
    () =>
      searchDocumentPrinter(prepareFiltersForDocumentPrinter(filters || {})),
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: filters,
      keepPreviousData: true,
    }
  );

  // When searchDocumentInputs updated, update filter to init the search
  useEffect(() => {
    if (hasValidInput(searchDocumentInputs)) {
      setSelectedTab(PrintDocumentTab.VIEW_AVAILABLE_DOCUMENTS);

      setFilters({
        ...searchDocumentInputs,
        page: 1,
        pageSize: DOCUMENT_PRINTER_PAGE_SIZE,
        usePaginatedVersion: false,
      });
    }
  }, [searchDocumentInputs]);

  return (
    <div>
      <Tabs
        type="card"
        className={styles['print-documents-tabs']}
        activeKey={selectedTab}
        onChange={(key) => {
          if (key === PrintDocumentTab.FIND_DOCUMENTS) {
            setHidePopover(!hidePopover);
            setEditColumnsHelpPopoverVisibility(false);
            setEditColumnsPopoverVisibility(false);
          }
          setSelectedTab(key as PrintDocumentTab);
        }}
      >
        <TabPane
          tab={<Trans>Search Documents</Trans>}
          key={PrintDocumentTab.FIND_DOCUMENTS}
        >
          <SearchDocuments />
        </TabPane>
        <TabPane
          tab={<Trans>View Available Documents</Trans>}
          key={PrintDocumentTab.VIEW_AVAILABLE_DOCUMENTS}
          disabled={
            !documentPrinterData ||
            isEqual(searchDocumentInputs, DEFAULT_SEARCH_DOCUMENT_INPUTS)
          }
        >
          {isFetching ? (
            <div className="h-80">
              <LoadingSpinner horizontal />
            </div>
          ) : (
            <>
              {documentPrinterData && (
                <AvailableDocuments
                  filters={filters}
                  documentPrinterData={documentPrinterData}
                />
              )}
            </>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PrintDocuments;
