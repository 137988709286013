import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../hooks/useAnalytics';
import useUser from '../../../hooks/useUser';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import fractionOrDecimalInput from '../../../services/Util/fractionOrDecimalInput.util';
import { IMP_REG_EX } from '../../../types/RegularExpression.constants';
import CalculatorResponse from '../types/CalculatorResponse.interface';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { getMWeight } from '../Calculator.service';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import { CalculatorUnitTypes } from '../types/CalculatorUnitTypes.enum';
import useTradeBasisOptions, {
  EMPTY_TRADE_BASIS_OPTION,
} from '../useTradeBasisOptions';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';

const MIN_REQUIRED = 0.1;
const MAX_REQUIRED = 9999.9;
const MWeightCalculators: FC = () => {
  const { register, formState, reset, getValues, errors, watch } = useForm({
    mode: 'all',
  });
  const { isDirty, isValid } = formState;
  const isFormUnsubmittable = !(isDirty && isValid);
  const isTradeBasisNotValid = watch('tradeBasis') === '';
  const { format } = useNumberFormatter();

  const [mWeightResponse, setMWeightResponse] = useState<CalculatorResponse>();
  const { tradeBasisOptions } = useTradeBasisOptions();
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();

  const doSubmit = async () => {
    trackPageView('CALCULATORS', 'MWEIGHT', {
      username: user?.username,
    });
    const tradeBasis = getValues('tradeBasis');
    const width = getValues('width');
    const length = getValues('length');
    const basisWeight = getValues('basisWeight');

    const result: CalculatorResponse = await getMWeight(
      basisWeight,
      CalculatorUnitTypes.IMP,
      length,
      width,
      tradeBasis
    );

    setMWeightResponse(result);
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.M_WEIGHT}
      instructions={t`All fields must be filled in to calculate M weight`}
      onCalculate={() => {
        doSubmit();
      }}
      onReset={() => {
        reset();
        setMWeightResponse(undefined);
      }}
      isCalculateDisabled={isFormUnsubmittable || isTradeBasisNotValid}
      result={
        <div className="flex justify-center">
          {mWeightResponse && (
            <div>{format(mWeightResponse.mWeightImp)}&nbsp;</div>
          )}
          <div>M Weight</div>
        </div>
      }
    >
      <Space direction="vertical" size="middle" className="w-full">
        <Select
          required
          name="tradeBasis"
          options={tradeBasisOptions}
          defaultValue={EMPTY_TRADE_BASIS_OPTION.value}
          style={{ width: '315px' }}
          ref={register({ required: true })}
          width="full"
          error={errors?.tradeBasis}
        />
        <Input
          required
          name="width"
          placeholder={t`Width (in)`}
          ref={register({
            required: true,
            pattern: IMP_REG_EX,
          })}
          onKeyPress={fractionOrDecimalInput}
          error={errors?.width}
        />
        <Input
          required
          name="length"
          placeholder={t`Length (in)`}
          ref={register({
            required: true,
            pattern: IMP_REG_EX,
          })}
          onKeyPress={fractionOrDecimalInput}
          error={errors?.length}
        />
        <Input
          required
          name="basisWeight"
          type="number"
          placeholder={t`Basis Weight`}
          onKeyPress={fractionOrDecimalInput}
          ref={register({
            min: MIN_REQUIRED,
            max: MAX_REQUIRED,
            validate: (value) => validateNumberOfDecimalPlaces(value, 1),
          })}
          error={errors?.basisWeight}
        />
      </Space>
    </CalculatorTemplate>
  );
};

export default MWeightCalculators;
