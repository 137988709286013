import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { t } from '@lingui/macro';
import { Space } from 'antd';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import {
  isCwtToLinFoot,
  isCwtToMsf,
  isCwtToThousandSht,
  isThousandShtToCwt,
} from './PriceCWT.util';
import {
  FRACTION_DECIMAL_INPUT,
  IMP_REG_EX,
} from '../../../types/RegularExpression.constants';
import useTradeBasisOptions, {
  EMPTY_TRADE_BASIS_OPTION,
} from '../useTradeBasisOptions';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';

const MIN_REQUIRED = 0.1;
const MAX_REQUIRED = 9999.9;
interface IMetricPriceCWTProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  conversionType: string;
}

const ImperialPriceCWT: React.FunctionComponent<IMetricPriceCWTProps> = ({
  register,
  errors,
  conversionType,
}) => {
  const { tradeBasisOptions } = useTradeBasisOptions();
  const showBaseWeightInput =
    isCwtToThousandSht(conversionType) ||
    isCwtToLinFoot(conversionType) ||
    isCwtToMsf(conversionType) ||
    isThousandShtToCwt(conversionType);
  const showSheetWidthOrLengthInput =
    isCwtToThousandSht(conversionType) || isThousandShtToCwt(conversionType);

  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Select
        required
        name="tradeBasis"
        options={tradeBasisOptions}
        defaultValue={EMPTY_TRADE_BASIS_OPTION.value}
        style={{ width: '315px' }}
        ref={register({ required: true })}
        width="full"
        error={errors?.tradeBasis}
      />
      {showBaseWeightInput && (
        <Input
          required
          name="baseWeight"
          type="number"
          placeholder={t`Basis Weight`}
          ref={register({
            required: showBaseWeightInput,
            min: MIN_REQUIRED,
            max: MAX_REQUIRED,
            validate: (value) => validateNumberOfDecimalPlaces(value, 1),
          })}
          error={errors?.baseWeight}
        />
      )}
      {showSheetWidthOrLengthInput && (
        <Input
          required
          name="sheetWidth"
          placeholder={t`Width (in)`}
          ref={register({
            required: showSheetWidthOrLengthInput,
            pattern: FRACTION_DECIMAL_INPUT,
          })}
          error={errors?.sheetWidth}
        />
      )}
      {showSheetWidthOrLengthInput && (
        <Input
          required
          name="sheetLength"
          placeholder={t`Length (in)`}
          ref={register({
            required: showSheetWidthOrLengthInput,
            pattern: FRACTION_DECIMAL_INPUT,
          })}
          error={errors?.sheetLength}
        />
      )}
      {isCwtToLinFoot(conversionType) && (
        <Input
          required
          name="reelWidthImp"
          placeholder={t`Roll Width (in)`}
          ref={register({
            required: isCwtToLinFoot(conversionType),
            pattern: IMP_REG_EX,
          })}
          error={errors?.reelWidthImp}
        />
      )}
    </Space>
  );
};

export default ImperialPriceCWT;
