import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import SimpleCard from '../../../components/SimpleCard/SimpleCard';
import useLanguage from '../../../hooks/useLanguage';
import { getRecentNewsContent } from '../../../services/Content';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import NewsItem from './NewsItem';

const NewsWidget: FC = () => {
  const lang = useLanguage();

  const { data: newsContent } = useQuery(
    QueryCacheName.NEWS_AND_NOTIFICATIONS,
    () => getRecentNewsContent(lang),
    {
      enabled: lang,
      staleTime: 60000,
      keepPreviousData: true,
    }
  );
  return (
    <SimpleCard>
      <h2 className="text-4xl mt-1 mb-6">
        <Trans>News & Notifications</Trans>
      </h2>
      <Space
        direction="vertical"
        size="middle"
        className="w-full overflow-hidden"
      >
        {newsContent &&
          newsContent?.map((newsItem) => (
            <NewsItem key={newsItem.id} item={newsItem} />
          ))}
      </Space>
    </SimpleCard>
  );
};

export default NewsWidget;
