const FED_EX_URL =
  'https://www.fedex.com/fedextrack/html/oldindex.html?tracknumbers=';
const DPD_URL = 'https://tracking.dpd.de/cgi-bin/delistrack?pknr=';
const DHL_URL =
  'http://www.dhl.com/content/g0/en/express/tracking.shtml?brand=DHL&AWB=';

enum Shipper {
  FED_EX = 'FEDEX',
  DHL = 'DHL',
  DPD = 'DPD',
}

export const getShippingUrl = (shipper = '', tourName = ''): string => {
  let url = '';
  if (shipper.indexOf(Shipper.DPD) > -1) {
    url = DPD_URL + tourName;
    console.debug('We found DPD.');
  } else if (shipper.indexOf(Shipper.DHL) > -1) {
    url = DHL_URL + tourName;
    console.debug('We found DHL.');
  } else if (shipper.indexOf(Shipper.FED_EX) > -1) {
    url = FED_EX_URL + tourName;
    console.debug('We found FedEx.');
  } else if (shipper !== '') {
    url = '#';
    console.warn('SamplesStatusCtrl: Umatched shipping string encountered.');
  }
  return url;
};
