import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import StockWeights from '../../../components/StockWeights';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import { SavedEnquiryItem } from '../types/SavedEnquiryItem.interface';

interface ICharacteristicsCellProps {
  record: SavedEnquiryItem;
}

const CharacteristicsCell: React.FunctionComponent<ICharacteristicsCellProps> = ({
  record,
}) => {
  const { measurementSystem } = useMeasurementSystem();
  return (
    <>
      <strong>
        <Trans>Wrapping Type</Trans>:
      </strong>
      <br />
      {record.wrappingTypeDescription}
      {record.reelWrappingDescription}
      <div>
        {record.productType === 'S' && (
          <StockWeights
            stacksPerPallet={+record.stacksPerPallet}
            txtWrappingType={record.wrappingTypeDescription}
            sheetsPerReam={+record.sheetsPerReam}
            sheetsPerPallet={+record.sheetsPerPallet}
          />
        )}
        {(record.productType === PutUpCategory.ROLL ||
          record.productType === PutUpCategory.CUTTER) && (
          <div>
            <div>
              {record.width > record.length ? t`Short Grain` : t`Long Grain`}
            </div>
            {measurementSystem === MeasurementSystem.METRIC && (
              <span>
                <Trans>Roll Diameter</Trans>:{record.reelDiameter};&nbsp;
                <Trans>Roll Width</Trans>:{record.reelWidth};
              </span>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CharacteristicsCell;
