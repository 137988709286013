import React from 'react';
import { Controller, UseFormMethods, ValidationRules } from 'react-hook-form';
import DatePicker, { IDatePickerProps } from './DatePicker';

interface IControlledDatePickerProps extends IDatePickerProps {
  name: string;
  control: UseFormMethods['control'];
  rules?: ValidationRules;
  defaultValue?: unknown;
  required?: boolean;
  className?: string;
}

const ControlledDatePicker: React.FunctionComponent<IControlledDatePickerProps> = ({
  disabledDate,
  onOk,
  value,
  defaultValue,
  name,
  control,
  rules,
  hasError,
  toolTipErrorMsg,
  required,
  className,
  showTime,
  allowClear,
}) => {
  return (
    <Controller
      as={
        <DatePicker
          disabledDate={disabledDate}
          onOk={onOk}
          value={value}
          hasError={hasError}
          toolTipErrorMsg={toolTipErrorMsg}
          required={required}
          className={className}
          showTime={showTime}
          allowClear={allowClear}
        />
      }
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
    />
  );
};

export default ControlledDatePicker;
