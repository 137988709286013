import { Trans } from '@lingui/macro';
import React from 'react';
import { AvailableDocumentRow } from './AvailableDocumentRow.interface';

const ProductCell = (text: string, row: AvailableDocumentRow): JSX.Element => {
  let ret = <></>;
  if (row.overviews && row.overviews.length > 1) {
    ret = <Trans>Grouped Order</Trans>;
  } else if (row.overviews) {
    const { details } = row.overviews[0];
    if (details && (details.materialDescription || details.materialInfo)) {
      ret = (
        <>
          {details.materialDescription && (
            <div>
              {details.materialDescription}
              <br />
            </div>
          )}
          {details.materialInfo}
        </>
      );
    }
  }
  return ret;
};

export default ProductCell;
