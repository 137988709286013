import { t } from '@lingui/macro';
import { find } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import Select from '../../../components/Select/Select';
import {
  deriveCustomerOptions,
  getNaShipTos,
} from '../../../services/Customer';
import Country from '../../../types/Country.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import AddressRender from './AddressRender';
import NewAddress from './NewAddress';
import NewAddressInputWrapper from './NewAddressInputWrapper';
import useCustomerDeliveryOptionsStore from './useCustomerDeliveryOptions.store';

const BLANK_OPTION = '';
const NEW_ADDRESS_OPTION = '1';
interface IExternalUserDeliveryFormProps {
  countries: Country[];
}

const ExternalUserDeliveryForm: React.FunctionComponent<IExternalUserDeliveryFormProps> = ({
  countries,
}) => {
  const { register, errors, watch, reset, setValue } = useFormContext();
  const selectedDeliveryOption = watch('deliveryOptions');
  const {
    selectedCustomer,
    setDeliveryOptions,
    defaultDeliveryOptions,
  } = useCustomerDeliveryOptionsStore();
  const { data: shipToCustomers } = useQuery(
    [QueryCacheName.SAMPLES_SHIP_TO_CUSTOMERS, selectedCustomer?.number],
    (key, selectedCustomerNumber: string) =>
      getNaShipTos(selectedCustomerNumber),
    {
      staleTime: Infinity,
      keepPreviousData: true,
      enabled: selectedCustomer?.number,
    }
  );

  const [addressData, setAddressData] = useState<{
    customerNumber?: string;
    address: string;
    city: string;
    country: string;
    postalCode: string;
  } | null>();

  const updateAddressData = useCallback(
    (customerNumber: string) => {
      const shipToCustomer = find(shipToCustomers, { customerNumber });
      if (shipToCustomer) {
        setAddressData({
          customerNumber,
          address: shipToCustomer.address1,
          city: shipToCustomer.city,
          country: shipToCustomer.country || '',
          postalCode: shipToCustomer.postalCode,
        });
      }
    },
    [shipToCustomers]
  );

  useEffect(() => {
    if (shipToCustomers && shipToCustomers.length) {
      const derivedShipToOptions = deriveCustomerOptions(
        shipToCustomers,
        false,
        true,
        false,
        true
      );
      setDeliveryOptions(derivedShipToOptions, true);
      updateAddressData(shipToCustomers[0].customerNumber);
    } else {
      setDeliveryOptions([], true);
      setAddressData(null);
    }
  }, [
    shipToCustomers,
    setDeliveryOptions,
    updateAddressData,
    reset,
    selectedCustomer,
  ]);

  useEffect(() => {
    if (shipToCustomers && shipToCustomers[0]) {
      setValue('deliveryOptions', shipToCustomers[0].customerNumber, {
        shouldValidate: true,
      });
    } else {
      setValue('deliveryOptions', NEW_ADDRESS_OPTION);
    }
  }, [defaultDeliveryOptions, setValue, shipToCustomers]);

  return (
    <>
      <div className="flex content-start">
        <NewAddressInputWrapper required label={t`Delivery`}>
          <Select
            name="deliveryOptions"
            defaultValue={addressData?.customerNumber}
            options={defaultDeliveryOptions}
            ref={register({ required: true })}
            onChange={(e) => {
              if (
                e.target.value !== BLANK_OPTION &&
                e.target.value !== NEW_ADDRESS_OPTION
              ) {
                updateAddressData(e.target.value);
              }
            }}
            required
            error={errors.deliveryOptions}
          />
        </NewAddressInputWrapper>
        {addressData && addressData.customerNumber && (
          <div>
            <div className="pt-9 text-lg">
              <AddressRender addressData={addressData} />
            </div>
          </div>
        )}
      </div>
      {selectedDeliveryOption === '1' && <NewAddress countries={countries} />}
    </>
  );
};

export default ExternalUserDeliveryForm;
