import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import { getSheetTotal } from '../../../OrderBooking/SearchResults/SearchResults.util';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { getUOMReadableStringUnit } from '../../../../Util/UnitOfMeasure/UnitOfMeasure.util.';
import Input from '../../../../components/Input/Input';
import { METRIC_NUMBER_THREE_DECIMAL_REG_EX } from '../../../../types/RegularExpression.constants';
import { getRequestedQuantityError } from '../Basket.util';
import useItemRequiresUpdate from '../useItemRequiresUpdate';

interface IQuantityCellProps {
  item: AvailabilityBasketItem;
  index: number;
  basketId: number;
  formMethods: UseFormMethods;
}

const QuantityCell: FC<IQuantityCellProps> = ({
  item,
  index,
  basketId,
  formMethods,
}) => {
  const { format } = useNumberFormatter();
  const { register, errors } = formMethods;
  const { updateItemRequiresUpdate } = useItemRequiresUpdate();

  return (
    <>
      <Input
        style={{ width: 'calc(100% - 25px)' }}
        name={`basket[${index}].requestedQuantity`}
        type="number"
        className="text-blue-pacific"
        defaultValue={format(`${item.requestedQuantity}`)}
        ref={register({
          required: true,
          pattern: METRIC_NUMBER_THREE_DECIMAL_REG_EX,
          validate: {
            required: (input) => !!input && input !== '0',
          },
        })}
        onChange={() => {
          updateItemRequiresUpdate(item, index, basketId, formMethods);
        }}
        error={getRequestedQuantityError(errors, index)}
      />
      <div>
        {item.requestedUnitOfMeasure === UnitOfMeasure.KG
          ? item.requestedUnitOfMeasure
          : getUOMReadableStringUnit(
              (item.requestedUnitOfMeasure || '') as UnitOfMeasure
            )}
      </div>
      {item.requestedUnitOfMeasure !== UnitOfMeasure.KG && (
        <div>
          {format(item.confirmedQuantityKg)} <Trans>Kilograms</Trans>
        </div>
      )}
      {(item.basketType === 'STOCK' ||
        item.requestedUnitOfMeasure === UnitOfMeasure.TO ||
        item.requestedUnitOfMeasure === UnitOfMeasure.KG ||
        item.requestedUnitOfMeasure === UnitOfMeasure.PL ||
        item.requestedUnitOfMeasure === UnitOfMeasure.PK) &&
        item.productType === PutUpCategory.SHEET && (
          <>
            {format(
              getSheetTotal({
                quantity: item.requestedQuantity || 0,
                quantityInKg: item.confirmedQuantityKg || 0,
                unit: item.confirmedUnitOfMeasure as UnitOfMeasure,
                length: item.length || 0,
                width: item.width || 0,
                grammage: item.grammage || '0',
                sheetsPerPallet: item.sheetsPerPallet || '0',
                sheetsPerReam: item.sheetsPerReam || '0',
              })
            )}{' '}
            <Trans>Sheets</Trans>
          </>
        )}
    </>
  );
};

export default QuantityCell;
