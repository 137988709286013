import { t } from '@lingui/macro';
import { getFinishOptions } from '../../../services/Brand';
import Brand from '../../../types/Brand.interface';
import Option from '../../../types/Option.type';
import useCustomerBrands from './useCustomerBrands';

const useFinishOptions = (
  customer: string,
  brand?: string,
  availFlag?: string
): {
  finishOptions: Option[];
  isFetching: boolean;
} => {
  const { brands, isFetching } = useCustomerBrands(customer, availFlag);

  return {
    finishOptions: getFinishOptions(brands as Brand[], 
      brand,
      t`Select`),
    isFetching,
  };
};

export default useFinishOptions;
