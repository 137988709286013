import { t } from '@lingui/macro';
import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import Steps, { Step } from '../../../components/Steps/Steps';
import NaBasketStep from './types/NaBasketStep.enum';

type NaBasketStepperProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  currentStep: NaBasketStep;
};

const NaBasketStepper: FC<NaBasketStepperProps> = ({
  currentStep,
  style,
  ...rest
}) => {
  return (
    <div style={{ width: '375px', ...style }} {...rest}>
      <Steps current={currentStep} labelPlacement="vertical">
        <Step subTitle={t`Basket`} />
        <Step subTitle={t`Delivery Details`} />
        <Step subTitle={t`Submit Order`} />
      </Steps>
    </div>
  );
};

export default NaBasketStepper;
