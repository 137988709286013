import { Tooltip } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import defaultImageUrl from '../../assets/defaultProduct.png';
import { getImageUrl } from '../../services/Images';
import { SamplesProduct } from '../../types/Samples/SamplesProduct.interface';
import styles from './SamplesProductImage.module.scss';

interface ISamplesProductImageProps {
  product: SamplesProduct;
}

const SamplesProductImage: React.FunctionComponent<ISamplesProductImageProps> = ({
  product,
}) => {
  const [imgUrl, setImgUrl] = useState<string>();
  const defaultAltText = 'Sappi. Image not found.';
  const { imageId, brandFamilyDescription } = product;
  const altText =
    imgUrl === defaultImageUrl ? defaultAltText : brandFamilyDescription;

  useEffect(() => {
    const getImageUrlAsync = async () => {
      let retrievedUrl;

      if (imageId) {
        try {
          retrievedUrl = await getImageUrl(imageId);
        } catch (e) {
          retrievedUrl = defaultImageUrl;
        }
      } else {
        retrievedUrl = defaultImageUrl;
      }

      setImgUrl(retrievedUrl);
    };

    getImageUrlAsync();
  }, [imageId, brandFamilyDescription]);

  return (
    <>
      {imgUrl && (
        <Tooltip
          title={
            <img src={imgUrl} alt={altText} className="w-auto max-w-none" />
          }
          placement="right"
          color="white"
          getPopupContainer={(triggerNode) => triggerNode}
        >
          <div className={styles.productImageWrapper}>
            <img src={imgUrl} alt={altText} className="w-full" />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default SamplesProductImage;
