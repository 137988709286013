import { t } from '@lingui/macro';
import UnitOfMeasure from '../../types/UnitOfMeasure.enum';

export const getUOMReadableStringUnit = (
  unit: UnitOfMeasure
): string | undefined => {
  switch (unit) {
    case UnitOfMeasure.PL:
      return t`Pallets`;
    case UnitOfMeasure.RL:
      return t`Rolls`;
    case UnitOfMeasure.KG:
      return t`Kilograms`;
    case UnitOfMeasure.TO:
      return t`Tons`;
    case UnitOfMeasure.SH:
      return t`Sheets`;
    case UnitOfMeasure.PK:
      return t`Reams`;
    default:
      return undefined;
  }
};
