import React from 'react';
import { StockAvailabilityOverview } from '../../../types/StockAvailabilityOverview.interface';
import AddStockItemToBasket from './AddStockItemToBasket';
import { ResultRow } from './ResultRow.interface';
import StockOrMillResultTable from './StockOrMillResultTable';

interface IAltStockResultProps {
  data: ResultRow;
  compareStockData?: StockAvailabilityOverview;
}

const AltStockResult: React.FunctionComponent<IAltStockResultProps> = ({
  data,
  compareStockData,
}) => {
  return (
    <div className="-mx-4 mb-8 text-gray-dark200">
      <StockOrMillResultTable
        data={data.stockAvailabilities}
        resultTitle={data.stockAvailabilities[0].productName}
        compareStockData={compareStockData}
        isAltStockResult
      />
      <div className="flex justify-end pt-5 pl-4 pr-4 border-t border-gray-light400">
        <div>
          <AddStockItemToBasket
            data={data.stockAvailabilities[0]}
            isContainerExpanded
          />
        </div>
      </div>
    </div>
  );
};

export default AltStockResult;
