import { Popover } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import OldBasketPopUp from '../../../components/BasketPopUp/OldBasketPopUp';
import OldBasketButton from '../../../components/OldBasketButton/OldBasketButton';
import useNaBasketNumItems from '../../../hooks/useNaBasketNumItems';

const BasicBasketPopup: FunctionComponent = () => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { numBasketItems } = useNaBasketNumItems();
  const hasItems = numBasketItems > 0;
  return (
    <Popover
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <OldBasketPopUp
          onContinueShopping={() => setVisible(false)}
          onViewBasket={() => history.push('/submitOrder')}
        />
      }
    >
      <OldBasketButton
        disabled={!hasItems}
        onCaretClick={() => setVisible((state) => !state)}
        onButtonClick={() => history.push('/submitOrder')}
      />
    </Popover>
  );
};

export default BasicBasketPopup;
