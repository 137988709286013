import React, { FC } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import SimpleCard from '../../components/SimpleCard/SimpleCard';
import AccountForm from './AccountForm';
import UserInfo from './UserInfo/UserInfo';
import useFormProperties from './useFormProperties';

const Account: FC = () => {
  const { formProps, isFetchingSettings } = useFormProperties();

  return (
    <SimpleCard innerWrapperClassName="py-11 px-6">
      <UserInfo />
      {isFetchingSettings ? (
        <LoadingSpinner horizontal />
      ) : (
        formProps && <AccountForm {...formProps} />
      )}
    </SimpleCard>
  );
};

export default Account;
