import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import StockWeights from '../../../../components/StockWeights';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import MeasurementSystem from '../../../../types/MeasurementSystem.enum';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import useItemRequiresUpdate from '../useItemRequiresUpdate';

interface ICharacteristicsCellProps {
  item: AvailabilityBasketItem;
  index: number;
  basketId: number;
  formMethods: UseFormMethods;
}

const CharacteristicsCell: React.FunctionComponent<ICharacteristicsCellProps> = ({
  item,
  index,
  basketId,
  formMethods,
}) => {
  const { measurementSystem } = useMeasurementSystem();
  const { register } = formMethods;
  const { updateItemRequiresUpdate } = useItemRequiresUpdate();

  return (
    <div>
      {item.productType === PutUpCategory.SHEET && (
        <StockWeights
          stacksPerPallet={+(item.stacksPerPallet || '')}
          sheetsPerReam={+(item.sheetsPerReam || '')}
          sheetsPerPallet={+(item.sheetsPerPallet || '')}
        />
      )}
      {(item.productType === PutUpCategory.ROLL ||
        item.productType === PutUpCategory.CUTTER) && (
        <div>
          {measurementSystem === MeasurementSystem.METRIC && (
            <>
              <div>
                <Trans>Roll Diameter:</Trans> {item.reelDiameter}
              </div>
              <div>
                <Trans>Roll Width:</Trans> {item.reelWidth}
              </div>
              <div>
                <Trans>Core Diameter:</Trans> {item.coreDiameter}
              </div>
            </>
          )}
        </div>
      )}
      <InputLabel text={t`Pallet Remark:`} size="md" className="font-bold">
        <Input
          style={{ width: 'calc(100% - 7px)' }}
          className="font-normal text-base text-blue-pacific"
          name={`basket[${index}].newLabel`}
          defaultValue={item.newLabel || ''}
          ref={register()}
          onChange={() => {
            updateItemRequiresUpdate(item, index, basketId, formMethods);
          }}
        />
      </InputLabel>
    </div>
  );
};

export default CharacteristicsCell;
