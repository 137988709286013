import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import loadExternalScript from '../../services/Util/loadExternalScript.util';
import useLiveAgentChatConfig from '../Chat/hooks/useLiveAgentChatConfig';
import {
  HOME_CHAT_BTN_ID_OFFLINE,
  HOME_CHAT_BTN_ID_ONLINE,
  updateButton,
} from './ChatTrigger.util';
import ChatTriggerButton from './ChatTriggerButton';
import styles from './ChatTrigger.module.scss';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../hooks/useAnalytics';

const ChatTrigger: FC = () => {
  const [liveAgentScriptIsLoaded, setLiveAgentScriptIsLoaded] = useState<
    boolean
  >(false);
  const [chatIsInitialized, setChatIsInitialized] = useState<boolean>(false);
  const { data: liveAgentChatConfig } = useLiveAgentChatConfig();
  const { data: userData } = useUser();
  const { pathname } = useLocation();
  const { trackPageView } = useAnalytics();

  const openChatUnavailableWindow = () => {
    trackPageView('CHAT', 'CHAT_OFFLINE');

    window.open(
      'pages?page=chat/offline',
      'newwindow',
      'width=600, height=250'
    );
  };

  const startChat = () => {
    trackPageView('CHAT', 'CHAT_START');

    const { liveagent } = window;

    if (liveagent && liveAgentChatConfig) {
      liveagent.startChat(liveAgentChatConfig.buttonId, false);
    }
  };

  const handleLiveAgentScriptLoad = () => {
    setLiveAgentScriptIsLoaded(true);
  };

  // Once the liveagent config is loaded, load the liveagent script.
  useEffect(() => {
    const { liveagent } = window;

    if (!liveagent) {
      if (liveAgentChatConfig) {
        loadExternalScript(
          liveAgentChatConfig.deployerUrl,
          handleLiveAgentScriptLoad
        );
      }
    } else {
      setLiveAgentScriptIsLoaded(true);
    }
  }, [liveAgentChatConfig]);

  // Once the liveagent config and liveagent script are both done loading,
  // initialize the liveagent chat.
  useEffect(() => {
    if (liveAgentChatConfig && liveAgentScriptIsLoaded) {
      const { liveagent } = window;

      if (liveagent && !chatIsInitialized) {
        try {
          const eventHandler = (liveAgentButtonEvent: string): void => {
            updateButton(liveAgentButtonEvent, pathname);
          };

          liveagent.enableLogging();

          if (userData) {
            liveagent.addCustomDetail('SAPID', userData.username, true);
            liveagent.addCustomDetail('First Name', userData.firstName, true);
            liveagent.addCustomDetail('Last Name', userData.lastName, true);
            liveagent.addCustomDetail('Email', userData.email, true);
            liveagent.addCustomDetail(
              'Account',
              userData.customers[0].name,
              true
            );
            liveagent
              .findOrCreate('Contact')
              .map('e_commerce_User_ID__c', 'SAPID', true, true, true)
              .saveToTranscript('ContactId')
              .showOnCreate();
            liveagent
              .findOrCreate('Account')
              .map('SAP_Account_Number__c', 'Account', true, true, true)
              .saveToTranscript('AccountId')
              .showOnCreate();
            liveagent.setName(`${userData.firstName} ${userData.lastName}`);

            /* eslint-disable no-underscore-dangle */
            if (!window._laq) {
              window._laq = [];
            }
            window._laq.push(() => {
              liveagent.showWhenOnline(
                liveAgentChatConfig.buttonId,
                document.getElementById(HOME_CHAT_BTN_ID_ONLINE)
              );
              liveagent.showWhenOffline(
                liveAgentChatConfig.buttonId,
                document.getElementById(HOME_CHAT_BTN_ID_OFFLINE)
              );
            });
            /* eslint-enable no-underscore-dangle */

            // All buttons and customizations must be executed before initializing
            // the liveagent session.
            liveagent.addButtonEventHandler(
              liveAgentChatConfig.buttonId,
              eventHandler
            );
            liveagent.init(
              liveAgentChatConfig.initUrl,
              liveAgentChatConfig.deploymentId,
              liveAgentChatConfig.orgId
            );
            setChatIsInitialized(true);
          }
        } catch (error) {
          // Ignore errors.

          // Note that the liveagent library throws errors if one attempts to
          // perform any initialization/configuration (e.x. addCustomDetail etc)
          // after the init() function has been run, but it does not seem to
          // provide any means of checking whether init() has already been run.
          // Since liveagent is a global and will persist on the window object
          // until a refresh, but this component may be rendered or rerendered
          // by React when that global may or may not exist and may or may not
          // already be initialized, we need to make the initialization in this
          // component work regardless of whether liveagent exists or is
          // initialized prior. We do that by checking for the existence of the
          // liveagent global before we load the deployment script, and wrapping
          // the initialization functionality in this useEffect hook in a
          // try/catch block and suppressing any errors.
        }
      }
    }
  }, [
    liveAgentChatConfig,
    liveAgentScriptIsLoaded,
    chatIsInitialized,
    userData,
    pathname,
  ]);

  return (
    <section className={styles.chatTrigger}>
      {!chatIsInitialized && <LoadingSpinner />}
      <div
        className={`h-full ${chatIsInitialized ? '' : 'sr-only'}`}
        aria-hidden={!chatIsInitialized}
      >
        <ChatTriggerButton
          id={HOME_CHAT_BTN_ID_OFFLINE}
          onClick={openChatUnavailableWindow}
        />
        <ChatTriggerButton id={HOME_CHAT_BTN_ID_ONLINE} onClick={startChat} />
      </div>
    </section>
  );
};

export default ChatTrigger;
