import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Tabs, { TabPane } from '../../components/Tabs/Tabs';
import useAnalytics from '../../hooks/useAnalytics';
import useRole from '../../hooks/useRole';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import { cancellableSearchOrders } from '../../services/Order';
import { AuthorityRole } from '../../types/Authority.interface';
import { LinkResultResponse } from '../../types/LinkResultResponse.interface';
import { Order } from '../../types/Order.interface';
import QueryCacheName from '../../types/QueryCacheName.enum';
import DeliveryDetails from './DeliveryDetails/DeliveryDetails';
import InvoiceDetails from './InvoiceDetails/InvoiceDetails';
import OrderDetails from './OrderDetails/OrderDetails';
import SearchCriteria from './SearchCriteria/SearchCriteria';
import { SearchOrderDetailsInputs } from './SearchOrderDetailsInputs.interface';
import { SearchOrderInputs } from './SearchOrderInputs.interface';
import TrackOrderTab from './TrackOrderTab.enum';
import style from './TrackOrders.module.scss';
import prepareFiltersForQuery, { getMonthsFromToday } from './TrackOrders.util';
import activeMenuPopoverNotificationStore from './ViewOrders/Table/actionMenuPopoverNotification.store';
import ViewOrders from './ViewOrders/ViewOrders';
import useTrackOrdersStore from './useTrackOrders.store';

export const TRACK_ORDERS_PAGE_SIZE = 10;

const TrackOrders: React.FunctionComponent = () => {
  const history = useHistory();
  const { docNumber } = useParams<{ docNumber: string }>();
  const { docPosition } = useParams<{ docPosition: string }>();
  const location = useLocation();
  const urlParams = useUrlSearchParams();
  const { trackPageView } = useAnalytics();

  const { hidePopover, setHidePopover } = activeMenuPopoverNotificationStore();
  const { setStoredQuery } = useTrackOrdersStore();

  const { hasRole } = useRole();
  const [filters, setFilters] = useState<Partial<SearchOrderInputs>>({});
  const [hideOrderDetailsTab, setHideOrderDetailsTab] = useState<boolean>(true);
  const [hideDeliveryDetailsTab, setHideDeliveryDetailsTab] = useState<boolean>(
    true
  );
  const [hideInvoiceDetailsTab, setHideInvoiceDetailsTab] = useState<boolean>(
    true
  );
  const [searchOrderDetailsInputs, setSearchOrderDetailsInputs] = useState<
    SearchOrderDetailsInputs
  >();
  const [ordersToShow, setOrdersToShow] = useState<LinkResultResponse<
    Order
  > | null>(null);

  const defaultSelectedTab = () => {
    if (docNumber && docPosition) {
      const destination = location.pathname;
      let activeTab = TrackOrderTab.ENTER_SEARCH_CRITERIA;
      setHideOrderDetailsTab(true);
      setHideDeliveryDetailsTab(true);
      setHideInvoiceDetailsTab(true);
      setSearchOrderDetailsInputs({
        documentNumber: docNumber,
        documentPosition: docPosition,
        isNAUser: hasRole(AuthorityRole.ROLE_NA),
      });

      switch (destination) {
        case destination.indexOf('orderTracking/details') > 0 && destination:
          setHideOrderDetailsTab(false);
          activeTab = TrackOrderTab.VIEW_ORDER_DETAILS;
          break;
        case destination.indexOf('orderTracking/deliveryDetails') > 0 &&
          destination:
          setHideDeliveryDetailsTab(false);
          activeTab = TrackOrderTab.VIEW_DELIVERY_DETAILS;
          break;
        case destination.indexOf('orderTracking/invoice') > 0 && destination:
          setHideInvoiceDetailsTab(false);
          activeTab = TrackOrderTab.VIEW_INVOICE;
          break;
        default:
          activeTab = TrackOrderTab.ENTER_SEARCH_CRITERIA;
      }
      return activeTab;
    }

    if (urlParams.get('viewOrders')) {
      return TrackOrderTab.VIEW_ORDERS; // Url param format: /portal/p/?viewOrders=23232#/orderTracking
    }

    return TrackOrderTab.ENTER_SEARCH_CRITERIA;
  };

  const [selectedTab, setSelectedTab] = useState<TrackOrderTab>(
    defaultSelectedTab
  );

  const { resolvedData: orders, isFetching } = usePaginatedQuery(
    [QueryCacheName.ORDERS, filters || {}],
    cancellableSearchOrders,
    {
      refetchOnWindowFocus: false,
      staleTime: 0,
      enabled: !isEmpty(filters),
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setOrdersToShow(orders || null);
  }, [orders]);

  const doApplyFilters = (incomingFilters: Partial<SearchOrderInputs>) => {
    trackPageView('TRACK_ORDERS', 'APPLY_FILTER');
    setSelectedTab(TrackOrderTab.VIEW_ORDERS);
    const preparedFilters = prepareFiltersForQuery({
      ...incomingFilters,
      page: 1,
      pageSize: TRACK_ORDERS_PAGE_SIZE,
      usePaginatedVersion: false,
      monthsFromToday: getMonthsFromToday(incomingFilters),
    });
    setFilters(preparedFilters);
    setStoredQuery(preparedFilters);
  };

  const doApplySecondaryFilters = (
    incomingFilters: Partial<SearchOrderInputs>
  ) => {
    const preparedFilters = prepareFiltersForQuery({
      ...incomingFilters,
      usePaginatedVersion: true,
    });
    trackPageView('TRACK_ORDERS', 'APPLY_FILTER');
    setFilters(preparedFilters);
  };

  const openNewTab = (tab: TrackOrderTab, data: SearchOrderDetailsInputs) => {
    if (data) {
      setHideOrderDetailsTab(tab !== TrackOrderTab.VIEW_ORDER_DETAILS);
      setHideDeliveryDetailsTab(tab !== TrackOrderTab.VIEW_DELIVERY_DETAILS);
      setHideInvoiceDetailsTab(tab !== TrackOrderTab.VIEW_INVOICE);
      data.isNAUser = !!hasRole(AuthorityRole.ROLE_NA);
      setSearchOrderDetailsInputs(data);
      setSelectedTab(tab);
    }
  };

  const doReset = () => {
    trackPageView('TRACK_ORDERS', 'RESET_FORM');
    setFilters({});
    setOrdersToShow(null);
  };

  return (
    <div className={style.container}>
      <Tabs
        type="card"
        activeKey={selectedTab}
        onChange={(key) => {
          setHidePopover(!hidePopover);

          setSelectedTab(key as TrackOrderTab);

          if (key !== TrackOrderTab.VIEW_ORDER_DETAILS) {
            setHideOrderDetailsTab(true);
          }

          if (key !== TrackOrderTab.VIEW_DELIVERY_DETAILS) {
            setHideDeliveryDetailsTab(true);
          }

          if (key !== TrackOrderTab.VIEW_INVOICE) {
            setHideInvoiceDetailsTab(true);
          }

          if (key === TrackOrderTab.ENTER_SEARCH_CRITERIA) {
            history.push('/orderTracking');
          }
        }}
        className="p-4"
      >
        <TabPane
          tab={<Trans id="enter_criteria">Enter Search Criteria</Trans>}
          key={TrackOrderTab.ENTER_SEARCH_CRITERIA}
        >
          <SearchCriteria
            onApplyFilters={doApplyFilters}
            onReset={doReset}
            filters={filters}
          />
        </TabPane>
        <TabPane
          tab={<Trans id="view_orders">View Orders</Trans>}
          key={TrackOrderTab.VIEW_ORDERS}
          disabled={!ordersToShow}
        >
          {isFetching ? (
            <div className="h-80">
              <LoadingSpinner horizontal />
            </div>
          ) : (
            <ViewOrders
              orders={ordersToShow as LinkResultResponse<Order>}
              filters={filters}
              onApplySearchFilters={doApplySecondaryFilters}
              onNewTabTrigger={openNewTab}
            />
          )}
        </TabPane>
        {!hideOrderDetailsTab &&
          searchOrderDetailsInputs?.documentNumber &&
          searchOrderDetailsInputs?.documentPosition && (
            <TabPane
              tab={<Trans id="orderDetails.header">Order Details</Trans>}
              key={TrackOrderTab.VIEW_ORDER_DETAILS}
            >
              <OrderDetails
                onNewTabTrigger={openNewTab}
                searchOrderDetailsInputs={searchOrderDetailsInputs}
              />
            </TabPane>
          )}
        {!hideDeliveryDetailsTab &&
          searchOrderDetailsInputs?.documentNumber &&
          searchOrderDetailsInputs?.documentPosition && (
            <TabPane
              tab={<Trans id="delivery_details">Delivery Details</Trans>}
              key={TrackOrderTab.VIEW_DELIVERY_DETAILS}
            >
              <DeliveryDetails
                docNumber={searchOrderDetailsInputs.documentNumber}
                docPosition={searchOrderDetailsInputs.documentPosition}
              />
            </TabPane>
          )}
        {!hideInvoiceDetailsTab &&
          searchOrderDetailsInputs?.documentNumber &&
          searchOrderDetailsInputs?.documentPosition && (
            <TabPane
              tab={<Trans id="invoice_details">Invoice Details</Trans>}
              key={TrackOrderTab.VIEW_INVOICE}
            >
              <InvoiceDetails
                docNumber={searchOrderDetailsInputs.documentNumber}
                docPosition={searchOrderDetailsInputs.documentPosition}
              />
            </TabPane>
          )}
      </Tabs>
    </div>
  );
};

export default TrackOrders;
