import MeasurementSystem from '../types/MeasurementSystem.enum';
import MeasurementUnit from '../types/MeasurementUnit.enum';
import useMeasurementSystem from './useMeasurementSystem';

const useMeasurementUnit = (): {
  measurementUnit1: MeasurementUnit;
  measurementUnit2: MeasurementUnit;
  measurementUnit3: MeasurementUnit;
} => {
  const { measurementSystem } = useMeasurementSystem();
  return {
    measurementUnit1:
      measurementSystem === MeasurementSystem.IMPERIAL
        ? MeasurementUnit.IN
        : MeasurementUnit.CM,
    measurementUnit2:
      measurementSystem === MeasurementSystem.IMPERIAL
        ? MeasurementUnit.LB
        : MeasurementUnit.KG,
    measurementUnit3:
      measurementSystem === MeasurementSystem.IMPERIAL
        ? MeasurementUnit.FT
        : MeasurementUnit.M,
  };
};

export default useMeasurementUnit;
