import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { some } from 'lodash';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import SimpleCard from '../../components/SimpleCard/SimpleCard';
import styles from './SearchForm.module.scss';

interface ISearchFormProps {
  register: UseFormMethods['register'];
  formState: UseFormMethods['formState'];
  getValues: UseFormMethods['getValues'];
  trigger: UseFormMethods['trigger'];
  onSubmit: () => void;
  onReset: () => void;
  isWidget: boolean;
}

const SearchForm: React.FunctionComponent<ISearchFormProps> = ({
  register,
  formState,
  getValues,
  trigger,
  onReset,
  onSubmit,
  isWidget,
}) => {
  const oneInputFilled = () => some(getValues(), (value) => !!value);
  const { isValid } = formState;
  const FORM = (
    <form className="p-2" onSubmit={onSubmit}>
      <Space direction="vertical" size="large" className="w-full">
        <InputLabel
          text={t`Pallet Number`}
          position="side"
          size="lg"
          labelClassName={isWidget ? styles.widgetLabel : styles.label}
          className={isWidget ? styles.widgetInput : ''}
        >
          <Input
            ref={register({ validate: oneInputFilled })}
            name="pallet"
            width={isWidget ? 'full' : '50'}
            onChange={() => trigger()}
          />
        </InputLabel>
        <InputLabel
          text={
            <>
              <span className="font-normal text-base">
                <Trans>or</Trans>&nbsp;
              </span>
              <Trans>Roll Number</Trans>
            </>
          }
          position="side"
          size="lg"
          labelClassName={isWidget ? styles.widgetLabel : styles.label}
          className={isWidget ? styles.widgetInput : ''}
        >
          <Input
            ref={register({ validate: oneInputFilled })}
            name="reel"
            width={isWidget ? 'full' : '50'}
            onChange={() => trigger()}
          />
        </InputLabel>
        <InputLabel
          text={
            <div>
              <span className="font-normal text-base w-7">
                <Trans>or</Trans>&nbsp;
              </span>
              <Trans>Mill Order Number</Trans>
            </div>
          }
          position="side"
          size="lg"
          labelClassName={isWidget ? styles.widgetLabel : styles.label}
          className={isWidget ? styles.widgetInput : ''}
        >
          <Input
            ref={register({ validate: oneInputFilled })}
            name="mics"
            width={isWidget ? 'full' : '50'}
            onChange={() => trigger()}
          />
        </InputLabel>
        <Space size="middle">
          <Button theme="primary" type="submit" disabled={!isValid}>
            <Trans>Submit</Trans>
          </Button>
          <Button
            theme="link"
            className="text-lg font-bold"
            type="reset"
            onClick={onReset}
          >
            <Trans>Reset</Trans>
          </Button>
        </Space>
      </Space>
    </form>
  );
  return (
    <>
      {isWidget ? (
        <div style={{ maxWidth: '432px' }}>{FORM}</div>
      ) : (
        <SimpleCard style={{ width: '425px' }}>{FORM}</SimpleCard>
      )}
    </>
  );
};

export default SearchForm;
