import { t } from '@lingui/macro';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Select from '../../../components/Select/Select';
import useRole from '../../../hooks/useRole';
import useUser from '../../../hooks/useUser';
import { deriveCustomerOptions } from '../../../services/Customer';
import AddressRender from './AddressRender';
import NewAddressInputWrapper from './NewAddressInputWrapper';
import getCustomer, { getCustomersWithSamples } from './SelectBuyer.util';
import useCustomerDeliveryOptionsStore from './useCustomerDeliveryOptions.store';

interface IExternalUserCustomerFormProps {
  prop?: unknown;
}

const ExternalUserCustomerForm: React.FunctionComponent<IExternalUserCustomerFormProps> = (
  props
) => {
  const { isInternalUser } = useRole();
  const { data: user } = useUser();
  const {
    selectedCustomer,
    setSelectedCustomer,
    setCustomerOptions,
    defaultCustomerOptions,
  } = useCustomerDeliveryOptionsStore();
  const { register, setValue } = useFormContext();

  useEffect(() => {
    if (!isInternalUser) {
      const customersWithSamples = getCustomersWithSamples(user?.customers);
      const options = deriveCustomerOptions(
        customersWithSamples,
        false,
        true,
        false
      );
      setCustomerOptions(options);
      setSelectedCustomer(customersWithSamples[0]);
    }
  }, [isInternalUser, user, setCustomerOptions, setSelectedCustomer]);

  // Required to set default value after value has been async set in the store
  useEffect(() => {
    if (user) {
      const customersWithSamples = getCustomersWithSamples(user?.customers);
      setValue('customerSelected', customersWithSamples[0].number, {
        shouldValidate: true,
      });
    }
  }, [defaultCustomerOptions, setValue, user]);

  return (
    <div className="flex content-start">
      <NewAddressInputWrapper required label={t`Customer`}>
        <Select
          options={defaultCustomerOptions}
          required
          ref={register({ required: true })}
          name="customerSelected"
          onChange={(e) => {
            const customer = getCustomer(user?.customers || [], e.target.value);
            setSelectedCustomer(customer);
          }}
        />
      </NewAddressInputWrapper>
      {selectedCustomer && selectedCustomer.number && (
        <div className="pt-9 text-lg">
          <AddressRender addressData={selectedCustomer} />
        </div>
      )}
    </div>
  );
};

export default ExternalUserCustomerForm;
