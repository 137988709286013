import { some } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getUserSelectedMeasurementSystem } from '../services/User';
import { AuthorityRole } from '../types/Authority.interface';
import MeasurementSystem from '../types/MeasurementSystem.enum';
import QueryCacheName from '../types/QueryCacheName.enum';
import useUser from './useUser';

const useMeasurementSystem = (): {
  measurementSystem: MeasurementSystem;
  isMetric: boolean;
  isImperial: boolean;
} => {
  const { data: user } = useUser();
  const { data: system } = useQuery(
    [QueryCacheName.MEASUREMENT_SYSTEM],
    async () => getUserSelectedMeasurementSystem(),
    {
      staleTime: 100000, // 5 min
      enabled: user,
    }
  );

  const hasRole = (role: AuthorityRole) =>
    some(user?.authorities, { authority: role });
  const isEuUser = hasRole(AuthorityRole.ROLE_EU);

  const measurementSystem = useMemo(() => {
    if (system === MeasurementSystem.METRIC) {
      return MeasurementSystem.METRIC;
    }
    if (system === MeasurementSystem.IMPERIAL) {
      return MeasurementSystem.IMPERIAL;
    }

    return isEuUser ? MeasurementSystem.IMPERIAL : MeasurementSystem.METRIC;
  }, [isEuUser, system]);

  return {
    measurementSystem,
    isMetric: measurementSystem === MeasurementSystem.METRIC,
    isImperial: measurementSystem === MeasurementSystem.IMPERIAL,
  };
};

export default useMeasurementSystem;
