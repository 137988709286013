import React, { FC } from 'react';

interface ICustomerAddressProps {
  customerNumber?: string;
  customerName?: string;
  street: string;
  city: string;
  postalCode: string;
  country: string;
  className?: string;
}

const CustomerAddress: FC<ICustomerAddressProps> = ({
  customerNumber,
  customerName,
  street,
  city,
  postalCode,
  country,
  className = '',
}) => {
  return (
    <div className={className}>
      <div>{customerNumber}</div>
      {customerName && <div>{customerName}</div>}
      <div>{street}</div>
      <div>
        {city} {postalCode} {country}
      </div>
    </div>
  );
};

export default CustomerAddress;
