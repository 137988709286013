import React, { FC } from 'react';
import SimpleCard from '../../components/SimpleCard/SimpleCard';
import useLanguage from '../../hooks/useLanguage';
import PromoPosition from '../../types/PromoPosition.enum';
import usePromoContent from './usePromoContent';

const DashboardPromo: FC = () => {
  const lang = useLanguage();
  const { promoContent } = usePromoContent(
    lang,
    PromoPosition.DASHBOARD_SUSTAINABILITY
  );
  return (
    <SimpleCard>
      <h2 className="text-4xl mt-1 mb-4">{promoContent?.header}</h2>
      <div className="leading-8 text-lg">{promoContent?.summary}</div>
      <div className="flex justify-between items-end">
        <a
          className="app-link text-lg"
          href={promoContent?.link?.url}
          target="_blank"
          rel="noreferrer"
        >
          {promoContent?.link?.title}
        </a>
        <img
          src={promoContent?.image}
          alt="Sustainability Promo"
          className="w-28"
        />
      </div>
    </SimpleCard>
  );
};

export default DashboardPromo;
