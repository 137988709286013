import { Trans } from '@lingui/macro';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import * as React from 'react';
import Table from '../../../../components/Table/Table';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { ErrorResponse } from '../../../../types/ErrorResponse.interface';
import { ProductAvailabilityResult } from '../../../../types/ProductAvailabilityResult.interface';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import CharacteristicsCell from './CharacteristicsCell';
import ConfirmedQuantityCell from './ConfirmedQuantityCell';
import FirstTableRow from './FirstTableRow';
import './InitialResultTable.scss';
import AddToBasket from './AddToBasket';
import PackagingCell from '../AlternateSizes/PackagingCell';
import DeliveryDateCell from './DeliveryDateCell';
import useRole from '../../../../hooks/useRole';
import { AuthorityRole } from '../../../../types/Authority.interface';

const WRAP_WIDTH = '120px';
interface IInitialResultTableProps {
  results: ProductAvailabilityResult[];
  resetFullyToLander: () => void;
  error?: ErrorResponse | null;
  className?: string;
  hideHeaders?: boolean;
  requestedQuantity: string;
  productType: string;
}

const InitialResultTable: React.FunctionComponent<IInitialResultTableProps> = ({
  results,
  error,
  className = '',
  hideHeaders,
  resetFullyToLander,
  requestedQuantity,
  productType,
}) => {
  const { hasRole } = useRole();
  const { format } = useNumberFormatter();

  const packagingColumn: ColumnType<ProductAvailabilityResult> = {
    title: <Trans>Packaging</Trans>,
    key: 'packaging',
    render: (value, record) => <PackagingCell record={record} />,
  };

  const nonPriceColumns: ColumnsType<ProductAvailabilityResult> = [
    {
      title: <Trans>Product Type</Trans>,
      key: 'productType',
      render: (value, record) => (
        <>
          <div>
            {record.productType === PutUpCategory.SHEET ? (
              <Trans>Sheet</Trans>
            ) : (
              <Trans>Roll</Trans>
            )}
          </div>
          <div>
            <Trans>Location</Trans>: {record.plantLocation}
          </div>
        </>
      ),
      width: WRAP_WIDTH,
    },
    {
      title: <Trans>Description</Trans>,
      key: 'description',
      render: (value, record) => (
        <>
          <strong>
            {record.txtGradeBrandMember} {record.grammage}
          </strong>
          {record.productType === PutUpCategory.SHEET && (
            <div>
              <Trans>
                (W) {record.width} cm x (L) {record.length} cm
              </Trans>
            </div>
          )}
        </>
      ),
    },
    {
      title: <Trans>Characteristics</Trans>,
      key: 'characteristics',
      render: (value, record) => <CharacteristicsCell record={record} />,
    },
    ...(productType === PutUpCategory.ROLL ? [] : [packagingColumn]),
    {
      title: <Trans>Delivery Date</Trans>,
      key: 'confirmedDate',
      width: WRAP_WIDTH,
      render: (value, record) => (
        <DeliveryDateCell
          requestedDate={record.requestedDate}
          confirmedDate={record.confirmedDate}
        />
      ),
    },
    {
      title: <Trans>Confirmed Quantity</Trans>,
      key: 'confirmedQuantity',
      width: '140px',
      render: (value, record) => (
        <ConfirmedQuantityCell
          record={record}
          requestedQuantity={requestedQuantity}
        />
      ),
    },
  ];

  const priceColumns: ColumnsType<ProductAvailabilityResult> = [
    {
      title: <Trans>Per Ton Price</Trans>,
      key: 'pricePerTon',
      width: WRAP_WIDTH,
      render: (value, record) =>
        `${format(record.pricePerTon)} ${record.currency}`,
    },
    {
      title: <Trans>Per 1000 Sheets</Trans>,
      key: 'pricePer1kSheets',
      width: '120px',
      render: (value, record) =>
        `${format(record.pricePer1kSheets)} ${record.currency}`,
    },
  ];

  const columns = hasRole(AuthorityRole.ROLE_K_CODE_PRICES)
    ? [...nonPriceColumns, ...priceColumns]
    : nonPriceColumns;

  return (
    <div className={`relative mb-2 ${className}`}>
      {results && results[0] && !error && (
        <FirstTableRow
          headerHidden={hideHeaders}
          right={`${results[0].txtGradeBrandMember} ${results[0].grammage}`}
        />
      )}
      <Table
        rowKey="guid"
        className={`sappi-ant-override-order-booking-search-results ${
          hideHeaders
            ? 'sappi-ant-override-order-booking-search-results--hide-headers'
            : ''
        } mb-2`}
        dataSource={error ? [] : results}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: (
            <>
              {!error ? (
                <div className="text-gray-600 text-2xl">
                  <Trans>No product availability found.</Trans>
                </div>
              ) : (
                <div>
                  <div className="text-black-black text-xl -mt-6 mb-4">
                    <Trans>Error During Search</Trans>
                  </div>
                  <div className="text-red-red">
                    ({error.code}) {error.message}
                  </div>
                </div>
              )}
            </>
          ),
        }}
      />
      {!error && (
        <AddToBasket
          product={results?.[0] || null}
          resetFullyToLander={resetFullyToLander}
        />
      )}
    </div>
  );
};

export default InitialResultTable;
