import QuickOrderRow from '../../../components/QuickOrderBookingTable/QuickOrderRow.interface';
import { mergeCustomerBasketItems } from '../../../services/Basket';
import { AvailabilityBasketNA } from '../../../types/AvailabilityBasketNA.interface';
import { AvailabilityCustomer } from '../../../types/AvailabilityCustomer.interface';
import Customer from '../../../types/Customer.interface';
import { NaShipTo } from '../../../types/NaShipTo.interface';
import toAvailabilityBasketItems from './quickOrderRowsToAvailabilityBasketItems.util';

const getShipToCustomer = (
  shipToDetails: AvailabilityCustomer | NaShipTo
): Partial<Customer> => ({
  number:
    (shipToDetails as AvailabilityCustomer).shipToCustomerNumber ||
    shipToDetails.customerNumber,
  name: shipToDetails.name1,
  city: shipToDetails.city,
  address:
    (shipToDetails as AvailabilityCustomer).street ||
    (shipToDetails as NaShipTo).address1,
  postalCode: shipToDetails.postalCode,
  country: shipToDetails.country,
  salesOrg: (shipToDetails as AvailabilityCustomer).salesOrg,
  distributionChannel: (shipToDetails as AvailabilityCustomer)
    .distributionChannel,
  division: (shipToDetails as AvailabilityCustomer).division,
  orderBooking: (shipToDetails as AvailabilityCustomer).orderBooking,
  reservationBooking: (shipToDetails as AvailabilityCustomer)
    .reservationBooking,
});

export default function addQuickOrderRowsToNaBasket(
  rowsToAdd: QuickOrderRow[],
  customerBasket: AvailabilityBasketNA | null,
  requestedDeliveryDate: number | undefined,
  productOrderNumber: string | undefined,
  orderType: string | undefined,
  shipToDetails: AvailabilityCustomer | NaShipTo | undefined
): AvailabilityBasketNA | null {
  if (customerBasket) {
    const updatedBasket = {
      ...customerBasket,
      items: mergeCustomerBasketItems(
        customerBasket.items,
        toAvailabilityBasketItems(rowsToAdd, requestedDeliveryDate)
      ),
      shipToCustomer: shipToDetails ? getShipToCustomer(shipToDetails) : {},
      shipToName: shipToDetails?.name1,
      shipToAddress:
        (shipToDetails as AvailabilityCustomer).street ||
        (shipToDetails as NaShipTo).address1,
      shipToCity: shipToDetails?.city,
      shipToPostalCode: shipToDetails?.postalCode,
      shipToCountryCode:
        (shipToDetails as NaShipTo).countryAbrv || shipToDetails?.country,
      soldToPO: productOrderNumber,
      orderType,
    };
    return updatedBasket;
  }
  return customerBasket;
}
