import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { Button } from '../../../components/Button/Button';
import ErrorTooltip from '../../../components/ErrorTooltip/ErrorTooltip';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Modal, { IModalProps } from '../../../components/Modal/Modal';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { updateSamplesOrderItem } from '../../../services/SamplesOrder';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { SamplesHistoryOrder } from '../../../types/Samples/SamplesHistoryOrder.interface';

interface EditFormData {
  quantity: string;
}

interface IEditOrderItemModalProps extends IModalProps {
  orderItem: SamplesHistoryOrder;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
}

const EditOrderItemModal: React.FunctionComponent<IEditOrderItemModalProps> = ({
  orderItem,
  onCancel,
  title,
  ...rest
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { errors, handleSubmit, register, reset } = useForm({
    mode: 'all',
    defaultValues: { quantity: '' },
  });
  const { parse } = useNumberFormatter();
  const queryCache = useQueryCache();

  const doClose = (e: React.MouseEvent<HTMLElement>): void => {
    reset();
    onCancel(e);
  };

  const editItem = async (
    data: EditFormData,
    e: React.MouseEvent<HTMLElement>
  ): Promise<void> => {
    setIsProcessing(true);
    const parsedQuantity = parse(data.quantity);
    if (parsedQuantity !== null) {
      await updateSamplesOrderItem(
        orderItem.documentNumber,
        orderItem.documentPosition,
        {
          materialNumber: orderItem.materialNumber,
          quantity: parsedQuantity,
          unitOfMeasure: orderItem.unitSizeUnit,
        }
      );
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDERS_HISTORY);
    }
    setIsProcessing(false);
    doClose(e);
  };

  const validateQtyIsNum = (quantity: string): boolean => {
    if (quantity === '') {
      return true;
    }

    const parsed = parse(quantity);

    return parsed !== null;
  };

  return (
    <Modal
      title={<Trans>Edit Item Quantity</Trans>}
      {...rest}
      onCancel={doClose}
    >
      {isProcessing ? (
        <LoadingSpinner horizontal />
      ) : (
        <form className="mb-4">
          <InputLabel size="lg" text={<Trans>Quantity:</Trans>} />
          <ErrorTooltip
            hasError={!!errors?.quantity}
            message={<Trans>Invalid number</Trans>}
            placement="bottom"
          >
            <Input
              name="quantity"
              ref={register({
                validate: validateQtyIsNum,
              })}
              error={errors?.quantity}
            />
          </ErrorTooltip>
        </form>
      )}
      <Space size="large" className="justify-end w-full px-2">
        <Button
          theme="primary"
          className="uppercase"
          onClick={(e) =>
            handleSubmit((data: EditFormData) => editItem(data, e))()
          }
          disabled={isProcessing}
        >
          <Trans>Save</Trans>
        </Button>
        <Button
          theme="link"
          className="uppercase"
          onClick={doClose}
          disabled={isProcessing}
        >
          <Trans>Cancel</Trans>
        </Button>
      </Space>
    </Modal>
  );
};

export default EditOrderItemModal;
