import LiveAgentButtonEvent from '../Chat/types/LiveAgentButtonEvent.enum';

const ONLINE_CHAT_ID_PREFIX = 'liveagent_button_online_';
const OFFLINE_CHAT_ID_PREFIX = 'liveagent_button_offline_';
const HOME_CHAT_BTN_ID_SUFFIX = 'Home';
export const HOME_CHAT_BTN_ID_ONLINE = `${ONLINE_CHAT_ID_PREFIX}${HOME_CHAT_BTN_ID_SUFFIX}`;
export const HOME_CHAT_BTN_ID_OFFLINE = `${OFFLINE_CHAT_ID_PREFIX}${HOME_CHAT_BTN_ID_SUFFIX}`;

const shouldUpdateButton = (path: string): boolean =>
  path === '/home' || path === '/availability';

export const updateButton = (
  liveAgentButtonEvent: string,
  currentPath: string
): void => {
  // Currently ignoring all paths except /home and /availability.
  if (shouldUpdateButton(currentPath)) {
    const onlineBtnEl = document.getElementById(`${HOME_CHAT_BTN_ID_ONLINE}`);
    const offlineBtnEl = document.getElementById(`${HOME_CHAT_BTN_ID_OFFLINE}`);

    const setButtonStatus = (newStatusIsOnline: boolean): void => {
      if (onlineBtnEl) {
        onlineBtnEl.style.display = newStatusIsOnline ? '' : 'none';
      }
      if (offlineBtnEl) {
        offlineBtnEl.style.display = newStatusIsOnline ? 'none' : '';
      }
    };

    const showOnline = () => setButtonStatus(true);
    const showOffline = () => setButtonStatus(false);

    if (liveAgentButtonEvent === LiveAgentButtonEvent.BUTTON_AVAILABLE) {
      showOnline();
    }
    if (liveAgentButtonEvent === LiveAgentButtonEvent.BUTTON_UNAVAILABLE) {
      showOffline();
    }
    if (liveAgentButtonEvent === LiveAgentButtonEvent.BUTTON_ACCEPTED) {
      showOffline();
    }
    if (liveAgentButtonEvent === LiveAgentButtonEvent.BUTTON_REJECTED) {
      showOffline();
    }
  }
};
