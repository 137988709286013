import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import coverPaperImage from '../../../../assets/dummy_sample/cover_paper.png';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import { getSamplesParameters } from '../../../../services/SamplesOrder';
import Option from '../../../../types/Option.type';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import SamplesProductGroup from '../../OrderSamples/SamplesProductGroup.enum';
import { getDummyOptions } from '../DummySamples.util';
import styles from './CoverOptions.module.scss';
import { paperDifferentFromTextOption } from './CoverOptions.util';
import PaperDifferentFromTextOptionType from './PaperDifferentFromTextOptionType.enum';
import useDummyOrderStore from '../useDummyOrder.store';

interface ITextPaperProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  reset: UseFormMethods['reset'];
}

const CoverPaper: React.FunctionComponent<ITextPaperProps> = ({
  register,
  watch,
  reset,
}) => {
  const [brandFamilyOptions, setBrandFamilyOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [grammageOptions, setGrammageOptions] = useState<Option[]>([]);
  const dropdownValues = watch(['brandFamily', 'brand', 'grammage']);
  const paperDifferentFromTextOptionWatch = watch('paperDifferentFromText');
  const state = useDummyOrderStore();
  const isPaperDifferentFromText =
    paperDifferentFromTextOptionWatch !==
    PaperDifferentFromTextOptionType.FALSE;

  const {
    resolvedData: parameters,
    isFetching: isFetchingParameters,
  } = usePaginatedQuery(
    [
      QueryCacheName.SAMPLES_DUMMY_COVER_OPTIONS,
      {
        ...dropdownValues,
        productGroup: [SamplesProductGroup.PUBLIC, SamplesProductGroup.COMM],
      },
    ],
    getSamplesParameters,
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      paperDifferentFromTextOptionWatch ===
        PaperDifferentFromTextOptionType.FALSE ||
      state.prePaperDifferentFromTextOption ===
        PaperDifferentFromTextOptionType.FALSE
    ) {
      reset({
        paperDifferentFromText: paperDifferentFromTextOptionWatch,
      });
    }

    useDummyOrderStore.setState({
      prePaperDifferentFromTextOption: paperDifferentFromTextOptionWatch,
    });
  }, [
    paperDifferentFromTextOptionWatch,
    reset,
    state.prePaperDifferentFromTextOption,
  ]);

  useEffect(() => {
    setBrandFamilyOptions(
      getDummyOptions(parameters?.brandFamilies, 'display', 'brandFamily')
    );
    setProductOptions(
      getDummyOptions(parameters?.brands, 'brandName', 'brand')
    );
    setGrammageOptions(
      getDummyOptions(parameters?.grammages, 'grammage', 'grammage')
    );
  }, [parameters]);

  return (
    <div className="w-1/5">
      <h3 className="text-xl font-bold mb-6">
        <Trans>Cover paper</Trans>
      </h3>
      <div className="border-r border-gray-light400">
        <div className={`pl-6 ${styles.coverPaperWidth}`}>
          <img
            src={coverPaperImage}
            alt={t`Cover Paper`}
            className={styles.coverPaperImageSize}
          />
        </div>
        <div className="ml-5 pr-14 pb-6">
          <Space direction="vertical" className="w-full">
            <InputLabel size="lg" text={t`Cover paper different from text?`}>
              <Select
                name="paperDifferentFromText"
                ref={register()}
                width="full"
                options={paperDifferentFromTextOption}
                required
              />
            </InputLabel>
            <InputLabel
              size="lg"
              text={t`Brand`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="brandFamily"
                key="coverBrandFamily"
                ref={register({
                  required: isPaperDifferentFromText,
                })}
                width="full"
                options={brandFamilyOptions}
                disabled={isFetchingParameters || !isPaperDifferentFromText}
                required
              />
            </InputLabel>
            <InputLabel
              size="lg"
              text={t`Product`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="brand"
                key="coverBrand"
                ref={register({
                  required: isPaperDifferentFromText,
                })}
                width="full"
                options={productOptions}
                disabled={isFetchingParameters || !isPaperDifferentFromText}
                required
              />
            </InputLabel>
            <InputLabel
              size="lg"
              text={t`Grammage`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="grammage"
                key="coverGrammage"
                ref={register({
                  required: isPaperDifferentFromText,
                })}
                width="full"
                options={grammageOptions}
                disabled={isFetchingParameters || !isPaperDifferentFromText}
                required
              />
            </InputLabel>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default CoverPaper;
