enum RegistrationFormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY = 'companyName',
  SAPPI_PRODUCTS = 'sappiProducts',
  JOB_TITLE = 'jobTitle',
  POSTAL_ADDRESS = 'address',
  CITY = 'city',
  POSTAL_CODE = 'postalCode',
  COUNTRY = 'country',
  STATE = 'state',
  PHONE = 'telephone',
  EMAIL = 'email',
  MESSAGE = 'message',
  LANGUAGE = 'language',
  ACCEPTED_TERMS = 'acceptedTerms',
  OPT_IN_AE = 'optInAe',
}

export default RegistrationFormFields;
