import { UseFormMethods } from 'react-hook-form';
import * as React from 'react';
import { t, Trans } from '@lingui/macro';
import sixPageFrontImage from '../../../../assets/dummy_sample/6pg_Gatefold_front.png';
import styles from './CoverOptions.module.scss';
import Radio from '../../../../components/Radio/Radio';
import CoverOptionType from './CoverOptionType.enum';
import sixPageRearImage from '../../../../assets/dummy_sample/6pg_Gatefold_rear.png';
import eightPageImage from '../../../../assets/dummy_sample/8pg_Gatefold_front_and_rear.png';
import PaperDifferentFromTextOptionType from './PaperDifferentFromTextOptionType.enum';

interface ICoverFormatRightTopProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
}

const CoverFormatRightTop: React.FunctionComponent<ICoverFormatRightTopProps> = ({
  register,
  watch,
}) => {
  const paperDifferentFromTextOptionWatch = watch('paperDifferentFromText');
  const isPaperDifferentFromText =
    paperDifferentFromTextOptionWatch !==
    PaperDifferentFromTextOptionType.FALSE;

  return (
    <>
      <div className="flex">
        <div className="text-center w-1/3 pb-16">
          <img
            src={sixPageFrontImage}
            alt={t`6 page cover Gatefold, front`}
            className={`pl-28 ml-2 mb-4 ${styles.coverPaperImageSize}`}
          />
          <Radio
            name="coverOptionType"
            value={CoverOptionType.SIX_PAGE_STANDARD_FRONT}
            labelPosition="below"
            className="mb-4"
            label={
              <span className="text-lg">
                <Trans>6 page cover Gatefold, front</Trans>
              </span>
            }
            ref={register({
              required: isPaperDifferentFromText,
            })}
            disabled={!isPaperDifferentFromText}
            width="full"
            required
          />
        </div>
        <div className="border-l border-gray-light400 text-center w-1/3 pb-16">
          <img
            src={sixPageRearImage}
            alt={t`6 page cover Gatefold, rear`}
            className={`pl-28 ml-2 mb-4 ${styles.coverPaperImageSize}`}
          />
          <Radio
            name="coverOptionType"
            value={CoverOptionType.SIX_PAGE_STANDARD_REAR}
            label={
              <span className="text-lg">
                <Trans>6 page cover Gatefold, rear</Trans>
              </span>
            }
            labelPosition="below"
            className="mb-4"
            ref={register({
              required: isPaperDifferentFromText,
            })}
            disabled={!isPaperDifferentFromText}
            width="full"
            required
          />
        </div>
        <div className="border-l border-gray-light400 text-center w-1/3 pb-16">
          <img
            src={eightPageImage}
            alt={t`8 page cover Gatefold, front & rear`}
            className={`pl-28 mb-4 ${styles.coverPaperImageSize}`}
          />
          <Radio
            name="coverOptionType"
            value={CoverOptionType.EIGHT_PAGE}
            label={
              <span className="text-lg">
                <Trans>8 page cover Gatefold, front & rear</Trans>
              </span>
            }
            labelPosition="below"
            className="mb-4"
            ref={register({
              required: isPaperDifferentFromText,
            })}
            disabled={!isPaperDifferentFromText}
            width="full"
            required
          />
        </div>
      </div>
    </>
  );
};

export default CoverFormatRightTop;
