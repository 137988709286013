import { Space } from 'antd';
import * as React from 'react';
import { PartnerSearchCustomer } from '../../../../types/PartnerSearchCustomer.interface';

interface ICustomerCellProps {
  buyer?: PartnerSearchCustomer;
}

const CustomerCell: React.FunctionComponent<ICustomerCellProps> = ({
  buyer,
}) => {
  return (
    <Space direction="vertical">
      <div>{buyer?.customerNumber}</div>
      <div>{buyer?.name}</div>
      <div>{buyer?.address}</div>
      <div>
        {buyer?.city}, {buyer?.postalCode}
      </div>
      <div>{buyer?.country}</div>
    </Space>
  );
};

export default CustomerCell;
