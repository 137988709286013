import { Trans } from '@lingui/macro';
import * as React from 'react';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { InvoiceDetailsRow } from './InvoiceDetailsRow.interface';

interface IOrderInvoiceCellProps {
  record: InvoiceDetailsRow;
}

const OrderInvoiceCell: React.FunctionComponent<IOrderInvoiceCellProps> = ({
  record,
}) => {
  return (
    <div>
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans>My Order #</Trans>
        </dt>
        <dd className="font-bold">{record.details.customerOrderNumber}</dd>
      </dl>
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans id="Sappi Order #">Sappi Order #</Trans>
        </dt>
        <dd className="font-bold">
          {stripZeros(record.details.documentNumber)}/
          {stripZeros(record.details.documentPosition)}
        </dd>
      </dl>
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans id="orderDetails.invoice">Invoice #</Trans>
        </dt>
        <dd className="font-bold">
          {stripZeros(record.invoice.documentNumber)}/
          {stripZeros(record.invoice.documentPosition)}
        </dd>
      </dl>
    </div>
  );
};

export default OrderInvoiceCell;
