import { Location } from 'history';
import { getExistingComplaint, getComplaintByCustomerOrderNumber } from '../../services/Complaints';

export const getDocParams = (
  url: string
): { documentNumber: string; documentPosition: string; customerOrderNoFlag: boolean } => {
  let fileSegment = 'file/';
  let customerOrderFlag = false;
  if(url.indexOf('customerOrderNumber/') > 0){
    fileSegment = 'customerOrderNumber/';
    customerOrderFlag = true;
  }
  const paramStart = url.indexOf(fileSegment);
  const paramString = url.substring(paramStart + fileSegment.length);
  const paramArray = paramString.split('/');

  return {
    documentNumber: paramArray[0],
    documentPosition: paramArray[1],
    customerOrderNoFlag: customerOrderFlag,
  };
};

export const getComplaintRefs = async (
  location: Location<unknown>
): Promise<string[]> => {
  const { documentNumber, documentPosition, customerOrderNoFlag } = getDocParams(location.pathname);
  let complaintRefs: string[] = [];
  if(customerOrderNoFlag){
    const { returnMessage } = await getComplaintByCustomerOrderNumber(
      documentNumber,
      documentPosition
    );
    
    if (returnMessage[0] && returnMessage[0].complaintNumber) {
      complaintRefs = returnMessage
        .filter((message) => message.complaintNumber)
        .map((message) => ` ${message.customer}`);
    }
  }
  else{
    const { returnMessage } = await getExistingComplaint(
      documentNumber,
      documentPosition
    );
    
    if (returnMessage[0] && returnMessage[0].complaintNumber) {
      complaintRefs = returnMessage
        .filter((message) => message.complaintNumber)
        .map((message) => ` ${message.customer}`);
    }
  }
  
  console.log(complaintRefs);
  
  return complaintRefs;
};
