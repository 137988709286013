import { t, Trans } from '@lingui/macro';
import React, { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Button } from '../../../../components/Button/Button';
import ErrorTooltip from '../../../../components/ErrorTooltip/ErrorTooltip';
import Input from '../../../../components/Input/Input';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import positiveIntegerOnlyInput from '../../../../services/Util/positiveIntegerOnlyInput.util';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';

interface IQuantityCellProps {
  isInternalUser: boolean;
  isFetchingUser: boolean;
  product: SamplesProduct;
  updating?: boolean;
  register: UseFormMethods['register'];
  index: number;
  errors: UseFormMethods['errors'];
  trigger: UseFormMethods['trigger'];
  onRemove: (product: SamplesProduct) => void;
  onChange: () => void;
  readonly?: boolean;
}

const QuantityCell: React.FunctionComponent<IQuantityCellProps> = ({
  isInternalUser,
  isFetchingUser,
  product,
  updating,
  register,
  index,
  errors,
  trigger,
  onRemove,
  onChange,
  readonly,
}) => {
  const { format } = useNumberFormatter();

  // Initial form validation and calculations for Total price triggered
  useEffect(() => {
    if (!isFetchingUser && !readonly) {
      trigger(`quantity[${index}]`);
    }
  }, [index, trigger, isFetchingUser, readonly]);

  return (
    <>
      <div className="flex">
        {readonly ? (
          <strong>{product.quantity}</strong>
        ) : (
          <>
            <div className="w-28">
              {isInternalUser ? (
                <Input
                  disabled={updating}
                  name={`quantity[${index}]`}
                  width="full"
                  error={errors?.quantity && errors?.quantity[index]}
                  onKeyDown={positiveIntegerOnlyInput}
                  defaultValue={product.quantity}
                  onChange={onChange}
                  ref={register({ required: true })}
                />
              ) : (
                <ErrorTooltip
                  hasError={
                    errors?.quantity &&
                    errors.quantity[index]?.type === 'greaterThan3'
                  }
                  message={t`Maximum of 3 pieces/units per order.`}
                >
                  <Input
                    disabled={updating}
                    name={`quantity[${index}]`}
                    width="full"
                    onKeyDown={positiveIntegerOnlyInput}
                    onChange={onChange}
                    defaultValue={product.quantity}
                    error={errors?.quantity && errors?.quantity[index]}
                    ref={register({
                      required: true,
                      validate: {
                        greaterThan3: (value) => parseInt(value, 10) <= 3,
                      },
                    })}
                  />
                </ErrorTooltip>
              )}
            </div>
            <Button
              theme="link"
              onClick={() => onRemove(product)}
              disabled={updating}
              className="ml-3 text-black-black"
            >
              <i className="fa fa-2x fa-trash" />
            </Button>
          </>
        )}
      </div>
      {!updating && (
        <div className={`${readonly ? 'font-bold' : 'mt-4'}`}>
          {product.price > 0 && (
            <div>
              {format(product.price)} {product.currency} <Trans>Per Unit</Trans>
            </div>
          )}
          {product.price <= 0 && (
            <div>
              <Trans>No Cost</Trans>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default QuantityCell;
