import { t } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';

const MIN_REQUIRED = 0.1;
const MAX_REQUIRED = 9999.9;
interface IMetricAreaPerSheetProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

const MetricAreaPerSheet: React.FunctionComponent<IMetricAreaPerSheetProps> = ({
  register,
  errors,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Input
        name="sheetWidth"
        type="number"
        ref={register({
          required: true,
          min: MIN_REQUIRED,
          max: MAX_REQUIRED,
          validate: (value) => validateNumberOfDecimalPlaces(value, 1),
        })}
        required
        error={errors?.sheetWidth}
        placeholder={t`Width (cm)`}
      />
      <Input
        name="sheetLength"
        type="number"
        ref={register({
          required: true,
          min: MIN_REQUIRED,
          max: MAX_REQUIRED,
          validate: (value) => validateNumberOfDecimalPlaces(value, 1),
        })}
        required
        error={errors?.sheetLength}
        placeholder={t`Length (cm)`}
      />
    </Space>
  );
};

export default MetricAreaPerSheet;
