import { t } from '@lingui/macro';
import * as React from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import { UploadedItem } from '../../types/UploadedItem.interface';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';

interface IDimensionsCellProps {
  record: UploadedItem;
}

const DimensionsCell: React.FunctionComponent<IDimensionsCellProps> = ({
  record,
}) => {
  const { isMetric } = useMeasurementSystem();
  const isRoll = record.length === 0 && record.lengthImp === '';
  return (
    <>
      {isRoll ? (
        <>
          <LabelValue
            label={t`Roll Width`}
            inline
            value={
              isMetric 
                ? `${record.width} ${UnitOfMeasure.CM}` 
                : `${record.widthImp} ${UnitOfMeasure.IN}`
            }
          />
          <LabelValue
            label={t`Roll Diameter`}
            inline
            value={
              isMetric
                ? `${record.reelDiameter} ${UnitOfMeasure.CM}`
                : `${record.reelDiameterImp} ${UnitOfMeasure.IN}`
            }
          />
          <LabelValue
            label={t`Weight`}
            inline
            value={
              isMetric
                ? `${record.grossKG} ${UnitOfMeasure.KG}`
                : `${record.grossLB} ${UnitOfMeasure.LB}`
            }
          />
          <LabelValue
            label={t`Length`}
            inline
            value={
              isMetric
                ? `${record.lm} ${UnitOfMeasure.LM}`
                : `${record.lf} ${UnitOfMeasure.LF}`
            }
          />
          <LabelValue
            label={t`Area`}
            inline
            value={
              isMetric
                ? `${record.msm} ${UnitOfMeasure.MSM}`
                : `${record.msf} ${UnitOfMeasure.MSF}`
            }
          />
        </>
      ) : (
        <>
          <LabelValue
            label={t`Sheet Width`}
            inline
            value={
              isMetric 
                ? `${record.width} ${UnitOfMeasure.CM}` 
                : `${record.widthImp} ${UnitOfMeasure.IN}` 
            }
          />
          <LabelValue
            label={t`Sheet Length`}
            inline
            value={
              isMetric 
              ? `${record.length} ${UnitOfMeasure.CM}`
              : `${record.lengthImp} ${UnitOfMeasure.IN}`
            }
          />
          <LabelValue
            label={t`Weight`}
            inline
            value={
              isMetric
                ? `${record.grossKG} ${UnitOfMeasure.KG}`
                : `${record.grossLB} ${UnitOfMeasure.LB}`
            }
          />
          <LabelValue
            label={t`Area`}
            inline
            value={
              isMetric
                ? `${record.msm} ${UnitOfMeasure.MSM}`
                : `${record.msf} ${UnitOfMeasure.MSF}`
            }
          />
        </>
      )}
    </>
  );
};

export default DimensionsCell;
