import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import Legend from '../../components/Legend/Legend';
import Radio from '../../components/Radio/Radio';
import Select from '../../components/Select/Select';
import SimpleCard from '../../components/SimpleCard/SimpleCard';
import useAnalytics from '../../hooks/useAnalytics';
import { getDivisions } from '../../services/Customer';
import {
  EMPTY_OPTION_FROM,
  EMPTY_OPTION_TO,
} from '../../services/Util/emptyOption.const';
import fractionCommaOnlyInput from '../../services/Util/fractionCommaOnlyInput.util';
import {
  euNumStringFormat,
  parseNumberString,
} from '../../services/Util/parseNumberString.util';
import positiveIntegerOnlyInput from '../../services/Util/positiveIntegerOnlyInput.util';
import PutUpCategory from '../../types/PutUpCategory.enum';
import { EU_NUMBER_REG_EX } from '../../types/RegularExpression.constants';
import InputWithErrorTip from './InputWithErrorTip';
import { getProductType } from './StockLot.util';
import { SearchFormData } from './types/SearchFormData.interface';
import { StockLotRequest } from './types/StockLotRequest.interface';
import useCustomerOptions from './useCustomerOptions';
import useDeliveryLocationOptions from './useDeliveryLocationOptions';
import useDivisionOptions from './useDivisionOptions';
import useGradeOptions from './useGradeOptions';
import useGrammageOptions from './useGrammageOptions';
import useErrorInputTips from './useInputErrorTips';
import useStockLotStore, { DEFAULT_REQUEST } from './useStockLotStore.store';

const MIN_LENGTH_WIDTH = 20;
const MAX_LENGTH_WIDTH = 300;
const MAX_ROLL_DIAMETER = 99999999;

interface IStockLotFormProps {
  isSearching: boolean;
  onApplyFilters: (incomingFilters: StockLotRequest) => void;
}

const StockLotForm: FC<IStockLotFormProps> = ({
  isSearching,
  onApplyFilters,
}) => {
  const { trackPageView } = useAnalytics();

  const {
    setRequest,
    setResponse,
    setSearchFormRequest,
    setShowAll,
    setCurrentPage,
  } = useStockLotStore();
  const request = useStockLotStore((state) => state.request);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch,
    formState: { isValid, errors },
  } = useForm<SearchFormData>({
    mode: 'all',
    defaultValues: request as StockLotRequest,
  });
  const watchAllFields = watch();
  const [division, setDivision] = useState<string>('');
  const [hasSetInitialValue, setHasSetInitialValues] = useState(false);

  const {
    showError: widthFromError,
    errorMessage: widthFromMessage,
  } = useErrorInputTips(watch('widthFrom'));

  const {
    showError: widthToError,
    errorMessage: widthToMessage,
  } = useErrorInputTips(watch('widthTo'));

  const {
    showError: lengthFromError,
    errorMessage: lengthFromMessage,
  } = useErrorInputTips(watch('lengthFrom'));

  const {
    showError: lengthToError,
    errorMessage: lengthToMessage,
  } = useErrorInputTips(watch('lengthTo'));

  const { customerOptions, isFetchingUser } = useCustomerOptions();
  const { divisionOptions, isFetchingDivision } = useDivisionOptions(
    watchAllFields.soldToParty
  );
  const {
    deliveryLocationOptions,
    isFetchingDeliveryLocation,
  } = useDeliveryLocationOptions(watchAllFields.soldToParty, division);

  const { gradeOptions, isFetchingGrade } = useGradeOptions(
    watchAllFields.soldToParty,
    division,
    getProductType(watchAllFields.productType)
  );

  const { grammageOptions, isFetchingGrammage } = useGrammageOptions(
    watchAllFields.soldToParty,
    division,
    getProductType(watchAllFields.productType),
    watchAllFields.gradeDescription || ''
  );

  const isAnyFetching =
    isFetchingGrammage || isFetchingDeliveryLocation || isFetchingGrade;

  // To load division per soldToParty
  useEffect(() => {
    const getDivisionsAsync = async () => {
      if (watchAllFields.soldToParty) {
        const divisions = await getDivisions(
          String(watchAllFields.soldToParty)
        );
        if (divisions.length >= 1) {
          if (!watchAllFields.division) {
            setDivision(divisions[0]);
          } else if (watchAllFields.division) {
            setDivision(watchAllFields.division);
          }
        }
      }
    };

    getDivisionsAsync();
  }, [watchAllFields.soldToParty, watchAllFields.division]);

  // This is to update search form fields when the fields updated in filter form
  useEffect(() => {
    if (request) {
      setValue(
        'gradeDescription',
        request.gradeDescription !== '' ? request?.gradeDescription : 'All'
      );
      setValue('bReelDiameter', request.bReelDiameter);
      setValue(
        'coreDiameterComma',
        (request.coreDiameter || '').replace('.', ',')
      );
      setTimeout(() => {
        setValue('grammageFrom', request.grammageFrom);
        setValue('grammageTo', request.grammageTo);
      }, 0);
    }
  }, [request, setValue]);

  // This is to assign soldToParty when page is back to Stock Lot
  useEffect(() => {
    if (!isFetchingUser && request?.soldToParty && customerOptions.length > 1) {
      setValue('soldToParty', request?.soldToParty);
    }
  }, [request, setValue, isFetchingUser, customerOptions]);

  // This is to load and assign shipToParty etc when page is back to Stock Lot
  useEffect(() => {
    if (
      !isAnyFetching &&
      !hasSetInitialValue &&
      deliveryLocationOptions.length > 1
    ) {
      setValue('shipToParty', request?.shipToParty);
      setValue('division', request?.division);
      setValue('gradeDescription', request?.gradeDescription);
      setValue('productType', getProductType(request?.productType));
      // The timeout is needed here since getGrammages service depends on gradeDescription and shipToParty
      setTimeout(() => {
        setValue('grammageFrom', request?.grammageFrom);
        setValue('grammageTo', request?.grammageTo);
      }, 0);
      trigger();

      setHasSetInitialValues(true);
    }
  }, [
    isAnyFetching,
    hasSetInitialValue,
    request,
    setValue,
    trigger,
    deliveryLocationOptions,
  ]);

  const onSubmit = handleSubmit(async (data: SearchFormData) => {
    trackPageView('STOCK_LOT', 'SHOW_PRODUCT', { customerId: data.soldToParty });
    setShowAll(false);
    setCurrentPage(1);

    const appliedRequest: StockLotRequest = {
      ...data,
      bReelDiameter: '', // This value is not applied in Angular actually
      coreDiameter: watchAllFields.coreDiameterComma
        ? String(
            parseNumberString(
              String(watchAllFields.coreDiameterComma),
              euNumStringFormat
            )
          )
        : '',
      coreDiameterComma: undefined,
    };
    setSearchFormRequest(appliedRequest);
    onApplyFilters(appliedRequest);
  });

  const doReset = (): void => {
    setRequest(DEFAULT_REQUEST);
    reset(DEFAULT_REQUEST);
    setResponse(null);
  };

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      style={{ minWidth: '300px', maxWidth: '350px' }}
    >
      <SimpleCard>
        <Space size="middle" direction="vertical" className="w-full">
          <InputLabel
            text={t`Customer`}
            size="lg"
            required
            isFetching={isFetchingUser}
          >
            <Select
              name="soldToParty"
              ref={register({ required: true })}
              options={customerOptions}
              required
              width="full"
              disabled={isFetchingUser}
            />
          </InputLabel>
          <InputLabel
            text={t`Delivery Location`}
            size="lg"
            required
            isFetching={isFetchingDeliveryLocation}
          >
            <Select
              name="shipToParty"
              ref={register({ required: true })}
              options={deliveryLocationOptions}
              required
              width="full"
              disabled={isFetchingUser}
            />
          </InputLabel>
          <InputLabel
            text={t`Paper Type`}
            size="lg"
            required
            isFetching={isFetchingDivision}
          >
            <Select
              name="division"
              ref={register({ required: true })}
              options={divisionOptions}
              required
              width="full"
              disabled={isFetchingUser}
            />
          </InputLabel>
          <fieldset>
            <Legend size="lg" className="py-2">
              <Trans>Product Type</Trans>:
            </Legend>
            <Space size="middle">
              <Radio
                name="productType"
                value={PutUpCategory.SHEET}
                label={<Trans>Sheet</Trans>}
                ref={register()}
              />
              <Radio
                name="productType"
                value={PutUpCategory.ROLL}
                label={<Trans>Roll</Trans>}
                ref={register()}
              />
            </Space>
          </fieldset>
          <InputLabel text={t`Product`} size="lg" isFetching={isFetchingGrade}>
            <Select
              name="gradeDescription"
              ref={register()}
              options={gradeOptions}
              width="full"
              disabled={isFetchingUser}
            />
          </InputLabel>
          <fieldset>
            <Legend size="lg" className="py-2" isFetching={isFetchingGrammage}>
              <Trans>Grammage</Trans>
            </Legend>
            <Space size="middle" direction="vertical" className="w-full">
              <Select
                name="grammageFrom"
                ref={register()}
                options={[EMPTY_OPTION_FROM, ...grammageOptions]}
                width="full"
                disabled={isFetchingUser}
              />
              <Select
                name="grammageTo"
                ref={register()}
                options={[EMPTY_OPTION_TO, ...grammageOptions]}
                width="full"
                disabled={isFetchingUser}
              />
            </Space>
          </fieldset>
          <fieldset>
            <Legend size="lg" className="py-2">
              <Trans>Width Range (cm)</Trans>
            </Legend>
            <Space size="small" className="w-full">
              <InputWithErrorTip
                showError={widthFromError}
                errorMessage={widthFromMessage}
              >
                <Input
                  placeholder={t`From`}
                  name="widthFrom"
                  ref={register({
                    min: MIN_LENGTH_WIDTH,
                    max: MAX_LENGTH_WIDTH,
                  })}
                  type="number"
                  width="full"
                  onKeyPress={positiveIntegerOnlyInput}
                  error={errors?.widthFrom}
                />
              </InputWithErrorTip>
              <InputWithErrorTip
                showError={widthToError}
                errorMessage={widthToMessage}
              >
                <Input
                  placeholder={t`To`}
                  name="widthTo"
                  ref={register({
                    min: MIN_LENGTH_WIDTH,
                    max: MAX_LENGTH_WIDTH,
                  })}
                  type="number"
                  width="full"
                  onKeyPress={positiveIntegerOnlyInput}
                  error={errors?.widthTo}
                />
              </InputWithErrorTip>
            </Space>
          </fieldset>
          {getProductType(watchAllFields.productType) ===
            PutUpCategory.SHEET && (
            <fieldset>
              <Legend size="lg" className="py-2">
                <Trans>Length Range (cm)</Trans>
              </Legend>
              <Space size="small" className="w-full">
                <InputWithErrorTip
                  showError={lengthFromError}
                  errorMessage={lengthFromMessage}
                >
                  <Input
                    placeholder={t`From`}
                    name="lengthFrom"
                    ref={register({
                      min: MIN_LENGTH_WIDTH,
                      max: MAX_LENGTH_WIDTH,
                    })}
                    type="number"
                    width="full"
                    onKeyPress={positiveIntegerOnlyInput}
                    error={errors?.lengthFrom}
                  />
                </InputWithErrorTip>
                <InputWithErrorTip
                  showError={lengthToError}
                  errorMessage={lengthToMessage}
                >
                  <Input
                    placeholder={t`To`}
                    name="lengthTo"
                    ref={register({
                      min: MIN_LENGTH_WIDTH,
                      max: MAX_LENGTH_WIDTH,
                    })}
                    type="number"
                    width="full"
                    onKeyPress={positiveIntegerOnlyInput}
                    error={errors?.lengthTo}
                  />
                </InputWithErrorTip>
              </Space>
            </fieldset>
          )}
          {getProductType(watchAllFields.productType) ===
            PutUpCategory.ROLL && (
            <fieldset>
              <Legend size="lg" className="py-2">
                <Trans>Roll & Core Diameters (cm)</Trans>
              </Legend>
              <Space size="small" className="w-full">
                <Input
                  placeholder={t`Roll`}
                  name="bReelDiameter"
                  ref={register({
                    min: 0,
                    max: MAX_ROLL_DIAMETER,
                  })}
                  type="number"
                  width="full"
                  onKeyPress={positiveIntegerOnlyInput}
                  error={errors?.bReelDiameter}
                />

                <Input
                  placeholder={t`Core`}
                  name="coreDiameterComma"
                  ref={register({
                    pattern: EU_NUMBER_REG_EX,
                  })}
                  type="text"
                  width="full"
                  onKeyPress={fractionCommaOnlyInput}
                  error={errors?.coreDiameterComma}
                />
              </Space>
            </fieldset>
          )}
        </Space>
        <Space size="middle" className="flex mt-8">
          <Button
            type="submit"
            theme="primary"
            disabled={!isValid || isSearching}
          >
            <Trans>Show Products</Trans>
          </Button>
          <Button
            type="reset"
            theme="link"
            className="text-lg font-bold"
            onClick={doReset}
          >
            <Trans>Reset</Trans>
          </Button>
        </Space>
      </SimpleCard>
    </form>
  );
};

export default StockLotForm;
