import create from 'zustand';
import ProductQuery from '../../types/ProductQuery.interface';
import PutUpCategory from '../../types/PutUpCategory.enum';

type FilterState = {
  rollFilter: ProductQuery;
  setRollFilter: (filter: Partial<ProductQuery>) => void;
  sheetFilter: ProductQuery;
  setSheetFilter: (filter: Partial<ProductQuery>) => void;
};

export const DEFAULT_FILTER_STATE: ProductQuery = {
  brand: '',
  finish: '',
  caliper: undefined,
  type: PutUpCategory.ROLL,
  skuCodes: [],
  customerSoldTo: '',
  filterName: '',
  minLength: undefined,
  maxLength: undefined,
  minWidth: undefined,
  maxWidth: undefined,
  grammage: '',
  basisWeight: undefined,
  chemistry: undefined,
  parentBrand: undefined,
  texture: undefined,
};

const useQuickOrderFilterStore = create<FilterState>((set) => ({
  rollFilter: DEFAULT_FILTER_STATE,
  setRollFilter: (filter: Partial<ProductQuery>) =>
    set((state) => ({ rollFilter: { ...state.rollFilter, ...filter } })),
  sheetFilter: DEFAULT_FILTER_STATE,
  setSheetFilter: (filter: Partial<ProductQuery>) =>
    set((state) => ({ sheetFilter: { ...state.sheetFilter, ...filter } })),
}));

export default useQuickOrderFilterStore;
