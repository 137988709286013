import { Trans } from '@lingui/macro';
import { Popover } from 'antd';
import { forOwn } from 'lodash';
import React from 'react';
import useRole from '../../../hooks/useRole';
import {
  getUserCustomization,
  saveUserCustomization,
} from '../../../services/User';
import { AuthorityRole } from '../../../types/Authority.interface';
import { DocumentTypes } from '../../../types/DocumentTypes.interface';
import useDynamicColumnsStore from './hooks/dynamicColumns.store';
import useDynamicColumns from './hooks/useDynamicColumns';
import useAnalytics from '../../../hooks/useAnalytics';
import useUser from '../../../hooks/useUser';

interface IEditDocPopOverProps {
  visible?: boolean;
}

const EditDocPopOver: React.FunctionComponent<IEditDocPopOverProps> = ({
  visible,
  children,
}) => {
  const { dynamicColumns, setDynamicColumnVisibility } = useDynamicColumns();
  const { hasRole } = useRole();
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();

  const getColumnsToSave = () => {
    const columnsToSave: string[] = [];
    forOwn(useDynamicColumnsStore.getState().columns, (value, key) => {
      if (value && value.show) {
        columnsToSave.push(value.id);
      }
    });
    return columnsToSave;
  };

  const saveColumns = async () => {
    trackPageView('PRINT_DOCUMENTS', 'EDIT_COLUMNS', {
      username: user?.username,
    });
    const userCustomization = await getUserCustomization();
    await saveUserCustomization({
      ...userCustomization,
      printDocumentsColumns: JSON.stringify(getColumnsToSave()),
    });
  };

  const doUpdateColumnVisibility = async (
    type: DocumentTypes,
    isVisible: boolean
  ) => {
    setDynamicColumnVisibility(type, isVisible);
    await saveColumns();
  };

  return (
    <Popover
      placement="bottomRight"
      visible={visible}
      content={
        <>
          {hasRole(AuthorityRole.ROLE_TRACK_BY) && (
            <div>
              <input
                type="checkbox"
                className="mt-2 ml-3"
                checked={dynamicColumns[DocumentTypes.ORDER_CONFIRMATION]?.show}
                onChange={(e) => {
                  doUpdateColumnVisibility(
                    DocumentTypes.ORDER_CONFIRMATION,
                    e.target.checked
                  );
                }}
              />{' '}
              <span className="text-lg">
                <Trans>Order Conf. #</Trans>
              </span>
            </div>
          )}
          {hasRole(AuthorityRole.ROLE_TRACK_BY) && (
            <div>
              <input
                type="checkbox"
                className="mt-2 ml-3"
                checked={dynamicColumns[DocumentTypes.INVOICE]?.show}
                onChange={(e) => {
                  doUpdateColumnVisibility(
                    DocumentTypes.INVOICE,
                    e.target.checked
                  );
                }}
              />{' '}
              <span className="text-lg">
                <Trans>Invoice #</Trans>
              </span>
            </div>
          )}
          <div>
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={dynamicColumns[DocumentTypes.WEIGHTLIST]?.show}
              onChange={(e) => {
                doUpdateColumnVisibility(
                  DocumentTypes.WEIGHTLIST,
                  e.target.checked
                );
              }}
            />{' '}
            <span className="text-lg">
              <Trans>Weight List #</Trans>
            </span>
          </div>
          <div>
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={dynamicColumns[DocumentTypes.DELIVERY]?.show}
              onChange={(e) => {
                doUpdateColumnVisibility(
                  DocumentTypes.DELIVERY,
                  e.target.checked
                );
              }}
            />{' '}
            <span className="text-lg">
              <Trans>Delivery</Trans>
            </span>
          </div>
          {hasRole(AuthorityRole.ROLE_TRACK_BY) && (
            <div>
              <input
                type="checkbox"
                className="mt-2 ml-3"
                checked={dynamicColumns[DocumentTypes.CREDIT_NOTE]?.show}
                onChange={(e) => {
                  doUpdateColumnVisibility(
                    DocumentTypes.CREDIT_NOTE,
                    e.target.checked
                  );
                }}
              />{' '}
              <span className="text-lg">
                <Trans>Credit/Debit Note</Trans>
              </span>
            </div>
          )}
          <div>
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={dynamicColumns[DocumentTypes.BAR_CODE_WEIGHT_LIST]?.show}
              onChange={(e) => {
                doUpdateColumnVisibility(
                  DocumentTypes.BAR_CODE_WEIGHT_LIST,
                  e.target.checked
                );
              }}
            />{' '}
            <span className="text-lg">
              <Trans>Bar code weight list</Trans>
            </span>
          </div>
          <div>
            <input
              type="checkbox"
              className="mt-2 ml-3"
              checked={
                dynamicColumns[DocumentTypes.CERTIFICATE_OF_ANALYSIS]?.show
              }
              onChange={(e) => {
                doUpdateColumnVisibility(
                  DocumentTypes.CERTIFICATE_OF_ANALYSIS,
                  e.target.checked
                );
              }}
            />{' '}
            <span className="text-lg mr-2 mb-1">
              <Trans>Certificate of Analysis</Trans>
            </span>
          </div>
        </>
      }
      trigger="click"
    >
      {children}
    </Popover>
  );
};

export default EditDocPopOver;
