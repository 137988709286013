import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import useAnalytics from '../../../hooks/useAnalytics';
import { getEuGrammageOptions } from '../../../services/Brand';
import { EMPTY_OPTION_SELECT } from '../../../services/Util/emptyOption.const';
import EuBrand from '../../../types/EuBrand.interface';
import UnitOfMeasure from '../../../types/UnitOfMeasure.enum';
import useBrandOptions from '../useBrandOptions';
import useCalculatorCountryOptions from '../useCalculatorCountryOptions';
import { RollCalculatorResult } from './types/RollCalculatorResult.interface';
import { RollForm } from './types/RollForm.interface';
import { calculateRoll } from '../Calculator.service';
import Input from '../../../components/Input/Input';
import { EU_NUMBER_REG_EX } from '../../../types/RegularExpression.constants';
import fractionOrDecimalInput from '../../../services/Util/fractionOrDecimalInput.util';
import useDiameterOptions from '../Payload/useDiameterOptions';
import { EMPTY_ROLL_CALCULATOR_RESULT, UOM_OPTIONS } from './Criteria.util';
import Legend from '../../../components/Legend/Legend';

interface ICriteriaProps {
  onSubmit: (retrievedData: RollCalculatorResult) => void;
}

const Criteria: React.FunctionComponent<ICriteriaProps> = ({ onSubmit }) => {
  const {
    countryOptions,
    isFetching: isFetchingCountries,
  } = useCalculatorCountryOptions();

  const {
    register,
    watch,
    getValues,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<RollForm>({ mode: 'all' });

  const { trackPageView } = useAnalytics();

  const { brandOptions, rawBrands } = useBrandOptions({
    context: UnitOfMeasure.RL,
  });

  const selectedBrandIndex = watch('ivBrandMember') as string;
  const selectedBrand =
    selectedBrandIndex && rawBrands ? rawBrands[+selectedBrandIndex] : null;
  const grammageOptions = selectedBrand
    ? getEuGrammageOptions(
        rawBrands as EuBrand[],
        'grammages',
        selectedBrand.brand,
        t`Select`
      )
    : [EMPTY_OPTION_SELECT];

  const selectedGrammage = watch('ivGrammage');
  const { reelDiameterOptions, coreDiameterOptions } = useDiameterOptions({
    brandMember: selectedBrand?.brandMember || '',
    grade: selectedBrand?.grade || '',
    grammage: selectedGrammage,
  });

  const [error, setError] = useState('');
  const [isCalculating, setIsCalculating] = useState(false);

  const [isCalculated, setIsCalculated] = useState(false);

  const onReset = () => {
    reset();
    if (isCalculated) {
      onSubmit(EMPTY_ROLL_CALCULATOR_RESULT);
    }
  };

  const onCalculate = handleSubmit(async (data: RollForm) => {
    try {
      setError('');
      setIsCalculating(true);
      setIsCalculated(true);
      trackPageView('CALCULATORS', 'CALCULATE_ROLL');
      const response = await calculateRoll({
        ...data,
        ivBrandMember: selectedBrand?.brandMember || '',
        ivGrade: selectedBrand?.grade || '',
        ivReelWidth: data.ivReelWidth.replace(',', '.'),
        ivQty: data.ivQty.replace(',', '.'),
      });
      onSubmit(response);
    } catch (e) {
      setError(e.message ? e.message : t`Unknown error occurred`);
      onSubmit({});
    } finally {
      setIsCalculating(false);
    }
  });

  return (
    <form
      style={{ width: '330px', minWidth: '330px' }}
      onSubmit={onCalculate}
      noValidate
      className="mr-14"
    >
      <Space direction="vertical" size="middle">
        <InputLabel
          text={t`Destination Country / Max Payload`}
          size="lg"
          required
          isFetching={isFetchingCountries}
        >
          <Select
            name="ivCountryMaxPl"
            ref={register({ required: true })}
            options={countryOptions}
            required
            width="full"
            error={errors?.ivCountryMaxPl}
            disabled={isFetchingCountries}
          />
        </InputLabel>
        <InputLabel text={t`Sales Grade`} size="lg" required>
          <Select
            name="ivBrandMember"
            ref={register({ required: true })}
            options={brandOptions}
            required
            width="full"
            error={errors?.ivBrandMember}
          />
        </InputLabel>
        <InputLabel text={t`Grammage`} size="lg" required>
          <Select
            name="ivGrammage"
            ref={register({ required: true })}
            options={grammageOptions}
            required
            width="full"
            disabled={!selectedBrandIndex}
            error={errors?.ivGrammage}
          />
        </InputLabel>
        <InputLabel text={t`Roll Width (cm)`} size="lg" required>
          <Input
            name="ivReelWidth"
            ref={register({
              required: true,
              pattern: EU_NUMBER_REG_EX,
            })}
            onKeyPress={fractionOrDecimalInput}
            required
            width="full"
            error={errors?.ivReelWidth}
          />
        </InputLabel>
        <InputLabel text={t`Roll Diameter (cm)`} size="lg" required>
          <Select
            name="ivReelDiameter"
            ref={register({
              validate: (value) => {
                return !!getValues().ivGrammage || !!value;
              },
            })}
            options={reelDiameterOptions}
            required
            width="full"
            error={errors?.ivReelDiameter}
          />
        </InputLabel>
        <InputLabel text={t`Core Diameter (cm)`} size="lg" required>
          <Select
            name="ivCoreDiameter"
            ref={register({ required: true })}
            options={coreDiameterOptions}
            required
            width="full"
            error={errors?.ivCoreDiameter}
          />
        </InputLabel>
        <fieldset>
          <Legend className="my-2 pt-2" required size="lg">
            <Trans>Ordered Quantity</Trans>
          </Legend>
          <Space size="large" className="items-end">
            <Input
              name="ivQty"
              required
              width="full"
              ref={register({
                required: true,
                pattern: EU_NUMBER_REG_EX,
              })}
              maxLength={9}
              error={errors?.ivQty}
            />
            <Select
              name="ivUom"
              className="ml-4"
              ref={register({ required: true })}
              options={UOM_OPTIONS}
              required
              width="full"
              error={errors?.ivUom}
            />
          </Space>
        </fieldset>
        <Space size="middle" className="mt-8">
          <Button
            theme="primary"
            disabled={!isValid || isCalculating}
            loading={isCalculating}
          >
            <Trans>Calculate</Trans>
          </Button>
          <Button
            type="reset"
            theme="link"
            className="text-lg font-bold"
            onClick={() => onReset()}
          >
            <Trans>Reset</Trans>
          </Button>
        </Space>
        {!!error && <div className="text-red-red">{error}</div>}
      </Space>
    </form>
  );
};

export default Criteria;
