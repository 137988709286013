enum SearchFormField {
  FORWARDING_AGENT = 'forwardingAgent',
  DELIVERY_NOTE = 'deliveryNote',
  TRANSIT_ID = 'transitId',
  TRANSIT_DATE_FROM = 'transitDateFrom',
  TRANSIT_DATE_TO = 'transitDateTo',
  SHIPPING_POINT = 'shippingPoint',
  ARRIVAL_STATE = 'arrivalState',
  SHIP_TO_COUNTRY = 'shipToCountry',
  SUBCONTRACTOR = 'subcontractor',
  REF_FORWARDING_AGENT = 'referenceForwardingAgent',
}

export default SearchFormField;
