import { Trans } from '@lingui/macro';
import * as React from 'react';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import styles from './ExpandedRowProductDescription.module.scss';
import QuickOrderRow from './QuickOrderRow.interface';
import useMeasurementSystem from '../../hooks/useMeasurementSystem';
import MeasurementSystem from '../../types/MeasurementSystem.enum';
import MeasurementUnit from '../../types/MeasurementUnit.enum';
import PutUpCategory from '../../types/PutUpCategory.enum';

interface IExpandedRowProductDescriptionProps {
  record: QuickOrderRow;
}

const ExpandedRowProductDescription: React.FunctionComponent<IExpandedRowProductDescriptionProps> = ({
  record,
}) => {
  const { format } = useNumberFormatter();
  const { measurementSystem } = useMeasurementSystem();

  let weight = '';
  if (record.productType === PutUpCategory.ROLL) {
    weight =
      measurementSystem === MeasurementSystem.METRIC
        ? `${format(record.weightKg)} ${MeasurementUnit.KG}`
        : `${record.weight} ${MeasurementUnit.LB}`;
  }

  return (
    <div className={`${styles.container}  pl-12`}>
      <div>
        <strong>
          <Trans>Product Description</Trans>
        </strong>
        <div className="flex justify-between mt-3">
          <div>
            {record.productType === PutUpCategory.ROLL && (
              <Trans>Avg Roll Wgt:</Trans>
            )}
          </div>
          <strong className="w-1/2">{weight}</strong>
        </div>
        <div className="flex justify-between">
          <div>
            <Trans>Wrap Type:</Trans>
          </div>
          <strong className="w-1/2">{record.wrapType}</strong>
        </div>
      </div>
    </div>
  );
};

export default ExpandedRowProductDescription;
