import { t } from '@lingui/macro';
import CalculatorId from './CalculatorId.enum';
import EuCalculatorsTab from './EuCalculatorsTab.enum';
import NaOrReleaseCalculatorsTab from './NaOrReleaseCalculatorsTab.enum';

export const CALCULATOR_LABELS: Record<CalculatorId, string> = {
  // Sheet calculators:
  [CalculatorId.M_WEIGHT]: t`M Weight`,
  [CalculatorId.PAGES_PER_INCH]: t`Pages Per Inch`,
  [CalculatorId.SHEETS_TO_WEIGHT]: t`Sheets To Weight`,
  [CalculatorId.AREA_PER_SHEET]: t`Area Per Sheet`,
  // Roll calculators:
  [CalculatorId.ROLL_WEIGHT_AND_LENGTH]: t`Roll Weight & Length`,
  [CalculatorId.LINEAR_LENGTH_TO_AREA]: t`Lineal Length To Area`,
  [CalculatorId.AREA_WEIGHT_CONVERSION]: t`Square Area/Weight Conversion`,
  [CalculatorId.ROLL]: '',
  // Price calculators:
  [CalculatorId.PRICE_CWT]: t`Price Per Hundred Weight Conversion`,
  [CalculatorId.PRICE_PER_TON]: t`Price Per Ton Conversions`,
  // Conversion calculators:
  [CalculatorId.WEIGHT_CONVERSION]: t`Weight`,
  [CalculatorId.LENGTH_CONVERSION]: t`Length`,
  [CalculatorId.BASIS_WEIGHT_CONVERSION]: t`Basis Weight`,
  [CalculatorId.THICKNESS_CONVERSION]: t`Thickness`,
  // eQ calculator:
  [CalculatorId.EQ]: t`eQ Calculator`,
  // Pallet height & weight calculator:
  [CalculatorId.PALLET_HEIGHT_WEIGHT]: t`Pallet Height And Weight`,
  // Payload calculator:
  [CalculatorId.PAYLOAD]: '',
};

export const CALCULATOR_TAB_LABELS: Record<
  NaOrReleaseCalculatorsTab | EuCalculatorsTab,
  string
> = {
  [NaOrReleaseCalculatorsTab.SHEET_CALCULATORS]: t`Sheet Calculators`,
  [NaOrReleaseCalculatorsTab.ROLL_CALCULATORS]: t`Roll Calculators`,
  [NaOrReleaseCalculatorsTab.PRICE_CALCULATORS]: t`Price Calculators`,
  [NaOrReleaseCalculatorsTab.CONVERSION_CALCULATORS]: t`Conversion Calculators`,
  [NaOrReleaseCalculatorsTab.EQ_CALCULATORS]: t`eQ Calculator`,
  [EuCalculatorsTab.METRIC_CONVERSIONS]: t`Metric Conversions`,
  [EuCalculatorsTab.PAPER_MATH]: t`Paper Math`,
  [EuCalculatorsTab.ROLL]: t`Roll`,
  [EuCalculatorsTab.PALLET_HEIGHT_WEIGHT]: t`Pallet Height & Weight`,
  [EuCalculatorsTab.PAYLOAD]: t`Payload`,
};
