import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import useAnalytics from '../../hooks/useAnalytics';
import useRole from '../../hooks/useRole';
import stripZeros from '../../services/Util/stripZeros.util';
import { AuthorityRole } from '../../types/Authority.interface';
import ResultLabelValue from './ResultLabelValue';
import { TrackByLabelResponse } from './types/TrackByLabelResponse.interface';

interface IResultsProps {
  result: TrackByLabelResponse;
  isWidget?: boolean;
}

const Results: React.FunctionComponent<IResultsProps> = ({
  result,
  isWidget = false,
}) => {
  const { hasRole } = useRole();
  const { trackPageView } = useAnalytics();
  const history = useHistory();

  const navigateToOrderDetails = () => {
    trackPageView('TRACK_BY_LABEL_ID', 'ORDER_DETAILS');
    history.push(
      `/orderTracking/details/${result.documentNumber}/${result.documentPosition}`
    );
  };

  const navigateToFileAClaim = () => {
    trackPageView('TRACK_BY_LABEL_ID', 'FILE_CLAIM');
    history.push(
      `/claim/file/${result.documentNumber}/${result.documentPosition}`
    );
  };

  return (
    <Space
      className={`border border-gray-light400 ${
        isWidget ? 'mt-1 py-4 px-5' : 'py-5 px-7'
      }`}
      size="middle"
      direction="vertical"
      style={{ maxWidth: '350px', borderWidth: '5px' }}
    >
      <h2 className={`text-4xl ${isWidget ? '-mt-2 -mb-3' : 'mb-2'}`}>
        Results
      </h2>
      <ResultLabelValue
        label={t`Sappi Order Number`}
        value={`${stripZeros(result.documentNumber)} / ${stripZeros(
          result.documentPosition
        )}`}
        className={isWidget ? '' : 'mb-3'}
      />
      <ResultLabelValue
        label={t`My Order Number`}
        value={result.customerOrderNumber}
        className={isWidget ? '' : 'mb-3'}
      />
      <Space size="middle" className="flex flex-wrap">
        <Button
          theme="primary"
          onClick={navigateToOrderDetails}
          className="mb-3"
        >
          <Trans>Order Details</Trans>
        </Button>
        {hasRole(AuthorityRole.ROLE_COMPLAINT_CREATE) && (
          <Button
            theme="primary"
            onClick={navigateToFileAClaim}
            className="mb-3"
          >
            <Trans>File a Claim</Trans>
          </Button>
        )}
      </Space>
    </Space>
  );
};

export default Results;
