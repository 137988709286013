import { t } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';

interface IDescriptionCellProps {
  items: ConsignmentInventoryItem[];
}

const DescriptionCell: React.FunctionComponent<IDescriptionCellProps> = ({
  items,
}) => {
  const { format } = useNumberFormatter();
  const { isMetric } = useMeasurementSystem();
  const lastIndex = items.length - 1;
  return (
    <Space direction="vertical" size="large" className="w-full print:block">
      {items.map((item, index) => (
        <Space
          key={`${item.documentNumber}${item.documentPosition}${item.salesDocumentItem}${item.itemNumber}`}
          direction="vertical"
          className={`${
            lastIndex !== index ? 'border-b pb-5' : ''
          } border-gray-light400 w-full`}
        >
          {item.brand && (
            <LabelValue inline label={t`Brand`} value={item.brand} />
          )}
          {item.width && (
            <LabelValue
              inline
              label={t`Roll Width`}
              value={
                isMetric 
                  ? `${item.width} ${UnitOfMeasure.CM}` 
                  : `${item.widthImp} ${UnitOfMeasure.IN}`
              }
            />
          )}
          {item.realReelDiameter && (
            <LabelValue
              inline
              label={t`Roll Diameter`}
              value={
                isMetric 
                  ? `${item.realReelDiameter} ${UnitOfMeasure.CM}` 
                  : `${item.realReelDiameterImp} ${UnitOfMeasure.IN}`
              }
            />
          )}
          { /* {item.materialNumber && (
            <LabelValue
              inline
              label={t`Sappi Material Number`}
              value={item.materialNumber}
            />
          )} */ }
          {item.customerMaterialNumber && (
            <LabelValue
              inline
              label={t`Customer Material Number`}
              value={item.customerMaterialNumber}
            />
          )}
          {item.kcode && (
            <LabelValue inline label={t`Sappi SKU`} value={item.kcode} />
          )}
          {item.nariNumber && (
            <LabelValue
              inline
              label={t`Pallet/Roll ID`}
              value={item.nariNumber}
            />
          )}
          {item.poundsGross && !isMetric && (
            <LabelValue
              inline
              label={t`Weight`}
              value={`${item.poundsGross} ${UnitOfMeasure.LB}` }
            />
          )}
          {item.kgGross && isMetric && (
            <LabelValue
              inline
              label={t`Weight`}
              value={`${item.kgGross} ${UnitOfMeasure.KG}` }
            />
          )}
          {item.msf && !isMetric && (
            <LabelValue
              inline
              label={t`Area`}
              value={`${item.msf} ${UnitOfMeasure.MSF}`}
            />
          )}
          {item.msm && isMetric && (
            <LabelValue
              inline
              label={t`Area`}
              value={`${item.msm} ${UnitOfMeasure.MSM}`}
            />
          )}
          {item.age && (
            <LabelValue
              inline
              label={t`Inventory Age`}
              value={`${item.age} ${t`Days`}`}
            />
          )}
        </Space>
      ))}
    </Space>
  );
};

export default DescriptionCell;
