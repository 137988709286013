import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPartnerSearchCustomerByNumber } from '../../../../services/Customer';
import { PartnerSearchCustomer } from '../../../../types/PartnerSearchCustomer.interface';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import ViewSampleOrdersTableRowType from '../../../../types/Samples/ViewSampleOrdersTableRowType.enum';
import OrderItem from './OrderItem';

interface IOrderProps {
  order: AugmentedSamplesHistoryOrder;
}

const Order: React.FunctionComponent<IOrderProps> = ({ order }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [augmentedOrder, setAugmentedOrder] = useState<
    AugmentedSamplesHistoryOrder
  >(order);
  const { data: soldToCustomerInfo } = useQuery<PartnerSearchCustomer>(
    [QueryCacheName.PARTNER_SEARCH_CUSTOMER, order?.soldToCustomer],
    getPartnerSearchCustomerByNumber,
    {
      staleTime: Infinity,
      enabled: order?.soldToCustomer,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setAugmentedOrder({ ...order, soldToCustomerInfo });
  }, [order, soldToCustomerInfo]);

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const isGrouped = !!order.grouped;

  return (
    <>
      <OrderItem
        order={augmentedOrder}
        rowType={
          isGrouped
            ? ViewSampleOrdersTableRowType.GROUPED
            : ViewSampleOrdersTableRowType.SINGLE
        }
        expanded={expanded}
        onToggleExpanded={toggleExpanded}
      />
      {isGrouped &&
        expanded &&
        augmentedOrder?.items?.map((item) => (
          <OrderItem
            order={item}
            rowType={ViewSampleOrdersTableRowType.SUBITEM}
          />
        ))}
    </>
  );
};

export default Order;
