import * as React from 'react';
import { Button } from '../Button/Button';

interface ISearchPopoverContentProps {
  title: string | React.ReactNode;
  onClose: () => void;
  body: string | React.ReactNode;
  button: React.ReactNode;
}

const SearchPopoverContent: React.FunctionComponent<ISearchPopoverContentProps> = ({
  title,
  onClose,
  body,
  button,
}) => {
  return (
    <div className="p-3 bg-gray-dark200 text-white-white w-full">
      <div className="flex justify-between bg-blue-pacific py-2 px-4 items-center">
        <div className="text-xl font-medium">{title}</div>
        <div className="relative">
          <Button theme="link" onClick={onClose}>
            <i className="fa fa-times text-white-white text-2xl" />
          </Button>
        </div>
      </div>
      <div className="text-lg my-3">{body}</div>
      <div className="w-100 text-right">{button}</div>
    </div>
  );
};

export default SearchPopoverContent;
