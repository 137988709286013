import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import stripZeros from '../../services/Util/stripZeros.util';
import { DeliveryResponse } from '../../types/DeliveryResponse.interface';
import { OrderOverviewRecord } from '../../types/OrderOverviewRecord.interface';
import ShipmentDetail from './ShipmentDetail';

interface IShipmentDetailProps {
  orderOverview: OrderOverviewRecord;
}

const OrderShipmentDetail: React.FunctionComponent<IShipmentDetailProps> = ({
  orderOverview,
}) => {
  const [deliveryInfo, setDeliveryInfo] = useState<DeliveryResponse[]>([]);

  useEffect(() => {
    setDeliveryInfo(orderOverview.deliveryInfo || []);
  }, [orderOverview.deliveryInfo]);
  return (
    <div className="bg-gray-whiteSmoke200 py-1 px-1 mb-2">
      <div className="border border-gray-light400 bg-white-white m-1 py-3 px-6 text-lg">
        <strong>
          <Trans id="order">Order</Trans>{' '}
          {stripZeros(orderOverview.documentNumber)}/
          {stripZeros(orderOverview.documentPosition)}
        </strong>
      </div>
      {deliveryInfo ? (
        deliveryInfo.map((item, idx) => (
          <ShipmentDetail
            key={item.transitId}
            deliveryInfo={item}
            index={idx}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default OrderShipmentDetail;
