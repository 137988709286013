export const prepareContactTelephone = (phone: string | undefined) => {
  if (phone && phone.length > 14) {
    return phone.replace(/[-/#+\s)(]/g, '');
  }
  return phone;
};

const parseOnSlash = (str: string) => str.split('/').join('');

export const getContactNotes1 = (
  contactName = '',
  phone = '',
  email = ''
): string =>
  `${parseOnSlash(contactName)}/${parseOnSlash(phone)}/${parseOnSlash(email)}`;

const getContactNotesBlob = (
  receiving = '',
  deliveryInstructions = '',
  dedicatedContactNotes = ''
) =>
  `${parseOnSlash(receiving)}/${parseOnSlash(
    deliveryInstructions
  )}/${parseOnSlash(dedicatedContactNotes)}`;

export const getContactNotes2 = (
  receiving = '',
  deliveryInstructions = '',
  dedicatedContactNotes = ''
): string => {
  const blob = getContactNotesBlob(
    receiving,
    deliveryInstructions,
    dedicatedContactNotes
  );
  return blob.substring(0, 72);
};

export const getContactNotes3 = (
  receiving = '',
  deliveryInstructions = '',
  dedicatedContactNotes = ''
): string => {
  const blob = getContactNotesBlob(
    receiving,
    deliveryInstructions,
    dedicatedContactNotes
  );
  return blob.substring(72, 144);
};

export const getContactNotes4 = (
  receiving = '',
  deliveryInstructions = '',
  dedicatedContactNotes = ''
): string => {
  const blob = getContactNotesBlob(
    receiving,
    deliveryInstructions,
    dedicatedContactNotes
  );
  return blob.substring(144, 216);
};
