import { t } from '@lingui/macro';
import useRole from '../../hooks/useRole';
import {
  getBaseWeightOptions,
  getEuBaseWeightOptions,
  QueryParams,
} from '../../services/Brand';
import { AuthorityRole } from '../../types/Authority.interface';
import Brand from '../../types/Brand.interface';
import EuBrand from '../../types/EuBrand.interface';
import Option from '../../types/Option.type';
import useBrandOptions from './useBrandOptions';

const useBasisWeightOptions = (
  params: Partial<QueryParams>,
  selectedBrand?: string,
  selectedCaliper?: string
): { basisWeightOptions: Option[] } => {
  const { hasRole } = useRole();
  const { rawBrands: brands } = useBrandOptions(params);
  const basisWeightOptions: Option[] = hasRole(AuthorityRole.ROLE_EU)
    ? getEuBaseWeightOptions(
        brands as EuBrand[],
        selectedBrand ? brands[+selectedBrand].brand : undefined,
        t`Basis Weight`,
        true
      )
    : getBaseWeightOptions(
        brands as Brand[],
        {
          defaultSelectLabel: t`Basis Weight`,
          selectedBrand,
          selectedCaliper,
        },
        false
      );
  return {
    basisWeightOptions,
  };
};

export default useBasisWeightOptions;
