import { t } from '@lingui/macro';
import Axios from 'axios';
import { AllContainerTypeResults } from './types/AllContainerTypeResults.interface';
import ContainerType from './types/ContainerType.enum';
import { DiameterRequestParams } from './types/DiameterRequestParams.interface';
import { DiameterResponse } from './types/DiameterResponse.interface';
import { PalletHeightResponse } from './types/PalletHeightResponse.interface';
import { PayloadBasketRequest } from './types/PayloadBasketRequest.interface';
import { PayloadBasketResponse } from './types/PayloadBasketResponse.interface';

export const getDiameters = async (
  params: DiameterRequestParams
): Promise<DiameterResponse> => {
  const { data } = await Axios.get('/portal/resources/eu/reelcalcdiam', {
    params,
  });
  return data;
};

export const getPalletHeight = async (
  country: string
): Promise<PalletHeightResponse> => {
  const { data } = await Axios.get(
    `/portal/resources/payload/pallet/${country}`
  );
  return data;
};

export const updateBasketInfo = async (
  params: PayloadBasketRequest
): Promise<PayloadBasketResponse> => {
  try {
    const { data } = await Axios.post(
      '/portal/resources/payload/basket',
      params
    );
    // Fail based on explicit and implied errors
    if (data.errorText) {
      console.info('Explicit error from SAP', data);
      Promise.reject(data.errorText);
    }
    if (!data.itemList[0]) {
      Promise.reject(t`No results returned.`);
    }
    return data;
  } catch (e) {
    return Promise.reject(e.response.data);
  }
};

export const calculatePayload = async (
  inputs: PayloadBasketRequest[],
  country: string,
  containerType: ContainerType
): Promise<PayloadBasketResponse> => {
  const { data } = await Axios.post('/portal/resources/payload', {
    containerType,
    finalCountry: country,
    inputs,
  });
  return data;
};

export const calculatePayloadForAllContainerTypes = async (
  inputs: PayloadBasketRequest[],
  country: string
): Promise<AllContainerTypeResults> => {
  const promises = [
    ContainerType.TYPE_20,
    ContainerType.TYPE_40,
    ContainerType.TYPE_40_HC,
  ].map((type) => calculatePayload(inputs, country, type));
  const result = await Promise.all(promises);
  return {
    [ContainerType.TYPE_20]: result[0],
    [ContainerType.TYPE_40]: result[1],
    [ContainerType.TYPE_40_HC]: result[2],
  };
};
