import { t } from '@lingui/macro';
import { forOwn } from 'lodash';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../hooks/useAnalytics';
import useUser from '../../../hooks/useUser';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';
import Criteria from './Criteria';
import { WeightConversion } from './WeightConversion.interface';
import { convertToEU, convertWeight } from './WeightConversionCalculator.util';
import WeightUnit from './WeightUnit.interface';

const WeightConversionCalculator: FC = () => {
  const {
    register,
    reset,
    errors,
    getValues,
    trigger,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<WeightConversion>({ mode: 'all' });
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();
  const [calculated, setCalculated] = useState(false);

  const onCalculate = (data: WeightConversion) => {
    forOwn(data, (value, key) => {
      if (value) {
        const convertedWeight = convertWeight(value, key as WeightUnit);
        if (user?.region === 'EU') {
          reset(convertToEU(convertedWeight));
        } else {
          reset(convertedWeight);
        }
      }
    });

    setCalculated(true);
    trackPageView('CALCULATORS', 'WEIGHT_CONVERT', {
      username: user?.username,
    });
  };

  const onReset = () => {
    reset({
      kg: '',
      lbs: '',
      metricTons: '',
      shortTons: '',
    });
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (calculated) {
      reset({
        [e.target.name]: e.target.value,
      });
      setCalculated(false);
    }
    setTimeout(() => trigger(), 0); // set timeout allows reset magic to happen
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.WEIGHT_CONVERSION}
      instructions={t`Specify one weight and calculate conversion`}
      onCalculate={handleSubmit(onCalculate)}
      isCalculateDisabled={!isValid}
      onReset={() => onReset()}
    >
      <Criteria
        errors={errors}
        watch={watch}
        register={register}
        getValues={getValues}
        calculated={calculated}
        onInputChange={onInputChange}
      />
    </CalculatorTemplate>
  );
};

export default WeightConversionCalculator;
