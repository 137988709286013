import Axios, { CancelToken } from 'axios';
import CancellablePromise from '../../types/CancellablePromise.interface';

export default function cancellableQuery<T, U>(
  key: string,
  query: (params: T, token: CancelToken) => CancellablePromise<U>,
  queryParams: T
): CancellablePromise<U> {
  const source = Axios.CancelToken.source();
  const promise = query(queryParams, source.token) as CancellablePromise<U>;
  promise.cancel = () => {
    source.cancel(`Query ${key} was cancelled by React Query`);
  };
  return promise;
}
