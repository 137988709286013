import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';

interface IBasketButtonsProps {
  updating?: boolean;
  onUpdateOrder: () => void;
}

const BasketButtons: React.FunctionComponent<IBasketButtonsProps> = ({
  updating,
  onUpdateOrder,
}) => {
  const history = useHistory();
  const { formState } = useFormContext();
  return (
    <div className="w-full text-right">
      <Space size="middle">
        <Button
          theme="primary"
          onClick={() => history.push('/samples/findProducts')}
          disabled={updating}
        >
          <Trans>Continue Shopping</Trans>
        </Button>
        {formState.isDirty && (
          <Button
            theme="primary"
            onClick={() => onUpdateOrder()}
            disabled={updating || !formState.isValid}
          >
            <Trans>Update Order</Trans>
          </Button>
        )}
        {!formState.isDirty && (
          <Button
            theme="primary"
            onClick={() => history.push('/samples/summary')}
            disabled={updating || !formState.isValid}
          >
            <Trans>Order Summary</Trans>
          </Button>
        )}
      </Space>
    </div>
  );
};

export default BasketButtons;
