import { t, Trans } from '@lingui/macro';
import moment from 'moment';
import * as React from 'react';
import DatePicker from '../../components/DatePicker/DatePicker';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import Option from '../../types/Option.type';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';
import { setRequestedWeekdayDefaultDate } from './util/setRequestedWeekdayDefaultDate.util';

interface IOtherCustomerInfoProps {
  deliveryLocationOptions: Option[];
  decisionMakerOptions: Option[];
  orderTypeOptions: Option[];
  selectedDeliveryLocation: string | undefined;
  selectedOrderType: string | undefined;
  selectedDecisionMaker: string | undefined;
  productOrderNumber: string | undefined;
  requestedDeliveryDate: number | undefined;
  onDeliveryLocationChange: (location: string) => void;
  onDecisionMakerChange: (decisionMaker: string) => void;
  onOrderTypeChange: (orderType: string) => void;
  onRequestedDateChange: (dateInMs: number) => void;
  onProductOrderNumberChange: (orderNumber: string) => void;
}

const OtherCustomerInfo: React.FunctionComponent<IOtherCustomerInfoProps> = ({
  selectedDeliveryLocation,
  selectedDecisionMaker,
  selectedOrderType,
  productOrderNumber,
  requestedDeliveryDate,
  deliveryLocationOptions,
  decisionMakerOptions,
  onDeliveryLocationChange,
  onDecisionMakerChange,
  onOrderTypeChange,
  onRequestedDateChange,
  onProductOrderNumberChange,
}) => {
  const getFirstDeliveryLocationLabel = () => {
    if (deliveryLocationOptions && deliveryLocationOptions.length === 1) {
      return deliveryLocationOptions[0].label;
    }
    return '';
  };
  const getFirstDecisionMakerLabel = () => {
    if (decisionMakerOptions && decisionMakerOptions.length === 1) {
      return decisionMakerOptions[0].label;
    }
    return '';
  };
  const { hasRole } = useRole();
  const orderTypeOptions = [
    hasRole(AuthorityRole.ROLE_RELEASE) ? { value: 'ZOR', label: t`Order General` } : { value: 'ZTA', label: t`Order General` },
    { value: 'ZKB', label: t`Replenish Consign` },
  ];
  const getFirstOrderTypeLabel = () => {
    if (orderTypeOptions && orderTypeOptions.length === 1) {
      return orderTypeOptions[0].label;
    }
    return '';
  };

  return (
    <div className="mt-3 p-5 bg-gray-whiteSmoke200 rounded-sm">
      <div className="flex items-start">
        <div>
          <div className="flex items-center h-10">
            <div className="w-48">
              <strong>
                <Trans>Delivery Location:</Trans>
              </strong>
            </div>
            <div>
              {deliveryLocationOptions.length > 1 ? (
                <Select
                  className="text-black-black"
                  value={selectedDeliveryLocation}
                  options={deliveryLocationOptions}
                  onChange={(e) => onDeliveryLocationChange(e.target.value)}
                />
              ) : (
                <strong data-test-id="qob-delivery-location">
                  {getFirstDeliveryLocationLabel()}
                </strong>
              )}
            </div>
          </div>
          {hasRole(AuthorityRole.ROLE_EU) && (
            <div className="flex items-center mt-5">
              <div className="w-48">
                <strong>
                  <Trans>Requested Delivery Date:</Trans>
                </strong>
              </div>
              <div>
                <DatePicker
                  data-test-id="qob-req-delivery-date-input"
                  value={
                    requestedDeliveryDate
                      ? moment(requestedDeliveryDate)
                      : setRequestedWeekdayDefaultDate(hasRole(AuthorityRole.ROLE_EU))
                  }
                  onChange={(momentDate, dateStr) => {
                    if (momentDate) {
                      onRequestedDateChange(momentDate.valueOf());
                    } else {
                      onRequestedDateChange(setRequestedWeekdayDefaultDate(hasRole(AuthorityRole.ROLE_EU)).valueOf());
                    }
                  }}
                  disabledDate={(date) => date.isBefore(moment())}
                />
              </div>
            </div>
          )}
          {hasRole(AuthorityRole.ROLE_NA) && (
            <div className="flex items-center mt-5">
              <div className="w-48">
                <strong>
                  <Trans>Requested Delivery Date:</Trans>
                </strong>
              </div>
              <div>
                <DatePicker
                  data-test-id="qob-req-delivery-date-input"
                  value={
                    requestedDeliveryDate
                      ? moment(requestedDeliveryDate)
                      : undefined
                  }
                  onChange={(momentDate, dateStr) => {
                    if (momentDate) {
                      onRequestedDateChange(momentDate.valueOf());
                    } else {
                      onRequestedDateChange(moment().add(1, 'days').valueOf());
                    }
                  }}
                  disabledDate={(date) => date.isBefore(moment())}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <div className="flex items-center ml-20 h-10">
            <div className="w-40">
              <strong>
                <Trans>Purchase Order #:</Trans>
              </strong>
            </div>
            <div>
              <Input
                name="productOrderNum"
                maxLength={20}
                value={productOrderNumber}
                onChange={(e) => onProductOrderNumberChange(e.target.value)}
                data-test-id="qob-po-number-input"
              />
            </div>
          </div>
          <div className="flex items-center mt-5 ml-20">
            <div className="w-40">
              <strong>
                <Trans>Order Type:</Trans>
              </strong>
            </div>
            <div>
              {orderTypeOptions.length > 1 ? (
                <Select
                  disabled={!hasRole(AuthorityRole.ROLE_CONSIGNMENT)}
                  width="max"
                  className="w-40"
                  value={hasRole(AuthorityRole.ROLE_RELEASE) ? 'ZOR' : selectedOrderType}
                  options={orderTypeOptions}
                  onChange={(e) => onOrderTypeChange(e.target.value)}
                />
              ) : (
                <strong>{getFirstOrderTypeLabel()}</strong>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherCustomerInfo;
