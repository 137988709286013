import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { Button } from '../../../../components/Button/Button';
import LabelValue from '../../../../components/LabelValue/LabelValue';

interface IHeaderProps {
  customerName: string;
  date: React.ReactNode;
  onBackToSearch: () => void;
  onPrintConsumption: () => void;
}

const Header: React.FunctionComponent<IHeaderProps> = ({
  customerName,
  date,
  onBackToSearch,
  onPrintConsumption,
}) => {
  return (
    <div className="flex justify-between pb-10 border-b border-gray-light400">
      <Space direction="vertical" size="middle">
        <Button theme="primary" onClick={() => onBackToSearch()}>
          <Trans>Back to Search</Trans>
        </Button>
        <LabelValue inline label={t`Customer Name`} value={customerName} />
        <LabelValue inline label={t`Date`} value={date} />
      </Space>
      <div>
        <Button onClick={() => onPrintConsumption()}>
          <Trans>Print Confirmation</Trans>
        </Button>
      </div>
    </div>
  );
};

export default Header;
