import { filter, find, forOwn } from 'lodash';
import { t } from '@lingui/macro';
import PutUpCategory from '../../types/PutUpCategory.enum';
import Batch from '../../types/Batch.interface';
import ComplaintsReasons from '../../types/ComplaintsReasons.interface';
import { Order } from '../../types/Order.interface';
import ComplaintReason from '../../types/ComplaintReason.interface';
import Option from '../../types/Option.type';
import { ClaimTypes } from '../../types/ClaimTypes.enum';
import ComplaintRecord from '../../types/ComplaintRecord.interface';
import ComplaintComment from '../../types/ComplaintComment.interface';

export const getComplaintsReasonsOptions = (
  complaintsReasons?: ComplaintReason[]
): Option[] => {
  const options: Option[] = [
    {
      value: '',
      label: t`Problem`,
    },
  ];
  complaintsReasons?.forEach((c) => {
    if (!find(options, { value: c.problemCodeID })) {
      options.push({
        value: c.problemCodeID,
        label: c.problemDescription,
      });
    }
  });
  return options;
};

export const getComplaintReasons = (
  complaintsReasons?: ComplaintReason[],
  claimType?: ClaimTypes
): ComplaintReason[] => {
  let filteredComplaintsReasons;

  if (claimType === ClaimTypes.QUALITY) {
    
    filteredComplaintsReasons = filter(
      complaintsReasons,
      (complaintReason) => complaintReason.claimType.toUpperCase() === ClaimTypes.QUALITY
    );
  }

  if (claimType === ClaimTypes.TRANSIT) {
    
    filteredComplaintsReasons = filter(
      complaintsReasons,
      (complaintReason) => complaintReason.claimType.toUpperCase() === ClaimTypes.TRANSIT
    );
  }

  return filteredComplaintsReasons || complaintsReasons || [];
};

export const getSkuCode = (batch: Batch): string => {
  return batch.itemId ? batch.itemId : batch.ifraCode;
};

// NA orders' distChannel will ALWAYS be IM, IS, or UC
export const isNAOrder = (order: Order | undefined): boolean => {
  return (
    order?.overview.distChannel !== '' &&
    ['IM', 'IS', 'UC'].includes(order?.overview.distChannel || '')
  );
};

export const getErrorMessage = (result?: ComplaintRecord): string => {
  let resultMsg = '';
  result?.complaintResult?.forEach((complaintResult) => {
    resultMsg += complaintResult.message;
  });
  return resultMsg;
};

export const buildComplaintComments = (
  complaintItems: string[],
  comment: string
): ComplaintComment[] => {
  const complaintComments: ComplaintComment[] = [];
  for (let i = 0, charsLength = comment.length; i < charsLength; i += 132) {
    const complaintComment: ComplaintComment = {
      comment: comment.substring(i, i + 132),
    };
    complaintComments.push(complaintComment);
  }

  complaintItems.forEach((complaintItem) => {
    if (complaintItem.indexOf('SW') === -1) {
      const complaintIFRA = {
        comment: ` IFRA Code Reference: ${complaintItem}`,
      };
      complaintComments.push(complaintIFRA);
    }
  });

  return complaintComments;
};

export const getFilteredComplaintsReasons = (
  isNaUser: boolean,
  isReleaseEuUser: boolean,
  complaintsReasons?: ComplaintsReasons,
  order?: Order
): ComplaintReason[] => {
  if (isNaUser || isReleaseEuUser) {
    return complaintsReasons?.complaintReasons || [];
  }

  const filteredComplaintReasons = filter(
    complaintsReasons?.complaintReasons,
    (complaintReason) =>
      complaintReason.productType ===
      (order?.overview.putup === 1 ? PutUpCategory.ROLL : PutUpCategory.SHEET) ||
      complaintReason.productType === "*"
  );
  return filteredComplaintReasons;
};

export const getEvidenceDescriptions = (
  reason?: ComplaintReason,
  complaintsReasons?: ComplaintsReasons
): string[] => {
  const evidenceDescs: string[] = [];

  const evidenceRecords = complaintsReasons?.complaintReasons.filter(r => {
    return r.problemCode === reason?.problemCode;
  })

  evidenceRecords?.forEach((e) => {
    evidenceDescs.push(e.evidenceDescription);
  });

  return evidenceDescs;
};

export const assignComplaintItemsPerItem = (
  complaintItems: string[],
  batch: Batch,
  itemSelected: boolean
): string[] => {
  if (itemSelected) {
    return [...complaintItems, getSkuCode(batch)];
  }
  const index = complaintItems.indexOf(getSkuCode(batch) || '', 0);
  const filteredState =
    index > -1 ? complaintItems.splice(index, 1) : complaintItems;
  return [...filteredState];
};

export const showPlannedDate = (
  isEuUser: boolean,
  reason: ComplaintReason | undefined,
  order: Order | undefined
): boolean => {
  // SAPPIM-294 Added logic to require a planned and achieved delivery date whenever the delivery popup flag is set. DJM
  return (
    isEuUser &&
    reason?.delivery_Popup === 'X' &&
    (order?.overview?.deliveryDate !== undefined ||
      order?.deliveryDate !== undefined)
  );
};
