enum QueryCacheName {
  ALT_ORDER_BOOKING_PRODUCTS = 'ALT_ORDER_BOOKING_PRODUCTS',
  BASKET = 'BASKET',
  BASKET_ORDER_BOOKING = 'BASKET_ORDER_BOOKING',
  BASKET_STOCK_LOT = 'BASKET_STOCK_LOT',
  BATCH = 'BATCH',
  BRANDS = 'BRANDS',
  CALC_COUNTRIES = 'CALC_COUNTRIES',
  CALC_DIAMETERS = 'CALC_DIAMETERS',
  LIVE_AGENT_CHAT_CONFIG = 'LIVE_AGENT_CHAT_CONFIG',
  COMPLAINTS = 'COMPLAINTS',
  COMPLAINTS_ATTACHMENTS = 'COMPLAINTS_ATTACHMENTS',
  CONSIGNMENT_INVENTORY = 'CONSIGNMENT_INVENTORY',
  COMPLAINTS_REASONS = 'COMPLAINTS_REASONS',
  CONSIGNMENT_INVENTORY_PO_REQUIRED = 'CONSIGNMENT_INVENTORY_PO_REQUIRED',
  COUNTRY_OF_REQUEST = 'COUNTRY_OF_REQUEST',
  COURIERS = 'COURIERS',
  DOWNLOAD_DOCS_CONTENT = 'DOWNLOAD_DOCS_CONTENT',
  DUMMY_SAMPLES_ORDER = 'DUMMY_SAMPLES_ORDER',
  EXISTING_COMPLAINT = 'EXISTING_COMPLAINT',
  EU_BRANDS = 'EU_BRANDS',
  EQ_AVAILABLE_BRANDS = 'EQ_AVAILABLE_BRANDS',
  EQ_CALCULATED_SAVINGS = 'EQ_CALCULATED_SAVINGS',
  EQ_UI_ELEMENT_BY_NAME = 'EQ_UI_ELEMENT_BY_NAME',
  EQ_UI_ELEMENTS_BY_TYPE = 'EQ_UI_ELEMENTS_BY_TYPE',
  FILTER = 'FILTER',
  FINANCIAL_SUMMARY = 'FINANCIAL_SUMMARY',
  FINANCIAL_INVOICES = 'FINANCIAL_INVOICES',
  HELP = 'HELP',
  INVOICE_INFO = 'INVOICE_INFO',
  LANDER_CONTENT = 'LANDER_CONTENT',
  LOCALE = 'LOCALE',
  LOCALE_COUNTRY = 'LOCALE_COUNTRY',
  LOGIN_HELP = 'LOGIN_HELP',
  LOGIN_NOTIFICATION = 'LOGIN_NOTIFICATION',
  MARKED_ORDERS = 'MARKED_ORDERS',
  MEASUREMENT_SYSTEM = 'MEASUREMENT_SYSTEM',
  MENU_PROPERTIES = 'MENU_PROPERTIES',
  MY_CONTACT = 'MY_CONTACT',
  MY_SAVED_ENQUIRIES = 'MY_SAVED_ENQUIRIES',
  NEWS_AND_NOTIFICATIONS = 'NEWS_AND_NOTIFICATIONS',
  NEWS_ITEM = 'NEWS_ITEM',
  OPEN_ORDERS = 'OPEN_ORDERS',
  ORDER_DETAILS = 'ORDER_DETAILS',
  ORDERS = 'ORDERS',
  PARTNER_SEARCH_CUSTOMER = 'PARTNER_SEARCH_CUSTOMER',
  PRINT_LABELS = 'PRINT_LABELS',
  PRODUCTS = 'PRODUCTS',
  PROPERTIES = 'PROPERTIES',
  USER = 'USER',
  USER_CUSTOMIZATION = 'USER_CUSTOMIZATION',
  ORDER_BOOKING_PRODUCTS = 'ORDER_BOOKING_PRODUCTS',
  ORDER_BOOKING_STOCKS = 'ORDER_BOOKING_STOCKS',
  PRINT_DOCUMENTS = 'PRINT_DOCUMENTS',
  PRODUCT_AVAILABILITY = 'PRODUCT_AVAILABILITY',
  PRODUCT_AVAILABILITY_ALT = 'PRODUCT_AVAILABILITY_ALT',
  PRODUCT_AVAILABILITY_SKU = 'PRODUCTS_AVAILABILITY_SKU',
  PROMO_CONTENT = 'PROMO_CONTENT',
  SAVED_SKUS = 'SAVED_SKUS',
  SAMPLES_ORDER = 'SAMPLES_ORDER',
  SAMPLES_ORDER_DELIVERY_COST_DETAILS = 'SAMPLES_ORDER_DELIVERY_COST_DETAILS',
  SAMPLES_ORDERS_HISTORY = 'SAMPLES_ORDERS_HISTORY',
  SAMPLES_PARAMETERS = 'SAMPLES_PARAMETERS',
  SAMPLES_DUMMY_TEXT_PARAMETERS = 'SAMPLES_DUMMY_TEXT_PARAMETERS',
  SAMPLES_DUMMY_ADDTL_PARAMETERS = 'SAMPLES_DUMMY_ADDTL_PARAMETERS',
  SAMPLES_DUMMY_COVER_OPTIONS = 'SAMPLES_DUMMY_COVER_OPTIONS',
  SAMPLES_PRODUCTS = 'SAMPLES_PRODUCTS',
  SAMPLES_SHIP_TO_CUSTOMERS = 'SAMPLES_SHIP_TO_CUSTOMERS',
  SAMPLES_SELECT_BUYER = 'SAMPLES_SELECT_BUYER',
  SHIP_TO_LOCATIONS = 'SHIP_TO_LOCATIONS',
  STOCK_LOT_DIVISIONS = 'STOCK_LOT_DIVISIONS',
  STOCK_LOT_FIELD_VALUES = 'STOCK_LOT_FIELD_VALUES',
  STOCK_LOT_GRADES = 'STOCK_LOT_GRADES',
  STOCK_LOT_GRAMMAGES = 'STOCK_LOT_GRAMMAGES',
  STOCK_LOT_SHIP_TO_CUSTOMERS = 'STOCK_LOT_SHIP_TO_CUSTOMERS',
  STOCK_LOT_SHIP_TOS = 'STOCK_LOT_SHIP_TOS',
  STOCK_LOT_HARBORS = 'STOCK_LOT_HARBORS',
  TRACK_AND_TRACE_LOGS = 'TRACK_AND_TACE_LOGS',
  TRACK_AND_TRACE_ERROR = 'TRACK_AND_TRACE_ERROR',
  TRADE_BASIS = 'TRADE_BASIS',
  VALUES = 'VALUES',
}

export default QueryCacheName;
