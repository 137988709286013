import React, { FC } from 'react';
import NewsWidget from '../News/Widget/NewsWidget';

interface IPodOneProps {
  className?: string;
}
const PodOne: FC<IPodOneProps> = ({ className = '' }) => {
  return (
    <div className={`${className}`}>
      <NewsWidget />
    </div>
  );
};

export default PodOne;
