import { t } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import { NUMBER_NO_DECIMAL_REG_EX } from '../../../types/RegularExpression.constants';
import { METRIC_IMP_OPTIONS } from '../Calculators.util';
import ImperialCriteria from './ImperialCriteria';
import MetricCriteria from './MetricCriteria';
import { CalculatorUnitTypes } from '../types/CalculatorUnitTypes.enum';

interface ICriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  reset: UseFormMethods['reset'];
  onMetricImperialChange: (selectedValue: CalculatorUnitTypes) => void;
  isImperial: boolean;
}

const Criteria: React.FunctionComponent<ICriteriaProps> = ({
  register,
  isImperial,
  errors,
  reset,
  onMetricImperialChange,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Select
        name="metImp"
        ref={register({ required: true })}
        options={METRIC_IMP_OPTIONS}
        required
        onChange={(e) =>
          onMetricImperialChange(e.target.value as CalculatorUnitTypes)
        }
      />
      {!isImperial && <MetricCriteria register={register} errors={errors} />}
      {isImperial && <ImperialCriteria register={register} errors={errors} />}
      <Input
        name="sheetCount"
        type="number"
        ref={register({
          required: true,
          min: 1,
          max: 999999999,
          pattern: NUMBER_NO_DECIMAL_REG_EX,
        })}
        required
        error={errors?.sheetCount}
        placeholder={t`Sheet Count`}
      />
    </Space>
  );
};

export default Criteria;
