import create from 'zustand';
import { FileClaimQuery } from './types/FileClaimQuery.interface';

type FileClaimState = {
  storedQuery: FileClaimQuery | undefined;
  setStoredQuery: (storedQuery: FileClaimQuery) => void;
};

const useFileClaimStore = create<FileClaimState>((set) => ({
  storedQuery: undefined,
  setStoredQuery: (storedQuery: FileClaimQuery) => set(() => ({ storedQuery })),
}));

export default useFileClaimStore;
