import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import InputLabel from '../../../../components/InputLabel';
import Textarea from '../../../../components/Textarea/Textarea';
import { NaShipTo } from '../../../../types/NaShipTo.interface';
import { DeliveryDetailsForm } from './types/DeliveryDetailsForm.type';
import DeliveryDetailsFormField from './types/DeliveryDetailsFormFields.enum';

interface IDeliveryInstructionsProps {
  register: UseFormMethods<DeliveryDetailsForm>['register'];
  selectedShipToCustomer: NaShipTo | undefined;
}

const DeliveryInstructions: FC<IDeliveryInstructionsProps> = ({
  register,
  selectedShipToCustomer,
}) => {
  return (
    <Space direction="vertical">
      {selectedShipToCustomer && (
        <InputLabel text={t`Delivery Instructions Already on File`} size="lg">
          <div>
            {selectedShipToCustomer.deliveryInstructionsLine1 && (
              <div>{selectedShipToCustomer.deliveryInstructionsLine1}</div>
            )}
            {selectedShipToCustomer.deliveryInstructionsLine2 && (
              <div>{selectedShipToCustomer.deliveryInstructionsLine2}</div>
            )}
            {selectedShipToCustomer.deliveryInstructionsLine3 && (
              <div>{selectedShipToCustomer.deliveryInstructionsLine3}</div>
            )}
            {selectedShipToCustomer.deliveryInstructionsLine4 && (
              <div>{selectedShipToCustomer.deliveryInstructionsLine4}</div>
            )}
          </div>
        </InputLabel>
      )}
      <InputLabel text={t`Additional Delivery Instructions`} size="lg">
        <Textarea
          name={DeliveryDetailsFormField.DELIVERY_INSTRUCTIONS}
          ref={register({ maxLength: 107 })}
          maxLength={107}
          style={{ height: '200px' }}
        />
      </InputLabel>
    </Space>
  );
};

export default DeliveryInstructions;
