import { Trans } from '@lingui/macro';
import moment from 'moment';
import React, { FC } from 'react';
import { DEFAULT_EU_FORMAT } from '../../../components/DatePicker/DatePicker';

const TodayErrorMessage: FC = () => {
  const TODAY = moment();
  return (
    <span className="font-xs">
      <Trans>Date must be after</Trans> {TODAY.format(DEFAULT_EU_FORMAT)}
    </span>
  );
};

export default TodayErrorMessage;
