import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import ViewSampleOrdersTableRowType from '../../../../types/Samples/ViewSampleOrdersTableRowType.enum';
import { SAMPLES_HISTORY_ORDER_LOCKED } from '../ViewSampleOrders.util';
import useSamplesOrderStore from '../../useSamplesOrderStore.store';

interface IActionsCellProps {
  order: AugmentedSamplesHistoryOrder;
  rowType: ViewSampleOrdersTableRowType;
  onEditClick: () => void;
  onRemoveClick: () => void;
}

const ActionsCell: React.FunctionComponent<IActionsCellProps> = ({
  order,
  rowType,
  onEditClick,
  onRemoveClick,
}) => {
  const isSubitem = rowType === ViewSampleOrdersTableRowType.SUBITEM;
  const isGrouped = rowType === ViewSampleOrdersTableRowType.GROUPED;
  const orderIsLocked = !!(order.orderStatus === SAMPLES_HISTORY_ORDER_LOCKED);
  const history = useHistory();

  return (
    <DeconstructedTableBodyCell className="actions-cell">
      <Space direction="vertical" className="w-full">
        {!isSubitem && !orderIsLocked && (
          <Button
            theme="primary"
            width="full"
            onClick={() => {
              useSamplesOrderStore.setState({
                ...order,
                isAddItemToExistingOrder: true,
              });

              history.push('/samples/findProducts');
            }}
            style={{ maxWidth: '155px' }}
          >
            <Trans>Add</Trans>
          </Button>
        )}
        {!isGrouped && !orderIsLocked && (
          <>
            <Button
              width="full"
              onClick={onEditClick}
              style={{ maxWidth: '155px' }}
            >
              <Trans>Edit</Trans>
            </Button>
            <Button
              width="full"
              theme="danger"
              onClick={onRemoveClick}
              style={{ maxWidth: '155px' }}
            >
              <Trans>Remove</Trans>
            </Button>
          </>
        )}
      </Space>
    </DeconstructedTableBodyCell>
  );
};

export default ActionsCell;
