import { QueryResult, useQuery } from 'react-query';
import { getUserCustomization } from '../services/User';
import QueryCacheName from '../types/QueryCacheName.enum';
import { UserCustomization } from '../types/User/UserCustomization.interface';

const useUserCustomization = (): QueryResult<UserCustomization> => {
  return useQuery(
    [QueryCacheName.USER_CUSTOMIZATION],
    async () => getUserCustomization(),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};

export default useUserCustomization;
