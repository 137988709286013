import Axios from 'axios';
import { Exceptions } from '../../types/Exceptions.interface';
import { ConsignmentResults } from './types/ConsignmentResults.interface';
import { ConsumeRequest } from './types/ConsumeRequest.interface';

export const isPoRequiredForCustomer = async (
  customerNumber: string
): Promise<boolean> => {
  const { data } = await Axios.get('/portal/resources/porequired', {
    params: {
      customers: customerNumber,
    },
  });
  return data ? data[Object.keys(data)[0]] : false;
};

export const getConsignmentErrorById = async (
  id: string
): Promise<Exceptions> => {
  const { data } = await Axios.get(`/portal/resources/consignment/error/${id}`);
  return data;
};

export const getConsignmentResults = async (): Promise<ConsignmentResults> => {
  const { data } = await Axios.get('/portal/resources/consignment/results');
  return data;
};

export const consumeConsignment = async (
  request: ConsumeRequest
): Promise<{ idocNumber: string }> => {
  const { data } = await Axios.put(
    '/portal/resources/consignment/order',
    request
  );
  return data;
};

export const consumeConsignmentsForMultiplePOs = async (
  requests: ConsumeRequest[]
): Promise<{ idocNumber: string }[]> =>
  Promise.all(requests.map(consumeConsignment));

export const CONSUMED_INPUT_PREFIX = 'consumed-';
