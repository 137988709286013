import * as React from 'react';
import { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { useForm } from 'react-hook-form';
import { Radio, Space } from 'antd';
import { find } from 'lodash';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import Legend from '../../components/Legend/Legend';
import { ClaimTypes } from '../../types/ClaimTypes.enum';
import Checkbox from '../../components/Checkbox/Checkbox';
import Batch from '../../types/Batch.interface';
import NonPaperValue from '../../types/NonPaperValue.interface';
import NonPaperValueEntry from '../../types/NonPaperValueEntry';
import NonPaperItem from './NonPaperItem';
import ComplaintsReasons from '../../types/ComplaintsReasons.interface';
import useUser from '../../hooks/useUser';
import useRole from '../../hooks/useRole';
import useLanguage from '../../hooks/useLanguage';
import Region from '../../types/Region.enum';
import { Order } from '../../types/Order.interface';
import ComplaintReason from '../../types/ComplaintReason.interface';
import Select from '../../components/Select/Select';
import {
  buildComplaintComments,
  getComplaintReasons,
  getComplaintsReasonsOptions,
  getSkuCode,
  getFilteredComplaintsReasons,
  getEvidenceDescriptions,
  getErrorMessage,
  assignComplaintItemsPerItem,
} from './FileClaim.util';
import Textarea from '../../components/Textarea/Textarea';
import { Button } from '../../components/Button/Button';
import { submitComplaint } from '../../services/Complaints';
import ComplaintRecord from '../../types/ComplaintRecord.interface';
import useAnalytics from '../../hooks/useAnalytics';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { InvoiceResponse } from '../../types/InvoiceResponse.interface';
import useFileClaimStore from './useFileClaim.store';
import FileClaimFormDescriptions from './FileClaimFormDescriptions';
import FileClaimFormClaimValues from './FileClaimFormClaimValues';
import FileClaimFormPlannedDeliveryDates from './FileClaimFormPlannedDeliveryDates';
import { METRIC_NUMBER_THREE_REG_EX } from '../../types/RegularExpression.constants';
import { getUnitOfMeasuresForProductTypeAlt } from '../../services/Product';
import PutUpCategory from '../../types/PutUpCategory.enum';

interface IFileClaimFormProps {
  order?: Order;
  batches?: Batch[];
  nonPaperItems?: NonPaperValue[];
  complaintsReasons?: ComplaintsReasons;
  invoiceResponse?: InvoiceResponse;
  onSubmitting: (isSubmitting: boolean) => void;
  onErrorMessage: (errorMessage: string) => void;
}
const FileClaimForm: React.FunctionComponent<IFileClaimFormProps> = ({
  order,
  batches,
  nonPaperItems,
  complaintsReasons,
  invoiceResponse,
  onSubmitting,
  onErrorMessage,
}) => {
  const { data: user } = useUser();
  const lang = useLanguage();
  const { isReleaseEuUser } = useRole();
  const isNaUser = user?.region === Region.NA;
  const isEuUser = user?.region === Region.EU;
  const history = useHistory();
  const { trackPageView } = useAnalytics();
  const { storedQuery: fileClaimQuery, setStoredQuery } = useFileClaimStore();

  const formMethods = useForm({
    mode: 'all',
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
    getValues,
    setValue,
    watch,
    trigger,
  } = formMethods;

  const comment = watch('comment');
  const claimValue = watch('claimValue');
  const additionalClaimValue = watch('additionalClaimValue');
  const replacementOrderQty = watch('replacementOrderQty');
  const [filteredComplaintsReasons, setFilteredComplaintsReasons] = useState<
    ComplaintReason[]
  >();
  const [chosenClaimType, setChosenClaimType] = useState<ClaimTypes>(
    fileClaimQuery ? fileClaimQuery.claimType : ClaimTypes.EMPTY
  );
  const [complaintsReason, setComplaintsReason] = useState<string>('');
  const [plannedDate, setPlannedDate] = useState<Moment | null>(
    moment(order?.overview.deliveryDate || order?.deliveryDate)
  );
  const [achievedDate, setAchievedDate] = useState<Moment | null>(null);
  const [applyDeliveryDate, setApplyDeliveryDate] = useState<boolean>(false);
  const [evidence, setEvidence] = useState<boolean>(
    fileClaimQuery ? fileClaimQuery.evidence : true
  );
  const [replacementOrderNeeded, setReplacementOrderNeeded] = useState<boolean>(false);
  const [replacementOrderQtyUom, setReplacementOrderQtyUom] = useState<string>('');
  const [defaultEvidence, setDefaultEvidence] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [claimTypeUpdated, setClaimTypeUpdated] = useState<boolean>(false);
  const [selectAllFlag, setSelectAllFlag] = useState<boolean>(
    fileClaimQuery ? fileClaimQuery.selectAllFlag : false
  );
  const [evidenceDescriptions, setEvidenceDescriptions] = useState<string[]>(
    []
  );
  const [complaintItems, setComplaintItems] = useState<string[]>([]);
  const [nonPaperValueEntries, setNonPaperValueEntries] = useState<NonPaperValueEntry[]>([{id: 0, npItem: "", npValue: "", npQuantity: ""}]);
  // const [nonPaperTotalValue, setTotalNonPaperValue] = useState<number>(0);
  const isFormUnsubmittable = isDirty && !isValid;

  const isNeededDataNotValid =
    chosenClaimType === ClaimTypes.EMPTY ||
    watch('complaintsReason') === '' ||
    (chosenClaimType === ClaimTypes.TRANSIT && achievedDate === null) ||
    (replacementOrderNeeded && (replacementOrderQtyUom === '' || replacementOrderQtyUom === 'Select'));

  // This to assign stored claim information when page re-loaded
  useEffect(() => {
    if (fileClaimQuery) {
      setValue(
        'customerReferenceNumber',
        fileClaimQuery.customerReferenceNumber
      );
      setValue('selectAll', fileClaimQuery.selectAllFlag);
      setValue('comment', fileClaimQuery.comment);
      setValue('claimValue', fileClaimQuery.claimValue);
      setValue('additionalClaimValue', fileClaimQuery.additionalClaimValue);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  // This to assign stored complaintsReason till filteredComplaintsReasons is loaded
  useEffect(() => {
    if (fileClaimQuery && filteredComplaintsReasons && !claimTypeUpdated) {
      setValue('complaintsReason', fileClaimQuery.complaintsReason); // Set dropdown value for complaintsReason
      setComplaintsReason(fileClaimQuery.complaintsReason); // set complaintsReason to trigger the update of planned and achieved delivery dates in useEffect
      setAchievedDate(fileClaimQuery.achievedDate);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fileClaimQuery, filteredComplaintsReasons]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // This useEffect is to transform complaintReasons in complaintsReasons for EU nor-release user
  useEffect(() => {
    if (complaintsReasons) {
      setFilteredComplaintsReasons(
        getFilteredComplaintsReasons(
          isNaUser,
          isReleaseEuUser,
          complaintsReasons,
          order
        )
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [complaintsReasons]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // This useEffect is to add/remove all checkBoxValue to complaintItems when selectAll checkBox checked/unchecked
  useEffect(() => {
    setComplaintItems([]);
    if (selectAllFlag) {
      batches?.forEach((batch) => {
        setComplaintItems((state) => {
          return [...state, getSkuCode(batch) || ''];
        });
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectAllFlag]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // This useEffect is to handle the changed complaints reason in complaintsReason selection
  // It will go through all properties of the complaints reason to display evidence descriptions
  // and enable the planned and achieved delivery date for EU user
  // when delivery_Popup property returned with 'X' flag
  useEffect(() => {
    if (filteredComplaintsReasons && complaintsReasons) {
      const reason = find(filteredComplaintsReasons, {
        problemCodeID: complaintsReason
      });
      setEvidenceDescriptions(
        getEvidenceDescriptions(reason, complaintsReasons)
      );
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [complaintsReason]);
  /* eslint-enable react-hooks/exhaustive-deps */

  // This useEffect is to handle the changed claim type.
  // It reset EvidenceDescriptions, and check and disable evidence button when the claim type is TRANSIT or QUALITY.
  useEffect(() => {
    setEvidenceDescriptions([]);

    setValue('complaintsReason', '');
    setAchievedDate(null);

    setDefaultEvidence(
      chosenClaimType === ClaimTypes.TRANSIT ||
        chosenClaimType === ClaimTypes.QUALITY
    );

    const loadedComplaintsReasons = getFilteredComplaintsReasons(
      isNaUser,
      isReleaseEuUser,
      complaintsReasons,
      order
    );
    setFilteredComplaintsReasons(
        getComplaintReasons(loadedComplaintsReasons, chosenClaimType)
    );

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [chosenClaimType]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const doReset = (): void => {
    reset();
    setComplaintItems([]);
    setEvidenceDescriptions([]);
    setChosenClaimType(ClaimTypes.EMPTY);
    setAchievedDate(null);
    setEvidence(true);
    setReplacementOrderNeeded(false);
    setReplacementOrderQtyUom('');
    setDefaultEvidence(false);
    setSelectAllFlag(false);

    setFilteredComplaintsReasons(
      getFilteredComplaintsReasons(
        isNaUser,
        isReleaseEuUser,
        complaintsReasons,
        order
      )
    );

    // setTimeout is to make sure complaintsReason can be reset after setFilteredComplaintsReasons
    setTimeout(() => {
      // To walk around the checkbox doesn't respond immediately after reset button just clicked
      setValue('batches', new Array(batches?.length).fill(false));
      setValue('complaintsReason', '');
      setComplaintsReason('');
      setValue('selectAll', false);
    }, 0);
  };

  const handleNpvAdd = () => {
    const i = nonPaperValueEntries.length;
    setNonPaperValueEntries([...nonPaperValueEntries, {id: i, npItem: "", npValue: "", npQuantity: ""}]);
  };

  const handleNpvRemove = (index : any) => {
    const entryList = [...nonPaperValueEntries];
    entryList.splice(index, 1);
    setNonPaperValueEntries(entryList);
  };

  const onNpValueChange = (value: any) => {
    setNonPaperValueEntries(value);
  };

  const onNpItemChange = (value: any) => {
    setNonPaperValueEntries(value);
  };

  const onNpQuantityChange = (value: any) => {
    setNonPaperValueEntries(value);
  };

  const handleSubmitComplaint = async (
    complaintRecord: ComplaintRecord
  ): Promise<void> => {
    if (complaintRecord) {
      trackPageView('TRACK_ORDERS', 'SUBMIT_COMPLAINT', {
        customerId: order?.customerNumber,
      });
      setIsSubmitting(true);
      onSubmitting(true);
      console.log("Calling submit complaint");
      const result: ComplaintRecord = await submitComplaint(complaintRecord);

      if (result.complaintNumber !== '') {
        const url = `/claim/${
          result.evidence === 'X' ? 'upload' : 'confirmation'
        }/${result.salesDocument}/${result.itemNumber}/${
          result.complaintNumber
        }/${result.evidence === 'X' ? '1' : '0'}`;
        history.push(url);
      } else {
        const errorMessage = getErrorMessage(result);
        console.error(`Complaint creation failed - ${errorMessage}`);
        onErrorMessage(errorMessage);
        setIsSubmitting(false);
        onSubmitting(false);
      }
    }
  };

  const doSubmit = async () => {
    const problemCodeArray = complaintsReason.split('|');
    const invoiceValue =  invoiceResponse?.invoices[0]?.value||'0';
    const complaintRecord: ComplaintRecord = {
      achievedDeliveryDate: achievedDate ? achievedDate?.format('YYYY-MM-DD') : undefined,
      additionalClaimCurrency:  invoiceValue?.slice(invoiceValue?.length - 3) || '',
      additionalClaimValue: additionalClaimValue ? +additionalClaimValue.replace(',', '.') : 0,
      claimType: '',
      claimValue: claimValue ? +claimValue.replace(',', '.') : 0,
      complaintComment: buildComplaintComments(complaintItems, comment),
      complaintItems,
      nonPaperValueEntries: 
        (isEuUser && chosenClaimType === ClaimTypes.TRANSIT) ||
        chosenClaimType === ClaimTypes.QUALITY
          ? nonPaperValueEntries : undefined,
      customerReferenceNumber:
        getValues('customerReferenceNumber') || order?.customerOrderNumber,
      evidence: evidence ? 'X' : '',
      itemNumber: order?.documentPosition || '',
      language: lang,
      plannedDeliveryDate:
        chosenClaimType === ClaimTypes.TRANSIT
          ? moment(order?.overview.deliveryDate || order?.deliveryDate).format(
              'YYYY-MM-DD'
            )
          : undefined,
      /* will be applied when Planned/Delivery data acceptable in SAP for Transit type */
      problemCodeGroup: problemCodeArray[0],
      problemCode: problemCodeArray[1],
      evidenceCode: '',
      evidenceDescription: '',
      salesDocument: order?.documentNumber || '',
      replacementOrderNeeded: replacementOrderNeeded ? 'X' : '',
      replacementOrderQty: replacementOrderNeeded ? replacementOrderQty : '0',
      replacementOrderQtyUom: replacementOrderQty ? replacementOrderQtyUom : '',
    };
    setStoredQuery({
      customerReferenceNumber:
        getValues('customerReferenceNumber') || order?.customerOrderNumber,
      claimType: chosenClaimType,
      selectAllFlag: getValues('selectAll'),
      complaintItems,
      nonPaperValueEntries,
      complaintsReason: getValues('complaintsReason'),
      achievedDate,
      comment,
      claimValue: getValues('claimValue'),
      additionalClaimValue: getValues('additionalClaimValue'),
      replacementOrderQty: getValues('replacementOrderQty'),
      evidence,
    });
    
    if (
      window.confirm(
        `${t`Please confirm that you are ready to submit your claim.
           You will not be able make changes to this form after it has been submitted.`}`
      )
    ) {
      await handleSubmitComplaint(complaintRecord);
    }
  };
  return (
    <>
      {isSubmitting ? (
        <LoadingSpinner horizontal />
      ) : (
        <form onSubmit={handleSubmit(doSubmit)}>
          <InputLabel
            size="lg"
            text={t`Customer Reference`}
            className="py-1"
            style={{ width: '500px' }}
          >
            <Input
              name="customerReferenceNumber"
              maxLength={20}
              ref={register({ maxLength: 20 })}
              width="full"
            />
          </InputLabel>
          <fieldset className="mt-3">
            <Legend size="lg" className="py-1" required>
              <Trans>Claim Type</Trans>
            </Legend>
            <Radio.Group
              className="flex mb-2"
              name="claimTypes"
              onChange={(e) => {
                setChosenClaimType(e.target.value);
                setClaimTypeUpdated(true);
              }}
              ref={register}
              value={chosenClaimType}
            >
              <Radio className="h-7 block text-lg" value={ClaimTypes.TRANSIT}>
                <Trans>Transit</Trans>
              </Radio>
              <Radio className="h-7 block text-lg" value={ClaimTypes.QUALITY}>
                <Trans>Quality</Trans>
              </Radio>
            </Radio.Group>
          </fieldset>
          <InputLabel
            size="lg"
            text={t`Affected Items`}
            className="pt-3 py-1"
            labelClassName="-mb-1"
          >
            <div className="flex-wrap text-lg flex" style={{ width: '500px' }}>
              <InputLabel text="" className="w-1/2">
                <Checkbox
                  label={t`Select All`}
                  ref={register()}
                  name="selectAll"
                  onChange={(e) => {
                    setSelectAllFlag(e.target.checked);
                    setValue(
                      'batches',
                      new Array(batches?.length).fill(e.target.checked)
                    );
                    trigger();
                  }}
                />
              </InputLabel>
            </div>

            <div className="flex-wrap text-lg flex" style={{ width: '500px' }}>
              {batches?.map((batch, index) => (
                <InputLabel
                  text=""
                  className="w-1/2"
                  key={`${batch.itemId || batch.ifraCode}`}
                >
                  <Checkbox
                    label={getSkuCode(batch) || ''}
                    ref={register()}
                    name={`batches[${index}]`}
                    defaultChecked={
                      selectAllFlag ||
                      fileClaimQuery?.complaintItems?.includes(
                        getSkuCode(batch)
                      )
                    }
                    key={`${batch.itemId || batch.ifraCode}Checkbox`}
                    onChange={(e) => {
                      // Deselect/Select a checkbox should remove Select All checkmark
                      setValue('selectAll', false);
                      setComplaintItems((state) =>
                        assignComplaintItemsPerItem(
                          state,
                          batch,
                          e.target.checked
                        )
                      );
                      trigger();
                    }}
                  />
                </InputLabel>
              ))}
            </div>
          </InputLabel>
          <InputLabel
            size="lg"
            text={t`Problem`}
            className="py-1 pt-5"
            style={{ width: '500px' }}
            required
          >
            <Select
              name="complaintsReason"
              options={getComplaintsReasonsOptions(filteredComplaintsReasons)}
              ref={register({
                required: true,
              })}
              required
              style={{ minWidth: '500px' }}
              onChange={(e) => {
                setAchievedDate(null);
                setComplaintsReason(e.target.value);
                trigger();
              }}
            />
          </InputLabel>
          <FileClaimFormPlannedDeliveryDates
            chosenClaimType={chosenClaimType}
            plannedDate={plannedDate}
            achievedDate={achievedDate}
            applyDeliveryDate={applyDeliveryDate}
            setAchievedDate={setAchievedDate}
          />
          <InputLabel
            size="lg"
            text={t`Problem Description`}
            className="py-1 pt-5"
            required
          >
            <div style={{ width: '500px' }}>
              <Textarea
                name="comment"
                rows={5}
                required
                width="full"
                placeholder={t`Problem Description`}
                ref={register({
                  required: true,
                  validate: {
                    required: (value) => value !== '',
                  },
                })}
              />
            </div>
          </InputLabel>
          {nonPaperValueEntries.map((entry, index) => (
            <div className="npv" key={`npv_${entry.id}`}>
              <div className="flex items-center" key={`npv_row_${entry.id}`}>
                <NonPaperItem
                  id={entry.id}
                  formMethods={formMethods}
                  nonPaperItems={nonPaperItems}
                  nonPaperValueEntries={nonPaperValueEntries}
                  chosenClaimType={chosenClaimType}
                  onValueChange={onNpValueChange}
                  onItemChange={onNpItemChange}
                  onQuantityChange={onNpQuantityChange}
                />
                <div className="pl-5 pt-7" key={`npv_remove_${entry.id}`}>
                  {(chosenClaimType === ClaimTypes.QUALITY) && 
                    (nonPaperValueEntries.length > 1) && 
                    (nonPaperValueEntries.length - 1 === index) && (
                    <Button
                      theme="link"
                      type='button'
                      className="text-lg font-bold"
                      onClick={() => handleNpvRemove(index)}
                    >
                      <Trans>Remove Item</Trans>
                    </Button>
                  )}
                </div>
              </div>
              <div className="pt-5" key={`npv_add_${entry.id}`}>
               {(chosenClaimType === ClaimTypes.QUALITY) && 
                  (nonPaperValueEntries.length - 1 === index) && (
                  <Button
                    theme="link"
                    type='button'
                    className="text-lg font-bold"
                    onClick={ () => handleNpvAdd()}
                  >
                    <Trans>Add Non Paper Item</Trans>
                  </Button>
                )}
              </div>
            </div>
          ))}
          <FileClaimFormClaimValues
            formMethods={formMethods}
            chosenClaimType={chosenClaimType}
          />
          <InputLabel
            text={t`Replacement Order Required?`}
            className="py-1 pt-5 font-bold text-lg"
            position="side"
            labelClassName="w-1/6 -mr-2"
          >
            <Checkbox
              label=""
              ref={register()}
              name="replacementOrderNeeded"
              onChange={(e) => {
                setReplacementOrderNeeded(e.target.checked);
                trigger();
              }}
              checked={replacementOrderNeeded}
            />
          </InputLabel>
          {replacementOrderNeeded && (
          <InputLabel
            size="lg"
            required
            text={t`Replacement Order Qty:`}
            className="py-1 pt-5"
            style={{ width: '500px' }}
          >
            <Input
              name="replacementOrderQty"
              width="full"
              ref={register({
                required: replacementOrderQty,
                pattern: METRIC_NUMBER_THREE_REG_EX,
              })}
              defaultValue="0"
              required
            />
          </InputLabel>
          )}
          {replacementOrderNeeded && order?.overview.putup && order?.overview.putup === 1 && (
          <InputLabel
          size="lg"
          required
          text={t`Replacement Order Unit of Measure:`}
          className="py-1 pt-5"
          style={{ width: '500px' }}
          >
            <Select
              required
              ref={register({
                required: true,
              })}
              options={getUnitOfMeasuresForProductTypeAlt(
                PutUpCategory.ROLL
              ).map((key) => ({
                label: key,
                value: key,
              }))}
              value={replacementOrderQtyUom}
              onChange={(e) => {
                setReplacementOrderQtyUom(e.target.value);
                trigger();
              }}
            />
          </InputLabel>
          )}
          {replacementOrderNeeded && order?.overview.putup && order?.overview.putup !== 1 && (
          <InputLabel
          size="lg"
          required
          text={t`Replacement Order Unit of Measure:`}
          className="py-1 pt-5"
          style={{ width: '500px' }}
          >
            <Select
              required
              ref={register({
                required: true,
              })}
              options={getUnitOfMeasuresForProductTypeAlt(
                PutUpCategory.SHEET
              ).map((key) => ({
                label: key,
                value: key,
              }))}
              value={replacementOrderQtyUom}
              onChange={(e) => {
                setReplacementOrderQtyUom(e.target.value);
                trigger();
              }}
            />
          </InputLabel>
          )}
          <InputLabel
            text={t`Have Evidence`}
            className="py-1 pt-5 font-bold text-lg"
            position="side"
            labelClassName="w-1/12 -mr-2"
          >
            <Checkbox
              label=""
              ref={register()}
              name="evidence"
              onChange={(e) => setEvidence(e.target.checked)}
              checked={evidence}
              disabled={defaultEvidence}
            />
          </InputLabel>
          <FileClaimFormDescriptions
            evidence={evidence}
            chosenClaimType={chosenClaimType}
            complaintsReason={complaintsReason}
            evidenceDescriptions={evidenceDescriptions}
          />
          {complaintsReason !== '' && (
            <div className="pt-4 flex-wrap" style={{ width: '500px' }}>
              <Trans>
                Please send all physical evidence samples to the following
                address:
              </Trans><br/>
                {order?.billToAddressLine1}<br/>
                {order?.billToAddressLine2}<br/>
                {order?.billToAddressLine3}<br/>
                {order?.billToAddressLine4}
            </div>
          )}
          <div className="mt-7">
            <Space size="middle">
              <Button
                type="submit"
                theme="primary"
                disabled={isFormUnsubmittable || isNeededDataNotValid}
              >
                <Trans>Submit Claim</Trans>
              </Button>
              <Button
                type="reset"
                theme="link"
                className="text-lg font-bold"
                onClick={doReset}
              >
                <Trans>Reset</Trans>
              </Button>
              <div className="ml-14">
                <Button
                  type="submit"
                  theme="inverse"
                  onClick={() => {
                    history.push(`/orderTracking`);
                  }}
                >
                  <Trans>Back to Order Tracking</Trans>
                </Button>
              </div>
            </Space>
          </div>
          {(isFormUnsubmittable || isNeededDataNotValid) && (
            <div className="py-1 pt-5 text-red-red">
              <Trans>Please fill in all mandatory fields.</Trans>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default FileClaimForm;
