import { t } from '@lingui/macro';
import React, { FC } from 'react';
import CalculatorId from '../../Calculators/types/CalculatorId.enum';
import CalculatorGroup from './CalculatorGroup';

const CALCULATORS: CalculatorId[] = [
  CalculatorId.PRICE_CWT,
  CalculatorId.PRICE_PER_TON,
];

const HeaderPriceCalculators: FC = () => {
  return (
    <CalculatorGroup
      calculators={CALCULATORS}
      pickerLabel={t`Price Calculator Type`}
    />
  );
};

export default HeaderPriceCalculators;
