import { t } from '@lingui/macro';

export const AFGHANISTAN = t`Afghanistan`;
export const ALAND_ISLANDS = t`Aland Islands`;
export const ALBANIA = t`Albania`;
export const ALGERIA = t`Algeria`;
export const ANDORRA = t`Andorra`;
export const ANGOLA = t`Angola`;
export const ANGUILLA = t`Anguilla`;
export const ANTARCTICA = t`Antarctica`;
export const ANTIGUA_AND_BARBUDA = t`Antigua and Barbuda`;
export const ARGENTINA = t`Argentina`;
export const ARMENIA = t`Armenia`;
export const ARUBA = t`Aruba`;
export const AUSTRALIA = t`Australia`;
export const AUSTRIA = t`Austria`;
export const AZERBAIJAN = t`Azerbaijan`;
export const BAHAMAS = t`Bahamas`;
export const BAHRAIN = t`Bahrain`;
export const BANGLADESH = t`Bangladesh`;
export const BARBADOS = t`Barbados`;
export const BELARUS = t`Belarus`;
export const BELGIUM = t`Belgium`;
export const BELIZE = t`Belize`;
export const BENIN = t`Benin`;
export const BERMUDA = t`Bermuda`;
export const BHUTAN = t`Bhutan`;
export const BOLIVIA_PLURINATIONAL_STATE_OF = t`Bolivia, Plurinational State of`;
export const BONAIRE_SINT_EUSTATIUS_AND_SABA = t`Bonaire, Sint Eustatius and Saba`;
export const BOSNIA_AND_HERZEGOVINA = t`Bosnia and Herzegovina`;
export const BOTSWANA = t`Botswana`;
export const BOUVET_ISLAND = t`Bouvet Island`;
export const BRAZIL = t`Brazil`;
export const BRITISH_INDIAN_OCEAN_TERRITORY = t`British Indian Ocean Territory`;
export const BRUNEI_DARUSSALAM = t`Brunei Darussalam`;
export const BULGARIA = t`Bulgaria`;
export const BURKINA_FASO = t`Burkina Faso`;
export const BURUNDI = t`Burundi`;
export const CAMBODIA = t`Cambodia`;
export const CAMEROON = t`Cameroon`;
export const CANADA = t`Canada`;
export const CAPE_VERDE = t`Cape Verde`;
export const CAYMAN_ISLANDS = t`Cayman Islands`;
export const CENTRAL_AFRICAN_REPUBLIC = t`Central African Republic`;
export const CHAD = t`Chad`;
export const CHILE = t`Chile`;
export const CHINA = t`China`;
export const CHRISTMAS_ISLAND = t`Christmas Island`;
export const COCOS = t`Cocos (Keeling) Islands`;
export const COLOMBIA = t`Colombia`;
export const COMOROS = t`Comoros`;
export const CONGO = t`Congo`;
export const CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE = t`Congo, the Democratic Republic of the`;
export const COOK_ISLANDS = t`Cook Islands`;
export const COSTA_RICA = t`Costa Rica`;
export const COTE_DIVOIRE = t`Cote d\'Ivoire`;
export const CROATIA = t`Croatia`;
export const CUBA = t`Cuba`;
export const CURATAO = t`Cura‡ao`;
export const CYPRUS = t`Cyprus`;
export const CZECH_REPUBLIC = t`Czech Republic`;
export const DENMARK = t`Denmark`;
export const DJIBOUTI = t`Djibouti`;
export const DOMINICA = t`Dominica`;
export const DOMINICAN_REPUBLIC = t`Dominican Republic`;
export const ECUADOR = t`Ecuador`;
export const EGYPT = t`Egypt`;
export const EL_SALVADOR = t`El Salvador`;
export const EQUATORIAL_GUINEA = t`Equatorial Guinea`;
export const ERITREA = t`Eritrea`;
export const ESTONIA = t`Estonia`;
export const ETHIOPIA = t`Ethiopia`;
export const FALKLAND_ISLANDS = t`Falkland Islands (Malvinas)`;
export const FAROE_ISLANDS = t`Faroe Islands`;
export const FIJI = t`Fiji`;
export const FINLAND = t`Finland`;
export const FRANCE = t`France`;
export const FRENCH_GUIANA = t`French Guiana`;
export const FRENCH_POLYNESIA = t`French Polynesia`;
export const FRENCH_SOUTHERN_TERRITORIES = t`French Southern Territories`;
export const GABON = t`Gabon`;
export const GAMBIA = t`Gambia`;
export const GEORGIA = t`Georgia`;
export const GERMANY = t`Germany`;
export const GHANA = t`Ghana`;
export const GIBRALTAR = t`Gibraltar`;
export const GREECE = t`Greece`;
export const GREENLAND = t`Greenland`;
export const GRENADA = t`Grenada`;
export const GUADELOUPE = t`Guadeloupe`;
export const GUATEMALA = t`Guatemala`;
export const GUERNSEY = t`Guernsey`;
export const GUINEA = t`Guinea`;
export const GUINEA_BISSAU = t`Guinea-Bissau`;
export const GUYANA = t`Guyana`;
export const HAITI = t`Haiti`;
export const HEARD_ISLAND_AND_MCDONALD_ISLANDS = t`Heard Island and McDonald Islands`;
export const HOLY_SEE = t`Holy See (Vatican City State)`;
export const HONDURAS = t`Honduras`;
export const HONG_KONG = t`Hong Kong`;
export const HUNGARY = t`Hungary`;
export const ICELAND = t`Iceland`;
export const INDIA = t`India`;
export const INDONESIA = t`Indonesia`;
export const IRAN_ISLAMIC_REPUBLIC_OF = t`Iran, Islamic Republic of`;
export const IRAQ = t`Iraq`;
export const IRELAND = t`Ireland`;
export const ISLE_OF_MAN = t`Isle of Man`;
export const ISRAEL = t`Israel`;
export const ITALY = t`Italy`;
export const JAMAICA = t`Jamaica`;
export const JAPAN = t`Japan`;
export const JERSEY = t`Jersey`;
export const JORDAN = t`Jordan`;
export const KAZAKHSTAN = t`Kazakhstan`;
export const KENYA = t`Kenya`;
export const KIRIBATI = t`Kiribati`;
export const KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF = t`Korea, Democratic People\'s Republic of`;
export const KOREA_REPUBLIC_OF = t`Korea, Republic of`;
export const KUWAIT = t`Kuwait`;
export const KYRGYZSTAN = t`Kyrgyzstan`;
export const LAO_PEOPLE_DEMOCRATIC_REPUBLIC = t`Lao People\'s Democratic Republic`;
export const LATVIA = t`Latvia`;
export const LEBANON = t`Lebanon`;
export const LESOTHO = t`Lesotho`;
export const LIBERIA = t`Liberia`;
export const LIBYA = t`Libya`;
export const LIECHTENSTEIN = t`Liechtenstein`;
export const LITHUANIA = t`Lithuania`;
export const LUXEMBOURG = t`Luxembourg`;
export const MACAO = t`Macao`;
export const MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF = t`Macedonia, the former Yugoslav Republic of`;
export const MADAGASCAR = t`Madagascar`;
export const MALAWI = t`Malawi`;
export const MALAYSIA = t`Malaysia`;
export const MALDIVES = t`Maldives`;
export const MALI = t`Mali`;
export const MALTA = t`Malta`;
export const MARTINIQUE = t`Martinique`;
export const MAURITANIA = t`Mauritania`;
export const MAURITIUS = t`Mauritius`;
export const MAYOTTE = t`Mayotte`;
export const MEXICO = t`Mexico`;
export const MOLDOVA_REPUBLIC_OF = t`Moldova, Republic of`;
export const MONACO = t`Monaco`;
export const MONGOLIA = t`Mongolia`;
export const MONTENEGRO = t`Montenegro`;
export const MONTSERRAT = t`Montserrat`;
export const MOROCCO = t`Morocco`;
export const MOZAMBIQUE = t`Mozambique`;
export const MYANMAR = t`Myanmar`;
export const NAMIBIA = t`Namibia`;
export const NAURU = t`Nauru`;
export const NEPAL = t`Nepal`;
export const NETHERLANDS = t`Netherlands`;
export const NEW_CALEDONIA = t`New Caledonia`;
export const NEW_ZEALAND = t`New Zealand`;
export const NICARAGUA = t`Nicaragua`;
export const NIGER = t`Niger`;
export const NIGERIA = t`Nigeria`;
export const NIUE = t`Niue`;
export const NORFOLK_ISLAND = t`Norfolk Island`;
export const NORWAY = t`Norway`;
export const OMAN = t`Oman`;
export const PAKISTAN = t`Pakistan`;
export const PALESTINE = t`Palestine`;
export const PANAMA = t`Panama`;
export const PAPUA_NEW_GUINEA = t`Papua New Guinea`;
export const PARAGUAY = t`Paraguay`;
export const PERU = t`Peru`;
export const PHILIPPINES = t`Philippines`;
export const PITCAIRN = t`Pitcairn`;
export const POLAND = t`Poland`;
export const PORTUGAL = t`Portugal`;
export const QATAR = t`Qatar`;
export const REUNION = t`Reunion`;
export const ROMANIA = t`Romania`;
export const RUSSIAN_FEDERATION = t`Russian Federation`;
export const RWANDA = t`Rwanda`;
export const SAINT_BARTH_LEMY = t`Saint Barth‚lemy`;
export const SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA = t`Saint Helena, Ascension and Tristan da Cunha`;
export const SAINT_KITTS_AND_NEVIS = t`Saint Kitts and Nevis`;
export const SAINT_LUCIA = t`Saint Lucia`;
export const SAINT_MARTIN_FRENCH = t`Saint Martin (French part)`;
export const SAINT_PIERRE_AND_MIQUELON = t`Saint Pierre and Miquelon`;
export const SAINT_VINCENT_AND_THE_GRENADINES = t`Saint Vincent and the Grenadines`;
export const SAMOA = t`Samoa`;
export const SAN_MARINO = t`San Marino`;
export const SAO_TOME_AND_PRINCIPE = t`Sao Tome and Principe`;
export const SAUDI_ARABIA = t`Saudi Arabia`;
export const SENEGAL = t`Senegal`;
export const SERBIA = t`Serbia`;
export const SEYCHELLES = t`Seychelles`;
export const SIERRA_LEONE = t`Sierra Leone`;
export const SINGAPORE = t`Singapore`;
export const SINT_MAARTEN_DUTCH = t`Sint Maarten (Dutch part)`;
export const SLOVAKIA = t`Slovakia`;
export const SLOVENIA = t`Slovenia`;
export const SOLOMON_ISLANDS = t`Solomon Islands`;
export const SOMALIA = t`Somalia`;
export const SOUTH_AFRICA = t`South Africa`;
export const SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = t`South Georgia and the South Sandwich Islands`;
export const SOUTH_SUDAN = t`South Sudan`;
export const SPAIN = t`Spain`;
export const SRI_LANKA = t`Sri Lanka`;
export const SUDAN = t`Sudan`;
export const SURINAME = t`Suriname`;
export const SVALBARD_AND_JAN_MAYEN = t`Svalbard and Jan Mayen`;
export const SWAZILAND = t`Swaziland`;
export const SWEDEN = t`Sweden`;
export const SWITZERLAND = t`Switzerland`;
export const SYRIAN_ARAB_REPUBLIC = t`Syrian Arab Republic`;
export const TAIWAN = t`Taiwan`;
export const TAJIKISTAN = t`Tajikistan`;
export const TANZANIA_UNITED_REPUBLIC_OF = t`Tanzania, United Republic of`;
export const THAILAND = t`Thailand`;
export const TIMOR_LESTE = t`Timor-Leste`;
export const TOGO = t`Togo`;
export const TOKELAU = t`Tokelau`;
export const TONGA = t`Tonga`;
export const TRINIDAD_AND_TOBAGO = t`Trinidad and Tobago`;
export const TUNISIA = t`Tunisia`;
export const TURKEY = t`Turkey`;
export const TURKMENISTAN = t`Turkmenistan`;
export const TURKS_AND_CAICOS_ISLANDS = t`Turks and Caicos Islands`;
export const TUVALU = t`Tuvalu`;
export const UGANDA = t`Uganda`;
export const UKRAINE = t`Ukraine`;
export const UNITED_ARAB_EMIRATES = t`United Arab Emirates`;
export const UNITED_KINGDOM = t`United Kingdom`;
export const UNITED_STATES = t`United States`;
export const URUGUAY = t`Uruguay`;
export const UZBEKISTAN = t`Uzbekistan`;
export const VANUATU = t`Vanuatu`;
export const VENEZUELA_BOLIVARIAN_REPUBLIC_OF = t`Venezuela, Bolivarian Republic of`;
export const VIETNAM = t`Vietnam`;
export const VIRGIN_ISLANDS_BRITISH = t`Virgin Islands, British`;
export const WALLIS_AND_FUTUNA = t`Wallis and Futuna`;
export const WESTERN_SAHARA = t`Western Sahara`;
export const YEMEN = t`Yemen`;
export const ZAMBIA = t`Zambia`;
export const ZIMBABWE = t`Zimbabwe`;

export const COUNTRIES = [
  AFGHANISTAN,
  ALAND_ISLANDS,
  ALBANIA,
  ALGERIA,
  ANDORRA,
  ANGOLA,
  ANGUILLA,
  ANTARCTICA,
  ANTIGUA_AND_BARBUDA,
  ARGENTINA,
  ARMENIA,
  ARUBA,
  AUSTRALIA,
  AUSTRIA,
  AZERBAIJAN,
  BAHAMAS,
  BAHRAIN,
  BANGLADESH,
  BARBADOS,
  BELARUS,
  BELGIUM,
  BELIZE,
  BENIN,
  BERMUDA,
  BHUTAN,
  BOLIVIA_PLURINATIONAL_STATE_OF,
  BONAIRE_SINT_EUSTATIUS_AND_SABA,
  BOSNIA_AND_HERZEGOVINA,
  BOTSWANA,
  BOUVET_ISLAND,
  BRAZIL,
  BRITISH_INDIAN_OCEAN_TERRITORY,
  BRUNEI_DARUSSALAM,
  BULGARIA,
  BURKINA_FASO,
  BURUNDI,
  CAMBODIA,
  CAMEROON,
  CANADA,
  CAPE_VERDE,
  CAYMAN_ISLANDS,
  CENTRAL_AFRICAN_REPUBLIC,
  CHAD,
  CHILE,
  CHINA,
  CHRISTMAS_ISLAND,
  COCOS,
  COLOMBIA,
  COMOROS,
  CONGO,
  CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE,
  COOK_ISLANDS,
  COSTA_RICA,
  COTE_DIVOIRE,
  CROATIA,
  CUBA,
  CURATAO,
  CYPRUS,
  CZECH_REPUBLIC,
  DENMARK,
  DJIBOUTI,
  DOMINICA,
  DOMINICAN_REPUBLIC,
  ECUADOR,
  EGYPT,
  EL_SALVADOR,
  EQUATORIAL_GUINEA,
  ERITREA,
  ESTONIA,
  ETHIOPIA,
  FALKLAND_ISLANDS,
  FAROE_ISLANDS,
  FIJI,
  FINLAND,
  FRANCE,
  FRENCH_GUIANA,
  FRENCH_POLYNESIA,
  FRENCH_SOUTHERN_TERRITORIES,
  GABON,
  GAMBIA,
  GEORGIA,
  GERMANY,
  GHANA,
  GIBRALTAR,
  GREECE,
  GREENLAND,
  GRENADA,
  GUADELOUPE,
  GUATEMALA,
  GUERNSEY,
  GUINEA,
  GUINEA_BISSAU,
  GUYANA,
  HAITI,
  HEARD_ISLAND_AND_MCDONALD_ISLANDS,
  HOLY_SEE,
  HONDURAS,
  HONG_KONG,
  HUNGARY,
  ICELAND,
  INDIA,
  INDONESIA,
  IRAN_ISLAMIC_REPUBLIC_OF,
  IRAQ,
  IRELAND,
  ISLE_OF_MAN,
  ISRAEL,
  ITALY,
  JAMAICA,
  JAPAN,
  JERSEY,
  JORDAN,
  KAZAKHSTAN,
  KENYA,
  KIRIBATI,
  KOREA_DEMOCRATIC_PEOPLES_REPUBLIC_OF,
  KOREA_REPUBLIC_OF,
  KUWAIT,
  KYRGYZSTAN,
  LAO_PEOPLE_DEMOCRATIC_REPUBLIC,
  LATVIA,
  LEBANON,
  LESOTHO,
  LIBERIA,
  LIBYA,
  LIECHTENSTEIN,
  LITHUANIA,
  LUXEMBOURG,
  MACAO,
  MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF,
  MADAGASCAR,
  MALAWI,
  MALAYSIA,
  MALDIVES,
  MALI,
  MALTA,
  MARTINIQUE,
  MAURITANIA,
  MAURITIUS,
  MAYOTTE,
  MEXICO,
  MOLDOVA_REPUBLIC_OF,
  MONACO,
  MONGOLIA,
  MONTENEGRO,
  MONTSERRAT,
  MOROCCO,
  MOZAMBIQUE,
  MYANMAR,
  NAMIBIA,
  NAURU,
  NEPAL,
  NETHERLANDS,
  NEW_CALEDONIA,
  NEW_ZEALAND,
  NICARAGUA,
  NIGER,
  NIGERIA,
  NIUE,
  NORFOLK_ISLAND,
  NORWAY,
  OMAN,
  PAKISTAN,
  PALESTINE,
  PANAMA,
  PAPUA_NEW_GUINEA,
  PARAGUAY,
  PERU,
  PHILIPPINES,
  PITCAIRN,
  POLAND,
  PORTUGAL,
  QATAR,
  REUNION,
  ROMANIA,
  RUSSIAN_FEDERATION,
  RWANDA,
  SAINT_BARTH_LEMY,
  SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA,
  SAINT_KITTS_AND_NEVIS,
  SAINT_LUCIA,
  SAINT_MARTIN_FRENCH,
  SAINT_PIERRE_AND_MIQUELON,
  SAINT_VINCENT_AND_THE_GRENADINES,
  SAMOA,
  SAN_MARINO,
  SAO_TOME_AND_PRINCIPE,
  SAUDI_ARABIA,
  SENEGAL,
  SERBIA,
  SEYCHELLES,
  SIERRA_LEONE,
  SINGAPORE,
  SINT_MAARTEN_DUTCH,
  SLOVAKIA,
  SLOVENIA,
  SOLOMON_ISLANDS,
  SOMALIA,
  SOUTH_AFRICA,
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS,
  SOUTH_SUDAN,
  SPAIN,
  SRI_LANKA,
  SUDAN,
  SURINAME,
  SVALBARD_AND_JAN_MAYEN,
  SWAZILAND,
  SWEDEN,
  SWITZERLAND,
  SYRIAN_ARAB_REPUBLIC,
  TAIWAN,
  TAJIKISTAN,
  TANZANIA_UNITED_REPUBLIC_OF,
  THAILAND,
  TIMOR_LESTE,
  TOGO,
  TOKELAU,
  TONGA,
  TRINIDAD_AND_TOBAGO,
  TUNISIA,
  TURKEY,
  TURKMENISTAN,
  TURKS_AND_CAICOS_ISLANDS,
  TUVALU,
  UGANDA,
  UKRAINE,
  UNITED_ARAB_EMIRATES,
  UNITED_KINGDOM,
  UNITED_STATES,
  URUGUAY,
  UZBEKISTAN,
  VANUATU,
  VENEZUELA_BOLIVARIAN_REPUBLIC_OF,
  VIETNAM,
  VIRGIN_ISLANDS_BRITISH,
  WALLIS_AND_FUTUNA,
  WESTERN_SAHARA,
  YEMEN,
  ZAMBIA,
  ZIMBABWE,
];

export const CHINESE_LANGUAGE_COUNTRIES = [
  CHINA,
  HONG_KONG,
  MACAO,
  SINGAPORE,
  TAIWAN,
  THAILAND,
];
export const CZECH_LANGUAGE_COUNTRIES = [CZECH_REPUBLIC];
export const DUTCH_LANGUAGE_COUNTRIES = [
  ARUBA,
  BELGIUM,
  CURATAO,
  NETHERLANDS,
  SINT_MAARTEN_DUTCH,
  SURINAME,
];
export const ENGLISH_LANGUAGE_COUNTRIES = [
  UNITED_STATES,
  UNITED_KINGDOM,
  IRELAND,
  GIBRALTAR,
  GHANA,
  KENYA,
  LIBERIA,
  NIGERIA,
  SIERRA_LEONE,
  CANADA,
  GUYANA,
  BELIZE,
  AUSTRALIA,
  SAMOA,
  FIJI,
  NEW_ZEALAND,
];
export const FINNISH_LANGUAGE_COUNTRIES = [FINLAND];
export const FRENCH_LANGUAGE_COUNTRIES = [
  BENIN,
  BURKINA_FASO,
  CONGO,
  COTE_DIVOIRE,
  FRANCE,
  GABON,
  GUINEA,
  MALI,
  MONACO,
  NIGER,
  SENEGAL,
  TOGO,
];
export const GERMAN_LANGUAGE_COUNTRIES = [
  GERMANY,
  BELGIUM,
  AUSTRIA,
  SWITZERLAND,
  LUXEMBOURG,
  LIECHTENSTEIN,
];
export const GREEK_LANGUAGE_COUNTRIES = [GREECE, CYPRUS];
export const HUNGARIAN_LANGUAGE_COUNTRIES = [HUNGARY];
export const ITALIAN_LANGUAGE_COUNTRIES = [ITALY];
export const POLISH_LANGUAGE_COUNTRIES = [POLAND];
export const PORTUGUESE_LANGUAGE_COUNTRIES = [
  ANGOLA,
  BRAZIL,
  CAPE_VERDE,
  TIMOR_LESTE,
  GUINEA_BISSAU,
  MACAO,
  MOZAMBIQUE,
  PORTUGAL,
  SAO_TOME_AND_PRINCIPE,
];
export const ROMANIAN_LANGUAGE_COUNTRIES = [ROMANIA, MOLDOVA_REPUBLIC_OF];
export const RUSSIAN_LANGUAGE_COUNTRIES = [
  RUSSIAN_FEDERATION,
  BELARUS,
  KYRGYZSTAN,
  KAZAKHSTAN,
];
export const SERBIAN_LANGUAGE_COUNTRIES = [
  SERBIA,
  BOSNIA_AND_HERZEGOVINA,
  MONTENEGRO,
];
export const SLOVAK_LANGUAGE_COUNTRIES = [SERBIA];
export const SPANISH_LANGUAGE_COUNTRIES = [
  ARGENTINA,
  BOLIVIA_PLURINATIONAL_STATE_OF,
  CHILE,
  COLOMBIA,
  COSTA_RICA,
  CUBA,
  DOMINICAN_REPUBLIC,
  ECUADOR,
  EL_SALVADOR,
  EQUATORIAL_GUINEA,
  GUATEMALA,
  HONDURAS,
  MEXICO,
  NICARAGUA,
  PANAMA,
  PARAGUAY,
  PERU,
  SPAIN,
  URUGUAY,
  VENEZUELA_BOLIVARIAN_REPUBLIC_OF,
];
export const SWEDISH_LANGUAGE_COUNTRIES = [SWEDEN];
export const TURKISH_LANGUAGE_COUNTRIES = [
  TURKEY,
  AZERBAIJAN,
  IRAQ,
  SYRIAN_ARAB_REPUBLIC,
  LEBANON,
  MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF,
  ISRAEL,
  BULGARIA,
];
