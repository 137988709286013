import { t } from '@lingui/macro';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import useMeasurementSystem from '../../hooks/useMeasurementSystem';
import useMeasurementUnit from '../../hooks/useMeasurementUnit';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import useRole from '../../hooks/useRole';
import { getUnitOfMeasuresForProductType } from '../../services/Product';
import positiveIntegerOnlyInput from '../../services/Util/positiveIntegerOnlyInput.util';
import { AuthorityRole } from '../../types/Authority.interface';
import MeasurementSystem from '../../types/MeasurementSystem.enum';
import PutUpCategory from '../../types/PutUpCategory.enum';
import Input from '../Input/Input';
import Select from '../Select/Select';
import ExpandedRow from './ExpandedRow';
import styles from './QuickOrderBookingTable.module.scss';
import QuickOrderRow from './QuickOrderRow.interface';
import useAnalytics from '../../hooks/useAnalytics';
import stripZeros from '../../services/Util/stripZeros.util';

// TODO: Data to be placed in a store via zustand
interface IQuickOrderBookingTableProps {
  /** Product information for booking an order retrieved from Sappi Service  */
  data: QuickOrderRow[];

  loading: boolean;

  /** Callback that emits entire dataset when an editable field changes */
  onCellEdit?: (changedData: QuickOrderRow[]) => void;

  /** Will Show and Hide columns depending on value passed in */
  putUpCategory: PutUpCategory;
}

const QuickOrderBookingTable: React.FunctionComponent<IQuickOrderBookingTableProps> = ({
  data,
  loading,
  onCellEdit,
  putUpCategory,
}) => {
  const DEFAULT_START_PAGE = 1;

  const [dataSource, setDataSource] = useState(data);
  const [current, setCurrent] = useState(DEFAULT_START_PAGE);
  useEffect(() => {
    setDataSource(data);
    setCurrent(DEFAULT_START_PAGE);
  }, [data]);

  const { hasRole } = useRole();
  const { format } = useNumberFormatter();
  const { measurementUnit1 } = useMeasurementUnit();
  const { measurementSystem } = useMeasurementSystem();
  const { trackPageView } = useAnalytics();
  const onChange = (page: React.SetStateAction<number>) => {
    trackPageView('QUICK_ORDER_BOOKING', 'PAGE_CHANGE');
    setCurrent(page);
  };

  const pageSize = 100;

  const onEditableCellChange = (key: string, dataIndex: string) => {
    return (value: string) => {
      const temp = cloneDeep(dataSource);
      const target: QuickOrderRow = temp.find(
        (item) => item.key === key
      ) as QuickOrderRow;
      if (target) {
        target[dataIndex] = value;
      }
      setDataSource(temp);
      if (onCellEdit) {
        onCellEdit(temp);
      }
    };
  };

  const getSizeChildren = (): any => {
    return putUpCategory === PutUpCategory.ROLL
      ? [
          {
            title: t`Width`,
            dataIndex: 'width',
            key: 'width',
            render: (text: string, record: QuickOrderRow) =>
              measurementSystem === MeasurementSystem.METRIC
                ? `${format(text)} ${measurementUnit1}`
                : `${record.reelWidthInches} ${measurementUnit1}`,
            sorter: (a: QuickOrderRow, b: QuickOrderRow) =>
              a.width && b.width ? a.width - b.width : 0,
            sortDirections: ['descend', 'ascend'],
          },
          {
            title: t`Roll & Core Diameter`,
            dataIndex: 'reelAndCoreDiameter',
            key: 'reelAndCoreDiameter',
            render: (text: string, record: QuickOrderRow) =>
              measurementSystem === MeasurementSystem.METRIC
                ? `${format(
                    record.reelDiameter
                  )} ${measurementUnit1} x ${format(
                    record.coreDiameter
                  )} ${measurementUnit1}`
                : `${record.reelDiameterInches} ${measurementUnit1} x ${record.coreDiameterInches} ${measurementUnit1}`,
            sorter: (a: QuickOrderRow, b: QuickOrderRow) => {
              let sortValue = false;
              if (a.reelDiameter && b.reelDiameter) {
                sortValue = a.reelDiameter > b.reelDiameter;
              }
              return sortValue ? 1 : -1;
            },
            sortDirections: ['descend', 'ascend'],
          },
        ]
      : [
          {
            title: t`Width & Length`,
            dataIndex: 'widthAndLength',
            key: 'widthAndLength',
            render: (text: string, record: QuickOrderRow) =>
              measurementSystem === MeasurementSystem.METRIC
                ? `${format(record.width)} ${measurementUnit1} x ${format(
                    record.length
                  )} ${measurementUnit1}`
                : `${record.widthInches} ${measurementUnit1} x ${record.lengthInches} ${measurementUnit1}`,
            sorter: (a: QuickOrderRow, b: QuickOrderRow) => {
              let sortValue = false;
              if (a.width && b.width) {
                sortValue = a.width > b.width;
              }
              return sortValue ? 1 : -1;
            },
            sortDirections: ['descend', 'ascend'],
          },
        ];
  };

  const getGrammageOrBasis = () => {
    return measurementSystem === MeasurementSystem.METRIC
      ? {
          title: t`Grammage`,
          dataIndex: 'grammage',
          key: 'grammage',
          sorter: (a: QuickOrderRow, b: QuickOrderRow) =>
            a.grammage > b.grammage ? 1 : -1,
          sortDirections: ['descend', 'ascend'],
        }
      : {
          title: t`Basis Weight`,
          dataIndex: 'bookweight',
          key: 'bookweight',
          render: (text: string, record: QuickOrderRow) =>
            `${text} ${record.usBaseWeight}`,
          sorter: (a: QuickOrderRow, b: QuickOrderRow) =>
            a.bookweight > b.bookweight ? 1 : -1,
          sortDirections: ['descend', 'ascend'],
        };
  };

  const getProductChildren = () => {
    const BRAND_COL = {
      title: t`Brand`,
      dataIndex: 'brand',
      key: 'brand',
      render: (text: string, record: QuickOrderRow) => (
        <div>
          <div>{text}</div>
          <sup>{record.kcode}</sup>
        </div>
      ),
      sorter: (a: QuickOrderRow, b: QuickOrderRow) =>
        a.brand > b.brand ? 1 : -1,
      sortDirections: ['descend', 'ascend'],
    };

    const FINISH_COL = {
      title: t`Finish`,
      dataIndex: 'finishDescription',
      key: 'finishDescription',
      render: (text: string) => text && text.toUpperCase(),
      sorter: (a: QuickOrderRow, b: QuickOrderRow) =>
        a?.finishDescription > b?.finishDescription ? 1 : -1,
      sortDirections: ['descend', 'ascend'],
    };
    const CALIPER_COL = {
      title: t`Caliper`,
      dataIndex: 'caliper',
      key: 'caliper',
      render: (text: string, record: QuickOrderRow) =>
        measurementSystem === MeasurementSystem.METRIC
          ? format(record.caliperMils)
          : format(text),
      sorter: (a: QuickOrderRow, b: QuickOrderRow) => a.caliper - b.caliper,
      sortDirections: ['descend', 'ascend'],
    };

    return hasRole(AuthorityRole.ROLE_RELEASE)
      ? [BRAND_COL, getGrammageOrBasis()]
      : [BRAND_COL, FINISH_COL, getGrammageOrBasis(), CALIPER_COL];
  };

  const getQuantityChildren = () => {
    return putUpCategory === PutUpCategory.ROLL
      ? [
        {
          title: t`Unit of Measure`,
          dataIndex: 'selectedMeasureUnit',
          key: 'selectedMeasureUnit',
          render: (text: string, record: QuickOrderRow) => {
            return (
              <div className={styles['cell-min-width']}>
                <Select
                  options={getUnitOfMeasuresForProductType(
                    record.productType
                  ).map((key) => ({
                    label: key,
                    value: key,
                  }))}
                  value={record.selectedMeasureUnit}
                  onChange={(e) =>
                    onEditableCellChange(
                      record.key,
                      'selectedMeasureUnit'
                    )(e.target.value)
                  }
                />
              </div>
            );
          },
        },
        {
          title: t`Quantity`,
          dataIndex: 'userEnteredQuantity',
          key: 'userEnteredQuantity',
          render: (text: number, record: QuickOrderRow) => (
            <div className={`flex items-center ${styles['cell-min-width']}`}>
              <Input
                name="quantity"
                type="number"
                placeholder="0"
                value={String(text).replace(/\b0+/g, '')}
                onKeyDown={positiveIntegerOnlyInput}
                onChange={(e) =>
                  onEditableCellChange(
                    record.key,
                    'userEnteredQuantity'
                  )(e.target.value)
                }
              />
              <span
                className={`ml-4 fas fa-check ${
                  text > 0 ? 'text-blue-pacific' : 'hidden'
                }`}
              />
            </div>
          ),
        },
      ]
      : [
        {
          title: t`Sheets Per Pallet`,
          dataIndex: 'sheetsPerPallet',
          key: 'sheetsPerPallet',
          render: (text: string) => stripZeros(text),
        },
        {
          title: t`Unit of Measure`,
          dataIndex: 'selectedMeasureUnit',
          key: 'selectedMeasureUnit',
          render: (text: string, record: QuickOrderRow) => {
            return (
              <div className={styles['cell-min-width']}>
                <Select
                  options={getUnitOfMeasuresForProductType(
                    record.productType
                  ).map((key) => ({
                    label: key,
                    value: key,
                  }))}
                  value={record.selectedMeasureUnit}
                  onChange={(e) =>
                    onEditableCellChange(
                      record.key,
                      'selectedMeasureUnit'
                    )(e.target.value)
                  }
                />
              </div>
            );
          },
        },
        {
          title: t`Quantity`,
          dataIndex: 'userEnteredQuantity',
          key: 'userEnteredQuantity',
          render: (text: number, record: QuickOrderRow) => (
            <div className={`flex items-center ${styles['cell-min-width']}`}>
              <Input
                name="quantity"
                type="number"
                placeholder="0"
                value={String(text).replace(/\b0+/g, '')}
                onKeyDown={positiveIntegerOnlyInput}
                onChange={(e) =>
                  onEditableCellChange(
                    record.key,
                    'userEnteredQuantity'
                  )(e.target.value)
                }
              />
              <span
                className={`ml-4 fas fa-check ${
                  text > 0 ? 'text-blue-pacific' : 'hidden'
                }`}
              />
            </div>
          ),
        },
      ];
  }

  const columns: ColumnsType<QuickOrderRow> = [
    {
      title: t`Product`,
      children: getProductChildren(),
    },
    {
      title: t`Size`,
      children: getSizeChildren(),
    },
    {
      title: '',
      children: getQuantityChildren(),
    },
  ];

  const headerStyle = () => {
    let style = styles['quick-order-booking-table--reels'];
    if (hasRole(AuthorityRole.ROLE_RELEASE)) {
      style = styles['quick-order-booking-table--release'];
    } else if (putUpCategory === PutUpCategory.SHEET) {
      style = styles['quick-order-booking-table--sheets'];
    }
    return style;
  };
  return (
    <>
      <Table
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => <ExpandedRow record={record} />,
          rowExpandable: (record) => !!record.locations,
        }}
        className={`${styles['quick-order-booking-table']} ${headerStyle()}`}
        pagination={{ current, pageSize, onChange }}
      />
    </>
  );
};

export default QuickOrderBookingTable;
