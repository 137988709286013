import * as React from 'react';

interface ISimpleCardProps {
  className?: string;
  style?: React.CSSProperties;
  innerWrapperClassName?: string;
}

const SimpleCard: React.FunctionComponent<ISimpleCardProps> = ({
  className = '',
  style,
  children,
  innerWrapperClassName = '',
}) => {
  return (
    <div
      className={`${className} border-t-4 border-blue-cobaltVariation`}
      style={style}
    >
      <div
        className={`border-l border-r border-b border-gray-light400 py-4 px-6 ${innerWrapperClassName}`}
        style={{ marginTop: '-1px' }}
      >
        {children}
      </div>
    </div>
  );
};

export default SimpleCard;
