import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import useAnalytics from '../../hooks/useAnalytics';
import showNotification from '../../services/Notification';
import PageTitle from '../PageHeader/PageTitle';
import { sendForgotPasswordReset } from './ForgotPassword.service';

const ForgotPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ username: string }>({ mode: 'all' });
  const [hasError, setHasError] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const history = useHistory();
  const { trackPageView } = useAnalytics();
  const submit = async (formData: { username: string }) => {
    const { username } = formData;
    trackPageView('ADMIN', 'FORGOT_PASSWORD', { username });
    setHasError(false);
    setIsResetting(true);
    // eslint-disable-next-line no-console
    console.log(`user ${username} forgot password`);
    try {
      await sendForgotPasswordReset(username);
      history.push('/login');
      showNotification({
        message: '',
        description: t`Your password has been sent to your email address.`,
      });
    } catch (e) {
      setHasError(true);
    }
    setIsResetting(false);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Space direction="vertical" size="large" className="mt-10">
        <PageTitle title={t`Password Request`} />
        <div className="text-lg mt-4">
          <Trans>
            Please enter your user id below and we will email you a new
            temporary password.
          </Trans>
        </div>
        <InputLabel text={t`User ID`} required size="lg" className="w-96">
          <Input
            name="username"
            ref={register({ required: true })}
            required
            width="96"
          />
        </InputLabel>
        {hasError && (
          <div className="text-red-red">
            <Trans>No user with that id can be found</Trans>
          </div>
        )}
        <Button
          type="submit"
          theme="primary"
          loading={isResetting}
          disabled={!isValid}
        >
          <Trans>Submit</Trans>
        </Button>
      </Space>
    </form>
  );
};

export default ForgotPassword;
