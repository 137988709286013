enum Locale {
  en = 'en_US', // English
  de = 'de_DE', // German
  es = 'es_ES', // Spanish
  fi = 'fi_FI', // Finland
  fr = 'fr_FR', // French
  hu = 'hu_HU', // Hungary
  it = 'it_IT', // Italian
  nl = 'nl_NL', // Dutch
  pl = 'pl_PL', // Polish
  pt = 'pt_PT', // Portuguese
  ru = 'ru_RU', // Russian
  sk = 'sk_SK', // Slovakia
  eu = 'eu_EU',
}

export default Locale;
