import * as React from 'react';
import { SavedEnquiry } from '../types/SavedEnquiry.interface';
import EnquiryAsideInfo from './EnquiryAsideInfo';
import ResultTable from './ResultTable';
import useRecreateEnquiry from './useRecreateEnquiry.store';

interface IResultsProps {
  results: SavedEnquiry[];
}

const Results: React.FunctionComponent<IResultsProps> = ({ results }) => {
  const { enquiryRecreating } = useRecreateEnquiry();
  return (
    <>
      {results.map((result) => (
        <div key={result.id} className="flex mb-8">
          <div style={{ width: '80%' }}>
            <ResultTable
              createDate={result.createDate}
              createTime={result.createTime}
              items={result.items}
            />
          </div>
          <div style={{ width: '20%' }}>
            <EnquiryAsideInfo
              enquiryNumber={result.pacNumber}
              salesOrg={result.salesOrg}
              customerName={result.soldToParty.name}
              shipToCustomer={result.shipToParty}
              isRecreatingDisabled={enquiryRecreating}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default Results;
