import { ModalProps } from 'antd/lib/modal';
import * as React from 'react';
import Modal from '../Modal/Modal';

interface SummaryField {
  label: string;
  value: string | React.ReactNode;
}

interface IInformationModalProps extends ModalProps {
  summaryFields: SummaryField[];
  detailsTitle: string | React.ReactNode;
}

const InformationModal: React.FunctionComponent<IInformationModalProps> = ({
  title,
  summaryFields,
  detailsTitle,
  visible,
  children,
  onCancel,
  footer,
}) => {
  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      width="970px"
      footer={footer}
    >
      <div className="flex flex-wrap px-8 mb-5">
        {summaryFields.map((field) => (
          <div
            key={field.label}
            className="text-lg text-gray-dark400 mr-20 mb-2"
          >
            {field.label}: <strong>{field.value}</strong>
          </div>
        ))}
      </div>
      {detailsTitle ? (
        <div className="bg-gray-dark200 text-white-white text-xl px-5 py-3 mb-2 font-bold">
          {detailsTitle}
        </div>
      ) : (
        <></>
      )}
      {children}
    </Modal>
  );
};

export default InformationModal;
