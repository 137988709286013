import { Trans } from '@lingui/macro';
import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import {
  ExpandableConfig,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { Button } from '../Button/Button';
import './Table.scss';
import { getCurrentRange } from './Table.util';

interface TableConfig {
  noStripes?: boolean;
  noOutsideBorders?: boolean;
  showAllOption?: boolean;
  showExtraPaginationInfo?: boolean;
}
interface ITableProps extends TableProps<any> {
  config?: TableConfig;
  isShowAll?: boolean; // showAllOption must be set to true for this to work
  onToggleShowAll?: () => void; // showAllOption must be set to true for this to work
}

export const TABLE_STRIPED_CLASS = 'table-striped';
export const TABLE_NO_OUTSIDE_BORDERS_CLASS = 'no-outside-borders';
export const TABLE_ANT_OVERRIDE_CLASS = 'sappi-ant-override-table';

const Table: React.FunctionComponent<ITableProps> = ({
  className,
  expandable,
  dataSource,
  pagination,
  config,
  isShowAll,
  onToggleShowAll,
  ...rest
}) => {
  const [total, setTotal] = useState(0);
  const [currentRange, setCurrentRange] = useState('');
  const baseClasses = `${TABLE_ANT_OVERRIDE_CLASS}  ${className} ${
    config?.noOutsideBorders ? 'no-outside-borders' : ''
  }`;
  const classes =
    dataSource && dataSource.length > 0
      ? `${baseClasses} ${config?.noStripes ? '' : `${TABLE_STRIPED_CLASS} `}  `
      : `${baseClasses}`;
  const expandableConfig: ExpandableConfig<any> = {
    ...expandable,
    expandIcon: ({ expanded, onExpand, record, expandable: isExpandable }) => {
      if (isExpandable) {
        return expanded ? (
          <Button
            theme="link"
            className="table-row-expand-icon"
            onClick={(e) => onExpand(record, e)}
          >
            <i className="fa fa-minus-circle text-xl" />
          </Button>
        ) : (
          <Button
            theme="link"
            className="table-row-expand-icon"
            onClick={(e) => onExpand(record, e)}
          >
            <i className="fa fa-plus-circle text-xl" />
          </Button>
        );
      }
      return <></>;
    },
  };

  useEffect(() => {
    if (pagination) {
      const totalItems = (pagination as TablePaginationConfig).total || 0;
      setTotal(totalItems);
      const { pageSize, current } = pagination as TablePaginationConfig;
      setCurrentRange(getCurrentRange(pageSize || 0, current || 0, totalItems));
    }
  }, [pagination]);
  const paginationOptions:
    | false
    | TablePaginationConfig
    | undefined = config?.showAllOption
    ? {
        ...pagination,
        itemRender: (page, type, orginalElement) => {
          if (type === 'prev') {
            return (
              <div className="relative">
                <Button
                  theme="link"
                  className="absolute z-10 sappi-toggle-show-all-button"
                  width="40"
                  style={{ right: '20px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onToggleShowAll) {
                      onToggleShowAll();
                    }
                  }}
                >
                  {!isShowAll ? (
                    <Trans>Page Results</Trans>
                  ) : (
                    <Trans>Show All</Trans>
                  )}
                </Button>
                {orginalElement}
              </div>
            );
          }
          return <div>{orginalElement}</div>;
        },
      }
    : pagination;
  return (
    <div>
      {config?.showExtraPaginationInfo && (
        <div className="text-right -mb-1">
          <span className="border-gray-light400 border-r pr-3 mr-3">
            {total} <Trans>Items</Trans>
          </span>
          <span>
            <Trans>Displaying</Trans> <strong>{currentRange}</strong>{' '}
            <Trans>of</Trans> <strong>{total}</strong>{' '}
          </span>
        </div>
      )}
      <AntTable
        {...rest}
        dataSource={dataSource}
        pagination={paginationOptions}
        expandable={expandableConfig}
        className={classes}
      />
    </div>
  );
};

export default Table;
