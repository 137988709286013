import { Trans } from '@lingui/macro';
import * as React from 'react';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import useRole from '../../../../hooks/useRole';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import {
  shouldShowCost,
  shouldShowNoCost,
} from './SamplesProductAvailability.util';

interface ISamplesProductAvailabilityProps {
  product: SamplesProduct;
}

const SamplesProductAvailability: React.FunctionComponent<ISamplesProductAvailabilityProps> = ({
  product,
}) => {
  const { format } = useNumberFormatter();
  const { isInternalUser } = useRole();

  return (
    <>
      {product.available == 'X' && ( // eslint-disable-line eqeqeq
        <div className="font-bold text-green-pineTree">
          <Trans>Available</Trans>
        </div>
      )}
      {product.available !== 'X' && (
        <div className="font-bold">
          <Trans>Not Available</Trans>
        </div>
      )}
      {shouldShowCost(product) ? (
        <div>
          {format(product.price)} {product.currency} <Trans>Per Unit</Trans>
        </div>
      ) : (
        <>
          {shouldShowNoCost(product, isInternalUser) && (
            <div>
              <Trans>No Cost</Trans>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SamplesProductAvailability;
