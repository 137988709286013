import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { concat } from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Select from '../../../components/Select/Select';
import useAnalytics from '../../../hooks/useAnalytics';
import useUser from '../../../hooks/useUser';
import { deriveCustomerOptions } from '../../../services/Customer';
import { AuthorityRole } from '../../../types/Authority.interface';
import useRole from '../../../hooks/useRole';
import Option from '../../../types/Option.type';
import Input from '../../../components/Input/Input';
import { DocumentDateTypes } from '../../../types/DocumentDateTypes.interface';
import { SearchDocumentInputs } from './SearchDocumentInputs';
import './SearchDocuments.scss';
import ControlledDatePicker from '../../../components/DatePicker/ControlledDatePicker';
import { PARAM_FORMAT } from '../../../components/DatePicker/DatePicker';
import fractionOrDecimalInput from '../../../services/Util/fractionOrDecimalInput.util';
import { mediumWidthKwd } from '../../../services/Util/getWidthCssClass.util';
import useDashboardStore, {
  DEFAULT_SEARCH_DOCUMENT_INPUTS,
} from '../../Dashboard/useDashboard.store';
import Radio from '../../../components/Radio/Radio';
import { anyInputExists, hasValidInput } from './SearchDocuments.util';
import SearchButtons from './SearchButtons';

interface ISearchDocumentsProps {
  isWidget?: boolean;
}

const SearchDocuments: React.FC<ISearchDocumentsProps> = ({
  isWidget = false,
}) => {
  const history = useHistory();
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();
  const { hasRole, isNonReleaseEuUser } = useRole();

  const { searchDocumentInputs, setSearchDocumentInputs } = useDashboardStore();

  const {
    register,
    handleSubmit,
    reset,
    errors,
    control,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    mode: 'all',
    defaultValues: DEFAULT_SEARCH_DOCUMENT_INPUTS,
  });

  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [isInputValid, setIsInputValid] = useState<boolean>(true);

  const watchFields = watch([
    'customerOrderNumberFrom',
    'documentNumberFrom',
    'invoiceNumberFrom',
  ]);
  const dateFromValueWatch = watch('dateFromValue');
  const dateToValueWatch = watch('dateToValue');
  const isDateFromAfterDateTo = () => {
    return (
      dateFromValueWatch &&
      dateToValueWatch &&
      dateFromValueWatch.isAfter(dateToValueWatch)
    );
  };

  useEffect(() => {
    if (user) {
      const { customers } = user;
      const derivedCustomerOptions = deriveCustomerOptions(
        customers || [],
        isNonReleaseEuUser,
        hasRole(AuthorityRole.ROLE_EU),
        true
      );
      setCustomerOptions(
        concat([{ label: t`ALL ACCOUNTS`, value: 0 }], derivedCustomerOptions)
      );
    }
  }, [user, isNonReleaseEuUser, hasRole]);

  // To set values in SearchDocument component when it switches between Dashboard's SearchDocument widget and dropdown menu
  useEffect(() => {
    if (customerOptions.length > 0) {
      setValue('customerNumber', searchDocumentInputs.customerNumber || '0');
      setValue(
        'customerOrderNumberFrom',
        searchDocumentInputs.customerOrderNumberFrom
      );
      setValue('documentNumberFrom', searchDocumentInputs.documentNumberFrom);
      setValue('invoiceNumberFrom', searchDocumentInputs.invoiceNumberFrom);
      setValue('deliveryNote', searchDocumentInputs.deliveryNote);
      setValue('weightListNumber', searchDocumentInputs.weightListNumber);
      setValue('dataType', searchDocumentInputs.dataType);
      setValue('dateFromValue', searchDocumentInputs.dateFromValue);
      setValue('dateToValue', searchDocumentInputs.dateToValue);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [setValue, customerOptions]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const getDateFromValueStr = () => {
    return moment(dateFromValueWatch).format(
      hasRole(AuthorityRole.ROLE_NA) ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
    );
  };

  const getDateFromValue = () => {
    return dateFromValueWatch
      ? moment(dateFromValueWatch).format(PARAM_FORMAT)
      : '';
  };

  const getDateToValue = () => {
    return dateToValueWatch
      ? moment(dateToValueWatch).format(PARAM_FORMAT)
      : '';
  };

  const submit = handleSubmit((data: SearchDocumentInputs) => {
    data.dateCreatedFrom =
      data.dataType === DocumentDateTypes.ORDER_DATE ? getDateFromValue() : '';

    data.dateCreatedTo =
      data.dataType === DocumentDateTypes.ORDER_DATE ? getDateToValue() : '';

    data.invoiceDateFrom =
      data.dataType === DocumentDateTypes.INVOICE_CREDIT_NOTE_DATE
        ? getDateFromValue()
        : '';

    data.invoiceDateTo =
      data.dataType === DocumentDateTypes.INVOICE_CREDIT_NOTE_DATE
        ? getDateToValue()
        : '';

    data.billingDateFrom =
      data.dataType === DocumentDateTypes.INVOICE_CREDIT_NOTE_DUE_DATE
        ? getDateFromValue()
        : '';

    data.billingDateTo =
      data.dataType === DocumentDateTypes.INVOICE_CREDIT_NOTE_DUE_DATE
        ? getDateToValue()
        : '';

    data.customerNumber =
      !data.customerNumber || data.customerNumber === '0'
        ? ''
        : data.customerNumber;

    if (hasValidInput(data)) {
      trackPageView('PRINT_DOCUMENTS', 'SEARCH', {
        customerId: data.customerNumber || 'ALL ACCOUNT',
      });
      setIsInputValid(true);

      setSearchDocumentInputs({ ...data });

      if (isWidget) {
        history.push('/documentPrinter_');
      }
    } else {
      setIsInputValid(false);
    }
  });

  return (
    <>
      <form onSubmit={submit}>
        <div className={`${isWidget ? 'mx-3' : 'mx-5 py-5'}`}>
          <div className="my-2 flex">
            <fieldset className="w-3/6 pr-1 align-top">
              <div className="py-2.5">
                <div className="text-xl font-extrabold text-gray-dark200 pb-3">
                  <Trans>Customer</Trans>
                </div>
                <Select
                  name="customerNumber"
                  options={customerOptions}
                  ref={register()}
                />
              </div>
              <div className="block">
                {!isWidget && (
                  <p>
                    <Trans>
                      Use an * as a wildcard to match one or more characters in
                      the Order # search.
                    </Trans>
                  </p>
                )}
                <div>
                  <Input
                    name="customerOrderNumberFrom"
                    className="mr-6 p-1.5 w-4/6 h-8 shadow-md text-blue-pacific"
                    type="text"
                    placeholder={t`My Order #`}
                    ref={register({ maxLength: 150 })}
                    error={errors.customerOrderNumberFrom}
                  />
                </div>
                <p className="mb-2.5">
                  <Trans>
                    Enter multiple order numbers, separated by a comma.
                  </Trans>
                </p>
                <div>
                  <Input
                    name="documentNumberFrom"
                    width={mediumWidthKwd}
                    className="mb-3.5 text-blue-pacific"
                    type="text"
                    placeholder={t`Sappi Order #`}
                    ref={register({ maxLength: 150 })}
                    error={errors.documentNumberFrom}
                  />
                </div>
                <div>
                  <Input
                    name="invoiceNumberFrom"
                    width={mediumWidthKwd}
                    className="mb-3.5 text-blue-pacific"
                    type="text"
                    placeholder={t`Invoice/Credit/Debit Note #`}
                    ref={register({ maxLength: 150 })}
                    error={errors.invoiceNumberFrom}
                  />
                </div>
                {hasRole(AuthorityRole.ROLE_NA) && !isWidget && (
                  <div>
                    <Input
                      name="deliveryNote"
                      width={mediumWidthKwd}
                      className="mb-3.5 text-blue-pacific"
                      type="text"
                      onKeyPress={fractionOrDecimalInput}
                      placeholder={t`Bill of Lading #`}
                      ref={register({
                        maxLength: 50,
                        pattern: {
                          value: /^\d*$/,
                          message: t({
                            id: 'orderTracking.kCode.error',
                            message: 'Please enter numbers only.',
                          }),
                        },
                      })}
                      error={errors.deliveryNote}
                    />
                  </div>
                )}
                {!isWidget && (
                  <div>
                    <Input
                      name="weightListNumber"
                      width={mediumWidthKwd}
                      className="mb-3.5 text-blue-pacific"
                      type="text"
                      placeholder={t`Weight List #`}
                      onKeyPress={fractionOrDecimalInput}
                      ref={register({
                        maxLength: 10,
                        pattern: {
                          value: /^\d*$/,
                          message: t({
                            id: 'orderTracking.kCode.error',
                            message: 'Please enter numbers only.',
                          }),
                        },
                      })}
                      error={errors.weightListNumber}
                    />
                  </div>
                )}
              </div>
              {isWidget &&
                !anyInputExists(
                  watchFields,
                  dateFromValueWatch,
                  dateToValueWatch
                ) && (
                  <div className="mb-2.5">
                    <Trans>
                      To perform a search of available documents, please enter{' '}
                      <strong>at minimum</strong> a Document Number{' '}
                      <strong>or</strong> a Date Search Parameter.
                    </Trans>
                  </div>
                )}
            </fieldset>
            <fieldset className="w-3/6 pl-1 align-top">
              <div className="text-xl font-extrabold text-gray-dark200 pt-2.5 mb-3">
                <Trans id="search.orderDateHeader">Order Related Dates</Trans>
              </div>
              <Space size="small" direction="vertical">
                <Radio
                  name="dataType"
                  value={DocumentDateTypes.ORDER_DATE}
                  label={<Trans>Order Date</Trans>}
                  ref={register()}
                />
                <Radio
                  name="dataType"
                  value={DocumentDateTypes.INVOICE_CREDIT_NOTE_DATE}
                  label={<Trans>Invoice/Credit Note Date</Trans>}
                  ref={register()}
                />
                <Radio
                  name="dataType"
                  value={DocumentDateTypes.INVOICE_CREDIT_NOTE_DUE_DATE}
                  label={<Trans>Invoice/Credit Note Due Date</Trans>}
                  ref={register()}
                />
              </Space>
              <div className="flex pt-4">
                <ControlledDatePicker
                  name="dateFromValue"
                  control={control}
                  defaultValue={null}
                  hasError={isDateFromAfterDateTo()}
                />
                <span className="px-3 pt-1 text-lg">
                  <Trans>to</Trans>
                </span>
                <ControlledDatePicker
                  name="dateToValue"
                  control={control}
                  defaultValue={null}
                  hasError={isDateFromAfterDateTo()}
                  toolTipErrorMsg={
                    <div>
                      <Trans>Date must be after</Trans> {getDateFromValueStr()}
                    </div>
                  }
                />
                {isDateFromAfterDateTo() && (
                  <span className="pl-2 text-3xl red">
                    <i className="fa fa-exclamation-circle" />
                  </span>
                )}
              </div>
              {isDateFromAfterDateTo() && (
                <div className="flex">
                  <div className="w-1/3 max-width-30" />
                  <div className="pb-3 text-red-red">
                    <Trans>Date needs to be after the first Order Date</Trans>
                  </div>
                </div>
              )}
              {isWidget && (
                <SearchButtons
                  isWidget
                  isInputValid={isInputValid}
                  isDateFromAfterDateTo={isDateFromAfterDateTo() || false}
                  clearErrors={clearErrors}
                  reset={reset}
                  setValue={setValue}
                  dateFromValueWatch={dateFromValueWatch}
                  dateToValueWatch={dateToValueWatch}
                  watchFields={watchFields}
                  setIsInputValid={setIsInputValid}
                />
              )}
            </fieldset>
          </div>
        </div>

        {!isWidget && (
          <SearchButtons
            isInputValid={isInputValid}
            isDateFromAfterDateTo={isDateFromAfterDateTo() || false}
            clearErrors={clearErrors}
            reset={reset}
            setValue={setValue}
            dateFromValueWatch={dateFromValueWatch}
            dateToValueWatch={dateToValueWatch}
            watchFields={watchFields}
            setIsInputValid={setIsInputValid}
          />
        )}
      </form>
    </>
  );
};

export default SearchDocuments;
