import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import useRole from '../../../../hooks/useRole';
import OrderType from '../OrderType.enum';

interface IOrderSummaryProps {
  orderType?: OrderType | null;
  poNumber?: string | null;
  shipToPoNumber?: string | null;
}

const OrderSummary: FC<IOrderSummaryProps> = ({
  orderType,
  poNumber,
  shipToPoNumber,
}) => {
  const orderTypeText =
    orderType === OrderType.ZOR || orderType === OrderType.STANDARD
      ? t`orderDetails.type.standard`
      : t`orderDetails.type.consignment`;
  const { isNaUser } = useRole();
  return (
    <Space size="middle">
      {orderType && (
        <LabelValue
          inline
          inverse
          label={t`Type of Order`}
          value={orderTypeText}
        />
      )}
      {poNumber && isNaUser && (
        <LabelValue
          inline
          inverse
          label={t`Purchase Order #`}
          value={poNumber}
        />
      )}
      {isNaUser && (
        <LabelValue
          inline
          inverse
          label={t`Ship to Purchase Order #`}
          value={shipToPoNumber || t`None`}
        />
      )}
    </Space>
  );
};

export default OrderSummary;
