enum Language {
  CHINESE = 'Chinese',
  CZECH = 'Czech',
  DUTCH = 'Dutch',
  ENGLISH = 'English',
  FINNISH = 'Finnish',
  FRENCH = 'French',
  GERMAN = 'German',
  GREEK = 'Greek',
  HUNGARIAN = 'Hungarian',
  ITALIAN = 'Italian',
  POLISH = 'Polish',
  PORTUGUESE = 'Portuguese',
  ROMANIAN = 'Romanian',
  RUSSIAN = 'Russian',
  SERBIAN = 'Serbian',
  SLOVAK = 'Slovak',
  SPANISH = 'Spanish',
  SWEDISH = 'Swedish',
  TURKISH = 'Turkish',
  UNKNOWN = 'Unknown',
}
export default Language;
