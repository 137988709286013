import React, { FC } from 'react';

export type DeconstructedTableBodyCellProps = React.HTMLProps<
  HTMLTableCellElement
>;

const DeconstructedTableBodyCell: FC<DeconstructedTableBodyCellProps> = ({
  className = '',
  children,
  ...rest
}) => {
  const baseClass = 'ant-table-cell';
  return (
    <td className={`${baseClass} ${className}`} {...rest}>
      {children}
    </td>
  );
};

export default DeconstructedTableBodyCell;
