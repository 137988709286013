import React, { FunctionComponent } from 'react';
import { Trans } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import AddAttachmentCore from './AddAttachmentCore';

const FileClaimUpload: FunctionComponent = () => {
  const { documentNumber, documentPosition, complaintNumber } = useParams<{
    documentNumber: string;
    documentPosition: string;
    complaintNumber: string;
  }>();

  return (
    <>
      <Trans>
        Your claim has been created! Please do not use the &apos;Back&apos;
        button. Changes to this claim are no longer possible. You can add or
        delete file attachments in the Claim Reporting tool if needed.
      </Trans>
      <AddAttachmentCore
        documentNumber={documentNumber}
        documentPosition={documentPosition}
        complaintNumber={complaintNumber}
        isFileClaimUpdate
      />
    </>
  );
};

export default FileClaimUpload;
