import React, { FC } from 'react';
import SimpleCard from '../../../components/SimpleCard/SimpleCard';

const CalculatorWrapper: FC = ({ children }) => {
  return (
    <SimpleCard style={{ width: '400px' }} className="mr-8 mb-12">
      <form noValidate>{children}</form>
    </SimpleCard>
  );
};

export default CalculatorWrapper;
