import React, { FC } from 'react';
import { noop } from 'lodash';
import { Button } from '../../components/Button/Button';
import { AccountFormSectionProps } from './types/AccountFormSectionProps.interface';

const AccountFormSection: FC<AccountFormSectionProps> = ({
  headingText,
  className = '',
  showCloseButton = false,
  setVisibility,
  children,
}) => {
  return (
    <fieldset className={className}>
      <div className="flex justify-between">
        <legend className="mb-9 text-4xl">{headingText}</legend>
        {showCloseButton && (
          <Button
            theme="link"
            className="-mt-20"
            onClick={() => (setVisibility ? setVisibility(false) : noop)}
          >
            <i className="fa fa-times text-gray-600 text-2xl" />
          </Button>
        )}
      </div>
      <div>{children}</div>
    </fieldset>
  );
};

export default AccountFormSection;
