import * as React from 'react';
import { Button } from '../Button/Button';

interface IBookmarkProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  solid?: boolean;
  inheritColor?: boolean;
}

const Bookmark: React.FunctionComponent<IBookmarkProps> = ({
  solid,
  inheritColor,
  ...rest
}) => {
  const color = inheritColor ? '' : 'text-green-lime';
  return (
    <Button
      theme="link"
      style={{ color: inheritColor ? 'inherit' : '' }}
      {...rest}
    >
      {solid ? (
        <i className={`fa fa-bookmark text-2xl ${color}`} />
      ) : (
        <i className="far fa-bookmark text-2xl" />
      )}
    </Button>
  );
};

export default Bookmark;
