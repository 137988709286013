import { t } from '@lingui/macro';
import { Tooltip } from 'antd';
import { debounce, noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import useExcelNotificationStore from '../../domain/PageHeader/ExcelNotification/excelNotification.store';
import { SearchOrderInputs } from '../../domain/TrackOrders/SearchOrderInputs.interface';
import useAnalytics from '../../hooks/useAnalytics';
import { exportExcel } from '../../services/Excel';
import './ExcelDownload.scss';

interface IExcelDownloadProps {
  params: Partial<SearchOrderInputs>;
  className?: string;
}

const ExcelDownload: React.FunctionComponent<IExcelDownloadProps> = ({
  className,
  params,
  children,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const { trackPageView } = useAnalytics();
  const fireOffExport = async () => {
    try {
      trackPageView('TRACK_ORDERS', 'EXCEL_DOWNLOAD', {
        customerId: params.customerNumber,
      });
      await exportExcel(params);
      setTooltipText(
        t({
          id: 'export.started',
          message:
            'Excel export started. Please watch the status area above for current status.',
        })
      );
      setTooltipVisible(true);
      useExcelNotificationStore.setState({ exportInProcess: true });
    } catch (error) {
      if (error.response.status === 400) {
        setTooltipText(
          t({
            id: 'export.running',
            message:
              'Please wait till current export is done before starting another.',
          })
        );
      } else {
        setTooltipText(
          t({
            id: 'export.error',
            message: 'There was an error starting your export.',
          })
        );
      }
      setTooltipVisible(true);
    }
  };

  useEffect(() => {
    if (tooltipVisible) {
      debounce(() => setTooltipVisible(false), 4500)();
    }
  }, [tooltipVisible]);

  return (
    <div className={className}>
      <Tooltip
        title={tooltipText}
        color="white"
        overlayClassName="sappi-ant-override-tooltip"
        visible={tooltipVisible}
        autoAdjustOverflow={false}
      >
        <div
          onClick={() => fireOffExport()}
          role="button"
          onKeyDown={noop}
          tabIndex={0}
        >
          {children}
        </div>
      </Tooltip>
    </div>
  );
};

export default ExcelDownload;
