import { Trans } from '@lingui/macro';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import useAnalytics from '../../../hooks/useAnalytics';
import useSamplesOrderPlacedStore from './useSamplesOrderPlacedStore.store';

interface IOrderPlacedErrorProps {
  prop?: unknown;
}

const OrderPlacedError: React.FunctionComponent<IOrderPlacedErrorProps> = (
  props
) => {
  const { errorMessage } = useSamplesOrderPlacedStore();
  const { failedOrderReference } = useParams<{
    failedOrderReference: string;
  }>();
  const { trackPageView } = useAnalytics();
  const history = useHistory();

  const goBackToSamplesSearch = () => {
    trackPageView('SAMPLES', 'NEW_ORDER');
    history.push('/samples/selectBuyer');
  };

  return (
    <div>
      <Trans>Your order was unable to be placed because of an error.</Trans>
      &nbsp;
      {errorMessage && (
        <>
          <Trans>The reason is:</Trans>&nbsp;
          <strong>{errorMessage}</strong>
        </>
      )}
      &nbsp;
      <div>
        <Trans>Your reference number is:</Trans>{' '}
        <strong>{failedOrderReference}</strong>{' '}
      </div>
      <div className="mt-4">
        <Button theme="primary" onClick={() => goBackToSamplesSearch()}>
          <Trans>Create a New Order</Trans>
        </Button>
      </div>
    </div>
  );
};

export default OrderPlacedError;
