import { t } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import { FRACTION_DECIMAL_INPUT } from '../../../types/RegularExpression.constants';

interface IImperialAreaPerSheetProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

const ImperialAreaPerSheet: React.FunctionComponent<IImperialAreaPerSheetProps> = ({
  register,
  errors,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Input
        name="sheetWidthImp"
        ref={register({
          required: true,
          pattern: FRACTION_DECIMAL_INPUT,
        })}
        required
        error={errors?.sheetWidthImp}
        placeholder={t`Width (in)`}
      />
      <Input
        name="sheetLengthImp"
        ref={register({
          required: true,
          pattern: FRACTION_DECIMAL_INPUT,
        })}
        required
        error={errors?.sheetWidthImp}
        placeholder={t`Length (in)`}
      />
    </Space>
  );
};

export default ImperialAreaPerSheet;
