import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { PartnerSearchCustomer } from '../../../../types/PartnerSearchCustomer.interface';
import { SamplesOrderDeliveryAddress } from '../../../../types/Samples/SamplesOrderDeliveryAddress.interface';

interface IShipToCellProps {
  shipTo?: PartnerSearchCustomer | SamplesOrderDeliveryAddress | null;
  deliveryInfoName?: string;
}

const ShipToCell: React.FunctionComponent<IShipToCellProps> = ({
  shipTo,
  deliveryInfoName,
}) => {
  const [isNewAddress, setIsNewAddress] = useState(false);
  useEffect(() => {
    setIsNewAddress(!(shipTo as PartnerSearchCustomer)?.customerNumber);
  }, [shipTo]);
  return (
    <Space direction="vertical">
      <div>{deliveryInfoName}</div>
      {!isNewAddress && (
        <div>{(shipTo as PartnerSearchCustomer)?.customerNumber}</div>
      )}
      <div>{shipTo?.name}</div>
      <div>
        {isNewAddress
          ? (shipTo as SamplesOrderDeliveryAddress)?.address1
          : (shipTo as PartnerSearchCustomer)?.address}
      </div>
      {isNewAddress && (shipTo as SamplesOrderDeliveryAddress)?.address2 && (
        <div>{(shipTo as SamplesOrderDeliveryAddress)?.address2}</div>
      )}
      <div>
        {shipTo?.city}, {shipTo?.postalCode}
      </div>
      <div>{shipTo?.country}</div>
    </Space>
  );
};

export default ShipToCell;
