import * as React from 'react';
import { ErrorResponse } from '../../../types/ErrorResponse.interface';
import { ProductAvailabilityResult } from '../../../types/ProductAvailabilityResult.interface';
import AlternateBrands from './AlternateBrands';
import InitialResultTable from './InitialResultTable/InitialResultTable';
import { OrderBookingProductQuery } from '../../../types/OrderBookingProductQuery.interface';
import AlternateSizes from './AlternateSizes/AlternateSizes';

interface ISearchResultsProps {
  productQuery: OrderBookingProductQuery;
  results: ProductAvailabilityResult[];
  error: ErrorResponse | null;
  className?: string;
  resetFullyToLander: () => void;
}

const SearchResults: React.FunctionComponent<ISearchResultsProps> = ({
  productQuery,
  results = [],
  error,
  className,
  resetFullyToLander,
}) => {
  const hasAlternates =
    results?.length &&
    (results[0].alt1 !== '' ||
      results[0].alt2 !== '' ||
      results[0].alt3 !== '');

  return (
    <div className={className}>
      <InitialResultTable
        results={results}
        error={error}
        resetFullyToLander={resetFullyToLander}
        requestedQuantity={productQuery.requestedQuantity}
        productType={productQuery.characteristics.productType}
      />
      {hasAlternates && (
        <AlternateBrands
          productToFindAlternatesFor={results?.length ? results[0] : null}
          resetFullyToLander={resetFullyToLander}
          requestedDate={productQuery.requestedDate}
          requestedQuantity={productQuery.requestedQuantity}
          productType={productQuery.characteristics.productType}
        />
      )}
      {results?.length && (
        <AlternateSizes
          productQuery={productQuery}
          resetFullyToLander={resetFullyToLander}
        />
      )}
    </div>
  );
};

export default SearchResults;
