import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BasketPopUpBasketProps from '../../../../components/BasketPopUp/BasketPopUpBasketProps';
import BasketPopUpButton from '../../../../components/BasketPopUp/BasketPopUpButton';
import BasketPopUpButtons from '../../../../components/BasketPopUp/BasketPopUpButtons';
import BasketPopUpHeader from '../../../../components/BasketPopUp/BasketPopUpHeader';
import BasketPopUpItemProps from '../../../../components/BasketPopUp/BasketPopUpItemProps';
import BasketPopUpWrapper from '../../../../components/BasketPopUp/BasketPopUpWrapper';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { SamplesOrder } from '../../../../types/Samples/SamplesOrder.interface';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import useAnalytics from '../../../../hooks/useAnalytics';

interface IAddedToBasketPopupProps {
  onClose: () => void;
  order?: SamplesOrder;
  orderIsFetching: boolean;
  product: SamplesProduct | null;
}

const AddedToBasketPopup: React.FunctionComponent<IAddedToBasketPopupProps> = ({
  onClose,
  order,
  orderIsFetching,
  product,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { trackPageView } = useAnalytics();
  const pathName = location.pathname;

  const numItems = order?.products?.length || 0;

  const basketEnabled = !(
    order?.buyerNumber === null || order?.buyerNumber === undefined
  );

  const checkoutEnabled =
    pathName !== '/samples/selectBuyer' && !basketEnabled && numItems > 0;

  const goToBasket = (): void => {
    trackPageView('SAMPLES_BASKET', 'VIEW_BASKET');
    history.push('/samples/basket');
  };

  const goToCheckout = (): void => {
    trackPageView('SAMPLES_BASKET', 'CONTINUE_CHECKOUT');
    history.push('/samples/selectBuyer');
  };

  return (
    <>
      {orderIsFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        <BasketPopUpWrapper>
          <BasketPopUpHeader>
            <Trans>Added to Basket</Trans>
          </BasketPopUpHeader>
          <BasketPopUpItemProps>
            {product ? (
              product.materialDescription
            ) : (
              <Trans>No items recently added to your basket.</Trans>
            )}
          </BasketPopUpItemProps>
          <Space direction="vertical" size="small" className="w-full px-2">
            <BasketPopUpBasketProps>
              {numItems} <Trans>items in your basket.</Trans>
            </BasketPopUpBasketProps>
            <BasketPopUpButtons>
              {basketEnabled && (
                <BasketPopUpButton theme="inverse" onClick={goToBasket}>
                  <Trans>View Basket</Trans>
                </BasketPopUpButton>
              )}
              {checkoutEnabled && (
                <BasketPopUpButton theme="inverse" onClick={goToCheckout}>
                  <Trans>Checkout</Trans>
                </BasketPopUpButton>
              )}
              <BasketPopUpButton theme="primary" onClick={onClose}>
                <Trans>Continue Shopping</Trans>
              </BasketPopUpButton>
            </BasketPopUpButtons>
          </Space>
        </BasketPopUpWrapper>
      )}
    </>
  );
};

export default AddedToBasketPopup;
