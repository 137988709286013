import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Moment } from 'moment';
import React, { FC } from 'react';
import Table from '../../../components/Table/Table';
import { BASE_COLUMNS } from '../BaseColumns';
import { TrackAndTraceData } from '../types/TrackAndTraceData.interface';
import TrackAndTraceStep from '../types/TrackAndTraceStep.enum';
import ArrivalDatesCell from './ArrivalDatesCell';

interface IResultsTableProps {
  data: TrackAndTraceData[];
  showExcessResultsMessage?: boolean;
  step: TrackAndTraceStep;
}

const ResultsTable: FC<IResultsTableProps> = ({
  data,
  showExcessResultsMessage,
  step,
}) => {
  const columns: ColumnsType<TrackAndTraceData> = [
    ...BASE_COLUMNS,
    {
      title: t`Arrival/Departure Date & Time`,
      key: 'arrivalDepartTimes',
      className: 'align-top',
      render: (value, record, index) => (
        <ArrivalDatesCell
          arrivalDate={record.arrivalDate || 0}
          arrivalTime={record.arrivalTime}
          departureDate={record.departureDate || 0}
          departureTime={record.departureTime}
          newArrivalDate={record.newArrivalDate as Moment}
          newDepartureDate={record.newDepartureDate as Moment}
          step={step}
          index={index}
        />
      ),
    },
  ];

  return (
    <Space direction="vertical" className="w-full">
      {showExcessResultsMessage && (
        <div>
          <Trans>
            *Results returned below are limited to 250. Please use additional
            filters for more specificity.
          </Trans>
        </div>
      )}
      <Table
        rowKey={(record) => JSON.stringify(record)}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </Space>
  );
};

export default ResultsTable;
