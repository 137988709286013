import * as React from 'react';

const BasketPopUpHeader: React.FunctionComponent<React.HTMLProps<
  HTMLDivElement
>> = ({ className = '', children, ...rest }) => {
  return (
    <div
      className={`p-2.5 font-bold text-lg text-white-white bg-gray-dark100 ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default BasketPopUpHeader;
