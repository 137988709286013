import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { useForm, ValidateResult } from 'react-hook-form';
import { Button } from '../../components/Button/Button';
import InputWithHiddenLabel from '../../components/InputWithHiddenLabel/InputWithHiddenLabel';
import GetPrintableLabelsParams from './types/GetPrintableLabelsParams.type';
import SearchCriteriaFormData from './types/SearchCriteriaFormData.type';
import SearchCriteriaFormField from './types/SearchCriteriaFormField.enum';
import useAnalytics from '../../hooks/useAnalytics';


const MIN_SEARCH_CRITERIA_ERR_MSG = t`To perform a search of available labels, please enter at minimum My Order # or Sappi Order # and Sappi Item # or a Roll or Pallet #`;

const DEFAULT_FORM_VALUES: SearchCriteriaFormData = {
  [SearchCriteriaFormField.LABEL_PO]: '',
  [SearchCriteriaFormField.LABEL_PO_ITEM]: '',
  [SearchCriteriaFormField.SALES_DOC]: '',
  [SearchCriteriaFormField.SALES_DOC_ITEM]: '',
  [SearchCriteriaFormField.REEL_NUM]: '',
};

interface SearchCriteriaProps {
  onSearch: (searchParams: GetPrintableLabelsParams) => void;
  onReset: () => void;
}

const SearchCriteria: FC<SearchCriteriaProps> = ({ onSearch, onReset }) => {
  const {
    formState: { isDirty, isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    trigger,
  } = useForm<SearchCriteriaFormData>({
    mode: 'all',
    defaultValues: DEFAULT_FORM_VALUES,
  });
  const { trackPageView } = useAnalytics();

  const validateMinSearchCriteria = (): ValidateResult => {
    const values = getValues();

    const hasOrderNum = !!values[SearchCriteriaFormField.LABEL_PO];
    const hasSappiOrderInfo =
      !!values[SearchCriteriaFormField.SALES_DOC] &&
      !!values[SearchCriteriaFormField.SALES_DOC_ITEM];
    const hasReelNum = !!values[SearchCriteriaFormField.REEL_NUM];

    const fieldIsValid = hasOrderNum || hasSappiOrderInfo || hasReelNum;

    return fieldIsValid ? true : MIN_SEARCH_CRITERIA_ERR_MSG;
  };

  const doSubmit = (values: SearchCriteriaFormData) => {
    trackPageView('PRINT_LABELS', 'SEARCH');
    onSearch(values);
  };

  const doReset = () => {
    reset();
    onReset();
  };

  return (
    <form onSubmit={handleSubmit(doSubmit)}>
      <div className="my-11">
        <Space direction="vertical" className="w-full" size="middle">
          <div className="flex">
            <InputWithHiddenLabel
              name={SearchCriteriaFormField.LABEL_PO}
              ref={register({
                validate: {
                  minSearchCriteria: validateMinSearchCriteria,
                },
              })}
              label={t`My Order #`}
              wrapperProps={{ className: 'w-80' }}
            />
            <InputWithHiddenLabel
              name={SearchCriteriaFormField.LABEL_PO_ITEM}
              ref={register()}
              onChange={() => trigger(SearchCriteriaFormField.LABEL_PO)}
              label={t`My Order Item #`}
              wrapperProps={{ className: 'w-80' }}
            />
          </div>
          <div className="flex">
            <InputWithHiddenLabel
              name={SearchCriteriaFormField.SALES_DOC}
              ref={register()}
              onChange={() => trigger(SearchCriteriaFormField.LABEL_PO)}
              label={t`Sappi Order #`}
              wrapperProps={{ className: 'w-80' }}
            />
            <InputWithHiddenLabel
              name={SearchCriteriaFormField.SALES_DOC_ITEM}
              ref={register()}
              onChange={() => trigger(SearchCriteriaFormField.LABEL_PO)}
              label={t`Sappi Order Item #`}
              wrapperProps={{ className: 'w-80' }}
            />
          </div>
          <InputWithHiddenLabel
            name={SearchCriteriaFormField.REEL_NUM}
            ref={register()}
            onChange={() => trigger(SearchCriteriaFormField.LABEL_PO)}
            label={t`Roll or Pallet #`}
            wrapperProps={{ className: 'w-80' }}
          />
        </Space>
      </div>
      <div className="pt-8 border-t border-color-gray-light400">
        <Space size="large">
          <Space size="middle">
            <Button type="submit" theme="primary" disabled={!isValid}>
              <Trans>Search</Trans>
            </Button>
            <Button
              onClick={doReset}
              type="reset"
              theme="link"
              className="text-lg font-bold"
            >
              <Trans>Reset</Trans>
            </Button>
          </Space>
          {isDirty && !isValid && (
            <div className="text-red-red" style={{ maxWidth: '450px' }}>
              {MIN_SEARCH_CRITERIA_ERR_MSG}
            </div>
          )}
        </Space>
      </div>
    </form>
  );
};

export default SearchCriteria;
