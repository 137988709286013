import { t } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Space } from 'antd';
import useAnalytics from '../../../hooks/useAnalytics';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import Select from '../../../components/Select/Select';
import { CalculatorUnitTypes } from '../types/CalculatorUnitTypes.enum';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import ImperialCriteria from './ImperialCriteria';
import MetricCriteria from './MetricCriteria';
import { LinearLengthToArea } from './LinearLengthToArea.interface';
import { METRIC_IMP_OPTIONS } from '../Calculators.util';
import { getLengthToArea } from '../Calculator.service';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';

const LinearLengthToAreaCalculator: FC = () => {
  const {
    register,
    reset,
    errors,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = useForm({ mode: 'all' });
  const { trackPageView } = useAnalytics();
  const { measurementSystem } = useMeasurementSystem();
  const [isCalculating, setIsCalculating] = useState(false);

  const metImp = watch('metImp');
  const isMetric = metImp === CalculatorUnitTypes.MET;
  const [result, setResult] = useState<string>();
  const defaultMetImp =
    measurementSystem === MeasurementSystem.METRIC
      ? CalculatorUnitTypes.MET
      : CalculatorUnitTypes.IMP;

  useEffect(() => {
    setValue('metImp', defaultMetImp);
  }, [defaultMetImp, setValue]);

  const onCalculate = async (data: LinearLengthToArea) => {
    trackPageView('CALCULATORS', 'LENGTH_TO_AREA');
    setIsCalculating(true);
    const response = await getLengthToArea(data);
    setResult(`${isMetric ? response.squareArea : response.squareAreaImp}`);
    setIsCalculating(false);
  };

  const onReset = () => {
    reset({
      metImp: defaultMetImp,
    });
    setResult('');
  };
  
  const onMetricImperialChange = (selectedValue: CalculatorUnitTypes) => {
    reset({
      metImp: selectedValue,
    });
    setResult('');
  };
  
  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.LINEAR_LENGTH_TO_AREA}
      instructions={t`All fields must be filled in to calculate`}
      onCalculate={handleSubmit(onCalculate)}
      isCalculating={isCalculating}
      isCalculateDisabled={!isValid || isCalculating}
      onReset={() => onReset()}
      result={
        <div className="flex justify-center -mx-4">
          {result}&nbsp;
          <div className="text-left">
            {isMetric ? t`Thousand Square Meters` : t`Thousand Square Feet`}
          </div>
        </div>
      }
    >
      <Space direction="vertical" size="middle" className="w-full">
        <Select
          name="metImp"
          ref={register({ required: true })}
          options={METRIC_IMP_OPTIONS}
          required
          onChange={(e) =>
            onMetricImperialChange(e.target.value as CalculatorUnitTypes)
          }
        />
        {isMetric && <MetricCriteria register={register} errors={errors} />}
        {!isMetric && <ImperialCriteria register={register} errors={errors} />}
      </Space>
    </CalculatorTemplate>
  );
};

export default LinearLengthToAreaCalculator;
