import { Trans } from '@lingui/macro';
import React from 'react';
import Date from '../../../components/Date';
import { ProductionPlan } from '../../../types/ProductionPlan.interface';
import styles from './PlannedProductionResultTable.module.scss';

interface IPlannedProductionResultTableProps {
  data: ProductionPlan[];
}

const PlannedProductionResultTable: React.FunctionComponent<IPlannedProductionResultTableProps> = ({
  data,
}) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <td>
            <Trans>Location</Trans>
          </td>
          <td>
            <Trans>Start Date</Trans>
          </td>
          <td>
            <Trans>Ship Date</Trans>
          </td>
          <td>
            <Trans>LDR Date</Trans>
          </td>
          <td>
            <Trans>Run Status</Trans>
          </td>
        </tr>
      </thead>
      <tbody>
        {data.map((plan, index) => (
          <tr key={plan.latestDateOfRevision}>
            {index === 0 && (
              <td rowSpan={data.length} valign="baseline">
                <strong>{plan.plantName}</strong>
              </td>
            )}
            <td align="right">
              <Date date={plan.startDate} />
            </td>
            <td align="right">
              <Date date={plan.shipDate} />
            </td>
            <td align="right">
              <Date date={plan.latestDateOfRevision} />
            </td>
            {plan.status === 'O' && (
              <td>
                <Trans>Open</Trans>
              </td>
            )}
            {plan.status === 'C' && (
              <td>
                <Trans>Closed</Trans>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PlannedProductionResultTable;
