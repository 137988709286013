import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import positiveIntegerOnlyInput from '../../../../services/Util/positiveIntegerOnlyInput.util';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import AvailabilityBasketType from '../../../../types/AvailabilityBasketType.enum';
import CustomerBasketFormData from './types/CustomerBasketFormData.interface';
import CustomerBasketFormField from './types/CustomerBasketFormField.enum';

interface QuantityCellContentProps {
  basketItem: AvailabilityBasketItem;
  formMethods: UseFormMethods<CustomerBasketFormData>;
  itemIndex: number;
}

const QuantityCellContent: FC<QuantityCellContentProps> = ({
  basketItem,
  formMethods,
  itemIndex,
}) => {
  const { format: formatNumber } = useNumberFormatter();
  const { isMetric } = useMeasurementSystem();
  const isProduct = basketItem.basketType === AvailabilityBasketType.PRODUCT;
  const { errors, register } = formMethods;

  return (
    <div className="mr-4">
      {isProduct && (
        <div className="font-bold text-blue-pacific uppercase">
          <Trans>Planned Production</Trans>
        </div>
      )}
      <Space direction="vertical" className="w-full">
        <Space size="middle">
          <Input
            name={`items.${itemIndex}.${CustomerBasketFormField.REQUESTED_QUANTITY}`}
            ref={register({ required: true, min: 1, max: 999999999999999 })}
            error={
              errors?.items?.[itemIndex]?.[
                CustomerBasketFormField.REQUESTED_QUANTITY
              ]
            }
            onKeyDown={positiveIntegerOnlyInput}
            id="quantity"
            width="full"
            required
          />
          <InputLabel
            htmlFor="quantity"
            text={basketItem.requestedUnitOfMeasure}
            size="lg"
          />
        </Space>
        {isMetric ? (
          <div>
            {formatNumber(Math.round(basketItem.quantityKg || 0))}{' '}
            <Trans>kg</Trans>
          </div>
        ) : (
          <div>
            {formatNumber(Math.round(basketItem.quantityLbs || 0))}{' '}
            <Trans>pounds</Trans>
          </div>
        )}
      </Space>
    </div>
  );
};

export default QuantityCellContent;
