import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import {
  VALID_EMAIL_REG_EX,
  VALID_PHONE_NUMBER,
} from '../../../../types/RegularExpression.constants';
import { DeliveryDetailsForm } from './types/DeliveryDetailsForm.type';
import DeliveryDetailsFormField from './types/DeliveryDetailsFormFields.enum';

interface IContactInformationProps {
  register: UseFormMethods<DeliveryDetailsForm>['register'];
  errors: UseFormMethods<DeliveryDetailsForm>['errors'];
  isNewDeliveryLocation: boolean;
}

const ContactInformation: FC<IContactInformationProps> = ({
  register,
  errors,
  isNewDeliveryLocation,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <InputLabel text={t`Delivery Contact`} size="lg">
        <Input
          name={DeliveryDetailsFormField.CONTACT_NAME}
          ref={register({ required: isNewDeliveryLocation, maxLength: 28 })}
          placeholder={t`First and Last Name`}
          maxLength={28}
          required={isNewDeliveryLocation}
        />
      </InputLabel>
      <Input
        name={DeliveryDetailsFormField.PHONE}
        ref={register({
          required: isNewDeliveryLocation,
          pattern: VALID_PHONE_NUMBER,
        })}
        placeholder={t`Phone Number in 1-555-555-5555 Format`}
        required={isNewDeliveryLocation}
        error={errors[DeliveryDetailsFormField.PHONE]}
      />
      <Input
        name={DeliveryDetailsFormField.EMAIL}
        ref={register({ pattern: VALID_EMAIL_REG_EX, maxLength: 28 })}
        placeholder={t`Email Address`}
        maxLength={28}
        error={errors[DeliveryDetailsFormField.EMAIL]}
      />
      <Input
        name={DeliveryDetailsFormField.RECEIVING}
        ref={register({ maxLength: 72 })}
        maxLength={72}
        placeholder={t`Receiving days/hours`}
      />
    </Space>
  );
};

export default ContactInformation;
