import create from 'zustand';

type RecreateEnquiryState = {
  enquiryRecreating: boolean;
};

const useRecreateEnquiry = create<RecreateEnquiryState>(() => ({
  enquiryRecreating: false,
}));

export default useRecreateEnquiry;
