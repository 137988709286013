import HeaderItemKey from '../domain/PageHeader/types/HeaderItemKey.enum';
import { AuthorityRole } from '../types/Authority.interface';
import useRole from './useRole';

interface IUsePageHeaderItems {
  basePageHeaderItemSet: HeaderItemKey[]; // used whereever a header is present
  commonPageHeaderItemSet: HeaderItemKey[]; // most frequent combination of page header items
}

const usePageHeaderItems = (): IUsePageHeaderItems => {
  const { hasRole } = useRole();

  const isBasicBasketShown =
    (hasRole(AuthorityRole.ROLE_EU) && hasRole(AuthorityRole.ROLE_RELEASE) ||
    hasRole(AuthorityRole.ROLE_NA)) &&
      hasRole(AuthorityRole.ROLE_ORDER_BOOKING);

  const isNonReleaseEuBasketShown =
    hasRole(AuthorityRole.ROLE_EU) &&
    !hasRole(AuthorityRole.ROLE_RELEASE) &&
    !hasRole(AuthorityRole.ROLE_TRACK_AND_TRACE);
  
  const isNaStockBookShown = 
    hasRole(AuthorityRole.ROLE_NA) && 
    hasRole(AuthorityRole.ROLE_STOCK_VIEW) &&
    hasRole(AuthorityRole.ROLE_STOCK_REPORT) && 
    !hasRole(AuthorityRole.ROLE_RELEASE);
  
  const isReleaseStockBookShown = 
    hasRole(AuthorityRole.ROLE_RELEASE) && 
    hasRole(AuthorityRole.ROLE_STOCK_VIEW) &&
    hasRole(AuthorityRole.ROLE_STOCK_REPORT);

  const getBaseHeaderItems = () => 
    isNaStockBookShown || isReleaseStockBookShown
        ? [
            HeaderItemKey.EXCEL_NOTIFICATION,
            HeaderItemKey.STOCK_BOOK,
            HeaderItemKey.CALCULATOR,
            HeaderItemKey.HELP,
          ]
        : [
            HeaderItemKey.EXCEL_NOTIFICATION,
            HeaderItemKey.CALCULATOR,
            HeaderItemKey.HELP,
          ]; 
  
  const getCommmonHeaderItems = () => {
    let basket: HeaderItemKey[] = [];
    if (isBasicBasketShown) {
      basket = [HeaderItemKey.NA_BASKET, ...getBaseHeaderItems()];
    }
    if (isNonReleaseEuBasketShown) {
      basket = [HeaderItemKey.EU_BASKET, ...getBaseHeaderItems()];
    }
    if (!isBasicBasketShown && !isNonReleaseEuBasketShown) {
      // Still need to show the other base header items regardless of the basket permissions.
      basket = [...getBaseHeaderItems()];
    }
    return basket;
  };

  return {
    basePageHeaderItemSet: getBaseHeaderItems(),
    commonPageHeaderItemSet: getCommmonHeaderItems(),
  };
};

export default usePageHeaderItems;
