import { t, Trans } from '@lingui/macro';
import { Collapse, Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import ExpandButton from '../../../components/ExpandButton/ExpandButton';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import SearchFormField from '../types/SearchFormField.enum';
import { ISearchForm } from '../types/TSearchForm.type';

const { Panel } = Collapse;
interface IAdvancedSearchFormProps {
  register: UseFormMethods<ISearchForm>['register'];
}

const AdvancedSearchForm: FC<IAdvancedSearchFormProps> = ({ register }) => {
  return (
    <Collapse
      ghost
      expandIcon={({ isActive }) => (
        <ExpandButton
          isExpanded={isActive || false}
          style={{ left: '-60px', position: 'relative', margin: '-20px 0' }}
        >
          <Trans>Advanced Search</Trans>
        </ExpandButton>
      )}
    >
      <Panel key="advancedSearch" header="">
        <Space size="large" direction="vertical" className="w-full">
          <InputLabel text={t`Subcontractor`} size="lg">
            <Input
              name={SearchFormField.SUBCONTRACTOR}
              ref={register()}
              width="full"
            />
          </InputLabel>
          <InputLabel text={t`Truck Reference`} size="lg">
            <Input
              name={SearchFormField.REF_FORWARDING_AGENT}
              ref={register()}
              width="full"
            />
          </InputLabel>
        </Space>
      </Panel>
    </Collapse>
  );
};

export default AdvancedSearchForm;
