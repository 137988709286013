import create from 'zustand';
import { SearchOrderInputs } from './SearchOrderInputs.interface';

type TrackOrdersState = {
  storedQuery: Partial<SearchOrderInputs> | undefined;
  setStoredQuery: (storedQuery: Partial<SearchOrderInputs>) => void;
};

const useTrackOrdersStore = create<TrackOrdersState>((set) => ({
  storedQuery: undefined,
  setStoredQuery: (storedQuery: Partial<SearchOrderInputs>) =>
    set(() => ({ storedQuery })),
}));

export default useTrackOrdersStore;
