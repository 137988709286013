import { t } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import { METRIC_NUMBER_ONE_DECIMAL_REG_EX } from '../../../../types/RegularExpression.constants';

const MAX_REQUIRED = 999;
interface IMetricCriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

const MetricCriteria: React.FunctionComponent<IMetricCriteriaProps> = ({
  register,
  errors,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Input
        name="grammage"
        type="number"
        ref={register({
          required: true,
          min: 0.1,
          max: MAX_REQUIRED,
          pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
        })}
        required
        error={errors?.grammage}
        placeholder={t`Grammage`}
      />
      <Input
        name="reelWidth"
        type="number"
        ref={register({
          required: true,
          max: MAX_REQUIRED,
          pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
        })}
        required
        error={errors?.reelWidth}
        placeholder={t`Width (cm)`}
      />
      <Input
        name="reelDiam"
        type="number"
        ref={register({
          required: true,
          max: MAX_REQUIRED,
          pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
        })}
        required
        error={errors?.reelDiam}
        placeholder={t`Diameter (cm)`}
      />
      <Input
        name="coreDiam"
        type="number"
        ref={register({
          required: true,
          max: MAX_REQUIRED,
          pattern: METRIC_NUMBER_ONE_DECIMAL_REG_EX,
        })}
        required
        error={errors?.coreDiam}
        placeholder={t`Core Diameter (cm)`}
      />
    </Space>
  );
};

export default MetricCriteria;
