import { Trans } from '@lingui/macro';
import { capitalize } from 'lodash';
import * as React from 'react';
import { DateFormat } from '../../hooks/useDateFormatter';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';
import { DeliveryResponse } from '../../types/DeliveryResponse.interface';
import Date from '../Date';
import LabelValue from '../LabelValue/LabelValue';
import stripZeros from '../../services/Util/stripZeros.util';

interface IShipmentDetailProps {
  deliveryInfo: DeliveryResponse;
  index: number;
}

const ShipmentDetail: React.FunctionComponent<IShipmentDetailProps> = ({
  deliveryInfo,
  index,
}) => {
  const { details } = deliveryInfo;
  const { hasRole } = useRole();
  const { formatWithUnit } = useNumberFormatter();
  return (
    <div className="py-4 px-10">
      <div className="mb-4">
        <strong>
          <Trans id="Shipment">Shipment</Trans> {index + 1}
        </strong>
      </div>
      <LabelValue
        className="mb-2"
        inline
        label={
          <Trans id="delivery.confirmedDelivery">Confirmed Delivery Date</Trans>
        }
        value={<Date date={details.confirmedDeliveryDate} />}
      />
      {!stripZeros(details.shipToPo) && <div className="mb-4" />}
      {stripZeros(details.shipToPo) && (
        <LabelValue
          className="mb-6"
          inline
          label={<Trans>Ship To PO #</Trans>}
          value={stripZeros(details.shipToPo)}
        />
      )}
      <div className="flex">
        <div className="w-1/2">
          <LabelValue
            inline
            center
            label={
              <Trans id="delivery.requestedDelivery">
                Requested Delivery Date
              </Trans>
            }
            value={<Date date={details.requestedDeliveryDate} />}
          />
          <LabelValue
            inline
            center
            label={<Trans id="delivery.shipmentDate">Shipment Date</Trans>}
            value={<Date date={details.shipDate} />}
          />
          <LabelValue
            inline
            center
            label={
              <Trans id="delivery.estArrivalDate">Est. Arrival Date</Trans>
            }
            value={<Date date={details.arrivalDate} />}
          />
          {hasRole(AuthorityRole.ROLE_EU) && details.reasonText && (
            <LabelValue
              inline
              center
              label={
                <Trans id="delivery.deviationReason">Deviation Reason</Trans>
              }
              value={
                <div>
                  {details.reasonText} - <Date date={details.arrivalDate} />{' '}
                </div>
              }
            />
          )}
          <br />

          {deliveryInfo.latestEvent && (
            <>
              <LabelValue
                inline
                center
                label={<Trans id="delivery.status">Status</Trans>}
                value={
                  deliveryInfo &&
                  deliveryInfo.latestEvent &&
                  deliveryInfo.latestEvent.status &&
                  capitalize(deliveryInfo.latestEvent.status.toLowerCase())
                }
              />
              <LabelValue
                inline
                center
                label={<Trans id="delivery.trackLocation">Location</Trans>}
                value={deliveryInfo.latestEvent.location}
              />
              <LabelValue
                inline
                center
                label={<Trans id="delivery.trackDate">Date</Trans>}
                value={
                  <>
                    {deliveryInfo.latestEvent &&
                      deliveryInfo.latestEvent.messageDate && (
                        <Date date={deliveryInfo.latestEvent.messageDate} />
                      )}{' '}
                    {deliveryInfo.latestEvent &&
                      deliveryInfo.latestEvent.messageTime && (
                        <Date
                          date={deliveryInfo.latestEvent.messageTime}
                          format={DateFormat.BASIC_TIME}
                        />
                      )}
                  </>
                }
              />
            </>
          )}
        </div>

        <div className="w-1/2">
          <LabelValue
            inline
            center
            label={<Trans>Bill of Lading #</Trans>}
            value={details.deliveryNote}
          />
          <LabelValue
            inline
            center
            label={<Trans id="delivery.freight">Freight #</Trans>}
            value={stripZeros(details.transitId)}
          />
          <LabelValue
            inline
            center
            label={<Trans id="delivery.weightlist">Weightlist #</Trans>}
            value={stripZeros(details.weightListNumber)}
          />
          <LabelValue
            inline
            center
            label={<Trans id="delivery.quantity">Gross Quantity</Trans>}
            value={formatWithUnit(details.quantity)}
          />
          {details.sheetCount > 0 && (
            <LabelValue
              inline
              center
              label={<Trans id="delivery.sheet.count">Sheet Count</Trans>}
              value={details.sheetCount}
            />
          )}
          <LabelValue
            inline
            center
            label={<Trans>Container/Trailer #</Trans>}
            value={details.payloadInfo}
          />
          <LabelValue
            inline
            center
            label={<Trans id="delivery.forwarder">Forwarding Agent</Trans>}
            value={details.forwardingAgent}
          />
          {details.plTypeDesc && (
            <LabelValue
              inline
              center
              label={<Trans id="delivery.plTypeDesc">Mode of Transport</Trans>}
              value={details.plTypeDesc}
            />
          )}
          {hasRole(AuthorityRole.ROLE_NA) && details.consigneeName && (
            <LabelValue
              inline
              center
              label={<Trans id="delivery.consignedTo">Reconsigned To</Trans>}
              value={details.consigneeName}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetail;
