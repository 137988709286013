import { t, Trans } from '@lingui/macro';
import { orderBy, upperCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import excelIconSmallImage from '../../assets/eu/excel-icon-small.png';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../hooks/useAnalytics';
import { getComplaints } from '../../services/Complaints';
import Complaints from '../../types/Complaints.interface';
import QueryCacheName from '../../types/QueryCacheName.enum';
import AttachmentModal from './AttachmentModal';
import ClaimsReportingTable from './ClaimsReportingTable';
import DescriptionModal from './DescriptionModal';
import DownloadClaims from './DownloadClaims';
import SelectCustomer from './SelectCustomer';
import ComplaintDocument from '../../types/ComplaintDocument.interface';
import DocumentModal from './DocumentModal';
import useRole from '../../hooks/useRole';

interface IClaimReportProps {
  prop?: unknown;
}

const ClaimsReporting: React.FunctionComponent<IClaimReportProps> = (props) => {
  const { customerNumber: customerNumberParam } = useParams<{
    customerNumber: string;
  }>();
  const [selectedCustomerNum, setSelectedCustomerNum] = useState<string>(
    customerNumberParam
  );
  const [complaints, setComplaints] = useState<Complaints[]>();
  const [isAttachmentModalVisible, setIsAttachmentModalVisible] = useState(
    false
  );
  const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(
    false
  );
  const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
  const [modalComplaintNotes, setModalComplaintNotes] = useState(
    [] as ComplaintDocument[]
  );
  const [modalComplaintNumber, setModalComplaintNumber] = useState('');
  const [modalSalesDocumentNumber, setModalSalesDocumentNumber] = useState('');
  const { trackPageView } = useAnalytics();
  const history = useHistory();
  const { isComplaintCreator } = useRole();

  const { resolvedData: data, isFetching } = usePaginatedQuery(
    [QueryCacheName.COMPLAINTS, selectedCustomerNum],
    getComplaints,
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: selectedCustomerNum,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setComplaints(
        orderBy(data, ['createDate', 'complaintNumber'], ['desc', 'desc'])
      );
    }
  }, [data]);

  const openAttachmentModal = (record: Complaints) => {
    setModalComplaintNumber(record.complaintNumber);
    setModalSalesDocumentNumber(record.salesDocumentNumber);
    setIsAttachmentModalVisible(true);
    trackPageView('REPORTING', 'COMPLAINT_VIEW_ATTACH', {
      customerId: selectedCustomerNum,
    });
  };

  const handleAttachmentLinkClick = (
    record: Complaints,
    bypassModal?: boolean
  ) => {
    if (bypassModal) {
      const fileNumber = (record?.numberOfAttachments || 0) + 1;
      history.push(
        `/reporting/claimReport/addAttachment/${record.complaintNumber}/${fileNumber}/${selectedCustomerNum}`
      );
    } else {
      openAttachmentModal(record);
    }
  };

  const openDescriptionModal = (record: Complaints) => {
    setModalComplaintNumber(record.complaintNumber);
    setIsDescriptionModalVisible(true);
    trackPageView('REPORTING', 'COMPLAINT_VIEW_DESC', {
      customerId: selectedCustomerNum,
    });
  };

  const openDocumentNoteModal = (record: Complaints) => {
    setModalComplaintNotes(record.complaintDocuments);
    setIsDocumentModalVisible(true);
    trackPageView('REPORTING', 'COMPLAINT_VIEW_CREDIT_DEBIT', {
      customerId: selectedCustomerNum,
    });
  };

  const handleDocumentNoteLinkClick = (record: Complaints) => {
    if (record.complaintDocuments) {
      openDocumentNoteModal(record);
    }
  };

  return (
    <>
      <div className="flex" style={{ minHeight: '75px' }}>
        <div className="whitespace-normal -mx-3.5">
          <div className="mx-3.5" style={{ width: '580px' }}>
            <SelectCustomer
              defaultCustomerNumber={customerNumberParam}
              setSelectedCustomerNum={setSelectedCustomerNum}
            />
          </div>
          {isComplaintCreator && (
            <div className="w-2/3 mx-3.5 mt-5">
              <Trans>To file a new claim, find your order in</Trans>{' '}
              <a className="app-link" href="#/orderTracking">
                <Trans>Track Orders</Trans>
              </a>{' '}
              <Trans>
                and select &apos;Available Actions&apos; or enter your Sappi
                order number and line number in
              </Trans>{' '}
              <a className="app-link" href="#/fileClaimSearch">
                <Trans>File a claim</Trans>
              </a>
            </div>
          )}
        </div>
        {selectedCustomerNum && (
          <div className="w-2/3 ml-10 border-t-4 border-blue-cobaltVariation">
            <div className="border-r border-b border-l border-gray-light400 align-right p-6 ">
              {isFetching ? (
                <LoadingSpinner horizontal />
              ) : (
                <>
                  <div className="flex justify-end font-bold text-xl mb-6 mt-1">
                    {upperCase(t`Download Excel Report`)}
                    <DownloadClaims complaints={complaints || []}>
                      <img
                        src={excelIconSmallImage}
                        alt={t`Excel Icon Small`}
                        className="ml-2 -mt-1"
                      />
                    </DownloadClaims>
                  </div>
                  <div className="mb-6 text-4xl font-bold text-blue-cerulean">
                    {complaints?.length}{' '}
                    {upperCase(t`Claims Received In The Last 6 Months`)}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {selectedCustomerNum && !isFetching && (
        <ClaimsReportingTable
          results={complaints}
          onAttachmentLinkClick={handleAttachmentLinkClick}
          onDescriptionLinkClick={openDescriptionModal}
          onDocumentNoteLinkClick={handleDocumentNoteLinkClick}
        />
      )}

      <AttachmentModal
        complaintNumber={modalComplaintNumber}
        customerNumber={selectedCustomerNum}
        salesDocumentNumber={modalSalesDocumentNumber}
        visible={isAttachmentModalVisible}
        onCancel={() => setIsAttachmentModalVisible(false)}
      />

      <DescriptionModal
        complaintNumber={modalComplaintNumber}
        visible={isDescriptionModalVisible}
        onCancel={() => setIsDescriptionModalVisible(false)}
      />

      <DocumentModal
        complaintDocuments={modalComplaintNotes}
        visible={isDocumentModalVisible}
        onCancel={() => setIsDocumentModalVisible(false)}
      />
    </>
  );
};

export default ClaimsReporting;
