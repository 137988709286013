import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Tabs, { TabPane } from '../../components/Tabs/Tabs';
import useLocationQueryParams from '../../hooks/useLocationQueryParams';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';
import AreaPerSheetCalculator from './AreaPerSheet/AreaPerSheetCalculator';
import BasisWeightConversionCalculator from './BasisWeightConversion/BasisWeightConversionCalculator';
import EqCalculator from './EqCalculator/EqCalculator';
import LengthConversionCalculator from './LengthConversion/LengthConversionCalculator';
import LinearLengthToAreaCalculator from './LinealLengthToArea/LinearLengthToAreaCalculator';
import MWeightCalculators from './MWeight/MWeight';
import PagesPerInchCalculator from './PagesPerInch/PagesPerInchCalculator';
import PriceCWT from './PriceCalculators/PriceCWT';
import PricePerTon from './PriceCalculators/PricePerTon';
import RollWeightAndLengthCalculator from './RollWeightAndLength/RollWeightAndLengthCalculator';
import SheetsToWeightCalculator from './SheetsToWeight/SheetsToWeightCalculator';
import SquareAreaWeightConversionCalculator from './SquareAreaWeightConversion/SquareAreaWeightConversionCalculator';
import ThicknessConversionCalculator from './ThicknessConversion/ThicknessConversionCalculator';
import WeightConversionCalculator from './WeightConversion/WeightConversionCalculator';
import { CALCULATOR_TAB_LABELS } from './types/Calculator.constants';
import NaOrReleaseCalculatorsTab from './types/NaOrReleaseCalculatorsTab.enum';

const NaOrReleaseCalculators: FC = () => {
  const queryParams = useLocationQueryParams();
  const [selectedTab, setSelectedTab] = useState<NaOrReleaseCalculatorsTab>();
  const history = useHistory();
  const { hasRole } = useRole();

  useEffect(() => {
    setSelectedTab(queryParams.get('type') as NaOrReleaseCalculatorsTab);
  }, [queryParams]);
  return (
    <Tabs
      type="card"
      activeKey={selectedTab}
      onChange={(key) => {
        history.push(`/calculators?type=${key}`);
      }}
    >
      {!hasRole(AuthorityRole.ROLE_RELEASE) && (
        <TabPane
          tab={
            CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.SHEET_CALCULATORS]
          }
          key={NaOrReleaseCalculatorsTab.SHEET_CALCULATORS}
          className="flex"
        >
          <div className="flex flex-wrap">
            <MWeightCalculators />
            <PagesPerInchCalculator />
            <SheetsToWeightCalculator />
            <AreaPerSheetCalculator />
          </div>
        </TabPane>
      )}
      <TabPane
        tab={CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.ROLL_CALCULATORS]}
        key={NaOrReleaseCalculatorsTab.ROLL_CALCULATORS}
      >
        <div className="flex flex-wrap">
          <RollWeightAndLengthCalculator />
          <LinearLengthToAreaCalculator />
          <SquareAreaWeightConversionCalculator />
        </div>
      </TabPane>
      <TabPane
        tab={CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.PRICE_CALCULATORS]}
        key={NaOrReleaseCalculatorsTab.PRICE_CALCULATORS}
      >
        <div className="flex flex-wrap">
          <PriceCWT />
          <PricePerTon />
        </div>
      </TabPane>
      <TabPane
        tab={
          CALCULATOR_TAB_LABELS[
            NaOrReleaseCalculatorsTab.CONVERSION_CALCULATORS
          ]
        }
        key={NaOrReleaseCalculatorsTab.CONVERSION_CALCULATORS}
      >
        <div className="flex flex-wrap">
          <WeightConversionCalculator />
          <LengthConversionCalculator />
          <BasisWeightConversionCalculator />
          <ThicknessConversionCalculator />
        </div>
      </TabPane>
      {!hasRole(AuthorityRole.ROLE_RELEASE) && (
        <TabPane
          tab={CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.EQ_CALCULATORS]}
          key={NaOrReleaseCalculatorsTab.EQ_CALCULATORS}
        >
          <EqCalculator />
        </TabPane>
      )}
    </Tabs>
  );
};

export default NaOrReleaseCalculators;
