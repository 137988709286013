import { usePaginatedQuery } from 'react-query';
import useRole from '../../hooks/useRole';
import {
  getBrandOptions,
  getBrands,
  getEuBrandOptions,
  QueryParams,
} from '../../services/Brand';
import { AuthorityRole } from '../../types/Authority.interface';
import Brand from '../../types/Brand.interface';
import EuBrand from '../../types/EuBrand.interface';
import Option from '../../types/Option.type';
import QueryCacheName from '../../types/QueryCacheName.enum';

const useBrandOptions = (
  params: Partial<QueryParams>,
  defaultSelectLabel?: string
): {
  rawBrands: EuBrand[] | Brand[];
  brandOptions: Option[];
  isFetchingBrands: boolean;
} => {
  const { hasRole } = useRole();
  const { data: brands = [], isFetching } = usePaginatedQuery(
    [QueryCacheName.BRANDS, params],
    getBrands,
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const brandOptions: Option[] = hasRole(AuthorityRole.ROLE_EU)
    ? getEuBrandOptions(brands as EuBrand[], {
        defaultSelectLabel,
      })
    : getBrandOptions(brands as Brand[], {
        defaultSelectLabel,
      });
  return {
    rawBrands: brands,
    brandOptions,
    isFetchingBrands: isFetching,
  };
};

export default useBrandOptions;
