import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import Modal from '../../components/Modal/Modal';
import Table from '../../components/Table/Table';
import ComplaintDocument from '../../types/ComplaintDocument.interface';
import stripZeros from '../../services/Util/stripZeros.util';

interface IDocumentModalProps {
  complaintDocuments: ComplaintDocument[];
  visible: boolean;
  onCancel: () => void;
}

const DocumentModal: React.FunctionComponent<IDocumentModalProps> = ({
  complaintDocuments,
  visible,
  onCancel,
}) => {
  const columns: ColumnsType<ComplaintDocument> = [
    {
      title: <Trans>Document Type</Trans>,
      key: 'documentType',
      render: (value, record) => (
        <>
          {record.documentType && record.documentType === 'C' && <><Trans>Credit Note</Trans></>}
          {record.documentType && record.documentType === 'D' && <><Trans>Debit Note</Trans></>}
        </>
      ),
    },
    {
      title: <Trans>Document Number</Trans>,
      key: 'documentNumber',
      render: (value, record) => (
        <>
            <a
              className="app-link"
              href={`../resources/complaints/creditNote/${record.documentID}`}
            >
              {stripZeros(record.documentNumber)}
            </a>
          </>
      ),
    },
  ];
  return (
    <Modal visible={visible} title="Credit/Debit Notes" onCancel={onCancel}>
      <Table
        columns={columns}
        dataSource={complaintDocuments}
        pagination={false}
        locale={{
          emptyText: (
            <div className="text-gray-600">
              <Trans>No Documents</Trans>
            </div>
          ),
        }}
      />
    </Modal>
  );
};

export default DocumentModal;