import * as React from 'react';
import { Trans } from '@lingui/macro';

interface IContainerViewRightColumnBottomRowProps {
  contValue: string;
  rlValue: string;
  rlWeightValue: string;
}

const ContainerViewRightColumnBottomRow: React.FunctionComponent<IContainerViewRightColumnBottomRowProps> = ({
  contValue,
  rlValue,
  rlWeightValue,
}) => {
  return (
    <div className="ml-4 text-xl font-bold">
      <span className="text-blue-malibu">{contValue}</span>{' '}
      <Trans>Containers</Trans> ={' '}
      <span className="text-blue-malibu">{rlValue}</span> <Trans>Rolls</Trans> (
      <span>{rlWeightValue}</span> <Trans>Kg</Trans>)
    </div>
  );
};

export default ContainerViewRightColumnBottomRow;
