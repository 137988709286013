import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { Button } from '../../components/Button/Button';

interface ISearchStepButtonsProps {
  onCancel: () => void;
  onSaveAndContinue: () => void;
  requirementsMessage?: string;
  errorMessage: string | null;
}

const SearchStepButtons: FC<ISearchStepButtonsProps> = ({
  onCancel,
  onSaveAndContinue,
  requirementsMessage,
  errorMessage,
}) => {
  return (
    <Space direction="vertical">
      {requirementsMessage && <div className="mb-2">{requirementsMessage}</div>}
      <Space size="middle">
        <Button type="reset" onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button
          type="button"
          theme="primary"
          onClick={onSaveAndContinue}
          disabled={!!errorMessage}
        >
          <Trans>Save And Continue</Trans>
        </Button>
      </Space>
      {!!errorMessage && <div className="text-red-red">{errorMessage}</div>}
    </Space>
  );
};

export default SearchStepButtons;
