import { t } from '@lingui/macro';
import useRole from '../../hooks/useRole';
import {
  getEuGrammageOptions,
  getGrammageOptions,
  QueryParams,
} from '../../services/Brand';
import { AuthorityRole } from '../../types/Authority.interface';
import Brand from '../../types/Brand.interface';
import EuBrand from '../../types/EuBrand.interface';
import Option from '../../types/Option.type';
import useBrandOptions from './useBrandOptions';

const useGrammageOptions = (
  params: Partial<QueryParams>,
  selectedBrand?: string
): { grammageOptions: Option[] } => {
  const { hasRole } = useRole();
  const { rawBrands: brands } = useBrandOptions(params);
  const grammageOptions: Option[] = hasRole(AuthorityRole.ROLE_EU)
    ? getEuGrammageOptions(
        brands as EuBrand[],
        'grammages',
        selectedBrand ? brands[+selectedBrand].brand : undefined,
        t`Grammage`
      )
    : getGrammageOptions(brands as Brand[], selectedBrand, t`Grammage`);
  return {
    grammageOptions,
  };
};

export default useGrammageOptions;
