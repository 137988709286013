import Axios from 'axios';
import parsePageInfoString from '../../services/Util/parsePageInfoString.util';
import { PageInfo } from '../../types/PageInfo.interface';
import DateRangeSelection from './types/DateRangeSelection.enum';
import { SavedEnquiryResponse } from './types/SavedEnquiryResponse.interface';

const BASE_URL = '/portal/resources/savedenquiries';

const getPageUrl = (page?: number) => (page ? `/page/${page}` : '');

const getSavedEnquiryUrl = (dateRange: DateRangeSelection, page?: number) => {
  let url;
  switch (dateRange) {
    case DateRangeSelection.LAST_WEEK:
      url = `${BASE_URL}/weeks/1`;
      break;
    case DateRangeSelection.PAST_MONTH:
      url = `${BASE_URL}/months/1`;
      break;
    case DateRangeSelection.PAST_3_MONTHS:
      url = `${BASE_URL}/months/3`;
      break;
    case DateRangeSelection.PAST_6_MONTHS:
      url = `${BASE_URL}/months/6`;
      break;
    default:
      url = `${BASE_URL}/months/1`;
  }
  return `${url}${getPageUrl(page)}`;
};

export const getSavedEnquiries = async (
  dateRange: DateRangeSelection,
  selectedPage?: number
): Promise<SavedEnquiryResponse> => {
  const { data, headers } = await Axios.get(
    getSavedEnquiryUrl(dateRange, selectedPage)
  );
  const pagination: Partial<PageInfo> = {
    page: parsePageInfoString(headers['page-info-page']),
    pageCount: parsePageInfoString(headers['page-info-pages']),
    recordCount: parsePageInfoString(headers['page-info-total']),
  };
  return {
    ...data,
    pagination,
  };
};

export const recreateEnquiry = async (
  enquiryNumber: string,
  salesOrg: string
): Promise<unknown> => {
  // Since there is no product availability for EU user now, only create direct basket
  // from saved inquiry, which makes it ready for order booking.
  // if (salesOrg === '1050' || salesOrg === '1060' || salesOrg === '1010') {
  const { data } = await Axios.post(
    `/portal/resources/eu/availability/basket/recreate/direct/${enquiryNumber}`
  );
  return data;
  // }
};
