import { t } from '@lingui/macro';
import { cloneDeep, each } from 'lodash';
import { GetSamplesOrdersParams } from '../../../types/Samples/GetSamplesOrdersParams.interface';
import { SamplesHistoryOrder } from '../../../types/Samples/SamplesHistoryOrder.interface';
import { FilterFormData } from './ViewSampleOrdersFilters';
import { AugmentedSamplesHistoryOrder } from '../../../types/Samples/AugmentedSamplesHistoryOrder.interface';

export const SAMPLES_HISTORY_ORDER_LOCKED = 'LOCK';

export const getParamsFromFilters = (
  filters: FilterFormData
): GetSamplesOrdersParams => {
  const params: GetSamplesOrdersParams = {};

  if (filters.customer) {
    params.customerNumber = filters.customer;
  }

  if (filters.status) {
    params.status = filters.status;
  }

  if (filters.documentRefNum) {
    params.documentNumber = filters.documentRefNum;
  }

  return params;
};

const alterUnitsOfOrders = (
  ogOrders: (SamplesHistoryOrder | AugmentedSamplesHistoryOrder)[]
): AugmentedSamplesHistoryOrder[] => {
  const orders = ogOrders.map((ogOrder) => {
    const alteredOrder = ogOrder;

    if (alteredOrder.unitSize > 1 && alteredOrder.salesUnit !== 'BOX') {
      alteredOrder.salesUnit = t`Units`;
    }
    if (alteredOrder.salesUnit === 'ST') {
      alteredOrder.salesUnit = 'PCE';
    }
    if (alteredOrder.unitSizeUnit === 'ST') {
      alteredOrder.unitSizeUnit = 'PCE';
    }
    return alteredOrder;
  });

  return orders;
};

const lumpOrdersByDocNum = (
  ogOrders: (SamplesHistoryOrder | AugmentedSamplesHistoryOrder)[]
): Record<string, (SamplesHistoryOrder | AugmentedSamplesHistoryOrder)[]> => {
  const ordersByDocNum: Record<
    string,
    (SamplesHistoryOrder | AugmentedSamplesHistoryOrder)[]
  > = {};

  ogOrders.forEach((ogOrder) => {
    const docNum = ogOrder.documentNumber;

    if (!ordersByDocNum[docNum]) {
      ordersByDocNum[docNum] = [];
    }
    ordersByDocNum[docNum].push(ogOrder);
  });

  return ordersByDocNum;
};

const groupOrders = (
  ogOrders: (SamplesHistoryOrder | AugmentedSamplesHistoryOrder)[]
): AugmentedSamplesHistoryOrder[] => {
  const groupedOrders: AugmentedSamplesHistoryOrder[] = [];

  const ordersByDocNum = lumpOrdersByDocNum(ogOrders);

  Object.values(ordersByDocNum).forEach((orders) => {
    const subOrders = [...orders];
    const topLevelOrder: AugmentedSamplesHistoryOrder | null =
      subOrders.shift() || null;

    if (topLevelOrder) {
      if (subOrders.length > 0) {
        topLevelOrder.items = [cloneDeep(topLevelOrder), ...subOrders];
        topLevelOrder.grouped = true;
        topLevelOrder.status = '';
        topLevelOrder.quantity = orders.reduce(
          (accumulator, currentOrder) => accumulator + currentOrder.quantity,
          0
        );
        topLevelOrder.shippingCost = orders.reduce(
          (accumulator, currentOrder) =>
            accumulator + currentOrder.shippingCost,
          0
        );
        topLevelOrder.price = orders.reduce(
          (accumulator, currentOrder) => accumulator + currentOrder.price,
          0
        );
        topLevelOrder.hasDummy = orders.some((order) => order.hasDummy);
      } else {
        topLevelOrder.hasDummy = topLevelOrder.materialNumber === 'DUMMY';
      }

      groupedOrders.push(topLevelOrder);
    }
  });

  return groupedOrders;
};

const alterStatusOfOrders = (
  ogOrders: (SamplesHistoryOrder | AugmentedSamplesHistoryOrder)[]
): AugmentedSamplesHistoryOrder[] => {
  const orders: AugmentedSamplesHistoryOrder[] = ogOrders;

  // Alter orderStatus to keep track of rolled up OPEN status for Action permissions.
  each(orders, (order) => {
    /* eslint-disable eqeqeq */
    if (order.status !== 'OPEN' && order.status != '') {
      /* eslint-enable eqeqeq */
      order.orderStatus = SAMPLES_HISTORY_ORDER_LOCKED;
      each(order.items, (item) => {
        item.orderStatus = SAMPLES_HISTORY_ORDER_LOCKED;
      });
    } else if (order.status === 'OPEN' || order.status === '') {
      each(order.items, (item) => {
        // Roll up shipping account number to order level for display.
        if (item.shippingAccountNumber !== '') {
          order.shippingAccountNumber = item.shippingAccountNumber;
        }

        if (item.status !== 'OPEN') {
          order.orderStatus = SAMPLES_HISTORY_ORDER_LOCKED;
          each(order.items, (i) => {
            i.orderStatus = SAMPLES_HISTORY_ORDER_LOCKED;
          });
        }
      });
    }
  });

  return orders;
};

export const transformOrders = (
  rawOrders: SamplesHistoryOrder[]
): AugmentedSamplesHistoryOrder[] => {
  // TODO: clean up the functionality in the below 3 functions (ported from Angular and left as-is for now), and/or write some unit tests around it.
  let transformedOrders = alterUnitsOfOrders(cloneDeep(rawOrders));

  transformedOrders = groupOrders(transformedOrders);

  transformedOrders = alterStatusOfOrders(transformedOrders);

  return transformedOrders;
};
