import create from 'zustand';
import { AvailabilityCustomer } from '../../types/AvailabilityCustomer.interface';
import { OrderBookingProductQuery } from '../../types/OrderBookingProductQuery.interface';

type OrderBookingState = {
  selectedCustomer: AvailabilityCustomer | undefined;
  storedQuery: Partial<OrderBookingProductQuery> | undefined;
};

const useOrderBookingStore = create<OrderBookingState>(() => ({
  selectedCustomer: undefined,
  storedQuery: undefined,
}));

export default useOrderBookingStore;
