import { Trans } from '@lingui/macro';
import * as React from 'react';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';

interface ITableMaterialNumCellProps {
  item: ConsignmentInventoryItem;
}

const TableMaterialNumCell: React.FunctionComponent<ITableMaterialNumCellProps> = ({
  item,
}) => {
  return (
    <>
      {item.customerMaterialNumber && (
        <div>
          <Trans>Customer Material #</Trans>: {item.customerMaterialNumber}
        </div>
      )}
      {item.kcode && (
        <div>
          <Trans>Sappi SKU</Trans>: {item.kcode}
        </div>
      )}
    </>
  );
};

export default TableMaterialNumCell;
