import { Space } from 'antd';
import React, { FC } from 'react';
import ArrowRight from '../../assets/Arrow-Thin-Right.svg';
import PromoPosition from '../../types/PromoPosition.enum';
import usePromoContent from './usePromoContent';

interface ILoginPromoProps {
  position: PromoPosition;
  lang?: string;
}

const LoginPromo: FC<ILoginPromoProps> = ({ position, lang }) => {
  const { promoContent } = usePromoContent(lang || '', position);

  return (
    <div className="border border-gray-light200">
      <a href={promoContent?.link.url} target="_blank" rel="noreferrer">
        <img src={promoContent?.image} alt={promoContent?.title} />
      </a>
      <Space direction="vertical" size="large" className="w-full p-5 pb-8">
        <a
          href={promoContent?.link.url}
          target="_blank"
          rel="noreferrer"
          className="text-xl font-bold"
        >
          {promoContent?.header}
          <img
            src={ArrowRight}
            alt=""
            className="ml-4 w-2 relative inline"
            style={{ top: '2px' }}
          />
        </a>
        <div>{promoContent?.summary}</div>
      </Space>
    </div>
  );
};

export default LoginPromo;
