import { Space } from 'antd';
import React, { useState } from 'react';
import { useQueryCache } from 'react-query';
import { stockLotAddItemToBasket } from '../StockLot.service';
import StockLotAddToBasketParams from '../types/StockLotAddToBasketParams.interface';
import ResultsTableRow from './ResultsTableRow';
import { Batch } from '../types/Batch.interface';
import useStockLotStore from '../useStockLotStore.store';
import QueryCacheName from '../../../types/QueryCacheName.enum';

interface ILoadPlanTableProps {
  results: Batch[];
}

const ResultsTable: React.FunctionComponent<ILoadPlanTableProps> = ({
  results,
}) => {
  const [isAddingItem, setIsAddingItem] = useState<boolean>(false);
  const request = useStockLotStore((state) => state.request);
  const queryCache = useQueryCache();

  const addToBasketParams: StockLotAddToBasketParams | null =
    request?.soldToParty && request?.shipToParty && request?.division
      ? {
          soldToParty: request.soldToParty,
          shipToParty: request.shipToParty,
          division: request.division,
        }
      : null;

  const doAddItemToBasket = async (item: Batch): Promise<void> => {
    if (addToBasketParams) {
      setIsAddingItem(true);

      await stockLotAddItemToBasket(item, addToBasketParams);
      queryCache.invalidateQueries(QueryCacheName.BASKET_STOCK_LOT);

      setIsAddingItem(false);
    }
  };

  return (
    <Space direction="vertical" size="middle" className="w-full">
      {results.map((result, index) => (
        <ResultsTableRow
          key={result.id}
          result={result}
          showHeader={index === 0}
          highlightRow={index % 2 === 1}
          onAddItemToBasket={doAddItemToBasket}
          isItemBeingAdded={isAddingItem}
        />
      ))}
    </Space>
  );
};

export default ResultsTable;
