import { t } from '@lingui/macro';
import { useMemo } from 'react';
import useDashboardCustomizationAccess from '../domain/Dashboard/hooks/useDashboardCustomizationAccess';
import DashboardWidgetId from '../domain/Dashboard/types/DashboardWidgetId.enum';
import DashboardWidgetInfo from '../domain/Dashboard/types/DashboardWidgetInfo.interface';
import safelyParseJsonToStringArray from '../services/Util/safelyParseJsonToStringArray.util';
import { AuthorityRole } from '../types/Authority.interface';
import useUserCustomization from './useUserCustomization';

export const ALL_DASHBOARD_WIDGETS: DashboardWidgetInfo[] = [
  {
    label: t`Product Availability`,
    id: DashboardWidgetId.PRODUCT_AVAILABILITY_NA,
    allowedRoles: [
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
      AuthorityRole.ROLE_NA,
      AuthorityRole.ROLE_RELEASE,
    ],
    requiredRoles: [
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
      AuthorityRole.ROLE_NA,
    ],
  },
  {
    label: t`Product Availability`,
    id: DashboardWidgetId.PRODUCT_AVAILABILITY_EU,
    allowedRoles: [
      AuthorityRole.ROLE_EU,
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
      AuthorityRole.ROLE_RELEASE,
    ],
    requiredRoles: [
      AuthorityRole.ROLE_EU,
      AuthorityRole.ROLE_STOCK_AVAILABILITY,
    ],
  },
  // TODO: Will be deleted till the sap work is done to enable metric values in NA prod availability
  // {
  //   label: t`Product Availability`,
  //   id: DashboardWidgetId.PRODUCT_AVAILABILITY_EU,
  //   allowedRoles: [
  //     AuthorityRole.ROLE_EU,
  //     AuthorityRole.ROLE_STOCK_AVAILABILITY,
  //   ],
  //   requiredRoles: [
  //     AuthorityRole.ROLE_EU,
  //     AuthorityRole.ROLE_STOCK_AVAILABILITY,
  //   ],
  //   excludedRoles: [AuthorityRole.ROLE_RELEASE],
  // },
  {
    label: t`Print Documents`,
    id: DashboardWidgetId.PRINT_DOCUMENTS,
    allowedRoles: [AuthorityRole.ROLE_DOCUMENT_PRINTER],
    requiredRoles: [AuthorityRole.ROLE_DOCUMENT_PRINTER],
  },
  {
    label: t`Track By Label ID`,
    id: DashboardWidgetId.TRACK_BY_LABEL_ID,
    allowedRoles: [AuthorityRole.ROLE_TRACK_BY],
    requiredRoles: [AuthorityRole.ROLE_TRACK_BY],
  },
];

const useDashboardWidgets = (): {
  allDashboardWidgets: DashboardWidgetInfo[];
  authorizedDashboardWidgets: DashboardWidgetInfo[];
  enabledDashboardWidgets: DashboardWidgetInfo[] | undefined;
  enabledDashboardWidgetIds: DashboardWidgetId[] | undefined;
} => {
  const { userHasAccessToItem } = useDashboardCustomizationAccess();
  const { data: userCustomizationSettings } = useUserCustomization();

  const authorizedDashboardWidgets = useMemo(
    () => ALL_DASHBOARD_WIDGETS.filter((widget) => userHasAccessToItem(widget)),
    [userHasAccessToItem]
  );

  const { enabledDashboardWidgets, enabledDashboardWidgetIds } = useMemo(() => {
    const dashboardWidgetsSettings = safelyParseJsonToStringArray(
      userCustomizationSettings?.tabs
    );

    const enabled = dashboardWidgetsSettings
      ? authorizedDashboardWidgets.filter((dashboardWidget) =>
          dashboardWidgetsSettings.includes(dashboardWidget.id)
        )
      : undefined;

    const enabledIds = enabled?.map((dashboardWidget) => dashboardWidget.id);

    return {
      enabledDashboardWidgets: enabled,
      enabledDashboardWidgetIds: enabledIds,
    };
  }, [userCustomizationSettings, authorizedDashboardWidgets]);

  return {
    allDashboardWidgets: ALL_DASHBOARD_WIDGETS,
    authorizedDashboardWidgets,
    enabledDashboardWidgets,
    enabledDashboardWidgetIds,
  };
};

export default useDashboardWidgets;
