import { Trans } from '@lingui/macro';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import CoverFormatRightTop from './CoverFormatRightTop';
import CoverFormatRightBottom from './CoverFormatRightBottom';
import CoverFormatLeft from './CoverFormatLeft';

interface ICoverFormatProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  setValue: UseFormMethods['setValue'];
  errors: UseFormMethods['errors'];
  clearErrors: UseFormMethods['clearErrors'];
}

const CoverFormat: React.FunctionComponent<ICoverFormatProps> = ({
  register,
  watch,
  setValue,
  errors,
  clearErrors,
}) => {
  return (
    <div className="w-4/5">
      <h3 className="text-xl font-bold mb-6">
        <Trans>Cover format</Trans>
      </h3>

      <div className="flex justify-around">
        <div className="text-center w-1/4 pb-16">
          <CoverFormatLeft register={register} watch={watch} />
        </div>
        <div className="border-l border-gray-light400 w-3/4">
          <CoverFormatRightTop register={register} watch={watch} />
          <div className="border-t border-gray-light400">
            <div className="pl-4 py-8 text-lg">
              <Trans>
                If the gatefold is not the same width as the dummy please
                specify
              </Trans>
            </div>
            <CoverFormatRightBottom
              register={register}
              watch={watch}
              setValue={setValue}
              errors={errors}
              clearErrors={clearErrors}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverFormat;
