import moment from 'moment';
import React, { FC } from 'react';
import Date from '../../../../components/Date';
import { PARAM_FORMAT } from '../../../../components/DatePicker/DatePicker';

interface DeliveryDateCellProps {
  requestedDate?: number;
  confirmedDate?: number;
}

const DeliveryDateCell: FC<DeliveryDateCellProps> = ({
  requestedDate,
  confirmedDate,
}) => {
  const confirmedDateIsLater =
    requestedDate &&
    confirmedDate &&
    moment(confirmedDate).isAfter(moment(requestedDate));
  return (
    <div
      className={`${
        confirmedDateIsLater ? 'text-orange-orange' : 'text-green-600'
      } font-bold`}
    >
      <Date date={confirmedDate || 0} />
    </div>
  );
};

export default DeliveryDateCell;
