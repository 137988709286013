import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../hooks/useAnalytics';
import padZeros from '../../services/Util/padZeros.util';
import SearchForm from './SearchForm';
import { FileClaimSearchRequest } from './FileClaimSearchRequest.interface';
import useFileClaimSearchStore, {
  DEFAULT_REQUEST,
} from './useFileClaimSearchStore.store';

const MISSING_INPUT_ERROR = t`Please be sure to supply a document number and line number before submitting.`;
const NO_ORDERS_FOUND_ERROR = t`No orders found for the given information.`;

interface IFileClaimSearchProps {
  isWidget: boolean;
}

const FileClaimSearch: React.FunctionComponent<IFileClaimSearchProps> = ({
  isWidget,
}) => {
  const { trackPageView } = useAnalytics();
  const request = useFileClaimSearchStore((state) => state.request);
  const error = useFileClaimSearchStore((state) => state.error);
  const { setError, setRequest } = useFileClaimSearchStore();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    reset,
    formState,
    getValues,
    trigger,
  } = useForm<FileClaimSearchRequest>({
    mode: 'all',
    defaultValues: request as FileClaimSearchRequest,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = handleSubmit(async (formData: FileClaimSearchRequest) => {
    trackPageView('FILECLAIMSEARCH', 'SUBMIT');
    setIsSubmitting(true);
    try {
      history.push(`/claim/file/${padZeros(formData.documentNumber, 10)}/${padZeros(formData.documentPosition, 6)}`);
    } catch (e) {
      if (e.response.status === 404) {
        setError(NO_ORDERS_FOUND_ERROR);
      } else {
        setError(MISSING_INPUT_ERROR);
      }
    }
    setIsSubmitting(false);
  });

  const onReset = () => {
    reset(DEFAULT_REQUEST);
    setError(null);
  };

  return (
    <Space direction="vertical" size="large">
      {!isWidget && (
        <div className="w-1/2">
          <Trans>
            Here you can file a claim for a shipped order by entering the 
            Sappi order number and the Sappi order line item that your claim
            is based on.
          </Trans>
        </div>
      )}
      <Space size="small" align="start">
        <div className={isWidget ? 'py-7' : ''}>
          <SearchForm
            onSubmit={onSubmit}
            onReset={onReset}
            isWidget={isWidget}
            register={register}
            formState={formState}
            getValues={getValues}
            trigger={trigger}
          />
          <div className="mx-3.5 mt-5">
            <Trans>Use</Trans>{' '}
            <a className="app-link" href="#/orderTracking">
              <Trans>Track Orders</Trans>
            </a>{' '}
            <Trans>to search for your order if you do not know the number.</Trans>
          </div>
        </div>
        <div>
          {isSubmitting && (
            <LoadingSpinner
              horizontal
              className="relative"
              style={{ top: '50px', left: '100px' }}
            />
          )}
          {error && <div className="text-lg text-red-red mt-10">{error}</div>}
        </div>
      </Space>
    </Space>
  );
};

export default FileClaimSearch;
