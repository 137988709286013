import { Trans } from '@lingui/macro';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import DummyButtons from '../DummyButtons';
import useDummyOrderStore from '../useDummyOrder.store';
import AdditionalSections from './AdditionalSections';
import TextPaper from './TextPaper';
import DummySamplesUserGuide from '../DummySamplesUserGuide';

interface ITextPagesProps {
  prop?: unknown;
}

const TextPages: React.FunctionComponent<ITextPagesProps> = (props) => {
  const state = useDummyOrderStore();
  const {
    register,
    watch,
    getValues,
    formState,
    errors,
    reset,
    trigger,
  } = useForm({
    mode: 'all',
    defaultValues: {
      brand: state.textPaperBrand,
      brandFamily: state.textPaperBrandFamily,
      grammage: state.textPaperGrammage,
      numPages: String(state.textPaperNumberOfPages),
      brandAddtl: state.additionalSectionsBrand,
      brandFamilyAddtl: state.additionalSectionsBrandFamily,
      grammageAddtl: state.additionalSectionsGrammage,
      numPagesAddtl: String(state.additionalSectionsNumberOfPages),
      position: state.additionalSectionsPositionInDummy,
      additionalSectionsPositionDescription:
        state.additionalSectionsPositionDescription,
    },
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const history = useHistory();
  const next = () => {
    const textNumPages = getValues('numPages');
    const addtlNumPages = getValues('numPagesAddtl');
    useDummyOrderStore.setState({
      textPaperBrand: getValues('brand'),
      textPaperBrandFamily: getValues('brandFamily'),
      textPaperGrammage: getValues('grammage'),
      textPaperNumberOfPages: textNumPages ? parseInt(textNumPages, 10) : 0,
      additionalSectionsBrand: getValues('brandAddtl'),
      additionalSectionsBrandFamily: getValues('brandFamilyAddtl'),
      additionalSectionsGrammage: getValues('grammageAddtl'),
      additionalSectionsNumberOfPages: textNumPages
        ? parseInt(addtlNumPages, 10)
        : 0,
      additionalSectionsPositionInDummy: getValues('position'),
      additionalSectionsPositionDescription: getValues(
        'additionalSectionsPositionDescription'
      ),
    });
    history.push('/samples/createDummy/coverOptions');
  };
  return (
    <>
      <DummySamplesUserGuide />
      <div style={{ minWidth: '960px' }}>
        <Card header={<Trans>Select your text pages paper type</Trans>}>
          <form>
            <div className="flex justify-between">
              <TextPaper register={register} watch={watch} errors={errors} />
              <AdditionalSections
                register={register}
                getValues={getValues}
                errors={errors}
                trigger={trigger}
                watch={watch}
              />
            </div>
          </form>
        </Card>
        <DummyButtons
          onNext={next}
          onNextDisabled={!formState.isValid}
          onPrev={() => history.push('/samples/createDummy/format')}
        />
      </div>
    </>
  );
};

export default TextPages;
