import create, { State } from 'zustand';
import { ArrivalForm } from './ArrivalForm.interface';

interface ArrivalDataState extends ArrivalForm, State {}

const useArrivalDataStore = create<ArrivalDataState>(() => ({
  arrivalRow: [],
}));

export default useArrivalDataStore;
