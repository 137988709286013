import Axios from 'axios';
import { DeliveryResponse } from '../../types/DeliveryResponse.interface';

const getDeliveryInfo = async (
  docNumber: string,
  docPosition: string
): Promise<DeliveryResponse[]> => {
  const { data } = await Axios.get(
    `/portal/resources/delivery/${docNumber}/${docPosition}`
  );
  return data;
};

export default getDeliveryInfo;
