import React, { FC } from 'react';
import useRole from '../../../hooks/useRole';
import { AuthorityRole } from '../../../types/Authority.interface';
import RollWeightAndLengthCalculatorNonRelease from './NonRelease/RollWeightAndLengthCalculatorNonRelease';
import RollWeightAndLengthCalculatorForRelease from './Release/RollWeightAndLengthCalculatorForRelease';

const RollWeightAndLengthCalculator: FC = () => {
  const { hasRole } = useRole();

  return (
    <>
      {hasRole(AuthorityRole.ROLE_RELEASE)  ? (
        <RollWeightAndLengthCalculatorForRelease />
      ) : (
        <RollWeightAndLengthCalculatorNonRelease />
      )}
    </>
  );
};

export default RollWeightAndLengthCalculator;
