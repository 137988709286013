import { t, Trans } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../../hooks/useAnalytics';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import useRole from '../../../../hooks/useRole';
import useUser from '../../../../hooks/useUser';
import { QueryParams } from '../../../../services/Brand';
import { AuthorityRole } from '../../../../types/Authority.interface';
import Brand from '../../../../types/Brand.interface';
import EuBrand from '../../../../types/EuBrand.interface';
import MeasurementSystem from '../../../../types/MeasurementSystem.enum';
import { calculateRollWeightAndLength } from '../../Calculator.service';
import CalculatorTemplate from '../../CalculatorTemplate/CalculatorTemplate';
import { CALCULATOR_LABELS } from '../../types/Calculator.constants';
import { CalculatorUnitTypes } from '../../types/CalculatorUnitTypes.enum';
import useBasisWeightOptions from '../../useBasisWeightOptions';
import useBrandOptions from '../../useBrandOptions';
import useCaliperOptions from '../../useCaliperOptions';
import useGrammageOptions from '../../useGrammageOptions';
import Criteria from './Criteria';
import { Criteria as RollWeightAndLengthCriteria } from './Criteria.interface';
import {
  deriveAdditionalEUParams,
  deriveAdditionalNAParams,
} from './RollWeightAndLengthCalculatorNonRelease.util';

const RollWeightAndLengthCalculatorNonRelease: FC = () => {
  const {
    register,
    reset,
    errors,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<RollWeightAndLengthCriteria>({ mode: 'all' });
  const { measurementSystem } = useMeasurementSystem();
  const { format } = useNumberFormatter();
  const [weightResult, setWeightResult] = useState<string>();
  const [lengthResult, setLengthResult] = useState<string>();
  const [isCalculating, setIsCalculating] = useState(false);
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();

  const paperBrand = watch('paperBrand');
  const caliper = watch('caliper');
  const basisWeight = watch('basisWeight');
  const metImp = watch('metImp');
  const defaultMetImp =
    measurementSystem === MeasurementSystem.METRIC
      ? CalculatorUnitTypes.MET
      : CalculatorUnitTypes.IMP;
  const { hasRole } = useRole();
  const customerNumber = user?.customers[0].number;
  const brandParams: Partial<QueryParams> = hasRole(AuthorityRole.ROLE_NA)
    ? {
        context: 'RL',
        customer: customerNumber,
      }
    : { context: 'RL' };
  const { rawBrands: brands, brandOptions } = useBrandOptions(
    brandParams,
    t`Paper Brand`
  );
  const { caliperOptions } = useCaliperOptions(brandParams, paperBrand, basisWeight);
  const { basisWeightOptions } = useBasisWeightOptions(
    brandParams,
    paperBrand,
    caliper
  );
  const { grammageOptions } = useGrammageOptions(brandParams, paperBrand);

  useEffect(() => {
    setValue('metImp', defaultMetImp);
  }, [defaultMetImp, setValue]);

  const onCalculate = async (data: RollWeightAndLengthCriteria) => {
    trackPageView('CALCULATORS', 'ROLL_WEIGHT_LENGTH', { username: user?.username });
    setIsCalculating(true);
    const response = await calculateRollWeightAndLength(
      hasRole(AuthorityRole.ROLE_EU)
        ? deriveAdditionalEUParams(data, brands as EuBrand[])
        : deriveAdditionalNAParams(data, brands as Brand[])
    );
    if (data.metImp === CalculatorUnitTypes.IMP) {
      setWeightResult(format(response.reelWeightImp));
      setLengthResult(format(response.reelLengthImp));
    } else {
      setWeightResult(format(response.reelWeight));
      setLengthResult(format(response.reelLength));
    }
    setIsCalculating(false);
  };

  const onReset = () => {
    reset({
      metImp: defaultMetImp,
    });
    setWeightResult(undefined);
    setLengthResult(undefined);
  };

  const onMetricImperialChange = (selectedValue: CalculatorUnitTypes) => {
    reset({
      metImp: selectedValue,
    });
    setWeightResult(undefined);
    setLengthResult(undefined);
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.ROLL_WEIGHT_AND_LENGTH}
      instructions={t`All fields must be filled in to calculate roll weight & length`}
      onCalculate={handleSubmit(onCalculate)}
      isCalculating={isCalculating}
      isCalculateDisabled={!isValid || isCalculating}
      onReset={() => onReset()}
      result={
        <>
          <div>
            {metImp === CalculatorUnitTypes.IMP ? (
              <Trans>Roll Weight (lbs):</Trans>
            ) : (
              <Trans>Roll Weight (kg):</Trans>
            )}{' '}
            {weightResult}
          </div>
          <div>
            {metImp === CalculatorUnitTypes.IMP ? (
              <Trans>Roll Length (lineal feet):</Trans>
            ) : (
              <Trans>Roll Length (m):</Trans>
            )}{' '}
            {lengthResult}
          </div>
        </>
      }
    >
      <Criteria
        register={register}
        errors={errors}
        reset={reset}
        watch={watch}
        onMetricImperialChange={onMetricImperialChange}
        isImperial={metImp === CalculatorUnitTypes.IMP}
        brandOptions={brandOptions}
        caliperOptions={caliperOptions}
        basisWeightOptions={basisWeightOptions}
        grammageOptions={grammageOptions}
      />
    </CalculatorTemplate>
  );
};

export default RollWeightAndLengthCalculatorNonRelease;
