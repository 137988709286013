import create from 'zustand';

type ActiveMenuPopoverNotificationState = {
  hidePopover: boolean;
  setHidePopover: (hidePopover: boolean) => void;
};

const activeMenuPopoverNotificationStore = create<
  ActiveMenuPopoverNotificationState
>((set) => ({
  hidePopover: false,
  setHidePopover: (hidePopover) => set({ hidePopover }),
}));

export default activeMenuPopoverNotificationStore;
