import { Trans } from '@lingui/macro';
import * as React from 'react';
import LabelValue from '../LabelValue/LabelValue';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import { AvailabilityCustomer } from '../../types/AvailabilityCustomer.interface';

interface ICustomerInformationProps {
  selectedCustomer: AvailabilityCustomer | undefined;
  isInBasket?: boolean;
  deliveryLocationName?: string;
}

const CustomerInformation: React.FunctionComponent<ICustomerInformationProps> = ({
  selectedCustomer,
  isInBasket,
  deliveryLocationName,
}) => {
  const { format } = useNumberFormatter();
  return (
    <>
      <div
        className={`flex ${
          isInBasket
            ? 'mt-5 text-lg'
            : 'px-7 py-5 mb-7 border border-gray-light400'
        }`}
      >
        <LabelValue
          inline
          skipColon
          className={`pr-6 border-r ${
            isInBasket ? 'py-3 border-gray-light400' : 'border-black-black'
          }`}
          label={
            <span className={`${isInBasket ? 'font-bold text-red-red' : ''}`}>
              <Trans>Customer:</Trans>
            </span>
          }
          value={selectedCustomer?.name1}
        />

        <LabelValue
          inline
          skipColon
          className={`px-6 border-r ${
            isInBasket ? 'py-3 border-gray-light400' : 'border-black-black'
          }`}
          label={
            <span className={`${isInBasket ? 'font-bold text-red-red' : ''}`}>
              <Trans>Credit Limit:</Trans>
            </span>
          }
          value={`${format(selectedCustomer?.creditLimit)} ${
            selectedCustomer?.currency
          }`}
        />
        {selectedCustomer && selectedCustomer?.availableCredit && (
          <LabelValue
            inline
            skipColon
            className={`pl-6 ${isInBasket ? 'py-3' : ''} ${
              isInBasket && selectedCustomer.availableCredit < 0
                ? 'text-red-red'
                : ''
            }`}
            label={
              <span className={`${isInBasket ? 'font-bold text-red-red' : ''}`}>
                <Trans>Available:</Trans>
              </span>
            }
            value={`${format(selectedCustomer?.availableCredit, true)} ${
              selectedCustomer?.currency
            }`}
          />
        )}
      </div>
      {isInBasket && deliveryLocationName && (
        <div className="mb-7 mt-1 text-lg">
          <LabelValue
            inline
            skipColon
            label={
              <span className="font-bold text-red-red">
                <Trans>Delivery Location:</Trans>
              </span>
            }
            value={deliveryLocationName}
          />
        </div>
      )}
    </>
  );
};

export default CustomerInformation;
