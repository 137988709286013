import create, { State } from 'zustand';
import { SamplesDummyCharacteristics } from '../../../types/Samples/SamplesDummyCharacteristics.interface';
import PaperDifferentFromTextOptionType from './CoverOptions/PaperDifferentFromTextOptionType.enum';
import SamplesProductType from '../../../types/Samples/SamplesProductType.enum';

export interface DummyOrderState extends SamplesDummyCharacteristics, State {
  // This is to save the form values for YES and Empty dropdown changes
  prePaperDifferentFromTextOption: PaperDifferentFromTextOptionType;
  quantity: number | null;
  dummyType: SamplesProductType;
}

const useDummyOrderStore = create<Partial<DummyOrderState>>(() => ({}));

export default useDummyOrderStore;
