import { t, Trans } from '@lingui/macro';
import React from 'react';
import { Space } from 'antd';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import { Button } from '../../../../components/Button/Button';
import Modal from '../../../../components/Modal/Modal';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';

interface IAddItemToExistingOrderModalProps {
  visible: boolean;
  order: AugmentedSamplesHistoryOrder;
  product: SamplesProduct;
  quantityInput: number | undefined;
  isProcessing: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const AddItemToExistingOrderModal: React.FunctionComponent<IAddItemToExistingOrderModalProps> = ({
  visible,
  order,
  product,
  quantityInput,
  isProcessing,
  onConfirm,
  onClose,
}) => {
  return (
    <Modal
      visible={visible}
      title={
        <Trans>
          Add Item to Existing Order: {stripZeros(order.documentNumber)}
        </Trans>
      }
      onCancel={onClose}
    >
      <div className="overflow-auto w-full">
        {isProcessing ? (
          <LoadingSpinner horizontal />
        ) : (
          <>
            <div>
              <Trans>
                Please confirm that you wish to add a new item to order:{' '}
                {order.documentNumber}.
              </Trans>
            </div>
            <h3 className="pt-3 text-lg font-bold">
              <Trans>Item Details</Trans>
            </h3>
            <div className="font-bold">Description:</div>
            <div>{product.materialDescription}</div>
            <div className="font-bold">
              <Trans>Quantity:</Trans>
            </div>
            <div>{quantityInput}</div>
          </>
        )}
        <Space className="justify-end w-full px-2">
          <Button
            key="confirm"
            theme="primary"
            onClick={onConfirm}
            className="uppercase"
            disabled={isProcessing}
          >
            <Trans>Confirm</Trans>
          </Button>
          <Button
            key="cancel"
            className="uppercase"
            theme="link"
            onClick={onClose}
            disabled={isProcessing}
          >
            <Trans>Cancel</Trans>
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

export default AddItemToExistingOrderModal;
