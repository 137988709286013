import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import useRole from '../../../hooks/useRole';
import { getCountriesOptions } from '../../../services/Countries';
import {
  deriveCustomerOptions,
  searchCustomers,
} from '../../../services/Customer';
import { AuthorityRole } from '../../../types/Authority.interface';
import Country from '../../../types/Country.interface';
import Customer from '../../../types/Customer.interface';
import AddressRender from './AddressRender';
import NewAddress from './NewAddress';
import NewAddressInputWrapper from './NewAddressInputWrapper';
import getCustomer, {
  getNumNonEmptyFormInputs,
  resetValueForDeliveryForm,
} from './SelectBuyer.util';
import useCustomerDeliveryOptionsStore, {
  NEW_ADDRESS_OPTION_VALUE,
} from './useCustomerDeliveryOptions.store';

const PREFIX = 'delivery';
interface ISelectBuyerProps {
  countries: Country[];
}

const SearchDeliveryForm: React.FunctionComponent<ISelectBuyerProps> = ({
  countries,
}) => {
  const { register, watch, getValues, setValue } = useFormContext();
  const {
    defaultDeliveryOptions,
    setDeliveryOptions,
    setSearchDeliveryFormValid,
    selectedDelivery,
    setSelectedDelivery,
    selectedCustomer,
  } = useCustomerDeliveryOptionsStore();
  const { hasRole } = useRole();
  const form = watch();

  const customerLocationAsDeliveryWatch = watch('customerLocationAsDelivery');
  const deliveryCountryWatch = watch('deliveryCountry');

  const [retrievedDeliveries, setRetrievedDeliveries] = useState<Customer[]>(
    []
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [deliveryOptionsError, setDeliveryOptionsError] = useState<boolean>(
    false
  );
  const [deliveryCountryCode, setDeliveryCountryCode] = useState<string>();
  const [customerNumber, setCustomerNumber] = useState<string>();
  const [addNewAddress, setAddNewAddress] = useState<boolean>(false);

  // These two useEffects are to keep chosen deliveryCountry when the checkbox of
  // customerLocationAsDelivery is clicked/un-clicked/clicked
  useEffect(() => {
    if (
      !customerLocationAsDeliveryWatch &&
      deliveryCountryWatch !== undefined
    ) {
      setDeliveryCountryCode(deliveryCountryWatch);
    }
  }, [deliveryCountryWatch, customerLocationAsDeliveryWatch]);

  useEffect(() => {
    if (!customerLocationAsDeliveryWatch) {
      setValue('deliveryCountry', deliveryCountryCode);
    }
  }, [
    deliveryCountryCode,
    customerLocationAsDeliveryWatch,
    setValue,
    selectedDelivery,
  ]);

  // This useEffect is to keep chosen deliveryOptions/customerNumber when the checkbox of
  // customerLocationAsDelivery is clicked/un-clicked/clicked
  useEffect(() => {
    if (!customerLocationAsDeliveryWatch) {
      setValue('deliveryOptions', customerNumber);
    }
  }, [
    customerLocationAsDeliveryWatch,
    customerNumber,
    form.customerLocationAsDelivery,
    setValue,
  ]);

  // This to load init address for deliveryOptions
  useEffect(() => {
    setSelectedDelivery(getCustomer(retrievedDeliveries, form.deliveryOptions));
  }, [form.deliveryOptions, setSelectedDelivery, retrievedDeliveries]);

  useEffect(() => {
    // Skip searching when COUNTRY is chosen
    if (deliveryCountryWatch === '1' || deliveryCountryWatch === '') {
      setValue('deliveryCountry', '');
    }
  }, [setValue, customerLocationAsDeliveryWatch, deliveryCountryWatch]);

  useEffect(() => {
    const isDeliveryOptionsChosen =
      form.deliveryOptions && form.deliveryOptions !== NEW_ADDRESS_OPTION_VALUE;
    const newCountryValid =
      form.deliveryOptions === NEW_ADDRESS_OPTION_VALUE &&
      form.newAddressCountryCode !== '';
    setSearchDeliveryFormValid(
      form.customerLocationAsDelivery ||
        isDeliveryOptionsChosen ||
        newCountryValid
    );
  }, [
    form.customerLocationAsDelivery,
    form.deliveryCountry,
    form.deliveryOptions,
    form.newAddressCountryCode,
    setSearchDeliveryFormValid,
  ]);

  useEffect(() => {
    const inputCount = getNumNonEmptyFormInputs(
      form,
      PREFIX,
      'deliveryOptions'
    );
    setIsButtonDisabled(inputCount < 1);
  }, [form]);

  const onSearch = async () => {
    const deliveries = await searchCustomers({
      city: getValues('deliveryCity'),
      country: getValues('deliveryCountry'),
      customerNumber: getValues('deliveryNumber'),
      name: getValues('deliveryName'),
      postalCode: getValues('deliveryPostal'),
      shipToFlag: 'X',
      soldToFlag: '+',
    });

    setRetrievedDeliveries(deliveries);

    const derivedCustomerOptions = deriveCustomerOptions(
      deliveries,
      false,
      hasRole(AuthorityRole.ROLE_EU),
      false,
      true
    );
    setDeliveryOptions(derivedCustomerOptions);

    setDeliveryOptionsError(false);
    setAddNewAddress(false);

    if (derivedCustomerOptions.length < 1) {
      resetValueForDeliveryForm(setValue);
    }

    if (derivedCustomerOptions.length > 0) {
      setValue('deliveryOptions', derivedCustomerOptions[0].value, {
        shouldValidate: true,
      });
      setCustomerNumber(derivedCustomerOptions[0].value as string);
    }
    setSearchDeliveryFormValid(derivedCustomerOptions.length > 0);
  };

  return (
    <>
      {customerLocationAsDeliveryWatch && (
        <>
          <div className="text-xl font-extrabold">
            <i className="required">*</i> <Trans>Delivery</Trans>
          </div>
          <div className="flex text-lg">
            <div className="pt-2 pr-5">
              <AddressRender addressData={selectedCustomer} />
            </div>
            <div>
              <input
                type="checkbox"
                name="acceptPallets"
                ref={register()}
                className="mt-0.5 mr-1"
              />
              <Trans>
                Please select if your delivery location can accept pallets.
              </Trans>
            </div>
          </div>
        </>
      )}
      {!customerLocationAsDeliveryWatch && (
        <div className="select-buyer">
          <>
            <div className="font-bold pb-1">
              <Trans>Search for Delivery Location:</Trans>
            </div>
            <div className="flex items-center mr-12 mb-3 select-buyer">
              <div className="w-1/6 pr-5">
                <Input
                  placeholder={t`NAME`}
                  name="deliveryName"
                  ref={register()}
                  type="string"
                  width="full"
                />
              </div>
              <div className="w-1/6 pr-5">
                <Input
                  placeholder={t`ID`}
                  name="deliveryNumber"
                  ref={register()}
                  type="string"
                  width="full"
                />
              </div>
              <div className="w-1/6 pr-5">
                <Select
                  options={getCountriesOptions(countries)}
                  width="full"
                  name="deliveryCountry"
                  ref={register()}
                />
              </div>
              <div className="w-1/6 pr-5">
                <Input
                  placeholder={t`CITY`}
                  name="deliveryCity"
                  ref={register()}
                  type="string"
                  width="full"
                />
              </div>
              <div className="w-1/6 pr-5">
                <Input
                  placeholder={t`POSTAL`}
                  name="deliveryPostal"
                  ref={register()}
                  type="string"
                  width="full"
                />
              </div>
              <Button
                type="button"
                theme="primary"
                disabled={isButtonDisabled}
                onClick={onSearch}
              >
                <Trans>Search</Trans>
              </Button>
            </div>
            <div className="flex content-start">
              <NewAddressInputWrapper required label="Delivery">
                <Select
                  name="deliveryOptions"
                  options={defaultDeliveryOptions}
                  ref={register({ required: true })}
                  onChange={(e) => {
                    if (e.target.value !== '0' && e.target.value !== '1') {
                      setSelectedDelivery(
                        getCustomer(retrievedDeliveries, e.target.value)
                      );
                      setCustomerNumber(
                        getCustomer(retrievedDeliveries, e.target.value)
                          ?.customerNumber
                      );
                    } else {
                      setCustomerNumber(e.target.value);
                    }

                    setAddNewAddress(e.target.value === '1');
                  }}
                  required
                  error={deliveryOptionsError ? { message: '' } : undefined}
                />
              </NewAddressInputWrapper>
              {selectedDelivery && selectedDelivery.customerNumber && (
                <div>
                  <div className="pt-9 text-lg">
                    <AddressRender addressData={selectedDelivery} />
                  </div>
                </div>
              )}
            </div>
          </>
          {selectedCustomer && selectedCustomer.customerNumber && (
            <label className="text-lg">
              <input
                type="checkbox"
                name="acceptPallets"
                ref={register()}
                className="mt-0.5 mr-1"
              />
              <Trans>
                Please select if your delivery location can accept pallets.
              </Trans>
            </label>
          )}
          {addNewAddress && <NewAddress countries={countries} />}
        </div>
      )}
    </>
  );
};

export default SearchDeliveryForm;
