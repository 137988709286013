import * as React from 'react';
import { Button } from '../Button/Button';

interface IExpandButtonProps {
  isExpanded: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const ExpandButton: React.FunctionComponent<IExpandButtonProps> = ({
  isExpanded,
  onClick,
  style,
  children,
}) => {
  return (
    <Button
      theme="clear"
      type="button"
      style={style}
      onClick={() => (onClick ? onClick() : null)}
    >
      <span>
        <span className="py-1 px-2 bg-blue-pacific mr-2">
          {!isExpanded ? (
            <i
              className="fa fa-plus text-lg text-white-white"
              aria-label="Expand"
            />
          ) : (
            <i
              className="fa fa-minus text-lg text-white-white"
              aria-label="Collapse"
            />
          )}
        </span>
        {children}
      </span>
    </Button>
  );
};

export default ExpandButton;
