import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import AboveTableHeader from '../AboveTableHeader';
import { TrackAndTraceData } from '../types/TrackAndTraceData.interface';
import TrackAndTraceStep from '../types/TrackAndTraceStep.enum';
import { getShownResults } from './Arrival.util';
import { ArrivalForm } from './ArrivalForm.interface';
import ReviewStep from './ReviewStep';
import SearchStep from './SearchStep';
import useArrivalDataStore from './useArrivalData.store';

const MAX_RESULTS = 250;
interface IArrivalProps {
  data: TrackAndTraceData[];
  onConfirm: (data: TrackAndTraceData[]) => void;
}

const Arrival: FC<IArrivalProps> = ({ data, onConfirm }) => {
  const hasExcessResults = data.length > MAX_RESULTS;
  const [currentTableStep, setCurrentTableStep] = useState<TrackAndTraceStep>(
    TrackAndTraceStep.SEARCH
  );
  const [changedData, setChangedData] = useState<TrackAndTraceData[]>([]);

  const doSaveAndContinue = (formData: ArrivalForm) => {
    useArrivalDataStore.setState({ ...formData });
    setCurrentTableStep(TrackAndTraceStep.REVIEW);
    setChangedData(getShownResults(data, formData));
  };

  return (
    <Space direction="vertical" className="w-full">
      <AboveTableHeader
        headerText={t`Report Arrival`}
        arrivalExcelData={
          currentTableStep === TrackAndTraceStep.SEARCH ? data : changedData
        }
      />
      <div className="text-right w-full">
        {currentTableStep === TrackAndTraceStep.SEARCH && (
          <SearchStep
            data={hasExcessResults ? data.slice(0, MAX_RESULTS) : data}
            showExcessResultsMessage={hasExcessResults}
            onSaveAndContinue={doSaveAndContinue}
          />
        )}
        {currentTableStep === TrackAndTraceStep.REVIEW &&
          changedData.length > 0 && (
            <ReviewStep
              data={changedData}
              onBack={() => setCurrentTableStep(TrackAndTraceStep.SEARCH)}
              onConfirm={onConfirm}
            />
          )}
      </div>
    </Space>
  );
};

export default Arrival;
