import React, { FC, useState } from 'react';
import Tabs, { TabPane } from '../../../components/Tabs/Tabs';
import useRole from '../../../hooks/useRole';
import { AuthorityRole } from '../../../types/Authority.interface';
import { CALCULATOR_TAB_LABELS } from '../../Calculators/types/Calculator.constants';
import NaOrReleaseCalculatorsTab from '../../Calculators/types/NaOrReleaseCalculatorsTab.enum';
import styles from './HeaderCalculators.module.scss';
import HeaderConversionCalculators from './HeaderConversionCalculators';
import HeaderPriceCalculators from './HeaderPriceCalculators';
import HeaderRollCalculators from './HeaderRollCalculators';
import HeaderSheetCalculators from './HeaderSheetCalculators';

const NaOrReleaseCalculators: FC = () => {
  const { hasRole } = useRole();

  const [activeTab, setActiveTab] = useState<NaOrReleaseCalculatorsTab>();

  const safelySetActiveTab = (key: string): void => {
    if (Object.values<string>(NaOrReleaseCalculatorsTab).includes(key)) {
      setActiveTab(key as NaOrReleaseCalculatorsTab);
    }
  };

  return (
    <Tabs
      type="card"
      activeKey={activeTab}
      onChange={(key) => safelySetActiveTab(key)}
      className={styles.calculatorTabs}
    >
      {!hasRole(AuthorityRole.ROLE_RELEASE) && (
        <TabPane
          tab={
            CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.SHEET_CALCULATORS]
          }
          key={NaOrReleaseCalculatorsTab.SHEET_CALCULATORS}
        >
          <HeaderSheetCalculators />
        </TabPane>
      )}
      <TabPane
        tab={CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.ROLL_CALCULATORS]}
        key={NaOrReleaseCalculatorsTab.ROLL_CALCULATORS}
      >
        <HeaderRollCalculators />
      </TabPane>
      <TabPane
        tab={CALCULATOR_TAB_LABELS[NaOrReleaseCalculatorsTab.PRICE_CALCULATORS]}
        key={NaOrReleaseCalculatorsTab.PRICE_CALCULATORS}
      >
        <HeaderPriceCalculators />
      </TabPane>
      <TabPane
        tab={
          CALCULATOR_TAB_LABELS[
            NaOrReleaseCalculatorsTab.CONVERSION_CALCULATORS
          ]
        }
        key={NaOrReleaseCalculatorsTab.CONVERSION_CALCULATORS}
      >
        <HeaderConversionCalculators />
      </TabPane>
    </Tabs>
  );
};

export default NaOrReleaseCalculators;
