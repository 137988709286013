import { noop } from 'lodash';

export const getCurrentRange = (
  pageSize: number,
  currentPage: number,
  total: number
): string => {
  const beginningRange = pageSize * (currentPage - 1);
  const endRange = beginningRange + pageSize;
  const actualEndRange = endRange > total ? total : endRange;
  return `${beginningRange + 1}-${actualEndRange}`;
};

export const unusedUtil = () => noop;
