import { Trans } from '@lingui/macro';
import * as React from 'react';
import { InvoiceDetailsRow } from './InvoiceDetailsRow.interface';
import stripZeros from '../../../../services/Util/stripZeros.util';

interface IBillingDeliveryInfoCellProps {
  record: InvoiceDetailsRow;
}

const BillingDeliveryInfoCell: React.FunctionComponent<IBillingDeliveryInfoCellProps> = ({
  record,
}) => {
  return (
    <div>
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans id="orderDetails.billToParty">Bill to Party</Trans>
        </dt>
        <dd className="font-bold">{stripZeros(record.invoice.billToParty)}</dd>
      </dl>
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans id="orderDetails.billToPartyName">Bill to Party Name</Trans>
        </dt>
        <dd className="font-bold">{record.invoice.billToPartyName}</dd>
      </dl>
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans id="orderDetails.shipTo">Bill to Party</Trans>
        </dt>
        <dd className="font-bold">{record.details.shipToName}</dd>
      </dl>
    </div>
  );
};

export default BillingDeliveryInfoCell;
