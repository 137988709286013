import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Select from '../../../../components/Select/Select';
import useRole from '../../../../hooks/useRole';
import CertificationValue from '../types/CertificationValue.enum';
import CustomerBasketFormData from './types/CustomerBasketFormData.interface';
import CustomerBasketFormField from './types/CustomerBasketFormField.enum';

interface CertificationCellContentProps {
  formMethods: UseFormMethods<CustomerBasketFormData>;
  itemIndex: number;
}

const CertificationCellContent: FC<CertificationCellContentProps> = ({
  formMethods,
  itemIndex,
}) => {
  const { isReleaseUser, isNaUser } = useRole();
  const { register, watch } = formMethods;

  const certFieldName = `items.${itemIndex}.${CustomerBasketFormField.CERTIFICATION}`;

  const certFieldWatch = watch(certFieldName);

  const hasUpchargeWarning =
    certFieldWatch === CertificationValue.FSC ||
    certFieldWatch === CertificationValue.PEFC;

  const options = [
    { label: t`NONE`, value: CertificationValue.NONE },
    ...(isNaUser
      ? [{ label: 'SFI Certified Sourcing', value: CertificationValue.SFI_CS },
        { label: 'SFI minimum 20%', value: CertificationValue.SFI_CC }]
      : []
    ),
    { label: 'FSC', value: CertificationValue.FSC },
    ...(isReleaseUser
      ? []
      : [{ label: 'PEFC', value: CertificationValue.PEFC }]),
  ];

  return (
    <Space direction="vertical" className="w-full">
      <Select name={certFieldName} ref={register()} options={options} />
      {hasUpchargeWarning && (
        <div className="italic text-red-red">
          <Trans>Possible upcharge for {certFieldWatch} option.</Trans>
        </div>
      )}
    </Space>
  );
};

export default CertificationCellContent;
