import { t } from '@lingui/macro';
import React, { FC } from 'react';
import CalculatorId from '../../Calculators/types/CalculatorId.enum';
import CalculatorGroup from './CalculatorGroup';

const CALCULATORS: CalculatorId[] = [
  CalculatorId.ROLL_WEIGHT_AND_LENGTH,
  CalculatorId.LINEAR_LENGTH_TO_AREA,
  CalculatorId.AREA_WEIGHT_CONVERSION,
];

const HeaderRollCalculators: FC = () => {
  return (
    <CalculatorGroup
      calculators={CALCULATORS}
      pickerLabel={t`Roll Calculator Type`}
    />
  );
};

export default HeaderRollCalculators;
