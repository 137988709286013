import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import textPaperImage from '../../../../assets/dummy_sample/text_paper.png';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import { getSamplesParameters } from '../../../../services/SamplesOrder';
import Option from '../../../../types/Option.type';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import SamplesProductGroup from '../../OrderSamples/SamplesProductGroup.enum';
import NumPagesInput from './NumPagesInput';
import { getTextPagesOptions } from './TextPages.util';

interface ITextPaperProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  errors: UseFormMethods['errors'];
}

const TextPaper: React.FunctionComponent<ITextPaperProps> = ({
  register,
  watch,
  errors,
}) => {
  const [brandFamilyOptions, setBrandFamilyOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [grammageOptions, setGrammageOptions] = useState<Option[]>([]);
  const dropdownValues = watch(['brandFamily', 'brand', 'grammage']);

  const {
    resolvedData: parameters,
    isFetching: isFetchingParameters,
  } = usePaginatedQuery(
    [
      QueryCacheName.SAMPLES_DUMMY_TEXT_PARAMETERS,
      {
        ...dropdownValues,
        productGroup: [SamplesProductGroup.PUBLIC, SamplesProductGroup.COMM],
      },
    ],
    getSamplesParameters,
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setBrandFamilyOptions(
      getTextPagesOptions(parameters?.brandFamilies, 'display', 'brandFamily')
    );
    setProductOptions(
      getTextPagesOptions(parameters?.brands, 'brandName', 'brand')
    );
    setGrammageOptions(
      getTextPagesOptions(parameters?.grammages, 'grammage', 'grammage')
    );
  }, [parameters]);

  return (
    <div className="border-r border-gray-light400 w-1/2">
      <h3 className="text-xl font-bold mb-6">
        <Trans>Text Paper</Trans>
      </h3>
      <div className="flex">
        <div style={{ maxWidth: '25%' }}>
          <img src={textPaperImage} alt={t`Text Paper`} />
        </div>
        <div className="ml-9 mr-28 w-full">
          <Space direction="vertical" className="w-full">
            <InputLabel
              size="lg"
              text={t`Brand`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="brandFamily"
                ref={register({ required: true })}
                width="full"
                options={brandFamilyOptions}
                disabled={isFetchingParameters}
                required
              />
            </InputLabel>
            <InputLabel
              size="lg"
              text={t`Product`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="brand"
                ref={register({ required: true })}
                width="full"
                options={productOptions}
                disabled={isFetchingParameters}
                required
              />
            </InputLabel>
            <InputLabel
              size="lg"
              text={t`Grammage`}
              isFetching={isFetchingParameters}
            >
              <Select
                name="grammage"
                ref={register({ required: true })}
                width="full"
                options={grammageOptions}
                disabled={isFetchingParameters}
                required
              />
            </InputLabel>
            <NumPagesInput
              name="numPages"
              register={register}
              errors={errors}
              required
              min={4}
            />
          </Space>
        </div>
      </div>
    </div>
  );
};

export default TextPaper;
