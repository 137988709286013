import { t, Trans } from '@lingui/macro';
import { Divider, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { MANUAL_LOG_OUT } from '../../App.const';
import { Button } from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import useAnalytics from '../../hooks/useAnalytics';
import useEuBasketNumItems from '../../hooks/useEuBasketNumItems';
import useNaBasketNumItems from '../../hooks/useNaBasketNumItems';
import useRole from '../../hooks/useRole';
import useUser from '../../hooks/useUser';
import { logout } from '../../services/Login';
import { AuthorityRole } from '../../types/Authority.interface';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { resetLiveAgent } from '../Chat/resetLiveAgent.util';
import ChatTrigger from './ChatTrigger';
import HeaderLink from './HeaderLink';
import LanguageDropdown from './LanguageDropdown';

const SiteHeader: FC = () => {
  const history = useHistory();
  const { hasRole, isNaUser, isReleaseEuUser } = useRole();
  const { trackPageView } = useAnalytics();
  const { numBasketItems } = useNaBasketNumItems();
  const { numBasketItems: numEuBasketItems } = useEuBasketNumItems();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const { data: user } = useUser();
  const isAuthenticated = !!user;
  const queryCache = useQueryCache();

  const showLoginLink = (pathname: string) =>
    pathname === '/loginHelp' ||
    pathname === '/passwordChangeRequired' ||
    pathname === '/forgotUsername' ||
    pathname === '/forgotPassword' ||
    pathname === '/registration';
  const [showLogin, setShowLogin] = useState(
    showLoginLink(history.location.pathname)
  );
  const [showLoginHelp, setShowLoginHelp] = useState(
    history.location.pathname === '/login' ||
      showLoginLink(history.location.pathname)
  );

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setShowLogin(showLoginLink(location.pathname));
      setShowLoginHelp(
        location.pathname === '/login' ||
          showLoginLink(history.location.pathname)
      );
    });
    return unlisten;
  }, [history]);

  const doLogout = async () => {
    await logout();
    setShowLogoutPopup(false);
    resetLiveAgent();

    // tells 403/401 response handler to ignore setting a last known location prior to logging out
    localStorage.setItem(MANUAL_LOG_OUT, 'true');
    await queryCache.invalidateQueries(QueryCacheName.USER);
    history.push('/login');
    localStorage.setItem(MANUAL_LOG_OUT, 'false');
    setTimeout(() => {
      window.onbeforeunload = null; // prevents prompt from popping up
      window.location.reload(); // clears locally cached data
    }, 0);
  };

  const initLogout = async () => {
    if (numBasketItems > 0 || numEuBasketItems > 0) {
      setShowLogoutPopup(true);
    } else {
      doLogout();
    }
  };

  return (
    <div
      className="bg-blue-cobaltVariation w-full text-white-white text-right"
      style={{ height: '50px' }}
    >
      <div className="wrapper text-gray-whiteSmoke400 text-md">
        <Space size="large" style={{ height: '50px' }}>
          {isAuthenticated && (
            <strong className="mr-5">
              <Trans>Hello, </Trans> {user?.firstName} {user?.lastName}
            </strong>
          )}
          <Space size="middle">
            <Space split={<Divider type="vertical" />}>
              {isAuthenticated && (
                <>
                  <HeaderLink
                    href="#/account"
                    onClick={() => trackPageView('ADMIN', 'VIEW_MY_ACCOUNT', {username: user?.username})}
                  >
                    <Trans>My Account</Trans>
                  </HeaderLink>
                  <HeaderLink onClick={initLogout}>
                    <Trans>Logout</Trans>
                  </HeaderLink>
                </>
              )}
              {showLogin && (
                <HeaderLink href="#/login">
                  <Trans>Login</Trans>
                </HeaderLink>
              )}
              {showLoginHelp ? (
                <HeaderLink href="#/loginHelp">
                  <Trans>Help</Trans>
                </HeaderLink>
              ) : (
                <HeaderLink href="#/help">
                  <Trans>Help</Trans>
                </HeaderLink>
              )}
              <LanguageDropdown />
            </Space>
            {isAuthenticated && hasRole(AuthorityRole.ROLE_CHAT) && (
              <ChatTrigger />
            )}
          </Space>
        </Space>
      </div>
      <Modal
        visible={showLogoutPopup}
        title={t`Notice`}
        onCancel={() => setShowLogoutPopup(false)}
        footer={[
          <Button onClick={doLogout}>
            <Trans>Logout</Trans>
          </Button>,
          <Button theme="primary" onClick={() => setShowLogoutPopup(false)}>
            <Trans>Back to Sappi E-Commerce</Trans>
          </Button>,
        ]}
      >
        <Space
          direction="vertical"
          size="middle"
          className="px-10 py-5 text-center w-full"
        >
          <div>
            <div>
              <Trans>You still have products in your basket(s).</Trans>
            </div>
            <Trans>Are you sure you want to logout?</Trans>
          </div>
          {(isNaUser || isReleaseEuUser) && (
            <div>
              <Trans>
                Your basket(s) will be on hold for the next 24 hours.
              </Trans>
            </div>
          )}
        </Space>
      </Modal>
    </div>
  );
};

export default SiteHeader;
