import { t } from '@lingui/macro';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import usePageHeaderItems from '../../hooks/usePageHeaderItems';
import PageHeader from '../PageHeader/PageHeader';

const AttachFilesPageHeader: FC = () => {
  const { commonPageHeaderItemSet } = usePageHeaderItems();
  const { complaintNumber } = useParams<{
    complaintNumber: string;
  }>();
  return (
    <PageHeader
      title={`${t`Attach Files to Claim #`} ${complaintNumber}`}
      items={commonPageHeaderItemSet}
    />
  );
};

export default AttachFilesPageHeader;
