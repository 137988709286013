import { t } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import { some } from 'lodash';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Table from '../../../../components/Table/Table';
import useRole from '../../../../hooks/useRole';
import { AuthorityRole } from '../../../../types/Authority.interface';
import { UploadedItem } from '../../types/UploadedItem.interface';
import DimensionsCell from './DimensionsCell';

interface IResultsTableProps {
  results: UploadedItem[];
  submittedResults: string[];
  register: UseFormMethods['register'];
  setValue: UseFormMethods['setValue'];
}

const ResultsTable: React.FunctionComponent<IResultsTableProps> = ({
  results,
  submittedResults,
  register,
  setValue,
}) => {
  const { hasRole } = useRole();
  const columns: ColumnsType<UploadedItem> = [
    {
      title: t`Customer`,
      key: 'shipToName',
      render: (value, record) => (
        <div className={record.numberOfUnits === 0 ? 'text-red-red' : ''}>
          {record.shipToName}
        </div>
      ),
    },
    {
      title: t`My Order #`,
      key: 'customerPurchaseOrder',
      render: (value, record) =>
        record.selectedPurchaseOrder || record.customerPurchaseOrder,
    },
    {
      title: t`Sappi Order #`,
      key: 'documentLineNumber',
      render: (value, record) => (
        <div>
          {record.documentNumber}
          <br />
          {record.documentLineNumber}
        </div>
      ),
    },
    {
      title: t`Brand`,
      key: 'brand',
      render: (value, record) =>
        `${record.materialDescription || ''} ${record.bookweight || ''} ${
          record.tradeBasis || ''
        }`,
    },
    {
      title: t`Dimensions`,
      key: 'dimensions',
      render: (value, record) => <DimensionsCell record={record} />,
    },
    {
      title: t`Units`,
      key: 'numberOfUnits',
      render: (value, record) => (
        <div className={record.numberOfUnits === 0 ? 'text-red-red' : ''}>
          {record.numberOfUnits}
        </div>
      ),
    },
    {
      title: (
        <Checkbox
          name="checkAll"
          ref={register()}
          label={t`Roll / Pallet ID`}
          onChange={(e) =>
            setValue(
              'rollPalletId',
              new Array(results.length).fill(e.target.checked)
            )
          }
        />
      ),
      key: 'rollPalletId',
      render: (value, record, index) => (
        <div className={record.numberOfUnits === 0 ? 'text-red-red' : ''}>
          {record.numberOfUnits > 0 ? (
            <Checkbox
              name={`rollPalletId[${index}]`}
              ref={register()}
              onChange={(e) => {
                if (!e.target.checked) {
                  setValue('checkAll', false);
                }
              }}
              disabled={some(submittedResults, (r) => r === record.barCode)}
              label={
                hasRole(AuthorityRole.ROLE_EU)
                  ? record.ifraCode
                  : record.barCode
              }
            />
          ) : (
            <span>
              {hasRole(AuthorityRole.ROLE_EU)
                ? record.ifraCode
                : record.barCode}
            </span>
          )}
        </div>
      ),
    },
  ];
  return (
    <Table
      rowKey="barCode"
      dataSource={results}
      columns={columns}
      pagination={false}
    />
  );
};

export default ResultsTable;
