import { t } from '@lingui/macro';
import { capitalize } from 'lodash';
import Option from '../../types/Option.type';
import { CalculatorUnitTypes } from './types/CalculatorUnitTypes.enum';
import MeasurementSystem from '../../types/MeasurementSystem.enum';
import { TradeBasisTypeValue } from './types/TradeBasisTypeValue.interface';
import { TradeBasisTypes } from './types/TradeBasisTypes.enum';

export const METRIC_IMP_OPTIONS: Option[] = [
  {
    value: '',
    label: t`Unit`,
    disabled: true,
  },
  {
    value: CalculatorUnitTypes.MET,
    label: capitalize(MeasurementSystem.METRIC),
  },
  {
    value: CalculatorUnitTypes.IMP,
    label: capitalize(MeasurementSystem.IMPERIAL),
  },
];

export const tradeBasisTypeValues: TradeBasisTypeValue[] = [
  {
    type: TradeBasisTypes.CVR,
    value: t`Cover (20 x 26)`,
  },
  {
    type: TradeBasisTypes.TAG,
    value: t`Tag (24 x 36)`,
  },
  {
    type: TradeBasisTypes.TXT,
    value: t`Text (25 x 38)`,
  },
];
