import { t } from '@lingui/macro';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import StorageLocation from '../../../types/StorageLocation.enum';
import MillInventoryResult from './MillInventoryResult';
import ResultAlert from './ResultAlert';
import { ResultRow } from './ResultRow.interface';

interface IStockResultsProps {
  data: ResultRow[];
}

const MillInventoryResults: React.FunctionComponent<IStockResultsProps> = ({
  data,
}) => {
  const [filteredResults, setFilteredResults] = useState<ResultRow[]>([]);
  useEffect(() => {
    if (data.length) {
      const sortFields = [
        'brand',
        'stockAvailabilities[0].grammage',
        'stockAvailabilities[0].widthInches',
        'stockAvailabilities[0].reelWidthInches',
        'stockAvailabilities[0].lengthInches',
        'stockAvailabilities[0].reelDiameterInches',
        'usBaseWeight',
      ];
      setFilteredResults(
        orderBy(
          data.filter(
            (item) =>
              item.storageLocation === StorageLocation.AM &&
              item.stockAvailabilities.length > 0
          ),
          sortFields
        )
      );
    }
  }, [data]);
  return (
    <div className="-mt-4">
      {filteredResults.map((result, idx) => (
        <MillInventoryResult
          key={`${result.brand}-${result.grammage}-${result.grainDirection}`}
          data={result}
          alert={
            idx === 0 && (
              <ResultAlert
                alertText={t`Inventory at a Mill location is subject to availability and cannot be reserved. Please allow for additional processing time. Please consult your Sappi representative for special making requests.`}
              />
            )
          }
        />
      ))}
    </div>
  );
};

export default MillInventoryResults;
