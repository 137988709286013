import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods, ValidateResult } from 'react-hook-form';
import UpdatePasswordRequest from '../../types/User/UpdatePasswordRequest.interface';
import { Button } from '../Button/Button';
import Input from '../Input/Input';
import InputLabel from '../InputLabel';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../hooks/useAnalytics';

const FIELDS: Record<
  keyof Omit<UpdatePasswordRequest, 'username'>,
  keyof UpdatePasswordRequest
> = {
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  confirmedPassword: 'confirmedPassword',
};

const MATCHES_NEW_PW = 'matchesNewPw';

interface IChangePasswordProps {
  onSubmit: (values: UpdatePasswordRequest) => void;
  formMethods: UseFormMethods<UpdatePasswordRequest>;
  isSubmitting?: boolean;
  hasError?: boolean;
  errorMessage?: string;
}

const ChangePassword: FC<IChangePasswordProps> = ({
  onSubmit,
  formMethods,
  isSubmitting,
  hasError,
  errorMessage,
}) => {
  const {
    errors,
    formState: { isDirty, isValid },
    getValues,
    handleSubmit,
    register,
    trigger,
  } = formMethods;

  const validateMatchesNewPw = (value: string): ValidateResult => {
    const newPwValue = getValues(FIELDS.newPassword);
    
    return value === newPwValue ? true : t`Passwords must match`;
  };
  const { trackPageView } = useAnalytics();

  return (
    <div className="flex my-6">
      <div className="w-1/2">
        <Space direction="vertical" size="large" className="w-full">
          <InputLabel text={t`Current Password`} size="lg" required>
            <Input
              name={FIELDS.currentPassword}
              ref={register({ required: true })}
              type="password"
              width="72"
              error={errors[FIELDS.currentPassword]}
              required
            />
          </InputLabel>
          <InputLabel text={t`New Password`} size="lg" required>
            <Input
              name={FIELDS.newPassword}
              ref={register({ required: true })}
              type="password"
              width="72"
              error={errors[FIELDS.newPassword]}
              required
              onChange={() => trigger(FIELDS.confirmedPassword)}
            />
          </InputLabel>
          <InputLabel text={t`Confirm Password`} size="lg" required>
            <Space>
              <Input
                name={FIELDS.confirmedPassword}
                ref={register({
                  validate: { [MATCHES_NEW_PW]: validateMatchesNewPw },
                })}
                type="password"
                width="72"
                error={errors[FIELDS.confirmedPassword]}
                errorMessageDisplay={{
                  icon: false,
                  belowInput: false,
                  tooltip: false,
                }}
                required
              />
              {errors?.[FIELDS.confirmedPassword]?.type === MATCHES_NEW_PW && (
                <div className="text-red-red">
                  {errors[FIELDS.confirmedPassword]?.message}
                </div>
              )}
            </Space>
          </InputLabel>
          <Button
            theme="primary"
            disabled={!(isDirty && isValid) || isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            <Trans>Update Password</Trans>
          </Button>
          <div>
            {isSubmitting && (
              <>
                <div className="text-center">
                  <Trans>Updating Password. Please Wait...</Trans>
                </div>
                <LoadingSpinner horizontal />
              </>
            )}
            {hasError && (
              <div className="text-red-red">
                  {errorMessage && <div>{errorMessage}</div>}
                  {!errorMessage && <Trans>There was a problem updating your password.</Trans>}
              </div>
            )}
          </div>
        </Space>
      </div>
      <div className="w-1/2">
        <div className="mb-4 text-xl font-extrabold">
          <Trans>New Password</Trans>
        </div>
        <ul>
          <li>
            <Trans>Must be EN characters.</Trans>
          </li>
          <li>
            <Trans>Cannot reuse one of your last 5 passwords.</Trans>
          </li>
          <li>
            <Trans>MUST contain at least 8 characters (12+ recommended).</Trans>
          </li>
          <li>
            <Trans>MUST contain at least one lowercase character.</Trans>
          </li>
          <li>
            <Trans>MUST contain at least one uppercase character.</Trans>
          </li>
          <li>
            <Trans>MUST contain at least one number.</Trans>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChangePassword;
