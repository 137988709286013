import React, { FC } from 'react';

const CalculatorResults: FC = ({ children }) => {
  return (
    <div
      className="border-gray-whiteSmoke200 text-center p-8 text-xl mt-8 font-semibold"
      style={{ borderWidth: '20px' }}
    >
      {children}
    </div>
  );
};

export default CalculatorResults;
