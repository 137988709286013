export enum FilterType {
  DIVISION = 'division',
  GRADE_DESCRIPTION = 'gradeDescription',
  GRAMMAGE = 'grammage',
  WIDTH = 'width',
  LENGTH = 'length',
  LOCATION = 'location',
  B_REEL_PARAMETER = 'bReelDiameter',
  CORE_DIAMETER = 'coreDiameter',
}
