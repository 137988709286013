import Axios from 'axios';
import LoginHelpContent from '../../domain/LoginHelp/types/LoginHelpContent.interface';
import DownloadDocsContent from '../../domain/Policies/types/DownloadDocsContent.interface';
import { GetDownloadDocsContentParams } from '../../domain/Policies/types/GetDownloadDocsContentParams.interface';
import { LanderContent } from '../../types/LanderContent.interface';
import { LoginNotificationContent } from '../../types/LoginNotificationContent.interface';
import { PromoContent } from '../../types/PromoContent.interface';
import PromoPosition from '../../types/PromoPosition.enum';
import { RecentNewsContent } from '../../types/RecentNewsContent.interface';

export const getLanderContent = async (
  _key: string,
  params: {
    lang: string;
    type: string;
  }
): Promise<LanderContent> => {
  const { data } = await Axios.get(
    `/portal/resources/content/lander/${params.lang}/${params.type}`
  );
  return data;
};

export const getDownloadDocsContent = async (
  params: GetDownloadDocsContentParams
): Promise<DownloadDocsContent> => {
  const { data } = await Axios.get(
    `/portal/resources/content/downloadDocs/${params.lang}/${params.type}`
  );
  return data;
};

export const getRecentNewsContent = async (
  lang: string
): Promise<RecentNewsContent[]> => {
  const { data } = await Axios.get(
    `/portal/resources/content/recentNews/${lang}`
  );
  return data;
};

export const getNewsContentById = async (
  id: string
): Promise<RecentNewsContent> => {
  const { data } = await Axios.get(`/portal/resources/news/${id}`);
  return data;
};

export const getPromoContent = async (
  lang: string,
  position: PromoPosition
): Promise<PromoContent> => {
  const { data } = await Axios.get(
    `/portal/resources/content/promo/${lang}/${position}`
  );
  return data;
};

export const getLoginNotificationContent = async (
  lang: string
): Promise<LoginNotificationContent> => {
  const { data } = await Axios.get(
    `/portal/resources/content/loginNotification/${lang}`
  );
  return data;
};

export const getLoginHelpContent = async (
  lang: string
): Promise<LoginHelpContent> => {
  const helpContentLang = lang === 'PT' ? 'pt_pt' : lang.toLowerCase();
  const { data } = await Axios.get(
    `/portal/resources/content/loginHelp/${helpContentLang}`
  );
  return data;
};
