import { i18n } from '@lingui/core';
import {
  de,
  en,
  es,
  eu,
  fi,
  fr,
  hu,
  it,
  nl,
  pl,
  pt,
  ru,
  sk,
} from 'make-plural/plurals';
import * as catalog_de from '../../locales/de/messages';
import * as catalog_en from '../../locales/en/messages';
import * as catalog_es from '../../locales/es/messages';
import * as catalog_eu from '../../locales/eu/messages';
import * as catalog_fi from '../../locales/fi/messages';
import * as catalog_fr from '../../locales/fr/messages';
import * as catalog_hu from '../../locales/hu/messages';
import * as catalog_it from '../../locales/it/messages';
import * as catalog_nl from '../../locales/nl/messages';
import * as catalog_pl from '../../locales/pl/messages';
import * as catalog_pt from '../../locales/pt/messages';
import * as catalog_ru from '../../locales/ru/messages';
import * as catalog_sk from '../../locales/sk/messages';
import Locale from '../../types/Locale.enum';

export const languageOptions = [
  { value: Locale.en, label: Locale.en },
  { value: Locale.de, label: Locale.de },
  { value: Locale.es, label: Locale.es },
  { value: Locale.fi, label: Locale.fi },
  { value: Locale.fr, label: Locale.fr },
  { value: Locale.hu, label: Locale.hu },
  { value: Locale.it, label: Locale.it },
  { value: Locale.nl, label: Locale.nl },
  { value: Locale.pl, label: Locale.pl },
  { value: Locale.ru, label: Locale.ru },
  { value: Locale.sk, label: Locale.sk },
  { value: Locale.eu, label: Locale.eu },
];

export const doLanguageChange = (locale: Locale): void => {
  let catalogMsgs;
  let plurals;
  switch (locale) {
    case Locale.de:
      catalogMsgs = catalog_de.messages;
      plurals = de;
      break;
    case Locale.es:
      catalogMsgs = catalog_es.messages;
      plurals = es;
      break;
    case Locale.fi:
      catalogMsgs = catalog_fi.messages;
      plurals = fi;
      break;
    case Locale.fr:
      catalogMsgs = catalog_fr.messages;
      plurals = fr;
      break;
    case Locale.hu:
      catalogMsgs = catalog_hu.messages;
      plurals = hu;
      break;
    case Locale.it:
      catalogMsgs = catalog_it.messages;
      plurals = it;
      break;
    case Locale.nl:
      catalogMsgs = catalog_nl.messages;
      plurals = nl;
      break;
    case Locale.pl:
      catalogMsgs = catalog_pl.messages;
      plurals = pl;
      break;
    case Locale.pt:
      catalogMsgs = catalog_pt.messages;
      plurals = pt;
      break;
    case Locale.ru:
      catalogMsgs = catalog_ru.messages;
      plurals = ru;
      break;
    case Locale.sk:
      catalogMsgs = catalog_sk.messages;
      plurals = sk;
      break;
    case Locale.eu:
      catalogMsgs = catalog_eu.messages;
      plurals = eu;
      break;
    default:
      catalogMsgs = catalog_en.messages;
      plurals = en;
  }
  i18n.loadLocaleData(locale, { plurals });
  i18n.load(locale, catalogMsgs);
  i18n.activate(locale);
};
