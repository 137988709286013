import { t } from '@lingui/macro';
import { pickBy } from 'lodash';
import { SearchOrderInputs } from './SearchOrderInputs.interface';

export default function prepareFiltersForQuery(
  filters: Partial<SearchOrderInputs>
): Partial<SearchOrderInputs> {
  let newFilters = { ...filters };
  if (
    newFilters.paperBrand &&
    newFilters.paperBrand === t({ id: 'search.any', message: 'Any' })
  ) {
    delete newFilters.paperBrand;
  }
  if (filters.weightEntry) {
    const { weightEntry } = newFilters;
    newFilters = {
      ...newFilters,
      bookweightFrom: weightEntry?.split(' ')[0],
      tradeBasis: weightEntry?.split(' ')[1],
    };
  }
  return pickBy(newFilters);
}

export const getMonthsFromToday = (
  incomingFilters: Partial<SearchOrderInputs>
): string => {
  return incomingFilters?.monthsFromToday &&
    +incomingFilters?.monthsFromToday > 0
    ? incomingFilters?.monthsFromToday
    : '-1';
};
