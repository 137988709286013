import { find } from 'lodash';
import { useMemo } from 'react';
import User from '../types/User/User.interface';
import useUser from './useUser';
import { AvailabilityCustomer } from '../types/AvailabilityCustomer.interface';

interface IUseCustomer {
  getAvailabilityCustomerByNumber: (
    customerNumber: string
  ) => AvailabilityCustomer | undefined;
}

const getEUAvailabilityCustomerByNumber = (
  customerNumber: string,
  user: User
) => {
  return find(user.euDirectCustomers, (c) => {
    return c.customerNumber === customerNumber;
  });
};

const useEUAvailabilityCustomer = (): IUseCustomer => {
  const { data: user } = useUser();
  return {
    getAvailabilityCustomerByNumber: useMemo(
      () => (customerNumber: string) =>
        user
          ? getEUAvailabilityCustomerByNumber(customerNumber, user)
          : undefined,
      [user]
    ),
  };
};

export default useEUAvailabilityCustomer;
