import { t } from '@lingui/macro';
import { filter, get, orderBy, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import PlanneProductionResult from './PlannedProductionResult';
import ResultAlert from './ResultAlert';
import { ResultRow } from './ResultRow.interface';

interface IPlannedProductionResultsProps {
  data: ResultRow[];
}

const PlannedProductionResults: React.FunctionComponent<IPlannedProductionResultsProps> = ({
  data,
}) => {
  const [filteredResults, setFilteredResults] = useState<ResultRow[]>([]);
  useEffect(() => {
    if (data.length) {
      const sortFields = [
        'productionPlansByPlant[0][0].gradeName',
        'productionPlansByPlant[0][0].grammage',
        'productionPlansByPlant[0][0].tradeBasis',
      ];
      const sortDirections: readonly (boolean | 'desc' | 'asc')[] = [
        'asc',
        'asc',
        'desc',
      ];
      setFilteredResults(
        orderBy(
          filter(
            uniqBy(data, 'plannedUniqueName'),
            (item) => get(item, 'productionPlansByPlant.length', 0) > 0
          ),
          sortFields,
          sortDirections
        )
      );
    }
  }, [data]);
  return (
    <div className="-mt-4">
      {filteredResults.map((result, idx) => (
        <PlanneProductionResult
          key={result.plannedUniqueName}
          data={result}
          alert={
            idx === 0 && (
              <ResultAlert
                alertText={t`All dates quoted are subject to standard sizes and put ups, runs subject to change without notice. Transit time not included. Please contact your Sappi Inside Sales representative if you need assistance with a closed production run.`}
              />
            )
          }
        />
      ))}
    </div>
  );
};

export default PlannedProductionResults;
