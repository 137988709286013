import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import { some } from 'lodash';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import { METRIC_NUMBER_REG_EX } from '../../../types/RegularExpression.constants';

interface ICriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  watch: UseFormMethods['watch'];
  getValues: UseFormMethods['getValues'];
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  calculated: boolean;
}

const Criteria: React.FunctionComponent<ICriteriaProps> = ({
  register,
  errors,
  watch,
  getValues,
  onInputChange,
  calculated,
}) => {
  const mm = watch('mm');
  const cm = watch('cm');
  const inches = watch('inches');
  const feet = watch('feet');

  const oneInputFilled = () => some(getValues(), (value) => !!value);
  return (
    <Space direction="vertical" size="middle">
      <div className="grid grid-cols-2 gap-4">
        <Input
          name="mm"
          ref={register({
            pattern: METRIC_NUMBER_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.mm}
          disabled={(cm || inches || feet) && !calculated}
          onChange={onInputChange}
          width="full"
        />
        <div className="text-xl font-bold">
          <Trans>mm</Trans>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          name="cm"
          ref={register({
            pattern: METRIC_NUMBER_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.cm}
          disabled={(mm || inches || feet) && !calculated}
          onChange={onInputChange}
          width="full"
        />
        <div className="text-xl font-bold">
          <Trans>cm</Trans>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          name="inches"
          ref={register({
            pattern: METRIC_NUMBER_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.inches}
          onChange={onInputChange}
          disabled={(mm || cm || feet) && !calculated}
          width="full"
        />
        <div className="text-xl font-bold">
          <Trans>inches</Trans>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          name="feet"
          ref={register({
            pattern: METRIC_NUMBER_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.feet}
          disabled={(mm || inches || cm) && !calculated}
          onChange={onInputChange}
          width="full"
        />
        <div className="text-xl font-bold">
          <Trans>feet</Trans>
        </div>
      </div>
    </Space>
  );
};

export default Criteria;
