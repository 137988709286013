import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';

const showNotification = (props: ArgsProps): void => {
  notification.open({
    ...props,
    className: 'sappi-notification',
    top: 0,
  });
};

export default showNotification;
