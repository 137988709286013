import { t, Trans } from '@lingui/macro';
import { round } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../../hooks/useAnalytics';
import useUser from '../../../hooks/useUser';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import { calculateSheetsToWeight } from '../Calculator.service';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';
import { CalculatorUnitTypes } from '../types/CalculatorUnitTypes.enum';
import Criteria from './Criteria';
import { Criteria as SheetToWeightCriteria } from './Criteria.interface';

const LBS_TO_SHORT_TON_CONSTANT = 0.0005;
const KGS_TO_METRIC_TON_CONSTANT = 0.001;

const SheetsToWeightCalculator: FC = () => {
  const {
    register,
    reset,
    errors,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<SheetToWeightCriteria>({ mode: 'all' });
  const { measurementSystem } = useMeasurementSystem();
  const metImp = watch('metImp');
  const metImpDefaultValue =
    measurementSystem === MeasurementSystem.METRIC
      ? CalculatorUnitTypes.MET
      : CalculatorUnitTypes.IMP;
  const [weightResult, setWeightResult] = useState<number>();
  const [tonResult, setTonResult] = useState<number>();
  const [isCalculating, setIsCalculating] = useState(false);
  const { trackPageView } = useAnalytics();
  const { data: user } = useUser();

  useEffect(() => {
    setValue('metImp', metImpDefaultValue);
  }, [metImpDefaultValue, setValue]);

  const onCalculate = async (data: SheetToWeightCriteria) => {
    trackPageView('CALCULATORS', 'SHEETS_TO_WEIGHT', {
      username: user?.username,
    });
    setIsCalculating(true);
    const response = await calculateSheetsToWeight(data);
    if (data.metImp === CalculatorUnitTypes.IMP) {
      setWeightResult(response.eWeightImp);
      setTonResult(round(response.eWeightImp * LBS_TO_SHORT_TON_CONSTANT, 3));
    } else {
      setWeightResult(response.eWeight);
      setTonResult(round(response.eWeight * KGS_TO_METRIC_TON_CONSTANT, 3));
    }
    setIsCalculating(false);
  };

  const onReset = () => {
    reset({
      metImp: metImpDefaultValue,
    });
    setWeightResult(undefined);
    setTonResult(undefined);
  };
  
  const onMetricImperialChange = (selectedValue: CalculatorUnitTypes) => {
    reset({
      metImp: selectedValue,
    });
    setWeightResult(undefined);
    setTonResult(undefined);
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.SHEETS_TO_WEIGHT}
      instructions={t`All fields must be filled in to calculate`}
      onCalculate={handleSubmit(onCalculate)}
      isCalculating={isCalculating}
      isCalculateDisabled={!isValid || isCalculating}
      onReset={() => onReset()}
      result={
        <>
          <div>
            {metImp === CalculatorUnitTypes.IMP ? (
              <Trans>Weight (lbs)</Trans>
            ) : (
              <Trans>Weight (kgs)</Trans>
            )}{' '}
            {weightResult}
          </div>
          <div>
            {metImp === CalculatorUnitTypes.IMP ? (
              <Trans>Short Tons</Trans>
            ) : (
              <Trans>Metric Tons</Trans>
            )}{' '}
            {tonResult}
          </div>
        </>
      }
    >
      <Criteria
        register={register}
        errors={errors}
        reset={reset}
        onMetricImperialChange={onMetricImperialChange}
        isImperial={metImp === CalculatorUnitTypes.IMP}
      />
    </CalculatorTemplate>
  );
};

export default SheetsToWeightCalculator;
