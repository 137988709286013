import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';

const NoMatchRoute: FC = () => {
  return (
    <Space
      direction="vertical"
      size="large"
      className="m-20 w-full text-center"
    >
      <h1 className="text-4xl font-bold">
        <Trans>Page Not Found</Trans>
      </h1>
      <div>
        <Trans>This page does not exist.</Trans>
      </div>
    </Space>
  );
};

export default NoMatchRoute;
