import React, { FC, useState } from 'react';
import { Trans } from '@lingui/macro';
import PackingListReportingForm from './PackingListReportingForm';
import PackingListReportingTable from './PackingListReportingTable';
import PackingListResponse from './types/PackingListResponse.interface';
import { hasValidResults } from './PackingList.util';

const PackingListReporting: FC = () => {
  const [results, setResults] = useState<PackingListResponse | null>(null);
  return (
    <>
      <PackingListReportingForm onDisplayResponse={setResults} />
      {results && (
        <>
          {hasValidResults(results) ? (
            <>
              <div className="flex justify-end mr-6">
                {results?.orderXls && (
                  <a
                    className="app-link"
                    title="Export to Microsoft Excel"
                    href="../resources/packing/excel"
                  >
                    <i
                      className="fa fa-file-excel-o text-xl mr-2"
                      aria-hidden="true"
                    />
                    <span className="text-lg">
                      <Trans>Download Excel Report</Trans>
                    </span>
                  </a>
                )}
              </div>
              <PackingListReportingTable results={results} />
            </>
          ) : (
            <div className="text-red-red py-5">
              <Trans>No results returned.</Trans>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PackingListReporting;
