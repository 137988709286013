enum EqUiElementName {
  IMPACT_TITLE = 'ImpactTitle',
  IMPACT_STATEMENT = 'ImpactStatement',
  IMPACT_CAR = 'CarImpact',
  IMPACT_GAS = 'GasImpact',
  IMPACT_SCOPE_1 = 'Scope1',
  IMPACT_SCOPE_2 = 'Scope2',
  IMPACT_INDUSTRY_AVERAGE = 'IndustryAverage',
  IMPACT_EPA_CALCULATOR = 'EPACalculator',
  PRODUCTS_INCLUSION = 'ProductInclusion',
  BENEFITS_TITLE = 'Environmental Benefits',
}

export default EqUiElementName;
