import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { Button } from '../../../components/Button/Button';
import CalculatorTemplateProps from './CalculatorTemplateProps.interface';

interface ICalculatorButtonsProps {
  isCalculateDisabled: CalculatorTemplateProps['isCalculateDisabled'];
  isCalculating: CalculatorTemplateProps['isCalculating'];
  onCalculate: CalculatorTemplateProps['onCalculate'];
  onReset: CalculatorTemplateProps['onReset'];
}

const CalculatorButtons: FC<ICalculatorButtonsProps> = ({
  isCalculateDisabled,
  isCalculating,
  onCalculate,
  onReset,
}) => {
  return (
    <Space className="mt-8" size="large">
      <Button
        type="submit"
        theme="primary"
        loading={isCalculating}
        disabled={isCalculateDisabled}
        onClick={() => onCalculate()}
      >
        <Trans>Calculate</Trans>
      </Button>
      <Button
        type="reset"
        theme="link"
        className="text-lg font-bold"
        onClick={() => onReset()}
      >
        <Trans>Reset</Trans>
      </Button>
    </Space>
  );
};

export default CalculatorButtons;
