import { Trans } from '@lingui/macro';
import * as React from 'react';
import { Button } from '../Button/Button';

interface IBackToTopProps {
  className?: string;
}

const BackToTop: React.FunctionComponent<IBackToTopProps> = ({ className }) => {
  const scrollToTop = () => window.scrollTo(0, 0);
  return (
    <Button
      theme="link"
      onClick={(e) => {
        e.stopPropagation();
        scrollToTop();
      }}
      className={className}
    >
      <Trans>Back to Top</Trans>
    </Button>
  );
};

export default BackToTop;
