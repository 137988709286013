import { useCallback } from 'react';
import useRole from '../../../hooks/useRole';
import CustomizableDashboardItem from '../types/CustomizableDashboardItem.interface';

const useDashboardCustomizationAccess = (): {
  userHasAccessToItem: (item: CustomizableDashboardItem) => boolean;
} => {
  const { hasRole } = useRole();

  const userHasAccessToItem = useCallback(
    (item: CustomizableDashboardItem): boolean => {
      const userHasAtLeastOneAllowedRole =
        item.allowedRoles.length > 0
          ? item.allowedRoles.some((role) => hasRole(role))
          : false;

      const userHasAllRequiredRoles =
        item.requiredRoles && item.requiredRoles.length > 0
          ? item.requiredRoles.every((role) => hasRole(role))
          : true;

      const userDoesNotHaveAnyExcludedRoles =
        item.excludedRoles && item.excludedRoles.length > 0
          ? item.excludedRoles.every((role) => !hasRole(role))
          : true;

      return (
        userHasAtLeastOneAllowedRole &&
        userHasAllRequiredRoles &&
        userDoesNotHaveAnyExcludedRoles
      );
    },
    [hasRole]
  );

  return {
    userHasAccessToItem,
  };
};

export default useDashboardCustomizationAccess;
