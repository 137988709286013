import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Radio from '../../../../components/Radio/Radio';
import Customer from '../../../../types/Customer.interface';
import CustomerAddress from '../BasketInformation/CustomerAddress';
import BasketSummaryColumn from '../BasketSummaryColumn';
import { StockLotBasketForm } from './StockLotBasketForm.type';
import StockLotBasketFormField from './StockLotBasketFormField.enum';

interface ICustomerDeliveryLocationProps {
  register: UseFormMethods<StockLotBasketForm>['register'];
  soldTo: Customer;
  shipTo: Customer;
  harbors: Customer[];
}

const CustomerDeliveryLocation: FC<ICustomerDeliveryLocationProps> = ({
  register,
  soldTo,
  shipTo,
  harbors,
}) => {
  return (
    <div className="grid grid-cols-3">
      <BasketSummaryColumn title={t`Customer`}>
        <CustomerAddress customer={soldTo} />
      </BasketSummaryColumn>
      <BasketSummaryColumn title={t`Delivery Location`}>
        <CustomerAddress customer={shipTo} />
      </BasketSummaryColumn>
      <BasketSummaryColumn title={t`Harbor`}>
        <Space size="small" direction="vertical">
          {harbors.map((harbor) => (
            <Radio
              key={harbor.number}
              name={StockLotBasketFormField.HARBOR}
              value={harbor.number}
              label={harbor.name}
              ref={register({ required: true })}
              defaultChecked={harbors.length === 1}
            />
          ))}
        </Space>
      </BasketSummaryColumn>
    </div>
  );
};

export default CustomerDeliveryLocation;
