import { t } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import Select from '../../../../components/Select/Select';
import Option from '../../../../types/Option.type';
import { FRACTION_DECIMAL_INPUT } from '../../../../types/RegularExpression.constants';

interface IImperialCriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  caliperOptions: Option[];
  basisWeightOptions: Option[];
}

const ImperialCriteria: React.FunctionComponent<IImperialCriteriaProps> = ({
  register,
  errors,
  caliperOptions,
  basisWeightOptions,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Select name="caliper" options={caliperOptions} ref={register()} />
      <Select
        name="basisWeight"
        options={basisWeightOptions}
        ref={register()}
      />
      <Input
        name="reelWidthImp"
        ref={register({
          required: true,
          pattern: FRACTION_DECIMAL_INPUT,
        })}
        required
        error={errors?.reelWidthImp}
        placeholder={t`Width (in)`}
      />
      <Input
        name="reelDiamImp"
        ref={register({
          required: true,
          pattern: FRACTION_DECIMAL_INPUT,
        })}
        required
        error={errors?.reelDiamImp}
        placeholder={t`Diameter (in)`}
      />
      <Input
        name="coreDiamImp"
        ref={register({
          required: true,
          pattern: FRACTION_DECIMAL_INPUT,
        })}
        required
        error={errors?.coreDiamImp}
        placeholder={t`Core Diameter (in)`}
      />
    </Space>
  );
};

export default ImperialCriteria;
