import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import useOrderBookingBaskets from '../../hooks/useOrderBookingBaskets';
import useStockLotBasket from '../../hooks/useStockLotBasket';
import {
  getOrderBookingBasketQuantityCount,
  getStockLotBasketQuantityCount,
} from '../../services/Basket';
import UnitOfMeasure from '../../types/UnitOfMeasure.enum';
import BasketPopUpBasketProps from './BasketPopUpBasketProps';
import BasketPopUpButton from './BasketPopUpButton';
import BasketPopUpButtons from './BasketPopUpButtons';
import BasketPopUpHeader from './BasketPopUpHeader';
import BasketPopUpItemProps from './BasketPopUpItemProps';
import BasketPopUpWrapper from './BasketPopUpWrapper';
import useAnalytics from '../../hooks/useAnalytics';

interface INonSamplesEuBasketPopupProps {
  onClose: () => void;
  onContinueShopping: () => void;
}

const NonSamplesEuBasketPopup: React.FunctionComponent<INonSamplesEuBasketPopupProps> = ({
  onClose,
  onContinueShopping,
  children,
}) => {
  const { format } = useNumberFormatter();
  const { trackPageView } = useAnalytics();
  const { data: orderBookingBaskets } = useOrderBookingBaskets();
  const { data: stockLotBaskets } = useStockLotBasket();
  const quantity =
    getOrderBookingBasketQuantityCount(orderBookingBaskets || []) +
    getStockLotBasketQuantityCount(stockLotBaskets || []);
  const numStockLotItems =
    stockLotBaskets?.reduce((acc, next) => acc + next.items.length, 0) || 0;
  const numOrderBookingBasketItems =
    orderBookingBaskets?.reduce((acc, next) => acc + next.items.length, 0) || 0;
  const history = useHistory();

  const doViewBasket = () => {
    trackPageView('BASKET_POPUP', 'VIEW_BASKET');
    history.push('/basket');
  };

  const doContinueShopping = () => {
    trackPageView('BASKET_POPUP', 'CONTINUE_SHOPPING');
    onContinueShopping();
    onClose();
  };

  return (
    <BasketPopUpWrapper>
      <BasketPopUpHeader>
        <Trans>Added to Basket</Trans>
      </BasketPopUpHeader>
      <BasketPopUpItemProps>
        {children ? (
          <>{children}</>
        ) : (
          <Trans>No items recently added to your basket.</Trans>
        )}
      </BasketPopUpItemProps>
      <Space direction="vertical" size="small" className="w-full px-2">
        <BasketPopUpBasketProps>
          <div>
            {numOrderBookingBasketItems + numStockLotItems}&nbsp;
            <Trans>items in your basket.</Trans>
          </div>
          <div>
            <Trans>Total Quantity:</Trans> {format(quantity)} {UnitOfMeasure.KG}
          </div>
        </BasketPopUpBasketProps>
        <BasketPopUpButtons>
          <BasketPopUpButton theme="inverse" onClick={doViewBasket}>
            <Trans>View Basket</Trans>
          </BasketPopUpButton>
          <BasketPopUpButton theme="primary" onClick={doContinueShopping}>
            <Trans>Continue Shopping</Trans>
          </BasketPopUpButton>
        </BasketPopUpButtons>
      </Space>
    </BasketPopUpWrapper>
  );
};

export default NonSamplesEuBasketPopup;
