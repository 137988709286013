import * as React from 'react';

const DeconstructedTableHead: React.FunctionComponent<React.HTMLProps<
  HTMLTableSectionElement
>> = ({ className, children, ...rest }) => {
  return (
    <thead className={`ant-table-thead ${className || ''}`} {...rest}>
      {children}
    </thead>
  );
};

export default DeconstructedTableHead;
