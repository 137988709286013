import { t } from '@lingui/macro';
import React, { FC } from 'react';
import Date from '../../../components/Date';
import InputLabel from '../../../components/InputLabel';

interface IPlannedDateProps {
  arrivalDate: number;
  arrivalTime: string;
}

const PlannedDate: FC<IPlannedDateProps> = ({ arrivalDate, arrivalTime }) => {
  return (
    <InputLabel text={t`Planned`} size="lg">
      <Date date={arrivalDate} />
      &nbsp;{arrivalTime}
    </InputLabel>
  );
};

export default PlannedDate;
