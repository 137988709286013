import { noop } from 'lodash';
import * as React from 'react';
import { Button } from '../../components/Button/Button';

interface IHeaderLinkProps {
  href?: string;
  onClick?: () => void;
}

const HeaderLink: React.FunctionComponent<IHeaderLinkProps> = ({
  href,
  onClick,
  children,
}) => {
  return (
    <>
      {href ? (
        <a className="hover:underline" href={href} onClick={onClick || noop}>
          {children}
        </a>
      ) : (
        <Button
          className="hover:underline text-gray-whiteSmoke400 no-underline"
          theme="link"
          onClick={onClick || noop}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export default HeaderLink;
