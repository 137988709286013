import React, { useState } from 'react';
import DeconstructedTableBodyRow from '../../../../components/DeconstructedTable/DeconstructedTableBodyRow';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import ViewSampleOrdersTableRowType from '../../../../types/Samples/ViewSampleOrdersTableRowType.enum';
import EditOrderItemModal from '../EditOrderItemModal';
import RemoveOrderItemModal from '../RemoveOrderItemModal';
import ShippingInformationModal from '../ShippingInformationModal';
import ActionsCell from './ActionsCell';
import OrderCell from './OrderCell';
import ProductCell from './ProductCell';
import QtyValueCell from './ValueCell';
import ShipToCell from './ShipToCell';
import StatusCell from './StatusCell';

interface IOrderItemProps {
  order: AugmentedSamplesHistoryOrder;
  rowType: ViewSampleOrdersTableRowType;
  expanded?: boolean;
  onToggleExpanded?: () => void;
}

const OrderItem: React.FunctionComponent<IOrderItemProps> = ({
  order,
  rowType,
  expanded,
  onToggleExpanded,
}) => {
  const [
    isShippingInformationModalVisibile,
    setIsShippingInformationModalVisible,
  ] = useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState<boolean>(
    false
  );

  const isSubitem = rowType === ViewSampleOrdersTableRowType.SUBITEM;

  return (
    <>
      <DeconstructedTableBodyRow className={isSubitem ? 'subitem-row' : ''}>
        <OrderCell
          order={order}
          rowType={rowType}
          expanded={expanded}
          onToggleExpanded={onToggleExpanded}
          onDocRefClick={() => setIsShippingInformationModalVisible(true)}
        />
        <StatusCell order={order} rowType={rowType} />
        <ProductCell order={order} rowType={rowType} />
        <QtyValueCell order={order} rowType={rowType} />
        <ShipToCell order={order} />
        <ActionsCell
          order={order}
          rowType={rowType}
          onEditClick={() => setIsEditModalVisible(true)}
          onRemoveClick={() => setIsRemoveModalVisible(true)}
        />
      </DeconstructedTableBodyRow>
      <ShippingInformationModal
        visible={isShippingInformationModalVisibile}
        documentNumber={order.documentNumber}
        documentPosition={order.documentPosition}
        shipToCustomerNumber={order.shipToCustomer}
        details={{
          shipmentDate: order.shipmentDate,
          deliveryDate: order.deliveryDate,
          status: order.status,
          shippingMethodText: order.shippingMethodText,
          tourName: order.tourName || '',
          shipper: order.shipper,
        }}
        onClose={() => setIsShippingInformationModalVisible(false)}
      />
      <EditOrderItemModal
        visible={isEditModalVisible}
        orderItem={order}
        onCancel={() => setIsEditModalVisible(false)}
      />
      <RemoveOrderItemModal
        visible={isRemoveModalVisible}
        orderItem={order}
        onCancel={() => setIsRemoveModalVisible(false)}
      />
    </>
  );
};

export default OrderItem;
