import * as React from 'react';
import styles from './AlertModal.module.scss';

interface IAlertProps {
  size?: 'sm' | 'md';
  buttons?: React.ReactNode[];
  title: string | React.ReactNode;
  body: string | React.ReactNode;
}

const AlertModal: React.FunctionComponent<IAlertProps> = ({
  buttons,
  size,
  title,
  body,
}) => {
  return (
    <div
      className={`ant-modal-content alertContainer ${
        size === 'md' ? styles['alertContainer--medium'] : ''
      }`}
    >
      <div className={`ant-modal-header ${styles.alertModalHeader}`}>
        <div className="ant-modal-title">{title}</div>
      </div>
      <div className="ant-modal-body">{body}</div>
      <div className="ant-modal-footer">{buttons?.map((btn) => btn)}</div>
    </div>
  );
};

export default AlertModal;
