import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import useRole from '../../../hooks/useRole';
import { getCountriesOptions } from '../../../services/Countries';
import {
  deriveCustomerOptions,
  searchCustomers,
} from '../../../services/Customer';
import { AuthorityRole } from '../../../types/Authority.interface';
import Country from '../../../types/Country.interface';
import Customer from '../../../types/Customer.interface';
import AddressRender from './AddressRender';
import NewAddressInputWrapper from './NewAddressInputWrapper';
import getCustomer, { getNumNonEmptyFormInputs } from './SelectBuyer.util';
import useCustomerDeliveryOptionsStore from './useCustomerDeliveryOptions.store';

const PREFIX = 'customer';
interface ISelectBuyerProps {
  countries: Country[];
}

const SearchCustomerForm: React.FunctionComponent<ISelectBuyerProps> = ({
  countries,
}) => {
  const { register, watch, getValues, setValue } = useFormContext();

  const form = watch();
  const { hasRole } = useRole();
  const {
    defaultCustomerOptions,
    setCustomerOptions,
    customerLocationAsDeliveryChecked,
    setCustomerLocationAsDeliveryChecked,
    selectedCustomer,
    setSelectedCustomer,
  } = useCustomerDeliveryOptionsStore();

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [searchedCustomers, setSearchedCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    const inputCount = getNumNonEmptyFormInputs(
      form,
      PREFIX,
      'customerSelected'
    );
    setIsButtonDisabled(inputCount < 1);
  }, [form]);

  const onSearch = async () => {
    const customers = await searchCustomers({
      city: getValues('customerCity'),
      country: getValues('customerCountry'),
      customerNumber: getValues('customerNumber'),
      name: getValues('customerName'),
      postalCode: getValues('customerPostal'),
    });
    setSearchedCustomers(customers);

    const derivedCustomerOptions = deriveCustomerOptions(
      customers,
      false,
      hasRole(AuthorityRole.ROLE_EU),
      false
    );

    setCustomerOptions(derivedCustomerOptions);

    setValue(
      'customerSelected',
      derivedCustomerOptions.length > 0
        ? (derivedCustomerOptions[0].value as string)
        : undefined,
      { shouldValidate: true }
    );

    if (derivedCustomerOptions.length > 0) {
      const customer = getCustomer(
        customers,
        derivedCustomerOptions[0].value as string
      );
      setSelectedCustomer(customer);
    }

    if (derivedCustomerOptions.length < 1) {
      setSelectedCustomer(undefined);
    }
    setValue('applyLocation', '');
    setValue('customerLocationAsDelivery', customerLocationAsDeliveryChecked);
  };

  return (
    <div className="pb-2">
      <>
        <div className="font-bold pb-1">
          <Trans>Search for Customer:</Trans>
        </div>
        <div className="flex items-center mr-12 mb-3 select-buyer">
          <div className="w-1/6 pr-5">
            <Input
              placeholder={t`NAME`}
              name="customerName"
              ref={register()}
              type="string"
              width="full"
            />
          </div>
          <div className="w-1/6 pr-5">
            <Input
              placeholder={t`ID`}
              name="customerNumber"
              ref={register()}
              type="string"
              width="full"
            />
          </div>
          <div className="w-1/6 pr-5">
            <Select
              options={getCountriesOptions(countries)}
              width="full"
              name="customerCountry"
              ref={register()}
            />
          </div>
          <div className="w-1/6 pr-5">
            <Input
              placeholder={t`CITY`}
              name="customerCity"
              ref={register()}
              type="string"
              width="full"
            />
          </div>
          <div className="w-1/6 pr-5">
            <Input
              placeholder={t`POSTAL`}
              name="customerPostal"
              ref={register()}
              type="string"
              width="full"
            />
          </div>
          <Button
            type="button"
            theme="primary"
            disabled={isButtonDisabled}
            onClick={onSearch}
          >
            <Trans>Search</Trans>
          </Button>
        </div>
        <div className="flex content-start">
          <NewAddressInputWrapper required label="Customer">
            <Select
              options={defaultCustomerOptions}
              defaultValue={selectedCustomer?.customerNumber}
              required
              ref={register({ required: true })}
              name="customerSelected"
              onChange={(e) => {
                const customer = getCustomer(searchedCustomers, e.target.value);
                setSelectedCustomer(customer);
              }}
            />
          </NewAddressInputWrapper>
          {selectedCustomer && selectedCustomer.customerNumber && (
            <div>
              <div className="my-2 flex items-start">
                <input
                  type="checkbox"
                  name="customerLocationAsDelivery"
                  ref={register()}
                  className="mt-0.5"
                  onChange={(e) => {
                    setCustomerLocationAsDeliveryChecked(e.target.checked);
                  }}
                />
                <label className="ml-2 font-bold">
                  <Trans>
                    Check here to use this location as the delivery location
                  </Trans>
                </label>
              </div>
              <div className="pt-9 text-lg">
                <AddressRender addressData={selectedCustomer} />
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default SearchCustomerForm;
