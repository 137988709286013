import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import SamplesProductType from '../../../../types/Samples/SamplesProductType.enum';

export const shouldShowCost = (product: SamplesProduct): boolean => {
  const prodTypeIsAlwaysFree =
    product.type === SamplesProductType.UNPRINTED ||
    product.type === SamplesProductType.PRINTED;

  return product.price > 0 && !prodTypeIsAlwaysFree;
};

export const shouldShowNoCost = (
  product: SamplesProduct,
  isInternalUser: boolean
): boolean => {
  const isFree = product.price <= 0;
  const isProductUnprinted = product.type === SamplesProductType.UNPRINTED;
  return !(isFree && isProductUnprinted && !isInternalUser);
};
