import Axios from 'axios';

/**
 * @param {string} imageId The ID of the image for which to get the URL.
 * @throws {Error} Throws an error with a 404 not found message if the image ID
 *   can't be found.
 * @returns {string} The URL of the image.
 * @see /src/main/java/com/sappi/ecomm/portal/resources/ImageResource.java
 */
export const getImageUrl = async (imageId: string): Promise<string> => {
  const { data } = await Axios.get(
    `/portal/resources/sap-images/${imageId}/url`
  );
  return data;
};
