import Axios from 'axios';
import { sortBy } from 'lodash';
import ProductQuery from '../../types/ProductQuery.interface';

export const getCustomerSavedFilter = async (
  customerNumber: string,
  type: string,
  measurementSystem: string
): Promise<ProductQuery[]> => {
  const { data } = await Axios.get(`/portal/resources/filter/customer`, {
    params: { customer: customerNumber, type, measurementSystem },
  });
  return sortBy(data, (o) => o.filterName);
};

export const isFilterExisting = async (
  customerNumber: string,
  filterName: string,
  type: string,
  measurementSystem: string
): Promise<boolean> => {
  const { data } = await Axios.get(
    `/portal/resources/filter/${customerNumber}/filterName/${filterName}/type/${type}/measurementSystem/${measurementSystem}`,
    {}
  );
  return data;
};

export const saveCustomerFilter = async (
  query: ProductQuery
): Promise<ProductQuery> => {
  const { data } = await Axios.post('/portal/resources/filter', query);
  return data;
};

export const deleteCustomerFilter = async (
  customerNumber: string,
  filterName: string,
  type: string,
  measurementSystem: string
): Promise<any> => {
  await Axios.delete(
    `/portal/resources/filter/${customerNumber}/filterName/${filterName}/type/${type}/measurementSystem/${measurementSystem}`,
    {}
  );
};
