import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import useRole from '../../../hooks/useRole';
import { AuthorityRole } from '../../../types/Authority.interface';
import AccountFormData from '../types/AccountFormData.interface';
import FieldPrefixes from '../types/FieldPrefixes.enum';
import {
  // DATE_FORMAT_OPTIONS,
  MEASUREMENT_SYSTEM_OPTIONS,
  ROLL_UNIT_OPTIONS,
  SHEET_UNIT_OPTIONS,
} from './UserSettings.util';

interface UserSettingsProps {
  formMethods: UseFormMethods<AccountFormData>;
  onSaveSettings: (accountFormData: AccountFormData) => void;
  isSaving: boolean;
}

const UserSettings: FC<UserSettingsProps> = ({
  formMethods,
  onSaveSettings,
  isSaving,
}) => {
  const { hasRole } = useRole();

  const {
    formState: { isValid },
    handleSubmit,
    register,
  } = formMethods;

  return (
    <>
      <Space
        direction="vertical"
        size="middle"
        className="w-1/4 mb-5"
        style={{ minWidth: '250px' }}
      >
        <InputLabel text={t`Measurement System`} size="lg">
          <Select
            name={`${FieldPrefixes.USER_SETTINGS}.unitOfMeasure`}
            ref={register()}
            options={MEASUREMENT_SYSTEM_OPTIONS}
          />
        </InputLabel>
        {hasRole(AuthorityRole.ROLE_QOB) && (
          <InputLabel text={t`Sheet Ordering Units`} size="lg">
            <Select
              name={`${FieldPrefixes.USER_SETTINGS}.sheetUnit`}
              ref={register()}
              options={SHEET_UNIT_OPTIONS}
            />
          </InputLabel>
        )}
        {hasRole(AuthorityRole.ROLE_QOB) && (
          <InputLabel text={t`Roll Ordering Units`} size="lg">
            <Select
              name={`${FieldPrefixes.USER_SETTINGS}.reelUnit`}
              ref={register()}
              options={ROLL_UNIT_OPTIONS}
            />
          </InputLabel>
        )}
        {/* <InputLabel text={t`Date Format`} size="lg">
          <Select
            name={`${FieldPrefixes.USER_SETTINGS}.dateFormat`}
            ref={register()}
            options={DATE_FORMAT_OPTIONS}
          />
        </InputLabel> */}
      </Space>
      <div className="flex justify-end">
        <Button
          theme="primary"
          onClick={handleSubmit(onSaveSettings)}
          disabled={isSaving || !isValid}
          loading={isSaving}
        >
          <Trans>Save Settings</Trans>
        </Button>
      </div>
    </>
  );
};

export default UserSettings;
