import useValues from '../../hooks/useValues';
import ValueKey from '../../types/ValueKey.enum';
import {
  downloadArrivalExcel,
  downloadDeviationExcel,
} from './TrackAndTrace.service';
import { DeviationData } from './types/DeviationData.interface';
import { TrackAndTraceData } from './types/TrackAndTraceData.interface';

const useDownloadExcel = (data: {
  deviation?: DeviationData[];
  arrival?: TrackAndTraceData[];
}): { downloadExcel: () => void } => {
  const { deviation: deviationData, arrival: arrivalData } = data;
  const { items } = useValues(ValueKey.DELAY_REASON);
  const downloadExcel = () => {
    if (deviationData && deviationData?.length > 0) {
      downloadDeviationExcel(deviationData, items || []);
    }
    if (arrivalData && arrivalData?.length > 0) {
      downloadArrivalExcel(arrivalData);
    }
  };

  return {
    downloadExcel,
  };
};

export default useDownloadExcel;
