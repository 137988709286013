import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import { login } from '../../services/Login';
import User from '../../types/User/User.interface';
import styles from './LoginForm.module.scss';
import LoginLinks from './LoginLinks';
import useRequiredPasswordUser from './useRequiredPasswordUser.store';

const ERROR_INVALID = t`Username or password entered is incorrect.`;
const ERROR_SERVER = t`There was an error processing your request. Please wait a few minutes and then try again.`;

interface LoginFormFields {
  username: string;
  password: string;
}

interface ILoginFormProps {
  onLoginSuccess: (data: User) => void;
}

const LoginForm: FC<ILoginFormProps> = ({ onLoginSuccess }) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const { register, errors, handleSubmit } = useForm<LoginFormFields>({
    mode: 'onSubmit',
  });

  const doSubmit = async (data: LoginFormFields) => {
    setErrorMessage(null);
    try {
      setLoggingIn(true);
      const { data: userData } = await login(data.username, data.password);
      setLoggingIn(false);
      onLoginSuccess(userData);
    } catch (e) {
      if (e.response.headers['password-change'] === 'true') {
        useRequiredPasswordUser.setState({ username: data.username });
        history.push('/passwordChangeRequired');
      } else if (e.response.headers['server-error'] === 'true') {
        setErrorMessage(ERROR_SERVER);
      } else {
        setErrorMessage(ERROR_INVALID);
      }
      setLoggingIn(false);
    }
    return true;
  };
  return (
    <form className={styles.loginForm} onSubmit={handleSubmit(doSubmit)}>
      <Space direction="vertical" size="large" className="w-full">
        <InputLabel
          text={t`User ID`}
          size="lg"
          className="text-white-white"
          htmlFor="username"
        >
          <Input
            ref={register({ required: true })}
            name="username"
            error={errors.username}
          />
        </InputLabel>
        <InputLabel
          text={t`Password`}
          size="lg"
          className="text-white-white"
          htmlFor="password"
        >
          <Input
            name="password"
            ref={register({
              required: true,
            })}
            type="password"
            error={errors.password}
          />
        </InputLabel>
        {(errors.password?.type === 'required' ||
          errors.username?.type === 'required') && (
          <div className="text-red-red">
            <Trans>Username and password are required.</Trans>
          </div>
        )}
        {errorMessage && <div className="text-red-red">{errorMessage}</div>}
        <Button theme="primary" className="my-4" loading={loggingIn}>
          <Trans>Login</Trans>
        </Button>
        <LoginLinks />
      </Space>
    </form>
  );
};

export default LoginForm;
