import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import {
  getCertificationDefaultValue,
  getCertificationOptions,
} from '../Basket.util';
import Select from '../../../../components/Select/Select';
import useItemRequiresUpdate from '../useItemRequiresUpdate';

interface ICertificationCellProps {
  item: AvailabilityBasketItem;
  index: number;
  basketId: number;
  formMethods: UseFormMethods;
}

const CertificationCell: FC<ICertificationCellProps> = ({
  item,
  index,
  basketId,
  formMethods,
}) => {
  const { register } = formMethods;
  const { updateItemRequiresUpdate } = useItemRequiresUpdate();

  return (
    <Select
      options={getCertificationOptions()}
      defaultValue={getCertificationDefaultValue(item)}
      className="text-blue-pacific"
      name={`basket[${index}].paperType`}
      ref={register()}
      onChange={() => {
        updateItemRequiresUpdate(item, index, basketId, formMethods);
      }}
    />
  );
};

export default CertificationCell;
