import { StockLotItem } from '../../../../../types/StockLotItem.interface';

export const getTotalQuantity = (items: StockLotItem[]): number =>
  items.map((item) => item.atpQuantity).reduce((prev, curr) => prev + curr, 0);

export const getAtpQuantity = (
  numberOfBatches: number,
  batches: StockLotItem[]
): number => {
  let atpQuantity = 0;
  for (let i = 0; i < numberOfBatches; i++) {
    atpQuantity += batches[i].atpQuantity;
  }
  return atpQuantity;
};
