import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';

interface ITransIdCellProps {
  transitId: string;
  payloadId: string;
}

const TransIdCell: FC<ITransIdCellProps> = ({ transitId, payloadId }) => {
  const history = useHistory();
  return (
    <Button
      theme="link"
      onClick={() => history.push(`/trackAndTrace/log/${payloadId}`)}
    >
      {transitId}
    </Button>
  );
};

export default TransIdCell;
