import * as React from 'react';
import { useState } from 'react';
import FinancialReportingMain from './Main/FinancialReportingMain';
import FinancialReportingSidebar from './Sidebar/FinancialReportingSidebar';
import style from './FinancialReporting.module.scss';

interface IFinancialReportingProps {
  attr?: unknown;
}

const FinancialReporting: React.FunctionComponent<IFinancialReportingProps> = () => {
  const [selectedCustomerNum, setSelectedCustomerNum] = useState<string>();

  return (
    <>
      <div className={`${style.container} flex -mx-3.5`}>
        <div className={`${style['sidebar-wrapper']} mx-3.5`}>
          <FinancialReportingSidebar
            selectedCustomerNum={selectedCustomerNum || ''}
            setSelectedCustomerNum={setSelectedCustomerNum}
          />
        </div>
        <div className="mx-3.5 flex-grow">
          <FinancialReportingMain customerNumber={selectedCustomerNum || ''} />
        </div>
      </div>
    </>
  );
};

export default FinancialReporting;
