import { t } from '@lingui/macro';
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import Table from '../../../../components/Table/Table';
import { AvailabilityBasketNA } from '../../../../types/AvailabilityBasketNA.interface';
import CustomerAddress from '../CustomerAddress';
import {
  deriveContactNameFromNotes,
  deriveContactNotesFromNotes,
  deriveDeliveryInstructionsFromNotes,
  deriveEmailFromNotes,
  derivePhoneFromNotes,
  deriveReceivingFromNotes,
} from '../NaBasket.util';
import ContactInfoCell from './ContactInfoCell';
import styles from './CustomerDeliveryLocationTable.module.scss';

interface ICustomerDeliveryLocationTableProps {
  basket: AvailabilityBasketNA;
}

const CustomerDeliveryLocationTable: FC<ICustomerDeliveryLocationTableProps> = ({
  basket,
}) => {
  const columns: ColumnsType<AvailabilityBasketNA> = [
    {
      title: t`Customer`,
      key: 'customerInformation',
      className: 'align-top',
      render: (value, record) => (
        <CustomerAddress
          customerNumber={record.soldToCustomer.number}
          customerName={record.soldToCustomer.name}
          street={record.soldToCustomer.address}
          city={record.soldToCustomer.city}
          postalCode={record.soldToCustomer.postalCode}
          country={record.soldToCustomer.country}
        />
      ),
    },
    {
      title: t`Delivery Location`,
      key: 'deliveryLocation',
      className: 'align-top',
      render: (value, record) => (
        <CustomerAddress
          customerNumber={record.shipToCustomer?.number}
          customerName={record.shipToName || ''}
          street={record.shipToAddress || ''}
          city={record.shipToCity || ''}
          postalCode={record.shipToPostalCode || ''}
          country={record.soldToCustomer.country}
        />
      ),
    },
    {
      title: t`Contact Info`,
      key: 'contactInfo',
      className: 'align-top',
      render: (value, record) => (
        <ContactInfoCell
          name={deriveContactNameFromNotes(record.contactNotes1)}
          phone={derivePhoneFromNotes(record.contactNotes1)}
          email={deriveEmailFromNotes(record.contactNotes1)}
          receiving={deriveReceivingFromNotes(record)}
        />
      ),
    },
    {
      title: t`Additional Instructions`,
      key: 'addtlInstructions',
      className: 'align-top',
      render: (value, record) => (
        <Space size="middle" direction="vertical">
          <div>{deriveDeliveryInstructionsFromNotes(record)}</div>
          <div>{deriveContactNotesFromNotes(record)}</div>
        </Space>
      ),
    },
  ];
  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={[basket]}
      pagination={false}
      className={styles.table}
    />
  );
};

export default CustomerDeliveryLocationTable;
