import { AnalyticsEventProps } from './AnalyticsEventProps.interface';
import AnalyticsEventType from './AnalyticsEventType.enum';

const track = (type: AnalyticsEventType, props: AnalyticsEventProps): void => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: type,
    eventProps: props,
  });
};

export default track;
