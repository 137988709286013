import { t, Trans } from '@lingui/macro';
import { Table as AntTable } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import Table from '../../../../components/Table/Table';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import { AvailabilityBasketNA } from '../../../../types/AvailabilityBasketNA.interface';
import AvailabilityBasketType from '../../../../types/AvailabilityBasketType.enum';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import ItemDescriptionCellContent from '../BasketStep/ItemDescriptionCellContent';
import CertificationValue from '../types/CertificationValue.enum';
import OrderDetailsCell from './OrderDetailsCell';
import styles from './OrderDetailsTable.module.scss';
import PlannedProductionDescriptionCell from './PlannedProductionDescriptionCell';
import QuantityCell from './QuantityCell';

interface IOrderDetailsTableProps {
  basket: AvailabilityBasketNA;
}

const OrderDetailsTable: FC<IOrderDetailsTableProps> = ({ basket }) => {
  const { items } = basket;
  const { isMetric } = useMeasurementSystem();
  const { format } = useNumberFormatter();
  const columns: ColumnsType<AvailabilityBasketItem> = [
    {
      title: t`Item Description`,
      key: 'itemDescription',
      render: (value, record) =>
        record.basketType === AvailabilityBasketType.PRODUCT ? (
          <PlannedProductionDescriptionCell basketItem={record} />
        ) : (
          <ItemDescriptionCellContent basketItem={record} />
        ),
    },
    {
      title: t`Label Info`,
      key: 'labelInfo',
      className: 'align-top',
      render: (value, record) => (
        <div>
          {record.productType === PutUpCategory.SHEET && (
            <LabelValue
              inline
              inverse
              label={t`Pallet Label Info`}
              value={record.newLabel}
            />
          )}
          {record.productType === PutUpCategory.ROLL && (
            <LabelValue
              inline
              inverse
              label={t`Roll Label Info`}
              value={record.newLabel}
            />
          )}
        </div>
      ),
    },
    {
      title: t`Certification`,
      key: 'certification',
      className: 'align-top',
      render: (value, record) =>
        record.basketType !== AvailabilityBasketType.PRODUCT ? (
          <>
            <div className="font-bold">{record.certification}</div>
            {record.certification === CertificationValue.FSC && (
              <div>
                <Trans>Possible upcharge for FSC option.</Trans>
              </div>
            )}
          </>
        ) : (
          <></>
        ),
    },
    {
      title: t`Quantity`,
      key: 'quantity',
      className: 'align-top',
      render: (value, record) => <QuantityCell basketItem={record} />,
    },
    {
      title: t`Order Details`,
      key: 'orderDetails',
      className: 'align-top',
      render: (value, record) => <OrderDetailsCell basketItem={record} />,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={items}
      pagination={false}
      className={styles.table}
      summary={() => (
        <>
          <AntTable.Summary.Row className="text-lg font-bold">
            <AntTable.Summary.Cell
              index={0}
              colSpan={3}
              className="text-right border-r border-gray-light400"
            >
              <Trans>Total Weight:</Trans>
            </AntTable.Summary.Cell>
            <AntTable.Summary.Cell index={1} colSpan={2}>
              {isMetric ? (
                <>
                  {format(Math.round(basket.totalQuantityKg))} <Trans>kg</Trans>
                </>
              ) : (
                <>
                  {format(Math.round(basket.totalQuantityLb))}{' '}
                  <Trans>pounds</Trans>
                </>
              )}
            </AntTable.Summary.Cell>
          </AntTable.Summary.Row>
        </>
      )}
    />
  );
};

export default OrderDetailsTable;
