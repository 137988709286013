import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { Modal } from 'antd';
import { noop } from 'lodash';
import { Button } from '../Button/Button';

interface IConfirmationProps {
  show: boolean;
  filterNameInput: string;
  onConfirm: (continueToSave: boolean) => void;
}

const ConfirmationModal: React.FunctionComponent<IConfirmationProps> = ({
  show,
  filterNameInput,
  onConfirm,
}) => {
  return (
    <Modal
      className="filter-modal-container"
      maskTransitionName=""
      visible={show}
      title={
        <div
          key="alertFilterModalTitle"
          className="text-white-white px-4 py-3 text-lg justify-between items-center flex bg-blue-pacific"
        >
          <p className="text-2xl font-bold pl-3 py-4 flex-grow">
            <Trans>Save Filter</Trans>
          </p>
        </div>
      }
      closeIcon={
        <div className="pt-8 pr-10" key="alertFilterModalIcon">
          <div
            className="fa fa-times text-2xl"
            onClick={() => onConfirm(false)}
            onKeyDown={noop}
            role="button"
            aria-label={t`Close Confirmation Modal`}
            tabIndex={0}
          />
        </div>
      }
      footer={[
        <div className="pl-9 pb-5 text-left" key="alertFilterModalFooter">
          <Button
            key="continueOverwriteFilter"
            theme="primary"
            onClick={() => onConfirm(true)}
          >
            <Trans>Continue</Trans>
          </Button>

          <Button key="cancelOverwriteFilter" onClick={() => onConfirm(false)}>
            <Trans>Cancel</Trans>
          </Button>
        </div>,
      ]}
    >
      <p className="pl-7">
        <Trans>Filter</Trans>
        <span className="mx-0.5" />
        <strong>{filterNameInput}</strong>
        <span className="mx-0.5" />
        <Trans>
          already exists. Click continue to override the existing filter.
        </Trans>
      </p>
    </Modal>
  );
};

export default ConfirmationModal;
