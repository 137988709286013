import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ChangePassword from '../../components/ChangePassword/ChangePassword';
import { login } from '../../services/Login';
import { updatePasswordOnLogin } from '../../services/User';
import UpdatePasswordRequest from '../../types/User/UpdatePasswordRequest.interface';
import User from '../../types/User/User.interface';
import { getPasswordErrorMessage } from '../Account/types/PasswordErrors.service';
import useRequiredPasswordUser from './useRequiredPasswordUser.store';

const DEFAULT_VALUES: UpdatePasswordRequest = {
  currentPassword: '',
  newPassword: '',
  confirmedPassword: '',
};

interface IRequiredPasswordChangeProps {
  onLoginSuccess: (data: User) => void;
}

const RequiredPasswordChange: FC<IRequiredPasswordChangeProps> = ({
  onLoginSuccess,
}) => {
  const history = useHistory();
  const username = useRequiredPasswordUser((state) => state.username);
  const formMethods = useForm<UpdatePasswordRequest>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES,
  });
  const { reset } = formMethods;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const doSubmit = async (values: UpdatePasswordRequest) => {
    if (username) {
      setHasError(false);
      setIsSubmitting(true);
      let responseError: any;

      try {
        await updatePasswordOnLogin({ ...values, username }).catch(e => {
          setHasError(true);
          responseError = e;
          throw new Error();
        });;
        const { data } = await login(username, values.newPassword);
        onLoginSuccess(data);
      } catch {
        setHasError(true);
        setErrorMessage(getPasswordErrorMessage(responseError?.response?.data?.error || ''))
      } finally {
        reset();
      }

      setIsSubmitting(false);
    } else {
      history.push('/login');
    }
  };

  return (
    <ChangePassword
      onSubmit={doSubmit}
      formMethods={formMethods}
      isSubmitting={isSubmitting}
      hasError={hasError}
      errorMessage={errorMessage}
    />
  );
};

export default RequiredPasswordChange;
