import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import React from 'react';
import LabelValue from '../../../components/LabelValue/LabelValue';
import StockWeights from '../../../components/StockWeights';
import Table from '../../../components/Table/Table';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import { Batch } from '../types/Batch.interface';
import styles from './ResultsTableRow.module.scss';
import StockLotAddToBasket from './StockLotAddToBasket';

interface IResultsTableRowProps {
  result: Batch;
  showHeader?: boolean;
  highlightRow?: boolean;
  onAddItemToBasket: (item: Batch) => Promise<void>;
  isItemBeingAdded: boolean;
}

const ResultsTableRow: React.FunctionComponent<IResultsTableRowProps> = ({
  result,
  showHeader,
  highlightRow,
  onAddItemToBasket,
  isItemBeingAdded,
}) => {
  const { format } = useNumberFormatter();
  const PRODUCT: ColumnType<Batch> = {
    title: t`Product`,
    key: 'salesGradeDescription',
    dataIndex: 'salesGradeDescription',
    width: '15%',
  };
  const GRAMMAGE: ColumnType<Batch> = {
    title: t`Grammage`,
    key: 'grammage',
    dataIndex: 'grammage',
    width: '10%',
  };
  const WIDTH: ColumnType<Batch> = {
    title: t`Width`,
    key: 'bwidth',
    dataIndex: 'bwidth',
    width: '10%',
  };
  const LENGTH: ColumnType<Batch> = {
    title: t`Length`,
    key: 'blength',
    dataIndex: 'blength',
    width: '10%',
  };
  const ROLL_DIAMETER: ColumnType<Batch> = {
    title: t`Roll Diameter`,
    key: 'bReelDiameter',
    dataIndex: 'bReelDiameter',
    width: '15%',
  };
  const CORE_DIAMETER: ColumnType<Batch> = {
    title: t`Core Diameter`,
    key: 'coreDiameter',
    dataIndex: 'coreDiameter',
    width: '15%',
  };
  const STOCK_LOCATION: ColumnType<Batch> = {
    title: t`Stock Location`,
    key: 'plantLocation',
    dataIndex: 'plantLocation',
    width: '15%',
  };
  const CHARACTERISTICS: ColumnType<Batch> = {
    title: t`Characteristics`,
    key: 'characteristics',
    width: '25%',
    render: (value: unknown, record: Batch) => (
      <Space direction="vertical" size="middle">
        <div>
          <StockWeights
            stacksPerPallet={+record.stacksPerPallet}
            sheetsPerReam={+record.sheetsPerReam}
            txtWrappingType={record.wrappingDescription}
            sheetsPerPallet={+record.sheetsPerPallet}
          />
          {record.wrappingDescription}
        </div>
        <LabelValue
          inverse
          label={t`Unit`}
          value={
            record.productType === PutUpCategory.SHEET ? (
              <Trans>Pallet</Trans>
            ) : (
              <Trans>Roll</Trans>
            )
          }
        />
      </Space>
    ),
  };
  const AVAILABLE: ColumnType<Batch> = {
    title: t`Available`,
    key: 'available',
    width: '15%',
    render: (value: unknown, record: Batch) => (
      <Space direction="vertical" size="middle">
        <LabelValue
          inverse
          label={t`Total Qty`}
          value={`${record.atpQuantity.toLocaleString()} KG`}
        />
        <LabelValue
          inverse
          inline
          label={t`Available`}
          value={record.numberOfUnits}
        />
      </Space>
    ),
  };

  const SHEETS_COLUMNS: ColumnsType<Batch> = [
    PRODUCT,
    GRAMMAGE,
    WIDTH,
    LENGTH,
    STOCK_LOCATION,
    CHARACTERISTICS,
    AVAILABLE,
  ];
  const ROLL_COLUMNS: ColumnsType<Batch> = [
    PRODUCT,
    GRAMMAGE,
    WIDTH,
    ROLL_DIAMETER,
    CORE_DIAMETER,
    STOCK_LOCATION,
    AVAILABLE,
  ];
  return (
    <div>
      <Table
        rowKey="id"
        className={`${styles.table} ${
          highlightRow ? styles['table--blue'] : ''
        }`}
        columns={
          result.productType === PutUpCategory.SHEET
            ? SHEETS_COLUMNS
            : ROLL_COLUMNS
        }
        dataSource={[result]}
        pagination={false}
        showHeader={showHeader}
      />
      <div
        className={`p-3 border-b border-l border-r border-gray-light400 text-right ${
          highlightRow ? 'bg-lavender-pale' : ''
        }`}
      >
        <StockLotAddToBasket
          item={result}
          onAddToBasket={onAddItemToBasket}
          isItemBeingAdded={isItemBeingAdded}
        />
      </div>
    </div>
  );
};

export default ResultsTableRow;
