import { t } from '@lingui/macro';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import ControlledDatePicker from '../../../components/DatePicker/ControlledDatePicker';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import ValueKey from '../../../types/ValueKey.enum';
import useValueOptions from '../useValueOptions';
import DeviationFormField from './DeviationFormField.enum';
import useDeviationDataStore from './useDeviationData.store';

interface ISearchStepDeviationDatesCellProps {
  index: number;
}

const SearchStepDeviationDatesCell: FC<ISearchStepDeviationDatesCellProps> = ({
  index,
}) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const newPlannedDateName = `deviationRow.${index}.${DeviationFormField.NEW_PLANNED_DATE}`;
  const reasonName = `deviationRow.${index}.${DeviationFormField.DEVIATION_REASON}`;
  const reasonError =
    errors?.deviationRow?.[index]?.[DeviationFormField.DEVIATION_REASON];
  const defaultPlannedDate = useDeviationDataStore(
    (state) => state?.deviationRow[index]?.newPlannedDate
  );
  const defaultReason = useDeviationDataStore(
    (state) => state?.deviationRow[index]?.deviationReason
  );
  const { valueOptions: reasonOptions } = useValueOptions(
    ValueKey.DELAY_REASON
  );
  const newPlannedDate = watch(newPlannedDateName);
  return (
    <>
      <InputLabel text={t`New Planned`} size="lg">
        <ControlledDatePicker
          name={newPlannedDateName}
          className="w-56"
          showTime={{ format: 'HH:mm' }}
          control={control}
          defaultValue={defaultPlannedDate}
        />
      </InputLabel>
      <InputLabel text={t`Reason`} size="lg" required={!!newPlannedDate}>
        <Select
          name={reasonName}
          ref={register({ required: !!newPlannedDate })}
          options={reasonOptions}
          defaultValue={defaultReason || ''}
          error={reasonError}
          required={!!newPlannedDate}
        />
      </InputLabel>
    </>
  );
};

export default SearchStepDeviationDatesCell;
