import React, { FC } from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

export type ILegendProps = {
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  required?: boolean;
  isFetching?: boolean;
};

const Legend: FC<ILegendProps> = ({
  size,
  required,
  className = '',
  isFetching,
  children,
  ...rest
}) => {
  return (
    <div className="flex">
      <legend
        className={`${size === 'lg' ? 'text-xl font-extrabold' : ''} ${
          size === 'md' ? 'text-lg' : ''
        } ${className}`}
        {...rest}
      >
        {required && <span className="text-red-red pr-0.5">*</span>}
        {children}
      </legend>
      {isFetching && <LoadingSpinner className="mt-3 ml-2" />}
    </div>
  );
};

export default Legend;
