import { t, Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tabs, { TabPane } from '../../../components/Tabs/Tabs';
import useAnalytics from '../../../hooks/useAnalytics';
import { Exception } from '../../../types/Exception.interface';
import {
  getConsignmentErrorById,
  getConsignmentResults,
} from '../Consignment.service';
import ErrorMessage from '../ErrorMessage';
import { UploadedItem } from '../types/UploadedItem.interface';
import Results from './Results/Results';
import UploadCosumptionItems from './UploadCosumptionItems';

enum Tab {
  UPLOAD = 'UPLOAD',
  RESULTS = 'RESULTS',
}

interface IConsignmentConsumptionProps {
  prop?: unknown;
}

const ConsignmentConsumption: React.FunctionComponent<IConsignmentConsumptionProps> = () => {
  const { status: statusParam } = useParams<{ status: string }>();
  const { trackPageView } = useAnalytics();
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.UPLOAD);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorExceptions, setErrorExceptions] = useState<Exception[] | null>(
    null
  );
  const [results, setResults] = useState<UploadedItem[]>([]);
  const [defaultSelectedCustomer, setDefaultSelectedCustomer] = useState<
    string
  >();
  const [defaultSelectedPoNumber, setDefaultSelectedPoNumber] = useState<
    string | null
  >(null);

  useEffect(() => {
    const clearErrors = () => {
      setErrorMessage(null);
      setErrorExceptions(null);
    };
    const getExceptions = async (status: string) => {
      const error = await getConsignmentErrorById(status);
      setErrorExceptions(error.exceptions);
    };
    const onSuccess = async () => {
      const { uploadedItems = [] } = await getConsignmentResults();
      setResults(uploadedItems);
      setDefaultSelectedCustomer(uploadedItems[0].selectedCustomerNumber);
      setDefaultSelectedPoNumber(uploadedItems[0].selectedPurchaseOrder);
      trackPageView('CONSIGNMENT', 'UPLOAD_FILE');
      setSelectedTab(Tab.RESULTS);
      clearErrors();
    };

    if (statusParam) {
      if (statusParam === 'success') {
        onSuccess();
      } else if (statusParam === 'error') {
        setErrorMessage(t`Please use a CSV, XLS, or XLSX file`);
      } else if (statusParam !== 'null') {
        getExceptions(statusParam);
      } else {
        setErrorMessage(
          t`An unspecified error has occurred while attempting to upload the above file.`
        );
      }
    }
  }, [statusParam, trackPageView]);

  return (
    <Tabs
      type="card"
      activeKey={selectedTab}
      onChange={(key) => {
        setSelectedTab(key as Tab);
      }}
    >
      <TabPane tab={<Trans>Upload Consumption Items</Trans>} key={Tab.UPLOAD}>
        <UploadCosumptionItems
          defaultSelectedCustomer={defaultSelectedCustomer}
          defaultSelectedPoNumber={defaultSelectedPoNumber}
        />
        <ErrorMessage message={errorMessage} exceptions={errorExceptions} />
      </TabPane>
      <TabPane
        tab={<Trans>Consumption Upload Results</Trans>}
        key={Tab.RESULTS}
        disabled={statusParam !== 'success'}
      >
        <Results results={results} />
      </TabPane>
    </Tabs>
  );
};

export default ConsignmentConsumption;
