import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import useOrderBookingBasket from '../../../hooks/useOrderBookingBasket';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

const EuBasketSaved: FC = () => {
  const { basketId } = useParams<{ basketId: string }>();
  const { data: basket, isFetching } = useOrderBookingBasket(basketId);

  return (
    <>
      {isFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        <>
          {basket && (
            <>
              <div className="text-3xl">
                <Trans>
                  Your Enquiry has been saved and an email confirmation has been
                  sent to you.
                </Trans>
              </div>
              <div className="text-2xl font-bold">
                <Trans>Enquiry # {basket.enquiryNumber}.</Trans>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EuBasketSaved;
