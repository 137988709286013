import { t } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import { EMPTY_OPTION_SELECT } from '../../../../services/Util/emptyOption.const';
import fractionOrDecimalInput from '../../../../services/Util/fractionOrDecimalInput.util';
import positiveIntegerOnlyInput from '../../../../services/Util/positiveIntegerOnlyInput.util';
import { EU_NUMBER_REG_EX } from '../../../../types/RegularExpression.constants';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import { DiameterRequestParams } from '../types/DiameterRequestParams.interface';
import useDiameterOptions from '../useDiameterOptions';

const UOM_OPTIONS = [
  EMPTY_OPTION_SELECT,
  {
    value: UnitOfMeasure.RL,
    label: t`Rolls`,
  },
  {
    value: UnitOfMeasure.KG,
    label: t`Kilograms`,
  },
  {
    value: UnitOfMeasure.TO,
    label: t`Tons`,
  },
];

interface IRollCriteriaProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  getValues: UseFormMethods['getValues'];
  trigger: UseFormMethods['trigger'];
  errors: UseFormMethods['errors'];
  diameterParams: DiameterRequestParams;
}

const RollCriteria: React.FunctionComponent<IRollCriteriaProps> = ({
  register,
  watch,
  getValues,
  trigger,
  errors,
  diameterParams,
}) => {
  const reelLength = watch('ivReelLength');
  const reelDiameter = watch('ivReelDiameter');
  const { reelDiameterOptions, coreDiameterOptions } = useDiameterOptions(
    diameterParams
  );

  return (
    <Space direction="vertical" size="middle">
      <InputLabel text={t`Roll Width (cm)`} size="lg" required>
        <Input
          name="ivReelWidth"
          ref={register({
            required: true,
            pattern: EU_NUMBER_REG_EX,
          })}
          onKeyPress={fractionOrDecimalInput}
          required
          error={errors?.ivReelWidth}
        />
      </InputLabel>
      <InputLabel text={t`Roll Length (m)`} size="lg" required={!reelDiameter}>
        <Input
          name="ivReelLength"
          ref={register({
            pattern: EU_NUMBER_REG_EX,
            validate: (value) => {
              return !!getValues().ivReelDiameter || !!value;
            },
          })}
          onKeyPress={fractionOrDecimalInput}
          onChange={() => trigger('ivReelDiameter')}
          required
          disabled={!!reelDiameter}
          error={errors?.ivReelLength}
        />
      </InputLabel>
      <Space size="large">
        <InputLabel
          text={t`Roll Diameter (cm)`}
          size="lg"
          required={!reelLength}
        >
          <Select
            name="ivReelDiameter"
            ref={register({
              validate: (value) => {
                return !!getValues().ivReelLength || !!value;
              },
            })}
            options={reelDiameterOptions}
            required
            width="36"
            onChange={() => trigger('ivReelLength')}
            disabled={!!reelLength}
            error={errors?.ivReelDiameter}
          />
        </InputLabel>
        <InputLabel text={t`Core Diameter (cm)`} size="lg" required>
          <Select
            name="ivCoreDiameter"
            ref={register({ required: true })}
            options={coreDiameterOptions}
            required
            width="36"
            error={errors?.ivCoreDiameter}
          />
        </InputLabel>
      </Space>
      <Space size="large" align="end">
        <InputLabel text={t`Rolls per Package`} size="lg" required>
          <Input
            name="ivReelsPerPackage"
            type="number"
            ref={register({ required: true, max: 9 })}
            required
            defaultValue="1"
            error={errors?.ivReelsPerPackage}
          />
        </InputLabel>
        <Checkbox name="ivPackageType" ref={register()} label={t`On Pallet`} />
      </Space>
      <div className="flex items-end">
        <InputLabel text={t`Unit Quantity`} size="lg" required>
          <Input
            name="ivQtyReels"
            ref={register({ maxLength: 9, required: true })}
            required
            width="36"
            onKeyDown={positiveIntegerOnlyInput}
            error={errors?.ivQtyReels}
          />
        </InputLabel>
        <InputLabel text="" size="lg" className="ml-16">
          <Select
            name="ivReelUom"
            ref={register({ required: true })}
            options={UOM_OPTIONS}
            required
            width="36"
            error={errors?.ivReelUom}
          />
        </InputLabel>
      </div>
    </Space>
  );
};

export default RollCriteria;
