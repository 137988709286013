import { Trans } from '@lingui/macro';
import React from 'react';
import { Button, ButtonProps } from '../../../components/Button/Button';

const PrintInvoicesButton: React.FunctionComponent<ButtonProps> = ({
  children,
  ...rest
}) => {

  return (
    <Button {...rest}>
      <div className="flex">
        <span className="flex items-center">
          <i className="fa fa-file-pdf-o pr-3 fa-2x" />
          <Trans id="availableActions.printInvoice">Print Invoice(s)</Trans>
        </span>
      </div>
    </Button>
  );
};

export default PrintInvoicesButton;
