import { t, Trans } from '@lingui/macro';
import { Collapse, Space } from 'antd';
import { orderBy, upperCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import { Button } from '../../../components/Button/Button';
import excelIconSmallImage from '../../../assets/eu/excel-icon-small.png';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import SearchPopover from '../../../components/SearchPopover/SearchPopover';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import useMeasurementUnit from '../../../hooks/useMeasurementUnit';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { searchOpenOrders } from '../../../services/OpenOrders';
import { OrderOverviewRecord } from '../../../types/OrderOverviewRecord.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { OpenOrderFilters } from './OpenOrderFilters.interface';
import { PopoverOptions } from './PopoverOptions.interface';
import './Results.scss';
import getStatusSectionData, { getPopoverOptions, getOpenOrdersExcel } from './Results.util';
import ResultsSearchForm from './ResultsSearchForm';
import ResultsTable from './ResultsTable';
import Status from './Status.enum';
import StatusSectionHeader from './StatusSectionHeader';
import useOpenOrdersStore from '../useOpenOrdersStore';
import DownloadOpenOrders from './DownloadOpenOrders';
import OpenOrders from '../../../types/OpenOrders.interface';
import useAnalytics from '../../../hooks/useAnalytics';

const { Panel } = Collapse;

const ExpandIcon = (isActive?: boolean) => (
  <span>
    <i
      className={`fas text-2xl relative -top-1 -left-2 text-blue-pacific ${
        isActive ? 'fa-minus-circle' : 'fa-plus-circle'
      }`}
    />
  </span>
);

interface IResultsProps {
  customerNumber: string;
  activeKeys: Status[];
  setActiveKeys: (keys: Status[]) => void;
}

const Results: React.FunctionComponent<IResultsProps> = ({
  customerNumber,
  activeKeys,
  setActiveKeys,
}) => {
  const { measurementSystem } = useMeasurementSystem();
  const { measurementUnit2 } = useMeasurementUnit();
  const { format } = useNumberFormatter();
  const { trackPageView } = useAnalytics();
  const [openOrders, setOpenOrders] = useState<OpenOrders[]>();
  const [statusSections, setStatusSections] = useState({
    [Status.OPEN]: {
      count: 0,
      totalWgt: 0,
      results: [] as OrderOverviewRecord[],
    },
    [Status.UNDER_PRODUCTION]: {
      count: 0,
      totalWgt: 0,
      results: [] as OrderOverviewRecord[],
    },
    [Status.READY_TO_SHIP]: {
      count: 0,
      totalWgt: 0,
      results: [] as OrderOverviewRecord[],
    },
    [Status.SHIPPED]: {
      count: 0,
      totalWgt: 0,
      results: [] as OrderOverviewRecord[],
    },
  });
  const [popoverOptions, setPopoverOptions] = useState<PopoverOptions>({
    mill: [],
    product: [],
    orderPlacedDate: [],
    confirmedDeliveryDate: [],
    shipTo: [],
  });
  const { visible: popoverVisible } = useOpenOrdersStore();
  const [updatePopoverOptions, setUpdatePopoverOptions] = useState(false);
  const { register, handleSubmit, formState } = useForm({
    mode: 'all',
  });
  const [filters, setFilters] = useState<Partial<OpenOrderFilters>>({
    customerNumber,
  });
  const { resolvedData: data, isFetching } = usePaginatedQuery(
    [QueryCacheName.ORDERS, filters],
    searchOpenOrders,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: customerNumber,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (data) {
      setStatusSections({
        [Status.OPEN]: getStatusSectionData(
          Status.OPEN,
          data,
          measurementSystem
        ),
        [Status.UNDER_PRODUCTION]: getStatusSectionData(
          Status.UNDER_PRODUCTION,
          data,
          measurementSystem
        ),
        [Status.READY_TO_SHIP]: getStatusSectionData(
          Status.READY_TO_SHIP,
          data,
          measurementSystem
        ),
        [Status.SHIPPED]: getStatusSectionData(
          Status.SHIPPED,
          data,
          measurementSystem
        ),
      });
      if (updatePopoverOptions) {
        setPopoverOptions(getPopoverOptions(data.results));
        setUpdatePopoverOptions(false);
      }
    
      setOpenOrders(getOpenOrdersExcel(data.results));
    }
  }, [data, measurementSystem, updatePopoverOptions]);

  useEffect(() => {
    setUpdatePopoverOptions(true);
    setFilters({
      customerNumber,
    });
  }, [customerNumber]);

  const getTotalWgt = (status: Status) =>
    `${format(
      Math.round(statusSections[status].totalWgt)
    )} ${measurementUnit2}`;

  const submitSearch = handleSubmit((newFilters: Partial<OpenOrderFilters>) => {
    useOpenOrdersStore.setState({ visible: false });
    setActiveKeys([]);
    setFilters({
      customerNumber,
      ...newFilters,
    });
  });

  return (
    <div className="mb-5">
      {isFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        <>
          <div className="flex justify-between items-center">
            <Space className="mb-4">
              <Button
                theme="link"
                onClick={() => {
                  trackPageView('MILL_DIRECT', 'EXPAND_ALL');
                  setActiveKeys([
                    Status.OPEN,
                    Status.UNDER_PRODUCTION,
                    Status.READY_TO_SHIP,
                    Status.SHIPPED,
                  ]);
                }}
              >
                <Trans>Expand All</Trans>
              </Button>
              |
              <Button theme="link" onClick={() => {
                trackPageView('MILL_DIRECT', 'COLLAPSE_ALL');
                setActiveKeys([]);
              }}>
                <Trans>Collapse All</Trans>
              </Button>
            </Space>
            <Space size="middle">
              <div>
                {isFetching ? (
                  <LoadingSpinner horizontal />
                ) : (
                  <>
                    <div className="flex justify-end font-bold text-xl mb-6 mt-4">
                      {upperCase(t`Download Excel Report`)}
                      <DownloadOpenOrders openOrders={openOrders || []}>
                        <img
                          src={excelIconSmallImage}
                          alt={t`Excel Icon Small`}
                          className="ml-2 -mt-1"
                        />
                      </DownloadOpenOrders>
                    </div>
                  </>
                )}
              </div>
              <SearchPopover
                visible={popoverVisible}
                placement="bottomLeft"
                title={t`Search Orders`}
                onClose={() => useOpenOrdersStore.setState({ visible: false })}
                className="open-orders-search-popover"
                body={
                  <ResultsSearchForm
                    register={register}
                    millOptions={popoverOptions.mill}
                    productOptions={popoverOptions.product}
                    orderPlacedDateOptions={popoverOptions.orderPlacedDate}
                    confirmedDeliveryDateOptions={
                      popoverOptions.confirmedDeliveryDate
                    }
                    shipToOptions={popoverOptions.shipTo}
                    selectedFilters={filters}
                  />
                }
                button={
                  <Button
                    theme="primary"
                    onClick={submitSearch}
                    disabled={!formState.isValid}
                  >
                    {t({
                      id: 'search.inline.submit.value',
                      message: 'Search',
                    })}
                  </Button>
                }
              >
                <Button
                  theme="link"
                  aria-label="Search Icon"
                  onClick={() => useOpenOrdersStore.setState({ visible: true })}
                >
                  <i className="fa fa-search text-xl" />
                </Button>
              </SearchPopover>
            </Space>
          </div>
          <div>
            <Collapse
              activeKey={activeKeys}
              onChange={(key) => {
                setActiveKeys(key as Status[]);
              }}
              ghost
              expandIcon={({ isActive }) => ExpandIcon(isActive)}
            >
              <Panel
                key={Status.OPEN}
                header={
                  <StatusSectionHeader
                    statusText={<Trans>Pre-Production</Trans>}
                    count={statusSections[Status.OPEN].count}
                    totalWgt={getTotalWgt(Status.OPEN)}
                  />
                }
              >
                <ResultsTable
                  status={Status.OPEN}
                  results={statusSections[Status.OPEN].results}
                />
              </Panel>
              <Panel
                key={Status.UNDER_PRODUCTION}
                header={
                  <StatusSectionHeader
                    statusText={<Trans>Under Production</Trans>}
                    count={statusSections[Status.UNDER_PRODUCTION].count}
                    totalWgt={getTotalWgt(Status.UNDER_PRODUCTION)}
                  />
                }
              >
                <ResultsTable
                  status={Status.UNDER_PRODUCTION}
                  results={statusSections[Status.UNDER_PRODUCTION].results}
                />
              </Panel>
              <Panel
                key={Status.READY_TO_SHIP}
                header={
                  <StatusSectionHeader
                    statusText={<Trans>Ready to Ship</Trans>}
                    count={statusSections[Status.READY_TO_SHIP].count}
                    totalWgt={getTotalWgt(Status.READY_TO_SHIP)}
                  />
                }
              >
                <ResultsTable
                  status={Status.READY_TO_SHIP}
                  results={statusSections[Status.READY_TO_SHIP].results}
                />
              </Panel>
              <Panel
                key={Status.SHIPPED}
                header={
                  <StatusSectionHeader
                    statusText={<Trans>Shipped</Trans>}
                    count={statusSections[Status.SHIPPED].count}
                    totalWgt={getTotalWgt(Status.SHIPPED)}
                  />
                }
              >
                <ResultsTable
                  status={Status.SHIPPED}
                  results={statusSections[Status.SHIPPED].results}
                />
              </Panel>
            </Collapse>
          </div>
        </>
      )}
    </div>
  );
};

export default Results;
