import create from 'zustand';

type QuantityUpdateState = {
  isQuantityUpdating: boolean;
};

const useQuantityUpdateStore = create<QuantityUpdateState>(() => ({
  isQuantityUpdating: false,
}));

export default useQuantityUpdateStore;
