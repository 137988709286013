import Axios from 'axios';
import { FinancialPagedResults } from '../../types/FinancialPagedResults.interface';
import { FinancialSummary } from '../../types/FinancialSummary.interface';
import { SavedInvoice } from '../../types/SavedInvoice.interface';

export const getFinancialSummary = async (
  customerNumber: string
): Promise<FinancialSummary> => {
  const { data } = await Axios.get(
    `/portal/resources/financials/${customerNumber}`
  );
  return data;
};

export interface FinancialInvoiceParams {
  customerNumber: string;
  period: string;
  page: number;
  sort: string;
  sortDirection: 'ASC' | 'DESC';
}

export const getFinancialInvoices = async (
  _: string,
  params: FinancialInvoiceParams
): Promise<FinancialPagedResults<SavedInvoice>> => {
  const url =
    params.page === 1
      ? `/portal/resources/financials/invoices/${params.customerNumber}/${params.period}/page/${params.page}/${params.sort}/${params.sortDirection}`
      : `/portal/resources/financials/${params.customerNumber}/page/${params.page}/${params.sort}/${params.sortDirection}`;
  // const url =
  //   'https://my.api.mockaroo.com/portal/resources/financials/invoices/123/1/page/123/123/123?key=da49ce80';
  const { data, headers } = await Axios.get(url);
  return {
    results: data,
    totalPages: headers['invoice-pages'],
    totalResults: headers['invoice-total'],
    totalAmount: headers['invoice-total-amt'],
    totalCurrency: headers['invoice-total-curr'],
  };
};
