enum MeasurementUnit {
  CM = 'cm',
  IN = 'in',
  M = 'm',
  FT = 'ft',
  KG = 'kg',
  LB = 'lb',
  NONE = '',
}

export default MeasurementUnit;
