import create from 'zustand';

type AppState = {
  locationPriorToLogout: string | null;
};

const useAppStore = create<AppState>(() => ({
  locationPriorToLogout: null,
}));

export default useAppStore;
