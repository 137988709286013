import { t } from '@lingui/macro';
import useRole from '../../hooks/useRole';
import {
  getCaliperOptions,
  getEuCaliperOptions,
  QueryParams,
} from '../../services/Brand';
import { AuthorityRole } from '../../types/Authority.interface';
import Brand from '../../types/Brand.interface';
import EuBrand from '../../types/EuBrand.interface';
import Option from '../../types/Option.type';
import useBrandOptions from './useBrandOptions';

const useCaliperOptions = (
  params: Partial<QueryParams>,
  selectedBrand?: string,
  selectedGrammage?: string
): { caliperOptions: Option[] } => {
  const { hasRole } = useRole();
  const { rawBrands: brands } = useBrandOptions(params);
  const caliperOptions: Option[] = hasRole(AuthorityRole.ROLE_EU)
    ? getEuCaliperOptions(
        brands as EuBrand[],
        selectedBrand ? brands[+selectedBrand].brand : undefined,
        false,
        t`Caliper`
      )
    : getCaliperOptions(brands as Brand[], {
        defaultSelectLabel: t`Caliper`,
        selectedBrand,
        selectedGrammage,
      });
  return {
    caliperOptions,
  };
};

export default useCaliperOptions;
