import { Trans } from '@lingui/macro';
import * as React from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePaginatedQuery, useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import SectionHeader from '../../../components/SectionHeader';
import useRole from '../../../hooks/useRole';
import useSamplesOrder from '../../../hooks/useSamplesOrder';
import useUser from '../../../hooks/useUser';
import { getCountries } from '../../../services/Countries';
import { getLocale } from '../../../services/Properties';
import { updateSamplesOrder } from '../../../services/SamplesOrder';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { SamplesOrderDeliveryAddress } from '../../../types/Samples/SamplesOrderDeliveryAddress.interface';
import DeliveryInformation from './DeliveryInformation';
import ExternalUserCustomerForm from './ExternalUserCustomerForm';
import ExternalUserDeliveryForm from './ExternalUserDeliveryForm';
import SearchCustomerForm from './SearchCustomerForm';
import SearchDeliveryForm from './SearchDeliveryForm';
import {
  getCountryNamePerCode,
  getCustomersWithSamples,
  resetValueForDeliveryForm,
} from './SelectBuyer.util';
import useCustomerDeliveryOptionsStore, {
  NEW_ADDRESS_OPTION_VALUE,
} from './useCustomerDeliveryOptions.store';

const SelectBuyer: React.FunctionComponent = () => {
  const { data: user } = useUser();
  const { isInternalUser } = useRole();
  const history = useHistory();
  const queryCache = useQueryCache();

  const {
    reset: resetStore,
    resetDeliveryOptions,
    resetCustomerOptions,
    searchDeliveryFormValid,
    setSelectedDelivery,
    selectedCustomer,
    setSelectedCustomer,
  } = useCustomerDeliveryOptionsStore();

  type SearchForm = {
    customerName: string;
    customerNumber: string;
    customerCity: string;
    customerPostal: string;
    customerCountry: string;
    deliveryName: string;
    deliveryNumber: string;
    deliveryCity: string;
    deliveryPostal: string;
    deliveryCountry: string;
    deliveryOptions: string;
    newAddressCompanyName: string;
    newAddressAddress1: string;
    newAddressAddress2: string;
    newAddressCity: string;
    newAddressCountryCode: string;
    newAddressPostalCode: string;
    name: string;
    phoneNumber: string;
    emailAddress: string;
    customerLocationAsDelivery: boolean;
  };

  const methods = useForm({
    mode: 'all',
  });
  const { handleSubmit, reset, formState, setValue } = methods;
  const { isDirty, isValid } = formState;
  const { data: order, isFetching: isFetchingOrder } = useSamplesOrder();

  const getCountriesAsync = async () => {
    const locale = await getLocale();
    return getCountries(locale);
  };

  const { resolvedData: countries } = usePaginatedQuery(
    [QueryCacheName.SAMPLES_SELECT_BUYER],
    getCountriesAsync,
    {
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!isFetchingOrder && (!order || order?.id === 0)) {
      /* eslint-disable no-console */
      console.info('No order or order not saved');
      /* eslint-enable no-console */
      history.push('/samples/findProducts');
    }
  }, [order, history, isFetchingOrder]);

  const resetForm = () => {
    if (isInternalUser) {
      reset();
      resetDeliveryOptions();
      resetCustomerOptions();
      setSelectedCustomer(undefined);
      setSelectedDelivery(undefined);
      resetValueForDeliveryForm(setValue);
    } else {
      const customersWithSamples = getCustomersWithSamples(user?.customers);
      setSelectedCustomer(customersWithSamples[0]);
      reset({
        customerSelected: customersWithSamples[0].number,
      });
    }
  };

  const onSubmitReset = () => {
    resetForm();
  };

  window.onbeforeunload = () => {
    resetForm();
  };

  const doSubmit = async (data: SearchForm) => {
    if (order) {
      const deliveryAddress: SamplesOrderDeliveryAddress | null =
        data.deliveryOptions === NEW_ADDRESS_OPTION_VALUE
          ? {
              name: data.newAddressCompanyName,
              address1: data.newAddressAddress1,
              address2: data.newAddressAddress2,
              city: data.newAddressCity,
              country:
                getCountryNamePerCode(
                  countries || [],
                  data.newAddressCountryCode
                ) || '',
              countryCode: data.newAddressCountryCode,
              postalCode: data.newAddressPostalCode,
            }
          : null;

      const shipToNumber = data.customerLocationAsDelivery
        ? selectedCustomer?.customerNumber
        : data.deliveryOptions || data.customerNumber;

      await updateSamplesOrder({
        ...order,
        deliveryAddress,
        buyerNumber:
          selectedCustomer?.customerNumber || selectedCustomer?.number || '',
        deliveryInfo: {
          name: data?.name,
          email: data?.emailAddress,
          phone: data?.phoneNumber,
        },
        shipToNumber:
          data.deliveryOptions === NEW_ADDRESS_OPTION_VALUE
            ? 'NEW'
            : shipToNumber || '',
      });
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDER);
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDERS_HISTORY);
    }

    history.push('/samples/basket');
    resetStore();
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(doSubmit)}>
        <div className="w-full py-4">
          <SectionHeader darkMode>
            <Trans>
              Fill out below information to continue with your sample order
            </Trans>
          </SectionHeader>
        </div>
        {isInternalUser ? (
          <>
            <SearchCustomerForm countries={countries || []} />
            <SearchDeliveryForm countries={countries || []} />
          </>
        ) : (
          <>
            <ExternalUserCustomerForm />
            <ExternalUserDeliveryForm countries={countries || []} />
          </>
        )}

        <DeliveryInformation />
        <div className="flex pt-4 justify-end">
          <Button
            type="reset"
            theme="link"
            className="text-lg font-bold"
            onClick={onSubmitReset}
          >
            <Trans>Reset</Trans>
          </Button>
          <span className="px-2" />
          <Button
            type="submit"
            theme="primary"
            disabled={
              !isDirty ||
              !isValid ||
              (isInternalUser && !searchDeliveryFormValid)
            } // TODO: needs refactor to use isValid only
          >
            <Trans>Next</Trans>
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default SelectBuyer;
