import create from 'zustand';
import { AvailabilityBasketNA } from '../../../types/AvailabilityBasketNA.interface';

type NaBasketState = {
  currentBasketSubmissionCandidate: AvailabilityBasketNA | null;
};

const useNaBasketStore = create<NaBasketState>(() => ({
  currentBasketSubmissionCandidate: null,
}));

export const soldToCustomerNumberSelector = (
  state: NaBasketState
): string | undefined =>
  state.currentBasketSubmissionCandidate?.soldToCustomer.number;

export default useNaBasketStore;
