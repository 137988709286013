import { Menu } from 'antd';
import React, { FC, Key, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useLocationQueryParams from '../../hooks/useLocationQueryParams';
import { getHelpIdAndBody } from './Help.util';
import { Help } from './types/Help.interface';

interface IHelpMenuProps {
  helps: Help[];
  setHelpBody: (body: string) => void;
}

const HelpMenu: FC<IHelpMenuProps> = ({ helps, setHelpBody }) => {
  const { SubMenu } = Menu;

  const history = useHistory();
  const queryParams = useLocationQueryParams();
  const category = queryParams.get('category') || '';
  const page = queryParams.get('page') || '';

  const [openKeys, setOpenKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>();

  useEffect(() => {
    if (helps) {
      const { tid: categoryTid } = getHelpIdAndBody(helps, category);
      const { tid: pageTid, body } = getHelpIdAndBody(helps, category, page);

      setHelpBody(body);
      setOpenKeys([categoryTid]);
      setSelectedKeys([pageTid]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, helps, page]);

  const onOpenChange = (keys: Key[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  return (
    <Menu
      openKeys={openKeys as string[]}
      selectedKeys={selectedKeys}
      onOpenChange={onOpenChange}
      className="text-lg font-bold border-r-0 w-1/4"
      mode="inline"
      style={{ minWidth: '208px' }}
    >
      {helps &&
        helps.map((help) => (
          <SubMenu
            key={help.tid}
            title={help.category}
            onTitleClick={() => {
              history.push(`/help?category=${help.category}`);
              setHelpBody(help.body);
            }}
            className="border-b border-gray-500"
          >
            {help.children &&
              help.children.map((child) => (
                <Menu.Item
                  key={child.tid}
                  className="text-lg whitespace-normal h-auto"
                  style={{ lineHeight: '20px', fontSize: '15px' }}
                  onClick={() => {
                    history.push(
                      `/help?category=${help.category}&page=${child.category}`
                    );
                    setHelpBody(child.body);
                  }}
                >
                  {child.category}
                </Menu.Item>
              ))}
          </SubMenu>
        ))}
    </Menu>
  );
};

export default HelpMenu;
