import { Space } from 'antd';
import * as React from 'react';

interface IBasketPopUpButtonsProps {
  className?: string;
}

const BasketPopUpButtons: React.FunctionComponent<IBasketPopUpButtonsProps> = ({
  className = '',
  children,
}) => {
  return (
    <Space direction="vertical" size="small" className={`w-full ${className}`}>
      {children}
    </Space>
  );
};

export default BasketPopUpButtons;
