import React, { FC, Fragment, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import DeconstructedTableBodyRow from '../../../../components/DeconstructedTable/DeconstructedTableBodyRow';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import AvailabilityBasketType from '../../../../types/AvailabilityBasketType.enum';
import CustomerBasketFormData from './types/CustomerBasketFormData.interface';
import CustomerBasketSummaryColumnType from './types/CustomerBasketSummaryColumnType.type';
import OrderDetailsCellContent from './OrderDetailsCellContent';
import useAnalytics from '../../../../hooks/useAnalytics';

interface CustomerBasketSummaryItemProps {
  basketItem: AvailabilityBasketItem;
  columns: CustomerBasketSummaryColumnType[];
  itemIndex: number;
  formMethods: UseFormMethods<CustomerBasketFormData>;
  onRemoveItem: (item: AvailabilityBasketItem) => void;
}

const CustomerBasketSummaryItem: FC<CustomerBasketSummaryItemProps> = ({
  basketItem,
  columns,
  itemIndex,
  formMethods,
  onRemoveItem,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const isProduct = basketItem.basketType === AvailabilityBasketType.PRODUCT;
  const { trackPageView } = useAnalytics();
  const doToggleItemIsExpanded = (): void => {
    setIsExpanded((currentValue) => !currentValue);
  };

  const doRemoveItem = () => {
    trackPageView('BASKET', 'REMOVE_ITEM', {
      customerId: '',
    });
    onRemoveItem(basketItem);
  };

  const stripeCssClass = itemIndex % 2 !== 0 ? 'stripe' : '';

  return (
    <>
      <DeconstructedTableBodyRow className={stripeCssClass}>
        {columns.map((column) => (
          <DeconstructedTableBodyCell
            key={column.key}
            style={{ width: column.width }}
          >
            {column.render(
              basketItem,
              itemIndex,
              isExpanded,
              doToggleItemIsExpanded,
              doRemoveItem
            )}
          </DeconstructedTableBodyCell>
        ))}
      </DeconstructedTableBodyRow>
      {isProduct && (
        <DeconstructedTableBodyRow
          className={`${stripeCssClass} ${isExpanded ? '' : 'sr-only'}`}
          aria-hidden={!isExpanded}
        >
          <DeconstructedTableBodyCell colSpan={columns.length}>
            <OrderDetailsCellContent
              itemProductType={basketItem.productType}
              itemRequestedUnitOfMeasure={basketItem.requestedUnitOfMeasure}
              formMethods={formMethods}
              itemIndex={itemIndex}
            />
          </DeconstructedTableBodyCell>
        </DeconstructedTableBodyRow>
      )}
    </>
  );
};

export default CustomerBasketSummaryItem;
