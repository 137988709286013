import { find } from 'lodash';
import { Help } from './types/Help.interface';

export const getHelpIdAndBody = (
  helps: Help[],
  category: string,
  page?: string
): { tid: string; body: string } => {
  let help: Help | undefined;

  if (category) {
    help = find(helps, { category });
  }

  if (help && page) {
    help = find(help.children, { category: page });
  }

  return {
    tid: help ? help.tid : helps[0].tid,
    body: help ? help.body : helps[0].body,
  };
};
