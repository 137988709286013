import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useForm, ValidateResult } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import InputWithHiddenLabel from '../../../components/InputWithHiddenLabel/InputWithHiddenLabel';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import SelectWithHiddenLabel from '../../../components/SelectWithHiddenLabel/SelectWithHiddenLabel';
import { QueryParams } from '../../../services/Brand';
import positiveIntegerOnlyInput from '../../../services/Util/positiveIntegerOnlyInput.util';
import stripZeros from '../../../services/Util/stripZeros.util';
import EuBrand from '../../../types/EuBrand.interface';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import { METRIC_NUMBER_REG_EX } from '../../../types/RegularExpression.constants';
import UnitOfMeasure from '../../../types/UnitOfMeasure.enum';
import { calculatePalletHeightAndWeight } from '../Calculator.service';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';
import DynamicField from '../types/PalletHeightAndWeight/DynamicField.enum';
import PalletHeightAndWeightFormData from '../types/PalletHeightAndWeight/PalletHeightAndWeightFormData.type';
import useBrandOptions from '../useBrandOptions';
import useGrammageOptions from '../useGrammageOptions';
import { getParamsFromFormData } from './PalletHeightAndWeight.util';
import useAnalytics from '../../../hooks/useAnalytics';

const EMPTY_PAPER_BRAND_OPTION = {
  label: t`Paper Brand`,
  value: '',
  disabled: true,
};

const EMPTY_GRAMMAGE_OPTION = {
  label: t`Grammage`,
  value: '',
  disabled: true,
};

const EMPTY_DYNAMIC_FIELD_VALUE = '0.00';

const DYNAMIC_FIELD_MAX = 999999;

const DEFAULT_VALUES: PalletHeightAndWeightFormData = {
  paperBrand: EMPTY_PAPER_BRAND_OPTION.value,
  grammage: EMPTY_GRAMMAGE_OPTION.value,
  sheetWidth: '',
  sheetLength: '',
  stacksPerPallet: '',
  [DynamicField.SHEETS_PER_PALLET]: '',
  [DynamicField.HEIGHT_PER_PALLET]: '',
  [DynamicField.WEIGHT_PER_PALLET]: '',
};

const GET_BRANDS_PARAMS: Partial<QueryParams> = {
  productType: PutUpCategory.SHEET,
  context: UnitOfMeasure.PL,
};

const PalletHeightAndWeight: FC = () => {
  const {
    errors,
    formState: { isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    trigger,
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: DEFAULT_VALUES,
  });
  const paperBrandWatch = watch('paperBrand');
  const { trackPageView } = useAnalytics();
  const [selectedBrand, setSelectedBrand] = useState<EuBrand>();
  const [isCalculating, setIsCalculating] = useState<boolean>(false);
  const [disabledDynamicFields, setDisabledDynamicFields] = useState<
    DynamicField[]
  >([]);

  const { rawBrands: brands, brandOptions, isFetchingBrands } = useBrandOptions(
    GET_BRANDS_PARAMS,
    EMPTY_PAPER_BRAND_OPTION.label
  );

  const { grammageOptions } = useGrammageOptions(
    GET_BRANDS_PARAMS,
    paperBrandWatch
  );

  useEffect(() => {
    const newSelectedBrand = ((brands || []) as EuBrand[])?.[+paperBrandWatch];

    setSelectedBrand(newSelectedBrand);
  }, [brands, paperBrandWatch]);

  const doCalculate = async (
    values: PalletHeightAndWeightFormData
  ): Promise<void> => {
    if (selectedBrand) {
      trackPageView('CALCULATORS', 'PALLET_HEIGHT_WEIGHT');
      const params = getParamsFromFormData(values, selectedBrand);

      setIsCalculating(true);

      const results = await calculatePalletHeightAndWeight(params);

      setDisabledDynamicFields([]);
      setValue(
        DynamicField.SHEETS_PER_PALLET,
        stripZeros(results.palletSheets) || EMPTY_DYNAMIC_FIELD_VALUE
      );
      setValue(
        DynamicField.HEIGHT_PER_PALLET,
        stripZeros(results.palletHeight) || EMPTY_DYNAMIC_FIELD_VALUE
      );
      setValue(
        DynamicField.WEIGHT_PER_PALLET,
        stripZeros(results.palletWeight) || EMPTY_DYNAMIC_FIELD_VALUE
      );

      setIsCalculating(false);
    }
  };

  const doReset = () => {
    reset();
    setSelectedBrand(undefined);
    setIsCalculating(false);
    setDisabledDynamicFields([]);
  };

  const updateDynamicFieldStatusesAndValues = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const nameOfChangedField = event.target.name;
    const valueOfChangedField = event.target.value;

    if (!valueOfChangedField) {
      reset({
        ...getValues(),
        [DynamicField.SHEETS_PER_PALLET]:
          DEFAULT_VALUES[DynamicField.SHEETS_PER_PALLET],
        [DynamicField.HEIGHT_PER_PALLET]:
          DEFAULT_VALUES[DynamicField.HEIGHT_PER_PALLET],
        [DynamicField.WEIGHT_PER_PALLET]:
          DEFAULT_VALUES[DynamicField.WEIGHT_PER_PALLET],
      });
      setDisabledDynamicFields([]);
    } else {
      /* eslint-disable default-case */
      switch (nameOfChangedField) {
        case DynamicField.SHEETS_PER_PALLET:
          setValue(
            DynamicField.HEIGHT_PER_PALLET,
            DEFAULT_VALUES[DynamicField.HEIGHT_PER_PALLET]
          );
          setValue(
            DynamicField.WEIGHT_PER_PALLET,
            DEFAULT_VALUES[DynamicField.WEIGHT_PER_PALLET]
          );
          setDisabledDynamicFields([
            DynamicField.HEIGHT_PER_PALLET,
            DynamicField.WEIGHT_PER_PALLET,
          ]);
          break;
        case DynamicField.HEIGHT_PER_PALLET:
          setValue(
            DynamicField.SHEETS_PER_PALLET,
            DEFAULT_VALUES[DynamicField.SHEETS_PER_PALLET]
          );
          setValue(
            DynamicField.WEIGHT_PER_PALLET,
            DEFAULT_VALUES[DynamicField.WEIGHT_PER_PALLET]
          );
          setDisabledDynamicFields([
            DynamicField.SHEETS_PER_PALLET,
            DynamicField.WEIGHT_PER_PALLET,
          ]);
          break;
        case DynamicField.WEIGHT_PER_PALLET:
          setValue(
            DynamicField.SHEETS_PER_PALLET,
            DEFAULT_VALUES[DynamicField.SHEETS_PER_PALLET]
          );
          setValue(
            DynamicField.HEIGHT_PER_PALLET,
            DEFAULT_VALUES[DynamicField.HEIGHT_PER_PALLET]
          );
          setDisabledDynamicFields([
            DynamicField.SHEETS_PER_PALLET,
            DynamicField.HEIGHT_PER_PALLET,
          ]);
          break;
      }
      /* eslint-enable default-case */
    }
  };

  const handleDynamicFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    updateDynamicFieldStatusesAndValues(event);
    trigger(Object.values(DynamicField));
  };

  const requireAtLeastOneDynamicField = (): ValidateResult => {
    const dynamicFieldValues = getValues(Object.values(DynamicField));

    const atLeastOneField = Object.values(dynamicFieldValues).some(
      (fieldValue) => !!fieldValue
    );

    return atLeastOneField;
  };

  return brands ? (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.PALLET_HEIGHT_WEIGHT}
      onCalculate={handleSubmit(doCalculate)}
      onReset={doReset}
      instructions={isFetchingBrands ? undefined : t`Fill out all values`}
      isCalculating={isCalculating}
      isCalculateDisabled={!isValid}
    >
      {isFetchingBrands ? (
        <LoadingSpinner horizontal />
      ) : (
        <Space direction="vertical" size="middle" className="w-full">
          <SelectWithHiddenLabel
            name="paperBrand"
            label={EMPTY_PAPER_BRAND_OPTION.label}
            options={brandOptions}
            ref={register({ required: true })}
            defaultValue={EMPTY_PAPER_BRAND_OPTION.value}
            error={errors.paperBrand}
            required
          />
          <SelectWithHiddenLabel
            name="grammage"
            label={EMPTY_GRAMMAGE_OPTION.label}
            options={grammageOptions}
            ref={register({ required: true })}
            defaultValue={EMPTY_GRAMMAGE_OPTION.value}
            error={errors.grammage}
            required
          />
          <InputWithHiddenLabel
            name="sheetWidth"
            label={t`Width (cm)`}
            ref={register({
              required: true,
              pattern: METRIC_NUMBER_REG_EX,
            })}
            error={errors.sheetWidth}
            required
          />
          <InputWithHiddenLabel
            name="sheetLength"
            label={t`Length (cm)`}
            ref={register({
              required: true,
              pattern: METRIC_NUMBER_REG_EX,
            })}
            error={errors.sheetLength}
            required
          />
          <InputWithHiddenLabel
            name="stacksPerPallet"
            label={t`Stacks Per Pallet`}
            type="number"
            ref={register({ required: true, min: 1, max: 99 })}
            error={errors.stacksPerPallet}
            required
          />
          <div>
            <div>
              <Trans>Fill out at least one of the following</Trans>
            </div>
            <Space
              direction="vertical"
              size="middle"
              className="w-full py-6 px-4 border border-gray-light400"
            >
              <InputLabel text={t`Sheets/Pallet`} size="lg">
                <Input
                  name={DynamicField.SHEETS_PER_PALLET}
                  placeholder={t`Sheets Per Pallet`}
                  type="number"
                  ref={register({
                    min: 1,
                    max: DYNAMIC_FIELD_MAX,
                    validate: {
                      atLeastOneDynamicField: requireAtLeastOneDynamicField,
                    },
                  })}
                  onKeyDown={positiveIntegerOnlyInput}
                  onChange={handleDynamicFieldChange}
                  disabled={disabledDynamicFields.includes(
                    DynamicField.SHEETS_PER_PALLET
                  )}
                  error={
                    errors[DynamicField.SHEETS_PER_PALLET] &&
                    errors[DynamicField.SHEETS_PER_PALLET]?.type !==
                      'atLeastOneDynamicField'
                      ? errors[DynamicField.SHEETS_PER_PALLET]
                      : undefined
                  }
                />
              </InputLabel>
              <InputLabel text={t`Pallet Height (cm)*`} size="lg">
                <Input
                  name={DynamicField.HEIGHT_PER_PALLET}
                  placeholder={t`Pallet Height (cm)`}
                  type="number"
                  ref={register({
                    min: 1,
                    max: DYNAMIC_FIELD_MAX,
                    validate: {
                      atLeastOneDynamicField: requireAtLeastOneDynamicField,
                    },
                  })}
                  onKeyDown={positiveIntegerOnlyInput}
                  onChange={handleDynamicFieldChange}
                  disabled={disabledDynamicFields.includes(
                    DynamicField.HEIGHT_PER_PALLET
                  )}
                  error={
                    errors[DynamicField.HEIGHT_PER_PALLET] &&
                    errors[DynamicField.HEIGHT_PER_PALLET]?.type !==
                      'atLeastOneDynamicField'
                      ? errors[DynamicField.HEIGHT_PER_PALLET]
                      : undefined
                  }
                />
              </InputLabel>
              <InputLabel text={t`Pallet Weight (kg)`} size="lg">
                <Input
                  name={DynamicField.WEIGHT_PER_PALLET}
                  placeholder={t`Pallet Weight (kg)`}
                  type="number"
                  ref={register({
                    min: 1,
                    max: DYNAMIC_FIELD_MAX,
                    validate: {
                      atLeastOneDynamicField: requireAtLeastOneDynamicField,
                    },
                  })}
                  onKeyDown={positiveIntegerOnlyInput}
                  onChange={handleDynamicFieldChange}
                  disabled={disabledDynamicFields.includes(
                    DynamicField.WEIGHT_PER_PALLET
                  )}
                  error={
                    errors[DynamicField.WEIGHT_PER_PALLET] &&
                    errors[DynamicField.WEIGHT_PER_PALLET]?.type !==
                      'atLeastOneDynamicField'
                      ? errors[DynamicField.WEIGHT_PER_PALLET]
                      : undefined
                  }
                />
              </InputLabel>
              <div>
                <Trans>* Including the pallet</Trans>
              </div>
            </Space>
          </div>
        </Space>
      )}
    </CalculatorTemplate>
  ) : (
    <></>
  );
};

export default PalletHeightAndWeight;
