import * as React from 'react';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import DeconstructedTableBodyRow from '../../../../components/DeconstructedTable/DeconstructedTableBodyRow';
import ConsignmentExpandCollapseInfo from '../../types/ConsignmentExpandCollapseInfo.interface';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import { ConsignmentInventoryTableColumns } from '../../types/ConsignmentInventoryTableColumns.interface';
import styles from './ConsignmentInventoryTable.module.scss';
import ConsignmentInventoryTableDetails from './ConsignmentInventoryTableDetails';
import ConsignmentInventoryTableSpacerRow from './ConsignmentInventoryTableSpacerRow';

interface IConsignmentInventoryTableItemProps {
  item: ConsignmentInventoryItem;
  columns: ConsignmentInventoryTableColumns;
  isPoRequired: boolean;
  rowIndex: number;
  detailsExpanded: boolean;
  onExpandDetails: ConsignmentExpandCollapseInfo['onExpandItem'];
  onCollapseDetails: ConsignmentExpandCollapseInfo['onCollapseItem'];
}

const ConsignmentInventoryTableItem: React.FunctionComponent<IConsignmentInventoryTableItemProps> = ({
  item,
  columns,
  isPoRequired,
  rowIndex,
  detailsExpanded,
  onExpandDetails,
  onCollapseDetails,
}) => {
  return (
    <>
      <ConsignmentInventoryTableSpacerRow numCols={columns.length} />
      <DeconstructedTableBodyRow className={styles.contentRowTop}>
        {columns.map((column) => (
          <DeconstructedTableBodyCell key={column.key}>
            {column.render(item)}
          </DeconstructedTableBodyCell>
        ))}
      </DeconstructedTableBodyRow>
      <DeconstructedTableBodyRow className={styles.contentRowBottom}>
        <DeconstructedTableBodyCell colSpan={columns.length}>
          {item.details && item.details.length > 0 && (
            <ConsignmentInventoryTableDetails
              details={item.details}
              isPoRequired={isPoRequired}
              rowIndex={rowIndex}
              itemNumber={item.itemNumber}
              expanded={detailsExpanded}
              onExpand={onExpandDetails}
              onCollapse={onCollapseDetails}
            />
          )}
        </DeconstructedTableBodyCell>
      </DeconstructedTableBodyRow>
      <ConsignmentInventoryTableSpacerRow numCols={columns.length} />
    </>
  );
};

export default ConsignmentInventoryTableItem;
