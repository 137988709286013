import { t } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import Table from '../../../components/Table/Table';
import { SampleRow } from './SampleRow.interface';
import styles from './SampleTable.module.scss';

const SampleTable: FC = () => {
  const data: SampleRow[] = [
    {
      transId: '9999999999',
      delDate: 'YYYYMMDD',
      delTime: 'HHMMSS',
      depDate: 'YYYYMMDD',
      depTime: 'HHMMSS',
      devReasonCode: '00',
    },
  ];
  const columns: ColumnsType<SampleRow> = [
    {
      title: t`TransId`,
      key: 'transId',
      dataIndex: 'transId',
    },
    {
      title: t`DelDate`,
      key: 'delDate',
      dataIndex: 'delDate',
    },
    {
      title: t`DelTime`,
      key: 'delTime',
      dataIndex: 'delTime',
    },
    {
      title: t`DepDate`,
      key: 'depDate',
      dataIndex: 'depDate',
    },
    {
      title: t`DepTime`,
      key: 'depTime',
      dataIndex: 'depTime',
    },
    {
      title: t`DevReasonCode`,
      key: 'devReasonCode',
      dataIndex: 'devReasonCode',
    },
  ];
  return (
    <Table
      rowKey={(record) => JSON.stringify(record)}
      className={styles.table}
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
};

export default SampleTable;
