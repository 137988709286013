import { Trans } from '@lingui/macro';
import React from 'react';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import MeasurementSystem from '../../../../types/MeasurementSystem.enum';
import MeasurementUnit from '../../../../types/MeasurementUnit.enum';
import { Order } from '../../../../types/Order.interface';
import '../OrderDetailsTable.scss';

interface IQuantityCellProps {
  record: Order;
}

const QuantityCell: React.FunctionComponent<IQuantityCellProps> = ({
  record,
}) => {
  const { formatWithUnit, format } = useNumberFormatter();
  const { measurementSystem } = useMeasurementSystem();

  return (
    <>
      {record.quantity && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderDetails.orderQuantity">Order Quantity</Trans>
          </dt>
          <dd className="font-bold">{formatWithUnit(record.quantity)}</dd>
        </dl>
      )}
      <dl>
        <dt className="block text-gray-100 bg-gray-400 py-3 px-4 -mx-3 my-3">
          Quantity Produced:
        </dt>
        <dd>
          {record.quantityProducedNetString && (
            <dl className="mb-2.5 flex">
              <dt className="pr-2 label-colon">
                <Trans id="orderDetails.netLabel">Net</Trans>
              </dt>
              <dd className="font-bold uppercase">
                {formatWithUnit(record.quantityProducedNetString)}
              </dd>
            </dl>
          )}
          {record.quantityProducedGrossString && (
            <dl className="mb-2.5 flex">
              <dt className="pr-2 label-colon">
                <Trans id="orderDetails.grossLabel">Gross</Trans>
              </dt>
              <dd className="font-bold uppercase">
                {formatWithUnit(record.quantityProducedGrossString)}
              </dd>
            </dl>
          )}
          {record.quantityProducedSheetCountString && (
            <dl className="mb-2.5 flex">
              <dt className="pr-2 label-colon">
                <Trans>Sheet Count</Trans>
              </dt>
              <dd className="font-bold uppercase">
                {formatWithUnit(record.quantityProducedSheetCountString)}
              </dd>
            </dl>
          )}
          {measurementSystem === MeasurementSystem.IMPERIAL &&
            record.quantityProducedGrossMSF !== null && (
              <dl className="mb-2.5 flex">
                <dt className="pr-2 label-colon">
                  <Trans>MSF</Trans>
                </dt>
                <dd className="font-bold uppercase">
                  {format(record.quantityProducedGrossMSF)} {MeasurementUnit.FT}
                  <sup>2</sup>
                </dd>
              </dl>
            )}
          {measurementSystem === MeasurementSystem.METRIC &&
            record.quantityProducedGrossMSM !== null && (
              <dl className="mb-2.5 flex">
                <dt className="pr-2 label-colon">
                  <Trans>MSM</Trans>
                </dt>
                <dd className="font-bold uppercase">
                  {format(record.quantityProducedGrossMSM)} {MeasurementUnit.M}
                  <sup>2</sup>
                </dd>
              </dl>
            )}
          {measurementSystem === MeasurementSystem.IMPERIAL &&
            record.quantityProducedGrossLF !== null && (
              <dl className="mb-2.5 flex">
                <dt className="pr-2 label-colon">
                  <Trans>Lineal Feet</Trans>
                </dt>
                <dd className="font-bold uppercase">{`${format(
                  record.quantityProducedGrossLF
                )} ${MeasurementUnit.FT}`}</dd>
              </dl>
            )}
          {measurementSystem === MeasurementSystem.METRIC &&
            record.quantityProducedGrossLM !== null && (
              <dl className="mb-2.5 flex">
                <dt className="pr-2 label-colon">
                  <Trans>Lineal Metres</Trans>
                </dt>
                <dd className="font-bold uppercase">{`${format(
                  record.quantityProducedGrossLM
                )} ${MeasurementUnit.M}`}</dd>
              </dl>
            )}
            {record.quantityProducedPL && (
              <dl className="mb-2.5 flex">
                <dt className="pr-2 label-colon">
                  <Trans>Number of Pallets/Rolls</Trans>
                </dt>
                <dd className="font-bold uppercase">
                  {record.quantityProducedPL}
                </dd>
              </dl>
            )}
        </dd>
      </dl>
    </>
  );
};

export default QuantityCell;
