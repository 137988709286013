import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { find, isEmpty, orderBy, upperCase } from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import { Button } from '../../../components/Button/Button';
import ControlledDatePicker from '../../../components/DatePicker/ControlledDatePicker';
import {
  DEFAULT_EU_FORMAT,
  PARAM_FORMAT,
} from '../../../components/DatePicker/DatePicker';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import LabelValue from '../../../components/LabelValue/LabelValue';
import Select from '../../../components/Select/Select';
import SimpleCard from '../../../components/SimpleCard/SimpleCard';
import useAnalytics from '../../../hooks/useAnalytics';
import useLanguage from '../../../hooks/useLanguage';
import useUser from '../../../hooks/useUser';
import {
  getEUBrands,
  getEuOrderBookingBrandOptions,
  getOrderBookingGrammageOptions,
} from '../../../services/Brand';
import {
  deriveCustomerOptions,
  deriveShipToCustomerOptions,
} from '../../../services/Customer';
import fractionOrDecimalInput from '../../../services/Util/fractionOrDecimalInput.util';
import positiveIntegerOnlyInput from '../../../services/Util/positiveIntegerOnlyInput.util';
import { AvailabilityCustomer } from '../../../types/AvailabilityCustomer.interface';
import Option from '../../../types/Option.type';
import { OrderBookingProductQuery } from '../../../types/OrderBookingProductQuery.interface';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import UnitOfMeasure from '../../../types/UnitOfMeasure.enum';
import useOrderBookingStore from '../useOrderBooking.store';
import {
  CORE_DIAMETER_OPTIONS,
  PRODUCT_TYPE_OPTIONS,
  UNIT_OF_MEASURE_OPTIONS_ROLLS,
  UNIT_OF_MEASURE_OPTIONS_SHEETS,
} from './options.const';
import OtherOptions from './OtherOptions';
import { determineProductType, determineGrainDirection } from './SearchForm.util';

interface SearchFormData {
  soldToParty: string;
  productType: string;
  gradeBrandMember: string;
  grammage?: string;
  width?: string;
  length?: string;
  reelWidth?: string;
  reelDiameter?: string;
  coreDiameter?: string;
  sheetsPerReam?: string;
  sheetsPerPallet?: string;
  heightPerPallet?: string;
  weightPerPallet?: string;
  wrappingType?: string;
  packageType?: string;
  paperType?: string;
  requestedUnit: string;
  requestedQuantity: string;
  requestedDate: Moment;
  shipToParty: string;
}
interface ISearchFormProps {
  className?: string;
  onSearch: (data: OrderBookingProductQuery | undefined) => void;
  onReset: () => void;
  formMethods: UseFormMethods;
}

const SearchForm: React.FunctionComponent<ISearchFormProps> = ({
  className,
  onSearch,
  onReset,
  formMethods,
}) => {
  const {
    register,
    control,
    getValues,
    watch,
    errors,
    formState,
    reset,
    handleSubmit,
    setValue,
  } = formMethods;
  const { selectedCustomer, storedQuery } = useOrderBookingStore();
  const { isValid } = formState;
  const width = watch('width');
  const length = watch('length');
  const productType = watch('productType');
  const requestedUnit = watch('requestedUnit');
  const { data: user, isFetching: isFetchingUser } = useUser();
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [shipToOptions, setShipToOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [grammageOptions, setGrammageOptions] = useState<Option[]>([]);
  const [hasSetInitialValues, setHasSetInitialValues] = useState(false);

  const TODAY = moment();
  const { trackPageView } = useAnalytics();
  const lang = useLanguage();

  const {
    resolvedData: brands,
    isFetching: isFetchingBrands,
  } = usePaginatedQuery(
    [QueryCacheName.EU_BRANDS, selectedCustomer],
    getEUBrands,
    {
      enabled: user?.euDirectCustomers && selectedCustomer,
      refetchOnWindowFocus: false,
    }
  );

  const setSelectedCustomer = useCallback(
    (customerNumber: string) => {
      useOrderBookingStore.setState({
        selectedCustomer: find(user?.euDirectCustomers, {
          customerNumber,
        }) as AvailabilityCustomer,
      });
    },
    [user]
  );

  useEffect(() => {
    if (user?.euDirectCustomers) {
      const orderedCustomers = orderBy(
        deriveCustomerOptions(user.euDirectCustomers, true, true, true, false),
        ['label']
      );
      setCustomerOptions(orderedCustomers);
      reset(); // to allow async default values to be set
    }
  }, [user, reset, setSelectedCustomer, setValue]);

  // Set Stored Customer/ship to on initial load if available
  useEffect(() => {
    if (customerOptions.length) {
      const defaultCustomer =
        storedQuery?.soldToParty || (customerOptions[0].value as string);
      setValue('soldToParty', defaultCustomer, { shouldValidate: true });

      setSelectedCustomer(defaultCustomer);
    }
  }, [customerOptions, setValue, storedQuery, setSelectedCustomer]);

  const updateGrammageOptions = useCallback(
    (selected?: { productType?: PutUpCategory; product?: string }) => {
      if (brands) {
        const productTypeUsed =
          selected?.productType || getValues('productType');
        const product = selected?.product || getValues('gradeBrandMember');
        setGrammageOptions(
          getOrderBookingGrammageOptions(brands, productTypeUsed, product)
        );
      }
    },
    [brands, getValues]
  );

  const updateProductOptions = useCallback(
    (selected?: { productType?: PutUpCategory }) => {
      if (brands) {
        const productTypeUsed =
          selected?.productType || getValues('productType');
        setProductOptions(
          getEuOrderBookingBrandOptions(brands, productTypeUsed)
        );
      }
    },
    [brands, getValues]
  );

  const setInitialValues = useCallback(() => {
    if (storedQuery) {
      setTimeout(() => {
        reset({
          ...getValues(),
          gradeBrandMember: storedQuery?.characteristics?.gradeBrandMember,
          grammage: storedQuery?.characteristics?.grammage,
          width: storedQuery?.characteristics?.width,
          length: storedQuery?.characteristics?.length,
          reelWidth: storedQuery?.characteristics?.reelWidth,
          reelDiameter: storedQuery?.characteristics?.reelDiameter,
          coreDiameter: storedQuery?.characteristics?.coreDiameter,
          sheetsPerReam: storedQuery?.characteristics?.sheetsPerReam,
          sheetsPerPallet: storedQuery?.characteristics?.sheetsPerPallet,
          heightPerPallet: storedQuery?.characteristics?.heightPerPallet,
          weightPerPallet: storedQuery?.characteristics?.weightPerPallet,
          wrappingType: storedQuery?.characteristics?.wrappingType,
          packageType: storedQuery?.characteristics?.packageType,
          paperType: storedQuery?.paperType,
          requestedUnit: storedQuery?.requestedUnit || UnitOfMeasure.SH,
          requestedQuantity: storedQuery?.requestedQuantity,
          requestedDate: storedQuery?.requestedDate
            ? moment(storedQuery?.requestedDate)
            : null,
          shipToParty: storedQuery?.shipToParty || shipToOptions[0].value,
        });
      }, 100);
    } else {
      setValue('requestedUnit', UnitOfMeasure.SH, { shouldValidate: true });
    }
    setHasSetInitialValues(true);
  }, [
    storedQuery,
    setHasSetInitialValues,
    setValue,
    getValues,
    shipToOptions,
    reset,
  ]);

  useEffect(() => {
    if (brands && !isFetchingBrands && !isEmpty(getValues('soldToParty'))) {
      updateProductOptions();
      updateGrammageOptions();

      if (!hasSetInitialValues) {
        setInitialValues();
      }
    }
  }, [
    brands,
    updateGrammageOptions,
    updateProductOptions,
    isFetchingBrands,
    setInitialValues,
    hasSetInitialValues,
    getValues,
  ]);

  useEffect(() => {
    if (selectedCustomer) {
      const options = deriveShipToCustomerOptions(selectedCustomer.shipTos);
      setShipToOptions(options);
      setValue('shipToParty', options[0].value);
    }
  }, [selectedCustomer, setValue]);

  const onSubmit = handleSubmit((data: SearchFormData) => {
    trackPageView('ORDER_BOOKING', 'SEARCH', { customerId: data.soldToParty });

    if (selectedCustomer) {
      onSearch({
        characteristics: {
          productType: determineProductType(brands || [], {
            productType: data.productType as PutUpCategory,
            gradeBrandMember: data.gradeBrandMember,
            grammage: data.grammage || '',
          }),
          gradeBrandMember: data.gradeBrandMember,
          grammage: data.grammage,
          width: data.width,
          length: data.length,
          reelWidth: data.reelWidth,
          reelDiameter: data.reelDiameter,
          coreDiameter: data.coreDiameter,
          sheetsPerReam: data.sheetsPerReam,
          sheetsPerPallet: data.sheetsPerPallet,
          heightPerPallet: data.heightPerPallet,
          weightPerPallet: data.weightPerPallet,
          wrappingType: data.wrappingType,
          packageType: data.packageType,
        },
        paperType: data.paperType,
        distributionChannel: selectedCustomer.distributionChannel,
        division: selectedCustomer.division || '',
        language: upperCase(lang.substr(0, 2)),
        profile: selectedCustomer.profile || '',
        requestedDate: data.requestedDate.format(PARAM_FORMAT),
        requestedQuantity: data.requestedQuantity,
        requestedUnit: data.requestedUnit,
        salesOffice: selectedCustomer.salesOffice || '',
        salesOrg: selectedCustomer.salesOrg,
        shipToParty: data.shipToParty,
        skuCodeSearch: 'false', // TODO: do we still need to support this?
        soldToParty: selectedCustomer.customerNumber,
      });
    }
  });

  return (
    <form
      onSubmit={onSubmit}
      style={{ minWidth: '300px', maxWidth: '350px' }}
      className={className}
    >
      <SimpleCard>
        <Space size="large" direction="vertical" className="w-full">
          <InputLabel
            text={t`Customer`}
            size="lg"
            required
            isFetching={isFetchingUser}
          >
            <Select
              name="soldToParty"
              ref={register()}
              options={customerOptions}
              onChange={(e) => {
                setSelectedCustomer(e.target.value);
                reset({
                  ...getValues(),
                  grammage: undefined,
                  gradeBrandMember: undefined,
                });
              }}
              defaultValue={customerOptions[0]?.value}
              required
              width="full"
              disabled={isFetchingUser}
            />
          </InputLabel>
          <InputLabel text={t`Product Type`} size="lg" required>
            <Select
              name="productType"
              ref={register()}
              error={errors?.productType}
              options={PRODUCT_TYPE_OPTIONS}
              required
              width="full"
              defaultValue={
                storedQuery?.characteristics?.productType ===
                  PutUpCategory.CUTTER ||
                storedQuery?.characteristics?.productType === PutUpCategory.ROLL
                  ? PutUpCategory.ROLL
                  : PutUpCategory.SHEET
              }
              onChange={(e) => {
                updateProductOptions({
                  productType: e.target.value as PutUpCategory,
                });
                updateGrammageOptions({
                  productType: e.target.value as PutUpCategory,
                });
                reset({
                  soldToParty: getValues('soldToParty'),
                  requestedDate: getValues('requestedDate'),
                  shipToParty:
                    getValues('shipToParty') || shipToOptions[0].value,
                  productType: e.target.value,
                  requestedUnit:
                    e.target.value === PutUpCategory.SHEET
                      ? UnitOfMeasure.SH
                      : UnitOfMeasure.KG,
                });
              }}
            />
          </InputLabel>
          <InputLabel
            text={t`Product`}
            size="lg"
            required
            isFetching={isFetchingBrands}
          >
            <Select
              name="gradeBrandMember"
              ref={register({ required: true })}
              error={errors?.gradeBrandMember}
              options={productOptions}
              required
              width="full"
              disabled={isFetchingBrands}
              onChange={(e) =>
                updateGrammageOptions({ product: e.target.value })
              }
            />
          </InputLabel>
          <InputLabel
            text={t`Grammage`}
            size="lg"
            required
            isFetching={isFetchingBrands}
          >
            <Select
              name="grammage"
              ref={register({ required: true })}
              error={errors?.grammage}
              options={grammageOptions}
              required
              width="full"
              disabled={isFetchingBrands}
            />
          </InputLabel>
          {productType === PutUpCategory.SHEET && (
            <>
              <div className="grid grid-cols-2 gap-4">
                <InputLabel text={t`Width`} size="lg" required>
                  <Input
                    name="width"
                    ref={register({ required: true })}
                    error={errors?.width}
                    required
                    onKeyPress={fractionOrDecimalInput}
                    width="full"
                  />
                </InputLabel>
                <InputLabel text={t`Length`} size="lg" required>
                  <Input
                    name="length"
                    ref={register({ required: true })}
                    error={errors?.length}
                    required
                    onKeyPress={fractionOrDecimalInput}
                    width="full"
                  />
                </InputLabel>
              </div>
              {!!width && !!length && (
                <LabelValue
                  inline
                  label={t`Grain Direction`}
                  value={determineGrainDirection(width, length)}
                />
              )}
            </>
          )}
          {productType === PutUpCategory.ROLL && (
            <>
              <InputLabel text={t`Roll Width (cm)`} size="lg" required>
                <Input
                  name="reelWidth"
                  ref={register({ required: true })}
                  onKeyPress={fractionOrDecimalInput}
                  error={errors?.reelWidth}
                  required
                  width="full"
                />
              </InputLabel>
              <InputLabel text={t`Roll Diameter`} size="lg" required>
                <Input
                  name="reelDiameter"
                  ref={register({ required: true })}
                  onKeyDown={positiveIntegerOnlyInput}
                  error={errors?.reelDiameter}
                  required
                  width="full"
                />
              </InputLabel>
              <InputLabel text={t`Core Diameter`} size="lg" required>
                <Select
                  name="coreDiameter"
                  ref={register({ required: true })}
                  error={errors?.coreDiameter}
                  options={CORE_DIAMETER_OPTIONS}
                  required
                  width="full"
                />
              </InputLabel>
            </>
          )}
          <InputLabel text={t`Unit of Measure`} size="lg" required>
            <Select
              name="requestedUnit"
              ref={register({ required: true })}
              error={errors?.requestedUnit}
              options={
                productType === PutUpCategory.SHEET
                  ? UNIT_OF_MEASURE_OPTIONS_SHEETS
                  : UNIT_OF_MEASURE_OPTIONS_ROLLS
              }
              required
              width="full"
              onChange={() => {
                setValue('requestedQuantity', '', {
                  shouldValidate: formState.dirtyFields?.requestedQuantity,
                });
              }}
            />
          </InputLabel>
          <InputLabel text={t`Quantity`} size="lg" required>
            <Input
              name="requestedQuantity"
              ref={register({ required: true })}
              onKeyDown={positiveIntegerOnlyInput}
              error={errors?.requestedQuantity}
              required
              width="full"
            />
          </InputLabel>
          {productType === PutUpCategory.SHEET &&
            requestedUnit === UnitOfMeasure.PL && (
              <InputLabel text={t`SH/Pallet`} size="lg" required>
                <Input
                  name="sheetsPerPallet"
                  ref={register({ required: true })}
                  onKeyPress={fractionOrDecimalInput}
                  error={errors?.sheetsPerPallet}
                  required
                  width="full"
                />
              </InputLabel>
            )}
          <InputLabel text={t`Delivery Date`} size="lg" required>
            <ControlledDatePicker
              control={control}
              name="requestedDate"
              required
              className="w-full"
              rules={{
                required: true,
                validate: {
                  isDateAfterToday: (date: Moment) => date.isAfter(),
                },
              }}
              hasError={!!errors?.requestedDate}
              toolTipErrorMsg={
                errors?.requestedDate?.type === 'isDateAfterToday' ? (
                  <>
                    <Trans>Date must be after</Trans>{' '}
                    {TODAY.format(DEFAULT_EU_FORMAT)}
                  </>
                ) : undefined
              }
            />
          </InputLabel>
          <InputLabel text={t`Delivery Location`} size="lg" required>
            <Select
              name="shipToParty"
              ref={register()}
              error={errors?.shipToParty}
              options={shipToOptions}
              required
              width="full"
            />
          </InputLabel>
          {productType === PutUpCategory.SHEET && (
            <OtherOptions register={register} />
          )}
          <Space size="large">
            <Button type="submit" theme="primary" disabled={!isValid}>
              <Trans>Show Availability</Trans>
            </Button>
            <Button
              className="text-lg font-bold"
              theme="link"
              onClick={() => onReset()}
            >
              <Trans>Reset</Trans>
            </Button>
          </Space>
        </Space>
      </SimpleCard>
    </form>
  );
};

export default SearchForm;
