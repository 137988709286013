import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import AvailabilityBasketEU from '../../../../types/AvailabilityBasketEU.interface';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import TrashButton from '../TrashButton';
import RequestedDateComponent from './RequestedDateComponent';

interface IDeliveryDateCellProps {
  basket: AvailabilityBasketEU;
  item: AvailabilityBasketItem;
  index: number;
  formMethods: UseFormMethods;
  doRemoveEuBasketItem: (itemId: number) => void;
}

const DeliveryDateCell: FC<IDeliveryDateCellProps> = ({
  basket,
  item,
  index,
  formMethods,
  doRemoveEuBasketItem,
}) => {
  return (
    <div className="flex">
      <div className="w-4/5" style={{ minWidth: '133px' }}>
        <RequestedDateComponent
          basket={basket}
          item={item}
          formMethods={formMethods}
          index={index}
        />
      </div>
      <div className="w-1/5">
        <TrashButton
          onClick={() => {
            if (item.id) {
              doRemoveEuBasketItem(item.id);
            }
          }}
        />
      </div>
    </div>
  );
};

export default DeliveryDateCell;
