import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { t } from '@lingui/macro';
import { Space } from 'antd';
import Input from '../../../components/Input/Input';
import {
  isCwtToLinFoot,
  isCwtToMsf,
  isCwtToThousandSht,
  isThousandShtToCwt,
} from './PriceCWT.util';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';

const MIN_REQUIRED = 0.1;
const MAX_REQUIRED = 9999.9;
interface IMetricPriceCWTProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  conversionType: string;
}

const MetricPriceCWT: React.FunctionComponent<IMetricPriceCWTProps> = ({
  register,
  errors,
  conversionType,
}) => {
  const showGrammageInput =
    isCwtToThousandSht(conversionType) ||
    isCwtToLinFoot(conversionType) ||
    isCwtToMsf(conversionType) ||
    isThousandShtToCwt(conversionType);

  const showWidthOrLengthInput =
    isCwtToThousandSht(conversionType) || isThousandShtToCwt(conversionType);

  return (
    <>
      {(showGrammageInput || showWidthOrLengthInput) && (
        <Space direction="vertical" size="middle" className="w-full">
          {showGrammageInput && (
            <Input
              required
              name="grammage"
              type="number"
              placeholder={t`Grammage`}
              ref={register({
                required: showGrammageInput,
                min: MIN_REQUIRED,
                max: MAX_REQUIRED,
                validate: (value) => validateNumberOfDecimalPlaces(value, 1),
              })}
              error={errors?.grammage}
            />
          )}
          {showWidthOrLengthInput && (
            <Input
              required
              name="sheetWidth"
              type="number"
              placeholder={t`Width (cm)`}
              ref={register({
                required: showWidthOrLengthInput,
                min: MIN_REQUIRED,
                max: MAX_REQUIRED,
                validate: (value) => validateNumberOfDecimalPlaces(value, 1),
              })}
              error={errors?.sheetWidth}
            />
          )}
          {showWidthOrLengthInput && (
            <Input
              required
              name="sheetLength"
              type="number"
              placeholder={t`Length (cm)`}
              ref={register({
                required: showWidthOrLengthInput,
                min: MIN_REQUIRED,
                max: MAX_REQUIRED,
                validate: (value) => validateNumberOfDecimalPlaces(value, 1),
              })}
              error={errors?.sheetLength}
            />
          )}
        </Space>
      )}
    </>
  );
};

export default MetricPriceCWT;
