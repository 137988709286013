import useBasket from './useBasket';

const useNaBasketNumItems = (): { numBasketItems: number } => {
  const { data: allBaskets } = useBasket();

  const numBasketItems =
    allBaskets
      ?.map((basket) => basket.items.length)
      .reduce((prev, next) => prev + next, 0) || 0;
  return {
    numBasketItems,
  };
};

export default useNaBasketNumItems;
