import { t, Trans } from '@lingui/macro';
import { concat } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import useAnalytics from '../../hooks/useAnalytics';
import useRole from '../../hooks/useRole';
import useUser from '../../hooks/useUser';
import {
  deriveCustomerOptions,
  isValidCustomerNumber,
} from '../../services/Customer';
import { AuthorityRole } from '../../types/Authority.interface';
import Option from '../../types/Option.type';

interface ISelectCustomerProps {
  setSelectedCustomerNum(customerNumber: string): void;
  defaultCustomerNumber?: string;
}

interface SelectCustomerInputs {
  customerNumber: string;
}

const SelectCustomer: React.FunctionComponent<ISelectCustomerProps> = ({
  setSelectedCustomerNum,
  defaultCustomerNumber,
}) => {
  const { trackPageView } = useAnalytics();
  const { register, handleSubmit, formState, setValue } = useForm({
    mode: 'onChange',
  });
  const { isDirty, isValid } = formState;
  const { hasRole, isNonReleaseEuUser } = useRole();
  const { data: user } = useUser();

  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);

  const onSubmit = (data: SelectCustomerInputs) => {
    trackPageView('REPORTING', 'COMPLAINT_SEARCH', {
      customerId: data.customerNumber,
    });
    setSelectedCustomerNum(data.customerNumber);
  };

  useEffect(() => {
    if (user) {
      const { customers } = user;
      const derivedCustomerOptions = deriveCustomerOptions(
        customers || [],
        isNonReleaseEuUser,
        hasRole(AuthorityRole.ROLE_EU),
        true
      );
      setCustomerOptions(
        concat(
          [{ label: t`Select Customer`, value: '' }],
          derivedCustomerOptions
        )
      );
    }
  }, [user, isNonReleaseEuUser, hasRole]);

  useEffect(() => {
    if (defaultCustomerNumber) {
      setValue('customerNumber', defaultCustomerNumber, {
        shouldValidate: true,
      });
    }
  }, [customerOptions, defaultCustomerNumber, setValue]);

  return (
    <div className="border-r border-b border-l border-gray-light400">
      <form
        className="py-10 px-6 border-t-4 border-blue-cobaltVariation"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex">
          <div className="mr-4" style={{ width: '360px' }}>
            <label
              className="block text-xl font-extrabold text-gray-dark200 pb-3"
              htmlFor="customerSelect"
            >
              <span className="text-red-red pr-0.5">*</span>
              <Trans>Select Customer</Trans>
            </label>
            <Select
              id="customerSelect"
              name="customerNumber"
              options={customerOptions}
              ref={register({
                required: true,
                validate: isValidCustomerNumber,
              })}
              width="full"
              required
            />
          </div>
          <Button
            className="self-end py-3 mt-7"
            type="submit"
            theme="primary"
            disabled={!(isDirty && isValid)}
          >
            <Trans>Search</Trans>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SelectCustomer;
