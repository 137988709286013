import create from 'zustand';

type ExcelNotificationState = {
  exportInProcess: boolean;
};

const useExcelNotificationStore = create<ExcelNotificationState>(() => ({
  exportInProcess: false,
}));

export default useExcelNotificationStore;
