import { t } from '@lingui/macro';
import React, { FC } from 'react';
// eslint-disable-next-line import/no-named-as-default
import useBlockRoute from '../../hooks/useBlockRoute';
import { getComplaintRefs } from './ClaimRouteListener.util';

const ClaimRouteListener: FC = () => {
  useBlockRoute(async (location) => {
    if (location.pathname.startsWith('/claim/file')) {
      const complaintRefs = await getComplaintRefs(location);
      if (complaintRefs.length > 0) {
        if (
          // eslint-disable-next-line no-alert
          window.confirm(
            `${t`A Complaint Exists for this Order Item. Click OK to continue.`}\n\n${complaintRefs.toString()}`
          )
        ) {
          return true;
        }
        return false;
      }
    }
    return true;
  });
  return <></>;
};

export default ClaimRouteListener;
