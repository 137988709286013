import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import SimpleCard from '../../../components/SimpleCard/SimpleCard';
import useLocationQueryParams from '../../../hooks/useLocationQueryParams';
import useNaBasketCustomerOptions from './hooks/useNaBasketCustomerOptions';
import { ALL_CUSTOMERS } from './NaBasket.util';

interface FormData {
  customer: string;
}

interface CustomerSelectProps {
  setSelectedCustomerNum: (customerNum: string) => void;
}

const CustomerSelect: FC<CustomerSelectProps> = ({
  setSelectedCustomerNum,
}) => {
  const urlParams = useLocationQueryParams();
  const customerDefaultValue = useMemo(
    () => urlParams.get('customer') || ALL_CUSTOMERS,
    [urlParams]
  );

  const { handleSubmit, register, reset } = useForm<FormData>({
    mode: 'all',
    defaultValues: { customer: customerDefaultValue },
  });

  const {
    isFetching,
    naBasketCustomerOptions: options,
  } = useNaBasketCustomerOptions();

  // Anytime the URL query params change (expected to only be on first page load
  // under normal usage), set the current selected customer based on the
  // customer from the URL query params.
  useEffect(() => {
    setSelectedCustomerNum(customerDefaultValue);
  }, [customerDefaultValue, setSelectedCustomerNum]);

  // Anytime the options change (expected to only be when the options finish
  // loading under normal usage), cause the default value to be selected in the
  // customer dropdown by resetting the form.
  useEffect(() => {
    reset();
  }, [options, reset]);

  const doSubmit = (values: FormData): void => {
    setSelectedCustomerNum(values.customer);
  };

  return (
    <SimpleCard>
      <form onSubmit={handleSubmit(doSubmit)}>
        <Space className="w-full" align="end">
          <InputLabel
            text={t`Customer`}
            size="lg"
            required
            className="w-96"
            isFetching={isFetching}
          >
            <Select
              name="customer"
              ref={register()}
              options={options}
              disabled={isFetching}
              required
            />
          </InputLabel>
          <Button theme="primary" disabled={isFetching} type="submit">
            <Trans>Select Customer</Trans>
          </Button>
        </Space>
      </form>
    </SimpleCard>
  );
};

export default CustomerSelect;
