import { Trans } from '@lingui/macro';
import { noop } from 'lodash';
import * as React from 'react';
import { Button } from '../Button/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import useUser from '../../hooks/useUser';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';
import Region from '../../types/Region.enum';
import styles from './BasketPopUp.module.scss';
import BasketPopupItem, { IBasketPopupItemProps } from './BasketPopUpItem';

interface IBasketPopup extends React.FunctionComponent<IBasketPupUpProps> {
  Item: React.FunctionComponent<IBasketPopupItemProps>;
}

interface IBasketPupUpProps {
  title: string;
  onClose: () => void;
  onSecondaryAction: () => void;
  onPrimaryAction: () => void;
  primaryActionLoading?: boolean;
  primaryActionDisabled?: boolean;
}

const BasketPopUp: IBasketPopup = ({
  title,
  children,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
  primaryActionLoading,
  primaryActionDisabled,
}) => {
  const { data: user } = useUser();
  const { hasRole } = useRole();
  const isEUUser = user?.region === Region.EU;
  
  return (
    <div className={`${styles.basketPopUp}`}>
      {/* HEADER */}
      <div className="text-white-white px-4 py-3 text-lg justify-between items-center flex bg-blue-pacific">
        <strong>{title}</strong>
        <div
          className="fa fa-times"
          onClick={onClose}
          onKeyDown={noop}
          role="button"
          aria-label="Close Basket"
          tabIndex={0}
        />
      </div>
      <div className="relative">
        <div className={styles.basketPopUpItemContainer}>{children}</div>
        <div className={styles.basketPopUpFuzz} />
      </div>
      {/* FOOTER */}
      <div className="p-4 flex justify-around border-t border-gray-light100">
        {!isEUUser && (
          <Button theme="inverse" onClick={onSecondaryAction}>
            <Trans>Review & Checkout</Trans>
          </Button>
        )}
        {isEUUser && hasRole(AuthorityRole.ROLE_RELEASE) && (
          <Button theme="inverse" onClick={onSecondaryAction}>
            <Trans>Review & Checkout</Trans>
          </Button>
        )}
        <Button
          theme="primary"
          onClick={onPrimaryAction}
          disabled={primaryActionDisabled}
        >
          <div className="flex items-center">
            <Trans>Place Order Now</Trans>
            {primaryActionLoading ? (
              <div className="ml-2">
                <LoadingSpinner />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Button>
      </div>
    </div>
  );
};

BasketPopUp.Item = BasketPopupItem;
export default BasketPopUp;
