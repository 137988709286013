import * as React from 'react';
import { Trans } from '@lingui/macro';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DummyButtons from '../DummyButtons';
import useDummyOrderStore from '../useDummyOrder.store';
import CoverPaper from './CoverPaper';
import CoverFormat from './CoverFormat';
import CoverOptionType from './CoverOptionType.enum';
import { getCoverOptionType, getInputValue } from './CoverOptions.util';
import Card from '../../../../components/Card/Card';
import DummySamplesUserGuide from '../DummySamplesUserGuide';

const CoverOptions: React.FunctionComponent = () => {
  const state = useDummyOrderStore();
  const history = useHistory();

  const {
    register,
    setValue,
    getValues,
    formState,
    errors,
    clearErrors,
    reset,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: {
      brand: state.coverBrand,
      brandFamily: state.coverBrandFamily,
      grammage: state.coverGrammage,
      paperDifferentFromText: state.coverPaperDifferentFromText,
      coverOptionType: getCoverOptionType({
        isFourPageCoverStandard: state.fourPageCoverStandard || false,
        isSixPageCoverGatefoldFront: state.sixPageCoverGatefoldFront || false,
        isSixPageCoverGatefoldRear: state.sixPageCoverGatefoldRear || false,
        isEightPageCoverGatefold: state.eightPageCoverGatefold || false,
      }),
      gatefoldFront: state.sixPageCoverGatefoldFront ? state.gatefoldFront : '',
      gatefoldRear: state.sixPageCoverGatefoldRear ? state.gatefoldRear : '',
      gatefoldFront2: !state.sixPageCoverGatefoldFront
        ? state.gatefoldFront
        : '',
      gatefoldRear2: !state.sixPageCoverGatefoldRear ? state.gatefoldRear : '',
    },
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const next = () => {
    useDummyOrderStore.setState({
      coverBrand: getValues('brand'),
      coverBrandFamily: getValues('brandFamily'),
      coverGrammage: getValues('grammage'),
      coverPaperDifferentFromText: getValues('paperDifferentFromText'),
      eightPageCoverGatefold:
        getValues('coverOptionType') === CoverOptionType.EIGHT_PAGE,
      fourPageCoverStandard:
        getValues('coverOptionType') === CoverOptionType.FOUR_PAGE_STANDARD,
      sixPageCoverGatefoldFront:
        getValues('coverOptionType') ===
        CoverOptionType.SIX_PAGE_STANDARD_FRONT,
      sixPageCoverGatefoldRear:
        getValues('coverOptionType') === CoverOptionType.SIX_PAGE_STANDARD_REAR,
      gatefoldFront:
        getValues('coverOptionType') === CoverOptionType.SIX_PAGE_STANDARD_FRONT
          ? getInputValue(getValues, 'gatefoldFront')
          : getInputValue(getValues, 'gatefoldFront2'),
      gatefoldRear:
        getValues('coverOptionType') === CoverOptionType.SIX_PAGE_STANDARD_REAR
          ? getInputValue(getValues, 'gatefoldRear')
          : getInputValue(getValues, 'gatefoldRear2'),
    });
    history.push('/samples/createDummy/copies');
  };

  return (
    <>
      <DummySamplesUserGuide />
      <div style={{ minWidth: '960px' }}>
        <Card header={<Trans>Select your cover paper type and options</Trans>}>
          <form>
            <div className="flex justify-between">
              <CoverPaper register={register} watch={watch} reset={reset} />
              <CoverFormat
                register={register}
                watch={watch}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
              />
            </div>
          </form>
        </Card>
        <DummyButtons
          onNext={next}
          onNextDisabled={!formState.isValid}
          onPrev={() => history.push('/samples/createDummy/textPages')}
        />
      </div>
    </>
  );
};

export default CoverOptions;
