import { Trans } from '@lingui/macro';
import * as React from 'react';
import Date from '../../../../components/Date';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import { Order } from '../../../../types/Order.interface';

interface IOrderRelatedDatesPopoverProps {
  order: Order;
}

const OrderRelatedDatesPopover: React.FunctionComponent<IOrderRelatedDatesPopoverProps> = ({
  order,
}) => {
  const { overviews } = order;
  return (
    <div>
      <h4 className=" bg-gray-dark100 text-white-white p-3 text-xl">
        <Trans id="search.orderDateHeader">Order Related Dates</Trans>
      </h4>
      <div className="p-3">
        <div className="border-b border-gray-500 pb-2">
          <LabelValue
            label={<Trans id="overview.orderDate">Order Placed</Trans>}
            value={
              overviews[0].sapDateCreated ? (
                <Date date={overviews[0].sapDateCreated} />
              ) : (
                '---'
              )
            }
          />
        </div>
        <div className="border-b border-gray-500 py-2">
          <LabelValue
            label={<Trans>Last Date of Revision</Trans>}
            value={
              overviews[0].latestRevisionDate ? (
                <Date date={overviews[0].latestRevisionDate} />
              ) : (
                '---'
              )
            }
          />
        </div>
        <div className="border-b border-gray-500 py-2">
          <LabelValue
            label={<Trans>Updated Delivery Date</Trans>}
            value={
              overviews[0].deliveryDateUpd ? (
                <Date date={overviews[0].deliveryDateUpd} />
              ) : (
                '---'
              )
            }
          />
        </div>
        <div className="pt-2">
          <LabelValue
            label={<Trans id="overview.arrivalDate">Arrival Date</Trans>}
            value={
              overviews[0].arrivalDate ? (
                <Date date={overviews[0].arrivalDate} />
              ) : (
                '---'
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OrderRelatedDatesPopover;
