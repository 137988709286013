import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import ProductQuery from '../../../types/ProductQuery.interface';
import PutUpCategory from '../../../types/PutUpCategory.enum';

export const getFiltersBasedOnMeasurementSystem = (
  putUpFilter: ProductQuery,
  measurementSystem: MeasurementSystem | undefined
): ProductQuery => {
  if (measurementSystem === MeasurementSystem.METRIC) {
    return {
      ...putUpFilter,
      measurementSystem,
      caliperInMils: putUpFilter.caliper
        ? parseFloat(String(putUpFilter.caliper)).toFixed(4)
        : putUpFilter.caliper,
    };
  }
  return { ...putUpFilter, measurementSystem };
};

export const getActualFilterValues = (
  globalRollFilter: ProductQuery,
  globalSheetFilter: ProductQuery,
  type: PutUpCategory,
  measurementSystem: MeasurementSystem | undefined
): ProductQuery => {
  const typedFilter: ProductQuery =
    type === PutUpCategory.ROLL
      ? { ...globalRollFilter, qobFlag: 'X' }
      : { ...globalSheetFilter, qobFlag: 'X' };
  return getFiltersBasedOnMeasurementSystem(typedFilter, measurementSystem);
};
