import { t, Trans } from '@lingui/macro';
import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { Button } from '../../components/Button/Button';
import { saveUserCustomization } from '../../services/User';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { UserCustomization } from '../../types/User/UserCustomization.interface';
import DashboardWidgetInfo from '../Dashboard/types/DashboardWidgetInfo.interface';
import QuickLinkInfo from '../Dashboard/types/QuickLinkInfo.interface';
import { getNewUserCustomizationSettings } from './Account.util';
import AccountFormSection from './AccountFormSection';
import CustomizeQuickLinks from './CustomizeDashboard/CustomizeQuickLinks';
import CustomizeWidgets from './CustomizeDashboard/CustomizeWidgets';
import AccountFormData from './types/AccountFormData.interface';
import UserSettings from './UserSettings/UserSettings';
import FieldPrefixes from './types/FieldPrefixes.enum';

export interface AccountFormProps {
  fetchedSettings: UserCustomization;
  initialValues: AccountFormData;
  dashboardWidgets: DashboardWidgetInfo[];
  quickLinks: QuickLinkInfo[];
  isWidget?: boolean;
  setVisibility?: (dashboardCustomizationIsVisible: boolean) => void;
}

const AccountForm: FC<AccountFormProps> = ({
  fetchedSettings,
  initialValues,
  dashboardWidgets,
  quickLinks,
  isWidget = false,
  setVisibility,
}) => {
  const queryCache = useQueryCache();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [initialResetIsDone, setInitialResetIsDone] = useState<boolean>(false);

  const formMethods = useForm<AccountFormData>({
    mode: 'all',
  });
  const {
    formState: { isValid },
    handleSubmit,
    reset,
    getValues,
    trigger,
  } = formMethods;

  useEffect(() => {
    reset(cloneDeep(initialValues));
    setInitialResetIsDone(true);
  }, [reset, initialValues]);

  useEffect(() => {
    if (!initialResetIsDone) {
      trigger();
    }
  }, [initialResetIsDone, trigger]);

  const doSaveSettings = async (values: AccountFormData) => {
    setIsSaving(true);

    const updatedWidgets = getValues(FieldPrefixes.DASHBOARD_WIDGETS);
    const updatedQuickLinks = getValues(FieldPrefixes.QUICK_LINKS);
    const newSettings = getNewUserCustomizationSettings(
      isWidget
        ? {
            ...initialValues,
            [FieldPrefixes.DASHBOARD_WIDGETS]: updatedWidgets,
            [FieldPrefixes.QUICK_LINKS]: updatedQuickLinks,
          }
        : values,
      fetchedSettings
    );

    await saveUserCustomization(newSettings);
    queryCache.invalidateQueries(QueryCacheName.MEASUREMENT_SYSTEM);
    queryCache.invalidateQueries(QueryCacheName.USER_CUSTOMIZATION);

    setIsSaving(false);
    
    if ( isWidget && setVisibility ) {
      setVisibility(false);
    }
  };

  return (
    <form>
      {!isWidget && (
        <AccountFormSection headingText={t`User Settings`} className="pb-6">
          <UserSettings
            formMethods={formMethods}
            onSaveSettings={doSaveSettings}
            isSaving={isSaving}
          />
        </AccountFormSection>
      )}
      <AccountFormSection
        setVisibility={setVisibility}
        showCloseButton={isWidget}
        headingText={t`Customize Your Dashboard`}
      >
        <div className="flex">
          <CustomizeWidgets
            formMethods={formMethods}
            widgets={dashboardWidgets}
            className="w-1/2"
          />
          <CustomizeQuickLinks
            formMethods={formMethods}
            quickLinks={quickLinks}
            className="w-1/2"
          />
        </div>
        <div className="flex justify-end">
          <Button
            theme="primary"
            onClick={handleSubmit(doSaveSettings)}
            disabled={isSaving || !isValid}
            loading={isSaving}
          >
            <Trans>Save Settings</Trans>
          </Button>
        </div>
      </AccountFormSection>
    </form>
  );
};

export default AccountForm;
