import React, { useEffect, useState } from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import getInvoiceInfo from '../../../services/Invoice';
import { InvoiceResponse } from '../../../types/InvoiceResponse.interface';
import InvoiceDetailsTable from './Table/InvoiceDetailsTable';

interface IInvoiceDetailsProps {
  docNumber?: string;
  docPosition?: string;
}

const InvoiceDetails: React.FunctionComponent<IInvoiceDetailsProps> = ({
  docNumber,
  docPosition,
}) => {
  const { trackPageView } = useAnalytics();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [invoice, setInvoice] = useState<InvoiceResponse>();

  useEffect(() => {
    if (docNumber && docPosition) {
      const getInvoiceDetailsAsync = async () => {
        const invoiceDetails = await getInvoiceInfo(
          String(docNumber),
          String(docPosition)
        );

        trackPageView('TRACK_ORDERS', 'INVOICE_DETAILS', {
          customerId: invoiceDetails.details.customerNumber,
        });

        setInvoice(invoiceDetails);
        setLoading(false);
      };
      getInvoiceDetailsAsync();
    }
  }, [docNumber, docPosition, trackPageView]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner horizontal />
      ) : (
        <InvoiceDetailsTable
          invoice={invoice || ({} as InvoiceResponse)}
          docNumber={docNumber}
          docPosition={docPosition}
        />
      )}
    </>
  );
};

export default InvoiceDetails;
