import Axios from 'axios';

export const sendForgotPasswordReset = async (
  username: string
): Promise<unknown> => {
  const config = { headers: { 'Content-Type': 'text/plain' } };
  const { data } = await Axios.post(
    '/portal/resources/login/password/forgot',
    username,
    config
  );
  return data;
};
