import { t, Trans } from '@lingui/macro';
import { EuOrderBookingBrand } from '../../../types/EuOrderBookingBrand.interface';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import {
  euNumStringFormat,
  naNumStringFormat,
  parseNumberString,
} from '../../../services/Util/parseNumberString.util';

export const determineProductType = (
  brands: EuOrderBookingBrand[],
  characteristics: {
    productType: PutUpCategory;
    gradeBrandMember: string;
    grammage: string;
  }
): PutUpCategory => {
  if (characteristics.productType === PutUpCategory.ROLL) {
    const materialNumber = `${characteristics.gradeBrandMember}-${characteristics.grammage}${characteristics.productType}`;
    const found = brands.some(
      (brand) => brand.materialNumber === materialNumber
    );
    if (!found) {
      return PutUpCategory.CUTTER;
    }
    return PutUpCategory.ROLL;
  }
  return PutUpCategory.SHEET;
};

export const determineGrainDirection = (
  width: string,
  length: string,
): string => {
  const testWidth = width.includes(",") ? parseNumberString(width, euNumStringFormat) : parseNumberString(width, naNumStringFormat);
  const testLen = length.includes(",") ? parseNumberString(length, euNumStringFormat) : parseNumberString(length, naNumStringFormat);
  const direction = testWidth > testLen ? t`Short Grain` : t`Long Grain`;
  return direction;
};
