import Axios from 'axios';
import { omitEmptyProperties } from '../../services/Util/omitEmptyProperties.util';
import GetPrintableLabelsParams from './types/GetPrintableLabelsParams.type';
import GetPrintableLabelsResponse from './types/GetPrintableLabelsResponse.interface';

export const getPrintableLabels = async (
  params: GetPrintableLabelsParams
): Promise<GetPrintableLabelsResponse> => {
  const { data } = await Axios.get('/portal/resources/label', {
    params: omitEmptyProperties(params),
  });
  return data;
};
