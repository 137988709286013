import Axios from 'axios';
import { LoginResponse } from '../../types/LoginResponse.interface';

export const login = async (
  username: string,
  password: string
): Promise<LoginResponse> => {
  const { data, headers } = await Axios.post('/portal/json_login', {
    username,
    password,
  });
  return {
    data,
    headers,
  };
};

export const logout = async (): Promise<unknown> => {
  const { data } = await Axios.post('/portal/logout');
  return {
    data,
  };
};
