import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React, { ChangeEvent, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Table from '../../../components/Table/Table';
import { getPutUpCategoryByKwd } from '../../../services/Product';
import Brand from '../../../types/Brand.interface';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import {
  skuCheckboxPrefix,
  getSkusToAddFromFormData,
} from './SkuLookupModalAddSkusForm.util';

interface IProductAvailabilityTableProp {
  data?: Partial<Brand>[];
  addSappiSkus: (sappiSkusToAdd: string[]) => void;
  getValues: UseFormMethods['getValues'];
  handleSubmit: UseFormMethods['handleSubmit'];
  register: UseFormMethods['register'];
  reset: UseFormMethods['reset'];
  setValue: UseFormMethods['setValue'];
  formId: string;
  onSubmit: () => void;
}

const ProductAvailabilitySkuTable: React.FunctionComponent<IProductAvailabilityTableProp> = ({
  data,
  addSappiSkus,
  getValues,
  handleSubmit,
  register,
  reset,
  setValue,
  formId,
  onSubmit,
}) => {
  const [allSkusBoxChecked, setAllSkusBoxChecked] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const { isMetric } = useMeasurementSystem();

  const doToggleAllSkusBox = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const inputNames = Object.keys(getValues());
      inputNames.forEach((inputName) => {
        if (inputName.startsWith(skuCheckboxPrefix)) {
          setValue(inputName, true);
        }
      });
      setAllSkusBoxChecked(true);
    } else {
      reset();
      setAllSkusBoxChecked(false);
    }
  };

  const doSubmit = (formData: Record<string, unknown>): void => {
    setProcessing(true);

    const sappiSkusToAdd = getSkusToAddFromFormData(formData);

    addSappiSkus(sappiSkusToAdd);
    onSubmit();
  };

  const columns: ColumnsType<Partial<Brand>> = [
    {
      title: (
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            checked={allSkusBoxChecked}
            onChange={doToggleAllSkusBox}
            className="mr-1.5"
          />
          <Trans>Sappi SKU</Trans>
        </label>
      ),
      key: 'sappiSku',
      render: (value, record) => {
        const sappiSku = record?.skuCode || null;
        return sappiSku ? (
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              name={`${skuCheckboxPrefix}${sappiSku}`}
              ref={register}
              className="mr-1.5"
            />
            {sappiSku}
          </label>
        ) : (
          <></>
        );
      },
    },
    { title: <Trans>Product</Trans>, dataIndex: 'gradeText', key: 'product' },
    {
      title: isMetric ? <Trans>Grammage</Trans> : <Trans>Basis Weight</Trans>,
      key: isMetric ? 'grammage' :'basisWeight',
      render: (value, record) => isMetric ? `${record.grammage}` : `${record.baseWeight} ${record.tradeBasis}`,
    },
    {
      title: <Trans>Width</Trans>,
      key: 'width',
      render: (value, record) => {
        if(isMetric){
          return record.width;
        }
        return record?.putUp &&
          getPutUpCategoryByKwd(record.putUp) === PutUpCategory.ROLL
          ? record.reelWidthImp
          : record.widthImp;
      },
    },
    {
      title: <Trans>Length / Diameter</Trans>,
      key: 'lengthOrDiameter',
      render: (value, record) => {
        if(isMetric){
          return record?.putUp &&
            getPutUpCategoryByKwd(record.putUp) === PutUpCategory.ROLL
            ? record.reelDiameter
            : record.length;
        }
        return record?.putUp &&
          getPutUpCategoryByKwd(record.putUp) === PutUpCategory.ROLL
          ? record.reelDiameterImp
          : record.lengthImp;
      },
    },
    {
      title: <Trans>Product Type</Trans>,
      dataIndex: 'putUpText',
      key: 'putUpText',
    },
  ];

  return processing ? (
    <LoadingSpinner horizontal />
  ) : (
    <form
      id={formId}
      onSubmit={handleSubmit(doSubmit)}
      className="max-h-80 overflow-y-auto"
    >
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        locale={{ emptyText: t`No matching Sappi SKUs found.` }}
      />
    </form>
  );
};

export default ProductAvailabilitySkuTable;
