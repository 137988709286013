import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import euStockLotIntroImage from '../../../assets/eu/ecomm_stock_lot.jpg';
import defaultStockLotIntroImage from '../../../assets/eu/stock-lot-intro.jpg';
import { Button } from '../../../components/Button/Button';
import DeconstructedTablePagination, {
  IDeconstructedTablePaginationProps,
} from '../../../components/DeconstructedTable/DeconstructedTablePagination';
import LanderContentRender from '../../../components/LanderContent/LanderContentRender';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import SearchPanel from '../../../components/SearchPopover/SearchPanel';
import useAnalytics from '../../../hooks/useAnalytics';
import useRole from '../../../hooks/useRole';
import { LanderContent } from '../../../types/LanderContent.interface';
import { FilterFormData } from '../types/FilterFormData.interface';
import { StockLotRequest } from '../types/StockLotRequest.interface';
import useStockLotStore from '../useStockLotStore.store';
import DownloadReport from './DownloadReport';
import ResultsTable from './ResultsTable';
import StockLotFilterForm from './StockLotFilterForm';

interface IResultsProps {
  isSearching: boolean;
  onApplyFilters: (incomingFilters: StockLotRequest) => void;
}

const getContent = (isEU: boolean): LanderContent => ({
  header: '',
  body: '',
  id: 1,
  image: isEU ? euStockLotIntroImage : defaultStockLotIntroImage,
  language: '',
  title: '',
});
const FIRST_PAGE_NUM = 1;
const TRUE = 'true';

const Results: FC<IResultsProps> = ({ isSearching, onApplyFilters }) => {
  const { setRequest, setShowAll, setCurrentPage } = useStockLotStore();
  const currentPage = useStockLotStore((state) => state.currentPage);
  const showAll = useStockLotStore((state) => state.showAll);
  const request = useStockLotStore((state) => state.request);
  const results = useStockLotStore((state) => state.response);
  const isSearched = useStockLotStore((state) => state.isSearched);
  const searchFormRequest = useStockLotStore(
    (state) => state.searchFormRequest
  );
  const { trackPageView } = useAnalytics();
  const { isEuUser } = useRole();

  const { register, handleSubmit, formState, setValue } = useForm({
    mode: 'all',
  });

  const [searchPopoverVisible, setSearchPopoverVisible] = useState(false);

  const doChangePage = (newPage: number): void => {
    trackPageView('STOCK_LOT', 'PAGE_CHANGE');
    setCurrentPage(newPage);
    if (request) {
      onApplyFilters({
        ...request,
        page: newPage,
        all: '',
      });
    }
  };

  const doToggleShowingAll = (): void => {
    trackPageView('STOCK_LOT', 'SHOW_ALL');
    const toggledShowingAll = !showAll;

    if (request) {
      onApplyFilters({
        ...request,
        page: FIRST_PAGE_NUM,
        all: toggledShowingAll ? TRUE : '',
      });
    }

    setCurrentPage(FIRST_PAGE_NUM);
    setShowAll(toggledShowingAll);
  };

  const paginationInfo = results?.pagination;
  const paginationProps: IDeconstructedTablePaginationProps | undefined =
    paginationInfo?.pageCount !== undefined &&
    paginationInfo?.recordCount !== undefined
      ? {
          pageCount: paginationInfo.pageCount,
          currentPage: currentPage !== undefined ? currentPage : FIRST_PAGE_NUM,
          recordCount: paginationInfo.recordCount,
          isShowingAll: showAll,
          onChangePage: doChangePage,
          onToggleShowingAll: doToggleShowingAll,
        }
      : undefined;

  const onSubmit = async (newFilters: Partial<FilterFormData>) => {
    if (request) {
      const appliedRequest: StockLotRequest = {
        ...request,
        ...newFilters,
        gradeDescription:
          newFilters.gradeDescription !== ''
            ? newFilters.gradeDescription
            : searchFormRequest?.gradeDescription,
        page: FIRST_PAGE_NUM,
      };

      setCurrentPage(FIRST_PAGE_NUM);
      setRequest(appliedRequest);
      onApplyFilters(appliedRequest);
    }
  };

  const onReset = async () => {
    if (searchFormRequest) {
      onApplyFilters({
        ...searchFormRequest,
        page: FIRST_PAGE_NUM,
      });

      setCurrentPage(FIRST_PAGE_NUM);
      setRequest(searchFormRequest);
    }
  };

  return (
    <>
      {!results?.batches && !isSearched && !isSearching ? (
        <LanderContentRender
          content={getContent(isEuUser)}
          className="flex-grow bg-cover bg-left-bottom ml-6"
        />
      ) : (
        <>
          {isSearching ? (
            <div className="w-full flex align-top justify-center">
              <div>
                <LoadingSpinner horizontal />
              </div>
            </div>
          ) : (
            <div
              className="flex-grow break-words ml-6"
              style={{ width: '70%' }}
            >
              {results?.batches && results.batches.length > 0 ? (
                <Space direction="vertical" className="w-full text-right">
                  <Space size="middle">
                    {paginationProps && (
                      <DeconstructedTablePagination {...paginationProps} />
                    )}
                    <DownloadReport />
                    <Button
                      theme="link"
                      onClick={() => setSearchPopoverVisible(true)}
                    >
                      <i className="fa fa-search text-xl" />
                    </Button>
                  </Space>
                  <SearchPanel
                    visible={searchPopoverVisible}
                    title={t({
                      message: 'Search Stock Lots',
                    })}
                    onClose={() => setSearchPopoverVisible(false)}
                    body={
                      <StockLotFilterForm
                        register={register}
                        setValue={setValue}
                        onSubmitSearch={handleSubmit(onSubmit)}
                      />
                    }
                    button={
                      <Button
                        theme="primary"
                        onClick={handleSubmit(onReset)}
                        disabled={!formState.isValid}
                        aria-expanded={searchPopoverVisible}
                      >
                        {t({
                          id: 'reset.inline.submit.value',
                          message: 'Reset',
                        })}
                      </Button>
                    }
                  />
                  <ResultsTable results={results.batches} />
                </Space>
              ) : (
                <div className="text-xl w-full text-center mt-10">
                  <Trans>No items found that match given criteria</Trans>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Results;
