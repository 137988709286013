import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React from 'react';
import useBasket from '../../hooks/useBasket';
import useMeasurementSystem from '../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import MeasurementSystem from '../../types/MeasurementSystem.enum';
import { Button } from '../Button/Button';
import LabelValue from '../LabelValue/LabelValue';

interface IOldBasketPopUpProps {
  onViewBasket: () => void;
  onContinueShopping: () => void;
}

const OldBasketPopUp: React.FunctionComponent<IOldBasketPopUpProps> = ({
  onViewBasket,
  onContinueShopping,
}) => {
  const { lastItemAdded } = useBasket();
  const { measurementSystem } = useMeasurementSystem();
  const { format } = useNumberFormatter();

  return (
    // TODO: Refactor to use reusable subcomponents in this directory e.x. BasketPopUpHeader etc.
    // TODO: Since basket pop ups differ by domain, move and rename this to reflect which domain(s) it applies to
    <div className="p-3" style={{ width: '290px' }}>
      <div className="bg-gray-dark200 py-2 px-4 items-center">
        <div className="text-lg text-white-white font-bold">
          <Trans id="eu.added_to_basket">Added to Basket</Trans>
        </div>
      </div>
      <div className="my-3 mx-3 flex flex-col">
        <Space direction="vertical" size={1}>
          <LabelValue
            inverse
            label={<Trans id="eu.customer">Customer</Trans>}
            value={lastItemAdded?.soldToCustomer?.name}
          />
          <div>
            <strong>
              {lastItemAdded?.item?.material && (
                <span>{lastItemAdded?.item?.material}</span>
              )}
              {lastItemAdded?.item?.product && (
                <span>{lastItemAdded?.item?.product}</span>
              )}{' '}
              {lastItemAdded?.item?.bookweight}{' '}
              {lastItemAdded?.item?.tradeBasis}
            </strong>
            {lastItemAdded?.item?.lengthImp && lastItemAdded?.item?.widthImp && (
              <div>
                {lastItemAdded?.item?.grainDirection}&nbsp;
                {lastItemAdded?.item?.widthImp}
                {lastItemAdded?.item?.lengthImp !== '' &&
                  lastItemAdded?.item?.widthImp !== '' && <span>x</span>}
                &nbsp;
                {lastItemAdded?.item?.lengthImp}
              </div>
            )}
            {lastItemAdded?.item?.sheetsPerPallet && (
              <div>
                {lastItemAdded?.item?.sheetsPerPallet}{' '}
                <Trans id="stockAvailability.unit.sheetsPerPallet">
                  SH/Pallet
                </Trans>
              </div>
            )}
            {lastItemAdded?.item?.stacksPerPallet && (
              <div>
                {lastItemAdded?.item?.stacksPerPallet}{' '}
                <Trans id="stockAvailability.unit.stacksPerPallet">
                  ST/Pallet
                </Trans>
              </div>
            )}
            {lastItemAdded?.item?.mWeight && (
              <LabelValue
                inline
                inverse
                label={
                  <Trans id="stockAvailability.label.weight">
                    M Weight/Avg Roll Wgt
                  </Trans>
                }
                value={lastItemAdded?.item?.mWeight}
              />
            )}
            {lastItemAdded?.item?.packageTypeDesc && (
              <div>{lastItemAdded.item.packageTypeDesc}</div>
            )}
          </div>
          <div>
            <LabelValue
              label={<Trans id="eu.qty">Qty</Trans>}
              value={`${
                lastItemAdded?.item?.requestedQuantity
                  ? lastItemAdded.item.requestedQuantity
                  : ''
              } ${
                lastItemAdded?.item?.requestedUnitOfMeasure
                  ? lastItemAdded.item.requestedUnitOfMeasure
                  : ''
              }`}
              inverse
              inline
            />
          </div>
        </Space>
        <Space direction="vertical" size="small">
          <div className="px-2 py-3 font-bold bg-gray-whiteSmoke200 border-gray-light400 border">
            <Space direction="vertical" size="small">
              <div>
                {lastItemAdded?.basketCount}{' '}
                <Trans id="eu.baskets_in">items in your basket</Trans>
              </div>
              <LabelValue
                label={<Trans id="total_quantity">Total Quantity</Trans>}
                value={
                  <>
                    {format(lastItemAdded?.basketWeight, true)}&nbsp;
                    {measurementSystem !== MeasurementSystem.METRIC ? (
                      <Trans id="stockAvailability.unit.pounds">LB(S)</Trans>
                    ) : (
                      <Trans id="stockAvailability.unit.kg">KG(S)</Trans>
                    )}
                  </>
                }
                inline
              />
            </Space>
          </div>
          <Space direction="vertical" size="small" className="w-full">
            <Button width="full" theme="inverse" onClick={() => onViewBasket()}>
              <Trans id="eu.basket.view">View Basket</Trans>
            </Button>
            <Button width="full" theme="primary" onClick={onContinueShopping}>
              <Trans id="eu.basket.booked.continue">Continue Shopping</Trans>
            </Button>
          </Space>
        </Space>
      </div>
    </div>
  );
};

export default OldBasketPopUp;
