import * as React from 'react';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { ProductAvailabilityResult } from '../../../../types/ProductAvailabilityResult.interface';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import { getSheetTotal } from '../SearchResults.util';

interface IConfirmedQuantityCellProps {
  record: ProductAvailabilityResult;
  requestedQuantity?: string;
}

const ConfirmedQuantityCell: React.FunctionComponent<IConfirmedQuantityCellProps> = ({
  record,
  requestedQuantity,
}) => {
  const { format } = useNumberFormatter();
  const showSheetTotal =
    (record.confirmedUnit === UnitOfMeasure.TO ||
      record.confirmedUnit === UnitOfMeasure.KG ||
      record.confirmedUnit === UnitOfMeasure.PL ||
      record.confirmedUnit === UnitOfMeasure.PK) &&
    record.productType === PutUpCategory.SHEET;

  const confirmedQuantityIsDifferent =
    !requestedQuantity || +requestedQuantity !== record.confirmedQuantity;

  return (
    <div>
      <div
        className={
          confirmedQuantityIsDifferent ? 'text-orange-orange font-bold' : ''
        }
      >
        <div>
          {format(record.confirmedQuantity)} {record.confirmedUnit}
        </div>
        {record.confirmedUnit !== 'KG' && (
          <div>{format(record.confirmedKilograms)} KG</div>
        )}
      </div>

      {showSheetTotal && (
        <div>
          {format(
            getSheetTotal({
              quantity: record.confirmedQuantity || 0,
              quantityInKg: record.confirmedKilograms || 0,
              unit: record.confirmedUnit as UnitOfMeasure,
              length: record.length || 0,
              width: record.width || 0,
              grammage: record.grammage || '0',
              sheetsPerPallet: record.sheetsPerPallet || '0',
              sheetsPerReam: record.sheetsPerReam || '0',
            })
          )}{' '}
          Sheets
        </div>
      )}
    </div>
  );
};

export default ConfirmedQuantityCell;
