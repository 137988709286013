import { Trans } from '@lingui/macro';
import { capitalize } from 'lodash';
import React from 'react';
import Date from '../../../components/Date';
import LabelValue from '../../../components/LabelValue/LabelValue';
import useAnalytics from '../../../hooks/useAnalytics';
import { DateFormat } from '../../../hooks/useDateFormatter';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import useRole from '../../../hooks/useRole';
import getDocument from '../../../services/Documents';
import printDocument from '../../../services/Util/printDocument.util';
import stripZeros from '../../../services/Util/stripZeros.util';
import { AuthorityRole } from '../../../types/Authority.interface';
import { OrderOverviewRecord } from '../../../types/OrderOverviewRecord.interface';
import PrintWeightListsButton from './PrintWeightListsButton';

interface IDeliveryDetailsSectionProps {
  deliveryOverview: OrderOverviewRecord;
  docNumber?: string;
  docPosition?: string;
}

const DeliveryDetailsSection: React.FunctionComponent<IDeliveryDetailsSectionProps> = ({
  deliveryOverview,
  docNumber,
  docPosition,
}) => {
  const { hasRole } = useRole();
  const { trackPageView } = useAnalytics();
  const { formatWithUnit } = useNumberFormatter();
  const deliveryDetails = deliveryOverview.deliveryInfo
    ? deliveryOverview.deliveryInfo[0].details
    : undefined;
  const deliveryLatestEvent = deliveryOverview.deliveryInfo
    ? deliveryOverview.deliveryInfo[0].latestEvent
    : undefined;

  const printWeightlist = async () => {
    if (deliveryDetails) {
      trackPageView('TRACK_ORDERS', 'PRINTING_DOCUMENTS', {
        customerId: deliveryDetails.customerNumber,
      });

      await getDocument(
        String(docNumber),
        String(docPosition),
        String('WEIGHTLIST')
      ).then((document) => {
        printDocument(document);
      });
    }
  };

  return (
    <div className="m-2">
      <div className="border-b border-gray-light400">
        <div className="p-4 bg-gray-dark100 text-white-white font-bold text-lg">
          <Trans id="delivery.infoHeader">Information About Shipping</Trans>
        </div>
        <div className="flex text-gray-dark200">
          <div className="w-1/3 py-4 px-3 border-r border-gray-light400">
            <LabelValue
              className="mb-2"
              inline
              label={<Trans id="delivery.sappiOrder">Sappi Order #</Trans>}
              value={stripZeros(deliveryOverview.documentNumber)}
            />
            <LabelValue
              className="mb-2"
              inline
              label={<Trans>My Order #</Trans>}
              value={stripZeros(deliveryOverview.customerOrderNumber)}
            />
          </div>
          <div className="w-2/3 py-4 px-3">
            <LabelValue
              className="mb-2"
              inline
              label={<Trans id="delivery.address">Delivery Location</Trans>}
              value={
                <div>
                  {deliveryOverview.shipToPartyName}
                  {` / `}
                  {deliveryOverview.shipToPartyCity}
                </div>
              }
            />
            {stripZeros(deliveryOverview.shipTpPoNumber) && (
              <LabelValue
                className="mb-2"
                inline
                label={<Trans>Ship To PO #</Trans>}
                value={stripZeros(deliveryOverview.shipTpPoNumber)}
              />
            )}
          </div>
        </div>
      </div>
      {deliveryDetails && (
        <div className="px-3 my-8">
          <div className="text-blue-pacific text-xl">
            <LabelValue
              inline
              label={
                <Trans id="delivery.confirmedDelivery">
                  Confirmed Delivery Date
                </Trans>
              }
              value={<Date date={deliveryDetails.confirmedDeliveryDate} />}
            />
          </div>
        </div>
      )}
      {deliveryDetails && (
        <div className="flex pt-4 text-gray-dark200">
          <div className="w-1/2">
            <LabelValue
              center
              inline
              label={
                <Trans id="delivery.requestedDelivery">
                  Requested Delivery Date
                </Trans>
              }
              value={<Date date={deliveryDetails.requestedDeliveryDate} />}
            />
            <LabelValue
              center
              inline
              label={<Trans id="delivery.shipmentDate">Shipment Date</Trans>}
              value={<Date date={deliveryDetails.shipDate} />}
            />
            <LabelValue
              center
              inline
              label={
                <Trans id="delivery.estArrivalDate">Est. Arrival Date</Trans>
              }
              value={<Date date={deliveryDetails.arrivalDate} />}
            />
            {hasRole(AuthorityRole.ROLE_EU) && deliveryOverview.reasonText && (
              <LabelValue
                center
                inline
                label={
                  <Trans id="delivery.deviationReason">Deviation Reason</Trans>
                }
                value={
                  <div>
                    {deliveryOverview.reasonText}
                    {` - `}
                    {deliveryOverview.arrivalDate && (
                      <Date date={deliveryOverview.arrivalDate} />
                    )}
                    {` `}
                    {deliveryOverview.arrivalTime && (
                      <Date
                        date={deliveryOverview.arrivalTime}
                        format={DateFormat.BASIC_TIME}
                      />
                    )}
                  </div>
                }
              />
            )}
            <br />
            {deliveryLatestEvent && (
              <div>
                <LabelValue
                  center
                  inline
                  label={<Trans id="delivery.status">Status</Trans>}
                  value={capitalize(deliveryLatestEvent.status)}
                />
                <LabelValue
                  center
                  inline
                  label={<Trans id="delivery.trackLocation">Location</Trans>}
                  value={deliveryLatestEvent.location}
                />
                <LabelValue
                  center
                  inline
                  label={<Trans id="delivery.trackDate">Date</Trans>}
                  value={
                    <div>
                      {deliveryLatestEvent &&
                        deliveryLatestEvent.messageDate && (
                          <Date date={deliveryLatestEvent.messageDate} />
                        )}
                      {` `}
                      {deliveryLatestEvent &&
                        deliveryLatestEvent.messageTime && (
                          <Date
                            date={deliveryLatestEvent.messageTime}
                            format={DateFormat.BASIC_TIME}
                          />
                        )}
                    </div>
                  }
                />
              </div>
            )}
          </div>
          <div className="w-1/2">
            <LabelValue
              center
              inline
              label={<Trans>Bill of Lading #</Trans>}
              value={deliveryDetails.deliveryNote}
            />
            <LabelValue
              center
              inline
              label={<Trans id="delivery.freight">Freight #</Trans>}
              value={stripZeros(deliveryDetails.transitId)}
            />
            <LabelValue
              center
              inline
              label={<Trans id="delivery.weightlist">Weightlist #</Trans>}
              value={stripZeros(deliveryDetails.weightListNumber)}
            />
            <LabelValue
              center
              inline
              label={<Trans id="delivery.quantity">Gross Quantity</Trans>}
              value={formatWithUnit(deliveryDetails.quantity)}
            />
            {deliveryDetails.sheetCount > 0 ? (
              <LabelValue
                center
                inline
                label={<Trans id="delivery.sheet.count">Sheet Count</Trans>}
                value={deliveryDetails.sheetCount}
              />
            ) : (
              <></>
            )}
            <LabelValue
              center
              inline
              label={<Trans>Container/Trailer #</Trans>}
              value={deliveryDetails.payloadInfo}
            />
            <LabelValue
              center
              inline
              label={<Trans id="delivery.forwarder">Forwarding Agent</Trans>}
              value={deliveryDetails.forwardingAgent}
            />
            {deliveryDetails.plTypeDesc ? (
              <LabelValue
                center
                inline
                label={<Trans id="delivery.plTypeDesc">Mode of Transport</Trans>}
                value={deliveryDetails.plTypeDesc}
              />
            ) : (
              <></>
            )}
            {hasRole(AuthorityRole.ROLE_NA) &&
              deliveryDetails.consigneeName && (
                <LabelValue
                  center
                  inline
                  label={
                    <Trans id="delivery.consignedTo">Reconsigned To</Trans>
                  }
                  value={deliveryDetails.consigneeName}
                />
              )}
          </div>
        </div>
      )}
      {deliveryDetails && (
        <div className="mt-12 pt-8 flex justify-end">
          <PrintWeightListsButton theme="primary" onClick={printWeightlist} />
        </div>
      )}
    </div>
  );
};

export default DeliveryDetailsSection;
