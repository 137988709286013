import * as React from 'react';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';

interface IShipToCellProps {
  order: AugmentedSamplesHistoryOrder;
}

const ShipToCell: React.FunctionComponent<IShipToCellProps> = ({ order }) => (
  <DeconstructedTableBodyCell className="ship-to-cell">
    <div>{order.shipToName}</div>
  </DeconstructedTableBodyCell>
);

export default ShipToCell;
