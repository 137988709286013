import Axios from 'axios';
import { differenceBy, filter, map } from 'lodash';
import moment from 'moment';
import AvailabilityBasketEU from '../../types/AvailabilityBasketEU.interface';
import AvailabilityBasketEUStatus from '../../types/AvailabilityBasketEUStatus.interface';
import { AvailabilityBasketItem } from '../../types/AvailabilityBasketItem.interface';
import { AvailabilityBasketNA } from '../../types/AvailabilityBasketNA.interface';
import SubmitEuOrderBookingBasketOrderResult from '../../types/Basket/SubmitEuOrderBookingBasketOrderResult.interface';
import IStockCharacteristics from '../../types/IStockCharacteristics.interface';
import { ProductAvailabilityResult } from '../../types/ProductAvailabilityResult.interface';
import { StockLotBasket } from '../../types/StockLotBasket.interface';
import { StockLotItem } from '../../types/StockLotItem.interface';

export const getCustomerBaskets = async (): Promise<AvailabilityBasketNA[]> => {
  const { data } = await Axios.get('/portal/resources/basket-na');
  return data;
};

export const getOrderBookingBaskets = async (): Promise<
  AvailabilityBasketEU[]
> => {
  const { data } = await Axios.get('/portal/resources/eu/availability/basket');
  return data;
};

export const getOrderBookingBasketById = async (
  basketId: string
): Promise<AvailabilityBasketEU> => {
  const { data } = await Axios.get(
    `/portal/resources/eu/availability/basket/${basketId}`
  );
  return data;
};

export const getStockLotBaskets = async (): Promise<StockLotBasket[]> => {
  const { data } = await Axios.get('/portal/resources/stocklot/basket');
  return data;
};

// update basket per customer
export const updateBasket = async (
  basket: AvailabilityBasketNA
): Promise<AvailabilityBasketNA> => {
  const { data } = await Axios.put(
    '/portal/resources/basket-na/update',
    basket
  );
  return data;
};

export const addStockToEuBasket = async (
  stock: IStockCharacteristics,
  quantityRequested: number,
  dateRequested: Date
): Promise<AvailabilityBasketEU> => {
  const formattedDate = moment(dateRequested).format('YYYY-MM-DD');
  const { data } = await Axios.post(
    `/portal/resources/eu/availability/basket/order-booking/stock/${quantityRequested}/${formattedDate}`,
    stock
  );
  return data;
};

export const addProductToEuBasket = async (
  product: ProductAvailabilityResult
): Promise<AvailabilityBasketEU> => {
  const { data } = await Axios.post(
    `/portal/resources/eu/availability/basket/order-booking/product`,
    product
  );
  return data;
};

export const updateEuBasket = async (
  basket: AvailabilityBasketEU
): Promise<AvailabilityBasketEU> => {
  const { data } = await Axios.put(
    `/portal/resources/eu/availability/basket/order-booking/${basket.id}`,
    basket
  );
  return data;
};

export const saveEuBasket = async (
  basket: AvailabilityBasketEU
): Promise<AvailabilityBasketEU> => {
  const { data } = await Axios.put(
    `/portal/resources/eu/availability/basket/${basket.id}`,
    basket
  );
  return data;
};

export const getEuBasketStatus = async (
  basket: AvailabilityBasketEU
): Promise<AvailabilityBasketEUStatus> => {
  const { data } = await Axios.get(
    `/portal/resources/eu/availability/basket/order-booking/${basket.id}/status`
  );
  return data;
};

export const submitNaBasketOrder = async (
  basket: AvailabilityBasketNA
): Promise<AvailabilityBasketNA> => {
  const { data } = await Axios.post(
    `/portal/resources/basket-na/order`,
    basket
  );
  return data;
};

export const submitEuOrderBookingBasketOrder = async (
  basket: AvailabilityBasketEU
): Promise<SubmitEuOrderBookingBasketOrderResult> => {
  const { data } = await Axios.put(
    `/portal/resources/eu/availability/basket/${basket.id}`,
    basket
  );
  return data;
};

export const submitNaBasketsOrder = async (
  baskets: AvailabilityBasketNA[]
): Promise<AvailabilityBasketNA[]> => {
  return Promise.all(map(baskets, submitNaBasketOrder));
};

export const mergeCustomerBasketItems = (
  customerBasketItems: AvailabilityBasketItem[],
  productsToAdd: AvailabilityBasketItem[]
): AvailabilityBasketItem[] => {
  const existingUnchangedBasketItems = differenceBy(
    customerBasketItems,
    productsToAdd,
    'skuCode'
  );
  return productsToAdd.concat(existingUnchangedBasketItems);
};

export const removeItemFromBasket = (
  basket: AvailabilityBasketNA,
  idToRemove: number
): AvailabilityBasketNA => {
  return {
    ...basket,
    items: filter(basket.items, (i) => i.id !== idToRemove),
  };
};

export const deleteAvailabilityItem = async (
  basketId: number | null,
  itemId: number | null
): Promise<unknown> => {
  const { data } = await Axios.delete(
    `/portal/resources/eu/availability/basket/${basketId}/${itemId}`
  );
  return data;
};

export const markEuItemForDeletion = (
  basket: AvailabilityBasketEU,
  idToMarkAsDeleted: number
): AvailabilityBasketEU => {
  return {
    ...basket,
    items: map(basket.items, (item) => ({
      ...item,
      delete: item.id === idToMarkAsDeleted,
    })),
  };
};

export const getOrderBookingBasketQuantityCount = (
  baskets: AvailabilityBasketEU[]
): number => {
  return baskets.reduce((totalAccumulator, currentBasket) => {
    const basketItems = currentBasket?.items || [];
    return (
      totalAccumulator +
      basketItems.reduce((accumulator, currentBasketItem) => {
        return accumulator + (currentBasketItem.requestedQuantityKg || 0);
      }, 0)
    );
  }, 0);
};

export const getStockLotBasketQuantityCount = (
  baskets: StockLotBasket[]
): number => {
  return baskets.reduce((totalAccumulator, currentBasket) => {
    const basketItems = currentBasket?.items || [];
    return (
      totalAccumulator +
      basketItems.reduce((accumulator, currentBasketItem) => {
        return accumulator + (currentBasketItem.quantity || 0);
      }, 0)
    );
  }, 0);
};

export const submitStockLotBasketOrder = async (
  basket: StockLotBasket
): Promise<StockLotBasket> => {
  const { data } = await Axios.put(
    `/portal/resources/stocklot/basket/${basket.soldTo.number}/${basket.shipTo.number}`,
    basket
  );
  return data;
};

export const updateStockLotBasketItem = async (
  item: StockLotItem,
  soldToCustomerNumber: string,
  shipToCustomerNumber: string
): Promise<StockLotBasket> => {
  const { data } = await Axios.put(
    `/portal/resources/stocklot/basket/${soldToCustomerNumber}/${shipToCustomerNumber}/${item.id}`,
    item
  );

  return data;
};

export const deleteStockLotBasketItem = async (
  soldToCustomerNumber: string,
  shipToCustomerNumber: string,
  basketItemId: number
): Promise<StockLotBasket> => {
  const { data } = await Axios.delete(
    `/portal/resources/stocklot/basket/${soldToCustomerNumber}/${shipToCustomerNumber}/${basketItemId}`
  );
  return data;
};
