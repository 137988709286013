import * as React from 'react';

export type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: React.ReactNode;
  labelPosition?: 'left' | 'right';
  checkboxClassName?: string;
};

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      labelPosition = 'right',
      className = '',
      checkboxClassName = '',
      ...rest
    },
    ref
  ) => (
    <label className={`block ${className}`}>
      {labelPosition === 'left' && <>{label}&nbsp;</>}
      <input
        type="checkbox"
        className={`mr-2 ${checkboxClassName}`}
        {...rest}
        ref={ref}
      />
      {labelPosition === 'right' && label}
    </label>
  )
);

/**
 * Component is described here.
 *
 * @example ../componentExamples/OtherInput.md
 */
export default Checkbox;
