import { Trans } from '@lingui/macro';
import { Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import { Button } from '../../../components/Button/Button';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../hooks/useAnalytics';
import useRole from '../../../hooks/useRole';
import { searchOrderDetailsAsync } from '../../../services/Order';
import enrichOrderQuantity from '../../../services/Util/enrichOrderQuantity.util';
import { AuthorityRole } from '../../../types/Authority.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { TrackOrder } from '../../../types/TrackOrder.interface';
import { SearchOrderDetailsInputs } from '../SearchOrderDetailsInputs.interface';
import TrackOrderTab from '../TrackOrderTab.enum';
import deliveryHeader from './Table/deliveryTableHeader.util';
import OrderDetailsDeliveryTable from './Table/OrderDetailsDeliveryTable';
import OrderDetailsHistoryTable from './Table/OrderDetailsHistoryTable';
import OrderDetailsTable from './Table/OrderDetailsTable';
import OrderDetailsTableHeader from './Table/OrderDetailsTableHeader';

interface IOrderDetailsProps {
  searchOrderDetailsInputs?: SearchOrderDetailsInputs;
  onNewTabTrigger: (tab: TrackOrderTab, data: SearchOrderDetailsInputs) => void;
}

const OrderDetails: React.FunctionComponent<IOrderDetailsProps> = ({
  searchOrderDetailsInputs,
  onNewTabTrigger,
}) => {
  const [order, setOrder] = useState<TrackOrder>();
  const [accordionClosed, setAccordionClosed] = useState<boolean>(false);
  const [footerForDelivery, setFooterForDelivery] = useState<boolean>(false);
  const [footerForHistory, setFooterForHistory] = useState<boolean>(false);

  const [accordionClosedDelivery, setAccordionDeliveryClosed] = useState<
    boolean
  >(false);

  const [isNAUser, setIsNAUser] = useState<boolean>(true);

  const { resolvedData: data, isFetching } = usePaginatedQuery(
    [QueryCacheName.ORDER_DETAILS, searchOrderDetailsInputs],
    searchOrderDetailsAsync,
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: searchOrderDetailsInputs,
      keepPreviousData: true,
    }
  );

  const { trackPageView } = useAnalytics();
  const { Panel } = Collapse;
  const { hasRole } = useRole();

  const onChange = () => {
    return setAccordionClosed(!accordionClosed);
  };

  const onChangeDelivery = () => {
    return setAccordionDeliveryClosed(!accordionClosedDelivery);
  };

  useEffect(() => {
    trackPageView('TRACK_ORDERS', 'VIEW_ORDER_DETAILS', {
      customerId: data?.searchedTrackOrderDetails.customerNumber,
    });

    if (data) {
      setOrder(enrichOrderQuantity(data.searchedTrackOrderDetails));
      if (
        hasRole(AuthorityRole.ROLE_NA) &&
        !hasRole(AuthorityRole.ROLE_RELEASE)
      ) {
        if (data.histories && data.histories.length > 0) {
          setFooterForDelivery(false);
          setFooterForHistory(true);
        } else if (data.deliveries && data.deliveries.length > 0) {
          setFooterForDelivery(true);
          setFooterForHistory(false);
        } else {
          setFooterForDelivery(false);
          setFooterForHistory(false);
        }
      }

      setIsNAUser(hasRole(AuthorityRole.ROLE_NA));
    }
  }, [data, hasRole, trackPageView]);

  return (
    <>
      {isFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        <>
          {order && (
            <>
              <section>
                {order && (
                  <OrderDetailsTableHeader
                    onNewTabTrigger={onNewTabTrigger}
                    order={order}
                    isNAUser={isNAUser}
                  />
                )}
                <OrderDetailsTable data={order} />
              </section>

              {data && data.deliveries && data.deliveries.length > 0 && (
                <section>
                  <div className="pt-5 text-2xl my-2">
                    <Trans>Shipment Detail</Trans>
                  </div>
                  <Collapse
                    className="order-history"
                    defaultActiveKey={['1']}
                    onChange={onChangeDelivery}
                    expandIcon={() =>
                      accordionClosedDelivery ? (
                        <Button theme="link" className="table-row-expand-icon">
                          <i className="fa fa-minus-circle text-xl" />
                        </Button>
                      ) : (
                        <Button theme="link" className="table-row-expand-icon">
                          <i className="fa fa-plus-circle text-xl" />
                        </Button>
                      )
                    }
                  >
                    {data.deliveries.map((delivery, idx) => {
                      return (
                        <Panel header={deliveryHeader(delivery, idx)} key="2">
                          <OrderDetailsDeliveryTable
                            data={delivery}
                            showFooter={footerForDelivery}
                          />
                        </Panel>
                      );
                    })}
                  </Collapse>
                </section>
              )}

              {data && data.histories && data.histories.length > 0 && (
                <section>
                  <div className="pt-5 text-2xl my-2">
                    <Trans>Order History</Trans>
                  </div>
                  <Collapse
                    className="order-history"
                    defaultActiveKey={['1']}
                    onChange={onChange}
                    expandIcon={() =>
                      accordionClosed ? (
                        <Button theme="link" className="table-row-expand-icon">
                          <i className="fa fa-plus-circle text-xl" />
                        </Button>
                      ) : (
                        <Button theme="link" className="table-row-expand-icon">
                          <i className="fa fa-minus-circle text-xl" />
                        </Button>
                      )
                    }
                  >
                    <Panel header=" " key="1">
                      <OrderDetailsHistoryTable
                        data={data.histories}
                        showFooter={footerForHistory}
                      />
                    </Panel>
                  </Collapse>
                </section>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrderDetails;
