import { Trans } from '@lingui/macro';
import * as React from 'react';
import ConsignmentInventorySubmittedState from '../../types/ConsignmentInventorySubmittedState.enum';
import useConsignmentInventoryStore from '../useConsignmentInventoryStore.store';

interface IConsignmentInventorySubmissionStateProps {
  rowKey: string;
}

const ConsignmentInventorySubmissionState: React.FunctionComponent<IConsignmentInventorySubmissionStateProps> = ({
  rowKey,
}) => {
  const { submittedStates } = useConsignmentInventoryStore();
  return (
    <>
      {submittedStates[rowKey] ===
        ConsignmentInventorySubmittedState.SUBMITTED && (
        <div>
          <Trans>Your submission has been received.</Trans>
        </div>
      )}
      {submittedStates[rowKey] ===
        ConsignmentInventorySubmittedState.ERRORED && (
        <div className="text-red-red">
          <Trans>An error has occurred, please try again later.</Trans>
        </div>
      )}
    </>
  );
};

export default ConsignmentInventorySubmissionState;
