import { MutationResultPair, useMutation, useQueryCache } from 'react-query';
import useSappiBasketStore from '../domain/PageHeader/SappiBasket/sappiBasket.store';
import { updateBasket } from '../services/Basket';
import { AvailabilityBasketNA } from '../types/AvailabilityBasketNA.interface';
import QueryCacheName from '../types/QueryCacheName.enum';

const useBasketMutation = (
  localLoadingOnly?: boolean
): MutationResultPair<
  AvailabilityBasketNA,
  unknown,
  AvailabilityBasketNA,
  unknown
> => {
  const queryCache = useQueryCache();

  // When this mutation succeeds, invalidate any queries with the `basket` query key
  return useMutation(updateBasket, {
    onMutate: () => {
      useSappiBasketStore.setState({
        loading: !localLoadingOnly,
        localLoadingOnly,
      });
    },
    onSuccess: () => {
      useSappiBasketStore.setState({ loading: false, localLoadingOnly: false });
      queryCache.invalidateQueries(QueryCacheName.BASKET);
    },
  });
};

export default useBasketMutation;
