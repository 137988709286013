import { t } from '@lingui/macro';
import * as React from 'react';
import LabelValue from '../../../components/LabelValue/LabelValue';
import useMeasurementFormatter from '../../../hooks/useMeasurementFormatter';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import useRole from '../../../hooks/useRole';
import { AuthorityRole } from '../../../types/Authority.interface';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import MeasurementUnit from '../../../types/MeasurementUnit.enum';
import { StockAvailabilityOverview } from '../../../types/StockAvailabilityOverview.interface';
import getSheetsAvailable from './AvailableProductCell.util';

interface IAvailableProductCellProps {
  data: StockAvailabilityOverview;
  isAltStockResult?: boolean;
}

const AvailableProductCell: React.FunctionComponent<IAvailableProductCellProps> = ({
  data,
  isAltStockResult,
}) => {
  const { format: formatNumber } = useNumberFormatter();
  const { format: formatMeasurement } = useMeasurementFormatter();
  const { hasRole } = useRole();
  const { measurementSystem } = useMeasurementSystem();
  return (
    <div className="text-lg text-gray-dark200">
      <LabelValue
        inverse
        inline
        label={t`Available`}
        value={
          <span className="text-blue-pacific font-bold">
            {data.availableQuantity} (
            {formatMeasurement(
              {
                amount: data.availableQuantityLbs,
                unit: MeasurementUnit.LB,
              },
              {
                amount: measurementSystem === MeasurementSystem.METRIC
                  ? data.availableQuantityKgs
                  : data.availableQuantityLbs,
                unit: measurementSystem === MeasurementSystem.METRIC
                  ? MeasurementUnit.KG
                  : MeasurementUnit.LB,
              }
            )}
            )
          </span>
        }
      />
      {!isAltStockResult && (
        <div className="my-4">
          {(data.unitOfMeasure.toLowerCase() === 'carton' ||
            data.unitOfMeasure.toLowerCase() === 'pallet') && (
            <LabelValue
              inverse
              inline
              label={t`Sheets available`}
              value={formatNumber(getSheetsAvailable(data))}
            />
          )}
          {data.availableQuantityLinealFt !== 0 && (
            <LabelValue
              inverse
              inline
              label={
                measurementSystem === MeasurementSystem.METRIC
                  ? t`Total Lineal Metre`
                  : t`Total Lineal Feet`
              }
              value={formatMeasurement(
                {
                  amount: data.availableQuantityLinealFt,
                  unit: MeasurementUnit.NONE,
                },
                {
                  amount: measurementSystem === MeasurementSystem.METRIC
                    ? data.availableQuantityLinealM
                    : data.availableQuantityLinealFt,
                  unit: MeasurementUnit.NONE,
                }
              )}
            />
          )}
          {data.availableQuantityMSF !== 0 && (
            <LabelValue
              inverse
              inline
              label={
                measurementSystem === MeasurementSystem.METRIC
                  ? t`Total MSM`
                  : t`Total MSF`
              }
              value={formatMeasurement(
                {
                  amount: data.availableQuantityMSF,
                  unit: MeasurementUnit.NONE,
                },
                {
                  amount: measurementSystem === MeasurementSystem.METRIC
                    ? data.availableQuantityMSM
                    : data.availableQuantityMSF,
                  unit: MeasurementUnit.NONE,
                }
              )}
            />
          )}
        </div>
      )}
      {!hasRole(AuthorityRole.ROLE_RELEASE) && (
        <div className="my-4">
          {String(data.reservedQuantity) !== '' && (
            <LabelValue
              inverse
              inline
              label={t`On hold`}
              value={ 
                measurementSystem === MeasurementSystem.METRIC 
                ? `${data.reservedQuantity} (${formatNumber(
                  data.reservedQuantityKg)} kg)`
                : `${data.reservedQuantity} (${formatNumber(
                  Math.round(data.reservedQuantityLbs))} lbs)`
              }
            />
          )}
          {String(data.replenishmentQuantity1) !== '' && (
            <LabelValue
              inverse
              inline
              label={t`Replenishment Qty`}
              value={ 
                measurementSystem === MeasurementSystem.METRIC 
                ? `${data.replenishmentQuantity1} (${formatNumber(
                  data.replenishmentQuantityKg1)} kg)`
                : `${data.replenishmentQuantity1} (${formatNumber(
                  Math.round(data.replenishmentQuantityLbs1))} lbs)`
              }
            />
          )}
          {String(data.replenishmentDate1) !== '' && (
            <LabelValue
              inverse
              inline
              label={t`Date`}
              value={data.replenishmentDate1}
            />
          )}
          {String(data.replenishmentQuantity2) !== '' && (
            <LabelValue
              inverse
              inline
              label={t`Replenishment Qty`}
              value={ 
                measurementSystem === MeasurementSystem.METRIC 
                ? `${data.replenishmentQuantity2} (${formatNumber(
                  data.replenishmentQuantityKg2)} kg)`
                : `${data.replenishmentQuantity2} (${formatNumber(
                  Math.round(data.replenishmentQuantityLbs2))} lbs)`
              }
            />
          )}
          {String(data.replenishmentDate2) !== '' && (
            <LabelValue
              inverse
              inline
              label={t`Date`}
              value={data.replenishmentDate2}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AvailableProductCell;
