import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import Date from '../../../components/Date';
import LabelValue from '../../../components/LabelValue/LabelValue';
import Table from '../../../components/Table/Table';
import useDateFormatter, { DateFormat } from '../../../hooks/useDateFormatter';
import UnitOfMeasure from '../../../types/UnitOfMeasure.enum';
import { SavedEnquiryItem } from '../types/SavedEnquiryItem.interface';
import CharacteristicsCell from './CharacteristicsCell';
import styles from './ResultTable.module.scss';
import { getUOMReadableStringUnit } from '../../../Util/UnitOfMeasure/UnitOfMeasure.util.';

interface IResultTableProps {
  createDate: number;
  createTime: number;
  items: SavedEnquiryItem[];
}

const ResultTable: React.FunctionComponent<IResultTableProps> = ({
  createDate,
  createTime,
  items,
}) => {
  const { format } = useDateFormatter();
  const columns: ColumnsType<SavedEnquiryItem> = [
    {
      title: t`Saved Date`,
      key: 'createDate',
      className: 'align-top',
      width: '15%',
      render: (value, record, index) => ({
        children: (
          <div style={{ minHeight: '180px' }}>
            <Date date={createDate} />
            <div>{format(createTime, DateFormat.BASIC_TIME)}</div>
          </div>
        ),
        props: {
          rowSpan: index === 0 ? items?.length : 0,
        },
      }),
    },
    {
      title: t`Description`,
      key: 'description',
      className: 'align-top',
      width: '20%',
      render: (value, record, index) => (
        <>
          <strong>
            {record.txtGradeBrandMember} {record.grammage}
          </strong>
          {record.productType === 'S' && (
            <div>
              (<Trans id="eu.width.abbrv">W</Trans>) {record.width}
              <Trans id="calculator.cm">cm</Trans> x (
              <Trans id="eu.length.abbrv">L</Trans>) {record.length}
              <Trans id="calculator.cm">cm</Trans>
            </div>
          )}
        </>
      ),
    },
    {
      title: t`Characteristics`,
      width: '30%',
      className: 'align-top',
      key: 'characteristics',
      render: (value, record, index) => <CharacteristicsCell record={record} />,
    },
    {
      title: t`Quantity`,
      key: 'quantity',
      width: '15%',
      className: 'align-top',
      render: (value, record, index) => (
        <>
          {record.totalQuantity}{' '}
          {getUOMReadableStringUnit(record.salesUnit as UnitOfMeasure)}
        </>
      ),
    },
    {
      title: t`Required Delivery Date`,
      width: '20%',
      className: 'align-top',
      key: 'deliveryDate',
      render: (value, record, index) => (
        <>
          <Date date={record.requestedDeliveryDate} />
          <LabelValue
            inverse
            className={
              record.confirmedDeliveryDate <= record.requestedDeliveryDate
                ? 'text-green-green'
                : 'text-orange-orange'
            }
            label={t`Confirmed Delivery Date:`}
            value={<Date date={record.confirmedDeliveryDate} />}
          />
        </>
      ),
    },
  ];
  return (
    <Table
      rowKey="id"
      className={styles.table}
      dataSource={items}
      columns={columns}
      pagination={false}
    />
  );
};

export default ResultTable;
