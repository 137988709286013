import Axios from 'axios';
import { PrintDocument } from '../../types/PrintDocument.interface';
import { DocumentPrinter } from '../../types/DocumentPrinter.interface';
import { DocumentQueryParam } from './DocumentQueryParam.interface';
import { DocumentPrinterResults } from '../../types/DocumentPrinterResults.interface';

type QueryParams = DocumentQueryParam[];

const getDocument = async (
  docNumber: string,
  docPosition: string,
  docType: string
): Promise<PrintDocument> => {
  const { data } = await Axios.post(
    `../resources/order/${docNumber}/${docPosition}/documents?documentTypes=${docType}`,
    {}
  );
  return data;
};

export const checkDocuments = async (
  docNumber: string,
  docPosition: string,
  queryParams: QueryParams
): Promise<any> => {
  const parameters = queryParams
    .map((q) => {
      return `${q.documentType}=${q.documentId}&`;
    })
    .join('');
  const { data } = await Axios.post(
    `../resources/order/${docNumber}/${docPosition}/documents?${parameters}`,
    {}
  );
  return data;
};

export const documentPrinterFromOrderTracking = async (
  customerNumber: string,
  documentNumberFrom?: string
): Promise<DocumentPrinter[]> => {
  const { data } = await Axios.get(
    `../resources/documentPrinter?customerNumber=${customerNumber}&documentNumberFrom=${documentNumberFrom}&page=1`,
    {}
  );
  return data;
};

export const searchDocumentPrinter = async (
  parameters: string
): Promise<DocumentPrinterResults> => {
  if (parameters.length < 10) {
    return {
      documentPrinters: [],
      totalPages: 0,
      totalResults: 0,
    };
  }

  const { data, headers } = await Axios.get(
    `../resources/documentPrinter?${parameters}`
  );
  return {
    documentPrinters: data,
    totalPages: headers['page-info-pages'],
    totalResults: headers['page-info-total'],
  };
};

export default getDocument;
