import { useQuery } from 'react-query';
import { getNaShipTos } from '../../../../services/Customer';
import { NaShipTo } from '../../../../types/NaShipTo.interface';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import useNaBasketStore, {
  soldToCustomerNumberSelector,
} from '../useNaBasketStore.store';

const useDeliveryLocations = (): {
  deliveryLocations: NaShipTo[];
  isFetching: boolean;
} => {
  const soldToCustomerNumber = useNaBasketStore(soldToCustomerNumberSelector);
  const { data: shipTos, isFetching } = useQuery(
    [QueryCacheName.SHIP_TO_LOCATIONS, soldToCustomerNumber],
    () => getNaShipTos(soldToCustomerNumber || ''),
    {
      enabled: soldToCustomerNumber,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return {
    deliveryLocations: shipTos || [],
    isFetching,
  };
};

export default useDeliveryLocations;
