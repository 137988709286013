import { t } from '@lingui/macro';
import React from 'react';
import { Space } from 'antd';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import { METRIC_FRACTION_COMMA_INPUT_REG_EX } from '../../../types/RegularExpression.constants';

const MIN_REQUIRED = 0;
const MAX_WIDTH_REQUIRED = 9999;
const MAX_LENGTH_REQUIRED = 999999999999;

interface IMetricAreaPerSheetProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

const MetricCriteria: React.FunctionComponent<IMetricAreaPerSheetProps> = ({
  register,
  errors,
}) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Input
        name="reelWidth"
        type="number"
        ref={register({
          required: true,
          min: MIN_REQUIRED,
          max: MAX_WIDTH_REQUIRED,
          pattern: METRIC_FRACTION_COMMA_INPUT_REG_EX,
        })}
        required
        error={errors?.reelWidth}
        placeholder={t`Roll Width (cm)`}
      />
      <Input
        name="length"
        type="number"
        ref={register({
          required: true,
          min: MIN_REQUIRED,
          max: MAX_LENGTH_REQUIRED,
          pattern: METRIC_FRACTION_COMMA_INPUT_REG_EX,
        })}
        required
        error={errors?.length}
        placeholder={t`Length (cm)`}
      />
    </Space>
  );
};

export default MetricCriteria;
