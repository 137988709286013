import { MutationResultPair, useMutation, useQueryCache } from 'react-query';
import QueryCacheName from '../types/QueryCacheName.enum';
import { saveCustomerFilter } from '../services/Filter';
import ProductQuery from '../types/ProductQuery.interface';

const useFilterMutation = (): MutationResultPair<
  ProductQuery,
  unknown,
  ProductQuery,
  unknown
> => {
  const queryCache = useQueryCache();

  // When this mutation succeeds, invalidate any queries with the `filter` query key
  return useMutation(saveCustomerFilter, {
    onSuccess: () => {
      queryCache.invalidateQueries(QueryCacheName.FILTER).then(() => {});
    },
  });
};

export default useFilterMutation;
