import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal/Modal';
import { getComplaintText } from '../../services/Complaints';

interface IDescriptionModalProps {
  complaintNumber: string;
  visible: boolean;
  onCancel: () => void;
}

const DescriptionModal: React.FunctionComponent<IDescriptionModalProps> = ({
  complaintNumber,
  visible,
  onCancel,
}) => {
  const [description, setDescription] = useState('');
  useEffect(() => {
    const getComplaintTextAsync = async () => {
      const { complaintText } = await getComplaintText(complaintNumber);
      const concatenatedComplaints = complaintText
        .map((text) => text.complaintTextLine)
        .join(' ');
      setDescription(concatenatedComplaints);
    };
    if (complaintNumber) {
      getComplaintTextAsync();
    }
  }, [complaintNumber]);
  return (
    <Modal visible={visible} title="Claim Description" onCancel={onCancel}>
      <div className="flex flex-wrap mx-4">{description}</div>
    </Modal>
  );
};

export default DescriptionModal;
