import { Action, Location } from 'history';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useBlockRouteStore from './useBlockRoute.store';

/*
 * allows blocking of naviation to a route based on the shouldContinue return value
 */
export const useBlockRoute = (
  shouldContinue: (
    location: Location<unknown>,
    action: Action
  ) => Promise<boolean>
): void => {
  const { block, location, listen, replace } = useHistory();
  const lastLocation = useBlockRouteStore((state) => state.lastLocation);
  useBlockRouteStore.setState({ lastLocation: location });

  const shouldContinueRef = useRef<
    (location: Location<unknown>, action: Action) => Promise<boolean>
  >();
  shouldContinueRef.current = shouldContinue;

  useEffect(() => {
    const blockRouteTrap = () => {
      const unblock = block((destination, action) => {
        const doBlock = async () => {
          if (shouldContinueRef.current) {
            const continueNavigation = await shouldContinueRef.current(
              destination,
              action
            );
            if (continueNavigation) {
              unblock();
              replace(`${destination.pathname}${destination.search}`);
            }
          }
        };
        doBlock();
        return false;
      });
    };

    // We use listen to create a subscription that allows it to fire on location changes regardless of useEffect dependencies
    const unlisten = listen((locationDestination: Location<unknown>) => {
      if (locationDestination === lastLocation || !shouldContinueRef.current)
        return;
      useBlockRouteStore.setState({ lastLocation: locationDestination });
      blockRouteTrap();
    });

    // used for first load of the page
    blockRouteTrap();

    // eslint-disable-next-line consistent-return
    return () => {
      unlisten();
    };
  }, [location, block, replace, lastLocation, listen]);
};

export default useBlockRoute;
