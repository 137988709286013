import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import React, { FC } from 'react';
import { FieldError, UseFormMethods } from 'react-hook-form';
import DashboardWidgetInfo from '../../Dashboard/types/DashboardWidgetInfo.interface';
import { validateMaxSelected, validateMinSelected } from '../Account.util';
import AccountFormData from '../types/AccountFormData.interface';
import FieldPrefixes from '../types/FieldPrefixes.enum';
import CustomizationArea, { CustomizationAreaProps } from './CustomizationArea';
import CustomizationCheckbox from './CustomizationCheckbox';

const MIN_SELECTED = 1;
const MAX_SELECTED = 2;

type CustomizeWidgetsProps = Omit<CustomizationAreaProps, 'heading'> & {
  formMethods: UseFormMethods<AccountFormData>;
  widgets: DashboardWidgetInfo[];
};

const CustomizeWidgets: FC<CustomizeWidgetsProps> = ({
  formMethods,
  widgets,
  ...rest
}) => {
  const { errors, getValues, register, trigger } = formMethods;

  // This type assertion is necessary due to what appears to be a bug wherein
  // TypeScript infers the type of widgetsError incorrectly as an array.
  const widgetsError = errors?.[FieldPrefixes.DASHBOARD_WIDGETS] as
    | FieldError
    | undefined;
  const widgetsErrorMessage = widgetsError?.message;

  return (
    <CustomizationArea
      heading={
        <Trans>
          Widgets{' '}
          <span className="font-normal">
            (select {MIN_SELECTED} to {MAX_SELECTED})
          </span>
        </Trans>
      }
      errorMessage={widgetsErrorMessage}
      {...{ ...rest }}
    >
      {widgets.map((widget) => (
        <CustomizationCheckbox
          key={widget.id}
          label={i18n._(widget.label)}
          name={FieldPrefixes.DASHBOARD_WIDGETS}
          value={widget.id}
          ref={register({
            validate: {
              minSelected: () =>
                validateMinSelected(
                  getValues()[FieldPrefixes.DASHBOARD_WIDGETS],
                  MIN_SELECTED
                ),
              maxSelected: () =>
                validateMaxSelected(
                  getValues()[FieldPrefixes.DASHBOARD_WIDGETS],
                  MAX_SELECTED
                ),
            },
          })}
          onChange={() => trigger(FieldPrefixes.DASHBOARD_WIDGETS)}
        />
      ))}
    </CustomizationArea>
  );
};

export default CustomizeWidgets;
