import { ThicknessConversion } from './ThicknessConversion.interface';
import ThicknessUnit from './Thickness.enum';

const CONSTANTS = {
  [ThicknessUnit.MILS]: {
    mils: 1,
    microns: 25.4,
  },
  [ThicknessUnit.MICRONS]: {
    microns: 1,
    mils: 0.0393701,
  },
};

export const convertThickness = (
  quantity: string,
  unit: ThicknessUnit
): ThicknessConversion => {
  return {
    mils: (
      Math.round(+quantity * CONSTANTS[unit].mils * 1000) / 1000
    ).toString(),
    microns: (
      Math.round(+quantity * CONSTANTS[unit].microns * 1000) / 1000
    ).toString(),
  };
};
