import Axios from 'axios';
import { Order } from '../../types/Order.interface';
import { OrderOverviewRecord } from '../../types/OrderOverviewRecord.interface';
import { PriorityFlag } from '../../types/PriorityFlag.interface';

export const getPriorityFlag = async (
  documentNumber: string,
  documentPosition: string
): Promise<PriorityFlag> => {
  const { data } = await Axios.get('/portal/resources/priorityFlag', {
    params: {
      documentNumber,
      documentPosition,
    },
  });
  return data;
};

export const getPriorityFlagById = async (
  id: number
): Promise<PriorityFlag> => {
  const { data } = await Axios.get(`/portal/resources/priorityFlag/${id}`);
  return data;
};

export const createPriorityFlag = async (
  flag: PriorityFlag
): Promise<PriorityFlag> => {
  const { data } = await Axios.post(`/portal/resources/priorityFlag`, flag);
  return data;
};

export const updatePriorityFlag = async (
  id: number,
  flag: PriorityFlag
): Promise<PriorityFlag> => {
  const { data } = await Axios.post(
    `/portal/resources/priorityFlag/${id}`,
    flag
  );
  return data;
};

export const savePriorityFlag = async (
  flag: PriorityFlag
): Promise<PriorityFlag> =>
  flag.id ? updatePriorityFlag(flag.id, flag) : createPriorityFlag(flag);

export const togglePriorityFlagFromOrder = async (
  order: Order
): Promise<OrderOverviewRecord[]> => {
  const { overviews } = order;
  const priority = overviews.some((o) => o.flag && o.flag.priority === 'HIGH')
    ? 'LOW'
    : 'HIGH';
  const updatedOverviews = overviews.map<Promise<OrderOverviewRecord>>(
    async (overview) => {
      let flag;
      if (overview.flag) {
        flag = await savePriorityFlag({
          ...overview.flag,
          priority,
        });
      } else {
        flag = await savePriorityFlag({
          documentNumber: overview.documentNumber,
          documentPosition: overview.documentPosition,
          priority,
        });
      }
      overview.flag = flag;
      return overview;
    }
  );
  return Promise.all(updatedOverviews);
};
