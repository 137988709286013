import { t } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import Option from '../../../types/Option.type';
import { OpenOrderFilters } from './OpenOrderFilters.interface';

interface IResultsSearchFormProps {
  register: UseFormMethods['register'];
  millOptions: Option[];
  productOptions: Option[];
  orderPlacedDateOptions: Option[];
  confirmedDeliveryDateOptions: Option[];
  shipToOptions: Option[];
  selectedFilters: Partial<OpenOrderFilters>;
}

const ResultsSearchForm: React.FunctionComponent<IResultsSearchFormProps> = ({
  register,
  millOptions,
  productOptions,
  orderPlacedDateOptions,
  confirmedDeliveryDateOptions,
  shipToOptions,
  selectedFilters,
}) => {
  return (
    <form>
      <Space size="large" className="flex items-baseline flex-wrap h-56">
        <InputLabel size="lg" text={t`Mill`} className="w-52">
          <Select
            name="millName"
            ref={register}
            defaultValue={selectedFilters.millName}
            width="full"
            options={millOptions}
          />
        </InputLabel>
        <InputLabel size="lg" text={t`Purchase Order #`} className="w-52">
          <Input
            name="customerOrderNumber"
            ref={register}
            width="full"
            defaultValue={selectedFilters.customerOrderNumber}
          />
        </InputLabel>
        <InputLabel size="lg" text={t`Product`} className="w-52">
          <Select
            name="paperBrand"
            ref={register}
            width="full"
            options={productOptions}
          />
        </InputLabel>
        <InputLabel size="lg" text={t`Sappi SKU`} className="w-52">
          <Input
            name="kcode"
            ref={register}
            width="full"
            defaultValue={selectedFilters.kcode}
          />
        </InputLabel>
        <InputLabel size="lg" text={t`Material #`} className="w-52">
          <Input
            name="customerMaterialNumber"
            ref={register}
            width="full"
            defaultValue={selectedFilters.customerMaterialNumber}
          />
        </InputLabel>
        <InputLabel size="lg" text={t`Order Placed Date`} className="w-52">
          <Select
            name="orderPlacedDate"
            ref={register}
            defaultValue={selectedFilters.orderPlacedDate}
            width="full"
            dateSelect
            options={orderPlacedDateOptions}
          />
        </InputLabel>
        <InputLabel
          size="lg"
          text={t`Confirmed Delivery Date`}
          className="w-64"
        >
          <Select
            name="deliveryDate"
            ref={register}
            defaultValue={selectedFilters.deliveryDate}
            width="full"
            dateSelect
            options={confirmedDeliveryDateOptions}
          />
        </InputLabel>
        <InputLabel size="lg" text={t`Ship To`} className="w-52">
          <Select
            name="shipToCustomerNumber"
            ref={register}
            defaultValue={selectedFilters.shipToCustomerNumber}
            width="full"
            options={shipToOptions}
          />
        </InputLabel>
      </Space>
    </form>
  );
};

export default ResultsSearchForm;
