import { t } from '@lingui/macro';
import { getBaseWeightOptions } from '../../../services/Brand';
import Brand from '../../../types/Brand.interface';
import Option from '../../../types/Option.type';
import useCustomerBrands from './useCustomerBrands';

const useBasisWeightOptions = (
  customer: string,
  brand?: string,
  caliper?: string,
  availFlag?: string
): {
  basisWeightOptions: Option[];
  isFetching: boolean;
} => {
  const { brands, isFetching } = useCustomerBrands(customer, availFlag);

  return {
    basisWeightOptions: getBaseWeightOptions(
      brands as Brand[],
      {
        selectedBrand: brand,
        selectedCaliper: String(caliper),
        defaultSelectLabel: t`Select`,
      },
      false
    ),
    isFetching,
  };
};

export default useBasisWeightOptions;
