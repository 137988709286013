/**
 * There are many different types of orders, represented by different putup
 * codes and putup keywords, but functionally these often require differential
 * treatment based on whether they fall into the broad categories of sheet or
 * roll, represented by this put up category enum.
 *
 * Note that this PutUpCategory corresponds to the 'productType' data key
 * from SAP.
 */
enum PutUpCategory {
  SHEET = 'S',
  ROLL = 'R',
  CUTTER = 'C',
}

export default PutUpCategory;
