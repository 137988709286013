import Axios, { CancelToken } from 'axios';
import { omitBy } from 'lodash';
import CancellablePromise from '../../types/CancellablePromise.interface';
import { OrderBookingProductQuery } from '../../types/OrderBookingProductQuery.interface';
import Product from '../../types/Product.interface';
import { ProductAvailabilityResult } from '../../types/ProductAvailabilityResult.interface';
import ProductQuery from '../../types/ProductQuery.interface';
import PutUpCategory from '../../types/PutUpCategory.enum';
import PutUpCode from '../../types/PutUpCode.enum';
import PutUpKwd from '../../types/PutUpKwd.enum';
import UnitOfMeasureOptions, { SelectOption } from '../../types/SelectOption';
import { StockAvailabilityAndProductionPlan } from '../../types/StockAvailabilityAndProductionPlan.interface';
import { StockAvailabilityResult } from '../../types/StockAvailabilityResult.interface';
import UnitOfMeasure from '../../types/UnitOfMeasure.enum';
import cancellableQuery from '../Util/cancellableQuery.util';
import { QueryPutUp } from './queryPutUp';
import MeasurementSystem from '../../types/MeasurementSystem.enum';

interface SkuCode {
  skuCode: string | undefined;
}

interface QueryParams {
  qobFlag?: 'X';
  caliperInMils?: number | string;
  gradeFamily: string | undefined;
  finish: string | undefined;
  putUps: QueryPutUp[];
  productType?: PutUpCategory;
  skuCodes: SkuCode[];
  soldTo: string | undefined;
  caliper: number | undefined;
  grammage: string | undefined;
  basisWeight: string | undefined;
  lengthMinInches: number | undefined;
  lengthMaxInches: number | undefined;
  widthMinInches: number | undefined;
  widthMaxInches: number | undefined;
  lengthMinCm: number | undefined;
  lengthMaxCm: number | undefined;
  widthMinCm: number | undefined;
  widthMaxCm: number | undefined;
  parentBrand: string | undefined;
  texture: string | undefined;
  chemistry: string | undefined;
}

/**
 * Function to get putup category by numeric putup code.
 *
 * There are many different types of orders, represented by different putup
 * codes, but functionally these often require differential treatment based on
 * whether they fall into the broad categories of sheet or roll. This function
 * maps each specific putup code to a category.
 */
export const getPutUpCategoryByCode = (putUpCode: PutUpCode): PutUpCategory => {
  // TODO: once we know the numeric code for "sheeter reel" type orders, map it to the roll category here.
  // TODO: once we know the numeric code for "skid" type order, map it to the sheet category here.
  switch (putUpCode) {
    case PutUpCode.ROLL:
      return PutUpCategory.ROLL;
    case PutUpCode.SHEET:
    case PutUpCode.CARTON:
    default:
      return PutUpCategory.SHEET;
  }
};

export const getPutUpCategoryByKwd = (putUpKwd: PutUpKwd): PutUpCategory => {
  switch (putUpKwd) {
    case PutUpKwd.ROLL:
    case PutUpKwd.SHEETER_REEL:
      return PutUpCategory.ROLL;
    case PutUpKwd.SHEET:
    case PutUpKwd.CARTON:
    case PutUpKwd.SKID:
    default:
      return PutUpCategory.SHEET;
  }
};

export const getMetricValue = (
  query: ProductQuery,
  value: number | undefined
): number | undefined => {
  return query.measurementSystem === MeasurementSystem.METRIC
    ? value
    : undefined;
};

export const getImperialValue = (
  query: ProductQuery,
  value: number | undefined
): number | undefined => {
  return query.measurementSystem === MeasurementSystem.IMPERIAL
    ? value
    : undefined;
};

/**
 * Utilities
 * @param query
 */
export const productQueryToQuery = (
  query: ProductQuery
): Partial<QueryParams> => {
  const lengthMin = query.maxLength && !query.minLength ? -1 : query.minLength;
  const lengthMax =
    query.minLength && !query.maxLength ? 9999 : query.maxLength;
  const widthMin = query.maxWidth && !query.minWidth ? -1 : query.minWidth;
  const widthMax = query.minWidth && !query.maxWidth ? 9999 : query.maxWidth;

  const putUps = query.isAvailabilityPlanning
    ? query.putUps || []
    : [
        {
          putUp: (() => {
            switch (query.type) {
              case PutUpCategory.ROLL:
                return PutUpKwd.ROLL;
              case PutUpCategory.SHEET:
                return PutUpKwd.SHEET;
              case undefined:
              default:
                return undefined;
            }
          })(),
        },
      ];
  const newQuery: QueryParams = {
    qobFlag: query.qobFlag,
    caliperInMils: query.caliperInMils,
    gradeFamily: query.brand,
    finish: query.finish,
    putUps,
    skuCodes: query.skuCodes
      ? query.skuCodes.map((code) => ({ skuCode: code }))
      : [],
    soldTo: query.customerSoldTo,
    caliper: query.caliperInMils ? undefined : query.caliper,
    grammage: query.grammage,
    basisWeight: query.basisWeight,
    lengthMinInches: getImperialValue(query, lengthMin),
    lengthMaxInches: getImperialValue(query, lengthMax),
    widthMinInches: getImperialValue(query, widthMin),
    widthMaxInches: getImperialValue(query, widthMax),
    lengthMinCm: getMetricValue(query, lengthMin),
    lengthMaxCm: getMetricValue(query, lengthMax),
    widthMinCm: getMetricValue(query, widthMin),
    widthMaxCm: getMetricValue(query, widthMax),
    parentBrand: query.parentBrand,
    texture: query.texture,
    chemistry: query.chemistry,
  };
  const getProductType = (): PutUpCategory | undefined => {
    let productType: PutUpCategory | undefined =
      query.type === PutUpCategory.ROLL
        ? PutUpCategory.ROLL
        : PutUpCategory.SHEET;
    if (query.skuCodes && query.skuCodes.length) {
      productType = undefined;
    }
    return productType;
  };
  newQuery.productType = query.isAvailabilityPlanning
    ? undefined
    : getProductType();
  return omitBy(newQuery, (value) => !value);
};

export function getUnitOfMeasuresForProductAvailability(
  type: PutUpCategory,
  isReleaseUser?: boolean
): UnitOfMeasureOptions[] {
  const reelUnits = [
    SelectOption.Select,
    UnitOfMeasure.LB,
    UnitOfMeasure.KG,
    UnitOfMeasure.RL,
    UnitOfMeasure.MSF,
    UnitOfMeasure.MSI,
    UnitOfMeasure.LF,
    UnitOfMeasure.LM,
    UnitOfMeasure.LY,
  ];
  const sheetUnits = [
    SelectOption.Select,
    UnitOfMeasure.LB,
    UnitOfMeasure.KG,
    UnitOfMeasure.CTN,
    UnitOfMeasure.SH,
    UnitOfMeasure.PL,
    UnitOfMeasure.MSF,
    UnitOfMeasure.MSI,
  ];
  const releaseUserUnits = [SelectOption.Select, UnitOfMeasure.RL];
  if (isReleaseUser) {
    return releaseUserUnits;
  }
  return type === 'R' ? reelUnits : sheetUnits;
}

export function getUnitOfMeasuresForProductType(
  type: PutUpCategory
): UnitOfMeasure[] {
  const reelUnits = [
    UnitOfMeasure.RL,
    UnitOfMeasure.KG,
    UnitOfMeasure.LB,
    UnitOfMeasure.MSF,
    UnitOfMeasure.MSI,
    UnitOfMeasure.MSM,
    UnitOfMeasure.LF,
    UnitOfMeasure.LM,
    UnitOfMeasure.LY,
  ];
  const sheetUnits = [
    UnitOfMeasure.PL,
    UnitOfMeasure.SH,
    UnitOfMeasure.CTN,
    UnitOfMeasure.KG,
    UnitOfMeasure.LB,
    UnitOfMeasure.MSF,
    UnitOfMeasure.MSI,
    UnitOfMeasure.MSM,
  ];
  return type === 'R' ? reelUnits : sheetUnits;
}

export function getUnitOfMeasuresForProductTypeAlt(
  type: PutUpCategory
): UnitOfMeasureOptions[] {
  const reelUnits = [
    SelectOption.Select,
    UnitOfMeasure.RL,
    UnitOfMeasure.KG,
    UnitOfMeasure.LB,
    UnitOfMeasure.MSF,
    UnitOfMeasure.MSI,
    UnitOfMeasure.MSM,
    UnitOfMeasure.LF,
    UnitOfMeasure.LM,
    UnitOfMeasure.LY,
  ];
  const sheetUnits = [
    SelectOption.Select,
    UnitOfMeasure.PL,
    UnitOfMeasure.SH,
    UnitOfMeasure.CTN,
    UnitOfMeasure.KG,
    UnitOfMeasure.LB,
    UnitOfMeasure.MSF,
    UnitOfMeasure.MSI,
    UnitOfMeasure.MSM,
  ];
  return type === 'R' ? reelUnits : sheetUnits;
}

/**
 * HTTP Calls
 * @param query
 */

// BASE PRODUCTS
export const queryProducts = async (
  query: ProductQuery,
  cancelToken?: CancelToken
): Promise<StockAvailabilityAndProductionPlan> => {
  const {
    data,
  } = await Axios.post(
    '/portal/resources/availability-planning/availability',
    productQueryToQuery(query),
    { cancelToken }
  );
  return data;
};

export const cancellableQueryProducts = (
  key: string,
  query: ProductQuery
): CancellablePromise<StockAvailabilityAndProductionPlan> => {
  return cancellableQuery(key, queryProducts, query);
};

// DERIVED STOCK AVAILABILITY
const queryStockAvailability = async (
  query: ProductQuery,
  cancelToken?: CancelToken
): Promise<Product[]> => {
  const { stockAvailabilityOverviews } = await queryProducts(
    query,
    cancelToken
  );
  return stockAvailabilityOverviews as Product[]; // TODO: update upon investigation if Product and StockAvailability can be interchanged
};

export const cancellableStockAvailability = (
  key: string,
  query: ProductQuery
): CancellablePromise<Product[]> => {
  return cancellableQuery(key, queryStockAvailability, query);
};

// ALTERNATE PRODUCT
export const queryAlternates = async (
  customerNumber: string,
  kcode: string,
  cancelToken?: CancelToken
): Promise<StockAvailabilityAndProductionPlan> => {
  const { data } = await Axios.get(
    `/portal/resources/availability-planning/availability/alternates/${customerNumber}/${kcode}`,
    {
      cancelToken,
    }
  );
  return data;
};

export const getOrderBookingAlternateProducts = async (
  _key: string,
  productToFindAlternatesFor: ProductAvailabilityResult[]
): Promise<ProductAvailabilityResult[]> => {
  const { data } = await Axios.post(
    '/portal/resources/eu/availability/product/alternates',
    productToFindAlternatesFor
  );
  return data;
};

export const queryOrderBookingProducts = async (
  query: OrderBookingProductQuery
): Promise<ProductAvailabilityResult[]> => {
  const { characteristics } = query;
  const { data } = await Axios.post(
    '/portal/resources/eu/availability/product',
    {
      ...query,
      characteristics: {
        ...characteristics,
        width: characteristics.width?.replace(',', '.'),
        length: characteristics.length?.replace(',', '.'),
        reelWidth: characteristics.reelWidth?.replace(',', '.'),
      },
    } as OrderBookingProductQuery
  );
  return data;
};

export const cancellableQueryOrderBookingProducts = (
  key: string,
  query: OrderBookingProductQuery
): CancellablePromise<ProductAvailabilityResult[]> => {
  return cancellableQuery(key, queryOrderBookingProducts, query);
};

export const getOrderBookingStockAlternateSizes = async (
  key: string,
  productQuery: OrderBookingProductQuery
): Promise<StockAvailabilityResult[]> => {
  const { data } = await Axios.get(`/portal/resources/eu/availability/stock`, {
    params: {
      distributionChannel: productQuery.distributionChannel,
      division: productQuery.division,
      gradeMemberBrand: productQuery?.characteristics?.gradeBrandMember,
      grammage: productQuery?.characteristics?.grammage,
      profile: productQuery.profile,
      salesOffice: productQuery.salesOffice,
      salesOrg: productQuery.salesOrg,
      shipToParty: productQuery.shipToParty,
      skuCodeSearch: productQuery.skuCodeSearch,
      soldToParty: productQuery.soldToParty,
    },
  });
  return data;
};

export const getOrderBookingStockAlternates = async (
  _key: string,
  stockAvailabilityResult: StockAvailabilityResult
): Promise<StockAvailabilityResult[]> => {
  const { data } = await Axios.post(
    '/portal/resources/eu/availability/stock/alternates',
    stockAvailabilityResult
  );
  return data;
};
