import { Trans } from '@lingui/macro';
import * as React from 'react';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { AuthorityRole } from '../../../../types/Authority.interface';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';

interface ITableDimensionsCellProps {
  item: ConsignmentInventoryItem;
}

const TableDimensionsCell: React.FunctionComponent<ITableDimensionsCellProps> = ({
  item,
}) => {
  const { isMetric } = useMeasurementSystem();
  const { parse } = useNumberFormatter();

  const isSkid = item.stacksPerPallet && parseFloat(item.stacksPerPallet) < 16;
  const isCarton = !isSkid;
  const isReamWrapped =
    item.sheetsPerReam && parseInt(item.sheetsPerReam, 10) > 0;

  const width = (
    <>
      {!isMetric && item.details[0].widthImp} {!isMetric && UnitOfMeasure.IN}
      {isMetric && item.details[0].width} {isMetric && item.details[0].widthUnit}
    </>
  );
  const length = (
    <>
      {!isMetric && item.details[0].lengthImp} {!isMetric && UnitOfMeasure.IN}
      {isMetric && item.details[0].length} {isMetric && item.details[0].lengthUnit}
    </>
  );
  const rollDiameter = (
    <>
      {!isMetric && item.details[0].reelDiameterImp} {!isMetric && UnitOfMeasure.IN}
      {isMetric && item.details[0].reelDiameter} {isMetric && item.details[0].reelDiameterUnit}
    </>
  );

  return (
    <>
      {item.productType === PutUpCategory.SHEET && (
        <>
          <div>
            <Trans>Width</Trans>: {width}
          </div>
          <div>
            <Trans>Length</Trans>: {length}
          </div>
        </>
      )}
      {item.productType === PutUpCategory.ROLL && (
        <>
          <div>{item.grainDirection}</div>
          <div>
            <Trans>Width</Trans>: {width}
          </div>
          <div>
            <Trans>Roll Diameter</Trans>: {rollDiameter}
          </div>
        </>
      )}
    </>
  );
};

export default TableDimensionsCell;
