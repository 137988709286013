import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/Button/Button';
import OrderDetailsModal from '../../../components/OrderDetailsModal/OrderDetailsModal';
import useAnalytics from '../../../hooks/useAnalytics';
import { DocumentPrinterResults } from '../../../types/DocumentPrinterResults.interface';
import { printDocs } from '../PrintDocuments.util';
import { SearchDocumentInputs } from '../SearchDocuments/SearchDocumentInputs';
import { AvailableDocumentRow } from './AvailableDocumentRow.interface';
import './AvailableDocuments.scss';
import AvailableDocumentsTable from './AvailableDocumentsTable';

interface IAvailableDocumentsProp {
  filters: SearchDocumentInputs | undefined;
  documentPrinterData: DocumentPrinterResults;
}

const AvailableDocuments: React.FunctionComponent<IAvailableDocumentsProp> = ({
  filters,
  documentPrinterData,
}) => {
  const { trackPageView } = useAnalytics();
  const [tableData, setTableData] = useState<AvailableDocumentRow[]>();

  const [openAllRows, setOpenAllRows] = useState<boolean>(false);
  const [clearButtonClicked, setClearButtonClicked] = useState<boolean>(false);
  const [expKeys, setExpKeys] = React.useState<(string | null | undefined)[]>();
  const [orderDetailsModalVisible, setOrderDetailsModalVisible] = useState<
    boolean
  >(false);
  const [orderDetailsDocNum, setOrderDetailsDocNum] = useState<string>();
  const [orderDetailsDocPos, setOrderDetailsDocPos] = useState<string>();

  useEffect(() => {
    const { documentPrinters } = documentPrinterData;

    const availableDocumentRows = documentPrinters.map(
      (availableDocumentRow, idx) => {
        const documentRow: AvailableDocumentRow = {
          ...availableDocumentRow,
          key: idx,
        };
        if (availableDocumentRow.overviews.length > 1) {
          documentRow.children = availableDocumentRow.overviews.map(
            (overview) => ({
              ...availableDocumentRow,
              key: `${overview.documentPosition}-${idx}`,
              overviews: [overview],
              isChild: true,
            })
          );
        }
        return documentRow;
      }
    );

    availableDocumentRows.map((row) => {
      row.overviews[0].printCoaDoc = false;
      row.overviews[0].printBarCodeWeightListDoc = false;
      row.overviews[0].printConfirmationDoc = false;
      row.overviews[0].printCreditNoteDoc = false;
      row.overviews[0].printDeliveryDoc = false;
      row.overviews[0].printInvoiceDoc = false;
      row.overviews[0].printWeightListDoc = false;
      return row;
    });

    setTableData(availableDocumentRows);
  }, [documentPrinterData]);

  const showOrderDetailsModal = (record: AvailableDocumentRow): void => {
    const documentNumber =
      record?.documentNumber || record?.overviews?.[0]?.documentNumber || null;
    const documentPosition = record?.overviews?.[0]?.documentPosition || null;

    if (documentNumber && documentPosition) {
      setOrderDetailsDocNum(documentNumber);
      setOrderDetailsDocPos(documentPosition);
    }
    
    trackPageView('PRINT_DOCUMENTS', 'ORDER_DETAILS');

    setOrderDetailsModalVisible(true);
  };

  const closeOrderDetailsModal = () => {
    setOrderDetailsModalVisible(false);
  };

  return (
    <div className="available-documents">
      <div
        className={`mt-5 ${tableData && tableData?.length > 0 ? '' : 'mb-5'}`}
      >
        <Button
          type="submit"
          theme="primary"
          onClick={() => {
            if (
              documentPrinterData &&
              documentPrinterData.documentPrinters[0]
            ) {
              trackPageView('PRINT_DOCUMENTS', 'CREATE_PDF', {
                customerId:
                  documentPrinterData?.documentPrinters[0].customerNumber,
              });
            }
            printDocs(tableData, documentPrinterData);
          }}
        >
          <Trans>Create PDF</Trans>
        </Button>
        <span className="px-2" />
        <Button
          type="reset"
          theme="round"
          className="reset-button"
          onClick={() => {
            trackPageView('PRINT_DOCUMENTS', 'CLEAR_SELECTED', {
              customerId:
                documentPrinterData?.documentPrinters[0].customerNumber,
            });
            setClearButtonClicked(!clearButtonClicked);
          }}
        >
          <Trans>Clear Selections</Trans>
        </Button>
        <span className="px-2" />
        <Button
          type="reset"
          theme="link"
          className=" pt-3 font-bold"
          onClick={() => {
            setOpenAllRows(!openAllRows);

            if (openAllRows) {
              trackPageView('PRINT_DOCUMENTS', 'COLLAPSE_ALL', {
                customerId:
                  documentPrinterData?.documentPrinters[0].customerNumber,
              });
              setExpKeys([]);
            } else {
              trackPageView('PRINT_DOCUMENTS', 'EXPAND_ALL', {
                customerId:
                  documentPrinterData?.documentPrinters[0].customerNumber,
              });
              const keys: React.SetStateAction<
                (string | null | undefined)[] | undefined
              > = [];

              (tableData as AvailableDocumentRow[]).forEach((i) => {
                keys.push(
                  i.isChild
                    ? i.overviews[0].documentNumber +
                        i.overviews[0].weightListNumber
                    : i.overviews[0].documentNumber
                );
              });

              setExpKeys(keys);
            }
          }}
        >
          {openAllRows && <Trans>Collapse All</Trans>}
          {!openAllRows && <Trans>Expand All</Trans>}
        </Button>
      </div>
      <AvailableDocumentsTable
        dataSource={tableData}
        clearSelected={clearButtonClicked}
        currentPage={documentPrinterData.page}
        totalResults={documentPrinterData?.totalResults}
        expKeys={expKeys}
        setExpKeys={setExpKeys}
        filters={filters}
        setCurrentPage={(page) => {
          documentPrinterData.page = page;
        }}
        updateDataSource={(dataSource) => {
          setTableData(dataSource);
        }}
        showOrderDetails={showOrderDetailsModal}
      />
      <div className="mb-5 mt-5 text-right">
        <Button
          type="submit"
          theme="primary"
          onClick={() => {
            if (
              documentPrinterData &&
              documentPrinterData.documentPrinters[0]
            ) {
              trackPageView('PRINT_DOCUMENTS', 'CREATE_PDF', {
                customerId:
                  documentPrinterData?.documentPrinters[0].customerNumber,
              });
            }
            printDocs(tableData, documentPrinterData);
          }}
        >
          <Trans>Create PDF</Trans>
        </Button>
        <span className="px-2" />
        <Button
          type="reset"
          theme="round"
          className="reset-button"
          onClick={() => {
            trackPageView('PRINT_DOCUMENTS', 'CLEAR_SELECTED', {
              customerId:
                documentPrinterData?.documentPrinters[0].customerNumber,
            });
            setClearButtonClicked(!clearButtonClicked);
          }}
        >
          <Trans>Clear Selections</Trans>
        </Button>
      </div>
      <OrderDetailsModal
        visible={orderDetailsModalVisible}
        onClose={closeOrderDetailsModal}
        documentNumber={orderDetailsDocNum}
        documentPosition={orderDetailsDocPos}
      />
    </div>
  );
};

export default AvailableDocuments;
