import * as React from 'react';

interface IResultAlertProps {
  alertText: string;
}

const ResultAlert: React.FunctionComponent<IResultAlertProps> = ({
  alertText,
}) => {
  return (
    <div className="text-center text-lg bg-yellow-yellow p-2 pt-3 border-gray-light400 border-b">
      <div>
        <i className="fa fa-exclamation-circle text-3xl mr-3 relative top-0.5" />
        {alertText}
      </div>
    </div>
  );
};

export default ResultAlert;
