import React from 'react';
import Checkbox, { CheckboxProps } from '../../../components/Checkbox/Checkbox';

type CustomizationCheckboxProps = Omit<CheckboxProps, 'ref'>;

const CustomizationCheckbox = React.forwardRef<
  HTMLInputElement,
  CustomizationCheckboxProps
>((props, ref) => {
  return (
    <div className="flex-grow-0 flex-shrink-0 w-1/2 mb-6 text-lg">
      <Checkbox ref={ref} {...props} />
    </div>
  );
});

export default CustomizationCheckbox;
