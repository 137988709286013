import useOrderBookingBaskets from './useOrderBookingBaskets';
import useStockLotBasket from './useStockLotBasket';

const useEuBasketNumItems = (): { numBasketItems: number } => {
  const { data: orderBookingBaskets } = useOrderBookingBaskets();
  const { data: stockLotBaskets } = useStockLotBasket();
  const numStockLotItems =
    stockLotBaskets?.reduce((acc, next) => acc + next.items.length, 0) || 0;
  const numOrderBookingBasketItems =
    orderBookingBaskets?.reduce((acc, next) => acc + next.items.length, 0) || 0;
  return {
    numBasketItems: numStockLotItems + numOrderBookingBasketItems,
  };
};

export default useEuBasketNumItems;
