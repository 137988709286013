import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useOrderBookingBasket from '../../../hooks/useOrderBookingBasket';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import { Button } from '../../../components/Button/Button';
import useAnalytics from '../../../hooks/useAnalytics';

const EuBasketOrderSubmitted: FC = () => {
  const history = useHistory();
  const { trackPageView } = useAnalytics();
  const { basketId } = useParams<{ basketId: string }>();

  const { data: basket, isFetching } = useOrderBookingBasket(basketId);

  const continueShopping = () => {
    trackPageView('BASKET_SUBMIT', 'CONTINUE_SHOPPING', {
      customerId: basket?.soldTo.number,
    });
    history.push('/orderBooking');
  };

  const hasCreditBlock = basket?.creditBlock === 'X';

  return (
    <div>
      {isFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        <>
          {basket && (
            <>
              <div>
                {hasCreditBlock ? (
                  <Trans>
                    Your order has been booked, but exceeds the credit limit.
                    Therefore we will not process the order until further
                    payment.
                  </Trans>
                ) : (
                  <>
                    <Trans>
                      Your order has been booked successfully and the order
                      confirmation will be sent to the following e-mail address
                    </Trans>
                    : <span className="font-bold">{basket?.email || ''}</span>
                  </>
                )}
              </div>
              <div className="p-3 text-2xl">
                {basket?.purchaseOrder ? (
                  <Trans>
                    Your PO # is{' '}
                    <span className="font-bold">{basket.purchaseOrder}</span>{' '}
                    and the Sappi reference # is{' '}
                    <span className="font-bold">{basket.enquiryNumber}</span>.
                  </Trans>
                ) : (
                  <Trans>
                    Your order reference # is{' '}
                    <span className="font-bold">{basket.enquiryNumber}</span>.
                  </Trans>
                )}
              </div>
            </>
          )}
          <div>
            <Button onClick={continueShopping} theme="primary">
              <Trans>Continue Shopping</Trans>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EuBasketOrderSubmitted;
