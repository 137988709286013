import { t } from '@lingui/macro';
import { getBrandOptions } from '../../../services/Brand';
import Brand from '../../../types/Brand.interface';
import Option from '../../../types/Option.type';
import useCustomerBrands from './useCustomerBrands';

const useBrandOptions = (
  customer: string,
  grammage?: string,
  caliper?: string,
  isMetric?: boolean,
  availFlag?: string,
): {
  brandOptions: Option[];
  isFetching: boolean;
} => {
  const { brands, isFetching } = useCustomerBrands(customer, availFlag);
  
  return {
      brandOptions: 
        isMetric 
        ? getBrandOptions(brands as Brand[], {
            selectedCaliperMils: String(caliper),
            selectedGrammage: grammage,
            defaultSelectLabel: t`Select`,
          })
        : getBrandOptions(brands as Brand[], {
            selectedCaliper: String(caliper),
            selectedGrammage: grammage,
            defaultSelectLabel: t`Select`,
          }),
      isFetching,
    };
};

export default useBrandOptions;
