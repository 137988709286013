import * as React from 'react';
import { Button } from '../../../components/Button/Button';
import { DocumentExportReport } from '../../../types/DocumentExportReport.interface';
import ExcelPopoverRow from './ExcelPopoverRow';

interface IExcelPopoverProps {
  title: string | React.ReactNode;
  reportsForDownload: DocumentExportReport[];
  onDownload: () => void;
  onClose: () => void;
}

const ExcelPopover: React.FunctionComponent<IExcelPopoverProps> = ({
  title,
  reportsForDownload,
  onDownload,
  onClose,
}) => {
  return (
    <div className="p-3 w-64">
      <h5 className=" bg-gray-dark100 text-white-white p-3 text-lg flex justify-between font-medium">
        {title}
        <Button
          theme="link"
          className="text-white-white"
          onClick={() => onClose()}
        >
          <i className="fa fa-close" />
        </Button>
      </h5>
      <div className="py-3 px-2">
        {reportsForDownload.map((report) => (
          <ExcelPopoverRow
            key={report.id}
            report={report}
            onDownload={onDownload}
          />
        ))}
      </div>
    </div>
  );
};

export default ExcelPopover;
