import { t, Trans } from '@lingui/macro';
import { Collapse, Space } from 'antd';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import ExpandButton from '../../../components/ExpandButton/ExpandButton';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import { EMPTY_OPTION } from '../../../services/Util/emptyOption.const';
import fractionOrDecimalInput from '../../../services/Util/fractionOrDecimalInput.util';
import Option from '../../../types/Option.type';

const { Panel } = Collapse;

const WRAPPING_TYPE_OPTIONS: Option[] = [
  EMPTY_OPTION,
  {
    label: t`Bulk Packed Non Tabbed`,
    value: '01',
  },
  {
    label: t`Bulk Packed Tabbed`,
    value: '00',
  },
];
const PALLET_TYPE_OPTIONS: Option[] = [
  EMPTY_OPTION,
  {
    label: t`Standard Pallet`,
    value: '01',
  },
  {
    label: t`Twin Pallet`,
    value: '03',
  },
];
const CERTIFICATION_OPTIONS: Option[] = [
  EMPTY_OPTION,
  {
    label: 'FSC',
    value: 'FSC',
  },
  {
    label: 'PEFC',
    value: 'PEFC',
  },
];

interface IOtherOptionsProps {
  register: UseFormMethods['register'];
}

const OtherOptions: React.FunctionComponent<IOtherOptionsProps> = ({
  register,
}) => {
  return (
    <Collapse
      ghost
      expandIcon={({ isActive }) => (
        <ExpandButton
          isExpanded={isActive || false}
          style={{ left: '-60px', position: 'relative', margin: '-20px 0' }}
        >
          <Trans>Other Options</Trans>
        </ExpandButton>
      )}
    >
      <Panel key="otherOptions" header="">
        <Space size="large" direction="vertical" className="w-full">
          <InputLabel text={t`SH/Ream`} size="lg">
            <Input
              name="sheetsPerReam"
              ref={register()}
              onKeyPress={fractionOrDecimalInput}
              width="full"
            />
          </InputLabel>
          <InputLabel text={t`Max Height/Pal (cm)`} size="lg">
            <Input
              name="heightPerPallet"
              ref={register()}
              onKeyPress={fractionOrDecimalInput}
              width="full"
            />
          </InputLabel>
          <InputLabel text={t`Max Weight/Pal (kg)`} size="lg">
            <Input
              name="weightPerPallet"
              ref={register()}
              onKeyPress={fractionOrDecimalInput}
              width="full"
            />
          </InputLabel>
          <InputLabel text={t`Wrapping Type`} size="lg">
            <Select
              name="wrappingType"
              ref={register()}
              options={WRAPPING_TYPE_OPTIONS}
              width="full"
            />
          </InputLabel>
          <InputLabel text={t`Pallet Type`} size="lg">
            <Select
              name="packageType"
              ref={register()}
              options={PALLET_TYPE_OPTIONS}
              width="full"
            />
          </InputLabel>
          <InputLabel text={t`Certification`} size="lg">
            <Select
              name="paperType"
              ref={register()}
              options={CERTIFICATION_OPTIONS}
              width="full"
            />
          </InputLabel>
        </Space>
      </Panel>
    </Collapse>
  );
};

export default OtherOptions;
