import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useRole from './useRole';
import useUser from './useUser';

const useLoggedInUserCheck = (): void => {
  const { data: user } = useUser();
  const { isTrackAndTraceUser } = useRole();
  const history = useHistory();

  const goHome = useCallback(() => {
    if (user && isTrackAndTraceUser) {
      history.push('/trackAndTrace');
    } else {
      history.push('/home');
    }
  }, [history, isTrackAndTraceUser, user]);

  useEffect(() => {
    if (user) {
      goHome();
    }
  }, [user, goHome]);
};

export default useLoggedInUserCheck;
