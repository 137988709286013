import * as React from 'react';

const DeconstructedTableBodyRow: React.FunctionComponent<React.HTMLProps<
  HTMLTableRowElement
>> = ({ className, children, ...rest }) => {
  const baseClass = 'ant-table-row';
  return (
    <tr className={`${baseClass} ${className || ''}`} {...rest}>
      {children}
    </tr>
  );
};

export default DeconstructedTableBodyRow;
