import Axios from 'axios';
import StockLotGrade from '../types/StockLotGrade.interface';
import StockLotGrammage from '../types/StockLotGrammage.interface';
import { StockLotRequestParams } from '../types/StockLotRequestParams.interface';
import { StockLotRequest } from '../types/StockLotRequest.interface';
import { FilterFormData } from '../types/FilterFormData.interface';
import { StockLotResponse } from '../types/StockLotResponse.interface';
import { PageInfo } from '../../../types/PageInfo.interface';
import parsePageInfoString from '../../../services/Util/parsePageInfoString.util';

export const getGrades = async (
  params: Pick<
    StockLotRequestParams,
    'customerNumber' | 'productType' | 'division'
  >
): Promise<StockLotGrade[]> => {
  const { data } = await Axios.get(
    `/portal/resources/stocklot/brands/${params.productType}/${params.customerNumber}/${params.division}`
  );
  return data;
};

export const getGrammages = async (
  params: StockLotRequestParams
): Promise<StockLotGrammage[]> => {
  const {
    data,
  } = await Axios.get(
    `/portal/resources/stocklot/brands/${params.productType}/${params.customerNumber}/${params.division}/grammages`,
    { params: { gradeDescription: params.gradeDescription } }
  );
  return data;
};

export const getStockLot = async (
  params: StockLotRequest
): Promise<StockLotResponse> => {
  const { data, headers } = await Axios.get(`/portal/resources/stocklot`, {
    params,
  });
  const pagination: Partial<PageInfo> = {
    page: parsePageInfoString(headers['page-info-page']),
    pageCount: parsePageInfoString(headers['page-info-pages']),
    recordCount: parsePageInfoString(headers['page-info-total']),
  };
  return {
    batches: data,
    pagination,
  };
};

export const getFieldValues = async (
  params: FilterFormData
): Promise<string[]> => {
  const { data } = await Axios.get(`/portal/resources/stocklot/fieldValues`, {
    params,
  });
  return data;
};
