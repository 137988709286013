import { Trans } from '@lingui/macro';
import Moment from 'moment';
import React from 'react';
import Date from '../../../../components/Date';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import useRole from '../../../../hooks/useRole';
import { AuthorityRole } from '../../../../types/Authority.interface';
import { DeliveryResponse } from '../../../../types/DeliveryResponse.interface';
import '../OrderDetailsTable.scss';
import stripZeros from '../../../../services/Util/stripZeros.util';

interface IOrderDetailsTableProps {
  data: DeliveryResponse;
  showFooter: boolean;
}

const OrderDetailsDeliveryTable: React.FunctionComponent<IOrderDetailsTableProps> = ({
  data,
  showFooter,
}) => {
  const { hasRole } = useRole();
  const { formatWithUnit } = useNumberFormatter();

  const deliveryDetails = (record: DeliveryResponse) => {
    return (
      <div className="flex">
        <div className="w-1/2 pr-3 py-5">
          <div className="py-5">
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="orderDetails.requestedDelivery">
                  Requested Delivery Date
                </Trans>
              </div>
              <div className="w-1/2 font-bold">
                <Date date={record.details.requestedDeliveryDate} />
              </div>
            </div>
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="delivery.shipmentDate">Shipment Date</Trans>
              </div>
              <div className="w-1/2 font-bold">
                <Date date={record.details.shipDate} />
              </div>
            </div>
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="delivery.estArrivalDate">Est. Arrival Date</Trans>
              </div>
              <div className="w-1/2 font-bold">
                <Date date={record.details.arrivalDate} />
              </div>
            </div>
            {hasRole(AuthorityRole.ROLE_EU) && record.details.reasonText && (
              <div className="flex mb-2.5">
                <div className="w-1/2 pr-1 text-right label-colon">
                  <Trans id="delivery.deviationReason">Deviation Reason</Trans>
                </div>
                <div className="w-1/2 font-bold">
                  <Trans id="delivery.deviationReason">Deviation Reason</Trans>-{' '}
                  <Date date={record.details.arrivalDate} />
                  {record.details.arrivalTime && (
                    <span>
                      {Moment.utc(record.details.arrivalTime).format('HH:mm')}
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="pb-5">
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="delivery.status">Status</Trans>
              </div>
              {record.latestEvent && record.latestEvent.status && (
                <div className="w-1/2 font-bold capitalize">
                  {record.latestEvent.status.toLocaleLowerCase()}
                </div>
              )}
            </div>
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="delivery.trackLocation">Location</Trans>
              </div>
              {record.latestEvent && record.latestEvent.status && (
                <div className="w-1/2 font-bold">
                  {record.latestEvent.location}
                </div>
              )}
            </div>
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="delivery.trackDate">Date</Trans>
              </div>
              {record.latestEvent &&
                record.latestEvent.messageDate &&
                record.latestEvent.messageTime && (
                  <div className="w-1/2 font-bold">
                    <Date date={record.latestEvent.messageDate} />{' '}
                    {Moment.utc(record.latestEvent.messageTime).format('HH:mm')}
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="w-1/2 pl-3 py-5">
          <div className="py-5">
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans>Bill of Lading #</Trans>
              </div>
              <div className="w-1/2 font-bold">
                {record.details.deliveryNote}
              </div>
            </div>
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="delivery.freight">Freight #</Trans>
              </div>
              <div className="w-1/2 font-bold">{stripZeros(record.transitId)} </div>
            </div>
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="delivery.weightlist">Weightlist #</Trans>
              </div>
              <div className="w-1/2 font-bold">
                {stripZeros(record.details.weightListNumber)}
              </div>
            </div>
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="delivery.quantity">Gross Quantity</Trans>
              </div>
              <div className="w-1/2 font-bold">
                {formatWithUnit(record.details.quantity)}
              </div>
            </div>
            {record.details.sheetCount > 0 && (
              <div className="flex mb-2.5">
                <div className="w-1/2 pr-1 text-right label-colon">
                  <Trans id="delivery.details.sheetCount">Sheet Count</Trans>
                </div>
                <div className="w-1/2 font-bold">{record.details.sheetCount}</div>
              </div>
            )}
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans>Container/Trailer #</Trans>
              </div>
              <div className="w-1/2 font-bold">
                {record.details.payloadInfo}
              </div>
            </div>
            <div className="flex mb-2.5">
              <div className="w-1/2 pr-1 text-right label-colon">
                <Trans id="delivery.forwarder">Forwarding Agent</Trans>
              </div>
              <div className="w-1/2 font-bold">
                {record.details.forwardingAgent}
              </div>
            </div>
            {record.details.plTypeDesc && (
              <div className="flex mb-2.5">
                <div className="w-1/2 pr-1 text-right label-colon">
                  <Trans id="delivery.plTypeDesc">Mode of Transport</Trans>
                </div>
                <div className="w-1/2 font-bold">{record.details.plTypeDesc}</div>
              </div>
            )}
            {hasRole(AuthorityRole.ROLE_NA) && record.details.consigneeName && (
              <div className="flex mb-2.5">
                <div className="w-1/2 pr-1 text-right label-colon">
                  <Trans id="delivery.consignedTo">Reconsigned To</Trans>
                </div>
                <div className="w-1/2 font-bold">
                  {record.details.consigneeName}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <dl>{deliveryDetails(data)}</dl>
    </>
  );
};

export default OrderDetailsDeliveryTable;
