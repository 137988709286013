import { Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { FC } from 'react';
import { DEFAULT_EU_FORMAT, DEFAULT_NA_FORMAT } from '../../components/DatePicker/DatePicker';
import Table from '../../components/Table/Table';
import styles from './PoilcyTable.module.scss';
import DownloadDocsContent from './types/DownloadDocsContent.interface';
import File from './types/File.interface';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';

interface IPackingListReportingTableProps {
  content: DownloadDocsContent | undefined;
}

const PoliciesTable: FC<IPackingListReportingTableProps> = ({ content }) => {
  const getRowKey = (record: File): string => {
    return record.filedate + record.fileDesc + record.filename;
  };
  
  const { hasRole } = useRole();

  const columns: ColumnsType<File> = [
    {
      title: (
        <div className="text-center">
          <Trans>Document</Trans>
        </div>
      ),
      key: 'document',
      render: (value, record) => (
        <>
          {record.url ? (
            <a
              className="app-link"
              href={`${record.url}`}
              target="_blank"
              rel="noreferrer"
            >
              {record.filename || 'null'}
            </a>
          ) : (
            <div>{record.filename}</div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="text-center">
          <Trans>Description</Trans>
        </div>
      ),
      key: 'description',
      render: (value, record) => (
        <div>{String(record.fileDesc).replace('&amp;', '&') || 'null'}</div>
      ),
    },
    {
      title: (
        <div className="text-center">
          <Trans>Updated</Trans>
        </div>
      ),
      key: 'updated',
      render: (value, record) => (
        <>
          {record.filedate ? (
            <div>{hasRole(AuthorityRole.ROLE_EU) ? moment(record?.filedate).format(DEFAULT_EU_FORMAT) : moment(record?.filedate).format(DEFAULT_NA_FORMAT)}</div>
          ) : (
            <div />
          )}
        </>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      className={styles.table}
      rowKey={(record) => getRowKey(record)}
      dataSource={content?.files}
      pagination={false}
    />
  );
};

export default PoliciesTable;
