import { Trans } from '@lingui/macro';
import * as React from 'react';
import AccountActivity from './AccountActivity';
import style from './FinancialReportingMain.module.scss';

interface IFinancialReportingMainProps {
  customerNumber?: string;
}

const FinancialReportingMain: React.FunctionComponent<IFinancialReportingMainProps> = ({
  customerNumber,
}) => {
  if (!customerNumber) {
    return (
      <div className={`${style.container} relative bg-cover`}>
        <div
          className={`${style.overlay} absolute bottom-9 left-9 pt-6 pr-36 pb-24 pl-6 text-white-white bg-blue-malibu`}
        >
          <div className="mb-6 text-3xl">
            <Trans>Financial Reporting</Trans>
          </div>
          <div className="text-xl">
            <p>
              <Trans>
                Credit line detail, payment due dates, and invoice activity
                captured here for easy reference.
              </Trans>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return <AccountActivity customerNumber={customerNumber} />;
};

export default FinancialReportingMain;
