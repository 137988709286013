import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { Button } from '../../../../components/Button/Button';
import useAnalytics from '../../../../hooks/useAnalytics';
import useContinueShopping from '../useContinueShopping';
import useNaBasketStore from '../useNaBasketStore.store';

const OrderSubmitted: FC = () => {
  const { currentBasketSubmissionCandidate: basket } = useNaBasketStore();
  const { continueShopping } = useContinueShopping();
  const { trackPageView } = useAnalytics();

  const doContinueShopping = () => {
    trackPageView('BASKET_SUBMIT', 'CONTINUE_SHOPPING', {
      customerId: '',
    });
    continueShopping();
  };

  const print = () => {
    trackPageView('PRODUCT_BASKET', 'PRINT');
    window.print();
  };
  return (
    <Space size="large" direction="vertical">
      <div className="text-lg w-3/4">
        <Trans>
          Your purchase order for <strong>{basket?.soldToCustomer.name}</strong>{' '}
          has been submitted successfully. Your order reference number is{' '}
          <strong>{basket?.soldToPO}</strong>. Once your order has been
          processed and confirmed, an order confirmation will be sent, and you
          will be able to track your order via the Track Orders function. If you
          need assistance or have any questions, please contact your dedicated
          Sappi Inside Sales representative. Thank you!
        </Trans>
      </div>
      <Space size="middle">
        <Button theme="primary" onClick={print}>
          <Trans>Print This Page</Trans>
        </Button>
        <Button theme="primary" onClick={doContinueShopping}>
          <Trans>Continue Shopping</Trans>
        </Button>
      </Space>
    </Space>
  );
};

export default OrderSubmitted;
