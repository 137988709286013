import { QueryResult, useQuery } from 'react-query';
import { getOrderBookingBasketById } from '../services/Basket';
import AvailabilityBasketEU from '../types/AvailabilityBasketEU.interface';
import QueryCacheName from '../types/QueryCacheName.enum';

const useOrderBookingBasket = (
  basketId: string
): QueryResult<AvailabilityBasketEU> => {
  return useQuery(
    [QueryCacheName.BASKET_ORDER_BOOKING, basketId],
    () => getOrderBookingBasketById(basketId),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};

export default useOrderBookingBasket;
