import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePaginatedQuery, useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import useSamplesOrder from '../../../hooks/useSamplesOrder';
import { getPartnerSearchCustomerByNumber } from '../../../services/Customer';
import { updateSamplesOrder } from '../../../services/SamplesOrder';
import { PartnerSearchCustomer } from '../../../types/PartnerSearchCustomer.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import BasketButtons from './BasketButtons';
import ItemsTable from './ItemsTable/ItemsTable';
import ShippingInstructions from './ShippingInstructions/ShippingInstructions';

const Basket: React.FunctionComponent = () => {
  const { data: order, isFetching: isFetchingOrder } = useSamplesOrder();
  const methods = useForm({
    mode: 'all',
  });
  const { getValues, handleSubmit, reset } = methods;
  const queryCache = useQueryCache();
  const history = useHistory();
  const [updating, setUpdating] = useState(false);

  const {
    resolvedData: buyer,
    isFetching: isFetchingBuyer,
  } = usePaginatedQuery<PartnerSearchCustomer>(
    [QueryCacheName.PARTNER_SEARCH_CUSTOMER, order?.buyerNumber],
    getPartnerSearchCustomerByNumber,
    {
      staleTime: Infinity,
      enabled: order?.buyerNumber,
      keepPreviousData: true,
    }
  );

  const updateOrder = async () => {
    if (order) {
      const form = getValues();
      setUpdating(true);
      await updateSamplesOrder({
        ...order,
        customShipping: form.customCourier,
        courierAccountNumber: form.courierAccount,
        shippingAccountNumber: form.customCourierSelect,
        shippingType: form.expressShipping,
        deliveryComment: form.comment,
        products: map(order.products, (product, index) => ({
          ...product,
          quantity: form.quantity[index],
          largeQuantity: form.largeQuantityReason
            ? form.largeQuantityReason[index]
            : null,
          customBrand: form.customBrand ? form.customBrand[index] : null,
        })),
      });
      queryCache.invalidateQueries(
        QueryCacheName.SAMPLES_ORDER_DELIVERY_COST_DETAILS
      );
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDER);
      queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDERS_HISTORY);
      setUpdating(false);
      reset(getValues());
    }
  };

  useEffect(() => {
    if (!isFetchingOrder && !order?.buyerNumber) {
      console.info('No order or buyer number');
      history.push('/samples/selectBuyer');
    }
  }, [order, history, isFetchingOrder]);

  return (
    <div className="mb-8">
      <FormProvider {...methods}>
        <ShippingInstructions
          order={order}
          buyer={buyer}
          isFetchingBuyer={isFetchingBuyer}
        />
        <div className="mb-8 mt-3">
          <ItemsTable
            items={order?.products || []}
            updating={updating}
            buyerName={buyer?.name}
          />
        </div>
        <BasketButtons
          onUpdateOrder={handleSubmit(updateOrder)}
          updating={updating}
        />
      </FormProvider>
    </div>
  );
};

export default Basket;
