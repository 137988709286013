/**
 * Corresponds to 'putUp' data key returned from SAP.
 */
enum PutUpKwd {
  SHEET = 'SHEET',
  ROLL = 'ROLL',
  SHEETER_REEL = 'SHEETER_RL',
  CARTON = 'CARTON',
  SKID = 'SKID',
}

export default PutUpKwd;
