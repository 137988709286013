import Axios from 'axios';
import { t } from '@lingui/macro';
import Option from '../../types/Option.type';
import NonPaperValue from '../../types/NonPaperValue.interface';
import ComplaintsBatches from '../../types/ComplaintsBatches.interface';
import { getLocale } from '../Properties';

export const getNPValOptions = (npVals?: NonPaperValue[]): Option[] => {
  const options: Option[] = [
    {
      value: '',
      label: t`Select`,
    },
  ];
  if (npVals) {
    npVals.forEach((value) => {
      const p1 = value.objectDescription;
      const p2 = value.uom;
      options.push({
        value: value.fieldName,
        label: `${p1} (${p2})`,
      });
    });
  }
  return options;
};

export const getComplaintsBatches = async (
  _: string,
  documentNumber: string,
  documentPosition: string
): Promise<ComplaintsBatches> => {
  const locale = await getLocale();
  const { data } = await Axios.get(
    `/portal/resources/batch/${documentNumber}/${documentPosition}/${locale}`
  );
  return data;
};
