import { t } from '@lingui/macro';
import React, { FC } from 'react';
import AvailabilityBasketEU from '../../../../types/AvailabilityBasketEU.interface';
import BasketSummaryColumn from '../BasketSummaryColumn';
import CustomerAddress from './CustomerAddress';

interface IBasketInformationProps {
  basket: AvailabilityBasketEU;
}

const BasketInformation: FC<IBasketInformationProps> = ({ basket }) => {
  return (
    <div className="flex">
      <BasketSummaryColumn title={t`Customer`} className="w-1/2">
        <CustomerAddress customer={basket.soldTo} />
      </BasketSummaryColumn>
      <BasketSummaryColumn title={t`Delivery Location`} className="w-1/2">
        <CustomerAddress customer={basket.shipTo} />
      </BasketSummaryColumn>
    </div>
  );
};

export default BasketInformation;
