import { some } from 'lodash';
import { useMemo } from 'react';
import Authority, { AuthorityRole } from '../types/Authority.interface';
import useUser from './useUser';

const useRole = (): {
  roles: Authority[] | undefined;
  hasRole: (role: AuthorityRole) => boolean;
  // TODO: refactor other code which is independently deriving these same
  // booleans (especially isNaUser, isEuUser, and isNaOrReleaseUser, which were
  // added to this hook fairly late) to just use the booleans returned from this
  // hook instead.
  isNaUser: boolean;
  isEuUser: boolean;
  isNaOrReleaseUser: boolean;
  isNonReleaseNaUser: boolean;
  isNonReleaseEuUser: boolean;
  isReleaseEuUser: boolean;
  isReleaseUser: boolean;
  isInternalUser: boolean;
  isTrackAndTraceUser: boolean;
  isFetching: boolean;
  isComplaintCreator: boolean;
} => {
  const { data: user, isFetching } = useUser();
  const hasRole = useMemo(
    () => (role: AuthorityRole) =>
      user ? some(user.authorities, { authority: role }) : false,
    [user]
  );
  const isNaUser = hasRole(AuthorityRole.ROLE_NA);
  const isEuUser = hasRole(AuthorityRole.ROLE_EU);
  const isNaOrReleaseUser =
    hasRole(AuthorityRole.ROLE_NA) || hasRole(AuthorityRole.ROLE_RELEASE);
  const isNonReleaseNaUser =
    hasRole(AuthorityRole.ROLE_NA) && !hasRole(AuthorityRole.ROLE_RELEASE);
  const isNonReleaseEuUser =
    hasRole(AuthorityRole.ROLE_EU) && !hasRole(AuthorityRole.ROLE_RELEASE);
  const isReleaseEuUser =
    hasRole(AuthorityRole.ROLE_EU) && hasRole(AuthorityRole.ROLE_RELEASE);
  const isReleaseUser = hasRole(AuthorityRole.ROLE_RELEASE);
  const isInternalUser = hasRole(AuthorityRole.ROLE_INTERNAL);
  const isTrackAndTraceUser =
    hasRole(AuthorityRole.ROLE_TRACK_AND_TRACE) &&
    !!user?.authorities &&
    user?.authorities?.length <= 2;
  const isComplaintCreator = hasRole(AuthorityRole.ROLE_COMPLAINT_CREATE);
  return {
    roles: user?.authorities,
    hasRole,
    isNaUser,
    isEuUser,
    isNaOrReleaseUser,
    isNonReleaseNaUser,
    isNonReleaseEuUser,
    isReleaseEuUser,
    isReleaseUser,
    isInternalUser,
    isTrackAndTraceUser,
    isFetching,
    isComplaintCreator,
  };
};

export default useRole;
