import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { StockAvailabilityOverview } from '../../../types/StockAvailabilityOverview.interface';
import { ResultRow } from './ResultRow.interface';
import StockOrMillResultTable from './StockOrMillResultTable';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';

interface IMillInventoryResultProps {
  data: ResultRow;
  alert: React.ReactNode;
}

const MillInventoryResult: React.FunctionComponent<IMillInventoryResultProps> = ({
  data,
  alert,
}) => {
  const [orderedResults, setOrderedResults] = useState<
    StockAvailabilityOverview[]
  >([]);
  useEffect(() => {
    if (data?.stockAvailabilities?.length) {
      setOrderedResults(
        orderBy(data.stockAvailabilities, [
          'brand',
          'grammage',
          'widthInches',
          'reelWidthInches',
          'lengthInches',
          'reelDiameterInches',
          'usBaseWeight',
        ])
      );
    }
  }, [data]);
  
  const { measurementSystem } = useMeasurementSystem();
  
  return (
    <div className="-mx-4 mb-8 text-gray-dark200 border-gray-light400 border">
      {alert}
      {measurementSystem === MeasurementSystem.METRIC && (
        <StockOrMillResultTable
          data={orderedResults}
          resultTitle={data.stockAvailabilities[0].productNameMet}
          isMillResult
        />
      )}
      {measurementSystem === MeasurementSystem.IMPERIAL && (
        <StockOrMillResultTable
          data={orderedResults}
          resultTitle={data.stockAvailabilities[0].productName}
          isMillResult
        />
      )}
    </div>
  );
};

export default MillInventoryResult;
