import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import reelCalculatorIntroImage from '../../../assets/eu/reel-calculator-intro.jpg';
import LanderContentRender from '../../../components/LanderContent/LanderContentRender';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { LanderContent } from '../../../types/LanderContent.interface';
import ContainerView from './ContainerView';
import { getOrderedQuantities } from './Criteria.util';
import { RollCalculatorResult } from './types/RollCalculatorResult.interface';

const content: LanderContent = {
  header: t`Select from the following to calculate the optimal number of reels per container`,
  body: t`This tool calculates optimal payloads based on theoretical values.<br> Results are 
          displayed by entering the destination or maximum payload together with the product 
          specification using the fields provided.`,
  id: 1,
  image: reelCalculatorIntroImage,
  language: '',
  title: '',
};

interface IResultsProps {
  results: RollCalculatorResult | null;
}

const Results: React.FunctionComponent<IResultsProps> = ({ results }) => {
  const { format } = useNumberFormatter();

  const [orderedQuantityReels, orderedQuantityKg] = getOrderedQuantities(
    results
  );

  return (
    <>
      {!results ? (
        <LanderContentRender
          content={content}
          className="flex-grow bg-cover mt-4"
        />
      ) : (
        <div className="mr-2 mt-5" style={{ width: '100%' }}>
          <div className="text-lg font-extrabold">
            <Trans>Container Comparison</Trans>
          </div>
          <div>
            <Trans>For</Trans>{' '}
            {results.ivUom === 'KG' ? (
              <span className="text-red-red">
                {format(orderedQuantityKg)} <Trans>Kg</Trans> (
                {format(orderedQuantityReels)} <Trans>Rolls</Trans>)
              </span>
            ) : (
              <span className="text-red-red">
                {format(orderedQuantityReels)} <Trans>Rolls</Trans> (
                {format(orderedQuantityKg)} <Trans>Kg</Trans>)
              </span>
            )}{' '}
            <Trans>your optimized container requirements would be:</Trans>
          </div>
          {results.resultCont20 && (
            <ContainerView
              title={t`20 ft Container`}
              reelsPerPack={results.reelsPerPack}
              resultCon={results.resultCont20}
            />
          )}
          {results.resultCont40 && (
            <ContainerView
              title={t`40 ft Container`}
              reelsPerPack={results.reelsPerPack}
              resultCon={results.resultCont40}
            />
          )}
          <div />
        </div>
      )}
    </>
  );
};

export default Results;
