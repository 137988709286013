import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useState } from 'react';
import { useQueryCache } from 'react-query';
import { Button } from '../../../components/Button/Button';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Modal, { IModalProps } from '../../../components/Modal/Modal';
import { deleteSamplesOrderItem } from '../../../services/SamplesOrder';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { SamplesHistoryOrder } from '../../../types/Samples/SamplesHistoryOrder.interface';

interface IRemoveOrderItemModalProps extends IModalProps {
  orderItem: SamplesHistoryOrder;
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
}

const RemoveOrderItemModal: React.FunctionComponent<IRemoveOrderItemModalProps> = ({
  orderItem,
  onCancel,
  title,
  ...rest
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const queryCache = useQueryCache();

  const removeItem = async (
    e: React.MouseEvent<HTMLElement>
  ): Promise<void> => {
    setIsProcessing(true);
    await deleteSamplesOrderItem(
      orderItem.documentNumber,
      orderItem.documentPosition
    );
    queryCache.invalidateQueries(QueryCacheName.SAMPLES_ORDERS_HISTORY);
    setIsProcessing(false);
    onCancel(e);
  };

  return (
    <Modal onCancel={onCancel} title={<Trans>Remove Item</Trans>} {...rest}>
      {isProcessing ? (
        <LoadingSpinner horizontal />
      ) : (
        <div className="mb-4">
          <p>
            <Trans>Are you sure you wish to remove this item?</Trans>
          </p>
        </div>
      )}
      <Space size="large" className="justify-end w-full px-2">
        <Button
          theme="danger"
          className="uppercase"
          onClick={removeItem}
          disabled={isProcessing}
        >
          <Trans>Remove</Trans>
        </Button>
        <Button
          theme="link"
          className="uppercase"
          onClick={onCancel}
          disabled={isProcessing}
        >
          <Trans>Cancel</Trans>
        </Button>
      </Space>
    </Modal>
  );
};

export default RemoveOrderItemModal;
