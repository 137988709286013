import { isEmpty, uniq } from 'lodash';

export const getParsedValue = (value: string): string[] =>
  isEmpty(value) ? [] : JSON.parse(value);

export const getStringifiedValue = (value?: string[]): string =>
  isEmpty(value) ? '' : JSON.stringify(value);

/**
 * Takes the current stringified array of form values and adds new deduped tags to it.
 * returns a new stringified array of form values
 * */
export const addToStringifiedArray = (
  tagFormValues: string,
  tagsToAdd: string[]
): string => {
  const newTags: string[] = getParsedValue(tagFormValues) as string[];
  const concattedTags = newTags.concat(tagsToAdd);
  return JSON.stringify(uniq(concattedTags));
};

/**
 * Takes the current stringified array of form values and removes tag from it.
 * returns a new stringified array of form values
 * */
export const removeFromStringifiedArray = (
  tagFormValues: string,
  tagsToRemove: string[]
): string => {
  const currentTags = getParsedValue(tagFormValues);
  const newTags = currentTags.filter((currentTag) =>
    tagsToRemove.every((tagToRemove) => tagToRemove !== currentTag)
  );
  return isEmpty(newTags) ? '' : JSON.stringify(uniq(newTags));
};
