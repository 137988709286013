import { t } from '@lingui/macro';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import useAnalytics from '../../../hooks/useAnalytics';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';
import { calculatePagesPerInch } from '../Calculator.service';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';

const UNIT = 'PPI';

const PagesPerInchCalculator: FC = () => {
  const {
    register,
    reset,
    errors,
    getValues,
    formState: { isValid },
  } = useForm({ mode: 'all' });
  const { trackPageView } = useAnalytics();
  const [result, setResult] = useState<string>(UNIT);
  const [isCalculating, setIsCalculating] = useState(false);
  const onCalculate = async () => {
    trackPageView('CALCULATORS', 'PAGES_PER_INCH');
    setIsCalculating(true);
    const response = await calculatePagesPerInch(getValues('caliper'));
    setResult(`${response.pagesPerInch} ${UNIT}`);
    setIsCalculating(false);
  };

  const onReset = () => {
    reset();
    setResult(UNIT);
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.PAGES_PER_INCH}
      instructions={t`All fields must be filled in to calculate`}
      onCalculate={onCalculate}
      isCalculating={isCalculating}
      isCalculateDisabled={!isValid || isCalculating}
      onReset={() => onReset()}
      result={result}
    >
      <Input
        name="caliper"
        type="number"
        ref={register({
          required: true,
          min: 0.1,
          max: 999.9,
          validate: (value) => validateNumberOfDecimalPlaces(value, 1),
        })}
        required
        min=".1"
        max="999.9"
        step=".1"
        error={errors?.caliper}
        placeholder={t`Caliper (x.x)`}
      />
    </CalculatorTemplate>
  );
};

export default PagesPerInchCalculator;
