import { Trans } from '@lingui/macro';
import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import chatRepImage from '../../assets/chat-representative-sm.jpg';

type ChatTriggerButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const ChatTriggerButton: FC<ChatTriggerButtonProps> = ({
  className = '',
  type = 'button',
  ...rest
}) => {
  return (
    <button
      type={type}
      className={`flex items-stretch h-full ${className}`}
      {...rest}
    >
      <div className="overflow-hidden" style={{ maxWidth: '70px' }}>
        <img src={chatRepImage} alt="" />
      </div>
      <div className="flex items-center px-3 text-xl text-blue-cobaltVariation bg-white-white">
        <Trans>Chat with rep</Trans>
      </div>
    </button>
  );
};

export default ChatTriggerButton;
