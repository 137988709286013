import { t } from '@lingui/macro';
import { getTextureOptionsForProductAvailability } from '../../../services/Brand';
import Brand from '../../../types/Brand.interface';
import Option from '../../../types/Option.type';
import useCustomerBrands from './useCustomerBrands';

const useTextureOptions = (
  customer: string,
  parentBrand?: string,
  chemistry?: string
): {
  textureOptions: Option[];
  isFetching: boolean;
} => {
  const { brands, isFetching } = useCustomerBrands(customer);

  return {
    textureOptions: getTextureOptionsForProductAvailability(brands as Brand[], {
      selectedParentBrand: parentBrand,
      selectedChemistry: chemistry,
      defaultSelectLabel: t`Select`,
    }),
    isFetching,
  };
};

export default useTextureOptions;
