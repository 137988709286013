import create from 'zustand';

type SamplesOrderPlacedState = {
  errorMessage: string;
};

const useSamplesOrderPlacedStore = create<SamplesOrderPlacedState>(() => ({
  errorMessage: '',
}));

export default useSamplesOrderPlacedStore;
