import { Badge, Space } from 'antd';
import * as React from 'react';

interface IStatusSectionHeaderProps {
  statusText: React.ReactNode;
  count: number;
  totalWgt: string;
}

const StatusSectionHeader: React.FunctionComponent<IStatusSectionHeaderProps> = ({
  statusText,
  count,
  totalWgt,
}) => {
  return (
    <Space className="flex relative -top-1">
      <div className="text-2xl font-semibold text-blue-pacific">
        {statusText}
      </div>
      <Badge count={count} style={{ backgroundColor: '#0090D9' }} />
      <strong>{totalWgt}</strong>
    </Space>
  );
};

export default StatusSectionHeader;
