import { filter, find } from 'lodash';
import { UseFormMethods } from 'react-hook-form';
import Country from '../../../types/Country.interface';
import Customer from '../../../types/Customer.interface';

const getCustomer = (
  searchedCustomers: Customer[],
  customerNumber: string
): Customer | undefined => {
  const byCustomerNumber = find(searchedCustomers, { customerNumber });
  const byNumber = find(searchedCustomers, { number: customerNumber });
  return byCustomerNumber || byNumber;
};

export const getCustomersWithSamples = (
  customers: Customer[] = []
): Customer[] => filter(customers, (customer) => customer.samples);

export const getNumNonEmptyFormInputs = (
  form: Record<string, unknown>,
  prefix: string,
  skippedInput?: string
): number => {
  const inputCount = filter(form, (value, key) => {
    if (key !== skippedInput) {
      return key.startsWith(prefix) && !!value;
    }
    return false;
  }).length;

  return inputCount;
};

export const resetValueForDeliveryForm = (
  setValue: UseFormMethods['setValue']
): void => {
  setTimeout(() => setValue('deliveryOptions', ''), 0); // TODO: figure out why without this, it resets to NEW ADDRESS
  setTimeout(() => setValue('deliveryCountry', ''), 0);
};

export const getCountryNamePerCode = (
  countries: Country[],
  countryCode: string
): string | undefined => {
  const country = find(countries, { countryCode });
  return country?.name;
};

export default getCustomer;
