import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { UseFormMethods, Validate } from 'react-hook-form';
import ErrorTooltip from '../../../../components/ErrorTooltip/ErrorTooltip';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';

interface INumPagesInputProps {
  name: string;
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  validate?: Validate | Record<string, Validate>;
  required?: boolean;
  min?: number;
}

const NumPagesInput: React.FunctionComponent<INumPagesInputProps> = ({
  name,
  register,
  errors,
  required,
  validate,
  min,
}) => {
  const getError = () => {
    if (
      errors &&
      errors[name] &&
      (errors[name]?.type === 'multipleOf2' || errors[name]?.type === 'min')
    ) {
      return { message: '' };
    }
    return undefined;
  };
  return (
    <InputLabel size="lg" text={t`Number of pages`}>
      <ErrorTooltip
        hasError={errors && errors[name]}
        placement="bottom"
        message={
          <>
            {min && (
              <div>
                <Trans>Value must be greater than or equal to {min}.</Trans>
              </div>
            )}
            <div>
              <Trans>Value must be a multiple of 2.</Trans>
            </div>
            <div>
              <Trans>Value must be positive.</Trans>
            </div>
          </>
        }
      >
        <Input
          name={name}
          ref={register({
            required,
            min,
            validate: {
              multipleOf2: (value) => value % 2 === 0,
              ...validate,
            },
          })}
          width="full"
          type="number"
          error={getError()}
          required={required}
        />
      </ErrorTooltip>
    </InputLabel>
  );
};

export default NumPagesInput;
