import useRole from '../../../../hooks/useRole';
import { AuthorityRole } from '../../../../types/Authority.interface';
import { AvailabilityBasketNA } from '../../../../types/AvailabilityBasketNA.interface';
import {
  deriveContactNameFromNotes,
  deriveContactNotesFromNotes,
  deriveDeliveryInstructionsFromNotes,
  deriveEmailFromNotes,
  derivePhoneFromNotes,
  deriveReceivingFromNotes,
} from '../NaBasket.util';
import OrderType from '../OrderType.enum';
import { DeliveryDetailsForm } from './types/DeliveryDetailsForm.type';
import DeliveryDetailsFormField from './types/DeliveryDetailsFormFields.enum';

const useDefaultValues = (
  basket?: AvailabilityBasketNA | null
): {
  defaultValues: Partial<DeliveryDetailsForm>;
} => {
  const { hasRole } = useRole();
  const defaultValues = {
    [DeliveryDetailsFormField.SELECTED_SHIP_TO]: '',
    [DeliveryDetailsFormField.SHIP_TO_NAME]: basket?.shipToName || '',
    [DeliveryDetailsFormField.SHIP_TO_ADDRESS]: basket?.shipToAddress || '',
    [DeliveryDetailsFormField.SHIP_TO_CITY]: basket?.shipToCity || '',
    [DeliveryDetailsFormField.SHIP_TO_COUNTRY_CODE]:
      basket?.shipToCountryCode || '',
    [DeliveryDetailsFormField.SHIP_TO_POSTAL_CODE]:
      basket?.shipToPostalCode || '',
    [DeliveryDetailsFormField.SHIP_TO_PO]: basket?.shipToPO || '',
    [DeliveryDetailsFormField.SOLD_TO_PO]: basket?.soldToPO || '',
    [DeliveryDetailsFormField.CONTACT_NAME]: deriveContactNameFromNotes(
      basket?.contactNotes1
    ),
    [DeliveryDetailsFormField.PHONE]: derivePhoneFromNotes(
      basket?.contactNotes1
    ),
    [DeliveryDetailsFormField.EMAIL]: deriveEmailFromNotes(
      basket?.contactNotes1
    ),
    [DeliveryDetailsFormField.RECEIVING]: deriveReceivingFromNotes(basket),
    [DeliveryDetailsFormField.DELIVERY_INSTRUCTIONS]: deriveDeliveryInstructionsFromNotes(
      basket
    ),
    [DeliveryDetailsFormField.CONTACT_NOTES]: deriveContactNotesFromNotes(
      basket
    ),
    [DeliveryDetailsFormField.ORDER_TYPE]: hasRole(AuthorityRole.ROLE_RELEASE)
      ? OrderType.ZOR
      : basket?.orderType || OrderType.STANDARD,
  };
  return {
    defaultValues,
  };
};

export default useDefaultValues;
