import Axios from 'axios';

export const sendForgotUsernameRequest = async (
  email: string
): Promise<unknown> => {
  const config = { headers: { 'Content-Type': 'text/plain' } };

  const { data } = await Axios.post(
    '/portal/resources/login/username/forgot',
    email,
    config
  );
  return data;
};
