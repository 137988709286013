import { useEffect, useState } from 'react';
import useDashboardWidgets from '../../hooks/useDashboardWidgets';
import useQuickLinks from '../../hooks/useQuickLinks';
import useUserCustomization from '../../hooks/useUserCustomization';
import { getAccountFormInitialValues } from './Account.util';
import { AccountFormProps } from './AccountForm';

const useFormProperties = (): {
  formProps: AccountFormProps | undefined;
  isFetchingSettings: boolean;
} => {
  const [formProps, setFormProps] = useState<AccountFormProps>();

  const {
    authorizedDashboardWidgets,
    enabledDashboardWidgetIds,
  } = useDashboardWidgets();
  const { authorizedQuickLinks, enabledQuickLinkIds } = useQuickLinks();

  const {
    data: fetchedSettings,
    isFetching: isFetchingSettings,
  } = useUserCustomization();

  useEffect(() => {
    if (fetchedSettings) {
      const initialValues = getAccountFormInitialValues(
        fetchedSettings,
        enabledDashboardWidgetIds || [],
        enabledQuickLinkIds || []
      );

      setFormProps({
        fetchedSettings,
        initialValues,
        dashboardWidgets: authorizedDashboardWidgets,
        quickLinks: authorizedQuickLinks,
      });
    }
  }, [
    fetchedSettings,
    enabledDashboardWidgetIds,
    enabledQuickLinkIds,
    authorizedDashboardWidgets,
    authorizedQuickLinks,
  ]);

  return {
    formProps,
    isFetchingSettings,
  };
};

export default useFormProperties;
