import React, { FC } from 'react';

interface UserInfoItemProps {
  label: string;
  value?: React.ReactNode;
  className?: string;
}

const UserInfoItem: FC<UserInfoItemProps> = ({
  label,
  value,
  className = '',
}) => {
  return (
    <div className={`flex-grow-0 box-border px-7 ${className}`}>
      <div className="h-full border-b border-gray-light400">
        <div className="pb-5 text-xl font-bold">{label}</div>
        <div className="text-lg pb-3">{value}</div>
      </div>
    </div>
  );
};

export default UserInfoItem;
