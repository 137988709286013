import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../hooks/useAnalytics';
import getDeliveryInfo from '../../../services/Delivery';
import { getOrderDetails } from '../../../services/Order';
import { OrderOverviewRecord } from '../../../types/OrderOverviewRecord.interface';
import DeliveryDetailsSection from './DeliveryDetailsSection';

interface IDeliveryDetailsProps {
  docNumber?: string;
  docPosition?: string;
}

const DeliveryDetails: React.FunctionComponent<IDeliveryDetailsProps> = ({
  docNumber,
  docPosition,
}) => {
  const { trackPageView } = useAnalytics();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [deliveryOverview, setDeliveryOverview] = useState<
    OrderOverviewRecord
  >();

  useEffect(() => {
    if (docNumber && docPosition) {
      const getOrderDeliveryDetailsAsync = async () => {
        const orderDetails = await getOrderDetails(
          String(docNumber),
          String(docPosition)
        );

        trackPageView('TRACK_ORDERS', 'DELIVERY_DETAILS', {
          customerId: orderDetails.customerNumber,
        });

        if (orderDetails.overview.deliveryInfo === undefined) {
          const deliveryDetails = await getDeliveryInfo(
            String(docNumber),
            String(docPosition)
          );

          if (deliveryDetails) {
            orderDetails.overview.deliveryInfo = deliveryDetails;
          }
        }
        setLoading(false);
        setDeliveryOverview(orderDetails.overview);
      };
      getOrderDeliveryDetailsAsync();
    }
  }, [docNumber, docPosition, trackPageView]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner horizontal />
      ) : (
        <DeliveryDetailsSection
          deliveryOverview={deliveryOverview || ({} as OrderOverviewRecord)}
          docNumber={docNumber}
          docPosition={docPosition}
        />
      )}
    </>
  );
};

export default DeliveryDetails;
