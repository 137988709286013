import { t, Trans } from '@lingui/macro';
import { Card } from 'antd';
import { forOwn, isMatch, pick, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePaginatedQuery } from 'react-query';
import useMeasurementSystem from '../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import useRole from '../../hooks/useRole';
import {
  getBaseWeightOptions,
  getBrandOptions,
  getBrands,
  getCaliperOptions,
  getEuBaseWeightOptions,
  getEuCaliperOptions,
  getEuFinishOptions,
  getEuGrammageOptions,
  getFinishOptions,
  getGradeOptions,
  getParentBrandOptions,
  getTextureOptions,
} from '../../services/Brand';
import setForm from '../../services/Util/form.util';
import fractionOrDecimalInput from '../../services/Util/fractionOrDecimalInput.util';
import { AuthorityRole } from '../../types/Authority.interface';
import Brand from '../../types/Brand.interface';
import EuBrand from '../../types/EuBrand.interface';
import MeasurementSystem from '../../types/MeasurementSystem.enum';
import Option from '../../types/Option.type';
import ProductQuery from '../../types/ProductQuery.interface';
import PutUpCategory from '../../types/PutUpCategory.enum';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { Button } from '../Button/Button';
import FilterModal from '../FilterModal/FilterModal';
import Input from '../Input/Input';
import InputLabel from '../InputLabel';
import Select from '../Select/Select';
import useQuickOrderFilterStore from './filter.store';
import { QuickOrderFilterForm } from '../../domain/QuickOrderBooking/types/QuickOrderFilterForm.interface';

interface IQuickOrderTableFiltersProps {
  filter: ProductQuery;
  customer?: string;
  type: PutUpCategory;
  context?: 'RL' | 'PL';
  tableRowHasQuantityEntered: boolean;
  onApply?: (filter: ProductQuery) => void;
  onReset?: () => void;
  onSave: (filter: ProductQuery) => void;
}

const FILTER = [
  'brand',
  'finish',
  'grammage',
  'basisWeight',
  'caliper',
  'minWidth',
  'maxWidth',
  'minLength',
  'maxLength',
  'chemistry',
  'parentBrand',
  'texture',
];

const QuickOrderTableFilters: React.FunctionComponent<IQuickOrderTableFiltersProps> = ({
  filter,
  customer,
  type,
  context,
  tableRowHasQuantityEntered,
  onApply,
  onReset,
  onSave,
}) => {
  const { register, handleSubmit, setValue, reset, watch } = useForm<
    QuickOrderFilterForm
  >();
  const { hasRole, isNonReleaseEuUser } = useRole();
  const { measurementSystem } = useMeasurementSystem();
  const { format } = useNumberFormatter();
  const [applyFilterDisabled, setApplyFilterDisabled] = useState<boolean>(true);
  const [visible, setVisible] = useState(false);

  const baseBrandFilters = {
    customer,
    context,
    qobFlag: 'X',
    productType: type,
  };
  const { resolvedData: brands } = usePaginatedQuery(
    [QueryCacheName.BRANDS, baseBrandFilters],
    getBrands,
    {
      staleTime: Infinity,
      enabled: customer,
      // PHASE 2
      // (!hasRole(AuthorityRole.ROLE_EU) ||
      //   hasRole(AuthorityRole.ROLE_RELEASE)),
      keepPreviousData: true,
    }
  );

  const {
    rollFilter: globalRollFilter,
    sheetFilter: globalSheetFilter,
  } = useQuickOrderFilterStore((state) => state);

  // PHASE 2
  // const findEuCustomer = (customerNumber?: string) => {
  //   const euCustomer = find(user?.euDirectCustomers, { customerNumber });
  //   return euCustomer;
  // };

  // PHASE 2
  // const { resolvedData: euBrands } = usePaginatedQuery(
  //   [QueryCacheName.EU_BRANDS, findEuCustomer(customer)],
  //   getEUBrands,
  //   {
  //     staleTime: Infinity,
  //     enabled:
  //       customer &&
  //       user?.euDirectCustomers &&
  //       hasRole(AuthorityRole.ROLE_EU) &&
  //       !hasRole(AuthorityRole.ROLE_RELEASE),
  //     keepPreviousData: true,
  //   }
  // );

  useEffect(() => {
    reset();
  }, [reset, customer]);

  useEffect(() => {
    setForm<QuickOrderFilterForm>(
      setValue,
      pick(
        type === PutUpCategory.ROLL ? globalRollFilter : globalSheetFilter,
        FILTER
      )
    );
  }, [setValue, type, globalRollFilter, globalSheetFilter]);

  const onSubmit = handleSubmit((newFilter) => {
    if (onApply && !visible) {
      onApply(newFilter);
    }
  });

  const onSubmitReset = handleSubmit(() => {
    if (onReset) {
      onReset();
    }

    if (!tableRowHasQuantityEntered) {
      reset();
    }
  });

  // TODO: update with each new filter
  const doesLocalFilterMatchGlobal = () => {
    const form = watch();
    return isMatch(form, pick(filter, FILTER));
  };

  const resetDependentFields = () => {
    setValue('finish', '');
    setValue('grammage', '');
    setValue('caliper', '');
  };

  const formatCaliperOption = (option: Option, idx: number) => {
    if (idx === 0) {
      return option;
    }
    option.label = format(option.label as string);
    return option;
  };

  const form = watch();
  const brandWatch = watch('brand');
  const parentBrandWatch = watch('parentBrand') as string;
  const chemistryWatch = watch('chemistry') as string;

  useEffect(() => {
    setApplyFilterDisabled(true);

    forOwn(form, (value) => {
      if (value !== undefined && value !== '' && trim(String(value)) !== '') {
        setApplyFilterDisabled(false);
      }
    });
  }, [brandWatch, parentBrandWatch, chemistryWatch, form]);

  const getFinishDropdownOptions = React.useMemo(
    () => (selectedBrand: string) => {
      return isNonReleaseEuUser
        ? getEuFinishOptions(brands as EuBrand[], selectedBrand)
        : getFinishOptions(brands as Brand[], selectedBrand);
    },
    [brands, isNonReleaseEuUser]
  );

  const NonReleaseUserExperienceLeft = (
    <div>
      <h6 className="text-gray-gray uppercase">
        <Trans>Product</Trans>
      </h6>
      <div className="flex">
        <div className="w-2/5">
          <InputLabel
            text={
              <strong>
                <Trans>Brand</Trans>
              </strong>
            }
          >
            <Select
              options={
                getBrandOptions(brands as Brand[])
                // PHASE 2
                // isNonReleaseEuUser
                //   ? getEuBrandOptions(brands as EuBrand[], type)
                //   : getBrandOptions(brands as Brand[])
              }
              ref={register}
              name="brand"
              onChange={resetDependentFields}
              data-test-id="qob-brand-dropdown"
            />
          </InputLabel>
        </div>
        <div className="w-1/5">
          <InputLabel
            text={
              <strong>
                <Trans>Finish</Trans>
              </strong>
            }
          >
            <Select
              options={getFinishDropdownOptions(brandWatch || '')}
              ref={register}
              name="finish"
              data-test-id="qob-finish-dropdown"
            />
          </InputLabel>
        </div>
        <div className="w-1/5">
          {measurementSystem === MeasurementSystem.METRIC ? (
            <InputLabel
              text={
                <strong>
                  <Trans>Grammage</Trans>
                </strong>
              }
            >
              <Select
                options={
                  getEuGrammageOptions(
                    brands as EuBrand[],
                    hasRole(AuthorityRole.ROLE_EU) ? 'grammages' : 'grammage',
                    brandWatch
                  )
                  // PHASE 2
                  // euBrands
                  //   ? getEUGrammageOptions(euBrands, type, brandWatch)
                  //   : getGrammageOptions(brands as EuBrand[], brandWatch)
                }
                ref={register}
                name="grammage"
                data-test-id="qob-grammage-dropdown"
              />
            </InputLabel>
          ) : (
            <InputLabel
              text={
                <strong>
                  <Trans>Basis Weight</Trans>
                </strong>
              }
            >
              <Select
                options={
                  hasRole(AuthorityRole.ROLE_EU)
                    ? getEuBaseWeightOptions(brands as EuBrand[], brandWatch)
                    : getBaseWeightOptions(brands as Brand[], {
                        selectedBrand: brandWatch,
                      })
                }
                ref={register}
                name="grammage"
                data-test-id="qob-basis-weight-dropdown"
              />
            </InputLabel>
          )}
        </div>
        <div className="w-1/5">
          <InputLabel
            text={
              <strong>
                <Trans>Caliper</Trans>
              </strong>
            }
          >
            <Select
              options={
                isNonReleaseEuUser
                  ? getEuCaliperOptions(
                      brands as EuBrand[],
                      brandWatch,
                      measurementSystem === MeasurementSystem.METRIC
                    ).map(formatCaliperOption)
                  : getCaliperOptions(brands as Brand[], {
                      selectedBrand: brandWatch,
                      isMetric: measurementSystem === MeasurementSystem.METRIC,
                    }).map(formatCaliperOption)
              }
              ref={register}
              name="caliper"
              data-test-id="qob-basis-caliper-dropdown"
            />
          </InputLabel>
        </div>
      </div>
    </div>
  );

  const NonReleaseUserExperienceRight = (
    <div className="flex">
      <div className="w-32">
        <InputLabel
          text={
            <strong>
              <Trans>Width</Trans>
            </strong>
          }
        >
          <Input
            placeholder={t`From`}
            name="minWidth"
            ref={register}
            type="string"
            onKeyPress={fractionOrDecimalInput}
            data-test-id="qob-width-from-input"
          />
        </InputLabel>
        <Input
          className="mt-1"
          placeholder={t`To`}
          name="maxWidth"
          ref={register}
          type="string"
          onKeyPress={fractionOrDecimalInput}
          data-test-id="qob-width-to-input"
        />
      </div>
      {context === 'PL' ? (
        <div className="w-32">
          <InputLabel
            text={
              <strong>
                <Trans>Length</Trans>
              </strong>
            }
          >
            <Input
              placeholder={t`From`}
              name="minLength"
              ref={register}
              type="string"
              onKeyPress={fractionOrDecimalInput}
              data-test-id="qob-length-from-input"
            />
          </InputLabel>
          <Input
            className="mt-1"
            placeholder={t`To`}
            name="maxLength"
            ref={register}
            type="string"
            onKeyPress={fractionOrDecimalInput}
            data-test-id="qob-length-to-input"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  const ReleaseUserExperience = (
    <div className="flex">
      <div className="w-2/4">
        <InputLabel
          text={
            <strong>
              <Trans>Product</Trans>
            </strong>
          }
        >
          <Select
            options={getParentBrandOptions(brands as Brand[])}
            ref={register}
            name="parentBrand"
          />
        </InputLabel>
      </div>
      <div className="w-1/4">
        <InputLabel
          text={
            <strong>
              <Trans>Grade</Trans>
            </strong>
          }
        >
          <Select
            options={getGradeOptions(brands as Brand[], {
              selectedParentBrand: parentBrandWatch,
            })}
            ref={register}
            name="chemistry"
          />
        </InputLabel>
      </div>
      <div className="w-1/4">
        <InputLabel
          text={
            <strong>
              <Trans>Texture</Trans>
            </strong>
          }
        >
          <Select
            options={getTextureOptions(
              brands as Brand[],
              parentBrandWatch,
              chemistryWatch
            )}
            ref={register}
            name="texture"
          />
        </InputLabel>
      </div>
    </div>
  );

  return (
    <Card title={t`FILTERS`}>
      <form className="flex" onSubmit={onSubmit} style={{ minWidth: '1000px' }}>
        <div className="w-7/12">
          {hasRole(AuthorityRole.ROLE_RELEASE)
            ? ReleaseUserExperience
            : NonReleaseUserExperienceLeft}
        </div>
        <div className="w-5/12">
          {hasRole(AuthorityRole.ROLE_RELEASE) ? (
            <></>
          ) : (
            <h6 className="text-gray-gray uppercase">
              <Trans>Size</Trans>
            </h6>
          )}
          <div className="flex justify-between">
            {hasRole(AuthorityRole.ROLE_RELEASE) ? (
              <div> </div>
            ) : (
              NonReleaseUserExperienceRight
            )}
            <div>
              <div className="mb-1">
                <Button
                  theme="primary"
                  width="40"
                  type="submit"
                  disabled={applyFilterDisabled}
                  data-test-id="qob-apply-filters"
                >
                  <Trans>Apply Filter</Trans>
                </Button>
              </div>
              <div className="mb-2">
                <Button width="40" onClick={onSubmitReset}>
                  <Trans>Reset</Trans>
                </Button>
              </div>
              <div>
                <Button
                  theme="link"
                  width="40"
                  style={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                  }}
                  onClick={() => setVisible(true)}
                  disabled={
                    !doesLocalFilterMatchGlobal() || applyFilterDisabled
                  }
                >
                  <Trans>Save Filter</Trans>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <FilterModal
          visible={visible}
          showSavedFilters={false as boolean}
          selectedCustomer={customer}
          type={type}
          productQuery={filter}
          onCancel={() => setVisible(false)}
          onSubmit={(name: string) => {
            filter.filterName = name;
            setVisible(false);
            onSave(filter);
          }}
        />
      </form>
    </Card>
  );
};

export default QuickOrderTableFilters;
