import { Space } from 'antd';
import React, { FC, useState } from 'react';
import generateOptions from '../../../services/Util/generateOptions.util';
import CalculatorId from '../../Calculators/types/CalculatorId.enum';
import { CALCULATOR_LABELS } from '../../Calculators/types/Calculator.constants';
import { EMPTY_OPTION_SELECT } from '../../../services/Util/emptyOption.const';
import CalculatorTypePicker from './CalculatorTypePicker';
import { CALCULATOR_COMPONENTS } from '../../Calculators/types/CalculatorComponents.constant';

interface CalculatorGroupProps {
  pickerLabel: string;
  calculators: CalculatorId[];
}

const CalculatorGroup: FC<CalculatorGroupProps> = ({
  calculators,
  pickerLabel,
}) => {
  const [selectedCalculator, setSelectedCalculator] = useState<CalculatorId>();

  const pickerOptions = generateOptions(
    calculators,
    (calculatorId) => ({
      label: CALCULATOR_LABELS[calculatorId],
      value: calculatorId,
    }),
    EMPTY_OPTION_SELECT
  );

  const doSelectCalculator = (calculatorId: string) => {
    if (Object.values<string>(CalculatorId).includes(calculatorId)) {
      setSelectedCalculator(calculatorId as CalculatorId);
    }
  };

  return (
    <Space align="start" className="w-full">
      <CalculatorTypePicker
        label={pickerLabel}
        options={pickerOptions}
        onChange={(event) => doSelectCalculator(event.target.value)}
      />
      <div className="flex-grow">
        {selectedCalculator &&
          React.createElement(CALCULATOR_COMPONENTS[selectedCalculator])}
      </div>
    </Space>
  );
};

export default CalculatorGroup;
