import { t } from '@lingui/macro';
import { getGradeOptions } from '../../../services/Brand';
import Brand from '../../../types/Brand.interface';
import Option from '../../../types/Option.type';
import useCustomerBrands from './useCustomerBrands';

const useGradeOptions = (
  customer: string,
  parentBrand?: string,
  texture?: string,
  availFlag?: string
): {
  gradeOptions: Option[];
  isFetching: boolean;
} => {
  const { brands, isFetching } = useCustomerBrands(customer, availFlag);

  return {
    gradeOptions: getGradeOptions(brands as Brand[], {
      selectedParentBrand: parentBrand,
      selectedTexture: texture,
      defaultSelectLabel: t`Select`,
    }),
    isFetching,
  };
};

export default useGradeOptions;
