import { noop } from 'lodash';
import moment from 'moment';
import { getOrderDetails } from '../../../../services/Order';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import AvailabilityBasketType from '../../../../types/AvailabilityBasketType.enum';
import { Order } from '../../../../types/Order.interface';

export const getItemToAdd = async (
  order: Order
): Promise<AvailabilityBasketItem> => {
  const { overviews } = order;
  const orderDetails = await getOrderDetails(
    order.documentNumber || overviews[0].documentNumber,
    order.documentPosition || overviews[0].documentPosition
  );
  const { overview } = orderDetails;

  return {
    id: null,
    basketType: AvailabilityBasketType.PRODUCT,
    customerMaterial: orderDetails.materialNumber,
    materialNumber: overview ? overview.kcode : '',
    requestedQuantity: parseInt(
      orderDetails?.quantity?.split(' ')[0] || '0',
      10
    ),
    requestedUnitOfMeasure: orderDetails?.quantity?.split(' ')[1] || '',
    bookweight: parseInt(orderDetails.zzcogrm.split(' ')[0] || '0', 10),
    material: orderDetails.materialDescription,
    tradeBasis: orderDetails.zzcogrm.split(' ')[1],
    finish: '',
    requestedDate: moment(new Date()).add(1, 'days').valueOf(),
    productType: orderDetails.reelDiameter ? 'R' : 'S',
    reelDiameterImp: orderDetails.reelDiameter.replace(/[a-zA-Z]/g, ''),
    reelWidthImp: orderDetails.reelWidthImp.replace(/[a-zA-Z]/g, ''),
    coreDiameterImp: orderDetails.coreDiameterImp.replace(/[a-zA-Z]/g, ''),
    widthImp: orderDetails.confirmedWidth.replace(/[a-zA-Z]/g, ''),
    lengthImp: orderDetails.confirmedLength.replace(/[a-zA-Z]/g, ''),
    skuCode: overview ? overview.kcode : '',
  };
};

export const otherUtil = noop;
