import { t, Trans } from '@lingui/macro';
import { Popover } from 'antd';
import { upperCase } from 'lodash';
import React from 'react';
import { Button } from '../../../../components/Button/Button';
import Date from '../../../../components/Date';
import OrderRelatedDatesPopover from './OrderRelatedDatesPopover';
import { ViewOrdersRow } from './ViewOrdersRow.interface';
import { Order } from '../../../../types/Order.interface';

export const shippingInfoCell = (
  text: string,
  record: ViewOrdersRow
): JSX.Element => (
  <Button
    theme="link"
    title={`${t({
      id: 'order_list.group_order.transit_info',
      message: 'Transit information for order #',
    })}
${record.customerOrderNumber}`}
  >
    <i className="fa fa-truck" />
  </Button>
);

export const productCell = (
  text: string,
  record: ViewOrdersRow
): JSX.Element => {
  let ret = <></>;
  if (record.overviews && record.overviews.length > 1) {
    ret = <Trans id="groupedOrder">Grouped Order</Trans>;
  } else if (record.overviews) {
    const order = record.overviews[0];
    if (order.materialDescription || order.materialInfo) {
      ret = (
        <>
          {order.materialDescription && (
            <div>
              {order.materialDescription}
              <br />
            </div>
          )}
          {order.materialInfo} {upperCase(order.putupText)}
        </>
      );
    }
  }
  return ret;
};

export const sappiSkuMaterialNumCell = (
  text: string,
  record: ViewOrdersRow
): JSX.Element => {
  let ret = <></>;
  if (record.overviews && record.overviews.length > 1) {
    ret = <Trans id="groupedOrder">Grouped Order</Trans>;
  } else if (record.overviews) {
    const order = record.overviews[0];
    if (order.materialDescription || order.materialInfo) {
      if (order.kcode.length > 0 && order.customerMaterialNumber.length > 0) {
        ret = (
          <>
            {order.kcode}
            <br />
            {order.customerMaterialNumber}
          </>
        );
      } else if (order.kcode.length > 0) {
        ret = <>{order.kcode}</>;
      } else {
        ret = <>{order.customerMaterialNumber}</>;
      }
    }
  }
  return ret;
};

export const confirmedDeliveryDateCell = (
  text: string,
  record: ViewOrdersRow
): JSX.Element => {
  let ret = <></>;
  if (record.overviews && record.overviews.length > 1) {
    ret = <Trans id="groupedOrder">Grouped Order</Trans>;
  } else if (record.overviews && record.overviews.length > 0) {
    const ov = record.overviews[0];
    return (
      <div className="flex justify-between items-center">
        <Date date={ov.deliveryDate} />
        <Popover
          placement="topLeft"
          content={<OrderRelatedDatesPopover order={record} />}
        >
          <span className="fas fa-info-circle text-blue-pacific text-2xl" />
        </Popover>
      </div>
    );
  }
  return ret;
};

export const isBookmarked = (record: ViewOrdersRow): boolean => {
  const { overviews } = record;
  return overviews.some(
    (overview) => overview.flag && overview.flag.priority === 'HIGH'
  );
};

export const getActiveState = (
  order: Order,
  position: number,
  isOrderDetailsPage?: boolean
): boolean => {
  if (isOrderDetailsPage) {
    if (order.overview && order.overview.activeState) {
      return order.overview.activeState.substr(position, 1) === 'X';
    }
    if (order.overview && order.overview.activeState) {
      return order.overview.activeState.substr(position, 1) === 'X';
    }
  } else {
    if (order.overviews && order.overviews[0].activeState) {
      return order.overviews[0].activeState.substr(position, 1) === 'X';
    }
    if (order.overviews && order.overviews[0].activeState) {
      return order.overviews[0].activeState.substr(position, 1) === 'X';
    }
  }

  return false;
};

export const showSignedBOL = (
  order: Order,
  isOrderDetailsPage?: boolean
): boolean => {
  return isOrderDetailsPage
    ? order.overview && order.overview.status === '60'
    : order.overviews &&
        order.overviews[0] &&
        order.overviews[0].status === '60';
};
