import { Tabs as AntTabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import * as React from 'react';
import './Tabs.scss';

export const { TabPane } = AntTabs;
interface ITabsProps extends TabsProps {
  activeBorderColor?: 'gray' | 'blue';
}
const Tabs: React.FunctionComponent<ITabsProps> = (props) => {
  const { children, activeBorderColor, ...rest } = props;
  return (
    <div
      className={`sappi-ant-override-tabs sappi-ant-override-tabs--${
        activeBorderColor ?? 'blue'
      } `}
    >
      <AntTabs {...rest}>{children}</AntTabs>
    </div>
  );
};

export default Tabs;
