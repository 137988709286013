import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import { some } from 'lodash';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import { METRIC_NUMBER_REG_EX } from '../../../types/RegularExpression.constants';

interface ICriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  watch: UseFormMethods['watch'];
  getValues: UseFormMethods['getValues'];
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  calculated: boolean;
}

const Criteria: React.FunctionComponent<ICriteriaProps> = ({
  register,
  errors,
  watch,
  getValues,
  onInputChange,
  calculated,
}) => {
  const kg = watch('kg');
  const lbs = watch('lbs');
  const metricTons = watch('metricTons');
  const shortTons = watch('shortTons');

  const oneInputFilled = () => some(getValues(), (value) => !!value);
  return (
    <Space direction="vertical" size="middle">
      <div className="grid grid-cols-2 gap-4">
        <Input
          name="kg"
          ref={register({
            pattern: METRIC_NUMBER_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.kg}
          disabled={(lbs || metricTons || shortTons) && !calculated}
          onChange={onInputChange}
          width="full"
        />
        <div className="text-xl font-bold">
          <Trans>kg</Trans>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          name="lbs"
          ref={register({
            pattern: METRIC_NUMBER_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.lbs}
          disabled={(kg || metricTons || shortTons) && !calculated}
          onChange={onInputChange}
          width="full"
        />
        <div className="text-xl font-bold">
          <Trans>lbs</Trans>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          name="metricTons"
          ref={register({
            pattern: METRIC_NUMBER_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.metricTons}
          onChange={onInputChange}
          disabled={(kg || lbs || shortTons) && !calculated}
          width="full"
        />
        <div className="text-xl font-bold">
          <Trans>tons(metric)</Trans>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Input
          name="shortTons"
          ref={register({
            pattern: METRIC_NUMBER_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.shortTons}
          disabled={(kg || metricTons || lbs) && !calculated}
          onChange={onInputChange}
          width="full"
        />
        <div className="text-xl font-bold">
          <Trans>tons(short)</Trans>
        </div>
      </div>
    </Space>
  );
};

export default Criteria;
