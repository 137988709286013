import { t } from '@lingui/macro';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Radio from '../../../../../components/Radio/Radio';
import { StockLotBasketForm } from '../StockLotBasketForm.type';
import StockLotBasketFormField from '../StockLotBasketFormField.enum';
import WrappingType from '../WrappingType.enum';

interface IChangeableWrappingTypeCellProps {
  register: UseFormMethods<StockLotBasketForm>['register'];
  externalWrappingType: string;
  index: number;
}

const ChangeableWrappingTypeCell: FC<IChangeableWrappingTypeCellProps> = ({
  externalWrappingType,
  register,
  index,
}) => {
  return (
    <>
      <Radio
        name={`basket.${index}.${StockLotBasketFormField.WRAPPING_TYPE}`}
        value={WrappingType.BULK_PACKED}
        label={t`Bulk Packed`}
        defaultChecked={externalWrappingType === WrappingType.BULK_PACKED}
        ref={register()}
      />
      <Radio
        name={`basket.${index}.${StockLotBasketFormField.WRAPPING_TYPE}`}
        value={WrappingType.REAM_WRAPPED}
        defaultChecked={externalWrappingType === WrappingType.REAM_WRAPPED}
        label={t`Ream Wrapped`}
        ref={register()}
      />
    </>
  );
};

export default ChangeableWrappingTypeCell;
