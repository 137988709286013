import { CalculatorUnitTypes } from '../../types/CalculatorUnitTypes.enum';
import { Criteria as RollWeightAndLengthCriteria } from './Criteria.interface';

export const calculateRollWeightAndLength = (
  data: RollWeightAndLengthCriteria
): [number, number] => {
  let rollWeight: number;
  let rollLength: number;

  if (data.metImp === CalculatorUnitTypes.IMP) {
    rollWeight =
      ((+(data.reelDiamImp || '') * +(data.reelDiamImp || '') -
        +(data.coreDiamImp || '') * +(data.coreDiamImp || '')) *
        +(data.textWeight || '') *
        +(data.reelWidthImp || '') *
        0.0016516) /
      +data.caliper;

    rollLength =
      (rollWeight * 950 * 500) /
      (+(data.reelWidthImp || '') * 12 * +(data.textWeight || ''));
  } else {
    rollWeight =
      ((+(data.reelDiam || '') * +(data.reelDiam || '') -
        +(data.coreDiam || '') * +(data.coreDiam || '')) *
        Math.PI *
        +(data.reelWidth || '')) /
      ((+data.caliper / +(data.grammage || '')) * 4000);

    rollLength =
      (rollWeight * 100000) /
      (+(data.grammage || '') * +(data.reelWidth || ''));
  }

  return [rollWeight, rollLength];
};
