import { Trans } from '@lingui/macro';
import { Popover } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import AddToBasketQtyInput from '../../../components/AddToBasket/AddToBasketQtyInput';
import { Button } from '../../../components/Button/Button';
import ErrorTooltip from '../../../components/ErrorTooltip/ErrorTooltip';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import useValidator from '../../../hooks/useValidator';
import { addStockToEuBasket } from '../../../services/Basket';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import { AvailabilityBasketItem } from '../../../types/AvailabilityBasketItem.interface';
import { OrderBookingProductQuery } from '../../../types/OrderBookingProductQuery.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { StockAvailabilityResult } from '../../../types/StockAvailabilityResult.interface';
import useNonReleaseEuBasketPopupStore from '../../PageHeader/OldSappiBasket/useNonReleaseEuBasketPopup.store';
import OrderBookingAddedToBasketPopUp from '../OrderBookingAddedToBasketPopUp';
import useAnalytics from '../../../hooks/useAnalytics';

interface AddFormData {
  quantity: string;
}

interface IAddToBasketWithQtyProps {
  stockResult: StockAvailabilityResult;
  productQuery?: OrderBookingProductQuery;
  resetFullyToLander: () => void;
}

enum Status {
  READY_TO_ADD = 'READY_TO_ADD',
  ADDING = 'ADDING',
  ALREADY_ADDED = 'ALREADY_ADDED',
}

const AddToBasketWithQty: React.FunctionComponent<IAddToBasketWithQtyProps> = ({
  stockResult,
  productQuery,
  resetFullyToLander,
}) => {
  const { trackPageView } = useAnalytics();
  const { errors, formState, handleSubmit, register } = useForm({
    mode: 'all',
  });
  const { isValid } = formState;
  const [popUpIsOpen, setPopUpIsOpen] = useState<boolean>(false);
  const [basketWithItemAdded, setBasketWithItemAdded] = useState<
    AvailabilityBasketEU
  >();
  const [itemAddedToBasket, setItemAddedToBasket] = useState<
    AvailabilityBasketItem
  >();
  const [status, setStatus] = useState<Status>(Status.READY_TO_ADD);
  const { validateStringIsNum } = useValidator();
  const { parse } = useNumberFormatter();
  const { setNonStockLotLastAddedFor15 } = useNonReleaseEuBasketPopupStore();
  const queryCache = useQueryCache();

  const doSubmit = async (data: AddFormData) => {
    const parsedQuantity = parse(data.quantity);
    const requestedDateParam = productQuery?.requestedDate
      ? moment(productQuery?.requestedDate).toDate()
      : null;

    if (parsedQuantity !== null && requestedDateParam !== null) {
      setStatus(Status.ADDING);
      
      const result: AvailabilityBasketEU = await addStockToEuBasket(
        stockResult,
        parsedQuantity,
        requestedDateParam
      );
      
      setBasketWithItemAdded(result);
      if (result?.items?.length && result?.items?.length > 0) {
        trackPageView('ORDER_BOOKING', 'ADD_TO_BASKET', {
          customerId: result.soldTo.customerNumber,
        });
        const item = result.items[result.items.length - 1];
        setItemAddedToBasket(item);
        setNonStockLotLastAddedFor15(item);
        queryCache.invalidateQueries(QueryCacheName.BASKET_ORDER_BOOKING);
      }
      setStatus(Status.ALREADY_ADDED);
      setPopUpIsOpen(true);
    }
  };

  return (
    <div className="flex">
      {status === Status.ADDING && <LoadingSpinner />}
      <Popover
        visible={basketWithItemAdded && itemAddedToBasket && popUpIsOpen}
        content={
          basketWithItemAdded &&
          itemAddedToBasket && (
            <OrderBookingAddedToBasketPopUp
              itemAdded={itemAddedToBasket}
              onClose={() => setPopUpIsOpen(false)}
              onContinueShopping={resetFullyToLander}
            />
          )
        }
        placement="topRight"
        overlayClassName="popover-remove-arrow"
      >
        <div className="ml-auto">
          {status !== Status.ALREADY_ADDED ? (
            <form
              onSubmit={handleSubmit(doSubmit)}
              className="flex items-stretch"
            >
              <ErrorTooltip
                hasError={!!errors?.quantity}
                placement="bottom"
                message={<Trans>Please enter a number.</Trans>}
              >
                <AddToBasketQtyInput
                  name="quantity"
                  ref={register({
                    required: true,
                    validate: {
                      isNumber: validateStringIsNum,
                    },
                  })}
                  hasError={!!errors?.quantity}
                />
              </ErrorTooltip>
              <Button
                theme="primary"
                disabled={!isValid || status === Status.ADDING}
                type="submit"
                className="whitespace-nowrap"
              >
                <Trans>Add to basket</Trans>
              </Button>
            </form>
          ) : (
            <Trans>Added to basket</Trans>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default AddToBasketWithQty;
