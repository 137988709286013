import Option from '../../types/Option.type';
import StockLotGrammage from './types/StockLotGrammage.interface';
import PutUpCategory from '../../types/PutUpCategory.enum';

export const deriveGrammageOptions = (
  grammages: StockLotGrammage[]
): Option[] => {
  return grammages.map((c) => {
    return {
      label: c.grammage,
      value: c.grammage,
    };
  });
};

export const getProductType = (productType?: string): PutUpCategory => {
  return productType === 'S' ? PutUpCategory.SHEET : PutUpCategory.ROLL;
};
