import * as React from 'react';
import {
  TABLE_ANT_OVERRIDE_CLASS,
  TABLE_NO_OUTSIDE_BORDERS_CLASS,
  TABLE_STRIPED_CLASS,
} from '../Table/Table';

export interface SparseColumnType<RecordType> {
  title: React.ReactNode;
  key: string;
  render: (record: RecordType) => React.ReactNode;
}

interface IDeconstructedTableProps {
  className?: string;
  tableElementProps?: React.HTMLProps<HTMLTableElement>;
  noStripes?: boolean;
  noOutsideBorders?: boolean;
}

const DeconstructedTable: React.FunctionComponent<IDeconstructedTableProps> = ({
  className,
  tableElementProps,
  noStripes,
  noOutsideBorders,
  children,
}) => {
  const borderClass = noOutsideBorders ? TABLE_NO_OUTSIDE_BORDERS_CLASS : '';
  const stripeClass = noStripes ? '' : TABLE_STRIPED_CLASS;

  return (
    <div
      className={`${TABLE_ANT_OVERRIDE_CLASS} ${borderClass} ${stripeClass} ${
        className || ''
      }`}
    >
      <div className="ant-table">
        <div className="ant-table-container">
          <div className="ant-table-content">
            <table {...tableElementProps}>{children}</table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeconstructedTable;
