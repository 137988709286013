import create from 'zustand';
import { DeviationData } from './types/DeviationData.interface';
import { TrackAndTraceData } from './types/TrackAndTraceData.interface';
import { ISearchForm } from './types/TSearchForm.type';

type TrackAndTraceState = {
  request: ISearchForm | null;
  arrivalData: TrackAndTraceData[] | null;
  deviationData: DeviationData[] | null;
};

const useTrackAndTraceStore = create<TrackAndTraceState>(() => ({
  request: null,
  arrivalData: null,
  deviationData: null,
}));

export default useTrackAndTraceStore;
