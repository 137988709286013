import CalculatorId from './CalculatorId.enum';
import MWeightCalculators from '../MWeight/MWeight';
import PagesPerInchCalculator from '../PagesPerInch/PagesPerInchCalculator';
import SheetsToWeightCalculator from '../SheetsToWeight/SheetsToWeightCalculator';
import AreaPerSheetCalculator from '../AreaPerSheet/AreaPerSheetCalculator';
import RollWeightAndLengthCalculator from '../RollWeightAndLength/RollWeightAndLengthCalculator';
import LinearLengthToAreaCalculator from '../LinealLengthToArea/LinearLengthToAreaCalculator';
import SquareAreaWeightConversionCalculator from '../SquareAreaWeightConversion/SquareAreaWeightConversionCalculator';
import PriceCWT from '../PriceCalculators/PriceCWT';
import PricePerTon from '../PriceCalculators/PricePerTon';
import WeightConversionCalculator from '../WeightConversion/WeightConversionCalculator';
import LengthConversionCalculator from '../LengthConversion/LengthConversionCalculator';
import BasisWeightConversionCalculator from '../BasisWeightConversion/BasisWeightConversionCalculator';
import ThicknessConversionCalculator from '../ThicknessConversion/ThicknessConversionCalculator';
import EqCalculator from '../EqCalculator/EqCalculator';
import PalletHeightAndWeight from '../PalletHeightAndWeight/PalletHeightAndWeight';
import PayloadCalculator from '../Payload/PayloadCalculator';
import RollCalculator from '../RollCalculator/RollCalculator';

// Note: this is separated out from Calculator.constants.ts in order to prevent
// cyclical dependencies, since the components imported and referenced here
// depend on many of the other constants defined in Calculator.constants.ts.

export const CALCULATOR_COMPONENTS: Record<CalculatorId, React.FC> = {
  // Sheet calculators:
  [CalculatorId.M_WEIGHT]: MWeightCalculators,
  [CalculatorId.PAGES_PER_INCH]: PagesPerInchCalculator,
  [CalculatorId.SHEETS_TO_WEIGHT]: SheetsToWeightCalculator,
  [CalculatorId.AREA_PER_SHEET]: AreaPerSheetCalculator,
  // Roll calculators:
  [CalculatorId.ROLL_WEIGHT_AND_LENGTH]: RollWeightAndLengthCalculator,
  [CalculatorId.LINEAR_LENGTH_TO_AREA]: LinearLengthToAreaCalculator,
  [CalculatorId.AREA_WEIGHT_CONVERSION]: SquareAreaWeightConversionCalculator,
  [CalculatorId.ROLL]: RollCalculator,
  // Price calculators:
  [CalculatorId.PRICE_CWT]: PriceCWT,
  [CalculatorId.PRICE_PER_TON]: PricePerTon,
  // Conversion calculators:
  [CalculatorId.WEIGHT_CONVERSION]: WeightConversionCalculator,
  [CalculatorId.LENGTH_CONVERSION]: LengthConversionCalculator,
  [CalculatorId.BASIS_WEIGHT_CONVERSION]: BasisWeightConversionCalculator,
  [CalculatorId.THICKNESS_CONVERSION]: ThicknessConversionCalculator,
  // eQ calculator:
  [CalculatorId.EQ]: EqCalculator,
  // Pallet height & weight calculator:
  [CalculatorId.PALLET_HEIGHT_WEIGHT]: PalletHeightAndWeight,
  // Payload calculator:
  [CalculatorId.PAYLOAD]: PayloadCalculator,
};
