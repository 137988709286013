import { find } from 'lodash';
import { useMemo } from 'react';
import { AvailabilityBasketNA } from '../types/AvailabilityBasketNA.interface';
import Customer from '../types/Customer.interface';
import useBasket from './useBasket';
import useCustomer from './useCustomer';

interface IUseCustomerBasket {
  getCustomerBasket: (customerNumber: string) => AvailabilityBasketNA | null;
  // getEuCustomerBasket: (customerNumber: string) => AvailabilityBasketEU | null;
}

const getCustomerBasket = (
  allBaskets: AvailabilityBasketNA[],
  customer: Customer
): AvailabilityBasketNA | null => {
  const existingBasket = find(allBaskets, (basket) => {
    return basket.soldToCustomer.number === customer.number;
  });
  return (
    existingBasket || {
      id: null,
      soldToCustomer: customer,
      items: [],
      totalQuantityKg: 0,
      totalQuantityLb: 0,
    }
  );
};

// Phase 2
// const getEuCustomerBasket = (
//   allBaskets: AvailabilityBasketEU[],
//   customer: Customer
// ): AvailabilityBasketEU | null => {
//   const existingBasket = find(allBaskets, (basket) => {
//     return basket.soldTo.number === customer.number;
//   });
//   return (
//     existingBasket || {
//       id: null,
//       soldTo: customer,
//       items: [],
//     }
//   );
// };

const useCustomerBasket = (): IUseCustomerBasket => {
  const { data: allBaskets } = useBasket();
  const { getCustomerByNumber } = useCustomer();
  return {
    getCustomerBasket: useMemo(
      () => (customerNumber: string) => {
        const customer = getCustomerByNumber(customerNumber);
        return customer
          ? getCustomerBasket(allBaskets as AvailabilityBasketNA[], customer)
          : null;
      },
      [allBaskets, getCustomerByNumber]
    ),
    // getEuCustomerBasket: (customerNumber: string) => {
    //   const customer = getCustomerByNumber(customerNumber);
    //   return customer
    //     ? getEuCustomerBasket(allBaskets as AvailabilityBasketEU[], customer)
    //     : null;
    // },
  };
};

export default useCustomerBasket;
