import { t, Trans } from '@lingui/macro';
import { Collapse, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { forOwn, get, isArray } from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import ExpandIcon from '../../../../components/ExpandIcon';
import Input from '../../../../components/Input/Input';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import Table from '../../../../components/Table/Table';
import useAnalytics from '../../../../hooks/useAnalytics';
import useCustomer from '../../../../hooks/useCustomer';
import useDateFormatter from '../../../../hooks/useDateFormatter';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import useRole from '../../../../hooks/useRole';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import { getConsignmentStatusFromCode } from '../../../../services/Consignment';
import { AuthorityRole } from '../../../../types/Authority.interface';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import {
  consumeConsignment,
  CONSUMED_INPUT_PREFIX,
} from '../../Consignment.service';
import ConsignmentExpandCollapseInfo from '../../types/ConsignmentExpandCollapseInfo.interface';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import ConsignmentInventorySubmittedState from '../../types/ConsignmentInventorySubmittedState.enum';
import ConsignmentStockStatus from '../../types/ConsignmentStockStatus.enum';
import useConsignmentInventoryStore from '../useConsignmentInventoryStore.store';
import ConsignmentInventorySubmissionState from './ConsignmentInventorySubmissionState';
import {
  convertSelectedItemsToPoRequest,
  getSelectedInventory,
} from './ConsignmentInventoryTable.util';
import styles from './ConsignmentInventoryTableDetails.module.scss';

interface IConsignmentInventoryTableDetailsProps {
  details: ConsignmentInventoryItem['details'];
  isPoRequired: boolean;
  rowIndex: number;
  itemNumber: string;
  expanded: boolean;
  onExpand: ConsignmentExpandCollapseInfo['onExpandItem'];
  onCollapse: ConsignmentExpandCollapseInfo['onCollapseItem'];
}

const ConsignmentInventoryTableDetails: React.FunctionComponent<IConsignmentInventoryTableDetailsProps> = ({
  details,
  isPoRequired,
  rowIndex,
  itemNumber,
  expanded,
  onExpand,
  onCollapse,
}) => {
  const { Panel } = Collapse;
  const DETAILS_KEY = 'DETAILS';
  const { format: formatDate } = useDateFormatter();
  const { format: formatNum } = useNumberFormatter();
  const { hasRole } = useRole();
  const { isMetric } = useMeasurementSystem();
  const isConsignSubmitUser = hasRole(AuthorityRole.ROLE_CONSIGN_SUBMIT);
  const {
    register,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
    trigger,
  } = useFormContext();
  const { getCustomerByNumber } = useCustomer();
  const { trackPageView } = useAnalytics();
  const {
    setSubmittedStates,
    submittedStates,
    isAnySubmitting,
    clearSubmittedInventory,
    addInventoryToSubmitted,
  } = useConsignmentInventoryStore();

  const checkboxesAreCleanAndNoneSelected =
    !dirtyFields[`${CONSUMED_INPUT_PREFIX}${rowIndex}`] ||
    errors[`${CONSUMED_INPUT_PREFIX}${rowIndex}`];
  const poInputCleanAndEmpty =
    !get(dirtyFields, `poNumber[${rowIndex}]`, false) ||
    get(errors, `poNumber[${rowIndex}].type`, false);
  const rowKey = `${details[0].documentNumber}-${details[0].salesDocumentItem}`;
  const [allCheckboxesChanging, setAllCheckboxesChanging] = useState(false); // needed to account for setValue on large number of checkboxes

  const onSubmit = async () => {
    const data = getValues();
    const poNumber = data.poNumber && data.poNumber[rowIndex];
    const checkboxes = data[`${CONSUMED_INPUT_PREFIX}${rowIndex}`];
    const firstRow = details[0];
    try {
      clearSubmittedInventory();
      setSubmittedStates(rowKey, ConsignmentInventorySubmittedState.SUBMITTING);
      await consumeConsignment(
        convertSelectedItemsToPoRequest(
          checkboxes,
          details,
          getCustomerByNumber(firstRow.customerNumber)?.name || '',
          poNumber
        )
      );
      setSubmittedStates(rowKey, ConsignmentInventorySubmittedState.SUBMITTED);
      const selectedInventory = getSelectedInventory(
        checkboxes,
        details,
        poNumber
      );
      if (selectedInventory) {
        addInventoryToSubmitted(selectedInventory);
      }
      setValue(
        `${CONSUMED_INPUT_PREFIX}${rowIndex}`,
        new Array(details.length).fill(false),
        {
          shouldValidate: true,
          shouldDirty: false,
        }
      );
    } catch (e) {
      setSubmittedStates(rowKey, ConsignmentInventorySubmittedState.ERRORED);
    }
    trackPageView('CONSIGNMENT', 'SUBMIT_SINGLE');
  };

  const isAtLeastOneCheckedInEntireForm = (): boolean => {
    const values = getValues();
    let isOneChecked = false;
    forOwn(values, (value, key) => {
      if (key.startsWith(CONSUMED_INPUT_PREFIX)) {
        if ((value as boolean[]).some((isConsumed) => isConsumed)) {
          isOneChecked = true;
        }
      }
    });
    return isOneChecked;
  };

  const isAtLeastOneChecked = (): boolean => {
    const values = getValues();
    const consumed = values[`${CONSUMED_INPUT_PREFIX}${rowIndex}`];
    const atLeastOneChecked = isArray(consumed)
      ? consumed.some((isConsumed) => isConsumed)
      : false;
    return atLeastOneChecked;
  };

  const filledOutAndIfAtLeastOneChecked = (): boolean => {
    const values = getValues();
    return isAtLeastOneChecked() ? !!values.poNumber[rowIndex] : true;
  };

  const columns: ColumnsType<ConsignmentInventoryItem> = [
    {
      key: 'palletRollId',
      title: <Trans>Pallet / Roll ID</Trans>,
      render: (value, record) => (
        record.ifraCode 
          ? <span className="font-bold">{record.ifraCode}</span>
          : <span className="font-bold">{record.nariNumber}</span>
      ),
    },
    {
      key: 'damaged',
      title: <Trans>Damaged</Trans>,
      dataIndex: 'damageText',
    },
    {
      key: 'inventoryAge',
      title: <Trans>Inventory Age (Days)</Trans>,
      render: (value, record) => (
        <>
          {record.age} <Trans>Days</Trans>
        </>
      ),
    },
    {
      key: 'ageBasis',
      title: <Trans>Age Basis</Trans>,
      dataIndex: 'ageBasis',
    },
    {
      key: 'ageBasisDate',
      title: <Trans>Age Basis Date</Trans>,
      render: (value, record) =>
        record.ageBasisDate ? formatDate(record.ageBasisDate) : <></>,
    },
    {
      key: 'units',
      title: <Trans>Units</Trans>,
      dataIndex: 'numberOfUnits',
    },
    {
      key: 'area',
      title: <Trans>Area</Trans>,
      render: (value, record) => (
        <>
          {isMetric && (
            <>
              {record.msm} {UnitOfMeasure.MSM}
            </>
          )}
          {!isMetric && (
            <>
              {record.msf} {UnitOfMeasure.MSF}
            </>
          )}
        </>
      ),
    },
    {
      key: 'length',
      title: <Trans>Length</Trans>,
      render: (value, record) => (
        <>
          {isMetric && (
            <>
              {record.linealMeters} {UnitOfMeasure.LM}
            </>
          )}
          {!isMetric && (
            <>
              {record.linealFt} {UnitOfMeasure.LF}
            </>
          )}
        </>
      ),
    },
    {
      key: 'weight',
      title: <Trans>Weight</Trans>,
      render: (value, record) => (
        <>
          {isMetric && (
            <>
              {formatNum(record.kgGross)} {UnitOfMeasure.KG}
            </>
          )}
          {!isMetric && (
            <>
              {record.poundsGross} {UnitOfMeasure.LB}
            </>
          )}
        </>
      )
    },
    {
      key: 'status',
      title: <Trans>Status</Trans>,
      render: (value, record) =>
        getConsignmentStatusFromCode(record.storageLocation, true),
    },
    {
      key: 'autoInvoicedFlag',
      title: <Trans>Auto Invoiced</Trans>,
      render: (value, record) => (
        <>
          {record.autoInvoicedFlag === 'X' && (
            <>
              <Trans>Yes</Trans>
            </>
          )}
          {record.autoInvoicedFlag !== 'X' && (
            <>
              <Trans>No</Trans>
            </>
          )}
        </>
      ),
      
      dataIndex: 'autoInvoicedFlag',
    },
    {
      key: 'invoiceNumber',
      title: <Trans>Invoice Number</Trans>,
      dataIndex: 'invoiceNumber',
    },
    {
      key: 'invoiceDate',
      title: <Trans>Invoice Date</Trans>,
      render: (value, record) =>
        record.invoiceDate ? formatDate(record.invoiceDate) : <></>,
    },
    {
      key: 'consumed',
      align: 'center',
      title: (
        <Space>
          <Checkbox
            name={`consumeAll${rowIndex}`}
            label={t`Consumed`}
            labelPosition="left"
            onChange={(e) => {
              setAllCheckboxesChanging(true);
              setTimeout(() => {
                setValue(
                  `${CONSUMED_INPUT_PREFIX}${rowIndex}`,
                  new Array(details.length).fill(e.target.checked),
                  { shouldDirty: true }
                );
                trigger();
                setAllCheckboxesChanging(false);
              }, 0);
            }}
            disabled={
              details[0].stockStatus === ConsignmentStockStatus.IN_TRANSIT ||
              details[0].stockStatus === ConsignmentStockStatus.ON_ORDER ||
              details[0].stockStatus === ConsignmentStockStatus.INVOICED
            }
          />
          <LoadingSpinner
            className={`relative ${
              allCheckboxesChanging ? 'visible' : 'invisible'
            }`}
          />
        </Space>
      ),
      render: (value, record, index) =>
        isConsignSubmitUser && (
          <div className="w-full flex justify-center">
            <input
              name={`${CONSUMED_INPUT_PREFIX}${rowIndex}[${index}]`}
              type="checkbox"
              ref={register({
                validate: isAtLeastOneCheckedInEntireForm,
              })}
              onChange={() => trigger()}
              disabled={
                record.stockStatus === ConsignmentStockStatus.IN_TRANSIT ||
                record.stockStatus === ConsignmentStockStatus.ON_ORDER ||
                record.stockStatus === ConsignmentStockStatus.INVOICED ||
                allCheckboxesChanging
              }
            />
          </div>
        ),
    },
  ];

  return (
    <Collapse
      className="sappi-collapse-custom-collapse"
      bordered={false}
      expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
      activeKey={expanded ? DETAILS_KEY : []}
      onChange={() =>
        expanded ? onCollapse(itemNumber) : onExpand(itemNumber)
      }
    >
      <Panel
        key={DETAILS_KEY}
        header={
          expanded ? <Trans>Hide Details</Trans> : <Trans>Show Details</Trans>
        }
        className={`sappi-collapse-custom-summary-collapse ${styles.detailsPanel}`}
      >
        <Table
          dataSource={details}
          columns={columns}
          pagination={false}
          className={styles.detailsTable}
          rowKey="id"
        />
        {isConsignSubmitUser && (
          <div className="w-full text-right mt-4">
            <div className="flex justify-end">
              <Space>
                <ConsignmentInventorySubmissionState rowKey={rowKey} />
                {isPoRequired && (
                  <Input
                    width="30"
                    name={`poNumber[${rowIndex}]`}
                    required
                    placeholder={t`My Order #`}
                    ref={register({
                      validate: filledOutAndIfAtLeastOneChecked,
                    })}
                    disabled={
                      checkboxesAreCleanAndNoneSelected || isAnySubmitting
                    }
                  />
                )}
                <Button
                  type="button"
                  theme="primary"
                  onClick={() => onSubmit()}
                  disabled={
                    checkboxesAreCleanAndNoneSelected ||
                    (isPoRequired && poInputCleanAndEmpty) ||
                    isAnySubmitting
                  }
                  loading={
                    submittedStates[rowKey] ===
                    ConsignmentInventorySubmittedState.SUBMITTING
                  }
                >
                  <Trans>Submit</Trans>
                </Button>
              </Space>
            </div>
          </div>
        )}
      </Panel>
    </Collapse>
  );
};

export default ConsignmentInventoryTableDetails;
