import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getLanderContent } from '../../services/Content';
import Locale from '../../types/Locale.enum';
import QueryCacheName from '../../types/QueryCacheName.enum';
import LanderContentType from './LanderContentType.enum';
import LanderContentRender from './LanderContentRender';

interface ILanderContentProps {
  lang: Locale;
  type: LanderContentType;
}

const LanderContent: React.FunctionComponent<ILanderContentProps> = ({
  lang,
  type,
}) => {
  const [twoLetterLang, setTwoLetterLang] = useState<string>();
  const { data: content } = useQuery(
    [QueryCacheName.LANDER_CONTENT, { lang: twoLetterLang, type }],
    getLanderContent,
    {
      enabled: twoLetterLang && type,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setTwoLetterLang(String(lang).substr(0, 2));
  }, [lang]);

  return <LanderContentRender content={content} />;
};

export default LanderContent;
