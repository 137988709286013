import { Trans } from '@lingui/macro';
import React from 'react';
import '../OrderDetailsTable.scss';
import Date from '../../../../components/Date';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { TrackOrder } from '../../../../types/TrackOrder.interface';

interface IStatusCell {
  record: TrackOrder;
}

const StatusCell: React.FunctionComponent<IStatusCell> = ({ record }) => {
  const { format } = useNumberFormatter();

  return (
    <>
      {record?.overview?.orderStatusText && (
        <span className="font-bold capitalize">
          {record?.overview?.orderStatusText?.toLocaleLowerCase() || ''}
        </span>
      )}
      {record.scheduleDataArray &&
        record.scheduleDataArray.map((agreementItem) => {
          return (
            <span key={agreementItem.id}>
              {agreementItem.micsNumber && (
                <dl className="mb-2.5 flex">
                  <dt className="pr-2 label-colon">
                    <Trans id="delivery.revisedLine">Line</Trans>
                  </dt>
                  <dd className="font-bold">
                    {format(agreementItem.micsNumber)}
                  </dd>
                </dl>
              )}
              {(agreementItem.orderQuantity || agreementItem.salesUnit) && (
                <dl className="mb-2.5 flex">
                  <dt className="pr-2 label-colon">
                    <Trans id="delivery.scheduleQuantity">Quantity</Trans>
                  </dt>
                  <dd className="font-bold">
                    {agreementItem.orderQuantity} {agreementItem.salesUnit}
                  </dd>
                </dl>
              )}
              {agreementItem.shipToPartyName && (
                <dl className="mb-2.5 flex">
                  <dt className="pr-2 label-colon">
                    <Trans id="delivery.revisedShipto">Ship to</Trans>
                  </dt>
                  <dd className="font-bold">{agreementItem.shipToPartyName}</dd>
                </dl>
              )}
              {agreementItem.deliveryDate && (
                <dl className="mb-2.5 flex">
                  <dt className="pr-2 label-colon">
                    <Trans id="delivery.revisedDeliverydate">
                      Delivery Date
                    </Trans>
                  </dt>
                  <dd className="font-bold">
                    <Date date={agreementItem.deliveryDate} />
                  </dd>
                </dl>
              )}
            </span>
          );
        })}
    </>
  );
};

export default StatusCell;
