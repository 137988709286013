import Axios from 'axios';
import { ComplaintAttachmentResponse } from '../../types/ComplaintAttachmentResponse.interface';
import Complaints from '../../types/Complaints.interface';
import { ComplaintTextResponse } from '../../types/ComplaintTextResponse.interface';
import { getLocale } from '../Properties';
import ComplaintsReasons from '../../types/ComplaintsReasons.interface';
import ComplaintRecord from '../../types/ComplaintRecord.interface';
import ExistingComplaint from '../../types/ExistingComplaint.interface';

export const getComplaints = async (
  _: string,
  customerNumber: string
): Promise<Complaints[]> => {
  const { data } = await Axios.get(
    `/portal/resources/complaints/${customerNumber}`
  );
  console.log(JSON.stringify(data));
  return data;
};

export const getComplaintText = async (
  complaintNumber: string
): Promise<ComplaintTextResponse> => {
  const { data } = await Axios.get(
    `/portal/resources/eu/complaints/text/${complaintNumber}`
  );
  return data;
};

export const getComplaintAttachments = async (
  complaintNumber: string
): Promise<ComplaintAttachmentResponse> => {
  const { data } = await Axios.get(
    `/portal/resources/eu/complaints/files/${complaintNumber}`
  );
  return data;
};

export const deleteComplaintAttachment = async (
  salesDocument: string,
  documentId: string
) => {
  const { data } = await Axios.delete(
    `/portal/resources/eu/complaints/files/${salesDocument}/${documentId}`
  );
  return data;
};

export const downloadAttachment = async (
  salesDocumentNumber: string,
  documentId: string
): Promise<unknown> => {
  const downloadUrl = `/portal/resources/eu/complaints/files/${salesDocumentNumber}/${documentId}/download`;
  await Axios.get(downloadUrl, {
    responseType: 'document',
  });
  window.location.href = downloadUrl;
  return 'success';
};

export const uploadAttachment = async (
  file: File,
  complaintNumber: string,
  fileNumber: string,
  customerNumber: string
): Promise<unknown> => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await Axios.post(
    `/portal/resources/eu/complaints/files/${complaintNumber}/${fileNumber}/${customerNumber}`,
    formData
  );
  return data;
};

export const uploadFileClaimAttachment = async (
  file: File,
  documentNumber: string,
  documentPosition: string,
  complaintNumber: string
): Promise<unknown> => {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await Axios.post(
    `/portal/resources/eu/complaints/files/${documentNumber}/${documentPosition}/${complaintNumber}/1`,
    formData
  );
  return data;
};

export const getComplaintsReasons = async (): Promise<ComplaintsReasons> => {
  const locale = await getLocale();
  const { data } = await Axios.get(
    `/portal/resources/eu/complaints/reasons/${locale}`
  );
  return data;
};

export const submitComplaint = async (
  complaintRecord: ComplaintRecord
): Promise<ComplaintRecord> => {
  const { data } = await Axios.post(
    `/portal/resources/eu/complaints`,
    complaintRecord
  );
  return data;
};

export const getExistingComplaint = async (
  documentNumber: string,
  documentPosition: string
): Promise<ExistingComplaint> => {
  const { data } = await Axios.get(
    `/portal/resources/eu/complaints/${documentNumber}/${documentPosition}`
  );
  return data;
};

export const getComplaintByCustomerOrderNumber = async (
  customerOrderNumber: string,
  documentPosition: string
): Promise<ExistingComplaint> => {
  const { data } = await Axios.get(
    `/portal/resources/eu/complaints/customerOrderNumber/${customerOrderNumber}/${documentPosition}`
  );
  console.log("success");
  return data;
};
