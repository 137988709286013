import { useQuery } from 'react-query';
import Option from '../../types/Option.type';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { StockLotRequestParams } from './types/StockLotRequestParams.interface';
import { getStockLotShipTos } from '../../services/Customer';
import { EMPTY_OPTION } from '../../services/Util/emptyOption.const';
import generateOptions from '../../services/Util/generateOptions.util';

const useDeliveryLocationOptions = (
  customerNumber: string,
  division: string
): {
  deliveryLocationOptions: Option[];
  isFetchingDeliveryLocation: boolean;
} => {
  const stockLotShipToParams: Pick<
    StockLotRequestParams,
    'customerNumber' | 'division'
  > = {
    customerNumber,
    division,
  };
  const { data: stockLotShipTos, isFetching } = useQuery(
    [QueryCacheName.STOCK_LOT_SHIP_TOS, stockLotShipToParams],
    () => getStockLotShipTos(stockLotShipToParams),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      enabled: customerNumber && division,
      keepPreviousData: true,
    }
  );

  const locationOpts = generateOptions(
    (stockLotShipTos || []).sort(),
    (item) => ({
      label: item.name1,
      value: item.customerNumber,
    }),
    EMPTY_OPTION
  );

  return {
    deliveryLocationOptions: locationOpts,
    isFetchingDeliveryLocation: isFetching,
  };
};

export default useDeliveryLocationOptions;
