enum CustomerBasketFormField {
  NEW_LABEL = 'newLabel',
  CERTIFICATION = 'certification',
  REQUESTED_QUANTITY = 'requestedQuantity',
  REQUESTED_DATE = 'requestedDate',
  LAST_DATE_OF_REVISION = 'ldr',
  CUSTOMER_MATERIAL = 'customerMaterial',
  REEL_WIDTH_IMP = 'reelWidthImp',
  REEL_WIDTH = 'reelWidth',
  REEL_DIAMETER_IMP = 'reelDiameterImp',
  REEL_DIAMETER = 'reelDiameter',
  CORE_DIAMETER_IMP = 'coreDiameterImp',
  CORE_DIAMETER = 'coreDiameter',
  WIDTH_IMP = 'widthImp',
  WIDTH = 'width',
  LENGTH_IMP = 'lengthImp',
  LENGTH = 'length',
  SHEETS_PER_PALLET_NA = 'sheetsPerPalletNa',
}

export default CustomerBasketFormField;
