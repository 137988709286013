import { t } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import Date from '../../../../components/Date';
import Table from '../../../../components/Table/Table';
import useAnalytics from '../../../../hooks/useAnalytics';
import getDocument from '../../../../services/Documents';
import printDocument from '../../../../services/Util/printDocument.util';
import { InvoiceResponse } from '../../../../types/InvoiceResponse.interface';
import PrintInvoicesButton from '../PrintInvoicesButton';
import BillingDeliveryInfoCell from './BillingDeliveryInfoCell';
import { InvoiceDetailsRow } from './InvoiceDetailsRow.interface';
import './InvoiceDetailsTable.scss';
import MaterialShipmentInfoCell from './MaterialShipmentInfoCell';
import OrderInvoiceCell from './OrderInvoiceCell';

interface IInvoiceDetailsTableProps {
  invoice: InvoiceResponse;
  docNumber?: string;
  docPosition?: string;
}

const InvoiceDetailsTable: React.FunctionComponent<IInvoiceDetailsTableProps> = ({
  invoice,
  docNumber,
  docPosition,
}) => {
  const [tableData, setTableData] = useState<InvoiceDetailsRow[]>();
  const { trackPageView } = useAnalytics();

  const printInvoice = async () => {
    if (docNumber && docPosition) {
      trackPageView('TRACK_ORDERS', 'PRINTING_DOCUMENTS', {
        customerId: invoice.details.customerNumber,
      });

      await getDocument(
        String(docNumber),
        String(docPosition),
        String('INVOICE')
      ).then((document) => {
        printDocument(document);
      });
    }
  };

  useEffect(() => {
    const invoiceDetailsRows: InvoiceDetailsRow[] = invoice.invoices.map(
      (invoiceRow, idx) => {
        const invoiceDetailsRow: InvoiceDetailsRow = {
          key: idx,
          invoice: invoiceRow,
          deliveryDetail: invoice.deliveryDetails[idx],
          details: invoice.details,
          overview: invoice.overview,
        };
        return invoiceDetailsRow;
      }
    );
    setTableData(invoiceDetailsRows);
  }, [invoice]);

  const columns: ColumnsType<InvoiceDetailsRow> = [
    {
      title: `${t({
        id: 'invoice.orderAndInvoiceNumber',
        message: 'Order & Invoice Number',
      })}`,
      dataIndex: 'order_invoice',
      key: 'order_invoice',
      render: (text: string, record: InvoiceDetailsRow) => (
        <OrderInvoiceCell record={record} />
      ),
    },
    {
      title: `${t({
        id: 'invoice.materialAndShipmentInfo',
        message: 'Material & Shipment Info',
      })}`,
      dataIndex: 'shipmentInfo_invoice',
      key: 'shipmentInfo_invoice',
      render: (text: string, record: InvoiceDetailsRow) => (
        <MaterialShipmentInfoCell record={record} />
      ),
    },
    {
      title: `${t({
        id: 'invoice.billingAndDeliveryInfo',
        message: 'Billing & Delivery Info',
      })}`,
      dataIndex: 'billingInfo_invoice',
      key: 'billingInfo_invoice',
      render: (text: string, record: InvoiceDetailsRow) => (
        <BillingDeliveryInfoCell record={record} />
      ),
    },
    {
      title: `${t({
        id: 'invoice.invoiceDate',
        message: 'Invoice Date',
      })}`,
      dataIndex: 'invoiceDate_invoice',
      key: 'invoiceDate_invoice',
      render: (text: string, record: InvoiceDetailsRow) => (
        <dl className="mb-2.5 flex text-xs text-gray-dark200">
          <dd className="font-bold">
            <Date date={record.invoice.invoiceDate} />
          </dd>
        </dl>
      ),
    },
    {
      title: `${t({
        id: 'invoice.quantity',
        message: 'Quantity',
      })}`,
      dataIndex: 'quantity_invoice',
      key: 'quantity_invoice',
      render: (text: string, record: InvoiceDetailsRow) => (
        <dl className="mb-2.5 flex text-xs text-gray-dark200">
          <dd className="font-bold">{record.invoice.quantity}</dd>
        </dl>
      ),
    },
    {
      title: `${t({
        id: 'invoice.value',
        message: 'Value',
      })}`,
      dataIndex: 'value_invoice',
      key: 'value_invoice',
      render: (text: string, record: InvoiceDetailsRow) => (
        <dl className="mb-2.5 flex text-xs text-gray-dark200">
          <dd className="font-bold">{record.invoice.value}</dd>
        </dl>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={tableData}
        columns={columns}
        expandIconColumnIndex={-1}
        pagination={false}
        config={{
          noStripes: false,
          noOutsideBorders: true,
        }}
        className="invoice-details m-2"
      />
      <div className="pt-8 flex justify-end">
        <PrintInvoicesButton theme="primary" onClick={printInvoice} />
      </div>
    </>
  );
};

export default InvoiceDetailsTable;
