import { Space } from 'antd';
import React, { FC } from 'react';
import ReviewStepButtons from '../ReviewStepButtons';
import { TrackAndTraceData } from '../types/TrackAndTraceData.interface';
import TrackAndTraceStep from '../types/TrackAndTraceStep.enum';
import ResultsTable from './ResultsTable';

interface IReviewStepProps {
  data: TrackAndTraceData[];
  onBack: () => void;
  onConfirm: (changedDate: TrackAndTraceData[]) => void;
}

const ReviewStep: FC<IReviewStepProps> = ({ data, onBack, onConfirm }) => {
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <ResultsTable data={data} step={TrackAndTraceStep.REVIEW} />
      <div className="text-right w-full">
        <ReviewStepButtons onBack={onBack} onConfirm={() => onConfirm(data)} />
      </div>
    </Space>
  );
};

export default ReviewStep;
