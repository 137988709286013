import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { ViewOrdersRow } from './ViewOrdersRow.interface';

interface IOrderCellProps {
  record: ViewOrdersRow;
}

const OrderCellForWidgetTable: React.FunctionComponent<IOrderCellProps> = ({
  record,
}) => {
  const history = useHistory();

  return (
    <dl>
      {!record.isChild ? (
        <>
          <dt>
            <Trans>My Order #:</Trans> &nbsp;
          </dt>
          <dd>{record.customerOrderNumber}</dd>
          <br />
          <dt>
            <Trans id="sappiOrder">Sappi Order #:</Trans>&nbsp;
          </dt>
        </>
      ) : (
        <dt />
      )}

      <dd>
        {record.overviews.length > 1 ? (
          <>{stripZeros(record.documentNumber)}</>
        ) : (
          <Button
            theme="link"
            title={t({ id: 'view_details', message: 'View Details' })}
            onClick={() => {
              history.push(
                `/orderTracking/details/${record.documentNumber}/${record.overviews[0].documentPosition}`
              );
            }}
          >
            <strong>
              {stripZeros(record.documentNumber)}/
              {stripZeros(record.overviews[0].documentPosition)}
            </strong>
          </Button>
        )}
      </dd>
    </dl>
  );
};

export default OrderCellForWidgetTable;
