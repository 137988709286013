import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import useLocale from '../../hooks/useLocale';
import useProperties from '../../hooks/useProperties';
import useRole from '../../hooks/useRole';
import useUser from '../../hooks/useUser';

const SiteFooter: FC = () => {
  const { isNorthAmericanRequest } = useLocale();
  const { data: properties } = useProperties();
  const { isNaUser } = useRole();
  const { data: user } = useUser();
  const isAuthenticated = !!user;

  return (
    <>
      <div className="mt-12 mb-4 flex justify-center text-lg">
        <span className="pr-3">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.sappi.com/contact-us"
          >
            <Trans>Contact Us</Trans>
          </a>
        </span>
        <span className="px-3 border-l border-gray-dark200">
          <a
            target="_blank"
            rel="noreferrer"
            href={
              (isAuthenticated && isNaUser) ||
              (!isAuthenticated && isNorthAmericanRequest)
                ? 'https://www.sappi.com/aboutus'
                : 'https://www.sappi.com'
            }
          >
            <Trans>About Us</Trans>
          </a>
        </span>
        {isAuthenticated ? (
          <span className="px-3 border-l border-gray-dark200">
            <a href="#/help?category=Welcome" target="_blank" rel="noreferrer">
              <Trans>Help</Trans>
            </a>
          </span>
        ) : (
          <span className="px-3 border-l border-gray-dark200">
            <a href="#/loginHelp" target="_self" rel="noreferrer">
              <Trans>Help</Trans>
            </a>
          </span>
        )}
        <span className="px-3 border-l border-gray-dark200">
          <a
            href="https://www.sappi.com/legal"
            target="_blank"
            rel="noreferrer"
          >
            <Trans>Legal Notices</Trans>
          </a>
        </span>
        {((isAuthenticated && isNaUser) ||
          (!isAuthenticated && isNorthAmericanRequest)) && (
          <span className="pl-3 border-l border-gray-dark200">
            <a
              href="https://www.sappi.com/sappi-north-america-california-transparency-supply-chains-act-statement"
              target="_blank"
              rel="noreferrer"
            >
              <Trans>California Transparency in Supply Chains Act</Trans>
            </a>
          </span>
        )}
      </div>
      <div className="flex justify-end text-base">
        <Trans>Version:</Trans>&nbsp;
        <span className="font-bold">{`${properties?.version}-${properties?.number}`}</span>
        &nbsp;
      </div>
    </>
  );
};

export default SiteFooter;
