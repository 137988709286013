const alphanumericOnlyInput = (e: React.KeyboardEvent<HTMLElement>): void => {
  const { key } = e;

  if (
    key !== 'Backspace' &&
    key !== 'Delete' &&
    !key.match(/^[a-zA-Z0-9\s]+$/)
  ) {
    e.preventDefault();
  }
};

export default alphanumericOnlyInput;
