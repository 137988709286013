import { t } from '@lingui/macro';
import Axios from 'axios';
import { SavedSku } from '../../types/SavedSkus.interface';

/**
 * Regex representing pattern for valid user input of a Sappi SKU.
 *
 * Allows optional leading 'K' or 'k', followed by 0 or more numeric digits.
 *
 * Note that this is currently very lax, in order to match the Angular
 * implementation. In the future, it is likely that this will want to be
 * modified to allow the optional K prefix followed by 3-5 numeric digits.
 */
export const sappiSkuInputRegex = /^[Kk]{0,1}[0-9]*$/;
export const sappiSkuOrMatlNoInputRegex = /^[a-zA-Z0-9]*$/;

/**
 * Function to test whether a value represents valid user input of a Sappi SKU.
 */
export const isValidSappiSkuInput = (value: string): boolean => {
  return sappiSkuInputRegex.test(value);
};

/**
 * Function to test whether a value represents a valid user input that is a
 * sappi sku number or a customer material number.
 */
export const isValidSappiSkuOrMatlNoInput = (value: string): boolean => {
  return sappiSkuOrMatlNoInputRegex.test(value);
};

/**
 * Regex representing pattern for a valid normalized Sappi SKU.
 *
 * Allows one or more numeric digits. Leading 'K' or 'k' not allowed.
 */
export const sappiSkuRegex = /^[0-9]+$/;
export const sappiSkuOrMatlNoRegex = /^[a-zA-Z0-9]+$/;

/**
 * Function to test whether a value is a valid, normalized Sappi SKU.
 */
export const isValidSappiSku = (value: string): boolean => {
  return sappiSkuRegex.test(value);
};

/**
 * Function to test whether a value is a valid, normalized Sappi SKU or 
 * customer material number.
 */
export const isValidSappiSkuOrMatlNo = (value: string): boolean => {
  return sappiSkuOrMatlNoRegex.test(value);
};

export const validateSappiSkuInput = (value: string): boolean | string => {
  const ERROR_MSG = t`Invalid Sappi SKU. Please enter only numbers.`;
  return value === '' || isValidSappiSkuInput(value) ? true : ERROR_MSG;
};

export const validateSappiSkuOrMatlNoInput = (value: string): boolean | string => {
  const ERROR_MSG = t`Invalid Entry. Please enter only alphanumeric characters.`;
  return value === '' || isValidSappiSkuOrMatlNoInput(value) ? true : ERROR_MSG;
};

/**
 * Function to normalize a Sappi SKU.
 *
 * Removes a leading 'K' or 'k' if present. Converts other alphabetic characters
 * to uppercase.
 *
 * Note that a string that has been run through this function is not currently
 * guaranteed to produce a valid Sappi SKU, even if the input is valid Sappi SKU
 * input (e.x. 'k' is currently valid Sappi SKU input, and would normalize to an
 * empty string, which is not a valid Sappi SKU).
 */
export const normalizeSappiSku = (sappiSku: string): string => {
  let normalizedSappiSku = sappiSku.toUpperCase();

  if (normalizedSappiSku.startsWith('K')) {
    normalizedSappiSku = normalizedSappiSku.substring(1);
  }

  return normalizedSappiSku;
};

export const loadSavedSkus = async (): Promise<SavedSku[]> => {
  const { data } = await Axios.get('/portal/resources/savedskus/getSkus');
  return data;
};

export const deleteSavedSku = async (skuCode: string): Promise<any> => {
  const { data } = await Axios.delete(
    `/portal/resources/savedskus/removeSku/${skuCode}`
  );
  return data;
};

export const saveSku = async (
  kcode: string,
  product: string,
  productMetric: string,
): Promise<string> => {
  const { data } = await Axios.post(`/portal/resources/savedskus/saveSku`, 
  {
    kcode,
    product,
    productMetric,
  });
  return data;
};
