import { t } from '@lingui/macro';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ChangePassword from '../../../components/ChangePassword/ChangePassword';
import useUser from '../../../hooks/useUser';
import showNotification from '../../../services/Notification';
import { updatePassword } from '../../../services/User';
import { ErrorResponse } from '../../../types/ErrorResponse.interface';
import UpdatePasswordRequest from '../../../types/User/UpdatePasswordRequest.interface';
import { getPasswordErrorMessage } from '../types/PasswordErrors.service';

const DEFAULT_VALUES: UpdatePasswordRequest = {
  currentPassword: '',
  newPassword: '',
  confirmedPassword: '',
};

const Password: FC = () => {
  const history = useHistory();
  const { data: userData } = useUser();
  const formMethods = useForm<UpdatePasswordRequest>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES,
  });
  const { reset } = formMethods;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const doSubmit = async (values: UpdatePasswordRequest) => {
    const username = userData?.username;
    let responseError: any;

    if (username) {
      setHasError(false);
      setIsSubmitting(true);

      try {
        await updatePassword(values).catch(e => {
          setHasError(true);
          responseError = e;
          throw new Error();
        });

        reset();
        history.push('/account');
        showNotification({
          message: '',
          description: t`Password update successful!`,
        });
      } catch {
        setHasError(true);
        setErrorMessage(getPasswordErrorMessage(responseError?.response?.data?.error || ''))
        reset();
      }

      setIsSubmitting(false);
    } else {
      history.push('/login');
    }
  };

  return (
    <ChangePassword
      onSubmit={doSubmit}
      formMethods={formMethods}
      isSubmitting={isSubmitting}
      hasError={hasError}
      errorMessage={errorMessage}
    />
  );
};

export default Password;
