import { QueryConfig, QueryResult, useQuery } from 'react-query';
import { getStockLotBaskets } from '../services/Basket';
import QueryCacheName from '../types/QueryCacheName.enum';
import { StockLotBasket } from '../types/StockLotBasket.interface';
import useUser from './useUser';
import useRole from './useRole';

const useStockLotBasket = (
  onSuccess?: () => void
): QueryResult<StockLotBasket[]> => {
  const { data: userData } = useUser();
  const { isEuUser } = useRole();
  const config: QueryConfig<StockLotBasket[]> = {
    enabled: userData && isEuUser,
    staleTime: 60000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  };
  if (onSuccess) {
    config.onSuccess = onSuccess;
  }
  return useQuery(
    [QueryCacheName.BASKET_STOCK_LOT],
    async () => getStockLotBaskets(),
    config
  );
};

export default useStockLotBasket;
