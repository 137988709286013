import { Collapse } from 'antd';
import { orderBy } from 'lodash';
import React, { useState } from 'react';
import { usePaginatedQuery } from 'react-query';
import ExpandIcon from '../../../components/ExpandIcon';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../hooks/useAnalytics';
import { getOrderBookingAlternateProducts } from '../../../services/Product';
import { ProductAvailabilityResult } from '../../../types/ProductAvailabilityResult.interface';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import InitialResultTable from './InitialResultTable/InitialResultTable';

const { Panel } = Collapse;
interface IAlternateBrandsProps {
  productToFindAlternatesFor: ProductAvailabilityResult | null;
  resetFullyToLander: () => void;
  requestedDate: string;
  requestedQuantity: string;
  productType: string;
}

const AlternateBrands: React.FunctionComponent<IAlternateBrandsProps> = ({
  productToFindAlternatesFor,
  resetFullyToLander,
  requestedDate,
  requestedQuantity,
  productType,
}) => {
  const { trackPageView } = useAnalytics();
  const [orderedAlternates, setOrderedAlternates] = useState<
    ProductAvailabilityResult[]
  >([]);
  const { isFetching } = usePaginatedQuery(
    [QueryCacheName.ALT_ORDER_BOOKING_PRODUCTS, productToFindAlternatesFor],
    getOrderBookingAlternateProducts,
    {
      enabled: productToFindAlternatesFor,
      staleTime: Infinity,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSettled: (data) => {
        trackPageView('ORDER_BOOKING', 'ALTERNATIVE_BRANDS');
        setOrderedAlternates(orderBy(data, 'txtGradeBrandMember') || []);
      },
    }
  );
  return (
    <Collapse
      className="sappi-collapse-custom-collapse"
      bordered={false}
      style={{ marginRight: '1px' }}
      expandIcon={({ isActive }) => <ExpandIcon isActive={isActive} />}
    >
      <Panel
        header="Alternate Brands"
        key="altBrands"
        className="sappi-collapse-custom-summary-collapse"
      >
        {isFetching ? (
          <LoadingSpinner horizontal />
        ) : (
          <>
            {orderedAlternates?.map((alternate) => (
              <InitialResultTable
                className="z-0 -mx-4"
                results={[alternate]}
                hideHeaders
                key={alternate.skuCode}
                resetFullyToLander={resetFullyToLander}
                requestedQuantity={requestedQuantity}
                productType={productType}
              />
            ))}
          </>
        )}
      </Panel>
    </Collapse>
  );
};

export default AlternateBrands;
