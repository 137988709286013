import { forOwn, some, trim } from 'lodash';
import moment, { Moment } from 'moment';
import { UseFormMethods } from 'react-hook-form';
import { DocumentQueryParam } from '../../../services/Documents/DocumentQueryParam.interface';
import { AvailableDocumentRow } from '../AvailableDocuments/AvailableDocumentRow.interface';
import { SearchDocumentInputs } from './SearchDocumentInputs';

export function prepareFiltersForDocumentPrinter(
  queryParam: DocumentQueryParam
): string {
  let parameters = '';

  forOwn(queryParam, (value, key) => {
    if (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      value !== 'undefined' &&
      value !== 'Invalid date' &&
      trim(String(value)) !== '' &&
      trim(String(key)) !== 'dateFromValue' &&
      trim(String(key)) !== 'dateToValue' &&
      trim(String(key)) !== 'pageSize' &&
      trim(String(key)) !== 'usePaginatedVersion'
    ) {
      parameters += `${key}=${value}&`;
    }
  });
  return parameters.slice(0, -1);
}

export const resetDate = (setValue: UseFormMethods['setValue']): void => {
  setValue('dateFromValue', moment());
  setTimeout(() => setValue('dateFromValue', null), 0);

  setValue('dateToValue', moment());
  setTimeout(() => setValue('dateToValue', null), 0);
};

export const isKeyExisting = (
  expKeys: (string | null | undefined)[],
  record: AvailableDocumentRow
): boolean => {
  return (
    (record.isChild &&
      !some(
        expKeys,
        record.overviews[0].documentNumber +
          record.overviews[0].weightListNumber
      )) ||
    (!record.isChild && !some(expKeys, record.overviews[0].documentNumber))
  );
};

export const isValidInput = (input: string | undefined): boolean => {
  return input !== undefined && input !== null && input.trim() !== '';
};

export const hasValidInput = (inputs: SearchDocumentInputs): boolean => {
  return (
    isValidInput(inputs.customerOrderNumberFrom) ||
    isValidInput(inputs.documentNumberFrom) ||
    isValidInput(inputs.invoiceNumberFrom) ||
    isValidInput(inputs.deliveryNote) ||
    isValidInput(inputs.weightListNumber) ||
    isValidInput(inputs.dateCreatedFrom) ||
    isValidInput(inputs.dateCreatedTo) ||
    isValidInput(inputs.invoiceDateFrom) ||
    isValidInput(inputs.invoiceDateTo) ||
    isValidInput(inputs.billingDateFrom) ||
    isValidInput(inputs.billingDateTo)
  );
};

export const anyInputExists = (
  watchFields: {
    customerOrderNumberFrom?: string | undefined;
    documentNumberFrom?: string | undefined;
    invoiceNumberFrom?: string | undefined;
  },
  dateFromValueWatch?: Moment | undefined,
  dateToValueWatch?: Moment | undefined
): boolean => {
  return (
    isValidInput(watchFields.customerOrderNumberFrom) ||
    isValidInput(watchFields.documentNumberFrom) ||
    isValidInput(watchFields.invoiceNumberFrom) ||
    (dateFromValueWatch !== undefined &&
      dateFromValueWatch !== null &&
      !dateFromValueWatch.parsingFlags().empty) ||
    (dateToValueWatch !== undefined &&
      dateToValueWatch !== null &&
      !dateToValueWatch.parsingFlags().empty)
  );
};
