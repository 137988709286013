import { useHistory } from 'react-router-dom';
import useLocationQueryParams from '../../../hooks/useLocationQueryParams';

const useContinueShopping = (): {
  continueShopping: () => void;
} => {
  const urlParams = useLocationQueryParams();
  const history = useHistory();
  const continueShopping = () => {
    if (urlParams.get('quickOrder')) {
      history.push('/quickOrder');
    } else {
      history.push('/availabilityPlanning');
    }
  };
  return {
    continueShopping,
  };
};

export default useContinueShopping;
