import { t } from '@lingui/macro';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import StorageLocation from '../../../types/StorageLocation.enum';
import ResultAlert from './ResultAlert';
import { ResultRow } from './ResultRow.interface';
import StockResult from './StockResult';

interface IStockResultsProps {
  data: ResultRow[];
  isStockExpanded?: boolean;
}

const StockResults: React.FunctionComponent<IStockResultsProps> = ({
  data,
  isStockExpanded,
}) => {
  const [filteredResults, setFilteredResults] = useState<ResultRow[]>([]);
  useEffect(() => {
    if (data.length) {
      const sortFields = [
        'brand',
        'stockAvailabilities[0].grammage',
        'stockAvailabilities[0].widthInches',
        'stockAvailabilities[0].reelWidthInches',
        'stockAvailabilities[0].lengthInches',
        'stockAvailabilities[0].reelDiameterInches',
        'usBaseWeight',
      ];
      setFilteredResults(
        orderBy(
          data.filter(
            (item) =>
              item.storageLocation !== StorageLocation.AM &&
              item.stockAvailabilities.length > 0
          ),
          sortFields
        )
      );
    }
  }, [data]);
  return (
    <div className="-mt-4">
      {filteredResults.map((result, idx) => (
        <StockResult
          key={`${result.kcode}-${result.stockAvailabilities[0].materialNumber}`}
          data={result}
          isStockExpanded={isStockExpanded}
          alert={
            idx === 0 && (
              <ResultAlert
                alertText={t`Inventory subject to availability. Please consult your Sappi representative for special making requests.`}
              />
            )
          }
        />
      ))}
    </div>
  );
};

export default StockResults;
