import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { noop, trim } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePaginatedQuery, useQueryCache } from 'react-query';
import Modal from '../../../components/Modal/Modal';
import Table from '../../../components/Table/Table';
import { isSkuSaved } from '../Results/Results.util';
import { deleteSavedSku, loadSavedSkus } from '../../../services/SappiSku';
import { Button } from '../../../components/Button/Button';
import { SavedSku } from '../../../types/SavedSkus.interface';
import {
  getCheckedBoxCount,
  getCheckedBoxCountWithoutKCode,
  getSkusToAddFromFormData,
  skuCheckboxPrefix,
} from '../SkuLookupModal/SkuLookupModalAddSkusForm.util';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import './BookmarkedSkusModal.scss';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';

interface IBookmarkedSkusModalProps {
  visible: boolean;
  onCancel: () => void;
  searchBySappiSkus: (sappiSkusToAdd: string[]) => void;
}

const BookmarkedSkusModal: React.FunctionComponent<IBookmarkedSkusModalProps> = ({
  visible,
  onCancel,
  searchBySappiSkus,
}) => {
  const queryCache = useQueryCache();
  const [allSkusBoxChecked, setAllSkusBoxChecked] = useState<boolean>(false);
  const { register, handleSubmit, reset, setValue, getValues, watch } = useForm(
    {
      mode: 'all',
    }
  );

  const form = watch();
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const { isMetric } = useMeasurementSystem();

  const { resolvedData: savedSkus } = usePaginatedQuery(
    [QueryCacheName.SAVED_SKUS],
    loadSavedSkus,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: visible,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    const count = getCheckedBoxCount(form);
    setDisableButton(!(count > 0));
  }, [form]);

  const toggleAllSkusBox = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const inputNames = Object.keys(getValues());
      inputNames.forEach((inputName) => {
        if (inputName.startsWith(skuCheckboxPrefix)) {
          setValue(inputName, true);
        }
      });
      setAllSkusBoxChecked(true);
      setDisableButton(false);
    } else {
      reset();
      setAllSkusBoxChecked(false);
      setDisableButton(true);
    }
  };

  const doSubmit = (formData: Record<string, unknown>): void => {
    const sappiSkusChosen = getSkusToAddFromFormData(formData);

    searchBySappiSkus(sappiSkusChosen);
    onCancel();
  };

  const deleteSku = async (record: SavedSku) => {
    if (isSkuSaved(savedSkus || [], record.kcode)) {
      await deleteSavedSku(record.kcode);
      queryCache.invalidateQueries(QueryCacheName.SAVED_SKUS);
    }

    const count = getCheckedBoxCountWithoutKCode(form, record.kcode);
    setDisableButton(!(count > 0));
  };

  const columns: ColumnsType<SavedSku> = [
    {
      title: '',
      key: 'removeBookmark',
      render: (value, record) => (
        <>
          <i
            aria-label={t`Clear Sku Code Bookmark`}
            className="fa fa-trash fa-2x ml-2"
            onKeyDown={noop}
            tabIndex={0}
            role="button"
            onClick={() => {
              deleteSku(record);
            }}
          />
        </>
      ),
    },
    {
      title: (
        <div className="flex items-baseline">
          <input
            type="checkbox"
            className="mx-2"
            disabled={savedSkus && savedSkus?.length < 1}
            checked={allSkusBoxChecked}
            onChange={toggleAllSkusBox}
          />{' '}
          <span className="text-lg font-bold leading-tight mr-2">
            <Trans>Sappi SKU</Trans>
          </span>
        </div>
      ),
      key: 'sappiSku',
      render: (value, record) => {
        const sappiSku = record?.kcode || undefined;
        return (
          <>
            <input
              type="checkbox"
              className="ml-2 mr-1"
              ref={register}
              name={`${skuCheckboxPrefix}${sappiSku}`}
            />
            {sappiSku}
          </>
        );
      },
    },
    {
      title: (
        <div className="text-lg font-bold">
          <Trans>Product</Trans>
        </div>
      ),
      key: 'product',
      render: (value, record) => <>{isMetric ? record.productMetric : record.product}</>,
    },
  ];

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={t`My Bookmarked Sappi SKUs`}
    >
      <form onSubmit={handleSubmit(doSubmit)}>
        <div className="bookmarked-sku-modal max-h-80 overflow-y-auto">
          <Table
            className="my-6 mx-3"
            dataSource={savedSkus}
            rowKey="kcode"
            columns={columns}
            pagination={false}
            locale={{ emptyText: t`No Sappi SKUs bookmarked.` }}
          />
        </div>
        <Button
          type="submit"
          theme="primary"
          className="ml-3 mt-3"
          disabled={disableButton}
        >
          <Trans>Add Sappi SKUs</Trans>
        </Button>
      </form>
    </Modal>
  );
};

export default BookmarkedSkusModal;
