import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import { UseFormMethods } from 'react-hook-form';
import formatAndSizeImage from '../../../../assets/dummy_sample/format_and_size.png';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';

interface IFormatAndSizeProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

const FormatAndSize: React.FunctionComponent<IFormatAndSizeProps> = ({
  register,
  errors,
}) => {
  return (
    <div className="border-r border-gray-light400 w-1/3">
      <h3 className="text-xl font-bold mb-6">
        <Trans>Format & Size</Trans>
      </h3>
      <div className="mr-10">
        <div className="mb-5">
          <img
            className="m-auto"
            src={formatAndSizeImage}
            alt={t`Format and Size`}
          />
        </div>
        <div className="flex justify-around">
          <InputLabel size="lg" text={t`Width`} className="w-36">
            <div className="flex items-center">
              <Input
                name="width"
                ref={register({ min: 100, max: 340, required: true })}
                width="full"
                type="number"
                error={errors?.width}
                required
              />
              <div className="ml-4">mm</div>
            </div>
          </InputLabel>
          <InputLabel size="lg" text={t`Height`} className="w-36">
            <div className="flex items-center">
              <Input
                name="height"
                ref={register({ min: 100, max: 480, required: true })}
                width="full"
                type="number"
                error={errors?.height}
                required
              />
              <div className="ml-4">mm</div>
            </div>
          </InputLabel>
        </div>
        <div className="mt-4">
          <div>
            <strong>
              <Trans>Max. Unprinted Dummy: </Trans>&nbsp;
            </strong>
            <span>340mm x 480mm</span>
          </div>
          <div>
            <strong>
              <Trans>Max. Printed Dummy: </Trans>&nbsp;
            </strong>
            <span>230mm x 305mm **</span>
          </div>
          <div>
            <Trans>** Only available for Galerie and Royal.</Trans>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormatAndSize;
