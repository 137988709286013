import { t, Trans } from '@lingui/macro';
import { Table as AntTable } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { noop } from 'lodash';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Table from '../../../../../components/Table/Table';
import useNumberFormatter from '../../../../../hooks/useNumberFormatter';
import PutUpCategory from '../../../../../types/PutUpCategory.enum';
import { StockLotItem } from '../../../../../types/StockLotItem.interface';
import TrashButton from '../../TrashButton';
import { StockLotBasketForm } from '../StockLotBasketForm.type';
import ChangeableWrappingTypeCell from './ChangeableWrappingTypeCell';
import CharacteristicsCell from './CharacteristicsCell';
import DescriptionCell from './DescriptionCell';
import { getAtpQuantity, getTotalQuantity } from './ItemsTable.util';
import QuantityCell from './QuantityCell';
import StaticWrappingTypeCell from './StaticWrappingTypeCell';

interface IItemsTableProps {
  register: UseFormMethods<StockLotBasketForm>['register'];
  errors: UseFormMethods<StockLotBasketForm>['errors'];
  items: StockLotItem[];
  onRemoveItem: (itemId: number) => void;
  onQuantityChange: (item: StockLotItem) => void;
}

const ItemsTable: FC<IItemsTableProps> = ({
  items,
  register,
  errors,
  onRemoveItem,
  onQuantityChange,
}) => {
  const { format } = useNumberFormatter();
  const totalQuantity = format(getTotalQuantity(items));
  const columns: ColumnsType<StockLotItem> = [
    {
      title: t`Stock Location`,
      key: 'plantLocation',
      className: 'align-top',
      dataIndex: 'plantLocation',
    },
    {
      title: t`Description`,
      key: 'description',
      className: 'align-top',
      render: (value, record) => (
        <DescriptionCell
          salesGradeDescription={record.salesGradeDescription}
          width={record.bwidth}
          length={record.blength}
          grammage={record.grammage}
        />
      ),
    },
    {
      title: t`Characteristics`,
      key: 'characteristics',
      className: 'align-top',
      render: (value, record) => <CharacteristicsCell item={record} />,
    },
    {
      title: t`Wrapping Type`,
      key: 'wrappingType',
      className: 'align-top',
      render: (value, record, index) =>
        record.wrappingTypeChangeable === 'X' ? (
          <ChangeableWrappingTypeCell
            register={register}
            externalWrappingType={record.externalWrappingType}
            index={index}
          />
        ) : (
          <StaticWrappingTypeCell
            externalWrappingType={record.externalWrappingType}
          />
        ),
    },
    {
      title: t`Quantity`,
      key: 'quantity',
      className: 'align-top',
      render: (value, record, index) => (
        <QuantityCell
          register={register}
          errors={errors}
          index={index}
          atpQuantity={record.atpQuantity}
          quantity={record.numberOfUnits}
          maxQuantity={record?.batches?.length || 0}
          onQuantityChange={(newQuantity) => {
            const newAtpQuantity = getAtpQuantity(
              newQuantity,
              record.batches || []
            );
            if (newAtpQuantity !== 0) {
              onQuantityChange({
                ...record,
                numberOfUnits: newQuantity,
                atpQuantity: newAtpQuantity,
              });
            }
          }}
        />
      ),
    },
    {
      title: t`Unit`,
      key: 'unit',
      className: 'align-top',
      render: (value, record) => (
        <>
          {record.productType === PutUpCategory.SHEET ? (
            <Trans>Pallet</Trans>
          ) : (
            <Trans>Roll</Trans>
          )}
        </>
      ),
    },
    {
      key: 'delete',
      className: 'align-baseline',
      render: (value, record) => (
        <TrashButton
          onClick={() => (record.id ? onRemoveItem(record.id) : noop)}
        />
      ),
    },
  ];
  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={items}
      pagination={false}
      summary={() => (
        <AntTable.Summary.Row>
          <AntTable.Summary.Cell
            colSpan={4}
            className="text-right font-bold text-lg border-r border-gray-light400"
            index={0}
          >
            <Trans>Total Qty</Trans>
          </AntTable.Summary.Cell>
          <AntTable.Summary.Cell
            colSpan={3}
            className="text-xl font-bold text-blue-corporate"
            index={1}
          >
            {totalQuantity} KG
          </AntTable.Summary.Cell>
        </AntTable.Summary.Row>
      )}
    />
  );
};

export default ItemsTable;
