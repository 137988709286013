import { t } from '@lingui/macro';
import { DateFormat } from '../../../hooks/useDateFormatter';
import { EMPTY_OPTION_SELECT } from '../../../services/Util/emptyOption.const';
import MeasurementSystemAbbr from '../../../types/MeasurementSystemAbbr.enum';
import Option from '../../../types/Option.type';
import UnitOfMeasure from '../../../types/UnitOfMeasure.enum';

export const MEASUREMENT_SYSTEM_OPTIONS: Option[] = [
  EMPTY_OPTION_SELECT,
  {
    value: MeasurementSystemAbbr.METRIC,
    label: t`Metric`,
  },
  {
    value: MeasurementSystemAbbr.IMPERIAL,
    label: t`Imperial`,
  },
];

export const ROLL_UNIT_OPTIONS: Option[] = [
  EMPTY_OPTION_SELECT,
  {
    value: UnitOfMeasure.RL,
    label: t`Rolls`,
  },
  {
    value: UnitOfMeasure.KG,
    label: t`KG`,
  },
  {
    value: UnitOfMeasure.LB,
    label: t`LB`,
  },
  {
    value: UnitOfMeasure.MSF,
    label: t`MSF`,
  },
  {
    value: UnitOfMeasure.MSM,
    label: t`MSM`,
  },
  {
    value: UnitOfMeasure.LF,
    label: t`LF`,
  },
  {
    value: UnitOfMeasure.LM,
    label: t`LM`,
  },
  {
    value: UnitOfMeasure.LY,
    label: t`LY`,
  },
];

export const SHEET_UNIT_OPTIONS: Option[] = [
  EMPTY_OPTION_SELECT,
  {
    value: UnitOfMeasure.PL,
    label: t`Pallets`,
  },
  {
    value: UnitOfMeasure.SH,
    label: t`Sheets`,
  },
  {
    value: UnitOfMeasure.CTN,
    label: t`Cartons`,
  },
  {
    value: UnitOfMeasure.KG,
    label: t`KG`,
  },
  {
    value: UnitOfMeasure.LB,
    label: t`LB`,
  },
  {
    value: UnitOfMeasure.MSF,
    label: t`MSF`,
  },
  {
    value: UnitOfMeasure.MSI,
    label: t`MSI`,
  },
  {
    value: UnitOfMeasure.MSM,
    label: t`MSM`,
  },
];

/* export const DATE_FORMAT_OPTIONS: Option[] = [
  EMPTY_OPTION_SELECT,
  {
    value: DateFormat.EU_SIMPLE,
    label: `${DateFormat.EU_SIMPLE}`,
  },
  {
    value: DateFormat.NA_SIMPLE,
    label: `${DateFormat.NA_SIMPLE}`,
  },
]; */
