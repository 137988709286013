import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import { forOwn, pick } from 'lodash';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { Button } from '../../../../components/Button/Button';
import useAnalytics from '../../../../hooks/useAnalytics';
import useUser from '../../../../hooks/useUser';
import { updateBasket } from '../../../../services/Basket';
import { AvailabilityBasketNA } from '../../../../types/AvailabilityBasketNA.interface';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import NaBasketStep from '../types/NaBasketStep.enum';
import StepComponentProps from '../types/StepComponentProps.interface';
import useContinueShopping from '../useContinueShopping';
import useNaBasketStore from '../useNaBasketStore.store';
import ContactInformationSection from './ContactInformationSection';
import CustomerSection from './CustomerSection';
import {
  getContactNotes1,
  getContactNotes2,
  getContactNotes3,
  getContactNotes4,
  prepareContactTelephone,
} from './DeliveryDetailsStep.util';
import DeliveryLocation from './DeliveryLocation';
import TypeOfOrderSection from './TypeOfOrderSection';
import { DeliveryDetailsForm } from './types/DeliveryDetailsForm.type';
import DeliveryDetailsFormField from './types/DeliveryDetailsFormFields.enum';
import useDefaultValues from './useDefaultValues';
import useSelectedShipToCustomer from './useSelectedShipToCustomer';

const DeliveryDetailsStep: FC<StepComponentProps> = ({ setCurrentStep }) => {
  const { continueShopping } = useContinueShopping();
  const { trackPageView } = useAnalytics();
  const queryCache = useQueryCache();
  const basket = useNaBasketStore(
    (state) => state.currentBasketSubmissionCandidate
  );
  const { defaultValues } = useDefaultValues(basket);
  const { data: user } = useUser();
  const formMethods = useForm<DeliveryDetailsForm>({
    mode: 'all',
    defaultValues,
  });
  const {
    register,
    watch,
    handleSubmit,
    formState: { isValid },
    getValues,
    errors,
  } = formMethods;
  const selectedShipToNumber = watch(DeliveryDetailsFormField.SELECTED_SHIP_TO);
  const { selectedShipToCustomer } = useSelectedShipToCustomer(
    selectedShipToNumber
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const getBasketToUpdate = (form: DeliveryDetailsForm) => {
    const {
      contactName,
      phone,
      email,
      receiving,
      deliveryInstructions,
      dedicatedContactNotes,
    } = form;
    if (basket) {
      const basketToUpdate: AvailabilityBasketNA = {
        ...basket,
        ...pick(form, [
          DeliveryDetailsFormField.SOLD_TO_PO,
          DeliveryDetailsFormField.SHIP_TO_PO,
          DeliveryDetailsFormField.ORDER_TYPE,
        ]),
        contactName: `${user?.firstName} ${user?.lastName}`,
        contactTelephone: prepareContactTelephone(user?.phone),
        contactNotes1: getContactNotes1(contactName, phone, email),
        contactNotes2: getContactNotes2(
          receiving,
          deliveryInstructions,
          dedicatedContactNotes
        ),
        contactNotes3: getContactNotes3(
          receiving,
          deliveryInstructions,
          dedicatedContactNotes
        ),
        contactNotes4: getContactNotes4(
          receiving,
          deliveryInstructions,
          dedicatedContactNotes
        ),
        shipToCustomer: selectedShipToCustomer
          ? {
              number: selectedShipToCustomer.customerNumber,
              name: selectedShipToCustomer.name1,
            }
          : undefined,
        shipToAddress: selectedShipToCustomer
          ? selectedShipToCustomer?.address1
          : form.shipToAddress,
        shipToCity: selectedShipToCustomer
          ? selectedShipToCustomer?.city
          : form.shipToCity,
        shipToCountryCode: selectedShipToCustomer
          ? selectedShipToCustomer?.countryAbrv
          : form.shipToCountryCode,
        shipToName: selectedShipToCustomer
          ? selectedShipToCustomer?.name1
          : form.shipToName,
        shipToPostalCode: selectedShipToCustomer
          ? selectedShipToCustomer?.postalCode
          : form.shipToPostalCode,
        orderType:
          form.orderType || basket.orderType || defaultValues.orderType,
      };
      return basketToUpdate;
    }
    return basket;
  };
  const updateAndStoreBasket = async (basketToUpdate: AvailabilityBasketNA) => {
    const updatedBasket = await updateBasket(basketToUpdate);
    useNaBasketStore.setState({
      currentBasketSubmissionCandidate: updatedBasket,
    });
    queryCache.invalidateQueries(QueryCacheName.BASKET);
  };

  const next = async (form: DeliveryDetailsForm) => {
    trackPageView('BASKET', 'NEXT_STEP');
    setHasError(false);
    try {
      setIsSubmitting(true);
      await updateAndStoreBasket(
        getBasketToUpdate(form) as AvailabilityBasketNA
      );
      setCurrentStep(NaBasketStep.SUBMIT_ORDER);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const previous = async () => {
    trackPageView('BASKET', 'PREVIOUS_STEP');
    const form = getValues();
    // remove erroroneous fields
    forOwn(form, (value, key) => {
      const typedKey = key as DeliveryDetailsFormField;
      if (errors[typedKey]) {
        form[typedKey] = '';
      }
    });
    setHasError(false);
    try {
      setIsSubmitting(true);
      await updateAndStoreBasket(
        getBasketToUpdate(form) as AvailabilityBasketNA
      );
      setCurrentStep(NaBasketStep.BASKET);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(next)} noValidate>
      <Space direction="vertical" size="large" className="w-full">
        {basket && (
          <Space direction="vertical" size="small" className="w-full">
            <CustomerSection customer={basket.soldToCustomer} />
            <DeliveryLocation
              defaultShipToCustomerNumber={basket?.shipToCustomer?.number}
              selectedShipToCustomer={selectedShipToCustomer}
              formMethods={formMethods}
            />
          </Space>
        )}
        <ContactInformationSection
          register={register}
          errors={errors}
          selectedShipToCustomer={selectedShipToCustomer}
        />
        <TypeOfOrderSection register={register} />
        <div className="flex justify-between">
          <Button onClick={continueShopping} disabled={isSubmitting}>
            <Trans>Continue Shopping</Trans>
          </Button>
          <Space size="middle">
            <Button onClick={() => previous()} disabled={isSubmitting}>
              <Trans>Previous</Trans>
            </Button>
            <Button
              type="submit"
              theme="primary"
              disabled={!isValid || isSubmitting}
            >
              <Trans>Next</Trans>
            </Button>
          </Space>
        </div>
        {hasError && (
          <div className="w-full text-red-red text-right">
            <Trans>An error has a occurred.</Trans>
          </div>
        )}
      </Space>
    </form>
  );
};

export default DeliveryDetailsStep;
