import React, { HTMLProps } from 'react';

const Note: React.FunctionComponent<HTMLProps<HTMLDivElement>> = ({
  className = '',
  children,
  ...rest
}) => {
  return (
    <div
      className={`px-3 py-3 bg-yellow-200 flex items-center ${className}`}
      {...rest}
    >
      <div className="pr-3 text-2xl">
        <i className="fa fa-exclamation-circle" aria-hidden="true" />
      </div>
      <div className="pr-10">{children}</div>
    </div>
  );
};

export default Note;
