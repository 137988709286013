import React, { FC } from 'react';
import ErrorTooltip from '../../components/ErrorTooltip/ErrorTooltip';

interface IInputWithErrorTipProps {
  showError: boolean;
  errorMessage: string;
}

const InputWithErrorTip: FC<IInputWithErrorTipProps> = ({
  showError,
  errorMessage,
  children,
}) => {
  return (
    <ErrorTooltip hasError={showError} message={errorMessage}>
      {children}
    </ErrorTooltip>
  );
};

export default InputWithErrorTip;
