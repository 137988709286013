import { t } from '@lingui/macro';
import { Space } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Select/Select';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';
import CalculationType from '../types/CalculationType.enum';

const CONVERSION_OPTIONS = [
  {
    value: '',
    label: t`Calculation Type`,
  },
  {
    value: CalculationType.MSM_TO_WEIGHT,
    label: CalculationType.MSM_TO_WEIGHT,
  },
  {
    value: CalculationType.WEIGHT_TO_MSM,
    label: CalculationType.WEIGHT_TO_MSM,
  },
];
const MIN_REQUIRED = 0.001;
const MAX_REQUIRED = 999999999.999;
interface IMetricCriteriaProps {
  register: UseFormMethods['register'];
  watch: UseFormMethods['watch'];
  errors: UseFormMethods['errors'];
}

const MetricCriteria: React.FunctionComponent<IMetricCriteriaProps> = ({
  register,
  watch,
  errors,
}) => {
  const metricConversionType = watch('metricConversionType');
  return (
    <Space direction="vertical" size="middle" className="w-full">
      <Select
        name="metricConversionType"
        ref={register({ required: true })}
        options={CONVERSION_OPTIONS}
        error={errors?.metricConversionType}
        required
      />
      <Input
        name="grammage"
        type="number"
        ref={register({
          required: true,
          min: 0.1,
          max: 9999.9,
          validate: (value) => validateNumberOfDecimalPlaces(value, 1),
        })}
        required
        error={errors?.grammage}
        placeholder={t`Grammage`}
      />
      {(metricConversionType === CalculationType.WEIGHT_TO_MSM ||
        isEmpty(metricConversionType)) && (
        <Input
          name="weight"
          type="number"
          ref={register({
            required: true,
            min: MIN_REQUIRED,
            max: MAX_REQUIRED,
          })}
          required
          error={errors?.weight}
          placeholder={t`Weight (kg)`}
        />
      )}
      {(metricConversionType === CalculationType.MSM_TO_WEIGHT ||
        isEmpty(metricConversionType)) && (
        <Input
          name="msm"
          type="number"
          ref={register({
            required: true,
            min: MIN_REQUIRED,
            max: MAX_REQUIRED,
          })}
          required
          error={errors?.msm}
          placeholder={t`Thousand Square Meters`}
        />
      )}
    </Space>
  );
};

export default MetricCriteria;
