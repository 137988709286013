import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SearchStepButtons from '../SearchStepButtons';
import { DeviationData } from '../types/DeviationData.interface';
import TrackAndTraceStep from '../types/TrackAndTraceStep.enum';
import { DeviationForm } from './DeviationForm.interface';
import DeviationFormField from './DeviationFormField.enum';
import ResultsTable from './ResultsTable';
import useDeviationDataStore from './useDeviationData.store';

interface ISearchStepProps {
  onSaveAndContinue: (formData: DeviationForm) => void;
  showExcessResultsMessage: boolean;
  data: DeviationData[];
}

const SearchStep: FC<ISearchStepProps> = ({
  onSaveAndContinue,
  showExcessResultsMessage,
  data,
}) => {
  const defaultValues = useDeviationDataStore((state) => state);
  const formMethods = useForm<DeviationForm>({
    mode: 'all',
    defaultValues,
  });
  const {
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    getValues,
    clearErrors,
  } = formMethods;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (errors.deviationRow) {
      setErrorMessage(t`Errors exist in above entries.`);
    } else {
      setErrorMessage(null);
    }
  }, [errors.deviationRow]);

  const isOnePlannedDateFilledIn = (): boolean =>
    getValues().deviationRow.some((row) => !!row.newPlannedDate);

  const saveAndContinue = (formData: DeviationForm) => {
    // For performance reasons we cannot use a validation rule on the ControlledDatePicker
    if (!isOnePlannedDateFilledIn()) {
      return;
    }
    setErrorMessage(null);
    onSaveAndContinue(formData);
  };

  const cancel = () => {
    // setTimeout required otherwise the value change doesn't take effect
    setTimeout(() => {
      const formValues = getValues();
      formValues.deviationRow.forEach((item, index) => {
        if (item.newPlannedDate) {
          setValue(
            `deviationRow.${index}.${DeviationFormField.NEW_PLANNED_DATE}`,
            null,
            { shouldValidate: false }
          );
          setValue(
            `deviationRow.${index}.${DeviationFormField.DEVIATION_REASON}`,
            '',
            { shouldValidate: false }
          );
        }
      });

      clearErrors('deviationRow');
    }, 0);
  };
  return (
    <FormProvider {...formMethods}>
      <form>
        <Space direction="vertical" size="middle" className="w-full">
          <ResultsTable
            data={data}
            step={TrackAndTraceStep.SEARCH}
            showExcessResultsMessage={showExcessResultsMessage}
          />
          <div className="text-right w-full">
            <SearchStepButtons
              onSaveAndContinue={handleSubmit(saveAndContinue)}
              onCancel={cancel}
              requirementsMessage={t`Enter at least one new planned date.`}
              errorMessage={errorMessage}
            />
          </div>
        </Space>
      </form>
    </FormProvider>
  );
};

export default SearchStep;
