import * as React from 'react';

interface IFirstTableRowProps {
  left?: React.ReactNode;
  right: React.ReactNode;
  headerHidden?: boolean;
}

const FirstTableRow: React.FunctionComponent<IFirstTableRowProps> = ({
  left,
  right,
  headerHidden,
}) => {
  return (
    <div
      className={`flex justify-between text-xl items-center text-black-black z-10 ${
        headerHidden ? 'my-5' : 'absolute top-28 right-0'
      } w-full`}
    >
      {!!left && (
        <div className="ml-4 mr-auto flex-grow-0">
          <strong>{left}</strong>
        </div>
      )}
      <div className="mr-4 ml-auto flex-grow-0">
        <strong>{right}</strong>
      </div>
    </div>
  );
};

export default FirstTableRow;
