import { every, filter, find, some } from 'lodash';
import { ConsumeRequest } from '../../types/ConsumeRequest.interface';
import { UploadedItem } from '../../types/UploadedItem.interface';

export const convertSelectedItemsToPoDelimitedRequests = (
  selectedRollPalletIds: boolean[],
  uploadedItems: UploadedItem[]
): ConsumeRequest[] => {
  const requests: ConsumeRequest[] = [];
  uploadedItems
    .filter(
      (item, index) =>
        selectedRollPalletIds[index] &&
        item.shipToName !== 'NON-CONSUMABLE ITEM!'
    )
    .forEach((item) => {
      const purchaseOrder =
        item.selectedPurchaseOrder || item.customerPurchaseOrder;
      const existingRequest = find(requests, { purchaseOrder });
      if (existingRequest) {
        existingRequest.barcodes.push(item.barCode || item.ifraCode);
      } else {
        requests.push({
          barcodes: [item.barCode || item.ifraCode],
          documentLineNumber: item.documentLineNumber,
          documentNumber: item.documentNumber,
          purchaseOrder,
          shipToName: item.shipToName,
          soldToCustomer: item.selectedCustomerNumber,
        });
      }
    });
  return requests;
};

export const getNonSubmittedRequests = (
  submitted: string[],
  requests: ConsumeRequest[]
): ConsumeRequest[] => {
  const nonSubmittedRequests: ConsumeRequest[] = [];
  requests.forEach((request) => {
    const isEveryBarcodeOfRequestSubmitted = every(
      request.barcodes,
      (barcode) => some(submitted, (submittedReq) => submittedReq === barcode)
    );
    if (!isEveryBarcodeOfRequestSubmitted) {
      nonSubmittedRequests.push({
        ...request,
        barcodes: filter(
          request.barcodes,
          (barcode) =>
            !some(submitted, (submittedReq) => submittedReq === barcode)
        ),
      });
    }
  });
  return nonSubmittedRequests;
};

export const areSelectedIdsAlreadySubmitted = (
  selected: boolean[],
  submittedResults: string[],
  results: UploadedItem[]
): boolean => {
  return every(selected, (isItemSelected, idx) => {
    if (!isItemSelected) {
      return true;
    }
    return !!find(
      submittedResults,
      (result) => result === results[idx].barCode
    );
  });
};
