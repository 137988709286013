export default interface DocumentType {
  documentTypes: DocumentTypes;
}

export enum DocumentTypes {
  INVOICE = 'INVOICE',
  WEIGHTLIST = 'WEIGHTLIST',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  CERTIFICATE_OF_ANALYSIS = 'CERTIFICATE_OF_ANALYSIS',
  BAR_CODE_WEIGHT_LIST = 'BAR_CODE_WEIGHT_LIST',
  CREDIT_NOTE = 'CREDIT_NOTE',
  DELIVERY = 'DELIVERY',
}
