enum CalculationType {
  MSF_TO_WEIGHT = 'MSF To Weight',
  WEIGHT_TO_MSF = 'Weight To MSF',
  MSM_TO_WEIGHT = 'MSM To Weight',
  WEIGHT_TO_MSM = 'Weight To MSM',
  CWT_TO_LINEAL_FOOT = 'CWT To Lineal Foot',
  CWT_TO_MSF = 'CWT To MSF',
  THOUSAND_SHEETS_TO_CWT = 'Thousand Sheets To CWT',
  TON_TO_LINEAL_FOOT = 'Ton To Lineal Foot',
  TON_TO_MSF = 'Ton To MSF',
  MSF_TO_TON = 'MSF To Ton',
  CWT_TO_THOUSAND_SHEETS = 'CWT To Thousand Sheets',
  CWT_TO_METRIC_TON = 'CWT To Metric Ton',
  METRIC_TON_TO_CWT = 'Metric Ton To CWT',
  TON_TO_METRIC_TON = 'Ton To Metric Ton',
}

export default CalculationType;
