import { t, Trans } from '@lingui/macro';
import { upperCase } from 'lodash';
import React from 'react';
import InformationModal from '../../../components/InformationModal/InformationModal';
import LabelValue from '../../../components/LabelValue/LabelValue';
import useWeightFormatter from '../../../hooks/useWeightFormatter';
import stripZeros from '../../../services/Util/stripZeros.util';
import { OrderOverviewRecord } from '../../../types/OrderOverviewRecord.interface';

interface IOrderInformationModalProps {
  visible: boolean;
  customerNumber: string;
  documentNumber: string;
  documentPosition: string;
  shipToPartyName: string;
  shipToPartyCity: string;
  customerOrderNumber: string;
  overview: OrderOverviewRecord;
  onClose: () => void;
}

const OrderInformationModal: React.FunctionComponent<IOrderInformationModalProps> = ({
  visible,
  customerNumber,
  documentNumber,
  documentPosition,
  shipToPartyName,
  shipToPartyCity,
  customerOrderNumber,
  overview,
  onClose,
}) => {
  let summaryFields;
  const { format: formatWeight } = useWeightFormatter();
  if (customerNumber) {
    summaryFields = [
      {
        label: t`Sappi Order #`,
        value: `${stripZeros(documentNumber)}/${stripZeros(documentPosition)}`,
      },
      {
        label: t`Delivery Location`,
        value: (
          <i>
            {shipToPartyName} / {shipToPartyCity}
          </i>
        ),
      },
      {
        label: t`My Order #`,
        value: stripZeros(customerOrderNumber),
      },
    ];
  }

  return (
    <>
      {overview && (
        <InformationModal
          visible={visible}
          title={<Trans>Order Information</Trans>}
          summaryFields={summaryFields || []}
          detailsTitle={<Trans>Progress Details</Trans>}
          onCancel={onClose}
        >
          <div className="flex overflow-auto w-full bg-gray-whiteSmoke200 border-t border-gray-light400 p-4">
            <div className="w-1/2">
              <LabelValue
                label={t`Product`}
                center
                inline
                inverse
                value={
                  <div>
                    {overview.materialDescription}
                    <br />
                    {overview.materialInfo}&nbsp;
                    {upperCase(overview.putupText)}
                  </div>
                }
              />
            </div>
            <div className="w-1/2">
              <LabelValue
                label={t`Order Total`}
                center
                inline
                inverse
                value={formatWeight(
                  overview.orderQuantity,
                  overview.orderQuantityKg
                )}
              />
              <LabelValue
                label={t`Pre-production`}
                center
                inline
                inverse
                value={formatWeight(
                  overview.openQuantity,
                  overview.openQuantityKg
                )}
              />
              <LabelValue
                label={t`Under Production`}
                center
                inline
                inverse
                value={formatWeight(
                  overview.underProductionQuantity,
                  overview.underProductionQuantityKg
                )}
              />
              <LabelValue
                label={t`Ready to Ship`}
                center
                inline
                inverse
                value={formatWeight(
                  overview.readyToShipQuantity,
                  overview.readyToShipQuantityKg
                )}
              />
              <LabelValue
                label={t`Shipped`}
                inverse
                center
                inline
                value={formatWeight(
                  overview.shippedQuantity,
                  overview.shippedQuantityKg
                )}
              />
            </div>
          </div>
        </InformationModal>
      )}
    </>
  );
};

export default OrderInformationModal;
