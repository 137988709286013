import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useEffect, useRef } from 'react';
import { Button } from '../../../components/Button/Button';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import useAnalytics from '../../../hooks/useAnalytics';
import useFinancialSummary from '../../../hooks/useFinancialSummary';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import { FinancialPeriod } from '../../../types/FinancialPeriod.interface';
import DownloadFinancials from '../DownloadFinancials';
import FinancialReportingDueSoon from './FinancialReportingDueSoon';
import FinancialReportingOverdue from './FinancialReportingOverdue';

interface IAccountActivityProps {
  customerNumber: string;
}

const AccountActivity: React.FunctionComponent<IAccountActivityProps> = ({
  customerNumber,
}) => {
  const { format } = useNumberFormatter();
  const { trackPageView } = useAnalytics();
  const trackPageViewRef = useRef({
    trackFinancialPageView: trackPageView,
  });
  const { data: financialSummary, isFetching } = useFinancialSummary(
    customerNumber
  );

  useEffect(() => {
    if (customerNumber) {
      const { trackFinancialPageView } = trackPageViewRef.current;
      trackFinancialPageView('REPORTING', 'FINANCIALSTATEMENTS', {
        customerId: customerNumber,
      });
    }
  }, [customerNumber]);

  return (
    <>
      {isFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        <div className="w-full">
          <h2 className="text-4xl mb-5">
            <Trans>Summary of Account Activity</Trans>
          </h2>
          <FinancialReportingOverdue
            customerNumber={customerNumber}
            totalOverdue={financialSummary?.totalOverdue || 0}
            lessThan1WeekPeriod={
              financialSummary?.periods[3] || ({} as FinancialPeriod)
            }
            between1and4WeekPeriod={
              financialSummary?.periods[2] || ({} as FinancialPeriod)
            }
            moreThan4WeekPeriod={
              financialSummary?.periods[1] || ({} as FinancialPeriod)
            }
          />
          <FinancialReportingDueSoon
            customerNumber={customerNumber}
            totalDue={financialSummary?.totalDue || 0}
            lessThan1WeekPeriod={
              financialSummary?.periods[4] || ({} as FinancialPeriod)
            }
            moreThan1WeekPeriod={
              financialSummary?.periods[5] || ({} as FinancialPeriod)
            }
          />
          <div className="border-gray-light400 border-t mt-5 flex justify-between">
            <Space>
              <DownloadFinancials
                customerNumber={customerNumber}
                period="all"
                sort="date"
                sortDirection="ASC"
              >
                <Button>
                  <i className="far fa-file-excel-o text-2xl mr-2" />
                  <span className="text-lg">
                    <Trans>Download All</Trans>
                  </span>
                </Button>
              </DownloadFinancials>
              <DownloadFinancials
                customerNumber={customerNumber}
                period="current"
                sort="date"
                sortDirection="ASC"
              >
                <Button>
                  <i className="far fa-file-excel-o text-2xl mr-2" />
                  <span className="text-lg">
                    <Trans>Download Current</Trans>
                  </span>
                </Button>
              </DownloadFinancials>
            </Space>
            <div
              className="text-right text-lg p-6 border-gray-light400 border"
              style={{ marginTop: '-1px' }}
            >
              <span>
                <Trans>TOTAL (Overdue + Due)</Trans>:&nbsp;
              </span>
              {financialSummary?.total ? (
                <strong>
                  {format(financialSummary?.total)}{' '}
                  {financialSummary?.periods[1]?.currency}
                </strong>
              ) : (
                <strong>0</strong>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountActivity;
