import { t } from '@lingui/macro';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import AgingMethodDescription from '../../types/AgingMethodDescription.enum';
import ConsignmentExpandedItems from '../../types/ConsignmentExpandedItems.interface';

export const getDerivedAgeBasis = (
  item: ConsignmentInventoryItem
): ConsignmentInventoryItem['ageBasis'] => {
  switch (item.agingMethodDescription) {
    case AgingMethodDescription.RCPT:
      return t`Receipt Date`;
    case AgingMethodDescription.SHIP:
      return t`Ship Date`;
    case AgingMethodDescription.REQD:
      return t`Requested Delivery Date`;
    case AgingMethodDescription.COND:
      return t`Confirmed Delivery Date`;
    case AgingMethodDescription.CDWD:
      return t`Customer Delivery Window`;
    default:
      return null;
  }
};

export const getDerivedAgeBasisDate = (
  item: ConsignmentInventoryItem
): ConsignmentInventoryItem['ageBasisDate'] => {
  switch (item.agingMethodDescription) {
    case AgingMethodDescription.RCPT:
      return item.customerReceiptDate;
    case AgingMethodDescription.SHIP:
      return item.customerReceiptDate;
    case AgingMethodDescription.REQD:
      return item.customerRequestedDelDate;
    case AgingMethodDescription.COND:
      return item.customerConfirmedDelDate;
    case AgingMethodDescription.CDWD:
      return item.cdwDate;
    default:
      return null;
  }
};

export const processConsignmentInventory = (
  consignmentInventory: ConsignmentInventoryItem[]
): ConsignmentInventoryItem[] => {
  return consignmentInventory.map((item) => ({
    ...item,
    details: item.details.map((detailItem) => ({
      ...detailItem,
      ageBasis: getDerivedAgeBasis(detailItem),
      ageBasisDate: getDerivedAgeBasisDate(detailItem),
    })),
  }));
};

export const getUniformExpandedItemsState = (
  items: ConsignmentInventoryItem[],
  newItemExpandedState: boolean
): ConsignmentExpandedItems => {
  const entriesByItemNumber = items.map((item) => [
    item.itemNumber,
    newItemExpandedState,
  ]);

  const uniformState = Object.fromEntries(entriesByItemNumber);

  return uniformState;
};

export const areAllItemsExpandedOrCollapsed = (
  expandedItems: ConsignmentExpandedItems
): { allExpanded: boolean; allCollapsed: boolean } => {
  const values = Object.values(expandedItems);

  if (values.length > 0) {
    const firstValue = values[0];

    const allItemsHaveTheSameValue = values.every(
      (value) => value === firstValue
    );

    if (allItemsHaveTheSameValue) {
      return {
        allExpanded: firstValue,
        allCollapsed: !firstValue,
      };
    }
  }

  return {
    allExpanded: false,
    allCollapsed: false,
  };
};
