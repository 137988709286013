import { t } from '@lingui/macro';
import { DataNode } from 'antd/lib/tree';
import { isArray } from 'lodash';
import generateOptions from '../../services/Util/generateOptions.util';
import {
  CHINESE_LANGUAGE_COUNTRIES,
  COUNTRIES,
  CZECH_LANGUAGE_COUNTRIES,
  DUTCH_LANGUAGE_COUNTRIES,
  ENGLISH_LANGUAGE_COUNTRIES,
  FINNISH_LANGUAGE_COUNTRIES,
  FRENCH_LANGUAGE_COUNTRIES,
  GERMAN_LANGUAGE_COUNTRIES,
  GREEK_LANGUAGE_COUNTRIES,
  HUNGARIAN_LANGUAGE_COUNTRIES,
  ITALIAN_LANGUAGE_COUNTRIES,
  POLISH_LANGUAGE_COUNTRIES,
  PORTUGUESE_LANGUAGE_COUNTRIES,
  ROMANIAN_LANGUAGE_COUNTRIES,
  RUSSIAN_LANGUAGE_COUNTRIES,
  SERBIAN_LANGUAGE_COUNTRIES,
  SLOVAK_LANGUAGE_COUNTRIES,
  SPANISH_LANGUAGE_COUNTRIES,
  SWEDISH_LANGUAGE_COUNTRIES,
  TURKISH_LANGUAGE_COUNTRIES,
} from './Countries.const';
import { CANADA_PROVINCES, USA_STATES } from './States.const';
import Language from './types/Language.enum';

const CASTING_RELEASE_PAPERS = t`Casting and Release Papers`;
const GRAPHICS_PAPER = t`Graphics Papers`;
export const PACKAGING_SPECIALTY_PAPERS = t`Packaging and Speciality Papers`;
const CONTAINER_BOARD = t`Container Board`;
const DYE_SUBLIMATION_PAPERS = t`Dye Sublimation Papers`;
const FLEXIBLE_PACKAGING_PAPERS = t`Flexible Packaging Papers`;
const FUNCTIONAL_PAPER_PACKAGING = t`Functional Paper Packaging`;
const INKJET_PAPERS = t`Inkjet Papers`;
const LABEL_PAPERS = t`Label Papers`;
const PAPERBOARD = t`Paperboard`;
const SILICONE_BASE_PAPERS = t`Silicone Base Papers`;

export const SAPPI_PRODUCTS: DataNode[] = [
  {
    title: CASTING_RELEASE_PAPERS,
    key: CASTING_RELEASE_PAPERS,
  },
  {
    title: GRAPHICS_PAPER,
    key: GRAPHICS_PAPER,
  },
  {
    title: PACKAGING_SPECIALTY_PAPERS,
    key: PACKAGING_SPECIALTY_PAPERS,
    children: [
      {
        title: CONTAINER_BOARD,
        key: CONTAINER_BOARD,
      },
      {
        title: DYE_SUBLIMATION_PAPERS,
        key: DYE_SUBLIMATION_PAPERS,
      },
      {
        title: FLEXIBLE_PACKAGING_PAPERS,
        key: FLEXIBLE_PACKAGING_PAPERS,
      },
      {
        title: FUNCTIONAL_PAPER_PACKAGING,
        key: FUNCTIONAL_PAPER_PACKAGING,
      },
      {
        title: INKJET_PAPERS,
        key: INKJET_PAPERS,
      },
      {
        title: LABEL_PAPERS,
        key: LABEL_PAPERS,
      },
      {
        title: PAPERBOARD,
        key: PAPERBOARD,
      },
      {
        title: SILICONE_BASE_PAPERS,
        key: SILICONE_BASE_PAPERS,
      },
    ],
  },
];

export const deriveBusinessUnitInterests = (
  selectedProducts: string[]
): string =>
  selectedProducts
    .filter((selectedProduct) =>
      SAPPI_PRODUCTS.some((product) => product.key === selectedProduct)
    )
    .join(', ');

export const deriveBusinessUnitSubInterests = (
  selectedProducts: string[]
): string =>
  selectedProducts
    .filter((selectedProduct) =>
      SAPPI_PRODUCTS.some((product) => {
        if (isArray(product.children)) {
          return product.children.some(
            (childProduct) => childProduct.key === selectedProduct
          );
        }
        return false;
      })
    )
    .join(', ');

export const addInParentProducts = (selectedProducts: string[]): string[] => {
  if (
    !selectedProducts.find((product) => product === PACKAGING_SPECIALTY_PAPERS)
  ) {
    const productIsChild = SAPPI_PRODUCTS[2].children?.some((child) =>
      selectedProducts.some((p) => p === child.key)
    );
    if (productIsChild) {
      return [...selectedProducts, PACKAGING_SPECIALTY_PAPERS];
    }
  }
  return selectedProducts;
};

export const COUNTRY_OPTIONS = generateOptions(
  COUNTRIES,
  (country) => ({ label: country, value: country }),
  {
    label: '',
    value: '',
  }
);

export const CANADA_PROVINCE_OPTIONS = generateOptions(
  CANADA_PROVINCES,
  (state) => ({ label: state, value: state }),
  {
    label: '',
    value: '',
  }
);

export const USA_STATE_OPTIONS = generateOptions(
  USA_STATES,
  (state) => ({ label: state, value: state }),
  {
    label: '',
    value: '',
  }
);

export const getLanguage = (country: string): Language => {
  if (CHINESE_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.CHINESE;
  }
  if (CZECH_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.CZECH;
  }
  if (DUTCH_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.DUTCH;
  }
  if (ENGLISH_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.ENGLISH;
  }
  if (FINNISH_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.FINNISH;
  }
  if (FRENCH_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.FRENCH;
  }
  if (GERMAN_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.GERMAN;
  }
  if (GREEK_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.GREEK;
  }
  if (HUNGARIAN_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.HUNGARIAN;
  }
  if (ITALIAN_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.ITALIAN;
  }
  if (POLISH_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.POLISH;
  }
  if (PORTUGUESE_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.PORTUGUESE;
  }
  if (ROMANIAN_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.ROMANIAN;
  }
  if (RUSSIAN_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.RUSSIAN;
  }
  if (SERBIAN_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.SERBIAN;
  }
  if (SLOVAK_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.SLOVAK;
  }
  if (SPANISH_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.SPANISH;
  }
  if (SWEDISH_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.SWEDISH;
  }
  if (TURKISH_LANGUAGE_COUNTRIES.some((c) => c === country)) {
    return Language.TURKISH;
  }
  return Language.UNKNOWN;
};

export const isPageNavigated = (): boolean => {
  // TrackPageView throws errors when page reloaded
  if (window.performance.getEntriesByType('navigation')) {
    const entryType = (window.performance.getEntriesByType(
      'navigation'
    )[0] as any).type;
    return entryType === 'navigate';
  }

  return false;
};
