import { Trans } from '@lingui/macro';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import SectionHeader from '../../../components/SectionHeader';
import phoneNumberOnlyInput from '../../../services/Util/phoneNumberOnlyInput.util';
import { VALID_EMAIL_REG_EX } from '../../../types/RegularExpression.constants';

const DeliveryInformation: React.FunctionComponent = () => {
  const data = useFormContext();
  return (
    <div className="pb-2">
      <div className="w-full py-4">
        <SectionHeader darkMode>
          <Trans>Delivery Information</Trans>
        </SectionHeader>
      </div>
      <div className="flex items-center w-1/3">
        <InputLabel
          size="lg"
          className="w-full pr-12"
          required
          text={<Trans>Name</Trans>}
        >
          <Input
            name="name"
            required
            ref={data.register({ required: true, minLength: 1 })}
            type="string"
            width="full mb-4"
            error={data.errors.name}
          />
        </InputLabel>
      </div>
      <div className="flex items-center w-1/3">
        <InputLabel
          size="lg"
          className="w-full pr-12"
          required
          text={<Trans>Phone Number</Trans>}
        >
          <Input
            name="phoneNumber"
            type="string"
            width="full"
            required
            ref={data.register({ required: true, minLength: 3, maxLength: 25 })}
            onKeyPress={phoneNumberOnlyInput}
            error={data.errors.phoneNumber}
          />
          <div className="mt-1 mb-4">
            Phone number should include country code
          </div>
        </InputLabel>
      </div>
      <div className="flex items-center w-1/3">
        <InputLabel
          size="lg"
          className="w-full pr-12"
          text={<Trans>E-mail Address</Trans>}
        >
          <Input
            name="emailAddress"
            type="string"
            width="full"
            ref={data.register({
              minLength: 3,
              maxLength: 30,
              pattern: {
                value: VALID_EMAIL_REG_EX,
                message: '',
              },
            })}
            error={data.errors.emailAddress}
          />
        </InputLabel>
      </div>
    </div>
  );
};

export default DeliveryInformation;
