import Axios from 'axios';
import { omit } from 'lodash';
import {
  deriveBusinessUnitInterests,
  deriveBusinessUnitSubInterests,
} from './Registration.util';
import { RegistrationForm } from './types/RegistrationForm.interface';
import RegistrationFormFields from './types/RegistrationFormFields.enum';
import { RegistrationRequest } from './types/RegistrationRequest.interface';

export const sendRegistration = async (
  form: RegistrationForm,
  captchaToken: string,
  captchaSiteKey: string
): Promise<unknown> => {
  const request: RegistrationRequest = {
    ...omit(form, [RegistrationFormFields.SAPPI_PRODUCTS]),
    businessUnitInterests: deriveBusinessUnitInterests(form.sappiProducts),
    businessUnitSubInterests: deriveBusinessUnitSubInterests(
      form.sappiProducts
    ),
    token: captchaToken,
    siteKey: captchaSiteKey,
  };
  const { data } = await Axios.post('/portal/resources/registration', request);
  console.log(JSON.stringify(data));
  return data;
};
