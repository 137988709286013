import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { ChangeEvent, FC, useState } from 'react';
import { useForm, ValidateResult } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Button } from '../../components/Button/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Table from '../../components/Table/Table';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { getPrintableLabels } from './PrintLabels.service';
import GetPrintableLabelsParams from './types/GetPrintableLabelsParams.type';
import PrintableLabel from './types/PrintableLabel.interface';

const LABEL_NUMS = 'labelNums';

interface FormData {
  [LABEL_NUMS]: (string | false)[];
}

interface ViewLabelsProps {
  searchParams?: GetPrintableLabelsParams;
}

const ViewLabels: FC<ViewLabelsProps> = ({ searchParams }) => {
  const [checkAllIsChecked, setCheckAllIsChecked] = useState<boolean>(false);

  const { data: searchResponse, isFetching } = useQuery(
    [QueryCacheName.PRINT_LABELS, { ...searchParams, all: true }],
    (_key, params: GetPrintableLabelsParams) =>
      params ? getPrintableLabels(params) : undefined,
    {
      enabled: searchParams,
      refetchOnWindowFocus: false,
      staleTime: 60000,
    }
  );

  const results = searchResponse?.results;
  const firstCheckboxIndex = results?.findIndex((label) => !!label.micsNumber);

  const allLabelNums: string[] =
    results
      ?.map((result) => result.micsNumber || '')
      ?.filter((labelNum) => !!labelNum) || [];

  const {
    formState: { isValid },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { [LABEL_NUMS]: allLabelNums.map(() => false) },
  });

  const toggleCheckAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue(LABEL_NUMS, allLabelNums, { shouldValidate: true });
      setCheckAllIsChecked(true);
      if (firstCheckboxIndex !== undefined) {
        trigger(`${LABEL_NUMS}[${firstCheckboxIndex}]`);
      }
    } else {
      reset({ [LABEL_NUMS]: allLabelNums.map(() => false) });
      setCheckAllIsChecked(false);
    }
  };

  const updateCheckAll = () => {
    const labelNumValues = getValues()[LABEL_NUMS];

    const allAreChecked = labelNumValues.every(
      (labelNumValue) => !!labelNumValue
    );
    if (allAreChecked) {
      setCheckAllIsChecked(true);
    }

    const allAreUnchecked = labelNumValues.every(
      (labelNumValue) => !labelNumValue
    );
    if (allAreUnchecked) {
      setCheckAllIsChecked(false);
    }
  };

  const doSubmit = (values: FormData) => {
    const labelNumsToSubmit = values[LABEL_NUMS];

    if (labelNumsToSubmit.length > 0) {
      const destinationUrl = new URL(
        '/portal/resources/labelPDFs',
        window.location.origin
      );

      labelNumsToSubmit.forEach((labelNumToSubmit) => {
        if (labelNumToSubmit) {
          destinationUrl.searchParams.append('micsNumber', labelNumToSubmit);
        }
      });

      window.open(destinationUrl.toString());
    }
  };

  const doReset = () => {
    reset({ [LABEL_NUMS]: allLabelNums.map(() => false) });
    setCheckAllIsChecked(false);
  };

  const validateAtLeastOneChecked = (): ValidateResult => {
    const labelNumValues = getValues()[LABEL_NUMS];

    const anyAreChecked = labelNumValues.some(
      (labelNumValue) => !!labelNumValue
    );

    return anyAreChecked;
  };

  const columns: ColumnsType<PrintableLabel> = [
    {
      key: 'sappiOrderNum',
      title: t`Sappi Order`,
      render: (value, record) => record.salesDocument,
    },
    {
      key: 'sappiOrderItemNum',
      title: t`Order Item`,
      render: (value, record) => record.salesDocumentItem,
    },
    {
      key: 'transactionIdNum',
      title: t`Trans ID`,
      render: (value, record) => record.transactionIDNumber,
    },
    {
      key: 'weightListNum',
      title: t`Weight List Number`,
      render: (value, record) => record.deliveryNumber,
    },
    {
      key: 'deliveryNote',
      title: t`Delivery Note`,
      render: (value, record) => record.deliveryNote,
    },
    {
      key: 'labelNum',
      title: (
        <Checkbox
          label={t`Label Number`}
          checked={checkAllIsChecked}
          onChange={toggleCheckAll}
        />
      ),
      render: (value, record, index) =>
        record.micsNumber ? (
          <Checkbox
            name={`${LABEL_NUMS}[${index}]`}
            ref={register(
              index === firstCheckboxIndex
                ? {
                    validate: { atLeastOneChecked: validateAtLeastOneChecked },
                  }
                : undefined
            )}
            label={record.micsNumber}
            value={record.micsNumber}
            onChange={() => {
              if (
                firstCheckboxIndex !== undefined &&
                index !== firstCheckboxIndex
              ) {
                trigger(`${LABEL_NUMS}[${firstCheckboxIndex}]`);
              }
              updateCheckAll();
            }}
          />
        ) : (
          <></>
        ),
    },
  ];

  return (
    <div>
      {isFetching ? (
        <LoadingSpinner horizontal />
      ) : (
        results && (
          <form onSubmit={handleSubmit(doSubmit)}>
            <Space direction="vertical" size="large" className="w-full">
              <Table
                columns={columns}
                dataSource={results}
                pagination={false}
                rowKey={(record: PrintableLabel) =>
                  `${record.salesDocument}${record.salesDocumentItem}${record.micsNumber}`
                }
              />
              <div className="flex justify-end">
                <Space size="middle">
                  <Button type="submit" theme="primary" disabled={!isValid}>
                    <Trans>Create PDF</Trans>
                  </Button>
                  <Button type="reset" onClick={doReset}>
                    <Trans>Clear Selections</Trans>
                  </Button>
                </Space>
              </div>
            </Space>
          </form>
        )
      )}
    </div>
  );
};

export default ViewLabels;
