import React, { FC } from 'react';
import DashboardPromo from '../Promo/DashboardPromo';
import MyContact from '../MyContact/MyContact';
import useRole from '../../hooks/useRole';
import { AuthorityRole } from '../../types/Authority.interface';

interface IPodTwoProps {
  className?: string;
}
const PodTwo: FC<IPodTwoProps> = ({ className = '' }) => {
  const { hasRole } = useRole();

  return (
    <div className={`${className}`}>
      {!hasRole(AuthorityRole.ROLE_SHIP_TO) &&
        !hasRole(AuthorityRole.ROLE_PROJECT_GROUP) && <MyContact />}
      <DashboardPromo />
    </div>
  );
};

export default PodTwo;
