import { t } from '@lingui/macro';
import { Space } from 'antd';
import { round } from 'lodash';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import CalculatorResponse from '../types/CalculatorResponse.interface';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import CalculatorTypeIdentifier from '../types/CalculatorTypeIdentifier.enum';
import { CalculatorUnitTypes } from '../types/CalculatorUnitTypes.enum';
import {
  EMPTY_CALC_TYPE_OPTION,
  getCalculationTypeOptions,
} from './PriceCalculators.util';
import useTradeBasisOptions, {
  EMPTY_TRADE_BASIS_OPTION,
} from '../useTradeBasisOptions';
import CalculationType from '../types/CalculationType.enum';
import { validateNumberOfDecimalPlaces } from '../../../services/Util/validateNumberOfDecimalPlaces.util';
import InputWithHiddenLabel from '../../../components/InputWithHiddenLabel/InputWithHiddenLabel';
import SelectWithHiddenLabel from '../../../components/SelectWithHiddenLabel/SelectWithHiddenLabel';
import { FRACTION_DECIMAL_INPUT } from '../../../types/RegularExpression.constants';
import useAnalytics from '../../../hooks/useAnalytics';
import useNumberFormatter from '../../../hooks/useNumberFormatter';
import PriceTonToPriceLinFootParams from '../types/PriceCalculators/PriceTonToPriceLinFootParams.interface';
import { getTonPriceTradeBasis } from './PricePerTon.util';
import {
  calculatePriceMsfToPriceTon,
  calculatePriceTonToPriceLinFoot,
  calculatePriceTonToPriceMetricTon,
  calculatePriceTonToPriceMsf,
} from '../Calculator.service';
import PriceTonToPriceMsfParams from '../types/PriceCalculators/PriceTonToPriceMsfParams.interface';
import PriceMsfToPriceTonParams from '../types/PriceCalculators/PriceMsfToPriceTonParams.interface';
import PriceTonToPriceMetricTonParams from '../types/PriceCalculators/PriceTonToPriceMetricTonParams.interface';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';

interface FormData {
  conversionType: string;
  tradeBasis: string;
  baseWeight: string;
  reelWidthImp: string;
  price: string;
}

const PricePerTon: FC = () => {
  const { trackPageView } = useAnalytics();

  const [calculationResult, setCalculationResult] = useState<
    CalculatorResponse
  >();
  const [priceLabel, setPriceLabel] = useState<string>();

  const { errors, formState, handleSubmit, register, reset, watch } = useForm({
    mode: 'all',
  });
  const { isValid } = formState;
  
  const { format } = useNumberFormatter();

  const conversionTypeWatch = watch('conversionType');

  const { tradeBasisOptions } = useTradeBasisOptions();

  const calculationTypeOptions = getCalculationTypeOptions(
    CalculatorTypeIdentifier.PRICE_TON_CONVERTER,
    CalculatorUnitTypes.IMP
  );

  const doReset = () => {
    reset();
  };

  const doCalculate = async (values: FormData) => {
    const {
      conversionType,
      tradeBasis,
      baseWeight,
      reelWidthImp,
      price,
    } = values;

    const tonPriceTradeBasis = getTonPriceTradeBasis(
      tradeBasis,
      tradeBasisOptions
    );

    let result;
    let newPriceLabel;

    /* eslint-disable default-case */
    switch (conversionType) {
      case CalculationType.TON_TO_LINEAL_FOOT:
        trackPageView('CALCULATORS', 'TON_TO_LINEAL_FOOT');
        newPriceLabel = t`per Lineal Foot`;

        if (
          baseWeight &&
          price &&
          reelWidthImp &&
          tonPriceTradeBasis &&
          tradeBasis
        ) {
          const params: PriceTonToPriceLinFootParams = {
            baseWeight,
            metImp: CalculatorUnitTypes.IMP,
            price,
            reelWidthImp,
            tonPriceTradeBasis,
            tradeBasis,
          };

          result = await calculatePriceTonToPriceLinFoot(params);
        }
        break;
      case CalculationType.TON_TO_MSF:
        trackPageView('CALCULATORS', 'TON_TO_MSF');
        newPriceLabel = t`per MSF`;

        if (baseWeight && price && tonPriceTradeBasis && tradeBasis) {
          const params: PriceTonToPriceMsfParams = {
            baseWeight,
            metImp: CalculatorUnitTypes.IMP,
            price,
            tonPriceTradeBasis,
            tradeBasis,
          };

          result = await calculatePriceTonToPriceMsf(params);
        }
        break;
      case CalculationType.MSF_TO_TON:
        trackPageView('CALCULATORS', 'MSF_TO_TON');
        newPriceLabel = t`per Ton`;

        if (baseWeight && price && tonPriceTradeBasis && tradeBasis) {
          const params: PriceMsfToPriceTonParams = {
            baseWeight,
            metImp: CalculatorUnitTypes.IMP,
            price,
            tonPriceTradeBasis,
            tradeBasis,
          };

          result = await calculatePriceMsfToPriceTon(params);
        }
        break;
      case CalculationType.TON_TO_METRIC_TON:
        trackPageView('CALCULATORS', 'TON_TO_METRIC_TON');
        newPriceLabel = t`per Metric Ton`;

        if (price) {
          const params: PriceTonToPriceMetricTonParams = {
            metImp: CalculatorUnitTypes.IMP,
            price,
          };

          result = await calculatePriceTonToPriceMetricTon(params);
        }
        break;
    }
    /* eslint-enable default-case */

    setCalculationResult(result);
    setPriceLabel(newPriceLabel);
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.PRICE_PER_TON}
      onCalculate={handleSubmit(doCalculate)}
      onReset={doReset}
      instructions={t`All fields must be filled in to calculate.`}
      result={
        <div>
          {calculationResult?.calcPrice !== undefined && (
            <>
              ${format(round(calculationResult.calcPrice || 0, 2)) || '0.00'} {priceLabel}
            </>
          )}
        </div>
      }
      isCalculateDisabled={!isValid}
    >
      <Space direction="vertical" size="middle" className="w-full">
        <SelectWithHiddenLabel
          name="conversionType"
          label={EMPTY_CALC_TYPE_OPTION.label}
          ref={register({ required: true })}
          options={calculationTypeOptions}
          defaultValue={EMPTY_CALC_TYPE_OPTION.value}
          required
        />
        {conversionTypeWatch !== CalculationType.TON_TO_METRIC_TON && (
          <>
            <SelectWithHiddenLabel
              name="tradeBasis"
              label={t`Select Trade Basis`}
              ref={register({
                required: true,
              })}
              options={tradeBasisOptions}
              defaultValue={EMPTY_TRADE_BASIS_OPTION.value}
              required
            />
            <InputWithHiddenLabel
              name="baseWeight"
              label={t`Basis Weight`}
              type="number"
              ref={register({
                required: true,
                min: 0.1,
                max: 9999.9,
                validate: (value) => validateNumberOfDecimalPlaces(value, 1),
              })}
              error={errors.baseWeight}
              required
            />
          </>
        )}
        {conversionTypeWatch === CalculationType.TON_TO_LINEAL_FOOT && (
          <InputWithHiddenLabel
            name="reelWidthImp"
            label={t`Roll Width (in)`}
            ref={register({
              required: true,
              pattern: FRACTION_DECIMAL_INPUT,
            })}
            error={errors.reelWidthImp}
            required
          />
        )}
        {!!conversionTypeWatch && (
          <InputWithHiddenLabel
            name="price"
            label={
              conversionTypeWatch === CalculationType.MSF_TO_TON
                ? t`Price Per MSF`
                : t`Price Per Ton`
            }
            type="number"
            ref={register({
              required: true,
              min: 0.01,
              max: 99999999.99,
              validate: (value) => validateNumberOfDecimalPlaces(value, 2),
            })}
            error={errors.price}
            required
          />
        )}
      </Space>
    </CalculatorTemplate>
  );
};

export default PricePerTon;
