import { useQuery } from 'react-query';
import QueryCacheName from '../../types/QueryCacheName.enum';
import { getHelpContent } from './Help.service';
import { Help } from './types/Help.interface';

const useHelp = (): {
  helps?: Help[];
  isFetching: boolean;
} => {
  const { data: helps, isFetching } = useQuery(
    [QueryCacheName.HELP],
    () => getHelpContent(),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );

  return {
    helps,
    isFetching,
  };
};

export default useHelp;
