import React, { FC } from 'react';

const DownloadReport: FC = () => {
  return (
    <a
      className="app-link px-3 border-l border-r border-gray-light400"
      href="/portal/resources/stocklot/excel"
      target="_excel"
    >
      <i className="far fa-file-excel-o text-blue-pacific text-2xl mr-2" />
      Download Report
    </a>
  );
};

export default DownloadReport;
