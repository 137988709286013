import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import Date from '../../../../components/Date';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import Textarea from '../../../../components/Textarea/Textarea';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { getDeliveryCostInformationForOrder } from '../../../../services/SamplesOrder';
import QueryCacheName from '../../../../types/QueryCacheName.enum';

interface IShippingInstructionsCellRightProps {
  defaultComment?: string;
  defaultCourierAccount?: string;
  readonly?: boolean;
}

const ShippingInstructionsCellRight: React.FunctionComponent<IShippingInstructionsCellRightProps> = ({
  defaultComment,
  defaultCourierAccount,
  readonly,
}) => {
  const { register, watch, errors } = useFormContext() || {
    watch: () => {},
  }; // watch: () => {} Used for readonly version
  const { data: deliveryCostDetails } = useQuery(
    QueryCacheName.SAMPLES_ORDER_DELIVERY_COST_DETAILS,
    getDeliveryCostInformationForOrder,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { format } = useNumberFormatter();
  const customCourier = readonly
    ? defaultCourierAccount
    : watch('customCourier');
  return (
    <Space direction="vertical" className="w-full">
      {readonly ? (
        <div className="mb-3">
          {defaultComment ? `"${defaultComment}"` : ''}
        </div>
      ) : (
        <Textarea
          name="comment"
          width="full"
          rows={5}
          placeholder={t`Please enter a message for the recipient`}
          ref={register({ maxLength: 35 })}
          defaultValue={defaultComment}
          error={errors.comment}
        />
      )}

      {deliveryCostDetails?.deliveryDate && (
        <LabelValue
          label={t`Estimated Delivery Date`}
          value={<Date date={deliveryCostDetails?.deliveryDate} />}
        />
      )}

      {readonly ? (
        <>
          {defaultCourierAccount && (
            <Trans>Courier Number: {defaultCourierAccount}</Trans>
          )}
        </>
      ) : (
        <>
          {customCourier && (
            <InputLabel
              text={
                <span className="text-xl font-bold">
                  <Trans>Account number of courier</Trans>:
                </span>
              }
              position="top"
            >
              <Input
                name="courierAccount"
                defaultValue={defaultCourierAccount}
                ref={register({ required: true, maxLength: 35 })}
                error={errors.courierAccount}
              />
            </InputLabel>
          )}
        </>
      )}
      {deliveryCostDetails?.deliveryCost && !customCourier && (
        <LabelValue
          label={t`Estimated Shipping Cost`}
          value={`${format(deliveryCostDetails?.deliveryCost)} ${
            deliveryCostDetails.currency
          }`}
        />
      )}
    </Space>
  );
};

export default ShippingInstructionsCellRight;
