import { t, Trans } from '@lingui/macro';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { upperCase } from 'lodash/fp';
import React, { useState } from 'react';
import { get } from 'lodash';
import { Button } from '../../../components/Button/Button';
import Date from '../../../components/Date';
import ShippingInformationModal from '../../../components/ShippingInformationModal/ShippingInformationModal';
import Table from '../../../components/Table/Table';
import useAnalytics from '../../../hooks/useAnalytics';
import useWeightFormatter from '../../../hooks/useWeightFormatter';
import stripZeros from '../../../services/Util/stripZeros.util';
import { OrderOverviewRecord } from '../../../types/OrderOverviewRecord.interface';
import OrderInformationModal from '../OrderInformationModal/OrderInformationModal';
import Status from './Status.enum';

interface IResultsTableProps {
  status: Status;
  results: OrderOverviewRecord[];
}

const ResultsTable: React.FunctionComponent<IResultsTableProps> = ({
  status,
  results,
}) => {
  const { format: formatWeight } = useWeightFormatter();
  const [currentOrderForModal, setCurrentOrderForModal] = useState<
    OrderOverviewRecord
  >();
  const [currentOrderOverviews, setCurrentOrderOverviews] = useState<
    OrderOverviewRecord[]
  >([]);
  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const { trackPageView } = useAnalytics();
  const columns: ColumnsType<OrderOverviewRecord> = [
    {
      title: <Trans>Shipping Location</Trans>,
      dataIndex: 'millName',
      key: 'millName',
      width: '10%',
    },
    {
      title: <Trans>Purchase Order #</Trans>,
      dataIndex: 'customerOrderNumber',
      key: 'customerOrderNumber',
    },
    {
      title: <Trans>Sappi Order #</Trans>,
      dataIndex: 'sappiOrderNum',
      key: 'sappiOrderNum',
      render: (value, record) => {
        return (
          <Button
            theme="link"
            onClick={() => {
              trackPageView('MILL_DIRECT', 'ORDER_DETAILS');
              setCurrentOrderForModal({ ...record });
              setShowOrderModal(true);
            }}
          >
            {stripZeros(record.documentNumber)}/
            {stripZeros(record.documentPosition)}
          </Button>
        );
      },
    },
    {
      title: <i className="fa fa-truck" />,
      dataIndex: 'shippingInformation',
      key: 'shippingInformation',
      render: (text, record) => {
        if (status === Status.READY_TO_SHIP || status === Status.SHIPPED) {
          return (
            <Button
              theme="link"
              onClick={() => {
                trackPageView('MILL_DIRECT', 'SHIPPING_INFO');
                setCurrentOrderForModal({ ...record });
                setCurrentOrderOverviews([{ ...record }]);
                setShowShippingModal(true);
              }}
              title={`${t`Transit information for order #`}
        ${record.customerOrderNumber}`}
            >
              <i className="fa fa-truck" />
            </Button>
          );
        }
        return <i className="fa fa-truck" />;
      },
      align: 'center',
    },
    {
      title: <Trans>Weight</Trans>,
      dataIndex: 'weight',
      key: 'weight',
      render: (value, record) => {
        switch (status) {
          case Status.OPEN:
            return formatWeight(record.openQuantity, record.openQuantityKg);
          case Status.READY_TO_SHIP:
            return formatWeight(
              record.readyToShipQuantity,
              record.readyToShipQuantityKg
            );
          case Status.UNDER_PRODUCTION:
            return formatWeight(
              record.underProductionQuantity,
              record.underProductionQuantityKg
            );
          case Status.SHIPPED:
            return formatWeight(
              record.shippedQuantity,
              record.shippedQuantityKg
            );
          default:
            return '--';
        }
      },
    },
    {
      title: <Trans>Product</Trans>,
      dataIndex: 'product',
      key: 'product',
      width: '23%',
      render: (value, record) => (
        <div>
          {record.materialDescription}
          <br />
          {record.materialInfo}&nbsp;
          {upperCase(record.putupText)}
        </div>
      ),
    },
    {
      title: (
        <div>
          <Trans>Sappi SKU</Trans>
          <br />
          <Trans>Customer Material #</Trans>
        </div>
      ),
      dataIndex: 'sappiNumber',
      key: 'sappiNumber',
      width: 180,
      render: (value, record) => {
        if (record.materialDescription) {
          if (
            record.kcode.length > 0 &&
            record.customerMaterialNumber.length > 0
          ) {
            return (
              <>
                {record.kcode} <br /> {record.customerMaterialNumber}
              </>
            );
          }
          if (record.kcode.length > 0) {
            return <>{record.kcode}</>;
          }
          return <>{record.customerMaterialNumber}</>;
        }
        return '';
      },
    },
    {
      title: <Trans>Order Placed Date</Trans>,
      dataIndex: 'sapDateCreated',
      key: 'sapDateCreated',
      render: (value, record) => <Date date={record.sapDateCreated} />,
    },
    {
      title: <Trans>Confirmed Delivery Date</Trans>,
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      render: (value, record) => <Date date={record.deliveryDate} />,
    },
    {
      title: <Trans>Updated Delivery Date</Trans>,
      dataIndex: 'deliveryDateUpd',
      key: 'deliveryDateUpd',
      render: (value, record) => <Date date={record.deliveryDateUpd} />,
    },
    {
      title: <Trans>Ship To</Trans>,
      dataIndex: 'shipTo',
      key: 'shipTo',
      width: '15%',
      render: (value, record) => (
        <Popover
          content={
            <div className="p-3">
              {record.shipToPartyName} [{record.shipToPartyCity}]
            </div>
          }
          trigger="hover"
          placement="left"
        >
          {record.shipToPartyName}
        </Popover>
      ),
    },
  ];
  return (
    <>
      <Table
        rowKey={(record) => `${status}-${record.id}`}
        dataSource={results}
        columns={columns}
        pagination={false}
      />
      <ShippingInformationModal
        visible={showShippingModal}
        customerNumber={currentOrderForModal?.customerNumber || ''}
        shipToPartyCity={currentOrderForModal?.shipToPartyCity || ''}
        shipToPartyName={currentOrderForModal?.shipToPartyName || ''}
        documentNumber={currentOrderForModal?.documentNumber || ''}
        customerOrderNumber={currentOrderForModal?.customerOrderNumber || ''}
        overviews={currentOrderOverviews}
        onClose={() => setShowShippingModal(false)}
      />
      <OrderInformationModal
        visible={showOrderModal}
        customerNumber={currentOrderForModal?.customerNumber || ''}
        shipToPartyCity={currentOrderForModal?.shipToPartyCity || ''}
        shipToPartyName={currentOrderForModal?.shipToPartyName || ''}
        documentNumber={currentOrderForModal?.documentNumber || ''}
        documentPosition={currentOrderForModal?.documentPosition || ''}
        customerOrderNumber={currentOrderForModal?.customerOrderNumber || ''}
        overview={currentOrderForModal as OrderOverviewRecord}
        onClose={() => setShowOrderModal(false)}
      />
    </>
  );
};

export default ResultsTable;
