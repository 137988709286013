import React, { FC } from 'react';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';

interface IInvoicePriceCellProps {
  item: AvailabilityBasketItem;
}

const InvoicePriceCell: FC<IInvoicePriceCellProps> = ({ item }) => {
  const { format } = useNumberFormatter();
  return (
    <>
      <div>
        {format(item.pricePerTon)} {item.currency}
      </div>
      <br /> <br />
      {item.productType === PutUpCategory.SHEET && (
        <div>
          {format(item.pricePer1kSheets)} {item.currency}
        </div>
      )}
    </>
  );
};

export default InvoicePriceCell;
