enum ButtonTheme {
  primary = 'primary',
  inverse = 'inverse',
  danger = 'danger',
  clear = 'clear',
  round = 'round',
  tiny = 'tiny',
  link = 'link',
}

export default ButtonTheme;
