import React, { FC, useEffect } from 'react';
import { Space } from 'antd';
import { t } from '@lingui/macro';
import { UseFormMethods } from 'react-hook-form';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import PutUpCategory from '../../../types/PutUpCategory.enum';
import { getProductType } from '../StockLot.util';
import useAllFieldValueOptions from '../useAllFieldValueOptions';
import useStockLotStore from '../useStockLotStore.store';

interface IStockLotFormProps {
  register: UseFormMethods['register'];
  setValue: UseFormMethods['setValue'];
  onSubmitSearch: () => void;
}

const StockLotFilterForm: FC<IStockLotFormProps> = ({
  register,
  setValue,
  onSubmitSearch,
}) => {
  const request = useStockLotStore((state) => state.request);
  const {
    gradeOptions,
    widthOptions,
    grammageOptions,
    lengthOptions,
    locationOptions,
    rollOptions,
    coreOptions,
    isFetching,
  } = useAllFieldValueOptions(request || undefined);

  // To assign values when page loaded
  useEffect(() => {
    if (!isFetching && request) {
      setValue(
        'gradeDescription',
        request?.gradeDescription !== 'All' ? request?.gradeDescription : ''
      );
      setValue('grammage', request?.grammage);
      setValue('width', request?.width);
      setValue('length', request?.length);
      setValue('location', request?.location);
      setValue('bReelDiameter', request?.bReelDiameter);
      setValue('coreDiameter', request?.coreDiameter);
    }
  }, [setValue, isFetching, request]);

  return (
    <form>
      <Space size="middle" className="w-full flex flex-wrap">
        <InputLabel text={t`Product`} size="lg">
          <Select
            name="gradeDescription"
            ref={register()}
            options={gradeOptions}
            width="full"
            style={{ minWidth: '230px' }}
            onChange={onSubmitSearch}
          />
        </InputLabel>
        <InputLabel text={t`Grammage`} size="lg">
          <Select
            name="grammage"
            ref={register()}
            options={grammageOptions}
            width="full"
            style={{ minWidth: '100px' }}
            onChange={onSubmitSearch}
          />
        </InputLabel>
        <InputLabel text={t`Width`} size="lg">
          <Select
            name="width"
            ref={register()}
            options={widthOptions}
            width="full"
            style={{ minWidth: '85px' }}
            onChange={onSubmitSearch}
          />
        </InputLabel>
        {getProductType(request?.productType) === PutUpCategory.SHEET && (
          <InputLabel text={t`Length`} size="lg">
            <Select
              name="length"
              ref={register()}
              options={lengthOptions}
              width="full"
              style={{ minWidth: '85px' }}
              onChange={onSubmitSearch}
            />
          </InputLabel>
        )}
        {getProductType(request?.productType) === PutUpCategory.ROLL && (
          <>
            <InputLabel text={t`Roll Diameter`} size="lg">
              <Select
                name="bReelDiameter"
                ref={register()}
                options={rollOptions}
                width="full"
                style={{ minWidth: '140px' }}
                onChange={onSubmitSearch}
              />
            </InputLabel>
            <InputLabel text={t`Core Diameter`} size="lg">
              <Select
                name="coreDiameter"
                ref={register()}
                options={coreOptions}
                width="full"
                style={{ minWidth: '140px' }}
                onChange={onSubmitSearch}
              />
            </InputLabel>
          </>
        )}
        <InputLabel text={t`Stock Location`} size="lg">
          <Select
            name="location"
            ref={register()}
            options={locationOptions}
            width="full"
            style={{ minWidth: '140px' }}
            onChange={onSubmitSearch}
          />
        </InputLabel>
      </Space>
    </form>
  );
};

export default StockLotFilterForm;
