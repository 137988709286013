import { Trans } from '@lingui/macro';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import useAnalytics from '../../../hooks/useAnalytics';

interface IOrderPlacedProps {
  prop?: unknown;
}

const OrderPlaced: React.FunctionComponent<IOrderPlacedProps> = (props) => {
  const { orderNumber } = useParams<{ orderNumber: string }>();
  const { trackPageView } = useAnalytics();
  const history = useHistory();

  const goBackToSamplesSearch = () => {
    trackPageView('SAMPLES', 'NEW_ORDER');
    history.push('/samples/selectBuyer');
  };
  return (
    <div>
      <Trans>
        Your Order has been sent and an email confirmation has been sent to you.
        Order Reference:
      </Trans>
      &nbsp;
      <strong>{orderNumber}</strong>
      <div className="mt-4">
        <Button theme="primary" onClick={() => goBackToSamplesSearch()}>
          <Trans>Create a New Order</Trans>
        </Button>
      </div>
    </div>
  );
};

export default OrderPlaced;
