import { t } from '@lingui/macro';
import { ValidateResult } from 'react-hook-form';
import useNumberFormatter from './useNumberFormatter';

const useValidator = (): {
  validateStringIsNum: (
    string: string,
    allowEmpty?: boolean,
    errorMessage?: string
  ) => ValidateResult;
} => {
  const { parse } = useNumberFormatter();

  const validateStringIsNum = (
    string: string,
    allowEmpty = true,
    errorMessage = t`Please enter a valid number`
  ): ValidateResult => {
    if (allowEmpty && string === '') {
      return true;
    }

    const parsed = parse(string);

    return parsed !== null ? true : errorMessage;
  };

  return { validateStringIsNum };
};

export default useValidator;
