import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';
import Select from '../../components/Select/Select';
import Textarea from '../../components/Textarea/Textarea';
import useLocale from '../../hooks/useLocale';
import { getLoginHelpContent } from '../../services/Content';
import showNotification from '../../services/Notification';
import { EMPTY_OPTION } from '../../services/Util/emptyOption.const';
import generateOptions from '../../services/Util/generateOptions.util';
import QueryCacheName from '../../types/QueryCacheName.enum';
import PageHeader from '../PageHeader/PageHeader';
import { sendLoginHelpRequest } from './LoginHelp.service';
import LoginHelpRequestParams from './types/LoginHelpRequestParams.interface';
import useAnalytics from '../../hooks/useAnalytics';

const PROBLEMS_ARRAY = [t`Forgot Password`, t`Other Login Problem`];

const LoginHelp: FC = () => {
  const { language } = useLocale();
  const history = useHistory();
  const {
    formState: { isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<LoginHelpRequestParams>({
    mode: 'all',
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { trackPageView } = useAnalytics();
  const { data: content } = useQuery(
    QueryCacheName.LOGIN_HELP,
    () => getLoginHelpContent(language || ''),
    {
      enabled: language,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const problemOptions = generateOptions(
    PROBLEMS_ARRAY,
    (problem) => ({
      label: problem,
      value: problem,
    }),
    EMPTY_OPTION
  );

  const doSubmit = async (values: LoginHelpRequestParams) => {
    
    setIsSubmitting(true);

    await sendLoginHelpRequest(values);

    showNotification({
      message: '',
      description: t`Your help request has been sent to Sappi for review.`,
    });
    reset();
    history.push('/login');

    setIsSubmitting(false);
  };

  return (
    <div>
      <PageHeader title={t`Having trouble logging in?`} items={[]} />
      <div className="flex">
        <form
          onSubmit={handleSubmit(doSubmit)}
          className="px-6 flex-grow-0 flex-shrink-0"
          style={{ width: '460px' }}
        >
          <Space direction="vertical" size="large" className="w-full">
            <InputLabel size="lg" text={t`How can we help?`} required>
              <Select
                name="problem"
                ref={register({ required: true })}
                options={problemOptions}
                required
              />
            </InputLabel>
            <InputLabel size="lg" text={t`User ID`} required>
              <Input
                name="userName"
                ref={register({ required: true })}
                type="text"
                required
              />
            </InputLabel>
            <InputLabel size="lg" text={t`First Name`} required>
              <Input
                name="firstName"
                ref={register({ required: true })}
                type="text"
                required
              />
            </InputLabel>
            <InputLabel size="lg" text={t`Last Name`} required>
              <Input
                name="lastName"
                ref={register({ required: true })}
                type="text"
                required
              />
            </InputLabel>
            <InputLabel size="lg" text={t`Note to Administrator`}>
              <Textarea name="note" ref={register()} cols={35} rows={5} />
            </InputLabel>
            <Space size="middle">
              <Button
                theme="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
              >
                <Trans>Submit</Trans>
              </Button>
              <Button
                theme="link"
                type="reset"
                className="text-lg font-bold"
                onClick={() => reset()}
                disabled={isSubmitting}
              >
                <Trans>Reset</Trans>
              </Button>
            </Space>
          </Space>
        </form>
        <div className="px-6">
          {content && (
            <div>
              {/* eslint-disable react/no-danger */}
              {content.header && (
                <div
                  dangerouslySetInnerHTML={{ __html: content.header }}
                  className="mb-4 text-3xl font-extrabold"
                />
              )}
              {content.body && (
                <div
                  dangerouslySetInnerHTML={{ __html: content.body }}
                  className="text-xl"
                />
              )}
              {/* eslint-enable react/no-danger */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginHelp;
