import React from 'react';
import useRole from '../../../hooks/useRole';
import { AuthorityRole } from '../../../types/Authority.interface';
import { StockAvailabilityOverview } from '../../../types/StockAvailabilityOverview.interface';
import AddStockItemToBasket from './AddStockItemToBasket';
import AltStockResults from './AltStockResults';
import { ResultRow } from './ResultRow.interface';
import StockOrMillResultTable from './StockOrMillResultTable';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';

interface IStockResultProps {
  data: ResultRow;
  compareStockData?: StockAvailabilityOverview;
  alert: React.ReactNode;
  isAltStockResult?: boolean;
  isStockExpanded?: boolean;
}

const StockResult: React.FunctionComponent<IStockResultProps> = ({
  data,
  compareStockData,
  alert,
  isAltStockResult,
  isStockExpanded,
}) => {
  const { hasRole } = useRole();
  const { measurementSystem } = useMeasurementSystem();

  return (
    <div className="-mx-4 border-gray-light400 border mb-8 text-gray-dark200">
      {alert}
      {measurementSystem === MeasurementSystem.METRIC && (
        <StockOrMillResultTable
          data={data.stockAvailabilities}
          resultTitle={
            hasRole(AuthorityRole.ROLE_RELEASE)
              ? data.stockAvailabilities[0].brand
              : data.stockAvailabilities[0].productNameMet
          }
          compareStockData={compareStockData}
          isAltStockResult={isAltStockResult}
        />
      )}
      {measurementSystem === MeasurementSystem.IMPERIAL && (
        <StockOrMillResultTable
          data={data.stockAvailabilities}
          resultTitle={
            hasRole(AuthorityRole.ROLE_RELEASE)
              ? data.stockAvailabilities[0].brand
              : data.stockAvailabilities[0].productName
          }
          compareStockData={compareStockData}
          isAltStockResult={isAltStockResult}
        />
      )}
      <AddStockItemToBasket
        data={data.stockAvailabilities[0]}
        isContainerExpanded={isStockExpanded}
      />
      {!isAltStockResult && !hasRole(AuthorityRole.ROLE_RELEASE) && (
        <AltStockResults data={data} />
      )}
    </div>
  );
};

export default StockResult;
