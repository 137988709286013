import BindingType from './BindingType.enum';

interface BindingTypeParams {
  isStitchedStapled?: boolean;
  isPerfectBoundGlued?: boolean;
  isNoBindingLoosePages?: boolean;
}

export const getBindingType = (
  params: BindingTypeParams
): BindingType | null => {
  if (params.isStitchedStapled) {
    return BindingType.STICHED_STAPLED;
  }
  if (params.isPerfectBoundGlued) {
    return BindingType.PERFECT_BOUND_GLUED;
  }
  if (params.isNoBindingLoosePages) {
    return BindingType.NO_BINDING_LOOSE_PAGES;
  }
  return null;
};
