import { Trans } from '@lingui/macro';
import * as React from 'react';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import SamplesProductItemDetailRow from './SamplesProductItemDetailRow';
import styles from './SamplesProductItemDetail.module.scss';

interface ISamplesProductItemDetailProps {
  product: SamplesProduct;
}

const SamplesProductItemDetail: React.FunctionComponent<ISamplesProductItemDetailProps> = ({
  product,
}) => {
  const surface = product.allocationProcedureDisplay || null;
  const { grammage, size, unitSize } = product;
  const unitSizeUnit =
    product.unitSizeUnit === 'ST' ? 'PCE' : product.unitSizeUnit;
  const displayUnit = `${unitSize} ${unitSizeUnit}`;

  return (
    <table className={`${styles['item-detail-table']} w-full`}>
      <tbody>
        {surface && (
          <SamplesProductItemDetailRow
            label={<Trans>Surface</Trans>}
            value={surface}
          />
        )}
        {grammage && (
          <SamplesProductItemDetailRow
            label={<Trans>Grammage</Trans>}
            value={grammage}
          />
        )}
        {size && (
          <SamplesProductItemDetailRow
            label={<Trans>Size</Trans>}
            value={size}
          />
        )}
        {displayUnit && (
          <SamplesProductItemDetailRow
            label={<Trans>Unit</Trans>}
            value={displayUnit}
          />
        )}
      </tbody>
    </table>
  );
};

export default SamplesProductItemDetail;
