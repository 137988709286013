import { t } from '@lingui/macro';
import React, { FC } from 'react';
import LabelValue from '../../../../../components/LabelValue/LabelValue';
import StockWeights from '../../../../../components/StockWeights';
import useMeasurementSystem from '../../../../../hooks/useMeasurementSystem';
import PutUpCategory from '../../../../../types/PutUpCategory.enum';
import { StockLotItem } from '../../../../../types/StockLotItem.interface';

interface ICharacteristicsCellProps {
  item: StockLotItem;
}

const CharacteristicsCell: FC<ICharacteristicsCellProps> = ({ item }) => {
  const { isMetric, isImperial } = useMeasurementSystem();
  return (
    <div>
      {item.productType === PutUpCategory.SHEET && (
        <>
          <StockWeights
            stacksPerPallet={+(item.stacksPerPallet || '')}
            sheetsPerReam={+(item.sheetsPerReam || '')}
            sheetsPerPallet={+(item.sheetsPerPallet || '')}
          />
          <div>{item.wrappingDescription}</div>
        </>
      )}
      {(item.productType === PutUpCategory.ROLL ||
        item.productType === PutUpCategory.CUTTER) && (
        <div>
          {isMetric && (
            <>
              <LabelValue
                inverse
                inline
                label={t`Roll Diameter`}
                value={
                  item.bReelDiameter ? item.bReelDiameter : item.reelDiameter
                }
              />
              <LabelValue
                inverse
                inline
                label={t`Roll Width`}
                value={item.bwidth ? item.bwidth : item.reelWidth}
              />
              <LabelValue
                inverse
                inline
                label={t`Core Diameter`}
                value={item.coreDiameter}
              />
            </>
          )}
          {isImperial && (
            <>
              <LabelValue
                inverse
                inline
                label={t`Roll Diameter`}
                value={item.realReelDiameterImp}
              />
              <LabelValue
                inverse
                inline
                label={t`Core Diameter`}
                value={item.coreDiameter}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CharacteristicsCell;
