import { forEach, sortBy } from 'lodash';
import { AvailabilityBasketItem } from '../types/AvailabilityBasketItem.interface';
import { AvailabilityBasketLastItemAdded } from '../types/AvailabilityBasketLastItemAdded.interface';
import { AvailabilityBasketNA } from '../types/AvailabilityBasketNA.interface';
import MeasurementSystem from '../types/MeasurementSystem.enum';

export const getLastItemAdded = (
  baskets: AvailabilityBasketNA[] | undefined,
  measurementSystem: MeasurementSystem
): AvailabilityBasketLastItemAdded | null => {
  let newestItem: AvailabilityBasketItem | undefined;
  let newestItemBasket: AvailabilityBasketNA | undefined;
  forEach(baskets, (basket) => {
    const sortedItems = sortBy(basket.items, ['lastUpdated']);
    const newestBasketItem: AvailabilityBasketItem =
      sortedItems[sortedItems.length - 1];
    if (
      !newestItem ||
      (newestBasketItem.lastUpdated || 0) > (newestItem.lastUpdated || 0)
    ) {
      newestItem = sortedItems[sortedItems.length - 1];
      newestItemBasket = basket;
    }
  });

  return newestItem && newestItemBasket
    ? {
        soldToCustomer: newestItemBasket.soldToCustomer,
        item: newestItem,
        basketCount: newestItemBasket.items.length,
        basketWeight:
          measurementSystem !== MeasurementSystem.METRIC
            ? newestItemBasket.totalQuantityLb
            : newestItemBasket.totalQuantityKg,
      }
    : null;
};
