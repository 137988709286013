import { t } from '@lingui/macro';
import { UseFormMethods } from 'react-hook-form';
import CoverOptionType from './CoverOptionType.enum';
import Option from '../../../../types/Option.type';
import PaperDifferentFromTextOptionType from './PaperDifferentFromTextOptionType.enum';

interface CoverOptionTypeParams {
  isFourPageCoverStandard?: boolean;
  isSixPageCoverGatefoldFront?: boolean;
  isSixPageCoverGatefoldRear?: boolean;
  isEightPageCoverGatefold?: boolean;
}

export const getCoverOptionType = (
  params: CoverOptionTypeParams
): CoverOptionType | null => {
  if (params.isEightPageCoverGatefold) {
    return CoverOptionType.EIGHT_PAGE;
  }
  if (params.isFourPageCoverStandard) {
    return CoverOptionType.FOUR_PAGE_STANDARD;
  }
  if (params.isSixPageCoverGatefoldFront) {
    return CoverOptionType.SIX_PAGE_STANDARD_FRONT;
  }
  if (params.isSixPageCoverGatefoldRear) {
    return CoverOptionType.SIX_PAGE_STANDARD_REAR;
  }
  return null;
};

export const paperDifferentFromTextOption: Option[] = [
  {
    value: PaperDifferentFromTextOptionType.EMPTY,
    label: '',
  },
  {
    value: PaperDifferentFromTextOptionType.TRUE,
    label: 'Yes',
  },
  {
    value: PaperDifferentFromTextOptionType.FALSE,
    label: t`No (selfCover)`,
  },
];

export const getInputValue = (
  getValues: UseFormMethods['getValues'],
  inputname: string
): number | null => {
  const gatefoldRear = getValues(inputname) as string | null | undefined;
  let gatefoldRearValue = null;
  if (gatefoldRear) {
    gatefoldRearValue = +gatefoldRear;
  }

  return gatefoldRearValue;
};
