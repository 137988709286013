import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { find } from 'lodash';
import { usePaginatedQuery } from 'react-query';
import { getOrderDetailsWithQuery } from '../../services/Order';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import LabelValue from '../../components/LabelValue/LabelValue';
import Date from '../../components/Date';
import FileClaimTable from './FileClaimTable';
import useUser from '../../hooks/useUser';
import Customer from '../../types/Customer.interface';
import QueryCacheName from '../../types/QueryCacheName.enum';
import FileClaimForm from './FileClaimForm';
import { getComplaintsBatches } from '../../services/Batch';
import { getComplaintsReasons } from '../../services/Complaints';
import getInvoiceInfo from '../../services/Invoice';
import { getLocale } from '../../services/Properties';

const FileClaim: FunctionComponent = () => {
  const { documentNumber, documentPosition } = useParams<{
    documentNumber: string;
    documentPosition: string;
  }>();

  const { data: user } = useUser();
  const [customer, setCustomer] = useState<Customer>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  
  
  const { resolvedData: order, isFetching } = usePaginatedQuery(
    [QueryCacheName.ORDER_DETAILS, documentNumber, documentPosition],
    getOrderDetailsWithQuery,
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: documentNumber && documentPosition,
      keepPreviousData: true,
    }
  );

  const {
    resolvedData: invoiceResponse,
    isFetching: isFetchingInvoice,
  } = usePaginatedQuery(
    [QueryCacheName.INVOICE_INFO, documentNumber, documentPosition],
    (key, number: string, position: string) => getInvoiceInfo(number, position),
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: documentNumber && documentPosition,
      keepPreviousData: true,
    }
  );

  const { resolvedData: batches, isFetching: loadingBatch } = usePaginatedQuery(
    [QueryCacheName.BATCH, documentNumber, documentPosition],
    getComplaintsBatches,
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: documentNumber && documentPosition,
      keepPreviousData: true,
    }
  );

  const {
    resolvedData: complaintsReasons,
    isFetching: loadingComplaintsReasons,
  } = usePaginatedQuery(
    [QueryCacheName.COMPLAINTS_REASONS],
    getComplaintsReasons,
    {
      refetchOnWindowFocus: false,
      staleTime: 600000,
      enabled: documentNumber && documentPosition,
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (user && order) {
      const { customers } = user;
      const foundCustomer = find(customers, { number: order.customerNumber });
      setCustomer(foundCustomer);
    }
  }, [user, order]);

  return (
    <>
      {errorMessage && (
        <div>
          <div className="py-1 pt-5 text-red-red">
            <Trans>Failed to create Complaint:</Trans> {errorMessage}
          </div>
        </div>
      )}
      {!isSubmitting && (
        <>
          {(isFetching ||
            loadingBatch ||
            loadingComplaintsReasons ||
            isFetchingInvoice) && <LoadingSpinner horizontal />}
          {!isFetching &&
            !loadingBatch &&
            !loadingComplaintsReasons &&
            order &&
            !isFetchingInvoice && (
              <div className="mb-12">
                <div className="flex mt-8 pb-3">
                  <div className="px-3" style={{ width: '30%' }}>
                    <LabelValue
                      inline
                      inverse
                      label={t`Order Date`}
                      value={<Date date={order.createDate} />}
                    />
                  </div>
                  <div className="px-3" style={{ width: '30%' }}>
                    <LabelValue
                      inline
                      inverse
                      label={t`Customer Name`}
                      value={customer?.name}
                    />
                  </div>
                </div>
                <div className="flex mb-8 mt-3">
                  <div className="px-3" style={{ width: '30%' }}>
                    <LabelValue
                      inline
                      inverse
                      label={t`Requested Delivery Date`}
                      value={<Date date={order.requestedDeliveryDate} />}
                    />
                  </div>
                  <div className="px-3" style={{ width: '30%' }}>
                    <LabelValue
                      inline
                      inverse
                      label={t`Delivery Location`}
                      value={
                        <div>
                          {order.overview.shipToPartyName}/
                          {order.overview.shipToPartyCity}
                        </div>
                      }
                    />
                  </div>
                </div>
                <FileClaimTable results={[order]} />
              </div>
            )}
        </>
      )}
      {!isFetching &&
        !loadingBatch &&
        !loadingComplaintsReasons &&
        order &&
        batches &&
        complaintsReasons &&
        !isFetchingInvoice && (
          <FileClaimForm
            order={order}
            batches={batches.batches}
            nonPaperItems={batches.nonPaperValues}
            complaintsReasons={complaintsReasons}
            invoiceResponse={invoiceResponse}
            onSubmitting={setIsSubmitting}
            onErrorMessage={setErrorMessage}
          />
        )}
      {!isFetching &&
        !loadingBatch &&
        !loadingComplaintsReasons &&
        !order &&
        !isFetchingInvoice && (
          <div className="py-1 pt-5 text-lg text-red-red">
            <Trans>
              There is no order found for documentNumber: {documentNumber} and
              documentPosition: {documentPosition}, thus the claim cannot be
              filed.
            </Trans>
          </div>
        )}
      {!isFetching &&
        !loadingBatch &&
        !loadingComplaintsReasons &&
        !batches &&
        !isFetchingInvoice && (
          <div className="py-1 pt-5 text-lg text-red-red">
            <Trans>
              There is no batch found for documentNumber: {documentNumber} and
              documentPosition: {documentPosition}, thus the claim cannot be
              filed.
            </Trans>
          </div>
        )}
    </>
  );
};

export default FileClaim;
