import { t, Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import Table from '../../../../components/Table/Table';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import './AlternateSizesTable.scss';
import { StockAvailabilityResult } from '../../../../types/StockAvailabilityResult.interface';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import { getItemUnit } from './AlternateSizes.util';
import CharacteristicsCell from './CharacteristicsCell';
import PackagingCell from './PackagingCell';

interface IAlternativesTableProps {
  results: StockAvailabilityResult[] | [];
  className?: string;
}
const AlternativesTable: React.FunctionComponent<IAlternativesTableProps> = ({
  results,
  className,
}) => {
  const { format: formatNumber } = useNumberFormatter();
  const columns: ColumnsType<StockAvailabilityResult> = [
    {
      title: <Trans>Available Stock</Trans>,
      key: 'availableStockOfAlternatives',
      width: '277px',
      render: (value, record) => (
        <>
          <div className="text-green-600">
            <LabelValue
              inline
              className="font-bold"
              label={t`Available Now`}
              value={record.quantity}
            />
          </div>
          <LabelValue
            inline
            noBold
            label={t`Stock Location`}
            value={record.txtPlant}
          />
          <LabelValue
            inline
            noBold
            label={t`Total Quantity`}
            value={t`${formatNumber(record.quantityKilograms)} KG`}
          />
        </>
      ),
    },
    {
      title: <Trans>Unit</Trans>,
      key: 'unitOfAlternatives',
      width: '85px',
      render: (value, record) => '',
    },
    {
      title: <Trans>Description</Trans>,
      key: 'descriptionOfAlternatives',
      width: '220px',
      render: (value, record) => (
        <>
          <strong>
            {record.txtGradeBrandMember} {record.grammage}
          </strong>
          {record.productType === PutUpCategory.SHEET && (
            <div>
              <Trans>
                (W) {record.width} cm x (L) {record.length} cm
              </Trans>
            </div>
          )}
        </>
      ),
    },
    {
      title: <Trans>Characteristics</Trans>,
      key: 'characteristicsOfAlternatives',
      width: '191px',
      render: (value, record) => <CharacteristicsCell record={record} />,
    },
    {
      title: <Trans>Packaging</Trans>,
      key: 'packagingOfAlternatives',
      width: '200px',
      render: (value, record) => <PackagingCell record={record} />,
    },
  ];

  return (
    <div className={`relative ${className}`}>
      <Table
        className="sappi-ant-override-order-booking-alternate-sizes-results"
        dataSource={results}
        columns={columns}
        pagination={false}
        showHeader={false}
        locale={{
          emptyText: (
            <div className="text-gray-600 text-2xl">
              <Trans>No product for alternatives found.</Trans>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default AlternativesTable;
