import { Trans } from '@lingui/macro';
import * as React from 'react';
import useNumberFormatter from '../../hooks/useNumberFormatter';
import BasketPopUpItemRow, {
  IBasketPopUpItemRowProps,
} from './BasketPopUpItemRow';

interface IBasketPopup extends React.FunctionComponent<IBasketPopupItemProps> {
  Row: React.FunctionComponent<IBasketPopUpItemRowProps>;
}
export interface IBasketPopupItemProps {
  customer: string;
  numItems: number;
  totalQuantity: number;
  totalQuantityUnit: string;
}

const BasketPopupItem: IBasketPopup = ({
  customer,
  numItems,
  totalQuantity,
  totalQuantityUnit,
  children,
}) => {
  const { format } = useNumberFormatter();
  return (
    <div className=" border-b border-gray-whiteSmoke200">
      {/* SUBHEADER */}
      <div className="px-4 py-3 font-medium border-b border-gray-light100 bg-gray-whiteSmoke100">
        <div>
          <strong>{customer}</strong>
        </div>
        <div className="flex justify-between">
          <div className="w-1/2">
            <div>
              {numItems} &nbsp;
              <Trans>item(s)</Trans>
            </div>
          </div>
          <div className="w-1/2">
            <Trans>
              Total Qty: {`${format(totalQuantity)} ${totalQuantityUnit}`}
            </Trans>
          </div>
        </div>
      </div>
      <div className="mx-3 my-1 p-2">{children}</div>
    </div>
  );
};

BasketPopupItem.Row = BasketPopUpItemRow;
export default BasketPopupItem;
