import Axios from 'axios';
import PackingListResponse from '../../domain/PackingListReporting/types/PackingListResponse.interface';
import { PackingListRequest } from '../../domain/PackingListReporting/types/PackingListRequest.interface';

export const getPackingList = async (
  request: PackingListRequest
): Promise<PackingListResponse> => {
  const { data } = await Axios.post(
    `/portal/resources/packing/${request.type}`,
    request.params
  );
  return data;
};
