import { Trans } from '@lingui/macro';
import * as React from 'react';
import DeconstructedTable, {
  SparseColumnType,
} from '../../../../components/DeconstructedTable/DeconstructedTable';
import DeconstructedTableBody from '../../../../components/DeconstructedTable/DeconstructedTableBody';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import DeconstructedTableBodyRow from '../../../../components/DeconstructedTable/DeconstructedTableBodyRow';
import DeconstructedTableHead from '../../../../components/DeconstructedTable/DeconstructedTableHead';
import DeconstructedTableHeadCell from '../../../../components/DeconstructedTable/DeconstructedTableHeadCell';
import DeconstructedTableHeadRow from '../../../../components/DeconstructedTable/DeconstructedTableHeadRow';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import './ViewSampleOrdersTable.scss';
import Order from './Order';

interface IViewSampleOrdersTableProps {
  orders: AugmentedSamplesHistoryOrder[];
  loading?: boolean;
}

const ViewSampleOrdersTable: React.FunctionComponent<IViewSampleOrdersTableProps> = ({
  orders,
  loading,
}) => {
  const columns: Partial<SparseColumnType<AugmentedSamplesHistoryOrder>>[] = [
    {
      key: 'order',
      title: <Trans>Order</Trans>,
    },
    {
      key: 'status',
      title: <Trans>Status</Trans>,
    },
    {
      key: 'product',
      title: <Trans>Product</Trans>,
    },
    {
      key: 'quantity_and_value',
      title: <Trans>Total quantity &amp; value</Trans>,
    },
    {
      key: 'ship_to',
      title: <Trans>Ship to</Trans>,
    },
    {
      key: 'actions',
      title: <Trans>Actions</Trans>,
    },
  ];

  return (
    <DeconstructedTable className="view-sample-orders-table" noStripes>
      <DeconstructedTableHead>
        <DeconstructedTableHeadRow>
          {columns.map((column) => (
            <DeconstructedTableHeadCell key={column.key}>
              {column.title}
            </DeconstructedTableHeadCell>
          ))}
        </DeconstructedTableHeadRow>
      </DeconstructedTableHead>
      <DeconstructedTableBody>
        {loading ? (
          <DeconstructedTableBodyRow>
            <DeconstructedTableBodyCell colSpan={columns.length}>
              <LoadingSpinner horizontal />
            </DeconstructedTableBodyCell>
          </DeconstructedTableBodyRow>
        ) : (
          <>
            {orders.map((order) => (
              <Order
                order={order}
                key={`${order.id}_${order.materialNumber}_${order.sapDateCreated}`}
              />
            ))}
            {!(orders.length > 0) && (
              <DeconstructedTableBodyRow>
                <DeconstructedTableBodyCell colSpan={columns.length}>
                  <Trans>No results returned, please try again</Trans>
                </DeconstructedTableBodyCell>
              </DeconstructedTableBodyRow>
            )}
          </>
        )}
      </DeconstructedTableBody>
    </DeconstructedTable>
  );
};

export default ViewSampleOrdersTable;
