import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { getUploadError } from '../TrackAndTrace.service';

interface IUploadSectionProps {
  style: React.CSSProperties;
}

const UploadSection: FC<IUploadSectionProps> = ({ style }) => {
  const { status } = useParams<{ status: string }>();
  const { data: errors } = useQuery(
    [QueryCacheName.TRACK_AND_TRACE_ERROR, status],
    () => getUploadError(status),
    {
      enabled: status && status !== 'success' && status !== 'error',
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
  const errorList = errors ? errors.exceptions : null;
  const {
    register,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  return (
    <form
      name="uploadForm"
      action="/portal/resources/trackAndTrace/upload"
      method="post"
      encType="multipart/form-data"
    >
      <Space direction="vertical" size="large" style={style}>
        <div>
          <div className="mb-2">
            <Trans>Deviation Report Upload</Trans>
          </div>
          <input
            name="file"
            size={45}
            type="file"
            ref={register({ required: true })}
          />
        </div>
        <Button theme="primary" disabled={!isValid}>
          <Trans>Upload File</Trans>
        </Button>
        <div>
          <Button theme="primary">
            <Trans>Return to Track and Trace Home</Trans>
          </Button>
          {status === 'success' && (
            <div className="mt-2">
              <Trans>Your upload was successfully submitted</Trans>
            </div>
          )}
          <div className="text-red-red mt-2">
            {status === 'error' && (
              <Trans>Please use a CSV, XLS, or XLSX file</Trans>
            )}
            {errorList && (
              <ul>
                {errorList.map((errorItem) => (
                  <li key={errorItem.id}>
                    <Trans>Error on line number</Trans> {errorItem.lineNumber} -{' '}
                    {errorItem.message}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Space>
    </form>
  );
};

export default UploadSection;
