import * as React from 'react';
import useDateFormatter, { DateFormat } from '../hooks/useDateFormatter';

interface IDateProps {
  date: number | Date;
  format?: DateFormat;
  useLocalTimezone?: boolean;
}

const Date: React.FunctionComponent<IDateProps> = ({
  date,
  format,
  useLocalTimezone,
}) => {
  const { format: formatDate } = useDateFormatter();
  return (
    <span>{date ? formatDate(date, format, useLocalTimezone) : '---'}</span>
  );
};

export default Date;
