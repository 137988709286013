import create from 'zustand';

type AvailableDocumentsPopoverNotificationState = {
  hidePopover: boolean;
  editColumnsHelpPopoverVisibility: boolean;
  editColumnsPopoverVisibility: boolean;
  setHidePopover: (hidePopover: boolean) => void;
  setEditColumnsHelpPopoverVisibility: (
    editColumnsHelpPopoverVisibility: boolean
  ) => void;
  setEditColumnsPopoverVisibility: (
    editColumnsPopoverVisibility: boolean
  ) => void;
};

const availableDocumentsPopoverNotificationStore = create<
  AvailableDocumentsPopoverNotificationState
>((set) => ({
  hidePopover: false,
  editColumnsHelpPopoverVisibility: false,
  editColumnsPopoverVisibility: false,
  setHidePopover: (hidePopover) => set({ hidePopover }),
  setEditColumnsHelpPopoverVisibility: (editColumnsHelpPopoverVisibility) =>
    set({ editColumnsHelpPopoverVisibility }),
  setEditColumnsPopoverVisibility: (editColumnsPopoverVisibility) =>
    set({ editColumnsPopoverVisibility }),
}));

export default availableDocumentsPopoverNotificationStore;
