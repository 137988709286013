import Axios from 'axios';
import { InvoiceResponse } from '../../types/InvoiceResponse.interface';

const getInvoiceInfo = async (
  docNumber: string,
  docPosition: string
): Promise<InvoiceResponse> => {
  const { data } = await Axios.get(
    `/portal/resources/invoice/${docNumber}/${docPosition}`
  );
  return data;
};

export default getInvoiceInfo;
