import * as React from 'react';
import Date from '../../../components/Date';
import { DateFormat } from '../../../hooks/useDateFormatter';
import { DocumentExportReport } from '../../../types/DocumentExportReport.interface';

interface IExcelPopoverRowProps {
  report: DocumentExportReport;
  onDownload: () => void;
}

const ExcelPopoverRow: React.FunctionComponent<IExcelPopoverRowProps> = ({
  report,
  onDownload,
}) => {
  return (
    <div className="text-gray-600">
      <a
        href={`/portal/resources/order/excel/${report.id}`}
        target="_blank"
        rel="noreferrer"
        onClick={() => onDownload()}
      >
        <i className="far fa-file-excel-o text-xl mr-1" />
        <span className="text-lg app-link">{report.fileName}</span>
      </a>
      <div className="ml-5 text-xs">
        <Date date={report.dateCreated} useLocalTimezone />
        &nbsp;
        <Date
          date={report.dateCreated}
          format={DateFormat.BASIC_TIME}
          useLocalTimezone
        />
      </div>
    </div>
  );
};

export default ExcelPopoverRow;
