import Option from '../../types/Option.type';
import { FilterType } from './types/FilterType.enum';
import { StockLotRequest } from './types/StockLotRequest.interface';
import useFieldValueOptions from './useFieldValueOptions';

const useAllFieldValueOptions = (
  stockLotRequest: StockLotRequest | undefined
): {
  divisionOptions: Option[];
  grammageOptions: Option[];
  gradeOptions: Option[];
  widthOptions: Option[];
  lengthOptions: Option[];
  locationOptions: Option[];
  rollOptions: Option[];
  coreOptions: Option[];
  isFetching: boolean;
} => {
  const {
    fieldValueOptions: divisionOptions,
    isFetching: isDivisionFetching,
  } = useFieldValueOptions(FilterType.DIVISION, stockLotRequest);
  
  const {
    fieldValueOptions: grammageOptions,
    isFetching: isGrammageFetching,
  } = useFieldValueOptions(FilterType.GRAMMAGE, stockLotRequest);

  const {
    fieldValueOptions: gradeOptions,
    isFetching: isGradeFetching,
  } = useFieldValueOptions(FilterType.GRADE_DESCRIPTION, stockLotRequest);

  const {
    fieldValueOptions: widthOptions,
    isFetching: isWidthFetching,
  } = useFieldValueOptions(FilterType.WIDTH, stockLotRequest);
  const {
    fieldValueOptions: lengthOptions,
    isFetching: isLengthFetching,
  } = useFieldValueOptions(FilterType.LENGTH, stockLotRequest);
  const {
    fieldValueOptions: locationOptions,
    isFetching: isLocationFetching,
  } = useFieldValueOptions(FilterType.LOCATION, stockLotRequest);
  const {
    fieldValueOptions: rollOptions,
    isFetching: isRollFetching,
  } = useFieldValueOptions(FilterType.B_REEL_PARAMETER, stockLotRequest);
  const {
    fieldValueOptions: coreOptions,
    isFetching: isCoreDiameterFetching,
  } = useFieldValueOptions(FilterType.CORE_DIAMETER, stockLotRequest);

  return {
    divisionOptions,
    grammageOptions,
    gradeOptions,
    widthOptions,
    lengthOptions,
    locationOptions,
    rollOptions,
    coreOptions,
    isFetching:
      isDivisionFetching ||
      isGrammageFetching ||
      isGradeFetching ||
      isWidthFetching ||
      isLengthFetching ||
      isLocationFetching ||
      isRollFetching ||
      isCoreDiameterFetching,
  };
};

export default useAllFieldValueOptions;
