import { t } from '@lingui/macro';
import { includes } from 'lodash';
import { useEffect } from 'react';
import useRole from '../../../../hooks/useRole';
import { getUserCustomization } from '../../../../services/User';
import { AuthorityRole } from '../../../../types/Authority.interface';
import { DocumentTypes } from '../../../../types/DocumentTypes.interface';
import useDynamicColumnsStore, { DynamicColumns } from './dynamicColumns.store';

const useDynamicColumns = () => {
  const { hasRole } = useRole();
  const { columns, set } = useDynamicColumnsStore();

  useEffect(() => {
    const updateColumns = async () => {
      const { printDocumentsColumns } = await getUserCustomization();
      const parsedColumns = JSON.parse(printDocumentsColumns);
      let otherColumnsToAdd: Partial<DynamicColumns> = {};
      if (hasRole(AuthorityRole.ROLE_TRACK_BY)) {
        otherColumnsToAdd = {
          [DocumentTypes.ORDER_CONFIRMATION]: {
            id: 'oc',
            display: t`Order Conf. #`,
            property: 'confirmationDocId',
            show: parsedColumns ? includes(parsedColumns, 'oc') : true,
            checked: false,
          },
          [DocumentTypes.INVOICE]: {
            id: 'in',
            display: t`Invoice #`,
            property: 'invoiceDocId',
            show: parsedColumns ? includes(parsedColumns, 'in') : true,
            checked: false,
          },
          [DocumentTypes.CREDIT_NOTE]: {
            id: 'cr',
            display: t`Credit/Debit Note`,
            property: 'creditNoteDocId',
            show: parsedColumns ? includes(parsedColumns, 'cr') : false,
            checked: false,
          },
        };
      }
      set((state) => {
        state.columns = {
          [DocumentTypes.WEIGHTLIST]: {
            ...state.columns[DocumentTypes.WEIGHTLIST],
            show: parsedColumns ? includes(parsedColumns, 'wl') : true,
          },
          [DocumentTypes.DELIVERY]: {
            ...state.columns[DocumentTypes.DELIVERY],
            show: parsedColumns ? includes(parsedColumns, 'de') : false,
          },
          [DocumentTypes.BAR_CODE_WEIGHT_LIST]: {
            ...state.columns[DocumentTypes.BAR_CODE_WEIGHT_LIST],
            show: parsedColumns ? includes(parsedColumns, 'bc') : false,
          },
          [DocumentTypes.CERTIFICATE_OF_ANALYSIS]: {
            ...state.columns[DocumentTypes.CERTIFICATE_OF_ANALYSIS],
            show: parsedColumns ? includes(parsedColumns, 'coa') : false,
          },
          ...otherColumnsToAdd,
        };
      });
    };
    updateColumns();
  }, [hasRole, set]);

  return {
    dynamicColumns: columns,
    setDynamicColumnVisibility: (type: DocumentTypes, visible: boolean) => {
      set((state) => {
        state.columns[type].show = visible;
      });
    },
    setDynamicColumnChecked: (type: DocumentTypes, checked: boolean) => {
      set((state) => {
        state.columns[type].checked = checked;
      });
    },
  };
};

export default useDynamicColumns;
