import { t } from '@lingui/macro';
import {
  capitalize,
  each,
  filter,
  get,
  groupBy,
  has,
  uniqBy,
  values,
} from 'lodash';
import moment from 'moment';
import { AvailabilityBasketItem } from '../../../types/AvailabilityBasketItem.interface';
import { AvailabilityBasketNA } from '../../../types/AvailabilityBasketNA.interface';
import { ProductionPlan } from '../../../types/ProductionPlan.interface';
import Region from '../../../types/Region.enum';
import { StockAvailabilityOverview } from '../../../types/StockAvailabilityOverview.interface';
import StorageLocation from '../../../types/StorageLocation.enum';
import addToAvailabilityBasketItem from '../../../Util/Basket/addToAvailabilityBasketItems';
import { Inventory } from './Inventory.interface';
import { ResultRow } from './ResultRow.interface';
import { SavedSku } from '../../../types/SavedSkus.interface';
import AvailabilityBasketType from '../../../types/AvailabilityBasketType.enum';

export const getInventoryCount = (
  inventory: Inventory,
  stockInventoryVisible: boolean,
  millInventoryVisible: boolean,
  plannedProductionVisible: boolean
): number => {
  let results = 0;
  if (stockInventoryVisible) {
    results += inventory.stock.length;
  }
  if (plannedProductionVisible) {
    results += inventory.productionPlans.length;
  }
  if (millInventoryVisible) {
    results += inventory.mill.length;
  }
  return results;
};

export const isSkuSaved = (savedSkus: SavedSku[], sku: string): boolean => {
  return savedSkus.findIndex((s) => s.kcode === sku) > -1;
};

const assignResultRow = (stock: StockAvailabilityOverview): ResultRow => {
  return {
    kcode: stock.kcode,
    stockAvailabilities: [stock],
    brand: stock.brand,
    grammage: stock.grammage,
    usBaseWeight: stock.usBaseWeight,
    grainDirection: stock.grainDirection,
    lengthInches: stock.lengthInches,
    widthInches: stock.widthInches,
    storageLocation: stock.storageLocation as StorageLocation,
    productType: stock.productType,
  };
};

const getInventoryFromResultArray = (resultRows: ResultRow[]): Inventory => {
  return {
    stock: resultRows.filter(
      (row) =>
        row.stockAvailabilities.length > 0 && row.storageLocation !== 'AM'
    ),
    mill: filter(
      resultRows,
      (row) =>
        row.stockAvailabilities.length > 0 && row.storageLocation === 'AM'
    ),
    productionPlans: filter(
      uniqBy(resultRows, 'plannedUniqueName'),
      (row) => get(row, 'productionPlansByPlant.length', 0) > 0
    ),
  };
};

const addPlannedUniqueName = (resultRows: ResultRow[]): ResultRow[] => {
  const generateUniqueName = (row: ResultRow): string => {
    let plannedUniqueName = '';
    const productType = get(row, 'productionPlansByPlant[0][0].prodType', '');
    if (row.brand !== '') {
      plannedUniqueName = `${row.brand}:${row.grammage}:${row.usBaseWeight}:${productType}`;
    }
    // Added to handle results which still have planned production items but no stock items.
    else if (get(row, 'productionPlansByPlant.length', 0) > 0) {
      const gradeName = get(row, 'productionPlansByPlant[0][0].gradeName', '');
      const grammage = get(row, 'productionPlansByPlant[0][0].grammage', '');
      const baseWeight = get(
        row,
        'productionPlansByPlant[0][0].tradeBasis',
        ''
      );
      const grade = get(row, 'productionPlansByPlant[0][0].grade', '');
      plannedUniqueName = `${grade}:${gradeName}:${grammage}:${baseWeight}:${productType}`;
    }
    return plannedUniqueName;
  };
  return resultRows.map((row) => ({
    ...row,
    plannedUniqueName: generateUniqueName(row),
  }));
};

// TODO: WARNING: Code ported over and needs some TLC refactoring. Enter at your own risk
export const processResults = (
  stockOverviews: StockAvailabilityOverview[],
  productionPlans: ProductionPlan[]
): Inventory => {
  const result: { [key: string]: ResultRow } = {};
  each(stockOverviews, (stock) => {
    if (has(result, stock.kcode)) {
      if (stock.kcode) {
        stock.isCpStock = false;
        result[stock.kcode].stockAvailabilities.push(stock);
      } else if (stock.materialNumber.trim().length === 9) {
        stock.isCpStock = true;
        if (
          result[
            stock.brand +
              stock.bookweight +
              stock.usBaseWeight +
              stock.productType
          ] === undefined
        ) {
          result[
            stock.brand +
              stock.bookweight +
              stock.usBaseWeight +
              stock.productType
          ] = assignResultRow(stock);
        } else {
          result[
            stock.brand +
              stock.bookweight +
              stock.usBaseWeight +
              stock.productType
          ].stockAvailabilities.push(stock);
        }
      } else {
        stock.isCpStock = false;
        if (result[stock.grammage + stock.grade + stock.weight] === undefined) {
          result[stock.grammage + stock.grade + stock.weight] = assignResultRow(
            stock
          );
        } else {
          result[
            stock.grammage + stock.grade + stock.weight
          ].stockAvailabilities.push(stock);
        }
      }
    } else if (stock.materialNumber.trim().length === 9) {
      stock.isCpStock = true;
      if (
        result[
          stock.brand +
            stock.bookweight +
            stock.usBaseWeight +
            stock.productType
        ] === undefined
      ) {
        result[
          stock.brand +
            stock.bookweight +
            stock.usBaseWeight +
            stock.productType
        ] = assignResultRow(stock);
      } else {
        result[
          stock.brand +
            stock.bookweight +
            stock.usBaseWeight +
            stock.productType
        ].stockAvailabilities.push(stock);
      }
    } else {
      stock.isCpStock = false;
      result[stock.kcode] = assignResultRow(stock);

      result[stock.kcode].productionPlans = [];

      for (let i = 0; i < productionPlans.length; i++) {
        const plan = productionPlans[i];
        if (
          stock.grade === plan.grade &&
          stock.grammage === plan.grammage &&
          stock.productType === plan.prodType
        ) {
          result[stock.kcode].productionPlans?.push(plan);
          plan.matched = true;
        }
      }
    }
  });

  const resultArray = values(result);

  const unmatched = groupBy(
    filter(productionPlans, (plan) => !plan.matched),
    (plan) => `${plan.grade}_${plan.grammage}_${plan.prodType}`
  );

  each(unmatched, (plans) => {
    resultArray.push({
      brand: '',
      grammage: '',
      grainDirection: '',
      kcode: '',
      lengthInches: '',
      widthInches: '',
      productType: '',
      storageLocation: StorageLocation.A1,
      usBaseWeight: '',
      stockAvailabilities: [],
      productionPlans: plans,
    });
  });

  each(resultArray, (availability) => {
    availability.productionPlansByPlant = values(
      groupBy(availability.productionPlans, 'plantName')
    );
  });

  each(resultArray, (stock) => {
    if (
      stock.stockAvailabilities[0] &&
      stock.stockAvailabilities[0].isCpStock
    ) {
      stock.stockAvailabilities[0].productName = `${stock.brand} ${stock.stockAvailabilities[0].bookweight} ${stock.usBaseWeight}`;
    } else if (
      stock.stockAvailabilities[0] &&
      stock.stockAvailabilities &&
      stock.stockAvailabilities[0].productName.indexOf(stock.brand) === -1
    ) {
      stock.stockAvailabilities[0].productName = `${stock.brand} ${stock.stockAvailabilities[0].productName}`;
    }
  });

  return getInventoryFromResultArray(addPlannedUniqueName(resultArray));
};

export const getUnitFromStockAvailOv = (
  region: Region,
  stockAvailOv: StockAvailabilityOverview
): string => {
  return capitalize(
    region === Region.NA && stockAvailOv.unitOfMeasure === 'reel'
      ? t`Roll`
      : stockAvailOv.unitOfMeasure
  );
};

export function toAvailabilityBasketItem(
  row: StockAvailabilityOverview,
  selectedMeasureUnit?: string,
  quantity?: number
): AvailabilityBasketItem | null {
  if (row.materialNumber) {
    const baseResult: AvailabilityBasketItem = {
      id: null,
      basketType: AvailabilityBasketType.STOCK,
      bookweight: row.bookweight,
      grainDirection: row.grainDirection || '',
      product: row.brand,
      skuCode: row.kcode || '',
      requestedQuantity: quantity,

      packageTypeDesc: row.wrapDescription || '',
      tradeBasis: row.usBaseWeight,
      grammage: row.grammage,

      requestedUnitOfMeasure: selectedMeasureUnit,
      productType: row.productType,
      reelWrapping: row.reelWrapping || '',
      reelPackage: row.reelPackage || '',
      materialNumber: `${row.grade}-${row.grammage}${row.productType}`,
      coreDiameter: row.coreDiameter || 0,
      coreDiameterImp: row.coreDiameterImp,
      weight: row.weight,
      windingDirection: row.windingDirection,
      requestedDate: moment().add(1, 'days').valueOf(),
    };

    return addToAvailabilityBasketItem(baseResult, row);
  }
  return null;
}

export const addStockRowToNaBasket = (
  rowToAdd: StockAvailabilityOverview,
  customerBasket: AvailabilityBasketNA | null,
  selectedMeasureUnit?: string,
  quantity?: number
): AvailabilityBasketNA | null => {
  if (customerBasket) {
    return {
      ...customerBasket,
      items: customerBasket.items.concat([
        toAvailabilityBasketItem(rowToAdd, selectedMeasureUnit, quantity),
      ] as AvailabilityBasketItem[]),
    };
  }
  return customerBasket;
};

const getProdPlanMaterialNumber = (plan: ProductionPlan) =>
  plan ? `${plan.grade}-${plan.grammage}${plan.prodType}` : undefined;

export const addPlanRowToNaBasket = (
  plan: ProductionPlan,
  bookweight: number,
  customerBasket: AvailabilityBasketNA | null,
  selectedMeasureUnit?: string,
  quantity?: number
): AvailabilityBasketNA | null => {
  const product = plan.gradeName || null;
  const materialNumber = getProdPlanMaterialNumber(plan);
  if (customerBasket) {
    return {
      ...customerBasket,
      items: customerBasket.items.concat([
        {
          basketType: AvailabilityBasketType.PRODUCT,
          materialNumber,
          requestedQuantity: quantity,
          requestedUnitOfMeasure: selectedMeasureUnit,
          bookweight,
          material: product,
          tradeBasis: plan.tradeBasis,
          finish: plan.surface,
          requestedDate: moment(new Date()).add(1, 'days').valueOf(),
          productType: plan.prodType,
        },
      ] as AvailabilityBasketItem[]),
    };
  }
  return customerBasket;
};
