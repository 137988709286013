import { Moment } from 'moment';
import {
  DEFAULT_EU_FORMAT,
  DEFAULT_NA_FORMAT,
} from '../components/DatePicker/DatePicker';
import { AuthorityRole } from '../types/Authority.interface';
import useRole from './useRole';

const useCorrectMomentFormat = () => {
  const { hasRole } = useRole();
  const momentFormat = (currentDate: Moment) => {
    const format = hasRole(AuthorityRole.ROLE_EU)
      ? DEFAULT_EU_FORMAT
      : DEFAULT_NA_FORMAT;
    return currentDate.format(format);
  };

  return {
    momentFormat,
  };
};

export default useCorrectMomentFormat;
