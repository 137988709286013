import { t } from '@lingui/macro';
import * as React from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { PayloadOrder } from '../types/PayloadOrder.interface';

interface IBasketCharacteristicsCellProps {
  record: PayloadOrder;
}

const BasketCharacteristicsCell: React.FunctionComponent<IBasketCharacteristicsCellProps> = ({
  record,
}) => {
  const { format } = useNumberFormatter();
  return (
    <>
      {record.productType === PutUpCategory.SHEET ? (
        <>
          <LabelValue
            label={t`Width (cm)`}
            value={format(record.width)}
            inline
            noBold
          />
          <LabelValue
            label={t`Length (cm)`}
            value={format(record.length as number)}
            inline
            noBold
          />
        </>
      ) : (
        <>
          <LabelValue
            label={t`Roll Width (cm)`}
            value={format(record.width)}
            inline
            noBold
          />
          {record.reelDiameter && (
            <LabelValue
              label={t`Roll Diameter (cm)`}
              value={format(record.reelDiameter)}
              inline
              noBold
            />
          )}
          <LabelValue
            label={t`Core Diameter (cm)`}
            value={format(record.coreDiameter)}
            inline
            noBold
          />
        </>
      )}
    </>
  );
};

export default BasketCharacteristicsCell;
