import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import { Moment } from 'moment';
import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import ControlledDatePicker from '../../../../components/DatePicker/ControlledDatePicker';
import InputLabel from '../../../../components/InputLabel';
import TodayErrorMessage from '../TodayErrorMessage';
import { StockLotBasketForm } from './StockLotBasketForm.type';
import StockLotBasketFormField from './StockLotBasketFormField.enum';

interface IRequiredShipmentDateProps {
  control: UseFormMethods<StockLotBasketForm>['control'];
  errors: UseFormMethods<StockLotBasketForm>['errors'];
}

const RequiredShipmentDate: FC<IRequiredShipmentDateProps> = ({
  control,
  errors,
}) => {
  return (
    <Space direction="vertical">
      <InputLabel text={t`Required Shipment Date`} required size="lg">
        <ControlledDatePicker
          name={StockLotBasketFormField.REQUIRED_DELIVERY_DATE}
          control={control}
          rules={{
            required: true,
            validate: {
              isDateAfterToday: (date: Moment) => date.isAfter(),
            },
          }}
          hasError={!!errors[StockLotBasketFormField.REQUIRED_DELIVERY_DATE]}
          toolTipErrorMsg={
            errors[StockLotBasketFormField.REQUIRED_DELIVERY_DATE]?.type ===
            'isDateAfterToday' ? (
              <TodayErrorMessage />
            ) : undefined
          }
          required
        />
      </InputLabel>
      <div>
        <Trans>
          (Requested Arrival date for D-Incoterms, Turkey, South Africa, USA)
        </Trans>
      </div>
    </Space>
  );
};

export default RequiredShipmentDate;
