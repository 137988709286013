import * as React from 'react';

interface ISamplesProductItemDetailRowProps {
  label: string | React.ReactNode;
  value: string | React.ReactNode;
}

const SamplesProductItemDetailRow: React.FunctionComponent<ISamplesProductItemDetailRowProps> = ({
  label,
  value,
}) => {
  return (
    <tr>
      <td className="w-1/2 font-bold text-left">{label}:</td>
      <td className="text-right">{value}</td>
    </tr>
  );
};

export default SamplesProductItemDetailRow;
