import { t, Trans } from '@lingui/macro';
import { Space } from 'antd';
import moment from 'moment';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import useAnalytics from '../../../../hooks/useAnalytics';
import {
  deleteStockLotBasketItem,
  submitStockLotBasketOrder,
  updateStockLotBasketItem,
} from '../../../../services/Basket';
import showNotification from '../../../../services/Notification';
import QueryCacheName from '../../../../types/QueryCacheName.enum';
import { StockLotBasket } from '../../../../types/StockLotBasket.interface';
import { StockLotItem } from '../../../../types/StockLotItem.interface';
import CustomerDeliveryLocation from './CustomerDeliveryLocation';
import ItemsTable from './ItemsTable/ItemsTable';
import useQuantityUpdateStore from './ItemsTable/useQuantityUpdate.store';
import RequiredShipmentDate from './RequiredShipmentDate';
import { StockLotBasketForm } from './StockLotBasketForm.type';
import StockLotBasketFormField from './StockLotBasketFormField.enum';
import useHarbors from './useHarbors';

interface IBasketProps {
  basket: StockLotBasket;
}

const Basket: FC<IBasketProps> = ({ basket }) => {
  const {
    formState: { isValid },
    register,
    errors,
    control,
    handleSubmit,
  } = useForm<StockLotBasketForm>({ mode: 'all' });
  const { harbors } = useHarbors(
    basket.soldTo.number,
    basket.shipTo.number,
    basket.division
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const queryCache = useQueryCache();
  const history = useHistory();
  const { trackPageView } = useAnalytics();

  const submitOrder = async (formData: StockLotBasketForm) => {
    setIsSubmitting(true);

    try {
      const result = await submitStockLotBasketOrder({
        ...basket,
        purchaseOrder: formData[StockLotBasketFormField.PO_NUMBER],
        harborCustomerNumber:
          formData[StockLotBasketFormField.HARBOR] ||
          basket.harborCustomerNumber,
        items: basket.items.map((item, index) => ({
          ...item,
          externalWrappingType:
            formData.basket[index][StockLotBasketFormField.WRAPPING_TYPE] ||
            item.externalWrappingType,
          deliveryDate: moment(
            formData[StockLotBasketFormField.REQUIRED_DELIVERY_DATE]
          ).format('YYYY-MM-DD'),
        })),
      });

      trackPageView('STOCK_LOT_BASKET', 'SEND_RESERVATION', {
        customerId: basket?.soldTo.number,
      });
      queryCache.invalidateQueries(QueryCacheName.BASKET_STOCK_LOT);

      showNotification({
        message: '',
        description: `${t`Successfully sent reservation. Your reference number for future enquires is`}: ${
          result.reservationDocument
        }`,
      });
    } catch (e) {
      showNotification({
        message: '',
        description: t`An error occurred while attempting to send the reservation.`,
      });
    }

    setIsSubmitting(false);
  };

  const doRemoveStockLotItem = async (id: number) => {
    await deleteStockLotBasketItem(
      basket.soldTo.number,
      basket.shipTo.number,
      id
    );
    queryCache.invalidateQueries(QueryCacheName.BASKET_STOCK_LOT);
  };

  const doUpdateStockLotItem = async (item: StockLotItem) => {
    useQuantityUpdateStore.setState({ isQuantityUpdating: true });
    await updateStockLotBasketItem(
      item,
      basket.soldTo.number,
      basket.shipTo.number
    );
    queryCache.invalidateQueries(QueryCacheName.BASKET_STOCK_LOT);
  };

  return (
    <form onSubmit={handleSubmit(submitOrder)}>
      <Space direction="vertical" className="w-full">
        <CustomerDeliveryLocation
          register={register}
          soldTo={basket.soldTo}
          shipTo={basket.shipTo}
          harbors={harbors}
        />
        <RequiredShipmentDate control={control} errors={errors} />
        <InputLabel text={t`P.O. Number`} size="lg" required>
          <Input
            name={StockLotBasketFormField.PO_NUMBER}
            ref={register({ required: true })}
            required
            width="52"
          />
        </InputLabel>
        <ItemsTable
          register={register}
          errors={errors}
          items={basket.items}
          onRemoveItem={doRemoveStockLotItem}
          onQuantityChange={doUpdateStockLotItem}
        />
        <div className="flex justify-between">
          <Button
            disabled={isSubmitting}
            onClick={() => history.push('/savedenquiries')}
          >
            <Trans>Back to Search</Trans>
          </Button>
          <Button
            theme="primary"
            disabled={isSubmitting || !isValid}
            loading={isSubmitting}
          >
            <Trans>Send Reservation</Trans>
          </Button>
        </div>
      </Space>
    </form>
  );
};

export default Basket;
