import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Steps, { Step } from '../../components/Steps/Steps';
import useSamplesOrder from '../../hooks/useSamplesOrder';
import SampleStep from './SampleStep.enum';

interface IOrderSamplesProps {
  activeStep: SampleStep;
}

const SamplesStepper: React.FunctionComponent<IOrderSamplesProps> = ({
  activeStep,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(activeStep);
  const [orderExists, setOrderExists] = useState<boolean>(false);
  const [hasBuyer, setHasBuyer] = useState<boolean>(false);

  const history = useHistory();
  const { data: order } = useSamplesOrder();
  const [isStepDisabled, setIsStepDisabled] = useState({
    [SampleStep.ORDER_SAMPLES]: false,
    [SampleStep.BASIC_INFORMATION]: false,
    [SampleStep.BASKET]: false,
    [SampleStep.ORDER_SUMMARY]: false,
  });

  useEffect(() => {
    const tempOrderExists = Boolean(order && order.id && order.id > 0);
    setOrderExists(tempOrderExists);
    const tempHasBuyer = Boolean(order && order?.buyerNumber !== null);
    setHasBuyer(tempHasBuyer);
    setIsStepDisabled({
      [SampleStep.ORDER_SAMPLES]: tempOrderExists && tempHasBuyer,
      [SampleStep.BASIC_INFORMATION]: !tempOrderExists || tempHasBuyer,
      [SampleStep.BASKET]: !tempOrderExists || !tempHasBuyer,
      [SampleStep.ORDER_SUMMARY]: !tempOrderExists || !tempHasBuyer,
    });
  }, [order]);

  return (
    <>
      <Steps
        current={currentStep}
        onChange={setCurrentStep}
        labelPlacement="vertical"
        className="ml-auto"
        style={{ width: '550px' }}
      >
        <Step
          subTitle={t`Samples`}
          disabled={isStepDisabled[SampleStep.ORDER_SAMPLES]}
          onClick={(e) => {
            e.preventDefault();
            if (!isStepDisabled[SampleStep.ORDER_SAMPLES]) {
              history.push('/samples/findProducts');
            }
          }}
        />
        <Step
          subTitle={t`Basic Information`}
          disabled={isStepDisabled[SampleStep.BASIC_INFORMATION]}
          onClick={(e) => {
            e.preventDefault();
            if (!isStepDisabled[SampleStep.BASIC_INFORMATION]) {
              history.push('/samples/selectBuyer');
            }
          }}
          status={orderExists && hasBuyer ? 'finish' : undefined}
        />
        <Step
          subTitle={t`Basket`}
          disabled={isStepDisabled[SampleStep.BASKET]}
          onClick={(e) => {
            e.preventDefault();
            if (!isStepDisabled[SampleStep.BASKET]) {
              history.push('/samples/basket');
            }
          }}
        />
        <Step
          subTitle={t`Order Summary`}
          disabled={isStepDisabled[SampleStep.ORDER_SUMMARY]}
          onClick={(e) => {
            e.preventDefault();
            if (!isStepDisabled[SampleStep.ORDER_SUMMARY]) {
              history.push('/samples/summary');
            }
          }}
        />
      </Steps>
    </>
  );
};

export default SamplesStepper;
