import { filter } from 'lodash';
import { TradeBasisTypes } from '../types/TradeBasisTypes.enum';
import { tradeBasisTypeValues } from '../Calculators.util';
import CalculationType from '../types/CalculationType.enum';

export const isCwtToThousandSht = (conversionType: string): boolean => {
  return conversionType === CalculationType.CWT_TO_THOUSAND_SHEETS;
};

export const isCwtToLinFoot = (conversionType: string): boolean => {
  return conversionType === CalculationType.CWT_TO_LINEAL_FOOT;
};

export const isCwtToMsf = (conversionType: string): boolean => {
  return conversionType === CalculationType.CWT_TO_MSF;
};

export const isCwtToTonne = (conversionType: string): boolean => {
  return conversionType === CalculationType.CWT_TO_METRIC_TON;
};

export const isThousandShtToCwt = (conversionType: string): boolean => {
  return conversionType === CalculationType.THOUSAND_SHEETS_TO_CWT;
};

export const isTonneToCwt = (conversionType: string): boolean => {
  return conversionType === CalculationType.METRIC_TON_TO_CWT;
};

export const getTradeBasisValuePerType = (type: TradeBasisTypes): string => {
  const { value } = filter(tradeBasisTypeValues, { type })[0];
  return value;
};
