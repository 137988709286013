import { t, Trans } from '@lingui/macro';
import { concat } from 'lodash';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import useRole from '../../../hooks/useRole';
import useUser from '../../../hooks/useUser';
import {
  deriveCustomerOptions,
  isValidCustomerNumber,
} from '../../../services/Customer';
import { AuthorityRole } from '../../../types/Authority.interface';
import Option from '../../../types/Option.type';
import styles from './SearchCriteria.module.scss';
import useOpenOrdersStore from '../useOpenOrdersStore';

interface ISearchCriteriaProps {
  onSubmit: (customerNumber: string) => void;
  onReset: () => void;
}

interface SearchCriteriaInputs {
  customerNumber: string;
}

const SearchCriteria: React.FunctionComponent<ISearchCriteriaProps> = ({
  onSubmit,
  onReset,
}) => {
  const { customerNum: storedCustomerNum } = useOpenOrdersStore();
  const {
    register,
    handleSubmit,
    reset,
    errors,
    formState,
    setValue,
  } = useForm<SearchCriteriaInputs>({
    mode: 'onChange',
  });
  const defaultOption = useMemo(() => {
    return { label: t`Select Customer`, value: '' } as Option;
  }, []);
  const { hasRole, isNonReleaseEuUser } = useRole();
  const { data: user } = useUser();
  const [customerOptions, setCustomerOptions] = useState<Option[]>([
    defaultOption,
  ]);
  const { isDirty, isValid } = formState;
  const isReadyToSearch = isDirty && isValid;
  const hasValidCustomerSelected =
    (isDirty && !errors.customerNumber) || storedCustomerNum !== '';

  const doSubmit = handleSubmit((data: SearchCriteriaInputs) => {
    onSubmit(data.customerNumber);
  });

  const doReset = (): void => {
    reset();
    onReset();
  };

  useEffect(() => {
    if (user) {
      const { customers } = user;
      const derivedCustomerOptions = deriveCustomerOptions(
        customers || [],
        isNonReleaseEuUser,
        hasRole(AuthorityRole.ROLE_EU),
        hasRole(AuthorityRole.ROLE_RELEASE)
      );
      setCustomerOptions(concat([defaultOption], derivedCustomerOptions));
    }
  }, [user, defaultOption, isNonReleaseEuUser, hasRole]);

  useEffect(() => {
    if (storedCustomerNum) {
      setValue('customerNumber', storedCustomerNum);
    }
  }, [setValue, storedCustomerNum, customerOptions]);

  return (
    <form onSubmit={doSubmit} className="text-gray-dark200 my-6">
      <div className="mb-12">
        <p>
          <Trans>
            Fill out one or more of the fields below to look up the progress of
            your orders.
          </Trans>
        </p>
      </div>
      <div className={`${styles['customer-select']} mb-11`}>
        <InputLabel
          text={t`Customer`}
          size="lg"
          className="text-gray-dark200"
          htmlFor="open-orders-search-criteria-customer"
          required
        />
        <Select
          options={customerOptions}
          ref={register({ required: true, validate: isValidCustomerNumber })}
          name="customerNumber"
          required
          id="open-orders-search-criteria-customer"
        />
      </div>
      <div className="flex items-center">
        <Button
          type="submit"
          theme="primary"
          disabled={!isReadyToSearch && !storedCustomerNum}
        >
          <Trans>Search</Trans>
        </Button>
        <Button
          theme="link"
          type="reset"
          className="mx-4 text-lg font-bold"
          onClick={doReset}
        >
          <Trans>Reset</Trans>
        </Button>
        {!hasValidCustomerSelected && (
          <div className="ml-7">
            <p className="text-red-red">
              <Trans>Please select a customer before proceeding.</Trans>
            </p>
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchCriteria;
