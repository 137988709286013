import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';

interface IUpdatingBasketProps {
  isUpdating: boolean;
}

const UpdatingBasket: FC<IUpdatingBasketProps> = ({ isUpdating }) => {
  return (
    <>
      {isUpdating ? (
        <>
          <LoadingSpinner horizontal />
          <div className="text-center">
            <Trans>Updating the basket. Please wait.</Trans>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default UpdatingBasket;
