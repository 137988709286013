import { t } from '@lingui/macro';
import { SappiBreadCrumb } from './Types/SappiBreadCrumb.interface';

// This to supplement the bread crumbs that cannot be retrieved from menu directly
export const getAllBreadCrumbs = (): SappiBreadCrumb[] => {
  return [
    {
      id: 'orderTracking',
      url: '/orderTracking/', // For orderTracking/:page
      siteLocation: t`My Orders`,
      breadcrumb: t`Track Orders`,
    },
    {
      id: 'orderTrackingDetails',
      url: '/orderTracking/details/', // For /orderTracking/details/:docNumber/:docPosition
      siteLocation: t`Track Orders`,
      breadcrumb: t`Order Details`,
    },
    {
      id: 'orderTrackingInvoice',
      url: '/orderTracking/invoice/', // For /orderTracking/invoice/:docNumber/:docPosition
      siteLocation: t`My Orders`,
      breadcrumb: t`Invoice Details`,
    },
    {
      id: 'deliveryDetails',
      url: '/deliveryDetails/', // For /deliveryDetails/:docNumber/:docPosition
      breadcrumb: t`Order Details`,
    },
    {
      id: 'orderTrackingDeliveryDetails',
      url: '/orderTracking/deliveryDetails/', // For /orderTracking/deliveryDetails/:docNumber/:docPosition
      siteLocation: t`My Orders`,
      breadcrumb: t`Delivery Details`,
    },
    {
      id: 'consignmentConsumption',
      url: '/consignmentConsumption/', // For /consignmentConsumption/:status
      siteLocation: t`My Orders`,
      breadcrumb: t`Consignment Consumption`,
    },
    {
      id: 'millDirect/results',
      url: '/millDirect/results', // For /millDirect/results
      siteLocation: t`My Orders`,
      breadcrumb: t`All Open Orders`,
    },
    {
      id: 'order',
      url: '/order/', // For /order/:docNumber/:docPosition
      siteLocation: t`Track Orders`,
      breadcrumb: t`Order Details`,
    },
    {
      id: 'invoice',
      url: '/invoice/', // For /invoice/:docNumber/:docPosition
      breadcrumb: t`Order Details`,
    },
    {
      id: 'trackByLabel',
      url: '/trackByLabel/', // For /trackByLabel/:labelType
      breadcrumb: t`Track By Label ID`,
    },
    {
      id: 'samplesCreateDummy',
      url: '/samples/createDummy',
      // For /samples/createDummy, /samples/createDummy/textPages, /samples/createDummy/coverOptions, /samples/createDummy/format samples/createDummy/copies
      breadcrumb: t`Order Samples`,
    },
    {
      id: 'samplesSelectBuyer',
      url: '/samples/selectBuyer', // For /samples/selectBuyer
      breadcrumb: t`Order Samples`,
    },
    {
      id: 'samplesFindProducts',
      url: '/samples/findProducts', // For /samples/findProducts
      siteLocation: t`Samples`,
      breadcrumb: t`Order Samples`,
    },
    {
      id: 'samplesBasket',
      url: '/samples/basket', // For /samples/basket
      siteLocation: t`Samples`,
      breadcrumb: t`Order Samples`,
    },
    {
      id: 'samplesSummary',
      url: '/samples/summary', // For /samples/summary
      breadcrumb: t`Order Samples`,
    },
    {
      id: 'samplesOrdered',
      url: '/samples/ordered/', // For /samples/ordered/:documentNumber /samples/ordered/error/:failedOrderReference
      siteLocation: t`Samples`,
      breadcrumb: t`Order Samples`,
    },
    {
      id: 'samplesStatus',
      url: '/samples/status', // For /samples/ordered/:documentNumber /samples/ordered/error/:failedOrderReference
      siteLocation: t`Samples`,
      breadcrumb: t`My Sample Orders`,
    },
    {
      id: 'trackAndTrace',
      url: '/trackAndTrace', // For /trackAndTrace /trackAndTrace/upload /trackAndTrace/upload/:status /trackAndTrace/log/:payloadId
      breadcrumb: t`Track And Trace`,
    },
    {
      id: 'basket',
      url: '/basket', // For /basket /basket/:id/saved /basket/:id/booked
      siteLocation: t`Availability`,
      breadcrumb: t`Basket`,
    },
    {
      id: 'planning',
      url: '/planning', // For /planning
      siteLocation: t`Availability`,
      breadcrumb: t`Production Plan`,
    },
    {
      id: 'contactUs',
      url: '/contact-us', // For /contact-us
      siteLocation: t`Company Info`,
      breadcrumb: t`Contact Us`,
    },
    {
      id: 'aboutUs',
      url: '/about-us', // For /about-us
      siteLocation: t`Company Info`,
      breadcrumb: t`About Us`,
    },
    {
      id: 'loginHelp',
      url: '/loginHelp', // For /loginHelp
      breadcrumb: t`Help`,
    },
    {
      id: 'help',
      url: '/help',
      breadcrumb: t`Help`,
    },
    {
      id: 'account',
      url: '/account', // For /account  /account/password
      breadcrumb: t`My Account`,
    },
    {
      id: 'consignment/confirmation',
      url: '/consignment/confirmation', // For /consignment/confirmation
      siteLocation: t`Reporting`,
      breadcrumb: t`Consignment`,
    },
    {
      id: 'reporting/claimReport',
      url: '/reporting/claimReport/', // For /reporting/claimReport/:customerNumber /reporting/claimReport/addAttachment/:complaintNumber/:fileNumber/:customerNumber
      siteLocation: t`Reporting`,
      breadcrumb: t`Claims Reporting`,
    },
    {
      id: 'styleGuide',
      url: '/styleGuide/', // For /styleGuide
      breadcrumb: t`Style Guide`,
    },
    {
      id: 'submitOrder',
      url: '/submitOrder', // For /submitOrder /submitOrder/submitted
      siteLocation: t`My Orders`,
      breadcrumb: t`Submit Order`,
    },
    {
      id: 'news',
      url: '/news', // For /news/:id
      breadcrumb: t`News`,
    },
  ];
};
