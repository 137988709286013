import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from './Button/Button';
import LoadingSpinner from './LoadingSpinner/LoadingSpinner';

interface IFileUploadScreenProps {
  filesUploaded: string[];
  primaryButton: {
    text: string;
    action: (fileList: FileList) => Promise<unknown>;
  };
  secondaryButton: {
    text: string;
    action: () => void;
  };
  deleteAttachmentButton: {
    text: string;
    action: () => void;
  }
}

const FileUploadScreen: React.FunctionComponent<IFileUploadScreenProps> = ({
  filesUploaded,
  primaryButton,
  secondaryButton,
  deleteAttachmentButton,
}) => {
  const [loading, setLoading] = useState(false);
  const { register, getValues, setValue } = useForm<{
    fileAttachment: FileList;
  }>({
    mode: 'all',
  });
  const handlePrimaryButtonAction = async () => {
    setLoading(true);
    await primaryButton.action(getValues('fileAttachment'));
    setValue('fileAttachment', null);
    setLoading(false);
  };
  return (
    <form>
      <Space direction="vertical" className="w-full">
        <div className="text-3xl font-bold">
          <Trans>File Attachments:</Trans>
        </div>
        <Space direction="vertical" className="ml-2">
          {filesUploaded.map((file) => (
            <div key={file}>
              <i className="fa fa-file mr-2" />
              {file}
            </div>
          ))}
          <div className="text-xl mt-3">
            <Trans>You have uploaded {filesUploaded.length} files</Trans>
          </div>
          {filesUploaded.length > 0 && (
            <Button onClick={() => deleteAttachmentButton.action()}>
              {deleteAttachmentButton.text}
            </Button>
          )}
        </Space>
        <Space direction="vertical" className="my-5">
          <div className="text-3xl font-bold">
            <Trans>Attach File (50mb Max Size):</Trans>
          </div>
          <input name="fileAttachment" size={45} type="file" ref={register()} />
        </Space>
        <div className="flex w-full justify-between">
          <Button
            theme="primary"
            disabled={loading}
            onClick={() => handlePrimaryButtonAction()}
          >
            <div className="flex">
              {primaryButton.text}
              {loading && <LoadingSpinner className="relative top-1 ml-2" />}
            </div>
          </Button>
          <Button onClick={() => secondaryButton.action()}>
            {secondaryButton.text}
          </Button>
        </div>
      </Space>
    </form>
  );
};

export default FileUploadScreen;
