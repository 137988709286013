import create from 'zustand';
import { SamplesProduct } from '../../../types/Samples/SamplesProduct.interface';

type SamplesBasketPopupState = {
  lastAdded: SamplesProduct | null;
  setLastAdded: (lastAdded: SamplesProduct | null) => void;
};

const useSamplesBasketPopupStore = create<SamplesBasketPopupState>((set) => ({
  lastAdded: null,
  setLastAdded: (lastAdded) => set({ lastAdded }),
}));

export default useSamplesBasketPopupStore;
