import { AuthorityRole } from '../../types/Authority.interface';
import User from '../../types/User/User.interface';

export const isUserTrackAndTraceUser = (user: User): boolean => {
  const { authorities } = user;
  const hasTrackAndTraceRole = authorities.some(
    (item) => item.authority === AuthorityRole.ROLE_TRACK_AND_TRACE
  );
  return hasTrackAndTraceRole && authorities && authorities.length <= 2;
};
