import { t } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import Table from '../../../../components/Table/Table';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import DescriptionCell from './DescriptionCell';
import OrderCell from './OrderCell';
import styles from './ResultsTable.module.scss';

interface IResultsTableProps {
  results: ConsignmentInventoryItem[];
}

const ResultsTable: React.FunctionComponent<IResultsTableProps> = ({
  results,
}) => {
  const columns: ColumnsType<ConsignmentInventoryItem> = [
    {
      title: t`Order #`,
      key: 'customerPurchaseOder',
      render: (value, record) => (
        <OrderCell
          purchaseOrderNumber={record.customerPurchaseOder || ''}
          sappiOrderNumber={`${stripZeros(
            record.documentNumber || ''
          )}/${stripZeros(record.salesDocumentItem || '')}`}
        />
      ),
    },
    {
      title: t`Description`,
      key: 'description',
      render: (value, record) => <DescriptionCell items={record.details} />,
    },
  ];
  return (
    <Table
      rowKey={(record: ConsignmentInventoryItem) =>
        `${record.documentNumber}${record.documentPosition}${record.salesDocumentItem}${record.itemNumber}`
      }
      className={styles.table}
      dataSource={results}
      columns={columns}
      pagination={false}
    />
  );
};

export default ResultsTable;
