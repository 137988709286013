import { t } from '@lingui/macro';
import { Space } from 'antd';
import { some } from 'lodash';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import { METRIC_NUMBER_THREE_DECIMAL_REG_EX } from '../../../types/RegularExpression.constants';

const MAX_REQUIRED = 999999999999999999999.999;
interface ICriteriaProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  watch: UseFormMethods['watch'];
  getValues: UseFormMethods['getValues'];
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  calculated: boolean;
}

const Criteria: React.FunctionComponent<ICriteriaProps> = ({
  register,
  errors,
  watch,
  getValues,
  onInputChange,
  calculated,
}) => {
  const mils = watch('mils');
  const microns = watch('microns');

  const oneInputFilled = () => some(getValues(), (value) => !!value);
  return (
    <Space direction="vertical" size="small" className="w-full pr-12">
      <InputLabel text={t`mils`} size="lg" className="-mt-4">
        <Input
          name="mils"
          type="number"
          ref={register({
            max: MAX_REQUIRED,
            pattern: METRIC_NUMBER_THREE_DECIMAL_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.mils}
          disabled={microns && !calculated}
          onChange={onInputChange}
          width="full"
        />
      </InputLabel>
      <InputLabel text={t`microns(metric)`} size="lg" className="mt-3">
        <Input
          name="microns"
          type="number"
          ref={register({
            max: MAX_REQUIRED,
            pattern: METRIC_NUMBER_THREE_DECIMAL_REG_EX,
            validate: oneInputFilled,
          })}
          error={errors?.microns}
          disabled={mils && !calculated}
          onChange={onInputChange}
          width="full"
        />
      </InputLabel>
    </Space>
  );
};

export default Criteria;
