import CalculateEqSavingsParams from '../types/EqCalculator/CalculateEqSavingsParams.interface';
import EqBrandInfo from '../types/EqCalculator/EqBrandInfo.interface';
import EqCalculatorFormData from '../types/EqCalculator/EqCalculatorFormData.interface';

export const EQ_CALC_BRAND_INPUT_PREFIX = 'brandinput';

export const getParamsFromFormData = (
  data: EqCalculatorFormData
): CalculateEqSavingsParams => {
  const { title, units } = data;

  const brands: EqBrandInfo[] = [];
  Object.entries(data).forEach((entry) => {
    const inputName = entry[0];
    const inputValue = entry[1];

    if (inputValue && inputName.startsWith(EQ_CALC_BRAND_INPUT_PREFIX)) {
      const dePrefixed = inputName.substr(EQ_CALC_BRAND_INPUT_PREFIX.length);

      brands.push({ brand: dePrefixed, value: inputValue });
    }
  });

  return {
    agreement: false,
    brands,
    cert: 'none',
    title,
    units,
  };
};
