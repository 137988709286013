import { Trans } from '@lingui/macro';
import * as React from 'react';
import DeconstructedTableBodyCell from '../../../../components/DeconstructedTable/DeconstructedTableBodyCell';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import { AugmentedSamplesHistoryOrder } from '../../../../types/Samples/AugmentedSamplesHistoryOrder.interface';
import ViewSampleOrdersTableRowType from '../../../../types/Samples/ViewSampleOrdersTableRowType.enum';

interface IValueCellProps {
  order: AugmentedSamplesHistoryOrder;
  rowType: ViewSampleOrdersTableRowType;
}

const ValueCell: React.FunctionComponent<IValueCellProps> = ({
  order,
  rowType,
}) => {
  const { format } = useNumberFormatter();
  const isGrouped = rowType === ViewSampleOrdersTableRowType.GROUPED;
  const isSubitem = rowType === ViewSampleOrdersTableRowType.SUBITEM;

  return (
    <DeconstructedTableBodyCell className="qty-value-cell">
      <div className="mb-6">
        <div className="font-bold">
          <Trans>Quantity:</Trans>
        </div>
        <div>
          <span className="text-3xl">
            <span>{format(order.quantity)}</span>{' '}
            {isGrouped ? (
              <span>
                <Trans>Units</Trans>
              </span>
            ) : (
              <span>{order.salesUnit}</span>
            )}
          </span>{' '}
          {!isGrouped && order.unitSize > 1 && (
            <span>
              ({order.unitSize} {order.unitSizeUnit} <Trans>Per Unit</Trans>)
            </span>
          )}
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2">
          <div className="font-bold">
            <Trans>Order Value:</Trans>
          </div>
          <div>
            {order.price > 0 ? (
              <span>
                {format(order.price)} {order.currency}
              </span>
            ) : (
              <span>
                <Trans>No Cost</Trans>
              </span>
            )}
          </div>
        </div>
        <div className="w-1/2">
          {!isSubitem && (
            <>
              {order.shippingAccountNumber === '' ? (
                <div className="font-bold">
                  <Trans>Delivery Value:</Trans>
                </div>
              ) : (
                <div className="font-bold">
                  <Trans>Delivery Cost:</Trans>
                </div>
              )}
              <div>
                {format(order.shippingCost)} {order.shippingCurrency}
              </div>
            </>
          )}
        </div>
      </div>
    </DeconstructedTableBodyCell>
  );
};

export default ValueCell;
