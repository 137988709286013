import { Trans } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import InputLabel from '../../../components/InputLabel';
import Select from '../../../components/Select/Select';
import useRole from '../../../hooks/useRole';
import { deriveCustomerOptions } from '../../../services/Customer';
import { AuthorityRole } from '../../../types/Authority.interface';
import Customer from '../../../types/Customer.interface';
import Option from '../../../types/Option.type';

export interface FilterFormData {
  customer: string;
  status: string;
  documentRefNum: string;
}

export const EMPTY_FILTERS: FilterFormData = {
  customer: '',
  status: '',
  documentRefNum: '',
};

interface IViewSampleOrdersFiltersProps {
  onUpdateFilters: (newFilters: FilterFormData) => void;
  statuses?: string[];
  customers?: Customer[];
  loading?: boolean;
}

const ViewSampleOrdersFilters: React.FunctionComponent<IViewSampleOrdersFiltersProps> = ({
  onUpdateFilters,
  statuses,
  customers,
  loading,
}) => {
  const { handleSubmit, register } = useForm();
  const { hasRole, isNonReleaseEuUser } = useRole();

  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (customers) {
      const derivedCustomerOptions = deriveCustomerOptions(
        customers || [],
        isNonReleaseEuUser,
        hasRole(AuthorityRole.ROLE_EU),
        true
      );

      setCustomerOptions([{ label: '', value: '' }, ...derivedCustomerOptions]);
    }
  }, [customers, hasRole, isNonReleaseEuUser]);

  useEffect(() => {
    if (statuses) {
      const generatedStatusOptions = statuses.map((status) => {
        return {
          label: status,
          value: status,
        };
      });

      setStatusOptions([{ label: '', value: '' }, ...generatedStatusOptions]);
    }
  }, [statuses]);

  const doSubmit = (data: FilterFormData): void => {
    onUpdateFilters(data);
  };

  return (
    <form
      onSubmit={handleSubmit(doSubmit)}
      className="flex items-end w-full pb-7"
    >
      <div className="w-80">
        <InputLabel text={<Trans>Customer</Trans>} size="lg" />
        <Select
          name="customer"
          ref={register()}
          options={customerOptions}
          disabled={loading}
        />
      </div>
      <div className="w-36">
        <InputLabel text={<Trans>Status</Trans>} size="lg" />
        <Select
          name="status"
          ref={register()}
          options={statusOptions}
          disabled={loading}
        />
      </div>
      <div className="w-80">
        <InputLabel text={<Trans>Document Ref #</Trans>} size="lg" />
        <Input
          name="documentRefNum"
          ref={register()}
          width="full"
          disabled={loading}
        />
      </div>
      <div className="ml-11">
        <Button type="submit" theme="primary" disabled={loading}>
          <Trans>Search</Trans>
        </Button>
      </div>
    </form>
  );
};

export default ViewSampleOrdersFilters;
