import { t } from '@lingui/macro';
import PackingListType from './types/PackingListType.enum';
import PackingListResponse from './types/PackingListResponse.interface';

export const PACKING_LIST_OPTIONS = [
  {
    value: PackingListType.CONTAINERS,
    label: t`Container Numbers`,
  },
  {
    value: PackingListType.INVOICES,
    label: t`Invoice Numbers`,
  },
];

export const hasValidResults = (results: PackingListResponse): boolean => {
  if (results?.containers) {
    return results.containers?.length > 0;
  }
  return false;
};
