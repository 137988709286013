import { Trans } from '@lingui/macro';
import * as React from 'react';
import { InvoiceDetailsRow } from './InvoiceDetailsRow.interface';
import useRole from '../../../../hooks/useRole';
import { AuthorityRole } from '../../../../types/Authority.interface';
import stripZeros from '../../../../services/Util/stripZeros.util';

interface IMaterialShipmentInfoCellProps {
  record: InvoiceDetailsRow;
}

const MaterialShipmentInfoCell: React.FunctionComponent<IMaterialShipmentInfoCellProps> = ({
  record,
}) => {
  const { hasRole } = useRole();

  return (
    <div>
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans id="orderDetails.brand">Brand</Trans>
        </dt>
        <dd className="font-bold">{record.details.materialDescription}</dd>
      </dl>
      {hasRole(AuthorityRole.ROLE_NA) && (
        <dl className="mb-2.5 flex text-xs text-gray-dark200">
          <dt className="pr-2 label-colon">
            <Trans>Sappi SKU #</Trans>
          </dt>
          <dd className="font-bold">{record.overview.kcode}</dd>
        </dl>
      )}
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans id="orderDetails.material">Customer Material #</Trans>
        </dt>
        <dd className="font-bold">{record.details.materialNumber}</dd>
      </dl>
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans>Bill of Lading #</Trans>
        </dt>
        <dd className="font-bold">{record.deliveryDetail.deliveryNote}</dd>
      </dl>
      <dl className="mb-2.5 flex text-xs text-gray-dark200">
        <dt className="pr-2 label-colon">
          <Trans id="orderDetails.weightlist">WeightList #</Trans>
        </dt>
        <dd className="font-bold">{stripZeros(record.deliveryDetail.weightListNumber)}</dd>
      </dl>
    </div>
  );
};

export default MaterialShipmentInfoCell;
