import { t } from '@lingui/macro';
import React, { FC, useState } from 'react';
import usePageHeaderItems from '../../../hooks/usePageHeaderItems';
import PageHeader from '../../PageHeader/PageHeader';
import HelpCategory from '../../PageHeader/types/HelpCategory.enum';
import BasketStep from './BasketStep/BasketStep';
import CustomerSelect from './CustomerSelect';
import DeliveryDetailsStep from './DeliveryDetails/DeliveryDetailsStep';
import { ALL_CUSTOMERS } from './NaBasket.util';
import NaBasketStepper from './NaBasketStepper';
import SubmitOrderStep from './SubmitOrderStep/SubmitOrderStep';
import NaBasketStep from './types/NaBasketStep.enum';

const BASKET_TITLE = t`Baskets`;
const DELIVERY_DETAILS_TITLE = t`Delivery Details`;
const SUBMIT_ORDER_TITLE = t`Submit Order`;

const NaBasket: FC = () => {
  // Note that this basket is not truly just for NA. EU users who add an item to
  // the basket via Quick Order and choose the "Review & Checkout" option in the
  // basket summary popup in the header will also come here. The items in the
  // basket that are added in this manner appear to still be of the correct type
  // (AvailablilityBasketNA), however.
  const [currentStep, setCurrentStep] = useState<NaBasketStep>(
    NaBasketStep.BASKET
  );
  const [selectedCustomerNum, setSelectedCustomerNum] = useState<string>(
    ALL_CUSTOMERS
  );
  const { basePageHeaderItemSet } = usePageHeaderItems();
  const [pageTitle, setPageTitle] = useState(BASKET_TITLE);

  const doSetCurrentStep = (step: NaBasketStep) => {
    switch (step) {
      case NaBasketStep.DELIVERY_DETAILS:
        setPageTitle(DELIVERY_DETAILS_TITLE);
        break;
      case NaBasketStep.SUBMIT_ORDER:
        setPageTitle(SUBMIT_ORDER_TITLE);
        break;
      default:
        setPageTitle(BASKET_TITLE);
    }
    setCurrentStep(step);
  };

  return (
    <>
      <div className="flex my-8">
        {currentStep === NaBasketStep.BASKET && (
          <CustomerSelect setSelectedCustomerNum={setSelectedCustomerNum} />
        )}
        <NaBasketStepper currentStep={currentStep} className="ml-auto" />
      </div>
      <PageHeader
        title={pageTitle}
        items={basePageHeaderItemSet}
        helpCategory={HelpCategory.BASKET}
      />
      <div>
        {currentStep === NaBasketStep.BASKET && (
          <BasketStep
            setCurrentStep={doSetCurrentStep}
            selectedCustomerNum={selectedCustomerNum}
          />
        )}
        {currentStep === NaBasketStep.DELIVERY_DETAILS && (
          <DeliveryDetailsStep setCurrentStep={doSetCurrentStep} />
        )}
        {currentStep === NaBasketStep.SUBMIT_ORDER && (
          <SubmitOrderStep setCurrentStep={doSetCurrentStep} />
        )}
      </div>
    </>
  );
};

export default NaBasket;
