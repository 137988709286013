import Axios from 'axios';
import { orderBy } from 'lodash';
import Country from '../../types/Country.interface';
import Option from '../../types/Option.type';

export const getCountriesOptions = (
  countries: Country[],
  emptyLabel?: boolean
): Option[] => {
  let options: Option[] = [];
  countries.forEach((f) => {
    options.push({
      label: f.name,
      value: f.countryCode,
    });
  });
  options = orderBy(options, ['label'], ['asc']);

  return ([
    {
      value: '',
      label: emptyLabel ? '' : 'COUNTRY',
    },
  ] as Option[]).concat(options);
};

export const getCountries = async (locale: string): Promise<Country[]> => {
  const { data } = await Axios.get(`../resources/countries/eu/${locale}`);
  return data;
};
