import { t } from '@lingui/macro';
import { Pagination, Space } from 'antd';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import InputLabel from '../../components/InputLabel';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Select from '../../components/Select/Select';
import QueryCacheName from '../../types/QueryCacheName.enum';
import Results from './Results/Results';
import { DATE_RANGE_OPTIONS } from './SavedEnquiries.util';
import { getSavedEnquiries } from './service';
import DateRangeSelection from './types/DateRangeSelection.enum';

const SavedEnquiries: FC = () => {
  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRangeSelection
  >(DateRangeSelection.PAST_MONTH);
  const [selectedPage, setSelectedPage] = useState<number>();

  const { data, isFetching } = useQuery(
    [QueryCacheName.MY_SAVED_ENQUIRIES, { selectedDateRange, selectedPage }],
    () => getSavedEnquiries(selectedDateRange, selectedPage),
    {
      staleTime: Infinity,
      enabled: selectedDateRange,
      keepPreviousData: true,
    }
  );

  return (
    <Space direction="vertical" size="middle" className="w-full">
      <div className="flex justify-between items-end">
        <InputLabel text={t`Select Date Range`} size="lg">
          <Select
            options={DATE_RANGE_OPTIONS}
            defaultValue={DateRangeSelection.PAST_MONTH}
            width="64"
            onChange={(e) => {
              setSelectedPage(0);
              setSelectedDateRange(e.target.value as DateRangeSelection);
            }}
          />
        </InputLabel>
        {data?.pagination && (
          <Pagination
            current={selectedPage}
            pageSize={5}
            onChange={(page) =>
              page <= data.pagination.pageCount ? setSelectedPage(page) : ''
            }
            total={data.pagination.recordCount}
            showSizeChanger={false}
          />
        )}
      </div>
      {isFetching && <LoadingSpinner horizontal />}
      {data?.content && !isFetching && <Results results={data?.content} />}
    </Space>
  );
};

export default SavedEnquiries;
