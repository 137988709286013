import moment, { Moment } from 'moment';

/**
 * Skip the weekend days when establishing the default delivery date.
 * The assumed first weekday is Monday.
 */
export const setRequestedWeekdayDefaultDate = (isEUUser: boolean): Moment => {
  if(isEUUser){
    if(moment().weekday() > 2){
      return moment().add(4, 'days');
    }
    return moment().add(2, 'days');
  };

  return moment().add(1, 'days');
};

