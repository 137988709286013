import { t } from '@lingui/macro';
import { orderBy } from 'lodash';
import Option from '../../../types/Option.type';
import CalculationType from '../types/CalculationType.enum';
import { CalculatorType } from '../types/CalculatorType.interface';
import CalculatorTypeIdentifier from '../types/CalculatorTypeIdentifier.enum';
import { CalculatorUnitTypes } from '../types/CalculatorUnitTypes.enum';

export const calculatorTypes: CalculatorType[] = [
  {
    id: '0',
    name: CalculationType.MSF_TO_WEIGHT,
    calculator: CalculatorTypeIdentifier.MSF_WEIGHT_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
  {
    id: '1',
    name: CalculationType.WEIGHT_TO_MSF,
    calculator: CalculatorTypeIdentifier.MSF_WEIGHT_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
  {
    id: '2',
    name: CalculationType.MSM_TO_WEIGHT,
    calculator: CalculatorTypeIdentifier.MSF_WEIGHT_CONVERTER,
    unit: CalculatorUnitTypes.MET,
  },
  {
    id: '3',
    name: CalculationType.WEIGHT_TO_MSM,
    calculator: CalculatorTypeIdentifier.MSF_WEIGHT_CONVERTER,
    unit: CalculatorUnitTypes.MET,
  },
  {
    id: '4',
    name: CalculationType.CWT_TO_THOUSAND_SHEETS,
    calculator: CalculatorTypeIdentifier.PRICE_CWT_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
  {
    id: '5',
    name: CalculationType.CWT_TO_LINEAL_FOOT,
    calculator: CalculatorTypeIdentifier.PRICE_CWT_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
  {
    id: '6',
    name: CalculationType.CWT_TO_MSF,
    calculator: CalculatorTypeIdentifier.PRICE_CWT_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
  {
    id: '7',
    name: CalculationType.THOUSAND_SHEETS_TO_CWT,
    calculator: CalculatorTypeIdentifier.PRICE_CWT_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
  {
    id: '8',
    name: CalculationType.TON_TO_LINEAL_FOOT,
    calculator: CalculatorTypeIdentifier.PRICE_TON_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
  {
    id: '9',
    name: CalculationType.TON_TO_MSF,
    calculator: CalculatorTypeIdentifier.PRICE_TON_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
  {
    id: '10',
    name: CalculationType.MSF_TO_TON,
    calculator: CalculatorTypeIdentifier.PRICE_TON_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
  {
    id: '11',
    name: CalculationType.CWT_TO_THOUSAND_SHEETS,
    calculator: CalculatorTypeIdentifier.PRICE_CWT_CONVERTER,
    unit: CalculatorUnitTypes.MET,
  },
  {
    id: '12',
    name: CalculationType.THOUSAND_SHEETS_TO_CWT,
    calculator: CalculatorTypeIdentifier.PRICE_CWT_CONVERTER,
    unit: CalculatorUnitTypes.MET,
  },
  {
    id: '13',
    name: CalculationType.CWT_TO_METRIC_TON,
    calculator: CalculatorTypeIdentifier.PRICE_CWT_CONVERTER,
    unit: CalculatorUnitTypes.MET,
  },
  {
    id: '14',
    name: CalculationType.METRIC_TON_TO_CWT,
    calculator: CalculatorTypeIdentifier.PRICE_CWT_CONVERTER,
    unit: CalculatorUnitTypes.MET,
  },
  {
    id: '15',
    name: CalculationType.TON_TO_METRIC_TON,
    calculator: CalculatorTypeIdentifier.PRICE_TON_CONVERTER,
    unit: CalculatorUnitTypes.IMP,
  },
];

export const getCalculatorTypes = (
  calculator: CalculatorTypeIdentifier,
  unit: CalculatorUnitTypes
): CalculatorType[] => {
  return calculatorTypes.filter(
    (calculatorType) =>
      calculatorType.calculator === calculator && calculatorType.unit === unit
  );
};

export const getTranslatedCalculationType = (
  name: CalculationType
): string | undefined => {
  switch (name) {
    case CalculationType.MSF_TO_WEIGHT:
      return t`MSF To Weight`;
    case CalculationType.WEIGHT_TO_MSF:
      return t`Weight To MSF`;
    case CalculationType.MSM_TO_WEIGHT:
      return t`MSM To Weight`;
    case CalculationType.WEIGHT_TO_MSM:
      return t`Weight To MSM`;
    case CalculationType.CWT_TO_LINEAL_FOOT:
      return t`CWT To Lineal Foot`;
    case CalculationType.CWT_TO_MSF:
      return t`CWT To MSF`;
    case CalculationType.THOUSAND_SHEETS_TO_CWT:
      return t`Thousand Sheets To CWT`;
    case CalculationType.TON_TO_LINEAL_FOOT:
      return t`Ton To Lineal Foot`;
    case CalculationType.TON_TO_MSF:
      return t`Ton To MSF`;
    case CalculationType.MSF_TO_TON:
      return t`MSF To Ton`;
    case CalculationType.CWT_TO_THOUSAND_SHEETS:
      return t`CWT To Thousand Sheets`;
    case CalculationType.CWT_TO_METRIC_TON:
      return t`CWT To Metric Ton`;
    case CalculationType.METRIC_TON_TO_CWT:
      return t`Metric Ton To CWT`;
    case CalculationType.TON_TO_METRIC_TON:
      return t`Ton To Metric Ton`;
    default:
      return undefined;
  }
};

export const EMPTY_CALC_TYPE_OPTION = {
  label: t`Select Calculation Type`,
  value: '',
  disabled: true,
};

export const getCalculationTypeOption = (
  calculatorType: CalculatorType
): Option => ({
  label: getTranslatedCalculationType(calculatorType.name),
  value: calculatorType.name,
});

export const getCalculationTypeOptions = (
  calculatorType: CalculatorTypeIdentifier,
  calculatorUnit: CalculatorUnitTypes,
  emptyOption?: Option | null
): Option[] => {
  const calcTypes = getCalculatorTypes(calculatorType, calculatorUnit);

  let options = calcTypes.map((calcType) => getCalculationTypeOption(calcType));

  options = orderBy(options, ['label'], ['asc']);

  if (emptyOption !== null) {
    options.unshift(emptyOption || EMPTY_CALC_TYPE_OPTION);
  }

  return options;
};
