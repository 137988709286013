import Option from '../../../../types/Option.type';

const convertToHumanReadableGrammage = (grammage: string) =>
  `${parseInt(grammage, 10)} g/m²`;

export const getTextPagesOptions = (
  items: any[] | undefined,
  labelField: string,
  valueField: string
): Option[] => {
  const options: Option[] = [
    {
      label: '',
      value: '',
    },
  ];
  return items
    ? options.concat(
        items.map((item) => {
          if (labelField === 'grammage') {
            return {
              value: item[valueField],
              label: convertToHumanReadableGrammage(item[labelField]),
            };
          }
          return {
            value: item[valueField],
            label: item[labelField],
          };
        })
      )
    : options;
};
