import useValues from '../../hooks/useValues';
import { EMPTY_OPTION } from '../../services/Util/emptyOption.const';
import generateOptions from '../../services/Util/generateOptions.util';
import Option from '../../types/Option.type';
import ValueKey from '../../types/ValueKey.enum';

const useValueOptions = (key: ValueKey): { valueOptions: Option[] } => {
  const { items } = useValues(key);

  const valueOptions = items
    ? generateOptions(
        items,
        (item) => ({
          label: item.text,
          value: item.value,
        }),
        EMPTY_OPTION
      )
    : [];

  return {
    valueOptions,
  };
};

export default useValueOptions;
