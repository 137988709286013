import { find } from 'lodash';
import { FieldError } from 'react-hook-form';
import moment from 'moment';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import AvailabilityBasketEU from '../../../types/AvailabilityBasketEU.interface';
import Option from '../../../types/Option.type';
import CertificationValue from './types/CertificationValue.enum';
import { AvailabilityBasketItem } from '../../../types/AvailabilityBasketItem.interface';

export const isBelowMinimum = (basket: AvailabilityBasketEU): boolean => {
  const item = find(basket.items, {
    belowMinimumFlag: 'X',
  });

  return !!item;
};

export const getTotalPrice = (basket: AvailabilityBasketEU): number => {
  return basket.items.reduce((acc, next) => acc + (next.priceOrder || 0), 0);
};

export const getCertificationOptions = (): Option[] => {
  return [
    {
      label: CertificationValue.NONE,
      value: CertificationValue.NONE,
    },
    {
      label: CertificationValue.FSC,
      value: CertificationValue.FSC,
    },
    {
      label: CertificationValue.PEFC,
      value: CertificationValue.PEFC,
    },
  ];
};

export const getCertificationDefaultValue = (
  item: AvailabilityBasketItem
): CertificationValue => {
  if (item.paperType === CertificationValue.FSC) {
    return CertificationValue.FSC;
  }
  if (item.paperType === CertificationValue.PEFC) {
    return CertificationValue.PEFC;
  }

  return CertificationValue.NONE;
};

export const availabilityUpdateRequired = (
  basket: AvailabilityBasketEU
): boolean => {
  let update = false;
  basket.items.forEach((item) => {
    if (item.requiresUpdate || item.delete) {
      update = true;
    }
  });

  return update;
};

export const showUseLatest = (basket: AvailabilityBasketEU): boolean => {
  if (basket.items.length <= 1) {
    return false;
  }
  let notTheSameDate = false;
  const firstDate = basket.items[0].confirmedDate;
  basket.items.forEach((item) => {
    if (item.confirmedDate !== firstDate) {
      notTheSameDate = true;
    }
  });

  return notTheSameDate;
};

export const backToSearch = async () => {
  window.open(`#/savedenquiries`, '_self');
};

export const isInputDateBeforeRequestedDate = (
  inputDateStr: string,
  requestedDateStr: number | undefined
): boolean => {
  if (!inputDateStr) {
    return true;
  }

  const inputDate = moment(new Date(inputDateStr)).format('YYYY-MM-DD');
  const requestedDate = moment(new Date(requestedDateStr || '')).format(
    'YYYY-MM-DD'
  );

  return inputDate < requestedDate;
};

export const hasRequestedDateError = (
  errors: FieldErrors,
  index: number
): boolean => {
  const formErrorKey = `basket`;

  const requestedDateError = errors[formErrorKey]
    ? errors[formErrorKey][index]?.requestedDate
    : undefined;
  return !!requestedDateError;
};

export const getRequestedQuantityError = (
  errors: FieldErrors,
  index: number
): FieldError | undefined => {
  const formErrorKey = `basket`;

  const requestedQuantityError = errors[formErrorKey]
    ? errors[formErrorKey][index]?.requestedQuantity
    : undefined;

  return requestedQuantityError;
};

export const isAnyRequestedDateBeforeToday = (
  basket: AvailabilityBasketEU,
  errors: FieldErrors
): boolean => {
  let dateBefore = false;
  basket.items.forEach((item, index) => {
    if (hasRequestedDateError(errors, index)) {
      dateBefore = true;
    }
  });
  return dateBefore;
};
