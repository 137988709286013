import create from 'zustand';

type BasketState = {
  loading: boolean;
  localLoadingOnly: boolean;
};

const useSappiBasketStore = create<BasketState>(() => ({
  loading: false,
  localLoadingOnly: false, // variable to check if all subscribers of loading should care or just the basket
}));

export default useSappiBasketStore;
