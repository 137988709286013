import { QueryResult, useQuery } from 'react-query';
import QueryCacheName from '../../../types/QueryCacheName.enum';
import { getLiveAgentChatConfig } from '../Chat.service';
import LiveAgentChatConfig from '../types/LiveAgentChatConfig.interface';

const useLiveAgentChatConfig = (): QueryResult<LiveAgentChatConfig> => {
  return useQuery(
    [QueryCacheName.LIVE_AGENT_CHAT_CONFIG],
    () => getLiveAgentChatConfig(),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
};

export default useLiveAgentChatConfig;
