import React, { FC } from 'react';
import { AccountSectionProps } from './types/AccountSectionProps.interface';

const AccountSection: FC<AccountSectionProps> = ({
  headingText,
  className = '',
  children,
}) => {
  return (
    <section className={className}>
      <h2 className="mb-9 text-4xl">{headingText}</h2>
      <div>{children}</div>
    </section>
  );
};

export default AccountSection;
