import { Trans } from '@lingui/macro';
import { isNil } from 'lodash';
import React from 'react';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import useRole from '../../../../hooks/useRole';
import { getPutUpCategoryByCode } from '../../../../services/Product';
import { AuthorityRole } from '../../../../types/Authority.interface';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import { TrackOrder } from '../../../../types/TrackOrder.interface';
import stripZeros from '../../../../services/Util/stripZeros.util';
import '../OrderDetailsTable.scss';

interface IDescriptionCell {
  record: TrackOrder;
  loading?: boolean;
}

const DescriptionCell: React.FunctionComponent<IDescriptionCell> = ({
  record,
}) => {
  const { hasRole } = useRole();
  const { format } = useNumberFormatter();
  const putUp = Number.isFinite(record?.overview?.putup)
    ? record.overview.putup
    : null;

  return (
    <>
      {record.materialDescription && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderDetails.brand">Brand</Trans>
          </dt>
          <dd className="font-bold">{record.materialDescription}</dd>
        </dl>
      )}
      {record.zzcogrm && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            {hasRole(AuthorityRole.ROLE_EU) ? (
              <Trans id="orderDetails.grammage">Grammage</Trans>
            ) : (
              <Trans id="orderDetails.basisweight">Basis Weight</Trans>
            )}
          </dt>
          <dd className="font-bold">{record.zzcogrm}</dd>
        </dl>
      )}
      {record.confirmedWidth && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderDetails.width">Width</Trans>
          </dt>
          <dd className="font-bold">{record.confirmedWidth}</dd>
        </dl>
      )}
      {record.confirmedLength && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderDetails.length">Length</Trans>
          </dt>
          <dd className="font-bold">{record.confirmedLength}</dd>
        </dl>
      )}
      {record.reelDiameter && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            {hasRole(AuthorityRole.ROLE_EU) ? (
              <Trans id="orderDetails.reelDia">Reel Diameter</Trans>
            ) : (
              <Trans id="orderDetails.reelDiaNA">Roll Diameter</Trans>
            )}
          </dt>
          {hasRole(AuthorityRole.ROLE_EU) ? (
            <dd className="font-bold">{stripZeros(record.reelDiameter)}</dd>
          ) : (
            <dd className="font-bold">{record.reelDiameter}</dd>
          )}
        </dl>
      )}
      {hasRole(AuthorityRole.ROLE_NA) &&
        !isNil(record.weight) &&
        putUp !== null && (
          <dl className="mb-2.5 flex">
            <dt className="pr-2 label-colon">
              {getPutUpCategoryByCode(putUp) === PutUpCategory.ROLL ? (
                <Trans>Avg Roll Wgt</Trans>
              ) : (
                <Trans>M Weight</Trans>
              )}
            </dt>
            <dd className="font-bold">{format(String(record.weight))}</dd>
          </dl>
        )}
      {(record.labelText1 ||
        record.labelText2 ||
        record.labelText3 ||
        record.labelText4) && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderDetails.label">Label Text/Stencil</Trans>
          </dt>
          <dd className="font-bold">{record.labelText1}</dd>
          {record.labelText2 && (
            <dd className="font-bold">{record.labelText2}</dd>
          )}
          {record.labelText3 && (
            <dd className="font-bold">{record.labelText3}</dd>
          )}
          {record.labelText4 && (
            <dd className="font-bold">{record.labelText4}</dd>
          )}
        </dl>
      )}
      {record.overview.kcode && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans>Sappi SKU</Trans>
          </dt>
          <dd className="font-bold">{record.overview.kcode}</dd>
        </dl>
      )}
      {record.materialNumber && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderDetails.material">Customer Material #</Trans>
          </dt>
          <dd className="font-bold">{record.materialNumber}</dd>
        </dl>
      )}
      {record.reelWrappingDesc && (
        <dl className=" mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderDetails.reelWrapping">Reel Wrapping</Trans>
          </dt>
          <dd className="font-bold">{record.reelWrappingDesc}</dd>
        </dl>
      )}
      {record.reelPackageDesc && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderDetails.reelPackage">Reel Package</Trans>
          </dt>
          <dd className="font-bold">{record.reelPackageDesc}</dd>
        </dl>
      )}
      {record.windingDirectionDesc && (
        <dl className="mb-2.5 flex">
          <dt className="pr-2 label-colon">
            <Trans id="orderDetails.windingDirection">Winding Direction</Trans>
          </dt>
          <dd className="font-bold">{record.windingDirectionDesc}</dd>
        </dl>
      )}
    </>
  );
};

export default DescriptionCell;
