import { Trans } from '@lingui/macro';
import * as React from 'react';
import SamplesProductTypeEnum from '../../types/Samples/SamplesProductType.enum';

interface ISamplesProductTypeProps {
  type: SamplesProductTypeEnum;
}

const SamplesProductType: React.FunctionComponent<ISamplesProductTypeProps> = ({
  type,
}) => {
  const productType = () => {
    switch (type) {
      case SamplesProductTypeEnum.UNPRINTED:
        return <Trans>Unprinted</Trans>;
      case SamplesProductTypeEnum.PRINTED:
        return <Trans>Printed</Trans>;
      case SamplesProductTypeEnum.PROMOTIONAL:
        return <Trans>Promotional</Trans>;
      case SamplesProductTypeEnum.CORPORATE:
        return <Trans>Corporate</Trans>;
      case SamplesProductTypeEnum.SUSTAINABILITY:
        return <Trans>Sustainability</Trans>;
      case SamplesProductTypeEnum.SAPPIANDYOU:
        return <Trans>Sappi &amp; You</Trans>;
      case SamplesProductTypeEnum.TECHNICAL:
        return <Trans>Technical</Trans>;
      default:
        return <></>;
    }
  };
  return <>{productType()}</>;
};

export default SamplesProductType;
