import { Trans } from '@lingui/macro';
import React, { FC, HTMLProps } from 'react';
import Note from '../../components/Note';

const CalculatorsNote: FC<HTMLProps<HTMLDivElement>> = ({
  className = '',
  ...rest
}) => {
  return (
    <Note className={className} {...rest}>
      <Trans>
        The calculations generated from our online calculator tools are for
        estimation and informational purposes only and are not guaranteed.
      </Trans>
    </Note>
  );
};

export default CalculatorsNote;
