const padZeros = (
  value: string, 
  length: number
): string => {
  
  if (value) {
    let number = value;
    while (number.length < length) number = `0${number}`;
    return number;
  }
  return '';
};

export default padZeros;
