import { forOwn, includes, trim, uniq } from 'lodash';
import moment from 'moment';
import { UseFormMethods } from 'react-hook-form';
import { DocumentQueryParam } from '../../services/Documents/DocumentQueryParam.interface';
import { AvailableDocumentRow } from './AvailableDocuments/AvailableDocumentRow.interface';
import { DocumentPrinterResults } from '../../types/DocumentPrinterResults.interface';
import { DocumentPrinterOverview } from '../../types/DocumentPrinterOverview.interface';
import { DocumentTypes } from '../../types/DocumentTypes.interface';

export function prepareFiltersForDocumentPrinter(
  queryParam: DocumentQueryParam
): string {
  let parameters = '';

  forOwn(queryParam, (value, key) => {
    if (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      value !== 'Invalid date' &&
      trim(String(value)) !== '' &&
      trim(String(key)) !== 'dateFromValue' &&
      trim(String(key)) !== 'dateToValue' &&
      trim(String(key)) !== 'pageSize' &&
      trim(String(key)) !== 'usePaginatedVersion' &&
      trim(String(key)) !== 'minOrderDate' &&
      trim(String(key)) !== 'maxOrderDate'
    ) {
      parameters += `${key}=${value}&`;
    }
  });
  return parameters.slice(0, -1);
}

export const resetDate = (setValue: UseFormMethods['setValue']): void => {
  setValue('dateFromValue', moment());
  setTimeout(() => setValue('dateFromValue', null), 0);

  setValue('dateToValue', moment());
  setTimeout(() => setValue('dateToValue', null), 0);
};

export const isKeyExisting = (
  expKeys: (string | null | undefined)[],
  record: AvailableDocumentRow
): boolean => {
  return (
    (record.isChild &&
      includes(
        expKeys,
        record.overviews[0].documentNumber +
          record.overviews[0].weightListNumber
      )) ||
    (!record.isChild && includes(expKeys, record.overviews[0].documentNumber))
  );
};

export const printDocs = (
  tableData: AvailableDocumentRow[] | undefined,
  documentPrinterData: DocumentPrinterResults
): void => {
  const parameters: string[] = [];

  if (tableData) {
    tableData.map((row) => {
      if (row.overviews) {
        row.overviews.map((o) => {
          if (o.printCoaDoc) {
            parameters.push(o.coaDocId);
          }
          if (o.printBarCodeWeightListDoc) {
            parameters.push(o.barCodeWeightListDocId);
          }
          if (o.printConfirmationDoc) {
            parameters.push(o.confirmationDocId);
          }
          if (o.printCreditNoteDoc) {
            parameters.push(o.creditNoteDocId);
          }
          if (o.printDeliveryDoc) {
            parameters.push(o.deliveryDocId);
          }
          if (o.printInvoiceDoc) {
            parameters.push(o.invoiceDocId);
          }
          if (o.printWeightListDoc) {
            parameters.push(o.weightListDocId);
          }
          if (o.printCoaDoc) {
            parameters.push(o.coaDocId);
          }
          return o;
        });
      }
      return row;
    });
  }

  const parameterStr = uniq(parameters)
    .map((q: string) => {
      return `documentId=${q}&`;
    })
    .join('');

  if (
    documentPrinterData &&
    documentPrinterData.documentPrinters[0] &&
    parameterStr
  ) {
    window.open(`../resources/documents?${parameterStr}`, '_self');
  }
};

export const getRowKey = (record: AvailableDocumentRow): string => {
  return record.isChild
    ? record.overviews[0].documentNumber + record.overviews[0].weightListNumber
    : record.overviews[0].documentNumber;
};

export const setPrintDocForRow = (
  row: DocumentPrinterOverview,
  documentType: DocumentTypes,
  toPrint: boolean
): DocumentPrinterOverview => {
  switch (documentType) {
    case DocumentTypes.ORDER_CONFIRMATION: {
      row.printConfirmationDoc = toPrint;
      break;
    }
    case DocumentTypes.WEIGHTLIST: {
      row.printWeightListDoc = toPrint;
      break;
    }
    case DocumentTypes.INVOICE: {
      row.printInvoiceDoc = toPrint;
      break;
    }
    case DocumentTypes.CERTIFICATE_OF_ANALYSIS: {
      row.printCoaDoc = toPrint;
      break;
    }
    case DocumentTypes.BAR_CODE_WEIGHT_LIST: {
      row.printBarCodeWeightListDoc = toPrint;
      break;
    }
    case DocumentTypes.DELIVERY: {
      row.printDeliveryDoc = toPrint;
      break;
    }
    default:
      row.printCreditNoteDoc = toPrint;
  }
  return row;
};

export const updatePrintFlag = (
  record: AvailableDocumentRow,
  row: DocumentPrinterOverview,
  documentType: DocumentTypes,
  toPrint: boolean
): DocumentPrinterOverview => {
  if (
    documentType === DocumentTypes.ORDER_CONFIRMATION &&
    row.confirmationNumber === record.overviews[0].confirmationNumber
  ) {
    row.printConfirmationDoc = toPrint;
  }
  if (
    documentType === DocumentTypes.INVOICE &&
    row.invoiceNumber === record.overviews[0].invoiceNumber
  ) {
    row.printInvoiceDoc = toPrint;
  }
  return row;
};
