import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import BasketPopUpBasketProps from '../../../../components/BasketPopUp/BasketPopUpBasketProps';
import BasketPopUpButton from '../../../../components/BasketPopUp/BasketPopUpButton';
import BasketPopUpButtons from '../../../../components/BasketPopUp/BasketPopUpButtons';
import BasketPopUpHeader from '../../../../components/BasketPopUp/BasketPopUpHeader';
import BasketPopUpItemProps from '../../../../components/BasketPopUp/BasketPopUpItemProps';
import { SamplesOrder } from '../../../../types/Samples/SamplesOrder.interface';
import { SamplesProduct } from '../../../../types/Samples/SamplesProduct.interface';
import { resetState } from './Copies.util';
import useAnalytics from '../../../../hooks/useAnalytics';

interface IAddedToBasketPopupProps {
  order?: SamplesOrder;
  product?: SamplesProduct;
}

const AddedToBasketDummyPopUp: React.FunctionComponent<IAddedToBasketPopupProps> = ({
  order,
  product,
}) => {
  const history = useHistory();
  const numItems = order?.products?.length || 0;
  const { trackPageView } = useAnalytics();
  const goToCheckout = (): void => {
    resetState();
    trackPageView('SAMPLES_BASKET', 'CONTINUE_CHECKOUT');
    history.push('/samples/selectBuyer');
  };

  const continueShopping = (): void => {
    resetState();
    trackPageView('SAMPLES_BASKET', 'CONTINUE_SHOPPING', {
      customerId: '',
    });
    history.push('/samples/findProducts');
  };

  return (
    <>
      <div className="p-1.5 text-gray-dark200" style={{ width: '280px' }}>
        <BasketPopUpHeader>
          <Trans>Added to Basket</Trans>
        </BasketPopUpHeader>
        <BasketPopUpItemProps>
          {product?.materialDescription}
        </BasketPopUpItemProps>
        <Space direction="vertical" size="small" className="w-full px-2">
          <BasketPopUpBasketProps>
            {numItems} <Trans>items in your basket.</Trans>
          </BasketPopUpBasketProps>
          <BasketPopUpButtons>
            <BasketPopUpButton theme="inverse" onClick={goToCheckout}>
              <Trans>Checkout</Trans>
            </BasketPopUpButton>
            <BasketPopUpButton theme="primary" onClick={continueShopping}>
              <Trans>Continue Shopping</Trans>
            </BasketPopUpButton>
          </BasketPopUpButtons>
        </Space>
      </div>
    </>
  );
};

export default AddedToBasketDummyPopUp;
