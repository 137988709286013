import { Trans } from '@lingui/macro';
import * as React from 'react';
import { Exception } from '../../types/Exception.interface';

interface IErrorMessageProps {
  message: string | null;
  exceptions: Exception[] | null;
}

const ErrorMessage: React.FunctionComponent<IErrorMessageProps> = ({
  message,
  exceptions,
}) => {
  return (
    <>
      {message && <div className="font-bold mt-5">{message}</div>}
      {exceptions && (
        <div className="text-red-red font-bold">
          <ul>
            {exceptions.map((exception) => (
              <li key={exception.id}>
                <Trans>Error on line number</Trans> {exception.lineNumber} -
                {exception.message}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default ErrorMessage;
