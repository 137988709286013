import React, { FC } from 'react';
import EqEnvImpactEquivalent from '../../types/EqCalculator/EqEnvImpactEquivalent.interface';

interface ImpactEquivalentProps {
  impactEquivalent: EqEnvImpactEquivalent;
}

const ImpactEquivalent: FC<ImpactEquivalentProps> = ({ impactEquivalent }) => {
  return (
    <div className="flex items-center">
      <div className="flex-grow-0">
        <img src={impactEquivalent.image} alt="" />
      </div>
      <div className="flex-grow">
        <div className="text-lg font-bold text-blue-euHeader">
          {impactEquivalent.amount.toFixed(1).toLocaleString()}
        </div>
        <div>{impactEquivalent.type}</div>
      </div>
    </div>
  );
};

export default ImpactEquivalent;
