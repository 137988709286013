import { t, Trans } from '@lingui/macro';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import InputLabel from '../../components/InputLabel';

interface ISKUSearchProps {
  searchString?: string;
  onSubmit: (data: string) => void;
}

const SKUSearch: React.FunctionComponent<ISKUSearchProps> = ({
  searchString,
  onSubmit,
}) => {
  const { register, handleSubmit, setValue, watch } = useForm();

  const submit = handleSubmit(({ skuSearch }) => {
    onSubmit(skuSearch);
  });

  useEffect(() => {
    setValue('skuSearch', searchString);
  }, [setValue, searchString]);
  const value = watch('skuSearch');

  return (
    <form className="flex items-center w-1/2" onSubmit={submit}>
      <div className="w-1/2">
        <InputLabel
          infoBubble={t`My Material # or Sappi SKU`}
          text={<strong>SKU</strong>}
          position="side"
        >
          <Input
            name="skuSearch"
            ref={register}
            data-test-id="qob-sku-search"
          />
        </InputLabel>
      </div>
      <Button
        theme="inverse"
        type="submit"
        disabled={value === ''}
        data-test-id="qob-sku-search-submit"
      >
        <Trans>Search</Trans>
      </Button>
    </form>
  );
};

export default SKUSearch;
