import { Tag as AntTag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import * as React from 'react';
import './Tag.scss';

const Tag: React.FunctionComponent<TagProps> = ({ className, ...rest }) => {
  return (
    <AntTag
      className={`sappi-ant-override-tag inline-flex items-center ${
        className || ''
      }`}
      {...rest}
    />
  );
};

export default Tag;
