import { t } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC, useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import Input from '../../../../components/Input/Input';
import InputLabel from '../../../../components/InputLabel';
import Select from '../../../../components/Select/Select';
import { NaShipTo } from '../../../../types/NaShipTo.interface';
import CustomerAddress from '../CustomerAddress';
import NewDeliveryAddress from './NewDeliveryAddress';
import { DeliveryDetailsForm } from './types/DeliveryDetailsForm.type';
import DeliveryDetailsFormField from './types/DeliveryDetailsFormFields.enum';
import useDefaultValues from './useDefaultValues';
import useDeliveryLocationOptions from './useDeliveryLocationOptions';
import useDeliveryLocations from './useDeliveryLocations';

interface IDeliveryLocationProps {
  formMethods: UseFormMethods<DeliveryDetailsForm>;
  defaultShipToCustomerNumber?: string;
  selectedShipToCustomer: NaShipTo | undefined;
}

const DeliveryLocation: FC<IDeliveryLocationProps> = ({
  formMethods,
  defaultShipToCustomerNumber,
  selectedShipToCustomer,
}) => {
  const { register, setValue, reset, getValues } = formMethods;
  const { deliveryLocations } = useDeliveryLocations();
  const { deliveryLocationOptions, isFetching } = useDeliveryLocationOptions();
  const { defaultValues } = useDefaultValues();

  // Initial population of Delivery location dropdown
  useEffect(() => {
    if (defaultShipToCustomerNumber && deliveryLocations.length > 0) {
      reset(
        {
          ...getValues(),
          [DeliveryDetailsFormField.SELECTED_SHIP_TO]: defaultShipToCustomerNumber,
        },
        { isValid: true }
      );
    }
  }, [
    deliveryLocations,
    setValue,
    defaultShipToCustomerNumber,
    reset,
    getValues,
  ]);

  return (
    <Space direction="vertical" size="large" className="w-full">
      <div className="grid grid-cols-2">
        <InputLabel
          text={t`Delivery Location`}
          size="lg"
          required
          isFetching={isFetching}
        >
          <Select
            name={DeliveryDetailsFormField.SELECTED_SHIP_TO}
            ref={register()}
            options={deliveryLocationOptions}
            onChange={(e) =>
              reset({
                ...defaultValues,
                [DeliveryDetailsFormField.SELECTED_SHIP_TO]: e.target.value,
              })
            }
          />
        </InputLabel>
        {selectedShipToCustomer && (
          <CustomerAddress
            className="mt-8"
            customerNumber={selectedShipToCustomer.customerNumber}
            customerName={selectedShipToCustomer.name1}
            street={selectedShipToCustomer.address1}
            city={selectedShipToCustomer.city}
            postalCode={selectedShipToCustomer.postalCode}
            country={selectedShipToCustomer.country || ''}
          />
        )}
      </div>
      <div className="grid grid-cols-2">
        <Space direction="vertical" size="middle">
          <Input
            name={DeliveryDetailsFormField.SOLD_TO_PO}
            ref={register({ required: true })}
            placeholder={t`Sold to PO Number`}
            required
          />
          {!selectedShipToCustomer && !isFetching && (
            <NewDeliveryAddress register={register} />
          )}
        </Space>
        <Input
          name={DeliveryDetailsFormField.SHIP_TO_PO}
          ref={register({ maxLength: 35 })}
          maxLength={35}
          placeholder={t`Ship to PO Number`}
        />
      </div>
    </Space>
  );
};

export default DeliveryLocation;
