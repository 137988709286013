import * as React from 'react';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import UnitOfMeasure from '../../../../types/UnitOfMeasure.enum';
import { ConsignmentInventoryItem } from '../../types/ConsignmentInventoryItem.interface';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';

interface ITableWeightCellProps {
  item: ConsignmentInventoryItem;
}

const TableWeightCell: React.FunctionComponent<ITableWeightCellProps> = ({
  item,
}) => {
  const { format } = useNumberFormatter();
  const { isMetric } = useMeasurementSystem();

  return (
    <>
      {!isMetric && item.poundsGross && `${format(item.poundsGross)} ${UnitOfMeasure.LB}`}
      {isMetric && item.kgGross && `${format(item.kgGross)} ${UnitOfMeasure.KG}`}
    </>
  );
};

export default TableWeightCell;
