import Axios from 'axios';
import Customer from '../../types/Customer.interface';
import { GetSamplesOrdersParams } from '../../types/Samples/GetSamplesOrdersParams.interface';
import { SamplesOrdersResponse } from '../../types/Samples/SamplesOrdersResponse.interface';

export const getSamplesOrders = async (
  params: GetSamplesOrdersParams
): Promise<SamplesOrdersResponse> => {
  const { data } = await Axios.get('/portal/resources/samples/history', {
    params,
  });
  return data;
};

export const getCustomersFromSamplesSearch = async (): Promise<Customer[]> => {
  const { data } = await Axios.get(
    '/portal/resources/samples/history/customers'
  );
  return data;
};

export const getStatusesFromSamplesSearch = async (): Promise<string[]> => {
  const { data } = await Axios.get(
    '/portal/resources/samples/history/statuses'
  );
  return data;
};
