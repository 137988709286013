import { t } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Space } from 'antd';
import useAnalytics from '../../../hooks/useAnalytics';
import { getSquareFootagePerSingleSheet } from '../Calculator.service';
import CalculatorTemplate from '../CalculatorTemplate/CalculatorTemplate';
import Select from '../../../components/Select/Select';
import { METRIC_IMP_OPTIONS } from '../Calculators.util';
import { CalculatorUnitTypes } from '../types/CalculatorUnitTypes.enum';
import MeasurementSystem from '../../../types/MeasurementSystem.enum';
import useMeasurementSystem from '../../../hooks/useMeasurementSystem';
import { AreaPerSheet } from './AreaPerSheet.interface';
import MetricAreaPerSheet from './MetricAreaPerSheet';
import ImperialAreaPerSheet from './ImperialAreaPerSheet';
import { CALCULATOR_LABELS } from '../types/Calculator.constants';

const AreaPerSheetCalculator: FC = () => {
  const {
    register,
    reset,
    errors,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = useForm({ mode: 'all' });
  const { trackPageView } = useAnalytics();
  const { measurementSystem } = useMeasurementSystem();
  const [isCalculating, setIsCalculating] = useState(false);

  const metImp = watch('metImp');
  const isMetric = metImp === CalculatorUnitTypes.MET;
  const [result, setResult] = useState<string>();
  const defaultMetImp =
    measurementSystem === MeasurementSystem.METRIC
      ? CalculatorUnitTypes.MET
      : CalculatorUnitTypes.IMP;

  useEffect(() => {
    setValue('metImp', defaultMetImp);
  }, [defaultMetImp, setValue]);

  const onCalculate = async (data: AreaPerSheet) => {
    trackPageView('CALCULATORS', 'AREA_PER_SHEET');
    setIsCalculating(true);
    const response = await getSquareFootagePerSingleSheet(data);
    setResult(`${isMetric ? response.squareArea : response.squareAreaImp}`);
    setIsCalculating(false);
  };

  const onReset = () => {
    reset({
      metImp: defaultMetImp,
    });
    setResult('');
  };
  
  const onMetricImperialChange = (selectedValue: CalculatorUnitTypes) => {
    reset({
      metImp: selectedValue,
    });
    setResult('');
  };

  return (
    <CalculatorTemplate
      title={CALCULATOR_LABELS.AREA_PER_SHEET}
      instructions={t`All fields must be filled in to calculate`}
      onCalculate={handleSubmit(onCalculate)}
      isCalculating={isCalculating}
      isCalculateDisabled={!isValid || isCalculating}
      onReset={() => onReset()}
      result={
        <div className="flex justify-center -mx-4">
          {result}&nbsp;
          <div className="text-left">
            {isMetric ? t`Square Meters` : t`Square Feet`}
          </div>
        </div>
      }
    >
      <Space direction="vertical" size="middle" className="w-full">
        <Select
          name="metImp"
          ref={register({ required: true })}
          options={METRIC_IMP_OPTIONS}
          required
          onChange={(e) =>
            onMetricImperialChange(e.target.value as CalculatorUnitTypes)
          }
        />
        {isMetric && <MetricAreaPerSheet register={register} errors={errors} />}
        {!isMetric && (
          <ImperialAreaPerSheet register={register} errors={errors} />
        )}
      </Space>
    </CalculatorTemplate>
  );
};

export default AreaPerSheetCalculator;
