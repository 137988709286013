import moment from 'moment';
import { AvailabilityBasketItem } from '../../../../types/AvailabilityBasketItem.interface';
import { AvailabilityBasketNA } from '../../../../types/AvailabilityBasketNA.interface';
import CertificationValue from '../types/CertificationValue.enum';
import CustomerBasketFormData from './types/CustomerBasketFormData.interface';
import CustomerBasketFormField from './types/CustomerBasketFormField.enum';
import CustomerBasketItemFormData from './types/CustomerBasketItemFormData.interface';

export const CELL_WIDTH_15_CSS_CLASS = 'cell-width-15';
export const CELL_WIDTH_40_CSS_CLASS = 'cell-width-40';

export const getCertificationDefaultValue = (
  basketItem: AvailabilityBasketItem,
  isReleaseUser: boolean
): string => {
  if (isReleaseUser) {
    return CertificationValue.NONE;
  }
  if (basketItem.certification) {
    return basketItem.certification;
  }
  return CertificationValue.SFI_CS;
};

const getUpdatedBasketItem = (
  existingItem: AvailabilityBasketItem,
  itemFormData: CustomerBasketItemFormData
): AvailabilityBasketItem => {
  const newItem: AvailabilityBasketItem = { ...existingItem };

  if (itemFormData.newLabel !== undefined) {
    newItem.newLabel = itemFormData.newLabel;
  }

  if (itemFormData.certification !== undefined) {
    newItem.certification = itemFormData.certification;
  }

  const parsedRequestedQuantity = +itemFormData.requestedQuantity;
  if (Number.isFinite(parsedRequestedQuantity)) {
    newItem.requestedQuantity = parsedRequestedQuantity;
  }

  const parsedRequestedDate = +itemFormData.requestedDate;
  if (Number.isFinite(parsedRequestedDate)) {
    newItem.requestedDate = parsedRequestedDate;
  }

  if (itemFormData.customerMaterial !== undefined) {
    newItem.customerMaterial = itemFormData.customerMaterial;
  }

  if(itemFormData.ldr !== undefined) {
    newItem.ldr = itemFormData.ldr;
  }
  
  if (itemFormData.reelWidthImp !== undefined) {
    newItem.reelWidthImp = itemFormData.reelWidthImp;
  }

  if (itemFormData.reelWidth !== undefined) {
    const parsedReelWidth = +itemFormData.reelWidth;

    if (Number.isFinite(parsedReelWidth)) {
      newItem.reelWidth = parsedReelWidth;
    }
  }

  if (itemFormData.reelDiameterImp !== undefined) {
    newItem.reelDiameterImp = itemFormData.reelDiameterImp;
  }

  if (itemFormData.reelDiameter !== undefined) {
    newItem.reelDiameter = itemFormData.reelDiameter;
  }

  if (itemFormData.coreDiameterImp !== undefined) {
    newItem.coreDiameterImp = itemFormData.coreDiameterImp;
  }

  if (itemFormData.coreDiameter !== undefined) {
    const parsedCoreDiameter = +itemFormData.coreDiameter;

    if (Number.isFinite(parsedCoreDiameter)) {
      newItem.coreDiameter = parsedCoreDiameter;
    }
  }

  if (itemFormData.widthImp !== undefined) {
    newItem.widthImp = itemFormData.widthImp;
  }

  if (itemFormData.width !== undefined) {
    const parsedWidth = +itemFormData.width;

    if (Number.isFinite(parsedWidth)) {
      newItem.width = parsedWidth;
    }
  }

  if (itemFormData.lengthImp !== undefined) {
    newItem.lengthImp = itemFormData.lengthImp;
  }

  if (itemFormData.length !== undefined) {
    const parsedLength = +itemFormData.length;

    if (Number.isFinite(parsedLength)) {
      newItem.length = parsedLength;
    }
  }

  if (itemFormData.sheetsPerPalletNa !== undefined) {
    const parsedSheetsPerPalletNa = +itemFormData.sheetsPerPalletNa;

    if (Number.isFinite(parsedSheetsPerPalletNa)) {
      newItem.sheetsPerPalletNa = parsedSheetsPerPalletNa;
    }
  }

  return newItem;
};

const getUpdatedBasketItems = (
  existingItems: AvailabilityBasketItem[],
  itemsFormData: CustomerBasketItemFormData[]
): AvailabilityBasketItem[] => {
  const newItems = [...existingItems];

  existingItems.forEach((existingItem, itemIndex) => {
    const valuesForItem = itemsFormData[itemIndex];
    if (valuesForItem) {
      newItems[itemIndex] = getUpdatedBasketItem(existingItem, valuesForItem);
    }
  });

  return newItems;
};

export const getUpdatedBasket = (
  basket: AvailabilityBasketNA,
  values: CustomerBasketFormData
): AvailabilityBasketNA => {
  const newBasket = {
    ...basket,
    items: getUpdatedBasketItems(basket.items, values.items),
  };

  return newBasket;
};

const getItemDefaultValues = (
  item: AvailabilityBasketItem
): CustomerBasketItemFormData => ({
  [CustomerBasketFormField.NEW_LABEL]: item.newLabel || undefined,
  [CustomerBasketFormField.CERTIFICATION]: item.certification || undefined,
  [CustomerBasketFormField.REQUESTED_QUANTITY]: `${item.requestedQuantity}`,
  [CustomerBasketFormField.REQUESTED_DATE]: moment(item.requestedDate),
  [CustomerBasketFormField.CUSTOMER_MATERIAL]:
    item.customerMaterial || undefined,
  [CustomerBasketFormField.REEL_WIDTH_IMP]: item.reelWidthImp,
  [CustomerBasketFormField.REEL_WIDTH]: `${item.reelWidth || ''}` || undefined,
  [CustomerBasketFormField.REEL_DIAMETER_IMP]: item.reelDiameterImp,
  [CustomerBasketFormField.REEL_DIAMETER]: item.reelDiameter,
  [CustomerBasketFormField.CORE_DIAMETER_IMP]: item.coreDiameterImp,
  [CustomerBasketFormField.CORE_DIAMETER]:
    item.coreDiameter != null ? `${item.coreDiameter}` : undefined,
  [CustomerBasketFormField.WIDTH_IMP]: item.widthImp || undefined,
  [CustomerBasketFormField.WIDTH]:
    item.width != null ? `${item.width}` : undefined,
  [CustomerBasketFormField.LENGTH_IMP]: item.lengthImp || undefined,
  [CustomerBasketFormField.LENGTH]:
    item.length != null ? `${item.length}` : undefined,
  [CustomerBasketFormField.SHEETS_PER_PALLET_NA]:
    item.sheetsPerPalletNa != null ? `${item.sheetsPerPalletNa}` : undefined,
});

export const getFormDefaultValues = (
  customerBasket: AvailabilityBasketNA
): CustomerBasketFormData => ({
  items: customerBasket.items.map((item) => getItemDefaultValues(item)),
});
