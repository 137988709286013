import * as React from 'react';

interface IContainerViewRightColumnRowProps {
  label: string;
  value: number | string;
}

const ContainerViewRightColumnRow: React.FunctionComponent<IContainerViewRightColumnRowProps> = ({
  label,
  value,
}) => {
  return (
    <div className="flex justify-between bg-gray-whiteSmoke400 my-3">
      <div className="ml-4 my-6 text-blue-malibu ">{label}</div>
      <div className="mr-4 my-6">{value}</div>
    </div>
  );
};

export default ContainerViewRightColumnRow;
