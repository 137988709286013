import { split } from 'lodash';

const convertToSearchDate = (inputDate: string, isNAUser: boolean): string => {
  if (inputDate.indexOf('/') > 0) {
    const dateArray = split(inputDate, '/');

    return isNAUser
      ? `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`
      : `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
  }
  return inputDate;
};

export default convertToSearchDate;
