import { useQuery } from 'react-query';
import { getLocale, isNorthAmericanRequest } from '../services/Properties';
import QueryCacheName from '../types/QueryCacheName.enum';

const useLocale = (): {
  language: string | undefined;
  isNorthAmericanRequest: boolean | undefined;
} => {
  const { data: localeLanguage } = useQuery(
    [QueryCacheName.LOCALE],
    async () => getLocale(),
    {
      staleTime: Infinity,
    }
  );
  const { data: isNARequest } = useQuery(
    [QueryCacheName.LOCALE_COUNTRY],
    async () => isNorthAmericanRequest(),
    {
      staleTime: Infinity,
    }
  );

  return {
    language: localeLanguage,
    isNorthAmericanRequest: isNARequest,
  };
};

export default useLocale;
