enum SamplesProductType {
  UNPRINTED = 'UNPRN',
  PRINTED = 'PRINT',
  PROMOTIONAL = 'PROMO',
  CORPORATE = 'CORP',
  SUSTAINABILITY = 'SUST',
  SAPPIANDYOU = 'SYOU',
  TECHNICAL = 'TECH',
}

export default SamplesProductType;
