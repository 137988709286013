import { t, Trans } from '@lingui/macro';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useMeasurementSystem from '../../../../hooks/useMeasurementSystem';
import useNumberFormatter from '../../../../hooks/useNumberFormatter';
import MeasurementSystem from '../../../../types/MeasurementSystem.enum';
import CalculatorTemplate from '../../CalculatorTemplate/CalculatorTemplate';
import { CalculatorUnitTypes } from '../../types/CalculatorUnitTypes.enum';
import Criteria from './Criteria';
import { Criteria as RollWeightAndLengthCriteria } from './Criteria.interface';
import { calculateRollWeightAndLength } from './RollWeightAndLengthCalculatorForRelease.util';

/**
 * This is the release version of RollWeightAndLength Calculator.
 * There ae some differences:
 For Imperial
    Release user does not see Paper Brand dropdown
    Release user sees Text Weight rather than Basis Weight
    The validation of Width/Diameter/Core Diameter is different from RollWeightAndLength Calculator
 For Metric
    Release user does not see Paper Brand dropdown
    Release user sees Caliper field while NA user does not
 * */

const RollWeightAndLengthCalculatorForRelease: FC = () => {
  const {
    register,
    reset,
    errors,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm<RollWeightAndLengthCriteria>({ mode: 'all' });
  const { measurementSystem } = useMeasurementSystem();
  const { format } = useNumberFormatter();
  const [weightResult, setWeightResult] = useState<string>();
  const [lengthResult, setLengthResult] = useState<string>();
  const [isCalculating, setIsCalculating] = useState(false);

  const metImp = watch('metImp');
  const defaultMetImp =
    measurementSystem === MeasurementSystem.METRIC
      ? CalculatorUnitTypes.MET
      : CalculatorUnitTypes.IMP;

  useEffect(() => {
    setValue('metImp', defaultMetImp);
  }, [defaultMetImp, setValue]);

  const onCalculate = async (data: RollWeightAndLengthCriteria) => {
    setIsCalculating(true);
    const [rollWeight, rollLength] = calculateRollWeightAndLength(data);
    setWeightResult(format(rollWeight));
    setLengthResult(format(rollLength));
    setIsCalculating(false);
  };

  const onReset = () => {
    reset({
      metImp: defaultMetImp,
    });
    setWeightResult(undefined);
    setLengthResult(undefined);
  };

  const onMetricImperialChange = (selectedValue: CalculatorUnitTypes) => {
    reset({
      metImp: selectedValue,
    });
    setWeightResult(undefined);
    setLengthResult(undefined);
  };

  return (
    <CalculatorTemplate
      title={t`Roll Weight & Length`}
      instructions={t`All fields must be filled in to calculate roll weight & length`}
      onCalculate={handleSubmit(onCalculate)}
      isCalculating={isCalculating}
      isCalculateDisabled={!isValid || isCalculating}
      onReset={() => onReset()}
      result={
        <>
          <div>
            {metImp === CalculatorUnitTypes.IMP ? (
              <Trans>Roll Weight (lbs):</Trans>
            ) : (
              <Trans>Roll Weight (kg):</Trans>
            )}{' '}
            {weightResult}
          </div>
          <div>
            {metImp === CalculatorUnitTypes.IMP ? (
              <Trans>Roll Length (lineal feet):</Trans>
            ) : (
              <Trans>Roll Length (m):</Trans>
            )}{' '}
            {lengthResult}
          </div>
        </>
      }
    >
      <Criteria
        register={register}
        errors={errors}
        onMetricImperialChange={onMetricImperialChange}
        isImperial={metImp === CalculatorUnitTypes.IMP}
      />
    </CalculatorTemplate>
  );
};

export default RollWeightAndLengthCalculatorForRelease;
