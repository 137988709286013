import { t, Trans } from '@lingui/macro';
import * as React from 'react';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import PutUpCategory from '../../../../types/PutUpCategory.enum';
import stripZeros from '../../../../services/Util/stripZeros.util';
import { StockResultDetails } from '../../../../types/StockResultDetails.interface';

interface IPackagingCellProps {
  record: StockResultDetails;
}

const PackagingCell: React.FunctionComponent<IPackagingCellProps> = ({
  record,
}) => {
  return (
    <>
      {record.productType === PutUpCategory.SHEET && (
        <>
          <div>
            {stripZeros(record.sheetsPerPallet || '') !== '0' && (
              <>
                {stripZeros(record.sheetsPerPallet || '')}{' '}
                <Trans>SH/Pallet</Trans>
              </>
            )}
          </div>
          <div>
            {stripZeros(record.stacksPerPallet || '')} <Trans>ST/Pallet</Trans>
          </div>
          <div>
            {stripZeros(record.sheetsPerReam || '') !== '0' && (
              <>
                {stripZeros(record.sheetsPerReam || '')} <Trans>SH/Pack</Trans>
              </>
            )}
          </div>
        </>
      )}
      {(record.productType === PutUpCategory.ROLL ||
        record.productType === PutUpCategory.CUTTER) && (
        <>
          <LabelValue
            inverse
            inline
            label={t`Roll Diameter`}
            value={t`${record.reelDiameter}`}
          />
          <LabelValue
            inverse
            inline
            label={t`Roll Width`}
            value={t`${record.reelWidth}`}
          />
          <LabelValue
            inverse
            inline
            label={t`Core Diameter`}
            value={t`${record.coreDiameter}`}
          />
        </>
      )}
    </>
  );
};

export default PackagingCell;
