import { each, isString } from 'lodash';

export const getTotalPrice = (
  quantities: string[] | number[],
  prices: number[]
): number => {
  const quantityArray: number[] = [];
  each(quantities, (q) =>
    quantityArray.push((isString(q) ? parseInt(q, 10) : q) as number)
  );

  return quantityArray.reduce((sum, quantity, idx) => {
    let totalPriceForItem = 0;
    if (quantity) {
      totalPriceForItem = prices[idx] * quantity;
    }
    return sum + totalPriceForItem;
  }, 0);
};
