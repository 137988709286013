import React, { FC } from 'react';
import SampleSection from './SampleSection';
import UploadSection from './UploadSection';

const Upload: FC = () => {
  return (
    <div className="flex">
      <UploadSection style={{ width: '350px', minWidth: '350px' }} />
      <SampleSection className="flex-grow" />
    </div>
  );
};

export default Upload;
