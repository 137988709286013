import { Trans } from '@lingui/macro';
import * as React from 'react';
import { useState } from 'react';
import Tabs, { TabPane } from '../../components/Tabs/Tabs';
import useAnalytics from '../../hooks/useAnalytics';
import Results from './Results/Results';
import Status from './Results/Status.enum';
import SearchCriteria from './SearchCriteria/SearchCriteria';
import useOpenOrdersStore from './useOpenOrdersStore';

export const MILL_DIRECT_PAGE_SIZE = 10;

enum OpenOrdersTab {
  ENTER_SEARCH_CRITERIA = 'ENTER_SEARCH_CRITERIA',
  VIEW_ALL_OPEN_ORDERS = 'VIEW_ALL_OPEN_ORDERS',
}

interface IOpenOrdersProps {
  attr?: unknown;
}

const OpenOrders: React.FunctionComponent<IOpenOrdersProps> = () => {
  const defaultSelectedTab = () => {
    return OpenOrdersTab.ENTER_SEARCH_CRITERIA;
    // TODO: Add logic to determine default tab, if needed
  };
  const { trackPageView } = useAnalytics();
  const {
    customerNum: storedCustomerNum,
    setCustomerNum: setStoredCustomerNum,
  } = useOpenOrdersStore();
  const [selectedTab, setSelectedTab] = useState<OpenOrdersTab>(
    defaultSelectedTab
  );
  const [expandedResultsSections, setExpandedResultsSections] = useState<
    Status[]
  >([]);

  const selectTab = (key: OpenOrdersTab): void => {
    setSelectedTab(key);
  };

  const doSubmit = (customerNumber: string) => {
    setExpandedResultsSections([]);
    setStoredCustomerNum(customerNumber);
    selectTab(OpenOrdersTab.VIEW_ALL_OPEN_ORDERS);
    trackPageView('MILL_DIRECT', 'SEARCH', {
      customerId: customerNumber,
    });
  };

  const doReset = (): void => {
    setExpandedResultsSections([]);
    setStoredCustomerNum('');
  };

  return (
    <div style={{ minWidth: '1200px' }}>
      <Tabs
        type="card"
        activeKey={selectedTab}
        onChange={(key) => {
          selectTab(key as OpenOrdersTab);
          useOpenOrdersStore.setState({ visible: false });
        }}
      >
        <TabPane
          tab={<Trans>Enter Search Criteria</Trans>}
          key={OpenOrdersTab.ENTER_SEARCH_CRITERIA}
        >
          <SearchCriteria onSubmit={doSubmit} onReset={doReset} />
        </TabPane>
        <TabPane
          tab={<Trans>View All Open Orders</Trans>}
          key={OpenOrdersTab.VIEW_ALL_OPEN_ORDERS}
          disabled={!storedCustomerNum}
        >
          {storedCustomerNum && (
            <Results
              customerNumber={storedCustomerNum}
              activeKeys={expandedResultsSections}
              setActiveKeys={setExpandedResultsSections}
            />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OpenOrders;
