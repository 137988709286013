import { t } from '@lingui/macro';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import ControlledDatePicker from '../../../components/DatePicker/ControlledDatePicker';
import DateError from '../../../components/DatePicker/DateError';
import InputLabel from '../../../components/InputLabel';
import { ArrivalForm } from './ArrivalForm.interface';
import ArrivalFormField from './ArrivalFormField.enum';
import useArrivalDataStore from './useArrivalData.store';

interface ISearchStepArrivalDatesCellProps {
  index: number;
}

const SearchStepArrivalDatesCell: FC<ISearchStepArrivalDatesCellProps> = ({
  index,
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useFormContext<ArrivalForm>();
  const defaultArrivalValue = useArrivalDataStore(
    (state) => state?.arrivalRow[index]?.newArrivalDate
  );
  const defaultDepartureValue = useArrivalDataStore(
    (state) => state?.arrivalRow[index]?.newDepartureDate
  );
  const departureDateName = `arrivalRow.${index}.${ArrivalFormField.NEW_DEPARTURE_DATE}`;
  const arrivalDateName = `arrivalRow.${index}.${ArrivalFormField.NEW_ARRIVAL_DATE}`;
  const arrivalDateError =
    errors?.arrivalRow?.[index]?.[ArrivalFormField.NEW_ARRIVAL_DATE];
  const departureDateError =
    errors?.arrivalRow?.[index]?.[ArrivalFormField.NEW_DEPARTURE_DATE];

  return (
    <>
      <InputLabel text={t`Arrival`} size="lg">
        <ControlledDatePicker
          name={arrivalDateName}
          className="w-56"
          showTime={{ format: 'HH:mm' }}
          rules={{
            validate: {
              isDateSameOrBeforeToday: (date: Moment) =>
                date ? date.isSameOrBefore() : true,
            },
          }}
          hasError={!!arrivalDateError}
          toolTipErrorMsg={
            (arrivalDateError as any)?.type === 'isDateSameOrBeforeToday' ? (
              <DateError
                date={moment()}
                messageTemplate={t`Date must be before {date}`}
              />
            ) : undefined
          }
          control={control}
          defaultValue={defaultArrivalValue || null}
          onOk={() => {
            trigger(departureDateName);
          }}
          allowClear={false}
        />
      </InputLabel>
      <InputLabel text={t`Departure`} size="lg">
        <ControlledDatePicker
          name={departureDateName}
          className="w-56"
          showTime={{ format: 'HH:mm' }}
          hasError={!!departureDateError}
          rules={{
            validate: {
              isAfterArrival: (date: Moment) => {
                const userArrivalDate = getValues(arrivalDateName) as Moment;
                return userArrivalDate && date
                  ? date.isAfter(userArrivalDate)
                  : true;
              },
            },
          }}
          defaultValue={defaultDepartureValue || null}
          toolTipErrorMsg={
            (departureDateError as any)?.type === 'isAfterArrival' &&
            getValues(arrivalDateName) ? (
              <DateError
                date={getValues(arrivalDateName)}
                messageTemplate={t`Date must be after {date}`}
              />
            ) : undefined
          }
          control={control}
          allowClear={false}
        />
      </InputLabel>
    </>
  );
};

export default SearchStepArrivalDatesCell;
