import { useEffect, useState } from 'react';
import { filter, orderBy } from 'lodash';
import Option from '../../types/Option.type';
import { EMPTY_OPTION } from '../../services/Util/emptyOption.const';
import { deriveCustomerOptions } from '../../services/Customer';
import useUser from '../../hooks/useUser';

const useCustomerOptions = (): {
  customerOptions: Option[];
  isFetchingUser: boolean;
} => {
  const { data: user, isFetching } = useUser();
  const [customerOptions, setCustomerOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (user?.customers) {
      const stockLotCustomers = filter(
        user.customers,
        (o) => o.stockLot === true
      );

      const sortedCustomers = orderBy(
        deriveCustomerOptions(
          stockLotCustomers,
          false,
          false,
          false,
          false,
          true
        ),
        ['label']
      );
      setCustomerOptions([EMPTY_OPTION, ...sortedCustomers]);
    }
  }, [user]);

  return {
    customerOptions,
    isFetchingUser: isFetching,
  };
};

export default useCustomerOptions;
